const AccountType = ({ accountType, mainText, subText, handleClick, icon }) => {
  return (
    <>
      <div
        className="account-type-section text-light pointer"
        id="account"
        name="account"
        value="1"
        onClick={() => {
          handleClick(accountType);
        }}
      >
        <div className="flex align-items-center space-x-5">
        <div className="flex flex-col justify-center align-tems-center">
            {icon}
        </div>
        <div>
          <span style={{ fontWeight: "bold" }}>{mainText}</span>
          <div style={{ fontSize: "12px" }}>{subText}</div>
        </div>
        </div>
        
      </div>
    </>
  );
};

export default AccountType;
