import React, { useState, useEffect } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
// import Toaster from '../../../Toaster/Toaster';
import { withRouter, Redirect, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
// import { createSeason } from '../../service/action/userAction';
// import { RESET_CREATE_SEASON } from '../../service/constants';
import classNames from 'classnames';
import Toaster from '../../../Toaster/Toaster';
import { createNewGroup, createTeamGroup, editGroupName, getGroupName } from '../../../service/action/userAction';
import { useHistory } from 'react-router-dom';
import Spinner from '../../../Spinner/Spinner';



const EditTeamGroup = (props) => {

    const history = useHistory()

    const { id, id1,id2 } = useParams()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        props.editGroupDetailsHandler({ id: id2})
    }, [])

    // const msg = useSelector(state => state.createSeasonReducer.message)
    const groupDetails = useSelector(state => state.getAllGroupsReducer.user.data)




    const initialState = {
        season_name: ""
    }

    const [state, setState] = useState({
        season_name: ""
    })

    useEffect(() => {
        if (groupDetails) {
            setState(prevState => ({
                ...prevState,
                season_name: groupDetails.group_name
            }))
        }
    }, [groupDetails])

    useEffect(() => {
        if (props.getGroupDetails?.success) {
            setLoading(false)
        }
    }, [props.getGroupDetails])


    const [errors, setErrors] = useState({

        season_name: false

    })

    //setTimeout form reseting data after submit of form
    function resetData() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    }

    //clear data on submit.
    const clearState = () => {
        setState({ ...initialState });
    };

    // const [checked, setChecked] = useState(false)
    // const onClickCheck = () => setChecked(!checked)

    const eventHandler = (e) => {
        const { id, value, name } = e.target


        setState(prevState => ({
            ...prevState,
            [id]: value
            // [e.target.name]: e.target.value.trim()
        }))
        switch (name) {
            case "season_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        season_name: "Group name can not be empty"
                    }))

                    document.getElementById("season_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        season_name: false
                    }))

                    document.getElementById("season_name").classList.remove("error")
                }

                break;

            default:
        }
    }

    const notify = (msg) => toast(msg);

    const submitRequest = (e) => {
        e.preventDefault();
        // alert("click")

        if (state.season_name == "") {
            setErrors(prevState => ({
                ...prevState,
                season_name: "Group name can not be empty"
            }))
            document.getElementById("season_name").classList.add("error")
        }

        if (state.season_name !== "") {
            props.editGrouphandler({
                group_name: state.season_name,
                event: id,
                organizer: id1,
                group:id2,
                id:id2
            })
        }

    }


    useEffect(() => {
        // alert("a")
        if (props.submit?.success) {
            // notify(msg.message)
            resetData().then(clearState);
            history.push(`/event-details/${id}`)
        } else if (props.submit?.error) {
        }
    }, [props.submit])

    return (
        <>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <h5 className="dash-head">Edit Team Group</h5>
                    </div>

                    <div className='d-flex justify-content-center'>

                        {loading ? <Spinner /> : null}
                    </div>
                    {
                        !loading &&
                        <form >
                            <div className="row">
                                <div className="col-md-4 col-xl-4">
                                    <div className="mt-24">
                                        <div className="text-left">
                                            <label className="text-gray fs-13">Group Name <span className="text-red fw-m">*</span></label>
                                            <input type="text" className="form-control team-name" id="season_name" name="season_name" value={state.season_name} placeholder="Group name" required onChange={eventHandler} />

                                            {errors.season_name &&
                                                <span className='text-red small d-block'>* {errors.season_name}</span>}

                                        </div>

                                        <div className="mt-3 text-left">
                                            <a className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitRequest} >Submit</a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                    <Toaster />
                </div>
            </div>

        </>
    )
}

// export default withRouter(CreateInvoice)



const mapStateToProp = state => ({
    submit: state.editGroupReducer,
    getGroupDetails: state.getAllGroupsReducer
})

const mapDispatchToProps = dispatch => ({
    createTeamGrouphandler: user => dispatch(createTeamGroup(user)),
    editGroupDetailsHandler: user => dispatch(getGroupName(user)),
    editGrouphandler:user=>dispatch(editGroupName(user))
    // resetCreateSeasonReducer:()=>dispatch({type:RESET_CREATE_SEASON})



})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(EditTeamGroup));