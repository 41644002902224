import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  CHECK_MAIL_EXIST_ERROR,
  CHECK_MAIL_EXIST_SUCCESS,
  GET_CITY_LIST_ERROR,
  GET_CITY_LIST_SUCCESS,
  GET_SPORT_LIST_ERROR,
  GET_SPORT_LIST_SUCCESS,
  GET_STATE_LIST_ERROR,
  GET_STATE_LIST_SUCCESS,
  GET_USER_DETAILS_ERROR,
  GET_USER_DETAILS_SUCCESS,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
  RESET_CHANGE_PASSWORD,
  RESET_CHECK_MAIL_EXIST,
  RESET_GET_CITY_LIST,
  RESET_GET_SPORT_LIST,
  RESET_GET_STATE_LIST,
  RESET_GET_USER_DETAILS,
  RESET_LOGIN,
  RESET_LOGOUT,
} from "../constant";

let initialState = {
  loginDetails: {
    user: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  logout: {
    user: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  stateList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  cityList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  sportList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  changePassword: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  userDetails: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  isMailExist: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
};
export default function authReducers(state = initialState, action) {
  switch (action.type) {
    //  login details
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          success: true,
          user: action.response.data,
          error: false,
        },
      };

    case LOGIN_ERROR:
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          error: true,
          success: false,
          user: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_LOGIN:
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    //  logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        logout: {
          ...state.logout,
          success: true,
          user: action.response.data,
          error: false,
        },
      };

    case LOGOUT_ERROR:
      return {
        ...state,
        logout: {
          ...state.logout,
          error: true,
          success: false,
          user: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_LOGOUT:
      return {
        ...state,
        logout: { ...state.logout, error: false, success: false, errorMsg: "" },
      };

    //  state
    case GET_STATE_LIST_SUCCESS:
      return {
        ...state,
        stateList: {
          ...state.stateList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_STATE_LIST_ERROR:
      return {
        ...state,
        stateList: {
          ...state.stateList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_STATE_LIST:
      return {
        ...state,
        stateList: {
          ...state.stateList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    //  city
    case GET_CITY_LIST_SUCCESS:
      return {
        ...state,
        cityList: {
          ...state.cityList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_CITY_LIST_ERROR:
      return {
        ...state,
        cityList: {
          ...state.cityList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_CITY_LIST:
      return {
        ...state,
        cityList: {
          ...state.cityList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    //  sport
    case GET_SPORT_LIST_SUCCESS:
      return {
        ...state,
        sportList: {
          ...state.sportList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_SPORT_LIST_ERROR:
      return {
        ...state,
        sportList: {
          ...state.sportList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_SPORT_LIST:
      return {
        ...state,
        sportList: {
          ...state.sportList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };
    //  chANGE PASSWORD
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // use <details>
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_USER_DETAILS_ERROR:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_USER_DETAILS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // check Mail  Is Exist
    case CHECK_MAIL_EXIST_SUCCESS:
      return {
        ...state,
        isMailExist: {
          ...state.isMailExist,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case CHECK_MAIL_EXIST_ERROR:
      return {
        ...state,
        isMailExist: {
          ...state.isMailExist,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_CHECK_MAIL_EXIST:
      return {
        ...state,
        isMailExist: {
          ...state.isMailExist,
          error: false,
          data: null,
          success: false,
          errorMsg: "",
        },
      };

    default:
      return state;
  }
}
