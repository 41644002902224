import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  getOrgClubList,
  getOrgCredit,
  getRefunds,
} from "../../../service/action/userAction";
import Spinner from "../../../Spinner/Spinner";
import RefundDetails from "./RefundDetails";
import moment from "moment";
import { Modal } from "react-bootstrap";
import Toaster from "../../../Toaster/Toaster";
import { toast } from "react-toastify";
import { RESET_CREATE_CREDIT } from "../../../service/constants";

const Refund = (props) => {
  const [loading, setLoading] = useState(true);
  const [viewDetails, setviewDetails] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const refund = useSelector((state) => state.getRefundsReducer.user.data);

  useEffect(() => {
    props.refundHandler();
  }, []);

  useEffect(() => {
    if (props.refund?.success) {
      setLoading(false);
    }
  }, [props.refund]);

  useEffect(() => {
    if (props.credit.success) {
      toast.success("Refund successfully");
      props.resetcreatecreditHandler();
    }
  }, [props.credit]);

  return (
    <>
      <React.Fragment>
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="d-flex justify-content-center">
              {loading ? (
                <div className="w-full h-[60vh] flex flex-col justify-center items-center">
                  <Spinner />
                </div>
              ) : null}
            </div>

            {!loading && (
              <>
                <div className="text-left">
                  <div className="create-team-head-space">
                    <h5 className="dash-head">Refunds</h5>
                    <Link to="/create-refund" className="text-decoaration-none">
                      <button className="btn-create-new"> +Add More </button>
                    </Link>
                  </div>
                </div>
                {/* <div className="text-left"> */}
                {/* <div className="create-team-head-event"> */}
                {/* <h5 className="dash-head">Credit</h5> */}
                {/* <div className="dropdown details-drp"> */}
                {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add More</i> */}
                {/* <Link className="dropdown-item" to={`/newcredit`}> <i className="mdi mdi-plus mr-2 website-ico"></i>Create New</Link> */}
                {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <Link className="dropdown-item" to={`/add-staff`}> <i className="mdi mdi-plus mr-2 website-ico"></i>Add Staff</Link>
                                </div> */}
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}
                {refund.length == 0 ? (
                  <>
                    <div className="container d-flex justify-content-center align-items-center">
                      <span className="noti-type">No refunds found</span>
                    </div>
                  </>
                ) : (
                  <>
                    {/* desktop view */}
                    <div className="table-responsive d-none d-md-block">
                      <table className="table event-table mt-30">
                        <thead>
                          <tr>
                            <th>Events</th>
                            <th>Date</th>
                            <th>Email</th>
                            <th>Refund amount</th>
                            <th>Payment status</th>
                            {/* <th>Recepients </th> */}
                            {/* <th>Payment</th>
                                                        <th>Status</th> */}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {refund.length == 0 ? (
                            <>
                              <span className="noti-type">
                                No refunds to display
                              </span>
                            </>
                          ) : (
                            <>
                              {refund.map((v, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <div
                                        className="d-flex align-items-center"
                                        onClick={() => {
                                          setviewDetails(v);
                                          handleShow();
                                        }}
                                      >
                                        {/* <div className="dashboard-img"> */}
                                        {/* <img src={v.event.event_image !==? v.event.event_image:"./images/pch-images/pch.svg"} /> */}
                                        {/* </div> */}
                                        {v?.event ? v?.event : "N/A"}
                                      </div>
                                    </td>
                                    <td>
                                      {v?.refund_date ? v?.refund_date : "N/A"}
                                    </td>
                                    <td>
                                      {v?.receipient
                                        ? v?.receipient?.email
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {v.refund_amount
                                        ? "$" + v.refund_amount
                                        : "N/A"}
                                    </td>
                                    <td>{"Refunded"}</td>
                                    {/* <td>{v.receipient?.name}</td> */}
                                    {/* <td>{v.payment}</td>
                                                                <td>{v.status}</td> */}
                                    {/* <td>
                                                                    <div className="dropdown event-drp">
                                                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                        More
                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                            <a className="dropdown-item" href="#">
                                                                                <i className="mdi mdi-bookmark-outline"></i>
                                                                                Bookmark</a>
                                                                            <a className="dropdown-item" href="#">
                                                                                <i className="mdi mdi-plus"></i>
                                                                                Invite</a>
                                                                        </div>
                                                                    </div>
                                                                </td> */}
                                  </tr>
                                );
                              })}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {/* mobile view */}
                    <div className="mt-30 d-md-none">
                      {refund &&
                        refund.map((v, i) => {
                          return (
                            <div
                              className="d-flex justify-content-between reg-details"
                              key={i}
                            >
                              <div className="d-flex">
                                <div className="dashboard-img mr-3">
                                  <img src="./images/pch-images/pch.svg" />
                                </div>
                                <div className="reg-content">
                                  <div
                                    className="text-decoration-none text-blue fw-m"
                                    onClick={() => {
                                      setviewDetails(v);
                                      handleShow();
                                    }}
                                  >
                                    Events :{" "}
                                    {v.event_name ? v.event_name : "N/A"}
                                  </div>
                                  <span className="d-block text-gray mt-2">
                                    Date :{" "}
                                    {v?.refund_date ? v?.refund_date : "n/A"}
                                  </span>
                                  <span className="d-block text-gray mt-2">
                                    Email :{" "}
                                    {v?.receipient
                                      ? v?.receipient?.email
                                      : "N/A"}
                                  </span>
                                  <span className="d-block text-gray mt-2">
                                    Refund amount :{" "}
                                    {v.refund_amount
                                      ? "$" + v.refund_amount
                                      : "N/A"}
                                  </span>
                                  <span className="d-block text-gray mt-2">
                                    Payment status : {"Refunded"}
                                  </span>
                                  {/* <span className="d-block text-gray mt-2">Status: {v.status}</span> */}
                                </div>
                              </div>
                              {/* <div className="dropdown event-drp">
                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-bookmark-outline"></i>
                                                Bookmark</a>
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-plus"></i>
                                                Invite</a>                                           
                                        </div>
                                    </div> */}
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>{" "}
      </React.Fragment>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setviewDetails(null);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div style={{ backgroundColor: "#ffffff" }}>
          <Modal.Header
            className="d-flex justify-content-between"
            style={{ fontWeight: "bold" }}
          >
            {viewDetails?.event}
          </Modal.Header>
          <Modal.Body>
            {viewDetails?.length !== 0 && (
              <>
                <div className="row mx-2">
                  <div className="col-7">
                    <span className="d-block text-gray mt-2">Recipient:</span>{" "}
                    <span className="d-block text-gray mt-2">Email:</span>{" "}
                    <span className="d-block text-gray mt-2">
                      Payment reference number:
                    </span>{" "}
                    <span className="d-block text-gray mt-2">
                      Refund reference number:
                    </span>{" "}
                    <span className="d-block text-gray mt-2">
                      Payment code::
                    </span>{" "}
                    <span className="d-block text-gray mt-2">Date:</span>{" "}
                    <span className="d-block text-gray mt-2">
                      Refund amount:
                    </span>
                    <span className="d-block text-gray mt-2">
                      Payment status:
                    </span>{" "}
                  </div>
                  <div className="col-5">
                    <span className="d-block text-gray mt-2">
                      {viewDetails?.receipient?.name
                        ? viewDetails?.receipient?.name
                        : "N/A"}
                    </span>
                    <span className="d-block text-gray mt-2">
                      {viewDetails?.receipient?.email
                        ? viewDetails?.receipient?.email
                        : "N/A"}
                    </span>
                    <span className="d-block text-gray mt-2">
                      {viewDetails?.payment_reference_number
                        ? viewDetails?.payment_reference_number
                        : "N/A"}
                    </span>
                    <span className="d-block text-gray mt-2">
                      {viewDetails?.payment_reference_number
                        ? viewDetails?.payment_reference_number
                        : "N/A"}
                    </span>
                    <span className="d-block text-gray mt-2">
                      {viewDetails?.payment_code
                        ? viewDetails?.payment_code
                        : "N/A"}
                    </span>
                    <span className="d-block text-gray mt-2">
                      {viewDetails?.refund_date
                        ? viewDetails?.refund_date
                        : "N/A"}
                    </span>
                    <span className="d-block text-gray mt-2">
                      {viewDetails?.refund_amount
                        ? viewDetails?.refund_amount
                        : "N/A"}
                    </span>
                    <span className="d-block text-gray mt-2">{"Refunded"}</span>
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </div>
      </Modal>

      <Toaster />
    </>
  );
};

// export default withRouter(Club);

const mapStateToProps = (state) => ({
  refund: state.getRefundsReducer,
  credit: state.createcreditReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getOrg_ClubListHandler: (user) => dispatch(getOrgClubList(user)),
  refundHandler: (user) => dispatch(getRefunds(user)),
  getCreditHandler: (user) => dispatch(getOrgCredit(user)),
  resetcreatecreditHandler: () => dispatch({ type: RESET_CREATE_CREDIT }),
  // userDataHandler: user => dispatch(userData(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Refund));
