import {
  CREATE_DIVISION_LOADING,
  CREATE_DIVISION_SUCCESS,
  CREATE_DIVISION_ERROR,
  RESET_CREATE_DIVISION,
} from "../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {},
  message: "",
};

export default function createDivisionReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_DIVISION_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case CREATE_DIVISION_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        message: action.msg,
        user: action?.user,
      };

    case CREATE_DIVISION_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        errors: [],
        user: [{}],
      };

    case RESET_CREATE_DIVISION:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        errors: [],
        user: [{}],
      };

    default:
      return state;
  }
}
