import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  getAllReport,
  getOrgCredit,
  getOutstandingReport,
  getPaymentRequest,
  getReceiveReport,
  getRefundReport,
  reminder,
  reqaddpaymentAction,
} from "../../../service/action/userAction";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import Spinner from "../../../Spinner/Spinner";
import {
  REMINDER_RESET,
  RESET_PAYMENT_REQUEST,
  RESET_REQ_ADD_PAYMENT,
} from "../../../service/constants";
import Moment from "react-moment";
import { current } from "@reduxjs/toolkit";
import Dashboard from "../../dashboard/Dashboard";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import { Button, Form, Modal } from "react-bootstrap";
import classNames from "classnames";

const Report = (props) => {
  let [loading, setLoading] = useState(true);
  let [loading1, setLoading1] = useState(true);
  // let [loading2, setLoading2] = useState(true);
  // let [loading3, setLoading3] = useState(true);

  const [show, setShow] = useState(false);
  const [refunmodal, setrefunmodal] = useState(false);
  const [viewDetails, setviewDetails] = useState();

  const [activeTab, setActiveTab] = useState("request-tab");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const refundmodalClose = () => setrefunmodal(false);
  const refundmodalShow = () => setrefunmodal(true);

  const [Amount, setAmount] = useState();
  const [requestId, setrequestId] = useState();
  const [value, setvalue] = useState({
    amount: "",
  });
  const [error, seterror] = useState({
    amount: false,
  });

  const notify = (msg) => toast(msg);

  useEffect(() => {
    props.getRefundedHandler({ id: 0 });
    props.getAllReportHandler({ id: 0 });
    props.getCreditHandler();
  }, []);

  useEffect(() => {
    if (props.paymentRequest?.success) {
      notify("Successfully Send");
      props.resetPaymentRequestHandler();
    }
  }, [props.paymentRequest]);

  let history = useHistory();

  const newReq = () => {
    if (activeTab !== "Credit-tab") {
      history.push("/new-payment-request");
    } else {
      history.push("/new-credit-request");
    }
  };
  const [refund, setRefund] = useState(null);
  const [reports, setReports] = useState(null);

  const [state, setState] = useState({
    Req_search: "",
    Out_search: "",
    Recevied_search: "",
    Refund_search: "",
    Credit_search: "",
  });
  const [filterData, setFilterData] = useState({
    Req_filter: [],
    Out_filter: [],
    Recevied_filter: [],
    Refund_filter: [],
    Credit_filter: [],
  });
  useEffect(() => {
    if (props.allReport?.success) {
      const data = props.allReport.user.reportArray;
      setLoading(false);
      setLoading1(false);
      setReports(data);
      setFilterData((pre) => ({
        ...pre,
        Req_filter: data,
        Out_filter: data.filter(
          (e) => e.type == "payment_request" && e.status == 0
        ),
        Recevied_filter: data.filter((e) => e.type == "received_payment"),
      }));
    }
  }, [props.allReport]);
  useEffect(() => {
    if (props.refundReducer?.success) {
      const data = props.refundReducer.user.data;
      setRefund(data);
      setFilterData((pre) => ({
        ...pre,
        Refund_filter: data,
      }));
    }
  }, [props.refundReducer]);

  useEffect(() => {
    if (props.creditListReducer.success) {
      const data = props.creditListReducer.user.data;
      setFilterData((pre) => ({
        ...pre,
        Credit_filter: data,
      }));
    }
  }, [props.creditListReducer]);

  useEffect(() => {
    if (props.creditListReducer.success) {
      const data = props.creditListReducer.user.data;
      if (state.Credit_search !== "") {
        const val = state.Credit_search.trim();
        const filter = data.filter((e) =>
          e?.receipient?.name.toLowerCase().includes(val.toLocaleLowerCase())
        );
        setFilterData((pre) => ({
          ...pre,
          Credit_filter: [...filter],
        }));
      } else {
        setFilterData((pre) => ({
          ...pre,
          Credit_filter: [...data],
        }));
      }
    }
  }, [state.Credit_search]);

  useEffect(() => {
    if (props.allReport?.success) {
      const data = [...props.allReport.user.reportArray].filter(
        (e) => e.type == "payment_request"
      );
      if (state.Req_search === "") {
        setFilterData((pre) => ({
          ...pre,
          Req_filter: data,
        }));
      } else {
        const filter = data.filter((e) => {
          return (
            e?.email?.toLowerCase().includes(state.Req_search.toLowerCase()) ||
            e?.event_name
              ?.toLowerCase()
              .includes(state.Req_search.toLowerCase()) ||
            e?.club_first_name
              ?.toLowerCase()
              .includes(state.Req_search.toLowerCase()) ||
            e?.player_first_name
              ?.toLowerCase()
              .includes(state.Req_search.toLowerCase()) ||
            e?.parent_first_name
              ?.toLowerCase()
              .includes(state.Req_search.toLowerCase())
          );
        });
        setFilterData((pre) => ({
          ...pre,
          Req_filter: filter,
        }));
      }
    }
  }, [state.Req_search]);

  useEffect(() => {
    if (props.allReport?.success) {
      const data = [...props.allReport.user.reportArray].filter(
        (e) => e.type == "payment_request" && e.status == 0
      );
      if (state.Out_search === "") {
        setFilterData((pre) => ({
          ...pre,
          Out_filter: data,
        }));
      } else {
        const filter = data.filter((e) => {
          return (
            e?.email?.toLowerCase().includes(state.Out_search.toLowerCase()) ||
            e?.event_name
              ?.toLowerCase()
              .includes(state.Out_search.toLowerCase()) ||
            e?.club_first_name
              ?.toLowerCase()
              .includes(state.Out_search.toLowerCase()) ||
            e?.player_first_name
              ?.toLowerCase()
              .includes(state.Out_search.toLowerCase()) ||
            e?.parent_first_name
              ?.toLowerCase()
              .includes(state.Out_search.toLowerCase())
          );
        });
        setFilterData((pre) => ({
          ...pre,
          Out_filter: filter,
        }));
      }
    }
  }, [state.Out_search]);

  useEffect(() => {
    if (props.allReport?.success) {
      const data = [...props.allReport.user.reportArray].filter(
        (e) => e.type == "received_payment"
      );
      if (state.Recevied_search === "") {
        setFilterData((pre) => ({
          ...pre,
          Recevied_filter: data,
        }));
      } else {
        const filter = data.filter((e) => {
          return (
            e?.email
              ?.toLowerCase()
              .includes(state.Recevied_search.toLowerCase()) ||
            e?.event_name
              ?.toLowerCase()
              .includes(state.Recevied_search.toLowerCase()) ||
            e?.club_first_name
              ?.toLowerCase()
              .includes(state.Recevied_search.toLowerCase()) ||
            e?.player_first_name
              ?.toLowerCase()
              .includes(state.Recevied_search.toLowerCase()) ||
            e?.parent_first_name
              ?.toLowerCase()
              .includes(state.Recevied_search.toLowerCase())
          );
        });
        setFilterData((pre) => ({
          ...pre,
          Recevied_filter: filter,
        }));
      }
    }
  }, [state.Recevied_search]);

  useEffect(() => {
    if (props.reminderResponse?.success) {
      notify("Notification sent successfully.");
    }
  }, [props.reminderResponse]);

  useEffect(() => {
    if (props.reqaddpaymentReducer?.success) {
      notify(props.reqaddpaymentReducer?.message);
      handleClose();
      props.resetAddpayment();
      setvalue();
      props.getAllReportHandler({ id: 0 });
    }
  }, [props.reqaddpaymentReducer]);

  const reminder = (v) => {
    props.receipientReminder({
      payment_request_id: v._id,
      recipient_type: v.type,
    });
    props.restReminder();
  };
  const Searchval = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const reminderResponse = useSelector((state) => state.reminderResponse);
  useEffect(() => {
    if (props.reminderResponse?.success) {
      props.getAllReportHandler({ id: 0 });
      props.restReminder();
    }
  }, reminderResponse);
  const location = useLocation();

  useEffect(() => {
    const currentTab = document.getElementById(location.state);
    if (currentTab) {
      currentTab.click();
    }
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setvalue((preve) => ({
      ...preve,
      [name]: value,
    }));

    if (value > Amount) {
      seterror((preve) => ({
        ...preve,
        amount: "error",
      }));
    } else {
      seterror((preve) => ({
        ...preve,
        amount: false,
      }));
    }
  };

  const handleCreate = () => {
    if (value?.amount > Amount) {
      seterror((preve) => ({
        ...preve,
        amount: "error",
      }));
    } else if (value?.amount == "") {
      seterror((preve) => ({
        ...preve,
        amount: "error",
      }));
    } else {
      seterror((preve) => ({
        ...preve,
        amount: false,
      }));
    }

    if (!error.amount && value?.amount !== "") {
      props.reqaddpaymentAction({
        amount: value?.amount,
        requestId: requestId,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head-event">
              <div className="d-flex align-items-center">
                <h5 className="dash-head pr-2 mb-0">Payment Request</h5>
              </div>
              <div className="dropdown details-drp">
                <div className="mt-3 mt-md-0 text-right">
                  <button className="btn-create-new" onClick={newReq}>
                    + New
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="team-details mt-4 mt-md-0 justify-content-between ">
            <ul
              className="nav nav-pills mb-3 mt-3"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="=request"
                  data-toggle="pill"
                  href="#request-tab"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  onClick={() => {
                    setActiveTab("request-tab");
                  }}
                >
                  Requests
                </a>
              </li>
              <li className="nav-item " role="presentation">
                <a
                  className="nav-link "
                  id="=outstanding"
                  data-toggle="pill"
                  href="#outstanding-tab"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  onClick={() => {
                    setActiveTab("outstanding-tab");
                  }}
                >
                  Outstanding
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="=recevied"
                  data-toggle="pill"
                  href="#recevied-tab"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  onClick={() => {
                    setActiveTab("recevied-tab");
                  }}
                >
                  Received
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="=refund"
                  data-toggle="pill"
                  href="#Refund-tab"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  onClick={() => {
                    setActiveTab("Refund-tab");
                  }}
                >
                  Refunded
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="=credit"
                  data-toggle="pill"
                  href="#Credit-tab"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  onClick={() => {
                    setActiveTab("Credit-tab");
                  }}
                >
                  Credit
                </a>
              </li>
            </ul>
          </div>

          <div className="tab-content" id="pills-tabContent">
            {/* <<<<<<<<<<<<<<<<-----------------------All Request Start------------------>>>>>>>>>>>>>>>> */}

            <div
              className="tab-pane fade show active  mt-30"
              id="request-tab"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="d-md-flex align-items-center">
                <div className="search-section mt-4 mt-md-0">
                  <i className="mdi mdi-magnify search-ico"></i>
                  <input
                    type="text"
                    className="form-control"
                    name="Req_search"
                    value={state.Req_search}
                    placeholder="Search"
                    onChange={Searchval}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                {loading ? <Spinner /> : null}
              </div>

              {/* <h6 className="text-left green-head">Full Payment</h6> */}
              {!loading && filterData.Req_filter ? (
                <>
                  <div className="green-section">
                    {filterData.Req_filter.filter(
                      (e) => e.type == "payment_request"
                    ).length == 0 ? (
                      <>
                        <div className="container d-flex justify-content-center align-items-center">
                          <span className="noti-type">No request found</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="table-responsive d-none d-md-block"
                          style={{ overflowY: "auto" }}
                        >
                          <table className="table green-table mt-24">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Recipient</th>
                                <th>Email</th>
                                <th>Event name</th>
                                <th>Payment code</th>
                                {/* <th>Request Type</th> */}
                                <th>Amount</th>
                                <th>Paid date</th>
                                <th>Payment status</th>
                                <th>Reminder</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filterData.Req_filter.filter(
                                (e) => e.type == "payment_request"
                              ).map((v, i) => {
                                return (
                                  <tr key={v._id} className="pay-row">
                                    <td>{i + 1}</td>
                                    <td>
                                      <span className="filter-string">
                                        {v.recipient == "club" &&
                                        v.club_first_name !== ""
                                          ? v.club_first_name
                                          : v.recipient == "player" &&
                                            v.player_first_name !== ""
                                          ? v.player_first_name
                                          : v.recipient == "parent" &&
                                            v.parent_first_name !== ""
                                          ? v.parent_first_name
                                          : "N/A"}
                                      </span>
                                    </td>
                                    <td>{v.email ? v?.email : "N/A"}</td>
                                    <td>
                                      {v.event_name ? v.event_name : "N/A"}
                                    </td>
                                    <td>
                                      {v?.payment_code
                                        ? v?.payment_code
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {v.amount ? " $ " + v.amount : "$ 0"}
                                    </td>
                                    <td>
                                      <Moment
                                        date={new Date(v.createdAt)}
                                        format="MM DD, YYYY"
                                      />
                                    </td>
                                    <td>
                                      {v?.status === 0 ? "Outstanding" : "Paid"}
                                    </td>

                                    {v?.type === "payment_request" &&
                                    v?.status === 0 ? (
                                      <td>
                                        <button
                                          className="btn-primary rounded-pill font-weight-medium p-2"
                                          onClick={() => {
                                            reminder({
                                              type:
                                                v.recipient == "club"
                                                  ? 4
                                                  : v.recipient == "player"
                                                  ? 5
                                                  : v.recipient == "parent"
                                                  ? 6
                                                  : 0,
                                              _id: v.request_id,
                                            });
                                          }}
                                        >
                                          Remind
                                        </button>
                                      </td>
                                    ) : (
                                      <td>
                                        <div></div>
                                      </td>
                                    )}
                                    <td>
                                      {v?.status === 0 && (
                                        <div className="dropdown event-drp">
                                          <span
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <i className="mdi mdi-dots-vertical"></i>{" "}
                                          </span>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuLink"
                                          >
                                            {/* <div className="dropdown-item d-flex align-items-center justify-content-center"> */}
                                            {/* <div className="text-center"> */}
                                            <Button
                                              variant="primary"
                                              onClick={() => {
                                                handleShow();
                                                setAmount(v?.amount);
                                                setrequestId(v?.request_id);
                                                setvalue({
                                                  amount: "",
                                                });
                                                seterror({
                                                  amount: false,
                                                });
                                                // setID(v?._id);
                                              }}
                                            >
                                              Add payment
                                            </Button>

                                            {/* </div> */}
                                            {/* </div> */}
                                          </div>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>

                        {/* Mobile view */}
                        <div className="mt-30 d-md-none">
                          <div className="mt-4">
                            {filterData.Req_filter.filter(
                              (e) => e.type == "payment_request"
                            ).map((v, i) => {
                              return (
                                <div
                                  className="d-flex justify-content-between reg-details  align-items-start"
                                  key={v._id}
                                >
                                  <div className="d-flex flex-grow-1">
                                    <div className="reg-content">
                                      <strong>
                                        {v.recipient == "club" &&
                                        v.club_first_name !== ""
                                          ? v.club_first_name
                                          : v.recipient == "player" &&
                                            v.player_first_name !== ""
                                          ? v.player_first_name
                                          : v.recipient == "parent" &&
                                            v.parent_first_name !== ""
                                          ? v.parent_first_name
                                          : "N/A"}
                                      </strong>
                                      <div className="mt-2 text-gray">
                                        <div>
                                          Email : {v.email ? v?.email : "N/A"}
                                        </div>
                                        <div>
                                          Event Name :{" "}
                                          {v.event_name ? v.event_name : "N/A"}
                                        </div>
                                        <div>
                                          Payment Code :{" "}
                                          {v?.payment_code
                                            ? v?.payment_code
                                            : "N/A"}
                                        </div>
                                        <div>
                                          Amount :{" "}
                                          {v.amount ? " $ " + v.amount : "$ 0"}
                                        </div>
                                        <div>
                                          Paid Date :{" "}
                                          <Moment
                                            date={new Date(v.createdAt)}
                                            format="MM DD, YYYY"
                                          />
                                        </div>
                                        <div>
                                          Payment Status :{" "}
                                          {v?.status === 0
                                            ? "Outstanding"
                                            : "Paid"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {v?.status === 0 && (
                                    <div className="d-flex align-items-center">
                                      <button
                                        className="btn-primary rounded-pill font-weight-medium py-1 px-3"
                                        onClick={() => {
                                          reminder({
                                            type:
                                              v.recipient == "club"
                                                ? 4
                                                : v.recipient == "player"
                                                ? 5
                                                : v.recipient == "parent"
                                                ? 6
                                                : 0,
                                            _id: v.request_id,
                                          });
                                        }}
                                      >
                                        Remind
                                      </button>
                                      <div className="dropdown event-drp ml-4">
                                        <span
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="mdi mdi-dots-vertical"></i>{" "}
                                        </span>
                                        <div
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuLink"
                                        >
                                          <div
                                            className="dropdown-item d-flex align-items-center justify-content-center"
                                            onClick={() => {
                                              handleShow();
                                              setAmount(v?.amount);
                                              setrequestId(v?.request_id);
                                              setvalue({
                                                amount: "",
                                              });
                                              seterror({
                                                amount: false,
                                              });
                                              // setID(v?._id);
                                            }}
                                            style={{
                                              background: "#143d4a",
                                              color: "#ffffff",
                                            }}
                                          >
                                            Add payment
                                            {/* <div className="text-center"> */}
                                            {/* <button className="btn-primary font-weight-medium p-2">
                                          </button> */}
                                          </div>
                                          {/* </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            {/* <<<<<<<<<<<<<<<<-----------------------All Request End------------------>>>>>>>>>>>>>>>> */}

            {/* <<<<<<<<<<<<<<<<-----------------------OutStanding Start------------------>>>>>>>>>>>>>>>> */}

            <div
              className="tab-pane fade mt-30"
              id="outstanding-tab"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="d-md-flex align-items-center">
                <div className="search-section mt-4 mt-md-0">
                  <i className="mdi mdi-magnify search-ico"></i>
                  <input
                    type="text"
                    className="form-control"
                    name="Out_search"
                    value={state.Out_search}
                    placeholder="Search"
                    onChange={Searchval}
                    autoComplete="off"
                  />
                </div>
              </div>
              {/* <div className="d-flex justify-content-center">
                {loading ? <Spinner /> : null}
              </div> */}
              <>
                <div className="yellow-section">
                  {/* <h6 className="text-left yellow-head">OutStanding</h6> */}
                  {filterData.Out_filter.length === 0 ? (
                    <>
                      <div className="container d-flex justify-content-center align-items-center">
                        <span className="noti-type">No outstanding found</span>
                      </div>
                    </>
                  ) : (
                    <>
                      {
                        <div
                          className="table-responsive d-none d-md-block"
                          style={{ overflowY: "auto" }}
                        >
                          <table className="table yellow-table mt-24">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Recipient</th>
                                <th>Email</th>
                                <th>Event name</th>
                                <th>Payment code</th>
                                {/* <th>Request Type</th> */}
                                <th>Amount</th>
                                <th>Paid date</th>
                                <th>Payment status</th>
                                <th>Reminder</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filterData.Out_filter.map((v, i) => {
                                return (
                                  <tr key={v._id} className="pay-row">
                                    <td>{i + 1}</td>
                                    <td>
                                      <span className="filter-string">
                                        {v.recipient == "club" &&
                                        v.club_first_name !== ""
                                          ? v.club_first_name
                                          : v.recipient == "player" &&
                                            v.player_first_name !== ""
                                          ? v.player_first_name
                                          : v.recipient == "parent" &&
                                            v.parent_first_name !== ""
                                          ? v.parent_first_name
                                          : "N/A"}
                                      </span>
                                    </td>
                                    <td>{v.email ? v?.email : "N/A"}</td>
                                    <td>
                                      {v.event_name ? v.event_name : "N/A"}
                                    </td>
                                    <td>
                                      {v?.payment_code
                                        ? v?.payment_code
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {v.amount ? " $ " + v.amount : "$ 0"}
                                    </td>
                                    <td>
                                      <Moment
                                        date={new Date(v.createdAt)}
                                        format="MM DD, YYYY"
                                      />
                                    </td>
                                    <td>
                                      {v?.status === 0 ? "Outstanding" : "Paid"}
                                    </td>

                                    {v?.type === "payment_request" &&
                                    v?.status === 0 ? (
                                      <td>
                                        <button
                                          className="btn-primary rounded-pill font-weight-medium p-2"
                                          onClick={() => {
                                            reminder({
                                              type:
                                                v.recipient == "club"
                                                  ? 4
                                                  : v.recipient == "player"
                                                  ? 5
                                                  : v.recipient == "parent"
                                                  ? 6
                                                  : 0,
                                              _id: v.request_id,
                                            });
                                          }}
                                        >
                                          Remind
                                        </button>
                                      </td>
                                    ) : (
                                      <td>
                                        <div></div>
                                      </td>
                                    )}
                                    <td>
                                      {v?.status === 0 && (
                                        <div className="dropdown event-drp">
                                          <span
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <i className="mdi mdi-dots-vertical"></i>{" "}
                                          </span>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuLink"
                                          >
                                            {/* <div className="dropdown-item d-flex align-items-center justify-content-center"> */}
                                            {/* <div className="text-center"> */}
                                            <Button
                                              variant="primary"
                                              onClick={() => {
                                                handleShow();
                                                setAmount(v?.amount);
                                                setrequestId(v?.request_id);
                                                setvalue({
                                                  amount: "",
                                                });
                                                seterror({
                                                  amount: false,
                                                });
                                                // setID(v?._id);
                                              }}
                                            >
                                              Add payment
                                            </Button>

                                            {/* </div> */}
                                            {/* </div> */}
                                          </div>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      }
                    </>
                  )}
                  {/* Mobile view For Downpayment */}

                  <div className="mt-30 d-md-none">
                    <div className="mt-4">
                      {filterData.Out_filter.map((v, i) => {
                        return (
                          <div
                            className="d-flex justify-content-between reg-details  align-items-start"
                            key={v._id}
                          >
                            <div className="d-flex flex-grow-1">
                              <div className="reg-content">
                                <strong>
                                  {v.recipient == "club" &&
                                  v.club_first_name !== ""
                                    ? v.club_first_name
                                    : v.recipient == "player" &&
                                      v.player_first_name !== ""
                                    ? v.player_first_name
                                    : v.recipient == "parent" &&
                                      v.parent_first_name !== ""
                                    ? v.parent_first_name
                                    : "N/A"}
                                </strong>
                                <div className="mt-2 text-gray">
                                  <div>
                                    Email : {v.email ? v?.email : "N/A"}
                                  </div>
                                  <div>
                                    Event Name :{" "}
                                    {v.event_name ? v.event_name : "N/A"}
                                  </div>
                                  <div>
                                    Payment Code :{" "}
                                    {v?.payment_code ? v?.payment_code : "N/A"}
                                  </div>
                                  <div>
                                    Amount :{" "}
                                    {v.amount ? " $ " + v.amount : "$ 0"}
                                  </div>
                                  <div>
                                    Paid Date :{" "}
                                    <Moment
                                      date={new Date(v.createdAt)}
                                      format="MM DD, YYYY"
                                    />
                                  </div>
                                  <div>
                                    Payment Status :{" "}
                                    {v?.status === 0 ? "Outstanding" : "Paid"}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {v?.status === 0 && (
                              <div className="d-flex align-items-center">
                                <button
                                  className="btn-primary rounded-pill font-weight-medium py-1 px-3"
                                  onClick={() => {
                                    reminder({
                                      type:
                                        v.recipient == "club"
                                          ? 4
                                          : v.recipient == "player"
                                          ? 5
                                          : v.recipient == "parent"
                                          ? 6
                                          : 0,
                                      _id: v.request_id,
                                    });
                                  }}
                                >
                                  Remind
                                </button>
                                <div className="dropdown event-drp ml-4">
                                  <span
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="mdi mdi-dots-vertical"></i>{" "}
                                  </span>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink"
                                  >
                                    <div
                                      className="dropdown-item d-flex align-items-center justify-content-center"
                                      onClick={() => {
                                        handleShow();
                                        setAmount(v?.amount);
                                        setrequestId(v?.request_id);
                                        setvalue({
                                          amount: "",
                                        });
                                        seterror({
                                          amount: false,
                                        });
                                        // setID(v?._id);
                                      }}
                                      style={{
                                        background: "#143d4a",
                                        color: "#ffffff",
                                      }}
                                    >
                                      Add payment
                                      {/* <div className="text-center"> */}
                                      {/* <button className="btn-primary font-weight-medium p-2">
                                          </button> */}
                                    </div>
                                    {/* </div> */}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            </div>

            {/* <<<<<<<<<<<<<<<<-----------------------OutStanding End------------------>>>>>>>>>>>>>>>> */}

            {/* <<<<<<<<<<<<<<<<-----------------------Received Start------------------>>>>>>>>>>>>>>>> */}

            <div
              className="tab-pane fade mt-30"
              id="recevied-tab"
              role="tabpanel"
              // aria-labelledby="pills-profile-tab"
            >
              <div className="d-md-flex align-items-center">
                <div className="search-section mt-4 mt-md-0">
                  <i className="mdi mdi-magnify search-ico"></i>
                  <input
                    type="text"
                    className="form-control"
                    name="Recevied_search"
                    value={state.Recevied_search}
                    placeholder="Search"
                    onChange={Searchval}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                {loading ? <Spinner /> : null}
              </div>
              <>
                <div className="yellow-section">
                  {/* <h6 className="text-left yellow-head">OutStanding</h6> */}

                  {filterData.Recevied_filter.filter(
                    (e) => e.type == "received_payment"
                  ).length == 0 ? (
                    <>
                      <div className="container d-flex justify-content-center align-items-center">
                        <span className="noti-type">
                          No received data found
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      {
                        <div
                          className="table-responsive d-none d-md-block"
                          style={{ overflowY: "auto" }}
                        >
                          <table className="table yellow-table mt-24">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Recipient</th>
                                <th>Email</th>
                                <th>Event name</th>
                                <th>Payment code</th>
                                {/* <th>Request Type</th> */}
                                <th>Amount</th>
                                <th>Paid date</th>
                                <th>Payment status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filterData.Recevied_filter.map((v, i) => {
                                return (
                                  <tr key={v._id} className="pay-row">
                                    <td>{i + 1}</td>
                                    <td>
                                      <span className="filter-string">
                                        {v.recipient == "club" &&
                                        v.club_first_name !== ""
                                          ? v.club_first_name
                                          : v.recipient == "player" &&
                                            v.player_first_name !== ""
                                          ? v.player_first_name
                                          : v.recipient == "parent" &&
                                            v.parent_first_name !== ""
                                          ? v.parent_first_name
                                          : "N/A"}
                                      </span>
                                    </td>
                                    <td>{v.email ? v?.email : "N/A"}</td>
                                    <td>
                                      {v.event_name ? v.event_name : "N/A"}
                                    </td>
                                    <td>
                                      {v?.payment_code
                                        ? v?.payment_code
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {v.amount ? " $ " + v.amount : "$ 0"}
                                    </td>
                                    <td>
                                      <Moment
                                        date={new Date(v.createdAt)}
                                        format="MM DD, YYYY"
                                      />
                                    </td>
                                    <td>{"Paid"}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      }
                    </>
                  )}
                  {/* Mobile view For Downpayment */}
                  <div className="mt-30 d-md-none">
                    <div className="mt-4">
                      {filterData.Recevied_filter.map((v, i) => {
                        return (
                          <div
                            className="d-flex justify-content-between reg-details  align-items-start"
                            key={v._id}
                          >
                            <div className="d-flex flex-grow-1">
                              <div className="reg-content">
                                <strong>
                                  {v.recipient == "club" &&
                                  v.club_first_name !== ""
                                    ? v.club_first_name
                                    : v.recipient == "player" &&
                                      v.player_first_name !== ""
                                    ? v.player_first_name
                                    : v.recipient == "parent" &&
                                      v.parent_first_name !== ""
                                    ? v.parent_first_name
                                    : "N/A"}
                                </strong>
                                <div className="mt-2 text-gray">
                                  <div>
                                    Email : {v.email ? v?.email : "N/A"}
                                  </div>
                                  <div>
                                    Event Name :{" "}
                                    {v.event_name ? v.event_name : "N/A"}
                                  </div>
                                  <div>
                                    Payment Code :{" "}
                                    {v?.payment_code ? v?.payment_code : "N/A"}
                                  </div>
                                  <div>
                                    Amount :{" "}
                                    {v.amount ? " $ " + v.amount : "$ 0"}
                                  </div>
                                  <div>
                                    Paid Date :{" "}
                                    <Moment
                                      date={new Date(v.createdAt)}
                                      format="MM DD, YYYY"
                                    />
                                  </div>
                                  <div>Payment Status : {"Paid"}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            </div>

            {/* <<<<<<<<<<<<<<<<-----------------------Received End------------------>>>>>>>>>>>>>>>> */}

            {/* <<<<<<<<<<<<<<<<-----------------------Refund start------------------>>>>>>>>>>>>>>>> */}

            <div
              className="tab-pane fade mt-30"
              id="Refund-tab"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="d-md-flex align-items-center">
                <div className="search-section mt-4 mt-md-0">
                  <i className="mdi mdi-magnify search-ico"></i>
                  <input
                    type="text"
                    className="form-control"
                    name="Refund_search"
                    value={state.Refund_search}
                    placeholder="Search"
                    onChange={Searchval}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="red-section">
                {/* <h6 className="text-left red-head">No Payment</h6> */}
                {filterData.Refund_filter &&
                filterData.Refund_filter.length == 0 ? (
                  <>
                    <div className="container d-flex justify-content-center align-items-center">
                      <span className="noti-type">
                        No refund amount to display
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="table-responsive d-none d-md-block"
                      style={{ overflowY: "auto" }}
                    >
                      <table className="table red-table mt-24">
                        <thead>
                          <tr>
                            <th>Recipient</th>
                            <th>Date</th>
                            <th>Email</th>
                            <th>Refund amount</th>
                            <th>Payment status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterData.Refund_filter.map((v, i) => {
                            return (
                              <tr key={v._id} className="pay-row">
                                <div
                                  className="d-flex align-items-center pointer"
                                  onClick={() => {
                                    setviewDetails(v);
                                    refundmodalShow();
                                  }}
                                >
                                  <td>{v.event ? v.event : "N/A"}</td>
                                </div>
                                <td>
                                  {v?.refund_date ? v?.refund_date : "N/A"}
                                </td>
                                <td>
                                  {v?.receipient ? v?.receipient?.email : "N/A"}
                                </td>
                                <td>
                                  {v?.refund_amount
                                    ? " $ " + v?.refund_amount
                                    : "N/A"}
                                </td>
                                <td>{"Refunded"}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    {/* Mobile view For No payment */}
                    <div className="mt-30 d-md-none">
                      <div className="mt-4">
                        {filterData.Refund_filter.map((v, i) => {
                          return (
                            <div
                              className="d-flex justify-content-between reg-details  align-items-start"
                              key={v._id}
                            >
                              {/* <div className="d-flex flex-grow-1">
                                <div className="reg-content">
                                  <strong>
                                    {v.recipient == "club" &&
                                    v.club_first_name !== ""
                                      ? v.club_first_name
                                      : v.recipient == "player" &&
                                        v.player_first_name !== ""
                                      ? v.player_first_name
                                      : v.recipient == "parent" &&
                                        v.parent_first_name !== ""
                                      ? v.parent_first_name
                                      : "N/A"}
                                  </strong>
                                  <div className="mt-2 text-gray">
                                    <div>
                                      Email : {v.email ? v?.email : "N/A"}
                                    </div>
                                    <div>
                                      Event Name :{" "}
                                      {v.event_name ? v.event_name : "N/A"}
                                    </div>
                                    <div>
                                      Payment Code :{" "}
                                      {v?.payment_code
                                        ? v?.payment_code
                                        : "N/A"}
                                    </div>
                                    <div>
                                      Amount :{" "}
                                      {v.amount ? " $ " + v.amount : "$ 0"}
                                    </div>
                                    <div>
                                      Paid Date :{" "}
                                      <Moment
                                        date={new Date(v.createdAt)}
                                        format="MM DD, YYYY"
                                      />
                                    </div>
                                    <div>
                                      Payment Status :{" "}
                                      {v?.status === 0 ? "Outstanding" : "Paid"}
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* <<<<<<<<<<<<<<<<-----------------------Refund End------------------>>>>>>>>>>>>>>>> */}

            {/*  credit start */}

            <div
              className="tab-pane fade mt-30"
              id="Credit-tab"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="d-md-flex align-items-center">
                <div className="search-section mt-4 mt-md-0">
                  <i className="mdi mdi-magnify search-ico"></i>
                  <input
                    type="text"
                    className="form-control"
                    id="search"
                    name="Credit_search"
                    value={state.Credit_search}
                    placeholder="Search"
                    onChange={Searchval}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="red-section">
                {/* <h6 className="text-left red-head">No Payment</h6> */}
                {filterData.Credit_filter.length == 0 ? (
                  <>
                    <div className="container d-flex justify-content-center align-items-center">
                      <span className="noti-type">No records to display</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="table-responsive"
                      style={{ overflowY: "auto" }}
                    >
                      <table className="table red-table mt-24">
                        <thead>
                          <tr>
                            <th>Recipient</th>
                            <th>Created Date</th>
                            <th>Email</th>
                            <th>Credit</th>
                            <th>Used Credit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterData.Credit_filter.map((v, i) => {
                            return (
                              <tr key={v._id} className="pay-row">
                                <div
                                  className="d-flex align-items-center pointer"
                                  onClick={() => {
                                    // setviewDetails(v);
                                    // refundmodalShow();
                                  }}
                                >
                                  <td>
                                    {v?.receipient
                                      ? v?.receipient?.name
                                      : "N/A"}
                                  </td>
                                </div>
                                <td>{v?.createdAt ? v?.createdAt : "N/A"}</td>
                                <td>
                                  {v?.receipient ? v?.receipient?.email : "N/A"}
                                </td>
                                <td>
                                  {v?.credit_amount ? v?.credit_amount : "N/A"}
                                </td>
                                <td>{v?.use_credit}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/*  credit end */}
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog-scrollable"
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>
            <div className="" style={{ fontWeight: "bold" }}>
              Pending : {Amount}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="row"> */}
          <div className="">
            <Form.Group className="mb-3">
              <Form.Label
                style={{
                  fontWeight: "bold",
                }}
              ></Form.Label>
              <Form.Control
                type="number"
                name="amount"
                label="amount"
                onChange={handleChange}
                value={value?.amount}
                placeholder="Amount"
                id="amount"
                className={classNames({
                  requireField: error.amount,
                })}
              />
            </Form.Group>
          </div>
          {/* </div> */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center">
          <Button
            className="btn btn-primary rounded-pill font-weight-medium auth-form-btn"
            onClick={handleClose}
          >
            Cancle
          </Button>
          <Button
            className="btn btn-primary rounded-pill font-weight-medium auth-form-btn"
            onClick={handleCreate}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Toaster />
      <Modal
        show={refunmodal}
        onHide={() => {
          setrefunmodal(false);
          setviewDetails(null);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div style={{ backgroundColor: "#ffffff" }}>
          <Modal.Header
            className="d-flex justify-content-between"
            style={{ fontWeight: "bold" }}
          >
            {viewDetails?.event}
          </Modal.Header>
          <Modal.Body>
            {viewDetails?.length !== 0 && (
              <>
                <div className="row mx-2">
                  <div className="col-7">
                    <span className="d-block text-gray mt-2">Recipient:</span>{" "}
                    <span className="d-block text-gray mt-2">Email:</span>{" "}
                    <span className="d-block text-gray mt-2">
                      Payment reference number:
                    </span>{" "}
                    <span className="d-block text-gray mt-2">
                      Refund reference number:
                    </span>{" "}
                    <span className="d-block text-gray mt-2">
                      Payment code::
                    </span>{" "}
                    <span className="d-block text-gray mt-2">Date:</span>{" "}
                    <span className="d-block text-gray mt-2">
                      Refund amount:
                    </span>
                    <span className="d-block text-gray mt-2">
                      Payment status:
                    </span>{" "}
                  </div>
                  <div className="col-5">
                    <span className="d-block text-gray mt-2">
                      {viewDetails?.receipient?.name
                        ? viewDetails?.receipient?.name
                        : "N/A"}
                    </span>
                    <span className="d-block text-gray mt-2">
                      {viewDetails?.receipient?.email
                        ? viewDetails?.receipient?.email
                        : "N/A"}
                    </span>
                    <span className="d-block text-gray mt-2">
                      {viewDetails?.payment_reference_number
                        ? viewDetails?.payment_reference_number
                        : "N/A"}
                    </span>
                    <span className="d-block text-gray mt-2">
                      {viewDetails?.payment_reference_number
                        ? viewDetails?.payment_reference_number
                        : "N/A"}
                    </span>
                    <span className="d-block text-gray mt-2">
                      {viewDetails?.payment_code
                        ? viewDetails?.payment_code
                        : "N/A"}
                    </span>
                    <span className="d-block text-gray mt-2">
                      {viewDetails?.refund_date
                        ? viewDetails?.refund_date
                        : "N/A"}
                    </span>
                    <span className="d-block text-gray mt-2">
                      {viewDetails?.refund_amount
                        ? viewDetails?.refund_amount
                        : "N/A"}
                    </span>
                    <span className="d-block text-gray mt-2">{"Refunded"}</span>
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

// export default withRouter(OrgAllPaymentReq)

const mapStateToProps = (state) => ({
  allReport: state.getAllReportReducer,
  refundReducer: state.getRefundReportReducer,
  reminderResponse: state.reminderReducer,
  reqaddpaymentReducer: state.reqaddpaymentReducer,
  paymentRequest: state.paymentRequestReducer,
  creditListReducer: state.getOrgCreditReducer,
});
const mapDispatchToProps = (dispatch) => ({
  // getPaymentRequestHandler: user => dispatch(getPaymentRequest(user)),
  getReceiveReportHandler: (user) => dispatch(getReceiveReport(user)),
  getOutstandingHandler: (user) => dispatch(getOutstandingReport(user)),
  getCreditHandler: (user) => dispatch(getOrgCredit(user)),
  getRefundedHandler: (user) => dispatch(getRefundReport(user)),
  getAllReportHandler: (user) => dispatch(getAllReport(user)),
  receipientReminder: (data) => dispatch(reminder(data)),
  reqaddpaymentAction: (data) => dispatch(reqaddpaymentAction(data)),
  restReminder: () => dispatch({ type: REMINDER_RESET }),
  resetAddpayment: () => dispatch({ type: RESET_REQ_ADD_PAYMENT }),
  resetPaymentRequestHandler: () => dispatch({ type: RESET_PAYMENT_REQUEST }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
