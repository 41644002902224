import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getAllReport } from "../../../service/action/userAction";
import { useState } from "react";
import moment from "moment";
import Spinner from "../../../Spinner/Spinner";

const PaymentHistory = (props) => {
  // .............................   usestate    ...........................
  const [allreport, setallreport] = useState();
  const [loading, setloading] = useState(true);

  //   ............................  useEffect  .............................

  useEffect(() => {
    props.getAllReportHandler({ id: 0 });
  }, []);

  useEffect(() => {
    if (props.allReport?.success) {
      setloading(false);
      const data = props.allReport?.user?.reportArray;
      setallreport(
        data.map((e) => {
          return {
            ...e,
            ...(e.amount != undefined &&
              e.amount != null && { amount: e.amount.toString() }),
            ...(e.refund_amount != undefined &&
              e.refund_amount != null && {
                refund_amount: e.refund_amount.toString(),
              }),
          };
        })
      );
    }
  }, [props.allReport]);

  //   ...........................................................................

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          {allreport?.length !== 0 ? (
            <>
              <div className="table-responsive d-none d-md-block">
                {loading ? (
                  <div className="w-full h-[60vh] flex flex-col justify-center items-center">
                    <Spinner />
                  </div>
                ) : (
                  <table className="table event-table mt-30">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Email</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allreport?.length !== 0 ? (
                        <>
                          {allreport?.map((v, i) => {
                            return (
                              (v?.type === "received_payment" ||
                                v?.type === "refund") && (
                                <tr>
                                  <td>
                                    {v?.event_name
                                      ? v?.event_name
                                      : "N/A" || v?.event
                                      ? v?.event
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {v?.createdAt
                                      ? moment(v?.createdAt).format(
                                          "MM-DD-yyyy"
                                        )
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {v?.type === "received_payment"
                                      ? v?.email
                                        ? v?.email
                                        : "N/A"
                                      : v?.receipient?.email
                                      ? v?.receipient?.email
                                      : "N/A"}
                                  </td>
                                  <td
                                    className={
                                      !!v.amount
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {!!v.amount &&
                                      v.amount !== "" &&
                                      `+ $${v.amount}`}
                                    {!!v.refund_amount &&
                                      v.refund_amount !== "" &&
                                      `- $${v.amount}`}
                                  </td>
                                </tr>
                              )
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center text-gray">
              No payment history found
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  allReport: state.getAllReportReducer,
});
const mapDispatchToProps = (dispatch) => ({
  getAllReportHandler: (user) => dispatch(getAllReport(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
