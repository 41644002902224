import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router";
import InputMask from "react-input-mask";

import Toaster from "../../Toaster/Toaster";
import {
  Club_changeStatus,
  discountCode,
  getClubTeam,
  getOrgEventTeam,
  getPaymentMethod,
  getRegTeamPayAmount,
  geteventDivision,
  payNow,
  sendPaymentInfo,
  teamRegister,
} from "../../service/action/Club/clubAction";
import {
  RESET_ACCEPT_INVITATION,
  RESET_DISCOUNT_CODE_APPLY,
  RESET_PAYMENT_METHOD,
  RESET_PAYNOW,
  RESET_REGISTERED_TEAM,
  RESET_REGISTER_SELECTED_TEAM,
} from "../../service/constants";
import { Modal } from "react-bootstrap";
import BreadcrumbHistory from "../../BreadcumbHistory/BreadcrumbHistory";
import classNames from "classnames";
import moment from "moment";

const PayNow = (props, team) => {
  const { id, id1 } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [_state, set_State] = useState({
    organizer: localStorage.getItem("Id"),
    event_id: id,
    invite_type: 0,
    teams: [],
    useCredit: false,
    discount: "",
  });
  const [error, setError] = useState({
    discount: false,
  });

  const [amountDetails, setAmountDetails] = useState({
    total: 0,
    discount: 0,
    proceesingFees: 0,
    credit: 0,
    totalAmount: 0,
  });

  const initialNewDetails = {
    cardNumber: "",
    expiry: "",
    cvv: "",
    name: "",
    zipCode: "",
  };
  const initialNewDetailsError = {
    cardNumber: false,
    expiry: false,
    cvv: false,
    name: false,
    zipCode: false,
  };

  const [payLoading, setPayLoading] = useState(false);

  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCardCvv, setSelectedCardCvv] = useState("");
  const [selectedCardCvvError, setSelectedCardCvvError] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [isOpenCardDetails, setIsOpenCardDetails] = useState(false);

  const [newCardDetails, setNewCardDetails] = useState(initialNewDetails);
  const [newCardDetailsError, setNewCardDetailsError] = useState(
    initialNewDetailsError
  );

  const [isAutoMandate, setIsAutoMandate] = useState(false);
  const teamlist = useSelector((state) => state.teamArrayReducer.user);
  const availableTeam =
    teamlist && location.state.remainingTeam
      ? teamlist.slice(0, location.state.remainingTeam)
      : teamlist;
  const paymentMethodList = useSelector(
    (state) => state.getPaymentMethodReducer
  );
  const eventDetails = useSelector(
    (state) => state.getClubOrgEventDetailsReducer.data
  ).data;
  const allTeam = useSelector((state) => state.getClubAllTeamReducer.user);

  const [liability, setLiability] = useState(false);
  const [regTeamPayAmount, setRegTeamPayAmount] = useState([]);
  const [regTeamPayDetails, setRegTeamPayDetails] = useState(null);
  const regTeamPayAmountReduce = useSelector(
    (state) => state.getRegTeamPayAmountReducer
  );

  useEffect(() => {
    props.getPaymentMethod();
  }, []);

  useEffect(() => {
    setSelectedCardCvv("");
    setSelectedCardCvvError(false);
  }, [selectedCard]);

  useEffect(() => {
    if (paymentMethodList.success) {
      const data = paymentMethodList.data.data;
      setCardList(data);
      dispatch({ type: RESET_PAYMENT_METHOD });
    }
  }, [paymentMethodList]);

  useEffect(() => {
    if (regTeamPayAmountReduce.success) {
      const data = regTeamPayAmountReduce?.user?.data;
      setRegTeamPayAmount(data);
      setRegTeamPayDetails(regTeamPayAmountReduce?.user);
    }
  }, [regTeamPayAmountReduce]);
  useEffect(() => {
    if (!props.getDivision.success) {
      props.getEventDivisionHandler({ id: id });
    }
  }, [props.getDivision]);

  useEffect(() => {
    if (!!eventDetails) {
      if (eventDetails.is_paid_event == "1") {
        dispatch(
          getRegTeamPayAmount({
            event_id: id,
            organizer: eventDetails?.user_id?._id,
            invite_type: location.state.inviteType == "1" ? 1 : 0,
            teams: location.state.teams.map((e) => {
              return {
                team: e.team,
                register_status: 1,
              };
            }),
          })
        );
      }
    }
  }, [eventDetails]);

  useEffect(() => {
    if (!props.getAllTeam.success) {
      props.getClubTeamHandler({ id: id });
    }
  }, [props.getAllTeam]);

  useEffect(() => {
    if (location.state) {
      setLiability(location.state.accept);
      if (!!location.state._state) {
        set_State(location.state._state);
      }
      if (!!location.state.amountDetails) {
        setAmountDetails(location.state.amountDetails);
      }
      if (!!location.state.selectedCard) {
        setSelectedCard(location.state.selectedCard);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (!!location.state) {
      if (!!location.state.teams) {
        const teams = location.state.teams;
        set_State((pre) => ({
          ...pre,
          teams: teams,
        }));
      } else {
        history.push(`/events/${id}/registernow` + (id1 ? `/${id1}` : ""));
      }
    }
  }, [location.state]);

  //onRefresh Page redirect to back
  useEffect(() => {
    if (availableTeam === null) {
      history.push(`/events/${id}/registernow` + (id1 ? `/${id1}` : ""));
      // props.resetregisteredSelectedTeamReducer()
    }
  }, []);

  //for discount code
  const payNow = (e) => {
    e.preventDefault();
    if (!!liability) {
      if (eventDetails?.is_paid_event == "1") {
        if (!!selectedCard) {
          paymentWithSelectedCard();
        } else {
          setIsOpenCardDetails(true);
        }
      } else if (eventDetails?.is_paid_event == "0") {
        props.teamRegister({
          event_id: id,
          organizer: eventDetails.user_id._id,
          teams: _state.teams,
          invite_type: location.state.inviteType == "1" ? 1 : 0,
        });
      }
    } else {
      history.push(`/liability-waiver-terms/${id}`, {
        ...location.state,
        from: location.pathname,
        _state: _state,
        amountDetails: amountDetails,
        selectedCard: selectedCard,
      });
    }
  };

  useEffect(() => {
    if (props.discountReducer.success) {
      const data = props.discountReducer.user;
      if (!data.status) {
        setError((pre) => ({
          ...pre,
          discount: data?.message,
        }));
        dispatch({ type: RESET_DISCOUNT_CODE_APPLY });
      }
    }
  }, [props.discountReducer]);
  useEffect(() => {
    if (props.teamRegisterReducer.success) {
      history.push(`/events/${id}`);
      dispatch({ type: RESET_REGISTERED_TEAM });
    }
  }, [props.teamRegisterReducer]);

  const handleCheckCode = () => {
    if (_state.discount !== "") {
      dispatch(
        discountCode({
          discount_code: _state.discount,
          organizer: eventDetails?.user_id?._id,
          amount: regTeamPayAmount
            .map((a) => a.amount)
            .reduce((a, b) => parseFloat(a) + parseFloat(b)),
          event: id,
        })
      );
    } else {
      toast.error("Enter discount code.");
    }
  };

  useEffect(() => {
    const total =
      location.state.paymentType == "b"
        ? regTeamPayDetails?.initial_total_amount
        : regTeamPayDetails?.total_amount;

    setAmountDetails((pre) => ({
      ...pre,
      totalAmount: total,
      proceesingFees: ((total * 3.9) / 100).toFixed(2),
      credit: parseFloat(regTeamPayAmount[0]?.getCredit).toFixed(2),
      total: (
        parseFloat(total) +
        parseFloat((total * 3.9) / 100) -
        parseFloat(_state.useCredit ? regTeamPayAmount[0]?.getCredit : 0)
      ).toFixed(2),
    }));
  }, [regTeamPayAmount]);

  useEffect(() => {
    setAmountDetails((pre) => ({
      ...pre,
      credit: _state.useCredit
        ? parseFloat(regTeamPayAmount[0]?.getCredit).toFixed(2)
        : 0,
      total: (
        parseFloat(pre.totalAmount) +
        parseFloat((parseFloat(pre.totalAmount) * 3.9) / 100) -
        parseFloat(_state.useCredit ? regTeamPayAmount[0]?.getCredit : 0)
      ).toFixed(2),
    }));
  }, [_state.useCredit]);

  const handleNewCard = (e) => {
    const { name, value } = e.target;
    if (name == "cardNumber") {
      setNewCardDetails((pre) => ({
        ...pre,
        [name]: value.replace(/\s/g, ""),
      }));
    } else if (name == "expiry") {
      setNewCardDetails((pre) => ({
        ...pre,
        [name]: value.replace("/", ""),
      }));
    } else {
      setNewCardDetails((pre) => ({
        ...pre,
        [name]: value,
      }));
    }
    switch (name) {
      case "name":
        if (value == "") {
          newCardDetailsError["name"] = "Required";
        } else {
          newCardDetailsError["name"] = false;
        }
        break;
      case "cardNumber":
        if (value == "") {
          newCardDetailsError["cardNumber"] = "Required";
        } else if (value.length != 19) {
          newCardDetailsError["cardNumber"] = "Required";
        } else {
          newCardDetailsError["cardNumber"] = false;
        }
        break;
      case "expiry":
        if (value == "") {
          newCardDetailsError["expiry"] = "Required";
        } else if (value.length != 5) {
          newCardDetailsError["expiry"] = "Required";
        } else {
          newCardDetailsError["expiry"] = false;
        }
        break;
      case "cvv":
        if (value == "") {
          newCardDetailsError["cvv"] = "Required";
        } else if (value.length != 3) {
          newCardDetailsError["cvv"] = "Required";
        } else {
          newCardDetailsError["cvv"] = false;
        }
        break;
      case "zipCode":
        if (value == "") {
          newCardDetailsError["zipCode"] = "Required";
        } else if (value.length != 5) {
          newCardDetailsError["zipCode"] = "Required";
        } else {
          newCardDetailsError["zipCode"] = false;
        }
        break;

      default:
        break;
    }
  };

  const handlePayWithNewCard = () => {
    if (newCardDetails.name == "") {
      newCardDetailsError["name"] = "required";
    }
    if (newCardDetails.cardNumber == "") {
      newCardDetailsError["cardNumber"] = "required";
    }
    if (newCardDetails.cvv == "") {
      newCardDetailsError["cvv"] = "required";
    }
    if (newCardDetails.expiry == "") {
      newCardDetailsError["expiry"] = "required";
    }
    if (newCardDetails.zipCode == "") {
      newCardDetailsError["zipCode"] = "required";
    }
    if (
      newCardDetails.name !== "" &&
      newCardDetails.cardNumber !== "" &&
      newCardDetails.cvv != "" &&
      newCardDetails.zipCode !== "" &&
      newCardDetails.expiry !== "" &&
      !newCardDetailsError.cardNumber &&
      !newCardDetailsError.cvv &&
      !newCardDetailsError.expiry &&
      !newCardDetailsError.zipCode
    ) {
      props.paynowHandler({
        club: localStorage.getItem("Id"),
        event: id,
        payment_request: regTeamPayAmount[0]._id,
        organizer: eventDetails?.user_id?._id,
        amount: regTeamPayAmount
          .map((a) => a.amount)
          .reduce((a, b) => parseFloat(a) + parseFloat(b)),
        panding_amount: regTeamPayAmount
          .map((a) => a.pending_amount)
          .reduce((a, b) => parseFloat(a) + parseFloat(b)),
        credit_note: _state.useCredit,
        card_type: 0,
        is_card_payment: 1,
        transaction_type: "sale",
        card_number: newCardDetails.cardNumber,
        card_key: "",
        expiration_date: newCardDetails.expiry,
        name_on_code: newCardDetails.name,
        zip_code: newCardDetails.zipCode,
        card_verification_number: newCardDetails.cvv,
        invite_type: location.state.inviteType == "1" ? 1 : 0,
        team: _state.teams,
      });
      setPayLoading(true);
    }
  };

  const paymentWithSelectedCard = () => {
    if (selectedCardCvv.length == 3 && !selectedCardCvvError) {
      props.paynowHandler({
        club: localStorage.getItem("Id"),
        event: id,
        payment_request: regTeamPayAmount[0]._id,
        organizer: eventDetails?.user_id?._id,
        amount: regTeamPayAmount
          .map((a) => a.amount)
          .reduce((a, b) => parseFloat(a) + parseFloat(b)),
        panding_amount: regTeamPayAmount
          .map((a) => a.pending_amount)
          .reduce((a, b) => parseFloat(a) + parseFloat(b)),
        credit_note: _state.useCredit,
        card_type: 1,
        is_card_payment: 0,
        transaction_type: "sale",
        card_number: selectedCard?.card_number,
        card_key: selectedCard?.card_key,
        expiration_date: selectedCard?.expiration_date,
        name_on_code: selectedCard?.card_name,
        zip_code: selectedCard?.zip_code,
        card_verification_number: selectedCardCvv,
        invite_type: location.state.inviteType == "1" ? 1 : 0,
        team: _state.teams,
      });
      setPayLoading(true);
    } else {
      setSelectedCardCvvError(true);
      toast.error("Enter 3 digit CVV code");
    }
  };
  useEffect(() => {
    if (props.paynowCheck.success) {
      props.teamRegister({
        event_id: id,
        organizer: eventDetails.user_id._id,
        teams: _state.teams,
        invite_type: location.state.inviteType == "1" ? 1 : 0,
      });
      setPayLoading(false);
      setSelectedCardCvv("");
      setSelectedCardCvvError(false);
      setSelectedCard(null);
      dispatch({ type: RESET_PAYNOW });
    } else if (props.paynowCheck.error) {
      if (props.paynowCheck.errors?.status == 500) {
        const msg = props.paynowCheck.errors?.data?.message;
        toast.error(msg);
        dispatch({ type: RESET_PAYNOW });
        setPayLoading(false);
      } else {
        dispatch({ type: RESET_PAYNOW });
        setPayLoading(false);
      }
    }
  }, [props.paynowCheck]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />
        <div className="row mx-0">
          <div className="col-12 col-lg-7">
            <div className="content-wrapper">
              {!!eventDetails && (
                <>
                  <div className="text-center font-semibold text-2xl text-[#189AD8]">
                    {eventDetails?.event_name}
                  </div>
                  <div className="border rounded-lg p-3 mt-3">
                    {!!teamlist && (
                      <>
                        {_state.teams?.map((e, i) => {
                          const teamName = allTeam.find(
                            (team) => team._id === e?.team
                          )?.team_name;
                          if (eventDetails?.is_paid_event == "1") {
                            const amount =
                              location.state.paymentType == "b"
                                ? regTeamPayAmount.find((team) => {
                                    return team.team._id == e.team;
                                  })?.dueAmount
                                : regTeamPayAmount.find((team) => {
                                    return team.team._id == e.team;
                                  })?.amount;
                            return (
                              <div className="row mx-0 pb-1" key={i}>
                                <div className="col-8 pr-0 font-semibold">
                                  {`${teamName}`}
                                </div>
                                <div className="col-4 pr-0 text-end	font-semibold text-slate-400">
                                  {`$ ${amount}`}
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="row mx-0 pb-1" key={i}>
                                <div className="col-8 pr-0 font-semibold">
                                  {`${teamName}`}
                                </div>
                                <div className="col-4 pr-0 text-end	font-semibold text-slate-400">
                                  {`Free`}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </>
                    )}
                  </div>

                  <div className="border rounded-lg p-3 mt-3">
                    {!!teamlist && (
                      <>
                        <div className="row mx-0 pb-1">
                          <div className="col-8 pr-0 font-semibold">
                            Total Amount
                          </div>
                          <div className="col-4 pr-0 text-end	font-semibold text-slate-400">
                            {`$ ${amountDetails.totalAmount}`}
                          </div>
                        </div>
                        <div className="row mx-0 pb-1">
                          <div className="col-8 pr-0 font-semibold">
                            Discount
                          </div>
                          <div className="col-4 pr-0 text-end	font-semibold text-slate-400">
                            {`$ ${amountDetails.discount}`}
                          </div>
                        </div>
                        <div className="row mx-0 pb-1">
                          <div className="col-8 pr-0 font-semibold">
                            Processing fees (3.75%)
                          </div>
                          <div className="col-4 pr-0 text-end	font-semibold text-slate-400">
                            {`$ ${amountDetails.proceesingFees}`}
                          </div>
                        </div>
                        <div className="row mx-0 pb-1">
                          <div className="col-8 pr-0 font-semibold">
                            Amount to Pay
                          </div>
                          <div className="col-4 pr-0 text-end	font-semibold text-slate-400">
                            {`$ ${amountDetails.total}`}
                          </div>
                        </div>
                        {location.state.paymentType == "b" && (
                          <React.Fragment>
                            <div className="row mx-0 pb-1">
                              <div className="col-8 pr-0 font-semibold">
                                Remaining Amount
                              </div>
                              <div className="col-4 pr-0 text-end	font-semibold text-slate-400">
                                {`$ ${
                                  !!regTeamPayDetails
                                    ? regTeamPayDetails?.remaining_total_amount
                                    : ""
                                }`}
                              </div>
                            </div>
                            <div className="row mx-0 pb-1">
                              <div className="col-8 pr-0 font-semibold">
                                Auto-Debit Date
                              </div>
                              <div className="col-4 pr-0 text-end	font-semibold text-slate-400">
                                {`${
                                  !!regTeamPayDetails
                                    ? moment(
                                        regTeamPayDetails?.auto_debit_date
                                      ).format("MM/DD/yyyy")
                                    : ""
                                }`}
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </>
                    )}
                  </div>
                  {eventDetails?.is_paid_event == "1" && (
                    <>
                      <div className="row mx-0 align-items-center mt-3 ">
                        <div className="col-8 pr-0 font-semibold">
                          <input
                            type="checkbox"
                            id="credit"
                            name="credit"
                            checked={_state.useCredit}
                            className="mr-2"
                            onChange={(e) => {
                              set_State((pre) => ({
                                ...pre,
                                useCredit: e.target.checked,
                              }));
                            }}
                          />
                          <span className="col-8 pr-0 font-bold fs-14">
                            Use Available Credit ?
                          </span>
                        </div>
                        <div className="col-4 pr-0 text-end	font-semibold text-slate-400">
                          {`$ ${regTeamPayAmount[0]?.getCredit}`}
                        </div>
                      </div>
                      <div className="mt-3">
                        <div className=" d-flex">
                          <input
                            type="text"
                            className={classNames(
                              "form-control mr-2 p-2 h-auto	border rounded",
                              {
                                requireField: error.discount,
                              }
                            )}
                            placeholder="Have a Discount Code?"
                            name="discount"
                            value={_state.discount}
                            onChange={(e) => {
                              setError((pre) => ({
                                ...pre,
                                discount: false,
                              }));
                              set_State((pre) => ({
                                ...pre,
                                discount: e.target.value,
                              }));
                            }}
                          />

                          <div
                            className="bg-sky-500 text-white d-flex align-items-center px-4 fs-14 font-semibold pointer border rounded"
                            onClick={handleCheckCode}
                          >
                            Apply
                          </div>
                        </div>
                        {!!error.discount && (
                          <div className="fs-13 text-red">{error.discount}</div>
                        )}
                      </div>
                      <div className="d-flex align-items-center mt-3 mx-0 ">
                        <input
                          type="checkbox"
                          id="Liability"
                          name="Liability"
                          value={liability}
                          checked={liability}
                          className="mr-2"
                          onClick={(e) => {
                            history.push(`/liability-waiver-terms/${id}`, {
                              ...location.state,
                              from: location.pathname,
                              _state: _state,
                              amountDetails: amountDetails,
                              selectedCard: selectedCard,
                            });
                            setLiability(e.target.checked);
                          }}
                          disabled={liability}
                        />
                        <span className="pr-0 font-bold fs-14">
                          <strong>Accept Liability waiver</strong>
                        </span>
                      </div>

                      <div className="flex justify-between items-center mx-auto mt-4">
                        <h2 className="text-xl font-bold ">Payment Method</h2>
                        <a
                          className="text-sky-500 font-bold text-lg hover:cursor-pointer"
                          onClick={() => {
                            history.push(
                              "/payment/payment-method/add-payment-method"
                            );
                          }}
                        >
                          Add More
                        </a>
                      </div>

                      <div className="bg-white mt-3 py-2 h-80 overflow-auto mb-2 pr-2">
                        {cardList.map((e, i) => {
                          return (
                            <div className="">
                              <div
                                key={i}
                                className="border p-2 my-2 rounded-md"
                              >
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div
                                      className="mr-2"
                                      style={{
                                        width: "35px",
                                      }}
                                    >
                                      <img
                                        src={`images/PaymentCards/${
                                          e.card_type == "MASTERCARD"
                                            ? "Mastercardnew.png"
                                            : e.card_type.trim() == "VISA"
                                            ? "visacard.png"
                                            : "visacard.png"
                                        }`}
                                        className="w-100"
                                      />
                                    </div>
                                    <div>
                                      <div className="fs-13">
                                        <strong>{e.card_name}</strong>
                                      </div>
                                      <div className="fs-13">
                                        <strong>{e.card_number}</strong>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mx-0 align-items-center flex-nowrap">
                                    {selectedCard?._id == e._id && (
                                      <div className="mr-4 w-20">
                                        <InputMask
                                          mask="999"
                                          className={classNames(
                                            "form-control p-1 ",
                                            {
                                              requireField:
                                                selectedCardCvvError,
                                            }
                                          )}
                                          value={selectedCardCvv}
                                          onChange={(value) => {
                                            setSelectedCardCvv(
                                              value.target.value
                                            );
                                            if (
                                              value.target.value.length == 3
                                            ) {
                                              setSelectedCardCvvError(false);
                                            } else {
                                              setSelectedCardCvvError(true);
                                            }
                                          }}
                                          maskChar={""}
                                          placeholder="123"
                                          name="cvv"
                                        />
                                      </div>
                                    )}
                                    <div>
                                      <input
                                        className="mr-3"
                                        type="checkbox"
                                        name="checkbox"
                                        onChange={(v) => {
                                          if (v.target.checked) {
                                            setSelectedCard({ ...e });
                                          } else {
                                            setSelectedCard(null);
                                          }
                                        }}
                                        checked={
                                          !!selectedCard &&
                                          selectedCard?._id == e._id
                                        }
                                        value={e._id}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex justify-center items-center mt-3">
                        <div
                          className="bg-sky-500 pointer text-white font-semibold border rounded w-full sm:w-auto py-2 px-3 text-center"
                          onClick={(e) => {
                            if (
                              location.state.paymentType == "b" &&
                              !isAutoMandate
                            ) {
                              toast.error(
                                "Please Accept Auto Mandate Amount Condition"
                              );
                            } else {
                              payNow(e);
                            }
                          }}
                        >
                          {eventDetails?.is_paid_event == "1"
                            ? !!selectedCard
                              ? "Pay Now"
                              : "Pay with New Card"
                            : "Register"}
                        </div>
                      </div>

                      {location.state.paymentType == "b" && (
                        <div className="flex justify-center space-x-2 mt-3 mb-2">
                          <div>
                            <input
                              type="checkbox"
                              checked={isAutoMandate}
                              onChange={(e) => {
                                setIsAutoMandate(e.target.checked);
                              }}
                              disabled={isAutoMandate}
                            />
                          </div>
                          <p className="text-slate-400 font-semibold">
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Suscipit corporis illo enim molestiae possimus
                            facere laudantium obcaecati at rerum velit?
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={isOpenCardDetails}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <div className="p-3" style={{ background: "#ffffff" }}>
          <div
            style={{
              maxHeight: "450px",
              overflowY: "auto",
            }}
          >
            <Modal.Header className="font-bold">Payment Details</Modal.Header>
            <Modal.Body>
              <div className="row mx-0">
                <div className="col-12">
                  <label className="font-bold fs-14">Card Number*</label>
                  <InputMask
                    mask="9999 9999 9999 9999"
                    className={classNames("form-control team-name", {
                      requireField: newCardDetailsError.cardNumber,
                    })}
                    value={newCardDetails.cardNumber}
                    onChange={handleNewCard}
                    maskChar={""}
                    placeholder="XXXX XXXX XXXX XXXX"
                    name="cardNumber"
                  />
                </div>
              </div>
              <div className="row mx-0 mt-3">
                <div className="col-6">
                  <label className="font-bold fs-14">Valid Through*</label>

                  <InputMask
                    mask="99/99"
                    className={classNames("form-control team-name", {
                      requireField: newCardDetailsError.expiry,
                    })}
                    value={newCardDetails.expiry}
                    onChange={handleNewCard}
                    maskChar={""}
                    placeholder="MM/YY"
                    name="expiry"
                  />
                </div>
                <div className="col-6 ">
                  <label className="font-bold fs-14">CVV*</label>
                  <InputMask
                    mask="999"
                    className={classNames("form-control team-name", {
                      requireField: newCardDetailsError.cvv,
                    })}
                    value={newCardDetails.cvv}
                    onChange={handleNewCard}
                    maskChar={""}
                    placeholder="123"
                    name="cvv"
                  />
                </div>
              </div>
              <div className="row mx-0 mt-3">
                <div className="col-12">
                  <label className="font-bold fs-14">Name On Card*</label>
                  <input
                    type="text"
                    name="name"
                    value={newCardDetails.name}
                    className={classNames("form-control team-name", {
                      requireField: newCardDetailsError.name,
                    })}
                    onChange={handleNewCard}
                    placeholder="Enter Name"
                  />
                </div>
              </div>
              <div className="row mx-0 mt-3">
                <div className="col-12">
                  <label className="font-bold fs-14">Zip Code*</label>
                  <InputMask
                    mask="99999"
                    className={classNames("form-control team-name", {
                      requireField: newCardDetailsError.zipCode,
                    })}
                    value={newCardDetails.zipCode}
                    onChange={handleNewCard}
                    maskChar={""}
                    placeholder="Zipcode"
                    name="zipCode"
                  />
                </div>
              </div>
            </Modal.Body>
          </div>
          <Modal.Footer className="mt-2 justify-content-center border-top-0">
            <button
              onClick={() => {
                setIsOpenCardDetails(false);
                setNewCardDetails(initialNewDetails);
                setNewCardDetailsError(initialNewDetailsError);
              }}
              className="text-info border-2 border-blue-400 fs-15 w-[45%] py-2 px-4 px-md-5 rounded"
            >
              Cancel
            </button>
            <button
              onClick={payLoading ? () => {} : handlePayWithNewCard}
              className="text-white bg-info border-0 fs-15 w-[45%] py-2 px-4 px-md-5 rounded"
            >
              Pay
            </button>
          </Modal.Footer>
        </div>
      </Modal>

      <Toaster />
    </React.Fragment>
  );
};

// export default TeamsCoches;

const mapStateToProps = (state) => ({
  paynowCheck: state.paynowReducer,
  accept: state.acceptInvitationReducer,
  discountReducer: state.applydiscountcodeReducer,
  PaymentMethod: state.paymentMethodReducer,
  getDivision: state.geteventDivisionReducer,
  getAllTeam: state.getClubAllTeamReducer,
  teamRegisterReducer: state.registerTeamReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getClubTeamHandler: (user) => dispatch(getClubTeam(user)),
  getEventTeamHandler: (user) => dispatch(getOrgEventTeam(user)),
  discountCodehandler: (user) => dispatch(discountCode(user)),
  resetregisteredSelectedTeamReducer: () =>
    dispatch({ type: RESET_REGISTER_SELECTED_TEAM }),
  paynowHandler: (user) => dispatch(payNow(user)),
  getPaymentMethod: (user) => dispatch(getPaymentMethod()),
  changestatusHandler: (user) => dispatch(Club_changeStatus(user)),
  getEventDivisionHandler: (user) => dispatch(geteventDivision(user)),
  resetAcceptInvitationReducer: (uset) =>
    dispatch({ type: RESET_ACCEPT_INVITATION }),
  sendPaymentData: (user) => dispatch(sendPaymentInfo(user)),
  teamRegister: (user) => dispatch(teamRegister(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayNow);
