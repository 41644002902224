import { useEffect, useState } from "react";
import { Button, Col, Modal, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { getTransactionDetails } from "../Services/Actions/transactionAction";
import moment from "moment";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";

const DATA_ITEM_KEY = "_id";

function Transaction(props) {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    props.getTransactionDetails();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (props.transactionDetailsReducer.success) {
      if (searchVal !== "") {
        const data = props.transactionDetailsReducer.data.data;
        const filter = data.filter(
          (e) =>
            e?.payer?.name?.toLowerCase().includes(searchVal.toLowerCase()) ||
            e?.receiver?.name
              ?.toLowerCase()
              .includes(searchVal.toLowerCase()) ||
            e?.receiverType?.toLowerCase().includes(searchVal.toLowerCase()) ||
            e?.payerType?.toLowerCase().includes(searchVal.toLowerCase())
        );
        const _data = filter.map((e) => {
          return {
            ...e,
            payer: e?.payer.name,
            date: moment(e.createdAt).format("DD MMM, yyyy"),
            receiver: e?.receiver?.name,
          };
        });
        setState(_data);
      } else {
        const data = props.transactionDetailsReducer.data.data;
        const _data = data.map((e) => {
          return {
            ...e,
            payer: e?.payer.name,
            date: moment(e.createdAt).format("DD MMM, yyyy"),
            receiver: e?.receiver?.name,
          };
        });
        setState(_data);
      }
    }
  }, [searchVal]);

  useEffect(() => {
    if (props.transactionDetailsReducer.success) {
      const data = props.transactionDetailsReducer.data.data;
      const _data = data.map((e) => {
        return {
          ...e,
          payer: e?.payer.name,
          date: moment(e.createdAt).format("DD MMM, yyyy"),
          receiver: e?.receiver?.name,
        };
      });
      setState(_data);
      setLoading(false);
    }
  }, [props.transactionDetailsReducer]);

  const customHeader = (cell) => {
    return <b>{cell.title}</b>;
  };

  const grid = (
    <Grid data={state} dataItemKey={DATA_ITEM_KEY}>
      <GridNoRecords>There are no transactions available</GridNoRecords>
      <GridColumn
        field="payer"
        title="Payer"
        //   width="160"
        headerCell={customHeader}
      />
      <GridColumn
        field="payerType"
        title="Payer Type"
        //   width="160"
        headerCell={customHeader}
      />
      <GridColumn
        field="receiver"
        title="Receiver"
        //   width="160"
        headerCell={customHeader}
      />
      <GridColumn
        field="receiverType"
        title="Reciver Type"
        //   width="160"
        headerCell={customHeader}
      />
      <GridColumn
        field="date"
        title="Date"
        //   width="150"
        headerCell={customHeader}
      />
      <GridColumn
        field="amount"
        title="Amount"
        //   width="150"
        headerCell={customHeader}
      />
    </Grid>
  );

  return (
    <>
      {loading ? (
        <>Loading....</>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <h2 className="page-heading">Transaction</h2>
            {/* <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} /> */}
          </div>
          <Col md="4" className="search">
            <i className="ri-search-line"></i>
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchVal}
              onChange={(e) => {
                setSearchVal(e.target.value);
              }}
            />
          </Col>

          <div className="table-card">{grid}</div>
        </>
      )}
    </>
  );
}

const mapStateToProp = (state) => ({
  transactionDetailsReducer:
    state.superAdminRootReducer.TransactionReducer.transactionDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getTransactionDetails: () => dispatch(getTransactionDetails()),
});

export default connect(mapStateToProp, mapDispatchToProps)(Transaction);
