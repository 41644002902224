import { CREATE_ADMIN_GROUP_ERROR, CREATE_ADMIN_GROUP_LOADING, CREATE_ADMIN_GROUP_SUCCESS, RESET_CREATE_ADMIN_GROUP } from "../constants";

let initialState = {
 loading: false,
 data: [],
 error: false,
 errors: [],
 success: false,
 payload: {},
};

export default function CreateAdminGroup(state = initialState, action) {
 switch (action.type) {
   case CREATE_ADMIN_GROUP_LOADING:
     return { ...state, error: false, loading: true, success: false };

   case CREATE_ADMIN_GROUP_SUCCESS:
     return {
       ...state,
       error: false,
       loading: false,
       success: true,
       message: action.msg,
       data: action.data,
     };

   case CREATE_ADMIN_GROUP_ERROR:
     return {
       ...state,
       error: true,
       loading: false,
       success: false,
       errors: action.errors,
       msg: action.errors,
     };

   case RESET_CREATE_ADMIN_GROUP:
     return {
       ...state,
       error: false,
       loading: false,
       success: false,
       errors: [],
       data: [{}],
     };
   default:
     return state;
 }
}
