import React, { useState } from "react";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useParams, withRouter } from "react-router-dom";
import { getSignedWaiver } from "../../../service/action/userAction";
import $ from "jquery";
import { Spinner } from "react-bootstrap";
import Moment from "react-moment";
import moment from "moment";

const SignedWaiver = (props) => {
  const { id } = useParams();
  let [loading, setLoading] = useState(true);
  const [SearchError, setSearchError] = useState("");

  const AllSignedWaiver = useSelector(
    (state) => state.getSignedWaiverReducer.user.data
  );
  useEffect(() => {
    props.getSignedWaiverHandler();
  }, []);
  useEffect(() => {
    if (props.getSignedWaiverReducer?.success) {
      props.getSignedWaiverHandler({ id: id });
    }
  }, [props.success]);

//   useEffect(() => {
//     if (props.getSignedWaiverReducer.success) {
//       setLoading(false);
//     }
//   }, [props.success]);


  const [state, setState] = useState({
    search: "",
  });

  const Searchval = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    UpdateArray(e.target.value);
  };

  const UpdateArray = (search) => {
    var query = search;

    $(".filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".pay-row").addClass("d-none");
      } else {
        $(v).parents(".pay-row").removeClass("d-none");
      }
    });
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        {loading && AllSignedWaiver !== null ? (
          <>
            <div className="d-md-flex align-items-center d-none">
              <div className="search-section">
                <i className="mdi mdi-magnify search-ico"></i>
                <input
                  type="text"
                  className="form-control"
                  id="search"
                  name="search"
                  value={state.search}
                  placeholder="Search"
                  onChange={Searchval}
                  autoComplete="off"
                />
              </div>
            </div>
            <div>
              {AllSignedWaiver && AllSignedWaiver.length !== 0 ? (
                <>
                  <div>
                    {AllSignedWaiver?.map((v, i) => {

                      return (
                        <div className="row pay-row">
                          <span>{SearchError}</span>
                          <div className="col-6 mt-2 mb-1">
                            <a
                              href={v?.url}
                              className="d-flex justify-content space-between text-decoration-none"
                            >
                              <div style={{ marginLeft: "20px" }}>
                                {
                                  <div className="dashboard-img">
                                    <img
                                      src={
                                        v?.parent?.profile_image !== ""
                                          ? v?.parent?.profile_image
                                          : "./images/pch-images/pch.svg"
                                      }
                                    />
                                  </div>
                                }
                              </div>
                              <div
                                style={{ marginLeft: "40px" }}
                                className="text-dark"
                              >
                                <p className="fw-bold filter-string" style={{fontWeight:"bold"}}>
                                  {v?.parent?.name}
                                </p>
                                <p>Event: {" " + v?.event?.event_name}</p>
                                <p>Liability:{" " + v?.liability?.title}</p>
                                <p>
                                  Date:
                                  <Moment
                                    date={
                                      new Date(
                                        " " + v.updatedAt ? v.updatedAt : "-"
                                      )
                                    }
                                    format="MM DD, YYYY"
                                  />
                                </p>
                                <p>
                                  Time:
                                  {" " + moment(v?.createdAt).format("hh:mm")}
                                </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-center">
                  <Spinner />
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        )}
        <div>{}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  getSignedWaiverReducer: state.getSignedWaiverReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getSignedWaiverHandler: (user) => dispatch(getSignedWaiver(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignedWaiver));
