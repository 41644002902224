import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import $ from 'jquery'
import classNames from "classnames";
import { connect, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useParams } from 'react-router';



const Request_Pay_Option = (props,value) => {

    const collectionData = useSelector(state => state.sendPaymentInfoReducer.user)

   
    const { id } = useParams();
    const history = useHistory()


    const [val,setVal]=useState(null)

    useEffect(()=>{
        setVal(value.send)
    },[val])




    const teamlist = useSelector(state => state.teamArrayReducer.user)
    const allTeam = useSelector(state => state.getEventTeamReducer.user.data)



    const [selectedTeam, setSelectedTeam] = useState([])

    //for filter team array from selected team id
    useEffect(() => {
        if (teamlist) {
            var result = allTeam.filter(function (e) {
                return teamlist.includes(e._id)
            });
            setSelectedTeam(result)
        }
    }, [teamlist])



    const [state, setState] = useState({
        is_credit_payment:""
    })

    //Calidation for discount code
    const [errors, setErrors] = useState({
        discountCode: false
    })



    const notify = (msg) => toast(msg);

    //Call Discount code api on Page load
    useEffect(() => {

        if (state.payment_id) {

            // props.discountCodehandler({
            //     discount_code: discountCode === null ? "" : discountCode,
            //     payment_code: state.payment_id,
            //     organizer: state.organizer_id,
            //     team: state.team
            // })
        }

    }, [state.payment_id])



    //onRefresh Page redirect to back
    useEffect(() => {
        if (collectionData === null) {
            history.push(`/requestpay/${id}`)
        }
    }, [])




    return (


        <React.Fragment>
            <div className="main-panel">
                {/* <div className="content-wrapper"> */}
                    <div className="container-fluid page-body-wrapper  full-page-wrapper">
                        <div className="content-wrapper d-flex  p-4 mb-4 bg-white my-4">
                            <div className="row flex-grow">
                                <div className="col-lg-4 mx-auto mt-24">
                                    <div className=" text-left p-5 shadow">
                                        <h6 className=" text-center dash-head">Select Payment Method</h6>
                                        <form className="pt-3">
                                            <div className="form-group">

                                            </div>



                                            <div className="mt-3 text-center">
                                                <Link to={`/request_pay_carddetails/${id}/1`} className='text-decoration-none'>
                                                    <button className='mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn card-btn'    >
                                                        Credit / Debit Card
                                                    </button>
                                                </Link>
                                            </div>
                                            {/* <div className="mt-3 text-center">
                                                <Link to={`/carddetails`} className='text-decoration-none'>
                                                    <button className='mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn card-btn'  >
                                                        Google Pay
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="mt-3 text-center">
                                                <Link to={`/carddetails`} className='text-decoration-none'>
                                                    <button className='mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn card-btn'  >
                                                        Apple Pay
                                                    </button>
                                                </Link>
                                            </div> */}

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="auth-form-light text-left p-5">
                    </div>
                {/* </div> */}
            </div>
            
        </React.Fragment>
    )
}

// export default TeamsCoches;

const mapStateToProps = (state) => ({
    // paynowCheck: state.paynowReducer,
    // accept: state.acceptInvitationReducer
})

const mapDispatchToProps = dispatch => ({
 
    // discountCodehandler: user => dispatch(discountCode(user)),
    // resetregisteredSelectedTeamReducer: () => dispatch({ type: RESET_REGISTER_SELECTED_TEAM }),
    // paynowHandler: user => dispatch(payNow(user)),
    // changestatusHandler: user => dispatch(Club_changeStatus(user)),
    // resetAcceptInvitationReducer: uset => dispatch({ type: RESET_ACCEPT_INVITATION }),

})

export default connect(mapStateToProps, mapDispatchToProps)((Request_Pay_Option))