import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import classNames from "classnames";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

const ParentAccountForm = ({ state, eventhandler, errors, handleSubmit }) => {
  return (
    <>
      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {
            error: errors.first_name,
          })}
          placeholder="First Name*"
          id="first_name"
          name="first_name"
          value={state.first_name}
          onChange={(e) => eventhandler(e)}
        />
        {errors.first_name && (
          <span className="text-red small d-block">Enter first name</span>
        )}
      </div>
      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {
            // error: errors.first_name,
          })}
          placeholder="Middle Name"
          id="middle_name"
          name="middle_name"
          value={state.middle_name}
          onChange={(e) => eventhandler(e)}
        />
      </div>
      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {
            error: errors.last_name,
          })}
          placeholder="Last Name*"
          id="last_name"
          name="last_name"
          // value={x.last_name}
          onChange={(e) => eventhandler(e)}
        />
        {errors.last_name && (
          <span className="text-red small d-block">Enter last name</span>
        )}
      </div>

      <div className="mt-18">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            value={state.DOB}
            clearable
            placeholder="DOB"
            onChange={(e) =>
              eventhandler({
                target: {
                  value: e,
                  name: "DOB",
                  id: "DOB",
                },
              })
            }
            maxDate={new Date("01-01-2005")}
            format="MM/dd/yyyy"
            mask="__/__/____"
            inputVariant="outlined"
            autoOk={true}
            InputAdornmentProps={{ position: "end" }}
            className={classNames("form-control team-name", {
              //   error: DOB,
            })}
          />
        </MuiPickersUtilsProvider>
        {errors.DOB == "required" && (
          <span className="text-red small d-block">Enter birth date</span>
        )}
      </div>

      <div className="mt-18">
        <select
          className="form-select form-control team-name"
          aria-label="Default select example"
          onChange={eventhandler}
          name="gender"
        >
          <option selected disabled>
            Gender
          </option>
          <option value={0}>Male</option>
          <option value={1}>Female</option>
        </select>
      </div>

      <div className="mt-18">
        <input
          type="number"
          className={classNames("form-control team-name", {
            error: errors.telephone,
          })}
          placeholder="Phone *"
          id="telephone"
          name="telephone"
          // value={x.tele}
          onChange={(e) => eventhandler(e)}
        />
        {errors.telephone && (
          <span className="text-red small d-block">Enter phone number.</span>
        )}
      </div>

      <div className="mt-18" style={{ marginLeft: "20px" }}>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value={state.terms_condition}
            name="terms_condition"
            id="flexCheckDefault"
            onChange={eventhandler}
            checked={state.terms_condition == 1}
          />
          <label className="form-check-label text-light" for="flexCheckDefault">
            Terms & Condition
          </label>
        </div>
      </div>

      <Button
        className="mt-3"
        style={{ backgroundColor: "#189AD6" }}
        onClick={handleSubmit}
      >
        Create
      </Button>
    </>
  );
};

export default ParentAccountForm;
