import { GET_EVENT_TEAM_ERROR, GET_EVENT_TEAM_LOADING, GET_EVENT_TEAM_SUCCESS } from "../../constants"
let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
  data: []
};

export default function getEventTeamReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EVENT_TEAM_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false,loading: true ,  success: false };

    case GET_EVENT_TEAM_SUCCESS:
      // alert({ ...state, loading: false, success: true, user: action.payload.data })
      return { ...state, loading: false, success: true, user: action.payload };

    case GET_EVENT_TEAM_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };
    default:
      return state;
  }
}
