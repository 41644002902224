import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
// import $ from 'jquery'
// import classNames from "classnames";
import { connect, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useLocation, useParams } from 'react-router';
// import GroupPopUp from '../../organiser/Account/Account_user/GroupPopUp'
import InputMask from "react-input-mask";


// import Toaster from '../../Toaster/Toaster'
import { Club_changeStatus, payNow, registerSelectedTeam, teamRegister } from '../../service/action/Club/clubAction';
import { RESET_ACCEPT_INVITATION, RESET_PAYNOW } from '../../service/constants';
import { CARD_CVV, CARD_NAME, CARD_NUMBER, EXPIRY_DATE } from '../../Validation/club';
import { Modal, Spinner } from 'react-bootstrap';


const Pay = (props) => {

    const location = useLocation()
    const [credit, setCredit] = useState(0)

    let [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false)
    const [errorDetails, setErrorDetails] = useState(null)
    const { id, id1, id2 } = useParams();
    const [cvv, setCvv] = useState({
        digit1: '',
        digit2: '',
        digit3: ''
    })

    const history = useHistory()

    const [state, setState] = useState({
        card_number: "",
        expiry_date: "",
        cvv: "",
        card_name: "",
    })


    const teamlist = useSelector(state => state.teamArrayReducer.user)
    const collectionData = useSelector(state => state.sendPaymentInfoReducer.user)
    // const eventDetails = useSelector(state => state.getClubOrgEventDetailsReducer.data).data
    // let errorDetails = props.paynowCheck.error ? props.paynowCheck.errors : null

    useEffect(() => {
        setErrorDetails(props.paynowCheck.error ? props.paynowCheck.errors : null)
    }, [props.paynowCheck.error])


    const handleChange = (e) => {
        const { value, id, name } = e.target

        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
        if (errorDetails) {
            switch (name) {
                case "card_number":
                    setErrorDetails(prev => ({
                        ...prev,
                        card_number: false
                    }))
                    break;
                case "expiry_date":
                    setErrorDetails(prev => ({
                        ...prev,
                        expiration_date: false
                    }))
                    break;
                case "cvv":
                    setErrorDetails(prev => ({
                        ...prev,
                        card_verification_number: false
                    }))
                    break;
                case "card_name":
                    setErrorDetails(prev => ({
                        ...prev,
                        name_on_code: false
                    }))
                    break;
                default:
                    return errorDetails
            }
        }
    }

    const notify = (msg) => toast(msg);

    // onRefresh Page redirect to back
    useEffect(() => {
        if (teamlist === null) {
            history.push(`/events/${id}/registernow` + (id1 == 721 ? "" : `/${id1}`))
            // props.resetregisteredSelectedTeamReducer()
        }
    }, [])



    const paynow = (e) => {
        e.preventDefault()
        props.paynowHandler({
            club: collectionData.club,
            event: collectionData.event,
            payment_code: collectionData.payment_code,
            organizer: collectionData.organizer,
            amount: collectionData.amount,
            panding_amount: collectionData.panding_amount,
            credit_note: collectionData.credit_note,
            transaction_type: "sale",
            card_number: state.card_number,
            expiration_date: state.expiry_date,
            name_on_code: state.card_name,
            card_verification_number: state.cvv,
            is_card_payment: id2,
            card_type: credit
        })
    }

    useEffect(() => {
        if (props.paynowCheck?.success && id1 !== '721') {
            props.resetPaynowReducer()
            props.changestatusHandler({
                invitation_id: id1,
                status: 1
            })
            props.selectedTeamHandler({
                event_id: id,
                teams: teamlist,
                organizer: collectionData.organizer,
                invite_type: 0,
            })

        }
        else if (props.paynowCheck?.success && id1 === '721') {
            props.resetPaynowReducer()
            setShow(true)
            props.selectedTeamHandler({
                event_id: id,
                teams: teamlist,
                organizer: collectionData.organizer,
                invite_type: 0,
            })
            setTimeout(() => {
                setShow(false)
                history.push("/dashboard")
            }, 3000)

        }

    }, [props.paynowCheck])


    useEffect(() => {
        if (props.accept?.success) {
            notify("accept succesfull")

            props.resetAcceptInvitationReducer()
            setShow(true)
            setTimeout(() => {
                setShow(false)
                history.push("/dashboard")
            }, 3000)


        }
    }, [props.accept])

    useEffect(() => {
        if (show) {
        }
    }, [show])

    const handleCvv = (e) => {
        const { name, value } = e.target
        setCvv(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const inputfocus = (e) => {
        if (e.key === "Delete" || e.key === "Backspace") {
            const next = e.target.tabIndex - 2;
            if (next > -1) {
                document.getElementsByClassName('cvvInput')[next].focus()
                // e.target.form.elements[next].focus()
            }
        }
        else {
            const next = e.target.tabIndex;
            if (next < 3) {
                document.getElementsByClassName('cvvInput')[next].focus()
            }
        }
    }

    const payWithCvv = (e) => {
        e.preventDefault()
        const cvvNumber = Object.values(cvv).join('')
        const { card_number, card_name, expiry_month, expiry_year } = location.state.selectedCard
        if (cvvNumber.trim().length === 3) {
            props.paynowHandler({
                club: collectionData.club,
                event: collectionData.event,
                payment_code: collectionData.payment_code,
                organizer: collectionData.organizer,
                amount: collectionData.amount,
                panding_amount: collectionData.panding_amount,
                credit_note: collectionData.credit_note,
                transaction_type: "sale",
                card_number: card_number,
                expiration_date: `${expiry_month}${expiry_year.substring(expiry_year.length - 2)}`,
                name_on_code: card_name,
                card_verification_number: cvvNumber,
                is_card_payment: id2,
                card_type: credit
            })
        } else {
            notify('Enter valid Cvv Number')
        }

    }
    return (


        <React.Fragment>
            <div className="main-panel">
                {/* <div className="content-wrapper"> */}
                {
                    location.state.selectedCard ?
                        <div className="content-wrapper">
                            {
                                props.paynowCheck?.loading ? <div className='container d-flex justify-content-center align-items-center'>
                                    <span className='noti-type'><Spinner /></span>
                                </div> : <>
                                    <div className='text-center text-gray'>
                                        Enter a 3 digit CVV for your card ending with **** {location.state.selectedCard.card_number.substring(location.state.selectedCard.card_number.length - 4)}
                                    </div>
                                    <div className='mt-24'>
                                        <div className='d-flex justify-content-center'>
                                            {/* <input name='digit1' className='cvvInput form-control rounded m-2' value={cvv.digit1} tabIndex="1" autoComplete="off" onChange={(e) => handleCvv(e)} onKeyUp={e => inputfocus(e)} maxLength='1'/> */}
                                            <InputMask mask="9" name='digit1' className='cvvInput form-control rounded m-2' value={cvv.digit1} tabIndex="1" autoComplete="off" onChange={(e) => handleCvv(e)} maskChar={""} onKeyUp={e => inputfocus(e)} />
                                            <InputMask mask="9" name='digit2' className='cvvInput form-control rounded m-2' value={cvv.digit2} tabIndex="2" autoComplete="off" onChange={(e) => handleCvv(e)} maskChar={""} onKeyUp={e => inputfocus(e)} />
                                            <InputMask mask="9" name='digit3' className='cvvInput form-control rounded m-2' value={cvv.digit3} tabIndex="3" autoComplete="off" onChange={(e) => handleCvv(e)} maskChar={""} onKeyUp={e => inputfocus(e)} />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mt-24'>
                                        <button className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 rounded" onClick={payWithCvv}>
                                            pay
                                        </button>
                                    </div>
                                </>
                            }

                        </div> :
                        <div className="container-fluid page-body-wrapper  full-page-wrapper">
                            <div className="content-wrapper d-flex  p-4 mb-4 bg-white my-4">

                                <div className="row flex-grow">
                                    <div className="col-lg-7 col-md-12  mx-auto mt-24">


                                        <div className=" text-left pl-5 pr-5 pb-3 shadow pt-3">
                                            <div className="team-details">

                                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">

                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link active" id="dm" data-toggle="pill" href="#dm-tab" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => setCredit(0)}>Credit Card</a>
                                                    </li>

                                                    {/* <li className="nav-item" role="presentation">
                                                        <a className="nav-link" id="=notification" data-toggle="pill" href="#notification-tab" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => setCredit(1)}>Debit Card</a>
                                                    </li> */}

                                                </ul>
                                            </div>

                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="dm-tab" role="tabpanel" aria-labelledby="pills-home-tab">

                                                    <h6 className="text-center dash-head">Credit Card Details</h6>
                                                    {props.paynowCheck?.loading ? <div className='container d-flex justify-content-center align-items-center'>
                                                        <span className='noti-type'><Spinner /></span>
                                                    </div> :
                                                        <div className="pt-3">
                                                            {/* <div className="form-group">

                                        </div> */}



                                                            <label className="text-gray fs-13">Card Number<span className="text-red fw-m">*</span></label>

                                                            <div className="mb-3">
                                                                <InputMask mask="9999 9999 9999 9999" className='form-control team-name quantity' minLength={8} value={state.card_number} onChange={handleChange} maskChar={"_"} placeholder="xxxx xxxx xxxx 8888 " name="card_number" id="card_number" />
                                                                {/* <input type="number" minLength="8" maxLength="8" className="form-control team-name quantity" id="card_number" name="card_number" placeholder="xxxx xxxx xxxx 8888 " required /> */}
                                                                {errorDetails && errorDetails.card_number ? <small className="text-red fw-m">{CARD_NUMBER}</small> : null}
                                                            </div>

                                                            <div className='row'>
                                                                <div className='col-md-5'>

                                                                    <label className="text-gray fs-13">Valid Through <span className="text-red fw-m">*</span></label>
                                                                    <div className="mb-3">
                                                                        <InputMask mask="9999" className='form-control team-name quantity' value={state.expiry_date} onChange={handleChange} maskChar={"_"} placeholder="MM/YY " name="expiry_date" id="expiry_date" />
                                                                        {errorDetails && errorDetails.expiration_date ? <small className="text-red fw-m">{EXPIRY_DATE}</small> : null}
                                                                        {/* <input type="number" className="form-control team-name quantity" id="valid_through" name="season_name" placeholder="MM/YY " required /> */}
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-5'>

                                                                    <label className="text-gray fs-13">CVV <span className="text-red fw-m">*</span></label>
                                                                    <div className="mb-3">
                                                                        <InputMask mask="999" className='form-control team-name quantity' value={state.cvv} onChange={handleChange} maskChar={"_"} placeholder="cvv " name="cvv" id="cvv" />
                                                                        {errorDetails && errorDetails.card_verification_number ? <small className="text-red fw-m">{CARD_CVV}</small> : null}
                                                                        {/* <input type="number" className="form-control team-name quantity" id="cvv" name="cvv" placeholder="cvv " required /> */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <label className="text-gray fs-13">Card Holder Name<span className="text-red fw-m">*</span></label>
                                                            <div className="mb-3">
                                                                <input type="text" className="form-control team-name" id="card_name" name="card_name" placeholder="Card Holder Name " required onChange={handleChange} value={state.card_name} />
                                                                {errorDetails && errorDetails.name_on_code ? <small className="text-red fw-m">{CARD_NAME}</small> : null}
                                                            </div>


                                                            <div className="mt-3 text-center">
                                                                {/* <Link to={`/carddetails`} className='text-decoration-none'> */}
                                                                <button className='mr-1 btn btn-info btn-lg font-weight-medium auth-form-btn ' onClick={paynow}  >
                                                                    Pay
                                                                </button>
                                                                {/* </Link> */}
                                                            </div>

                                                        </div>}
                                                </div>

                                                <div className="tab-pane fade" id="notification-tab" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                    <h6 className=" text-center dash-head">Debit Card Details</h6>
                                                    <form className="pt-3">

                                                        <label className="text-gray fs-13">Card Number<span className="text-red fw-m">*</span></label>

                                                        <div className="mb-3">
                                                            <InputMask mask="9999 9999 9999 9999" className='form-control team-name quantity' value={state.card_number} onChange={handleChange} maskChar={"_"} placeholder="xxxx xxxx xxxx 8888 " name="card_number" id="card_number" />
                                                            {errorDetails && errorDetails.card_number ? <small className="text-red fw-m">{CARD_NUMBER}</small> : null}
                                                            {/* <input type="number" minLength="8" maxLength="8" className="form-control team-name quantity" id="card_number" name="card_number" placeholder="xxxx xxxx xxxx 8888 " required /> */}
                                                        </div>

                                                        <div className='row'>
                                                            <div className=' col-md-6 col-sm-6'>

                                                                <label className="text-gray fs-13">Valid Through <span className="text-red fw-m">*</span></label>
                                                                <div className="mb-3 ">
                                                                    <InputMask mask="9999" className='form-control team-name quantity' value={state.expiry_date} onChange={handleChange} maskChar={"_"} placeholder="MM/YY " name="expiry_date" id="expiry_date" />
                                                                    {errorDetails && errorDetails.expiration_date ? <small className="text-red fw-m">{EXPIRY_DATE}</small> : null}
                                                                    {/* <input type="number" className="form-control team-name quantity" id="valid_through" name="season_name" placeholder="MM/YY " required /> */}
                                                                </div>
                                                            </div>
                                                            <div className=' col-md-6  col-sm-6 '>

                                                                <label className="text-gray fs-13">CVV <span className="text-red fw-m">*</span></label>
                                                                <div className="mb-3 ">
                                                                    <InputMask mask="999" className='form-control team-name quantity' value={state.cvv} onChange={handleChange} maskChar={"_"} placeholder="cvv " name="cvv" id="cvv" />
                                                                    {errorDetails && errorDetails.card_verification_number ? <small className="text-red fw-m">{CARD_CVV}</small> : null}
                                                                    {/* <input type="number" className="form-control team-name quantity" id="cvv" name="cvv" placeholder="cvv " required /> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <label className="text-gray fs-13">Card Holder Name<span className="text-red fw-m">*</span></label>
                                                        <div className="mb-3 ">
                                                            <input type="text" className="form-control team-name" id="card_name" name="card_name" placeholder="Card Holder Name " required onChange={handleChange} value={state.card_name} />
                                                            {errorDetails && errorDetails.name_on_code ? <small className="text-red fw-m">{CARD_NAME}</small> : null}
                                                        </div>


                                                        <div className="mt-3 text-center">
                                                            {/* <Link to={`/carddetails`} className='text-decoration-none'> */}
                                                            <button className='mr-1 btn btn-info btn-lg font-weight-medium auth-form-btn ' onClick={paynow}  >
                                                                Pay
                                                            </button>
                                                            {/* </Link> */}
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                }
            </div>





            {/* <Toaster /> */}

            <Modal
                show={show}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                style={{ borderRadius: '20px' }}
            >
                <div className="payment-1">
                    <div className="payment_header">
                        <div className="check-1"><i className="fa fa-check" aria-hidden="true"></i></div>
                    </div>
                    <div className="content-1 mt-24">
                        <h1>Payment Successfull !</h1>
                    </div>
                </div>
            </Modal>

        </React.Fragment>
    )
}

// export default TeamsCoches;

const mapStateToProps = (state) => ({
    paynowCheck: state.paynowReducer,
    accept: state.acceptInvitationReducer
})

const mapDispatchToProps = dispatch => ({
    paynowHandler: user => dispatch(payNow(user)),
    selectedTeamHandler: user => dispatch(teamRegister(user)),
    changestatusHandler: user => dispatch(Club_changeStatus(user)),
    resetAcceptInvitationReducer: () => dispatch({ type: RESET_ACCEPT_INVITATION }),
    resetPaynowReducer: () => dispatch({ type: RESET_PAYNOW })

})

export default connect(mapStateToProps, mapDispatchToProps)((Pay))