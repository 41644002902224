import { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import { connect } from "react-redux";
import {
  CreateStaffAction,
  DeleteStaffAction,
  UpdateStaffAction,
  changeUserStatus,
  getStaffList,
} from "../../Services/Actions/userAction";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  RESET_CHECK_MAIL_EXIST,
  RESET_CREATE_STAFF,
  RESET_DELETE_STAFF,
  RESET_UPDATE_STAFF,
  RESET_USER_STATUS,
  getDoubleDigitNumber,
  removeStorage,
  validEmailRegex,
  validatePhoneString,
} from "../../Services/constant";
import moment from "moment";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import classNames from "classnames";
import { CityList, StateList, checkMailIsExist } from "../../Services/Actions";
import InputMask from "react-input-mask";
import ReactDatePicker from "react-datepicker";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
function Staff(props) {
  const DATA_ITEM_KEY = "_id";
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [xlShow, setXlShow] = useState(false);
  const handleClose = () => setXlShow(false);
  const handleShow = () => setXlShow(true);
  const [selectedDay, setSelectedDay] = useState(null);

  const formatInputValue = () => {
    if (!selectedDay) return "";
    return `${selectedDay.day}/${selectedDay.month}/${selectedDay.year} `;
  };
  const [isMailExist, setIsMailExist] = useState(false);
  const [pageDetails, setPageDetails] = useState({
    skip: 0,
    take: 10,
  });

  const [delShow, setDelShow] = useState(false);
  const delDialClose = () => setDelShow(false);
  const delDialShow = () => setDelShow(true);

  const [successShow, setSuccessShow] = useState(false);
  const successDialClose = () => setSuccessShow(false);
  const successDialShow = () => {
    setDelShow(false);
    //     setSuccessShow(true);
    props.deletestaffHandler({ id: deletedID });
  };

  const InitialError = {
    first_name: false,
    middle_name: false,
    last_name: false,
    dob: false,
    mobile_number: false,
    email: false,
    address: false,
    city: false,
    state: false,
    zip: false,
    description: false,
  };

  const InitialForm = {
    first_name: "",
    middle_name: "",
    last_name: "",
    dob: selectedDay,
    mobile_number: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    description: "",
  };

  const [detailsForm, setDetailsForm] = useState(InitialForm);
  const [errorForm, setErrorForm] = useState(InitialError);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [state, setState] = useState([]);
  const [InitialState, setInitialState] = useState([]);
  const [selectedID, setSelectedId] = useState("");
  const [deletedID, setdeletedID] = useState();
  const [status, setStatus] = useState({
    status: "",
    isChanged: false,
  });
  useEffect(() => {
    props.getStaffList();
    props.StateList();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (detailsForm.state !== "") {
      props.CityList(detailsForm?.state);
    }
  }, [detailsForm?.state]);

  useEffect(() => {
    if (props.stateListReducer.success) {
      const data = props.stateListReducer.data;
      setStateList(data.data);
    }
  }, [props.stateListReducer]);

  useEffect(() => {
    if (props.cityListReducer.success) {
      const data = props.cityListReducer.data;
      setCityList(data.data);
    }
  }, [props.cityListReducer]);

  useEffect(() => {
    if (props.staffListReducer.success) {
      const data = props.staffListReducer.data;
      const _data = data.data.map((e) => {
        return {
          ...e,
          first_name: e.first_name != "" ? `${e.first_name}` : "N/A",
          middle_name: e.middle_name != "" ? `${e.middle_name} ` : "N/A",
          last_name: e.last_name != "" ? ` ${e.last_name}` : "N/A",
          email: !!e.email && e.email !== "" ? e.email : "N/A",
          address: !!e.address && e.address !== "" ? e.address : "N/A",
          city: e.city && e.city !== "" ? e.city : "N/A",
          state: !!e.state ? e.state.name : "N/A",
          stateID: !!e.state ? e.state._id : "N/A",
          zip: e.zip !== "" ? e.zip : "N/A",
          mobile_number:
            !!e.mobile_number && e.mobile_number !== ""
              ? e.mobile_number
              : "N/A",
        };
      });
      setState(_data);
      setInitialState(_data);
      setLoading(false);
    } else if (props.staffListReducer.error) {
      const errMsg = props.staffListReducer.errorMsg;
      !!errMsg && toast.error(errMsg);
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      }
    }
  }, [props.staffListReducer]);

  useEffect(() => {
    if (props.staffListReducer.success) {
      if (searchVal !== "") {
        const data = props.staffListReducer.data.data;
        const filter = data.filter((e) =>
          e.name.toLowerCase().includes(searchVal.toLowerCase())
        );
        setState(filter);
      } else {
        const data = props.staffListReducer.data.data;
        setState(data);
      }
    }
  }, [searchVal]);

  const customAction = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <button
          onClick={() => {
            const details = cell.dataItem;

            setSelectedId(details._id);
            setDetailsForm({
              first_name:
                details.first_name !== "N/A" ? details.first_name : "",
              middle_name:
                details.middle_name !== "N/A" ? details.middle_name : "",
              last_name: details.last_name !== "N/A" ? details.last_name : "",
              dob: !!details.dob
                ? moment(details.dob, "MM-DD-yyyy").toDate()
                : null,
              email: details.email !== "N/A" ? details.email : "",
              mobile_number:
                details.mobile_number !== "N/A" ? details.mobile_number : "",
              address: details.address !== "N/A" ? details.address : "",
              city: details.city !== "N/A" ? details.city : "",
              state: details.stateID !== "N/A" ? details.stateID : "",
              zip: details.zip !== "N/A" ? details.zip : "",
              description:
                details.description !== "N/A" ? details.description : "",
            });
            handleShow();
          }}
          className="btn-link btn text-secondary p-0 mx-2"
        >
          <i className="ri-pencil-fill"></i>
        </button>
        <button
          onClick={() => {
            const details = cell.dataItem;
            delDialShow();
            setdeletedID(details?._id);
          }}
          className="btn-link btn text-danger p-0 mx-2"
        >
          <i className="ri-delete-bin-fill"></i>
        </button>
      </td>
    );
  };

  const customHeader = (cell) => {
    return <b title={cell.title}>{cell.title}</b>;
  };
  const customStatus = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <Button
          type="button"
          className={classNames("btn-status  btn-primary", {
            active: cell.dataItem.status == 1,
          })}
          onClick={() => {
            setStatus({
              status: cell.dataItem.status == 1 ? "deactivate" : "activate",
              isChanged: true,
            });

            setSelectedId(cell.dataItem._id);
          }}
        >
          {cell.dataItem.status == 1 ? "Active" : "Inactive"}
        </Button>
      </td>
    );
  };

  useEffect(() => {
    if (props.changeUserStatusReducer.success) {
      const _data = state.map((e) => {
        if (e._id == selectedID) {
          return {
            ...e,
            status: e.status == 0 ? 1 : 0,
          };
        } else
          return {
            ...e,
          };
      });
      setStatus({
        status: "",
        isChanged: false,
      });
      setSelectedId("");
      setState(_data);
      toast.success("Status Changed Successfuly");
      props.resetStatus();
    } else if (props.changeUserStatusReducer.error) {
      setSelectedId("");
      toast.success("Get Error To change Status");
      props.resetStatus();
    }
  }, [props.changeUserStatusReducer]);
  const customCell = (cell) => {
    return (
      <td {...cell.tdProps} title={cell.children}>
        {cell.children}
      </td>
    );
  };
  const grid = (
    <Grid
      data={state.slice(pageDetails.skip, pageDetails.skip + pageDetails.take)}
      skip={pageDetails.skip}
      take={pageDetails.take}
      total={state.length}
      pageable={{
        buttonCount: 3,
        pageSizes: [5, 10, 15],
      }}
      onPageChange={(e) => {
        setPageDetails(() => ({
          ...e.page,
          take: e.page.take,
        }));
      }}
      dataItemKey={DATA_ITEM_KEY}
    >
      <GridNoRecords>No records are available</GridNoRecords>
      <GridColumn
        field="first_name"
        title="First Name"
        //   width="160"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="middle_name"
        title="Middle Name"
        //   width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="last_name"
        title="Last Name"
        //   width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="email"
        title="Email"
        //   width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="mobile_number"
        title="Phone"
        //   width="110"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="status"
        title="Status"
        width="100"
        headerCell={customHeader}
        cell={customStatus}
      />
      <GridColumn
        field="action"
        title="Actions"
        width="100"
        headerCell={customHeader}
        cell={customAction}
      />
    </Grid>
  );

  const handleForm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "zip") {
      if (value.length <= 6 && !isNaN(value)) {
        setDetailsForm((prev) => ({
          ...prev,
          zip: value,
        }));
      } else {
        return;
      }
    } else {
      setDetailsForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    switch (name) {
      case "first_name":
        if (value == "") {
          errorForm["first_name"] = "Required";
        } else {
          errorForm["first_name"] = false;
        }
        break;
      case "middle_name":
        if (value == "") {
          errorForm["middle_name"] = "Required";
        } else {
          errorForm["middle_name"] = false;
        }
        break;
      case "last_name":
        if (value == "") {
          errorForm["last_name"] = "Required";
        } else {
          errorForm["last_name"] = false;
        }
        break;
      case "email":
        if (value == "") {
          errorForm["email"] = "Required";
        } else if (!validEmailRegex.test(value)) {
          errorForm["Email"] = "Enter Valid Email";
        } else {
          errorForm["email"] = false;
        }
        break;
      case "mobile_number":
        const _value = value.replaceAll("-", "");
        const validateString = new RegExp(/^[0-9\b]+$/);
        if (_value === "") {
          errorForm["mobile_number"] = "Enter Phone Number";
        } else if (!validateString.test(_value)) {
          errorForm["mobile_number"] = "Enter Valid Phone Number";
        } else if (value.length != 0 && value.length < 12) {
          errorForm["mobile_number"] = "Enter Valid Phone Number";
        } else {
          errorForm["mobile_number"] = false;
        }
        break;
      case "address":
        if (value == "") {
          errorForm["address"] = "Required";
        } else {
          errorForm["address"] = false;
        }
        break;
      case "city":
        if (value == "") {
          errorForm["city"] = "Required";
        } else {
          errorForm["city"] = false;
        }
        break;
      case "state":
        if (value == "") {
          errorForm["state"] = "Required";
        } else {
          errorForm["state"] = false;
        }
        break;
      case "description":
        if (value == "") {
          errorForm["description"] = "Required";
        } else {
          errorForm["description"] = false;
        }
        break;
      default:
        break;
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    if (detailsForm.first_name == "") {
      setErrorForm((prev) => ({
        ...prev,
        first_name: "Required",
      }));
    }
    if (detailsForm.mobile_number == "") {
      setErrorForm((prev) => ({
        ...prev,
        mobile_number: "Required",
      }));
    }
    if (detailsForm.last_name == "") {
      setErrorForm((prev) => ({
        ...prev,
        last_name: "Required",
      }));
    }
    if (detailsForm.email == "") {
      setErrorForm((prev) => ({
        ...prev,
        email: "Required",
      }));
    }
    if (detailsForm.address == "") {
      setErrorForm((prev) => ({
        ...prev,
        address: "Required",
      }));
    }
    if (detailsForm.city == "") {
      setErrorForm((prev) => ({
        ...prev,
        city: "Required",
      }));
    }
    if (detailsForm.state == "") {
      setErrorForm((prev) => ({
        ...prev,
        state: "Required",
      }));
    }
    if (detailsForm.description == "" || detailsForm.description == undefined) {
      setErrorForm((prev) => ({
        ...prev,
        description: "Required",
      }));
    }
    if (detailsForm.dob == null) {
      setErrorForm((prev) => ({
        ...prev,
        dob: "Required",
      }));
    }
    const is18Plus =
      detailsForm.dob !== null
        ? moment().diff(detailsForm.dob, "years", false) >= 18
        : false;

    if (!is18Plus) {
      setErrorForm((prev) => ({
        ...prev,
        dob: "Required",
      }));
      toast.error("Age must be 18 years old");
    }

    if (
      detailsForm.first_name !== "" &&
      detailsForm.last_name !== "" &&
      detailsForm.email !== "" &&
      detailsForm.mobile_number !== "" &&
      detailsForm.dob !== null &&
      is18Plus &&
      !errorForm.email &&
      !errorForm.dob &&
      !errorForm.mobile_number && {
        ...(selectedID &&
          detailsForm.address !== "" &&
          detailsForm.city !== "" &&
          detailsForm.state !== "" &&
          detailsForm.description !== ""),
      }
    ) {
      const details = {
        StaffID: selectedID,
        user_type: 2,
        dob: moment(detailsForm?.dob).format("MM-DD-yyyy"),
        first_name:
          detailsForm.first_name !== "N/A" ? detailsForm.first_name : "",
        middle_name:
          detailsForm.middle_name !== "" ? detailsForm.middle_name : "",
        last_name: detailsForm.last_name !== "N/A" ? detailsForm.last_name : "",
        email: detailsForm.email !== "N/A" ? detailsForm.email : "",
        password: 123456789,
        mobile_number:
          detailsForm.mobile_number !== "N/A" ? detailsForm.mobile_number : "",
        ...(selectedID && {
          address: detailsForm.address !== "N/A" ? detailsForm.address : "",
          city: detailsForm.city !== "N/A" ? detailsForm.city : "",
          cityName: detailsForm.city !== "N/A" ? detailsForm.city : "",
          state: detailsForm.state !== "N/A" ? detailsForm.state : "",
          zip: detailsForm.zip !== "N/A" ? detailsForm.zip : "",
          description:
            detailsForm.description !== "N/A" ? detailsForm.description : "",
        }),
      };
      selectedID == ""
        ? props.createstaffHandler(details)
        : props.updatestaffHandler(details);
    }
  };

  useEffect(() => {
    if (detailsForm.email !== "") {
      if (selectedID != "") {
        const q = InitialState.find((e) => e._id == selectedID).email;
        if (q != detailsForm.email) {
          setTimeout(() => {
            props.checkMailIsExist(detailsForm.email);
          }, 500);
        } else {
          props.resetisCheckEmail();
          setIsMailExist(false);
        }
      } else {
        setTimeout(() => {
          props.checkMailIsExist(detailsForm.email);
        }, 500);
      }
    } else {
      props.resetisCheckEmail();
      setIsMailExist(false);
    }
  }, [detailsForm.email]);

  useEffect(() => {
    if (props.isMailExistReducer.success) {
      setIsMailExist(props.isMailExistReducer.data.status);
    } else if (props.isMailExistReducer.error) {
      const errMsg = props.isMailExistReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.isMailExistReducer]);

  useEffect(() => {
    if (props.createstaffReducer.success) {
      handleClose();
      toast.success("Staff created successfully");
      props.ResetCreateStaff();
      props.getStaffList();
      setLoading(true);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
      props.resetisCheckEmail();
    } else if (props.createstaffReducer.error) {
      const errMsg = props.createstaffReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.createstaffReducer]);

  useEffect(() => {
    if (props.updatestaffReducer.success) {
      handleClose();
      toast.success("Staff updated successfully");
      props.ResetCreateStaff();
      props.getStaffList();
      setLoading(true);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
      props.resetisCheckEmail();
      props.ResetUpdateStaff();
    } else if (props.updatestaffReducer.error) {
      const errMsg = props.updatestaffReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.updatestaffReducer]);

  useEffect(() => {
    if (props.deletestaffReducer.success) {
      handleClose();
      toast.success("Staff deleted successfully");
      props.ResetDeleteStaff();
      props.getStaffList();
      setLoading(true);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
    } else if (props.deletestaffReducer.error) {
      const errMsg = props.deletestaffReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.deletestaffReducer]);

  return (
    <>
      {loading ? (
        <>Loading.... </>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <h2 className="page-heading">Event Staff</h2>
          </div>

          <Row className="justify-content-between mx-0">
            <Col md="4" className="search px-0">
              <i className="ri-search-line"></i>
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchVal}
                onChange={(e) => {
                  setSearchVal(e.target.value);
                }}
              />
            </Col>
            <Col md="6" className="text-end px-0">
              <button
                className="btn-secondary px-4 py-2"
                onClick={() => {
                  handleShow();
                  setSelectedId("");
                }}
              >
                Add Staff
              </button>
            </Col>
          </Row>
          <div className="table-card">{grid}</div>
          <Modal
            size="xl"
            show={xlShow}
            onHide={() => {
              setDetailsForm(InitialForm);
              setErrorForm(InitialError);
              handleClose();
              setIsMailExist(false);
            }}
          >
            <Modal.Header>
              <Modal.Title>Event Staff</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="ml-auto">
                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>
                      First Name<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="first_name"
                      value={detailsForm.first_name}
                      className={classNames({
                        errorBorder: errorForm.first_name,
                      })}
                      onChange={handleForm}
                    />
                  </Form.Group>

                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>Middle Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="middle_name"
                      className={classNames({
                        errorBorder: errorForm.middle_name,
                      })}
                      value={detailsForm.middle_name}
                      onChange={handleForm}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>
                      Last Name<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="last_name"
                      className={classNames({
                        errorBorder: errorForm.last_name,
                      })}
                      value={detailsForm.last_name}
                      onChange={handleForm}
                    />
                  </Form.Group>
                </Row>
                {!!selectedID && (
                  <>
                    <div className="row gy-2 m-0">
                      <Form.Group className="mb-4 col-6" controlId="">
                        <Form.Label>
                          Address<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="address"
                          className={classNames({
                            errorBorder: errorForm.address,
                          })}
                          value={detailsForm.address}
                          onChange={handleForm}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 col-6" controlId="">
                        <Form.Label>
                          Description<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="description"
                          className={classNames({
                            errorBorder: errorForm.description,
                          })}
                          value={detailsForm.description}
                          onChange={handleForm}
                        />
                      </Form.Group>
                    </div>
                    <Row className="ml-auto">
                      <Form.Group as={Col} className="mb-4" controlId="">
                        <Form.Label>
                          State<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Select
                          onChange={handleForm}
                          value={detailsForm.state}
                          name="state"
                          className={classNames("form-control", {
                            errorBorder: errorForm.state,
                          })}
                        >
                          <option value={""} disabled>
                            Select State
                          </option>
                          {stateList.map((e, i) => {
                            return (
                              <option key={i} value={e._id}>
                                {e.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group as={Col} className="mb-4" controlId="">
                        <Form.Label>
                          City<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          list="cityNameList"
                          placeholder=""
                          name="city"
                          className={classNames("form-control", {
                            errorBorder: errorForm.city,
                          })}
                          value={detailsForm.city}
                          onChange={handleForm}
                        />
                        <datalist id="cityNameList">
                          {cityList.map((e, i) => {
                            return <option value={e.name} key={i} />;
                          })}
                        </datalist>
                      </Form.Group>
                    </Row>
                  </>
                )}
                <Row className="ml-auto">
                  <Form.Group
                    as={Col}
                    className="mb-4 d-flex flex-column"
                    controlId=""
                  >
                    <Form.Label>
                      Date of Birth<sup className="text-danger">*</sup>
                    </Form.Label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        value={detailsForm.dob}
                        clearable
                        placeholder=""
                        // onChange={date => startDateChange(date)}
                        onChange={(date) => {
                          setDetailsForm((prevState) => ({
                            ...prevState,
                            dob: date,
                          }));
                          if (date === null) {
                            setErrorForm((prevState) => ({
                              ...prevState,
                              dob: "Enter Valid Date of Birth",
                            }));
                          } else if (moment().diff(date, "years", false) < 18) {
                            setErrorForm((prevState) => ({
                              ...prevState,
                              dob: "Age Should be greter then 18 years",
                            }));
                          } else {
                            setErrorForm((prevState) => ({
                              ...prevState,
                              dob: false,
                            }));
                          }
                        }}
                        format="MM/dd/yyyy"
                        mask="__/__/____"
                        inputVariant="outlined"
                        autoOk={true}
                        InputAdornmentProps={{ position: "end" }}
                        className={classNames("form-control team-name", {
                          errorBorder: errorForm.dob,
                        })}
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>
                      Staff Email<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder=""
                      name="email"
                      className={classNames({
                        errorBorder: errorForm.email,
                      })}
                      value={detailsForm.email}
                      onChange={handleForm}
                      disabled={selectedID !== ""}
                    />
                    {isMailExist && (
                      <div
                        className="mt-2"
                        style={{
                          fontSize: "14px",
                          color: "red",
                          lineHeight: "23px",
                        }}
                      >
                        <u>
                          <b>Please note:</b>
                        </u>
                        &nbsp;This email ID has been used to create another
                        account. Please proceed if you'd like to add this
                        account underneath that account. Otherwise, please enter
                        a new email ID.
                      </div>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>
                      Phone<sup className="text-danger">*</sup>
                    </Form.Label>
                    <InputMask
                      mask="999-999-9999"
                      className={classNames("form-control team-name", {
                        errorBorder: errorForm.mobile_number,
                      })}
                      value={detailsForm.mobile_number}
                      onChange={handleForm}
                      maskChar={""}
                      placeholder=""
                      name="mobile_number"
                      id="mobile_number"
                    />

                    {/* <Form.Control
                      type="text"
                      placeholder=""
                      name="mobile_number"
                      className={classNames({
                        errorBorder: errorForm.mobile_number,
                      })}
                      value={detailsForm.mobile_number}
                      onChange={handleForm}
                    /> */}
                  </Form.Group>
                </Row>

                <div className="text-end">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      setDetailsForm(InitialForm);
                      setErrorForm(InitialError);
                      handleClose();
                      setIsMailExist(false);
                    }}
                    style={{ fontSize: "18px" }}
                    className="mr-3 pointer"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="secondary"
                    type="submit"
                    onClick={handleCreate}
                  >
                    {selectedID == "" ? "Submit" : "Update"}
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal
            size="sm"
            show={delShow}
            onHide={delDialClose}
            centered
            className="del-dialogue"
          >
            {/* <Modal.Header closeButton>
                              <Modal.Title>delete</Modal.Title>
                        </Modal.Header> */}
            <Modal.Body>
              <h6 className="text-center">
                Are you sure you want to delete this account?
              </h6>
            </Modal.Body>
            <Modal.Footer className="p-0">
              <Button className="btn-gray" onClick={delDialClose}>
                No
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  successDialShow();
                }}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          {/* <Modal
            size="sm"
            show={loginshow}
            onHide={handleloginClose}
            centered
            className="del-dialogue"
          >
            <Modal.Body>
            </Modal.Body>
            <Modal.Footer className="p-0 d-flex justify-content-center align-items-center">
              <Button className="btn-gray " onClick={handleloginClose}>
                Login
              </Button>
            </Modal.Footer>
          </Modal> */}
          <Modal
            size="sm"
            show={successShow}
            onHide={successDialClose}
            centered
            className="del-dialogue success"
          >
            <Modal.Body className="text-center">
              <i className="ri-close-circle-line fa-4x text-secondary"></i>
              <h5 className="text-center">Record deleted Successfully !!</h5>
            </Modal.Body>
          </Modal>

          {/* Status Changed  */}
          <Modal
            size="sm"
            show={status.isChanged}
            onHide={() =>
              setStatus({
                isChanged: false,
                status: "",
              })
            }
            centered
            className="del-dialogue"
          >
            <Modal.Body>
              <h6 className="text-center">
                Are you sure you want to <strong>{status.status}</strong> this
                account?
              </h6>
            </Modal.Body>
            <Modal.Footer className="p-0">
              <Button
                className="btn-gray"
                onClick={() =>
                  setStatus({
                    isChanged: false,
                    status: "",
                  })
                }
              >
                No
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  props.changeUserStatus({
                    type: 2,
                    id: selectedID,
                  });
                }}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

const mapStateToProp = (state) => ({
  staffListReducer: state.superAdminRootReducer.UserReducer.staffList,
  createstaffReducer: state.superAdminRootReducer.UserReducer.createstaff,
  updatestaffReducer: state.superAdminRootReducer.UserReducer.updatestaff,
  deletestaffReducer: state.superAdminRootReducer.UserReducer.deletestaff,
  stateListReducer: state.superAdminRootReducer.authReducers.stateList,
  cityListReducer: state.superAdminRootReducer.authReducers.cityList,
  changeUserStatusReducer:
    state.superAdminRootReducer.UserReducer.changeUserStatus,
  isMailExistReducer: state.superAdminRootReducer.authReducers.isMailExist,
});

const mapDispatchToProps = (dispatch) => ({
  getStaffList: () => dispatch(getStaffList()),
  StateList: () => dispatch(StateList()),
  CityList: (state) => dispatch(CityList(state)),
  createstaffHandler: (details) => dispatch(CreateStaffAction(details)),
  updatestaffHandler: (details) => dispatch(UpdateStaffAction(details)),
  deletestaffHandler: (details) => dispatch(DeleteStaffAction(details)),
  changeUserStatus: (details) => dispatch(changeUserStatus(details)),
  checkMailIsExist: (email) => dispatch(checkMailIsExist(email)),

  ResetCreateStaff: () => dispatch({ type: RESET_CREATE_STAFF }),
  ResetUpdateStaff: () => dispatch({ type: RESET_UPDATE_STAFF }),
  ResetDeleteStaff: () => dispatch({ type: RESET_DELETE_STAFF }),
  resetStatus: () => dispatch({ type: RESET_USER_STATUS }),
  resetisCheckEmail: () => dispatch({ type: RESET_CHECK_MAIL_EXIST }),
});

export default connect(mapStateToProp, mapDispatchToProps)(Staff);
