import React,{useEffect,useState} from 'react'
import { Link,withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import $ from "jquery";
import { RESET_CREATE_TEAM } from '../../service/constants';
import Toaster from '../../Toaster/Toaster';
import { getAllClub, inviteClub } from '../../service/action/userAction';
import { getPlayerList, InvitePlayerClub } from '../../service/action/Club/clubAction';



const PlayerNew = (props) => {
    const {id}=useParams()

    const getPlayerList = props.getPlayerListHandler
    useEffect(() => {
        getPlayerList()
    }, [])
    const notify = (msg) => toast(msg);

    const getPlayers = useSelector(state => state.getAllSeasonReducer.user)
    const InvitedMsg = useSelector(state => state.createTeamReducer.message)

    const invite = ({_id,have_parent} ) => {
        // alert("click")
        props.invitePlayerHandler({
            player:_id,
            event:id,
            have_parent:have_parent
        })
    }

    useEffect(() => {
        if (props.submit?.success) {
            notify(InvitedMsg.message)
            props.resetcreateTeamReducer()

            // history.push(`/event-details/${id}`);
        }

    }, [props.submit])

    const [state, setState] = useState({
        search: ""
    })



    const Searchval = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
        UpdateArray(e.target.value);
    }


    const UpdateArray = (search) => {
        var query = search;

        $(".filter-string").each(function (i, v) {
            if ($(v).text().toLowerCase().indexOf(query) === -1) {
                $(v).parents(".pay-row").addClass("d-none");
            } else {
                $(v).parents(".pay-row").removeClass("d-none");
            }
        });


    }


    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <div className="create-team-head">
                            <h5 className="dash-head mr-3">Search Clubs</h5>
                            <div className="d-flex align-items-center">
                                <div className="search-section">
                                    <i className="mdi mdi-magnify search-ico"></i>
                                    <input type="text" className="form-control" id="search" name="search" value={state.search} placeholder="Search" onChange={Searchval} autoComplete="off" />
                                </div>

                                <div>
                                    <i className="mdi mdi-filter-outline filter-ico"></i>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="table-responsive">
                        <table className="table event-table mt-30">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    {/* <th>Phone No.</th> */}
                                    {/* <th>Payment</th> */}
                                    {/* <th>Location</th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            {getPlayers? <>

                            <tbody>
                                {
                                    getPlayers.map((v, i) => {
                                        return <tr key={i} className='pay-row'>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="dashboard-img">
                                                        <img src={v.profile_image!==""?v.profile_image:"./images/pch-images/pch.svg"} />
                                                    </div>
                                                    <span className="ml-12 filter-string">{v.first_name}</span>
                                                </div>
                                            </td>
                                            {/* <td>{v.director_mobile_number}</td> */}
                                            {/* <td>{v.Phone}</td> */}
                                            {/* <td>{v.address}</td> */}
                                            <td>
                                                <Link to="#" className="text-decoration-none"  onClick={() => invite({_id:v._id,have_parent:v.parent_id !== null? 1:0})}>
                                                    <i className="mdi mdi-plus text-blue mr-2 website-ico"></i>
                                                    <span className="text-blue" >Invite</span></Link>
                                            </td>
                                        </tr>
                                    })
                                }


                            </tbody>
                            </>
                            :<><h4>Loading...</h4></>}
                        </table>
                        
                    </div>
                    <Toaster /> 
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    submit:state.createTeamReducer
    // getAllPayment: state.getAllPaymentCodeReducer,
   
})

const mapDispatchToProps = dispatch => ({
    getAllClubHandler: user => dispatch(getAllClub(user)),
    inviteclub:user=>dispatch(inviteClub(user)),
    resetcreateTeamReducer:()=>dispatch({type:RESET_CREATE_TEAM}),
    // userDataHandler: user => dispatch(userData(user)),
    invitePlayerHandler: user => dispatch(InvitePlayerClub(user)),
    getPlayerListHandler: user => dispatch(getPlayerList(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlayerNew))