import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { OrggetPlayerDetails } from "../../../service/action/userAction";
import { RESET_ORG_PLAYER_DETAILS } from "../../../service/constants";
import { useState } from "react";
import moment from "moment";
import Spinner from "../../../Spinner/Spinner";
import { connect, useSelector } from "react-redux";
import { useEffect } from "react";

const OrgPlayerDetails = (props) => {
  const { id } = useParams();

  const [playerDetails, setplayerDetails] = useState();
  const [contactSectionVisble, setContactSectionVisible] = useState(true);
  const [ShippingVisible, setShippingVisible] = useState(true);
  const [bioSectionVisble, setBioSectionVisible] = useState(true);
  const [loading, setLoading] = useState(true);

  // const playerDetails = props.OrgplayerReducer?.data?.data;

  useEffect(() => {
    props.Orgplayerdetails({ id: id });
  }, []);

  useEffect(() => {
    if (props.OrgplayerReducer?.success) {
      setLoading(false);
      const data = props.OrgplayerReducer?.data?.data?.player;
      setplayerDetails(data);
    }
  }, [props.OrgplayerReducer]);


  return (
    <div>
      {" "}
      <div className="main-panel">
        <div className="content-wrapper">
          {!loading && playerDetails ? (
            <div className="mb-5">
              <div
                className="d-flex align-items-center justify-content-center w-100"
                style={{ height: "150px" }}
              >
                <img
                  src={"./images/pch-images/pch.svg"}
                  className="w-100 h-100"
                />
              </div>
              <div className="mt-3">
                <h4 className="text-center mb-0">{`${playerDetails.first_name} ${playerDetails.last_name}`}</h4>
                <div className="text-center text-gray mt-2">
                  DOB: {moment(playerDetails.dob).format("MM/DD/YYYY")}
                </div>
              </div>

              <hr />

              <div
                style={{ borderBottom: "1px solid #899ea4" }}
                className="pb-2"
              >
                <div className="d-flex justify-content-between mt-4 align-items-center">
                  <div
                    className="d-flex"
                    onClick={() => {
                      setContactSectionVisible(!contactSectionVisble);
                      document.getElementById("contact").style.display =
                        contactSectionVisble ? "none" : "block";
                    }}
                  >
                    <h4 className="text-left mb-0 pointer">
                      Emergency Parent/Guardian Contact
                    </h4>
                    <a className="d-block text-info text-decoration-none ml-12 pointer fs-18">
                      {contactSectionVisble ? (
                        <i className="fas fa-angle-down"></i>
                      ) : (
                        <i className="fas fa-angle-up"></i>
                      )}
                    </a>
                  </div>
                </div>

                {playerDetails.primary_contact.length === 0 ? (
                  <div id="contact">No Contact to display</div>
                ) : (
                  <>
                    {playerDetails.primary_contact.map((v, i) => {
                      return (
                        <div key={i}>
                          <div className="mt-2 p-2" id="contact">
                            <div className="d-flex align-items-center mb-2 py-2">
                              <p className="text-left text-info mb-0  fs-14">
                                First Name :{" "}
                              </p>
                              <h6 className="text-left ml-1 text-gray m-0">
                                {v.first_name !== "" ? v.first_name : "N/A"}
                              </h6>
                            </div>
                            <hr className="m-0" />
                            <div className="d-flex align-items-center mb-2 py-2">
                              <p className="text-left text-info mb-0  fs-14">
                                Last Name :{" "}
                              </p>
                              <h6 className="text-left ml-1 text-gray m-0">
                                {v.last_name !== "" ? v.last_name : "N/A"}
                              </h6>
                            </div>
                            <hr className="m-0" />
                            <div className="d-flex align-items-center mb-2 py-2">
                              <p className="text-left text-info mb-0  fs-14">
                                Relationship :{" "}
                              </p>
                              <h6 className="text-left ml-1 text-gray m-0">
                                {v.relationship_player !== ""
                                  ? v.relationship_player
                                  : "N/A"}
                              </h6>
                            </div>
                            <hr className="m-0" />
                            <div className="d-flex align-items-center mb-2 py-2">
                              <p className="text-left text-info mb-0  fs-14">
                                Mobile No. :{" "}
                              </p>
                              <h6 className="text-left ml-1 text-gray m-0">
                                {v.mobile_no !== "" ? v.mobile_no : "N/A"}
                              </h6>
                            </div>
                            <hr className="m-0" />
                            <div className="d-flex align-items-center mb-2 py-2">
                              <p className="text-left text-info mb-0  fs-14">
                                Email :{" "}
                              </p>
                              <h6 className="text-left ml-1 text-gray m-0">
                                {v.email !== "" ? v.email : "N/A"}
                              </h6>
                            </div>
                            <div className="d-flex align-items-center mb-2 py-2">
                              <p className="text-left text-info mb-0  fs-14">
                                Sport :{" "}
                              </p>
                              <h6 className="text-left ml-1 text-gray m-0">
                                {playerDetails?.sport[i]?.sport !== ""
                                  ? playerDetails?.sport[i]?.sport
                                  : "N/A"}
                              </h6>
                            </div>
                          </div>
                          {i !== playerDetails.primary_contact.length - 1 && (
                            <div className="border-bottom" />
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
              <hr />

              <div
                style={{ borderBottom: "1px solid #899ea4" }}
                className="pb-2"
              >
                <div className="d-flex justify-content-between mt-4 align-items-center">
                  <div
                    className="d-flex"
                    onClick={() => {
                      setShippingVisible(!ShippingVisible);
                      document.getElementById("shipping").style.display =
                        ShippingVisible ? "none" : "block";
                    }}
                  >
                    <h4 className="text-left mb-0 pointer">Shipping Address</h4>
                    <a className="d-block text-info text-decoration-none ml-12 pointer fs-18">
                      {ShippingVisible ? (
                        <i className="fas fa-angle-down"></i>
                      ) : (
                        <i className="fas fa-angle-up"></i>
                      )}
                    </a>
                  </div>
                </div>

                {playerDetails.shipping_address.length === 0 ? (
                  <div id="shipping">No Shipping to display</div>
                ) : (
                  <>
                    <div>
                      <div className="mt-2 p-2" id="shipping">
                        <div className="d-flex align-items-center mb-2 py-2">
                          <p className="text-left text-info mb-0  fs-14">
                            Address :{" "}
                          </p>
                          <h6 className="text-left ml-1 text-gray m-0">
                            {playerDetails?.shipping_address?.address
                              ? playerDetails?.shipping_address?.address
                              : "N/A"}
                          </h6>
                        </div>
                        <div className="d-flex align-items-center mb-2 py-2">
                          <p className="text-left text-info mb-0  fs-14">
                            State :{" "}
                          </p>
                          <h6 className="text-left ml-1 text-gray m-0">
                            {playerDetails?.shipping_address?.state
                              ? playerDetails?.shipping_address?.state
                              : "N/A"}
                          </h6>
                        </div>
                        <div className="d-flex align-items-center mb-2 py-2">
                          <p className="text-left text-info mb-0  fs-14">
                            City :{" "}
                          </p>
                          <h6 className="text-left ml-1 text-gray m-0">
                            {playerDetails?.shipping_address?.city
                              ? playerDetails?.shipping_address?.city
                              : "N/A"}
                          </h6>
                        </div>
                        <div className="d-flex align-items-center mb-2 py-2">
                          <p className="text-left text-info mb-0  fs-14">
                            Zip Code :{" "}
                          </p>
                          <h6 className="text-left ml-1 text-gray m-0">
                            {playerDetails?.shipping_address?.zip_code
                              ? playerDetails?.shipping_address?.zip_code
                              : "N/A"}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div
                style={{ borderBottom: "1px solid #899ea4" }}
                className="pb-2"
              >
                <div className="d-flex justify-content-between mt-4 align-items-center">
                  <div
                    className="d-flex"
                    onClick={() => {
                      setBioSectionVisible(!bioSectionVisble);
                      document.getElementById("bio").style.display =
                        bioSectionVisble ? "none" : "block";
                    }}
                  >
                    <h4 className="text-left mb-0 pointer">Player bio</h4>
                    <a className="d-block text-info text-decoration-none ml-12 pointer fs-18">
                      {bioSectionVisble ? (
                        <i className="fas fa-angle-down"></i>
                      ) : (
                        <i className="fas fa-angle-up"></i>
                      )}
                    </a>
                  </div>
                </div>

                <div className="mt-2 p-2" id="bio">
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      School level:{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.school_information
                        .school_level ?? "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Grad. Month :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.school_information
                        .graduation_month ?? "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Grad. Year :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.school_information
                        .graduation_year ?? "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Current school :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.school_information
                        .current_school ?? "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">Height : </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.school_information.height ??
                        "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">Weight : </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.school_information.weight ??
                        "N/A"}
                    </h6>
                  </div>

                  {/* <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">Sport : </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerSport.length !== 0
                        ? playerSport.join(", ")
                        : "N/A"}
                    </h6>
                  </div> */}
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      US Lacrosse no. :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.sport_information
                        .us_lacrosse_number ?? "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Position1 :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.sport_information.position1 ??
                        "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Position2 :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.sport_information.position2 ??
                        "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Shirt Size :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.attire_info.shirt_size ??
                        "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Short Size :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.attire_info.short_size ??
                        "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Uniform Size :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.attire_info.uniform_size ??
                        "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Glove Size :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.attire_info.glove_size ??
                        "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Helmet Size :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.attire_info.helmet_size ??
                        "N/A"}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  OrgplayerReducer: state.OrgplayerdetailsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  Orgplayerdetails: (user) => dispatch(OrggetPlayerDetails(user)),
  resetOrgDetail: () => dispatch({ type: RESET_ORG_PLAYER_DETAILS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgPlayerDetails);
