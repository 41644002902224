import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createBankDetails, getBanks } from '../../../service/action/Club/clubAction';
import { RESET_BANK, RESET_BANKDETAILS,RESET_EDIT_BANK } from '../../../service/constants';
import Toaster from '../../../Toaster/Toaster';
import { deleteBankdetail } from '../../../service/action/Club/clubAction';

const BankInfo = (props) => {


    const getAllBank = props.getBanksHandler

    useEffect(() => {
        getAllBank()
    }, [])

    const allBank = useSelector(state => state.getBanksReducer.user)
    const deleteMessage = useSelector(state => state.deleteBankReducer.message)
    const preferred = allBank.find(e => e.default == 1)
    const alternet = allBank.filter((e) => e.default !== 1)

    const initialState = {
        bank_name: "",
        acc_name: "",
        acc_number: "",
        payrequired: 0,
        paymentchecked: false

    };


    const [bank, setBank] = useState({
        bank_name: "",
        acc_name: "",
        acc_number: "",
        payrequired: 0,
        paymentchecked: false
    })

    const [errors, setErrors] = useState({
        bank_name: false,
        acc_name: false,
        acc_number: false
    })

    const paycheck = () => {
        if (bank.payrequired == 0 && bank.paymentchecked == false) {
            setBank(prevState => ({
                ...prevState,
                payrequired: 1,
                paymentchecked: true
            }))
        } else if (bank.payrequired == 1 && bank.paymentchecked == true) {
            setBank(prevState => ({
                ...prevState,
                payrequire: 0,
                paymentchecked: false
            }))
        }
    }

    const bankMsg = useSelector(state => state.createBankDetailsReducer.message)
    const editbankMsg = useSelector(state => state.editBankReducer.message)

    const eventHandler = (e) => {
        const { id, value, name } = e.target
        setBank(prevState => ({
            ...prevState,
            [id]: value
            // [e.target.name]: e.target.value.trim()
        }))
        switch (name) {
            case "bank_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        bank_name: "Bank name can not be empty"
                    }))

                    document.getElementById("bank_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        bank_name: false
                    }))

                    document.getElementById("bank_name").classList.remove("error")
                }

                break;

            case "acc_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        acc_name: "Account name can not be empty"
                    }))

                    document.getElementById("acc_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        acc_name: false
                    }))

                    document.getElementById("acc_name").classList.remove("error")
                }

                break;

            case "acc_number":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        acc_number: "Account number can not be empty"
                    }))

                    document.getElementById("acc_number").classList.add("error")
                }
                else
                    if (!/^[0-9]+$/.test(value)) {
                        setErrors(prevState => ({
                            ...prevState,
                            acc_number: "Only numeric value is accept"
                        }))

                        document.getElementById("acc_number").classList.add("error")
                    }
                    else {

                        setErrors(prevState => ({
                            ...prevState,
                            acc_number: false
                        }))

                        document.getElementById("acc_number").classList.remove("error")
                    }

                break;

            case "payrequired":
                if (value == 0) {
                    setBank(prevState => ({
                        ...prevState,
                        payrequired: 1,
                        //     paymentchecked:true

                    }))

                } else {
                    setBank(prevState => ({
                        ...prevState,
                        payrequired: 0,
                        // paymentchecked:false
                    }))
                }

                break;

            default:

        }
    }


    const submit = (e) => {
        e.preventDefault();

        if (bank.bank_name == "") {
            setErrors(prevState => ({
                ...prevState,
                bank_name: "Bank name can not be empty"
            }))
            document.getElementById("bank_name").classList.add("error")
        }
        if (bank.acc_name == "") {
            setErrors(prevState => ({
                ...prevState,
                acc_name: "Account name can not be empty"
            }))
            document.getElementById("acc_name").classList.add("error")
        }
        if (bank.acc_number == "") {
            setErrors(prevState => ({
                ...prevState,
                acc_number: "Account number can not be empty"
            }))
            document.getElementById("acc_number").classList.add("error")
        }
        if (bank.bank_name !== "" && bank.acc_name !== "" && bank.acc_number !== "") {
            props.bankDetailshandler({
                bank_name: bank.bank_name,
                account_name: bank.acc_name,
                account_number: bank.acc_number,
                default: bank.payrequired
            })
        }
    }

    const notify = (msg) => toast(msg);
    function resetData() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    }

    //clear data on submit.
    const clearState = () => {
        setBank({ ...initialState });
    };


    useEffect(() => {
        // alert("a")
        if (props.submit?.success) {
            notify(bankMsg.message)
            resetData().then(clearState)
            props.resetCreateBankdetailsReducer()
            getAllBank()

        }
    }, [props.submit])

    useEffect(() => {
        // alert("a")
        if (props.deleteMsg?.success) {
            notify(deleteMessage.message)
            props.resetDeleteBankReducer()
            getAllBank()
            
        }
    }, [props.deleteMsg])

    useEffect(() => {
        // alert("a")
        if (props.editMsg?.success) {
            notify(editbankMsg.message)
            props.reseteditBankdetailsReducer()
            getAllBank()
            
        }
    }, [props.editMsg])


    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <div className="create-team-head-event">
                            <h5 className="dash-head">Banking Info</h5>
                            <div className="mt-3 mt-md-0 text-right">
                                <button className="btn-save" onClick={submit}>Save Changes</button>
                            </div>
                        </div>
                    </div>

                    <div className="bank-info-tab mt-4 mt-md-0">
                        <ul className="nav nav-pills mb-3 " id="pills-tab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="account-tab" data-toggle="pill" href="#account" role="tab" aria-controls="pills-home" aria-selected="true">Create Merchant Account</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Set up a Merchant account to.</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">

                                <div>
                                    <h5 className="payment text-left mt-42">Bank</h5>
                                    <div className="row">
                                        <div className="col-md-4 mt-24">
                                            <input type="text" className="form-control team-name" placeholder="Bank Name" name="bank_name" id="bank_name" value={bank.bank_name} onChange={eventHandler} />

                                            {errors.bank_name &&
                                                <span className='text-red small d-block'>* {errors.bank_name}</span>}

                                        </div>
                                        <div className="col-md-4 mt-24">
                                            <input type="text" className="form-control team-name" placeholder="Account Name"
                                                name="acc_name" id="acc_name" value={bank.acc_name} onChange={eventHandler} />


                                            {errors.acc_name &&
                                                <span className='text-red small d-block'>* {errors.acc_name}</span>}
                                        </div>
                                        <div className="col-md-4 mt-24">
                                            <input type="number" className="form-control team-name" placeholder="Account No." name="acc_number" id="acc_number" value={bank.acc_number} onChange={eventHandler} />


                                            {errors.acc_number &&
                                                <span className='text-red small d-block'>* {errors.acc_number}</span>}
                                        </div>

                                    </div>
                                    <div className="text-left mt-18">
                                        <input type="checkbox" className="mr-2  "
                                            id="payrequired"
                                            name="payrequired"
                                            value={bank.payrequired}
                                            onChange={eventHandler}
                                            checked={bank.paymentchecked}
                                            // defaultChecked={state.paymentchecked}    
                                            onClick={paycheck}
                                        // onClick={() => state.payrequired = (!state.payrequired)}

                                        />
                                        <label className="text-gray fs-13">Mark as Preferred payment method</label>



                                    </div>
                                </div>

                                <div className="mt-42 text-left">
                                    <h5 className="payment">Preferred</h5>
                                    <div className="row">
                                        {
                                            preferred &&
                                            <div className="col-md-4">
                                                <div className="d-flex justify-content-end align-items-center  event-drp">
                                                    <span className="form-control bg-preferred">{preferred.account_name}{ } {preferred.account_number.replace(/\d(?=\d{4})/g, "x")}  </span>

                                                    <i className="mdi mdi-dots-vertical ml-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ></i>

                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <Link className="dropdown-item" to={(`/editbankinfo/${preferred._id}`)}  >
                                                            <i className="mdi mdi-border-color"></i>
                                                            Edit</Link>

                                                        <Link className="dropdown-item" to="bank-info"
                                                            onClick={preferred ? () => props.deleteBankdetailsHandler(preferred._id) : "NA"}>
                                                            <i className="fas fa-trash-alt"></i>  Delete</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="mt-42 text-left">
                                    <h5 className="payment">Alternet</h5>
                                    <div className="row">
                                        {
                                            alternet ? <>
                                                {
                                                    alternet.map((v, i) => {
                                                        const acc_number = v.account_number == undefined ? "" : v.account_number.replace(/\d(?=\d{4})/g, "x")
                                                        return (

                                                            <div className="col-md-4 " key={i}>
                                                                <div className="d-flex justify-content-end align-items-center  event-drp pb-3 ">
                                                                    <span className="form-control bg-alternet">{v.account_name} {acc_number}
                                                                        {/* <i className="mdi mdi-chevron-right" ></i> */}
                                                                    </span>
                                                                    <i className="mdi mdi-dots-vertical ml-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ></i>

                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                        <Link className="dropdown-item" to={`/editbankinfo/${v._id}`}  >
                                                                            <i className="mdi mdi-border-color"></i>
                                                                            Edit</Link>

                                                                        <Link className="dropdown-item" to="/bank-info" onClick={() => props.deleteBankdetailsHandler(v._id)}
                                                                        >
                                                                            <i className="fas fa-trash-alt"></i>  Delete</Link>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    })
                                                }
                                            </> : null
                                        }

                                        {/* <div className="col-md-4 mt-4 mt-md-0">
                                            <div className="d-flex justify-content-end align-items-center  event-drp">
                                                <span className="form-control bg-alternet">Amex 9093 2323 xxxx  
                                                <i className="mdi mdi-chevron-right" ></i>
                                                </span>
                                                <i className="mdi mdi-dots-vertical ml-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ></i>

                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <Link className="dropdown-item" to={(`/event-details`)}  >
                                                        <i className="mdi mdi-border-color"></i>
                                                        Edit</Link>

                                                    <Link className="dropdown-item" to={`/event-details`}
                                                    >
                                                        <i className="fas fa-trash-alt"></i>  Delete</Link>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>


                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">...</div>
                        </div>

                    </div>
                    <Toaster />
                </div>
            </div>
        </React.Fragment>
    )
}

// export default BankInfo;

const mapStateToProp = state => ({
    submit: state.createBankDetailsReducer,
    deleteMsg: state.deleteBankReducer,
    editMsg: state.editBankReducer,

})

const mapDispatchToProps = dispatch => ({
    // createFieldhandler: user => dispatch(createField(user)),
    resetCreateBankdetailsReducer: () => dispatch({ type: RESET_BANKDETAILS }),
    reseteditBankdetailsReducer: () => dispatch({ type: RESET_EDIT_BANK }),
    resetDeleteBankReducer: () => dispatch({ type: RESET_BANK }),
    bankDetailshandler: user => dispatch(createBankDetails(user)),
    getBanksHandler: user => dispatch(getBanks(user)),
    deleteBankdetailsHandler: user => dispatch(deleteBankdetail(user))



})
export default connect(mapStateToProp, mapDispatchToProps)(BankInfo);