import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";
import { withRouter, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { connect, useSelector } from "react-redux";
// import { createSeason } from '../../service/action/userAction';
// import { RESET_CREATE_SEASON } from '../../service/constants';
import classNames from "classnames";
import {
  addteamtodivisionAction,
  createDivision,
  createPool,
  divisionCustomPaymentcode,
  getAcceptedPlayer,
  getAllDivision,
  getAllPaymentCode,
  getFreeTeam,
} from "../../service/action/userAction";
import Spinner from "../../Spinner/Spinner";
import {
  RESET_GET_DIVISIONCUSTOMPAYMENTCODE,
  RESET_GET_PAYMENTCODE,
} from "../../service/constants";

const Org_paymentCode = (props) => {
  let history = useHistory();
  const { id, id1 } = useParams();

  const [loading, setLoading] = useState(true);
  const [getalldivision, setgetalldivision] = useState();
  const [pooldata, setpooldata] = useState();
  const [getFreeTeam, setgetFreeTeam] = useState();
  const [acceptedplayer, setacceptedplayer] = useState();
  const [checkID, setcheckID] = useState([]);
  const [LatestCheckValue, setLatestCheckValue] = useState([]);

  useEffect(() => {
    props.getAllDivisionHandler({ id: id });
  }, []);

  useEffect(() => {
    props.getAcceptedPlayerhandler({ id: id });
  }, []);

  useEffect(() => {
    props.getFreeTeamHandler({ id: id });
  }, []);

  useEffect(() => {
    if (props.getFreeTeamReducer?.success) {
      const data = props.getFreeTeamReducer?.user?.data;
      setgetFreeTeam(data);
    }
  }, [props.getFreeTeamReducer]);

  useEffect(() => {
    if (props.getAllDivisionReducer?.success) {
      setLoading(false);
      const data = props.getAllDivisionReducer?.user?.data;
      setgetalldivision(data);
    }
  }, [props.getAllDivisionReducer]);

  useEffect(() => {
    if (props.playerAcceptListReducer?.success) {
      const data = props.playerAcceptListReducer?.user?.data;
      setacceptedplayer(data);
    }
  }, [props.playerAcceptListReducer]);

  useEffect(() => {
    if (getalldivision) {
      const data = getalldivision?.find((e) => e?._id === id1);
      setpooldata(data);
    }
  }, [getalldivision]);

  const [state, setState] = useState({
    team: "",
  });
  const [errors, setErrors] = useState({
    team: false,
  });

  const handleChecked = (e) => {
    // It's Magical function
    const { name, value } = e.target;
    if (checkID.includes(value)) {
      const list = [...checkID];
      const index = list.findIndex((e) => e === value);
      list.splice(index, 1);
      setcheckID(list);
    } else {
      setcheckID((pre) => [...pre, value]);
    }
  };

  // const [checked, setChecked] = useState(false)
  // const onClickCheck = () => setChecked(!checked)
  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    switch (name) {
      case "team":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            team: "team not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            team: false,
          }));
        }
        break;
      default:
    }
  };
  const notify = (msg) => toast(msg);
  const submitRequest = (e) => {
    e.preventDefault();
    if (state.team == "") {
      setErrors((prevState) => ({
        ...prevState,
        team: "team name can not be empty",
      }));
    } else {
      props.addteamtodivisionActionhandler({
        team: checkID,
        division: id1,
      });
    }
  };
  useEffect(() => {
    if (props.submit?.success) {
      history.push(`/event-details/${id}`);
      props.resetPaymentCodeReducer();
    } else if (props.submit?.error) {
      notify("Paymentcode Error");
    }
  }, [props.submit]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Add Team</h5>
          </div>
          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>
          {!loading && (
            <div>
              <div className="row mx-0">
                <div className="col-md-4 col-xl-4">
                  <div className="mt-24">
                    <div className="text-left">
                      {/* <label className="text-gray fs-13">
                        Tema name <span className="text-red fw-m">*</span>
                      </label> */}
                      {pooldata ? (
                        <>
                          <select
                            className={classNames(
                              "form-control team-name payterm p-1",
                              { error: errors.team }
                            )}
                            id="team"
                            name="team"
                            value={state.team}
                            onChange={eventHandler}
                          >
                            <option value="" disabled>
                              Pool Name
                            </option>
                            {pooldata?.pool?.map((v, i) => {
                              return (
                                <option value={v._id} key={v._id}>
                                  {v.pool_name}
                                </option>
                              );
                            })}
                          </select>

                          {errors.paymentcode && (
                            <span className="text-red small d-block">
                              * {errors.team}
                            </span>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      <div>
                        <div className="form-check ">
                          {getFreeTeam &&
                            getFreeTeam?.map((v, i) => {
                              return (
                                <div key={v?._id}>
                                  <input
                                    className=""
                                    type="checkbox"
                                    value={v._id}
                                    id={v._id}
                                    onChange={handleChecked}
                                  />
                                  <label className="ml-3" for={v._id}>
                                    {v?.team_id?.team_name}
                                  </label>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 text-left">
                      <a
                        className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={submitRequest}
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Toaster />
    </>
  );
};
// export default withRouter(Org_CreateDivision)
const mapStateToProps = (state) => ({
  getAllDivisionReducer: state.getAllDivisionReducer,
  playerAcceptListcReducer: state.playerAcceptListReducer,
  getFreeTeamReducer: state.getFreeTeamReducer,
  addteamtodivisionReducer: state.addteamtodivisionReducer,
});
const mapDispatchToProps = (dispatch) => ({
  getAllDivisionHandler: (user) => dispatch(getAllDivision(user)),
  getFreeTeamHandler: (user) => dispatch(getFreeTeam(user)),
  getAcceptedPlayerhandler: (user) => dispatch(getAcceptedPlayer(user)),
  addteamtodivisionActionhandler: (user) =>
    dispatch(addteamtodivisionAction(user)),
  resetGetPaymentCodeReducer: () => dispatch({ type: RESET_GET_PAYMENTCODE }),
  resetPaymentCodeReducer: () =>
    dispatch({ type: RESET_GET_DIVISIONCUSTOMPAYMENTCODE }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Org_paymentCode));
