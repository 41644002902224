import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import classes from "./Club_Org_eventDetails.module.css";
import { AiOutlineClockCircle } from "react-icons/ai";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "@dmuy/timepicker/dist/mdtimepicker.css";
import classNames from "classnames";
import { useSelector, connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getAllEvent } from "../../../service/action/userAction";
import Toaster from "../../../Toaster/Toaster";
import {
  addSelectedRoster,
  getClubOrgEventDetails,
  getOrgEventTeam,
} from "../../../service/action/Club/clubAction";
import Spinner from "../../../Spinner/Spinner";
import moment from "moment";
import { Modal, Table } from "react-bootstrap";
import {
  RESET_CLUB_ORG_EVENT_DETAIL,
  RESET_ADD_ROSTER,
  RESET_REGISTERED_TEAM,
} from "../../../service/constants";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import EventName from "./components/EventName";
import { convertTo12HourFormat } from "../../../helpers/convertTo12HourFormat";

const Club_Org_eventDetails = (props) => {
  const history = useHistory();
  const location = useLocation();

  // ********** for Roster Player **********
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isNextModal, setIsNextModal] = useState(true);
  const [rosterPlayer, setRosterPlayer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  const notify = (msg) => toast(msg);
  useEffect(() => {
    props.getOrgEventDetailsHandler({ id: id });
    props.getEventTeamHandler({ id: id });
  }, []);

  useEffect(() => {
    if (props.event.success) {
      setIsLoading(false);
      props.resetEvents();
    }
  }, [props.event]);

  const accountDetails = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );
  const eventDetails = useSelector(
    (state) => state.getClubOrgEventDetailsReducer.data.data
  );
  const getEventTeam = useSelector((state) => state.getEventTeamReducer.user);
  const divisionDetails = eventDetails?.event_division?.find(
    (div) => div.type === 0
  );
  const handleTeamRoster = (teamId) => {
    setModalOpen(true);
    setSelectedTeam(
      getEventTeam.club.find((team) => {
        return team._id === teamId;
      })
    );
    setRosterPlayer(
      getEventTeam.club.find((team) => {
        return team._id === teamId;
      })?.default_roster?.roster?._id
    );
  };

  const handleSelectRoster = () => {
    props.selectRoster({
      team: selectedTeam?._id,
      roster: rosterPlayer,
    });
    setSelectedTeam(null);
    setModalOpen(false);
    setRosterPlayer(null);
    setIsNextModal(true);
  };

  useEffect(() => {
    if (props.selectRosterState.success) {
      props.resetSelectRoster();
      props.getEventTeamHandler({ id: id });
    }
  }, [props.selectRosterState]);

  var getEventDetailsType1 = [];

  useEffect(() => {
    if (props.teamRegisterReducer.success) {
      notify("Team register successfully");
      props.resetRegisterTeam();
    }
  }, [props.teamRegisterReducer]);

  const user = props.event?.data?.data?.user_id?.name;

  const month = new Date(eventDetails?.event_start_date)
    .toLocaleString("default", {
      month: "short",
    })
    ?.toLocaleUpperCase();

  const day = new Date(eventDetails?.event_start_date).getUTCDate();

  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          {!isLoading ? (
            <>
              <div className="flex space-x-2">
                <div className="bg-[#76d0ee] flex flex-col justify-center items-center w-20 h-20 text-xl font-bold text-white rounded-sm">
                  <span>{month}</span>
                  <span className="text-2xl">{day}</span>
                </div>
                <div className="mt-3">
                  <span className="text-2xl font-bold">
                    {eventDetails?.event_name}
                  </span>
                  <div className="flex space-x-4">
                    <div className="flex justify-center items-center space-x-2">
                      <AiOutlineClockCircle />
                      <span>
                        <span className="text-gray-400">Start :</span>{" "}
                        <span className="font-bold">
                          {convertTo12HourFormat(
                            eventDetails?.registration_start_time
                          )}
                        </span>
                      </span>
                    </div>

                    <div className="flex justify-center items-center space-x-2">
                      <AiOutlineClockCircle />
                      <span>
                        <span className="text-gray-400">End :</span>{" "}
                        <span className="font-bold">
                          {convertTo12HourFormat(
                            eventDetails?.registration_end_time
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="mt-2 text-gray-400">{user}</div>
                <div className="text-left">
                  <div className="d-flex justify-content-end">
                    {moment(eventDetails.event_end_date) >= moment() ? (
                      <div className="reg-team mt-18">
                        <Link
                          className="text-decoration-none"
                          to={{
                            pathname: `/events/${id}/registernow`,
                            state: {
                              inviteType: 0,
                            },
                          }}
                        >
                          <i className="mdi mdi-account-multiple"></i>
                          <span>Register Teams</span>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                  {/* <div className="create-team-head-event mt-18">
                  <div className="">
                    {eventDetails.registerTeam >=
                      eventDetails.event_max_team ? (
                      <div className="py-1 px-2 border rounded bg-gray">
                        <i className="mdi mdi-circle text-red mr-2"></i>
                        <span className="fw-m">Event is Full</span>
                      </div>
                    ) : null}
                  </div>
                </div> */}
                </div>
              </div>

              <div className="team-details mt-4 mt-md-0">
                <ul
                  className="nav nav-pills mb-3 flex-wrap"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item  mt-2" role="presentation">
                    <a
                      className="nav-link active"
                      id="details"
                      data-toggle="pill"
                      href="#detail-tab"
                      role="tab"
                      aria-controls="pills-details"
                      aria-selected="true"
                    >
                      Details
                    </a>
                  </li>
                  <li className="nav-item mt-2" role="presentation">
                    <a
                      className="nav-link"
                      id="teams"
                      data-toggle="pill"
                      href="#team-tab"
                      role="tab"
                      aria-controls="pills-teams"
                      aria-selected="false"
                    >
                      Registered Teams
                    </a>
                  </li>
                  <li className="nav-item mt-2" role="presentation">
                    <a
                      className="nav-link"
                      id="division"
                      data-toggle="pill"
                      href="#division-tab"
                      role="tab"
                      aria-controls="pills-division"
                      aria-selected="false"
                    >
                      Divisions
                    </a>
                  </li>
                  {eventDetails?.show_schedule === 1 ? (
                    <>
                      {" "}
                      <li className="nav-item mt-2" role="presentation">
                        <a
                          className="nav-link"
                          id="schedule"
                          data-toggle="pill"
                          href="#schedule-tab"
                          role="tab"
                          aria-controls="pills-schedule"
                          aria-selected="false"
                        >
                          Schedule
                        </a>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>

                <div
                  className="tab-content md:max-w-[40vw] w-screen"
                  id="pills-tabContent"
                >
                  {/* <<----------------------------   Details  Start  --------------------------->> */}
                  <div
                    className="tab-pane fade show active"
                    id="detail-tab"
                    role="tabpanel"
                    aria-labelledby="pills-details"
                  >
                    <div className="mt-30">
                      <div className="divide-y divide-[#5eaec7]">
                        <EventName
                          name="Event Name"
                          value={eventDetails.event_name}
                        />

                        <EventName
                          name={"Event Status"}
                          value={
                            moment(eventDetails.event_end_date) < moment()
                              ? "Closed"
                              : "Open"
                          }
                        />

                        <EventName
                          name={"Event Type"}
                          value={
                            eventDetails.event_type == 0
                              ? "Club only"
                              : eventDetails.event_type == 1
                              ? "parent/player"
                              : eventDetails.event_type == 2
                              ? "Club & Parent"
                              : ""
                          }
                        />

                        <EventName
                          name={"Start Date"}
                          value={moment(eventDetails.event_start_date).format(
                            "MM-DD-yyyy"
                          )}
                        />
                        <EventName
                          name={"End Date"}
                          value={moment(eventDetails.event_end_date).format(
                            "MM-DD-yyyy"
                          )}
                        />
                        <EventName
                          name={"Start Time"}
                          value={eventDetails.event_from_time}
                        />
                        <EventName
                          name={"End Time"}
                          value={eventDetails.event_to_time}
                        />
                        <EventName
                          name={"Registration Start Date"}
                          value={moment(
                            eventDetails.registration_start_date
                          ).format("MM-DD-yyyy")}
                        />
                        <EventName
                          name={"Registration End Date"}
                          value={moment(
                            eventDetails.registration_end_date
                          ).format("MM-DD-yyyy")}
                        />
                        <EventName
                          name={"Registration Start Time"}
                          value={convertTo12HourFormat(
                            eventDetails.registration_start_time
                          )}
                        />
                        <EventName
                          name={"Registration Start Time"}
                          value={convertTo12HourFormat(
                            eventDetails.registration_end_time
                          )}
                        />
                        {eventDetails.event_facility?.map((x, i) => {
                          return (
                            <React.Fragment key={x._id}>
                              <div
                                className="d-flex justify-content-between"
                                key={x._id}
                              >
                                <h4 className="text-left mt-30">Facility</h4>
                              </div>

                              <EventName
                                name={"Facility Name"}
                                value={x.facility_name}
                              />
                              <EventName
                                name={"Facility Address"}
                                value={x.facility_address}
                              />

                              {/* <div className="mt-3">
                              <p className="text-left text-gray mb-1 fs-13">
                                Facility Website
                              </p>
                              <h6 className="text-left">
                                {x.facility_website}
                              </h6>
                            </div> */}
                              {/* <div className="">
                                <div className="">
                                  <p className="text-left text-gray fs-13 mb-1">
                                    Facility Website
                                  </p>
                                  {x.facility_website ? (
                                    <a
                                      className="text-dark text-decoration-none"
                                      href={x.facility_website}
                                      style={{
                                        fontWeight: "bold",
                                        color: "#143D4A",
                                      }}
                                    >
                                      <h6 className="text-left">
                                        {x.facility_website}
                                      </h6>
                                    </a>
                                  ) : (
                                    <h6 className="text-left">NA</h6>
                                  )}
                                </div>
                              </div> */}

                              <EventName
                                name={"Facility Website"}
                                value={x?.facility_website}
                              />
                            </React.Fragment>
                          );
                        })}
                      </div>

                      <div className="mt-4">
                        <div className="d-flex align-items-center mb-2">
                          <p className="text-left text-gray mb-0 fs-14">
                            Max Teams :{" "}
                          </p>
                          <h6 className="text-left ml-1 m-0">
                            {eventDetails?.infinity_max_team ? (
                              <strong>Unlimited</strong>
                            ) : (
                              eventDetails.event_max_team
                            )}
                          </h6>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="text-left text-gray mb-0 fs-14">
                            Max Players :{" "}
                          </p>
                          <h6 className="text-left ml-1 m-0">
                            {eventDetails?.infinity_max_player ? (
                              <strong>Unlimited</strong>
                            ) : (
                              eventDetails.event_max_player
                            )}
                          </h6>
                        </div>
                      </div>

                      <div className="">
                        <div className="d-flex justify-content-between">
                          <h4 className="text-left mt-30">Contact Person</h4>
                        </div>
                        {eventDetails.contact_person?.length !== 0 ? (
                          <>
                            {eventDetails.contact_person?.map((person) => {
                              return (
                                <div
                                  className="d-flex align-items-center mt-3"
                                  key={person._id}
                                >
                                  <div className="dashboard-img">
                                    <img
                                      src={
                                        person?.user_id?.profile_image !== ""
                                          ? person?.user_id?.profile_image
                                          : !!accountDetails &&
                                            accountDetails?.profile_image !== ""
                                          ? accountDetails.profile_image
                                          : `images/pch-images/PCH_Logo.png`
                                      }
                                    />
                                  </div>
                                  <div className="d-flex justify-content-between justify-content-sm-start flex-grow-1 flex-wrap ml-12">
                                    <h5 className="mb-0">
                                      {person?.user_id?.name}
                                    </h5>
                                    <Link
                                      className="d-block text-blue text-decoration-none ml-12"
                                      to={`/chat/${person._id}/`}
                                    >
                                      <i className="mdi mdi-message-text"></i>
                                      <span className="text-blue ml-2">
                                        Messages
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          "No contact person found"
                        )}
                      </div>
                    </div>
                    {/* <Toaster /> */}
                  </div>

                  {/* <<<<<<----------------------------   Details End  ---------------------------->>>>>> */}

                  {/* <<<<<<----------------------------   Team Start  ---------------------------->>>>>> */}

                  <div
                    className="tab-pane fade"
                    id="team-tab"
                    role="tabpanel"
                    aria-labelledby="pills-teams"
                  >
                    <div className="mt-30">
                      <div className="d-flex justify-content-between">
                        {/* <h4 className="text-left mb-3">Teams</h4> */}
                      </div>

                      {/* Desktop Section */}

                      <div className="table-responsive d-md-block d-none">
                        {getEventTeam?.club?.length === 0 ? (
                          <>
                            <div className="text-grat text-center mt-5">
                              Teams is not available
                            </div>
                          </>
                        ) : (
                          <table className="table event-table register-table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Registration</th>
                                <th>Payment</th>
                                <th>Player</th>
                                <th>Roster</th>
                              </tr>
                            </thead>
                            <tbody>
                              {getEventTeam?.club?.map((team, i) => {
                                const { default_roster } = team;
                                const {
                                  team_name,
                                  player,
                                  roster,
                                  image,
                                  _id,
                                } = team.team;
                                const { panding_amount, registrationStatus } =
                                  team;
                                return (
                                  <tr key={team._id}>
                                    <td>{i + 1}</td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="dashboard-img">
                                          <img
                                            src={
                                              image !== ""
                                                ? image
                                                : `images/pch-images/PCH_Logo.png`
                                            }
                                          />
                                        </div>
                                        <Link
                                          className="ml-12 text-decoration-none heading register-table filter-string"
                                          // onClick={() => { eventData(_id, event_facility)  }}
                                          to={`/teams/${_id}`}
                                        >
                                          {team_name}
                                        </Link>
                                      </div>
                                    </td>
                                    <td>
                                      {registrationStatus === 1
                                        ? "Registered"
                                        : "Not Register"}
                                    </td>
                                    <td>
                                      {panding_amount > 0
                                        ? "Partially Paid"
                                        : "Unpaid"}
                                    </td>
                                    <td>{player.length}</td>
                                    {/* <div className="d-flex align-items-center mb-1 flex-wrap"> */}
                                    {/* <p className="text-left text-gray mb-0 fs-14">
                                        Roster :{" "}
                                      </p> */}
                                    <td>
                                      <p
                                        className="text-left text-gray mb-0 fs-14 ml-2 text-blue pointer"
                                        onClick={() =>
                                          handleTeamRoster(team._id)
                                        }
                                      >
                                        {default_roster
                                          ? default_roster?.roster.roster_name
                                          : "N/A"}
                                      </p>
                                    </td>
                                    {/* </div> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                      </div>

                      {/* Mobile Section */}

                      <div className="d-md-none">
                        <div>
                          {getEventTeam?.club?.length === 0 ? (
                            <>
                              <div className="text-grat text-center">
                                {" "}
                                Teams is not available
                              </div>
                            </>
                          ) : (
                            <>
                              {getEventTeam.club &&
                                getEventTeam.club.map((team) => {
                                  const {
                                    team_name,
                                    player,
                                    roster,
                                    image,
                                    _id,
                                  } = team.team;
                                  const { panding_amount, registrationStatus } =
                                    team;
                                  return (
                                    <div
                                      className="d-flex justify-content-between reg-details"
                                      key={team._id}
                                    >
                                      <div className="d-flex">
                                        <div className="dashboard-img mr-3">
                                          <img
                                            src={
                                              image !== ""
                                                ? image
                                                : `${accountDetails?.profile_image}`
                                            }
                                          />
                                        </div>
                                        <div className="reg-content">
                                          <Link
                                            className="text-decoration-none text-black fw-m"
                                            to={`/teams/${_id}`}
                                          >
                                            {team_name}
                                          </Link>
                                          <div className="d-flex align-items-center mb-1 flex-wrap">
                                            <p className="text-left text-gray mb-0 fs-14">
                                              Registration :{" "}
                                            </p>
                                            <p className="text-left text-gray mb-0 fs-14 ml-2">
                                              {registrationStatus === 1
                                                ? "Registered"
                                                : ""}
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center mb-1 flex-wrap">
                                            <p className="text-left text-gray mb-0 fs-14">
                                              Payment :{" "}
                                            </p>
                                            <p className="text-left text-gray mb-0 fs-14 ml-2">
                                              {panding_amount > 0
                                                ? "Partially Paid"
                                                : ""}
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center mb-1 flex-wrap">
                                            <p className="text-left text-gray mb-0 fs-14">
                                              Player :{" "}
                                            </p>
                                            <p className="text-left text-gray mb-0 fs-14 ml-2">
                                              {player.length}
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center mb-1 flex-wrap">
                                            <p className="text-left text-gray mb-0 fs-14">
                                              Roster :{" "}
                                            </p>
                                            <p
                                              className="text-left text-gray mb-0 fs-14 ml-2 text-blue pointer"
                                              onClick={() =>
                                                handleTeamRoster(team._id)
                                              }
                                            >
                                              {roster
                                                ? roster.roster_name
                                                : "N/A"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <<<<<<----------------------------   Team End  ---------------------------->>>>>> */}

                  {/* <<<<<<----------------------------   Division Start  ---------------------------->>>>>> */}

                  <div
                    className="tab-pane fade"
                    id="division-tab"
                    role="tabpanel"
                    aria-labelledby="pills-division"
                  >
                    <div className="mt-30">
                      {eventDetails &&
                      eventDetails.event_division.length !== 0 ? (
                        <>
                          {eventDetails.event_division.map((v, i) => {
                            if (v.type === 0) {
                              getEventDetailsType1.push(v);
                            }
                          })}

                          {getEventDetailsType1 &&
                          getEventDetailsType1.length !== 0 ? (
                            <>
                              <Table className="table event-table">
                                <div
                                  className="mb-4"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Division
                                </div>
                                {getEventDetailsType1?.map((v, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <div>
                                        <p>Division Name</p>
                                        <p className="noti-type">
                                          {v?.division_name}
                                        </p>
                                      </div>
                                      <p>Pools</p>
                                      <div className="noti-type">
                                        {v?.pool && v?.pool?.length !== 0 ? (
                                          <>
                                            {" "}
                                            <select
                                              className={classNames(
                                                "form-control w-25 d-sm-block d-none"
                                              )}
                                              name="pools"
                                            >
                                              {v?.pool?.map((v, i) => {
                                                return (
                                                  <option
                                                    value={v?._id}
                                                    key={i}
                                                  >
                                                    {v?.pool_name}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          </>
                                        ) : (
                                          <>
                                            <div className="d-flex justify-content-center noti-type">
                                              No pools to display
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </React.Fragment>
                                  );
                                })}
                              </Table>
                            </>
                          ) : (
                            <>
                              <div className={classes.division}>
                                <span className="noti-type">
                                  No divisions to display
                                </span>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <div className={classes.division}>
                          <h2>Devision</h2>
                          <div>No divisions to display</div>
                        </div>
                      )}
                      {/* <div className="d-flex justify-content-between">
                        <h4 className="text-left mb-3">Division</h4>
                      </div>
                      {divisionDetails ? (
                        <>
                          <div>
                            <div className="mt-3">
                              <p className="text-left text-gray mb-1 fs-13">
                                Division Name
                              </p>
                              <h6 className="text-left">
                                {divisionDetails.division_name}
                              </h6>
                            </div>
                          </div>
                          <div>
                            <div className="mt-3 ">
                              <p className="text-left text-gray mb-1 fs-13">
                                Pools
                              </p>
                              Display Section
                              {divisionDetails?.pool.length !== 0 ? (
                                <>
                                  <select
                                    className={classNames(
                                      "form-control w-25 d-sm-block d-none"
                                    )}
                                    name="pools"
                                  >
                                    {divisionDetails.pool.map((pool, i) => {

                                      return (
                                        <option value={pool._id} key={i}>
                                          {pool.pool_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </>
                              ) : (
                                <>
                                  <div className="d-flex justify-content-center noti-type">
                                    No pools to display
                                  </div>
                                </>
                              )}
                              mobile Section
                              <select
                                className={classNames(
                                  "form-control w-100 d-sm-none"
                                )}
                                name="pools"
                              >
                                {divisionDetails.pool.map((pool, i) => {
                                  return (
                                    <option value={pool._id} key={i}>
                                      {pool.pool_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="text-grat text-center">
                          {" "}
                          No Division to Display
                        </div>
                      )} */}
                    </div>
                  </div>
                  {/* <<<<<<----------------------------   Division End  ---------------------------->>>>>> */}

                  {/* <<<<<<<-------------------------Schedule Start--------------------------------->>>>>> */}

                  <div
                    className="tab-pane fade"
                    id="schedule-tab"
                    role="tabpanel"
                    aria-labelledby="pills-schedule"
                  >
                    {eventDetails.event_schedule.length === 0 ? (
                      <div className="text-grat text-center mt-5">
                        {" "}
                        No schedule to display
                      </div>
                    ) : (
                      <div className="mt-4">
                        {eventDetails.event_schedule.map((v, i) => {
                          return (
                            <div key={i}>
                              <a
                                className="text-decoration-none text-black fw-m mt-2 pointer"
                                href={v.file}
                                target={"_blank"}
                              >
                                {v.file !== "" ? "Schedule Document" : ""}
                              </a>
                              <a
                                className="text-decoration-none text-black fw-m mt-2 pointer"
                                href={v.text}
                                target={"_blank"}
                              >
                                {v.text !== "" ? v.text : ""}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  {/* <<<<<<<-------------------------Schedule End--------------------------------->>>>>> */}
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          )}
          <Toaster />
        </div>
      </div>
      {modalOpen && (
        <Modal show={modalOpen} centered backdrop="static">
          <div className="p-4" style={{ backgroundColor: "#ffffff" }}>
            <div className="d-flex justify-content-between">
              <h3
                className="text-blue fs-w pointer"
                onClick={() => {
                  if (isNextModal) {
                    setModalOpen(false);
                    setSelectedTeam(null);
                  } else {
                    setIsNextModal(true);
                  }
                }}
              >
                <i className="mdi mdi-chevron-left"></i>
              </h3>
              {isNextModal ? (
                <div
                  className="text-blue fs-w pointer"
                  onClick={() => setIsNextModal(false)}
                >
                  Next
                </div>
              ) : (
                <h3
                  className="text-blue fs-w pointer"
                  onClick={() => handleSelectRoster()}
                >
                  <i className="mdi mdi-check"></i>
                </h3>
              )}
            </div>
            {isNextModal ? (
              <div>
                <h4 className="text-center mb-3">Players</h4>
                <div style={{ maxHeight: "350px", overflow: "auto" }}>
                  {selectedTeam?.team?.roster_player?.map((player) => {
                    return (
                      <div key={player._id} className="border-bottom mb-2">
                        <div className="mr-4 mb-2">
                          <div className="d-flex justify-content-between">
                            <div className="fw-m">{player.roster_name}</div>
                            <div className="d-flex">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id={player._id}
                                checked={player._id === rosterPlayer}
                                onChange={() => setRosterPlayer(player._id)}
                              />
                              <label
                                htmlFor={player._id}
                                className="mb-0 p-0"
                                style={{ marginTop: "4px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div>
                <h4 className="text-center">Players</h4>
                {selectedTeam?.team?.roster_player.find(
                  (e) => e._id === rosterPlayer
                )?.player?.length !== 0 ? (
                  selectedTeam?.team?.roster_player
                    ?.find((e) => e._id === rosterPlayer)
                    ?.player?.map((player) => {
                      return (
                        <div key={player._id} className="border-bottom mb-2">
                          <div className="mr-4 mb-2">
                            <div className="d-flex justify-content-between">
                              <div className="fw-m">{`${player.player.first_name} ${player.player.last_name}`}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <div
                    className="mt-2 text-gray d-flex justify-content-center align-items-center"
                    style={{ height: "50px" }}
                  >
                    No Player found
                  </div>
                )}
              </div>
            )}

            <Modal.Footer className="mt-2 justify-content-between border-top-0">
              <h4
                onClick={() => {
                  setModalOpen(false);
                  setSelectedTeam(null);
                  setIsNextModal(true);
                }}
                className="text-blue border-0 pointer"
              >
                Cancel
              </h4>
              <h4
                onClick={() => {
                  history.push(`/addroster/${selectedTeam?.team._id}`);
                  setSelectedTeam(null);
                  setIsNextModal(false);
                }}
                className="text-blue border-0 pointer h4"
              >
                Create Roster
              </h4>
            </Modal.Footer>
          </div>
        </Modal>
      )}
      <Toaster />
    </React.Fragment>
  );
};

// export default withRouter(Org_eventDetails);

const mapStateToProps = (state) => ({
  event: state.getClubOrgEventDetailsReducer,
  selectRosterState: state.addSelectedRosterReducer,
  teamRegisterReducer: state.registerTeamReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getEventHandler: (user) => dispatch(getAllEvent(user)),
  getEventTeamHandler: (user) => dispatch(getOrgEventTeam(user)),
  getOrgEventDetailsHandler: (user) => dispatch(getClubOrgEventDetails(user)),
  selectRoster: (user) => dispatch(addSelectedRoster(user)),
  resetSelectRoster: () => dispatch({ type: RESET_ADD_ROSTER }),
  resetEvents: () => dispatch({ type: RESET_CLUB_ORG_EVENT_DETAIL }),
  resetRegisterTeam: () => dispatch({ type: RESET_REGISTERED_TEAM }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Club_Org_eventDetails));
