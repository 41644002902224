import {GET_ORG_CLUB_ERROR, GET_ORG_CLUB_LOADING, GET_ORG_CLUB_SUCCESS, RESET_ORG_CLUB } from "../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false
};

export default function getOrgClubListReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORG_CLUB_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false,success:false};
     
    case GET_ORG_CLUB_SUCCESS:  
      // alert(" reducer Error")
      return { ...state, loading: false ,success:true,user:action.payload};
 
    case GET_ORG_CLUB_ERROR:
      // alert(" reducer Loading")
      return { ...state,error: true, errors: action.errors };

    case RESET_ORG_CLUB:
      return {...state,error:false ,success:false , loading :false,reset:true}
    default:
      return state;
  }
}


