import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";
import { connect } from "react-redux";
import { AdminInvite, getAllUsersList } from "../../service/action/userAction";

function Org_InviteAdmin(props) {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [userList, setUserList] = useState([]);

  const [error, setError] = useState(false);
  const [emailsList, setEmailsList] = useState([]);

  const notify = (msg) => toast(msg);
  const eventHandler = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    props.getAllUsersList();
  }, []);

  useEffect(() => {
    if (props.getAllUsersListReducer.success) {
      const data = props.getAllUsersListReducer.data;
      setUserList(data.data);
    }
  }, [props.getAllUsersListReducer]);

  const addEmails = () => {
    if (email === "") {
      notify("Please Check Email");
    } else {
      const validEmailRegex = RegExp(
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      );
      const list = email.split(/,|, /);
      const isValid = list.map((v) => {
        if (!validEmailRegex.test(v.trim())) {
          setError("Email is not valid !");
          return false;
        } else if (list.length === 0 || v.trim().length === 0) {
          setError("Email can not be empty");
          return false;
        } else {
          setError(false);
          return true;
        }
      });
      if (isValid.every((v) => v === true)) {
        setEmailsList([
          ...new Set([...emailsList, ...list.map((e) => e.trim())]),
        ]);
        setEmail("");
      }
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
  };

  const handleRemoveEmail = (index) => {
    const list = [...emailsList];
    list.splice(index, 1);
    setEmailsList([...list]);
  };

  useEffect(() => {
    if (props.getAllUsersListReducer.success) {
      if (searchVal !== "") {
        const data = props.getAllUsersListReducer?.data;
        const orgFilter = data.data.organizer.filter(
          (e) =>
            e.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.email.toLowerCase().includes(searchVal.toLowerCase())
        );
        const clubFilter = data.data.club.filter(
          (e) =>
            e.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.email.toLowerCase().includes(searchVal.toLowerCase())
        );
        const coachFilter = data.data.coach.filter(
          (e) =>
            e.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.email.toLowerCase().includes(searchVal.toLowerCase())
        );
        const parentFilter = data.data.parent.filter(
          (e) =>
            e.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.email.toLowerCase().includes(searchVal.toLowerCase())
        );
        const playerFilter = data.data.player.filter(
          (e) =>
            e.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.email.toLowerCase().includes(searchVal.toLowerCase())
        );
        const staffFilter = data.data.staff.filter(
          (e) =>
            e.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.email.toLowerCase().includes(searchVal.toLowerCase())
        );
        const filter = {
          organizer: [...orgFilter],
          club: [...clubFilter],
          coach: [...coachFilter],
          parent: [...parentFilter],
          player: [...playerFilter],
          staff: [...staffFilter],
        };
        setUserList(filter);
      } else {
        const data = props.getAllUsersListReducer?.data;
        setUserList(data?.data);
      }
    } else {
      setUserList([]);
    }
  }, [searchVal]);

  const handleInvaite = (id, type) => {
    props.AdminInvite({
      user: id,
      type: type,
    });
  };

  useEffect(() => {
    if (props.invaiteAdminReducer.success) {
      toast("Invitation send Succesfully");
    }
  }, [props.invaiteAdminReducer]);
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Invite Admin</h5>
          </div>
          <div className="w-md-75 w-100 p-2 border">
            <div className="d-flex justify-content-between align-items-center">
              <div className="flex-grow-1 d-flex align-items-center">
                <i className="mdi mdi-email mx-2"></i>
                <input
                  type="email"
                  id="email"
                  className="border-0 p-1 flex-grow-1 w-100"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => eventHandler(e)}
                />
              </div>
              <div className="mx-2">
                <span
                  className="send-btn pointer text-info"
                  onClick={sendEmail}
                >
                  Send
                </span>
              </div>
            </div>
          </div>
          {error && <span className="text-red small d-block">* {error}</span>}

          <div className="mt-3 fw-m text-red pointer" onClick={addEmails}>
            Add Email
          </div>
          <div>
            <div className="table-responsive">
              <table className="table event-table mt-30">
                <thead>
                  <tr>
                    <th className="py-1">Emails</th>
                    <th className="py-1"></th>
                  </tr>
                </thead>

                {emailsList.length === 0 ? (
                  <>
                    <div className="mt-2">No Emails Added</div>
                  </>
                ) : (
                  <>
                    <tbody className="mt-2">
                      {emailsList.map((email, i) => {
                        return (
                          <tr key={i}>
                            <td className="py-1">{email}</td>
                            <td className="py-1">
                              <span onClick={() => handleRemoveEmail(i)}>
                                <i
                                  className="mdi mdi-close pointer"
                                  style={{ fontSize: "20px", color: "#198AE3" }}
                                ></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                )}
              </table>
            </div>
          </div>
          <hr />
          <div>
            <div>
              <i className="fa fa-copy mr-2"></i>
              Copy invite link
            </div>

            <div className="w-md-75 w-100 p-2 border mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <div className="flex-grow-1 d-flex align-items-center">
                  <i className="mdi mdi-magnify mx-2"></i>
                  <input
                    type="text"
                    className="border-0 p-1 flex-grow-1 w-100"
                    name="searchVal"
                    placeholder="Search"
                    value={searchVal}
                    onChange={(e) => {
                      setSearchVal(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <>
              {userList.length == 0 ? (
                <div className="mt-4 text-center">
                  <strong>No List Available </strong>
                </div>
              ) : (
                <>
                  <div className="mt-3">
                    {userList.organizer.map((e, i) => {
                      return (
                        <div key={i}>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="mb-2">
                              <strong>{e.name}</strong>
                              <div>{e.email}</div>
                            </div>
                            <div
                              className="pointer"
                              onClick={() => handleInvaite(e._id, 1)}
                            >
                              <i className="mdi mdi-plus mr-2 text-blue"></i>
                              <strong style={{ color: "#189AD6" }}>
                                Invite
                              </strong>
                            </div>
                          </div>
                          <hr className="my-1" />
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-3">
                    {userList.club.map((e, i) => {
                      return (
                        <div key={i}>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="mb-2">
                              <strong>{e.name}</strong>
                              <div>{e.email}</div>
                            </div>
                            <div
                              className="pointer"
                              onClick={() => handleInvaite(e._id, 4)}
                            >
                              <i className="mdi mdi-plus mr-2 text-blue"></i>
                              <strong style={{ color: "#189AD6" }}>
                                Invite
                              </strong>
                            </div>
                          </div>
                          <hr className="my-1" />
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-3">
                    {userList.coach.map((e, i) => {
                      return (
                        <div key={i}>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="mb-2">
                              <strong>{e.name}</strong>
                              <div>{e.email}</div>
                            </div>
                            <div
                              className="pointer"
                              onClick={() => handleInvaite(e._id, 3)}
                            >
                              <i className="mdi mdi-plus mr-2 text-blue"></i>
                              <strong style={{ color: "#189AD6" }}>
                                Invite
                              </strong>
                            </div>
                          </div>
                          <hr className="my-1" />
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-3">
                    {userList.staff.map((e, i) => {
                      return (
                        <div key={i}>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="mb-2">
                              <strong>{e.name}</strong>
                              <div>{e.email}</div>
                            </div>
                            <div
                              className="pointer"
                              onClick={() => handleInvaite(e._id, 2)}
                            >
                              <i className="mdi mdi-plus mr-2 text-blue"></i>
                              <strong style={{ color: "#189AD6" }}>
                                Invite
                              </strong>
                            </div>
                          </div>
                          <hr className="my-1" />
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-3">
                    {userList.parent.map((e, i) => {
                      return (
                        <div key={i}>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="mb-2">
                              <strong>{e.name}</strong>
                              <div>{e.email}</div>
                            </div>
                            <div
                              className="pointer"
                              onClick={() => handleInvaite(e._id, 6)}
                            >
                              <i className="mdi mdi-plus mr-2 text-blue"></i>
                              <strong style={{ color: "#189AD6" }}>
                                Invite
                              </strong>
                            </div>
                          </div>
                          <hr className="my-1" />
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-3">
                    {userList.player.map((e, i) => {
                      return (
                        <div key={i}>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="mb-2">
                              <strong>{e.name}</strong>
                              <div>{e.email}</div>
                            </div>
                            <div
                              className="pointer"
                              onClick={() => handleInvaite(e._id, 5)}
                            >
                              <i className="mdi mdi-plus mr-2 text-blue"></i>
                              <strong style={{ color: "#189AD6" }}>
                                Invite
                              </strong>
                            </div>
                          </div>
                          <hr className="my-1" />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      </div>
      <Toaster />
    </React.Fragment>
  );
}

const mapStateToProp = (state) => ({
  getAllUsersListReducer: state.OrgPermissionReducer.getAllUsersList,
  invaiteAdminReducer: state.OrgPermissionReducer.invaiteAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  getAllUsersList: (details) => dispatch(getAllUsersList(details)),
  AdminInvite: (details) => dispatch(AdminInvite(details)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Org_InviteAdmin));
