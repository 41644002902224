import { RESET_UPDATE_PROFILE_IMAGE, UPDATE_PROFILE_IMAGE_ERROR, UPDATE_PROFILE_IMAGE_LOADING, UPDATE_PROFILE_IMAGE_SUCCESS } from "../../../constants";

let initialState={
    error:false,
    success:false,
    loading:false,
    errors:[],
    msg: ''
}

export default function updateProfileImageReducer(state=initialState,action){
    switch(action.type){
        case UPDATE_PROFILE_IMAGE_LOADING:
            return{...state,error:false,loading:true,success:false};
        case UPDATE_PROFILE_IMAGE_SUCCESS:
            return{...state,error:false,success:true,loading:false, msg: action.msg};
        case UPDATE_PROFILE_IMAGE_ERROR:
            return{...state,error:true,success:false,loading:false};
        case RESET_UPDATE_PROFILE_IMAGE:
            return{...state,error:false,success:false,loading:false};
    
        default:
            return state;
    }
}