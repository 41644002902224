import React, { useState, useEffect } from "react";
import { Link, useHistory, Redirect, useParams } from "react-router-dom";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Toaster from "../Toaster/Toaster";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { reset_Url } from "../apiSheet";

const Login = (props) => {
  const { id } = useParams();
  if (id !== undefined) {
    sessionStorage.setItem("link", `/registernow/${id}`);
  } else {
    sessionStorage.setItem("link", `/dashboard`);
  }

  const eye = {
    position: "relative",
  };
  const [show, setShow] = useState(false);
  const notify = (msg) => toast(msg);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    emailEr: false,
    passwordEr: false,
  });

  const resetMsg = useSelector((state) => state.resetPasswordReducer.message);
  const networkError = useSelector((state) => state.signinReducer);
  const signin = (e) => {
    // e.preventDefault();
    if (state.email == "") {
      setErrors((prevState) => ({
        ...prevState,
        emailEr: "Email can not be empty",
      }));
    }

    if (state.password == "") {
      setErrors((prevState) => ({
        ...prevState,
        passwordEr: "Password can not be empty",
      }));
    }

    if (state.email !== "" && state.password !== "") {
      //status = 1 = club, status =0 = organizer
      // sessionStorage.setItem("status",0);
      // history.push("/dashboard")
      props.signInHandler({
        email: state.email,
        password: state.password,
      });
    }
  };

  const resend = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      url: reset_Url,
      data: { email: state.email },
    };
    axios(requestOptions)
      .then((resp) => {
        notify(resp.data.message);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (props.signin?.success) {
      // props.resetSignin()
      // localStorage.setItem("token",props.token)
      localStorage.setItem("status", props.orgStatus);
      localStorage.setItem("club", props.clubStatus);
      localStorage.setItem("auth", props.auth);
      if (props.signin?.info?.data?.is_login_user == 0) {
        history.push("/switch");
      } else {
        history.push("/modifiedpassword");
      }
      // return <Redirect to="/switch"  notify="SignIn successfully"/>
    } else if (props.signin?.error) {
      setLoading(false);
      if (networkError.neterror) {
        if (networkError.neterror.message) {
          notify(networkError.neterror.message);
        }
      } else if (networkError.errors) {
        if (networkError.errors.password) {
          notify("Invalid Credential");
        } else if (networkError.errors.email) {
          notify(networkError.errors.email);
        }
      } else if (networkError.errors) {
        notify(networkError.errors?.email);
      } else if (networkError.errors?.email) {
        notify(networkError.errors?.email);
      }
    } else if (props.signin?.emailError) {
      setLoading(false);
      if (networkError.verifyError.data.message) {
        notify(networkError.verifyError.data.message);
        setFlashMsg(true);
        props.resetSigninReducer();
        // setTimeout(() => {
        // setFlashMsg(false)
        // },5000);
      }
    }
  }, [props.signin]);

  useEffect(() => {
    if (props.reset?.success) {
      notify(resetMsg.message);
      props.resetPasswordHandler();
    }
  }, []);

  useEffect(() => {
    if (props.signin?.loading) {
      setLoading(true);
    } else if (props.signin?.success) {
      setLoading(false);
    }
  }, [props.signin]);

  const [flashMsg, setFlashMsg] = useState(false);
  useEffect(() => {
    if (props.signup?.success) {
      notify("Register successfully");
      setFlashMsg(true);
      props.resetSignup();
      // return <Redirect to="/login" />;
    }
  }, [props.signup]);

  useEffect(() => {
    if (props.signUpByMail?.success) {
      notify("Register successfull");
      props.resetSignupBymailReducer();
    }
  }, [props.signUpByMail]);

  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    switch (name) {
      case "email":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            emailEr: "Email can not be empty",
          }));
        } else if (!validEmailRegex.test(value)) {
          setErrors((prevState) => ({
            ...prevState,
            emailEr: "Email is not valid !",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            emailEr: false,
          }));
        }
        // setEmailVal(false);
        break;
      case "password":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            passwordEr: "Password can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            passwordEr: false,
          }));
        }
        break;
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex auth login-section">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left p-5">
                <div className="brand-logo text-center">
                  <img
                    src="./images/pch-images/PCH_Logo_Hori_Blue Blue-white.svg"
                    alt="logo"
                  />
                </div>
                <h6 className="text-white">Sign In</h6>
                <form className="pt-3">
                  <div className="form-group">
                    <input
                      type="email"
                      className={classNames("form-control form-control-lg", {
                        "login-error": errors.emailEr,
                      })}
                      id="email"
                      name="email"
                      value={state.email}
                      placeholder="Email *"
                      required
                      onChange={eventHandler}
                      autoComplete="off"
                    />
                    {errors.emailEr && (
                      <span className="text-danger small d-block">
                        * {errors.emailEr}
                      </span>
                    )}
                  </div>
                  <div className="input-group form-group pass-wrapper ">
                    <input
                      type={show ? "text" : "password"}
                      className={classNames(
                        "form-control form-control-lg w-100 position-relative",
                        { "login-error": errors.passwordEr }
                      )}
                      id="password"
                      name="password"
                      placeholder="Password *"
                      value={state.password}
                      required
                      onChange={eventHandler}
                      aria-label="Password"
                      style={{ zIndex: 0 }}
                      aria-describedby="button-addon2"
                      autoComplete="off"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          signin(e);
                        }
                      }}
                    />
                    {errors.passwordEr && (
                      <span className="text-danger small d-block">
                        * {errors.passwordEr}
                      </span>
                    )}

                    <div
                      className="bg-transparent mr-2 pointer"
                      style={{
                        position: "absolute",
                        top: "18px",
                        right: "5px",
                      }}
                    >
                      {show ? (
                        <i
                          className="fas fa-eye-slash eye text-white"
                          aria-describedby="button-addon2"
                          onClick={(e) => {
                            setShow(!show);
                          }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-eye eye text-white"
                          aria-describedby="button-addon2"
                          onClick={(e) => {
                            setShow(!show);
                          }}
                        ></i>
                      )}
                    </div>
                  </div>

                  <div className="mb-2 d-md-flex justify-content-between align-items-center">
                    <div className="form-check my-0">
                      <label className="form-check-label text-white">
                        <input type="checkbox" className="form-check-input" />{" "}
                        Remember Me
                        <i className="input-helper"></i>
                      </label>
                    </div>
                    <Link
                      to="/forgotpassword"
                      className="auth-link text-white fw-m d-block text-right"
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <div className="mt-3 text-center">
                    <button
                      type="submit"
                      className="btn btn-login btn-lg font-weight-medium auth-form-btn"
                      onClick={signin}
                      disabled={props.signin?.loading}
                    >
                      Sign In
                    </button>
                  </div>
                  <p
                    className="mt-3 mb-0"
                    style={{ textAlign: "left", color: "white" }}
                  >
                    If you already have an account you can add multiple profile
                    types within your account. No need to create a new user id
                    and password, Don't forget you can ad more profiles to your
                    existing account.
                  </p>
                  <div className="font-weight-light text-white text-center pt-3">
                    {" "}
                    Not a member?{" "}
                    <Link to="/signup" className="auth-link fw-m">
                      Create an Account
                    </Link>
                  </div>
                  <div className="d-flex justify-content-center">
                    {loading ? (
                      <ClipLoader
                        color={"#FFFFFF"}
                        loading={loading}
                        css={css}
                        size={50}
                      />
                    ) : null}
                  </div>
                  {flashMsg && (
                    <div className="text-center mt-4 text-white fs-13">
                      {" "}
                      Check your email for verification.{" "}
                      <Link to="" className="auth-link fw-m" onClick={resend}>
                        Click here to resend.
                      </Link>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Login;
