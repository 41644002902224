import React from 'react'
import { connect, useSelector } from 'react-redux';
import { getPaymentReq } from '../../../service/action/Club/clubAction';
import { useEffect } from 'react';
import { useState } from 'react';
import Spinner from '../../../Spinner/Spinner';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import BreadcrumbHistory from '../../../BreadcumbHistory/BreadcrumbHistory';
import { useLocation } from 'react-router';

function Payment_History(props) {

  const [loading, setLoading] = useState(true)
  const [isShow, setIsShow] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState(null)
  const allpaymentReqSend = useSelector(
    (state) => state.getPaymentReqSendReducer.states.reportArray
  );

  const location = useLocation()



  useEffect(() => {
    props.getPaymentReq()
  }, [])

  useEffect(() => {
    if (props.getPaymentReqReducer.success) {
      setLoading(false)
    }
  }, [props.getPaymentReqReducer])


  return (
    <React.Fragment>
      <div className="main-panel">
      <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />

        <div className="content-wrapper">
          {
            loading ? (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            ) :
              <>
                <h5 className="dash-head mr-3 mb-2">Payment History</h5>
                <div>
                  {
                    allpaymentReqSend && allpaymentReqSend.length === 0 ?
                      <>
                        <div className="container d-flex justify-content-center align-items-center">
                          <span className="noti-type">
                            No payment history found
                          </span>
                        </div>
                      </> :
                      <>
                        <div className="table-responsive d-none d-md-block">

                          <table className="table event-table team-table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Email</th>
                                <th>Amount</th>
                                {/* <th></th> */}
                              </tr>
                            </thead>

                            <tbody>
                              {
                                allpaymentReqSend?.filter((e) => e.type !== "payment_request").map((v, i) => {

                                  return <tr key={v._id} className="team-row">
                                    <td className='pointer h6' onClick={() => {
                                      setIsShow(true)
                                      setPaymentDetails(v)
                                    }}>
                                      {/* {i+1} */}
                                      {v?.type === "received_payment" ? v?.first_name : v?.receipient?.first_name}
                                    </td>
                                    <td>
                                      {moment(v?.createdAt).format('MM-DD-yyyy')}
                                    </td>
                                    <td>
                                      {v?.type === "received_payment" ? v?.email : v?.receipient?.email}
                                    </td>
                                    <td className={v?.type === "refund" ? 'text-danger' : 'text-success'}>
                                      <b>{v?.type === "refund" ? "-" : "+"} ${v?.type === "refund" ? v?.refund_amount : v?.amount}</b>
                                    </td>
                                    {/* <td>
                                      {v?.type === "received_payment" ? "Paid" : "Outstanding"}
                                    </td> */}
                                  </tr>
                                })
                              }
                            </tbody>

                          </table>
                        </div>
                        <div className="d-md-none">
                          {!!allpaymentReqSend ? (
                            <>
                              {allpaymentReqSend.length !== 0 ? (
                                <>
                                  {allpaymentReqSend?.filter((e) => e.type !== "payment_request").map((v, i) => {
                                    return (
                                      <>
                                        <div className="d-flex justify-content space-between mb-3">
                                          <div className="col-9 noti-type">
                                            <p
                                              style={{ fontWeight: "bold" }}
                                              className="text-dark mb-1"
                                            >
                                              {v?.type === "received_payment" ? v?.first_name : v?.receipient?.first_name}
                                            </p>
                                            {/* <p>Event:{"  " + "NA"}</p> */}
                                            <p className="mb-1">{`Date: ${moment(v?.createdAt).format('MM-DD-yyyy')}`}</p>
                                            <p className="mb-1">{`Email: ${v?.type === "received_payment" ? v?.email : v?.receipient?.email}`}</p>
                                            {/* <p className="mb-1">
                                              {`Payment Status: Refunded`}
                                            </p> */}
                                          </div>
                                          <div className={`${v?.type === "refund" ? 'text-danger' : 'text-success'} col-3 p-0`}>
                                            <h4>{v?.type === "refund" ? "-" : "+"} ${v?.type === "refund" ? v?.refund_amount : v?.amount}</h4>
                                          </div>
                                        </div>
                                        <hr />
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <div className="d-flex justify-content-center noti-type">
                                    No payment request found
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-center">
                                <Spinner />
                              </div>
                            </>
                          )}
                        </div>
                      </>
                  }
                </div>
              </>
          }

        </div>
      </div>

      <Modal
        show={isShow}
        onHide={() => {
          setIsShow(false)
          setPaymentDetails(null)
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div style={{ backgroundColor: '#ffffff' }}>
          <Modal.Header className='d-flex justify-content-between'>
            <div className='h3'>{paymentDetails?.type === "received_payment" ? paymentDetails?.first_name : paymentDetails?.receipient?.first_name}</div>
            <div className={`h3 ${paymentDetails?.type === "refund" ? 'text-danger' : 'text-success'}`}>{paymentDetails?.type === "refund" ? "-" : "+"} ${paymentDetails?.type === "refund" ? paymentDetails?.refund_amount : paymentDetails?.amount}</div>
          </Modal.Header>
          <Modal.Body>
            {
              paymentDetails?.type === "received_payment" ? <>
                <div className="col-9 noti-type">
                  <p className="mb-1"> Name: {`${paymentDetails?.first_name} ${paymentDetails?.last_name}`} </p>
                  <p className="mb-1">Paymenr referance number: {paymentDetails?.payment_reference_number}</p>
                  <p className="mb-1">Recipient: {paymentDetails?.recipient}</p>
                  <p className="mb-1">Email: {paymentDetails?.email}</p>
                  <p className="mb-1">Date: {moment(paymentDetails?.createdAt).format('MM-DD-yyyy')}</p>
                  <p className="mb-1">Transaction time: {moment(paymentDetails?.createdAt).format('hh:mm')}</p>
                  <p className="mb-1">Payment Status: <span style={{ textTransform: 'capitalize' }}>{paymentDetails?.type}</span></p>

                </div>
              </> :
                <div className="col-9 noti-type">
                  <p className="mb-1"> Name: {`${paymentDetails?.receipient?.first_name} ${paymentDetails?.receipient?.last_name}`} </p>
                  <p className="mb-1">Paymenr referance number: {paymentDetails?.payment_reference_number}</p>
                  <p className="mb-1">Email: {paymentDetails?.receipient?.email}</p>
                  <p className="mb-1">Payment code name: {paymentDetails?.payment?.payment_code?.name}</p>
                  <p className="mb-1">Date: {moment(paymentDetails?.createdAt).format('MM-DD-yyyy')}</p>
                  <p className="mb-1">Transaction time: {moment(paymentDetails?.createdAt).format('hh:mm')}</p>
                  <p className="mb-1">Payment Status: <span style={{ textTransform: 'capitalize' }}>{paymentDetails?.type}</span></p>

                </div>
            }
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  // submit: state.deleteEventReducer,
  getPaymentReqReducer: state.getPaymentReqSendReducer,
  // submit_1: state.clonEventReducer,
  // bookmark: state.bookmarkReducer
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentReq: (user) => dispatch(getPaymentReq(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment_History);
