import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import {
  approveLiability,
  getClubOrgEventDetails,
  getLiability,
} from "../../../service/action/Club/clubAction";
import Parser from "html-react-parser";

function EventRegisterLiabilityWavier(props) {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [liability, setLiability] = useState(null);

  const eventDetails = useSelector(
    (state) => state.getClubOrgEventDetailsReducer.data
  ).data;
  const approve = useSelector((state) => state.approveLiabilityReducer);

  // useEffect(() => {
  //   if (eventList.success) {
  //     setCurrentEvent(eventList?.data?.data);
  //   } else {
  //     history.goBack();
  //   }
  // }, [eventList.success]);

  useEffect(() => {
    if (!!location.state) {
      props.getLiabilityhandler({ id: location?.state?.organizer });
    } else {
      history.goBack();
    }
  }, [location?.state]);

  useEffect(() => {
    if (props.OrgLiability.success) {
      const data = props.OrgLiability.user.data;
      setLiability(data);
    }
  }, [props.OrgLiability]);

  useEffect(() => {
    if (!eventDetails) {
      props.getOrgEventDetailsHandler({ id: id });
    }
  }, [eventDetails]);

  const handleAccept = () => {
    dispatch(
      approveLiability({
        organizer: eventDetails?.user_id?._id,
        event: eventDetails._id,
        liability: liability[0]?._id,
      })
    );
  };

  useEffect(() => {
    if (approve?.success) {
      window.open(approve.message.url, "_blank");
      history.push({
        pathname: location.state.from,
        state: {
          ...location.state,
          accept: true,
        },
      });
    }
  }, [approve]);
  return (
    <div className="main-panel" style={{ height: "85vh", overflow: "auto" }}>
      <div className="content-wrapper">
        <div className="text-left">
          <div className="create-team-head-space">
            <h5 className="dash-head mr-2 mb-3">Liability Terms</h5>
          </div>
        </div>
        <>
          <div
            className="border overflow-auto border-2 border-dark p-2 fs-14 text-gray"
            style={{ maxHeight: "600px" }}
          >
            {!!liability && Parser(liability[0].liability)}
          </div>
          <div className="text-gray fs-14 mt-2 ml-2">
            By accepting this liability waiver, all of the teams and players
            also accept.
          </div>

          <div
            className="mt-5 border-top-0 justify-content-end d-flex"
            style={{ gap: "10px" }}
          >
            <button
              onClick={() => {
                history.push({
                  pathname: location.state.from,
                  state: {
                    ...location.state,
                    accept: false,
                  },
                });
              }}
              className="text-white bg-info border-0 fs-15 p-2 rounded"
            >
              Declined
            </button>
            <button
              onClick={handleAccept}
              className="text-white bg-info border-0 fs-15 p-2 rounded"
            >
              Accept & Countinue
            </button>
          </div>
        </>
      </div>
    </div>
  );
}

const mapStateToProp = (state) => ({
  approve: state.approveLiabilityReducer,
  OrgLiability: state.getOrgLiabilityReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getOrgEventDetailsHandler: (user) => dispatch(getClubOrgEventDetails(user)),
  // approveLiability: user => dispatch(approveLiability(user)),
  getLiabilityhandler: (user) => dispatch(getLiability(user)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(EventRegisterLiabilityWavier));
