import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  getClubOrgEventDetails,
  getClubTeam,
  getEventDetailsForRegistration,
  getRegTeamPayAmount,
  geteventDivision,
  registerSelectedTeam,
} from "../../../service/action/Club/clubAction";
import {
  getAccountDetails,
  teamArray,
} from "../../../service/action/userAction";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Toaster from "../../../Toaster/Toaster";
import { RESET_REGISTER_SELECTED_TEAM } from "../../../service/constants";

function EventRegisterTeamListing(props) {
  const history = useHistory();
  const location = useLocation();

  const { id, id1 } = useParams(); //id -> current account id, id: event id

  const notify = (msg) => toast(msg);

  const getUserDetails = props.getAccountDetails;

  useEffect(() => {
    props.getClubTeamHandler({ id: id });
    props.getEventDetails({ id: id1 });
    sessionStorage.setItem("link", "/dashboard");
    props.getEventDivisionHandler({ id: id1 });
  }, []);

  const [teams, setTeams] = useState([]);
  const [organizerId, setOrganizerId] = useState(null);
  const [isModal, setIsModal] = useState(false);

  const eventHandler = (v_id, event) => {
    const { name, value } = event.target;
    let newArray = [...teams];
    const isInclude =
      teams.length > 0
        ? teams.find((team) => {
            return team.team === v_id;
          })
        : false;

    const divisionId = document.getElementById(v_id).value;
    if (isInclude && name === "checkbox") {
      newArray = newArray.filter((e) => e.team !== v_id);
      setTeams(newArray);
    } else if (isInclude && name === "division") {
      const index = teams.findIndex((team) => team.team === v_id);
      newArray.splice(index, 1, {
        team: v_id,
        division: value,
        amount: 0,
        panding_amount: 0,
      });
      setTeams(newArray);
    } else if (!isInclude && name === "checkbox") {
      newArray = [
        ...newArray,
        {
          team: v_id,
          division: divisionId,
          amount: 0,
          panding_amount: 0,
        },
      ];
      setTeams(newArray);
    }
  };

  useEffect(() => {
    getUserDetails();
    props.getClubTeamHandler({ id: id });
  }, [props.switch]);

  const getDivision = useSelector(
    (state) => state.geteventDivisionReducer.user
  ).filter((division) => division.type === 0);
  const allTeam = useSelector((state) => state.getClubAllTeamReducer.user);
  const registered = useSelector(
    (state) => state.registerSelectedTeamReducer.message
  );
  const eventDetails = useSelector(
    (state) => state.getClubOrgEventDetailsReducer.data
  );
  const accountDetails = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );

  useEffect(() => {
    if (allTeam) {
      let orgId = allTeam[0] !== undefined ? allTeam[0]?.season?.user_id : null;
      setOrganizerId(orgId);
    }
  }, [allTeam]);

  const onSubmit = (e) => {
    if (teams.length === 0) {
      notify("Please select team");
    } else {
      if (
        teams.length <=
          eventDetails.data.event_max_team - eventDetails.data.registerTeam ||
        !!eventDetails.data.infinity_max_team
      ) {
        props.selectedTeamHandler({
          event_id: id1,
          teams: teams,
          organizer: eventDetails.data.user_id._id,
          invite_type: 0,
        });
      } else {
        setIsModal(true);
      }
    }
  };

  const handleModal = () => {
    props.selectedTeamHandler({
      event_id: id1,
      teams: teams,
      organizer: eventDetails.data.user_id._id,
      invite_type: 0,
    });
    setIsModal(false);
  };

  useEffect(() => {
    if (props.registered?.success) {
      notify(registered.message);
      props.resetregisteredSelectedTeamReducer();

      props.sendTeamArrayHandler(teams);
      history.push({
        pathname: `/notification-accounts/${id}/register-event/${id1}/paynow`,
        state: {
          ...location.state,
          teams: teams,
          organizer: eventDetails?.data?.user_id?._id,
        },
      });
    }
  }, [props.registered]);
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head-space">
              <p className="h5 dash-head mr-2">Register Team</p>
              {/* <Link > */}
              <div className="mt-2 mt-md-0 text-right">
                <button className="btn-save" onClick={onSubmit}>
                  Next
                </button>
              </div>
            </div>
          </div>

          <div className="team-details mt-4 mt-md-0 justify-content-between">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="teams-tab"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="mt-30">
                  <p className="h4 text-center mb-3 fw-m event-name">
                    Evenet:{" "}
                    {eventDetails.status && eventDetails.data.event_name}
                  </p>
                  <div className="d-flex justify-content-center justify-content-sm-end">
                    <p className="text-gray">
                      Remaining Spot:{" "}
                      {eventDetails.data?.infinity_max_team
                        ? "Unlimited"
                        : eventDetails.data?.event_max_team -
                          eventDetails.data?.registerTeam}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <p className="h6 text-left">Available Teams</p>
                    {/* <Link
                      className="text-info h4 pointer"
                      to="/teams/create-team"
                    >
                      <i className="mdi mdi-plus"></i>
                      <span className="h6 text-left">Add</span>
                    </Link> */}
                  </div>

                  {/* desktop view */}
                  {allTeam ? (
                    <>
                      {allTeam.length === 0 ? (
                        <>
                          <div className="container d-flex justify-content-center align-items-center">
                            <span className="noti-type">
                              No teams to display{" "}
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="table-responsive d-none d-md-block">
                            <table className="table event-table team-table">
                              <thead>
                                <tr>
                                  <th>Teams</th>
                                  <th>Division</th>
                                </tr>
                              </thead>

                              <tbody>
                                {allTeam.map((v, i) => {
                                  return (
                                    <tr key={v._id} className="team-row">
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <input
                                            className="mr-3"
                                            type="checkbox"
                                            name="checkbox"
                                            onChange={(e) =>
                                              eventHandler(v._id, e)
                                            }
                                            checked={
                                              v.is_register ? true : null
                                            }
                                            disabled={v.is_register}
                                            value={v._id}
                                          />

                                          {/* <Link className="ml-12 heading text-decoration-none" to={`/team-details/${v._id}`}> */}
                                          <span className="filter-string fw-m">
                                            Team: {v.team_name}
                                          </span>
                                          {/* </Link> */}
                                        </div>
                                      </td>
                                      <td>
                                        {v.is_register ? (
                                          <select
                                            className={classNames(
                                              "form-control team-name payterm"
                                            )}
                                            id={v._id}
                                            name="division"
                                            disabled={true}
                                          >
                                            <option>
                                              {v.organizer_division !== ""
                                                ? v.organizer_division
                                                : "Division: N/A"}
                                            </option>
                                          </select>
                                        ) : (
                                          <select
                                            className={classNames(
                                              "form-control team-name payterm"
                                            )}
                                            id={v._id}
                                            name="division"
                                            onChange={(e) => {
                                              eventHandler(v._id, e);
                                            }}
                                          >
                                            <option value={""}>Division</option>
                                            {getDivision.map((division, i) => {
                                              return (
                                                <option
                                                  value={division._id}
                                                  key={i}
                                                >
                                                  {division.division_name}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          {/* mobile view */}
                          <div className="mt-4 d-md-none">
                            {allTeam.map((v, i) => {
                              return (
                                <div
                                  className="d-flex justify-content-between reg-details"
                                  key={i}
                                >
                                  <div className="d-flex w-100">
                                    <input
                                      className="mr-3"
                                      type="checkbox"
                                      name="checkbox"
                                      onChange={(e) => eventHandler(v._id, e)}
                                      checked={v.is_register ? true : null}
                                      disabled={v.is_register}
                                      value={v._id}
                                    />
                                    <div className="reg-content flex-grow-1">
                                      <span className="filter-string fw-m">
                                        Team: {v.team_name}
                                      </span>
                                      <select
                                        className={classNames(
                                          "form-control team-name payterm"
                                        )}
                                        id={v._id}
                                        name="division"
                                        onChange={(e) => eventHandler(v._id, e)}
                                        disabled={v.is_register}
                                      >
                                        <option value="">Division: </option>

                                        {getDivision.map((division, i) => {
                                          return (
                                            <option
                                              value={division._id}
                                              key={i}
                                            >
                                              {division.division_name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {/* <span className="d-block text-gray mt-2">Season: {v.season}</span> */}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
      <Modal show={isModal} centered backdrop="static">
        <div className="p-4" style={{ backgroundColor: "#ffffff" }}>
          <div className="text-gray">
            {`You have selected ${teams.length} team(s) of Which ${
              eventDetails.data?.event_max_team -
              eventDetails.data?.registerTeam
            } team(s) exceeds the event's remaining spots and will be placed on waitlist. Registration status for team(s) on a waitlist will be pending until the organizer changes their status.`}
            <br />
            <br />
            {`Would you like to continue?`}
          </div>
          <Modal.Footer className="mt-2 justify-content-center border-top-0">
            <button
              onClick={() => setIsModal(false)}
              className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 rounded"
            >
              cancel
            </button>
            <button
              onClick={handleModal}
              className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 rounded"
            >
              confirm
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  registered: state.registerSelectedTeamReducer,
  switch: state.switchAccountReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getClubTeamHandler: (user) => dispatch(getClubTeam(user)),
  selectedTeamHandler: (user) => dispatch(registerSelectedTeam(user)),
  resetregisteredSelectedTeamReducer: () =>
    dispatch({ type: RESET_REGISTER_SELECTED_TEAM }),
  sendTeamArrayHandler: (user) => dispatch(teamArray(user)),
  getAccountDetails: (user) => dispatch(getAccountDetails(user)),
  getEventDivisionHandler: (user) => dispatch(geteventDivision(user)),
  getEventDetails: (user) => dispatch(getClubOrgEventDetails(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventRegisterTeamListing);
