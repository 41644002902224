import React, { useState, useEffect } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import Toaster from '../../../Toaster/Toaster';
import { withRouter, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { connect, useSelector } from 'react-redux';
// import { createSeason } from '../../service/action/userAction';
// import { RESET_CREATE_SEASON } from '../../service/constants';
import classNames from 'classnames';
import {  createPool } from '../../../service/action/userAction';
import {  RESET_CREATE_POOL } from '../../../service/constants';
import { geteventDivision } from '../../../service/action/Club/clubAction';



const CreatePool = (props) => {
    let history = useHistory();
    const { id,id1 } = useParams()

    // const getDivision = props.getDivisionHandler;
    // const eventDivision = useSelector(state => state.geteventDivisionReducer.user)

    // useEffect(() => {
    //     // props.getDivisionHandler();
    //     props.getEventDivisionHandler({id:id})

    // }, [])


    const initialState = {
        // division_name: "",
        pool_name: ""
    }

    const [state, setState] = useState({
        // division_name: "",
        pool_name: ""
    })

    const [errors, setErrors] = useState({

        // division_name: false,
        pool_name: false

    })
    const clearState = () => {
        setState({ ...initialState });
    };

    function resetData() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    }

    // const [checked, setChecked] = useState(false)
    // const onClickCheck = () => setChecked(!checked)

    const eventHandler = (e) => {
        const { id, value, name } = e.target


        setState(prevState => ({
            ...prevState,
            [id]: value
            // [e.target.name]: e.target.value.trim()
        }))
        switch (name) {
            // case "division_name":
            //     if (value.length == 0 || value=="#") {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             division_name: "Division name can not be empty"
            //         }))

            //         document.getElementById("division_name").classList.add("error")
            //     }
            //     else {

            //         setErrors(prevState => ({
            //             ...prevState,
            //             division_name: false
            //         }))

            //         document.getElementById("division_name").classList.remove("error")
            //     }

            //     break;
            case "pool_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        pool_name: "Pool name can not be empty"
                    }))

                    document.getElementById("pool_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        pool_name: false
                    }))

                    document.getElementById("pool_name").classList.remove("error")
                }

                break;

            default:
        }
    }

    const notify = (msg) => toast(msg);

    const submitRequest = (e) => {
        e.preventDefault();

        // if (state.division_name == "") {
        //     setErrors(prevState => ({
        //         ...prevState,
        //         division_name: "Division name can not be empty"
        //     }))
        //     document.getElementById("division_name").classList.add("error")
        // }
        if (state.pool_name == "") {
            setErrors(prevState => ({
                ...prevState,
                pool_name: "Pool name can not be empty"
            }))
            document.getElementById("pool_name").classList.add("error")
        }

        if ( state.pool_name !== "" ) {
            props.createPoolhandler({
                event: id,
                pool: state.pool_name,
                team:id1

            })
        }
    }

    useEffect(() => {
        if (props.submit?.success) {
            // notify("Pool Created")
            resetData().then(clearState);
            // props.resetCreatePoolReducer()
            history.push(`/events/${id}`);
        } else if (props.submit?.error) {
            notify("Pool Error")
        }
    }, [props.submit])

    return (
        <>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <h5 className="dash-head">Create Pool</h5>
                    </div>

                    <form >
                        <div className="row">
                            <div className="col-md-4 col-xl-4">
                                <div className="mt-24">
                                    {/* <div className="text-left">
                                        <label className="text-gray fs-13">Division Name <span className="text-red fw-m">*</span></label>
                                        {
                                            eventDivision ? <>

                                                <select className={classNames("form-control team-name payterm", { "error": errors.division_name })} id="division_name" name="division_name" value={state.division_name} onChange={eventHandler}>
                                                    <option value="#" >Division *</option>
                                                    {eventDivision.map((v, i) => {
                                                        return (
                                                            <option value={v._id} key={v._id}>{v.division_name}</option>
                                                        )
                                                    })}
                                                </select>

                                                {errors.division_name &&
                                                    <span className='text-red small d-block'>* {errors.division_name}</span>}
                                            </> : <><h4>Loading...</h4></>
                                        }

                                    </div> */}
                                    <div className="text-left">
                                        <label className="text-gray fs-13">Pool Name <span className="text-red fw-m">*</span></label>
                                        <input type="text" className="form-control team-name" id="pool_name" name="pool_name" value={state.pool_name} placeholder="Pool name" required onChange={eventHandler} />

                                        {errors.pool_name &&
                                            <span className='text-red small d-block'>* {errors.pool_name}</span>}

                                    </div>

                                    <div className="mt-3 text-left">
                                        <a className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitRequest} >Submit</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                    <Toaster />
                </div>
            </div>

        </>
    )
}

// export default withRouter(Org_CreateDivision)



const mapStateToProp = state => ({
    submit: state.createPoolReducer,


})

const mapDispatchToProps = dispatch => ({
    createPoolhandler: user => dispatch(createPool(user)),
    resetCreatePoolReducer: () => dispatch({ type: RESET_CREATE_POOL }),
    // getAllDivisionHandler: user => dispatch(getAllDivision(user)),
    getEventDivisionHandler: (user) => dispatch(geteventDivision(user)),



})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(CreatePool));