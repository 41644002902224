import { GET_ALLEVENT_SUCCESS, GET_ALLEVENT_LOADING, GET_ALLEVENT_ERROR, EVENT_DETAIL, REMOVE_SELECTED_EVENT } from "../constants"

let initialState = {
    loading: false,
    user: [
    //     {
    //     "event_facility": [
    //         {
    //             "_id": "",
    //             "event_id": "",
    //             "facility_name": "",
    //             "facility_address": "",
    //             "facility_city": {
    //                 "_id": "",
    //                 "name": "",
    //                 "createdAt": "",
    //                 "updatedAt": "",
    //                 "__v": '',
    //                 "state_id": ""
    //             },
    //             "facility_state": {
    //                 "_id": "",
    //                 "country_id": "",
    //                 "name": "",
    //                 "createdAt": "",
    //                 "updatedAt": "",
    //                 "__v": ''
    //             },
    //             "facility_website": "",
    //             "createdAt": "",
    //             "updatedAt": "",
    //             "__v": ''
    //         }
    //     ],
    //     "payment_code": [],
    //     "contact_person": [],
    //     "show_number_of_player": '',
    //     "show_remaining_spots": '',
    //     "show_event_is_full": '',
    //     "show_schedule": '',
    //     "event_division": [],
    //     "event_schedule": [],
    //     "status": '',
    //     "isDeleted": '',
    //     "deletedAt": '',
    //     "_id": "",
    //     "user_id": "",
    //     "event_name": "",
    //     "event_type": '',
    //     "event_season": '',
    //     "event_from_date": new Date(),
    //     "event_to_date": new Date(),
    //     "event_from_time": "",
    //     "event_to_time": "",
    //     "event_access": '',
    //     "event_max_team": '',
    //     "createdAt": "",
    //     "updatedAt": "",
    //     "__v": ''
    // }
],
    error: false,
    errors: [],
    success: false,
    payload: {}
};



export const getAllEventReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALLEVENT_LOADING:
            // alert(" reducer succesfull")
            return { ...state, success: false, success: false };
            break;
        case GET_ALLEVENT_SUCCESS:
            // alert(" reducer Error")
            return { ...state, loading: false, success: true, user: action.payload };
            break;
        case GET_ALLEVENT_ERROR:
            // alert(" reducer Loading")
            return { ...state, error: true, errors: action.errors, networkError:action.error };

            break;

        default:
            return state;
    }
}

let initial={
    user: {
        "status": "",
        "message": "",
        "data": {
            "_id": "",
            "user_id": "",
            "event_name": "",
            "event_type": "",
            "event_season": "",
            "event_from_date": "",
            "event_to_date": "",
            "event_from_time": "",
            "event_to_time": "",
            "event_access": "",
            "event_max_team": "",
            "event_facility": [
                {
                    "_id": "",
                    "event_id": "",
                    "facility_name": "",
                    "facility_address": "",
                    "facility_city": {
                        "_id": "",
                        "name": "",
                        "createdAt": "",
                        "updatedAt": "",
                        "__v": "",
                        "state_id": ""
                    },
                    "facility_state": {
                        "_id": "",
                        "country_id": "",
                        "name": "",
                        "createdAt": "",
                        "updatedAt": "",
                        "__v": ""
                    },
                    "facility_website": "",
                    "createdAt": "",
                    "updatedAt": "",
                    "__v": ""
                }
            ],
            "show_number_of_player": "",
            "show_remaining_spots": "",
            "show_event_is_full": "",
            "show_schedule": "",
            "event_image": "",
            "status": "",
            "createdAt": "",
            "updatedAt": "",
            "payment_code": [],
            "contact_person": [],
            "event_schedule": [
                {
                    "_id": "",
                    "event_id": "",
                    "file": "",
                    "text": "",
                    "createdAt": "",
                    "updatedAt": ""
                }
            ],
            "event_division": [
                {
                    "_id": "",
                    "event_id": "",
                    "division_name": "",
                    "createdAt": "",
                    "updatedAt": "",
                    "isDeleted":"" ,
                    "deletedAt": "",
                    "pool": [
                        {
                            "_id": "",
                            "pool_name": "",
                            "createdAt": "",
                            "updatedAt": "",
                            "isDeleted": "",
                            "deletedAt": "",
                            "event_team": []
                        }
                    ]
                },
                {
                    "_id": "",
                    "event_id": "",
                    "division_name": "",
                    "createdAt": "",
                    "updatedAt": "",
                    "isDeleted": "",
                    "deletedAt": "",
                    "pool": []
                }
            ]
        }
    },
}

export const eventReducer = (state = initial, action) => {
    switch (action.type) {
        case EVENT_DETAIL:
            return { ...state, user: action.payload }
        case GET_ALLEVENT_ERROR:
            return { ...state, user: action.payload }
        default:
            return state;
    }

}

