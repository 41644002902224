import React, { useState,useEffect } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
// import Toaster from '../../../Toaster/Toaster';
import { withRouter ,Redirect, useParams} from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
// import { createSeason } from '../../service/action/userAction';
// import { RESET_CREATE_SEASON } from '../../service/constants';
import classNames from 'classnames';
import Toaster from '../../../Toaster/Toaster';
import { createNewGroup, createTeamGroup } from '../../../service/action/userAction';
import { useHistory } from 'react-router-dom';



const CreateTeamGroup = (props) => {

    const history=useHistory()

    const {id,id1}=useParams()

    const msg=useSelector(state=>state.createSeasonReducer.message)

    const initialState={
        season_name:""
    }

    const [state, setState] = useState({
        season_name: ""
    })

    const [errors, setErrors] = useState({
        
        season_name: false

    })
     function resetData() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    }
    const clearState = () => {
        setState({ ...initialState });
    };

    const eventHandler = (e) => {
        const { id, value, name } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
        switch (name) {
            case "season_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        season_name: "Group name can not be empty"
                    }))}
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        season_name: false
                    }))
                }

                break;

            default:
        }
    }

    const submitRequest = (e) => {
        e.preventDefault();
        // alert("click")

        if (state.season_name == "") {
            setErrors(prevState => ({
                ...prevState,
                season_name: "Group name can not be empty"
            }))
            document.getElementById("season_name").classList.add("error")
        }

        if (state.season_name !== "") {
            props.createTeamGrouphandler({
                group_name: state.season_name,
                organizer:id1,
                event:id
            })
        }

    }


    useEffect(() => {
        // alert("a")
        if (props.submit?.success) {
            // notify(msg.message)
            resetData().then(clearState);
            history.push(`/event-details/${id}`)
        } else if(props.submit?.error){
        }
    }, [props.submit])

    return (
        <>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <h5 className="dash-head">Create Team Group</h5>
                    </div>

                    <form >
                        <div className="row">
                            <div className="col-md-4 col-xl-4">
                                <div className="mt-24">
                                    <div className="text-left">
                                        <label className="text-gray fs-13">Group Name <span className="text-red fw-m">*</span></label>
                                        <input type="text" className="form-control team-name" id="season_name" name="season_name" value={state.season_name} placeholder="Group name" required onChange={eventHandler} />

                                        {errors.season_name &&
                                            <span className='text-red small d-block'>* {errors.season_name}</span>}

                                    </div>
                                   
                                    <div className="mt-3 text-left">
                                        <a className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitRequest} >Submit</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                                    <Toaster />
                </div>
            </div>

        </>
    )
}

// export default withRouter(CreateInvoice)



const mapStateToProp = state => ({
    submit: state.createNewGroupReducer,
})

const mapDispatchToProps = dispatch => ({
    createTeamGrouphandler: user => dispatch(createTeamGroup(user)),
    // resetCreateSeasonReducer:()=>dispatch({type:RESET_CREATE_SEASON})



})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(CreateTeamGroup));