import {
  READ_NOTIFICATION_ERROR,
  READ_NOTIFICATION_SUCCESS,
  RESET_READ_NOTIFICATION,
} from "../../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
};

export default function readNotificationReducer(state = initialState, action) {
  switch (action.type) {
    case READ_NOTIFICATION_SUCCESS:
      return { ...state, success: true, error: false, user: action.payload  };

    case READ_NOTIFICATION_ERROR:
      return { ...state, loading: false, error: true, success :false, user: [] };

    case RESET_READ_NOTIFICATION:
      return { ...state, error: false, success: false };

    default:
      return state;
  }
}
