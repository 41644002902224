import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { getClubNotification } from "../../../service/action/Club/clubAction";
import { connect, useDispatch, useSelector } from "react-redux";
import Spinner from "../../../Spinner/Spinner";
import {
  changeInvitationStatus,
  clearOrgnotificationsAction,
  getAccountDetails,
  getAllInvitation,
  getNotification,
  getNotificationsCountAction,
  orgConnectedUser,
  readInvitationAction,
  readNotificationAction,
  switchAccountDataForNotification,
} from "../../../service/action/userAction";
import { toast } from "react-toastify";
import {
  RESET_CHANGE_INVITATION_STATUS,
  RESET_CLEAR_ORG_NOTIFICATIONS,
  RESET_READ_INVITATION,
  RESET_READ_NOTIFICATION,
  RESET_SEND_BROADCAST,
  RESET_SWITCH_NOTIFICATION_USER,
} from "../../../service/constants";
import Toaster from "../../../Toaster/Toaster";
import Moment from "react-moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import classNames from "classnames";
import moment from "moment";
import AccountNotifications from "../../../club/Account/Account-notifications/AccountNotifications";

const Notification = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const data = useSelector((state) => state.notificationCountReducer);
  useEffect(() => {
    if (!!location.state && location.state?.id === id) {
      props.switchAccountHandler({
        id: location.state.id,
        type: location.state.type,
      });
    } else {
      history.push("/notification-accounts");
    }
  }, [location.state]);

  useEffect(() => {
    if (props.switch.success) {
      props.getInvitationHandler({
        id: location.state.id,
        type: location.state.type,
      });
      setLoading3(true);
      props.getOrgconnectedUserHandler({
        id: location.state.id,
        type: location.state.type,
      });
      setLoading1(true);
      props.getNotificationhandler({ type: location.state.type });
      setLoading2(true);
      dispatch(getNotificationsCountAction(id, location.state.type));
      props.resetSwitch();
    }
  }, [props.switch]);

  // const [loading3, setLoading3] = useState(true);

  // const [selectedNotification, setSelectedNotification] = useState(null);

  const initialConnectedUsser = {
    club: [],
    staff: [],
    player: [],
    organizer: [],
    coach: [],
    parent: [],
    unreadUserMsgUser: 0,
  };
  const [filterConnectedUser, setFilterConnectedUser] = useState(
    initialConnectedUsser
  );
  const [connectedUser, setConnectedUser] = useState(initialConnectedUsser);
  const [checkConnectedUserEmpty, setCheckConnectedUserEmpty] = useState(true);
  const [notificationList, setNotification] = useState([]);

  const [invitationList, setInvitationList] = useState([]);
  const notify = (msg) => toast(msg);

  const sendBroadcast_msg = useSelector(
    (state) => state.sendBroadcastReducer.message
  );
  const readNotificationReducer = useSelector(
    (state) => state.readNotificationReducer
  );
  const readInvitationReducer = useSelector(
    (state) => state.readInvitationReducer
  );

  useEffect(() => {
    if (readNotificationReducer.success) {
      dispatch(getNotificationsCountAction(id, location.state.type));
      dispatch({ type: RESET_READ_NOTIFICATION });
    }
  }, [readNotificationReducer]);
  useEffect(() => {
    if (readInvitationReducer.success) {
      dispatch(getNotificationsCountAction(id, location.state.type));
      dispatch({ type: RESET_READ_INVITATION });
    }
  }, [readInvitationReducer]);

  useEffect(() => {
    if (props.OrgConnectedUserReducer.success) {
      const data = props.OrgConnectedUserReducer.user;
      if (Array.isArray(data.data)) {
        setFilterConnectedUser(initialConnectedUsser);
        setConnectedUser(initialConnectedUsser);
      } else {
        setFilterConnectedUser((pre) => ({
          ...pre,
          club: !!data.data.club ? [...data.data.club] : [],
          staff: !!data.data.staff ? [...data.data.staff] : [],
          player: !!data.data.player ? [...data.data.player] : [],
          organizer: !!data.data.organizer ? [...data.data.organizer] : [],
          coach: !!data.data.coach ? [...data.data.coach] : [],
          parent: !!data.data.parent ? [...data.data.parent] : [],
          unreadUserMsgUser: !!data.data.unreadUserMsgUser
            ? data.data.unreadUserMsgUser
            : 0,
        }));
        setConnectedUser((pre) => ({
          ...pre,
          club: !!data.data.club ? [...data.data.club] : [],
          staff: !!data.data.staff ? [...data.data.staff] : [],
          player: !!data.data.player ? [...data.data.player] : [],
          organizer: !!data.data.organizer ? [...data.data.organizer] : [],
          coach: !!data.data.coach ? [...data.data.coach] : [],
          parent: !!data.data.parent ? [...data.data.parent] : [],
          unreadUserMsgUser: !!data.data.unreadUserMsgUser
            ? data.data.unreadUserMsgUser
            : 0,
        }));
      }

      setLoading1(false);
    }
  }, [props.OrgConnectedUserReducer]);

  useEffect(() => {
    if (props.invitationReducer.success) {
      setLoading3(false);
      const data = props.invitationReducer.user;
      setInvitationList(data.data);
    }
  }, [props.invitationReducer]);

  const checkAllConnectedUserEmpty = (obj) => {
    const keys = Object.keys(obj);
    const _map = keys.map((e, i) => {
      if(!Array.isArray(obj[keys[i]]) || obj[keys[i]].length !== 0) {
        return false;
      }else {
         return true;
      }
    })
    return _map
  };

  useEffect(() => {
    const data  = {...filterConnectedUser}
    delete data['unreadUserMsgUser']
    const isCheck = checkAllConnectedUserEmpty(data);
    setCheckConnectedUserEmpty(isCheck.every(e => e == true));
  }, [filterConnectedUser]);

  useEffect(() => {
    if (props.sendBroadcast?.success) {
      notify(sendBroadcast_msg.message);
      props.resetsendBroadcastHandler();
    }
  }, [props.sendBroadcast]);

  useEffect(() => {
    if (props.notification?.success) {
      setLoading2(false);
      const data = props.notification.user;
      setNotification(data.data);
    }
  }, [props.notification]);

  useEffect(() => {
    if (props.ClearOrgNotificationsReducer?.success) {
      props.getNotificationhandler({ type: location.state.type });
      setLoading2(true);
      props.resetClear();
    }
  }, [props.ClearOrgNotificationsReducer]);

  // useEffect(() => {
  //   props.getAccountDetails();
  // }, []);

  const [state, setState] = useState({
    search: "",
  });

  useEffect(() => {
    const search = state.search;
    if (search !== "") {
      const _filter = {
        club: connectedUser.club.filter((e) => {
          return e.name
            .trim()
            .toLowerCase()
            .includes(search.trim().toLowerCase());
        }),
        staff: connectedUser.staff.filter((e) => {
          return e.name
            .trim()
            .toLowerCase()
            .includes(search.trim().toLowerCase());
        }),
        player: connectedUser.player.filter((e) => {
          return (
            e.name.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
            e.first_name
              .trim()
              .toLowerCase()
              .includes(search.trim().toLowerCase()) ||
            e.last_name
              .toLowerCase()
              .trim()
              .includes(search.toLowerCase().trim())
          );
        }),
        organizer: connectedUser.organizer.filter((e) => {
          return e.name
            .trim()
            .toLowerCase()
            .includes(search.trim().toLowerCase());
        }),
        coach: connectedUser.coach.filter((e) => {
          return e.name
            .trim()
            .toLowerCase()
            .includes(search.trim().toLowerCase());
        }),
        parent: connectedUser.parent.filter((e) => {
          return e.name
            .trim()
            .toLowerCase()
            .includes(search.trim().toLowerCase());
        }),
      };

      setFilterConnectedUser((pre) => ({
        ...pre,
        ..._filter,
      }));
    } else {
      setFilterConnectedUser(connectedUser);
    }
  }, [state.search]);

  const Searchval = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  useEffect(() => {
    const activeTab = document.getElementById(location.state.name);
    if (activeTab) {
      activeTab.click();
    }
  }, [location.state.name]);

  const handleClear = () => {
    props.clearOrgnotificationsAction(location.state.id);
  };

  useEffect(() => {
    if (props.changeNotificationStatus.success) {
      props.getInvitationHandler({
        id: location.state.id,
        type: location.state.type,
      });
      setLoading3(true);
      props.resetNotificationStatus();
    }
  }, [props.changeNotificationStatus]);

  const handleInvitationAccept = (type, invitaionId) => {
    props.changeInvitationStatus({
      type: location.state.type,
      details: {
        status: 1,
        invite_from:
          type == "organizer"
            ? 1
            : type == "staff"
            ? 2
            : type == "coach"
            ? 3
            : type == "club"
            ? 4
            : type == "player"
            ? 5
            : type == "parent"
            ? 6
            : 0,
        id: invitaionId,
        type: location.state.type,
      },
    });
  };

  const handleInvitationRegister = (invitaionId, isEvent, eventId) => {
    if (isEvent) {
      history.push(`/notification-accounts/${id}/register-event`, {
        ...location.state,
        eventId: eventId,
        invitaionId: invitaionId,
      });
    } else {
      props.changeInvitationStatus({
        type: location.state.type,
        details: {
          status: 1,
          invite_from: 1,
          id: invitaionId,
          type: location.state.type,
        },
      });
    }
  };
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head">
              <h5 className="dash-head">Notifications</h5>
              {/* <button className="noti-plus-btn">+</button> */}
            </div>
          </div>
          <div className="mt-3">
            <AccountNotifications type={"1"} />
          </div>
          <div className="team-details">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="dm"
                  data-toggle="pill"
                  href="#dm-tab"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  <div className="d-flex align-items-center">
                    DMs
                    {parseInt(data?.data?.dm_unread_msg) > 0 && (
                      <div className="ml-3 count rounded-full w-[15px] h-[15px] d-flex align-items-center justify-content-center">
                        {data?.data?.dm_unread_msg}
                      </div>
                    )}
                  </div>
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="notification"
                  data-toggle="pill"
                  href="#notification-tab"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  onClick={() => {
                    setTimeout(() => {
                      dispatch(readNotificationAction(location.state.id));
                    }, 3000);
                  }}
                >
                  <div className="d-flex align-items-center">
                    Notifications
                    {parseInt(data?.data?.notificationCount) > 0 && (
                      <div className="ml-3 count rounded-full w-[15px] h-[15px] d-flex align-items-center justify-content-center">
                        {data?.data?.notificationCount}
                      </div>
                    )}
                  </div>
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="invitation"
                  data-toggle="pill"
                  href="#invitation-tab"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  onClick={() => {
                    props.getInvitationHandler({
                      id: location.state.id,
                      type: location.state.type,
                    });
                    setLoading3(true);
                    setTimeout(() => {
                      dispatch(readInvitationAction(location.state.type));
                    }, 3000);
                  }}
                >
                  <div className="d-flex align-items-center">
                    Invitation
                    {parseInt(data?.data?.invitation_unread_msg) > 0 && (
                      <div className="ml-3 count rounded-full w-[15px] h-[15px] d-flex align-items-center justify-content-center">
                        {data?.data?.invitation_unread_msg}
                      </div>
                    )}
                  </div>
                </a>
              </li>
            </ul>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="dm-tab"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="search-section">
                  <i className="mdi mdi-magnify search-ico"></i>
                  <input
                    type="text"
                    className="form-control"
                    id="search"
                    name="search"
                    value={state.search}
                    placeholder="Search"
                    onChange={Searchval}
                    autoComplete="off"
                  />
                </div>

                {!!loading1 ? (
                  <div className="d-flex justify-content-center">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {checkConnectedUserEmpty ? (
                      <>
                        <div className="container d-flex justify-content-center align-items-center">
                          <span className="noti-type">No Dm's to display</span>
                        </div>
                      </>
                    ) : (
                      <div className="mt-4">
                        {/* Organizer */}
                        {!!filterConnectedUser?.organizer &&
                          filterConnectedUser?.organizer.map((v, i) => {
                            
                            return (
                              <div
                                className="text-left pay-row border-bottom py-3"
                                key={v._id}
                              >
                                <Link
                                  className="text-decoration-none filter-string row mx-0"
                                  to={{
                                    pathname:
                                      v.lastMessage !== null
                                        ? `/chat/${v._id}/${v.lastMessage.roomId}/${v.name}/1`
                                        : `/chat/${v._id}`,
                                    state: {
                                      id: location.state.id,
                                      type: location.state.type,
                                    },
                                  }}
                                >
                                  <div className="col-7">
                                    <div className="d-flex flex-wrap align-items-center">
                                      <div
                                        className={classNames("dashboard-img", {
                                          onlineUser: !!v.is_online,
                                        })}
                                        style={{
                                          minWidth: "45px",
                                          height: "45px",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "100px",
                                            height: "40px",
                                          }}
                                          src={`${
                                            v.profile_image.length !== 0
                                              ? v.profile_image
                                              : "./images/faces-clipart/defaultUser.png"
                                          }`}
                                        />
                                      </div>
                                      <div className="ml-2">
                                        <label className="heading filter-string">
                                          <strong>{v.name}</strong>
                                        </label>
                                        <span
                                          className="noti-type text-truncate"
                                          style={{ maxWidth: "485px" }}
                                        >
                                          {v.lastMessage != null
                                            ? v.lastMessage.message_type ==
                                              "text"
                                              ? v.lastMessage.message
                                              : v.lastMessage.message_type
                                            : ""}
                                        </span>
                                      </div>
                                      {parseInt(v?.unreadMessage) >= 1 && (
                                        <div className="ml-auto">
                                          <div className="p-1 fs-13 unreadMsg ml-4">
                                            {v?.unreadMessage}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}

                        {/* staff */}
                        {!!filterConnectedUser?.staff &&
                          filterConnectedUser?.staff.map((v, i) => {
                            return (
                              <div
                                className="text-left pay-row border-bottom py-3"
                                key={v._id}
                              >
                                <Link
                                  className="text-decoration-none filter-string row mx-0"
                                  to={{
                                    pathname:
                                      v.lastMessage !== null
                                        ? `/chat/${v._id}/${v.lastMessage.roomId}/${v.name}/2`
                                        : `/chat/${v._id}/${v.name}/2`,
                                    state: {
                                      id: location.state.id,
                                      type: location.state.type,
                                    },
                                  }}
                                >
                                  <div className="col-7">
                                    <div className="d-flex flex-wrap align-items-center">
                                      <div
                                        className={classNames("dashboard-img", {
                                          onlineUser: !!v.is_online,
                                        })}
                                        style={{
                                          minWidth: "45px",
                                          height: "45px",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "100px",
                                            height: "40px",
                                          }}
                                          src={`${
                                            v.profile_image.length !== 0
                                              ? v.profile_image
                                              : "./images/faces-clipart/defaultUser.png"
                                          }`}
                                        />
                                      </div>
                                      <div className="ml-2">
                                        <label className="heading filter-string">
                                          <strong>{v.name}</strong>
                                        </label>
                                        <span
                                          className="noti-type text-truncate"
                                          style={{ maxWidth: "485px" }}
                                        >
                                          {v.lastMessage != null
                                            ? v.lastMessage.message_type ==
                                              "text"
                                              ? v.lastMessage.message
                                              : v.lastMessage.message_type
                                            : ""}
                                        </span>
                                      </div>
                                      {parseInt(v?.unreadMessage) >= 1 && (
                                        <div className="ml-auto">
                                          <div className="p-1 unreadMsg ml-4">
                                            {v?.unreadMessage}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}

                        {/* coach */}
                        {!!filterConnectedUser?.coach &&
                          filterConnectedUser?.coach.map((v, i) => {
                            return (
                              <div
                                className="text-left pay-row border-bottom py-3"
                                key={v._id}
                              >
                                <Link
                                  className="text-decoration-none filter-string row mx-0"
                                  to={{
                                    pathname:
                                      v.lastMessage !== null
                                        ? `/chat/${v._id}/${v.lastMessage.roomId}/${v.name}/3`
                                        : `/chat/${v._id}`,
                                    state: {
                                      id: location.state.id,
                                      type: location.state.type,
                                    },
                                  }}
                                >
                                  <div className="col-7">
                                    <div className="d-flex flex-wrap align-items-center">
                                      <div
                                        className={classNames("dashboard-img", {
                                          onlineUser: !!v.is_online,
                                        })}
                                        style={{
                                          minWidth: "45px",
                                          height: "45px",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "100px",
                                            height: "40px",
                                          }}
                                          src={`${
                                            v.profile_image.length !== 0
                                              ? v.profile_image
                                              : "./images/faces-clipart/defaultUser.png"
                                          }`}
                                        />
                                      </div>
                                      <div className="ml-2">
                                        <label className="heading filter-string">
                                          <strong>{v.name}</strong>
                                        </label>
                                        <span
                                          className="noti-type text-truncate"
                                          style={{ maxWidth: "485px" }}
                                        >
                                          {v.lastMessage != null
                                            ? v.lastMessage.message_type ==
                                              "text"
                                              ? v.lastMessage.message
                                              : v.lastMessage.message_type
                                            : ""}
                                        </span>
                                      </div>
                                      {parseInt(v?.unreadMessage) >= 1 && (
                                        <div className="ml-auto">
                                          <div className="p-1 unreadMsg ml-4">
                                            {v?.unreadMessage}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}

                        {/* club */}
                        {!!filterConnectedUser?.club &&
                          filterConnectedUser?.club.map((v, i) => {
                            return (
                              <div
                                className="text-left pay-row border-bottom py-3"
                                key={v._id}
                              >
                                <Link
                                  className="text-decoration-none filter-string row mx-0"
                                  to={{
                                    pathname:
                                      v.lastMessage !== null
                                        ? `/chat/${v._id}/${v.lastMessage.roomId}/${v.name}/4`
                                        : `/chat/${v._id}`,
                                    state: {
                                      id: location.state.id,
                                      type: location.state.type,
                                    },
                                  }}
                                >
                                  <div className="col-7">
                                    <div className="d-flex flex-wrap align-items-center">
                                      <div
                                        className={classNames("dashboard-img", {
                                          onlineUser: !!v.is_online,
                                        })}
                                        style={{
                                          minWidth: "45px",
                                          height: "45px",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "100px",
                                            height: "40px",
                                          }}
                                          src={`${
                                            v.profile_image.length !== 0
                                              ? v.profile_image
                                              : "./images/faces-clipart/defaultUser.png"
                                          }`}
                                        />
                                      </div>
                                      <div className="ml-2">
                                        <label className="heading filter-string">
                                          <strong>{v.name}</strong>
                                        </label>
                                        <span
                                          className="noti-type text-truncate"
                                          style={{ maxWidth: "485px" }}
                                        >
                                          {v.lastMessage != null
                                            ? v.lastMessage.message_type ==
                                              "text"
                                              ? v.lastMessage.message
                                              : v.lastMessage.message_type
                                            : ""}
                                        </span>
                                      </div>
                                      {parseInt(v?.unreadMessage) >= 1 && (
                                        <div className="ml-auto">
                                          <div className="p-1 unreadMsg ml-4">
                                            {v?.unreadMessage}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}

                        {/* player */}
                        {!!filterConnectedUser?.player &&
                          filterConnectedUser?.player.map((v, i) => {
                            return (
                              <div
                                className="text-left pay-row border-bottom py-3"
                                key={v._id}
                              >
                                <Link
                                  className="text-decoration-none filter-string row mx-0"
                                  to={{
                                    pathname:
                                      v.lastMessage !== null
                                        ? `/chat/${v._id}/${v.lastMessage.roomId}/${v.name}/5`
                                        : `/chat/${v._id}`,
                                    state: {
                                      id: location.state.id,
                                      type: location.state.type,
                                    },
                                  }}
                                >
                                  <div className="col-7">
                                    <div className="d-flex flex-wrap align-items-center">
                                      <div
                                        className={classNames("dashboard-img", {
                                          onlineUser: !!v.is_online,
                                        })}
                                        style={{
                                          minWidth: "45px",
                                          height: "45px",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "100px",
                                            height: "40px",
                                          }}
                                          src={`${
                                            v.profile_image.length !== 0
                                              ? v.profile_image
                                              : "./images/faces-clipart/defaultUser.png"
                                          }`}
                                        />
                                      </div>
                                      <div className="ml-2">
                                        <label className="heading filter-string">
                                          <strong>
                                            {v.first_name + " " + v.last_name}
                                          </strong>
                                        </label>
                                        <span
                                          className="noti-type text-truncate"
                                          style={{ maxWidth: "485px" }}
                                        >
                                          {v.lastMessage != null
                                            ? v.lastMessage.message_type ==
                                              "text"
                                              ? v.lastMessage.message
                                              : v.lastMessage.message_type
                                            : ""}
                                        </span>
                                      </div>
                                      {parseInt(v?.unreadMessage) >= 1 && (
                                        <div className="ml-auto">
                                          <div className="p-1 unreadMsg ml-4">
                                            {v?.unreadMessage}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}

                        {/* parent */}
                        {!!filterConnectedUser?.parent &&
                          filterConnectedUser?.parent.map((v, i) => {
                            return (
                              <div
                                className="text-left pay-row border-bottom py-3"
                                key={v._id}
                              >
                                <Link
                                  className="text-decoration-none filter-string row mx-0"
                                  to={{
                                    pathname:
                                      v.lastMessage !== null
                                        ? `/chat/${v._id}/${v.lastMessage.roomId}/${v.name}/6`
                                        : `/chat/${v._id}`,
                                    state: {
                                      id: location.state.id,
                                      type: location.state.type,
                                    },
                                  }}
                                >
                                  <div className="col-7">
                                    <div className="d-flex flex-wrap align-items-center">
                                      <div
                                        className={classNames("dashboard-img", {
                                          onlineUser: !!v.is_online,
                                        })}
                                        style={{
                                          minWidth: "45px",
                                          height: "45px",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "100px",
                                            height: "40px",
                                          }}
                                          src={`${
                                            v.profile_image.length !== 0
                                              ? v.profile_image
                                              : "./images/faces-clipart/defaultUser.png"
                                          }`}
                                        />
                                      </div>
                                      <div className="ml-2">
                                        <label className="heading filter-string">
                                          <strong>
                                            {v.first_name + " " + v.last_name}
                                          </strong>
                                        </label>
                                        <span
                                          className="noti-type text-truncate"
                                          style={{ maxWidth: "485px" }}
                                        >
                                          {v.lastMessage != null
                                            ? v.lastMessage.message_type ==
                                              "text"
                                              ? v.lastMessage.message
                                              : v.lastMessage.message_type
                                            : ""}
                                        </span>
                                      </div>
                                      {parseInt(v?.unreadMessage) >= 1 && (
                                        <div className="ml-auto">
                                          <div className="p-1 unreadMsg ml-4">
                                            {v?.unreadMessage}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </>
                )}
              </div>

              <div
                className="tab-pane fade"
                id="notification-tab"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="d-flex justify-content-center">
                  {loading2 ? <Spinner /> : null}
                </div>
                {!loading2 && notificationList ? (
                  <>
                    <div className="text-right">
                      <strong
                        className="pointer"
                        style={{ color: "#189AD6" }}
                        onClick={handleClear}
                      >
                        <u> Clear</u>
                      </strong>
                    </div>
                    {notificationList.length == 0 ? (
                      <>
                        {" "}
                        <div className="container d-flex justify-content-center align-items-center">
                          <span className="noti-type">
                            No Notifications to display
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="mt-3">
                        {notificationList.map((v, i) => {
                          return (
                            <div
                              className="text-left border-bottom py-2"
                              key={v._id}
                            >
                              <div className="ml-3">
                                <label className="heading">
                                  <strong>{v.heading}</strong>
                                </label>
                                <span className="noti-type">{v.message} </span>
                                <span className="noti-type my-1">
                                  <Moment
                                    date={new Date(v.createdAt)}
                                    format="dddd,MMM DD yyyy"
                                  />
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div
                className="tab-pane fade"
                id="invitation-tab"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="d-flex justify-content-center">
                  {loading3 ? <Spinner /> : null}
                </div>
                {!loading3 && (
                  <>
                    {invitationList.length == 0 ? (
                      <div className="container d-flex justify-content-center align-items-center">
                        <span className="noti-type">
                          No Invitation to display
                        </span>
                      </div>
                    ) : (
                      <div className="mt-4">
                        {invitationList.map((e, i) => {
                          return (
                            <React.Fragment key={i}>
                              {e.status == "0" ? (
                                <div
                                  className="d-flex my-2 py-2 border-bottom"
                                  key={i}
                                >
                                  <div className="dashboard-img mr-3">
                                    <img src={"./images/pch-images/pch.svg"} />
                                  </div>
                                  <div className="reg-content">
                                    <div>
                                      {e.invitor_type == "organizer" && (
                                        <div>
                                          {Object.keys(e.event).length == 0 ? (
                                            <>
                                              <strong>{e.invitor_name}</strong>
                                              <span className="text-gray">
                                                {" "}
                                                is Inviting you to join their
                                                account
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <strong>{e.invitor_name}</strong>
                                              <span className="text-gray">
                                                {" "}
                                                is Inviting you to register for
                                                the event:{" "}
                                              </span>
                                              <strong>
                                                {e.event.event_name}
                                              </strong>
                                            </>
                                          )}
                                        </div>
                                      )}
                                      {e.invitor_type == "player" && (
                                        <>
                                          <strong>{e.invitor_name}</strong>
                                          <span className="text-gray">
                                            {" "}
                                            has requested to join the club
                                          </span>
                                        </>
                                      )}
                                      {e.invitor_type == "coach" && (
                                        <>
                                          {/* <strong>{e.invitor_name}</strong> */}
                                          <span className="text-gray">
                                            You have to received on invite to
                                            join
                                          </span>
                                        </>
                                      )}
                                      {e.invitor_type == "staff" && (
                                        <>
                                          {/* <strong>{e.invitor_name}</strong> */}
                                          <span className="text-gray">
                                            You have to received on invite to
                                            join
                                          </span>
                                        </>
                                      )}
                                    </div>
                                    <div className="text-gray mt-1 fs-13">
                                      {moment(e.createdAt).format(
                                        "dddd, MMM DD yyyy"
                                      )}
                                    </div>
                                    <div className="mt-2 fs-15 d-flex">
                                      <div
                                        className="mr-5 pointer"
                                        style={{ color: "red" }}
                                        onClick={() => {
                                          // setSelectedNotification(e.invitation_id);
                                          props.changeInvitationStatus({
                                            type: location.state.type,
                                            details: {
                                              status: 2,
                                              invite_from:
                                                e.invitor_type == "organizer"
                                                  ? 1
                                                  : e.invitor_type == "staff"
                                                  ? 2
                                                  : e.invitor_type == "coach"
                                                  ? 3
                                                  : e.invitor_type == "club"
                                                  ? 4
                                                  : e.invitor_type == "player"
                                                  ? 5
                                                  : e.invitor_type == "parent"
                                                  ? 6
                                                  : 0,
                                              id: e.invitation_id,
                                              type: location.state.type,
                                            },
                                          });
                                        }}
                                      >
                                        <i className="fa-solid fa-xmark"></i>{" "}
                                        <strong>Declined</strong>
                                      </div>
                                      <div
                                        className="mr-5 pointer"
                                        style={{ color: "green" }}
                                        onClick={() => {
                                          if (e.invitor_type == "organizer") {
                                            handleInvitationRegister(
                                              e.invitation_id,
                                              Object.keys(e.event).length != 0,
                                              Object.keys(e.event).length != 0
                                                ? e.event._id
                                                : false
                                            );
                                          } else {
                                            handleInvitationAccept(
                                              e.invitor_type,
                                              e.invitation_id
                                            );
                                          }
                                        }}
                                      >
                                        <i className="fa-solid fa-check"></i>{" "}
                                        <strong>
                                          {e.invitor_type == "organizer"
                                            ? Object.keys(e.event).length != 0
                                              ? "Register"
                                              : "Accept"
                                            : "Accept"}
                                        </strong>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="d-flex my-2 py-2 border-bottom"
                                  key={i}
                                >
                                  <div className="dashboard-img mr-3">
                                    <img src={"./images/pch-images/pch.svg"} />
                                  </div>
                                  <div className="reg-content">
                                    {e.invitor_type == "organizer" && (
                                      <div>
                                        <span className="text-gray">
                                          Invitation{" "}
                                          {e.status == "1"
                                            ? "Accepted"
                                            : "Declined"}{" "}
                                          for{" "}
                                        </span>
                                        <strong>{e.invitor_name}</strong>
                                      </div>
                                    )}
                                    {e.invitor_type == "player" && (
                                      <div>
                                        <span className="text-gray">
                                          Request{" "}
                                          {e.status == "1"
                                            ? "Accepted"
                                            : "Declined"}{" "}
                                          for player{" "}
                                        </span>
                                        <strong>{e.invitor_name}</strong>
                                      </div>
                                    )}
                                    {e.invitor_type == "coach" && (
                                      <div>
                                        <span className="text-gray">
                                          Invitation{" "}
                                          {e.status == "1"
                                            ? "Accepted"
                                            : "Declined"}{" "}
                                          for
                                        </span>
                                        {/* <strong>{e.invitor_name}</strong> */}
                                      </div>
                                    )}
                                    {e.invitor_type == "staff" && (
                                      <div>
                                        <span className="text-gray">
                                          Invitation{" "}
                                          {e.status == "1"
                                            ? "Accepted"
                                            : "Declined"}{" "}
                                          for
                                        </span>
                                        {/* <strong>{e.invitor_name}</strong> */}
                                      </div>
                                    )}

                                    <div className="text-gray mt-1 fs-13">
                                      {moment(e.createdAt).format(
                                        "dddd, MMM DD yyyy"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(Notification);

const mapStateToProps = (state) => ({
  accept_invitation: state.acceptInvitationReducer,
  invitationReducer: state.getAllInvitationReducer,
  // dm: state.connectedUserReducer,
  notification: state.getOrgNotificationReducer,
  sendBroadcast: state.sendBroadcastReducer,
  ClearOrgNotificationsReducer: state.ClearOrgNotificationsReducer,
  OrgConnectedUserReducer: state.OrgConnectedUserReducer,
  switch: state.switchNotificationUser,
  changeNotificationStatus: state.changeNotificationStatusReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getInvitationHandler: (user) => dispatch(getAllInvitation(user)),
  switchAccountHandler: (user) =>
    dispatch(switchAccountDataForNotification(user)),
  getNotificationhandler: (user) => dispatch(getNotification(user)),
  getOrgconnectedUserHandler: (user) => dispatch(orgConnectedUser(user)),
  getAccountDetails: (user) => dispatch(getAccountDetails(user)),
  changeInvitationStatus: (user) => dispatch(changeInvitationStatus(user)),
  clearOrgnotificationsAction: (user) =>
    dispatch(clearOrgnotificationsAction(user)),
  resetClear: () => dispatch({ type: RESET_CLEAR_ORG_NOTIFICATIONS }),
  resetsendBroadcastHandler: () => dispatch({ type: RESET_SEND_BROADCAST }),
  resetSwitch: () => dispatch({ type: RESET_SWITCH_NOTIFICATION_USER }),
  resetNotificationStatus: () =>
    dispatch({ type: RESET_CHANGE_INVITATION_STATUS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
