import React, { useEffect, useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
// import { getAccountDetails, getOrgClubList } from '../../../service/action/userAction'
import Spinner from "../../../Spinner/Spinner";
import {
  assignCoach,
  coachAcceptList,
} from "../../../service/action/Club/clubAction";
import { toast } from "react-toastify";
import { RESET_ASSIGN_COACH } from "../../../service/constants";
import Toaster from "../../../Toaster/Toaster";

const AssignCoach = (props) => {
  const { id } = useParams();

  const notify = (msg) => toast(msg);

  const getCoachAcceptList = props.getCoachAcceptListHandler;

  const [loading, setLoading] = useState(true);

  const coachAcceptList = useSelector(
    (state) => state.coachAcceptListReducer.user.data
  );

  const assignCoachMsg = useSelector(
    (state) => state.assigncoachReducer.message
  );

  useEffect(() => {
    getCoachAcceptList();
  }, []);

  useEffect(() => {
    if (props.coach?.success) {
      setLoading(false);
    }
  }, [props.coach]);

  useEffect(() => {
    if (props.submit?.success) {
      notify(assignCoachMsg.message);
      props.resetassigncoachReducer();

      // history.push(`/event-details/${id}`);
    } else if (props.submit?.error) {
      notify("Assign Coach To Team Fail ");
    }
  }, [props.submit]);

  const addCoach = (_id) => {
    // alert("click")
    props.assignCoachHandler({
      coach: _id,
      team: id,
    });
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>
          {!loading && (
            <>
              {coachAcceptList.length == 0 ? (
                <div>
                  <div className="text-left">
                    <div className="create-team-head-event">
                      <h5 className="dash-head">Assign Coach</h5>
                      <div className="dropdown details-drp"></div>
                    </div>
                  </div>
                  <h3 className="d-flex justify-content-center noti-type">
                    No coach found{" "}
                  </h3>
                </div>
              ) : (
                <>
                  <div className="text-left">
                    <div className="create-team-head-event">
                      <h5 className="dash-head">Assign Coach</h5>
                      <div className="dropdown details-drp"></div>
                    </div>
                  </div>

                  {/* desktop view */}
                  <div className="table-responsive d-none d-md-block">
                    <table className="table event-table mt-30">
                      {coachAcceptList.length == 0 ? (
                        <div className="d-flex justify-content-center">
                          <h3>First Invite Coach </h3>{" "}
                          <Link
                            to="/teams/add-coach"
                            className="text-decoration-none"
                          >
                            {" "}
                            Click Here
                          </Link>
                        </div>
                      ) : (
                        <></>
                      )}
                      <thead>
                        <tr>
                          <th>Name</th>
                          {/* <th>Phone No.</th>
                                    <th>Payment</th>
                                    <th>Status</th> */}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {coachAcceptList &&
                          coachAcceptList.map((v, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="dashboard-img">
                                      <img
                                        src={
                                          v.coach.profile_image !== ""
                                            ? v.coach.profile_image
                                            : "./images/pch-images/pch.svg"
                                        }
                                      />
                                    </div>
                                    <span className="ml-12 filter-string">
                                      {v.coach.name !== undefined
                                        ? v.coach.name
                                        : ""}
                                    </span>
                                    <Link
                                      className="ml-12 text-blue text-decoration-none"
                                      to={`/club_details/${v._id}/${v.club._id}`}
                                    >
                                      {v.club.name}
                                    </Link>
                                  </div>
                                </td>
                                {/* <td>{v.Phone}</td>
                                            <td>{v.payment}</td>
                                            <td>{v.status}</td> */}
                                <td>
                                  <Link
                                    to="#"
                                    className="text-decoration-none"
                                    onClick={() => addCoach(v.coach._id)}
                                  >
                                    <i className="mdi mdi-plus text-blue mr-2 website-ico"></i>
                                    <span className="text-blue">
                                      Add To Team
                                    </span>
                                  </Link>
                                  {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <a className="dropdown-item" href="#">
                                                            <i className="mdi mdi-bookmark-outline"></i>
                                                            Bookmark</a>
                                                        <a className="dropdown-item" href="#">
                                                            <i className="mdi mdi-plus"></i>
                                                            Invite</a>
                                                    </div> */}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>

                  {/* mobile view */}

                  <div className="mt-30 d-md-none">
                    {coachAcceptList &&
                      coachAcceptList.map((v, i) => {
                        return (
                          <div
                            className="d-flex justify-content-between reg-details"
                            key={i}
                          >
                            <div className="d-flex">
                              <div className="dashboard-img mr-3">
                                <img src="./images/pch-images/pch.svg" />
                              </div>
                              <div className="reg-content">
                                <Link
                                  className="text-decoration-none text-blue fw-m"
                                  to="/club_details"
                                >
                                  {v.name}
                                </Link>
                                {/* <span className="d-block text-gray mt-2">Payment: {v.payment}</span>
                                            <span className="d-block text-gray mt-2">Status: {v.status}</span> */}
                              </div>
                            </div>
                            {/* <div className="dropdown event-drp">
                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-bookmark-outline"></i>
                                                Bookmark</a>
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-plus"></i>
                                                Invite</a>                                           
                                        </div>
                                    </div> */}
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </>
          )}
          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(Club);

const mapStateToProps = (state) => ({
  submit: state.assigncoachReducer,
  coach: state.coachAcceptListReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getCoachAcceptListHandler: (user) => dispatch(coachAcceptList(user)),
  resetassigncoachReducer: (user) => dispatch({ type: RESET_ASSIGN_COACH }),
  assignCoachHandler: (user) => dispatch(assignCoach(user)),
  // getOrg_ClubListHandler: user => dispatch(getOrgClubList(user)),
  // getAccountDetails: user => dispatch(getAccountDetails(user)),
  // userDataHandler: user => dispatch(userData(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssignCoach));
