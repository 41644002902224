
import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { getOrgClubList } from '../../service/action/userAction';
// import {getOrgClubList} from '../../../service/action/userAction'
import classNames from 'classnames';
import { useParams } from 'react-router';
import { getLiability } from '../../service/action/Club/clubAction';
// const { convert } = require('html-to-text');
// import parse from 'html-react-parser';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Parser from 'html-react-parser';
import Spinner from '../../Spinner/Spinner';
// const parse = require('html-react-parser')

const LiabilityTerms = (props) => {

    const { id } = useParams()
    const [loading, settLoading] = useState(true)


    useEffect(() => {
        props.getLiabilityhandler({ id: id })
    }, [])

    var htmlString = "<h1><b>test</b></h1>";
    var plainString = htmlString.replace(/<[^>]+>/g, '');


    const liability = useSelector(state => state.getOrgLiabilityReducer.user.data)


    const [state, setState] = useState({
        liability: ""
    })

    useEffect(() => {

        if (liability) {
            // var data = CKEDITOR.instances.editor1.getData();

            setState({
                liability: Parser(liability[0].liability)
            })
        }
    }, [liability])


    useEffect(() => {
        if (props.OrgLiability) {
            settLoading(false)
        }
    }, [props.OrgLiability])

    // var data = CKEditor.instances['comment']



    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">

                    <div className="text-left">
                        <h5 className="dash-head">Liability</h5>
                    </div>
                    <div className='d-flex justify-content-center'>

                        {loading ? <Spinner /> : null}
                    </div>
                    {
                        !loading && <>
                            <form >
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="mt-24">
                                            {
                                                liability ? <>
                                                    <div className="text-left">
                                                        {/* <label className="text-gray fs-13">Liability  <span className="text-red fw-m">*</span></label> */}

                                                        <div style={{ "height": "auto", "border": "0.5px solid black" }} className='w-100 rounded' >
                                                            <div name="terms_condition " id="terms_condition">
                                                                <div className='m-3'>{state.liability}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-3 text-left">
                                                        <Link className="btn btn-primary btn-lg font-weight-medium auth-form-btn" to={`/liability-waiver`}
                                                        // onClick={submitRequest}
                                                        >Decline</Link>
                                                        <Link className="btn btn-primary btn-lg font-weight-medium auth-form-btn ml-12" to={`/signature/${id}`}>Accept & Continue</Link>
                                                    </div>

                                                </> : <></>
                                            }
                                            {
                                                !liability ? "No Liability Available" : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </>
                    }
                    {/* <Toaster /> */}
                </div>
            </div>
        </React.Fragment>
    )
}

// export default withRouter(Club);

const mapStateToProps = (state) => ({
    OrgLiability: state.getOrgLiabilityReducer
})

const mapDispatchToProps = dispatch => ({
    getOrg_ClubListHandler: user => dispatch(getOrgClubList(user)),
    getLiabilityhandler: user => dispatch(getLiability(user))
    // userDataHandler: user => dispatch(userData(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LiabilityTerms))