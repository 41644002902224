import { GET_ACCOUNT_DETAILS_ERROR, GET_ACCOUNT_DETAILS_LOADING, GET_ACCOUNT_DETAILS_SUCCESS, RESET_ACCOUNT_DETAILS } from "../../../constants";

let initialState={
    success:false,
    error:false,
    loading:false,
    errors:[],
    user:{}
    }


export default function accountDetailsReducer(state=initialState,action){
    switch(action.type){
        case GET_ACCOUNT_DETAILS_LOADING:
            return {...state,error:false,success:false,loading:true}
        case GET_ACCOUNT_DETAILS_SUCCESS:
            return {...state,error:false,success:true,loading:false,user:action.payload}
        case GET_ACCOUNT_DETAILS_ERROR:
            return {...state,error:true,success:false,loading:false, errors:[]}
        case RESET_ACCOUNT_DETAILS:
            return {...state,error:false,success:false,loading:false,user:{}}
            
        default:
        return state
    }
}