import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Spinner from "../../../Spinner/Spinner";

const RoasterDetails = (props) => {
  const { id, id1 } = useParams();
  const [loading, setLoading] = useState(false);
  const [clubDetails, setclubDetails] = useState();

  useEffect(() => {
    if (props.clubTeamDetailsReducer?.success) {
      setLoading(false);
      const data = props.clubTeamDetailsReducer.user.data;
      setclubDetails(data);
    }
  }, [props.clubTeamDetailsReducer]);

  var teamplayer = [];

  clubDetails &&
    clubDetails?.map((v, i) => {
      teamplayer?.push(v);
    });

  const teamID = teamplayer?.find((v, i) => {
    if (v?._id === id) {
      return v;
    }
  });

  const rosterID = teamID?.roster_player?.find((v, i) => {
    return v._id === id1;
  });

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        {" "}
        <div className="d-flex justify-content-center">
          {loading ? <Spinner /> : null}
        </div>
        {!loading && (
          <>
            <table className="table event-table text-left players-tbl">
              {rosterID && rosterID !== undefined ? (
                <>
                  {rosterID?.player?.length !== 0 ? (
                    <>
                      <tbody>
                        {rosterID?.player?.map((v, i) => {
                          return (
                            <tr key={i} className="player-row">
                              <td>
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div
                                      className="dashboard-img"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <img
                                        src={
                                          v?.player?.profile_image !== ""
                                            ? v?.player?.profile_image
                                            : "./images/pch-images/pch.svg"
                                        }
                                      />
                                    </div>
                                    <Link
                                      className="ml-12 heading text-decoration-none filter-string text-dark pointer"
                                      style={{ fontWeight: "bold" }}
                                      to={`/roasterpersondetails/${v?.player?.id}`}
                                    >{`${
                                      v?.player?.name ? v?.player?.name : "N/A"
                                    } ${
                                      v?.player?.last_name
                                        ? v?.player?.last_name
                                        : ""
                                    }`}</Link>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <>
                      <div
                        className="d-flex text-gray justify-content-center align-items-center"
                        style={{ fontWeight: "bold" }}
                      >
                        Roaster is not available
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div>Roaster is not available</div>
                </>
              )}
            </table>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  team_details: state.getTeamDetailsReducer,
  clubTeamDetailsReducer: state.clubTeamDetailsReducer,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RoasterDetails);
