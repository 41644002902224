
import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import Toaster from '../../Toaster/Toaster';
import { withRouter, useHistory, useParams, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { editDiscountCode, getDiscountCodeById } from '../../service/action/userAction';
import { RESET_DISCOUNT_CODE, RESET_EDIT_DISCOUNT_CODE } from '../../service/constants';
import { Modal, ThemeProvider } from 'react-bootstrap';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import materialTheme from '../../materialUi';
import DateFnsUtils from "@date-io/date-fns";
import classNames from 'classnames';
import moment from 'moment';
import { getAllClubEvent } from '../../service/action/Club/clubAction';
import $ from 'jquery';
import Spinner from '../../Spinner/Spinner';
import BreadcrumbHistory from '../../BreadcumbHistory/BreadcrumbHistory';


const EditDiscount_Code = (props) => {

  const { id } = useParams()

  const history = useHistory()
  const notify = (msg) => toast(msg);

  const [state, setState] = useState({
    discount_name: '',
    discount_type: '1',
    amount: '',
    expiry_date: null,
    max_use_per_user: '',
    code_type: '1',
    code: '',
    selected_event: []
  })

  const [errors, setErrors] = useState({
    discount_name: false,
    discount_type: false,
    amount: false,
    expiry_date: false,
    max_use_per_user: false,
    code: false,
    selected_event: false
  })

  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState([])
  const [searchVal, setSearchVal] = useState('')

  const getClubEvent = useSelector(state => state.getAllClubEventReducer.data)
  const getDiscountCodeDetails = useSelector(state => state.getDiscountCodeReducer.data[0])
  const accountDetails = useSelector(state => state.accountDetailsReducer.user.data)
  const location = useLocation()

  useEffect(() => {
    props.getEventHandler({ id: 0 })
    props.getDisCountCodeById({ id: id })
  }, [])


  useEffect(() => {
    setLoading(props.getDiscountCode.loading)
    if (props.getDiscountCode.success) {
      setState({
        discount_name: getDiscountCodeDetails.code_name,
        discount_type: getDiscountCodeDetails.type,
        amount: getDiscountCodeDetails.amount,
        expiry_date: getDiscountCodeDetails.expire_date,
        max_use_per_user: getDiscountCodeDetails.max_apply,
        code_type: getDiscountCodeDetails.discount_code_type,
        code: getDiscountCodeDetails.code,
        selected_event: [...getDiscountCodeDetails.event]
      })
      props.resetGetDiscountCode()
    }

  }, [props.getDiscountCode])

  useEffect(() => {
    if (state.code_type === '1') {
      const randomStr = Math.round((Math.pow(36, 8 + 1) - Math.random() * Math.pow(36, 8))).toString(36).slice(1)
      setState(prevState => ({
        ...prevState,
        code: randomStr
      }))
      setErrors(prevState => ({
        ...prevState,
        code: false
      }))
    }

  }, [state.code_type])

  useEffect(() => {
    if (show) {
      const list = [...state.selected_event]
      setSelectedEvent([...list])
    }

  }, [show])

  useEffect(() => {
    if (props.editDiscountCodeReducer?.success) {
      // notify("Code Edited successfully.")
      props.resetGetDiscountCode()
      // props.resetEditCode()
      history.push("/payment/discount-code")
    }
  }, [props.editDiscountCodeReducer])


  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'code_type' && value === '0') {
      setState(prevState => ({
        ...prevState,
        code: ''
      }))
    }

    setState(prevState => ({
      ...prevState,
      [name]: value
    }))

    switch (name) {
      case "discount_name":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            discount_name: 'Enter Discount code'
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            discount_name: false
          }))
        } break;

      case "amount":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            amount: 'Enter Amount'
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            amount: false
          }))
        } break;

      case "expiry_date":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            expiry_date: 'Enter Expiry date'
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            expiry_date: false
          }))
        } break;

      case "max_use_per_user":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            max_use_per_user: 'Enter Max Use per user'
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            max_use_per_user: false
          }))
        } break;


      case "code":
        if (value === '') {
          setErrors(prevState => ({
            ...prevState,
            code: 'Enter Code'
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            code: false
          }))
        } break;

      case "selected_event":
        if (value === []) {
          setErrors(prevState => ({
            ...prevState,
            selected_event: 'Enter Select event'
          }))
        } else {
          setErrors(prevState => ({
            ...prevState,
            selected_event: false
          }))
        } break;



      default: break;
    }
  }


  const handleEventList = (e) => {
    const { value, checked } = e.target
    const list = [...selectedEvent]
    if (checked) {
      list.push(value)
    } else {
      const index = list.findIndex(e => e === value)
      list.splice(index, 1)
    }
    setSelectedEvent([...list])
  }

  const addAddList = () => {
    setState(prevState => ({
      ...prevState,
      selected_event: [...selectedEvent]
    }))
    setSelectedEvent([])
    setShow(false)
  }

  const handleSearch = (e) => {
    const { value } = e.target
    setSearchVal(value)

    $(".modal-body .filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(value) === -1) {
        $(v).parents(".event-row").addClass("d-none");
      } else {
        $(v).parents(".event-row").removeClass("d-none");
      }
    });
  }


  const handleEditCode = () => {
    if (state.discount_name === '') {
      setErrors(prevState => ({
        ...prevState,
        discount_name: 'Enter Discount code'
      }))
    }
    if (state.amount === '') {
      setErrors(prevState => ({
        ...prevState,
        amount: 'Enter Amount'
      }))
    }
    if (state.expiry_date === '' || state.expiry_date === null) {
      setErrors(prevState => ({
        ...prevState,
        expiry_date: 'Enter Expiry date'
      }))
    }
    if (state.code === '') {
      setErrors(prevState => ({
        ...prevState,
        code: 'Enter Code'
      }))
    }
    if (state.max_use_per_user === '') {
      setErrors(prevState => ({
        ...prevState,
        max_use_per_user: 'Enter Max Use per user'
      }))
    }
    if (state.selected_event.length === 0) {
      setErrors(prevState => ({
        ...prevState,
        selected_event: 'Enter Select event'
      }))
    }

    if (
      state.discount_name !== '' &&
      state.discount_type !== '' &&
      state.amount !== '' &&
      state.expiry_date !== '' &&
      state.max_use_per_user !== '' &&
      state.code !== '' &&
      state.selected_event.length !== 0
    ) {
      props.editDiscountCode({
        id: id,
        code_name: state.discount_name,
        code_type: state.discount_type,
        expiry_date: state.expiry_date,
        amount: state.amount,
        code: state.code,
        discount_code_type: state.code_type,
        max_apply: state.max_use_per_user,
        event: state.selected_event
      })
    }
  }

  return getDiscountCodeDetails ?
    <div className="main-panel">
      <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />

      <div className="content-wrapper">
        <div className="text-left ">
          <div className="create-team-head-space">
            <div className="d-md-flex align-items-center d-none">
              <h5 className="dash-head mr-3 mb-0">Edit Discount</h5>
            </div>

            <div className="mt-2 mt-md-0 text-right">
              <button className="btn-save" onClick={handleEditCode}>Edit</button>
            </div>
          </div>
        </div>


        {loading && !getDiscountCodeDetails ? <div className='d-flex justify-content-center'><Spinner /></div> :
          <div className="row col-md-6 px-0 mx-0">
            <div className="col-md-12 mt-24">

              <div>
                <input type="text" name="discount_name" value={state.discount_name} className="form-control team-name" placeholder='Discount code name *' onChange={handleChange} />
                {
                  errors.discount_name && <div className='text-red small'>* {errors.discount_name}</div>
                }
              </div>
              <div className='mt-24'>
                <div className='text-info'>Amount</div>
                <div className='d-flex mt-12'>
                  <div>
                    <input id='%terms' name='discount_type' value={'1'} type={'radio'} checked={'1' === (state.discount_type).toString()} onChange={handleChange} />
                    <label htmlFor="%terms">%</label>
                  </div>
                  <div className='ml-24'>
                    <input id='$terms' name='discount_type' value={'0'} type={'radio'} checked={'0' === (state.discount_type).toString()} onChange={handleChange} />
                    <label htmlFor="$terms">$</label>
                  </div>
                </div>
              </div>
              <div className='mt-24'>
                <input type="number" name="amount" value={state.amount} className="form-control team-name number" placeholder={`Amount in ${state.discount_type === '1' ? '%' : '$'} *`} onChange={handleChange} />
                {
                  errors.amount && <div className='text-red small'>* {errors.amount}</div>
                }
              </div>
              <div className='mt-24'>
                <ThemeProvider theme={materialTheme}>


                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      value={state.expiry_date}
                      placeholder="MM/DD/YYYY"
                      format="MM/dd/yyyy"
                      mask="__/__/____"
                      inputVariant="outlined"
                      autoOk="true"
                      minDate={moment()}
                      InputAdornmentProps={{ position: "end" }}
                      className={classNames(" form-control team-name")}
                      invalidDateMessage="Invalid Date Format"
                      onChange={(e, i) => {
                        handleChange({
                          target: {
                            value: e,
                            name: "expiry_date",
                            id: "date",
                          },
                        }, i)
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
                {
                  errors.expiry_date && <div className='text-red small'>* {errors.expiry_date}</div>
                }
              </div>

              <div className='mt-24'>
                <input type="number" name="max_use_per_user" value={state.max_use_per_user} className="form-control team-name number" placeholder='Max Usage per User *' onChange={handleChange} />
                {
                  errors.max_use_per_user && <div className='text-red small'>* {errors.max_use_per_user}</div>
                }
              </div>

              <div className='mt-24'>
                <div className='d-flex'>
                  <div>
                    <input id='random_code' name='code_type' value={'1'} type={'radio'} onChange={handleChange} checked={'1' === (state.code_type).toString()} />
                    <label htmlFor="random_code">Random Code</label>
                  </div>
                  <div className='ml-24'>
                    <input id='coustom_code' name='code_type' value={'0'} type={'radio'} onChange={handleChange} checked={'0' === (state.code_type).toString()} />
                    <label htmlFor="coustom_code">Coustom Code</label>
                  </div>
                </div>
                <div className='mt-12'>
                  <input id="code" name="code" value={state.code} className="form-control team-name number" placeholder='Create Code Name *' disabled={(state.code_type).toString() === '1'} onChange={handleChange} maxLength={'20'} />
                  {
                    errors.code && <div className='text-red small'>* {errors.code}</div>
                  }
                </div>
              </div>

              <div className='mt-24'>
                <div className='fw-m text-info' onClick={() => setShow(true)}> <span className='border p-2  pointer'>Select Event *</span></div>
                {
                  getClubEvent.map((e, i) => {
                    if (state.selected_event.includes(e._id)) {
                      return <div className='mt-12 text-gray fw-m ml-2' key={i}>
                        {e.event_name}
                      </div>
                    }
                  })
                }
                {
                  errors.selected_event && <div className='text-red small'>* {errors.selected_event}</div>
                }

              </div>
            </div>
            <Toaster />
          </div>}



      </div>
      {accountDetails && <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <div className="p-3" style={{ background: '#ffffff' }}>
          <div className="search-section m-auto">
            <i className="mdi mdi-magnify search-ico"></i>
            <input type="text" className="form-control" id="search" name="search" placeholder="Search" autoComplete="off" value={searchVal} onChange={handleSearch} />
          </div>

          <div className='mt-18 modal-body' style={{ maxHeight: '60vh', overflow: 'auto', minHeight: '25vh' }}>
            {
              getClubEvent.length === 0 ?
                <div className='text-center text-gray mt-3 d-flex align-items-center justify-content-center ' style={{ height: '25vh' }}>No Events to display</div> :
                <>
                  {
                    getClubEvent.map((v, i) => {
                      return (
                        <div className='event-row' key={i}>
                          <div key={i} className='d-flex mt-18 justify-content-between align-items-center '>
                            <div className='d-flex'>
                              <div className="dashboard-img mr-3">
                                <img src={v.event_image !== "" ? v.event_image : `${accountDetails.profile_image}`} />
                              </div>
                              <div className='fw-m filter-string'>{v.event_name}</div>
                            </div>
                            <input type="checkbox" className='selectedEvent' value={v._id} onChange={handleEventList} checked={selectedEvent.includes(v._id)} />

                          </div>
                        </div>
                      )
                    })
                  }

                </>
            }
          </div>
          <Modal.Footer className="mt-2 justify-content-center border-top-0">
            <button onClick={() => { setShow(false); setSelectedEvent([]) }} className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 rounded">
              cancel
            </button>
            <button className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 rounded" onClick={addAddList} >
              add
            </button>
          </Modal.Footer>
        </div>
      </Modal>}
    </div > : <></>

}

const mapStateToProp = state => ({
  getDiscountCode: state.getDiscountCodeReducer,
  editDiscountCodeReducer: state.editDiscountCodeReducer
})

const mapDispatchToProps = dispatch => ({
  getDisCountCodeById: user => dispatch(getDiscountCodeById(user)),
  getEventHandler: user => dispatch(getAllClubEvent(user)),
  editDiscountCode: user => dispatch(editDiscountCode(user)),
  resetGetDiscountCode: () => dispatch({ type: RESET_DISCOUNT_CODE }),
  resetEditCode: () => dispatch({ type: RESET_EDIT_DISCOUNT_CODE })
})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(EditDiscount_Code));