import { CREATE_PAYMENT_CODE_ERROR,CREATE_PAYMENT_CODE_LOADING,CREATE_PAYMENT_CODE_SUCCESS,RESET_CREATE_PAYMENT_CODE} from "../constants"

let initialState={
    error:false,
    success:false,
    errors:[],
    user:{},
    loading:false
}

export default function createPaymentCodeReducer(state=initialState,action){
    switch (action.type) {
        case CREATE_PAYMENT_CODE_LOADING:
            // alert("reducer Loading")
            return { ...state, loading: true, error: false, success: false };

        case CREATE_PAYMENT_CODE_SUCCESS:
            // alert("reducer success") 
            return { ...state, success: true,error:false,loading:false,message:action.msg};
        case CREATE_PAYMENT_CODE_ERROR:
            // alert("reducer Error")   
            return { ...state, error: true, errors:[],success:false,loading:false};
            
         case RESET_CREATE_PAYMENT_CODE:
             return {...state,error:false,success:false,loading:false,errors:[]}
            
        default:
            return state;
    }
}