import React, { useState, useEffect } from "react";
import {
  Link,
  useHistory,
  Redirect,
  withRouter,
  useParams,
} from "react-router-dom";
import classNames from "classnames";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Toaster from "../Toaster/Toaster";
import Spinner from "../Spinner/Spinner";
// import { connect } from 'react-redux';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { forgotpasswordEmail, verifyotp } from "../service/action/userAction";
import {
  RESET_FORGOT_PASSWORD_EMAIL,
  RESET_VERIFY_OTP,
} from "../service/constants";
import OtpInput from "react-otp-input";

const VerifyOtp = (props) => {
  const { id } = useParams();
  const notify = (msg) => toast(msg);
  const [loading, setLoading] = useState(false);

  let [color, setColor] = useState("#FFFFFF");

  const history = useHistory();

  const [errors, setErrors] = useState({
    otp: false,
  });

  const forgotpassword = useSelector(
    (state) => state.forgotpasswordEmailReducer.message
  );
  const verifyOtp = useSelector((state) => state.verifyotpReducer.message);

  const [otp, setOtp] = useState({ otp: "" });

  const handleChange = (otp) => setOtp({ otp });

  // verify button click
  const verify = (e) => {
    e.preventDefault();

    if (otp.otp == "") {
      setErrors((prevState) => ({
        ...prevState,
        otp: "otp can not be empty",
      }));
    }

    if (otp.otp !== "") {
      props.verifyOtpHandler({
        email: id,
        otp: otp.otp,
      });
    }
  };

  useEffect(() => {
    if (props.action?.loading) {
      setLoading(true);
    } else if (props.action?.success) {
      setLoading(false);
      if (verifyOtp.status == false) {
        notify(verifyOtp.message);
      } else if (verifyOtp.status == true) {
        history.push("/resetpassword");
        props.resetForgotpasswordHandler();
      }
    } else if (props.action?.error) {
      setLoading(false);
      notify(verifyOtp.msg);
      props.resetverifyotpHandler();
    }
  }, [props.action]);

  useEffect(() => {
    if (!forgotpassword) {
      history.goBack();
    }
  }, [props.forgotpassword]);

  // const eventHandler = (e) => {
  //     const { id, value, name } = e.target

  //     const validEmailRegex =
  //         RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

  //     setState(prevState => ({
  //         ...prevState,
  //         [id]: value
  //     }))
  //     switch (name) {
  //         case "email":
  //             if (value.length == 0) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     emailEr: "Email can not be empty"
  //                 }))
  //             } else if (!validEmailRegex.test(value)) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     emailEr: "Email is not valid !"
  //                 }))
  //             } else {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     emailEr: false
  //                 }))
  //             }
  //             break;

  //     }
  // }

  return (
    <React.Fragment>
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex auth login-section">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left p-5">
                <div className="brand-logo text-center">
                  <img
                    src="./images/pch-images/PCH_Logo_Hori_Blue Blue-white.svg"
                    alt="logo"
                  />
                </div>
                <div className="pb-2 text-center">
                  <h6 className="text-white ">Forgot Password</h6>
                </div>
                <form className="pt-3 ">
                  <div className=" otp-form pb-4">
                    <OtpInput
                      value={otp.otp}
                      onChange={handleChange}
                      numInputs={6}
                      separator={<span>-</span>}
                      shouldAutoFocus="true"
                      inputStyle="otp"
                      className={classNames({ "login-error": errors.otp })}
                    />
                    {/* <input type="email" className={classNames("form-control form-control-lg", { "login-error": errors.emailEr })} id="email" name="email" value={state.email} placeholder="Email" required onChange={eventHandler} autoComplete="off" />
                                        {errors.emailEr &&
                                            <span className='text-danger small d-block'>* {errors.emailEr}</span>} */}
                  </div>
                  {errors.otp ? (
                    <div className="otp-form">
                      {forgotpassword && (
                        <span className="text-red small d-block">
                          Please enter otp
                        </span>
                      )}
                    </div>
                  ) : (
                    <div className="otp-form">
                      {forgotpassword && (
                        <span className="text-white small d-block">
                          {" "}
                          {forgotpassword.message}{" "}
                        </span>
                      )}
                    </div>
                  )}

                  <div className="mt-3 text-center">
                    <button
                      type="submit"
                      className="btn btn-login btn-lg font-weight-medium auth-form-btn"
                      onClick={verify}
                    >
                      Verify
                    </button>
                  </div>

                  <div className="d-flex justify-content-center">
                    {/* {loading ? <ClipLoader color={color} loading={loading} css={css} size={50} /> : null} */}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};

// export default ForgotPassword;

const mapStateToProp = (state) => ({
  action: state.verifyotpReducer,
  forgotpassword: state.forgotpasswordEmailReducer,
});

const mapDispatchToProps = (dispatch) => ({
  forgotpasswordHandler: (user) => dispatch(forgotpasswordEmail(user)),
  resetForgotpasswordHandler: () =>
    dispatch({ type: RESET_FORGOT_PASSWORD_EMAIL }),
  verifyOtpHandler: (user) => dispatch(verifyotp(user)),
  resetverifyotpHandler: () => dispatch({ type: RESET_VERIFY_OTP }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(VerifyOtp));
