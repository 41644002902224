import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect, useSelector } from "react-redux";
import { createDivision } from "../../service/action/userAction";
import {
  RESET_CREATE_DIVISION,
  RESET_CREATE_PAYMENT_CODE,
} from "../../service/constants";
// import classNames from 'classnames';
import {
  createField,
  create_Division,
} from "../../service/action/Club/clubAction";
import { toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";
import { useHistory } from "react-router-dom";
import BreadcrumbHistory from "../../BreadcumbHistory/BreadcrumbHistory";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Create_Division = (props) => {
  const history = useHistory();

  const [state, setState] = useState({
    division_name: "",
  });
  const location = useLocation();
  const [errors, setErrors] = useState({
    division_name: false,
  });

  const initialState = {
    division_name: "",
  };

  //setTimeout form reseting data after submit of form
  function resetData() {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }

  //clear data on submit.
  const clearState = () => {
    setState({ ...initialState });
  };

  // const [checked, setChecked] = useState(false)
  // const onClickCheck = () => setChecked(!checked)
  const notify = (msg) => toast(msg);

  const DivisionMsg = useSelector(
    (state) => state.createPaymentCodeReducer.message
  );

  const eventHandler = (e) => {
    const { id, value, name } = e.target;

    setState((prevState) => ({
      ...prevState,
      [id]: value,
      // [e.target.name]: e.target.value.trim()
    }));
    switch (name) {
      case "division_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            division_name: "Division name can not be empty",
          }));

          document.getElementById("division_name").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            division_name: false,
          }));

          document.getElementById("division_name").classList.remove("error");
        }

        break;

      default:
    }
  };

  const submitRequest = (e) => {
    e.preventDefault();
    // alert("click")

    if (state.division_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        division_name: "Please enter division name",
      }));
      document.getElementById("division_name").classList.add("error");
    }

    if (state.division_name !== "") {
      props.createDivisionhandler({
        division_name: state.division_name,
        club: localStorage.getItem("Id"),
      });
    }
  };

  useEffect(() => {
    // alert("a")
    if (props.submit?.success) {
      // alert("b")
      resetData().then(clearState);
      // notify(DivisionMsg.message)
      props.resetCreateDivisionReducer();
      history.goBack();

      // history.push(`/event-details/${id}`);
    }
  }, [props.submit]);

  return (
    <>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Add Division</h5>
          </div>

          <form>
            <div className="row mx-0">
              <div className="col-md-4 col-xl-4">
                <div className="mt-24">
                  <div className="text-left">
                    <label className="text-gray fs-13">
                      Division Name <span className="text-red fw-m">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control team-name"
                      id="division_name"
                      name="division_name"
                      value={state.division_name}
                      placeholder="Division"
                      required
                      onChange={eventHandler}
                    />

                    {errors.division_name && (
                      <span className="text-red small d-block">
                        * {errors.division_name}
                      </span>
                    )}
                  </div>

                  <div className="mt-3 text-left">
                    <a
                      className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                      onClick={submitRequest}
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <Toaster />
        </div>
      </div>
    </>
  );
};

// export default withRouter(CreateInvoice)

const mapStateToProp = (state) => ({
  submit: state.createDivisionReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createDivisionhandler: (user) => dispatch(create_Division(user)),
  resetCreateDivisionReducer: () => dispatch({ type: RESET_CREATE_DIVISION }),
});
export default connect(mapStateToProp, mapDispatchToProps)(Create_Division);
