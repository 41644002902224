import React from 'react'
import BreadcrumbHistory from '../../BreadcumbHistory/BreadcrumbHistory'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { toast } from 'react-toastify'
import Toaster from '../../Toaster/Toaster'

function InviteAdmin() {
 const location = useLocation()
 const [email, setEmail] = useState("")

 const [error, setError] = useState(false)
 const [emailsList, setEmailsList] = useState([])

 const notify = (msg) => toast(msg)
 const eventHandler = (e) => {
  setEmail(
   e.target.value,
  );
 }

 const addEmails = () => {
  if (email === '') {
   notify("Please Check Email")
  }
  else {
   const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
   );
   const list = email.split(/,|, /)
   const isValid = list.map(v => {
    if (!validEmailRegex.test(v.trim())) {
     setError("Email is not valid !");
     return false
    } else if (list.length === 0 || v.trim().length === 0) {
     setError("Email can not be empty");
     return false
    } else {
     setError(false)
     return true
    }
   })
   if (isValid.every(v => v === true)) {
    setEmailsList([... new Set([...emailsList, ...list.map(e => e.trim())])])
    setEmail("")
   }
  }

 }


 const sendEmail = (e) => {
  e.preventDefault()
 }

 const handleRemoveEmail = (index) => {
  const list = [...emailsList]
  list.splice(index, 1)
  setEmailsList([...list])

 }
 return (
  <React.Fragment>
   <div className="main-panel">
    <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />

    <div className="content-wrapper">
     <div className="text-left">
      <h5 className="dash-head">Invite Admin</h5>
     </div>
     <div className='w-md-75 w-100 p-2 border' >
      <div className='d-flex justify-content-between align-items-center'>
       <div className='flex-grow-1 d-flex align-items-center'>
        <i className="mdi mdi-email mx-2"></i>
        <input type="email" id="email" className='border-0 p-1 flex-grow-1 w-100' name="email" placeholder="Email" value={email} onChange={(e) => eventHandler(e)} />
       </div>
       <div className='mx-2' >
        <span className='send-btn pointer text-info' onClick={sendEmail}>Send</span>
       </div>
      </div>
     </div>
     {error &&
      <span className='text-red small d-block'>* {error}</span>}



     <div className='mt-3 fw-m text-red pointer' onClick={addEmails}>Add Email</div>
     <div className='mt-3 fw-m text-red pointer'>Copy invite link</div>
     <div>
      <div className="table-responsive">
       <table className="table event-table mt-30">
        <thead>
         <tr>
          <th>Emails</th>
          <th></th>
         </tr>
        </thead>

        {
         emailsList.length === 0 ? <>
          <div>No Emails Added</div>
         </> : <>
          <tbody>
           {
            emailsList.map((email, i) => {
             return <tr key={i}>
              <td>{email}</td>
              <td><span onClick={() => handleRemoveEmail(i)}><i className="mdi mdi-close pointer" style={{ fontSize: '20px', color: '#198AE3' }}></i></span></td>
             </tr>
            })
           }
          </tbody>
         </>
        }


       </table>
      </div>
     </div>
    </div>
   </div>
   <Toaster />
  </React.Fragment>
 )
}

export default InviteAdmin