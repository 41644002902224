import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Toaster from "../../../Toaster/Toaster";
import {
  withRouter,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { connect, useSelector, useParams } from "react-redux";
import { createPaymentCode } from "../../../service/action/userAction";
import { RESET_CREATE_PAYMENT_CODE } from "../../../service/constants";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  AMOUNT,
  AMOUNT_DUE,
  BALANCE_DUE_DAYS,
  EVENT_TYPE,
  NUMERIC,
  PAYMENT_CODE,
  PAYMENT_TERM_CONDITION,
} from "../../../Validation/club";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";

const CreatePayment_Code = (props) => {
  const location = useLocation();
  const history = useHistory();

  const [addData, setVal] = useState("");
  const [state, setState] = useState({
    title: "",
    description: "",
    amount: "",
    paymentTerms: "1",
    frontAmount: "",
    afterBalanceDue: "",
    balanceDueReminder: [""],
    terms_condition: "",
  });

  const [errors, setErrors] = useState({
    title: false,
    description: false,
    amount: false,
    frontAmount: false,
    terms_condition: false,
  });
  // const [addedData, showData] = useState(0)

  const createEventMsg = useSelector(
    (state) => state.createPaymentCodeReducer.message
  );

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    switch (name) {
      case "title":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            [name]: "Enter title",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            [name]: false,
          }));
        }

      case "description":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            [name]: "Enter description",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            [name]: false,
          }));
        }

      case "amount":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            [name]: "Enter Amount",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            [name]: false,
          }));
        }

      case "frontAmount":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            [name]: "Enter Amount due up front",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            [name]: false,
          }));
        }

      default:
        break;
    }
  };

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setState((prevState) => ({
      ...prevState,
      terms_condition: data,
    }));

    if (data.length === 0) {
      setErrors((prevState) => ({
        ...prevState,
        terms_condition: "Enter Terms and Condition",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        terms_condition: false,
      }));
    }
  };

  const handleAddField = () => {
    const list = [...state.balanceDueReminder];
    list.push("");
    setState((prevState) => ({
      ...prevState,
      balanceDueReminder: [...list],
    }));
  };

  const handleRemoveField = (index) => {
    const list = [...state.balanceDueReminder];
    list.splice(index, 1);
    setState((prevState) => ({
      ...prevState,
      balanceDueReminder: [...list],
    }));
  };

  const submitRequest = (e) => {
    e.preventDefault();

    if (state.title === "") {
      setErrors((prevState) => ({
        ...prevState,
        title: "Enter title",
      }));
    }
    if (state.description === "") {
      setErrors((prevState) => ({
        ...prevState,
        description: false,
      }));
    }
    if (state.amount === "") {
      setErrors((prevState) => ({
        ...prevState,
        amount: "Enter Amount",
      }));
    }
    if (state.frontAmount === "")
      setErrors((prevState) => ({
        ...prevState,
        frontAmount: "Enter Amount due up front",
      }));

    if (
      state.title !== "" &&
      state.description !== "" &&
      state.amount !== "" &&
      state.frontAmount !== ""
    ) {
      props.paymentcodehandler({
        type: 1,
        name: state.title,
        description: state.description,
        amount: state.amount,
        payment_terms_type: state.paymentTerms,
        payment_terms: state.frontAmount,
        payment_due: state.afterBalanceDue,
        reminder: state.balanceDueReminder,
        payment_required: 1,
        terms_condition: state.terms_condition,
      });
    }
  };

  useEffect(() => {
    if (props.submitRequest?.success) {
      // notify(createEventMsg.message)
      // props.resetCreatePaymentCode()
      history.push(`/payment/payment-code`);
    }
  }, [props.submitRequest]);

  return (
    <>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Create Payment Code </h5>
          </div>

          <div className="row col-md-6 px-0 mx-0">
            <div className="col-md-12 mt-3">
              <div>
                <input
                  type="text"
                  name="title"
                  value={state.title}
                  className="form-control team-name"
                  placeholder="Title *"
                  onChange={handleOnChange}
                />
                {errors.title && (
                  <div className="text-red small">* {errors.title}</div>
                )}
              </div>

              <div className="mt-3">
                <input
                  type="text"
                  name="description"
                  value={state.description}
                  className="form-control team-name"
                  placeholder="Description *"
                  onChange={handleOnChange}
                />
                {errors.description && (
                  <div className="text-red small">* {errors.description}</div>
                )}
              </div>
              <div className="mt-3 text-gray ">Total Amount *</div>
              <div className="mt-3">
                <input
                  type="number"
                  name="amount"
                  value={state.amount}
                  className="form-control team-name number"
                  placeholder="Total Amount *"
                  onChange={handleOnChange}
                />
                {errors.amount && (
                  <div className="text-red small">* {errors.amount}</div>
                )}
              </div>
              <div className="mt-3 text-gray">
                <div>Payment Terms *</div>
                <div className="d-flex mt-3">
                  <div>
                    <input
                      id="%terms"
                      name="paymentTerms"
                      value={"1"}
                      type={"radio"}
                      onChange={handleOnChange}
                      defaultChecked
                      className="d-none"
                    />
                    <label htmlFor="%terms">%</label>
                  </div>
                  <div className="ml-24">
                    <input
                      id="$terms"
                      name="paymentTerms"
                      value={"0"}
                      type={"radio"}
                      onChange={handleOnChange}
                      className="d-none"
                    />
                    <label htmlFor="$terms">$</label>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <input
                  type="number"
                  name="frontAmount"
                  value={state.frontAmount}
                  className="form-control team-name number"
                  placeholder="Amount due up front *"
                  onChange={handleOnChange}
                />
                {errors.frontAmount && (
                  <div className="text-red small">* {errors.frontAmount}</div>
                )}
              </div>
              <div className="mt-3 text-gray ">Balance due *</div>
              <div className="mt-3">
                <input
                  type="number"
                  name="afterBalanceDue"
                  value={state.afterBalanceDue}
                  className="form-control team-name number"
                  placeholder="Balance due in days after initial payment *"
                  onChange={handleOnChange}
                />
              </div>
              <div className="mt-3 text-gray">
                <div>balance due Reminder *</div>
                {state.balanceDueReminder.map((e, i) => {
                  const list = [...state.balanceDueReminder];
                  return (
                    <div className="mt-3 d-flex align-items-center" key={i}>
                      <input
                        type="number"
                        name="balanceDueReminder"
                        value={list[i]}
                        className="form-control team-name number"
                        placeholder="No. of days before balance due *"
                        onChange={(e) => {
                          list[i] = e.target.value;
                          setState((prevState) => ({
                            ...prevState,
                            balanceDueReminder: [...list],
                          }));
                        }}
                      />
                      {i === list.length - 1 ? (
                        <div className="pointer ml-3" onClick={handleAddField}>
                          <i
                            className="mdi mdi-plus text-info"
                            style={{ fontSize: "26px" }}
                          ></i>
                        </div>
                      ) : (
                        <div
                          className="pointer ml-3"
                          onClick={() => handleRemoveField(i)}
                        >
                          <i
                            className="mdi mdi-close text-red"
                            style={{ fontSize: "26px" }}
                          ></i>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="mt-3">
                <label className="text-gray fs-13">
                  +Add Terms_Conditions*
                </label>
                <div name="terms_condition" id="terms_condition">
                  <CKEditor
                    editor={ClassicEditor}
                    data={state.terms_condition}
                    onChange={handleChange}
                    name="terms_condition"
                    id="terms_condition"
                  />
                  {errors.terms_condition && (
                    <div className="text-red small">
                      * {errors.terms_condition}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-3 text-left">
                <a
                  className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                  onClick={submitRequest}
                >
                  Send
                </a>
              </div>
            </div>
            <Toaster />
          </div>
        </div>
      </div>
    </>
  );
};

// export default withRouter(CreateInvoice)
const mapStateToProp = (state) => ({
  submitRequest: state.createPaymentCodeReducer,
});

const mapDispatchToProps = (dispatch) => ({
  paymentcodehandler: (user) => dispatch(createPaymentCode(user)),
  resetCreatePaymentCode: () => dispatch({ type: RESET_CREATE_PAYMENT_CODE }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(CreatePayment_Code));
// 670
