export function convertTo12HourFormat(time24) {
  // Split the time into hours and minutes
  const [hours, minutes] = time24.split(":");

  // Convert the hours to a number
  const hoursNum = parseInt(hours, 10);

  // Determine whether it's AM or PM
  const period = hoursNum >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const hours12 = hoursNum % 12 || 12;

  // Create the formatted time string
  const time12 = `${hours12}:${minutes} ${period}`;

  return time12;
}
