import {
  CLEAR_ORG_NOTIFICATIONS_ERROR,
  CLEAR_ORG_NOTIFICATIONS_LOADING,
  CLEAR_ORG_NOTIFICATIONS_SUCCESS,
  RESET_CLEAR_ORG_NOTIFICATIONS,
} from "../constants";

let initialState = {
  loading: false,
  user: {},
  error: false,
  errors: [],
  success: false,
};

export default function ClearOrgNotificationsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CLEAR_ORG_NOTIFICATIONS_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case CLEAR_ORG_NOTIFICATIONS_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };

    case CLEAR_ORG_NOTIFICATIONS_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    case RESET_CLEAR_ORG_NOTIFICATIONS:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        user: {},
      };
    default:
      return state;
  }
}
