import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "@dmuy/timepicker/dist/mdtimepicker.css";
import mdtimepicker from "@dmuy/timepicker";
import classNames from "classnames";
import { Link, Redirect, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  getAllCity,
  getAllPaymentCode,
  getAllSeason,
  getAllState,
  getPaymentCode,
  getPaymentCode1,
  saveEventData,
  sendImage,
} from "../../../service/action/userAction";
import { RESET_CREATE_EVENT } from "../../../service/constants";
import Spinner from "../../../Spinner/Spinner";
import {
  END_DATE,
  END_TIME,
  EVENT_ACCESS,
  EVENT_NAME,
  EVENT_PAYMENTCODE,
  EVENT_SEASON,
  EVENT_TYPE,
  FACILITY_ADDRESS,
  FACILITY_CITY,
  FACILITY_NAME,
  FACILITY_STATE,
  FACILITY_WEBSITE,
  MAX_TEAM,
  START_DATE,
  START_TIME,
} from "../../../Validation/club";
import { PAYMENT_CODE } from "../../../Validation/organizer";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import materialTheme from "../../../materialUi";
import moment from "moment";
import { ThemeProvider } from "@material-ui/core/styles";

const Create_Event = (props) => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.select2();
    // mdtimepicker('#from_time');
    // mdtimepicker('#to_time');
  }, []);
  useEffect(() => {
    window.select2();
    mdtimepicker(".timepicker");
    mdtimepicker(".timepicker");
  });

  const getSeason = props.getSeasonHandler;
  // const getPaymentCode = props.getPaymentCodeHandler;
  const getAllState = props.getAllStateHandler;
  // const getAllCity = props.getAllCityHandler;

  // const getStateId=()=>{
  //     props.getAllCityHandler({})
  // }

  useEffect(() => {
    // getAllCity()
    getAllState();
    getSeason();
    // getPaymentCode()
  }, []);
  const createEventMsg = useSelector((state) => state.createEventReducer);

  const allpayment = useSelector((state) => state.getPaymentcodeReducer.user);
  const paycode1 = useSelector((state) => state.getPaymentcode1Reducer.user);
  const allseason = useSelector((state) => state.getAllSeasonReducer.user);
  const allstate = useSelector((state) => state.getStateReducer.states);
  const allcity = useSelector((state) => state.getCityReducer.citys);
  const [startDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  // const [count, setcount] = useState();
  // const [personcount, setPersoncount] = useState();
  // const [counter, setCounter] = useState(1);
  // const [personcounter, setPersonCounter] = useState(1);
  // const fields = [];
  // const person = [];

  // const formatYmd = date => date.toISOString().slice(0, 10);

  {
    /* //-----------=====-----------Add contact person change Start-----------====----------- */
  }

  // const [contactPerson, setContectPerson] = useState([{ person: "" }])

  // // handle click event of the Remove button(contect person)
  // const handleRemoveContact = index => {
  //     const list = [...contactPerson];
  //     list.splice(index, 1);
  //     setContectPerson(list);
  // };

  // // handle input change(contact person)
  // const handleContactChange = (e, index) => {
  //     const { name, value } = e.target;
  //     const list = [...contactPerson];
  //     list[index][name] = value;
  //     setContectPerson(list);
  // };

  // // handle click event of the Add button(contact person)
  // const handleAddContact = () => {
  //     setContectPerson([...contactPerson, {
  //         person: ""
  //     }]);
  // };

  {
    /* //-----------=====-----------Add contact person change End-----------====----------- */
  }

  {
    /* //-----------=====-----------Add facility Info Start-----------====----------- */
  }

  // Try this demo
  const [inputList, setInputList] = useState([
    {
      facility_name: "",
      facility_address: "",
      facility_website: "",
      facility_state: "",
      facility_city: "",
    },
  ]);

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value, id } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    switch (name) {
      case "facility_state":
        if (value !== "") {
          props.getAllCityHandler({ id: value });
        }
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            facility_state: FACILITY_STATE,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            facility_state: false,
          }));
        }
        break;

      case "facility_city":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            facility_city: FACILITY_CITY,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            facility_city: false,
          }));
        }

        break;
      case "facility_website":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            facility_website: FACILITY_WEBSITE,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            facility_website: false,
          }));
        }
        break;
      case "facility_name":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            facility_name: FACILITY_NAME,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            facility_name: false,
          }));
        }

        break;
      case "facility_address":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            facility_address: FACILITY_ADDRESS,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            facility_address: false,
          }));
        }
        break;
      default:
        break;
    }
    // if ( name == state) {
    //     props.getAllCityHandler({ id: value })
    // }
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        facility_name: "",
        facility_address: "",
        facility_website: "",
        facility_state: "",
        facility_city: "",
      },
    ]);
  };

  {
    /* //-----------=====-----------Add Facility Info End-----------====----------- */
  }

  {
    /* //-----------=====-----------Add Event Date Times Start -----------====----------- */
  }

  // Start date/ start time +end time field
  const [eventDate, setEventDate] = useState([
    {
      date: null,
      start_time: "",
      end_time: "",
    },
  ]);

  // handle click event of the Add button
  const handleAddEventDate = () => {
    setEventDate([
      ...eventDate,
      {
        date: null,
        start_time: "",
        end_time: "",
      },
    ]);
  };

  // handle click event of the Remove button
  const handleRemovedate = (index) => {
    const list = [...eventDate];
    list.splice(index, 1);
    setEventDate(list);
  };

  // handle input change
  const handleEventDateChange = (e, i) => {
    const { name, value, id } = e.target;
    // alert("hello")

    const list = [...eventDate];

    list[i][name] = value;
    setEventDate(list);

    switch (name) {
      case "date":
        if (value === "" || value === null) {
          setErrors((prevState) => ({
            ...prevState,
            startDate: START_DATE,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            startDate: false,
          }));
        }
        break;

      case "start_time":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            start_time: START_TIME,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            start_time: false,
          }));
        }
        break;
      case "end_time":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            end_time: END_TIME,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            end_time: false,
          }));
        }
        break;
      default:
        break;
    }
  };

  {
    /* //-----------=====-----------Add Event Date Times  End-----------====----------- */
  }

  //   const getstatecity=(props)=>{
  //     props.getAllCityHandler({ id: props.x.state })
  //   }
  const checkVal = (e) => {
    const { id, value, name } = e.target;
    setState((prevState) => ({
      ...prevState,
      auto_payment: value == 0 ? 1 : value == 1 ? 0 : 1,
    }));
  };

  const [image, setImage] = useState("");
  const [pitcher, setPitcher] = useState({
    file: {},
  });

  const onChangePicture = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    if (event.target.files && event.target.files[0]) {
      setPitcher({ file: event.target.files[0] });
    }
  };

  const [state, setState] = useState({
    // startDate: "",
    // EndDate: "",
    from_time: "",
    to_time: "",
    event_name: "",
    // facility_name: '',
    // address: '',
    maxTeam: "",
    maxPlayer: "",
    // city: '',
    // state: '',
    option: "",
    event_access: "",
    // website: '',
    payment: "",
    payment1: "",
    person: "",
    season: "",
    numPlayers: 0,
    eventFull: 0,
    remainingSpots: 0,
    schedual_tab: 0,
    auto_payment: 0,
  });

  const [errors, setErrors] = useState({
    startDate: false,
    EndDate: false,
    from_time: false,
    start_time: false,
    end_time: false,
    to_time: false,
    event_name: false,
    facility_name: false,
    facility_address: false,
    facility_city: false,
    facility_state: false,
    facility_website: false,
    maxTeam: false,
    option: false,
    event_access: false,
    payment: false,
    payment1: false,
    person: false,
    season: false,
    auto_payment: false,
  });

  const eventHandler = (e) => {
    const { id, value, name } = e.target;

    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    switch (name) {
      case "startDate":
        setStartDate(value);

        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            startDate: START_DATE,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            startDate: false,
          }));
        }
        break;
      case "EndDate":
        setEndDate(value);

        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            EndDate: END_DATE,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            EndDate: false,
          }));
        }
        break;
      // case "from_time":
      //     if (value.length == 0) {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             from_time: "Value can not be empty"
      //         }))
      //     }
      //     else {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             from_time: false
      //         }))
      //     }
      //     break;

      case "event_name":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            event_name: EVENT_NAME,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            event_name: false,
          }));
        }
        break;

      // case "facility_name":
      //     if (value.length == 0) {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             facility_name: "Facility name can not be empty."
      //         }))
      //     }
      //     else {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             facility_name: false
      //         }))
      //     }

      //     break;
      // case "website":
      //     if (value.length == 0) {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             website: "Website can not be empty."
      //         }))
      //     }
      //     else {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             website: false
      //         }))
      //     }
      //     break;
      // case "address":
      //     if (value.length == 0) {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             address: "Address can not be empty."
      //         }))
      //     }
      //     else {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             address: false
      //         }))

      //     }
      //     break;
      case "from_time":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            from_time: START_TIME,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            from_time: false,
          }));
        }
        break;
      case "to_time":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            to_time: END_TIME,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            to_time: false,
          }));
        }
        break;
      // case "max-team":
      //     if (value.length == 0) {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             maxTeam: MAX_TEAM
      //         }))
      //     }
      //     else {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             maxTeam: false
      //         }))
      //     }
      //     break;
      // case "city":
      //     if (value.length == 0) {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             city: "City can not be empty."
      //         }))
      //     }
      //     else {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             city: false
      //         }))
      //     }

      //     break;
      // case "state":
      //     if (value !== "") {
      //         props.getAllCityHandler({ id: value })
      //     }
      //     if (value.length == 0) {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             state: "State can not be empty."
      //         }))
      //     }
      //     else {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             state: false
      //         }))
      //     }
      //     break;
      case "event_type":
        if (value === 0 && value !== "#") {
          props.getPaymentCodeHandler({ id: 0 });
        }
        if (value === 1 && value !== "#") {
          props.getPaymentCodeHandler({ id: 1 });
        }
        if (value === 2 && value !== "#") {
          props.getPaymentCodeHandler({ id: 0 });
          props.getPaymentCodeHandler1({ id: 1 });
        }
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            option: EVENT_TYPE,
          }));

          setState((prevState) => ({
            ...prevState,
            option: value,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            option: false,
          }));

          setState((prevState) => ({
            ...prevState,
            option: value,
          }));
        }
        break;

      case "season":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            season: EVENT_SEASON,
          }));

          setState((prevState) => ({
            ...prevState,
            season: value,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            season: false,
          }));

          setState((prevState) => ({
            ...prevState,
            season: value,
          }));
        }
        break;

      case "event_access":
        if (value.length === 0 || value === "") {
          setErrors((prevState) => ({
            ...prevState,
            event_access: EVENT_ACCESS,
          }));
          setState((prevState) => ({
            ...prevState,
            event_access: value,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            event_access: false,
          }));

          setState((prevState) => ({
            ...prevState,
            event_access: value,
          }));
        }
        break;
      case "payment":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            payment: PAYMENT_CODE,
          }));

          setState((prevState) => ({
            ...prevState,
            payment: value,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            payment: false,
          }));

          setState((prevState) => ({
            ...prevState,
            payment: value,
          }));
        }
        break;
      case "payment1":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            payment1: PAYMENT_CODE,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            payment1: false,
          }));
        }
        break;
      case "person":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            person: "Value can not be empty",
          }));

          // setState(prevState => ({
          //     ...prevState,
          //     person: value
          // }))
        } else {
          setErrors((prevState) => ({
            ...prevState,
            person: false,
          }));

          // setState(prevState => ({
          //     ...prevState,
          //     person: value
          // }))
        }
        break;
      case "numPlayers":
        if (value === 0) {
          setState((prevState) => ({
            ...prevState,
            numPlayers: 1,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            numPlayers: 0,
          }));
        }
        break;
      case "remainingSpots":
        if (value === 0) {
          setState((prevState) => ({
            ...prevState,
            remainingSpots: 1,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            remainingSpots: 0,
          }));
        }
        break;
      case "eventFull":
        if (value === 0) {
          setState((prevState) => ({
            ...prevState,
            eventFull: 1,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            eventFull: 0,
          }));
        }
        break;
      case "schedual_tab":
        if (value === 0) {
          setState((prevState) => ({
            ...prevState,
            schedual_tab: 1,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            schedual_tab: 0,
          }));
        }
        break;

      default:
    }
  };

  const saveData = (e) => {
    // alert("click")
    e.preventDefault();

    const dateFormateChange = eventDate.map((v, i) => ({
      // date: v.date.toISOString().slice(0, 10),
      date: moment(v.date).format("MM/DD/YYYY"),
      // date: v.date,
      start_time: v.start_time,
      end_time: v.end_time,
    }));

    if (state.event_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        event_name: EVENT_NAME,
      }));
    }

    if (inputList[0].facility_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        facility_name: FACILITY_NAME,
      }));
    }

    // if (inputList[0].facility_website == "") {
    //     setErrors(prevState => ({
    //         ...prevState,
    //         facility_website: FACILITY_WEBSITE
    //     }))
    // }
    if (inputList[0].facility_address === "") {
      setErrors((prevState) => ({
        ...prevState,
        facility_address: FACILITY_ADDRESS,
      }));
    }

    // if (state.maxTeam == "") {
    //     setErrors(prevState => ({
    //         ...prevState,
    //         maxTeam: MAX_TEAM
    //     }))
    // }
    // if (state.availability == "") {
    //     setErrors(prevState => ({
    //         ...prevState,
    //         availability: "Availability can not be empty."
    //     }))
    // }
    if (state.option === "") {
      setErrors((prevState) => ({
        ...prevState,
        option: EVENT_TYPE,
      }));
    }
    if (state.season === "") {
      setErrors((prevState) => ({
        ...prevState,
        season: EVENT_SEASON,
      }));
    }
    if (state.event_access === "") {
      setErrors((prevState) => ({
        ...prevState,
        event_access: EVENT_ACCESS,
      }));
    }
    if (state.payment === "") {
      setErrors((prevState) => ({
        ...prevState,
        payment: EVENT_PAYMENTCODE,
      }));
    }

    if (state.option === 2 && state.payment1 === "") {
      setErrors((prevState) => ({
        ...prevState,
        payment1: EVENT_PAYMENTCODE,
      }));
    }
    // if (state.person == "") {
    //     setErrors(prevState => ({
    //         ...prevState,
    //         person: "Value can not be empty"
    //     }))
    // }

    if (eventDate[0].date === "") {
      setErrors((prevState) => ({
        ...prevState,
        startDate: START_DATE,
      }));
    }

    if (eventDate[0].start_time === "") {
      setErrors((prevState) => ({
        ...prevState,
        start_time: START_TIME,
      }));
    }
    if (eventDate[0].end_time === "") {
      setErrors((prevState) => ({
        ...prevState,
        end_time: END_TIME,
      }));
    }
    if (inputList[0].facility_city === "") {
      setErrors((prevState) => ({
        ...prevState,
        facility_city: FACILITY_CITY,
      }));
    }
    if (inputList[0].facility_state === "") {
      setErrors((prevState) => ({
        ...prevState,
        facility_state: FACILITY_STATE,
      }));
    }

    if (
      eventDate[0].start_time !== "" &&
      eventDate[0].date !== "" &&
      eventDate[0].end_time !== "" &&
      state.event_name !== "" &&
      inputList[0].facility_name !== "" &&
      inputList[0].facility_address !== "" &&
      state.availability !== "" &&
      state.option !== "" &&
      state.event_access !== "" &&
      state.payment !== "" &&
      inputList[0].facility_city !== "" &&
      inputList[0].facility_state !== "" 
    ) {
      // for (let i = 1; i <= count; i++) {
      //     const event_facilityinfo = []
      //     event_facilityinfo.push([{
      //         facility_name: state.facility_name,
      //         facility_city: state.city,
      //         facility_state: state.state,
      //         facility_website: state.website,
      //         facility_address: state.address
      //     }])
      // }
      props.createEventHandler({
        event_name: state.event_name,
        event_season: state.season,
        event_type: state.option,
        // event_start_date: startDate.toISOString().slice(0, 10),
        // event_end_date: EndDate.toISOString().slice(0, 10),
        // event_start_time: state.from_time,
        // event_end_time: state.to_time,
        event_payment_code:
          state.payment1 !== ""
            ? [state.payment, state.payment1]
            : [state.payment],
        event_facility_info: inputList,
        event_max_team: state.maxTeam !== "" ? state.maxTeam : 0,
        event_max_player: state.maxPlayer !== "" ? state.maxPlayer : 0,
        event_access: state.event_access,
        show_number_of_player: state.numPlayers,
        show_remaining_spots: state.remainingSpots,
        show_event_is_full: state.eventFull,
        show_schedule: state.schedual_tab,
        // contact_person: contactPerson,
        auto_payment: state.auto_payment,
        event_date_time: dateFormateChange,
        // event_image: pitcher.file
      });
    }
  };

  const itemsRef = useRef([]);
  const itemsRefEnd = useRef([]);

  var el = document.querySelector(".timepicker");
  useEffect(() => {
    itemsRef?.current.forEach((e) => {
      e.addEventListener("timechanged", function (element, i) {
        const list = [...eventDate];
        // handleEventDateChange(element,element.target.dataset.id)

        list[element.target.dataset.id][element.target.name] =
          element.target.value;
        setEventDate(list);
      });
    });
  });
  useEffect(() => {
    itemsRefEnd?.current.forEach((e) => {
      e.addEventListener("timechanged", function (element, i) {
        const list = [...eventDate];
        // handleEventDateChange(element,element.target.dataset.id)

        list[element.target.dataset.id][element.target.name] =
          element.target.value;
        setEventDate(list);
      });
    });
  });
  useEffect(() => {
    if (props.saveData?.success) {
      props.sendImageHandler({
        event_image: pitcher.file,
        event_id: createEventMsg.event_id,
      });
      history.push("/events");
    }
  }, [props.saveData]);

  useEffect(() => {
    if (props.allseason?.success) {
      setLoading(false);
    }
  }, [props.allseason]);

  // const addMore = () => {

  //     setCounter(counter + 1)
  //     // facilityinfo.push({
  //     //     facility_name: "",
  //     //     address: "",
  //     //     website: "",
  //     //     state: "",
  //     //     city: ""
  //     // })
  //     for (let i = 1; i <= counter; i++) {
  //         fields.push(<div className="row" key={i}>
  //             <div className="col-md-4">
  //                 <div className="mt-24">
  //                     <input type="text" className={classNames("form-control team-name", { "error": errors.facility_name })} placeholder="Facility name" id={`facility_name${counter}`} name="facility_name" value={facilityinfo.facility_name} onChange={eventHandler} />

  //                     {errors.facility_name &&
  //                         <span className='text-red small d-block'>* {errors.facility_name}</span>}
  //                 </div>

  //                 <div className="mt-18">
  //                     <input type="text" className="form-control team-name" placeholder="Website" id={`website${counter}`} name="website" value={facilityinfo.website} onChange={eventHandler} />
  //                 </div>

  //             </div>
  //             <div className="col-md-4">
  //                 <div className="mt-24">
  //                     <input type="text" className={classNames("form-control team-name", { "error": errors.address })} placeholder=" Facility address" id={`address${counter}`} name="address" value={facilityinfo.address} onChange={eventHandler} />

  //                     {errors.address &&
  //                         <span className='text-red small d-block'>* {errors.address}</span>}
  //                 </div>
  //             </div>
  //             <div className="col-md-4">
  //                 <div className="row">
  //                     <div className="col-md-6">
  //                         <div className="mt-24">
  //                             {/* <input type="text" className="form-control team-name" placeholder="City" /> */}
  //                             <select className={classNames("form-control team-name", { "error": errors.state })} placeholder="State *" id={`state${counter}`} name="state" value={facilityinfo.state} onChange={eventHandler} >
  //                                 <option value="1">State</option>
  //                                 {allstate.map((v, i) => {
  //                                     return (
  //                                         <option key={v._id} value={v._id}>{v.name}</option>
  //                                     )
  //                                 })}
  //                             </select>
  //                         </div>
  //                     </div>
  //                     <div className="col-md-6">
  //                         <div className="mt-24">
  //                             {/* <input type="text" className="form-control team-name" placeholder="State" /> */}
  //                             <select className={classNames("form-control team-name", { "error": errors.city })} placeholder="City *" id={`city${counter}`} name="city" value={facilityinfo.city} onChange={eventHandler} >
  //                                 <option value="1">City</option>
  //                                 {allcity.map((v, i) => {
  //                                     return (
  //                                         <option key={v._id} value={v._id}>{v.name}</option>
  //                                     )
  //                                 })}
  //                             </select>
  //                         </div>
  //                     </div>

  //                 </div>
  //             </div>

  //         </div>)
  //     }
  //     setcount(fields)
  // }

  // const addContactPerson = () => {
  //     setPersonCounter(personcounter + 1)
  //     for (let i = 1; i <= personcounter; i++) {
  //         person.push(<div className="form-group event-drp">
  //             <select className={classNames("form-control team-name payterm", { "error": errors.person })} id={`person${personcounter}`} name="person" value={contactPerson.person} onChange={eventHandler}>
  //                 <option value="0" >Select value</option>
  //                 <option value="1">Type 1</option>
  //                 <option value="2">Type 2</option>
  //                 <option value="3">Type 3</option>
  //             </select>
  //         </div>)
  //     }
  //     setPersoncount(person)
  // }

  const notify = (msg) => toast(msg);

  useEffect(() => {
    if (loading === false) {
      window.select2();
      mdtimepicker("#from_time");
      mdtimepicker("#to_time");
    }
  }, [loading]);

  const Today = new Date().toISOString().slice(0, 10);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>
          {!loading && allseason ? (
            <>
              {allseason.length == 0 ? (
                <>
                  <div className="alert alert-danger" role="alert">
                    First you have to{" "}
                    <Link to="/season/create-season" className="alert-link">
                      create new season !!!
                    </Link>
                  </div>
                </>
              ) : null}
              {allpayment ? (
                <>
                  {state.option == 0 &&
                  state.option !== "" &&
                  allpayment.length == 0 ? (
                    <>
                      <div className="alert alert-danger" role="alert">
                        First you have to{" "}
                        <Link
                          to="/payment/payment-code/create-payment-code"
                          className="alert-link"
                        >
                          create new payment code{" "}
                        </Link>
                        for club only !!!
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              {allpayment ? (
                <>
                  {state.option == 1 &&
                  state.option !== "" &&
                  allpayment.length == 0 ? (
                    <>
                      <div className="alert alert-danger" role="alert">
                        First you have to{" "}
                        <Link
                          to="/payment/payment-code/create-payment-code"
                          className="alert-link"
                        >
                          create new payment code{" "}
                        </Link>{" "}
                        for parent/player !!!
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              {state.option == 2 &&
              allpayment.length == 0 &&
              paycode1.length == 0 ? (
                <>
                  <div className="alert alert-danger" role="alert">
                    First you have to{" "}
                    <Link
                      to="/payment/payment-code/create-payment-code"
                      className="alert-link"
                    >
                      create new payment code{" "}
                    </Link>{" "}
                    for club and parent/player !!!
                  </div>
                </>
              ) : (
                <></>
              )}
              {state.option == 2 &&
              allpayment.length == 0 &&
              paycode1.length !== 0 ? (
                <>
                  <div className="alert alert-danger" role="alert">
                    First you have to{" "}
                    <Link
                      to="/payment/payment-code/create-payment-code"
                      className="alert-link"
                    >
                      create new payment code{" "}
                    </Link>{" "}
                    for club only !!!
                  </div>
                </>
              ) : (
                <></>
              )}

              {state.option == 2 &&
              allpayment.length !== 0 &&
              paycode1.length == 0 ? (
                <>
                  <div className="alert alert-danger" role="alert">
                    First you have to{" "}
                    <Link
                      to="/payment/payment-code/create-payment-code"
                      className="alert-link"
                    >
                      create payment code for parent/player{" "}
                    </Link>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="text-left">
                <div className="create-team-head-event">
                  <h5 className="dash-head">Create Event</h5>
                  <button className="btn-save " onClick={saveData}>
                    Save
                  </button>
                </div>
                <div className="text-left mt-30">
                  <h6 className="payment">Basic Info</h6>
                  <div className="basic-info">
                    <input
                      type="file"
                      className="input-file file-upload"
                      onChange={onChangePicture}
                      id="file-upload"
                    />
                    <label className="change-pic" htmlFor="file-upload">
                      {/* "./images/faces/face3.jpg" */}
                      <img
                        src={image == "" ? "./images/faces/face3.jpg" : image}
                        className="img-fluid"
                      />
                    </label>
                    <span className="ml-12">Upload Picture</span>
                  </div>
                </div>
              </div>

              <div className="col-md-6 d-flex mt-4 px-0">
                <div className="mr-4">
                  <input
                    type="radio"
                    id="free"
                    name="eventType"
                    className="mr-2"
                    value="Free"
                  />
                  <label htmlFor="free">Free Events</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="paid"
                    name="eventType"
                    className="mr-2"
                    value="Paid"
                  />
                  <label htmlFor="paid">Paid Events</label>
                </div>
              </div>

              {/* //-----------=====-----------Event_Name/Date/Time Start-----------====----------- */}
              <div className="row col-md-6 px-0">
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      error: errors.event_name,
                    })}
                    placeholder="Event Name *"
                    id="event_name"
                    value={state.event_name}
                    name="event_name"
                    onChange={eventHandler}
                  />
                  {errors.event_name && (
                    <span className="text-red small d-block">
                      * {errors.event_name}
                    </span>
                  )}
                </div>
                <div className="col-md-6 mt-18">
                  <div className="form-group event-drp">
                    <select
                      className={classNames("form-control team-name payterm", {
                        error: errors.option,
                      })}
                      id="event_type"
                      name="event_type"
                      value={state.option}
                      onChange={eventHandler}
                    >
                      <option value="">Event Type *</option>
                      <option value="0">Club only</option>
                      <option value="1">Parent/player only</option>
                      <option value="2">Club & Parent</option>
                    </select>
                    {errors.option && (
                      <span className="text-red small d-block">
                        * {errors.option}
                      </span>
                    )}
                  </div>
                </div>
                {/* //-----------=====-----------Event Date/Time start-----------====----------- */}
                {eventDate.map((x, i) => {
                  return (
                    <div key={i} className="col-md-12">
                      {eventDate.length !== 1 && (
                        <div
                          className="text-left add-more"
                          style={{ marginTop: "14px" }}
                        >
                          <Link
                            to="#"
                            className="text-decoration-none"
                            onClick={() => handleRemovedate(i)}
                          >
                            {" "}
                            -Remove
                          </Link>
                        </div>
                      )}
                      <div className="row" key={i}>
                        <div className="col-md-12 mt-18">
                          <ThemeProvider theme={materialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                value={x.date}
                                placeholder="mm/dd/yyyy"
                                format="mm/dd/yyyy"
                                // InputProps={{ disableUnderline: true }}
                                mask="__/__/____"
                                // variant="inline"
                                inputVariant="outlined"
                                autoOk="true"
                                minDate={Today}
                                InputAdornmentProps={{ position: "end" }}
                                className={classNames(
                                  " form-control team-name"
                                )}
                                // error={errors.startDate}
                                invalidDateMessage="Invalid Date Format"
                                onChange={(e) =>
                                  handleEventDateChange(
                                    {
                                      target: {
                                        value: e,
                                        name: "date",
                                        id: "date",
                                      },
                                    },
                                    i
                                  )
                                }
                              />
                            </MuiPickersUtilsProvider>
                          </ThemeProvider>
                          {/* <DatePicker
                                                        selected={x.date}
                                                        // dateFormat="dd-MM-yyyy"
                                                        // dateFormat="yyyy-MM-dd"
                                                        minDate={new Date()}
                                                        placeholderText="Start Date *"
                                                        
                                                        onChange={(e) =>
                                                            handleEventDateChange({
                                                                target: {
                                                                    value: e,
                                                                    name: "date",
                                                                    id: "date",
                                                                },
                                                            }, i)
                                                        }
                                                        className={classNames("form-control team-name", {
                                                            error: errors.startDate,
                                                        })}
                                                    /> */}
                          {/* <CalendarIcon /> */}

                          {/* {errors.startDate && (
                                                        <span className="text-red small d-block">
                                                            * {errors.startDate}
                                                        </span>
                                                    )} */}
                        </div>
                        <div className="col-md-6 mt-18">
                          <input
                            type="text"
                            data-mintime="now"
                            className={classNames(
                              "form-control timepicker team-name",
                              {
                                error: errors.from_time,
                              }
                            )}
                            placeholder="Start Time *"
                            // id="start_time"
                            data-id={`${i}`}
                            ref={(el) => (itemsRef.current[i] = el)}
                            value={x.start_time}
                            name="start_time"
                            onChange={(e) => handleEventDateChange(e, i)}
                            autoComplete="off"
                          />
                          {errors.start_time && (
                            <span className="text-red small d-block">
                              * {errors.start_time}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6 mt-18">
                          <input
                            type="text"
                            data-mintime="now"
                            className={classNames(
                              "form-control timepicker team-name",
                              {
                                error: errors.end_time,
                              }
                            )}
                            placeholder="End Time *"
                            data-id={`${i}`}
                            ref={(el) => (itemsRefEnd.current[i] = el)}
                            value={x.end_time}
                            name="end_time"
                            onChange={(e) => handleEventDateChange(e, i)}
                            autoComplete="off"
                          />
                          {errors.end_time && (
                            <span className="text-red small d-block">
                              {" "}
                              * {errors.end_time}{" "}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* {count} */}
                      {eventDate.length - 1 == i && (
                        <div
                          className="text-left add-more"
                          style={{ marginTop: "14px" }}
                        >
                          <Link
                            to="#"
                            className="text-decoration-none"
                            onClick={handleAddEventDate}
                          >
                            + Add More
                          </Link>
                        </div>
                      )}
                      <hr />
                    </div>
                  );
                })}
                {/* //-----------=====-----------Event Date/Time End-----------====----------- */}

                {/* //-----------=====-----------Event_Season Start-----------====----------- */}
                <div className="col-md-12 mt-18">
                  {/* js-example-placeholder-single */}
                  <select
                    className={classNames("form-control team-name payterm", {
                      error: errors.season,
                    })}
                    id="season"
                    name="season"
                    value={state.season}
                    onChange={eventHandler}
                  >
                    <option value="">Season *</option>
                    {allseason.map((v, i) => {
                      return (
                        <option key={v._id} value={v._id}>
                          {v.season_name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.season && (
                    <span className="text-red small d-block">
                      * {errors.season}
                    </span>
                  )}
                  {allseason ? (
                    <>
                      <div
                        className="text-left add-more"
                        style={{ marginTop: "14px" }}
                      >
                        <Link
                          to="/season/create-season"
                          className="text-decoration-none"
                        >
                          + Add Season
                        </Link>
                      </div>
                    </>
                  ) : null}
                </div>
                {/* //-----------=====-----------Event_Season End-----------====----------- */}
              </div>

              {/* //-----------=====-----------Facility Info Start-----------====----------- */}
              <div className="text-left mt-42">
                <h6 className="payment">Facility Info</h6>
              </div>
              {inputList.map((x, i) => {
                return (
                  <div key={i} className="row col-md-6 px-0">
                    {inputList.length !== 1 && (
                      <div
                        className="text-left add-more"
                        style={{ marginTop: "14px" }}
                      >
                        <Link
                          to="#"
                          className="text-decoration-none"
                          onClick={() => handleRemoveClick(i)}
                        >
                          -Remove
                        </Link>
                      </div>
                    )}
                    <div className="col-md-6 mt-18">
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          error: errors.facility_name,
                        })}
                        placeholder="Facility Name *"
                        id="facility_name"
                        name="facility_name"
                        // value={state.facility_name}
                        // onChange={eventHandler}
                        value={x.facility_name}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                      {errors.facility_name && (
                        <span className="text-red small d-block">
                          * {errors.facility_name}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 mt-18">
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          error: errors.facility_website,
                        })}
                        placeholder="Website *"
                        id="facility_website"
                        // value={state.website}
                        value={x.facility_website}
                        name="facility_website"
                        // onChange={eventHandler}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                      {errors.facility_website && (
                        <span className="text-red small d-block">
                          * {errors.facility_website}
                        </span>
                      )}
                    </div>
                    <div className="col-md-12 mt-18">
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          error: errors.facility_address,
                        })}
                        placeholder=" Facility Address *"
                        id="facility_address"
                        name="facility_address"
                        value={x.facility_address}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                      {errors.facility_address && (
                        <span className="text-red small d-block">
                          * {errors.facility_address}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mt-24">
                        <select
                          className={classNames("form-control team-name", {
                            error: errors.facility_state,
                          })}
                          placeholder="State *"
                          id="facility_state"
                          name="facility_state"
                          // value={state.state}
                          value={x.facility_state}
                          // onSelect={getstatecity(x.state)}
                          onChange={(e) => handleInputChange(e, i)}
                        >
                          <option value="0">State *</option>
                          {allstate.map((v, i) => {
                            return (
                              <option key={v._id} value={v._id}>
                                {v.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.facility_state && (
                          <span className="text-red small d-block">
                            * {errors.facility_state}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mt-24">
                        <select
                          className={classNames("form-control team-name", {
                            error: errors.facility_city,
                          })}
                          placeholder="City *"
                          id="facility_city"
                          name="facility_city"
                          // value={state.city}
                          // onChange={eventHandler}
                          value={x.facility_city}
                          onChange={(e) => handleInputChange(e, i)}
                        >
                          <option value="0">City *</option>
                          {allcity.map((v, i) => {
                            return (
                              <option key={v._id} value={v._id}>
                                {v.name}
                              </option>
                            );
                          })}
                        </select>

                        {errors.facility_city && (
                          <span className="text-red small d-block">
                            * {errors.facility_city}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* {count} */}
                    {inputList.length - 1 == i && (
                      <div
                        className="text-left add-more col-md-12"
                        style={{ marginTop: "14px" }}
                      >
                        <Link
                          to="#"
                          className="text-decoration-none"
                          onClick={handleAddClick}
                        >
                          + Add More
                        </Link>
                      </div>
                    )}
                    <hr />
                  </div>
                );
              })}
              {/* //-----------=====-----------Facility Info End-----------====----------- */}

              {/* //-----------=====-----------Players Info Start-----------====----------- */}
              <div className="text-left mt-42">
                <h6 className="payment">Players</h6>
              </div>

              <div className="row col-md-6 px-0">
                <div className="col-md-12 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name")}
                    placeholder="Max. Players"
                    name="max_players"
                  />
                  {/* {errors.event_name && <span className='text-red small d-block'>* {errors.event_name}</span>} */}

                  <div className="custom-control custom-switch mt-18">
                    <input
                      type="checkbox"
                      className="custom-control-input payment "
                      id="customSwitch2"
                      name="remainingSpots"
                      value={state.remainingSpots}
                      onChange={eventHandler}
                      checked={state.remainingSpots}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitch2"
                    >
                      Show number of remaining spots
                    </label>
                  </div>

                  <div className="custom-control custom-switch  mt-18">
                    <input
                      type="checkbox"
                      className="custom-control-input payment "
                      id="customSwitch3"
                      name="eventFull"
                      value={state.eventFull}
                      onChange={eventHandler}
                      checked={state.eventFull}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitch3"
                    >
                      Show when event is full
                    </label>
                  </div>

                  <div className="custom-control custom-switch  mt-18">
                    <input
                      type="checkbox"
                      className="custom-control-input paymentn"
                      name="auto_payment"
                      id="auto_payment"
                      value={state.auto_payment}
                      onChange={checkVal}
                      checked={
                        state.auto_payment == 0
                          ? false
                          : state.auto_payment == 1
                          ? true
                          : false
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="auto_payment"
                    >
                      Auto payment request
                    </label>
                  </div>
                </div>
              </div>
              {/* //-----------=====-----------Players Info End-----------====----------- */}

              {/* //-----------=====-----------Event Access/ Payment/ Max team Start-----------====----------- */}
              <div className="row col-md-6 px-0">
                <div className="col-md-12">
                  <div className="text-left mt-42">
                    <h6 className="payment">Event Access</h6>
                    <div className="form-group event-drp">
                      <select
                        className={classNames(
                          "form-control team-name payterm",
                          { error: errors.event_access }
                        )}
                        id="event_access"
                        name="event_access"
                        value={state.event_access}
                        onChange={eventHandler}
                      >
                        <option value="">Event Type *</option>
                        <option value="0">Public</option>
                        <option value="1">Private</option>
                        {/* <option value="1">Type 3</option> */}
                      </select>

                      {errors.event_access && (
                        <span className="text-red small d-block">
                          * {errors.event_access}
                        </span>
                      )}
                    </div>

                    {/* <div className="text-left" style={{ marginTop: "14px" }}>
                                            <input type="checkbox" defaultChecked />
                                            <label className="ml-2 text-blue fs-13">Allow players to register directly </label>
                                        </div> */}
                  </div>
                </div>

                {state.option == 2 && (
                  <div className="col-md-3 ">
                    <div className="text-left mt-42">
                      <h6 className="payment">Parent/Player Payment Code *</h6>
                      <div className="form-group event-drp">
                        <select
                          className={classNames(
                            "form-control team-name payterm",
                            { error: errors.payment1 }
                          )}
                          id="payment1"
                          name="payment1"
                          value={state.payment1}
                          onChange={eventHandler}
                        >
                          <option value="#">
                            Parent/Player Payment Code *
                          </option>
                          {paycode1.map((v, i) => {
                            return (
                              <option key={v._id} value={v._id}>
                                {v.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.payment1 && (
                          <span className="text-red small d-block">
                            * {errors.payment1}
                          </span>
                        )}
                      </div>
                      {state.option == 2 && (
                        <>
                          <div
                            className="text-left add-more d-flex justify-content-end"
                            style={{ marginTop: "22px" }}
                          >
                            <input
                              type="checkbox"
                              name="auto_payment"
                              id="auto_payment"
                              value={state.auto_payment}
                              onChange={checkVal}
                              checked={
                                state.auto_payment == 0
                                  ? false
                                  : state.auto_payment == 1
                                  ? true
                                  : false
                              }
                            />
                            <label className="ml-2 text-blue fs-13">
                              Auto payment request{" "}
                            </label>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {(state.option == 0 && state.option !== "") ||
                state.option == 2 ? (
                  <div className="col-md-4">
                    <div className="text-left mt-42">
                      <h6 className="payment">Teams</h6>
                    </div>
                    <input
                      type="text"
                      className={classNames("form-control team-name")}
                      placeholder="Max. Teams *"
                      id="maxTeam"
                      name="max-team"
                      onChange={eventHandler}
                      value={state.maxTeam}
                    />
                    {/* {errors.maxTeam &&
                                        <span className='text-red small d-block'>* {errors.maxTeam}</span>} */}
                    {/* <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                        <Link to="/create-team" className="text-decoration-none">+ Create Teams</Link>
                                    </div> */}
                  </div>
                ) : state.option == 1 ? (
                  <div className="col-md-4">
                    <div className="text-left mt-42">
                      <h6 className="payment">Players</h6>
                    </div>
                    <input
                      type="text"
                      className={classNames("form-control team-name")}
                      placeholder="Max. Players *"
                      id="maxPlayer"
                      name="max-player"
                      onChange={eventHandler}
                      value={state.maxTeam}
                    />
                    {/* {errors.maxTeam &&
                                        <span className='text-red small d-block'>* {errors.maxTeam}</span>} */}
                    {/* <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                        <Link to="/create-team" className="text-decoration-none">+ Create Teams</Link>
                                    </div> */}
                  </div>
                ) : (
                  ""
                )}
                {state.option == 2 && (
                  <div className="col-md-4">
                    <div className="text-left mt-42">
                      <h6 className="payment">Players</h6>
                    </div>
                    <input
                      type="text"
                      className={classNames("form-control team-name")}
                      placeholder="Max. Player *"
                      id="maxPlayer"
                      name="max-player"
                      onChange={eventHandler}
                      value={state.maxPlayer}
                    />

                    {/* {errors.maxTeam &&
                                        <span className='text-red small d-block'>* {errors.maxTeam}</span>} */}

                    {/* <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                        <Link to="/create-team" className="text-decoration-none">+ Create Teams</Link>
                                    </div> */}
                  </div>
                )}
              </div>
              {/* //-----------=====-----------Event Access/ Payment/ Max team End-----------====----------- */}

              {/* //-----------=====-----------Contact Person Start-----------====----------- */}
              {/* <div className="row">
                                <div className="col-md-4">
                                    <div className="text-left mt-42">
                                        <h6 className="payment">Contact Person</h6> */}
              {/* {
                                            contactPerson.map((x, i) => {
                                                return (
                                                    <>
                                                        {contactPerson.length !== 1 &&
                                                            <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                                                <Link to="#" className="text-decoration-none" onClick={() => handleRemoveContact(i)}>-Remove</Link>
                                                            </div>}
                                                        <div className="form-group event-drp">
                                                            <select className={classNames("form-control team-name payterm", { "error": errors.person })} id="person" name="person" value={x.person} onChange={e => handleContactChange(e, i)}>
                                                                <option key={i} value="0" >Select value</option>
                                                                <option value="1">Type 1</option>
                                                                <option value="2">Type 2</option>
                                                                <option value="3">Type 3</option>
                                                            </select>
                                                           
                                                        </div>

                                                        {contactPerson.length - 1 === i &&

                                                            <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                                                <Link to="#" className="text-decoration-none" onClick={handleAddContact}>+ Add More</Link>
                                                            </div>
                                                        }
                                                      

                                                    </>
                                                )
                                            })
                                        } */}

              {/* </div>
                                </div>
                            </div> */}

              {/* //-----------=====-----------Contact Person End-----------====----------- */}

              {/* //-----------=====-----------Toggle Switch Start-----------====----------- */}
              <div className="row col-md-6 px-0">
                <div className="col-md-6 text-left mt-24">
                  <div className="custom-control custom-switch  mt-24">
                    <input
                      type="checkbox"
                      className="custom-control-input "
                      id="customSwitch1"
                      name="numPlayers"
                      value={state.numPlayers}
                      onChange={eventHandler}
                      checked={state.numPlayers}
                    />
                    <label
                      className="custom-control-label payment"
                      htmlFor="customSwitch1"
                    >
                      Show number of players
                    </label>
                  </div>
                </div>
                <div className="col-md-6 col-md-6 text-left mt-24">
                  <div className="custom-control custom-switch mt-24">
                    <input
                      type="checkbox"
                      className="custom-control-input payment "
                      id="customSwitch4"
                      name="schedual_tab"
                      value={state.schedual_tab}
                      checked={state.schedual_tab}
                      onChange={eventHandler}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitch4"
                    >
                      Show the scheduale tab
                    </label>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <h4>Loading...</h4>
            </>
          )}
          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};
//Connection between component to store
const mapStateToProps = (state) => ({
  saveData: state.createEventReducer,
  submit: state.createEventReducer,
  allseason: state.getAllSeasonReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createEventHandler: (user) => dispatch(saveEventData(user)),
  getSeasonHandler: (user) => dispatch(getAllSeason(user)),
  getPaymentCodeHandler: (user) => dispatch(getAllPaymentCode(user)),
  getAllStateHandler: (user) => dispatch(getAllState()),
  getAllCityHandler: (user) => dispatch(getAllCity(user)),
  getPaymentCodeHandler: (user) => dispatch(getPaymentCode(user)),
  resetCreateEventReducer: () => dispatch({ type: RESET_CREATE_EVENT }),
  getPaymentCodeHandler1: (user) => dispatch(getPaymentCode1(user)),
  sendImageHandler: (user) => dispatch(sendImage(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Create_Event);
