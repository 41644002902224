import React, { useState, useEffect } from "react";
import $ from "jquery";
import { useHistory, withRouter, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import { toast } from "react-toastify";
import {
  RESET_ADD_STAFF_GROUP,
  RESET_PERMISSION,
} from "../../../service/constants";
import Toaster from "../../../Toaster/Toaster";
import {
  getPermissionDetails,
  permission,
} from "../../../service/action/userAction";
import classNames from "classnames";

const Permission = (props) => {
  let history = useHistory();

  const { id } = useParams();
  const getpermission = props.getPermissionDetailshandler;

  useEffect(() => {
    getpermission({ id: id });
  }, [getpermission]);

  const notify = (msg) => toast(msg);

  const getPermissionCheck = useSelector(
    (state) => state.getPermissionDetailsReducer.user.data
  );

  const permissionMsg = useSelector((state) => state.permissionReducer.message);

  const addStaffMsg = useSelector(
    (state) => state.addStaffToGroupReducer.message
  );

  const [state, setState] = useState({
    search: "",
  });

  const UpdateArray = (search) => {
    var query = search;

    $(".filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".pay-row").addClass("d-none");
      } else {
        $(v).parents(".pay-row").removeClass("d-none");
      }
    });
  };

  const [role, setRole] = useState({
    role: "",
  });

  const [check, setCheck] = useState({
    // <<--Event -->>
    DetailsR: 0,
    DetailsW: 0,
    DetailsD: 0,
    TeamR: 0,
    TeamW: 0,
    TeamD: 0,
    DivisionR: 0,
    DivisionW: 0,
    DivisionD: 0,
    ScheduleR: 0,
    ScheduleW: 0,
    ScheduleD: 0,

    // <<--payemnt -->>
    PayReqR: 0,
    PayReqW: 0,
    PayReqD: 0,
    PaycodeR: 0,
    PaycodeW: 0,
    PaycodeD: 0,
    CreditR: 0,
    CreditW: 0,
    CreditD: 0,
    DiscountCodeR: 0,
    DiscountCodeW: 0,
    DiscountCodeD: 0,
    RefundR: 0,
    RefundW: 0,
    RefundD: 0,

    // <<--Club -->>

    ClubdetailsR: 0,
    ClubdetailsW: 0,
    ClubdetailsD: 0,

    //  <<-----season -->>

    SeasondetailsR: 0,
    SeasondetailsW: 0,
    SeasondetailsD: 0,

    //  <<-----liability -->>

    LiabilitydetailsR: 0,
    LiabilitydetailsW: 0,
    LiabilitydetailsD: 0,

    // ClubR: 0,
    // ClubW: 0,
    // ClubD: 0,
    // RoasterR: 0,
    // RoasterW: 0,
    // RoasterD: 0,

    // <<--Profile -->>
    AccountDetailsR: 0,
    AccountDetailsW: 0,
    AccountDetailsD: 0,
    // BankInfoR: 0,
    // BankInfoW: 0,
    // BankInfoD: 0,
    BankinfoR: 0,
    BankinfoW: 0,
    BankinfoD: 0,
    StaffR: 0,
    StaffW: 0,
    StaffD: 0,
    // ReportR: 0,
    // ReportW: 0,
    // ReportD: 0,
  });

  const [errors, setErrors] = useState({
    role: false,
  });

  const roleChange = (e) => {
    const { id, value, name } = e.target;
    setRole((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    switch (name) {
      case "role":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            role: "Role type can not be empty",
          }));

          document.getElementById("role").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            role: false,
          }));
          document.getElementById("role").classList.remove("error");
        }
        break;

      default:
    }
  };

  useEffect(() => {
    if (getPermissionCheck) {
      setCheck({
        //         // <<--Event -->>
        DetailsR: getPermissionCheck?.event?.detail.add,
        DetailsW: getPermissionCheck?.event?.detail.edit,
        DetailsD: getPermissionCheck?.event?.detail.delete,
        TeamR: getPermissionCheck?.event?.team.add,
        TeamW: getPermissionCheck?.event?.team.edit,
        TeamD: getPermissionCheck?.event?.team.delete,
        DivisionR: getPermissionCheck?.event?.division.add,
        DivisionW: getPermissionCheck?.event?.division.edit,
        DivisionD: getPermissionCheck?.event?.division.delete,
        ScheduleR: getPermissionCheck?.event?.schedule.add,
        ScheduleW: getPermissionCheck?.event?.schedule.edit,
        ScheduleD: getPermissionCheck?.event?.schedule.delete,

        // <<--payemnt -->>
        PayReqR: getPermissionCheck?.payment?.payment_request.add,
        PayReqW: getPermissionCheck?.payment?.payment_request.edit,
        PayReqD: getPermissionCheck?.payment?.payment_request.delete,
        PaycodeR: getPermissionCheck?.payment?.payment_code.add,
        PaycodeW: getPermissionCheck?.payment?.payment_code.edit,
        PaycodeD: getPermissionCheck?.payment?.payment_code.delete,
        CreditR: getPermissionCheck?.payment?.credit.add,
        CreditW: getPermissionCheck?.payment?.credit.edit,
        CreditD: getPermissionCheck?.payment?.credit.delete,
        DiscountCodeR: getPermissionCheck?.payment?.discount_code.add,
        DiscountCodeW: getPermissionCheck?.payment?.discount_code.edit,
        DiscountCodeD: getPermissionCheck?.payment?.discount_code.delete,
        RefundR: getPermissionCheck?.payment?.refund.add,
        RefundW: getPermissionCheck?.payment?.refund.edit,
        RefundD: getPermissionCheck?.payment?.refund.delete,

        // <<--Club -->>

        ClubdetailsR: getPermissionCheck?.club?.detail.add,
        ClubdetailsW: getPermissionCheck?.club?.detail.edit,
        ClubdetailsD: getPermissionCheck?.club?.detail.delete,

        SeasondetailsR: getPermissionCheck?.season?.detail.add,
        SeasondetailsW: getPermissionCheck?.season?.detail.edit,
        SeasondetailsD: getPermissionCheck?.season?.detail.delete,

        LiabilitydetailsR: getPermissionCheck?.liability?.detail.add,
        LiabilitydetailsW: getPermissionCheck?.liability?.detail.edit,
        LiabilitydetailsD: getPermissionCheck?.liability?.detail.delete,

        // <<--Profile -->>
        AccountDetailsR: getPermissionCheck?.profile?.account_detail.add,
        AccountDetailsW: getPermissionCheck?.profile?.account_detail.edit,
        AccountDetailsD: getPermissionCheck?.profile?.account_detail.delete,
        BankinfoR: getPermissionCheck?.profile?.bank_info.add,
        BankinfoW: getPermissionCheck?.profile?.bank_info.edit,
        BankinfoD: getPermissionCheck?.profile?.bank_info.delete,
        StaffR: getPermissionCheck?.profile?.staff.add,
        StaffW: getPermissionCheck?.profile?.staff.edit,
        StaffD: getPermissionCheck?.profile?.staff.delete,
      });
      setRole({
        role: getPermissionCheck?.role,
      });
    }
  }, [getPermissionCheck]);

  const checkVal = (e) => {
    const { id, value, name } = e.target;
    // setTeam((prevState => ({
    //     ...prevState,
    //     value: team.value == 0 ? 1 : team.value == 1 ? 0 : 1
    // })))

    setCheck((prevState) => ({
      ...prevState,
      [id]: value == 0 ? 1 : value == 1 ? 0 : 1,
    }));
  };

  const submit = (e) => {
    e.preventDefault();

    props.permissionHandler({
      // role: role.role,
      event: {
        detail: {
          add: !!check.DetailsR ? check.DetailsR : 0,
          edit: !!check.DetailsW ? check.DetailsW : 0,
          delete: !!check.DetailsD ? check.DetailsD : 0,
        },
        team: {
          add: !!check.TeamR ? check.TeamR : 0,
          edit: !!check.TeamW ? check.TeamW : 0,
          delete: !!check.TeamD ? check.TeamD : 0,
        },
        division: {
          add: !!check.DivisionR ? check.DivisionR : 0,
          edit: !!check.DivisionW ? check.DivisionW : 0,
          delete: !!check.DivisionD ? check.DivisionD : 0,
        },
        schedule: {
          add: !!check.ScheduleR ? check.ScheduleR : 0,
          edit: !!check.ScheduleW ? check.ScheduleW : 0,
          delete: !!check.ScheduleD ? check.ScheduleD : 0,
        },
      },
      payment: {
        payment_request: {
          add: !!check.PayReqR ? check.PayReqR : 0,
          edit: !!check.PayReqW ? check.PayReqW : 0,
          delete: !!check.PayReqD ? check.PayReqD : 0,
        },
        discount_code: {
          add: !!check.DiscountCodeR ? check.DiscountCodeR : 0,
          edit: !!check.DiscountCodeW ? check.DiscountCodeW : 0,
          delete: !!check.DiscountCodeD ? check.DiscountCodeD : 0,
        },
        refund: {
          add: !!check.RefundR ? check.RefundR : 0,
          edit: !!check.RefundW ? check.RefundW : 0,
          delete: !!check.RefundD ? check.RefundD : 0,
        },
        payment_code: {
          add: !!check.PaycodeR ? check.PaycodeR : 0,
          edit: !!check.PaycodeW ? check.PaycodeW : 0,
          delete: !!check.PaycodeD ? check.PaycodeD : 0,
        },
        credit: {
          add: !!check.CreditR ? check.CreditR : 0,
          edit: !!check.CreditW ? check.CreditW : 0,
          delete: !!check.CreditD ? check.CreditD : 0,
        },
      },
      club: {
        //   club: {
        //     add: !!check.ClubR ? check.ClubR : 0,
        //     edit: !!check.ClubW ? check.ClubW : 0,
        //     delete: !!check.ClubD ? check.ClubD : 0,
        //   },
        detail: {
          add: !!check.ClubdetailsR ? check.ClubdetailsR : 0,
          edit: !!check.ClubdetailsW ? check.ClubdetailsW : 0,
          delete: !!check.ClubdetailsD ? check.ClubdetailsD : 0,
        },
        //   roster: {
        //     add: !!check.RoasterR ? check.RoasterR : 0,
        //     edit: !!check.RoasterW ? check.RoasterW : 0,
        //     delete: !!check.RoasterD ? check.RoasterD : 0,
        //   },
      },
      season: {
        detail: {
          add: !!check.SeasondetailsR ? check.SeasondetailsR : 0,
          edit: !!check.SeasondetailsW ? check.SeasondetailsW : 0,
          delete: !!check.SeasondetailsD ? check.SeasondetailsD : 0,
        },
      },
      laiabilty: {
        detail: {
          add: !!check.LiabilitydetailsR ? check.LiabilitydetailsR : 0,
          edit: !!check.LiabilitydetailsW ? check.LiabilitydetailsW : 0,
          delete: !!check.LiabilitydetailsD ? check.LiabilitydetailsD : 0,
        },
      },
      profile: {
        account_detail: {
          add: !!check.AccountDetailsR ? check.AccountDetailsR : 0,
          edit: !!check.AccountDetailsW ? check.AccountDetailsW : 0,
          delete: !!check.AccountDetailsD ? check.AccountDetailsD : 0,
        },
        bank_info: {
          add: !!check.BankinfoR ? check.BankinfoR : 0,
          edit: !!check.BankinfoW ? check.BankinfoW : 0,
          delete: !!check.BankinfoD ? check.BankinfoD : 0,
        },
        staff: {
          add: !!check.StaffR ? check.StaffR : 0,
          edit: !!check.StaffW ? check.StaffW : 0,
          delete: !!check.StaffD ? check.StaffD : 0,
        },
      },
      group: id,
    });
  };

  useEffect(() => {
    if (props.submit?.success) {
      notify(permissionMsg.message);
      props.resetPermissionReducer();
      setTimeout(() => {
        history.push("/admin-permission");
      }, 3000);
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.staffadded?.success) {
      notify(addStaffMsg.message);
      props.resetaddstafftogroupReducer();
    }
  }, [props.staffadded]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head-space ">
              <h5 className="dash-head  mr-4 ">Permission</h5>

              <div className="mt-3 mt-md-0 text-right ">
                <Link to="/create-payment-req">
                  <button className="btn-create-new " onClick={submit}>
                    Save
                  </button>
                </Link>
              </div>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-md-4 col-xl-4 ">
              <div className="mt-24">
                <div className="form-group event-drp">
                  <select
                    className={classNames("form-control team-name payterm")}
                    id="role"
                    name="role"
                    value={role.role}
                    onChange={roleChange}
                  >
                    <option value="">Role Type *</option>
                    <option value="club">Club </option>
                    <option value="staff">Staff </option>
                    <option value="parent">Parent</option>
                    <option value="player">Player</option>
                  </select>

                  {errors.role && (
                    <span className="text-red small d-block">
                      * {errors.role}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div> */}

          {/* <<<<<<<<<<--------------Event Start------------>>>>>>>>>>> */}
          <div className="green-section ">
            {/* <h6 className="text-left">Event</h6> */}
            <div className="table-responsive d-none d-md-block">
              <table className="table green-table ">
                <thead>
                  <tr>
                    <th></th>
                    <th>Read</th>
                    <th>Write</th>
                    <th>Delete</th>

                    {/* <th>Payment Id</th> */}
                  </tr>
                </thead>
                <tbody>
                  {/* {
                                        green_tbl.map((v, i) => {
                                        return  */}
                  {/* <<<<<<<<------------Event-start------------>>>>>>>>> */}
                  <tr className="pay-row">
                    <td>
                      <strong className="filter-string mb-5">Event</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Details</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DetailsR"
                        name="DetailsR"
                        value={check.DetailsR}
                        onChange={checkVal}
                        checked={
                          check.DetailsR == 0
                            ? false
                            : check.DetailsR == 1
                            ? true
                            : false
                        }
                        // defaultChecked={state.paymentchecked}
                        // onClick={paycheck}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DetailsW"
                        name="DetailsW"
                        value={check.DetailsW}
                        onChange={checkVal}
                        checked={
                          check.DetailsW == 0
                            ? false
                            : check.DetailsW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DetailsD"
                        name="DetailsD"
                        value={check.DetailsD}
                        onChange={checkVal}
                        checked={
                          check.DetailsD == 0
                            ? false
                            : check.DetailsD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Team</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="TeamR"
                        name="TeamR"
                        value={check.TeamR}
                        onChange={checkVal}
                        checked={
                          check.TeamR == 0
                            ? false
                            : check.TeamR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="TeamW"
                        name="TeamW"
                        value={check.TeamW}
                        onChange={checkVal}
                        checked={
                          check.TeamW == 0
                            ? false
                            : check.TeamW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="TeamD"
                        name="TeamD"
                        value={check.TeamD}
                        onChange={checkVal}
                        checked={
                          check.TeamD == 0
                            ? false
                            : check.TeamD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Division</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DivisionR"
                        name="DivisionR"
                        value={check.DivisionR}
                        onChange={checkVal}
                        checked={
                          check.DivisionR == 0
                            ? false
                            : check.DivisionR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DivisionW"
                        name="DivisionW"
                        value={check.DivisionW}
                        onChange={checkVal}
                        checked={
                          check.DivisionW == 0
                            ? false
                            : check.DivisionW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DivisionD"
                        name="DivisionD"
                        value={check.DivisionD}
                        onChange={checkVal}
                        checked={
                          check.DivisionD == 0
                            ? false
                            : check.DivisionD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Schedule</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ScheduleR"
                        name="ScheduleR"
                        value={check.ScheduleR}
                        onChange={checkVal}
                        checked={
                          check.ScheduleR == 0
                            ? false
                            : check.ScheduleR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ScheduleW"
                        name="ScheduleW"
                        value={check.ScheduleW}
                        onChange={checkVal}
                        checked={
                          check.ScheduleW == 0
                            ? false
                            : check.ScheduleW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ScheduleD"
                        name="ScheduleD"
                        value={check.ScheduleD}
                        onChange={checkVal}
                        checked={
                          check.ScheduleD == 0
                            ? false
                            : check.ScheduleD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  {/* <<<<<<<<------------Event-End------------>>>>>>>>> */}

                  {/* <<<<<<<<------------Payment-Start------------>>>>>>>>> */}

                  <tr className="pay-row">
                    <td>
                      <strong className="filter-string my-5">Payment</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Payment Request</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="PayReqR"
                        name="PayReqR"
                        value={check.PayReqR}
                        onChange={checkVal}
                        checked={
                          check.PayReqR == 0
                            ? false
                            : check.PayReqR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="PayReqW"
                        name="PayReqW"
                        value={check.PayReqW}
                        onChange={checkVal}
                        checked={
                          check.PayReqW == 0
                            ? false
                            : check.PayReqW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="PayReqD"
                        name="PayReqD"
                        value={check.PayReqD}
                        onChange={checkVal}
                        checked={
                          check.PayReqD == 0
                            ? false
                            : check.PayReqD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Payment Code</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="PaycodeR"
                        name="PaycodeR"
                        value={check.PaycodeR}
                        onChange={checkVal}
                        checked={
                          check.PaycodeR == 0
                            ? false
                            : check.PaycodeR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="PaycodeW"
                        name="PaycodeW"
                        value={check.PaycodeW}
                        onChange={checkVal}
                        checked={
                          check.PaycodeW == 0
                            ? false
                            : check.PaycodeW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="PaycodeD"
                        name="PaycodeD"
                        value={check.PaycodeD}
                        onChange={checkVal}
                        checked={
                          check.PaycodeD == 0
                            ? false
                            : check.PaycodeD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Credit</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="CreditR"
                        name="CreditR"
                        value={check.CreditR}
                        onChange={checkVal}
                        checked={
                          check.CreditR == 0
                            ? false
                            : check.CreditR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="CreditW"
                        name="CreditW"
                        value={check.CreditW}
                        onChange={checkVal}
                        checked={
                          check.CreditW == 0
                            ? false
                            : check.CreditW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="CreditD"
                        name="CreditD"
                        value={check.CreditD}
                        onChange={checkVal}
                        checked={
                          check.CreditD == 0
                            ? false
                            : check.CreditD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Discount Code</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DiscountCodeR"
                        name="DiscountCodeR"
                        value={check.DiscountCodeR}
                        onChange={checkVal}
                        checked={
                          check.DiscountCodeR == 0
                            ? false
                            : check.DiscountCodeR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DiscountCodeW"
                        name="DiscountCodeW"
                        value={check.DiscountCodeW}
                        onChange={checkVal}
                        checked={
                          check.DiscountCodeW == 0
                            ? false
                            : check.DiscountCodeW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DiscountCodeD"
                        name="DiscountCodeD"
                        value={check.DiscountCodeD}
                        onChange={checkVal}
                        checked={
                          check.DiscountCodeD == 0
                            ? false
                            : check.DiscountCodeD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string"> Refund</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="RefundR"
                        name="RefundR"
                        value={check.RefundR}
                        onChange={checkVal}
                        checked={
                          check.RefundR == 0
                            ? false
                            : check.RefundR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="RefundW"
                        name="RefundW"
                        value={check.RefundW}
                        onChange={checkVal}
                        checked={
                          check.RefundW == 0
                            ? false
                            : check.RefundW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="RefundD"
                        name="RefundD"
                        value={check.RefundD}
                        onChange={checkVal}
                        checked={
                          check.RefundD == 0
                            ? false
                            : check.RefundD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  {/* <<<<<<<<------------Payment-End------------>>>>>>>>> */}

                  {/* <<<<<<<<------------Club-Start------------>>>>>>>>> */}

                  <tr className="pay-row">
                    <td>
                      <strong className="filter-string my-5">Club</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Details</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ClubdetailsR"
                        name="ClubdetailsR"
                        value={check.ClubdetailsR}
                        onChange={checkVal}
                        checked={
                          check.ClubdetailsR == 0
                            ? false
                            : check.ClubdetailsR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ClubdetailsW"
                        name="ClubdetailsW"
                        value={check.ClubdetailsW}
                        onChange={checkVal}
                        checked={
                          check.ClubdetailsW == 0
                            ? false
                            : check.ClubdetailsW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ClubdetailsD"
                        name="ClubdetailsD"
                        value={check.ClubdetailsD}
                        onChange={checkVal}
                        checked={
                          check.ClubdetailsD == 0
                            ? false
                            : check.ClubdetailsD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  {/* <<<<<<<<------------Club-End------------>>>>>>>>> */}
                  {/* <<<<<<<<------------Season-Start------------>>>>>>>>> */}
                  <tr className="pay-row">
                    <td>
                      <strong className="filter-string my-5">Season</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Details</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="SeasondetailsR"
                        name="SeasondetailsR"
                        value={check.SeasondetailsR}
                        onChange={checkVal}
                        checked={
                          check.SeasondetailsR == 0
                            ? false
                            : check.SeasondetailsR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="SeasondetailsW"
                        name="SeasondetailsW"
                        value={check.SeasondetailsW}
                        onChange={checkVal}
                        checked={
                          check.SeasondetailsW == 0
                            ? false
                            : check.SeasondetailsW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="SeasondetailsD"
                        name="SeasondetailsD"
                        value={check.SeasondetailsD}
                        onChange={checkVal}
                        checked={
                          check.SeasondetailsD == 0
                            ? false
                            : check.SeasondetailsD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  {/* <<<<<<<<------------Season-End------------>>>>>>>>> */}
                  {/* <<<<<<<<------------Liability-Start------------>>>>>>>>> */}

                  <tr className="pay-row">
                    <td>
                      <strong className="filter-string my-5">Liability</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Details</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="LiabilitydetailsR"
                        name="LiabilitydetailsR"
                        value={check.LiabilitydetailsR}
                        onChange={checkVal}
                        checked={
                          check.LiabilitydetailsR == 0
                            ? false
                            : check.LiabilitydetailsR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="LiabilitydetailsW"
                        name="LiabilitydetailsW"
                        value={check.LiabilitydetailsW}
                        onChange={checkVal}
                        checked={
                          check.LiabilitydetailsW == 0
                            ? false
                            : check.LiabilitydetailsW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="LiabilitydetailsD"
                        name="LiabilitydetailsD"
                        value={check.LiabilitydetailsD}
                        onChange={checkVal}
                        checked={
                          check.LiabilitydetailsD == 0
                            ? false
                            : check.LiabilitydetailsD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  {/* <<<<<<<<------------Liability-End------------>>>>>>>>> */}

                  {/* <<<<<<<<------------Profile-start------------>>>>>>>>> */}

                  <tr className="pay-row">
                    <td>
                      <strong className="filter-string my-5">Profile</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Account Details</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="AccountDetailsR"
                        name="AccountDetailsR"
                        value={check.AccountDetailsR}
                        onChange={checkVal}
                        checked={
                          check.AccountDetailsR == 0
                            ? false
                            : check.AccountDetailsR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="AccountDetailsW"
                        name="AccountDetailsW"
                        value={check.AccountDetailsW}
                        onChange={checkVal}
                        checked={
                          check.AccountDetailsW == 0
                            ? false
                            : check.AccountDetailsW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="AccountDetailsD"
                        name="AccountDetailsD"
                        value={check.AccountDetailsD}
                        onChange={checkVal}
                        checked={
                          check.AccountDetailsD == 0
                            ? false
                            : check.AccountDetailsD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Bank Info</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="BankinfoR"
                        name="BankinfoR"
                        value={check.BankinfoR}
                        onChange={checkVal}
                        checked={
                          check.BankinfoR == 0
                            ? false
                            : check.BankinfoR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="BankinfoW"
                        name="BankinfoW"
                        value={check.BankinfoW}
                        onChange={checkVal}
                        checked={
                          check.BankinfoW == 0
                            ? false
                            : check.BankinfoW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="BankinfoD"
                        name="BankinfoD"
                        value={check.BankinfoD}
                        onChange={checkVal}
                        checked={
                          check.BankinfoD == 0
                            ? false
                            : check.BankinfoD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Staff</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="StaffR"
                        name="StaffR"
                        value={check.StaffR}
                        onChange={checkVal}
                        checked={
                          check.StaffR == 0
                            ? false
                            : check.StaffR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="StaffW"
                        name="StaffW"
                        value={check.StaffW}
                        onChange={checkVal}
                        checked={
                          check.StaffW == 0
                            ? false
                            : check.StaffW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="StaffD"
                        name="StaffD"
                        value={check.StaffD}
                        onChange={checkVal}
                        checked={
                          check.StaffD == 0
                            ? false
                            : check.StaffD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  {/* <<<<<<<<------------Profile-End------------>>>>>>>>> */}

                  {/* })
                                    } */}
                </tbody>
              </table>
            </div>

            {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<------------------------------- Mobile add ----------------------------------------------------------------------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/}

            <div className="table-responsive d-md-none">
              <table className="table green-table mt-24">
                <thead>
                  <tr>
                    <th></th>
                    <th>Read</th>
                    <th>Write</th>
                    <th>Delete</th>

                    {/* <th>Payment Id</th> */}
                  </tr>
                </thead>
                <tbody>
                  {/* {
                                        green_tbl.map((v, i) => {
                                            return  */}
                  <tr className="pay-row">
                    <td>
                      <strong className="filter-string mb-5">Event</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Details</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DetailsR"
                        name="DetailsR"
                        value={check.DetailsR}
                        onChange={checkVal}
                        checked={
                          check.DetailsR == 0
                            ? false
                            : check.DetailsR == 1
                            ? true
                            : false
                        }
                        // defaultChecked={state.paymentchecked}
                        // onClick={paycheck}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DetailsW"
                        name="DetailsW"
                        value={check.DetailsW}
                        onChange={checkVal}
                        checked={
                          check.DetailsW == 0
                            ? false
                            : check.DetailsW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DetailsD"
                        name="DetailsD"
                        value={check.DetailsD}
                        onChange={checkVal}
                        checked={
                          check.DetailsD == 0
                            ? false
                            : check.DetailsD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Team</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="TeamR"
                        name="TeamR"
                        value={check.TeamR}
                        onChange={checkVal}
                        checked={
                          check.TeamR == 0
                            ? false
                            : check.TeamR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="TeamW"
                        name="TeamW"
                        value={check.TeamW}
                        onChange={checkVal}
                        checked={
                          check.TeamW == 0
                            ? false
                            : check.TeamW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="TeamD"
                        name="TeamD"
                        value={check.TeamD}
                        onChange={checkVal}
                        checked={
                          check.TeamD == 0
                            ? false
                            : check.TeamD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Division</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DivisionR"
                        name="DivisionR"
                        value={check.DivisionR}
                        onChange={checkVal}
                        checked={
                          check.DivisionR == 0
                            ? false
                            : check.DivisionR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DivisionW"
                        name="DivisionW"
                        value={check.DivisionW}
                        onChange={checkVal}
                        checked={
                          check.DivisionW == 0
                            ? false
                            : check.DivisionW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DivisionD"
                        name="DivisionD"
                        value={check.DivisionD}
                        onChange={checkVal}
                        checked={
                          check.DivisionD == 0
                            ? false
                            : check.DivisionD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Schedule</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ScheduleR"
                        name="ScheduleR"
                        value={check.ScheduleR}
                        onChange={checkVal}
                        checked={
                          check.ScheduleR == 0
                            ? false
                            : check.ScheduleR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ScheduleW"
                        name="ScheduleW"
                        value={check.ScheduleW}
                        onChange={checkVal}
                        checked={
                          check.ScheduleW == 0
                            ? false
                            : check.ScheduleW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ScheduleD"
                        name="ScheduleD"
                        value={check.ScheduleD}
                        onChange={checkVal}
                        checked={
                          check.ScheduleD == 0
                            ? false
                            : check.ScheduleD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  {/* <<<<<<<<------------Event-End------------>>>>>>>>> */}

                  {/* <<<<<<<<------------Payment-Start------------>>>>>>>>> */}

                  <tr className="pay-row">
                    <td>
                      <strong className="filter-string my-5">Payment</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Payment Request</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="PayReqR"
                        name="PayReqR"
                        value={check.PayReqR}
                        onChange={checkVal}
                        checked={
                          check.PayReqR == 0
                            ? false
                            : check.PayReqR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="PayReqW"
                        name="PayReqW"
                        value={check.PayReqW}
                        onChange={checkVal}
                        checked={
                          check.PayReqW == 0
                            ? false
                            : check.PayReqW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="PayReqD"
                        name="PayReqD"
                        value={check.PayReqD}
                        onChange={checkVal}
                        checked={
                          check.PayReqD == 0
                            ? false
                            : check.PayReqD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Payment Code</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="PaycodeR"
                        name="PaycodeR"
                        value={check.PaycodeR}
                        onChange={checkVal}
                        checked={
                          check.PaycodeR == 0
                            ? false
                            : check.PaycodeR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="PaycodeW"
                        name="PaycodeW"
                        value={check.PaycodeW}
                        onChange={checkVal}
                        checked={
                          check.PaycodeW == 0
                            ? false
                            : check.PaycodeW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="PaycodeD"
                        name="PaycodeD"
                        value={check.PaycodeD}
                        onChange={checkVal}
                        checked={
                          check.PaycodeD == 0
                            ? false
                            : check.PaycodeD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Credit</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="CreditR"
                        name="CreditR"
                        value={check.CreditR}
                        onChange={checkVal}
                        checked={
                          check.CreditR == 0
                            ? false
                            : check.CreditR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="CreditW"
                        name="CreditW"
                        value={check.CreditW}
                        onChange={checkVal}
                        checked={
                          check.CreditW == 0
                            ? false
                            : check.CreditW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="CreditD"
                        name="CreditD"
                        value={check.CreditD}
                        onChange={checkVal}
                        checked={
                          check.CreditD == 0
                            ? false
                            : check.CreditD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Discount Code</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DiscountCodeR"
                        name="DiscountCodeR"
                        value={check.DiscountCodeR}
                        onChange={checkVal}
                        checked={
                          check.DiscountCodeR == 0
                            ? false
                            : check.DiscountCodeR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DiscountCodeW"
                        name="DiscountCodeW"
                        value={check.DiscountCodeW}
                        onChange={checkVal}
                        checked={
                          check.DiscountCodeW == 0
                            ? false
                            : check.DiscountCodeW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DiscountCodeD"
                        name="DiscountCodeD"
                        value={check.DiscountCodeD}
                        onChange={checkVal}
                        checked={
                          check.DiscountCodeD == 0
                            ? false
                            : check.DiscountCodeD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string"> Refund</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="RefundR"
                        name="RefundR"
                        value={check.RefundR}
                        onChange={checkVal}
                        checked={
                          check.RefundR == 0
                            ? false
                            : check.RefundR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="RefundW"
                        name="RefundW"
                        value={check.RefundW}
                        onChange={checkVal}
                        checked={
                          check.RefundW == 0
                            ? false
                            : check.RefundW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="RefundD"
                        name="RefundD"
                        value={check.RefundD}
                        onChange={checkVal}
                        checked={
                          check.RefundD == 0
                            ? false
                            : check.RefundD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  {/* <<<<<<<<------------Payment-End------------>>>>>>>>> */}

                  {/* <<<<<<<<------------Club-Start------------>>>>>>>>> */}

                  <tr className="pay-row">
                    <td>
                      <strong className="filter-string my-5">Club</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Details</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ClubdetailsR"
                        name="ClubdetailsR"
                        value={check.ClubdetailsR}
                        onChange={checkVal}
                        checked={
                          check.ClubdetailsR == 0
                            ? false
                            : check.ClubdetailsR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ClubdetailsW"
                        name="ClubdetailsW"
                        value={check.ClubdetailsW}
                        onChange={checkVal}
                        checked={
                          check.ClubdetailsW == 0
                            ? false
                            : check.ClubdetailsW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ClubdetailsD"
                        name="ClubdetailsD"
                        value={check.ClubdetailsD}
                        onChange={checkVal}
                        checked={
                          check.ClubdetailsD == 0
                            ? false
                            : check.ClubdetailsD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  {/* <<<<<<<<------------Club-End------------>>>>>>>>> */}
                  {/* <<<<<<<<------------Season-Start------------>>>>>>>>> */}

                  <tr className="pay-row">
                    <td>
                      <strong className="filter-string my-5">Season</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Details</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="SeasondetailsR"
                        name="SeasondetailsR"
                        value={check.SeasondetailsR}
                        onChange={checkVal}
                        checked={
                          check.SeasondetailsR == 0
                            ? false
                            : check.SeasondetailsR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="SeasondetailsW"
                        name="SeasondetailsW"
                        value={check.SeasondetailsW}
                        onChange={checkVal}
                        checked={
                          check.SeasondetailsW == 0
                            ? false
                            : check.SeasondetailsW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="SeasondetailsD"
                        name="SeasondetailsD"
                        value={check.SeasondetailsD}
                        onChange={checkVal}
                        checked={
                          check.SeasondetailsD == 0
                            ? false
                            : check.SeasondetailsD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  {/* <<<<<<<<------------Season-End------------>>>>>>>>> */}
                  {/* <<<<<<<<------------Liability-Start------------>>>>>>>>> */}
                  <tr className="pay-row">
                    <td>
                      <strong className="filter-string my-5">Liability</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Details</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="LiabilitydetailsR"
                        name="LiabilitydetailsR"
                        value={check.LiabilitydetailsR}
                        onChange={checkVal}
                        checked={
                          check.LiabilitydetailsR == 0
                            ? false
                            : check.LiabilitydetailsR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="LiabilitydetailsW"
                        name="LiabilitydetailsW"
                        value={check.LiabilitydetailsW}
                        onChange={checkVal}
                        checked={
                          check.LiabilitydetailsW == 0
                            ? false
                            : check.LiabilitydetailsW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="LiabilitydetailsD"
                        name="LiabilitydetailsD"
                        value={check.LiabilitydetailsD}
                        onChange={checkVal}
                        checked={
                          check.LiabilitydetailsD == 0
                            ? false
                            : check.LiabilitydetailsD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  {/* <<<<<<<<------------Liability-End------------>>>>>>>>> */}

                  {/* <<<<<<<<------------Profile-start------------>>>>>>>>> */}

                  <tr className="pay-row">
                    <td>
                      <strong className="filter-string my-5">Profile</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Account Details</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="AccountDetailsR"
                        name="AccountDetailsR"
                        value={check.AccountDetailsR}
                        onChange={checkVal}
                        checked={
                          check.AccountDetailsR == 0
                            ? false
                            : check.AccountDetailsR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="AccountDetailsW"
                        name="AccountDetailsW"
                        value={check.AccountDetailsW}
                        onChange={checkVal}
                        checked={
                          check.AccountDetailsW == 0
                            ? false
                            : check.AccountDetailsW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="AccountDetailsD"
                        name="AccountDetailsD"
                        value={check.AccountDetailsD}
                        onChange={checkVal}
                        checked={
                          check.AccountDetailsD == 0
                            ? false
                            : check.AccountDetailsD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Bank Info</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="BankinfoR"
                        name="BankinfoR"
                        value={check.BankinfoR}
                        onChange={checkVal}
                        checked={
                          check.BankinfoR == 0
                            ? false
                            : check.BankinfoR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="BankinfoW"
                        name="BankinfoW"
                        value={check.BankinfoW}
                        onChange={checkVal}
                        checked={
                          check.BankinfoW == 0
                            ? false
                            : check.BankinfoW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="BankinfoD"
                        name="BankinfoD"
                        value={check.BankinfoD}
                        onChange={checkVal}
                        checked={
                          check.BankinfoD == 0
                            ? false
                            : check.BankinfoD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Staff</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="StaffR"
                        name="StaffR"
                        value={check.StaffR}
                        onChange={checkVal}
                        checked={
                          check.StaffR == 0
                            ? false
                            : check.StaffR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="StaffW"
                        name="StaffW"
                        value={check.StaffW}
                        onChange={checkVal}
                        checked={
                          check.StaffW == 0
                            ? false
                            : check.StaffW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="StaffD"
                        name="StaffD"
                        value={check.StaffD}
                        onChange={checkVal}
                        checked={
                          check.StaffD == 0
                            ? false
                            : check.StaffD == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  {/* })
                                    } */}
                </tbody>
              </table>
            </div>
          </div>

          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(Permission)

const mapStateToProps = (state) => ({
  submit: state.permissionReducer,
  staffadded: state.addStaffToGroupReducer,
});

const mapDispatchToProps = (dispatch) => ({
  resetPermissionReducer: () => dispatch({ type: RESET_PERMISSION }),
  resetaddstafftogroupReducer: () => dispatch({ type: RESET_ADD_STAFF_GROUP }),
  permissionHandler: (user) => dispatch(permission(user)),
  getPermissionDetailshandler: (user) => dispatch(getPermissionDetails(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Permission));
