import { GET_CLUB_LIST_FIND_BY_NAME_ERROR, GET_CLUB_LIST_FIND_BY_NAME_LOADING, GET_CLUB_LIST_FIND_BY_NAME_SUCCESS } from "../constants";

let initialState = {
    loading: false,
    user: [],
    error: false,
    errors: [],
    success: false,
    payload: {}
};



export default function getClubListFindByName (state = initialState, action) {
    switch (action.type) {
        case GET_CLUB_LIST_FIND_BY_NAME_LOADING:
            // alert(" reducer succesfull")
            return { ...state, success: false, success: false };
            break;
        case GET_CLUB_LIST_FIND_BY_NAME_SUCCESS:
            // alert(" reducer Error")
            return { ...state, loading: false, success: true, user: action.payload };
            break;
        case GET_CLUB_LIST_FIND_BY_NAME_ERROR:
            // alert(" reducer Loading")
            return { ...state, error: true, errors: action.errors, networkError:action.error };

            break;

        default:
            return state;
    }
}
