import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";

import { useEffect } from "react";
import { useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  RESET_UPDATE_LIABILITY,
  removeStorage,
} from "../Services/constant";
import { useHistory } from "react-router-dom";

import classNames from "classnames";
import {
  GetLiabilityDetails,
  UpdateLiabilityDetails,
} from "../Services/Actions/liabilityAction";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const DATA_ITEM_KEY = "_id";

function Liability(props) {
  const history = useHistory();
  const [xlShow, setXlShow] = useState(false);
  const handleClose = () => setXlShow(false);
  const handleShow = () => setXlShow(true);

  const [state, setState] = useState([]);
  const [selectedID, setSelectedId] = useState("");
  const InitialForm = {
    title: "",
    liability: "",
  };

  const InitialError = {
    liability: false,
  };
  const [detailsForm, setDetailsForm] = useState(InitialForm);
  const [errorForm, setErrorForm] = useState(InitialError);

  const [pageDetails, setPageDetails] = useState({
    skip: 0,
    take: 10,
  });

  useEffect(() => {
    props.GetLiabilityDetails();
  }, []);

  useEffect(() => {
    if (props.getLiabilityDetailsReducer.success) {
      const data = props.getLiabilityDetailsReducer.data;
      const _data = data.data.map((e) => {
        return {
          ...e,
        };
      });
      setState(_data);
    } else if (props.getLiabilityDetailsReducer.error) {
      const errMsg = props.getLiabilityDetailsReducer.errorMsg;
      !!errMsg && toast.error(errMsg);
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      }
    }
  }, [props.getLiabilityDetailsReducer]);

  const customHeader = (cell) => {
    return <b>{cell.title}</b>;
  };

  const customAction = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <button
          onClick={() => {
            const details = cell.dataItem;
            setSelectedId(details._id);
            setDetailsForm({
              ...details,
              liability: details.sport == "N/A" ? "" : details.liability,
              title: details.title,
            });
            setErrorForm({
              ...InitialError,
              liability: false,
            });
            handleShow();
          }}
          className="btn-link btn text-secondary p-0 mx-2"
        >
          <i className="ri-pencil-fill"></i>
        </button>
      </td>
    );
  };

  const grid = (
    <Grid
      data={state.slice(pageDetails.skip, pageDetails.skip + pageDetails.take)}
      skip={pageDetails.skip}
      take={pageDetails.take}
      total={state.length}
      pageable={{
        buttonCount: 3,
        pageSizes: [5, 10, 15],
      }}
      onPageChange={(e) => {
        setPageDetails(() => ({
          ...e.page,
          take: e.page.take,
        }));
      }}
      dataItemKey={DATA_ITEM_KEY}
    >
      <GridNoRecords>No records are available</GridNoRecords>
      <GridColumn field="title" title="Title" headerCell={customHeader} />
      <GridColumn
        field="liability"
        title="Liability"
        headerCell={customHeader}
      />

      <GridColumn
        field="action"
        title="Actions"
        width="100"
        headerCell={customHeader}
        cell={customAction}
      />
    </Grid>
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    setDetailsForm((pre) => ({
      ...pre,
      [name]: value,
    }));

    switch (name) {
      case "liability":
        if (value === "") {
          setErrorForm((prev) => ({
            ...prev,
            liability: "Required",
          }));
        } else {
          setErrorForm((prev) => ({
            ...prev,
            liability: false,
          }));
        }
        break;

      default:
        break;
    }
  };
  const handleCreate = (e) => {
    e.preventDefault();

    if (detailsForm.liability == "") {
      setErrorForm((prev) => ({
        ...prev,
        liability: "Required",
      }));
    }

    if (detailsForm.liability !== "") {
      props.UpdateLiabilityDetails({
        details: {
          title: detailsForm.title,
          liability: detailsForm.liability,
        },
        id: selectedID,
      });
    }
  };

  useEffect(() => {
    if (props.updateLiabilityDetailsReducer.success) {
      handleClose();
      toast.success("Liabilty updated successfully");
      props.resetUpdateLiability();
      props.GetLiabilityDetails();
      setTimeout(() => {
        setDetailsForm(InitialForm);
        setErrorForm(InitialError);
      }, 500);
      setSelectedId("");
    } else if (props.updateLiabilityDetailsReducer.error) {
      const errMsg = props.updateLiabilityDetailsReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.updateLiabilityDetailsReducer]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2 className="page-heading">Liability</h2>
      </div>

      <div className="table-card">{grid}</div>
      <Modal
        size="md"
        show={xlShow}
        oonHide={() => {
          setDetailsForm(InitialForm);
          setErrorForm(InitialError);
          handleClose();
        }}
      >
        <Modal.Header>
          <Modal.Title>Liability</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Col} className="mb-4" controlId="">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" value={detailsForm.title} disabled />
            </Form.Group>
            <Form.Group as={Col} className="mb-4" controlId="">
              <Form.Label>Liability</Form.Label>
              <div
                className={classNames({
                  errorBorder: errorForm.liability,
                })}
              >
                <CKEditor
                  editor={ClassicEditor}
                  data={detailsForm.liability}
                  onChange={(e, editor) => {
                    handleChange({
                      target: {
                        name: "liability",
                        value: editor.getData(),
                      },
                    });
                  }}
                  type="text"
                  placeholder=""
                  name="liability"
                />
              </div>
            </Form.Group>

            <div className="text-end ml-auto">
              <Button
                onClick={(e) => {
                  // e.preventDefault();
                  handleClose();
                  setTimeout(() => {
                    setDetailsForm(InitialForm);
                    setErrorForm(InitialError);
                  }, 500);
                }}
                style={{ fontSize: "18px" }}
                className="mr-3 pointer"
              >
                Cancel
              </Button>
              <Button variant="secondary" type="submit" onClick={handleCreate}>
                {selectedID == "" ? "Submit" : "Update"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProp = (state) => ({
  getLiabilityDetailsReducer:
    state.superAdminRootReducer.LiabilityRecucer.getLiabilityDetails,
  updateLiabilityDetailsReducer:
    state.superAdminRootReducer.LiabilityRecucer.updateLiabilityDetails,
});

const mapDispatchToProps = (dispatch) => ({
  GetLiabilityDetails: () => dispatch(GetLiabilityDetails()),
  UpdateLiabilityDetails: (details) =>
    dispatch(UpdateLiabilityDetails(details)),
  resetUpdateLiability: () => dispatch({ type: RESET_UPDATE_LIABILITY }),
});

export default connect(mapStateToProp, mapDispatchToProps)(Liability);
