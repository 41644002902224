import { GET_ALLCLUBSEASON_ERROR, GET_ALLCLUBSEASON_LOADING, GET_ALLCLUBSEASON_SUCCESS } from "../../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function getAllClubSeasonReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALLCLUBSEASON_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case GET_ALLCLUBSEASON_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };
     
    case GET_ALLCLUBSEASON_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}


