import { GET_ALL_INVITATION_ERROR, GET_ALL_INVITATION_LOADING, GET_ALL_INVITATION_SUCCESS} from "../../../constants";


let initialState = {
  loading: false,
  user: {},
  error: false,
  errors: [],
  success: false
  
  
};

export default function getAllInvitationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_INVITATION_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false,success:false};
     
    case GET_ALL_INVITATION_SUCCESS:  
      // alert(" reducer Error")
      return { ...state, loading: false ,success:true,user:action.payload};
 
    case GET_ALL_INVITATION_ERROR:
      // alert(" reducer Loading")
      return { ...state,error: true, errors: action.errors };
   
    default:
      return state;
  }
}


