import { createTheme } from "@material-ui/core/styles";

const materialTheme = createTheme({
    overrides: {
        MuiOutlinedInput: {
            input: {
                padding: "14px",
                // fontSize: '52px'
            }
        },
    }

});

export default materialTheme;
