import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import { useSelector, useDispatch, connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "@dmuy/timepicker/dist/mdtimepicker.css";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import {
  deletePool,
  getAllEvent,
  getEventById,
} from "../../../service/action/userAction";
import {
  RESET_REGISTER_SELECTED_TEAM,
  RESET_CREATE_EVENT_DIVISION,
  RESET_EDIT_EVENT_DIVISION,
  RESET_DELETE_EVENT,
  RESET_DELETE_POOL,
  RESET_EDIT_POOL,
  RESET_CREATE_POOL,
  RESET_DELETE_EVENT_POOL,
} from "../../../service/constants";
import {
  deleteClubEventPool,
  deleteEventDivision,
  getClubEventTeam,
  geteventDivision,
} from "../../../service/action/Club/clubAction";
import Moment from "react-moment";
import { convertTo12HourFormat } from "../../../helpers/convertTo12HourFormat";

const EventDetails = (props) => {
  const history = useHistory();

  const notify = (msg) => toast(msg);

  const { id } = useParams();

  const eventById = props.getEventByIdHandler;

  const clubEventTeam = props.getClubEventTeamHandler;
  // const eventDivision = props.getEventDivisionHandler
  useEffect(() => {
    eventById({ id: id });
    clubEventTeam({ id: id });
    // eventDivision({ id: id })
  }, []);

  const [state, setState] = useState({
    startDate: "",
    EndDate: "",
    event_name: "",
    facility_name: "",
    website: "",
    city: "",
    state: "",
    address: "",
    maxTeam: "",
    availability: "",
    option: "",
    from_time: "",
    to_time: "",
  });

  const [errors, setErrors] = useState({
    startDate: false,
    EndDate: false,
    event_name: false,
    facility_name: false,
    website: false,
    city: false,
    state: false,
    address: false,
    maxTeam: false,
    availability: false,
    option: false,
    from_time: false,
    to_time: false,
  });
  // const getdata = props.getEventHandler;      //for get all event

  // useEffect(() => {
  //     // getdata({id:0})
  // }, [])
  const allEvent = useSelector((state) => state.getAllEventReducer.user);
  const getEventDetails = useSelector(
    (state) => state.getEventByIdReducer.user
  );
  const getDivision = useSelector(
    (state) => state.geteventDivisionReducer.user
  );

  const registered = useSelector(
    (state) => state.registerSelectedTeamReducer.message
  );

  const divisionMsg = useSelector(
    (state) => state.createEventDivisionReducer.message
  );

  const editDivisionMsg = useSelector(
    (state) => state.editeventDivisionReducer.message
  );

  const editPoolMsg = useSelector((state) => state.editPoolReducer.message);

  const deleteDivisionMsg = useSelector(
    (state) => state.deleteEventReducer.message
  );

  const deletePoolMsg = useSelector(
    (state) => state.deleteEventPoolReducer.message
  );

  const createpoolMsg = useSelector((state) => state.createPoolReducer.message);

  const clubTeam = useSelector(
    (state) => state.getClubEventTeamReducer.user.data
  );

  const dateCheck = () => {
    if (getEventDetails) {
      if (new Date(getEventDetails.event_to_date) > new Date()) {
        return "open";
      } else {
        return "close";
      }
    }
  };

  const [eventDateTime, setEventDateTime] = useState(null);

  useEffect(() => {
    if (getEventDetails.length !== 0) {
      {
        getEventDetails?.event_facility &&
          getEventDetails?.event_facility.map((x, i) => {
            setState((prevState) => ({
              ...prevState,
              from_time: getEventDetails.event_from_time,
              to_time: getEventDetails.event_to_time,
              event_name: getEventDetails.event_name,
              facility_name: x.facility_name,
              address: x.facility_address,
              maxTeam: getEventDetails.event_max_team,
              city: x.facility_city !== undefined ? x.facility_city.name : "",
              state:
                x.facility_state !== undefined ? x.facility_state.name : "",
              option: getEventDetails.event_type,
              event_access: getEventDetails.event_access,
              website: x.facility_website,
              // event_Type:getEventDetails.event_type,
              payment: "",
              person: "",
              season: getEventDetails.event_season,
              numPlayers: getEventDetails.show_number_of_player,
              eventFull: getEventDetails.show_event_is_full,
              remainingSpots: getEventDetails.show_remaining_spots,
              schedual_tab: getEventDetails.show_schedule,
            }));
            // setStartDate(new Date(getEventDetails.event_from_date))
            // setEndDate(new Date(getEventDetails.event_to_date))

            setEventDateTime(getEventDetails.event_date_time);
          });
      }
    }
  }, [getEventDetails]);

  const [startDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");

  const team = [
    {
      teamname: "teamname",
      coachname: "coachname",
      gender: "male",
      players: "20",
      status: "registered",
    },
    {
      teamname: "teamname",
      coachname: "coachname",
      gender: "male",
      players: "20",
      status: "registered",
    },
    {
      teamname: "teamname",
      coachname: "coachname",
      gender: "male",
      players: "20",
      status: "registered",
    },
  ];

  useEffect(() => {
    if (props.registered?.success) {
      notify(registered.message);
      props.resetregisteredSelectedTeamReducer();
    }
  }, [props.registered]);

  useEffect(() => {
    if (props.createDivision?.success) {
      notify(divisionMsg.message);
      // notify("done")
      props.resetCreateDivision();
    }
  }, [props.createDivision]);

  useEffect(() => {
    if (props.editDivision?.success) {
      notify(editDivisionMsg.message);
      // notify("done")
      props.reseteditEventDivisionreducer();
    }
  }, [props.editDivision]);

  useEffect(() => {
    if (props.deleteDivision?.success) {
      notify(deleteDivisionMsg.message);
      props.resetDeleteEventDivision();
      // eventDivision({ id: id })
      // notify("done")
    }
  }, [props.deleteDivision]);

  useEffect(() => {
    if (props.deletePool?.success) {
      notify(deletePoolMsg.message);
      props.resetDeletePoolHandler();
      // eventDivision({ id: id })
      // notify("done")
    }
  }, [props.deletePool]);

  useEffect(() => {
    if (props.editPool?.success) {
      notify(editPoolMsg.message);
      props.resetEditPoolHandler();
      // eventDivision({ id: id })
      // notify("done")
    }
  }, [props.editPool]);

  useEffect(() => {
    if (props.createpool?.success) {
      notify(createpoolMsg.message);
      props.resetCreatePool();
      // eventDivision({ id: id })
      // notify("done")
    }
  }, [props.createpool]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left mb-3">
            <div className="create-team-head-space">
              <h5 className="dash-head"></h5>
              <div className="mt-3 mt-md-0 text-right">
                <Link to={`/editclubevent/${id}`}>
                  <button className="btn-save">Edit</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="text-left">
            <div className="create-team-head-space">
              <h5 className="dash-head">{state.event_name} 123</h5>
              <div className="d-flex align-items-center">
                <div className="reg-team">
                  <Link
                    className="text-decoration-none"
                    to={`/registerteam/${id}`}
                  >
                    <i className="mdi mdi-account-multiple"></i>
                    <span>Register Teams</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="team-details">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="details"
                  data-toggle="pill"
                  href="#details-tab"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Details
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="teams"
                  data-toggle="pill"
                  href="#teams-tab"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Teams
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="schedule"
                  data-toggle="pill"
                  href="#schedule-tab"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  Schedule
                </a>
              </li>
            </ul>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="details-tab"
                role="tabpanel"
                aria-labelledby="pills-details"
              >
                <div className="mt-30">
                  <div className="d-flex justify-content-between">
                    <h6 className="text-left payment mt-30">Event Status</h6>
                    {/* <div className="dropdown details-drp">
                                            <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                <a className="dropdown-item" href="#">Invite Team Action</a>
                                            </div>
                                        </div> */}
                  </div>

                  {getEventDetails ? (
                    <>
                      <div className="row">
                        <div className="col-md-4">
                          {/* <div className="dropdown event-drp mt-18 text-left">
                                                <label className="text-gray fs-13">Event Status</label>

                                                <a className="form-control dropdown-toggle gender-drp text-decoration-none" href="#" role="button" id="sports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Open
                                                </a>

                                                <div className="dropdown-menu" aria-labelledby="Select Sports">
                                                    <a className="dropdown-item" href="#">Hocky</a>
                                                    <a className="dropdown-item" href="#">Cricket</a>
                                                    <a className="dropdown-item" href="#">Tennis</a>
                                                </div>
                                            </div> */}

                          <div className="form-group event-drp">
                            {/* <select className={classNames("form-control team-name  payterm"
                                                                // , { "error": errors.option }
                                                            )} id="event_status" disabled name="event_status" value={getEventDetails.event_from_date > new Date()?<>
                                                            <option value="0" style={{ color: "#000" }} >open</option>
                                                            
                                                            </>:<><option value="0" style={{ color: "#000" }} >close</option></>} */}

                            {/* // onChange={eventHandler} */}
                            {/* > */}
                            {/* <option value="1" style={{ color: "#000" }}>Open</option>
                                                                <option value="2" style={{ color: "#000" }}>Close</option> */}
                            {/* <option value="2" style={{ color: "#000" }}>Account 2</option> */}
                            {/* </select> */}
                            <input
                              type="text"
                              className={classNames(
                                "form-control team-name"
                                // , { "error": errors.event_name }
                              )}
                              placeholder="Event Status"
                              id="event_status"
                              name="event_status"
                              value={dateCheck()}
                              disabled
                            />

                            {/* {errors.option &&
                                                    <span className='text-red small d-block'>* {errors.option}</span>} */}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-between">
                            <div className="text-left ">
                              <label className="text-gray fs-13">
                                Event Type
                              </label>
                              <div>
                                <i className="mdi mdi-lock-outline website-ico"></i>
                                <span className="text-blue fs-13 ml-12">
                                  {state.option == 0
                                    ? "Club only"
                                    : state.option == 1
                                    ? "parent/player"
                                    : state.option == 2
                                    ? "Club && parent/player"
                                    : ""}{" "}
                                </span>
                              </div>
                            </div>

                            <div className="text-left mt-18">
                              {/* <label className="text-gray fs-13">Registratin Link</label>
                                                            <div>
                                                                <i className="mdi mdi-open-in-new website-ico"></i>
                                                                <span className="text-blue fs-13 ml-12">Copy Link</span>
                                                            </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 col-6">
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="text-left mt-18">
                                {/* <label className="text-gray fs-13">From Date</label> */}
                                <div className="mt-30 table-responsive d-none d-md-block">
                                  <table className="table event-table">
                                    <thead>
                                      <tr>
                                        <th>Date</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {eventDateTime?.map((v, i) => {
                                        return (
                                          <tr key={v._id} className="pay-row ">
                                            <td>
                                              {/* {formatYmd(new Date(v.date))} */}
                                              <Moment
                                                date={new Date(v.date)}
                                                format="MM/DD/YYYY"
                                              />
                                            </td>
                                            <td>
                                              {convertTo12HourFormat(
                                                v.start_time
                                              )}
                                            </td>
                                            <td>
                                              {convertTo12HourFormat(
                                                v.end_time
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>

                                {/* <DatePicker
                                                                    selected={startDate}
                                                                    placeholderText="Choose start Date"
                                                                    // value={state.from_date}
                                                                    // onChange={date => eventHandler({ target: { value: date, name: 'startDate', id: "start-date" } })}
                                                                    className={classNames("form-control team-name"
                                                                        // , { "error": errors.startDate }
                                                                    )}
                                                                    disabled
                                                                /> */}
                                {/* {errors.startDate &&
                                                            <span className='text-red small d-block'>* {errors.startDate}</span>} */}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="text-left mt-18">
                                {/* <label className="text-gray fs-13">From Time</label>
                                                                <input type="text" data-mintime="now" className={classNames("form-control timepicker team-name"
                                                                    // , { "error": errors.from_time }
                                                                )} placeholder="Choose start time" id="from_time" value={state.from_time} name="from_time"
                                                                    //  onChange={eventHandler} 
                                                                    disabled /> */}
                                {/* {errors.from_time &&
                                                            <span className='text-red small d-block'>* {errors.from_time}</span>} */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="text-left mt-18">
                            <label className="text-gray fs-13">
                              Event Name
                            </label>
                            <input
                              type="text"
                              className={classNames(
                                "form-control team-name"
                                // , { "error": errors.event_name }
                              )}
                              placeholder="Event name"
                              id="event_name"
                              name="event_name"
                              value={state.event_name}
                              disabled
                              // onChange={eventHandler}
                            />

                            {/* {errors.event_name &&
                                                    <span className='text-red small d-block'>* {errors.event_name}</span>} */}
                          </div>
                        </div>

                        <div className="col-md-4 col-6">
                          <div className="form-row">
                            <div className="col-md-6">
                              <div className="text-left mt-18">
                                {/* <label className="text-gray fs-13">To Date</label>
                                                                <DatePicker
                                                                    selected={EndDate}
                                                                    placeholderText="Choose end Date"
                                                                    // onChange={date => eventHandler({ target: { value: date, name: 'EndDate', id: "End-date" } })}
                                                                    className={classNames("form-control team-name"
                                                                        // , { "error": errors.EndDate }
                                                                    )}
                                                                    disabled
                                                                /> */}

                                {/* {errors.EndDate &&
                                                            <span className='text-red small d-block'>* {errors.EndDate}</span>} */}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="text-left mt-18"></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {getEventDetails.event_facility ? (
                        <>
                          {getEventDetails.event_facility.map((x, i) => {
                            return (
                              <div key={i}>
                                <div className="d-flex justify-content-between">
                                  <h6 className="text-left payment mt-30">
                                    Facility
                                  </h6>
                                </div>

                                <div className="row" key={x.event_id}>
                                  <div className="col-md-4">
                                    <div className="text-left mt-18">
                                      <label className="text-gray fs-13">
                                        Name
                                      </label>

                                      <input
                                        type="text"
                                        className={classNames(
                                          "form-control team-name"
                                          // , { "error": errors.facility_name }
                                        )}
                                        placeholder="Facility name"
                                        id="facility_name"
                                        name="facility_name"
                                        value={state.facility_name}
                                        //  onChange={eventHandler}
                                        disabled
                                      />

                                      {/* {errors.facility_name &&
                                                            <span className='text-red small d-block'>* {errors.facility_name}</span>} */}
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="text-left mt-18">
                                      <label className="text-gray fs-13">
                                        Address
                                      </label>
                                      <textarea
                                        type="text"
                                        className={classNames(
                                          "form-control team-name text-area"
                                          // , { "error": errors.address }
                                        )}
                                        placeholder="Address"
                                        id="address"
                                        name="address"
                                        value={state.address}
                                        //  onChange={eventHandler}
                                        disabled
                                      />

                                      {/* {errors.address &&
                                                    <span className='text-red small d-block'>* {errors.address}</span>} */}
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-3 mt-md-0">
                                    <div className="text-left mt-18">
                                      <label className="text-gray fs-13">
                                        City
                                      </label>
                                      <input
                                        type="text"
                                        className={classNames(
                                          "form-control team-name"
                                          // , { "error": errors.city }
                                        )}
                                        placeholder="City"
                                        id="city"
                                        name="website"
                                        value={state.city}
                                        // onChange={eventHandler}
                                        disabled
                                      />
                                      {/* {errors.city &&
                                                    <span className='text-red small d-block'>* {errors.city}</span>} */}
                                    </div>
                                  </div>
                                  <div className="col-md-3 mt-md-0">
                                    <div className="text-left mt-18">
                                      <label className="text-gray fs-13">
                                        State
                                      </label>
                                      <input
                                        type="text"
                                        className={classNames(
                                          "form-control team-name"
                                          // , { "error": errors.state }
                                        )}
                                        placeholder="State"
                                        id="state"
                                        name="state"
                                        value={state.state}
                                        // onChange={eventHandler}
                                        disabled
                                      />
                                      {/* {errors.state &&
                                                    <span className='text-red small d-block'>* {errors.state}</span>} */}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-4 mt-md-0">
                                    <div className="text-left mt-18">
                                      <label className="text-gray fs-13">
                                        Website
                                      </label>
                                      <input
                                        type="text"
                                        className={classNames(
                                          "form-control team-name"
                                          // , { "error": errors.website }
                                        )}
                                        placeholder="Website"
                                        id="website"
                                        name="website"
                                        value={state.website}
                                        // onChange={eventHandler}
                                        disabled
                                      />
                                      {/* {errors.website &&
                                                    <span className='text-red small d-block'>* {errors.website}</span>} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      <h4>Loading...</h4>
                    </>
                  )}

                  <div className="d-flex justify-content-between">
                    <h6 className="text-left payment mt-30">Teams</h6>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-row">
                        <div className="col-md-12 col-12">
                          <div className="text-left mt-18">
                            <label className="text-gray fs-13">Max Teams</label>
                            <input
                              type="text"
                              className={classNames(
                                "form-control team-name"
                                // , { "error": errors.maxTeam }
                              )}
                              placeholder="Max teams"
                              id="maxTeam"
                              name="max-team"
                              // onChange={eventHandler}
                              value={state.maxTeam}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Toaster /> */}
              </div>

              <div
                className="tab-pane fade"
                id="teams-tab"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="d-flex justify-content-between">
                  <h6 className="text-left payment mt-30"></h6>
                  <div className="dropdown details-drp">
                    <i
                      className="mdi mdi-dots-vertical"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    ></i>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <Link
                        className="dropdown-item"
                        to={`/invite-player/${id}`}
                      >
                        Player Invite
                      </Link>
                    </div>
                  </div>
                </div>

                <h6 className="text-left payment mt-30">Teams</h6>

                <div className="table-responsive d-none d-md-block">
                  <table className="table event-table">
                    <thead>
                      <tr>
                        <th>Teams</th>
                        <th>Gender</th>
                        <th>Division</th>
                        <th>Players</th>
                        <th>Roster Player</th>
                        {/* <th></th> */}
                      </tr>
                    </thead>

                    {clubTeam ? (
                      <>
                        <tbody>
                          {clubTeam.map((a, i) => {
                            return (
                              <React.Fragment key={i}>
                                {/* {
                                                                a.team_id.map((v, i) => {
                                                                    return  */}
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="dashboard-img">
                                        <img
                                          src={"./images/pch-images/pch.svg"}
                                        />
                                      </div>
                                      <span className="ml-12">
                                        {a.team_id.team_name == null
                                          ? ""
                                          : a.team_id.team_name}
                                      </span>
                                    </div>
                                  </td>
                                  <td>{a.team_id.gender}</td>
                                  <td>
                                    {a.division !== undefined
                                      ? a.division.division_name
                                      : "Null"}
                                  </td>
                                  <td>
                                    {a.team_id.player.length !== 0
                                      ? a.team_id.player.length
                                      : "0"}
                                  </td>
                                  <td>
                                    {a.team_id.roster_player.length !== 0
                                      ? a.team_id.roster_player.length
                                      : "0"}
                                  </td>
                                  <td>
                                    <div className="dropdown event-drp">
                                      <i
                                        className="mdi mdi-dots-vertical"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      ></i>
                                      {/* More */}
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuLink"
                                      >
                                        {a.division !== undefined ? (
                                          <></>
                                        ) : (
                                          <>
                                            {" "}
                                            <Link
                                              className="dropdown-item"
                                              to={`/create-club-division/${id}/${a._id}`}
                                            >
                                              <i className="mdi mdi-plus website-ico mr-2"></i>
                                              Add Division
                                            </Link>
                                          </>
                                        )}
                                        {a.division !== undefined ? (
                                          <>
                                            <Link
                                              className="dropdown-item"
                                              to={`/createpool/${id}/${a._id}`}
                                            >
                                              <i className="mdi mdi-plus website-ico mr-2"></i>
                                              Add Pool
                                            </Link>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {/* })
                                                            } */}
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </>
                    ) : null}
                  </table>
                </div>

                <div className="d-md-none">
                  {team.map((v, i) => {
                    return (
                      <div className="reg-details" key={i}>
                        <div className="reg-content">
                          <Link
                            className="text-decoration-none text-blue fs-15"
                            to="/event-details"
                          >
                            {v.teamname}
                          </Link>

                          <div className="row">
                            <div className="col-6">
                              <div>
                                <span className="d-block text-gray mt-2 fs-13">
                                  Coach : {v.coachname}
                                </span>
                                <span className="d-block text-gray mt-2 fs-13">
                                  Gender: {v.gender}
                                </span>

                                <span className="d-block text-blue mt-2 fs-13">
                                  <i className="mdi mdi-pencil fw-m"></i>
                                  <Link
                                    to="#"
                                    className="text-blue text-decoration-none fs-13 ml-3 fw-m"
                                  >
                                    Roster Name
                                  </Link>
                                </span>
                              </div>
                            </div>

                            <div className="col-6">
                              <div>
                                <span className="d-block text-gray mt-2 fs-13">
                                  Status : {v.status}
                                </span>
                                <span className="d-block text-gray mt-2 fs-13">
                                  Players: {v.players}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* <<<<<<<<<--------------Schedule ------------->>>>>>>>>>>> */}

              <div
                className="tab-pane fade"
                id="schedule-tab"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                {clubTeam && (
                  <>
                    <div className="d-flex justify-content-end  ">
                      <div className="dropdown event-drp details-drp"></div>
                    </div>
                    {clubTeam.length == 0 ? (
                      <>
                        <span className="noti-type">
                          No schedules to display
                        </span>
                      </>
                    ) : (
                      <>
                        {/* <div className="col"> */}
                        {clubTeam.map((v, i) => {
                          return (
                            <React.Fragment key={v._id}>
                              <div>
                                <div className="d-flex justify-content-between">
                                  <h6 className="text-left payment mt-30 fw-m">
                                    ({i + 1})Team
                                  </h6>
                                </div>
                                <div className="row">
                                  <div className="col-md-4 w-75">
                                    <div className="text-left mt-18 ">
                                      <div className="dropdown event-drp d-flex justify-content-between">
                                        <label className="payment ml-3">
                                          {v.team_id.team_name}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <<<<<<-----Pools Info ------>>>>> */}

                              <div className="d-flex mt-24">
                                <h6 className="text-left payment mt-30 fw-m ml-3">
                                  Division
                                </h6>
                              </div>

                              {v.division == undefined ? (
                                <>
                                  <span className="noti-type ml-3">
                                    No division to display
                                  </span>
                                </>
                              ) : (
                                <>
                                  <div
                                    id="accordion"
                                    className="division w-50 accordian "
                                  >
                                    <div className="card ">
                                      <div className="justify-content-between d-flex align-items-center">
                                        <div
                                          className="card-header pl-3 w-100 event-drp"
                                          id={`pool-${i}`}
                                        >
                                          <h5 className="mb-0">
                                            <button
                                              className="btn btn-link collapsed text-decoration-none"
                                              data-toggle="collapse"
                                              data-target={`#pool-${v._id}`}
                                              aria-expanded="false"
                                              aria-controls={`pool-${v._id}`}
                                            >
                                              {v.division.division_name}

                                              <i className="mdi mdi-chevron-down down-arrow mr-4 ml-4"></i>
                                            </button>
                                          </h5>
                                        </div>
                                        {/* <i className="mdi mdi-dots-vertical pl-2 ml-2 event-drp dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i> */}

                                        {/* <div className="dropdown-menu event-drp " aria-labelledby="dropdownMenuLink">
                                                                                                <Link className="dropdown-item" to={(`/editpool/${v.event_id}/${a._id}/${v._id}`)}  >
                                                                                                    <i className="mdi mdi-border-color"></i>
                                                                                                    Edit Pool</Link>

                                                                                                <Link className="dropdown-item" to={`/event-details/${id}`} onClick={() => props.deletePoolHandler(a._id)} >
                                                                                                    <i className="fas fa-trash-alt"></i>  Delete</Link>
                                                                                            </div> */}
                                      </div>
                                      <div
                                        id={`pool-${v._id}`}
                                        className="collapse"
                                        aria-labelledby="pool-1"
                                        data-parent="#accordion"
                                      >
                                        <div className="card-body p-0">
                                          {/* desktop view */}
                                          <div className="table-responsive d-none d-md-block pl-3">
                                            {v.pool.length == 0 ? (
                                              <>
                                                <div className="p-2">
                                                  <span className="noti-type">
                                                    No pools to display
                                                  </span>
                                                </div>{" "}
                                              </>
                                            ) : (
                                              <>
                                                <table className="table event-table">
                                                  <thead>
                                                    <tr className="pl-2">
                                                      <th>Pool Name</th>
                                                      <th></th>
                                                      {/* <th>Club Name</th>
                                                                                                                <th>Couch Name</th> */}
                                                    </tr>
                                                  </thead>

                                                  {v.pool.map((b, i) => {
                                                    return (
                                                      <React.Fragment
                                                        key={b._id}
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td>
                                                              {b.pool_name}
                                                            </td>
                                                            <td>
                                                              <div className="dropdown event-drp">
                                                                <i
                                                                  className="mdi mdi-dots-vertical"
                                                                  data-toggle="dropdown"
                                                                  aria-haspopup="true"
                                                                  aria-expanded="false"
                                                                ></i>

                                                                <div
                                                                  className="dropdown-menu"
                                                                  aria-labelledby="dropdownMenuLink"
                                                                >
                                                                  <Link
                                                                    className="dropdown-item"
                                                                    to={`/edit-event-pool/${id}/${v._id}/${b._id}`}
                                                                  >
                                                                    <i className="mdi mdi-border-color"></i>
                                                                    Edit
                                                                  </Link>

                                                                  <Link
                                                                    className="dropdown-item"
                                                                    to={`/event-details/${id}`}
                                                                    onClick={() =>
                                                                      props.deleteEventpoolhandler(
                                                                        {
                                                                          pool: b._id,
                                                                          team: v._id,
                                                                        }
                                                                      )
                                                                    }
                                                                  >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                    Delete
                                                                  </Link>
                                                                </div>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </React.Fragment>
                                                    );
                                                  })}
                                                </table>
                                              </>
                                            )}
                                          </div>

                                          {/* mobile view */}
                                          {/* <div className="mt-3 d-md-none">
                                                                                                    {
                                                                                                        v.event_team.map((b, i) => {
                                                                                                            return <div className="d-flex justify-content-between reg-details" key={b._id}>
                                                                                                                <div className="d-flex">
                                                                                                                    <div className="dashboard-img mr-3">
                                                                                                                        <img src="./images/pch-images/pch.svg" />
                                                                                                                    </div>
                                                                                                                    <div className="reg-content">
                                                                                                                        <Link className="text-decoration-none text-blue fw-m" to="/event-details">{v.name}</Link>
                                                                                                                        <span className="d-block text-gray mt-2">Club Name: {b.team.club_name}</span>
                                                                                                                        <span className="d-block text-gray mt-2">coach Name: {b.team.coach_name}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        })
                                                                                                    }
                                                                                                </div> */}
                                        </div>
                                      </div>
                                    </div>

                                    {/* <div className="card mt-2 mt-md-0"> */}
                                    {/* <div className="card-header" id="pool-2">
                                                                                <h5 className="mb-0">
                                                                                    <button className="btn btn-link collapsed text-decoration-none" data-toggle="collapse" data-target="#pool-B" aria-expanded="false" aria-controls="pool-B">
                                                                                        Pool B(2)
                                                                                        <i className="mdi mdi-chevron-down down-arrow"></i>
                                                                                    </button>
                                                                                </h5>
                                                                            </div> */}
                                    {/* <div id="pool-B" className="collapse" aria-labelledby="pool-2" data-parent="#accordion-1">
                                                                                                <div className="card-body p-0"> */}

                                    {/* desktop view */}
                                    {/* <div className="table-responsive d-none d-md-block">
                                                                                        <table className="table event-table">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Name</th>
                                                                                                    <th>Club</th>
                                                                                                    <th>Players</th>
                                                                                                    <th>Payment</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    pool.map((v, i) => {
                                                                                                        return <tr key={i}>
                                                                                                            <td>
                                                                                                                <div className="d-flex align-items-center">
                                                                                                                    <div className="dashboard-img"></div>
                                                                                                                    <span className="ml-12">{v.name}</span>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td>{v.club}</td>
                                                                                                            <td>{v.player}</td>
                                                                                                            <td>{v.payment}</td>
                                                                                                        </tr>
                                                                                                    })
                                                                                                }
    
    
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div> */}

                                    {/* mobile view */}
                                    {/* <div className="mt-3 d-md-none">
                                                                                                        {
                                                                                                            pool.map((v, i) => {
                                                                                                                return <div className="d-flex justify-content-between reg-details" key={i}>
                                                                                                                    <div className="d-flex">
                                                                                                                        <div className="dashboard-img mr-3">
                                                                                                                            <img src="./images/pch-images/pch.svg" />
                                                                                                                        </div>
                                                                                                                        <div className="reg-content">
                                                                                                                            <Link className="text-decoration-none text-blue fw-m" to="/event-details">{v.name}</Link>
                                                                                                                            <span className="d-block text-gray mt-2">Club Name: {v.club}</span>
                                                                                                                            <span className="d-block text-gray mt-2">Payment: {v.payment}</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            })
                                                                                                        }
                                                                                                    </div> */}
                                    {/* </div>
                                                                                            </div>
                                                                                        </div> */}

                                    {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
    
                                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                                            <Link className="dropdown-item" to={(`/event-details/pool/${v.event_id}/${a._id}/${v._id}`)}  >
                                                                                                <i className="mdi mdi-border-color"></i>
                                                                                                Edit Pool</Link>
    
                                                                                            <Link className="dropdown-item" to={`/event-details/${id}`} onClick={ () => axios.delete(Org_EditPool_Url+ `/${a._id}`, header)} >
                                                                                                <i className="fas fa-trash-alt"></i>  Delete</Link>
                                                                                        </div> */}
                                  </div>
                                  {/* </div> */}
                                  {/* </>
                                                                        })
                                                                    } */}
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                        {/* <div className="col-md-6 mt-30 order-md-1 order-2">
                                                        <div className="schedule">
                                                            <h5 className="text-left payment fw-m">Full Schedule</h5>
                                                            <div className="text-left mt-18 d-flex align-items-center justify-content-between">
                                                                <span className="mb-0 payment">Full Name</span>
                                                                <div className="d-flex align-items-center">
                                                                    <i className="mdi mdi-open-in-new website-ico"></i>
                                                                    <h5 className="payment mb-0 ml-12">Website</h5>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div> */}
                        {/* <div className="col-md-6 mt-42 order-md-2 order-1">
                                                        <h5 className="text-left payment fw-m">Division B</h5>
                                                        <div id="accordion" className="division">
                                                            <div className="card">
                                                                <div className="card-header" id="headingThree">
                                                                    <h5 className="mb-0">
                                                                        <button className="btn btn-link collapsed text-decoration-none" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                            Pool C(3)
                                                                            <i className="mdi mdi-chevron-down down-arrow"></i>
                                                                        </button>
                                                                    </h5>
                                                                </div>
                                                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">

                                                                    <div className="card-body p-0">
                                                                        <div className="basic-info d-block text-left">
                                                                            <label className="heading">Team Name</label>
                                                                            <span className="noti-type">Club Name</span>
                                                                        </div>
                                                                        <div className="basic-info d-block text-left">
                                                                            <label className="heading">Team Name</label>
                                                                            <span className="noti-type">Club Name</span>
                                                                        </div>
                                                                        <div className="basic-info d-block text-left">
                                                                            <label className="heading">Team Name</label>
                                                                            <span className="noti-type">Club Name</span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="card">
                                                                <div className="card-header" id="headingFour">
                                                                    <h5 className="mb-0">
                                                                        <button className="btn btn-link collapsed text-decoration-none" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                            Pool D(3)
                                                                            <i className="mdi mdi-chevron-down down-arrow"></i>
                                                                        </button>
                                                                    </h5>
                                                                </div>
                                                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">

                                                                    <div className="card-body p-0">
                                                                        <div className="basic-info d-block text-left">
                                                                            <label className="heading">Team Name</label>
                                                                            <span className="noti-type">Club Name</span>
                                                                        </div>
                                                                        <div className="basic-info d-block text-left">
                                                                            <label className="heading">Team Name</label>
                                                                            <span className="noti-type">Club Name</span>
                                                                        </div>
                                                                        <div className="basic-info d-block text-left">
                                                                            <label className="heading">Team Name</label>
                                                                            <span className="noti-type">Club Name</span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                        {/* </div> */}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};

// export default EventDetails;

const mapStateToProps = (state) => ({
  // submit: state.deleteEventReducer,
  // submit1: state.deleteScheduleReducer,
  registered: state.registerSelectedTeamReducer,
  createDivision: state.createEventDivisionReducer,
  editDivision: state.editeventDivisionReducer,
  editPool: state.editPoolReducer,
  deleteDivision: state.deleteEventReducer,
  deletePool: state.deleteEventPoolReducer,
  createpool: state.createPoolReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getEventHandler: (user) => dispatch(getAllEvent(user)),
  getEventByIdHandler: (user) => dispatch(getEventById(user)),
  resetregisteredSelectedTeamReducer: () =>
    dispatch({ type: RESET_REGISTER_SELECTED_TEAM }),
  resetCreateDivision: () => dispatch({ type: RESET_CREATE_EVENT_DIVISION }),
  getEventDivisionHandler: (user) => dispatch(geteventDivision(user)),
  reseteditEventDivisionreducer: (user) =>
    dispatch({ type: RESET_EDIT_EVENT_DIVISION }),
  deleteDivisionHandler: (user) => dispatch(deleteEventDivision(user)),
  resetDeleteEventDivision: (user) => dispatch({ type: RESET_DELETE_EVENT }),
  deletePoolHandler: (user) => dispatch(deletePool(user)),
  resetDeletePoolHandler: () => dispatch({ type: RESET_DELETE_EVENT_POOL }),
  resetEditPoolHandler: () => dispatch({ type: RESET_EDIT_POOL }),
  resetCreatePool: () => dispatch({ type: RESET_CREATE_POOL }),
  getClubEventTeamHandler: (user) => dispatch(getClubEventTeam(user)),
  deleteEventpoolhandler: (user) => dispatch(deleteClubEventPool(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
// 1239
