import React, { useState,useEffect } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import Toaster from '../../../Toaster/Toaster';
import { withRouter ,useHistory} from 'react-router-dom';
import { useParams } from 'react-router';
import { connect, useSelector } from 'react-redux';
import {editPool } from '../../../service/action/userAction';
import { RESET_EDIT_POOL } from '../../../service/constants';
import { getClubEventTeam, geteventDivision } from '../../../service/action/Club/clubAction';




const EditEventPool = (props) => {

    let history = useHistory();
    const {id,id1,id2}= useParams()

   
 

    const clubEventTeam = props.getClubEventTeamHandler

    
    useEffect(()=>{
        clubEventTeam({ id: id })
    },[])
    
    const clubTeam = useSelector(state => state.getClubEventTeamReducer.user.data)


    const [teams,SetTeams]=useState(null)



    const getDivision=useSelector(state => state.geteventDivisionReducer.user)

    const [state, setState] = useState({
        pool_name: ""
    })

    const [errors, setErrors] = useState({
        
        pool_name: false

    })

    const getPoolName=()=>{
        if(clubTeam){
            const result = clubTeam.find( ({_id}) => _id === id1 );
            const poolArray=result.pool
            const finalPoolName=poolArray.find(({_id})=>_id==id2);
            
            setState({
                
                pool_name:finalPoolName.pool_name
            })
        }
    }

    useEffect(()=>{
        getPoolName()
        // if(allDivision){
            // const result = allDivision.find( ({ _id }) => _id === id2 );
            // const poolArray=result.pool
            // const finalPoolName=poolArray.find(({_id})=>_id==id1);
            
            // setState(...prevState=>({
            //     prevState,
            //     pool_name:finalPoolName.pool_name
            // }))
        // }
    },[clubTeam])



    const eventHandler = (e) => {
        const { id, value, name } = e.target


        setState(prevState => ({
            ...prevState,
            [id]: value
            // [e.target.name]: e.target.value.trim()
        }))
        switch (name) {
            case "pool_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        pool_name: "Pool name can not be empty"
                    }))

                    document.getElementById("pool_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        pool_name: false
                    }))

                    document.getElementById("pool_name").classList.remove("error")
                }

                break;

            default:
        }
    }

    const notify = (msg) => toast(msg);

    const submitRequest = (e) => {
        e.preventDefault();
        // alert("click")

        if (state.pool_name == "") {
            setErrors(prevState => ({
                ...prevState,
                pool_name: "Division name can not be empty"
            }))
            document.getElementById("pool_name").classList.add("error")
        }

        if (state.pool_name !== "") {
            props.editPoolhandler({
                id1:id2,
                pool_name: state.pool_name
            })
        }
    }

    useEffect(()=>{
        if(props.submit?.success){
            // notify("Pool Updated")
            // props.resetEditPoolReducer()

            history.push(`/events/${id}`);
        } else if(props.submit?.error){
            notify("Pool Update Error")
        }
    },[props.submit])
    // if(props.submit?.success){
    //         notify("Season submitted successfully.")
    //         props.resetCreateSeasonReducer()
    //         return <Redirect to="/season" />;
    //     }

    return (
        <>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <h5 className="dash-head">Edit Pool</h5>
                    </div>

                    <form >
                        <div className="row">
                            <div className="col-md-4 col-xl-4">
                                <div className="mt-24">
                                    {/* <div className="text-left">
                                        <label className="text-gray fs-13">Division Name <span className="text-red fw-m">*</span></label>
                                        {
                                            allDivision?<>
                                            
                                            <select className={classNames("form-control team-name payterm", { "error": errors.pool_name })} id="pool_name" name="pool_name" value={state.pool_name} onChange={eventHandler}>
                                                        <option value="#" >Division *</option>
                                                        {allDivision.map((v, i) => {
                                                        return (
                                                            <option value={v._id}>{v.pool_name}</option>
                                                        )
                                                    })}
                                                    </select>
    
                                            {errors.pool_name &&
                                                <span className='text-red small d-block'>* {errors.pool_name}</span>}
                                            </>:<><h4>Loading...</h4></>
                                        }

                                    </div> */}
                                    <div className="text-left">
                                        <label className="text-gray fs-13">Pool Name <span className="text-red fw-m">*</span></label>
                                        <input type="text" className="form-control team-name" id="pool_name" name="pool_name" value={state.pool_name} placeholder="Pool name" required onChange={eventHandler} />

                                        {errors.pool_name &&
                                            <span className='text-red small d-block'>* {errors.pool_name}</span>}

                                    </div>
                                   
                                    <div className="mt-3 text-left">
                                        <a className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitRequest} >Submit</a>
                                    </div>

                                    <Toaster />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )

}

// export default withRouter(Org_CreateDivision)



const mapStateToProp = state => ({
    submit: state.editPoolReducer,

})

const mapDispatchToProps = dispatch => ({
    // editDivisionhandler: user => dispatch(editDivision(user)),
    resetEditPoolReducer:()=>dispatch({type:RESET_EDIT_POOL}),
    editPoolhandler:user=>dispatch(editPool(user)),
    getClubEventTeamHandler: (user) => dispatch(getClubEventTeam(user)),

})
export default connect(mapStateToProp,mapDispatchToProps)(withRouter(EditEventPool));