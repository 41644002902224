import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  getAccountDetails,
  getOrgClubList,
} from "../../../service/action/userAction";
import Spinner from "../../../Spinner/Spinner";
import $ from "jquery";
import { RESET_ORG_CLUB } from "../../../service/constants";

const Club = (props) => {
  const { id, id1 } = useParams();
  const history = useHistory();

  useEffect(() => {
    props.resetClubHandler();
  }, []);

  const getClubList = props.getOrg_ClubListHandler;
  useEffect(() => {
    getClubList();
    props.getAccountDetails();
  }, []);

  const [loading, setLoading] = useState(true);

  const ClubList = useSelector(
    (state) => state.getOrgClubListReducer.user.data
  );

  const [image, setImage] = useState([]);

  useEffect(() => {
    if (props.club?.success) {
      // setImage(ClubList.map(v=>v.club))
      setLoading(false);
    }
  }, [props.club]);

  const [state, setState] = useState({
    search: "",
  });

  const Searchval = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    UpdateArray(e.target.value);
  };

  const UpdateArray = (search) => {
    var query = search;

    $(".filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".pay-row").addClass("d-none");
      } else {
        $(v).parents(".pay-row").removeClass("d-none");
      }
    });
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-center">
            {loading ? (
              <div className="w-full h-[60vh] flex flex-col justify-center items-center">
                <Spinner />
              </div>
            ) : null}
          </div>
          {ClubList && !loading && (
            <>
              <div className="text-left">
                <div className="create-team-head-event">
                  <div className="d-flex align-items-center">
                    <h5 className="dash-head pr-2 mb-0">Clubs</h5>

                    <div className="d-md-flex align-items-center d-none">
                      <div className="search-section">
                        <i className="mdi mdi-magnify search-ico"></i>
                        <input
                          type="text"
                          className="form-control"
                          id="search"
                          name="search"
                          value={state.search}
                          placeholder="Search"
                          onChange={Searchval}
                          autoComplete="off"
                        />
                      </div>
                      {/* <div>
                                                <i className="mdi mdi-filter-outline filter-ico"></i>
                                            </div> */}
                    </div>
                  </div>
                  <div
                    className="py-2 px-4 pointer border rounded"
                    style={{
                      backgroundColor: "#143d4a",
                      color: "#ffffff",
                    }}
                    onClick={() => {
                      history.push("/club-invite");
                    }}
                  >
                    <i className="mdi mdi-plus mr-2 website-ico"></i>Add Clubs
                  </div>
                </div>
              </div>
              {ClubList && ClubList.length == 0 ? (
                <>
                  <div className="container d-flex justify-content-center align-items-center">
                    <span className="noti-type text-danger">
                      No clubs to display
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {/* desktop view */}
                  <div className="table-responsive d-none d-md-block">
                    <table className="table event-table mt-30">
                      <thead>
                        <tr>
                          {/* <th>Name</th> */}
                          {/* <th>Phone No.</th>
                                    <th>Payment</th>
                                    <th>Status</th> */}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {ClubList ? (
                          <>
                            {ClubList.length == 0 ? (
                              <>
                                <span className="noti-type">
                                  No clubs to display
                                </span>
                              </>
                            ) : (
                              <>
                                {ClubList &&
                                  ClubList.map((v, i) => {
                                    return (
                                      <tr key={v._id} className="pay-row">
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <div className="dashboard-img">
                                              {/* <img src={v.club.profile_image !== "" ? v.club.profile_image : "./images/pch-images/pch.svg"} /> */}
                                              <img
                                                src={
                                                  v.profile_image !== ""
                                                    ? v.profile_image
                                                    : "./images/pch-images/pch.svg"
                                                }
                                              />
                                            </div>
                                            <Link
                                              className="ml-3 text-blue text-decoration-none filter-string"
                                              to={`/clubs/${v._id}`}
                                            >
                                              <span
                                                className="text-dark"
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {" "}
                                                {v.name}
                                              </span>
                                            </Link>
                                          </div>
                                        </td>
                                        {/* <td>{v.Phone}</td>
                                            <td>{v.payment}</td>
                                            <td>{v.status}</td> */}
                                        {/* <td>
                                                <div className="dropdown event-drp">
                                                    <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                    More
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <a className="dropdown-item" href="#">
                                                            <i className="mdi mdi-bookmark-outline"></i>
                                                            Bookmark</a>
                                                        <a className="dropdown-item" href="#">
                                                            <i className="mdi mdi-plus"></i>
                                                            Invite</a>
                                                    </div>
                                                </div>
                                            </td> */}
                                      </tr>
                                    );
                                  })}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <p className="text-danger">null</p>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* mobile view */}

                  <div className="mt-30 d-md-none">
                    {ClubList && ClubList.length == 0 ? (
                      <>
                        <span className="noti-type">No clubs to display</span>
                      </>
                    ) : (
                      <>
                        {ClubList.map((v, i) => {
                          return (
                            <div
                              className="d-flex justify-content-between reg-details"
                              key={v._id}
                            >
                              <div className="d-flex">
                                <div className="dashboard-img mr-3">
                                  <img
                                    src={
                                      v.profile_image !== ""
                                        ? v.profile_image
                                        : "./images/pch-images/pch.svg"
                                    }
                                  />
                                </div>
                                <div className="reg-content">
                                  <Link
                                    className="text-decoration-none text-blue fw-m"
                                    to={`/clubs/${v._id}`}
                                  >
                                    {v.name}
                                  </Link>
                                  {/* <span className="d-block text-gray mt-2">Payment: {v.payment}</span>
                                            <span className="d-block text-gray mt-2">Status: {v.status}</span> */}
                                </div>
                              </div>
                              {/* <div className="dropdown event-drp">
                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-bookmark-outline"></i>
                                                Bookmark</a>
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-plus"></i>
                                                Invite</a>                                           
                                        </div>
                                    </div> */}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(Club);

const mapStateToProps = (state) => ({
  submit: state.createTeamReducer,
  club: state.getOrgClubListReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getOrg_ClubListHandler: (user) => dispatch(getOrgClubList(user)),
  getAccountDetails: (user) => dispatch(getAccountDetails(user)),
  resetClubHandler: () => dispatch({ type: RESET_ORG_CLUB }),
  // userDataHandler: user => dispatch(userData(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Club));
