import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { connect, useSelector } from "react-redux";
import {
  acceptCoach,
  acceptPlayer,
  deleteDivision,
  activeDeactivePlayer,
  coachAcceptList,
  deleteClubTeam,
  getAllDivision_club,
  getClubAllTeam,
  getClubTeam,
  getCoachInvitationList,
  getPlayerInvitation,
  getPlayerList,
  playerAcceptList,
} from "../../../service/action/Club/clubAction";
import { toast } from "react-toastify";
import {
  RESET_ACCEPT_COACH,
  RESET_ACCEPT_PLAYER,
  RESET_ACTIVE_PLAYER,
  RESET_CREATE_DIVISION,
  RESET_CREATE_TEAM,
  RESET_DELETE_DIVISION,
  RESET_DELETE_EVENT,
  RESET_EDIT_CLUB_TEAM,
  RESET_EDIT_DIVISION,
} from "../../../service/constants";
import Toaster from "../../../Toaster/Toaster";
import { RESET_DELETE_EVENTTEAM } from "../../../service/constants";
import Spinner from "../../../Spinner/Spinner";
import {
  getAccountDetails,
  getAllSeason,
} from "../../../service/action/userAction";
import DeletePopUp from "../../components/DeletePopUp/DeletePopUp";
import PlayerImagePopup from "../../components/PlayerImagePopup/PlayerImagePopup";
import { Button } from "react-bootstrap";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";

const TeamsCoches = (props) => {
  // useState define

  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedProfileImage, setSelectedProfileImage] = useState(null);

  const [loading, setLoading] = useState(true);
  const [playerMember, setPlayerMember] = useState(null);
  const [state, setState] = useState({
    search: "",
  });
  const [isTeamsDelete, setIsTeamsDelete] = useState(false);
  const [isDivisionDelete, setIsDivisionDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [seassion, setSeassion] = useState({
    name: "All",
    id: "All",
  });

  const [seasonList, setSeasonList] = useState([]);
  const [filterTeam, setFilterTeam] = useState([]);
  // useSelecter define
  const allTeam = useSelector((state) => state.getClubAllTeamReducer.user);
  const PlayerInvitation = useSelector(
    (state) => state.getPlayerInvitationReducer.user.data
  );
  const acceptPlayerMsg = useSelector(
    (state) => state.acceptPlayerReducer.message
  );
  const acceptCoachMsg = useSelector(
    (state) => state.acceptCoachReducer.message
  );
  const coachInvitation = useSelector(
    (state) => state.getCoachInvitationReducer.user.data
  );
  const coachAcceptList = useSelector(
    (state) => state.coachAcceptListReducer.user.data
  );
  const playerAcceptList = useSelector(
    (state) => state.playerAcceptListReducer.user.data
  );
  const deleteTeam = useSelector((state) => state.deleteEventTeamReducer);
  const editTeamMsg = useSelector((state) => state.editClubTeamReducer.message);
  const statusUpdateMsg = useSelector(
    (state) => state.activePlayerReducer.message
  );
  const accountDetails = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );
  const alldivision = useSelector((state) => state.getAllClubDivision.user);
  // const deleteDivision = useSelector((state) => state.deleteEventReducer);

  const allseason = useSelector((state) => state.getAllSeasonReducer.user);
  const getdata = props.getTeamHandler;
  const getAllSeasion = props.getSeasonHandler;
  const getInvitation = props.getInvitationHandler;
  const getCoachInvitation = props.getCoachInvitationHandler;
  const getCoachAcceptList = props.getCoachAcceptListHandler;
  const getPlayerAcceptList = props.getPlayerAcceptListHandler;
  const division = props.getAllDivisionHandler;

  const notify = (msg) => toast(msg);
  const location = useLocation();

  useEffect(() => {
    const currentTab = document.getElementById(location.state);
    if (currentTab) {
      setTimeout(() => {
        currentTab.click();
      }, [50]);
    }
  }, [location.state]);

  useEffect(() => {
    setSeasonList(allseason);
  }, [allseason]);

  useEffect(() => {
    getPlayerAcceptList();
    getInvitation();
    getCoachAcceptList();
    getCoachInvitation();
    division();
    getAllSeasion();
  }, []);

  useEffect(() => {
    const filterTeamList = allTeam.filter((e) => {
      if (seassion.id === "All") {
        return e;
      } else if (seassion.id === "None") {
        return e.season === undefined || e.season === null;
      } else {
        return e?.season?._id === seassion.id;
      }
    });
    setFilterTeam(filterTeamList);
  }, [allTeam, seassion]);

  useEffect(() => {
    if (deleteTeam.success) {
      notify(deleteTeam.message);
      getdata({ id: accountDetails?._id });
    }
  }, [deleteTeam.success]);

  useEffect(() => {
    if (props?.acceptPlay.success) {
      const list = playerAcceptList.filter((e) => e.club_player);
      setPlayerMember(list);
    }
  }, [props.acceptPlay]);

  useEffect(() => {
    if (accountDetails?._id) {
      getdata({ id: accountDetails?._id });
    }
  }, [accountDetails]);

  useEffect(() => {
    if (props.getTeam?.success) {
      setLoading(false);
    }
  }, [props.getTeam]);

  useEffect(() => {
    if (playerAcceptList) {
      const list = playerAcceptList.filter((e) => e.club_player);
      setPlayerMember(list);
    }
  }, [playerAcceptList]);

  const Searchval = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    UpdateArray(e.target.value);
  };

  const UpdateArray = (search) => {
    var query = search;

    $(".team-table .filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".team-row").addClass("d-none");
      } else {
        $(v).parents(".team-row").removeClass("d-none");
      }
    });

    $(".players-table .filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".players-row").addClass("d-none");
      } else {
        $(v).parents(".players-row").removeClass("d-none");
      }
    });

    $(".coach-table .filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".coach-row").addClass("d-none");
      } else {
        $(v).parents(".coach-row").removeClass("d-none");
      }
    });
    $(".division-div .filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".division-row").addClass("d-none");
      } else {
        $(v).parents(".division-row").removeClass("d-none");
      }
    });
  };

  useEffect(() => {
    if (props.deleteDivisionReducer.success) {
      notify("Division Deleted");
      props.resetDeleteDivision();
      division();
      // history.push(`/event-details/${id}`);
    }
  }, [props.deleteDivisionReducer, alldivision]);

  useEffect(() => {
    if (props.editDivision.success) {
      notify("Division name changed");
      division();
      props.resetEditDivision();
    }
  }, [props.editDivision]);

  useEffect(() => {
    if (props.createDivisionReducer.success) {
      notify("Division created");
      division();
      props.resetCreateDivision();
    }
  }, [props.createDivisionReducer]);

  useEffect(() => {
    if (props.createTeamReducer.success) {
      notify("Team created");
      getdata({ id: accountDetails?._id });
      props.resetCreateTeamReducer();
    }
  }, [props.createTeamReducer]);
  useEffect(() => {
    if (props.TeamReducer.success) {
      notify("Team deleted");
      getdata({ id: accountDetails?._id });
      props.resetdeleteEventTeamReducer();
    }
  }, [props.TeamReducer]);

  useEffect(() => {
    if (props.editTeam.success) {
      notify("Team Edited");
      getdata({ id: accountDetails?._id });
      props.resetEditTeamReducer();
    }
  }, [props.editTeam]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head">
              <div className="dropdown details-drp mt-2 pt-1 ml-3 d-flex justify-content-between">
                <h5 className="dash-head mr-3 mb-0">Teams</h5>
                <i
                  className="mdi mdi-dots-vertical"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link className="dropdown-item" to="/teams/invite-player">
                    Invite Player
                  </Link>
                  <Link className="dropdown-item" to="/teams/add-coach">
                    Invite Coach
                  </Link>
                  <Link className="dropdown-item" to="/teams/create-team">
                    Create Team
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="team-details mt-4 justify-content-between">
            <div className="d-flex justify-content-between flex-wrap">
              <ul className="nav nav-pills my-2" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="teams"
                    data-toggle="pill"
                    href="#teams-tab"
                    role="tab"
                    aria-controls="pills-home"
                    // aria-selected="true"
                  >
                    Teams
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="=players"
                    data-toggle="pill"
                    href="#players-tab"
                    role="tab"
                    aria-controls="pills-profile"
                    // aria-selected="false"
                  >
                    Players
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="=coches"
                    data-toggle="pill"
                    href="#coaches-tab"
                    role="tab"
                    aria-controls="pills-profile"
                    // aria-selected="false"
                  >
                    Coaches
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="=division"
                    data-toggle="pill"
                    href="#division-tab"
                    role="tab"
                    aria-controls="pills-profile"
                    // aria-selected="false"
                  >
                    Division
                  </a>
                </li>
              </ul>
              <div className="d-md-flex align-items-center my-2">
                <div className="search-section mt-md-0">
                  <i className="mdi mdi-magnify search-ico"></i>
                  <input
                    type="text"
                    className="form-control"
                    id="search"
                    name="search"
                    value={state.search}
                    placeholder="Search"
                    onChange={Searchval}
                    autoComplete="off"
                  />
                </div>
                <div className="mt-md-0">
                  {/* <i className="mdi mdi-filter-outline filter-ico"></i> */}
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div className="me-3" style={{ minWidth: "150px" }}>
                  <b>Season: </b>
                  {seassion.name}
                </div>
                <div className="dropdown event-drp">
                  <i
                    className="mdi mdi-filter-outline filter-ico"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  ></i>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenu2"
                  >
                    <Link
                      className="dropdown-item"
                      to="/teams"
                      onClick={() => {
                        setSeassion({
                          name: "All",
                          id: "All",
                        });
                      }}
                    >
                      All
                    </Link>
                    {seasonList?.map((e, i) => {
                      return (
                        <Link
                          className="dropdown-item"
                          to="/teams"
                          key={i}
                          onClick={() => {
                            setSeassion({
                              name: e?.season_name,
                              id: e?._id,
                            });
                          }}
                        >
                          {e?.season_name}
                        </Link>
                      );
                    })}
                    <Link
                      className="dropdown-item"
                      to="/teams"
                      onClick={() => {
                        setSeassion({
                          name: "None",
                          id: "None",
                        });
                      }}
                    >
                      None
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <div className="w-full h-[30rem] flex flex-col justify-center items-center">
                <Spinner />
              </div>
            ) : (
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="teams-tab"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="mt-30">
                    {/* desktop view */}
                    {filterTeam ? (
                      <>
                        {filterTeam.length === 0 ? (
                          <>
                            <div className="container d-flex justify-content-center align-items-center">
                              <span className="noti-type">
                                No teams to display
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="table-responsive d-none d-md-block">
                              <table className="table event-table team-table">
                                <thead>
                                  <tr>
                                    <th>Teams</th>
                                    {/* <th>Gender</th> */}
                                    <th>Coach</th>
                                    <th>division</th>
                                    <th>season</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>

                                {filterTeam && (
                                  <tbody>
                                    {filterTeam.map((v, i) => {
                                      return (
                                        <tr key={i} className="team-row">
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <div className="dashboard-img">
                                                <img
                                                  src={
                                                    v.image !== ""
                                                      ? v.image
                                                      : accountDetails?.profile_image !==
                                                        ""
                                                      ? `${accountDetails?.profile_image}`
                                                      : "./images/pch-images/pch.svg"
                                                  }
                                                />
                                              </div>
                                              <Link
                                                className="ml-12 filter-string text-decoration-none text-blue fw-m"
                                                to={`/teams/${v._id}`}
                                              >
                                                {v.team_name}
                                              </Link>
                                            </div>
                                          </td>
                                          <td>
                                            {v.coach.length > 0
                                              ? v.coach.length[0]
                                              : "N/A"}
                                          </td>
                                          {/* <td>{v.coach !== null ? v.coach[0].coach_name : "null"}</td> */}
                                          <td>
                                            {v.division === undefined
                                              ? "N/A"
                                              : v.division !== null
                                              ? v.division.division_name
                                              : "N/A"}
                                          </td>
                                          <td>
                                            {v.season === undefined
                                              ? "N/A"
                                              : v.season !== null
                                              ? v.season.season_name
                                              : "N/A"}
                                          </td>
                                          <td>
                                            <div className="dropdown event-drp">
                                              <i
                                                className="mdi mdi-dots-vertical"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              ></i>
                                              {/* More */}
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                              >
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/teams/${v._id}/edit-team`}
                                                >
                                                  <i className="mdi mdi-border-color"></i>
                                                  Edit
                                                </Link>

                                                {/* <Link className="dropdown-item" to={`/teams`} onClick={() => props.deleteClubTeamHandler(v._id)} */}
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/teams`}
                                                  onClick={() => {
                                                    setIsTeamsDelete(true);
                                                    setDeleteId(v._id);
                                                  }}
                                                >
                                                  <i className="fas fa-trash-alt"></i>{" "}
                                                  Delete
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                )}
                              </table>
                            </div>

                            {/* mobile view */}
                            {filterTeam && (
                              <div className="mt-4 d-md-none team-table">
                                {filterTeam.map((v, i) => {
                                  return (
                                    <div className="team-row" key={i}>
                                      <div
                                        className="d-flex justify-content-between reg-details"
                                        key={i}
                                      >
                                        <div className="d-flex">
                                          <div className="dashboard-img mr-3">
                                            <img
                                              src={
                                                v?.image !== ""
                                                  ? v?.image
                                                  : accountDetails?.profile_image !==
                                                    ""
                                                  ? `${accountDetails?.profile_image}`
                                                  : "./images/pch-images/pch.svg"
                                              }
                                            />
                                          </div>
                                          <div className="reg-content">
                                            <Link
                                              className="filter-string text-decoration-none text-blue fw-m"
                                              to={`/teams/${v._id}`}
                                            >
                                              {" "}
                                              {v.team_name}
                                            </Link>
                                            <div className="d-flex align-items-center mb-1 flex-wrap">
                                              <p className="text-left text-gray mb-0 fs-14">
                                                Coach :{" "}
                                              </p>
                                              <p className="text-left text-gray mb-0 fs-14 ml-2">
                                                {v.coach.length > 0
                                                  ? v.coach.length[0]
                                                  : "N/A"}
                                              </p>
                                            </div>
                                            <div className="d-flex align-items-center mb-1 flex-wrap">
                                              <p className="text-left text-gray mb-0 fs-14">
                                                Division :{" "}
                                              </p>
                                              <p className="text-left text-gray mb-0 fs-14 ml-2">
                                                {v.division !== null
                                                  ? v.division?.division_name
                                                  : "null"}
                                              </p>
                                            </div>
                                            <div className="d-flex align-items-center mb-1 flex-wrap">
                                              <p className="text-left text-gray mb-0 fs-14">
                                                Season :{" "}
                                              </p>
                                              <p className="text-left text-gray mb-0 fs-14 ml-2">
                                                {v.season !== null
                                                  ? v.season?.season_name
                                                  : "null"}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/*  */}
                </div>

                <div
                  className="tab-pane fade"
                  id="players-tab"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="mt-30">
                    {/* <h6 className="text-left">Members</h6> */}

                    {/* desktop view */}
                    {playerMember ? (
                      <>
                        {playerMember.length === 0 ? (
                          <>
                            <div className="container d-flex justify-content-center align-items-center h-25">
                              <span className="noti-type">
                                No players found
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            {playerMember ? (
                              <>
                                <div className="table-responsive d-none d-md-block">
                                  <table className="table event-table players-table">
                                    <thead>
                                      <tr>
                                        <th>Players</th>
                                        {/* <th>Status</th> */}
                                        {/* <th>Status</th> */}
                                        {/* <th>Grad Yr.</th> */}
                                        {/* <th>Position</th> */}
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {playerMember.map((v, i) => {
                                        return (
                                          <tr
                                            key={v._id}
                                            className="players-row"
                                          >
                                            <td>
                                              <div className="d-flex align-items-center">
                                                <div className="dashboard-img">
                                                  <img
                                                    src={
                                                      v.profile_image !== ""
                                                        ? v.profile_image
                                                        : "./images/pch-images/pch.svg"
                                                    }
                                                    onClick={() => {
                                                      setOpenImageModal(true);
                                                      setSelectedProfileImage(
                                                        v.profile_image !== ""
                                                          ? v.profile_image
                                                          : "./images/pch-images/pch.svg"
                                                      );
                                                    }}
                                                  />
                                                </div>
                                                <div className="ml-12 ">
                                                  <Link
                                                    className=" filter-string text-decoration-none text-blue fw-m"
                                                    to={`/player-detail/${v._id}`}
                                                  >{`${v?.first_name} ${v?.last_name}`}</Link>
                                                  <span className="noti-type mt-2">
                                                    Email: {v.email}
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                                {/* mobile view */}
                                <div className="mt-4 d-md-none players-table">
                                  {playerMember.map((v, i) => {
                                    return (
                                      <div className="players-row" key={i}>
                                        <div
                                          className="d-flex justify-content-between reg-details"
                                          key={i}
                                        >
                                          <div className="d-flex">
                                            <div className="dashboard-img mr-3">
                                              <img
                                                src={
                                                  v.profile_image !== ""
                                                    ? v.profile_image
                                                    : "./images/pch-images/pch.svg"
                                                }
                                                onClick={() => {
                                                  setOpenImageModal(true);
                                                  setSelectedProfileImage(
                                                    v.profile_image !== ""
                                                      ? v.profile_image
                                                      : "./images/pch-images/pch.svg"
                                                  );
                                                }}
                                              />
                                            </div>
                                            <div className="reg-content">
                                              <Link
                                                className="text-decoration-none text-blue fw-m filter-string"
                                                to={`/player-detail/${v._id}`}
                                              >{`${v?.first_name} ${v?.last_name}`}</Link>
                                              <div className="d-flex align-items-center mb-1 flex-wrap">
                                                <p className="text-left text-gray mb-0 fs-14">
                                                  Email: &nbsp;
                                                </p>
                                                <p className="text-left text-gray mb-0 fs-14">
                                                  {v.email !== "" && v.email
                                                    ? v.email
                                                    : "-"}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            ) : null}
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="coaches-tab"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="mt-30">
                    <h6 className="text-left">Invited</h6>

                    {coachInvitation ? (
                      <>
                        {coachInvitation.length === 0 ? (
                          <>
                            <div className="container d-flex justify-content-center align-items-center h-50">
                              <span className="noti-type">
                                No invitations to display
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* desktop view */}
                            <div className="table-responsive d-none d-md-block">
                              <table className="table event-table coach-table">
                                <thead>
                                  <tr>
                                    <th>Coaches</th>
                                    <th>Email Address</th>
                                    <th>Phone no.</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {coachInvitation.map((v, i) => {
                                    return (
                                      <tr key={i} className="coach-row">
                                        {v.status === 0 ? (
                                          <>
                                            <td>
                                              <div className="d-flex align-items-center">
                                                <div className="dashboard-img">
                                                  <img src="./images/pch-images/pch.svg" />
                                                </div>
                                                <span className="ml-12 filter-string">
                                                  {v.coach.name}
                                                </span>
                                              </div>
                                            </td>
                                            <td>{v.email}</td>
                                            <td>{v.coach.mobile_number}</td>
                                            <td>
                                              <div className="d-flex align-items-center justify-content-around">
                                                <div className="cancel">
                                                  <i className="mdi mdi-close"></i>
                                                  <Link
                                                    to="/teams"
                                                    className="text-decoration-none"
                                                    onClick={() =>
                                                      props.acceptCoachHandler({
                                                        id: v._id,
                                                        status: 2,
                                                      })
                                                    }
                                                  >
                                                    Reject
                                                  </Link>
                                                </div>
                                                <div className="accept">
                                                  <i className="mdi mdi-check"></i>
                                                  <Link
                                                    to="/teams"
                                                    className="text-decoration-none"
                                                    onClick={() =>
                                                      props.acceptCoachHandler({
                                                        id: v._id,
                                                        status: 1,
                                                      })
                                                    }
                                                  >
                                                    Approve
                                                  </Link>
                                                </div>
                                              </div>
                                            </td>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}

                        {/* mobile view */}

                        <div className="mt-4 d-md-none">
                          {coachInvitation.map((v, i) => {
                            return (
                              <div
                                className="d-flex justify-content-between reg-details"
                                key={i}
                              >
                                <div className="d-flex">
                                  <div className="dashboard-img mr-3">
                                    <img src="./images/pch-images/pch.svg" />
                                  </div>
                                  <div className="reg-content">
                                    <Link
                                      className="text-decoration-none text-blue fw-m"
                                      to="/event-details"
                                    >
                                      {v.coach.name}
                                    </Link>
                                    <span className="d-block text-gray mt-2">
                                      Mail: {v.email}
                                    </span>
                                    <span className="d-block text-gray mt-2">
                                      Phone: {v.phone}
                                    </span>

                                    <div className="justify-content-between d-flex align-items-center mt-3">
                                      <div className="accept">
                                        <i className="mdi mdi-check"></i>
                                        <a href="#">Accept</a>
                                      </div>

                                      <div className="cancel">
                                        <i className="mdi mdi-close"></i>
                                        <a href="#">Reject</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mt-30">
                    <h6 className="text-left">Members</h6>

                    {coachAcceptList ? (
                      <>
                        {coachAcceptList.length === 0 ? (
                          <>
                            <div className="container d-flex justify-content-center align-items-center h-50">
                              <span className="noti-type">
                                No members to display
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* desktop view */}
                            <div className="table-responsive  d-none d-md-block">
                              <table className="table event-table coach-table">
                                <thead>
                                  <tr>
                                    <th>Coaches</th>
                                    <th>Address</th>
                                    <th>Phone No.</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {coachAcceptList.map((v, i) => {
                                    return (
                                      <tr key={i} className="coach-row">
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <div className="dashboard-img">
                                              <img
                                                src={
                                                  v.coach.profile_image !== ""
                                                    ? v.coach.profile_image
                                                    : "./images/pch-images/pch.svg"
                                                }
                                              />
                                            </div>
                                            <span className="ml-12 filter-string">
                                              {v.coach.name}
                                            </span>
                                          </div>
                                        </td>
                                        <td>{v.coach.address}</td>
                                        <td>{v.coach.mobile_number}</td>
                                        <td>
                                          {v.coach.status === 1
                                            ? "false"
                                            : "true"}
                                        </td>
                                        <td>
                                          <div className="dropdown event-drp">
                                            <i
                                              className="mdi mdi-dots-vertical"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            ></i>
                                            {/* More */}
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuLink"
                                            >
                                              <Link
                                                className="dropdown-item"
                                                to={`/addcoachtoteam/${v._id}`}
                                              >
                                                Add To Team
                                              </Link>
                                              <Link
                                                className="dropdown-item"
                                                to="#"
                                              >
                                                Send Notification
                                              </Link>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="division-tab"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="mt-30">
                    {/* desktop view */}
                    {division ? (
                      <div className="division-div">
                        {division.length === 0 ? (
                          <>
                            <div className="container d-flex justify-content-center align-items-center">
                              <span className="noti-type">
                                No divisions to display
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="d-flex justify-content-end">
                              <Link to={"/division/create-division"}>
                                <button className="bg-[#143D4A] px-4 py-2 text-white rounded-md hover:bg-[#0b232b]">
                                  ADD
                                </button>
                              </Link>
                            </div>
                            {alldivision?.map((v, i) => {
                              return (
                                <div
                                  className="mb-3 division-row col-sm-7 p-0"
                                  key={i}
                                >
                                  <div className="border px-3 py-2 d-flex justify-content-between align-center">
                                    <div>
                                      <div className="ml-12 filter-string text-decoration-none filter-string">
                                        {v.division_name}
                                      </div>
                                    </div>
                                    <div className="dropdown event-drp">
                                      <i
                                        className="mdi mdi-dots-vertical"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      ></i>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuLink"
                                      >
                                        <Link
                                          className="dropdown-item"
                                          to={`/division/${v._id}`}
                                        >
                                          <i className="mdi mdi-border-color"></i>
                                          Edit
                                        </Link>

                                        <div
                                          className="dropdown-item"
                                          onClick={() => {
                                            setIsDivisionDelete(true);
                                            setDeleteId(v._id);
                                          }}
                                        >
                                          <i className="fas fa-trash-alt"></i>{" "}
                                          Delete
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <DeletePopUp
        isOpen={isTeamsDelete}
        isClose={setIsTeamsDelete}
        handleEvent={() => props.deleteClubTeamHandler(deleteId)}
      />
      <DeletePopUp
        isOpen={isDivisionDelete}
        isClose={setIsDivisionDelete}
        handleEvent={() => props.deleteDivisionHandler(deleteId)}
      />
      <PlayerImagePopup
        isOpen={openImageModal}
        isClose={setOpenImageModal}
        selectedImage={selectedProfileImage}
        resetImage={setSelectedProfileImage}
      />
      <Toaster />
    </React.Fragment>
  );
};

// export default TeamsCoches;

const mapStateToProps = (state) => ({
  acceptPlay: state.acceptPlayerReducer,
  accept_rej_Coach: state.acceptCoachReducer,
  TeamReducer: state.deleteEventTeamReducer,
  editTeam: state.editClubTeamReducer,
  getTeam: state.getClubAllTeamReducer,
  playerStatus: state.activePlayerReducer,
  deleteDivisionReducer: state.deleteDivisionReducer,
  editDivision: state.editDivisionReducer,
  createDivisionReducer: state.createDivisionReducer,
  createTeamReducer: state.createTeamReducer,

  // submit_1: state.clonEventReducer,
  // bookmark: state.bookmarkReducer
});

const mapDispatchToProps = (dispatch) => ({
  getTeamHandler: (user) => dispatch(getClubTeam(user)),
  getInvitationHandler: (user) => dispatch(getPlayerInvitation(user)),
  getAccountDetails: (user) => dispatch(getAccountDetails(user)),
  getAllDivisionHandler: (user) => dispatch(getAllDivision_club(user)),
  deleteDivisionHandler: (user) => dispatch(deleteDivision(user)),
  getPlayerListHandler: (user) => dispatch(getPlayerList(user)),
  acceptPlayerHandler: (user) => dispatch(acceptPlayer(user)),
  getCoachInvitationHandler: (user) => dispatch(getCoachInvitationList(user)),
  acceptCoachHandler: (user) => dispatch(acceptCoach(user)),
  getCoachAcceptListHandler: (user) => dispatch(coachAcceptList(user)),
  getPlayerAcceptListHandler: (user) => dispatch(playerAcceptList(user)),
  deleteClubTeamHandler: (user) => dispatch(deleteClubTeam(user)),
  activePlayerHandler: (user) => dispatch(activeDeactivePlayer(user)),
  getSeasonHandler: (user) => dispatch(getAllSeason(user)),
  // resetdeletePaymentCodeReducer: () => dispatch({ type: RESET_DELETE_EVENT }),
  resetAcceptPlayerReducer: (user) => dispatch({ type: RESET_ACCEPT_PLAYER }),
  resetAcceptCoachReducer: (user) => dispatch({ type: RESET_ACCEPT_COACH }),
  resetdeleteEventTeamReducer: () => dispatch({ type: RESET_DELETE_EVENTTEAM }),
  resetEditTeamReducer: () => dispatch({ type: RESET_EDIT_CLUB_TEAM }),
  resetActivePlayerReducer: (user) => dispatch({ type: RESET_ACTIVE_PLAYER }),
  resetEditDivision: (user) => dispatch({ type: RESET_EDIT_DIVISION }),
  resetDeleteDivision: (user) => dispatch({ type: RESET_DELETE_DIVISION }),
  resetCreateDivision: (user) => dispatch({ type: RESET_CREATE_DIVISION }),
  resetCreateTeamReducer: () => dispatch({ type: RESET_CREATE_TEAM }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsCoches);
