import { CREATE_EVENT_DIVISION_ERROR, CREATE_EVENT_DIVISION_LOADING, CREATE_EVENT_DIVISION_SUCCESS, RESET_CREATE_EVENT_DIVISION } from "../../../../constants";

let initialState={
    error:false,
    success:false,
    errors:[],
    user:{},
    loading:false
}

export default function createEventDivisionReducer(state=initialState,action){
    switch (action.type) {
        case CREATE_EVENT_DIVISION_LOADING:
            // alert("reducer Loading")
            return { ...state, loading: true, error: false, success: false };

        case CREATE_EVENT_DIVISION_SUCCESS:
            // alert("reducer success") 
            return { ...state, success: true,error:false,loading:false,message:action.msg};
        case CREATE_EVENT_DIVISION_ERROR:
            // alert("reducer Error")   
            return { ...state, error: true, errors:[],success:false,loading:false};
            
         case RESET_CREATE_EVENT_DIVISION:
             return {...state,error:false,success:false,loading:false,errors:[]}
            
        default:
            return state;
    }
}