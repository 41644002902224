import React, { useState, useEffect } from "react";
import { withRouter, Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { useSelector, connect } from "react-redux";
import { createTeam, getAllDivision } from "../../../service/action/userAction";
import classNames from "classnames";
import { RESET_CREATE_TEAM } from "../../../service/constants";
import Toaster from "../../../Toaster/Toaster";

const EventTeamCreate = (props) => {
  let history = useHistory();
  const { id } = useParams();
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  const allDivision = useSelector(
    (state) => state.getAllDivisionReducer.user.data
  );
  useEffect(() => {
    if (id) {
      props.getAllDivisionHandler({ id });
    }
  }, []);

  const [state, setState] = useState({
    season_name: "",
    team_name: "",
    club_name: "",
    division: "",
    pool: "",
    coach_name: "",
    coach_email: "",
    coach_mobile: "",
  });

  const [pool, setPool] = useState([]);

  const [errors, setErrors] = useState({
    season_name: false,
    team_name: false,
    club_name: false,
    division: false,
    pool: false,
    coach_name: false,
    coach_email: false,
    coach_mobile: false,
  });

  const [image, setImage] = useState("");
  const [pitcher, setPitcher] = useState({
    file: {},
  });

  const onChangePicture = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    if (event.target.files && event.target.files[0]) {
      setPitcher({ file: event.target.files[0] });
    }
  };

  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    if (name == "division") {
      if (state.division !== "") {
        state["pool"] = "";
      }
    }
    switch (name) {
      case "team_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            team_name: "Team name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            team_name: false,
          }));
        }
        break;
      case "club_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            club_name: "Club name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            club_name: false,
          }));
        }
        break;
      case "division":
        if (value !== "") {
          const pool = allDivision.find((e) => e._id == value);
          const poolList = pool.pool;
          setPool(poolList);
        }
        if (value == 0) {
          setErrors((prevState) => ({
            ...prevState,
            division: "Division can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            division: false,
          }));
        }
        break;
      case "pool":
        if (value == 0) {
          setErrors((prevState) => ({
            ...prevState,
            pool: "pool can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            pool: false,
          }));
        }
        break;
      case "coach_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            coach_name: "Coach name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            coach_name: false,
          }));
        }
        break;
      case "coach_email":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            coach_email: "Coach email can not be empty",
          }));
        } else if (!validEmailRegex.test(value)) {
          setErrors((prevState) => ({
            ...prevState,
            coach_email: "Email is not valid !",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            coach_email: false,
          }));
        }
        break;
      case "coach_mobile":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            coach_mobile: "Coach mobile can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            coach_mobile: false,
          }));
        }
        break;
      default:
    }
  };

  const notify = (msg) => toast(msg);

  const submitRequest = (e) => {
    e.preventDefault();
    if (state.team_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        team_name: "Team name can not be empty",
      }));
    }
    if (state.division == "") {
      setErrors((prevState) => ({
        ...prevState,
        division: "Division can not be empty",
      }));
    }
    if (state.pool == "") {
      setErrors((prevState) => ({
        ...prevState,
        pool: "Pool can not be empty",
      }));
    }

    if (state.team_name !== "") {
      props.createTeamhandler({
        event_id: id,
        team_name: state.team_name,
        club_name: state.club_name,
        // coach_name: state.coach_name,
        // coach_email: state.coach_email,
        // coach_mobile: state.coach_mobile,
        division: state.division,
        pool: state.pool,
        image: pitcher.file ? pitcher.file : null,
      });
    }
  };

  useEffect(() => {
    if (props.submit?.success) {
      notify("Team Created");
      // resetData().then(clearState);
      props.resetCreateTeamReducer();
      history.push(`/event-details/${id}`);
    } else if (props.submit?.error) {
      notify("Team Error");
    }
  }, [props.submit]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head-event">
              <h5 className="dash-head">Create Team</h5>
              <button className="btn-done" onClick={submitRequest}>
                Save
              </button>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-10 px-0">
            <div className="mt-30">
              <div className="basic-info border-bottom-0">
                <input
                  type="file"
                  className="input-file file-upload"
                  id="file-upload"
                  onChange={onChangePicture}
                />
                <label className="change-pic" htmlFor="file-upload">
                  <img
                    src={image === "" ? "./images/pch-images/pch.svg" : image}
                    className="img-fluid"
                  />
                </label>
                <span className="ml-12">Upload Picture</span>
              </div>
            </div>
            <strong className="text-left payment mt-30">Team Info</strong>
            <div className="row mx-0">
              <div className="col-md-6 mt-18">
                <input
                  type="text"
                  placeholder="Team Name *"
                  className="form-control team-name"
                  id="team_name"
                  name="team_name"
                  value={state.team_name}
                  required
                  onChange={eventHandler}
                />
                {errors.team_name && (
                  <span className="text-red small d-block">
                    * {errors.team_name}
                  </span>
                )}
              </div>
              {/* <div className="col-md-6 mt-18">
                                <input type="text" className="form-control team-name" placeholder="Club Name *" id="club_name" name="club_name" value={state.club_name} required onChange={eventHandler} />
                                {errors.club_name &&
                                    <span className='text-red small d-block'>* {errors.club_name}</span>}
                            </div> */}
              <div className="col-md-6 mt-18">
                <select
                  className={classNames("form-control team-name payterm", {
                    error: errors.division,
                  })}
                  id="division"
                  name="division"
                  value={state.division}
                  onChange={eventHandler}
                >
                  <option value="" disabled>
                    Division{" "}
                  </option>
                  {allDivision?.map((v, i) => {
                    return (
                      <option key={v?._id} value={v?._id}>
                        {v?.division_name}
                      </option>
                    );
                  })}
                </select>
                {errors.division && (
                  <span className="text-red small d-block">
                    {" "}
                    {errors.division}
                  </span>
                )}
              </div>
              <div className="col-md-6 mt-18">
                <select
                  className={classNames("form-control team-name payterm", {
                    error: errors.pool,
                  })}
                  id="pool"
                  name="pool"
                  disabled={state.division == ""}
                  value={state.pool}
                  onChange={eventHandler}
                >
                  <option value="" disabled>
                    Pool{" "}
                  </option>
                  {pool?.map((v, i) => {
                    return (
                      <option key={v?._id} value={v?._id}>
                        {v?.pool_name}
                      </option>
                    );
                  })}
                </select>
                {errors.pool && (
                  <span className="text-red small d-block"> {errors.pool}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </React.Fragment>
  );
};

// export default withRouter(EventTeamCreate);
const mapStateToProp = (state) => ({
  submit: state.createTeamReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createTeamhandler: (user) => dispatch(createTeam(user)),
  resetCreateTeamReducer: () => dispatch({ type: RESET_CREATE_TEAM }),
  getAllDivisionHandler: (user) => dispatch(getAllDivision(user)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(EventTeamCreate));
// 516
