import { EVENT_DETAILS } from '../constants'

let initialState = {
    success: false,
    user: {
        id: "", 
        event_name:''
    },
    payload:{},
    data:{},
}

export default function sendEventDetailsReducer(state = initialState, action) {

    switch (action.type) {

        case EVENT_DETAILS:            
            // alert("reducer success")
            return { ...state, success: true, user: action.payload,data:action.data,id:action.id ,edituser:action.edituser};
        // break;

        default:
            return state;
    }

}    