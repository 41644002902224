import { CREATE_LEAGUE_ERROR, CREATE_LEAGUE_LOADING, CREATE_LEAGUE_SUCCESS, RESET_LEAGUE } from "../../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function createLeagueReducer(state = initialState, action) {
  switch (action.type) {

    case CREATE_LEAGUE_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case CREATE_LEAGUE_SUCCESS:
      return { ...state, error: false, loading: false, success: true,message:action.msg };

    case CREATE_LEAGUE_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: action.errors,msg:action.errors }

    case RESET_LEAGUE:
        return {...state,error:false,loading:false,success:false,errors:[],user:[{}]}  

    default:
      return state;
  }
}

