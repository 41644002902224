import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllUserRoll } from "../service/action/userAction";
import {FaUserTie} from 'react-icons/fa'
import {BiSolidTShirt, BiSolidCalendar} from 'react-icons/bi'
import {MdFamilyRestroom, MdSportsBaseball} from 'react-icons/md'
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import BreadcrumbHistory from "../BreadcumbHistory/BreadcrumbHistory";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {IoIosPeople} from 'react-icons/io'
import AccountType from "./components/AccountType";
  const AddAccount = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const notify = (msg) => toast(msg);
  const userRoll = useSelector((state) => state.getUserRollReducer.user);

  // Getting All User Roll When Component Mount
  useEffect(() => {
    dispatch(getAllUserRoll());
  }, []);

  const handleClick = (type) => {
    history.push(`/add-account/${type}`);
  };

  return (
    <div className="main-panel">
      <BreadcrumbHistory
        url={location.pathname !== "/" ? location.pathname : ""}
      />
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex auth my-4">
          <div className="row flex-grow">
            <div className="col-lg-5 mx-auto mt-5">
              <div className="auth-form-light text-left p-5">
                <h6 className="text-white text-center text-2xl font-bold">
                  Add New Account
                </h6>
                <p className="text-left mt-5 text-[#989898]">
                  You can Create Single Account for Staff, Coach, Parent, Player
                  And Multiple Accounts for Club & Organizer.
                </p>
                <form className="pt-3">
                  {userRoll.is_staff == 0 && (
                    <AccountType
                      mainText="I'm Event Staff"
                      subText={`I'm Registering to work an event,(Working an event? Create your account here)`}
                      accountType={2}
                      handleClick={handleClick}
                      icon={<IoIosPeople className="text-3xl" />}
                    
                    />
                  )}
                  {userRoll.is_coach == 0 && (
                    <AccountType
                      mainText="I'm a Coach"
                      subText={`I'm Connecting with my team and Players`}
                      accountType={3}
                      handleClick={handleClick}
                      icon={<FaUserTie className="text-3xl" />}
                    />
                  )}
                  {userRoll.is_player_parent == 0 && (
                    <AccountType
                      mainText="I'm a Player"
                      subText={` I'm over the age of 18 creating my own profile. (Also a parent? You can use one account as a parent and player)`}
                      accountType={5}
                      handleClick={handleClick}
                      icon={<BiSolidTShirt className="text-3xl" />}
                    />
                  )}
                  {userRoll.is_parent == 0 && (
                    <AccountType
                      mainText={`I'm a Parent/Guardian`}
                      subText={`Im' a registering kids under the age of 18 (You'll create your kids profiles w/in your account)`}
                      accountType={6}
                      handleClick={handleClick}
                      icon={<MdFamilyRestroom className="text-3xl" />}
                    />
                  )}
                  <AccountType
                    mainText={`I'm a Club Director`}
                    subText={`I'm creating an account for our club (This can also be
                      done within your exsiting account.)`}
                    accountType={4}
                    handleClick={handleClick}
                    icon={<MdSportsBaseball className="text-3xl" />}
                  />

                  <AccountType
                    mainText={`I'm a Event Organizer`}
                    subText={`I'm createing an account for my Organization`}
                    accountType={1}
                    handleClick={handleClick}
                    icon={<BiSolidCalendar className="text-3xl" />}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAccount;
