import { REMOVE_TEAM_PLAYER_ERROR, REMOVE_TEAM_PLAYER_LOADING, REMOVE_TEAM_PLAYER_SUCCESS, RESET_REMOVE_TEAM_PLAYER } from "../../../constants";

let initialState = {
  error: false,
  success: false,
  loading: false,
  errors: [],
  data: []
}

export default function removeTeamPlayerReducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_TEAM_PLAYER_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, error: false, loading: true };

    case REMOVE_TEAM_PLAYER_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };

    case REMOVE_TEAM_PLAYER_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors, success: false };

    case RESET_REMOVE_TEAM_PLAYER:
      // alert(" reducer Loading")
      return { ...state, error: false, loading: false, success: false };

    default:
      return state;
  }
}