import { RESET_SEND_IMAGE, SEND_IMAGE_ERROR, SEND_IMAGE_LOADING, SEND_IMAGE_SUCCESS } from "../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function sendImageReducer(state = initialState, action) {
  switch (action.type) {

    case SEND_IMAGE_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case SEND_IMAGE_SUCCESS:
      return { ...state, error: false, loading: false, success: true,message:action.msg };

    case SEND_IMAGE_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: action.errors,msg:action.errors }

    case RESET_SEND_IMAGE:
        return {...state,error:false,loading:false,success:false,errors:[],user:[{}]}  

   
    default:
      return state;
  }
}

