import {
  CREATE_MERCHAT_ACCOUNT_ERROR,
  CREATE_MERCHAT_ACCOUNT_SUCCESS,
  RESET_CREATE_MERCHAT_ACCOUNT,
} from "../../constants";

let initialState = {
  createMerchant: {
    success: false,
    error: false,
    data: null,
    errorMsg: false,
  },
};

export default function MerchantReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_MERCHAT_ACCOUNT_SUCCESS:
      return {
        ...state,
        createMerchant: {
          ...state.createMerchant,
          success: true,
          data: action.user,
        },
      };

    case CREATE_MERCHAT_ACCOUNT_ERROR:
      return {
        ...state,
        createMerchant: {
          ...state.createMerchant,
          error: true,
          errorMsg: action.errors,
        },
      };

    case RESET_CREATE_MERCHAT_ACCOUNT:
      return {
        ...state,
        createMerchant: {
          ...state.createMerchant,
          error: false,
          errorMsg: false,
          success: false,
        },
      };

    default:
      return state;
  }
}
