import React, { useEffect, useState } from 'react'
import { useSelector, connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { addRoaster, getPlayerList, InvitePlayerClub, playerAcceptList } from '../../../service/action/Club/clubAction'

import { toast } from 'react-toastify';
import Toaster from '../../../Toaster/Toaster';
import { RESET_CREATE_TEAM } from '../../../service/constants';
import { useParams } from 'react-router';
import { getTeamDetails } from '../../../service/action/Club/clubAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Spinner from '../../../Spinner/Spinner';


const AddRoaster = (props) => {

    const history = useHistory()

    const { id, id1 } = useParams()
    const [selectedPlayer, setSelectedPlayer] = useState([])

    const [loading, setLoading] = useState(true)

    const getPlayerAcceptList = props.getPlayerAcceptListHandler

    useEffect(() => {
        getPlayerAcceptList()
        props.getTeamDetails({ id: id })
    }, [])

    useEffect(() => {
        if (props.getTeamDetailsReducer.success && props.playerAcceptListReducer.success) {
            setLoading(false)
        }
    }, [props.playerAcceptListReducer, props.getTeamDetailsReducer])

    // useEffect(() => {
    //     setLoading(props.getTeamDetailsReducer)
    // }, [props.getTeamDetailsReducer])

    const notify = (msg) => toast(msg);

    const [playerMember, setPlayerMember] = useState(null)

    const playerAcceptList = useSelector(state => state.playerAcceptListReducer.user.data)
    const teamDetail = useSelector(state => state.getTeamDetailsReducer.user.data)

    useEffect(() => {
        if (playerAcceptList && teamDetail) {
            const list = playerAcceptList.filter(e => e.club_player !== false)
            const teamPlayerIds = teamDetail.player.map(e => e._id)
            const rosterPlayerIds = teamDetail.roster_player.find(e => e._id === id1).player.map(e => e.player)
            setPlayerMember(list.filter(e => teamPlayerIds.includes(e._id) && !rosterPlayerIds.includes(e._id)))
        }
    }, [playerAcceptList, teamDetail])

    // const getPlayers = useSelector(state => state.getAllSeasonReducer.user)
    // const InvitedMsg = useSelector(state => state.createTeamReducer.message)

    const invite = () => {
        // alert("click")
        if (selectedPlayer.length !== 0) {
            props.invitePlayerHandler({
                player: selectedPlayer.map(v => v._id),
                roster: id1
            })
        } else {
            notify("Please Select Player")
        }

    }

    useEffect(() => {
        if (props.submit?.success) {
            props.getTeamDetails({ id: id })
            history.push(`/team-roster/${id}/${id1}`);
        }

    }, [props.submit])

    const handlePlayer = (e) => {
        const { value, checked } = e.target

        if (checked) {
            const player = playerMember.find(v => v._id === value)
            setSelectedPlayer([...selectedPlayer, player])
        }
        else {
            const playerIndex = selectedPlayer.findIndex(v => v._id === value)
            selectedPlayer.splice(playerIndex, 1)
            setSelectedPlayer([...selectedPlayer])
        }
    }

    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    {
                        loading ? <Spinner /> :
                            <>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="dash-head">Add Rosters</h5>
                                    <div>
                                        <div>
                                            <button className="btn-save" onClick={invite}>Add</button>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (playerMember && playerMember.length !== 0) ? <>
                                        <div className="table-responsive">
                                            <table className="table event-table mt-30">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        playerMember.map((v, i) => {
                                                            return <tr key={i}>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="dashboard-img">
                                                                            <img src={v.profile_image !== "" ? v.profile_image : "./images/pch-images/pch.svg"} />
                                                                        </div>
                                                                        <span className="ml-12">{v.first_name} {v?.last_name}</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <input type="checkbox" name={v.first_name} value={v._id} onChange={(e) => handlePlayer(e)} />
                                                                </td>
                                                            </tr>
                                                        })
                                                    }


                                                </tbody>
                                            </table>
                                            {
                                                !playerMember && <>Loading....</>
                                            }
                                        </div>
                                    </> : <div className='container d-flex justify-content-center align-items-center'>
                                        <span className='noti-type'>No roster players to display  </span>
                                    </div>
                                }
                            </>
                    }

                </div>
            </div>
            <Toaster />
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    submit: state.createTeamReducer,
    playerAcceptListReducer: state.playerAcceptListReducer,
    getTeamDetailsReducer: state.getTeamDetailsReducer,

})

const mapDispatchToProps = dispatch => ({
    // getAllClubHandler: user => dispatch(getAllClub(user)),
    getTeamDetails: user => dispatch(getTeamDetails(user)),
    invitePlayerHandler: user => dispatch(addRoaster(user)),
    // getPlayerListHandler: user => dispatch(getPlayerList(user)),
    resetcreateTeamReducer: () => dispatch({ type: RESET_CREATE_TEAM }),
    // userDataHandler: user => dispatch(userData(user)),
    getPlayerAcceptListHandler: user => dispatch(playerAcceptList(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddRoaster)