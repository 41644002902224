import React, { useState, useEffect } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
// import Toaster from '../../../Toaster/Toaster';
import { withRouter, Redirect, useParams, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { createSeason, editSeason, getAllSeason } from '../../service/action/userAction';
import { RESET_EDIT_DIVISION, RESET_EDIT_SEASON } from '../../service/constants';
// import { RESET_CREATE_SEASON } from '../../service/constants';
import classNames from 'classnames';
import axios from 'axios';
import Toaster from '../../Toaster/Toaster';
import { getAllDivision_club, editDivision } from '../../service/action/Club/clubAction';
import BreadcrumbHistory from '../../BreadcumbHistory/BreadcrumbHistory';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';




const EditDivision = (props) => {

    const { id } = useParams();
    const history = useHistory()
    const location = useLocation()
    //all season
    const getDivision = props.getDivisionHandler;
    useEffect(() => {
        getDivision()
    }, [])

    const allDivision = useSelector(state => state.getAllClubDivision.user)





    const [state, setState] = useState({
        division_name: ""
    })

    const [errors, setErrors] = useState({

        division_name: false

    })

    useEffect(() => {
        if (allDivision) {
            const editDivision = allDivision.find((e) => e._id == id)
            if (editDivision) {

                setState(prevState => ({
                    ...prevState,
                    division_name: editDivision.division_name
                }))
            }
        }
    }, [allDivision])

    const eventHandler = (e) => {
        const { id, value, name } = e.target


        setState(prevState => ({
            ...prevState,
            [id]: value
            // [e.target.name]: e.target.value.trim()
        }))
        switch (name) {
            case "division_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        division_name: "Division name can not be empty"
                    }))

                    document.getElementById("division_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        division_name: false
                    }))

                    document.getElementById("division_name").classList.remove("error")
                }

                break;

            default:
        }
    }

    const notify = (msg) => toast(msg);

    useEffect(() => {
        if (editSeason) {
            setState(prevState => ({
                ...prevState,
                division_name: editSeason.division_name
            }))
        }

    }, [editSeason])

    const submitRequest = (e) => {
        e.preventDefault();
        if (state.division_name == "") {
            setErrors(prevState => ({
                ...prevState,
                division_name: "Division name can not be empty"
            }))
            document.getElementById("division_name").classList.add("error")
        }

        if (state.division_name !== "") {
            props.editDivisionhandler({
                division_name: state.division_name,
                id: id
            })

            // const datas = { division_name: state.division_name }
            // const headers = {
            //     "Content-Type": "application/json",
            //     'Authorization': `Bearer ${localStorage.getItem("token")}`
            // }

            // if (acctype == 1) {

            //     const requestOptions = {
            //         method: "PUT",
            //         headers: {
            //             'content-type': 'application/json',
            //             'Authorization': `Bearer ${localStorage.getItem("token")}`
            //         },
            //         url: `http://192.168.0.33:5555/api/organizer/event-season/${id}`, data: datas
            //     };
            //     axios(requestOptions)

            //     return <Redirect to="/switch" />
            // } else {
            //     const requestOptions = {
            //         method: "PUT",
            //         headers: {
            //             'content-type': 'application/json',
            //             'Authorization': `Bearer ${localStorage.getItem("token")}`
            //         },
            //         url: `http://192.168.0.33:5555/api/club/event-season/${id}`, data: datas
            //     };

            // }
            //  axios.put(acctype==1?`http://192.168.0.33:5555/api/organizer/event-season/${id}`:`http://192.168.0.33:5555/api/club/event-season/${id}`,datas, headers)


        }

    }

    useEffect(() => {

        if (props.submit?.success) {

            props.resetEditDivision()
            history.goBack()

        }
    }, [props.submit])



    return (
        <>
            <div className="main-panel">
                <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />

                <div className="content-wrapper">
                    <div className="text-left">
                        <h5 className="dash-head">Edit Division</h5>
                    </div>

                    <div className="row">
                        <div className="col-md-4 col-xl-4">
                            <div className="mt-24">
                                <div className="text-left">
                                    <label className="text-gray fs-13">Division Name <span className="text-red fw-m">*</span></label>
                                    <input type="text" className="form-control team-name" id="division_name" name="division_name" value={state.division_name} placeholder="Division Name *" required onChange={eventHandler} />

                                    {errors.division_name &&
                                        <span className='text-red small d-block'>* {errors.division_name}</span>}

                                </div>

                                <div className="mt-3 text-left">
                                    <a className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitRequest} >Submit</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Toaster />
        </>
    )
}

// export default withRouter(CreateInvoice)



const mapStateToProp = state => ({
    submit: state.editDivisionReducer,

})

const mapDispatchToProps = dispatch => ({
    getDivisionHandler: (user) => dispatch(getAllDivision_club(user)),

    resetEditDivision: () => dispatch({ type: RESET_EDIT_DIVISION }),
    // getSeasonHandler: user => dispatch(getAllSeason(user)),
    editDivisionhandler: (user) => dispatch(editDivision(user))



})
export default connect(mapStateToProp, mapDispatchToProps)(EditDivision);