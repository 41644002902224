import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPlayerDetails } from "../../../service/action/Club/clubAction";
import { getAllSport } from "../../../service/action/userAction";
import { RESET_PLAYER_DETAILS } from "../../../service/constants";
import Spinner from "../../../Spinner/Spinner";

function PlayerDetails(props) {
  const { id } = useParams();

  const [contactSectionVisble, setContactSectionVisible] = useState(false);
  const [bioSectionVisble, setBioSectionVisible] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [playerSport, setPlayerSport] = useState([]);

  const playerDetails = useSelector(
    (state) => state.playerDetailsReducer.data.data
  )?.player;
  const sportList = useSelector((state) => state.allSportReducer).SPORTs;
  // const primaryContact = playerDetails ? playerDetails.primary_contact : []

  useEffect(() => {
    setLoading(props.playerReducer.loading);
    if (props.playerReducer.success) {
      props.resetDetail();
    }
  }, [props.playerReducer]);

  useEffect(() => {
    if (sportList && playerDetails) {
      const list = [];
      playerDetails?.player_bio?.sport_information?.sport?.map((v) => {
        sportList?.map((s) => {
          if (v === s._id) {
            list.push(s.sport);
          }
        });
      });
      setPlayerSport([...list]);
    }
  }, [sportList]);

  useEffect(() => {
    props.playerDetails({ id: id });
    props.getAllSports();
  }, []);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        {!loading && playerDetails ? (
          <div className="mb-5">
            <div
              className="d-flex align-items-center justify-content-center w-100"
              style={{ height: "150px" }}
            >
              <img
                src={
                  playerDetails?.profile_image !== ""
                    ? playerDetails?.profile_image
                    : "./images/pch-images/pch.svg"
                }
                className="h-100"
                style={{ borderRadius: "50%" }}
              />
            </div>
            <div className="mt-3">
              <h4 className="text-center mb-0">{`${playerDetails.first_name} ${playerDetails.last_name}`}</h4>
              <div className="text-center text-gray mt-2">
                DOB: {moment(playerDetails.dob).format("MM/DD/YYYY")}
              </div>
              <div className="text-center text-gray mt-2">
                Email: {playerDetails.email}
              </div>
            </div>

            {/* <hr />

     <div className='h4 mt-2' style={{ color: '#899ea4' }}>Jursey No. & positions</div>
     <div className='mx-3'>
      <div className='text-gray mt-2 d-flex justify-content-between'>
       <div className='d-flex justify-content-between'>
        <div>name : </div>
        <div>value</div>
       </div>
       <div>Edit</div>
      </div>
     </div> */}

            <hr />

            <div style={{ borderBottom: "1px solid #899ea4" }} className="pb-2">
              <div className="d-flex justify-content-between mt-4 align-items-center">
                <h4 className="text-left mb-0">
                  Emergency Parent/Guardian Contact
                </h4>
                <div
                  onClick={() => {
                    setContactSectionVisible(!contactSectionVisble);
                    // document.getElementById("contact").style.display = contactSectionVisble ? "none" : "block";
                  }}
                >
                  <a className="d-block text-info text-decoration-none ml-12 pointer fs-18">
                    {contactSectionVisble ? (
                      <i className="fas fa-angle-down"></i>
                    ) : (
                      <i className="fas fa-angle-up"></i>
                    )}
                  </a>
                </div>
              </div>
              {/* ---------- */}
              {contactSectionVisble && (
                <>
                  {playerDetails.primary_contact.length === 0 ? (
                    <div id="contact">No Contact to display</div>
                  ) : (
                    <>
                      {playerDetails.primary_contact.map((v, i) => {
                        return (
                          <div key={i}>
                            <div className="mt-2 p-2" id="contact">
                              <div className="d-flex align-items-center mb-2 py-2">
                                <p className="text-left text-info mb-0  fs-14">
                                  First Name :{" "}
                                </p>
                                <h6 className="text-left ml-1 text-gray m-0">
                                  {v.first_name !== "" ? v.first_name : "N/A"}
                                </h6>
                              </div>
                              <hr className="m-0" />
                              <div className="d-flex align-items-center mb-2 py-2">
                                <p className="text-left text-info mb-0  fs-14">
                                  Last Name :{" "}
                                </p>
                                <h6 className="text-left ml-1 text-gray m-0">
                                  {v.last_name !== "" ? v.last_name : "N/A"}
                                </h6>
                              </div>
                              <hr className="m-0" />
                              <div className="d-flex align-items-center mb-2 py-2">
                                <p className="text-left text-info mb-0  fs-14">
                                  Relationship :{" "}
                                </p>
                                <h6 className="text-left ml-1 text-gray m-0">
                                  {v.relationship_player !== ""
                                    ? v.relationship_player
                                    : "N/A"}
                                </h6>
                              </div>
                              <hr className="m-0" />
                              <div className="d-flex align-items-center mb-2 py-2">
                                <p className="text-left text-info mb-0  fs-14">
                                  Mobile No. :{" "}
                                </p>
                                <h6 className="text-left ml-1 text-gray m-0">
                                  {v.mobile_no !== "" ? v.mobile_no : "N/A"}
                                </h6>
                              </div>
                              <hr className="m-0" />
                              <div className="d-flex align-items-center mb-2 py-2">
                                <p className="text-left text-info mb-0  fs-14">
                                  Email :{" "}
                                </p>
                                <h6 className="text-left ml-1 text-gray m-0">
                                  {v.email !== "" ? v.email : "N/A"}
                                </h6>
                              </div>
                            </div>
                            {i !== playerDetails.primary_contact.length - 1 && (
                              <div className="border-bottom" />
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </div>

            <div style={{ borderBottom: "1px solid #899ea4" }} className="pb-2">
              <div className="d-flex justify-content-between mt-4 align-items-center">
                <h4 className="text-left mb-0">Shipping Address</h4>
                <div
                  onClick={() => {
                    setShippingAddress(!shippingAddress);
                    // document.getElementById("address").style.display = shippingAddress ? "none" : "block";
                  }}
                >
                  <a className="d-block text-info text-decoration-none ml-12 pointer fs-18">
                    {shippingAddress ? (
                      <i className="fas fa-angle-down"></i>
                    ) : (
                      <i className="fas fa-angle-up"></i>
                    )}
                  </a>
                </div>
              </div>
              {/* ---------- */}
              {shippingAddress && (
                <div className="mt-2 p-2" id="address">
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">Address: </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {!!playerDetails.home_address?.address &&
                      playerDetails.home_address?.address !== ""
                        ? playerDetails.home_address?.address
                        : "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">State : </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {!!playerDetails.home_address?.state &&
                      playerDetails.home_address?.state !== ""
                        ? playerDetails.home_address?.state
                        : "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">City : </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {!!playerDetails.home_address?.city &&
                      playerDetails.home_address?.city !== ""
                        ? playerDetails.home_address?.city
                        : "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Zip Code :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {!!playerDetails.home_address?.zip_code &&
                      playerDetails.home_address?.zip_code !== ""
                        ? playerDetails.home_address?.zip_code
                        : "N/A"}
                    </h6>
                  </div>
                </div>
              )}
            </div>

            <div style={{ borderBottom: "1px solid #899ea4" }} className="pb-2">
              <div className="d-flex justify-content-between mt-4 align-items-center">
                <h4 className="text-left mb-0">Player bio</h4>
                <div
                  onClick={() => {
                    setBioSectionVisible(!bioSectionVisble);
                  }}
                >
                  <a className="d-block text-info text-decoration-none ml-12 pointer fs-18">
                    {bioSectionVisble ? (
                      <i className="fas fa-angle-down"></i>
                    ) : (
                      <i className="fas fa-angle-up"></i>
                    )}
                  </a>
                </div>
              </div>
              {/* ---------- */}
              {bioSectionVisble && (
                <div className="mt-2 p-2" id="bio">
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      School level:{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.school_information
                        .school_level ?? "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Grad. Month :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.school_information
                        .graduation_month ?? "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Grad. Year :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.school_information
                        .graduation_year ?? "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Current school :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.school_information
                        .current_school ?? "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">Height : </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.school_information.height ??
                        "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">Weight : </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.school_information.weight ??
                        "N/A"}
                    </h6>
                  </div>

                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">Sport : </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerSport.length !== 0
                        ? playerSport.join(", ")
                        : "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      US Lacrosse no. :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.sport_information
                        .us_lacrosse_number ?? "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Position1 :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.sport_information.position1 ??
                        "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Position2 :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.sport_information.position2 ??
                        "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Shirt Size :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.attire_info.shirt_size ??
                        "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Short Size :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.attire_info.short_size ??
                        "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Uniform Size :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.attire_info.uniform_size ??
                        "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Glove Size :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.attire_info.glove_size ??
                        "N/A"}
                    </h6>
                  </div>
                  <hr className="m-0" />
                  <div className="d-flex align-items-center mb-2 py-2">
                    <p className="text-left text-info mb-0  fs-14">
                      Helmet Size :{" "}
                    </p>
                    <h6 className="text-left ml-1 text-gray m-0">
                      {playerDetails.player_bio?.attire_info.helmet_size ??
                        "N/A"}
                    </h6>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="w-full h-full flex flex-col justify-center items-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  playerReducer: state.playerDetailsReducer,
  sportReducer: state.allSportReducer,
});

const mapDispatchToProps = (dispatch) => ({
  playerDetails: (user) => dispatch(getPlayerDetails(user)),
  getAllSports: () => dispatch(getAllSport()),
  resetDetail: () => dispatch({ type: RESET_PLAYER_DETAILS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerDetails);
