import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";
import {
  Coach_Invite,
  getCoachForInvitation,
} from "../../service/action/Club/clubAction";
import { RESET_SEND_COACH_INVITATION } from "../../service/constants";
import $ from "jquery";
import Spinner from "../../Spinner/Spinner";
import BreadcrumbHistory from "../../BreadcumbHistory/BreadcrumbHistory";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import classes from "./add-Coach.module.css";

const AddCoach = (props) => {
  const notify = (msg) => toast(msg);
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  // submit handellar

  const submitHandelar = (e) => {
    e.preventDefault();
  };

  const getdata = props.getAllCoachHandler;
  // const {id}=useParams()

  useEffect(() => {
    getdata();
  }, []);

  const allcoach = useSelector(
    (state) => state.getPlayerInvitationReducer.user.data
  );
  const msg = useSelector(
    (state) => state.sendInvitationToCoachReducer.message
  );

  const invite = (_id) => {
    props.invitecoach({
      coach: _id,
    });
  };

  useEffect(() => {
    if (props.submit?.success) {
      notify(msg.message);
      props.resetcreateTeamReducer();

      // history.push(`/event-details/${id}`);
    } else if (props.submit?.error) {
      notify("Invite Staff fail ");
    }
  }, [props.submit]);

  const staff = [
    {
      name: "username",
      email: "xyz@gmail.com",
    },
    {
      name: "username",
      email: "xyz@gmail.com",
    },
    {
      name: "username",
      email: "xyz@gmail.com",
    },
    {
      name: "username",
      email: "xyz@gmail.com",
    },
  ];

  const [state, setState] = useState({
    search: "",
  });

  const Searchval = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    UpdateArray(e.target.value);
  };

  const UpdateArray = (search) => {
    var query = search.toLowerCase();

    $(".filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".pay-row").addClass("d-none");
      } else {
        $(v).parents(".pay-row").removeClass("d-none");
      }
    });
  };

  useEffect(() => {
    if (props.coach?.success) {
      setLoading(false);
    }
  }, [props.coach]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>

          {!loading && (
            <>
              <div className="create-team-head">
                <h5 className="dash-head mr-3 mb-3">Add Coach</h5>

                <div className="w-md-75 w-100 p-2 border">
                  <form className="d-flex justify-content-between align-items-center">
                    <div className="flex-grow-1 d-flex align-items-center">
                      <i className="mdi mdi-email mx-2"></i>
                      <input
                        type="email"
                        className="border-0 p-1 flex-grow-1 w-100"
                        placeholder="Please enter valid email"
                        value={state.search}
                        onChange={Searchval}
                        autoComplete="off"
                        id="search"
                      />
                    </div>

                    <div className="mx-2">
                      <button
                        type="submit"
                        className={classes.btnSubmit}
                        onClick={(e) => submitHandelar(e)}
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <button className={classes.inviteLink}>Copy invite link</button>
              <div className="mt-30">
                {allcoach ? (
                  <>
                    {allcoach.length == 0 ? (
                      <>
                        <div className="container d-flex justify-content-center align-items-center">
                          <span className="noti-type">
                            No payment coaches to display
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* desktop view */}
                        <div className="table-responsive d-none d-md-block">
                          <table className="table event-table">
                            <thead>
                              <tr>
                                <th>Coach Name</th>
                                <th>Email</th>
                                <th>Invite</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allcoach.length == 0 ? (
                                <>
                                  <span className="noti-type"></span>
                                </>
                              ) : (
                                <>
                                  {allcoach.map((v, i) => {
                                    return (
                                      <tr key={i} className="pay-row">
                                        <td>
                                          <div className="d-flex align-items-center">
                                            {" "}
                                            <div className="dashboard-img">
                                              <img src="./images/pch-images/pch.svg" />
                                            </div>
                                            <span className="ml-12 filter-string">
                                              {v?.name?.trim() !== ""
                                                ? v.name
                                                : "N/A"}
                                            </span>
                                          </div>
                                        </td>
                                        {/* <td>""</td> */}
                                        <td>
                                          {v.email !== "" ? v.email : "N/A"}
                                        </td>
                                        <td>
                                          <Link
                                            to="#"
                                            className="text-decoration-none"
                                            onClick={() => invite(v._id)}
                                          >
                                            <i className="mdi mdi-plus text-blue mr-2 website-ico"></i>
                                            <span className="text-blue">
                                              Invite
                                            </span>
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>

                        {/* mobile view */}
                        <div className="mt-3 d-md-none">
                          {allcoach.map((v, i) => {
                            return (
                              <div
                                className="d-flex justify-content-between reg-details"
                                key={i}
                              >
                                <div className="d-flex w-100">
                                  <div className="dashboard-img mr-3">
                                    <img src="./images/pch-images/pch.svg" />
                                  </div>
                                  <div className="reg-content w-100">
                                    <div className="d-flex justify-content-between">
                                      <Link
                                        className="text-decoration-none text-blue fw-m"
                                        to="/event-details"
                                      >
                                        {v.name}
                                      </Link>

                                      <Link
                                        className="text-decoration-none text-blue fs-13 fw-m"
                                        to="/event-details"
                                      >
                                        <i className="mdi mdi-plus text-blue mr-1 website-ico"></i>
                                        Invite
                                      </Link>
                                    </div>
                                    {/* <span className="d-block text-gray mt-2">Email: {v.email}</span> */}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <h4></h4>
                  </>
                )}
              </div>
            </>
          )}
          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(AddStaff);

const mapStateToProps = (state) => ({
  submit: state.sendInvitationToCoachReducer,
  coach: state.getPlayerInvitationReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCoachHandler: (user) => dispatch(getCoachForInvitation(user)),
  invitecoach: (user) => dispatch(Coach_Invite(user)),
  resetcreateTeamReducer: () => dispatch({ type: RESET_SEND_COACH_INVITATION }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddCoach));
