import { GET_EVENT_BY_ID_LOADING, GET_ALLEVENT_ERROR, EVENT_DETAIL } from "../../constants"

let initialState = {
  loading: false,
  events: [],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function getEventDateFilterReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EVENT_BY_ID_LOADING:
      // alert(" reducer Loading")
      return { ...state, success: false};
      
      case EVENT_DETAIL:
      // alert("reducer succesfull")
      return { ...state, loading: false, success: true, events: action.payload };
      
      case GET_ALLEVENT_ERROR:
      // alert("reducer Error")
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}
