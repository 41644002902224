import React, { useEffect, useState } from "react";
import { useSelector, connect } from "react-redux";
import {
  getPlayerList,
  InvitePlayerClub,
} from "../../../service/action/Club/clubAction";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import {
  RESET_CREATE_TEAM,
  RESET_SEND_INVITATION_EMAIL,
} from "../../../service/constants";
import { useParams } from "react-router";
import {
  findUser,
  sendInvitationBymail,
} from "../../../service/action/userAction";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const InvitePlayer = (props) => {
  const { id, id1 } = useParams();
  const location = useLocation();

  const getPlayerList = props.getPlayerListHandler;
  useEffect(() => {
    getPlayerList();
  }, []);
  const notify = (msg) => toast(msg);

  useEffect(() => {
    if (props.player?.success) {
      setLoading(false);
    }
  }, [props.player]);

  const [loading, setLoading] = useState(true);

  const [parentid, setParentid] = useState(null);

  const getPlayers = useSelector((state) => state.getAllSeasonReducer.user);
  const InvitedMsg = useSelector((state) => state.createTeamReducer.message);

  const sendByMailMsg = useSelector(
    (state) => state.sendInvitationByMailReducer.message
  );

  // useEffect(()=>{
  //     if(getPlayers.parent_id !==null){
  //         setParentid(getPlayers.parent_id._id)
  //     }
  // },[getPlayers])

  const invite = ({ _id, have_parent, parent_id }) => {
    // alert("click")

    if (id !== undefined && id1 == undefined) {
      props.invitePlayerHandler({
        player: _id,
        event: id,
        have_parent: have_parent,
        parent_id: parent_id,
        event_invite: true,
      });
    }

    if (id !== undefined && id1 !== undefined) {
      props.invitePlayerHandler({
        player: _id,
        have_parent: have_parent,
        parent_id: parent_id,
        team_invite: true,
        team: id,
      });
    }

    if (id == undefined && id1 == undefined) {
      props.invitePlayerHandler({
        player: _id,
        have_parent: have_parent,
        parent_id: parent_id,
      });
    }
  };

  useEffect(() => {
    if (props.submit?.success) {
      notify(InvitedMsg.message);
      props.resetcreateTeamReducer();
    }
  }, [props.submit]);

  const [email, setEmail] = useState("");

  const [error, setError] = useState(false);
  const [emailsList, setEmailsList] = useState([]);

  useEffect(() => {
    if (props.sendByMail?.success) {
      notify("Invitation send successfully");
      props.resetInvitationByMailReducer();
      setTimeout(() => {
        setEmail("");
      }, 1000);
      setError(false);
    }
  }, [props.sendByMail]);

  const eventHandler = (e) => {
    setEmail(e.target.value);
  };

  const addEmails = () => {
    if (email === "") {
      notify("Please Check Email");
    } else {
      const validEmailRegex = RegExp(
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      );
      const list = email.split(/,|, /);
      const isValid = list.map((v) => {
        if (!validEmailRegex.test(v.trim())) {
          setError("Email is not valid !");
          return false;
        } else if (list.length === 0 || v.trim().length === 0) {
          setError("Email can not be empty");
          return false;
        } else {
          setError(false);
          return true;
        }
      });
      if (isValid.every((v) => v === true)) {
        setEmailsList([
          ...new Set([...emailsList, ...list.map((e) => e.trim())]),
        ]);
        setEmail("");
      }
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (emailsList.length === 0) {
      notify("Please Check Email");
    }
    if (emailsList.length !== 0 && error === false) {
      props.sendInvitationBymailHandler({
        type: 7,
        invite_type: 0,
        email: [...emailsList],
      });
    }
  };
  const handleRemoveEmail = (index) => {
    const list = [...emailsList];
    list.splice(index, 1);
    setEmailsList([...list]);
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Invite Player</h5>
          </div>
          <div className="w-md-75 w-100 p-2 border">
            <div className="d-flex justify-content-between align-items-center">
              <div className="flex-grow-1 d-flex align-items-center">
                <i className="mdi mdi-email mx-2"></i>
                <input
                  type="email"
                  id="email"
                  className="border-0 p-1 flex-grow-1 w-100"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => eventHandler(e)}
                />
              </div>
              <div className="mx-2">
                <span
                  className="send-btn pointer text-info"
                  onClick={sendEmail}
                >
                  Send
                </span>
              </div>
            </div>
          </div>
          {error && <span className="text-red small d-block">Enter mail</span>}

          <div className="mt-3 fw-m text-red pointer" onClick={addEmails}>
            Add Email
          </div>

          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  submit: state.createTeamReducer,
  player: state.getClubPlayer,
  sendByMail: state.sendInvitationByMailReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // getAllClubHandler: user => dispatch(getAllClub(user)),
  invitePlayerHandler: (user) => dispatch(InvitePlayerClub(user)),
  getPlayerListHandler: (user) => dispatch(getPlayerList(user)),
  resetcreateTeamReducer: () => dispatch({ type: RESET_CREATE_TEAM }),
  sendInvitationBymailHandler: (user) => dispatch(findUser(user)),
  resetInvitationByMailReducer: () =>
    dispatch({ type: RESET_SEND_INVITATION_EMAIL }),
  // userDataHandler: user => dispatch(userData(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitePlayer);
