import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import classNames from "classnames";

import { connect, useSelector } from "react-redux";
import { getInvitationData } from "../service/action/userAction";
import { signupByMail } from "../service/action/userAction";

const SignupByMail = (props) => {
    // console.clear()

    const [show, setShow] = useState(false)

    const { id } = useParams();

    const history = useHistory()



    const getdata = () => {
        if (id !== "") {
            props.getInvitationDataHandler(
                {
                    invitation_id: id
                }
            );
        }
    }
    useEffect(() => {
        getdata()
    }, [id])

    const getInviteDetails = useSelector(state => state.getInviteDataReducer.data.data)

    const [state, setState] = useState({
        name: "",
        // email: "",
        password: "",
        // option: "",
        id: ""
    });

    // const [startDate, setStartDate] = useState();
    // const [showDatePicker, setShowDatePicker] = useState(false);
    const [errors, setErrors] = useState({
        name: false,
        // email: false,
        password: false,
        // option: false,
    });

    const eventhandler = (e) => {
        const { id, value, name } = e.target;
        const validPassword = new RegExp("^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})");

        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));

        switch (name) {
            case "name":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        name: "Name can not be empty.",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        name: false,
                    }));
                }
                break;

                case "password":
                    if (value.length === 0) {
                        setErrors((prevState) => ({
                            ...prevState,
                            password: "Password can not be empty",
                        }));
                    }
                    else if (!validPassword.test(value)) {
                        setErrors((prevState) => ({
                            ...prevState,
                            password: "(Hint:Password must be at least 8 characters long with uppercase, lowercase, and special character)",
                        }));
                    }
    
                    else {
                        setErrors((prevState) => ({
                            ...prevState,
                            password: false,
                        }));
                    }
    
                    break;
            default:
        }
    };

    const signup = (e) => {
        e.preventDefault();

        if (state.name === "") {
            setErrors((prevState) => ({
                ...prevState,
                name: "Name can not be empty",
            }));
        }

        if (state.password === "") {
            setErrors((prevState) => ({
                ...prevState,
                password: "Password can not be empty",
            }));
        }

        if (state.password !== "" && state.name !== "") {
            //status = 1 = club, status =2 = organizer
            props.signupByMailHandler({
                password: state.password,
                name: state.name,
                id: getInviteDetails._id
                // email: state.email,
                // user_type: state.option,
                // dob: moment(startDate).format('YYYY-MM-DD')
            });
        }
    };

    useEffect(() => {
    })
    // if(getInviteDataReducer.data.message==invitation)
    // if (props.signup?.success) {
    //     // props.resetSignup()

    //     return <Redirect to="/login" />;
    // }
    // if(props.signup?.)


    useEffect(() => {
        const token = localStorage.getItem("token")
        const type = localStorage.getItem("type")
        const check = () => {

            if (props.signupByMails?.success) {
                if (token == null && type == null) {
                    history.push("/login")

                } else {

                    history.push("/dashboard")
                    props.resetSignupBymailReducer()
                }
            }
        }
        check()
    }, [props.signupByMails])

    return (
        <React.Fragment>
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex auth login-section">

                    {
                        getInviteDetails ? <>

                            <div className="row flex-grow">
                                <div className="col-lg-4 mx-auto my-auto">
                                    <div className="auth-form-light text-left p-5">
                                        <div className="brand-logo text-center">
                                            <img src="./images/pch-images/PCH_Logo_Hori_Blue Blue-white.svg" alt="logo" />
                                        </div>
                                        <h6 className="text-white">Sign-Up</h6>
                                        <form className="pt-3">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    placeholder="Name"
                                                    className={classNames("form-control ", {
                                                        "login-error": errors.name,
                                                    })}
                                                    id="name"
                                                    name="name"
                                                    value={state.name}
                                                    onChange={eventhandler}
                                                />

                                                {errors.name && (
                                                    <span className="text-danger small d-block">
                                                        * {errors.name}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form-group event-drp">
                                                <select
                                                    className={classNames("form-control payterm", {
                                                        "login-error": errors.option,
                                                    })}
                                                    id="account"
                                                    name="account"
                                                    value={getInviteDetails.invitation_as}
                                                    onChange={eventhandler}
                                                    disabled
                                                >
                                                    <option style={{ color: "#000" }}>Account Type</option>
                                                    <option value="1" style={{ color: "#000" }}>
                                                        organizer
                                                    </option>
                                                    <option value="2" style={{ color: "#000" }}>
                                                        staff
                                                    </option>
                                                    <option value="3" style={{ color: "#000" }}>
                                                        coach
                                                    </option>
                                                    <option value="4" style={{ color: "#000" }}>
                                                        club
                                                    </option>
                                                    <option value="5" style={{ color: "#000" }}>
                                                        parent or player
                                                    </option>
                                                </select>

                                                {errors.option && (
                                                    <span className="text-danger small d-block">
                                                        * {errors.option}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className={classNames("form-control ", {
                                                        "login-error": errors.email,
                                                    })}
                                                    placeholder="Email"
                                                    id="email"
                                                    name="email"
                                                    value={getInviteDetails.invitation_to}
                                                    onChange={eventhandler}
                                                    disabled
                                                />

                                                {errors.email && (
                                                    <span className="text-danger small d-block">
                                                        * {errors.email}
                                                    </span>
                                                )}
                                            </div>

                                            {/* {
                                        showDatePicker &&
                                        (<div className="form-group">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control"
                                                selected={startDate}
                                                placeholderText="dob"
                                                onChange={(date) => setStartDate(date)}
                                                dropdownMode="select"
                                                showMonthDropdown
                                                showYearDropdown
                                                adjustDateOnChange
                                            />
                                        </div>)
                                    } */}

                                            <div className="input-group  form-group pass-wrapper form-group-1">
                                                <input
                                                    type={show ? "text" : "password"}
                                                    className={classNames("form-control form-control-lg w-100", {
                                                        "login-error": errors.password,
                                                    })}
                                                    placeholder="Password"
                                                    id="password"
                                                    value={state.password}
                                                    name="password"
                                                    onChange={eventhandler}
                                                    aria-label="Password" aria-describedby="button-addon2"
                                                    style={{ "z-index": "1" }}
                                                />
                                                {/* <div
                                            className="input-group-append " 
                                            
                                            onClick={() => setShow(!show)} >
                                            {
                                                show ? <i className=" fas fa-eye-slash eye " id="button-addon2" ></i> :
                                                    <i className=" fas fa-eye eye" id="button-addon2"></i>
                                            }
                                        </div> */}
                                                <button className="bg-transparent input-group-append position-absolute eye-button mr-1" onClick={(e) => { e.preventDefault(); setShow(!show) }}>
                                                    {
                                                        show ? <i className="fas fa-eye-slash eye " aria-describedby="button-addon2" ></i> :
                                                            <i className="fas fa-eye eye" aria-describedby="button-addon2"></i>
                                                    }
                                                </button>

                                            </div>
                                            {errors.password && (
                                                <span className="text-white small d-block">
                                                    {errors.password}
                                                </span>
                                            )}
                                            <div className="mt-3 text-center">
                                                <button
                                                    type="submit"
                                                    className="btn btn-login font-weight-medium auth-form-btn"
                                                    disabled={props.signup?.loading}
                                                    onClick={signup}
                                                >
                                                    Sign up
                                                </button>
                                            </div>

                                            {/* <div className="text-center mt-4 text-white">
                                        {" "}
                                        Already have an account?{" "}
                                        <Link to="/login" className="auth-link fw-m">
                                            Log In
                                        </Link>
                                    </div> */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </> : null

                    }
                </div>
            </div>
        </React.Fragment>
    );
};

// export default SignupByMail;

const mapStateToProps = state => ({
    signupByMails: state.signupByMailRducer

})

const mapDispatchToProps = dispatch => ({
    getInvitationDataHandler: user => dispatch(getInvitationData(user)),
    signupByMailHandler: user => dispatch(signupByMail(user)),
    resetSignupBymailReducer: () => dispatch({ type: "RESET_SIGNUP_BY_MAIL" })

})

export default connect(mapStateToProps, mapDispatchToProps)(SignupByMail)
