import { READ_INVITATION_ERROR, READ_INVITATION_SUCCESS, RESET_READ_INVITATION } from "../../../constants";

let initialState = {
 loading: false,
 user: [],
 error: false,
 errors: [],
 success: false,
 payload: {},
};

export default function readInvitationReducer(state = initialState, action) {
 switch (action.type) {
   case READ_INVITATION_SUCCESS:
     return { ...state, success: true, error: false, user: action.payload  };

   case READ_INVITATION_ERROR:
     return { ...state, loading: false, error: true, success :false, user: [] };

   case RESET_READ_INVITATION:
     return { ...state, error: false, success: false };

   default:
     return state;
 }
}
