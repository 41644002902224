import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { removeaccount } from "../../../service/action/userAction";

const RemoveAccount = (props) => {
  const { id } = useParams();

  const [error, seterror] = useState("");

  const removeAccount = useSelector(
    (state) => state.removeAccountReducer.message
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [inputvalue, setinputvalue] = useState("");
  const [inpobj, setinpobj] = useState([]);

  const getinpvalue = (e) => {
    setinputvalue(e.target.value);
  };

  const Delete = () => {
    if (inputvalue == "delete") {
      props.getRemoveAccountHandler({ id: id });
    } else {
      seterror("Please type 'delete");
    }
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <div>
          <Button variant="primary" onClick={handleShow}>
            Remove Your Account
          </Button>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            // style={{ color: "#143d4a" }}
          >
            <Modal.Body>
              <div>
                <p className="mt-2">
                  {" "}
                  Are you sure you want to remove your account ?{" "}
                </p>
                <p className="mt-3">
                  All of your account data, history, and information will be
                  removed from our system and will not be recoverable
                </p>
                <p className="MT-3">Type 'delete'</p>
              </div>
            </Modal.Body>
            <div className="d-flex justify-content-center">
              <input type="text" onChange={getinpvalue} />
            </div>
            <span className="d-flex justify-content-center text-danger">
              {error}
            </span>

            <div className="d-flex justify-content-center mt-3 mb-3">
              <Button onClick={handleClose} className="">
                Cancel
              </Button>

              <Button onClick={Delete} style={{ marginLeft: "20px" }}>
                Confirm
              </Button>
            </div>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  submit: state.deleteEventReducer,
  submit1: state.deleteScheduleReducer,
  Team: state.deleteEventTeamReducer,
  createDivision: state.createDivisionReducer,
  createPool: state.createPoolReducer,
  deletePoolmessage: state.deletePoolReducer,
  deletePoolTeamMessage: state.deletePoolTeamReducer,
  editPoolmessage: state.editPoolReducer,
  editDivisionmessage: state.editDivisionReducer,
  deleteDivisionmessage: state.deleteDivisionReducer,
  schedulemessage: state.createTeamReducer,
  updateFreeTeam: state.updateFreeTeamReducer,
  teamGroup: state.createNewGroupReducer,
  addTeamgroup: state.addPlayerToGroupReducer,
  editGroup: state.editGroupReducer,
  deleteGroup: state.deleteGroupReducer,
  deletePayment: state.deleteEventReducer,
  editCode: state.editPaymentCodeReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getRemoveAccountHandler: (data) => dispatch(removeaccount(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RemoveAccount));
