import { GET_SINGLE_CHAT_ERROR, GET_SINGLE_CHAT_LOADING, GET_SINGLE_CHAT_RESET, GET_SINGLE_CHAT_SUCCESS } from "../../constants";

let initialState={
    loading:false,
    error:false,
    success:false,
    error:[],
    data:[]
}
export default function singleChatReducer(state=initialState,action){
    switch(action.type){
        case GET_SINGLE_CHAT_LOADING:
            return {...state,loading:true,error:false,loading:false,success:false}
        case GET_SINGLE_CHAT_SUCCESS:
            return {...state,loading:false,error:false,success:true,data:action.data}
        case GET_SINGLE_CHAT_ERROR:
            return {...state,loading:false,error:true,success:false,error:[action.errors]}
        case GET_SINGLE_CHAT_RESET:
            return {...state,loading:false,error:false,success:false,error:[]}
        default :
            return state;
    }
}