import { EDIT_ACCOUNT_DETAILS_ERROR, EDIT_ACCOUNT_DETAILS_LOADING, EDIT_ACCOUNT_DETAILS_SUCCESS, RESET_ACCOUNT_DETAILS } from "../../../constants";

let initialState={
    error:false,
    success:false,
    loading:false,
    errors:[]
}

export default function editAccountDetailsReducer(state=initialState,action){
    switch(action.type){
        case EDIT_ACCOUNT_DETAILS_LOADING:
            return{...state,error:false,loading:true,success:false};
        case EDIT_ACCOUNT_DETAILS_SUCCESS:
            return{...state,error:false,success:true,loading:false};
        case EDIT_ACCOUNT_DETAILS_ERROR:
            return{...state,error:true,success:false,loading:false};
        case RESET_ACCOUNT_DETAILS:
            return{...state,error:false,success:false,loading:false}
    
        default:
            return state;
    }
}