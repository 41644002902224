import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ParentSingupForm from "./ParentSingupForm";
import PlayerSingupForm from "./PlayerSingupForm";
import CoachSingupForm from "./CoachSingupForm";
import StaffSingupForm from "./StaffSingupForm";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../service/action/userAction";
import { ToastContainer, toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
const SingupFormContainer = () => {
  const dispatch = useDispatch();
  const signupReducer = useSelector((state) => state.signupReducer);
  const history = useHistory();

  const { id } = useParams();
  const [DOB, setDOB] = useState(null);
  const [state, setState] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    telephone: "",
    DOB: null,
    gender: "",
    terms_condition: "",
    clubName: "",
    address: "",
    state: "",
    city: "",
    zip: "",
    website: "",
    sport: "",
    director_name: "",
    director_email: "",
    director_mobile_number: "",
    organizer_personal_mail: "",
    primary_contact: [],
    orgName: "",
    description: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    first_name: false,
    middle_name: false,
    last_name: false,
    telephone: false,
    DOB: false,
    gender: false,
    terms_condition: false,
    clubName: false,
    address: false,
    state: false,
    city: false,
    city: false,
    zip: false,
    website: false,
    sport: false,
    director_name: false,
    director_email: false,
    director_mobile_number: false,
    organizer_personal_mail: false,
    primary_contact: [],
    description: false,
    orgName: false,
    email: false,
    password: false,
    confirmPassword: false,
  });

  const eventhandler = (e) => {
    const { id, value, name } = e.target;

    if (name === "terms_condition") {
      setState((preve) => ({
        ...preve,
        terms_condition: e.target.checked === true ? 1 : 0,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    switch (name) {
      case "first_name":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            first_name: "first name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            first_name: false,
          }));
        }
        break;
      case "middle_name":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            middle_name: "middle name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            middle_name: false,
          }));
        }
        break;
      case "last_name":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            last_name: "Last name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            last_name: false,
          }));
        }
        break;
      case "telephone":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            telephone: "telephone can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            telephone: false,
          }));
        }
        break;
      case "DOB":
        setDOB(value);
        // capital != null && capital.length < 1
        if (value !== null && value.length < 0) {
          setErrors((prevState) => ({
            ...prevState,
            DOB: "DOB Required",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            DOB: false,
          }));
        }
        break;

      case "address":
        if (value.length == 0) {
          setErrors((prev) => {
            return { ...prev, address: true };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, address: false };
          });
        }

        break;

      case "state":
        if (value == "") {
          setErrors((prev) => {
            return { ...prev, state: true };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, state: false };
          });
        }
        break;
      case "city":
        if (value == "") {
          setErrors((prev) => {
            return { ...prev, city: true };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, city: false };
          });
        }
        break;

      case "zip":
        if (value == "_____") {
          setErrors((prev) => {
            return { ...prev, zip: true };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, zip: false };
          });
        }
        break;

      case "clubName":
        if (value == "") {
          setErrors((prev) => {
            return { ...prev, clubName: true };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, clubName: false };
          });
        }
        break;

      case "email":
        if (value == "") {
          setErrors((prev) => {
            return { ...prev, email: true };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, email: false };
          });
        }
        break;

      case "confirmPassword":
        if (value !== state.password) {
          setErrors((prev) => {
            return { ...prev, confirmPassword: true };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, confirmPassword: false };
          });
        }
        break;

      default:
    }
  };

  const notify = (msg) => toast(msg);

  const handleSubmit = (e) => {
    e.preventDefault();

    const filteredObject = Object.fromEntries(
      Object.entries(state).filter(
        ([key, value]) => value !== undefined && value !== null && value !== ""
      )
    );

    // Parent form creation

    if (id == 6 || id == 5 || 2 || 3) {
      // Validation

      if (state.first_name == "") {
        setErrors((prev) => {
          return { ...prev, first_name: true };
        });
      }

      if (state.last_name == "") {
        setErrors((prev) => {
          return { ...prev, last_name: true };
        });
      }

      if (state.password == "") {
        setErrors((prev) => {
          return { ...prev, password: true };
        });
      }

      if (state.telephone == "") {
        setErrors((prev) => {
          return { ...prev, telephone: true };
        });
      }

      if (state.email == "") {
        setErrors((prev) => {
          return { ...prev, email: true };
        });
      }

      if (state.DOB == "") {
        setErrors((prev) => {
          return { ...prev, DOB: true };
        });
      }

      if (state.gender == "") {
        setErrors((prev) => {
          return { ...prev, gender: true };
        });
      }

      if (
        state.first_name !== "" &&
        state.last_name !== "" &&
        state.password !== "" &&
        state.email !== "" &&
        state.telephone !== ""
      ) {
        const user = {
          ...filteredObject,
          user_type: id,
          dob: state.DOB,
        };
        dispatch(register(user));
      }
    }
  };

  useEffect(() => {
    if (signupReducer.success) {
      setTimeout(() => {
        history.push("/login");
      }, 2000);
    }
  }, [signupReducer.success]);

  return (
    <div className="w-screen">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex auth my-4">
          <div className="row flex-grow">
            <div className="col-lg-5 mx-auto mt-24">
              <div className="auth-form-light text-left p-5">
                <div className="text-gray" style={{ fontWeight: "bold" }}>
                  {id == 2 && (
                    <>
                      <div>Create Account</div>
                      <div>I'm a Event Staff</div>
                    </>
                  )}
                  {id == 3 && (
                    <>
                      <div>Create Account</div>
                      <div>I'm a Coach</div>
                    </>
                  )}
                  {id == 5 && (
                    <>
                      <div>Create Account</div>
                      <div>I'm a Player</div>
                    </>
                  )}
                  {id == 6 && (
                    <>
                      <div>Create Account</div>
                      <div>I'm a Parent/guardian</div>
                    </>
                  )}
                  {id == 4 && (
                    <>
                      <div>Create Account</div>
                      <div>I'm a Club Director</div>
                    </>
                  )}
                  {id == 1 && (
                    <>
                      <div>Create Account</div>
                      <div>I'm a Event Organizer</div>
                    </>
                  )}
                </div>

                {id == 6 && (
                  <ParentSingupForm
                    state={state}
                    errors={errors}
                    eventhandler={eventhandler}
                    DOB={DOB}
                    handleSubmit={handleSubmit}
                  />
                )}
                {id == 5 && (
                  <PlayerSingupForm
                    state={state}
                    errors={errors}
                    eventhandler={eventhandler}
                    DOB={DOB}
                    handleSubmit={handleSubmit}
                  />
                )}
                {id == 3 && (
                  <CoachSingupForm
                    state={state}
                    errors={errors}
                    eventhandler={eventhandler}
                    DOB={DOB}
                    handleSubmit={handleSubmit}
                  />
                )}
                {id == 2 && (
                  <StaffSingupForm
                    state={state}
                    errors={errors}
                    eventhandler={eventhandler}
                    DOB={DOB}
                    handleSubmit={handleSubmit}
                  />
                )}
                {id == 4 && (
                  <div className="text-white space-y-1">
                    <h2 className="text-white text-center text-lg font-bold">
                      Contact us
                    </h2>
                    <p className="text-xl font-bold text-center">
                      info@playersclearinghouse.com
                    </p>
                    <p className="text-center">
                      for information about creating this account type
                    </p>
                  </div>
                )}
                {id == 1 && (
                  <div className="text-white space-y-1">
                    <h2 className="text-white text-center text-lg font-bold">
                      Contact us
                    </h2>
                    <p className="text-xl font-bold text-center">
                      info@playersclearinghouse.com
                    </p>
                    <p className="text-center">
                      for information about creating this account type
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0">
        <Toaster />
      </div>
    </div>
  );
};

export default SingupFormContainer;
