import { CREATE_CREDIT_ERROR, CREATE_CREDIT_LOADING, CREATE_CREDIT_SUCCESS, EDIT_GROUP_ERROR, EDIT_GROUP_LOADING, EDIT_GROUP_SUCCESS, RESET_CREATE_CREDIT, RESET_EDIT_GROUP } from "../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function createcreditReducer(state = initialState, action) {
  switch (action.type) {

    case CREATE_CREDIT_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case CREATE_CREDIT_SUCCESS:
      return { ...state, error: false, loading: false, success: true, message: action.msg };

    case CREATE_CREDIT_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: action.errors }

    case RESET_CREATE_CREDIT:
      return { ...state, error: false, loading: false, success: false, errors: [], user: [{}] }

    default:
      return state;
  }
}


