import {  GET_SPORT_LOADING, GET_SPORT_SUCCESS, GET_SPORT_ERROR } from "../../constants";

let initialState={
    error:false,
    loading:false,
    success:false,
    errors:[],
    citys:[]
}

export default function allSportReducer(state=initialState,action){
    switch(action.type){
        case GET_SPORT_LOADING:
            return {...state,error:false,loading:true,success:false}
        case GET_SPORT_SUCCESS:
            return {...state,error:false,loading:false,success:true,SPORTs:action.payload}
        case GET_SPORT_ERROR:
            return {...state,error:true,loading:false,success:false}
        default:
            return state;
    }
}