import { combineReducers } from "redux";
import termsAndConditionsReducer from "./termsAndConditionsReducer";
import signupReducer from "./signupReducer";
import signinReducer from "./signinReducer";
import createEventReducer from "./createEventReducer";
import fetchEventDataReducer from "./fetchEventDataReducer";
import resetReducer from "./resetReducer";
import notificationCountReducer from "./Notification/notificationCountReducer";
import createPaymentCodeReducer from "./createPaymentCodeReducer";
import getAllPaymentCodeReducer from "./getAllPaymentCodeReducer.js";
import editPaymentCodeReducer from "./editPaymentCodeReducer";
import editEventReducer from "./editEventReducer";
import { getAllEventReducer } from "./getAllEventReducer";
import getClubListFindByName from "./getClubListFindByName";
import sendEventDetailsReducer from "./sendEventDetailsReducer";
import { RESET_STORE } from "../constants";
import { eventReducer } from "./getAllEventReducer";
import switchAccountReducer from "./switchAccountReducer";
import switchNotificationUser from "./switchNotificationUser";
import getUserRollReducer from "./getUserRollReducer";
import addAccountReducer from "./addAccountReducer";
import getAllSeasonReducer from "./Season Reducer/getAllSeasonReducer";
import createSeasonReducer from "./Season Reducer/createSeasonReducer";
import editSeasonReducer from "./Season Reducer/editSeasonReducer";
import getInviteDataReducer from "./SignupByEmailLink/getInviteDataReducer";
import signupByMailRducer from "./SignupByEmailLink/signupByMailReducer";
import getStateReducer from "./State_City Reducer/getStateReducer";
import getCityReducer from "./State_City Reducer/getCityReducer";
import allSportReducer from "./Sport/allSportReducer";
import getDiscountCodeReducer from "./DiscountCodeReducer/getDiscountCodeReducer";
import createDiscountCodeReducer from "./DiscountCodeReducer/createDiscountCodeReducer";
import editDiscountCodeReducer from "./DiscountCodeReducer/editDiscountCodeReducer";
import accountDetailsReducer from "./Account/Account-Details/accountDetailsReducer";
import editAccountDetailsReducer from "./Account/Account-Details/editAccountDetailsReducer";
import updateProfileImageReducer from "./Account/Account-Details/updateProfileImageReducer";
import createDivisionReducer from "./Division/createDivisionReducer";
import editDivisionReducer from "./Division/editDivisionReducer";
import getAllDivisionReducer from "./Division/getAllDivisionReducer";
import deleteDivisionReducer from "./Division/deleteDivisionReducer";
import createPoolReducer from "./Pool/createPoolReducer";
import editPoolReducer from "./Pool/editPoolReducer";
import deletePoolReducer from "./Pool/deletePoolReducer";
import createTeamReducer from "./Create-Team/createTeamReducer";
import createRosterReducer from "./Create-Team/createRosterReducer";
import removeRosterReducer from "./Create-Team/removeRosterReducer";
import removePayerFromRosterReducer from "./Create-Team/removePayerFromRosterReducer";
import getAllClubReducer from "./GetAllClub/getAllClubReducer";
import getEventStaffReducer from "./Get Event Staff/getEventStaffReducer";
import deleteEventReducer from "./Org_Event/deleteEventReducer";
import clonEventReducer from "./Org_Event/clonEventReducer";
import getEventScheduleReducer from "./Event-Schedule/getEventScheduleReducer";
import deleteScheduleReducer from "./Event-Schedule/deleteScheduleReducer";
import getEventDateFilterReducer from "./Event-Schedule/getEventDateFilterReducer";
import getFreeTeamReducer from "./Create-Team/getFreeTeamReducer";
import getEventTeamReducer from "./Create-Team/getEventTeamReducer";
import updateFreeTeamReducer from "./Create-Team/updateFreeTeamReducer";
import deleteEventTeamReducer from "./Create-Team/deleteEventTeamReducer";
import deleteSeasonReducer from "./Season Reducer/deleteSeasonReducer";

import getAllClubEventReducer from "./Club-Reducer/Club-Event/getAllClubEventReducer";
import bookmarkReducer from "./Club-Reducer/bookmarkReducer";
import getClubAllTeamReducer from "./Club-Reducer/Team/getClubAllTeamReducer";
import getPlayerInvitationReducer from "./Club-Reducer/getPlayerInvitaionReducer";
import getFieldReducer from "./Club-Reducer/Field/getFieldReducer";
import getAllClubSeasonReducer from "./Club-Reducer/Season/getAllClubSeasonReducer";
import createLeagueReducer from "./Club-Reducer/League/createLeagueReducer";
import updateLeagueREducer from "./Club-Reducer/League/updateLeagueREducer";
import getLeagueReducer from "./Club-Reducer/League/getLeagueReducer";
import createBankDetailsReducer from "./Club-Reducer/Bank/createBankDetailsReducer";
import getBanksReducer from "./Club-Reducer/Bank/getBanksReducer";
import deleteBankReducer from "./Club-Reducer/Bank/deleteBankReducer";
import editBankReducer from "./Club-Reducer/Bank/editBankReducer";
import sendInvitationToCoachReducer from "./Club-Reducer/Coach/sendInvitaionToCoachReducer";
import getTeamDetailsReducer from "./Club-Reducer/Team/getTeamDetailsReducer";
import acceptPlayerReducer from "./Club-Reducer/Team/acceptPlayerReducer";
import getCoachInvitationReducer from "./Club-Reducer/Team/getCoachInvitationReducer";
import acceptCoachReducer from "./Club-Reducer/Team/acceptCoachReducer";
import getAllInvitationReducer from "./Club-Reducer/Notification/getAllInvitationReducer";
import acceptInvitationReducer from "./Club-Reducer/Notification/acceptInvitationReducer";
import getOrgClubListReducer from "./Org-Club List/getOrgClubListReducer";
import coachAcceptListReducer from "./Club-Reducer/Coach/coachAcceptListReducer";
import getPaymentcodeReducer, {
  divisionCustomPaymentcodeReducer,
} from "./getPaymentcodeReducer";
import clubDashBoardReducer from "./Club-Reducer/Dash-board/clubDashBoardReducer";
import createLiabilityReducer from "./Liability/createLiabilityReducer";
import getClubsOrgListReducer from "./Club-Reducer/ClubsOrgList/getClubsOrgReducer";
import getOrgLiabilityReducer from "./Liability/getOrgLiabilityReducer";
import signaturePostReducer from "./Liability/signaturePostReducer";
import getClubOrgEventDetailsReducer from "./Club-Reducer/Club-Event/getClubOrgEventDetailsReducer";
import approveLiabilityReducer from "./Club-Reducer/approveLiabilityReducer";
import getClubOrgEventReducer from "./Club-Reducer/Club-Event/getClubOrgEventReducer";
import getStaffInvitationReducer from "./Club-Reducer/Notification/getStaffInvitationReducer";
import readNotificationReducer from "./Club-Reducer/Notification/readNotificationReducer";
import readInvitationReducer from "./Club-Reducer/Notification/readInvitationReducer";
import getTeamByIdReducer from "./Club-Reducer/Team/getTeamByIdReducer";
import editClubTeamReducer from "./Club-Reducer/Team/editClubTeamReducer";
import addSelectedRosterReducer from "./Club-Reducer/Team/addSelectedRosterReducer";
import getNotificationReducer from "./Club-Reducer/Notification/getNotificationReducer";
import createNewGroupReducer from "./Account/Account-user/createNewGroupReducer";
import getAllGroupsReducer from "./Account/Account-user/getAllGroupsReducer";
import addStaffToGroupReducer from "./Account/Account-user/addStaffToGroupReducer";
import permissionReducer from "./Account/Account-user/permissionReducer";
import getPermissionDetailsReducer from "./Account/Account-user/getPermissionDetailsReducer";
import getEventByIdReducer from "./Event/getEventByIdReducer";
import getEventDetailsForRegistrationReducer from "./Club-Reducer/Event/getEventDetailsForRegistrationReducer";
import registerSelectedTeamReducer from "./Club-Reducer/Registration/registerSelectedTeam";
import getRegTeamPayAmountReducer from "./Club-Reducer/Registration/getRegTeamPayAmountReducer";
import registerTeamReducer from "./Club-Reducer/Registration/registerTeamReducer";
import teamArrayReducer from "./Club-Reducer/Registration/teamArrayReducer";
import applydiscountcodeReducer from "./Club-Reducer/Registration/applydiscountcodeReducer";
import paynowReducer from "./Club-Reducer/Registration/Payment/paynowReducer";
import connectedUserClubReducer from "./Club-Reducer/Notification/connectedUserClubReducer";
import clearAllNotification from "./Club-Reducer/Notification/clearAllNotification";
import playerAcceptListReducer from "./Club-Reducer/Team/playerAcceptListReducer";
import signoutReducer from "./Sign-out/signoutReducer";
import getEventRegisterTeamReducer from "./Payment/Paymentreq/getEventRegisterTeamReducer";
import getPendingAmountReducer from "./Payment/Paymentreq/getPendingAmountReducer";
import paymentRequestReducer from "./Payment/Paymentreq/paymentRequestReducer";
import getPaymentcode1Reducer from "./getPaymentcode1Reducer";
import getPaymentRequestReducer from "./Payment/Paymentreq/getPaymentRequestReducer";
import getPrintOrgLiabilityReducer from "./Liability/getPrintOrgLiabilityReducer";
import getTeamGroupsReducer from "./Create-Team/getTeamGroupsReducer";
import addPlayerToGroupReducer from "./Player/addPlayerToGroupReducer";
import playerDetailsReducer from "./Player/playerDetailsReducer";
import removePlayerReducer from "./Player/removePlayerReducer";
import editGroupReducer from "./Player/editGroupReducer";
import deleteGroupReducer from "./Player/deleteGroupReducer";
import getPaidRecipientReducer from "./Payment/getPaidRecipientReducer";
import paymentMethodReducer from "./Payment/paymentMethodReducer";
import getPaymentMethodReducer from "./Payment/getPaymentMethodReducer";
import removePaymentMethodReducer from "./Payment/removePaymentMethodReducer";
import createcreditReducer from "./Payment/createCreditReducer";
import sendPaymentInfoReducer from "./Club-Reducer/Registration/Payment/sendPaymentInfoReducer";
import getOrgCreditReducer from "./Payment/Paymentreq/getOrgCreditReduce";
import getPaymentReducer from "./Payment/Refund/getPaymentReducer";
import getReferenceNumberReducer from "./Payment/Refund/getReferenceNumberReducer";
import getRefundsReducer from "./Payment/Refund/getRefundsReducer";
import getOrgRequestReducer from "./Club-Reducer/Notification/getOrgRequestReducer";
import getOrgNotificationReducer from "./Club-Reducer/Notification/getOrgNotificationReducer";
import changeNotificationStatusReducer from "./Club-Reducer/Notification/changeNotificationStatusReducer";
import forgotpasswordEmailReducer from "./Forgotpassword/forgotpasswordEmailReducer";
import verifyotpReducer from "./Forgotpassword/verifyotpReducer";
import resetPasswordReducer from "./Forgotpassword/resetPasswordReducer";
import getOrgClubDetailsReducer from "./Org-Club List/getOrgClubDetailsReducer";
import clubTeamDetailsReducer from "./Org-Club List/clubTeamDetailsReducer";
import createMerchandAccountReducer from "./Club-Reducer/Bank/createMerchandAccountReducer";
import sendImageReducer from "./Event/sendImageReducer";
import LeagueBookmarkReducer from "./Club-Reducer/League/leagueBookmarkReducer";
import getReceiveReportReducer from "./Account/Reports/getReceiveReportReducer";
import getOutStandingReportReducer from "./Account/Reports/getOutStandingReducer";
import getRefundReportReducer from "./Account/Reports/getRefundReportReducer";
import getRefundRecivedReducer from "./Account/Reports/getRefundRecivedReducer";
import getDashboardPaymentReducer from "./DashBoard/getDashBoardPaymentReducer";
import getTeamEventsReducer from "./Club-Reducer/Team/getTeamEventsReducer";
import assigncoachReducer from "./Club-Reducer/Team/assigncoachReducer";
import connectedUserReducer from "./Notification/connectedUserReducer";
import OrgConnectedUserReducer from "./Notification/OrgConnectedUserReducer";
import getAllReportReducer from "./Account/Reports/getAllReportReducer";
import staffListReducer from "./Account/staffListReducer";
import createEventDivisionReducer from "./Club-Reducer/Event/Event-division/createEventDivisionReducer";
import geteventDivisionReducer from "./Club-Reducer/Event/Event-division/geteventDivisionReducer";
import editeventDivisionReducer from "./Club-Reducer/Event/Event-division/editEventDivisionReducer";
import getOrgEventDetailsByIdReducer from "./Event/getOrgEventDetailsByIdReducer";
import sendInvitationByMailReducer from "./SignupByEmailLink/sendInvitationByMailReducer";
import getClubEventTeamReducer from "./Club-Reducer/Event/getClubEventTeamReducer";

import deleteEventPoolReducer, {
  deletePoolTeamReducer,
} from "./Club-Reducer/Event/Event-division/deleteEventPoolReducer";
import deletePoolPlayerReducer from "./Club-Reducer/Event/Event-division/deletePoolPlayerReducer";
import activePlayerReducer from "./Club-Reducer/Team/activePlayerReducer";
import singleChatReducer from "./Chat/singleChatReducer";
import sendBroadcastReducer from "./Chat/sendBroadcastReducer";
import publishScheduleRedcuer from "./Event/publishScheduleReducer";
import InviteClubReducer from "./Event/InviteClubReducer";
import getParentInvitationReducer from "./Club-Reducer/Notification/getParentInvitationReducer";
import acceptParentReducer from "./Club-Reducer/Notification/acceptParenInvitationReducer";
import adminReducer from "./adminReducer";
import getBulkRecipientReducer from "./Payment/Paymentreq/getBulkRecipientReducer";
import reminderReducer from "./Account/Reports/reminderReducer";
import findUserReducer from "./Account/Reports/findUserReducer";
import getEvenActivityLogReducer from "./GetActivitylogReducer/getActivityLogReducer";
import removeAccountReducer from "./removeAccountReducer";
import getAllOrgSeasonReducer from "./Create-Team/createOrgSeasonReducer";
import createOrgTeamReducer from "./Create-Team/createOrgTeamReducer";
import getAccountingReducer from "./Club-Reducer/Team/getAccountingReducer";
import getMerchantOrgReducer from "./GetMerchantOrgReducer";
import getMerchantStatusReducer from "./GetMerchantStatusReducer";
import getSignedWaiverReducer from "./GetSignedWaiverReducer";
import getLiabilityTermsReducer from "./GetLiabilityTermsReducer";
import getPaymentReqSendReducer from "./Payment/Paymentreq/GetpaymentReqReducer";
import reqaddpaymentReducer from "./reqaddpaymentOrgReducer";
import getTeamEventDetails from "./Club-Reducer/Team/getTeamEventDetails";
import removeTeamPlayerReducer from "./Club-Reducer/Team/removeTeamPlayerReducer";
import addTeamPlayerReducer from "./Club-Reducer/Team/addTeamPlayerReducer";
import getClubPlayer from "./Club-Reducer/Player/getClubPlayer";
import getAllClubDivision from "./Club-Reducer/getAllClubDivision";
import getClubRecipientReducer from "./Club-Reducer/getClubRecipientReducer";

import EventRegistrationReducer from "./Event/EventRegistrationReducer";
import OrgplayerdetailsReducer from "./Player/OrgplayerdetailsReducer";
import clubdiscountReducer from "./ClubdiscountReducer";
import clubcreditReducer from "./ClubcreditReducer";
import OrgGetallclubReducer from "./GetAllClub/OrgGetallclubReducer";
import getClubByIdReducer from "./GetAllClub/getClubByIdReducer";
import OrgbalancedueReducer from "./OrgBalancedueReducer";
import switchAccessReducer from "./SwitchAccessReducer";
import switchPlayerAccessReducer from "./switchPlayerAccessReducer";
import moveToFreeTeamReducer from "./moveToFreeTeamReducer";
import eventdivisionpollReducer from "./EventdivisionpoolReducer";
import GetPermiossionAdminGroupReducer from "./GetPermiossionAdminGroupReducer";
import CreateAdminGroup from "./CreateAdminGroup";
import addteamtodivisionReducer from "./AddteamtodivisionReducer";
import addplayertodivisionReducer from "./addplayertodivisionReducer";
import updateScheduleReducer from "./UpdateScheduleReducer";
import createfieldmapReducer from "./Field-Map/CreateFieldMapReducer";
import viewfieldmapReducer from "./Field-Map/ViewfieldmapReducer";
import updatefieldmapReducer from "./Field-Map/EditfieldmapReducer";
import DeleteFiledMApReducer from "./Field-Map/DeleteFiledMApReducer";
import ClearOrgNotificationsReducer from "./ClearOrgNotificationsReducer";
import CreateScheduleMessageReducer from "./CreateScheduleMessageReducer";
import CreateFieldmapMessageReducer from "./CreateFieldmapReducerMessageReducer";
import ViewStayandPlayReducer from "./Stay_and_play/ViewStayandPlayReducer";
import StayandPlayReducer from "./Stay_and_play/StayandPlayReducer";
import OrgPermissionReducer from "./Permission/OrgPermissionReducer";
import MerchantReducer from "./Merchant/MerchantReducer";
import superAdminRootReducer from "../../SuperAdmin/Services/index.js";
import UserReducer from "../../SuperAdmin/Services/Reducer/UserReducer";

const appReducer = combineReducers({
  signoutReducer,
  signupReducer,
  signinReducer,
  createEventReducer,
  fetchEventDataReducer,
  createPaymentCodeReducer,
  getAllPaymentCodeReducer,
  editPaymentCodeReducer,
  teamArrayReducer,
  editEventReducer,
  getAllEventReducer,
  getClubListFindByName,
  getEventDateFilterReducer,
  sendEventDetailsReducer,
  eventReducer,
  switchAccountReducer,
  switchNotificationUser,
  getUserRollReducer,
  addAccountReducer,
  getAllSeasonReducer,
  createSeasonReducer,
  editSeasonReducer,
  getInviteDataReducer,
  signupByMailRducer,
  getStateReducer,
  getCityReducer,
  getDiscountCodeReducer,
  createDiscountCodeReducer,
  editDiscountCodeReducer,
  accountDetailsReducer,
  editAccountDetailsReducer,
  updateProfileImageReducer,
  createDivisionReducer,
  getAllDivisionReducer,
  deleteDivisionReducer,
  createPoolReducer,
  editDivisionReducer,
  deletePoolReducer,
  createTeamReducer,
  createRosterReducer,
  removeRosterReducer,
  removePayerFromRosterReducer,
  editPoolReducer,
  getAllClubReducer,
  getEventStaffReducer,
  deleteEventReducer,
  clonEventReducer,
  getEventScheduleReducer,
  deleteScheduleReducer,
  getFreeTeamReducer,
  getEventTeamReducer,
  updateFreeTeamReducer,
  deleteEventTeamReducer,
  allSportReducer,
  getPaymentcodeReducer,
  clubDashBoardReducer,
  createLiabilityReducer,
  createNewGroupReducer,
  getAllGroupsReducer,
  addStaffToGroupReducer,
  permissionReducer,
  getPermissionDetailsReducer,
  getEventByIdReducer,
  getEventRegisterTeamReducer,
  getPendingAmountReducer,
  paymentRequestReducer,
  getPaymentcode1Reducer,
  getPaymentRequestReducer,
  getPrintOrgLiabilityReducer,
  getTeamGroupsReducer,
  addPlayerToGroupReducer,
  playerDetailsReducer,
  removePlayerReducer,
  editGroupReducer,
  deleteGroupReducer,
  getPaidRecipientReducer,
  createcreditReducer,
  getOrgCreditReducer,
  getPaymentReducer,
  getReferenceNumberReducer,
  getRefundsReducer,
  getOrgRequestReducer,
  getOrgNotificationReducer,
  changeNotificationStatusReducer,
  forgotpasswordEmailReducer,
  verifyotpReducer,
  resetPasswordReducer,
  getOrgClubDetailsReducer,
  clubTeamDetailsReducer,
  sendImageReducer,
  getReceiveReportReducer,
  getOutStandingReportReducer,
  getRefundReportReducer,
  getRefundRecivedReducer,
  getDashboardPaymentReducer,
  getTeamEventsReducer,
  connectedUserReducer,
  OrgConnectedUserReducer,
  getAllReportReducer,
  staffListReducer,
  createEventDivisionReducer,
  getOrgEventDetailsByIdReducer,
  sendInvitationByMailReducer,
  singleChatReducer,
  sendBroadcastReducer,
  publishScheduleRedcuer,
  InviteClubReducer,
  getEvenActivityLogReducer,
  removeAccountReducer,
  getAllOrgSeasonReducer,
  createOrgTeamReducer,
  getAccountingReducer,
  getMerchantOrgReducer,
  getMerchantStatusReducer,
  getSignedWaiverReducer,
  getLiabilityTermsReducer,
  clubdiscountReducer,
  clubcreditReducer,
  OrgGetallclubReducer,
  getClubByIdReducer,
  updateScheduleReducer,
  createfieldmapReducer,
  viewfieldmapReducer,
  updatefieldmapReducer,
  DeleteFiledMApReducer,
  MerchantReducer,

  //   <<<<<<<<<<<<<<<-------------------------Club Reducer ------------------------->>>>>>>>>>>>>>>

  getAllClubEventReducer,
  bookmarkReducer,
  getClubAllTeamReducer,
  getPlayerInvitationReducer,
  getFieldReducer,
  getAllClubSeasonReducer,
  createLeagueReducer,
  updateLeagueREducer,
  getLeagueReducer,
  createBankDetailsReducer,
  getBanksReducer,
  deleteBankReducer,
  editBankReducer,
  deleteSeasonReducer,
  sendInvitationToCoachReducer,
  getTeamDetailsReducer,
  acceptPlayerReducer,
  getCoachInvitationReducer,
  acceptCoachReducer,
  getAllInvitationReducer,
  acceptInvitationReducer,
  getOrgClubListReducer,
  coachAcceptListReducer,
  getClubsOrgListReducer,
  getOrgLiabilityReducer,
  signaturePostReducer,
  getClubOrgEventDetailsReducer,
  getClubOrgEventReducer,
  getStaffInvitationReducer,
  readNotificationReducer,
  readInvitationReducer,
  getTeamByIdReducer,
  editClubTeamReducer,
  addSelectedRosterReducer,
  getNotificationReducer,
  getEventDetailsForRegistrationReducer,
  registerSelectedTeamReducer,
  getRegTeamPayAmountReducer,
  registerTeamReducer,
  applydiscountcodeReducer,
  paynowReducer,
  connectedUserClubReducer,
  clearAllNotification,
  playerAcceptListReducer,
  sendPaymentInfoReducer,
  createMerchandAccountReducer,
  LeagueBookmarkReducer,
  assigncoachReducer,
  geteventDivisionReducer,
  editeventDivisionReducer,
  getClubEventTeamReducer,
  deleteEventPoolReducer,
  activePlayerReducer,
  getParentInvitationReducer,
  acceptParentReducer,
  adminReducer,
  getBulkRecipientReducer,
  reminderReducer,
  deletePoolTeamReducer,
  deletePoolPlayerReducer,
  divisionCustomPaymentcodeReducer,
  findUserReducer,
  approveLiabilityReducer,
  paymentMethodReducer,
  getPaymentMethodReducer,
  removePaymentMethodReducer,
  getPaymentReqSendReducer,
  EventRegistrationReducer,
  reqaddpaymentReducer,
  UserReducer,
  getTeamEventDetails,
  removeTeamPlayerReducer,
  addTeamPlayerReducer,
  getClubPlayer,
  getAllClubDivision,
  getClubRecipientReducer,
  OrgbalancedueReducer,
  switchAccessReducer,
  switchPlayerAccessReducer,
  moveToFreeTeamReducer,
  eventdivisionpollReducer,
  OrgplayerdetailsReducer,
  addteamtodivisionReducer,
  addplayertodivisionReducer,
  ClearOrgNotificationsReducer,
  GetPermiossionAdminGroupReducer,
  CreateAdminGroup,
  CreateScheduleMessageReducer,
  CreateFieldmapMessageReducer,
  ViewStayandPlayReducer,
  StayandPlayReducer,
  superAdminRootReducer,
  OrgPermissionReducer,
  termsAndConditionsReducer,
  notificationCountReducer,

  // resetReducer
});
const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export default rootReducer;
