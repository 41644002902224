import { GET_EVENTACTIVITYLOG_ERROR, GET_EVENTACTIVITYLOG_LOADING, GET_EVENTACTIVITYLOG_SUCCESS } from "../../constants";

let initialState = {
    loading: false,
    user: [],
    error: false,
    errors: [],
    success: false,
    payload: {}
};


export default function getEvenActivityLogReducer(state = initialState, action) {
    switch (action.type) {
        case GET_EVENTACTIVITYLOG_LOADING:
            // alert(" reducer succesfull")
            return { ...state, success: false, success: false };

        case GET_EVENTACTIVITYLOG_SUCCESS:
            // alert(" reducer Error")
            return { ...state, loading: false, success: true, user: action.payload };

        case GET_EVENTACTIVITYLOG_ERROR:
            // alert(" reducer Loading")
            return { ...state, error: true, errors: action.errors };

        default:
            return state;
    }
}
