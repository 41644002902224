import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  OrggetallclubByIdAction,
  getAllClub,
  inviteClub,
  sendInvitationBymail,
} from "../../../service/action/userAction";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import {
  RESET_CREATE_TEAM,
  RESET_INVITE_CLUB_ORG,
  RESET_SEND_INVITATION_EMAIL,
} from "../../../service/constants";
import Spinner from "../../../Spinner/Spinner";
import classNames from "classnames";

const ClubNew = (props) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [emailsList, setEmailsList] = useState([]);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [Orgallclub, setOrgallclub] = useState();
  useEffect(() => {
    props.OrggetallclubByIdAction(id);
  }, []);

  useEffect(() => {
    if (props.OrgGetallclubReducer?.success) {
      setOrgallclub(props.OrgGetallclubReducer?.user?.data);
      setLoading(false);
    }
  }, [props.OrgGetallclubReducer]);

  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    setEmail(e.target.value);

    switch (name) {
      case "email":
        if (value.length == 0) {
          setError("Email can not be empty");
        } else {
          setError(false);
        }
        break;
      default:
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (emailsList.length == 0) {
      setError(true);
      notify("Please add email");
    }
    if (emailsList.length != 0 && error == false) {
      props.sendInvitationBymailHandler({
        invite_user_email: emailsList.join(", "),
        invite_user_type: "2",
      });
    }
  };

  const notify = (msg) => toast(msg);

  // const getdata = props.getAllClubHandler;

  // useEffect(() => {
  //   getdata();
  // }, []);
  // const allclub = useSelector((state) => state.getAllClubReducer.user.data);
  const ClubInviteMsg = useSelector((state) => state.createTeamReducer.message);

  const invite = (_id, type) => {
    // alert("d")
    if (id == null) {
      props.inviteclub({
        
        club_id: _id,
        invite_type: 0,
        invite_user_type: 0,
      });
    } else if (id !== null) {
      props.inviteclub({
        event_id: id,
        club_id: _id,
        invite_type: 0,
        // division  if invite type 1
        is_send: type,
        event_invite: 1,
      });
    }
  };

  const [copied, setCopied] = useState(false);
  function copy() {
    const el = document.createElement("input");
    // el.value = `${window.location.origin}/registernow/${id}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }

  useEffect(() => {
    if (props.sendByMail?.success) {
      notify("Invitation send successfully");
      props.OrggetallclubByIdAction(id);
      props.resetInvitationByMailReducer();
      setTimeout(() => {
        setEmail("");
      }, 1000);
      setError(false);
    }
  }, [props.sendByMail]);

  useEffect(() => {
    if (props.submit?.success) {
      notify("Club Invite Successfull");
      props.OrggetallclubByIdAction(id);
      props.resetInvite();
    } else if (props.submit?.error) {
      notify("Club Invite fail ");
    }
  }, [props.submit]);

  const addEmails = () => {
    if (email === "") {
      notify("Please Check Email");
    } else {
      const validEmailRegex = RegExp(
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      );
      const list = email.split(/,|, /);
      const errorList = [];
      const isValid = list.map((v) => {
        if (!validEmailRegex.test(v.trim())) {
          errorList.push(v);
          return false;
        } else if (list.length === 0 || v.trim().length === 0) {
          return false;
        } else {
          setError(false);
          return v;
        }
      });
      const _list = isValid.filter((e) => !!e);
      const combainArray = [...emailsList, ..._list];
      setEmailsList([...new Set(combainArray)]);
      if (errorList.length != 0) {
        setEmail(errorList.join(", "));
        setError("Email is not valid !");
      } else {
        setEmail("");
      }
    }
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head">
              <h5 className="dash-head mr-3 mb-0">Invite Clubs</h5>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-md-6">
              <div className="text-left invite-email mt-42">
                <i className="mdi mdi-email"></i>
                <input
                  type="email"
                  className={classNames("form-control team-name", {
                    error: error,
                  })}
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => eventHandler(e)}
                />
              </div>

              {error && (
                <span className="text-red small d-block">* {error}</span>
              )}
              <div className="mt-3 fw-m text-red pointer" onClick={addEmails}>
                Add Email
              </div>

              {emailsList.length != 0 ? (
                <div className="mt-2">
                  {emailsList.map((e, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div className="row mx-0">
                          <div className="col-10 text-gray">{e}</div>
                          <div
                            className="col-2 text-right pointer"
                            onClick={() => {
                              const list = emailsList.filter(
                                (list, index) => index != i
                              );
                              setEmailsList(list);
                            }}
                          >
                            <i className="fas fa-close"></i>
                          </div>
                        </div>
                        <hr className="my-1" />
                      </React.Fragment>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}

              <div className="mt-3 text-left">
                <a
                  className="btn btn-primary font-weight-medium auth-form-btn"
                  onClick={sendEmail}
                >
                  Send
                </a>
              </div>
            </div>
          </div>
          <button
            className="btn  p-0 ml-1 mt-3"
            style={{ color: "#143D4A" }}
            onClick={copy}
          >
            <i
              className="mdi mdi-open-in-new website-ico"
              style={{ color: "#80bdff" }}
            ></i>{" "}
            {!copied ? "Copy Invite link" : "Copied!"}
          </button>

          {
            <div className="d-flex justify-content-center">
              {loading ? <Spinner /> : null}
            </div>
          }

          {!loading && (
            <div className="table-responsive">
              {Orgallclub?.length !== 0 ? (
                <>
                  <table className="table event-table mt-30">
                    <thead>
                      <tr>
                        <th>Club Name</th>
                        <th>Phone No.</th>
                        {/* <th>Payment</th> */}
                        <th>Location</th>
                        <th></th>
                      </tr>
                    </thead>
                    {Orgallclub ? (
                      <>
                        <tbody>
                          {Orgallclub.filter((v, i) => v?.status === 0).map(
                            (v, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="dashboard-img">
                                        <img
                                          src={
                                            v.profile_image == ""
                                              ? "./images/pch-images/pch.svg"
                                              : v.profile_image
                                          }
                                        />
                                      </div>
                                      <span className="ml-3">{v.name}</span>
                                    </div>
                                  </td>
                                  <td>
                                    {v.mobile_number ? v.mobile_number : "NA"}
                                  </td>
                                  {/* <td>{v.Phone}</td> */}
                                  <td>{v.address}</td>
                                  <td>
                                    <Link
                                      to="#"
                                      className="text-decoration-none"
                                      onClick={() => invite(v._id, v?.is_send)}
                                    >
                                      <i className="mdi mdi-plus text-blue mr-2 website-ico"></i>
                                      {v?.is_send == false ? (
                                        <span className="text-blue">
                                          Invite
                                        </span>
                                      ) : (
                                        <span className="text-blue">
                                          Resend
                                        </span>
                                      )}
                                    </Link>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </>
                    ) : (
                      <>
                        <div className="container d-flex justify-content-center align-items-center">
                          <span className="noti-type">No club to display</span>
                        </div>
                      </>
                    )}
                  </table>
                </>
              ) : (
                <div className="d-flex justify-content-center text-gray mt-2">
                  No clubs to display
                </div>
              )}
            </div>
          )}
          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(ClubNew);
const mapStateToProps = (state) => ({
  submit: state.InviteClubReducer,
  allClub: state.getAllClubReducer,
  // getAllPayment: state.getAllPaymentCodeReducer,
  sendByMail: state.sendInvitationByMailReducer,
  OrgGetallclubReducer: state.OrgGetallclubReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // getAllClubHandler: (user) => dispatch(getAllClub(user)),
  inviteclub: (user) => dispatch(inviteClub(user)),
  resetInvite: () => dispatch({ type: RESET_INVITE_CLUB_ORG }),
  sendInvitationBymailHandler: (user) => dispatch(sendInvitationBymail(user)),
  resetInvitationByMailReducer: () =>
    dispatch({ type: RESET_SEND_INVITATION_EMAIL }),
  OrggetallclubByIdAction: (user) => dispatch(OrggetallclubByIdAction(user)),

  // userDataHandler: user => dispatch(userData(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClubNew));
