import axios from "axios";
import {
  checkMailExistUrl,
  getCity,
  getSportUrl,
  getStateUrl,
  logOutUrl,
  signInUrl,
  userDetails_Url,
} from "../apiSheet";
import {
  GET_CITY_LIST_ERROR,
  GET_CITY_LIST_SUCCESS,
  GET_SPORT_LIST_ERROR,
  GET_SPORT_LIST_SUCCESS,
  GET_STATE_LIST_SUCCESS,
  GET_USER_DETAILS_ERROR,
  GET_USER_DETAILS_SUCCESS,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
  GET_STATE_LIST_ERROR,
  CHECK_MAIL_EXIST_SUCCESS,
  CHECK_MAIL_EXIST_ERROR,
} from "../constant";

export const login = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
      },
      url: signInUrl,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: LOGIN_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: LOGIN_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: LOGIN_ERROR,
            errorMsg: validationError,
          });
        }
      });
  };
};

export const logout = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: logOutUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: LOGOUT_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: LOGOUT_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: LOGOUT_ERROR,
            errorMsg: validationError,
          });
        }
      });
  };
};

export const StateList = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getStateUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_STATE_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_STATE_LIST_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_STATE_LIST_ERROR,
            errorMsg: validationError,
          });
        }
      });
  };
};

export const CityList = (stateId) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getCity}/${stateId}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_CITY_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_CITY_LIST_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_CITY_LIST_ERROR,
            errorMsg: validationError,
          });
        }
      });
  };
};

export const SportList = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getSportUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_SPORT_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SPORT_LIST_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_SPORT_LIST_ERROR,
            errorMsg: validationError,
          });
        }
      });
  };
};

export const checkMailIsExist = (mail) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${checkMailExistUrl}/${mail}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CHECK_MAIL_EXIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CHECK_MAIL_EXIST_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: CHECK_MAIL_EXIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: CHECK_MAIL_EXIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};
