import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import classNames from "classnames";
import { Link, withRouter } from "react-router-dom";
import {
  saveEventData,
  getAllSeason,
  getAllState,
  getAllCity,
  getPaymentCode,
  getPaymentCode1,
  sendImage,
  getStaff,
  getOrgLiability,
  getAdmin,
  getAllPaymentCode,
  getMerchantOrg,
  getMerchantStatus,
} from "../../../service/action/userAction";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  RESET_CREATE_EVENT,
  RESET_CREATE_PAYMENT_CODE,
  RESET_CREATE_SEASON,
  RESET_GET_ALL_SEASON,
  RESET_GET_PAYMENTCODE,
} from "../../../service/constants";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import { useHistory } from "react-router-dom";
import Spinner from "../../../Spinner/Spinner";
// import moment from 'moment';
import {
  END_DATE,
  END_TIME,
  REG_START_TIME,
  REG_END_TIME,
  EVENT_ACCESS,
  EVENT_NAME,
  EVENT_PAYMENTCODE,
  EVENT_SEASON,
  EVENT_TYPE,
  FACILITY_ADDRESS,
  FACILITY_CITY,
  FACILITY_NAME,
  FACILITY_STATE,
  START_DATE,
  START_TIME,
  REG_START_DATE,
  REG_END_DATE,
} from "../../../Validation/organizer";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import { FACILITY_WEBSITE } from "../../../Validation/club";
import moment from "moment";
import InputMask from "react-input-mask";

const CreateEvent = (props) => {
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isMerchant, setIsMerchant] = useState(false);
  const dispatch = useDispatch();
  const msg = useSelector((state) => state.createSeasonReducer.message);

  const createEventMsg = useSelector((state) => state.createEventReducer);
  const allseason = useSelector((state) => state.getAllSeasonReducer.user);
  const allstate = useSelector((state) => state.getStateReducer.states);
  const allcity = useSelector((state) => state.getCityReducer.citys);
  const allLiability = useSelector(
    (state) => state.getOrgLiabilityReducer.user.data
  );

  const getMerchatReducer = useSelector((state) => state.getMerchantOrgReducer);
  const getMerchatStatusReducer = useSelector(
    (state) => state.getMerchantStatusReducer
  );

  const createCodeMsg = useSelector(
    (state) => state.createPaymentCodeReducer.message
  );
  const getSeason = props.getSeasonHandler;
  const getAllState = props.getAllStateHandler;
  const admin = props.getAdminListHandler;
  const getLiability = props.getOrganizerLiability;
  useEffect(() => {
    getAllState();
    admin();
    getSeason();
    getLiability();
    getPaymentCode();
    props.getPaymentCodeHandler();
    dispatch(getMerchantOrg());
  }, [getAllState, getSeason, getPaymentCode, getLiability]);

  const initialState = {
    start_date: null,
    end_date: null,
    start_time: null,
    end_time: null,
    club_code: "",
    parent_code: "",
    reg_start_date: null,
    reg_end_date: null,
    reg_start_time: null,
    reg_end_time: null,
    event_name: "",
    maxTeam: "",
    maxPlayer: "",
    event_type: "",
    event_access: "",
    payment_Process: 0,
    payment: 0,
    person: "",
    season: "",
    numPlayers: 0,
    eventFull: 0,
    remainingSpots: 0,
    schedual_tab: 0,
    hide_team: 0,
    pass_on_key: 0,
    event_liability: "",
    personal_details_name: "",
    personal_details_email: "",
    personal_details_phone: "",
  };
  const initialError = {
    start_date: false,
    end_date: false,
    start_time: false,
    end_time: false,
    club_code: false,
    parent_code: false,
    reg_start_date: false,
    reg_end_date: false,
    reg_start_time: false,
    reg_end_time: false,
    event_name: false,
    facility_name: false,
    facility_address: false,
    facility_city_name: false,
    facility_state: false,
    facility_website: false,
    maxTeam: false,
    maxPlayer: false,
    event_type: false,
    event_access: false,
    payment: false,
    person: false,
    season: false,
    event_liability: false,
    personal_details_name: false,
    personal_details_email: false,
    personal_details_phone: false,
  };
  const [inputList, setInputList] = useState({
    facility_name: "",
    facility_address: "",
    facility_website: "",
    facility_state: "",
    facility_city_name: "",
  });
  const [state, setState] = useState(initialState);
  const [errors, setErrors] = useState(initialError);
  const [paymentCodeList, setPaymentCodeList] = useState([]);

  const [image, setImage] = useState("");
  const [pitcher, setPitcher] = useState(null);

  useEffect(() => {
    if (props.getAllPayment.success) {
      const data = props.getAllPayment.user;
      setPaymentCodeList(data);
    }
  }, [props.getAllPayment]);

  useEffect(() => {
    if (getMerchatReducer.success) {
      const data = getMerchatReducer.user.data;
      if (data.length == 0) {
        setIsMerchant(false);
        setState((pre) => ({
          ...pre,
          payment_Process: 0,
        }));
      } else {
        dispatch(getMerchantStatus(data[0]?.merchant_key));
      }
    }
  }, [getMerchatReducer]);

  useEffect(() => {
    if (getMerchatStatusReducer.success) {
      setIsMerchant(true);
    }
  }, [getMerchatStatusReducer]);

  // handle input change
  const handleInputChange = (e, i) => {
    const { name, value, id } = e.target;
    setInputList((pre) => ({
      ...pre,
      [name]: value,
    }));

    switch (name) {
      case "facility_state":
        if (value !== "") {
          props.getAllCityHandler({ id: value });
        }
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            facility_state: FACILITY_STATE,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            facility_state: false,
          }));
        }
        break;

      case "facility_city_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            facility_city_name: FACILITY_CITY,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            facility_city_name: false,
          }));
        }
        break;
      case "facility_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            facility_name: FACILITY_NAME,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            facility_name: false,
          }));
        }
        break;
      case "facility_address":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            facility_address: FACILITY_ADDRESS,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            facility_address: false,
          }));
        }
        break;
      case "facility_website":
        const validUrlExpression = new RegExp(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~# &=]*)/g
        );
        if (value == "") {
          errors["facility_website"] = false;
        } else if (!validUrlExpression.test(value)) {
          errors["facility_website"] = "Enter Valid Website";
        } else {
          errors["facility_website"] = false;
        }
        break;
      default:
        break;
    }
  };

  const onChangePicture = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    if (event.target.files && event.target.files[0]) {
      setPitcher({ file: event.target.files[0] });
    }
  };

  const eventHandler = (e) => {
    const { value, name, checked } = e.target;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    if (name == "maxTeam" || name == "maxPlayer") {
      if (value.length <= 5 && !isNaN(value)) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else if (name == "payment_Process") {
      if (isMerchant) {
        setState((prevState) => ({
          ...prevState,
          [name]: !!checked ? 1 : 0,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          [name]: 0,
        }));
        toast.error(
          "To create paid event, you'll need to setup your merchant account."
        );
      }
    } else if (
      name == "remainingSpots" ||
      name == "eventFull" ||
      name == "schedual_tab" ||
      name == "hide_team" ||
      name == "pass_on_key"
    ) {
      setState((prevState) => ({
        ...prevState,
        [name]: !!checked ? 1 : 0,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if ((name == "payment_Process" && value == 0) || name == "event_type") {
      setState((pre) => ({
        ...pre,
        club_code: "",
        parent_code: "",
      }));

      setErrors((pre) => ({
        ...pre,
        club_code: false,
        parent_code: false,
      }));
    }

    switch (name) {
      case "event_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            event_name: EVENT_NAME,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            event_name: false,
          }));
        }
        break;
      case "club_code":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            club_code: "Select Club",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            club_code: false,
          }));
        }
        break;
      case "parent_code":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            parent_code: "Select Parent",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            parent_code: false,
          }));
        }
        break;

      case "start_date":
        if (value == null || value == "") {
          errors["start_date"] = START_DATE;
        } else if (value.toString() == "Invalid Date") {
          errors["start_date"] = "Enter Valid Date";
        } else if (
          moment(value).format("MM-DD-YYYY") <= moment().format("MM-DD-YYYY")
        ) {
          errors["start_date"] = "Date should not be before minimal date";
        } else {
          errors["start_date"] = false;
        }
        break;

      case "end_date":
        // setstate.end_date(value);
        if (value == null || value == "") {
          errors["end_date"] = END_DATE;
        } else if (value.toString() == "Invalid Date") {
          errors["end_date"] = "Enter Valid Date";
        } else if (
          moment(value).format("MM-DD-YYYY") <
          moment(state.start_date).format("MM-DD-YYYY")
        ) {
          errors["end_date"] = "End date should be greter than start date";
        } else {
          errors["end_date"] = false;
        }
        break;

      case "reg_start_date":
        if (value == null && value == "") {
          errors["reg_start_date"] = REG_START_DATE;
        } else if (value.toString() == "Invalid Date") {
          errors["reg_start_date"] = "Enter Valid Date";
        } else if (
          state.start_date !== null && state.start_date !== ""
            ? moment(value).format("MM-DD-YYYY") >
              moment(state.start_date).format("MM-DD-YYYY")
            : false
        ) {
          errors["reg_start_date"] = "Enter Valid Registration start date";
        } else if (
          moment(value).format("MM-DD-YYYY") < moment().format("MM-DD-YYYY")
        ) {
          errors["reg_start_date"] = "Enter Valid Registration start date";
        } else {
          errors["reg_start_date"] = false;
        }
        break;
      case "reg_end_date":
        if (value == null && value == "") {
          errors["reg_end_date"] = REG_END_DATE;
        } else if (value.toString() == "Invalid Date") {
          errors["reg_end_date"] = "Enter Valid Date";
        } else if (
          state.end_date !== null && state.end_date !== ""
            ? moment(value).format("MM-DD-YYYY") >
              moment(state.end_date).format("MM-DD-YYYY")
            : false
        ) {
          errors["reg_end_date"] = "Enter Valid Registration end date";
        } else if (
          moment(value).format("MM-DD-YYYY") <
          moment(state.reg_start_date).format("MM-DD-YYYY")
        ) {
          errors["reg_end_date"] = "Enter Valid Registration end date";
        } else {
          errors["reg_end_date"] = false;
        }
        break;

      case "start_time":
        if (value == null && value == "") {
          errors["start_time"] = START_TIME;
        } else {
          errors["start_time"] = false;
        }
        break;
      case "end_time":
        if (value !== null && value.length < 0) {
          setErrors((prevState) => ({
            ...prevState,
            end_time: END_TIME,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            end_time: false,
          }));
        }
        break;

      case "reg_start_time":
        if (value !== null && value.length < 0) {
          setErrors((prevState) => ({
            ...prevState,
            reg_start_time: REG_START_TIME,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            reg_start_time: false,
          }));
        }
        break;
      case "reg_end_time":
        if (value !== null && value.length < 0) {
          setErrors((prevState) => ({
            ...prevState,
            reg_end_time: REG_END_TIME,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            reg_end_time: false,
          }));
        }
        break;
      case "event_type":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            event_type: EVENT_TYPE,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            event_type: false,
          }));
        }
        break;
      case "season":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            season: EVENT_SEASON,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            season: false,
          }));
        }
        break;
      case "event_access":
        if (value.length == 0 || value == "") {
          setErrors((prevState) => ({
            ...prevState,
            event_access: EVENT_ACCESS,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            event_access: false,
          }));
        }
        break;
      case "event_liability":
        if (value.length == 0 || value === "") {
          setErrors((prevState) => ({
            ...prevState,
            event_liability: EVENT_ACCESS,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            event_liability: false,
          }));
        }
        break;
      case "payment":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            payment: EVENT_PAYMENTCODE,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            payment: false,
          }));
        }
        break;
      case "person":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            person: "Please select contact person",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            person: false,
          }));
        }
        break;

      case "personal_details_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            personal_details_name: "Name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            personal_details_name: false,
          }));
        }
        break;
      case "personal_details_email":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            personal_details_email: "Email can not be empty",
          }));
        } else if (!validEmailRegex.test(value)) {
          setErrors((prevState) => ({
            ...prevState,
            personal_details_email: "Email is not valid !",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            personal_details_email: false,
          }));
        }
        break;
      case "personal_details_phone":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            personal_details_phone: "Phone can not be empty",
          }));
        } else if (value.length < 12) {
          setErrors((prevState) => ({
            ...prevState,
            personal_details_phone: "Phone number is not valid !",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            personal_details_phone: false,
          }));
        }
        break;
      default:
        break;
    }
  };

  const saveData = (e) => {
    const formData = new FormData();
    formData.append("file", image);
    if (state.event_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        event_name: EVENT_NAME,
      }));
    }
    if (state.event_type == "") {
      setErrors((prevState) => ({
        ...prevState,
        event_type: EVENT_TYPE,
      }));
    }

    if (state.start_date == null || state.start_date === "") {
      setErrors((prevState) => ({
        ...prevState,
        start_date: START_DATE,
      }));
    }
    if (state.end_date == null || state.end_date === "") {
      setErrors((prevState) => ({
        ...prevState,
        end_date: END_DATE,
      }));
    }
    if (state.start_time == null || state.start_time === "") {
      setErrors((prevState) => ({
        ...prevState,
        start_time: START_DATE,
      }));
    }
    if (state.end_time == null || state.end_time === "") {
      setErrors((prevState) => ({
        ...prevState,
        end_time: END_DATE,
      }));
    }
    if (state.reg_start_date == null || state.reg_start_date === "") {
      setErrors((prevState) => ({
        ...prevState,
        reg_start_date: START_DATE,
      }));
    }
    if (state.reg_end_date == null || state.reg_end_date === "") {
      setErrors((prevState) => ({
        ...prevState,
        reg_end_date: END_DATE,
      }));
    }
    if (state.reg_start_time == null || state.reg_start_time === "") {
      setErrors((prevState) => ({
        ...prevState,
        reg_start_time: START_DATE,
      }));
    }
    if (state.reg_end_time == null || state.reg_end_time === "") {
      setErrors((prevState) => ({
        ...prevState,
        reg_end_time: END_DATE,
      }));
    }

    if (inputList.facility_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        facility_name: FACILITY_NAME,
      }));
    }

    if (inputList.facility_address === "") {
      setErrors((prevState) => ({
        ...prevState,
        facility_address: FACILITY_ADDRESS,
      }));
    }
    if (state.event_type === "") {
      setErrors((prevState) => ({
        ...prevState,
        event_type: EVENT_TYPE,
      }));
    }
    if (state.season === "") {
      setErrors((prevState) => ({
        ...prevState,
        season: EVENT_SEASON,
      }));
    }
    if (state.event_access === "") {
      setErrors((prevState) => ({
        ...prevState,
        event_access: EVENT_ACCESS,
      }));
    }
    if (state.event_liability === "") {
      setErrors((prevState) => ({
        ...prevState,
        event_liability: EVENT_ACCESS,
      }));
    }
    if (state.payment === "") {
      setErrors((prevState) => ({
        ...prevState,
        payment: EVENT_PAYMENTCODE,
      }));
    }

    if (inputList.facility_city_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        facility_city_name: FACILITY_CITY,
      }));
    }
    if (inputList.facility_state === "") {
      setErrors((prevState) => ({
        ...prevState,
        facility_state: FACILITY_STATE,
      }));
    }
    if (
      !!state.payment_Process &&
      (state.event_type == "0" || state.event_type == "2")
    ) {
      if (state.club_code == "") {
        setErrors((prevState) => ({
          ...prevState,
          club_code: "Select Club Code",
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          club_code: false,
        }));
      }
    } else {
      setErrors((prevState) => ({
        ...prevState,
        club_code: false,
      }));
    }
    if (
      !!state.payment_Process &&
      (state.event_type == "1" || state.event_type == "2")
    ) {
      if (state.parent_code == "") {
        setErrors((prevState) => ({
          ...prevState,
          parent_code: "Select Parent Code",
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          parent_code: false,
        }));
      }
    } else {
      setErrors((prevState) => ({
        ...prevState,
        parent_code: false,
      }));
    }

    if (state.personal_details_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        personal_details_name: "Name can not be Empty",
      }));
    }
    if (state.personal_details_email == "") {
      setErrors((prevState) => ({
        ...prevState,
        personal_details_email: "Email can not be Empty",
      }));
    }
    if (state.personal_details_phone == "") {
      setErrors((prevState) => ({
        ...prevState,
        personal_details_phone: "Phone number can not be Empty",
      }));
    }

    const validEndTime = () => {
      if (state.end_time == null || state.end_time == "") {
        return false;
      } else {
        if (
          state.start_date != null &&
          state.start_date != "" &&
          state.start_time != null &&
          state.start_time != "" &&
          state.end_date != null &&
          state.end_date != "" &&
          state.end_time != null &&
          state.end_time != ""
        ) {
          const startDateTime = moment(
            `${moment(state.start_date).format("MM-DD-YYYY")} ${moment(
              state.start_time
            ).format("HH:mm")}`,
            "MM-DD-YYYY HH:mm"
          );
          const endDateTime = moment(
            `${moment(state.end_date).format("MM-DD-YYYY")} ${moment(
              state.end_time
            ).format("HH:mm")}`,
            "MM-DD-YYYY HH:mm"
          );
          if (
            moment(startDateTime, "MM-DD-YYYY HH:mm") <=
            moment(endDateTime, "MM-DD-YYYY HH:ss")
          ) {
            return true;
          } else {
            setErrors((pre) => ({
              ...pre,
              end_date: "End date should be greter than start date",
              end_time: "End time should be greter than start time",
            }));
            return false;
          }
        }
      }
    };
    const validRegEndTime = () => {
      if (state.reg_end_time == null || state.reg_end_time == "") {
        return false;
      } else {
        if (
          state.reg_start_date != null &&
          state.reg_start_date != "" &&
          state.reg_start_time != null &&
          state.reg_start_time != "" &&
          state.reg_end_date != null &&
          state.reg_end_date != "" &&
          state.reg_end_time != null &&
          state.reg_end_time != ""
        ) {
          const startDateTime = moment(
            `${moment(state.reg_start_date).format("MM-DD-YYYY")} ${moment(
              state.reg_start_time
            ).format("HH:mm")}`,
            "MM-DD-YYYY HH:mm"
          );
          const endDateTime = moment(
            `${moment(state.reg_end_date).format("MM-DD-YYYY")} ${moment(
              state.reg_end_time
            ).format("HH:mm")}`,
            "MM-DD-YYYY HH:mm"
          );
          if (
            moment(startDateTime, "MM-DD-YYYY HH:mm") <=
            moment(endDateTime, "MM-DD-YYYY HH:ss")
          ) {
            return true;
          } else {
            setErrors((pre) => ({
              ...pre,
              reg_end_date:
                "Registration end date should be greter than start date",
              reg_end_time:
                "Registration end time should be greter than start time",
            }));
            return false;
          }
        }
      }
    };
    if (
      state.event_name !== "" &&
      state.event_type != "" &&
      inputList.facility_name !== "" &&
      inputList.facility_address !== "" &&
      state.event_access !== "" &&
      state.event_liability !== "" &&
      inputList.facility_city_name !== "" &&
      inputList.facility_state !== "" &&
      !errors.facility_website &&
      !errors.club_code &&
      !errors.parent_code &&
      state.personal_details_name !== "" &&
      state.personal_details_email !== "" &&
      state.personal_details_email !== "" &&
      !errors.personal_details_email &&
      !errors.personal_details_phone &&
      validEndTime() &&
      validRegEndTime()
    ) {
      const newEventArr = {
        event_name: state.event_name,
        event_type: state.event_type,
        event_season: state.season,
        event_start_date: moment(state.start_date).format("MM-DD-YYYY"),
        event_end_date: moment(state.end_date).format("MM-DD-YYYY"),
        registration_start_date: moment(state.reg_start_date).format(
          "MM-DD-YYYY"
        ),
        registration_end_date: moment(state.reg_end_date).format("MM-DD-YYYY"),
        event_start_time: moment(state.start_time).format("HH:mm"),
        event_end_time: moment(state.end_time).format("HH:mm"),
        registration_start_time: moment(state.reg_start_time).format("HH:mm"),
        registration_end_time: moment(state.reg_end_time).format("HH:mm"),
        event_access: state.event_access,
        event_payment_code: !!state.payment_Process
          ? state.event_type == "0"
            ? [state.club_code]
            : state.event_type == "1"
            ? [state.parent_code]
            : state.event_type == "2"
            ? [state.club_code, state.parent_code]
            : []
          : [],

        event_max_team: state.maxTeam !== "" ? state.maxTeam : "null",
        event_max_player: state.maxPlayer !== "" ? state.maxPlayer : "null",
        show_number_of_player: state.numPlayers,
        show_remaining_spots: state.remainingSpots,
        show_event_is_full: state.eventFull,
        show_schedule: state.schedual_tab,
        show_team: state.hide_team,
        is_paid_event: state.payment_Process,
        liability: state.event_liability,
        // is_publish:
        event_facility_info: [{ ...inputList }],
        personal_detail: {
          name: state.personal_details_name,
          email: state.personal_details_email,
          phone: state.personal_details_phone,
        },
        ...(state.pass_on_key == 1 && { pass_on_key: 3.90 }),
      };
      props.createEventHandler(newEventArr);
    }
  };

  useEffect(() => {
    if (props.allseason?.success) {
      setLoading(false);
    }
  }, [props.allseason]);

  useEffect(() => {
    if (loading == false) {
      window.select2();
    }
  }, [loading]);

  useEffect(() => {
    if (props.submit?.success) {
      notify(msg.message);
      props.resetCreateSeasonReducer();
    }
  }, [props.submit]);
  useEffect(() => {
    if (props.createCode?.success) {
      notify(createCodeMsg.message);
      props.resetcreatePaymentCodeReducer();
      // history.push(`/event-details/${id}`);
    }
  }, [props.createCode]);

  useEffect(() => {
    if (props.saveData?.success) {
      if (image !== "") {
        props.sendImageHandler({
          event_image: pitcher.file,
          event_id: createEventMsg.event_id,
        });
        history.push("/events");
      } else {
        history.push("/events");
      }
    }
  }, [props.saveData]);

  const notify = (msg) => toast.success(msg);
  useEffect(() => {
    if (props.saveData?.success) {
      history.push("/events");
    }
  }, [props.saveData]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>
          {!loading && allseason ? (
            <>
              <div className="">
                <div className="create-team-head-event">
                  <h5 className="dash-head mb-0">Create Event</h5>
                  <button
                    className="btn-save "
                    disabled={props.saveData.loading}
                    onClick={saveData}
                  >
                    Save
                  </button>
                </div>
                <div className="mt-30">
                  <strong>Basic Info</strong>
                </div>
              </div>

              {/* Begin:: Create event form */}
              <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-10 px-0">
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    type="file"
                    className="input-file file-upload"
                    id="file-upload"
                    onChange={onChangePicture}
                  />
                  <label className="change-pic" htmlFor="file-upload">
                    <img
                      src={image == "" ? "./images/pch-images/pch.svg" : image}
                      className="img-fluid"
                    />
                  </label>
                  <span className="ml-12">Upload Picture</span>
                </div>

                <div className="custom-control custom-switch mt-4">
                  <input
                    type="checkbox"
                    className="custom-control-input "
                    id="paymentProcessing"
                    name="payment_Process"
                    value={state.payment_Process}
                    checked={state.payment_Process}
                    onChange={eventHandler}
                  />
                  <label
                    className="custom-control-label payment"
                    htmlFor="paymentProcessing"
                  >
                    Payment Processing
                  </label>
                </div>

                <div className="row px-0 mx-0">
                  <div className="col-md-6 mt-18">
                    <input
                      type="text"
                      className={classNames("form-control team-name", {
                        errorBorder: errors.event_name,
                      })}
                      placeholder="Event Name *"
                      id="event_name"
                      value={state.event_name}
                      name="event_name"
                      onChange={eventHandler}
                    />
                    {errors.event_name && (
                      <span className="text-red small d-block">
                        * {errors.event_name}
                      </span>
                    )}
                  </div>

                  <div className="col-md-6 mt-18">
                    <div className="form-group event-drp">
                      <select
                        className={classNames(
                          "form-control team-name payterm",
                          { errorBorder: errors.event_type }
                        )}
                        id="event_type"
                        name="event_type"
                        value={state.event_type}
                        onChange={eventHandler}
                      >
                        <option value="" disabled>
                          Event Type *
                        </option>
                        <option value="0">Club only</option>
                        <option value="1">Parent/player only</option>
                        <option value="2">Club & Parent/player</option>
                      </select>
                      {errors.event_type && (
                        <span className="text-red small d-block">
                          * {errors.event_type}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mt-18">
                    <select
                      className="form-control team-name payterm"
                      id="season"
                      name="season"
                      value={state.season}
                      onChange={eventHandler}
                    >
                      <option value="">Season</option>
                      {allseason.map((v, i) => {
                        return (
                          <option key={i} value={v._id}>
                            {v.season_name}
                          </option>
                        );
                      })}
                    </select>
                    {/* {errors.season && <span className='text-red small d-block'>* {errors.season}</span>} */}
                    {allseason ? (
                      <>
                        <div className="text-right add-more mt-2">
                          <Link
                            to="/createseason"
                            className="text-decoration-none"
                          >
                            + Create Season
                          </Link>
                        </div>
                      </>
                    ) : null}
                    {/* //-----------=====-----------Event Season End-----------====----------- */}
                  </div>

                  {/*  payment code */}

                  {state.payment_Process === 1 && (
                    <div className="col-md-12 mt-18">
                      <div>
                        <strong>Payment Code</strong>
                      </div>
                      {(state.event_type == "0" || state.event_type == "2") && (
                        <div className="mt-2">
                          <select
                            className={classNames("form-control team-name", {
                              errorBorder: errors.club_code,
                            })}
                            name="club_code"
                            value={state.club_code}
                            onChange={eventHandler}
                          >
                            <option value="" disabled>
                              Club Code *
                            </option>
                            {paymentCodeList
                              .filter(
                                (e) =>
                                  e.type == "0" && e.paymentcode_type == "0"
                              )
                              .map((v, i) => {
                                return (
                                  <option key={i} value={v._id}>
                                    {v.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      )}
                      {(state.event_type == "1" || state.event_type == "2") && (
                        <div className="mt-2">
                          <select
                            className={classNames("form-control team-name", {
                              errorBorder: errors.parent_code,
                            })}
                            name="parent_code"
                            value={state.parent_code}
                            onChange={eventHandler}
                          >
                            <option value="" disabled>
                              Parent Code *
                            </option>
                            {paymentCodeList
                              .filter(
                                (e) =>
                                  e.type == "0" && e.paymentcode_type == "1"
                              )
                              .map((v, i) => {
                                return (
                                  <option key={i} value={v._id}>
                                    {v.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      )}
                      {state.event_type == "" && (
                        <div className="my-2 text-danger">
                          Please Select Event Type *
                        </div>
                      )}

                      <div className="text-right add-more mt-2">
                        <Link
                          to="/create-payment-code"
                          className="text-decoration-none"
                        >
                          + Create Payment Code
                        </Link>
                      </div>
                    </div>
                  )}
                  {/* //-----------=====-----------Event Date/Time start-----------====----------- */}
                  <div className="row mx-0">
                    {/* Event start date */}
                    <div className="col-md-6 mt-18">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          value={state.start_date}
                          clearable
                          placeholder="Start Date*"
                          onChange={(e, a) => {
                            eventHandler({
                              target: {
                                value: e,
                                name: "start_date",
                                id: "start_date",
                              },
                            });
                          }}
                          minDate={moment().add(1, "days")}
                          format="MM/dd/yyyy"
                          mask="__/__/____"
                          inputVariant="outlined"
                          autoOk={true}
                          error={Boolean(errors.start_date)}
                          helperText={""}
                          InputAdornmentProps={{ position: "end" }}
                          className={classNames("form-control team-name", {
                            errorBorder: errors.start_date,
                          })}
                        />
                      </MuiPickersUtilsProvider>
                      {errors.start_date && (
                        <span className="text-red small d-block mt-2">
                          * {errors.start_date}
                        </span>
                      )}
                    </div>

                    {/*  event start time */}
                    <div className="col-md-6 mt-18">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                          autoOk
                          ampm={false}
                          value={state.start_time}
                          placeholder="Start Time*"
                          clearable
                          onChange={(e) => {
                            eventHandler({
                              target: {
                                value: e,
                                name: "start_time",
                                id: "start_time",
                              },
                            });
                          }}
                          InputAdornmentProps={{ position: "end" }}
                          inputVariant="outlined"
                          error={Boolean(errors.start_time)}
                          helperText={""}
                          className={classNames("form-control team-name", {
                            errorBorder: errors.start_time,
                          })}
                        />
                      </MuiPickersUtilsProvider>
                      {errors.start_time && (
                        <span className="text-red small d-block mt-2">
                          * {errors.start_time}
                        </span>
                      )}
                    </div>

                    {/* Event end date */}
                    <div className="col-md-6 mt-18">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          value={state.end_date}
                          clearable
                          placeholder="End Date*"
                          onChange={(e) =>
                            eventHandler({
                              target: {
                                value: e,
                                name: "end_date",
                                id: "end_date",
                              },
                            })
                          }
                          error={Boolean(errors.start_date)}
                          helperText={""}
                          minDate={
                            state.start_date !== null && !errors.start_date
                              ? new Date(state.start_date)
                              : new Date()
                          }
                          format="MM/dd/yyyy"
                          mask="__/__/____"
                          inputVariant="outlined"
                          autoOk={true}
                          InputAdornmentProps={{ position: "end" }}
                          className={classNames("form-control team-name", {
                            errorBorder: errors.end_date,
                          })}
                        />
                      </MuiPickersUtilsProvider>
                      {errors.end_date && (
                        <span className="text-red small d-block mt-2">
                          * {errors.end_date}
                        </span>
                      )}
                    </div>

                    {/*  event end time */}

                    <div className="col-md-6 mt-18">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                          autoOk
                          ampm={false}
                          value={state.end_time}
                          placeholder="End Time*"
                          clearable
                          onChange={(e) =>
                            eventHandler({
                              target: {
                                value: e,
                                name: "end_time",
                                id: "end_time",
                              },
                            })
                          }
                          InputAdornmentProps={{ position: "end" }}
                          inputVariant="outlined"
                          error={Boolean(errors.end_time)}
                          helperText={""}
                          className={classNames("form-control team-name", {
                            errorBorder: errors.end_time,
                          })}
                        />
                      </MuiPickersUtilsProvider>
                      {errors.end_time && (
                        <span className="text-red small d-block mt-2">
                          * {errors.end_time}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* Registration start date */}
                  <div className="col-md-6 mt-18">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        value={state.reg_start_date}
                        clearable
                        placeholder="Registration start Date*"
                        minDate={new Date()}
                        {...(state.start_date !== null && {
                          maxDate: new Date(state.start_date),
                        })}
                        format="MM/dd/yyyy"
                        mask="__/__/____"
                        inputVariant="outlined"
                        autoOk={true}
                        InputAdornmentProps={{ position: "end" }}
                        onChange={(e) =>
                          eventHandler({
                            target: {
                              value: e,
                              name: "reg_start_date",
                              id: "reg_start_date",
                            },
                          })
                        }
                        error={Boolean(errors.reg_start_date)}
                        helperText={""}
                        className={classNames("form-control team-name", {
                          errorBorder: errors.reg_start_date,
                        })}
                      />
                    </MuiPickersUtilsProvider>
                    {errors.reg_start_date && (
                      <span className="text-red small d-block mt-2">
                        * {errors.reg_start_date}
                      </span>
                    )}
                  </div>

                  {/*  registration start time */}
                  <div className="col-md-6 mt-18">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        autoOk
                        ampm={false}
                        value={state.reg_start_time}
                        placeholder="Start Time*"
                        clearable
                        onChange={(e) =>
                          eventHandler({
                            target: {
                              value: e,
                              name: "reg_start_time",
                              id: "reg_start_time",
                            },
                          })
                        }
                        InputAdornmentProps={{ position: "end" }}
                        inputVariant="outlined"
                        error={Boolean(errors.reg_start_time)}
                        helperText={""}
                        className={classNames("form-control team-name", {
                          errorBorder: errors.reg_start_time,
                        })}
                      />
                    </MuiPickersUtilsProvider>
                    {errors.reg_start_time && (
                      <span className="text-red small d-block mt-2">
                        * {errors.reg_start_time}
                      </span>
                    )}
                  </div>

                  {/* Registration end date */}
                  <div className="col-md-6 mt-18">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        value={state.reg_end_date}
                        clearable
                        placeholder="Registration End Date*"
                        minDate={
                          state.reg_start_date !== null
                            ? new Date(state.reg_start_date)
                            : new Date()
                        }
                        {...(state.end_date !== null && {
                          maxDate: new Date(state.end_date),
                        })}
                        format="MM/dd/yyyy"
                        mask="__/__/____"
                        inputVariant="outlined"
                        autoOk={true}
                        InputAdornmentProps={{ position: "end" }}
                        onChange={(e) =>
                          eventHandler({
                            target: {
                              value: e,
                              name: "reg_end_date",
                              id: "reg_end_date",
                            },
                          })
                        }
                        error={Boolean(errors.reg_end_date)}
                        helperText={""}
                        className={classNames("form-control team-name", {
                          errorBorder: errors.reg_end_date,
                        })}
                      />
                    </MuiPickersUtilsProvider>
                    {errors.reg_end_date && (
                      <span className="text-red small d-block mt-2">
                        * {errors.reg_end_date}
                      </span>
                    )}
                  </div>

                  {/*  registration end time */}

                  <div className="col-md-6 mt-18">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        autoOk
                        ampm={false}
                        value={state.reg_end_time}
                        placeholder="End Time*"
                        clearable
                        onChange={(e) =>
                          eventHandler({
                            target: {
                              value: e,
                              name: "reg_end_time",
                              id: "reg_end_time",
                            },
                          })
                        }
                        InputAdornmentProps={{ position: "end" }}
                        inputVariant="outlined"
                        error={Boolean(errors.reg_end_time)}
                        helperText={""}
                        className={classNames("form-control team-name", {
                          errorBorder: errors.reg_end_time,
                        })}
                      />
                    </MuiPickersUtilsProvider>
                    {errors.reg_end_time && (
                      <span className="text-red small d-block mt-2">
                        * {errors.reg_end_time}
                      </span>
                    )}
                  </div>
                </div>

                {/* //-----------=====-----------Facility Info Start-----------====----------- */}
                <div className="mt-42">
                  <strong>Facility Info</strong>
                  <div>
                    <div className="row mx-0">
                      <div className="col-md-6 mt-18">
                        <input
                          type="text"
                          className={classNames("form-control team-name", {
                            errorBorder: errors.facility_name,
                          })}
                          placeholder="Facility Name *"
                          id="facility_name"
                          name="facility_name"
                          value={inputList.facility_name}
                          onChange={handleInputChange}
                        />
                        {errors.facility_name && (
                          <span className="text-red small d-block">
                            * {errors.facility_name}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6 mt-18">
                        <input
                          type="text"
                          className={classNames("form-control team-name", {
                            errorBorder: errors.facility_website,
                          })}
                          placeholder="Website "
                          id="facility_website"
                          value={inputList.facility_website}
                          name="facility_website"
                          onChange={handleInputChange}
                        />
                        {errors.facility_website && (
                          <span className="text-red small d-block">
                            {" "}
                            {errors.facility_website}
                          </span>
                        )}
                      </div>

                      <div className="col-md-12 mt-18">
                        <input
                          type="text"
                          className={classNames("form-control team-name", {
                            errorBorder: errors.facility_address,
                          })}
                          placeholder=" Facility Address *"
                          id="facility_address"
                          name="facility_address"
                          value={inputList.facility_address}
                          onChange={handleInputChange}
                        />
                        {errors.facility_address && (
                          <span className="text-red small d-block">
                            * {errors.facility_address}
                          </span>
                        )}
                      </div>

                      <div className="col-md-6 mt-18">
                        <select
                          className={classNames("form-control team-name", {
                            errorBorder: errors.facility_state,
                          })}
                          placeholder="State *"
                          id="facility_state"
                          name="facility_state"
                          // value={state.state}
                          value={inputList.facility_state}
                          // onSelect={getstatecity(x.state)}
                          onChange={handleInputChange}
                        >
                          <option value="">State</option>
                          {allstate.map((v, i) => {
                            return (
                              <option key={i} value={v._id}>
                                {v.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.facility_state && (
                          <span className="text-red small d-block">
                            * {errors.facility_state}
                          </span>
                        )}
                      </div>

                      <div className="col-md-6 mt-18">
                        <input
                          type="text"
                          list="cityNameList"
                          placeholder="City"
                          name="facility_city_name"
                          className={classNames("form-control team-name", {
                            errorBorder: errors.facility_city_name,
                          })}
                          value={inputList.facility_city_name}
                          onChange={handleInputChange}
                        />
                        {errors.facility_city_name && (
                          <span className="text-red small d-block">
                            * {errors.facility_city_name}
                          </span>
                        )}
                        <datalist id="cityNameList">
                          {allcity.map((e, i) => {
                            return <option value={e.name} key={i} />;
                          })}
                        </datalist>
                      </div>

                      <hr />
                    </div>
                  </div>
                </div>
                {/* //-----------=====-----------Facility Info End-----------====----------- */}

                {/* //-----------=====-----------Contact Person Start-----------====----------- */}
                <div className="mt-42">
                  <strong>Contact Person</strong>
                  <div className="row px-0 mx-0">
                    <div className="col-md-6 mt-18">
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          errorBorder: errors.personal_details_name,
                        })}
                        placeholder="Contact Person Name *"
                        id="personal_details_name"
                        value={state.personal_details_name}
                        name="personal_details_name"
                        onChange={eventHandler}
                      />
                      {errors.personal_details_name && (
                        <span className="text-red small d-block">
                          * {errors.personal_details_name}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 mt-18">
                      <input
                        type="email"
                        className={classNames("form-control team-name", {
                          errorBorder: errors.personal_details_email,
                        })}
                        placeholder="Contact Person Email *"
                        id="personal_details_email"
                        value={state.personal_details_email}
                        name="personal_details_email"
                        onChange={eventHandler}
                      />
                      {errors.personal_details_email && (
                        <span className="text-red small d-block">
                          * {errors.personal_details_email}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 mt-18">
                      <InputMask
                        mask="999-999-9999"
                        className={classNames("form-control team-name", {
                          errorBorder: errors.personal_details_phone,
                        })}
                        value={state.personal_details_phone}
                        onChange={eventHandler}
                        maskChar={""}
                        placeholder="Contact Person Phone *"
                        name="personal_details_phone"
                        id="personal_details_phone"
                      />
                      {errors.personal_details_phone && (
                        <span className="text-red small d-block">
                          * {errors.personal_details_phone}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* //-----------=====-----------Contact Person End-----------====----------- */}

                {/* ------------------------------teams start ------------------------------ */}
                {(state.event_type == "0" || state.event_type == "2") && (
                  <div className="mt-42">
                    <strong>Teams</strong>
                    <div className="row mx-0">
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          className={classNames("form-control team-name", {
                            errorBorder: errors.maxTeam,
                          })}
                          placeholder="Max Team"
                          value={state.maxTeam}
                          name="maxTeam"
                          onChange={eventHandler}
                        />
                        {errors.maxTeam && (
                          <span className="text-red small d-block">
                            * {errors.maxTeam}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/* ------------------------------teams end ------------------------------ */}

                {/* ------------------------------player start ------------------------------ */}
                {(state.event_type == "1" || state.event_type == "2") && (
                  <div className="mt-42">
                    <strong>Players</strong>
                    <div className="row mx-0">
                      <div className="col-md-6 mt-2">
                        <input
                          type="text"
                          className={classNames("form-control team-name", {
                            errorBorder: errors.maxPlayer,
                          })}
                          placeholder="Max Player"
                          value={state.maxPlayer}
                          name="maxPlayer"
                          onChange={eventHandler}
                        />
                        {errors.maxPlayer && (
                          <span className="text-red small d-block">
                            * {errors.maxPlayer}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/* ------------------------------plater end ------------------------------ */}

                {/* Begin:: Switches */}
                <div className="mt-42">
                  <div className="row mx-0">
                    <div className="col-md-6">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input payment "
                          id="customSwitch2"
                          name="remainingSpots"
                          value={state.remainingSpots}
                          onChange={eventHandler}
                          checked={state.remainingSpots}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitch2"
                        >
                          Show number of remaining spots
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input payment "
                          id="customSwitch3"
                          name="eventFull"
                          value={state.eventFull}
                          onChange={eventHandler}
                          checked={state.eventFull}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitch3"
                        >
                          Show when event is full
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End:: Switches */}

                {/* Begin:: Payment code */}

                {/* Begin:: Event Access  */}
                <div className="mt-42">
                  <strong>Event Access</strong>

                  <div className="row mx-0">
                    <div className=" col-12 mt-2">
                      <select
                        className={classNames(
                          "form-control team-name payterm",
                          {
                            errorBorder: errors.event_access,
                          }
                        )}
                        id="event_access"
                        name="event_access"
                        value={state.event_access}
                        onChange={eventHandler}
                      >
                        <option value="" disabled>
                          Event Access Type *
                        </option>
                        <option value="0">Public </option>
                        <option value="1">Private </option>
                      </select>
                      {errors.event_access && (
                        <span className="text-red small d-block">
                          * {errors.event_access}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* End:: Event Access  */}

                {/* Begin:: Switches */}
                <div className="mt-42">
                  <div className="row mx-0">
                    <div className="col-md-6">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input payment "
                          id="schTab"
                          name="schedual_tab"
                          value={state.schedual_tab}
                          checked={state.schedual_tab}
                          onChange={eventHandler}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="schTab"
                        >
                          Show the scheduale tab
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input payment"
                          id="hideTeam"
                          name="hide_team"
                          value={state.hide_team}
                          checked={state.hide_team}
                          onChange={eventHandler}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="hideTeam"
                        >
                          Hide Teams
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End:: Switches */}
                <div className="mt-42">
                  <strong>Pass On The Fees</strong>
                  <div className="custom-control custom-switch mt-2">
                    <input
                      type="checkbox"
                      className="custom-control-input payment "
                      id="pass_on_key"
                      name="pass_on_key"
                      value={state.pass_on_key}
                      onChange={eventHandler}
                      checked={state.pass_on_key}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="pass_on_key"
                    >
                      Pass On The Fees
                    </label>
                  </div>
                </div>

                <div className=" mt-42">
                  <strong>Liability Waiver</strong>
                  <div className="row mx-0">
                    <div className="col-12 mt-2">
                      <select
                        className={classNames(
                          "form-control team-name payterm",
                          {
                            errorBorder: errors.event_liability,
                          }
                        )}
                        id="event_liability"
                        name="event_liability"
                        value={state.event_liability}
                        onChange={eventHandler}
                      >
                        <option value="" disabled>
                          Liability *
                        </option>
                        {allLiability?.map((v, i) => {
                          return (
                            <option value={v._id} key={i}>
                              {v.title}
                            </option>
                          );
                        })}
                      </select>
                      {errors.event_liability && (
                        <span className="text-red small d-block">
                          * {errors.event_liability}
                        </span>
                      )}
                    </div>
                    <div
                      className="add-more text-right"
                      style={{ marginTop: "14px" }}
                    >
                      <Link
                        to="/createliability"
                        className="text-decoration-none"
                      >
                        + Create Liability
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* End:: Create event form */}
            </>
          ) : null}
          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};
//Connection between component to store
const mapStateToProps = (state) => ({
  getAllPayment: state.getAllPaymentCodeReducer,
  saveData: state.createEventReducer,
  allseason: state.getAllSeasonReducer,
  allLiability: state.getOrgLiabilityReducer,
  submit: state.createSeasonReducer,
  adminRes: state.adminReducer,
  createCode: state.createPaymentCodeReducer,
});
const mapDispatchToProps = (dispatch) => ({
  createEventHandler: (user) => dispatch(saveEventData(user)),
  getPaymentCodeHandler: (user) => dispatch(getAllPaymentCode(user)),
  getSeasonHandler: (user) => dispatch(getAllSeason(user)),
  getOrganizerLiability: (user) => dispatch(getOrgLiability(user)),
  getAllStateHandler: (user) => dispatch(getAllState()),
  getAllCityHandler: (user) => dispatch(getAllCity(user)),
  resetCreateEventReducer: () => dispatch({ type: RESET_CREATE_EVENT }),
  resetgetAllSeasonHandler: () => dispatch({ type: RESET_GET_ALL_SEASON }),
  resetCreateSeasonReducer: () => dispatch({ type: RESET_CREATE_SEASON }),
  resetcreatePaymentCodeReducer: () =>
    dispatch({ type: RESET_CREATE_PAYMENT_CODE }),
  sendImageHandler: (user) => dispatch(sendImage(user)),
  getAdminListHandler: () => dispatch(getAdmin()),
  resetgetPaymentCodeHandler: () => dispatch({ type: RESET_GET_PAYMENTCODE }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateEvent));
