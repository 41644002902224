import { GET_ALLDIVISION_LOADING, GET_ALLDIVISION_SUCCESS, GET_ALLDIVISION_ERROR } from "../../constants"

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {}
};

export default function getAllDivisionReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALLDIVISION_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, loading : false , success: false };

    case GET_ALLDIVISION_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };
     
    case GET_ALLDIVISION_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}


