import moment from "moment";
import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const MobileEventList = ({ v, i, bookmarkHandler }) => {
  return (
    <>
      <div className="d-flex justify-content-between reg-details" key={i}>
        <div className="d-flex">
          <div className="dashboard-img mr-3">
            <img
              src={
                v.event_image !== ""
                  ? v.event_image
                  : "./images/pch-images/pch.svg"
              }
            />
          </div>
          <div className="reg-content">
            <Link
              className="text-decoration-none text-black fw-m"
              to={
                v.type === "club"
                  ? `/event-details/${v._id}`
                  : `/events/${v._id}`
              }
            >
              {v.event_name}
            </Link>
            {v.is_bookmarked === true ? (
              <span className="ml-2">
                <i className="fas fa-flag m-1" style={{ color: "#DB1F26" }}></i>
              </span>
            ) : (
              <></>
            )}
            <span className="d-block text-gray mt-2">
              Event Date:
              <span className="py-1 ml-2">
                <Moment
                  date={new Date(v.event_start_date)}
                  format="MM/DD/YYYY"
                />
              </span>
            </span>
            <span className="d-block text-gray mt-2">
              Registration Status:
              <span className="py-1 ml-2">
                {moment(v.registration_end_date) >= moment()
                  ? "Open"
                  : "Closed"}
              </span>
            </span>
            <span className="d-block text-gray mt-2">
              Event Status:
              <span className="py-1 ml-2">
                {moment(v.event_end_date) >= moment() ? "Open" : "Closed"}
              </span>
            </span>
          </div>
        </div>
        <div className="dropdown event-drp">
          <i
            className="mdi mdi-dots-vertical"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          ></i>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <Link
              className="dropdown-item"
              to="/events"
              onClick={() => {
                bookmarkHandler({
                  bookmarkType: "EVENT",
                  id: v._id,
                });
              }}
            >
              <i className="mdi mdi-bookmark-outline"></i>
              {v.is_bookmarked === true ? "Remove bookmark" : "Add Bookmark"}
            </Link>
            <Link
              className="dropdown-item"
              to={{
                pathname:
                  v.type === "club"
                    ? `registerteam/${v._id}`
                    : `/events/${v._id}/registernow`,
                state: {
                  inviteType: 0,
                },
              }}
            >
              <i className="mdi mdi-account-multiple"></i> Register Teams
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileEventList;
