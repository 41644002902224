import { FETCH_EVENT_SUCCESS,FETCH_EVENT_LOADING,FETCH_EVENT_ERROR} from "../constants";

let initialState = {
  loading: false,
  user: {
    "status": "",
    "message": "",
    "data": {
        "_id": "",
        "user_id": "",
        "event_name": "",
        "event_type": "",
        "event_season": "",
        "event_from_date": "",
        "event_to_date": "",
        "event_from_time": "",
        "event_to_time": "",
        "event_access": "",
        "event_max_team": "",
        "event_facility": [
            {
                "_id": "",
                "event_id": "",
                "facility_name": "",
                "facility_address": "",
                "facility_city": {
                    "_id": "",
                    "name": "",
                    "createdAt": "",
                    "updatedAt": "",
                    "__v": "",
                    "state_id": ""
                },
                "facility_state": {
                    "_id": "",
                    "country_id": "",
                    "name": "Gujarat",
                    "createdAt": "",
                    "updatedAt": "",
                    "__v": ""
                },
                "facility_website": "",
                "createdAt": "",
                "updatedAt": "",
                "__v": ""
            }
        ],
        "show_number_of_player": "",
        "show_remaining_spots": "",
        "show_event_is_full": "",
        "show_schedule": "",
        "event_image": "",
        "status": "",
        "createdAt": "",
        "updatedAt": "",
        "payment_code": [],
        "contact_person": [],
        "event_schedule": [
            {
                "_id": "",
                "event_id": "",
                "file": "",
                "text": "",
                "createdAt": "",
                "updatedAt": ""
            }
        ],
        "event_division": [
            {
                "_id": "",
                "event_id": "",
                "division_name": "",
                "createdAt": "",
                "updatedAt": "",
                "isDeleted":"" ,
                "deletedAt": "",
                "pool": [
                    {
                        "_id": "",
                        "pool_name": "",
                        "createdAt": "",
                        "updatedAt": "",
                        "isDeleted": "",
                        "deletedAt": "",
                        "event_team": []
                    }
                ]
            },
            {
                "_id": "",
                "event_id": "",
                "division_name": "",
                "createdAt": "",
                "updatedAt": "",
                "isDeleted": "",
                "deletedAt": "",
                "pool": []
            }
        ]
    }
},
  error: false,
  errors: [],
  success: false
};

export default function fetchEventDataReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVENT_SUCCESS:
      // alert("reducer succesfull")
      return { ...state, success: true,user:action.payload};
      break;
    case FETCH_EVENT_LOADING:  
      // alert("getData reducer Error")
      return { ...state, loading: true };
      break;
    case FETCH_EVENT_ERROR:
      // alert("getdata reducer Loading")   
      return { ...state,error: true, errors: action.errors };
      
      break;
   
    default:
      return state;
  }
}


