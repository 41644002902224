import {
  ORG_SWITCH_ACCESS_PLAYER_ERROR,
  ORG_SWITCH_ACCESS_PLAYER_LOADING,
  ORG_SWITCH_ACCESS_PLAYER_SUCCESS,
  RESET_ORG_SWITCH_ACCESS_PLAYER,
} from "../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
};

export default function switchPlayerAccessReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ORG_SWITCH_ACCESS_PLAYER_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case ORG_SWITCH_ACCESS_PLAYER_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        message: action.msg,
      };

    case ORG_SWITCH_ACCESS_PLAYER_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        errors: action.errors,
        msg: action.errors,
      };

    case RESET_ORG_SWITCH_ACCESS_PLAYER:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        errors: [],
        user: [{}],
      };
    default:
      return state;
  }
}
