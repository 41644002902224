import classNames from "classnames";
import React from "react";
import { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import InputMask from "react-input-mask";
import { createPaymentMethod } from "../../../service/action/Club/clubAction";
import { connect, useSelector } from "react-redux";
import { useEffect } from "react";
import { RESET_PAYMENT_METHOD } from "../../../service/constants";
import { getAllCity, getAllState } from "../../../service/action/userAction";
import Spinner from "../../../Spinner/Spinner";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";
import TextField from "@mui/material/TextField";

function AddPaymentMethod(props) {
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    card_name: "",
    card_no: "",
    // expiry_month: '',
    // expiry_year: '',
    expiration_date: "",
    address: "",
    zip: "",
    country: "",
    state: "",
    city: "",
    save_payment_method: false,
    preferred_payment: false,
    delete_from_payment: false,
  });

  const [errors, setErrors] = useState({
    card_name: false,
    card_no: false,
    // expiry_month: false,
    // expiry_year: false,
    expiration_date: false,
    address: false,
    zip: false,
    country: false,
    state: false,
    city: false,
  });
  const history = useHistory();
  const getAllState = props.getAllStateHandler;
  const getAllCity = props.getAllCityHandler;

  const allstate = useSelector((state) => state.getStateReducer.states);
  const allcity = useSelector((state) => state.getCityReducer.citys);

  const location = useLocation();
  useEffect(() => {
    const getDetails = async () => {
      await getAllState();
    };
    getDetails();
  }, []);

  useEffect(() => {
    const id = allstate.find((v) => v._id === state.state)?._id;
    if (id !== undefined) {
      getAllCity({ id: id });
    }
  }, [allstate, state.state]);

  useEffect(() => {
    setLoading(props.PaymentMethod.loading);
    if (props.PaymentMethod?.success) {
      history.goBack();
      props.resetPaymentMethod();
    }
  }, [props.PaymentMethod]);

  const eventhandler = (e) => {
    const { name, value, type, checked } = e.target;
    if (type !== "checkbox") {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }

    switch (name) {
      case "card_name":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            card_name: "Enter valid card name",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            card_name: false,
          }));
        }
        break;

      case "card_no":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            card_no: "Enter valid card number",
          }));
        } else if (value.length !== 19) {
          setErrors((prevState) => ({
            ...prevState,
            card_no: "Enter valid card number",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            card_no: false,
          }));
        }

        break;

      //  case "expiry_month":
      //   if (value === '') {
      //    setErrors(prevState => ({
      //     ...prevState,
      //     expiry_month: 'Enter Expiry month'
      //    }))
      //   } else {
      //    setErrors(prevState => ({
      //     ...prevState,
      //     expiry_month: false
      //    }))
      //   }
      //   break;

      case "expiration_date":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            expiration_date: "Enter expiration date",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            expiration_date: false,
          }));
        }
        break;

      case "address":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            address: "Enter address",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            address: false,
          }));
        }
        break;

      case "zip":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            zip: "Enter zip code",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            zip: false,
          }));
        }
        break;

      case "country":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            country: "Enter country name",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            country: false,
          }));
        }
        break;

      case "state":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            state: "Enter state name",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            state: false,
          }));
        }
        break;

      case "city":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            city: "Enter city name",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            city: false,
          }));
        }
        break;
      default:
        break;
    }
  };

  const createMethod = (e) => {
    e.preventDefault();
    if (state.card_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        card_name: "Enter valid card name",
      }));
    }

    if (state.card_no === "") {
      setErrors((prevState) => ({
        ...prevState,
        card_no: "Enter valid card number",
      }));
    }

    // if (state.expiry_month === '') {
    //  setErrors(prevState => ({
    //   ...prevState,
    //   expiry_month: 'Enter Expiry month'
    //  }))
    // }

    if (state.expiration_date === "") {
      setErrors((prevState) => ({
        ...prevState,
        expiration_date: "Enter expiration date",
      }));
    }

    if (state.address === "") {
      setErrors((prevState) => ({
        ...prevState,
        address: "Enter address",
      }));
    }

    if (state.zip === "") {
      setErrors((prevState) => ({
        ...prevState,
        zip: "Enter zip code",
      }));
    }

    if (state.country === "") {
      setErrors((prevState) => ({
        ...prevState,
        country: "Enter country name",
      }));
    }

    if (state.state === "") {
      setErrors((prevState) => ({
        ...prevState,
        state: "Enter state name",
      }));
    }

    if (state.city === "") {
      setErrors((prevState) => ({
        ...prevState,
        city: "Enter city name",
      }));
    }

    if (
      state.card_name !== "" &&
      state.card_no !== "" &&
      state.expiration_date !== "" &&
      state.zip !== "" &&
      state.card_no.length == 19
    ) {
      const details = {
        card_name: state.card_name,
        card_number: state.card_no.split("-").join(""),
        expiration_date: state.expiration_date,
        preferred_payment: state.preferred_payment ? 1 : 0,
        zip_code: state.zip,
      };
      props.createPaymentMethod(details);
    }
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          {loading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <>
              <div className="text-left d-flex justify-content-between align-items-center flex-wrap">
                <div className="create-team-head">
                  <h5 className="dash-head">Create Card</h5>
                </div>
              </div>

              {/* details */}
              <div>
                <div className="d-flex justify-content-between mt-30">
                  <h4 className="text-left">Card Details</h4>
                </div>
                <div className="row mx-0">
                  <div className="text-left col-12 col-md-7 mt-18">
                    {/* card name */}
                    <div className="form-row">
                      <div className="col-12 mt-18">
                        <input
                          type="text"
                          className={classNames("form-control team-name", {
                            error: errors.card_name,
                          })}
                          placeholder="Card Name *"
                          id="card_name"
                          value={state.card_name}
                          name="card_name"
                          onChange={eventhandler}
                        />

                        {errors.card_name && (
                          <span className="text-red small d-block">
                            * {errors.card_name}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* card number */}

                    <div className="form-row">
                      <div className="col-12 mt-18">
                        {/* <InputMask
                          mask="9999-9999-9999-9999"
                          className={classNames("form-control team-name", {
                            error: errors.card_no,
                          })}
                          placeholder="Card No. *"
                          id="card_no"
                          value={state.card_no}
                          name="card_no"
                          onChange={eventhandler}
                          maskChar={""}
                        /> */}

                        <InputMask
                          mask="9999-9999-9999-9999"
                          className={classNames("form-control team-name", {
                            error: errors.card_no,
                          })}
                          value={state.card_no}
                          onChange={eventhandler}
                          maskChar={""}
                          placeholder="Card No. *"
                          name="card_no"
                          id="card_no"
                        />

                        {errors.card_no && (
                          <span className="text-red small d-block">
                            * {errors.card_no}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* expiry date and expiry_year */}

                    <div className="form-row">
                      {/* <div className="col-md-6 mt-18">
                      <InputMask mask="99" className={classNames("form-control team-name", { "error": errors.expiry_month })} placeholder="ExpMonth *" id="expiry_month" value={state.expiry_month} name="expiry_month" onChange={(e) => eventhandler(e)} maskChar={"_"} />
                      {errors.expiry_month && <span className='text-red small d-block'>* {errors.expiry_month}</span>}
                    </div> */}

                      <div className="col-md-6 mt-18">
                        <InputMask
                          mask="9999"
                          className={classNames("form-control team-name", {
                            error: errors.expiration_date,
                          })}
                          placeholder="Expiration date (MM/YY)"
                          id="expiration_date"
                          value={state.expiration_date}
                          name="expiration_date"
                          onChange={eventhandler}
                          maskChar={"_"}
                        />
                        {errors.expiration_date && (
                          <span className="text-red small d-block">
                            * {errors.expiration_date}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Zip code */}
              <div className="col-md-6 mt-18">
                <InputMask
                  mask="999999"
                  className={classNames("form-control team-name", {
                    error: errors.zip,
                  })}
                  placeholder="Zip Code *"
                  id="zip"
                  value={state.zip}
                  name="zip"
                  onChange={eventhandler}
                  maskChar={"_"}
                />
                {errors.zip && (
                  <span className="text-red small d-block">* {errors.zip}</span>
                )}
              </div>

              <div className="mt-18">
                <div className="d-flex align-items-center">
                  <input
                    type={"checkbox"}
                    name="preferred_payment"
                    id="preferred_payment"
                    onChange={eventhandler}
                    value={state.preferred_payment}
                  />
                  <div className="fw-m ml-3">
                    Make preferred form of payment
                  </div>
                </div>
              </div>

              <div className="mt-10 text-left  rounded">
                <Link
                  to="/payment/payment-method/add-payment-method"
                  className="text-decoration-none "
                >
                  <button className="btn-create-new " onClick={createMethod}>
                    Submit
                  </button>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  PaymentMethod: state.paymentMethodReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createPaymentMethod: (user) => dispatch(createPaymentMethod(user)),
  getAllStateHandler: (user) => dispatch(getAllState()),
  getAllCityHandler: (user) => dispatch(getAllCity(user)),
  resetPaymentMethod: () => dispatch({ type: RESET_PAYMENT_METHOD }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethod);
