import React, { useEffect, useState } from 'react'
import { useParams, withRouter } from "react-router-dom"
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RESET_CREATE_NEW_GROUP } from '../../../service/constants';
import Toaster from '../../../Toaster/Toaster';
import { getAllGroups } from '../../../service/action/userAction';

const AccountGroup = (props) => {

    const { id } = useParams()

    const notify = (msg) => toast(msg);

    const getGroups = props.getAllGroupsHandler

    useEffect(() => {
        getGroups()
    }, [getGroups])

    const [state, setState] = useState([])

    const getAllGropsList = useSelector(state => state.getAllGroupsReducer.user.data)

    // const getGroups = useSelector(state => state.getAllGroupsReducer.user.data)

    // const getGroupStaff=getAllGropsList?getAllGropsList.find(e=>e._id==id):""


    useEffect(() => {
        if (getAllGropsList) {
            const getGroupStaff = getAllGropsList.find(e => e._id == id)
            if (getGroupStaff) {
                setState([getGroupStaff])
            }

        }
    }, [getAllGropsList])


    var userData = [
        { name: "username", img: "" },
        { name: "username", img: "" },
        { name: "Username", img: "" },
        { name: "Username", img: "" },
        { name: "Username", img: "" },
    ];

    const creategroupMsg = useSelector(state => state.createNewGroupReducer.message)


    useEffect(() => {
        // alert("a")
        if (props.submit?.success) {
            notify(creategroupMsg.message)
            props.resetCreateGroupReducer()

        } else if (props.submit?.error) {
        }
    }, [props.submit])

    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <div className="create-team-head-space">
                            <h5 className="dash-head"> Account Users</h5>

                        </div>
                    </div>

                    {
                        state !== [] ? <>


                            {
                                state.map((v, i) => {
                                    return <>

                                        <div className="row mt-18" key={v._id}>
                                            <div className="col-md-4 text-left user-content">
                                                <span className="rep"> {v.group_name} </span>

                                            </div>
                                        </div>
                                        {/* <Link className="account-tab" to="/account-staff-list">
                            <div className="account-type-section col-md-4 text-left user-content">
                                Staff
                                <i className="mdi mdi-chevron-right" ></i>
                            </div>
    
                        </Link> */}


                                        <div className="d-flex flex-wrap">

                                            { 
                                                v.user.map((v, i) => {
                                                    return <>
                                                        <div className="user-profile mt-18 mr-18" key={v._id}>
                                                            <div>
                                                                <div className="img-section">
                                                                    <img src={v.profile_image == ""?"./images/faces/face3.jpg" : v.profile_image} className="img-fluid" />
                                                                </div>
                                                                <span className="text-center">{v.name}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                              
                                            })
                                            }
                                        </div>
                                    </>
                                })
                            }

                        </> : <></>
                    }

                    {/* <div className="row mt-30">
                        <div className="col-md-4 text-left user-content">
                            <span className="rep">Rep. Type 2</span>
                        </div>
                    </div>

                    <div className="user-profile mt-18 mr-18">
                        <div>
                            <div className="img-section">
                                <img src="./images/faces/face3.jpg" className="img-fluid" />
                            </div>
                            <span className="text-center">username</span>
                        </div>
                    </div> */}

                    <Toaster />
                </div>
            </div>

        </React.Fragment>
    )
}

// export default withRouter(OrgAccountUser);

const mapStateToProp = state => ({
    submit: state.createNewGroupReducer,

})

const mapDispatchToProps = dispatch => ({
    // createNewGrouphandler: user => dispatch(createNewGroup(user)),
    resetCreateGroupReducer: () => dispatch({ type: RESET_CREATE_NEW_GROUP }),
    getAllGroupsHandler: user => dispatch(getAllGroups(user)),



})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(AccountGroup));
