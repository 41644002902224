import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import { getStaffGroupList } from "../../service/action/userAction";
import { useEffect } from "react";

function Staff_Permission(props) {
  const history = useHistory();

  const [state, setState] = useState([]);

  useEffect(() => {
    props.getStaffGroupList();
  }, []);

  useEffect(() => {
    if (props.getStaffPermissionListReducer.success) {
      const data = props.getStaffPermissionListReducer.data.data;
      setState(data);
    }
  }, [props.getStaffPermissionListReducer]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-right">
            <div>
              <div className="mt-3 mt-md-0 text-right create-team-head-btn flex-wrap">
                <Link to="/staff-permission/create-group" className="mr-2 mt-2">
                  <button className="btn-create-new ">Create Group</button>
                </Link>
                <Link to="/staff-permission/add-staff" className="mr-2 mt-2">
                  <button className="btn-create-new ">Add Staff</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="mt-3">
            {state.length == 0 ? (
              <> no group </>
            ) : (
              <>
                <div className="d-flex flex-wrap justify-content-start">
                  {state.map((e, i) => {
                    return (
                      <div
                        key={i}
                        className="border pointer p-2 m-2 d-flex justify-content-between align-items-center admin_group"
                        onClick={() =>
                          history.push(`/staff-permission/${e._id}`)
                        }
                      >
                        <div className=" text-danger">{e.group_name}</div>
                        <i className="mdi mdi-chevron-right"></i>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProp = (state) => ({
  getStaffPermissionListReducer:
    state.OrgPermissionReducer.getStaffPermissionList,
});

const mapDispatchToProps = (dispatch) => ({
  getStaffGroupList: () => dispatch(getStaffGroupList()),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Staff_Permission));
