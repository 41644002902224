import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import { withRouter, Redirect, useParams, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  getAllEvent,
  getAllDiscountCode,
  editDiscountCode,
  getClubFindByName,
} from "../../../service/action/userAction";
import Multiselect from "multiselect-react-dropdown";
import { RESET_EDIT_DISCOUNT_CODE } from "../../../service/constants";
import {
  AMOUNT,
  DISCOUNT_CODE_NAME,
  EXPIRY_DATE,
  NUMERIC,
  START_DATE,
} from "../../../Validation/organizer";
import classNames from "classnames";
import materialTheme from "../../../materialUi";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Moment from "react-moment";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import Spinner from "../../../Spinner/Spinner";

const EditDiscountCode = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const gelAllDiscount = useSelector(
    (state) => state.getDiscountCodeReducer.data
  );
  const allEvent = useSelector((state) => state.getAllEventReducer.user); //Get All Event from redux store

  const initialState = {
    discount_type: "",
    discountCode_name: "",
    code_type: "0",
    amount: "",
    expireDate: null,
    maxUsagePerPerson: "",
    discount_code_type: "0",
    code: "",
  };

  const initialError = {
    discount_type: false,
    discountCode_name: false,
    amount: false,
    expireDate: false,
    maxUsagePerPerson: false,
    code: false,
  };

  const [state, setState] = useState(initialState);
  const [errors, setErrors] = useState(initialError);
  const [selected, setSelected] = useState({
    oldSelected: [],
    newSelected: [],
  });
  const [selectedClub, setSelectedClub] = useState({
    oldSelectedClub: [],
    newSelectedClub: [],
  });
  const [filterEventList, setFilterEventList] = useState([]);
  const [filterClubList, setFilterClubList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenClub, setIsOpenClub] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [clubSearchVal, setClubSearchVal] = useState("");

  useEffect(() => {
    props.getAllDiscountcodehandler();
  }, []);

  useEffect(() => {
    if (!!id && props.discountcode.success) {
      const codes = props.discountcode.data;
      const currentData = codes.find((e) => e._id == id);

      if (!!currentData) {
        setState((pre) => ({
          ...pre,
          discount_type: currentData.discount_type,
          discountCode_name: currentData.code_name,
          code_type: currentData.type,
          amount: currentData.amount,
          expireDate: moment(currentData.expire_date, "MM-DD-yyyy"),
          maxUsagePerPerson: !!currentData.max_apply
            ? currentData.max_apply
            : "",
          discount_code_type:
            currentData.discount_code_type != undefined ||
            currentData.discount_code_type != null
              ? currentData.discount_code_type
              : "",
          code: !!currentData.code ? currentData.code : "",
        }));

        if (currentData?.discount_type == "0") {
          setSelected((pre) => ({
            ...pre,
            oldSelected: [...currentData.event].map((e) => e._id),
          }));
        } else if (currentData?.discount_type == "1") {
          setSelectedClub((pre) => ({
            ...pre,
            oldSelectedClub: [...currentData.recipient].map((e) => e._id),
          }));
        }
      }
    }
  }, [id, props.discountcode]);
  useEffect(() => {
    if (state.discount_type == "0" && !props.event.success) {
      props.getEventHandler({ id: 0 });
    } else if (state.discount_type == "1" && !props.clubList.success) {
      props.getClubFindByName();
    }
  }, [state.discount_type]);

  useEffect(() => {
    if (props.event.success) {
      const data = props.event.user;
      setFilterEventList(data);
    }
  }, [props.event]);
  useEffect(() => {
    if (props.clubList.success) {
      const data = props.clubList.user;
      setFilterClubList(data);
    }
  }, [props.clubList]);

  useEffect(() => {
    if (props.event.success) {
      if (searchVal !== "") {
        const data = [...props.event.user];
        const filter = data.filter((e) =>
          e.event_name.toLowerCase().includes(searchVal.toLowerCase())
        );

        setFilterEventList(filter);
      } else {
        const data = [...props.event.user];
        setFilterEventList(data);
      }
    }
  }, [searchVal]);
  useEffect(() => {
    if (props.clubList.success) {
      if (clubSearchVal !== "") {
        const data = [...props.clubList.user];
        const filter = data.filter((e) =>
          e.name.toLowerCase().includes(clubSearchVal.toLowerCase())
        );

        setFilterClubList(filter);
      } else {
        const data = [...props.clubList.user];
        setFilterClubList(data);
      }
    }
  }, [clubSearchVal]);

  useEffect(() => {
    if (state.discount_code_type === "0") {
      const randomStr = Math.round(
        Math.pow(36, 8 + 1) - Math.random() * Math.pow(36, 8)
      )
        .toString(36)
        .slice(1);
      setState((prevState) => ({
        ...prevState,
        code: randomStr,
      }));
      setErrors((prevState) => ({
        ...prevState,
        code: false,
      }));
    }
  }, [state.discount_code_type]);

  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    if (name === "discount_code_type") {
      if (value == "1") {
        setState((prevState) => ({
          ...prevState,
          code: "",
          discount_code_type: value,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          discount_code_type: value,
        }));
      }
    } else if (name === "amount") {
      if (!isNaN(value)) {
        if (state.code_type == "0") {
          setState((prevState) => ({
            ...prevState,
            amount: value,
          }));
        } else {
          if (parseFloat(value) <= 100 || value == "") {
            setState((prevState) => ({
              ...prevState,
              amount: value,
            }));
          } else return;
        }
      } else return;
    } else if (name == "maxUsagePerPerson") {
      if (!isNaN(value)) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    switch (name) {
      case "discountCode_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            discountCode_name: DISCOUNT_CODE_NAME,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            discountCode_name: false,
          }));
        }

        break;

      case "discountcodetype":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            discount_type: DISCOUNT_CODE_NAME,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            discount_type: false,
          }));
        }

        break;

      case "amount":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            amount: AMOUNT,
          }));
        } else if (!/^[0-9]+$/.test(value)) {
          setErrors((prevState) => ({
            ...prevState,
            amount: NUMERIC,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            amount: false,
          }));
        }
        break;

      case "expireDate":
        if (value == null || value == "") {
          setErrors((prevState) => ({
            ...prevState,
            expireDate: EXPIRY_DATE,
          }));
        } else if (value.toString() == "Invalid Date") {
          errors["expireDate"] = "Enter Valid Date";
        } else if (
          moment(value).format("MM-DD-yyyy") < moment().format("MM-DD-yyyy")
        ) {
          errors["expireDate"] = "Enter Valid Date";
        } else {
          setErrors((prevState) => ({
            ...prevState,
            expireDate: false,
          }));
        }
        break;

      case "code_type":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            option: "Value can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            option: false,
          }));
        }
        break;

      case "maxUsagePerPerson":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            maxUsagePerPerson: "Value can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            maxUsagePerPerson: false,
          }));
        }
        break;

      case "code":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            code: "Value can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            code: false,
          }));
        }
        break;

      default:
    }
  };

  {
    /* -----------=====----------- onChange Value End-----------====----------- */
  }

  {
    /* -----------=====----------- Submite Data(Button Click) start-----------====----------- */
  }

  const submitEventRequest = (e) => {
    e.preventDefault();
    if (state.discountCode_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        discountCode_name: DISCOUNT_CODE_NAME,
      }));
    }
    if (state.amount == "") {
      setErrors((prevState) => ({
        ...prevState,
        amount: AMOUNT,
      }));
    }
    if (state.expireDate == null && state.expireDate !== "") {
      setErrors((prevState) => ({
        ...prevState,
        expireDate: EXPIRY_DATE,
      }));
    }
    if (state.maxUsagePerPerson == "") {
      setErrors((prevState) => ({
        ...prevState,
        maxUsagePerPerson: "Enter Max usage per person",
      }));
    }

    if (state.code == "") {
      setErrors((prevState) => ({
        ...prevState,
        code: "Enter code",
      }));
    }

    if (selected.oldSelected.length == 0) {
      toast.error("please Select Event");
    }

    if (
      state.discountCode_name !== "" &&
      state.amount !== "" &&
      state.expireDate !== "" &&
      state.code !== "" &&
      selected.oldSelected.length !== 0
    ) {
      const details = {
        id: id,
        code_name: state.discountCode_name,
        code_type: state.code_type,
        discount_type: state.discount_type,
        amount: state.amount,
        expiry_date: moment(state.expireDate).format("MM-DD-YYYY"),
        events: selected.oldSelected,
        code: state.code,
        discount_code_type: state.discount_code_type,
        max_apply: state.maxUsagePerPerson,
      };
      props.EditDiscountCodeHandler(details);
    }
    // history.push("/discount-code");
  };

  const submitClubRequest = (e) => {
    e.preventDefault();
    if (state.discountCode_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        discountCode_name: DISCOUNT_CODE_NAME,
      }));
    }
    if (state.amount == "") {
      setErrors((prevState) => ({
        ...prevState,
        amount: AMOUNT,
      }));
    }
    if (state.expireDate == null && state.expireDate !== "") {
      setErrors((prevState) => ({
        ...prevState,
        expireDate: EXPIRY_DATE,
      }));
    }

    if (selectedClub.oldSelectedClub.length == 0) {
      toast.error("please Select Club");
    }

    if (
      state.discountCode_name !== "" &&
      state.amount !== "" &&
      state.expireDate !== "" &&
      selectedClub.oldSelectedClub.length !== 0
    ) {
      const details = {
        id: id,
        code_name: state.discountCode_name,
        code_type: state.code_type,
        discount_type: state.discount_type,
        amount: state.amount,
        expiry_date: moment(state.expireDate).format("MM-DD-YYYY"),
        recipient: selectedClub.oldSelectedClub,
      };
      props.EditDiscountCodeHandler(details);
    }
    // history.push("/discount-code");
  };

  useEffect(() => {
    if (props.editDiscountCode.success) {
      toast.success("Data submitted successfully.");
      props.resetEditDiscountCode();
      history.push("/discount-code");
    }
  }, [props.editDiscountCode]);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="d-flex justify-content-center">
          {loading ? <Spinner /> : null}
        </div>
        {!loading && (
          <>
            <div className="text-left">
              <h5 className="dash-head">Edit Discount Code</h5>
            </div>
            <div className="row col-md-6 px-0">
              <div className="col-md-12">
                <div className="form-group event-drp mt-24">
                  <select
                    className={classNames("form-control team-name payterm", {
                      error: errors.discount_type,
                    })}
                    id="discount_type"
                    name="discount_type"
                    value={state.discount_type}
                    onChange={eventHandler}
                  >
                    <option value="" disabled>
                      Division type*
                    </option>
                    <option value="0">Standard</option>
                    <option value="1">Club</option>
                    {/* <option value="2">Club & Parent</option> */}
                  </select>
                  {errors.discount_type && (
                    <span className="text-red small d-block">
                      * {errors.discount_type}
                    </span>
                  )}
                </div>
                {state.discount_type !== "" && (
                  <div className="mt-24" id="standard-tab">
                    {/* -----------=====----------- Discount code name start-----------====----------- */}

                    <div className="mt-18">
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          errorBorder: errors.discountCode_name,
                        })}
                        id="discountCode_name"
                        name="discountCode_name"
                        value={state.discountCode_name}
                        placeholder="Discount code name *"
                        onChange={eventHandler}
                        autoComplete="off"
                      />
                      {errors.discountCode_name && (
                        <span className="text-red small d-block">
                          * {errors.discountCode_name}
                        </span>
                      )}
                    </div>

                    {/* -----------=====----------- Discount code name End-----------====----------- */}

                    {/* -----------=====----------- Amount start-----------====----------- */}
                    <div className="mt-24">
                      <strong>
                        Amount <span className="text-red fw-m">*</span>
                      </strong>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="form-check form-check-inline mr-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="code_type"
                          id="%"
                          value="1"
                          checked={state.code_type == "1"}
                          onChange={eventHandler}
                        />
                        <label className="form-check-label ml-0" for="%">
                          %
                        </label>
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="code_type"
                          id="$"
                          value="0"
                          checked={state.code_type == "0"}
                          onChange={eventHandler}
                        />
                        <label className="form-check-label ml-0" for="$">
                          $
                        </label>
                      </div>
                    </div>

                    <div className="form-row ">
                      <div className="col-12">
                        <input
                          type="text"
                          className={classNames("form-control team-name", {
                            errorBorder: errors.amount,
                          })}
                          placeholder="Amount*"
                          id="amount"
                          name="amount"
                          value={state.amount}
                          onChange={eventHandler}
                        />
                        {errors.amount && (
                          <span className="text-red small d-block">
                            * {errors.amount}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      {/* //-----------=====----------- Amount End-----------====----------- */}

                      {/* //-----------=====-----------expiration Date start-----------====----------- */}

                      <div className="col-md-12 col-12">
                        <div className="text-left mt-18">
                          <ThemeProvider theme={materialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                value={state.expireDate}
                                clearable
                                placeholder="Expire date"
                                onChange={(e, a) => {
                                  eventHandler({
                                    target: {
                                      value: e,
                                      id: "expireDate",
                                      name: "expireDate",
                                    },
                                  });
                                }}
                                minDate={moment().add(1, "days")}
                                format="MM/dd/yyyy"
                                mask="__/__/____"
                                inputVariant="outlined"
                                autoOk={true}
                                error={Boolean(errors.expireDate)}
                                helperText={""}
                                InputAdornmentProps={{ position: "end" }}
                                className={classNames(
                                  "form-control team-name",
                                  {
                                    errorBorder: errors.expireDate,
                                  }
                                )}
                              />
                            </MuiPickersUtilsProvider>
                          </ThemeProvider>
                          {errors.expireDate && (
                            <span className="text-red small d-block">
                              * {errors.expireDate}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* //-----------=====-----------expiration Date End-----------====----------- */}
                    </div>

                    {/* -----------=====----------- Max. apply start-----------====----------- */}
                    {state.discount_type == "0" && (
                      <div className="text-left mt-18">
                        <input
                          type="text"
                          className={classNames("form-control team-name", {
                            errorBorder: errors.maxUsagePerPerson,
                          })}
                          id="maxUsagePerPerson"
                          name="maxUsagePerPerson"
                          value={state.maxUsagePerPerson}
                          placeholder="Max. Usage per user*"
                          required
                          onChange={eventHandler}
                          autoComplete="off"
                        />
                        {errors.maxUsagePerPerson && (
                          <span className="text-red small d-block">
                            * {errors.maxUsagePerPerson}
                          </span>
                        )}
                      </div>
                    )}
                    {/* -----------=====----------- Max. apply name End-----------====----------- */}

                    {/* //-----------=====-----------Code Generate start-----------====----------- */}

                    {state.discount_type == "0" && (
                      <>
                        <div className="mt-24">
                          <strong>
                            Code Generate{" "}
                            <span className="text-red fw-m">*</span>
                          </strong>
                        </div>

                        <div className="mt-24">
                          <div className="d-flex">
                            <div>
                              <input
                                id="random_code"
                                name="discount_code_type"
                                value={"0"}
                                type={"radio"}
                                checked={state.discount_code_type == "0"}
                                // defaultChecked
                                onChange={eventHandler}
                              />
                              <label htmlFor="random_code">Random Code</label>
                            </div>
                            <div className="ml-24">
                              <input
                                id="coustom_code"
                                name="discount_code_type"
                                value={"1"}
                                type={"radio"}
                                checked={state.discount_code_type == "1"}
                                onChange={eventHandler}
                              />
                              <label htmlFor="coustom_code">Custom Code</label>
                            </div>
                          </div>
                          <div className="mt-12">
                            <input
                              name="code"
                              value={state.code}
                              className={classNames("form-control team-name", {
                                errorBorder: errors.code,
                              })}
                              placeholder="Create Code Name *"
                              disabled={state.discount_code_type == "0"}
                              onChange={eventHandler}
                              maxLength={"20"}
                            />
                            {errors.code && (
                              <div className="text-red small">
                                * {errors.code}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {/* //-----------=====-----------Code Generate End-----------====----------- */}

                    {/* //-----------=====-----------Select Event start-----------====----------- */}

                    {state.discount_type == "0" ? (
                      <div className="text-left mt-18">
                        <div className="mt-3 text-left">
                          <span
                            className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                            onClick={() => {
                              setIsOpen(true);
                              setIsOpenClub(false);
                              setSelected((pre) => ({
                                ...pre,
                                newSelected: [...pre.oldSelected],
                              }));
                            }}
                          >
                            Select Event
                          </span>
                        </div>
                        {selected.oldSelected.length !== 0 ? (
                          <div className="my-3">
                            <div className="">
                              <strong>Event List</strong>
                            </div>
                            <div
                              style={{
                                maxHeight: "250px",
                                overflowY: "auto",
                              }}
                              className="pb-3 my-3 border-bottom"
                            >
                              {selected.oldSelected.map((e, i) => {
                                const event = filterEventList.find(
                                  (f) => f._id == e
                                );
                                return (
                                  <li className="text-gray mt-1" key={i}>
                                    {event?.event_name}
                                  </li>
                                );
                                // return <>
                              })}
                            </div>
                          </div>
                        ) : (
                          <div className="text-gray mt-4 text-center">
                            There is no event selected
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="text-left mt-18">
                        <div className="mt-3 text-left">
                          <span
                            className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                            onClick={() => {
                              setIsOpen(false);
                              setIsOpenClub(true);
                              setSelectedClub((pre) => ({
                                ...pre,
                                newSelectedClub: [...pre.oldSelectedClub],
                              }));
                            }}
                          >
                            Select Club
                          </span>
                        </div>
                        {selectedClub.oldSelectedClub.length !== 0 ? (
                          <div className="my-3">
                            <div className="">
                              <strong>Club List</strong>
                            </div>
                            <div
                              style={{
                                maxHeight: "250px",
                                overflowY: "auto",
                              }}
                              className="pb-3 my-3 border-bottom"
                            >
                              {selectedClub.oldSelectedClub.map((e, i) => {
                                const club = filterClubList.find(
                                  (f) => f._id == e
                                );
                                return (
                                  <li className="text-gray mt-1" key={i}>
                                    {club?.name}
                                  </li>
                                );
                                // return <>
                              })}
                            </div>
                          </div>
                        ) : (
                          <div className="text-gray mt-4 text-center">
                            There is no club selected
                          </div>
                        )}
                      </div>
                    )}
                    {/* //-----------=====-----------Select Event End-----------====----------- */}

                    <div className="mt-5 text-left">
                      <a
                        className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={
                          state.discount_type == "0"
                            ? submitEventRequest
                            : submitClubRequest
                        }
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        show={isOpen}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
            id="contained-modal-title-vcenter"
          >
            Select Event
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="video_modal">
          <div className="search-section">
            <i className="mdi mdi-magnify search-ico"></i>
            <input
              type="text"
              className="form-control"
              id="search"
              name="search"
              value={searchVal}
              placeholder="Search"
              onChange={(e) => {
                setSearchVal(e.target.value);
              }}
              autoComplete="off"
            />
          </div>
          <hr />
          <div className="mt-2">
            <strong>Event List</strong>
          </div>
          <div
            className="mt-24"
            style={{
              maxHeight: "500px",
              overflowY: "auto",
            }}
          >
            {filterEventList.length !== 0 ? (
              <>
                {filterEventList.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="row mx-0">
                        <label for={e._id} className="col-10 pl-2 mb-0">
                          <div>{e.event_name}</div>
                        </label>
                        <div className="col-2">
                          <input
                            type="checkbox"
                            value={e._id}
                            id={e._id}
                            checked={selected.newSelected.includes(e._id)}
                            onChange={(event) => {
                              const value = event.target.value;
                              const selectedList = [...selected.newSelected];
                              if (selectedList.includes(value)) {
                                const filter = selectedList.filter(
                                  (e) => e !== value
                                );
                                setSelected((pre) => ({
                                  ...pre,
                                  newSelected: [...filter],
                                }));
                              } else {
                                setSelected((pre) => ({
                                  ...pre,
                                  newSelected: [...selectedList, value],
                                }));
                              }
                            }}
                          />
                        </div>
                      </div>
                      <hr />
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <div className="text-gray text-center fs-25my-4">
                There are no events available
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Button className="btn-gray m-3" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            className="m-3"
            onClick={() => {
              setSelected((pre) => ({
                ...pre,
                oldSelected: [...selected.newSelected],
                newSelected: [],
              }));
              setIsOpen(false);
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isOpenClub}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
            id="contained-modal-title-vcenter"
          >
            Select Club
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="video_modal">
          <div className="search-section">
            <i className="mdi mdi-magnify search-ico"></i>
            <input
              type="text"
              className="form-control"
              id="search"
              name="search"
              value={clubSearchVal}
              placeholder="Search"
              onChange={(e) => {
                setClubSearchVal(e.target.value);
              }}
              autoComplete="off"
            />
          </div>
          <hr />
          <div className="mt-2">
            <strong>Club List</strong>
          </div>
          <div
            className="mt-24"
            style={{
              maxHeight: "500px",
              overflowY: "auto",
            }}
          >
            {filterClubList.length !== 0 ? (
              <>
                {filterClubList.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="row mx-0">
                        <label for={e._id} className="col-10 pl-2 mb-0">
                          <div>{e.name}</div>
                        </label>
                        <div className="col-2">
                          <input
                            type="checkbox"
                            value={e._id}
                            id={e._id}
                            checked={selectedClub.newSelectedClub.includes(
                              e._id
                            )}
                            onChange={(club) => {
                              const value = club.target.value;
                              const selectedList = [
                                ...selectedClub.newSelectedClub,
                              ];
                              if (selectedList.includes(value)) {
                                const filter = selectedList.filter(
                                  (e) => e !== value
                                );
                                setSelectedClub((pre) => ({
                                  ...pre,
                                  newSelectedClub: [...filter],
                                }));
                              } else {
                                setSelectedClub((pre) => ({
                                  ...pre,
                                  newSelectedClub: [...selectedList, value],
                                }));
                              }
                            }}
                          />
                        </div>
                      </div>
                      <hr />
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <div className="text-gray text-center fs-25my-4">
                There are no events available
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Button className="btn-gray m-3" onClick={() => setIsOpenClub(false)}>
            Cancel
          </Button>
          <Button
            className="m-3"
            onClick={() => {
              setSelectedClub((pre) => ({
                ...pre,
                oldSelectedClub: [...selectedClub.newSelectedClub],
                newSelectedClub: [],
              }));
              setIsOpenClub(false);
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Toaster />
    </div>
  );
};
// export default withRouter(EditDiscountCode)
const mapStateToProp = (state) => ({
  editDiscountCode: state.editDiscountCodeReducer,
  event: state.getAllEventReducer,
  clubList: state.getClubListFindByName,
  discountcode: state.getDiscountCodeReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getEventHandler: (user) => dispatch(getAllEvent(user)),
  getClubFindByName: (user) => dispatch(getClubFindByName(user)),
  getAllDiscountcodehandler: (user) => dispatch(getAllDiscountCode(user)),
  EditDiscountCodeHandler: (user) => dispatch(editDiscountCode(user)),
  resetEditDiscountCode: () => dispatch({ type: RESET_EDIT_DISCOUNT_CODE }),
  // CreateDiscountCodehandler: user => dispatch(createDiscountCodeAction(user)),
  // resetCreatePaymentCode:()=>dispatch({type:RESET_CREATE_PAYMENT_CODE})
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(EditDiscountCode));
