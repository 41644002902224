import { GET_FREETEAM_ERROR, GET_FREETEAM_LOADING, GET_FREETEAM_SUCCESS } from "../../constants"

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function getFreeTeamReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FREETEAM_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case GET_FREETEAM_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };
     
    case GET_FREETEAM_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}
