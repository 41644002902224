import React from "react";
import { Link } from "react-router-dom";

const Notifications = ({ accountDetails, notification }) => {
  const userId = localStorage.getItem("Id");
  return (
    <>
      <div className="text-left">
        {notification && notification.length !== 0 ? (
          <>
            {notification
              .filter((item, index) => index < 2)
              .map((v, i) => {
                return (
                  <div className="text-left" key={i}>
                    <div className="basic-info">
                      <div className="dashboard-img">
                        <img src="./images/pch-images/pch.svg" />
                      </div>
                      <div className="ml-12">
                        <Link
                          className="see-all text-decoration-none heading"
                          to={{
                            pathname: `/account-notifications/${userId}`,
                            state: {
                              id: accountDetails?._id,
                              type: parseInt(localStorage.getItem("type")),
                              name: "notification",
                            },
                          }}
                        >
                          {v.heading}
                        </Link>
                        <span className="noti-type">{v.message} </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </>
        ) : (
          <>
            <span className="noti-type">No notifications to display</span>
          </>
        )}
      </div>
    </>
  );
};

export default Notifications;
