import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "@dmuy/timepicker/dist/mdtimepicker.css";
import { useSelector, connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  getAllEvent,
  getAllDivision,
  getEventStaff,
  deleteEventStaff,
  getEventSchedule,
  deleteSchedule,
  getFreeTeam,
  getEventTeam,
  deletePaymentCode,
  deleteEventTeam,
  deletePool,
  deleteDivision,
  getAcceptedPlayer,
  getTeamGroups,
  deleteGroup,
  updateFreeTeam,
  removePoolTeam,
  createPool,
  getActivityLog,
  getAccounting,
  SwitchacccessAction,
  viewfieldmapAction,
  CreateScheduleMessageAction,
  CreateFieldmapMessageAction,
  viewStayandplayAction,
  CreateStayandPlayMessageAction,
  DeleteStayandPlayAction,
  SwitchacccessPlayerAction,
  deleteFileMapAction,
} from "../../../service/action/userAction";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import {
  RESET_CREATE_DIVISION,
  RESET_CREATE_POOL,
  RESET_DELETE_EVENT,
  RESET_DELETE_EVENTTEAM,
  RESET_DELETE_POOL,
  RESET_DELETE_SCHEDULE,
  RESET_EDIT_POOL,
  RESET_EDIT_DIVISION,
  RESET_DELETE_DIVISION,
  RESET_CREATE_TEAM,
  RESET_FREETEAM,
  RESET_CREATE_NEW_GROUP,
  RESET_ADD_PLAYER_GROUP,
  RESET_EDIT_GROUP,
  RESET_DELETE_GROUP,
  RESET_EDIT_PAYMENT_CODE,
  RESET_VIEW_FIELD_MAP,
  RESET_CREATE_FIELD_MAP,
  RESET_UPDATE_FIELD_MAP,
  RESET_ORG_ADD_TEAM_TO_DIVISION,
  RESET_ORG_SWITCH_ACCESS,
  RESET_CREATE_FIELDMAP_MESSAGE,
  RESET_VIEW_STAY_AND_PLAY,
  RESET_CREATE_STAY_AND_PLAY,
  RESET_UPDATE_STAY_AND_PLAY,
  RESET_UPDATE_SCHEDULE,
  RESET_MOVE_TO_FREE_TEAM_PLAYER,
  RESET_ORG_SWITCH_ACCESS_PLAYER,
  RESET_CREATE_SCHEDULE_MESSAGE,
  RESET_CREATE_STAY_AND_PLAY_MESSAGE,
  RESET_DELETE_FIELD_MAP,
  RESET_DELETE_STAY_AND_PLAY,
} from "../../../service/constants";
import Spinner from "../../../Spinner/Spinner";
import Table from "react-bootstrap/Table";
import { confirmAlert } from "react-confirm-alert";
import Moment from "react-moment";
import classNames from "classnames";
import DeletePopUp from "../../../club/components/DeletePopUp/DeletePopUp";
import moment from "moment";

const Org_eventDetails = (props) => {
  var waitlistTeams = [];
  var waitlistfree = [];
  var divisionfreeagents = [];
  var waitlistinternal = [];
  var getEventDetailsType1 = [];
  const history = useHistory();

  const [deleteScheduleID, setdeleteScheduleID] = useState();

  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [isDeleteSchedule, setIsDeleteSchedule] = useState(false);
  const [deletedSchedule, setdeletedSchedule] = useState(null);

  const [isDeleteFiledMap, setIsDeleteFiledMap] = useState(false);
  const [deletedFiledMap, setdeletedFiledMap] = useState(null);

  const [isDeleteStayandplay, setisDeleteStayandplay] = useState(false);
  const [deletedStayandPlay, setdeletedStayandPlay] = useState(null);

  const [isDeleteTeam, setisDeleteTeam] = useState(false);
  const [deleteIdTeam, setDeleteIdTeam] = useState(null);

  const val = () => {
    confirmAlert({
      title: "You Want To Delete This Data ?",
      message: "Are You Sure You Want To Delete This Data ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => alert("Click Yes"),
          //   style:{backgroud-co    lor:"#143D4A"}
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
      customUI: ({ onClose }) => {
        return (
          <div id="react-confirm-alert">
            <div
              className="react-confirm-alert-overlay"
              style={{ background: "rgba(255, 255, 255, 0.1)" }}
            >
              <div className="react-confirm-alert">
                <div className="react-confirm-alert-body">
                  <h2 style={{ color: "#143D4A" }}>
                    Are you sure you want to Delete ?
                  </h2>
                  {/* Are You Sure You Want To Delete This Data ? */}
                  <div className="react-confirm-alert-button-group">
                    <button
                      style={{ background: "#143D4A" }}
                      onClick={() => {
                        props.deleteScheduleHandler(deleteScheduleID);
                      }}
                    >
                      Yes
                    </button>
                    <button
                      style={{ background: "#143D4A" }}
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const [loading, setLoading] = useState(true);
  const notify = (msg) => toast(msg);
  const [state, setState] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    reg_startDate: "",
    reg_endDate: "",
    reg_startTime: "",
    reg_endTime: "",
    event_name: "",
    event_image: "",
    facility_name: "",
    website: "",
    city: "",
    state: "",
    address: "",
    maxTeam: "",
    maxPlayer: "",
    availability: "",
    option: "",
    from_time: "",
    to_time: "",
    event_type: "",
    reg_date: "",
  });

  const { id, id1 } = useParams();
  const getdata = props.getEventHandler; //for get all event

  useEffect(() => {
    getdata({ id: 0 });
    props.getEventTeamHandler({ id: id });
  }, []);

  const [copied, setCopied] = useState(false);
  function copy() {
    const el = document.createElement("input");
    el.value = `${window.location.origin}/registernow/${id}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  const allEvent = useSelector((state) => state.getAllEventReducer.user);
  const allDivisionState = useSelector(
    (state) => state.getAllDivisionReducer.user.data
  );

  const getEventStaff = useSelector(
    (state) => state.getEventStaffReducer.user.data
  );
  const getActivityLog = useSelector(
    (state) => state.getEvenActivityLogReducer.user.data
  );
  const deleteEventMsg = useSelector(
    (state) => state.deleteEventReducer.message
  ); //Remove staff
  const deleteScheduleMsg = useSelector(
    (state) => state.deleteScheduleReducer.message
  ); //Remove staff
  const deleteTeamMsg = useSelector(
    (state) => state.deleteEventTeamReducer.message
  );
  const getEventSchedule = useSelector(
    (state) => state.getEventScheduleReducer.user.data
  );
  const getEventTeam = useSelector((state) => state.getEventTeamReducer.user);
  const getFreeTeam = useSelector(
    (state) => state.getFreeTeamReducer.user.data
  );
  const createDivisionMsg = useSelector(
    (state) => state.createDivisionReducer.message
  );

  const createPoolMsg = useSelector((state) => state.createPoolReducer.message);
  const deletePoolMsg = useSelector((state) => state.deletePoolReducer.message);
  const editPoolMsg = useSelector((state) => state.editPoolReducer.message);
  const editDivisionMsg = useSelector(
    (state) => state.editDivisionReducer.message
  );
  const deleteDivisionMsg = useSelector(
    (state) => state.deleteDivisionReducer.message
  );
  const ScheduleMsg = useSelector((state) => state.createTeamReducer.message);
  const updateFreeTeamMsg = useSelector(
    (state) => state.updateFreeTeamReducer.message
  );
  const teamGroupMsg = useSelector(
    (state) => state.createNewGroupReducer.message
  );
  const getTeamGroupList = useSelector(
    (state) => state.getTeamGroupsReducer.user.data
  );
  const addGroupMsg = useSelector(
    (state) => state.addPlayerToGroupReducer.message
  );
  const editGroupMsg = useSelector((state) => state.editGroupReducer.message);
  const deleteGroupMsg = useSelector(
    (state) => state.deleteGroupReducer.message
  );
  const editCodeMsg = useSelector(
    (state) => state.editPaymentCodeReducer.message
  );
  const playerAcceptList = useSelector(
    (state) => state.playerAcceptListReducer.user.data
  );

  const AccountingList = useSelector(
    (state) => state.getAccountingReducer.user.data
  );

  const getEventDetails = allEvent.find((e) => e._id == id); //find spacific event for Edit
  const [allDivision, setAllDivision] = useState({
    club: [],
    freeAgent: [],
  });

  // const [currentDivision, setcurrentDivision] = useState();
  const [allfieldmap, setallfieldmap] = useState();
  const [allstayandplay, setallstayandplay] = useState();
  const [status, setstatus] = useState("team");

  // useEffect(() => {
  //   if (allDivisionState)
  //     setcurrentDivision(allDivisionState.find((e) => e?._id == id1));
  // }, allDivisionState);

  useEffect(() => {
    if (allDivisionState) {
      setAllDivision((pre) => ({
        ...pre,
        club: allDivisionState.filter((e) => e.type == 0),
        freeAgent: allDivisionState.filter((e) => e.type == 1),
      }));
    }
  }, [allDivisionState]);
  useEffect(() => {
    if (getEventDetails !== undefined) {
      if (getEventDetails.event_type == 1 || getEventDetails.event_type == 2) {
        props.getTeamGroupsHandler({ id: id });
      }
    }
  }, [getEventDetails]);

  useEffect(() => {
    if (props.createStayandPlayReducer?.success) {
      notify(props.createStayandPlayReducer?.data?.message);
      history.push(`/event-details/${id}`);
      props.resetstayandplay();
    }
  }, [props.createStayandPlayReducer]);

  useEffect(() => {
    if (props.addteamtodivisionReducer?.success) {
      notify(props.addteamtodivisionReducer?.message);
    }
  }, [props.addteamtodivisionReducer]);

  useEffect(() => {
    if (props.switchAccessReducer?.success) {
      props.resetswitchReducer();
      props.getAllDivisionHandler({ id: id });
      props.getFreeTeamHandler({ id: id });
      props.getEventTeamHandler({ id: id });
    }
  }, [props.switchAccessReducer]);
  useEffect(() => {
    if (props.switchPlayerAccessReducer?.success) {
      props.resetswitchPlayerReducer();
      props.getAllDivisionHandler({ id: id });
      props.getAcceptedPlayerhandler({ id: id });
    }
  }, [props.switchPlayerAccessReducer]);

  useEffect(() => {
    props.getAllDivisionHandler({ id: id });
  }, []);

  const deleteMsg = useSelector((state) => state.deleteDivisionReducer.message);
  const msg = useSelector((state) => state.createDivisionReducer.message);

  useEffect(() => {
    if (props.submit?.success) {
      setLoading(false);
      notify(deleteEventMsg.message);
      props.resetdeletePaymentCodeReducer();
      props.getEventStaffHandler({ id: id });
      // history.push(`/event-details/${id}`);
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.createfieldmapReducer?.success) {
      props.resetcreatefielsmap();
      notify(props.createfieldmapReducer?.message?.message);
      history.push(`/event-details/${id}`);
    }
  }, [props.createfieldmapReducer]);

  useEffect(() => {
    if (props.updatefieldmapReducer?.success) {
      notify(props.updatefieldmapReducer?.message);
      props.resetupdatefieldmap();
    }
  }, [props.updatefieldmapReducer]);

  useEffect(() => {
    if (props.DeleteFiledMApReducer?.success) {
      notify("Delete Successfully");
      setIsDeleteFiledMap(false);
      setdeletedFiledMap(null);
      props.viewfieldmapActionhandler({ id: id });
      props.resetdeletefieldmap();
    }
  }, [props.DeleteFiledMApReducer]);

  useEffect(() => {
    if (props.viewfieldmapReducer?.success) {
      setLoading(false);
      setallfieldmap(props.viewfieldmapReducer?.user);
    }
  }, [props.viewfieldmapReducer]);

  useEffect(() => {
    if (props.ViewStayandPlayReducer?.success) {
      setLoading(false);
      setallstayandplay(props.ViewStayandPlayReducer?.user);
    }
  }, [props.ViewStayandPlayReducer]);

  useEffect(() => {
    if (props.updateScheduleReducer?.success) {
      setLoading(false);
      notify(props.updateScheduleReducer?.message);
      props.resetupdateschedule();
    }
  }, [props.updateScheduleReducer]);

  useEffect(() => {
    if (props.updateStayandPlay?.success) {
      setLoading(false);
      notify(props.updateStayandPlay?.data?.message);
      props.resetupdatestayandplay();
    }
  }, [props.updateStayandPlay]);
  useEffect(() => {
    if (props.deleteStayandPlay?.success) {
      setLoading(false);
      notify("Delete Successfully");
      props.resetdeleteStayAndPlay();
      props.viewStayandplayActionhandler({ id: id });
    }
  }, [props.deleteStayandPlay]);

  useEffect(() => {
    if (props.submit?.success) {
      setLoading(false);
      notify(deleteEventMsg.message);
      props.resetdeletePaymentCodeReducer();
      props.getEventActivityLogHandler({ id: id });
      // history.push(`/event-details/${id}`);
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.submit?.success) {
      notify(deleteEventMsg.message);
      props.resetdeletePaymentCodeReducer();
      props.getAcceptedPlayerhandler({ id: id });
      // history.push(`/event-details/${id}`);
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.submit?.success) {
      setLoading(false);
      notify(deleteEventMsg.message);
      props.resetdeletePaymentCodeReducer();
      props.getAccountinghandler({ id: id });
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.submit?.success) {
      setLoading(false);
      notify(deleteEventMsg.message);
      props.resetviewfieldmapReducer();
      props.viewfieldmapActionhandler({ id: id });
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.submit?.success) {
      setLoading(false);
      notify(deleteEventMsg.message);
      props.resetviewstayandplay();
      props.viewStayandplayActionhandler({ id: id });
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.submit1?.success) {
      setLoading(false);
      notify(deleteScheduleMsg.message);
      setIsDeleteSchedule(false);
      setdeletedSchedule(null);
      props.resetdeleteScheduleReducer();
      props.getEventScheduleHandler({ id: id });
    }
  }, [props.submit1]);

  useEffect(() => {
    if (props.createDivision?.success) {
      setLoading(false);
      notify(props.createDivision?.message?.message);
      props.resetcreatedivisionReducer();
      props.getAllDivisionHandler({ id: id });
    }
  }, [props.createDivision]);

  useEffect(() => {
    if (props.createPool?.success) {
      setLoading(false);
      notify(createPoolMsg.message);
      props.resetCreatePoolReducer();
      props.getAllDivisionHandler({ id: id });
    }
  }, [props.createPool]);

  //Delete Poll Successfull Msg
  useEffect(() => {
    if (props.deletePoolmessage?.success) {
      setLoading(false);
      notify(deletePoolMsg.message);
      props.resetDeletePoolReducer();
      props.getEventHandler({ id: 0 });
    }
  }, [props.deletePoolmessage]);

  //Add Schedule Successfull Msg
  useEffect(() => {
    if (props.schedulemessage?.success) {
      setLoading(false);
      notify(ScheduleMsg.message);
      props.resetscheduleReducer();
      props.getEventHandler({ id: 0 });
    }
  }, [props.schedulemessage]);

  //Create Team Successfull Msg
  useEffect(() => {
    if (props.createTeamMsg?.success) {
      setLoading(false);
      notify(ScheduleMsg.message);
      props.resetscheduleReducer();
      props.getEventHandler({ id: 0 });
    }
  }, [props.createTeamMsg]);

  //edit Poll Successfull Msg
  useEffect(() => {
    if (props.editPoolmessage?.success) {
      setLoading(false);
      notify(editPoolMsg.message);
      props.reseteditPoolReducer();
      props.getEventHandler({ id: 0 });
    }
  }, [props.editPoolmessage]);

  //edit Division Successfull Msg
  useEffect(() => {
    if (props.editDivisionmessage?.success) {
      setLoading(false);
      notify(editDivisionMsg.message);
      props.reseteditDivisionReducer();
      props.getEventHandler({ id: 0 });
    }
  }, [props.editDivisionmessage]);

  //delete Division Successfull Msg
  useEffect(() => {
    if (props.deleteDivisionmessage?.success) {
      setLoading(false);
      notify(deleteDivisionMsg.message);
      props.resetdeleteDivisionReducer();
      props.getEventHandler({ id: 0 });
      props.getAllDivisionHandler({ id: id });
    }
  }, [props.deleteDivisionmessage]);

  //Update free Team  Successfull Msg
  useEffect(() => {
    if (props.updateFreeTeam?.success) {
      setLoading(false);
      notify(props.updateFreeTeam?.success);
      props.resetFreetaemReducer();
      props.getEventHandler({ id: 0 });
    }
  }, [props.updateFreeTeam]);

  //remove team from pool
  useEffect(() => {
    if (props.deletePoolTeamMessage?.success) {
      setLoading(false);
      notify(props.deletePoolTeamMessage?.success);
      props.resetFreetaemReducer();
      props.getEventHandler({ id: 0 });
    }
  }, [props.deletePoolTeamMessage]);

  useEffect(() => {
    if (props.Team?.success) {
      setLoading(false);
      notify(deleteTeamMsg.message);
      props.resetdeleteEventTeamReducer();
      props.getEventTeamHandler({ id: id });
    }
  }, [props.Team]);

  //create Group for player
  useEffect(() => {
    if (props.teamGroup?.success) {
      setLoading(false);
      notify(teamGroupMsg.message);
      props.resetCreateGroupreducer();
    }
  }, [props.teamGroup]);

  //add player to group
  useEffect(() => {
    if (props.addTeamgroup?.success) {
      setLoading(false);
      notify(addGroupMsg.message);
      props.resetAddGroupreducer();
    }
  }, [props.addTeamgroup]);

  //Edit groups msg
  useEffect(() => {
    if (props.editGroup?.success) {
      setLoading(false);
      notify(editGroupMsg.message);
      props.resetEditGroupreducer();
    }
  }, [props.editGroup]);

  //Delete groups msg
  useEffect(() => {
    if (props.deleteGroup?.success) {
      setLoading(false);
      notify(deleteGroupMsg.message);
      props.resetdeleteGroupreducer();
      props.getTeamGroupsHandler({ id: id });
    }
  }, [props.deleteGroup]);

  useEffect(() => {
    if (props.deletePayment?.success) {
      setLoading(false);
      notify(deleteEventMsg.message);
      props.resetdeletePaymentCodeReducer();
      props.getEventHandler({ id: 0 });
      // history.push(`/event-details/${id}`);
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.editCode?.success) {
      setLoading(false);
      notify(editCodeMsg.message);
      props.reseteditPaymentCodeReducer();
      props.getEventHandler({ id: 0 });
      // history.push(`/event-details/${id}`);
    }
  }, [props.editCode]);

  useEffect(() => {
    if (props.CreateScheduleMessageReducer?.success) {
      setLoading(false);
      notify(props.CreateScheduleMessageReducer?.message);
      props.resetScheduleMsg();
    }
  }, [props.CreateScheduleMessageReducer]);

  useEffect(() => {
    if (props.createStayandPlayMessageReducer?.success) {
      setLoading(false);
      notify(props.createStayandPlayMessageReducer?.data?.message);
      props.resetStayandPlay();
    }
  }, [props.createStayandPlayMessageReducer]);

  useEffect(() => {
    if (props.CreateFieldmapMessageReducer?.success) {
      setLoading(false);
      notify(props.CreateFieldmapMessageReducer?.message);
      props.resetFiledMap();
    }
  }, [props.CreateFieldmapMessageReducer]);

  const [paymentcode, setPaymentcode] = useState(null);
  const [eventDateTime, setEventDateTime] = useState(null);
  useEffect(() => {
    if (getEventDetails) {
      getEventDetails.event_facility.map((x, i) => {
        setState((prevState) => ({
          ...prevState,
          startDate: getEventDetails?.event_start_date,
          endDate: getEventDetails?.event_end_date,
          startTime: getEventDetails?.event_from_time,
          endTime: getEventDetails?.event_to_time,
          reg_startDate: getEventDetails?.registration_start_date,
          reg_endDate: getEventDetails?.registration_end_date,
          reg_startTime: getEventDetails?.registration_start_time,
          reg_endTime: getEventDetails?.registration_end_time,
          event_name: getEventDetails?.event_name,
          event_image: getEventDetails?.event_image,
          event_type: getEventDetails?.event_type,
          facility_name: x?.facility_name,
          address: x?.facility_address,
          maxTeam: getEventDetails?.event_max_team,
          maxPlayer: getEventDetails?.event_max_player,
          city: x?.facility_city?.name,
          state: x?.facility_state?.name,
          option: getEventDetails?.event_type,
          event_access: getEventDetails?.event_access,
          website: x?.facility_website,
          event_Type: getEventDetails?.event_type,
          payment: "",
          person: "",
          season: getEventDetails?.event_season,
          numPlayers: getEventDetails?.show_number_of_player,
          eventFull: getEventDetails?.show_event_is_full,
          remainingSpots: getEventDetails?.show_remaining_spots,
          schedual_tab: getEventDetails?.show_schedule,
          reg_date: getEventDetails?.registration_date,
        }));
        // setStartDate(new Date(getEventDetails.event_from_date))
        // setEndDate(new Date(getEventDetails.event_to_date))
        setEventDateTime(getEventDetails.event_date_time);
        setPaymentcode(getEventDetails.payment_code);
      });

      if (getEventDetails.event_type == 1) {
        props.getAcceptedPlayerhandler({ id: id });
      }
    }
  }, [getEventDetails]);

  useEffect(() => {
    if (getEventDetails) {
      if (state.event_type) {
        props.getAcceptedPlayerhandler({ id: id });
      }
    }
  }, [state.event_type]);

  const geteventeamtorg = getEventTeam.organizer;
  const geteventteamclub = getEventTeam.club;

  const result = geteventeamtorg?.concat(geteventteamclub);

  var registrationTotalTeam = [];
  var registrationTotalPlayer = [];
  var paymentArray = [];
  var finalPaymentArray = [];

  result?.map((v, i) => {
    if (v.registrationStatus === 1) {
      registrationTotalTeam?.push(v);
    }
  });

  playerAcceptList?.map((v, i) => {
    if (v.registrationStatus === 1) {
      registrationTotalPlayer.push(v);
    }
  });

  getEventDetails?.event_division?.map((v, i) => {
    if (v.type === 0) {
      paymentArray.push(v);
    }
  });

  for (let i = 0; i < paymentArray.length; i++) {
    if (paymentArray[i]?.payment_code?.name) {
      finalPaymentArray?.push(paymentArray[i]?.payment_code?.name);
    }
  }
  useEffect(() => {
    if (props.Delete?.success) {
      props.deleteDivisionHandler({ id: id });
    }
  }, [props.Delete]);

  useEffect(() => {
    if (props.Delete?.success) {
      props.deleteEventTeamHandler({ id: id });
    }
  }, [props.Delete]);

  // const deletevent = () => {
  //   props.deleteDivisionHandler({ id: id });
  //   handleClose();
  // };

  const handleSwitch = (teamID, type, from) => {
    props.SwitchacccessActionhandler({
      team: teamID,
      type: type,
      from: from,
    });
  };

  const [message, setmessage] = useState({
    message: "",
  });
  const [error, seterror] = useState({
    message: false,
  });

  const [fieldmapmessage, setfieldmapmessage] = useState("");
  const [fieldmapmessageerror, setfieldmapmessageerror] = useState(false);

  const [stayandplay, setstayandplay] = useState("");
  const [stayandplayerror, setstayandplayerror] = useState(false);

  useEffect(() => {
    if (props.getAllEventReducer?.success) {
      const data = props.getAllEventReducer?.user?.find((e) => e?._id === id);
      setmessage((preve) => ({
        ...preve,
        message: data?.scheduleMessage,
      }));
      setfieldmapmessage(data?.fieldmapMessage);
      setstayandplay(data?.stayAndPlayMessage);
    }
  }, [props.getAllEventReducer]);

  const handletextarea = (e) => {
    const { name, value } = e.target;
    setmessage((preve) => ({
      ...preve,
      [name]: value,
    }));

    if (value == "") {
      seterror((preve) => ({
        ...preve,
        message: "error",
      }));
    } else {
      seterror((preve) => ({
        ...preve,
        message: false,
      }));
    }
  };

  const createScheduleMessage = () => {
    if (state.schedulemsg !== "") {
      props.CreateScheduleMessageAction({
        id: id,
        schedule_message: message.message,
      });
    }
  };

  const handlefieldmapmsg = (e) => {
    const { name, value } = e.target;
    setfieldmapmessage(value);
    if (value == "") {
      setfieldmapmessageerror("Error");
    } else {
      setfieldmapmessageerror(false);
    }
  };

  const handleSubmitfiledmapmsg = () => {
    if (fieldmapmessage !== "") {
      props.CreateFieldmapMessageAction({
        event: id,
        field_message: fieldmapmessage,
      });
    }
  };

  const handlestayandplay = (e) => {
    const { name, value } = e.target;
    setstayandplay(value);
    if (value == "") {
      setstayandplayerror("Error");
    } else {
      setstayandplayerror(false);
    }
  };

  const handlesubmitstayandplaymsg = () => {
    if (stayandplay !== "") {
      props.CreateStayandPlayMessageAction({
        event: id,
        message: stayandplay,
      });
    }
  };

  // useEffect(() => {
  // if(props.getEventTeamReducer.success) {
  // props.getEventTeamHandler({ id: id });
  // }
  //   },[props.getEventTeamReducer])
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          {getEventDetails ? (
            <>
              <div className="">
                <div className="create-team-head-event justify-content-end">
                  <div className="mt-3 mt-md-0">
                    <Link to={`/editEvent/${id}`}>
                      <button className="btn-save">Edit</button>
                    </Link>
                  </div>
                </div>
              </div>
              {/* ------------------Event Image ----------------------------- */}
              <div className="row mx-0">
                <div className="col-1 ms-2" style={{ float: "left" }}>
                  <div
                    className="border pt-4 pb-4 text-center text-light"
                    style={{ backgroundColor: "#ADD8E6", borderRadius: "10px" }}
                  >
                    <div>
                      {state.startDate
                        ? moment(state.startDate).format("MMM").toUpperCase()
                        : "Month"}
                    </div>
                    <div style={{ fontWeight: "bold", fontSize: "25px" }}>
                      {state.startDate
                        ? moment(state.startDate).format("D")
                        : "Day"}
                    </div>
                  </div>
                </div>

                <div style={{ paddingTop: "20px" }} className="col">
                  <h5
                    className="dash-head text-capitalize"
                    style={{
                      // marginLeft: "120px",
                      color: "#143D4A",
                      fontWeight: "bold",
                    }}
                  >
                    {state.event_name ? state.event_name : "n/A"}
                  </h5>
                  <i
                    className="mdi mdi-clock"
                    style={{
                      float: "left",
                      // marginLeft: "20px",
                      color: "#ADD8E6",
                    }}
                  ></i>
                  <span
                    style={{
                      float: "left",
                      marginLeft: "8px",
                      color: "#ADD8E6",
                    }}
                  >
                    Start
                  </span>
                  <span
                    className="ml-2 d-inline-block"
                    style={{ float: "left", color: "#ADD8E6" }}
                  >
                    {" "}
                    {state.startTime ? state.startTime : "N/A"}
                  </span>

                  <i
                    className="mdi mdi-clock "
                    style={{
                      float: "left",
                      marginLeft: "20px",
                      color: "#ADD8E6",
                    }}
                  ></i>
                  <span
                    style={{
                      float: "left",
                      marginLeft: "8px",
                      color: "#ADD8E6",
                    }}
                  >
                    End
                  </span>
                  <span
                    className="ml-2 d-inline-block"
                    style={{ float: "left", color: "#ADD8E6" }}
                  >
                    {" "}
                    {state.endTime ? state.endTime : "n/A"}
                  </span>
                </div>
              </div>

              <div className="team-details mt-md-0 " style={{}}>
                <ul
                  className="nav nav-pills mb-3"
                  style={{ marginTop: "50px" }}
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="details"
                      data-toggle="pill"
                      href="#detail-tab"
                      role="tab"
                      aria-controls="pills-details"
                      aria-selected="true"
                    >
                      Details
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="division"
                      data-toggle="pill"
                      href="#division-tab"
                      role="tab"
                      aria-controls="pills-division"
                      aria-selected="false"
                      onClick={() => [
                        props.getAllDivisionHandler({ id: id }),
                        props.getFreeTeamHandler({ id: id }),
                      ]}
                    >
                      Division
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="teams"
                      data-toggle="pill"
                      href="#team-tab"
                      role="tab"
                      aria-controls="pills-teams"
                      aria-selected="false"
                      onClick={() => {
                        setstatus("team");
                      }}
                    >
                      Teams
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="waitlist"
                      data-toggle="pill"
                      href="#waitlist-tab"
                      role="tab"
                      aria-controls="pills-waitlist"
                      aria-selected="false"
                      onClick={() => {
                        props.getFreeTeamHandler({ id: id });
                        setstatus("waitlistteam");
                      }}
                    >
                      Wait list
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="staff"
                      data-toggle="pill"
                      href="#staff-tab"
                      role="tab"
                      aria-controls="pills-staff"
                      aria-selected="false"
                      onClick={() => props.getEventStaffHandler({ id: id })}
                    >
                      Staff
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="ActivityLog"
                      data-toggle="pill"
                      href="#ActivityLog-tab"
                      role="tab"
                      aria-controls="pills-ActivityLog"
                      aria-selected="false"
                      onClick={() => {
                        props.getEventActivityLogHandler({ id: id });
                        props.getEventScheduleHandler({ id: id });
                      }}
                    >
                      Activity Log
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="schedule"
                      data-toggle="pill"
                      href="#schedule-tab"
                      role="tab"
                      aria-controls="pills-schedule"
                      aria-selected="false"
                      onClick={() => props.getEventScheduleHandler({ id: id })}
                    >
                      Schedule
                    </a>
                  </li>

                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="fieldmap"
                      data-toggle="pill"
                      href="#fieldmap-tab"
                      role="tab"
                      aria-controls="pills-fieldmap"
                      aria-selected="false"
                      onClick={() =>
                        props.viewfieldmapActionhandler({ id: id })
                      }
                    >
                      Filed map
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="stayandplay"
                      data-toggle="pill"
                      href="#stayandplay-tab"
                      role="tab"
                      aria-controls="pills-stayandplay"
                      aria-selected="false"
                      onClick={() =>
                        props.viewStayandplayActionhandler({ id: id })
                      }
                    >
                      Stay and Play
                    </a>
                  </li>
                  {getEventDetails?.is_paid_event == 1 && (
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="accounting"
                        data-toggle="pill"
                        href="#accounting-tab"
                        role="tab"
                        aria-controls="pills-accounting"
                        aria-selected="false"
                        onClick={() => props.getAccountinghandler({ id: id })}
                      >
                        Accounting
                      </a>
                    </li>
                  )}
                </ul>

                <div className="tab-content" id="pills-tabContent">
                  {/* <<----------------------------   Details  Start  --------------------------->> */}
                  <div
                    className="tab-pane fade show active event-table"
                    id="detail-tab"
                    role="tabpanel"
                    aria-labelledby="pills-details"
                  >
                    <div className="mt-30">
                      {/* <div className="d-flex justify-content-end">
                                                    <div className="dropdown details-drp">
                                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                            <a className="dropdown-item" href="#">Invite Team Action</a>
                                                        </div>
                                                    </div>
                                                </div> */}
                      {/* <h4>Event Details </h4> */}
                      <div className="row col-xl-8 px-0">
                        <div className="col-12 table-line">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Event Access :{" "}
                          </label>
                          <i
                            className="mdi mdi-web ml-1"
                            style={{ color: "#80bdff" }}
                          ></i>
                          <span
                            className="ml-1 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {state.event_access == 0 ? "Public" : "Private"}
                          </span>
                        </div>
                        <div className="col-12 table-line ">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Event Type :{" "}
                          </label>
                          <span
                            className="ml-2 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {state.event_type == 0
                              ? "Club only"
                              : state.event_type == 1
                              ? "Parent/Player"
                              : state.event_type == 2
                              ? "Club & Parent/Player"
                              : ""}
                          </span>
                        </div>
                        <div className="col-12 table-line">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Registration Link :{" "}
                          </label>
                          <button
                            className="btn  p-0 ml-1"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                            onClick={copy}
                          >
                            <i
                              className="mdi mdi-open-in-new website-ico"
                              style={{ color: "#80bdff" }}
                            ></i>{" "}
                            {!copied ? "Copy Invite link" : "Copied!"}
                          </button>
                        </div>
                        <div className="col-md-12 table-line">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Registration Start Date :{" "}
                          </label>
                          <span
                            className="ml-2 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {state.reg_startDate
                              ? moment(state.reg_startDate)?.format(
                                  "MM-DD-yyyy"
                                )
                              : "n/A"}
                          </span>
                        </div>
                        <div className="col-md-12 table-line">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Registration End Date :{" "}
                          </label>
                          <span
                            className="ml-2 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {state.reg_endDate
                              ? moment(state.reg_endDate).format("MM-DD-yyyy")
                              : "N/A"}
                          </span>
                        </div>
                        <div className="col-md-12 table-line">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Registration Start Time :{" "}
                          </label>
                          <span
                            className="ml-2 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {" "}
                            {state.reg_startTime ? state.reg_startTime : "N/A"}
                          </span>
                        </div>
                        <div className="col-md-12 table-line">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Registration End Time :{" "}
                          </label>
                          <span
                            className="ml-2 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {" "}
                            {state.reg_endTime ? state.reg_endTime : "N/A"}
                          </span>
                        </div>
                        <div className="col-12 table-line">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Facility Name :{" "}
                          </label>
                          <span
                            className="ml-2 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {state.facility_name ? state.facility_name : "N/A"}
                          </span>
                        </div>
                        <div className="col-12 table-line d-flex">
                          <div className=" col-6" style={{ color: "#189ad6" }}>
                            Facility Address :{" "}
                          </div>
                          <div
                            className="ml-2 col-6 d-inline-block"
                            style={{ fontWeight: "bold" }}
                          >
                            {/* {state.address +
                              " , " +
                              state.state +
                              " , " +
                              state.city} */}
                            {`${state.address ? state.address : "N/A"}, ${
                              state.state ? state.state : "N/A"
                            }, ${state.city ? state.city : "N/A"}`}
                          </div>
                        </div>
                        {state.website ? (
                          <div className="col-12 table-line">
                            <label
                              className=" col-6"
                              style={{ color: "#189ad6" }}
                            >
                              Facility Website :{" "}
                            </label>
                            <span className="ml-2 d-inline-block ">
                              <a
                                className="text-dark text-decoration-none"
                                href={state.website}
                                style={{ fontWeight: "bold", color: "#143D4A" }}
                              >
                                {state.website ? state.website : "N/A"}
                              </a>
                            </span>
                          </div>
                        ) : (
                          <div className="col-12 col-6 table-line">
                            <label
                              className=" col-6"
                              style={{ color: "#189ad6" }}
                            >
                              Facility Website :{" "}
                            </label>
                            <span
                              className="ml-2 d-inline-block text-decoration-none"
                              style={{ fontWeight: "bold", color: "#143D4A" }}
                            >
                              NA
                            </span>
                          </div>
                        )}
                        <div className="col-12 table-line">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Maximum Team :{" "}
                          </label>
                          <span
                            className="ml-2 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {`${registrationTotalTeam?.length + " / "}${
                              state?.maxTeam ? state?.maxTeam : "0"
                            }`}
                          </span>
                        </div>

                        <div className="col-12 table-line">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Maximum Player :{" "}
                          </label>
                          <span
                            className="ml-2 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {`${registrationTotalPlayer?.length + " / "}${
                              state?.maxPlayer ? state?.maxPlayer : "0"
                            }`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <<<<<<----------------------------   Details End  ---------------------------->>>>>> */}

                  {/* <<<<<<----------------------------   Division Start  ---------------------------->>>>>> */}

                  <div
                    className="tab-pane fade"
                    id="division-tab"
                    role="tabpanel"
                    aria-labelledby="pills-division"
                  >
                    <div
                      className="d-flex justify-content-beetwen"
                      style={{ marginTop: "30px" }}
                    >
                      <div className="col-10">
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                          Divisions
                        </span>
                      </div>
                      <div className="col">
                        <Link to={`/adddivision/${id}`}>
                          <button className="add-division-btn">
                            Add Division
                          </button>
                        </Link>
                      </div>
                    </div>
                    <ul
                      className="nav nav-pills mb-3 mt-4"
                      id="pills-tab"
                      role="tablist"
                    >
                      {state.event_type == 0 ||
                        (state.event_type == 2 && (
                          <li className="nav-item" role="presentation">
                            <a
                              className={classNames("nav-link", {
                                active:
                                  state.event_type == 0 ||
                                  state.event_type == 2,
                              })}
                              id="division-club"
                              data-toggle="pill"
                              href="#pills-divisionclub"
                              role="tab"
                              aria-controls="pills-divisionclub"
                              aria-selected="false"
                            >
                              Club
                            </a>
                          </li>
                        ))}
                      {state.event_type == 1 ||
                        (state.event_type == 2 && (
                          <li className="nav-item" role="presentation">
                            <a
                              className={classNames("nav-link", {
                                active: state.event_type == 1,
                              })}
                              id="division-freeAgents"
                              data-toggle="pill"
                              href="#pills-division-freeAgents"
                              role="tab"
                              aria-controls="pills-division-freeAgents"
                              aria-selected="false"
                            >
                              Free Agents
                            </a>
                          </li>
                        ))}
                    </ul>
                    <div className="tab-content" id="team-tabContent">
                      <div
                        className={classNames("tab-pane fade ", {
                          "show active":
                            state.event_type == 0 || state.event_type == 2,
                        })}
                        id="pills-divisionclub"
                        role="tabpanel"
                        aria-labelledby="division-club"
                      >
                        {allDivision.club.length == 0 ? (
                          <>
                            <div className="d-flex justify-content-center text-gray">
                              No Division Found
                            </div>
                          </>
                        ) : (
                          <>
                            {
                              <Table className="table event-table">
                                <thead>
                                  <tr>
                                    <th>Division</th>
                                    <th>Teams</th>
                                    <th>Payment Code</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {allDivision.club.map((v, i) => {
                                    return (
                                      <tr key={v._id}>
                                        <td>
                                          <Link
                                            className="text-blue text-decoration-none text-dark"
                                            to={`/event-division-details/${id}/${v._id}`}
                                          >
                                            {v.division_name}{" "}
                                          </Link>
                                        </td>

                                        <td>
                                          {v.max_participated == null
                                            ? "Unlimited"
                                            : v.totalRegTeam +
                                              " / " +
                                              v.max_participated}
                                        </td>
                                        <td>
                                          {!!getEventDetails && !!v.payment_code
                                            ? getEventDetails?.payment_code.find(
                                                (e) => e._id === v?.payment_code
                                              )?.name
                                            : "N/A"}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            }
                          </>
                        )}
                        <div className="mt-4">
                          <strong>Free Teams</strong>
                          {!!getFreeTeam && getFreeTeam.length !== 0 ? (
                            <>
                              {
                                <Table className="table event-table">
                                  <thead>
                                    <tr>
                                      <th>team</th>
                                      <th>Registration</th>
                                      <th>Payment</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {getFreeTeam.map((v, i) => {
                                      const amount = !!v.amount
                                        ? v.amount.toString()
                                        : false;
                                      const panding_amount = !!v.panding_amount
                                        ? v.panding_amount.toString()
                                        : false;
                                      return (
                                        <tr>
                                          <td>
                                            <Link
                                              to={{
                                                pathname: `/team-details/${v._id}`,
                                                state: {
                                                  path: "event",
                                                },
                                              }}
                                              className="text-blue text-decoration-none text-dark"
                                            >
                                              {v?.team_id?.team_name ||
                                                v?.team?.team_name}
                                            </Link>
                                          </td>
                                          <td>
                                            {v.registrationStatus == 0
                                              ? "Waitlist"
                                              : "registered"}
                                          </td>
                                          <td>
                                            {!!amount &&
                                            !!panding_amount &&
                                            panding_amount == 0 &&
                                            amount == 0
                                              ? "Free"
                                              : (amount != 0 &&
                                                  panding_amount != 0) ||
                                                (!amount && !panding_amount)
                                              ? "Partially paid"
                                              : !!amount &&
                                                !!panding_amount &&
                                                amount > 0 &&
                                                panding_amount == 0
                                              ? "Paid"
                                              : "Unpaid"}
                                          </td>
                                          <td>
                                            {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> */}
                                            {/* <i className="mdi mdi-plus website-ico mr-2 " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add More</i> */}
                                            <i
                                              className="mdi mdi-dots-horizontal text-dark pointer"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            ></i>

                                            <div
                                              className="dropdown-menu pointer"
                                              aria-labelledby="dropdownMenuLink"
                                            >
                                              <div>
                                                {" "}
                                                {v.registrationStatus == 1 && (
                                                  <div className="py-2 px-3">
                                                    <Link
                                                      to={`/updatepooldivision/${id}/${v?._id}`}
                                                      className="text-decoration-none pointer"
                                                    >
                                                      <i className="mdi mdi-plus text-blue mr-2"></i>
                                                      <span className="text-dark">
                                                        Add to Division
                                                      </span>
                                                    </Link>
                                                  </div>
                                                )}
                                              </div>
                                              <div>
                                                {v.registrationStatus == 0 ? (
                                                  <div
                                                    className="text-decoration-none pointer py-2 px-3"
                                                    onClick={() => {
                                                      props.SwitchacccessActionhandler(
                                                        {
                                                          team: v._id,
                                                          type: v.type,
                                                          from: 1,
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <i className="fa text-blue fa-repeat mr-2"></i>
                                                    <span className="text-dark">
                                                      Switch to Registered
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="text-decoration-none pointer mt-2 py-2 px-3"
                                                    onClick={() => {
                                                      props.SwitchacccessActionhandler(
                                                        {
                                                          team: v._id,
                                                          type: v.type,
                                                          from: 1,
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <i className="fa text-blue fa-repeat mr-2"></i>
                                                    <span className="text-dark">
                                                      Switch to wait list
                                                    </span>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              }
                            </>
                          ) : (
                            <div className="mt-4">
                              <div className="d-flex justify-content-center text-gray">
                                No Free Teams Found
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={classNames("tab-pane fade ", {
                          "show active": state.event_type == 1,
                        })}
                        id="pills-division-freeAgents"
                        role="tabpanel"
                        aria-labelledby="division-freeAgents"
                      >
                        {allDivision.freeAgent.length == 0 ? (
                          <>
                            <div className="d-flex justify-content-center text-gray">
                              No Division Found
                            </div>
                          </>
                        ) : (
                          <>
                            {
                              <Table className="table event-table">
                                <thead>
                                  <tr>
                                    <th>Division</th>
                                    <th>Teams</th>
                                    <th>Payment Code</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {allDivision.freeAgent.map((v, i) => {
                                    return (
                                      <tr key={v._id}>
                                        <td>
                                          <Link
                                            className="text-blue text-decoration-none text-dark"
                                            to={`/event-division-details/${id}/${v._id}`}
                                          >
                                            {v.division_name}{" "}
                                          </Link>
                                        </td>

                                        <td>
                                          {v.max_participated == null
                                            ? "Unlimited"
                                            : v.totalRegPlayer +
                                              " / " +
                                              v.max_participated}
                                        </td>
                                        <td>
                                          {!!getEventDetails && !!v.payment_code
                                            ? getEventDetails?.payment_code.find(
                                                (e) => e._id == v.payment_code
                                              )?.name
                                            : "N/A"}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            }
                          </>
                        )}

                        <div className="mt-4">
                          <strong>Unassigned free Agents</strong>
                          {!!playerAcceptList &&
                          playerAcceptList.length !== 0 ? (
                            <>
                              <Table className="table event-table">
                                <thead>
                                  <tr>
                                    <th>Team</th>
                                    {/* <th>Division</th> */}
                                    <th>registration</th>
                                    {/* <th>Division Status</th> */}
                                    <th>Payment</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>

                                {/* <tbody> */}
                                {playerAcceptList
                                  ?.filter((e) => e.division_player == false)
                                  .map((v, i) => {
                                    return (
                                      <tbody key={i}>
                                        <tr>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <div>
                                                <img
                                                  src={
                                                    v.player?.profile_image !==
                                                    ""
                                                      ? v.player?.profile_image
                                                      : "./images/pch-images/pch.svg"
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                              <div className="ml-2">
                                                <Link
                                                  to={{
                                                    pathname: `/team-details/${v._id}`,
                                                    state: {
                                                      path: "event",
                                                    },
                                                  }}
                                                  className="text-blue text-decoration-none"
                                                >
                                                  {v.player?.first_name +
                                                    " " +
                                                    v.player?.last_name}
                                                </Link>
                                              </div>
                                            </div>
                                          </td>

                                          <td>
                                            {v?.registrationStatus == 0
                                              ? "Waitlist"
                                              : "Registered"}
                                          </td>
                                          <td>
                                            {v?.player?.panding_amount === 0
                                              ? "Paid"
                                              : "Unpaid"}
                                          </td>
                                          <td>
                                            <i
                                              className="mdi mdi-dots-horizontal text-dark pointer"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            ></i>

                                            <div
                                              className="dropdown-menu pointer"
                                              aria-labelledby="dropdownMenuLink"
                                            >
                                              <div>
                                                {" "}
                                                {v.registrationStatus == 1 && (
                                                  <div className="py-2 px-3">
                                                    <Link
                                                      to={`/event-freeAgents/${id}/${v?._id}`}
                                                      className="text-decoration-none pointer"
                                                    >
                                                      <i className="mdi mdi-plus text-blue mr-2"></i>
                                                      <span className="text-dark">
                                                        Add to Division
                                                      </span>
                                                    </Link>
                                                  </div>
                                                )}
                                              </div>
                                              <div>
                                                {v.registrationStatus == 0 ? (
                                                  <div
                                                    className="text-decoration-none pointer py-2 px-3"
                                                    onClick={() => {
                                                      props.SwitchacccessPlayerAction(
                                                        {
                                                          player: v._id,
                                                          from: 1,
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <i className="fa text-blue fa-repeat mr-2"></i>
                                                    <span className="text-dark">
                                                      Switch to Registered
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="text-decoration-none pointer mt-2 py-2 px-3"
                                                    onClick={() => {
                                                      props.SwitchacccessPlayerAction(
                                                        {
                                                          player: v._id,
                                                          from: 1,
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <i className="fa text-blue fa-repeat mr-2"></i>
                                                    <span className="text-dark">
                                                      Switch to wait list
                                                    </span>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  })}
                                {/* </tbody> */}
                              </Table>
                            </>
                          ) : (
                            <>
                              <div className="mt-4">
                                <div className="d-flex justify-content-center text-gray">
                                  No Parent / Players Found
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <<<<<<----------------------------   Division End  ---------------------------->>>>>> */}

                  {/* <<<<<<----------------------------   Team Start  ---------------------------->>>>>> */}

                  <div
                    className="tab-pane fade"
                    id="team-tab"
                    role="tabpanel"
                    aria-labelledby="pills-teams"
                  >
                    {getEventDetails.event_type == 0 ||
                    getEventDetails.event_type == 2 ? (
                      <>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <ul
                              className="nav nav-pills my-3"
                              id="pills-tab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <a
                                  className="nav-link active"
                                  id="org-team"
                                  data-toggle="pill"
                                  href="#pills-orgTeam"
                                  role="tab"
                                  aria-controls="pills-orgTeam"
                                  aria-selected="true"
                                  onClick={() => {
                                    setstatus("team");
                                  }}
                                >
                                  Teams
                                </a>
                              </li>
                              <li className="nav-item" role="presentation">
                                <a
                                  className="nav-link"
                                  id="org-InternalTeam"
                                  data-toggle="pill"
                                  href="#pills-orgInteralTeam"
                                  role="tab"
                                  aria-controls="pills-orgInteralTeam"
                                  aria-selected="false"
                                  onClick={() => {
                                    setstatus("internalteam");
                                  }}
                                >
                                  Internal teams
                                </a>
                              </li>
                              <li className="nav-item" role="presentation">
                                <a
                                  className="nav-link"
                                  id="org-freeAgents"
                                  data-toggle="pill"
                                  href="#pills-org-freeAgents"
                                  role="tab"
                                  aria-controls="pills-org-freeAgents"
                                  aria-selected="false"
                                >
                                  Free Agents
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown details-drp ">
                            <i
                              className="mdi mdi-dots-vertical ms-auto"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></i>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <Link
                                className="dropdown-item"
                                to={`/club-invite/${id}`}
                              >
                                Invite Club
                              </Link>
                              <Link
                                className="dropdown-item"
                                to={`/org-invite-player/${id}`}
                              >
                                Invite Player
                              </Link>
                              <Link
                                className="dropdown-item"
                                to={`/create-team/${id}`}
                              >
                                Create Internal Teams
                              </Link>
                              <Link className="dropdown-item" to="/report">
                                Payment Request
                              </Link>
                              <Link
                                className="dropdown-item"
                                to={`/search-team/${id}`}
                              >
                                Search Team
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="tab-content" id="team-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-orgTeam"
                            role="tabpanel"
                            aria-labelledby="org-team"
                          >
                            <div className="d-flex justify-content-between">
                              <h6 className="text-left payment mt-30">Teams</h6>
                            </div>
                            {getEventTeam ? (
                              <>
                                {getEventTeam.club &&
                                getEventTeam.club.length !== 0 ? (
                                  <>
                                    {/* desktop view */}
                                    <div className="mt-24 table-responsive col-xl-8 col-lg-10 px-0">
                                      <table className="table event-table">
                                        <thead>
                                          <tr>
                                            <th>Team</th>
                                            <th>Division</th>
                                            <th>Registration</th>
                                            {/* <th>Pool</th> */}
                                            <th>Payment</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {getEventTeam?.club?.map((v) => {
                                            return (
                                              <tr key={v._id}>
                                                <td>
                                                  <Link
                                                    to={`/Teaminsideteam/${v._id}`}
                                                    className="text-blue text-decoration-none"
                                                  >
                                                    {
                                                      (v.team_id || v.team)
                                                        ?.team_name
                                                    }
                                                  </Link>
                                                </td>

                                                <td>
                                                  <Link
                                                    to={`/team-details/${id}/${v.team_id?._id}`}
                                                    className="d-flex align-items-center text-dark text-decoration-none"
                                                  >
                                                    {/* <div className="dashboard-img">
                                                                                                                <img src={v.team_id?.image !== "" ? v.team_id?.image : "./images/pch-images/pch.svg"} />
                                                                                                            </div> */}
                                                    <span className="ml-12">
                                                      {v.team_id === null
                                                        ? "Null"
                                                        : v.division
                                                            ?.division_name
                                                        ? v.division
                                                            ?.division_name
                                                        : " - "}
                                                    </span>
                                                  </Link>
                                                </td>

                                                {/* <td>{v.team.division === null ? "Null" : v.team.division} </td> */}
                                                <td>
                                                  {v.registrationStatus == 1
                                                    ? "Registered"
                                                    : "Wiatlist"}
                                                </td>
                                                {/* <td>{v.pool === null ? "null" : v.pool.pool_name}</td> */}
                                                <td>
                                                  <span
                                                    className={
                                                      "badge " +
                                                      (v.registrationStatus == 0
                                                        ? "badge-warning"
                                                        : "badge-success")
                                                    }
                                                  >
                                                    {v.registrationStatus == 0
                                                      ? "Paid"
                                                      : "Unpaid"}{" "}
                                                  </span>
                                                </td>
                                                <td>
                                                  <i
                                                    className="mdi mdi-dots-horizontal pointer"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  ></i>
                                                  <div
                                                    className="dropdown-menu pointer"
                                                    aria-labelledby="dropdownMenuLink"
                                                  >
                                                    <Link
                                                      className="dropdown-item"
                                                      to={`/event-details/${id}`}
                                                      //  onClick={() => props.deleteEventTeamHandler({ id: v._id, type: 0 })}
                                                      onClick={() => {
                                                        setisDeleteTeam(true);
                                                        setDeleteIdTeam(v._id);
                                                      }}
                                                    >
                                                      <i className="mr-2 fas fa-trash-alt text-blue"></i>{" "}
                                                      {/* <span className="text-blue"> */}
                                                      Delete
                                                      {/* </span> */}
                                                    </Link>

                                                    {v.team_id === null ? (
                                                      "Null"
                                                    ) : v.division
                                                        ?.division_name ? (
                                                      <Link
                                                        to={`/updatepooldivision/${id}/${v?._id}`}
                                                        className="dropdown-item"
                                                      >
                                                        <i className="mdi mdi-plus text-blue mr-2"></i>
                                                        {/* <span className="text-blue"> */}
                                                        Switch to Division
                                                        {/* </span> */}
                                                      </Link>
                                                    ) : (
                                                      <Link
                                                        to={`/updatepooldivision/${id}/${v?._id}`}
                                                        className="dropdown-item"
                                                      >
                                                        <i className="mdi mdi-plus text-blue mr-2"></i>
                                                        {/* <span className="text-blue"> */}
                                                        Add to Division
                                                        {/* </span> */}
                                                      </Link>
                                                    )}

                                                    {v.registrationStatus ==
                                                    1 ? (
                                                      <div
                                                        className="dropdown-item"
                                                        onClick={() => {
                                                          handleSwitch(
                                                            v?._id,
                                                            0,
                                                            1
                                                          );
                                                        }}
                                                      >
                                                        <i className="mdi mdi-plus text-blue mr-2"></i>
                                                        {/* <span className="text-blue"> */}
                                                        Switch to wait list
                                                        {/* </span> */}
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="dropdown-item"
                                                        onClick={() => {
                                                          handleSwitch(
                                                            v?._id,
                                                            0,
                                                            1
                                                          );
                                                        }}
                                                      >
                                                        <i className="mdi mdi-plus text-blue mr-2"></i>
                                                        {/* <span className="text-blue"> */}
                                                        Switch to registred
                                                        {/* </span> */}
                                                      </div>
                                                    )}
                                                  </div>
                                                  {/* </div> */}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className=" d-flex justify-content-center align-items-center">
                                      <span className="noti-type">
                                        No Teams Found
                                      </span>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <div className="w-full h-[60vh] flex flex-col justify-center items-center">
                                  <Spinner />
                                </div>
                              </>
                            )}
                          </div>

                          <div
                            className="tab-pane fade"
                            id="pills-orgInteralTeam"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                          >
                            <div className="d-flex justify-content-between">
                              <h6 className="text-left payment mt-30">Teams</h6>
                            </div>
                            {getEventTeam ? (
                              <>
                                {getEventTeam.organizer &&
                                getEventTeam.organizer.length !== 0 ? (
                                  <>
                                    {/* desktop view */}
                                    <div className="mt-24 table-responsive col-xl-8 col-lg-10 px-0">
                                      <table className="table event-table">
                                        <thead>
                                          <tr>
                                            <th>Team</th>
                                            <th>Division</th>
                                            <th>Registration</th>
                                            {/* <th>Pool</th> */}
                                            <th>Payment</th>
                                            <th>Actions</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {getEventTeam?.organizer?.map((v) => {
                                            return (
                                              <tr key={v._id}>
                                                <td>
                                                  <Link
                                                    to={{
                                                      pathname: `/team-details/${v._id}`,
                                                      state: {
                                                        path: "event",
                                                      },
                                                    }}
                                                    className="text-decoration-none text-gray"
                                                  >
                                                    {
                                                      (v.team_id || v.team)
                                                        ?.team_name
                                                    }
                                                  </Link>
                                                </td>

                                                {/* <td>{v.team.division === null ? "Null" : v.team.division} </td> */}
                                                <td>
                                                  {v.division?.division_name
                                                    ? v.division?.division_name
                                                    : "-"}
                                                </td>
                                                <td>
                                                  {v.registrationStatus == 1
                                                    ? "Registered"
                                                    : "Waitlist"}
                                                </td>
                                                {/* <td>{v.pool === null ? "null" : v.pool.pool_name}</td> */}
                                                <td>
                                                  <span
                                                    className={
                                                      "badge " +
                                                      (v.registrationStatus == 1
                                                        ? "badge-success"
                                                        : "badge-warning")
                                                    }
                                                  >
                                                    {v.registrationStatus == 1
                                                      ? "Paid"
                                                      : "Unpaid"}{" "}
                                                  </span>
                                                </td>
                                                <td>
                                                  <i
                                                    className="mdi mdi-dots-horizontal pointer"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  ></i>
                                                  <div
                                                    className="dropdown-menu pointer"
                                                    aria-labelledby="dropdownMenuLink"
                                                  >
                                                    {/* <div
                                                      className="dropdown-menu"
                                                      aria-labelledby="dropdownMenuLink"
                                                    > */}
                                                    <Link
                                                      to={`/editteam/${v.team_id?._id}/${id}`}
                                                      className="dropdown-item"
                                                    >
                                                      <i className="mdi mdi-border-color text-blue mr-2"></i>
                                                      <span className="text-blue">
                                                        Edit
                                                      </span>
                                                    </Link>
                                                    <Link
                                                      to={`/event-details/${id}`}
                                                      //  onClick={() => props.deleteEventTeamHandler({ id: v._id, type: 0 })}
                                                      onClick={() => {
                                                        setisDeleteTeam(true);
                                                        setDeleteIdTeam(v._id);
                                                      }}
                                                      className="dropdown-item"
                                                    >
                                                      <i className="fas fa-trash-alt text-blue mr-2 "></i>{" "}
                                                      <span className="text-blue">
                                                        Delete
                                                      </span>
                                                    </Link>
                                                    {v.team_id === null
                                                      ? "Null"
                                                      : v.registrationStatus ===
                                                          1 && (
                                                          <Link
                                                            to={`/updatepooldivision/${id}/${v?._id}`}
                                                            className="dropdown-item"
                                                          >
                                                            <i className="mdi mdi-plus text-blue mr-2"></i>
                                                            <span className="text-blue">
                                                              Switch to Division
                                                            </span>
                                                          </Link>
                                                        )}
                                                    {v.registrationStatus ==
                                                    1 ? (
                                                      <div
                                                        className="dropdown-item"
                                                        onClick={() => {
                                                          handleSwitch(
                                                            v?._id,
                                                            1,
                                                            1
                                                          );
                                                        }}
                                                      >
                                                        <i className="mdi mdi-plus text-blue mr-2"></i>
                                                        <span className="text-blue">
                                                          Switch to wait list
                                                        </span>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="dropdown-item"
                                                        onClick={() => {
                                                          handleSwitch(
                                                            v?._id,
                                                            1,
                                                            1
                                                          );
                                                        }}
                                                      >
                                                        <i className="mdi mdi-plus text-blue mr-2"></i>
                                                        <span className="text-blue">
                                                          Switch to registred
                                                        </span>
                                                      </div>
                                                    )}
                                                    {/* </div> */}
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="d-flex justify-content-center align-items-center">
                                      <span className="noti-type">
                                        No Teams Found
                                      </span>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <div className="d-flex justify-content-center">
                                  <div className="w-full h-[60vh] flex flex-col justify-center items-center">
                                    <Spinner />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          <div
                            className="tab-pane fade"
                            id="pills-org-freeAgents"
                            role="tabpanel"
                            aria-labelledby="org-freeAgents"
                          >
                            {!!playerAcceptList && (
                              <>
                                <p
                                  className="mt-5"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Free Agents Assignes to Teams
                                </p>
                                {playerAcceptList.filter(
                                  (ev) => !!ev.team_player
                                ).length == 0 ? (
                                  <>
                                    <p className="text-center">
                                      No Parent/Players Found
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <Table className="table event-table">
                                      <thead>
                                        <tr>
                                          <th>Players</th>
                                          <th>Registration</th>
                                          <th>Payment</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {playerAcceptList.map((v) => {
                                          if (v.team_player) {
                                            return (
                                              <tr key={v._id}>
                                                <td>
                                                  <Link
                                                    to={{
                                                      pathname: `/team-details/${v._id}`,
                                                      state: {
                                                        path: "event",
                                                      },
                                                    }}
                                                    className="text-blue text-decoration-none"
                                                  >
                                                    {(v.team_id || v.player)
                                                      ?.first_name +
                                                      " " +
                                                      (v.team_id || v.player)
                                                        ?.last_name}
                                                  </Link>
                                                </td>
                                                <td>
                                                  {v.team_player == true
                                                    ? "waitlist"
                                                    : "registered"}
                                                </td>
                                                <td>
                                                  {v.player?.amountStatus == 1
                                                    ? "Unpaid"
                                                    : "Paid"}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        })}
                                      </tbody>
                                    </Table>
                                  </>
                                )}
                              </>
                            )}
                            {!!playerAcceptList && (
                              <>
                                <p
                                  className="mt-5"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Unassigned free Agents
                                </p>
                                {playerAcceptList.filter(
                                  (ev) => !ev.team_player
                                ).length == 0 ? (
                                  <>
                                    <p className="text-center">
                                      No Parent/Players Found
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <Table className="table event-table">
                                      <thead>
                                        <tr>
                                          <th>Players</th>
                                          <th>Division</th>
                                          <th>Registration</th>
                                          <th>Payment</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {playerAcceptList.map((v) => {
                                          if (v.team_player == false) {
                                            return (
                                              <tr key={v._id}>
                                                <td>
                                                  <Link
                                                    to={`/player-detail/${v._id}`}
                                                    className="text-blue text-decoration-none"
                                                  >
                                                    {(v.team_id || v.player)
                                                      ?.first_name +
                                                      " " +
                                                      (v.team_id || v.player)
                                                        ?.last_name}
                                                  </Link>
                                                </td>
                                                <td>
                                                  {v.division
                                                    ? v.division?.division_name
                                                    : "-"}
                                                </td>
                                                <td>
                                                  {v?.registrationStatus == 0
                                                    ? "waitlist"
                                                    : "registered"}
                                                </td>
                                                <td>
                                                  {v?.panding_amount == 0
                                                    ? "Unpaid"
                                                    : "Paid"}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        })}
                                      </tbody>
                                    </Table>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <p className="mt-5">Free Agents Assignes to Teams</p>
                        <p className="text-center">No Parent/Players Found</p>
                      </>
                    )}
                  </div>
                  {/* <<<<<<----------------------------   Team End  ---------------------------->>>>>> */}

                  {/* ..................................   Wait List Start ................................... */}

                  <div
                    className="tab-pane fade"
                    id="waitlist-tab"
                    role="tabpanel"
                    aria-labelledby="pills-waitlist"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="waitlist-club-tab"
                      role="tabpanel"
                      aria-labelledby="pills-waitlist"
                    >
                      {/* <p>Wait List</p> */}

                      <ul
                        className="nav nav-pills mb-3 mt-5"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link active"
                            id="waitlist-team"
                            data-toggle="pill"
                            href="#pills-waitlistTeam"
                            role="tab"
                            aria-controls="pills-waitlistTeam"
                            aria-selected="true"
                            onClick={() => {
                              setstatus("waitlistteam");
                            }}
                          >
                            Teams
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link"
                            id="waitlist-InternalTeam"
                            data-toggle="pill"
                            href="#pills-waitlistInteralTeam"
                            role="tab"
                            aria-controls="pills-waitlistInteralTeam"
                            aria-selected="false"
                            onClick={() => {
                              setstatus("waitlistinternalteam");
                            }}
                          >
                            Internal teams
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link"
                            id="waitlist-freeAgents"
                            data-toggle="pill"
                            href="#pills-waitlist-freeAgents"
                            role="tab"
                            aria-controls="pills-waitlist-freeAgents"
                            aria-selected="false"
                          >
                            Free Agents
                          </a>
                        </li>
                      </ul>

                      <div className="tab-content" id="team-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-waitlistTeam"
                          role="tabpanel"
                          aria-labelledby="waitlist-team"
                        >
                          {getEventTeam && getEventTeam.length !== 0 ? (
                            <>
                              {getEventTeam?.club?.map((v, i) => {
                                if (v.registrationStatus == 0) {
                                  waitlistTeams.push(v);
                                }
                              })}

                              {waitlistTeams && waitlistTeams.length !== 0 ? (
                                <>
                                  <Table className="table event-table">
                                    <thead>
                                      <tr>
                                        <th>Team</th>
                                        <th>Division</th>
                                        <th>Event Status</th>
                                        <th>Division Status</th>
                                        <th>Payment</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>

                                    {/* <tbody> */}
                                    {waitlistTeams?.map((v, i) => {
                                      return (
                                        <>
                                          {/* <tr>
                                                                                                    <td>{data._id}</td>
                                                                                                </tr> */}
                                          <tbody>
                                            <tr>
                                              <td>{v?.team?.team_name}</td>
                                              <td>
                                                {v.division ? v.division : "-"}
                                              </td>
                                              <td>
                                                {v.registrationStatus == 0
                                                  ? "Waitlist"
                                                  : "Paid"}
                                              </td>
                                              <td>
                                                {v.division ? v.division : "-"}
                                              </td>
                                              <td>
                                                {v.registrationStatus == 0
                                                  ? "Waitlist"
                                                  : "Paid"}
                                              </td>
                                              <td>
                                                <i
                                                  className="mdi mdi-dots-horizontal pointer"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                ></i>
                                                <div
                                                  className="dropdown-menu pointer"
                                                  aria-labelledby="dropdownMenuLink"
                                                >
                                                  {/* <Link
                                                    to={`/updatepooldivision/${id}/${v?._id}`}
                                                    className="dropdown-item"
                                                  >
                                                    <i className="mdi mdi-plus text-blue mr-2 "></i>
                                                    <span className="text-blue">
                                                      Add to Division
                                                    </span>
                                                  </Link> */}
                                                  <Link
                                                    className="dropdown-item"
                                                    to={`/event-details/${id}`}
                                                    onClick={() => {
                                                      setisDeleteTeam(true);
                                                      setDeleteIdTeam(v._id);
                                                    }}
                                                  >
                                                    <i className="fas fa-trash-alt  mr-2 text-danger"></i>
                                                    <span className="text-blue">
                                                      Delete
                                                    </span>
                                                  </Link>

                                                  <div
                                                    to={`/updatepooldivision/${id}/${v?._id}`}
                                                    className="dropdown-item"
                                                    onClick={() => {
                                                      handleSwitch(
                                                        v?._id,
                                                        0,
                                                        1
                                                      );
                                                    }}
                                                  >
                                                    <i className="fa text-blue fa-repeat mr-2"></i>
                                                    <span className="text-blue">
                                                      Switch to registered for
                                                      event
                                                    </span>
                                                  </div>

                                                  {/* </div> */}
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </>
                                      );
                                    })}
                                    {/* </tbody> */}
                                  </Table>
                                </>
                              ) : (
                                <>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span className="noti-type">
                                      No Teams Found
                                    </span>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <div className="d-flex justify-content-center align-items-center">
                              <span className="noti-type">No Teams Found</span>
                            </div>
                          )}
                        </div>

                        <div
                          className="tab-pane fade"
                          id="pills-waitlistInteralTeam"
                          role="tabpanel"
                          aria-labelledby="waitlist-InternalTeam"
                        >
                          {getEventTeam && getEventTeam.length !== 0 ? (
                            <>
                              {getEventTeam?.organizer?.map((v, i) => {
                                if (v.registrationStatus == 0) {
                                  waitlistinternal.push(v);
                                }
                              })}

                              {waitlistinternal &&
                              waitlistinternal.length !== 0 ? (
                                <>
                                  <Table className="table event-table">
                                    <thead>
                                      <tr>
                                        <th>Team</th>
                                        <th>Division</th>
                                        <th>Event Status</th>
                                        <th>Division Status</th>
                                        <th>Payment</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>

                                    {/* <tbody> */}
                                    {waitlistinternal?.map((v, i) => {
                                      return (
                                        <>
                                          {/* <tr>
                                                                                                    <td>{data._id}</td>
                                                                                                </tr> */}
                                          <tbody>
                                            {
                                              // getEventTeam?.club?.map((v, i) => {
                                              // return(
                                              <tr>
                                                {/* <td>{v?.team_id?.team_name}</td>
                                                 */}
                                                <td>
                                                  <Link
                                                    to={{
                                                      pathname: `/team-details/${v._id}`,
                                                      state: {
                                                        path: "event",
                                                      },
                                                    }}
                                                    className="text-gray text-decoration-none"
                                                  >
                                                    {v?.team_id?.team_name}
                                                  </Link>
                                                </td>
                                                <td>
                                                  {v?.division?.division_name
                                                    ? v?.division?.division_name
                                                    : " - "}
                                                </td>
                                                <td>
                                                  {v?.registrationStatus == 1
                                                    ? "Registered"
                                                    : "Waitlist"}
                                                </td>
                                                <td>
                                                  {v?.division == null
                                                    ? " - "
                                                    : v?.registrationStatus == 1
                                                    ? "registered"
                                                    : "Waitlist"}
                                                </td>
                                                <td>paid</td>
                                                <td>
                                                  <i
                                                    className="mdi mdi-dots-horizontal pointer"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  ></i>
                                                  <div
                                                    className="dropdown-menu pointer"
                                                    aria-labelledby="dropdownMenuLink"
                                                  >
                                                    <Link
                                                      to={`/editteam/${v.team_id?._id}/${id}`}
                                                      className="dropdown-item"
                                                    >
                                                      <i className="mdi mdi-border-color text-blue mr-2"></i>
                                                      <span className="text-blue">
                                                        Edit
                                                      </span>
                                                    </Link>

                                                    <Link
                                                      className="dropdown-item"
                                                      to={`/event-details/${id}`}
                                                      onClick={() => {
                                                        setisDeleteTeam(true);
                                                        setDeleteIdTeam(v._id);
                                                      }}
                                                    >
                                                      <i className="fas fa-trash-alt  mr-2 text-danger"></i>
                                                      <span className="text-blue">
                                                        Delete
                                                      </span>
                                                    </Link>

                                                    <div
                                                      to={`/updatepooldivision/${id}/${v?._id}`}
                                                      className="dropdown-item"
                                                      onClick={() => {
                                                        handleSwitch(
                                                          v?._id,
                                                          1,
                                                          1
                                                        );
                                                      }}
                                                    >
                                                      <i className="fa text-blue fa-repeat mr-2"></i>
                                                      <span className="text-blue">
                                                        Switch to registered for
                                                        event
                                                      </span>
                                                    </div>
                                                  </div>
                                                  {/* </div> */}
                                                </td>
                                              </tr>
                                              // )
                                              // })
                                            }
                                          </tbody>
                                        </>
                                      );
                                    })}
                                    {/* </tbody> */}
                                  </Table>
                                </>
                              ) : (
                                <>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span className="noti-type">
                                      No Teams Found
                                    </span>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {" "}
                              <div className="d-flex justify-content-center align-items-center">
                                <span className="noti-type">
                                  No Teams Found
                                </span>
                              </div>
                            </>
                          )}
                        </div>

                        <div
                          className="tab-pane fade"
                          id="pills-waitlist-freeAgents"
                          role="tabpanel"
                          aria-labelledby="waitlist-freeAgents"
                        >
                          {playerAcceptList && playerAcceptList.length !== 0 ? (
                            <>
                              {playerAcceptList?.map((v, i) => {
                                if (v.registrationStatus == 0) {
                                  waitlistfree.push(v);
                                }
                              })}

                              {playerAcceptList &&
                              playerAcceptList.length !== 0 ? (
                                <>
                                  <Table className="table event-table">
                                    <thead>
                                      <tr>
                                        <th>Team</th>
                                        <th>Division</th>
                                        <th>Event Status</th>
                                        <th>Division Status</th>
                                        <th>Payment</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>

                                    {/* <tbody> */}
                                    {waitlistfree?.map((v, i) => {
                                      return (
                                        <>
                                          {/* <tr>
                <td>{data._id}</td>
            </tr> */}
                                          <tbody>
                                            <tr>
                                              {/* <td>{}</td> */}
                                              <td>
                                                <Link
                                                  to={{
                                                    pathname: `/team-details/${v._id}`,
                                                    state: {
                                                      path: "event",
                                                    },
                                                  }}
                                                  className="text-blue text-decoration-none"
                                                >
                                                  {v.player?.first_name +
                                                    " " +
                                                    v.player?.last_name}
                                                </Link>
                                              </td>

                                              {/* <td> */}
                                              <td>
                                                {v.division?.division_name
                                                  ? v.division?.division_name
                                                  : "-"}
                                              </td>
                                              <td>
                                                {v?.registrationStatus == 1
                                                  ? "Registered"
                                                  : "Waitlist"}
                                              </td>
                                              <td>
                                                {v?.division == null
                                                  ? " - "
                                                  : v?.divisionRegistrationStatus ==
                                                    1
                                                  ? "registered"
                                                  : "Waitlist"}
                                              </td>
                                              <td></td>
                                              <td>
                                                <i
                                                  className="mdi mdi-dots-horizontal pointer"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                ></i>
                                                <div
                                                  className="dropdown-menu pointer"
                                                  aria-labelledby="dropdownMenuLink"
                                                >
                                                  <Link
                                                    to={`/updatepooldivision/${id}/${v?._id}`}
                                                    className="dropdown-item"
                                                  >
                                                    <i className="mdi mdi-plus text-blue mr-2"></i>
                                                    <span className="text-blue">
                                                      Add to Division
                                                    </span>
                                                  </Link>

                                                  <Link
                                                    className="dropdown-item"
                                                    to={`/event-details/${id}`}
                                                    onClick={val}
                                                  >
                                                    <i className="fas fa-trash-alt  mr-2 text-danger"></i>
                                                    <span className="text-blue">
                                                      Delete
                                                    </span>
                                                  </Link>
                                                </div>
                                                {/* </div> */}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </>
                                      );
                                    })}
                                    {/* </tbody> */}
                                  </Table>
                                </>
                              ) : (
                                <>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span className="noti-type">
                                      No Teams Found
                                    </span>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-center align-items-center">
                                <span className="noti-type">
                                  No Parent / Players Found
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ....................................  Wait List End ......................................... */}

                  {/* <<<<<<----------------------------   Staff Start  ---------------------------->>>>>> */}

                  <div
                    className="tab-pane fade"
                    id="staff-tab"
                    role="tabpanel"
                    aria-labelledby="pills-staff"
                  >
                    <div className="mt-30">
                      <div className="d-flex justify-content-between">
                        <h6 className="text-left payment mt-30">
                          {/* Staff Members */}
                        </h6>
                        <div className="dropdown details-drp">
                          {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add More</i> */}

                          {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuLink"> */}
                          <Link
                            className="dropdown-item"
                            to={`/add-staff/${id}`}
                          >
                            {" "}
                            <i className="mdi mdi-plus mr-2 website-ico text-blue"></i>
                            Add Staff
                          </Link>
                          {/* </div> */}
                        </div>
                      </div>

                      {getEventStaff ? (
                        <>
                          {getEventStaff.length !== 0 ? (
                            <>
                              <div className="d-flex justify-content-center text-gray">
                                No staff to display
                              </div>
                            </>
                          ) : (
                            <>
                              {/* desktop view */}
                              <div className="table-responsive d-none d-md-block">
                                <table className="table event-table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Email Address</th>
                                      {/* <th>Phone No.</th> */}
                                      <th></th>
                                    </tr>
                                  </thead>

                                  {getEventStaff.length !== 0 ? (
                                    <>
                                      <span className="noti-type"></span>
                                    </>
                                  ) : (
                                    <>
                                      <tbody>
                                        {getEventStaff.map((v, i) => {
                                          return (
                                            <tr key={v._id}>
                                              <td>
                                                <div className="d-flex align-items-center">
                                                  <div className="dashboard-img">
                                                    <img
                                                      src={
                                                        v.user_id
                                                          .profile_image !== ""
                                                          ? v.user_id
                                                              .profile_image
                                                          : "/images/faces/face16.jpg"
                                                      }
                                                    />
                                                  </div>
                                                  <span className="ml-12">
                                                    {v.user_id.name}
                                                  </span>
                                                </div>
                                              </td>
                                              <td>{v.user_id.email}</td>
                                              {/* <td>{v.mobile}</td> */}
                                              <td>
                                                <div className="dropdown  event-drp">
                                                  <i
                                                    className="mdi mdi-dots-vertical"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  ></i>
                                                  {/* More */}
                                                  <div
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuLink"
                                                  >
                                                    <Link
                                                      className="dropdown-item"
                                                      to="#"
                                                      //  onClick={() => props.deleteEventStaffHandler(v._id)}
                                                      onClick={val}
                                                    >
                                                      <i className="fas fa-trash-alt text-blue"></i>{" "}
                                                      Delete
                                                    </Link>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </>
                                  )}
                                </table>
                              </div>

                              {/* mobile view */}
                              <div className="mt-3 d-md-none">
                                {getEventStaff.map((v, i) => {
                                  return (
                                    <div
                                      className="d-flex justify-content-between reg-details"
                                      key={v._id}
                                    >
                                      <div className="d-flex">
                                        <div className="dashboard-img mr-3">
                                          <img
                                            src={
                                              v.user_id.profile_image !== ""
                                                ? v.user_id.profile_image
                                                : "/images/faces/face16.jpg"
                                            }
                                          />
                                        </div>
                                        <div className="reg-content align-items-center">
                                          <Link
                                            className="text-decoration-none text-blue"
                                            to="#"
                                          >
                                            {v.user_id.name}
                                          </Link>
                                          <a className="d-block text-blue text-decoration-none">
                                            {/* <i className="mdi mdi-message-text"></i> */}
                                            <span className="text-blue ml-2">
                                              Email: {v.user_id.email}
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center text-gray">
                            No staff to display
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* <<<<<<----------------------------   Staff End  ---------------------------->>>>>> */}

                  {/* <<<<<<----------------------------   Schedule Start  ---------------------------->>>>>> */}

                  <div
                    className="tab-pane fade"
                    id="schedule-tab"
                    role="tabpanel"
                    aria-labelledby="pills-schedule"
                  >
                    <div className="mb-3">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        name="message"
                        value={message?.message}
                        onChange={handletextarea}
                        placeholder="Message"
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-end  text-blue mt-1">
                      <div
                        onClick={createScheduleMessage}
                        className="pointer text-blue"
                      >
                        {/* <button className="btn-save">Save</button> */}
                        <span className="h4">Save</span>
                      </div>
                    </div>
                    <hr />

                    <div className="d-flex justify-content-between align-items-center mt-4">
                      <h3 className="text-left mb-0">Uploads</h3>
                      {/* <div className="dropdown details-drp"> */}
                      {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> */}

                      {/* <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Link
                          className="dropdown-item"
                          
                        >
                         Add
                        </Link>
                      </div> */}
                      <div className="">
                        <Link
                          to={`/addschedule/${id}`}
                          className="text-decoration-none text-dark"
                        >
                          <button className="btn-save">
                            <i className="mdi mdi-plus mr-2 website-ico"></i>Add
                          </button>
                        </Link>
                      </div>
                    </div>

                    {getEventSchedule ? (
                      <>
                        {getEventSchedule.length == 0 ? (
                          <>
                            <div className="d-flex justify-content-center text-gray mt-5">
                              No schedules to display
                            </div>
                          </>
                        ) : (
                          <div className="mt-5 ">
                            {getEventSchedule.map((v, i) => {
                              return (
                                <div
                                  className="row mx-0 border-bottom pb-2 mt-2"
                                  key={v._id}
                                >
                                  <div className="col-md-10 col-10">
                                    <div className="text-left">
                                      {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> */}
                                      {/* <div className=" d-flex "> */}
                                      <div className="payment w-100 text-break">
                                        <a
                                          href={v.text == "" ? v.file : v.text}
                                          target="_blank"
                                          rel="noreferrer noopener"
                                          className="text-dark text-decoration-none"
                                        >
                                          <strong>
                                            {v.text == "" ? v.file : v.text}
                                          </strong>
                                        </a>
                                      </div>
                                      {/* </div> */}
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-2 align-items-end justify-content-end d-flex">
                                    <div className="dropdown event-drp">
                                      <i
                                        className="mdi mdi-dots-vertical"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      ></i>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuLink"
                                      >
                                        <Link
                                          className="dropdown-item"
                                          to={{
                                            pathname: `/EditSchedule/${id}/${v?._id}`,
                                            state: {
                                              flag:
                                                v?.text !== "" && v?.file === ""
                                                  ? "text"
                                                  : "image",
                                            },
                                          }}
                                        >
                                          <i className="mdi mdi-border-color text-blue"></i>
                                          Edit
                                        </Link>
                                        <Link
                                          className="dropdown-item"
                                          to={`/event-details/${id}`}
                                          // onClick={() => props.deleteScheduleHandler(v._id)}
                                          onClick={() => {
                                            setIsDeleteSchedule(true);
                                            setdeletedSchedule(v._id);
                                          }}
                                        >
                                          <i className="fas fa-trash-alt text-blue"></i>
                                          Delete
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="w-full h-[60vh] flex flex-col justify-center items-center">
                          <Spinner />
                        </div>
                      </>
                    )}
                  </div>

                  {/* <<<<<<----------------------------   Schedule End  ---------------------------->>>>>> */}

                  {/* ...................................... Activity Log Start ........................ */}

                  <div
                    className="tab-pane fade"
                    id="ActivityLog-tab"
                    role="tabpanel"
                    aria-labelledby="pills-ActivityLog"
                  >
                    {getActivityLog ? (
                      <>
                        {getActivityLog.length == 0 ? (
                          <>
                            <div className="d-flex justify-content-center text-gray">
                              No Activity to display
                            </div>
                          </>
                        ) : (
                          <>
                            <Table
                              responsive="sm"
                              className="table event-table"
                            >
                              <thead>
                                <tr>
                                  <th>Team / Player</th>
                                  <th>Club / Parent</th>
                                  <th>From Division</th>
                                  <th>To Division</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {getActivityLog.map((v, i) => {
                                  // const {name} = v.player?.player

                                  return (
                                    <tr key={i}>
                                      <td>
                                        {v.player?.player?.first_name ||
                                        v.team?.team?.team_name
                                          ? v.player?.player?.first_name ||
                                            v.team?.team?.team_name
                                          : "-"}
                                      </td>
                                      <td>
                                        {v.player?.player?.parent_id?.name ||
                                        v.team?.club?.name
                                          ? v.player?.player?.parent_id?.name ||
                                            v.team?.club?.name
                                          : "-"}
                                      </td>
                                      <td>
                                        {v.from?.division_name
                                          ? v.from?.division_name
                                          : "-"}
                                      </td>
                                      <td>
                                        {v.to?.division_name
                                          ? v.to?.division_name
                                          : "-"}
                                      </td>
                                      <td>
                                        <Moment
                                          date={
                                            new Date(
                                              v.updatedAt ? v.updatedAt : "-"
                                            )
                                          }
                                          format="MM DD, YYYY"
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="d-flex justify-content-center text-gray">
                          No Activity Found
                        </div>
                      </>
                    )}
                  </div>

                  {/* ...................................... Activity Log End ........................ */}

                  {/* <<<<<<----------------------------   Accounting Start  ---------------------------->>>>>> */}

                  <div
                    className="tab-pane fade"
                    id="accounting-tab"
                    role="tabpanel"
                    aria-labelledby="pills-accounting"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="accounting-tab"
                      role="tabpanel"
                      aria-labelledby="pills-accounting"
                    >
                      {/* <p>Division</p> */}
                      <ul
                        className="nav nav-pills mb-3 mt-5"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link active"
                            id="accounting-payment"
                            data-toggle="pill"
                            href="#pills-accountingpayment"
                            role="tab"
                            aria-controls="pills-accountingpayment"
                            aria-selected="false"
                          >
                            Payment Code
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            className="nav-link"
                            id="accounting-collection"
                            data-toggle="pill"
                            href="#pills-accounting-collection"
                            role="tab"
                            aria-controls="pills-accounting-collection"
                            aria-selected="false"
                          >
                            Collection
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="tab-content" id="team-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-accountingpayment"
                        role="tabpanel"
                        aria-labelledby="accounting-payment"
                      >
                        {paymentcode ? (
                          <>
                            {paymentcode.length == 0 ? (
                              <>
                                <div className="d-flex justify-content-center text-gray">
                                  No payment code to display
                                </div>
                              </>
                            ) : (
                              <>
                                <Table className="table event-table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Amount</th>
                                      <th>Amount Due</th>
                                      <th>Payment Due Days</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {paymentcode.map((v, i) => {
                                      return (
                                        <tr key={i}>
                                          <td>{v.name}</td>
                                          <td>{"$" + " " + v.amount}</td>
                                          <td>{"%" + " " + v.payment_terms}</td>
                                          <td>{v.payment_due}</td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="w-full h-[60vh] flex flex-col justify-center items-center">
                              <Spinner />
                            </div>
                          </>
                        )}
                      </div>

                      <div
                        className="tab-pane fade"
                        id="pills-accounting-collection"
                        role="tabpanel"
                        aria-labelledby="accounting-collection"
                      >
                        {AccountingList ? (
                          <>
                            {AccountingList.length !== 0 ? (
                              <>
                                {/* desktop view */}
                                <div className="mt-24 table-responsive  col-xl-8 col-lg-10 px-0">
                                  <table className="table event-table">
                                    <thead>
                                      <tr>
                                        <th>Team / Player</th>
                                        <th>Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {AccountingList?.map((v) => {
                                        return (
                                          <tr key={v._id}>
                                            <td>
                                              <Link
                                                to={`/team-details/${id}/${v.team_id?._id}`}
                                                className="d-flex align-items-centedivision er text-dark text-decoration-none"
                                              >
                                                {/* <div className="dashboard-img">
                                                                                                                <img src={v.team_id?.image !== "" ? v.team_id?.image : "./images/pch-images/pch.svg"} />
                                                                                                            </div> */}
                                                <span className="ml-12">
                                                  {v?.team?.team_name ||
                                                  v?.player?.first_name +
                                                    " " +
                                                    v?.player?.last_name
                                                    ? v?.team?.team_name ||
                                                      v?.player?.first_name +
                                                        " " +
                                                        v?.player?.last_name
                                                    : " - "}
                                                </span>
                                              </Link>
                                            </td>
                                            <td
                                              className={
                                                v.credit > 0
                                                  ? "text-green"
                                                  : v.panding_amount == 0
                                                  ? ""
                                                  : "text-red"
                                              }
                                            >
                                              {v.credit > 0
                                                ? " + " + v.credit
                                                : v.panding_amount == 0
                                                ? v.panding_amount
                                                : " - " + v.panding_amount}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                      {/* {
                                                                                        (getEventTeam?.club.length > 0) &&
                                                                                        getEventTeam?.club?.map((v) => {

                                                                                            return <tr key={v._id}>
                                                                                                <td>
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <div className="dashboard-img">
                                                                                                            <img src={!v.team_id?.image && v.team_id?.image !== "" ? v.team_id?.image : "./images/pch-images/pch.svg"} />
                                                                                                        </div>
                                                                                                        <span className="ml-12">{v.team_id === null ? "Null" : v.team_id?.team_name}</span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>{v.registrationStatus == 1 ? "True" : "False"}</td>
                                                                                                <td>
                                                                                                    <span className={"badge " + (v.registrationStatus == 0 ? 'badge-warning' : 'badge-success')}>{v.registrationStatus == 0 ? "Unpaid" : "Paid"} </span>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div className="dropdown event-drp">
                                                                                                        <button className='btn btn-link text-dark p-0' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                            <i className="mdi mdi-dots-vertical action-ico"></i>
                                                                                                            More
                                                                                                        </button>
                                                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                                                            <Link className="dropdown-item" to={`/event-details/${id}`} onClick={() => props.deleteEventTeamHandler({ id: v._id, type: 1 })}><i className="fas fa-trash-alt"></i>  Delete</Link>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        })
                                                                                    } */}
                                    </tbody>
                                  </table>
                                </div>
                                {/* mobile view */}
                              </>
                            ) : (
                              <>
                                <div className="d-flex justify-content-center text-gray">
                                  No collection to display
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="w-full h-[60vh] flex flex-col justify-center items-center">
                              <Spinner />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <<<<<<----------------------------   Accounting End  ---------------------------->>>>>> */}

                  {/* .....................................   field map start   .................................. */}

                  <div
                    className="tab-pane fade"
                    id="fieldmap-tab"
                    role="tabpanel"
                    aria-labelledby="pills-fieldmap"
                  >
                    <div className="mb-3">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        name="filedmapmessage"
                        value={fieldmapmessage}
                        onChange={handlefieldmapmsg}
                        placeholder="Message"
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-end  text-blue mt-1">
                      <div
                        onClick={handleSubmitfiledmapmsg}
                        className="pointer text-blue"
                      >
                        <span className="h4">Save</span>
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center mt-4">
                      <h3 className="text-left mb-0">Uploads</h3>
                      <div className="">
                        {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> */}

                        {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuLink"> */}
                        <Link
                          className="text-decoration-none text-dark"
                          to={`/addfieldmap/${id}`}
                        >
                          <button className="btn-save">
                            <i className="mdi mdi-plus mr-2 website-ico"></i>Add
                          </button>
                        </Link>
                        {/* </div> */}
                      </div>
                    </div>

                    {allfieldmap ? (
                      <>
                        {allfieldmap.length == 0 ? (
                          <>
                            <div className="d-flex justify-content-center text-gray mt-5">
                              No filed map to display
                            </div>
                          </>
                        ) : (
                          <div className=" mt-5 ">
                            {allfieldmap.map((v, i) => {
                              return (
                                <div
                                  className="row mx-0 border-bottom pb-2 mt-2"
                                  key={v._id}
                                >
                                  <div className="col-md-10 col-10">
                                    <div className="text-left ">
                                      {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> */}
                                      {/* <div className=" d-flex "> */}
                                      <div className="payment w-100 text-break">
                                        <a
                                          href={v.text == "" ? v.file : v.text}
                                          target="_blank"
                                          rel="noreferrer noopener"
                                          className="text-dark text-decoration-none"
                                        >
                                          <strong>
                                            {v.text == "" ? v.file : v.text}
                                          </strong>
                                        </a>
                                      </div>
                                      {/* </div> */}
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-2 align-items-end justify-content-end d-flex">
                                    <div className="dropdown event-drp">
                                      <i
                                        className="mdi mdi-dots-vertical"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      ></i>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuLink"
                                      >
                                        <Link
                                          className="dropdown-item"
                                          to={`/editfieldmap/${id}/${v?._id}`}
                                          // onClick={() => props.deleteScheduleHandler(v._id)}
                                          // onClick={val}
                                        >
                                          <i className="mdi mdi-border-color text-blue"></i>
                                          Edit
                                        </Link>
                                        <Link
                                          className="dropdown-item"
                                          to={`/event-details/${id}`}
                                          // onClick={() => props.deleteScheduleHandler(v._id)}
                                          onClick={() => {
                                            setIsDeleteFiledMap(true);
                                            setdeletedFiledMap(v._id);
                                          }}
                                        >
                                          <i className="fas fa-trash-alt text-blue"></i>
                                          Delete
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="w-full h-[60vh] flex flex-col justify-center items-center">
                          <Spinner />
                        </div>
                      </>
                    )}
                  </div>

                  {/* ...............................  field map end  .................................................... */}

                  {/* .....................................   stay and play start   .................................. */}

                  <div
                    className="tab-pane fade"
                    id="stayandplay-tab"
                    role="tabpanel"
                    aria-labelledby="pills-stayandplay"
                  >
                    <div className="mb-3">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        name="filedmapmessage"
                        value={stayandplay}
                        onChange={handlestayandplay}
                        placeholder="Message"
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-end   mt-1">
                      <div
                        onClick={handlesubmitstayandplaymsg}
                        className="pointer text-blue"
                      >
                        {/* <button className="btn-save">Save</button> */}
                        <span className="h4">Save</span>
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center mt-4">
                      <h3 className="text-left mb-0">Uploads</h3>
                      <div className="">
                        {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> */}

                        {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuLink"> */}
                        <Link
                          className="text-decoration-none text-dark"
                          to={`/addstayandplay/${id}`}
                        >
                          <button className="btn-save">
                            <i className="mdi mdi-plus mr-2 website-ico"></i>Add
                          </button>
                        </Link>
                        {/* </div> */}
                      </div>
                    </div>

                    {allstayandplay ? (
                      <>
                        {allstayandplay.length == 0 ? (
                          <>
                            <div className="d-flex justify-content-center text-gray mt-5">
                              No stay and play to display
                            </div>
                          </>
                        ) : (
                          <div className="mt-5">
                            {allstayandplay.map((v, i) => {
                              return (
                                <div
                                  className="row mx-0  border-bottom pb-2 mt-2"
                                  key={v._id}
                                >
                                  <div className="col-md-10 col-10">
                                    <div className="text-left">
                                      {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> */}
                                      {/* <div className=" d-flex "> */}
                                      <div className="payment w-100 text-break">
                                        <a
                                          href={v.text == "" ? v.file : v.text}
                                          target="_blank"
                                          rel="noreferrer noopener"
                                          className="text-dark text-decoration-none"
                                        >
                                          <strong>
                                            {v.text == "" ? v.file : v.text}
                                          </strong>
                                        </a>
                                      </div>
                                      {/* </div> */}
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-2 align-items-end justify-content-end d-flex">
                                    <div className="dropdown event-drp">
                                      <i
                                        className="mdi mdi-dots-vertical"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      ></i>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuLink"
                                      >
                                        <Link
                                          className="dropdown-item"
                                          to={{
                                            pathname: `/editstayandplay/${id}/${v?._id}`,
                                            state: {
                                              flag:
                                                v.text !== ""
                                                  ? "text"
                                                  : "image",
                                            },
                                          }}
                                          // onClick={() => props.deleteScheduleHandler(v._id)}
                                          // onClick={val}
                                        >
                                          <i className="mdi mdi-border-color text-blue"></i>
                                          Edit
                                        </Link>
                                        <Link
                                          className="dropdown-item"
                                          to={`/event-details/${id}`}
                                          // onClick={() => props.deleteScheduleHandler(v._id)}
                                          onClick={() => {
                                            setisDeleteStayandplay(true);
                                            setdeletedStayandPlay(v._id);
                                          }}
                                        >
                                          <i className="fas fa-trash-alt text-blue"></i>
                                          Delete
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="w-full h-[60vh] flex flex-col justify-center items-center">
                          <Spinner />
                        </div>
                      </>
                    )}
                  </div>

                  {/* ...............................  stay and play end  .................................................... */}
                </div>
              </div>
            </>
          ) : (
            <div className="w-full h-[60vh] flex flex-col justify-center items-center">
              <Spinner />
            </div>
          )}
          <Toaster />
        </div>
      </div>
      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => props.deleteDivisionHandler(deleteId)}
      />
      <DeletePopUp
        isOpen={isDeleteSchedule}
        isClose={setIsDeleteSchedule}
        handleEvent={() => props.deleteScheduleHandler(deletedSchedule)}
      />
      <DeletePopUp
        isOpen={isDeleteFiledMap}
        isClose={setIsDeleteFiledMap}
        handleEvent={() => props.deleteFileMapAction(deletedFiledMap)}
      />
      <DeletePopUp
        isOpen={isDeleteStayandplay}
        isClose={setisDeleteStayandplay}
        handleEvent={() => props.DeleteStayandPlayAction(deletedStayandPlay)}
      />
      <DeletePopUp
        isOpen={isDeleteTeam}
        isClose={setisDeleteTeam}
        handleEvent={() =>
          props.deleteEventTeamHandler({
            deletedID: deleteIdTeam,
            type: status == "team" || status == "waitlisteam" ? 1 : 0,
          })
        }
      />
    </React.Fragment>
  );
};

// export default withRouter(Org_eventDetails);

const mapStateToProps = (state) => ({
  getAllEventReducer: state.getAllEventReducer,
  submit: state.deleteEventReducer,
  submit1: state.deleteScheduleReducer,
  Team: state.deleteEventTeamReducer,
  createDivision: state.createDivisionReducer,
  createPool: state.createPoolReducer,
  deletePoolmessage: state.deletePoolReducer,
  deletePoolTeamMessage: state.deletePoolTeamReducer,
  editPoolmessage: state.editPoolReducer,
  editDivisionmessage: state.editDivisionReducer,
  deleteDivisionmessage: state.deleteDivisionReducer,
  schedulemessage: state.createTeamReducer,
  updateFreeTeam: state.updateFreeTeamReducer,
  teamGroup: state.createNewGroupReducer,
  addTeamgroup: state.addPlayerToGroupReducer,
  editGroup: state.editGroupReducer,
  deleteGroup: state.deleteGroupReducer,
  deletePayment: state.deleteEventReducer,
  editCode: state.editPaymentCodeReducer,
  deleteDivisionReducer: state.deleteDivisionReducer,
  switchAccessReducer: state.switchAccessReducer,
  switchPlayerAccessReducer: state.switchPlayerAccessReducer,
  updateScheduleReducer: state.updateScheduleReducer,
  viewfieldmapReducer: state.viewfieldmapReducer,
  createfieldmapReducer: state.createfieldmapReducer,
  updatefieldmapReducer: state.updatefieldmapReducer,
  addteamtodivisionReducer: state.addteamtodivisionReducer,
  CreateScheduleMessageReducer: state.CreateScheduleMessageReducer,
  CreateFieldmapMessageReducer: state.CreateFieldmapMessageReducer,
  ViewStayandPlayReducer: state.ViewStayandPlayReducer,
  createStayandPlayReducer: state.StayandPlayReducer.createStayandPlay,
  createStayandPlayMessageReducer:
    state.StayandPlayReducer.createStayandPlayMessage,
  updateStayandPlay: state.StayandPlayReducer.updateStayandPlay,
  deleteStayandPlay: state.StayandPlayReducer.deleteStayandPlay,
  getEventTeamReducer: state.getEventTeamReducer,
  DeleteFiledMApReducer: state.DeleteFiledMApReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getEventHandler: (user) => dispatch(getAllEvent(user)),
  getAllDivisionHandler: (user) => dispatch(getAllDivision(user)),
  getEventStaffHandler: (user) => dispatch(getEventStaff(user)),
  getEventScheduleHandler: (user) => dispatch(getEventSchedule(user)),
  getEventTeamHandler: (user) => dispatch(getEventTeam(user)),
  deleteEventStaffHandler: (user) => dispatch(deleteEventStaff(user)),
  deleteScheduleHandler: (user) => dispatch(deleteSchedule(user)),
  deleteFileMapAction: (user) => dispatch(deleteFileMapAction(user)),
  resetdeletePaymentCodeReducer: () => dispatch({ type: RESET_DELETE_EVENT }),
  resetdeleteScheduleReducer: () => dispatch({ type: RESET_DELETE_SCHEDULE }),
  resetdeleteEventTeamReducer: () => dispatch({ type: RESET_DELETE_EVENTTEAM }),
  resetcreatedivisionReducer: () => dispatch({ type: RESET_CREATE_DIVISION }),
  resetCreatePoolReducer: () => dispatch({ type: RESET_CREATE_POOL }),
  reseteditPoolReducer: () => dispatch({ type: RESET_EDIT_POOL }),
  reseteditDivisionReducer: () => dispatch({ type: RESET_EDIT_DIVISION }),
  resetdeleteDivisionReducer: () => dispatch({ type: RESET_DELETE_DIVISION }),
  resetDeletePoolReducer: () => dispatch({ type: RESET_DELETE_POOL }),
  resetscheduleReducer: () => dispatch({ type: RESET_CREATE_TEAM }),
  resetFreetaemReducer: () => dispatch({ type: RESET_FREETEAM }),
  getFreeTeamHandler: (user) => dispatch(getFreeTeam(user)),
  deleteEventTeamHandler: (user) => dispatch(deleteEventTeam({ user })),
  deletePoolHandler: (user) => dispatch(deletePool(user)),
  removePoolTeam: (data) => dispatch(removePoolTeam(data)),
  deleteDivisionHandler: (user) => dispatch(deleteDivision(user)),
  resetCreateGroupreducer: (user) => dispatch({ type: RESET_CREATE_NEW_GROUP }),
  getTeamGroupsHandler: (user) => dispatch(getTeamGroups(user)),
  resetAddGroupreducer: (user) => dispatch({ type: RESET_ADD_PLAYER_GROUP }),
  resetEditGroupreducer: () => dispatch({ type: RESET_EDIT_GROUP }),
  deleteGroupHandler: (user) => dispatch(deleteGroup(user)),
  updateFreeTeamHandler: (data) => dispatch(updateFreeTeam(data)),
  createPoolHandler: (data) => dispatch(createPool(data)), // create pool
  resetdeleteGroupreducer: () => dispatch({ type: RESET_DELETE_GROUP }),
  deletePaymentCodeHandler: (user) => dispatch(deletePaymentCode(user)),
  resetdeletePaymentCodeReducer: () => dispatch({ type: RESET_DELETE_EVENT }),
  reseteditPaymentCodeReducer: () =>
    dispatch({ type: RESET_EDIT_PAYMENT_CODE }),
  getEventActivityLogHandler: (user) => dispatch(getActivityLog(user)),
  getAcceptedPlayerhandler: (user) => dispatch(getAcceptedPlayer(user)),
  getAccountinghandler: (user) => dispatch(getAccounting(user)),
  SwitchacccessActionhandler: (user) => dispatch(SwitchacccessAction(user)),
  SwitchacccessPlayerAction: (user) =>
    dispatch(SwitchacccessPlayerAction(user)),
  viewfieldmapActionhandler: (user) => dispatch(viewfieldmapAction(user)),
  viewStayandplayActionhandler: (user) => dispatch(viewStayandplayAction(user)),
  CreateScheduleMessageAction: (user) =>
    dispatch(CreateScheduleMessageAction(user)),
  CreateFieldmapMessageAction: (user) =>
    dispatch(CreateFieldmapMessageAction(user)),
  CreateStayandPlayMessageAction: (user) =>
    dispatch(CreateStayandPlayMessageAction(user)),
  DeleteStayandPlayAction: (user) => dispatch(DeleteStayandPlayAction(user)),
  resetviewfieldmapReducer: () => dispatch({ type: RESET_VIEW_FIELD_MAP }),
  resetcreatefielsmap: () => dispatch({ type: RESET_CREATE_FIELD_MAP }),
  resetupdateschedule: () => dispatch({ type: RESET_UPDATE_SCHEDULE }),
  resetupdatefieldmap: () => dispatch({ type: RESET_UPDATE_FIELD_MAP }),
  resetdeletefieldmap: () => dispatch({ type: RESET_DELETE_FIELD_MAP }),
  resetswitchReducer: () => dispatch({ type: RESET_ORG_SWITCH_ACCESS }),
  resetswitchPlayerReducer: () =>
    dispatch({ type: RESET_ORG_SWITCH_ACCESS_PLAYER }),
  resetviewstayandplay: () => dispatch({ type: RESET_VIEW_STAY_AND_PLAY }),
  resetfieldmapmessage: () => dispatch({ type: RESET_CREATE_FIELDMAP_MESSAGE }),
  resetaddteamtodivisionreducer: (user) =>
    dispatch({ type: RESET_ORG_ADD_TEAM_TO_DIVISION }),
  resetstayandplay: () => dispatch({ type: RESET_CREATE_STAY_AND_PLAY }),
  resetupdatestayandplay: () => dispatch({ type: RESET_UPDATE_STAY_AND_PLAY }),
  resetMoveToFreeTeam: () => dispatch({ type: RESET_MOVE_TO_FREE_TEAM_PLAYER }),
  resetScheduleMsg: () => dispatch({ type: RESET_CREATE_SCHEDULE_MESSAGE }),
  resetFiledMap: () => dispatch({ type: RESET_CREATE_FIELDMAP_MESSAGE }),
  resetdeleteStayAndPlay: () => dispatch({ type: RESET_DELETE_STAY_AND_PLAY }),
  resetStayandPlay: () =>
    dispatch({ type: RESET_CREATE_STAY_AND_PLAY_MESSAGE }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Org_eventDetails));
