import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../Services/Actions";
import classNames from "classnames";
import { RESET_LOGIN, validEmailRegex } from "../../Services/constant";
import { toast } from "react-toastify";

function SignIn(props) {
  const history = useHistory();
  const [visible, setvisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    email: false,
    password: false,
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));

    switch (name) {
      case "email":
        if (value == "") {
          error["email"] = "Required";
        } else if (!validEmailRegex.test(value)) {
          error["email"] = "Enter Valid Email";
        } else {
          error["email"] = false;
        }
        break;

      case "password":
        if (value == "") {
          error["password"] = "Required";
        } else {
          error["password"] = false;
        }
        break;
      default:
        break;
    }
  };

  const loginSuccess = (e) => {
    e.preventDefault();
    if (details.email == "") {
      setError((prev) => ({
        ...prev,
        email: "Required",
      }));
    }
    if (details.password == "") {
      setError((prev) => ({
        ...prev,
        password: "Required",
      }));
    }

    if (details.email !== "" && details.password !== "" && !error.email) {
      props.login({
        email: details.email,
        password: details.password,
      });
    }

    // navigate('/dashboard');
  };
  useEffect(() => {
    if (props.loginReducer.success) {
      const data = props.loginReducer.user;
      localStorage.setItem("token", data.token);
      localStorage.setItem("userID", data.data._id);
      localStorage.setItem("isAdmin", !!data.data.role ? true : false);
      props.resetLogIn();
      history.push("/superadmin/dashboard");
    } else if (props.loginReducer.error) {
      const msg = props.loginReducer.errorMsg;
      toast.error(msg);
    }
  }, [props.loginReducer]);

  const passwordvisible = () => {
    setvisible(!visible);
  };

  return (
    <div className="signin">
      <div className="signin-logo">
        <img src={logo} className="img-fluid logo-img" alt="logo" />
      </div>

      {/* Login form */}
      <div className="login-form">
        <h1 className="superAdmin-heading text-white text-center">Sign In</h1>
        <form className="">
          <div className="form-group">
            <input
              type="text"
              className={classNames("form-control", {
                errorBorder: error.email,
              })}
              placeholder="Email"
              name="email"
              value={details.email}
              onChange={handleChange}
            />
          </div>

          <div className="form-group" style={{ position: "relative" }}>
            <input
              type={!!visible ? "text" : "password"}
              className={classNames("form-control", {
                errorBorder: error.password,
              })}
              placeholder="Password"
              name="password"
              value={details.password}
              onChange={handleChange}
            />
            <div
              className="form-control-icon pointer"
              onClick={passwordvisible}
            >
              {visible ? (
                <i className="fa fa-eye"></i>
              ) : (
                <i className="fa fa-eye-slash"></i>
              )}
            </div>
          </div>
          <div className="text-end mt-3">
            <Link
              to="/superadmin/forgotPassword"
              className="btn btn-link text-white p-0"
            >
              Forgot password?
            </Link>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-secondary"
              onClick={loginSuccess}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapStateToProp = (state) => ({
  loginReducer: state.superAdminRootReducer.authReducers.loginDetails,
});

const mapDispatchToProps = (dispatch) => ({
  login: (user) => dispatch(login(user)),
  resetLogIn: () => dispatch({ type: RESET_LOGIN }),
});

export default connect(mapStateToProp, mapDispatchToProps)(SignIn);
