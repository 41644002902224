import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
// import Toaster from '../../../Toaster/Toaster';
import { withRouter, Redirect, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Toaster from "../../../Toaster/Toaster";
import { sendBroadcast } from "../../../service/action/userAction";
import {Modal } from "react-bootstrap";

const SendBroadcast = (props) => {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const initialState = {
    sendBroadcast_msg: "",
    is_filter: "",
  };

  const [state, setState] = useState({
    sendBroadcast_msg: "",
    is_filter: "",
  });

  const [errors, setErrors] = useState({
    sendBroadcast_msg: false,
    is_filter: false,
  });

  //setTimeout form reseting data after submit of form
  function resetData() {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }

  //clear data on submit.
  const clearState = () => {
    setState({ ...initialState });
  };

  // const [checked, setChecked] = useState(false)
  // const onClickCheck = () => setChecked(!checked)

  const eventHandler = (e) => {
    const { id, value, name } = e.target;

    setState((prevState) => ({
      ...prevState,
      [id]: value,
      // [e.target.name]: e.target.value.trim()
    }));
    switch (name) {
      case "sendBroadcast_msg":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            sendBroadcast_msg: "No user found",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            sendBroadcast_msg: false,
          }));
        }

        break;

      default:
    }
  };

  const notify = (msg) => toast(msg);

  const submitRequest = (e) => {
    e.preventDefault();
    // alert("click")

    if (state.sendBroadcast_msg == "") {
      setErrors((prevState) => ({
        ...prevState,
        sendBroadcast_msg: "No user found",
      }));
    }

    if (state.sendBroadcast_msg !== "") {
      props.sendBroadcastHandler({
        message: state.sendBroadcast_msg,
        is_filter: false,
      });
      // history.push("/");
    }
  };

  useEffect(() => {
    if (props.submit?.success) {
      notify('Broadcast sent successfully');
      resetData().then(clearState);
      history.push("/notifications");
    }
  }, [props.submit]);

  // if(props.submit?.success){
  //         notify("Season submitted successfully.")
  //         props.resetCreateSeasonReducer()
  //         return <Redirect to="/season" />;
  //     }

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Send Broadcast</h5>
          </div>

          <form className="row mx-0">
            <div className="col-md-8 col-xl-8">
              <div className="mt-24">
                <div className="text-left">
                  {/* <label className="text-gray fs-13">Season Name <span className="text-red fw-m">*</span></label> */}
                  <input
                    type="text"
                    className="form-control team-name"
                    id="sendBroadcast_msg"
                    name="sendBroadcast_msg"
                    value={state.sendBroadcast_msg}
                    placeholder="Enter message..."
                    required
                    onChange={eventHandler}
                  />

                  {errors.sendBroadcast_msg && (
                    <span className="text-red small d-block">
                      * {errors.sendBroadcast_msg}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-2 col-xl-2">
              <div className="mt-24 text-left">
                <button type="submit"
                  className="bg-[#143D4A] px-5 py-2 rounded-md text-white"
                  onClick={(e)=> submitRequest(e)}
                >
                  Send
                </button>
              </div>
            </div>
          </form>
          <div
            className="mt-3 text-gray pointer"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Advanced Broadcast
          </div>
        </div>
      </div>

      <Modal
        size="md"
        show={isOpen}
        onHide={() => setIsOpen(false)}
        centered
        className="del-dialogue"
      >
        <Modal.Body>
          <h6 className="text-center">Send Broadcast</h6>
        </Modal.Body>
        {/* <Modal.Footer className="p-0">
          <Button className="btn-gray" >
            No
          </Button>
          <Button variant="secondary" >
            Yes
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Toaster />
    </>
  );
};

// export default withRouter(CreateInvoice)

const mapStateToProp = (state) => ({
  submit: state.sendBroadcastReducer,
});

const mapDispatchToProps = (dispatch) => ({
  sendBroadcastHandler: (user) => dispatch(sendBroadcast(user)),
  // resetSendBroadcast:()=>dispatch({type:RESET_SEND_BROADCAST})
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(SendBroadcast));
