import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAcceptedPlayer,
  getEventTeam,
  getTeamDetails,
} from "../../../service/action/userAction";

const Teaminsideteam = (props) => {
  const { id, id1 } = useParams();
  const notify = (msg) => toast(msg);

  useEffect(() => {
    props.getTeamDetailsHandler({ id: id });
  }, []);

  useEffect(() => {
    props.getAcceptedPlayerhandler({ id: id });
  }, []);

  const getTeamdetails = useSelector(
    (state) => state.getTeamDetailsReducer.user.data
  );

  const loading = useSelector((state) => state.getTeamDetailsReducer.loading);

  useEffect(() => {
    if (props.submit?.success) {
      // notify(deleteEventMsg.message);
      // props.resetdeletePaymentCodeReducer();
      props.getAcceptedPlayerhandler({ id: id });
      // history.push(`/event-details/${id}`);
    }
  }, [props.submit]);

  const [state, setState] = useState({
    team: "",
    division: "",
    pool: "",
    image: "",
    player: [],
  });

  useEffect(() => {
    if (!loading) {
      setState((prevState) => ({
        ...prevState,
        team: getTeamdetails?.club?.team_name,
        division:
          getTeamdetails?.division == null
            ? "null"
            : getTeamdetails?.division?.division_name,
        pool:
          getTeamdetails?.pool == null
            ? "null"
            : getTeamdetails?.pool?.pool_name,
        image: getTeamdetails?.team?.image,
        player: getTeamdetails?.player,
      }));
    }
  }, [!loading]);

  useEffect(() => {
    if (props.Delete?.success) {
    }
  }, [props.Delete]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="text-left my-4">
              <img
                src={
                  getTeamdetails?.team?.image
                    ? getTeamdetails?.team?.image
                    : "./images/pch-images/pch.svg"
                }
                className="img-fluid event-img"
              />
            </div>
            <div className="create-team-head">
              <h5 className="dash-head pr-3">
                {getTeamdetails?.team?.team_name}
              </h5>
              {/* <div className="d-flex align-items-center">
                                            <div className="reg-team">
                                                <Link className="text-decoration-none" to="/teams">
                                                    <i className="mdi mdi-account-multiple"></i>
                                                    <span>Register Teams</span>
                                                </Link>
                                            </div>
                                        </div> */}
            </div>
          </div>

          <div className="team-details">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="details"
                  data-toggle="pill"
                  href="#details-tab"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Details
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="teams"
                  data-toggle="pill"
                  href="#teams-tab"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Players
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="payment"
                  data-toggle="pill"
                  href="#payment-tab"
                  role="tab"
                  aria-controls="pills-payment"
                  aria-selected="false"
                >
                  Rosters
                </a>
              </li>
            </ul>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="details-tab"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div>
                  <div style={{ fontWeight: "bold" }}>
                    {getTeamdetails?.team?.team_name}
                  </div>
                  <div>club name: {getTeamdetails?.team?.club?.name}</div>
                  <div className="mt-4" style={{ fontWeight: "bold" }}>
                    Coach
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="teams-tab"
                role="tabpanel"
                aria-labelledby="pills-teams-tab"
              >
                {getTeamdetails?.player?.length === 0 ? (
                  <div className="d-flex justify-content-center mt-3 text-gray">
                    No players found
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div
                className="tab-pane fade"
                id="payment-tab"
                role="tabpanel"
                aria-labelledby="pills-payment-tab"
              >
                {getTeamdetails?.team?.roster_player?.length === 0 ? (
                  <div className="d-flex justify-content-center mt-3 text-gray">
                    No rosters found
                  </div>
                ) : (
                  <>
                    {getTeamdetails?.team?.roster_player?.map((v, i) => {
                      return (
                        <>
                          <div
                            className="border p-2 pointer"
                            style={{ fontWeight: "bold" }}
                          >
                            {v?.roster_name ? v?.roster_name : "N/A"}
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  submit: state.deleteEventReducer,
  submit1: state.deleteScheduleReducer,
  Team: state.deleteEventTeamReducer,
  createDivision: state.createDivisionReducer,
  createPool: state.createPoolReducer,
  deletePoolmessage: state.deletePoolReducer,
  deletePoolTeamMessage: state.deletePoolTeamReducer,
  editPoolmessage: state.editPoolReducer,
  editDivisionmessage: state.editDivisionReducer,
  deleteDivisionmessage: state.deleteDivisionReducer,
  schedulemessage: state.createTeamReducer,
  updateFreeTeam: state.updateFreeTeamReducer,
  teamGroup: state.createNewGroupReducer,
  addTeamgroup: state.addPlayerToGroupReducer,
  editGroup: state.editGroupReducer,
  deleteGroup: state.deleteGroupReducer,
  deletePayment: state.deleteEventReducer,
  editCode: state.editPaymentCodeReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getTeamDetailsHandler: (user) => dispatch(getTeamDetails(user)),
  getAcceptedPlayerhandler: (user) => dispatch(getAcceptedPlayer(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Teaminsideteam));
