import {connect} from "react-redux"
import { getAllCity, getAllState, register } from "../service/action/userAction";
import { RESET_SIGNIN, RESET_SIGNUP } from "../service/constants";
import Signup from "../signup/Signup";

const mapStateToProp = state => ({
    signup:state.signupReducer
})          

const mapDispatchToProps = dispatch => ({
    signUpHandler:user => dispatch(register(user)),
    resetSignup:() => dispatch({type:RESET_SIGNUP}),
    resetSigninHandler:()=>dispatch({type:RESET_SIGNIN}),
    getAllStateHandler: () => dispatch(getAllState()),
    getAllCityHandler: user => dispatch(getAllCity(user)),
})

export default connect(mapStateToProp,mapDispatchToProps)(Signup)
// export default Home;