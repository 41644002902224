import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllClubEvent,
  getClubDashBoard,
  getClubNotification,
} from "../../../service/action/Club/clubAction";
import Spinner from "../../../Spinner/Spinner";
import { RESET_GET_PAYMENT_REQUEST } from "../../../service/constants";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";
import DashboardStats from "./components/DashboardStats";
import Notifications from "./components/Notifications";
import NotificationHeading from "./components/NotificationHeading";
import EventsHeading from "./components/EventsHeading";
import EventsList from "./components/EventsList";

const ClubDashBoard = () => {
  let [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const AllEvent = useSelector((state) => state.getAllClubEventReducer.data);
  const Notification = useSelector(
    (state) => state.getNotificationReducer.user.data
  );
  const accountDetails = useSelector(
    (state) => state.accountDetailsReducer?.user?.data
  );

  const isAccountSwitch = useSelector((state) => state.switchAccountReducer);

  const [firstRender, setFirstRender] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (firstRender) {
      if (token !== null) {
        if (!isAccountSwitch?.loading) {
          dispatch(getAllClubEvent({ id: 0 }));
          dispatch(getClubNotification());
          dispatch({ type: RESET_GET_PAYMENT_REQUEST });
          dispatch(getClubDashBoard());
        }
      }
    }
  }, [firstRender, isAccountSwitch]);

  useEffect(() => {
    setFirstRender(true);
  }, []);

  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Dashboard</h5>
          </div>
          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>
          {!loading && (
            <>
              <DashboardStats setLoading={setLoading} />

              <div className="row mx-0 divide-x mt-4">
                <div className="col-md-6">
                  <NotificationHeading accountDetails={accountDetails} />
                  <Notifications
                    accountDetails={accountDetails}
                    notification={Notification}
                  />
                </div>
                <div className="col-md-6 pl-4">
                  <EventsHeading />
                  <EventsList AllEvent={AllEvent} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClubDashBoard;
