import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
// import Toaster from '../../../Toaster/Toaster';
import { withRouter, Redirect, useHistory, Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { createSeason } from "../../service/action/userAction";
import { RESET_CREATE_SEASON } from "../../service/constants";
import classNames from "classnames";
import Toaster from "../../Toaster/Toaster";
import BreadcrumbHistory from "../../BreadcumbHistory/BreadcrumbHistory";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

function Club_createSeason(props) {
  const history = useHistory();
  const location = useLocation();

  const msg = useSelector((state) => state.createSeasonReducer.message);

  const [seasonName, setSeasonName] = useState("");

  const [errors, setErrors] = useState({
    season_name: false,
  });

  //setTimeout form reseting data after submit of form
  function resetData() {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }

  //clear data on submit.
  const clearState = () => {
    setSeasonName("");
  };

  // const [checked, setChecked] = useState(false)
  // const onClickCheck = () => setChecked(!checked)

  const eventHandler = (e) => {
    e.preventDefault()
    const { id, value, name } = e.target;

    setSeasonName(value);

    switch (name) {
      case "season_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            season_name: "Please add season name",
          }));

          document.getElementById("season_name").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            season_name: false,
          }));

          document.getElementById("season_name").classList.remove("error");
        }

        break;

      default:
    }
  };

  const notify = (msg) => toast(msg);

  const submitRequest = (e) => {
    e.preventDefault();
    // alert("click")

    if (seasonName === "") {
      setErrors((prevState) => ({
        ...prevState,
        season_name: "Please add season name",
      }));
      document.getElementById("season_name").classList.add("error");
    }

    if (seasonName !== "") {
      props.createSeasonhandler({
        season_name: seasonName,
      });
      // if (props.submitRequest?.success) {
      //     notify("Data submitted successfully.")
      // }
      // resetData().then(clearState);
    }

    // alert(state.checkbox)
  };

  useEffect(() => {
    // alert("a")
    if (props.submit?.success) {
      notify(msg.message);
      resetData().then(clearState);
      history.goBack();
    }
  }, [props.submit]);

  return (
    <>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Add Season</h5>
          </div>

          <div className="row mx-0">
            <div className="col-md-4 col-xl-4">
              <div className="mt-24">
                <div className="text-left">
                  <label className="text-gray fs-13">
                    Season<span className="text-red fw-m">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control team-name"
                    id="season_name"
                    name="season_name"
                    value={seasonName}
                    placeholder="Season Name *"
                    onChange={eventHandler}
                  />

                  {errors.season_name && (
                    <span className="text-red small d-block">
                      * {errors.season_name}
                    </span>
                  )}
                </div>

                <div className="mt-3 text-left">
                  <div
                    className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                    onClick={submitRequest}
                  >
                    Submit
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
    </>
  );
}
const mapStateToProp = (state) => ({
  submit: state.createSeasonReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createSeasonhandler: (user) => dispatch(createSeason(user)),
  resetCreateSeasonReducer: () => dispatch({ type: RESET_CREATE_SEASON }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Club_createSeason));
