import React from "react";
import { Link } from "react-router-dom";
const NotificationHeading = ({ accountDetails }) => {
  return (
    <div className="text-left payment-head notification-head border-bottom pb-3">
      <h6 className="payment">Notification </h6>
      <Link
        className="see-all text-decoration-none"
        to={{
          // pathname: "/notifications",
          pathname: `/account-notifications/${accountDetails?._id}`,
          state: {
            id: accountDetails?._id,
            type: parseInt(localStorage.getItem("type")),
            name: "notification",
          },
        }}
      >
        See all
      </Link>
    </div>
  );
};

export default NotificationHeading;
