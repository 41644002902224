    import React,{useState} from 'react'
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const Spinner = () => {
//const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;
let [loading, setLoading] = useState(true);
let [color, setColor] = useState("#143D4A");
    return (
        <div>
            <ClipLoader color={color} loading={loading} css={css} size={50} />
        </div>
    )
}
export default Spinner
