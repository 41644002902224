import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { Link } from "react-router-dom";
import Spinner from "../../../Spinner/Spinner";
import { getPaymentRequest } from "../../../service/action/userAction";
import { connect, useSelector } from "react-redux";
import { RESET_GET_PAYMENT_REQUEST } from "../../../service/constants";
import Moment from "react-moment";

const PaymentReq = (props) => {
  let [loading, setLoading] = useState(true);
  useEffect(() => {
    props.resetGetPaymentCode();
  }, []);

  useEffect(() => {
    props.getPaymentRequestHandler();
  }, []);

  let history = useHistory();
  const allpaymentReq = useSelector(
    (state) => state.getPaymentRequestReducer.user.data
  );
  const [outStanding, setOutStanding] = useState(null);
  const [received, setReceived] = useState(null);
  const [date, setDate] = useState(null);
  const formatYmd = (date) => date.toISOString().slice(0, 10);

  useEffect(() => {
    if (allpaymentReq !== undefined) {
      setLoading(false);
      setReceived(allpaymentReq.filter((e) => e.payment_terms == 1));
      setOutStanding(allpaymentReq.filter((e) => e.payment_terms == 0));

      // const today = new Date()
      // const date = allpaymentReq.map((v) => (v.createdAt))
      // const due_date=allpaymentReq.map((v,i)=>v.due_date)
      // const due=due_date.map((v,i)=>v)
      // const createDate = date.map(v => v)
      // const createFormate=createDate.map((v,i)=>formatYmd(new Date(v)));
      // const DueFormate=due.map((v,i)=>formatYmd(new Date(v)));
    }
  }, [allpaymentReq]);

  const green_tbl = [
    {
      recipient: "green 1",
      event: "xyz",
      invoice: "code",
      date: "June 29,2022",
      payment_id: "1",
    },
    {
      recipient: "green 2",
      event: "abc",
      invoice: "code",
      date: "June 29,2022",
      payment_id: "2",
    },
  ];

  const yellow_tbl = [
    {
      recipient: "yellow 1",
      event: "xyz",
      invoice: "code",
      date: "June 29,2022",
      payment_id: "1",
    },
    {
      recipient: "yellow 2",
      event: "abc",
      invoice: "code",
      date: "June 29,2022",
      payment_id: "2",
    },
  ];

  const red_tbl = [
    {
      recipient: "red 1",
      event: "xyz",
      invoice: "code",
      date: "June 29,2022",
      payment_id: "1",
    },
    {
      recipient: "red 2",
      event: "abc",
      invoice: "code",
      date: "June 29,2022",
      payment_id: "2",
    },
  ];

  const newReq = () => {
    history.push("/payment/report/create-payment-request");
  };

  const [state, setState] = useState({
    search: "",
  });

  const Searchval = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    UpdateArray(e.target.value);
  };

  const UpdateArray = (search) => {
    var query = search;

    $(".filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".pay-row").addClass("d-none");
      } else {
        $(v).parents(".pay-row").removeClass("d-none");
      }
    });
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>

          {!loading && (
            <>
              <div className="text-left">
                <div className="create-team-head-event">
                  <div className="d-flex align-items-center">
                    <h5 className="dash-head pr-2 mb-0">Payment Requests</h5>
                    <div className="d-md-flex align-items-center d-none">
                      <div className="search-section mt-4 mt-md-0">
                        <i className="mdi mdi-magnify search-ico"></i>
                        <input
                          type="text"
                          className="form-control"
                          id="search"
                          name="search"
                          value={state.search}
                          placeholder="Search"
                          onChange={Searchval}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="dropdown details-drp">
                    <div className="mt-3 mt-md-0 text-right">
                      <button className="btn-create-new" onClick={newReq}>
                        Create New
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="team-details mt-4 mt-md-0 justify-content-between d-flex align-items-center">
                <ul
                  className="nav nav-pills mb-3 mt-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="teams"
                      data-toggle="pill"
                      href="#teams-tab"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      All Requests
                    </a>
                  </li>
                  <li className="nav-item " role="presentation">
                    <a
                      className="nav-link "
                      id="=players"
                      data-toggle="pill"
                      href="#players-tab"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Outstanding
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="=coches"
                      data-toggle="pill"
                      href="#coaches-tab"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Received
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="=refund"
                      data-toggle="pill"
                      href="#Refund-tab"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Refunded
                    </a>
                  </li>
                </ul>
              </div>

              <div className="tab-content" id="pills-tabContent">
                {/* <<<<<<<<<<<<<<<<-----------------------All Request Start------------------>>>>>>>>>>>>>>>> */}
                <div
                  className="tab-pane fade show active"
                  id="teams-tab"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="green-section mt-30">
                    {/* <h6 className="text-left green-head">Green Section</h6> */}
                    {allpaymentReq && allpaymentReq.length == 0 ? (
                      <>
                        <div className="container d-flex justify-content-center align-items-center">
                          <span className="noti-type">
                            No payment requests to display
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* desktop view */}
                        <div className="table-responsive d-none d-md-block">
                          <table className="table green-table mt-24">
                            <thead>
                              <tr>
                                <th>Recipient</th>
                                <th>Event</th>
                                <th>Invoice</th>
                                <th>Date</th>
                                <th>Payment Id</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allpaymentReq &&
                                allpaymentReq.map((v, i) => {
                                  return (
                                    <tr className="pay-row" key={i}>
                                      <td>
                                        <span className="filter-string">
                                          {v.recipient.first_name}
                                        </span>
                                      </td>
                                      <td>{v.event.event_name}</td>
                                      <td>{v.amount}</td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>

                        {/* mobile view */}
                        <div className="mt-3 d-md-none">
                          {green_tbl.map((v, i) => {
                            return (
                              <div
                                className="d-flex justify-content-between reg-details"
                                key={i}
                              >
                                <div className="d-flex">
                                  <div className="reg-content pay-row">
                                    <Link
                                      className="text-decoration-none text-blue fw-m filter-string"
                                      to="/event-details"
                                    >
                                      {v.recipient}
                                    </Link>
                                    <span className="d-block text-gray mt-2">
                                      Event: {v.event}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* <<<<<<<<<<<<<<<<-----------------------All payment req End------------------>>>>>>>>>>>>>>>> */}

                {/* <<<<<<<<<<<<<<<<-----------------------OutStanding Start------------------>>>>>>>>>>>>>>>> */}

                <div
                  className="tab-pane fade"
                  id="players-tab"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="yellow-section mt-30">
                    {outStanding ? (
                      <>
                        {outStanding && outStanding.length == 0 ? (
                          <>
                            <div className="container d-flex justify-content-center align-items-center">
                              <span className="noti-type">
                                No payment outstanding to display
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* <h6 className="text-left yellow-head">Yellow Section</h6> */}
                            {/* desktop view */}
                            <div className="table-responsive d-none d-md-block">
                              <table className="table yellow-table mt-24">
                                <thead>
                                  <tr>
                                    <th>Recipient</th>
                                    <th>Event</th>
                                    <th>Amount</th>
                                    {/* <th>Date</th>
                                                <th>Payment Id</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {outStanding.map((v, i) => {
                                    return (
                                      <tr key={i} className="pay-row">
                                        <td>
                                          <span className="filter-string">
                                            {v.recipient.first_name}
                                          </span>
                                        </td>
                                        <td>{v.event.event_name}</td>
                                        <td>{v.amount}</td>
                                        {/* <td>{v.date}1</td>
                                                        <td>{v.payment_id}</td> */}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>

                            {/* mobile view  */}
                            <div className="mt-3 d-md-none">
                              {yellow_tbl.map((v, i) => {
                                return (
                                  <div
                                    className="d-flex justify-content-between reg-details"
                                    key={i}
                                  >
                                    <div className="d-flex">
                                      <div className="reg-content pay-row">
                                        <Link
                                          className="text-decoration-none text-blue fw-m filter-string"
                                          to="/event-details"
                                        >
                                          {" "}
                                          {v.recipient}
                                        </Link>
                                        <span className="d-block text-gray mt-2">
                                          Event: {v.event}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>

                {/* <<<<<<<<<<<<<<<<-----------------------OutStanding End------------------>>>>>>>>>>>>>>>> */}

                {/* <<<<<<<<<<<<<<<<-----------------------Receive Start------------------>>>>>>>>>>>>>>>> */}

                <div
                  className="tab-pane fade"
                  id="coaches-tab"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="red-section mt-30">
                    {received ? (
                      <>
                        {received.length == 0 ? (
                          <>
                            <div className="container d-flex justify-content-center align-items-center">
                              <span className="noti-type">
                                No payment received to display
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* <h6 className="text-left red-head">Red Section</h6> */}
                            {/* <h6 className="text-left green-head">Green Section</h6> */}

                            {/* desktop view */}
                            <div className="table-responsive d-none d-md-block">
                              <table className="table red-table mt-24">
                                <thead>
                                  <tr>
                                    <th>Recipient</th>
                                    <th>Event</th>
                                    <th>Amount</th>
                                    {/* <th>Date</th> */}
                                    {/* <th>Payment Id</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {received.map((v, i) => {
                                    return (
                                      <tr key={i} className="pay-row">
                                        <td>
                                          <span className="filter-string">
                                            {v.recipient.first_name}
                                          </span>
                                        </td>
                                        <td>{v.event.event_name}</td>
                                        <td>{v.amount}</td>
                                        {/* <td>{v.date}1</td> */}
                                        {/* <td>{v.payment_id}</td> */}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>

                            {/* mobile view */}
                            <div className="mt-3 d-md-none red-table">
                              {red_tbl.map((v, i) => {
                                return (
                                  <div
                                    className="d-flex justify-content-between reg-details"
                                    key={i}
                                  >
                                    <div className="d-flex">
                                      <div className="reg-content pay-row">
                                        <Link
                                          className="text-decoration-none text-blue fw-m filter-string"
                                          to="/event-details"
                                        >
                                          {v.recipient}
                                        </Link>
                                        <span className="d-block text-gray mt-2">
                                          Event: {v.event}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>

                {/* <<<<<<<<<<<<<<<<-----------------------Receive End------------------>>>>>>>>>>>>>>>> */}

                <div
                  className="tab-pane fade show active"
                  id="Refund-tab"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                ></div>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

// export default PaymentReq;

const mapStateToProps = (state) => ({
  allpayment: state.getPaymentRequestReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentRequestHandler: (user) => dispatch(getPaymentRequest(user)),
  resetGetPaymentCode: () => dispatch({ type: RESET_GET_PAYMENT_REQUEST }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReq);
