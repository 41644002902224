import React from "react";
import { useDebounce } from "use-debounce/lib";
import $ from "jquery";
import { Link, useParams, withRouter } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import {
  getAcceptedPlayer,
  getEventTeam,
  getTeamDetails,
} from "../../../service/action/userAction";
import { connect, useSelector } from "react-redux";
import { Table } from "react-bootstrap";

const SearchTeam = (props) => {
  const [socket, setSocket] = useState();

  const { id, id1, id2, id3 } = useParams();

  const [message, setMessage] = useState("");
  const debouncedSearchTerm = useDebounce(message, 1000);

  const [teamList, setTeamList] = useState({
    club: [],
    organizer: [],
  });

  useEffect(() => {
    props.getTeamDetailsHandler({ id: id });
  }, []);

  useEffect(() => {
    props.getAcceptedPlayerhandler({ id: id });
  }, []);

  const getTeamdetails = useSelector(
    (state) => state.getTeamDetailsReducer.user.data
  );

  useEffect(() => {
    if (props.getEventTeamReducer.success) {
      const data = props.getEventTeamReducer;
      setTeamList(data.user);
    }
  }, [props.getEventTeamReducer]);
  useEffect(() => {
    if (props.submit?.success) {
      // notify(deleteEventMsg.message);
      // props.resetdeletePaymentCodeReducer();
      props.getAcceptedPlayerhandler({ id: id });
      // history.push(`/event-details/${id}`);
    }
  }, [props.submit]);

  useEffect(() => {
    setTimeout(() => {
      if (socket) {
        socket.emit("typingEnd", { roomId: id1, userType: id3 });
      }
    }, 1000);
  }, [debouncedSearchTerm]);

  const [searchstate, setsearchstate] = useState({
    search: "",
  });

  const Searchval = (e) => {
    const { id, value } = e.target;
    setsearchstate((prevsearchstate) => ({
      ...prevsearchstate,
      [id]: value,
    }));
    UpdateArray(e.target.value);
  };

  const UpdateArray = (search) => {
    if (props.getEventTeamReducer.success) {
      const data = props.getEventTeamReducer.user;
      if (search == "") {
        setTeamList(data);
      } else {
        const filterOrg = data.organizer.filter((e) => {
          return e.team_id.team_name
            .toLowerCase()
            .includes(search.toLowerCase().trim());
        });

        setTeamList((pre) => ({
          ...pre,
          organizer: [...filterOrg],
        }));
      }
    }
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className=" ">
            {/* <div className="recent_heading"> */}
            {/* <h4>Recent</h4> */}
            {/* </div> */}
            <div className="d-md-flex align-items-center d-none">
              <div className="search-section">
                <i className="mdi mdi-magnify search-ico"></i>
                <input
                  type="text"
                  className="form-control"
                  id="search"
                  name="search"
                  value={searchstate.search}
                  placeholder="Search"
                  onChange={Searchval}
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="mt-5">
              {teamList.organizer.length != 0 ? (
                <>
                  {teamList.organizer.map((e, i) => {
                    return (
                      <div
                        className="d-flex justify-content-between reg-details pay-row border-bottom pb-3 mt-3"
                        key={i}
                      >
                        <div className="d-flex">
                          <div className="dashboard-img mr-3">
                            <img src={"./images/pch-images/pch.svg"} />
                          </div>
                          <div className="reg-content">
                            <strong>{e?.team_id?.team_name}</strong>
                            {/* <span className="d-block text-gray mt-2">Starts: {formatYmd(new Date(v.event_from_date))}</span> */}
                            <span className="d-block text-gray mt-2">
                              Division:{" "}
                              {!!e.division && e.division?.division_name !== ""
                                ? e.division.division_name
                                : "N/A"}
                            </span>
                            <span className="d-block text-gray mt-2">
                              Pool:{" "}
                              {!!e.pool && e.pool?.pool_name !== ""
                                ? e.pool.pool_name
                                : "N/A"}
                            </span>
                            <span className="d-block text-gray mt-2">
                              Status: {e.status == 1 ? "Open" : "Closed"}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="d-flex justify-content-center noti-type">
                  No team to display
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  getEventTeamReducer: state.getEventTeamReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getTeamDetailsHandler: (user) => dispatch(getEventTeam(user)),
  getAcceptedPlayerhandler: (user) => dispatch(getAcceptedPlayer(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchTeam));
