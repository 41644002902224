import {  RESET_SEND_INVITATION_EMAIL, SEND_INVITATION_EMAIL_ERROR, SEND_INVITATION_EMAIL_LOADING, SEND_INVITATION_EMAIL_SUCCESS} from "../../constants";

const initialState={
    error:false,
    success:false,
    userL:{},
    loading:false,
    errors:[]
}

export default function sendInvitationByMailReducer(state=initialState,action){
    switch(action.type){
        case SEND_INVITATION_EMAIL_LOADING:
            return{...state,error:false,success:false,loading:true}
        
        case SEND_INVITATION_EMAIL_SUCCESS:
            return {...state,error:false,loading:false,success:true, message:action.message}

        case SEND_INVITATION_EMAIL_ERROR:
            return { ...state,error:true,errors:[],success:false,loading:false}

        case RESET_SEND_INVITATION_EMAIL:
            return {...state,error:false, loading :false, success :false}
        default:
            return state
    }
}