import { DELETE_PAYMENT_METHOD, PAYMENT_METHOD_ERROR, PAYMENT_METHOD_LOAING, PAYMENT_METHOD_SUCCESS, RESET_PAYMENT_METHOD } from "../../constants";

let initialState = {
 loading: false,
 data: [],
 error: false,
 errors: [],
 success: false,
};
export default function paymentMethodReducer(state = initialState, action) {
 switch (action.type) {
  case PAYMENT_METHOD_LOAING:
   return { ...state, loading: true };

  case PAYMENT_METHOD_SUCCESS:
   return { ...state, loading: false, success: true, data: action.data };

  case PAYMENT_METHOD_ERROR:
   return { ...state, error: true, loading: false, success: false, errors: action.errors };

  case RESET_PAYMENT_METHOD:
   return { ...state, error: false, loading: false, success: false, errors: [] };

  default:
   return state;
 }
}


