import {DELETE_SCHEDULE_ERROR, DELETE_SCHEDULE_LOADING, DELETE_SCHEDULE_SUCCESS, RESET_DELETE_SCHEDULE } from "../../constants";

let initialState={
    success:false,
    error:false,
    loading:false,
    error:[]
}


export default function deleteScheduleReducer(state=initialState,action){
    switch(action.type){
        case DELETE_SCHEDULE_LOADING:
            return {...state,success:false,loading:true,error:false}
        case DELETE_SCHEDULE_SUCCESS:
            return {...state,success:true,loading:false,error:false,message:action.msg}
        case DELETE_SCHEDULE_ERROR:
            return {...state,success:false,loading:false,error:false}
        case RESET_DELETE_SCHEDULE:
            return {...state,success:false,loading:false,error:false}
        default :
        return state
    }
}