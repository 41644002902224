import { RESET_FREETEAM, UPDATE_FREETEAM_ERROR, UPDATE_FREETEAM_LOADING, UPDATE_FREETEAM_SUCCESS } from "../../constants";

let initialState={
    success:false,
    error:false,
    loading:false,
    error:[],
    msg:{}
}

export default function updateFreeTeamReducer(state=initialState,action){
    switch(action.type){
        case UPDATE_FREETEAM_LOADING:
            return{...state, loading:true,error:false, success:false }
        case UPDATE_FREETEAM_SUCCESS:
            return{...state,success:true,loading:false,error:false,msg:action.msg}
        case UPDATE_FREETEAM_ERROR:
            return {...state,error:true,loading:false,success:false,error:[action.msg]}
        case RESET_FREETEAM:
            return{...state,error:false,loading:false,success:false,error:[]}
        default :
        return state
    
    }
}