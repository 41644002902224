import {  GET_EVENT_DETAILS_ERROR, GET_EVENT_DETAILS_LOADING, GET_EVENT_DETAILS_SUCCESS } from "../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
  data:[]
};

export default function getOrgEventDetailsByIdReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EVENT_DETAILS_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case GET_EVENT_DETAILS_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };
     
    case GET_EVENT_DETAILS_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}
