import React, { useEffect, useState } from 'react'
import $ from "jquery";
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { deleteField, getField } from '../../service/action/Club/clubAction';
import { RESET_CREATE_PAYMENT_CODE, RESET_DELETE_EVENT, RESET_EDIT_SEASON } from '../../service/constants';
import { toast } from 'react-toastify';
import Toaster from '../../Toaster/Toaster';
import Spinner from '../../Spinner/Spinner';
import DeletePopUp from '../components/DeletePopUp/DeletePopUp';
import BreadcrumbHistory from '../../BreadcumbHistory/BreadcrumbHistory';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';





const Field = (props) => {

    const getFieled = props.getFieledHandler;
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [isDelete, setIsDelete] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const [searchValue, setSearchValue] = useState('')


    const notify = (msg) => toast(msg);

    const allfield = useSelector(state => state.getFieldReducer.user.data)
    const deleteFieldMsg = useSelector(state => state.deleteEventReducer.message)
    const editMsg = useSelector(state => state.editSeasonReducer.message)

    const fieldMsg = useSelector(state => state.createPaymentCodeReducer.message)

    useEffect(() => {
        getFieled()
    }, [])

    useEffect(() => {
        if (props.submit?.success) {
            notify(deleteFieldMsg.message)
            props.resetdeletePaymentCodeReducer()
            getFieled()

        }

    }, [props.submit])

    useEffect(() => {
        if (props.Msg?.success) {
            notify(editMsg.message)
            props.resetEditSeason()
        }
    }, [props.Msg])


    useEffect(() => {
        if (props.field?.success) {
            setLoading(false)
        }

    }, [props.field])


    const green_tbl = [
        {
            name: "Rio"
        },
        {
            name: "Jhon"
        }
    ]

    useEffect(() => {
        if (props.createfield?.success) {
            notify(fieldMsg.message)
            props.resetCreateFieldReducer()

        }

    }, [props.createfield])


    const handleSearch = (e) => {
        setSearchValue(e.target.value)
        $(".team-table .filter-string").each(function (i, v) {

            if ($(v).text().toLowerCase().indexOf(e.target.value) === -1) {
                $(v).parents(".team-row").addClass("d-none");
            } else {
                $(v).parents(".team-row").removeClass("d-none");
            }
        });
    }

    return (
        <>
            <div className="main-panel">
                <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />
                <div className="content-wrapper">
                    <div className='d-flex justify-content-center'>

                        {loading ? <Spinner /> : null}
                    </div>


                    {!loading &&
                        allfield ? <>
                        <div className='d-flex justify-content-between flex-wrap'>

                            <div className="d-flex align-items-center">
                                <h5 className="dash-head mr-3 mb-0">Fields</h5>
                                <div className="search-section mt-3 mt-md-0">
                                    <i className="mdi mdi-magnify search-ico"></i>
                                    <input type="text" className="form-control" id="search" name="search" value={searchValue} onChange={handleSearch} placeholder="Search" autoComplete="off" />
                                </div>
                            </div>
                            <div className="text-right">
                                <div >

                                    <div className="mt-3 mt-md-0 text-right create-team-head-btn">
                                        <Link to="/field/create-field">
                                            <button className="btn-create-new ">+Add</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="green-section mt-30">
                            <h6 className="text-left green-head">Field List</h6>
                            <div className="table-responsive d-none d-md-block">
                                <table className="table green-table mt-24 team-table">
                                    <thead>
                                        <tr>
                                            <th>Field Name</th>
                                            <th></th>

                                        </tr>
                                    </thead>

                                    {/* Season data callS */}

                                    <tbody>
                                        {allfield.length == 0 ? <><span className='noti-type'>You Don't have any Field yet</span> </> : <>

                                            {

                                                allfield.map((v, i) => {
                                                    return <tr className="pay-row team-row" key={v._id}>
                                                        <td><span className="filter-string">{v.name}</span></td>
                                                        <td >
                                                            <div className="dropdown event-drp">
                                                                <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                {/* More */}
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                    <Link className="dropdown-item" to={(`/field/${v._id}`)}>
                                                                        <i className="mdi mdi-border-color"></i>
                                                                        Edit</Link>

                                                                    <Link className="dropdown-item" to="/field" onClick={() => {
                                                                        setIsDelete(true)
                                                                        setDeleteId(v._id)
                                                                    }}>
                                                                        <i className="fas fa-trash-alt"></i>  Delete</Link>
                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                })
                                            }
                                        </>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/* Mobile View  */}
                            <div className="mt-30 d-md-none">
                                <div className="mt-4">
                                    {
                                        allfield.map((v, i) => {
                                            return <div className="d-flex justify-content-between reg-details" key={v._id}>
                                                <div className="d-flex">
                                                    <div className="reg-content">
                                                        <Link className="text-decoration-none text-black" to="/event-details">{v.name}</Link>
                                                    </div>
                                                </div>
                                                <div className="dropdown event-drp">
                                                    <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <Link className="dropdown-item" to={(`/season/${v._id}`)}>
                                                            <i className="mdi mdi-border-color"></i> Edit
                                                        </Link>
                                                        <Link className="dropdown-item" to="/field" onClick={() => {
                                                            setIsDelete(true)
                                                            setDeleteId(v._id)
                                                        }}>
                                                            <i className="fas fa-trash-alt"></i> Delete</Link>
                                                    </div>
                                                </div>


                                            </div>
                                        })
                                    }

                                </div>
                            </div>


                        </div>
                    </> : ""
                    }
                    <Toaster />
                </div>
            </div>
            <DeletePopUp
                isOpen={isDelete}
                isClose={setIsDelete}
                handleEvent={() => props.deleteFieldHandler(deleteId)}
            />
        </>
    )
}



const mapStateToProps = (state) => ({
    submit: state.deleteEventReducer,
    Msg: state.editSeasonReducer,
    field: state.getFieldReducer,
    createfield: state.createPaymentCodeReducer,

})

const mapDispatchToProps = dispatch => ({
    getFieledHandler: (user) => dispatch(getField(user)),
    deleteFieldHandler: user => dispatch(deleteField(user)),
    resetdeletePaymentCodeReducer: () => dispatch({ type: RESET_DELETE_EVENT }),
    resetEditSeason: () => dispatch({ type: RESET_EDIT_SEASON }),
    resetCreateFieldReducer: () => dispatch({ type: RESET_CREATE_PAYMENT_CODE })

})

export default connect(mapStateToProps, mapDispatchToProps)(Field)