import {
  ADD_LIABILITY_ERROR,
  ADD_LIABILITY_SUCCESS,
  GET_LIABILITY_ERROR,
  GET_LIABILITY_SUCCESS,
  RESET_ADD_LIABILITY,
  RESET_GET_LIABILITY,
  RESET_UPDATE_LIABILITY,
  UPDATE_LIABILITY_ERROR,
  UPDATE_LIABILITY_SUCCESS,
} from "../constant";

let initialState = {
  getLiabilityDetails: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  updateLiabilityDetails: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  addLiabilityDetails: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
};
export default function LiabilityRecucer(state = initialState, action) {
  switch (action.type) {
    //  get details
    case GET_LIABILITY_SUCCESS:
      return {
        ...state,
        getLiabilityDetails: {
          ...state.getLiabilityDetails,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_LIABILITY_ERROR:
      return {
        ...state,
        getLiabilityDetails: {
          ...state.getLiabilityDetails,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_LIABILITY:
      return {
        ...state,
        getLiabilityDetails: {
          ...state.getLiabilityDetails,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    //  add details
    case ADD_LIABILITY_SUCCESS:
      return {
        ...state,
        addLiabilityDetails: {
          ...state.addLiabilityDetails,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case ADD_LIABILITY_ERROR:
      return {
        ...state,
        addLiabilityDetails: {
          ...state.addLiabilityDetails,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_ADD_LIABILITY:
      return {
        ...state,
        addLiabilityDetails: {
          ...state.addLiabilityDetails,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    //  update details
    case UPDATE_LIABILITY_SUCCESS:
      return {
        ...state,
        updateLiabilityDetails: {
          ...state.updateLiabilityDetails,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case UPDATE_LIABILITY_ERROR:
      return {
        ...state,
        updateLiabilityDetails: {
          ...state.updateLiabilityDetails,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_UPDATE_LIABILITY:
      return {
        ...state,
        updateLiabilityDetails: {
          ...state.updateLiabilityDetails,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    default:
      return state;
  }
}
