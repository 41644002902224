import React, { useState, useEffect } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import Toaster from '../../Toaster/Toaster';
import { withRouter } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { RESET_LEAGUE } from '../../service/constants';
import { useHistory } from "react-router-dom";
import { editLeague } from '../../service/action/Club/clubAction';
import BreadcrumbHistory from '../../BreadcumbHistory/BreadcrumbHistory';




const EditLeague = (props) => {
    let history = useHistory();
    const { id } = useParams()
    const location = useLocation()

    const [leagueName, setLeagueName] = useState('')
    const [error, setError] = useState(false)

    const league = useSelector(state => state.getLeagueReducer.user)

    const editLeague = league.find((e) => e._id == id)

    useEffect(() => {
        setLeagueName(editLeague?.league_name)
    }, [editLeague])

    const eventHandler = (e) => {
        const { value } = e.target

        setLeagueName(value)

        if (value === '') {
            setError('League name not empty')
        } else {
            setError(false)
        }
    }

    const submitRequest = (e) => {
        e.preventDefault();

        if (leagueName !== '') {
            props.editLeagueHandler({
                id: id,
                league_name: leagueName
            })
        }
    }

    const notify = (msg) => toast(msg);

    useEffect(() => {

        if (props.submit?.success) {
            history.push("/leagues")
        }
        else if (props.submit?.error) {
            notify("League not created")
        }
    }, [props.submit])

    return (
        <>
            <div className="main-panel">
            <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />

                <div className="content-wrapper">
                    <div className="text-left">
                        <div>
                            <h5 className="dash-head">Edit League</h5>
                            <div className="row">
                                <div className="col-md-4 col-xl-4">
                                    <div className="mt-24">
                                        <div className="text-left">
                                            <label className="text-gray fs-13">League Name <span className="text-red fw-m">*</span></label>
                                            <input type="text" className="form-control team-name" id="league_name" name="league_name" value={leagueName} placeholder="League Name *" onChange={eventHandler} />

                                            {error &&
                                                <span className='text-red small d-block'>* {error}</span>}

                                        </div>

                                        <div className="mt-3 text-left">
                                            <a className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitRequest}>Submit</a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <Toaster />
                </div>
            </div>

        </>
    )
}

// export default withRouter(Org_CreateDivision)



const mapStateToProp = state => ({
    submit: state.updateLeagueREducer,

})

const mapDispatchToProps = dispatch => ({

    editLeagueHandler: user => dispatch(editLeague(user)),
    resetCreateLeagueReducer: () => dispatch({ type: RESET_LEAGUE }),
})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(EditLeague));