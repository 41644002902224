import { GET_ADMIN_ERROR, GET_ADMIN_LIST_LOADING, GET_ADMIN_SUCCESS } from "../constants";

let initialState = {
  loading: false,
  data : {},
  error: false,
  errors: [],
  success: false,
  
};
export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN_LIST_LOADING : 
      return { ...state, error: false, loading: true, success: false , data : {}};

    case GET_ADMIN_SUCCESS:
      return { ...state, error: false, loading: false, success: true, message:action.msg , data : action.payload};

    case GET_ADMIN_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: [] , data : {} }

    default: return state;
  }
}


