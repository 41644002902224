import React, { useEffect, useState } from "react";
import { Button, Collapse, Modal } from "react-bootstrap";

import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../assets/images/logo-white.svg";
import { connect } from "react-redux";
import { RESET_LOGOUT, removeStorage } from "../Services/constant";
import { logout } from "../Services/Actions";

function Sidebar(props) {
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [isLogOut, setIsLogout] = useState(false);
  const location = useLocation().pathname;

  const usersRoutes = [
    "/superadmin/superAdmin",
    "/superadmin/superSubAdmin",
    "/superadmin/event-organizer",
    "/superadmin/club",
    "/superadmin/staff",
    "/superadmin/coach",
    "/superadmin/parentGuardian",
    "/superadmin/players",
  ];

  const handleLogOut = () => {
    props.logout();
  };

  useEffect(() => {
    if (props.logoutReducer.success) {
      props.resetLogout();
      removeStorage();
      history.push("/superadmin/login");
    }
  }, [props.logoutReducer]);
  return (
    <>
      <aside>
        <div className="logo">
          <img src={logo} className="img-fluid" alt="logo" />
        </div>
        <ul>
          <li
            className={
              location === "/superadmin" || location === "/superadmin/dashboard"
                ? "active"
                : ""
            }
            onClick={() => setOpen(false)}
          >
            <Link to="/superadmin/dashboard">
              <i className="ri-dashboard-fill mr-3"></i> Dashboard
            </Link>
          </li>
          <li className={usersRoutes.includes(location) ? "active" : ""}>
            <Link to="#" onClick={() => setOpen(!open)} aria-expanded={open}>
              {" "}
              <i className="ri-user-3-fill mr-3"></i> Users
            </Link>
            <Collapse in={open}>
              <ul className="sub-drp">
                <li
                  className={
                    location == "/superadmin/superAdmin" ? "active" : ""
                  }
                >
                  <Link to="/superadmin/superAdmin">
                    <i className="ri-arrow-right-s-line"></i>Super Admin
                  </Link>
                </li>
                <li
                  className={
                    location == "/superadmin/superSubAdmin" ? "active" : ""
                  }
                >
                  <Link to="/superadmin/superSubAdmin">
                    <i className="ri-arrow-right-s-line"></i>Super Sub-Admin
                  </Link>
                </li>
                <li
                  className={
                    location == "/superadmin/event-organizer" ? "active" : ""
                  }
                >
                  <Link to="/superadmin/event-organizer">
                    <i className="ri-arrow-right-s-line"></i>Event Organizers
                  </Link>
                </li>
                <li className={location == "/superadmin/club" ? "active" : ""}>
                  <Link to="/superadmin/club">
                    <i className="ri-arrow-right-s-line"></i>Clubs
                  </Link>
                </li>
                <li className={location == "/superadmin/staff" ? "active" : ""}>
                  <Link to="/superadmin/staff">
                    <i className="ri-arrow-right-s-line"></i>Staff
                  </Link>
                </li>
                <li className={location == "/superadmin/coach" ? "active" : ""}>
                  <Link to="/superadmin/coach">
                    <i className="ri-arrow-right-s-line"></i>Coaches
                  </Link>
                </li>
                <li
                  className={
                    location == "/superadmin/parentGuardian" ? "active" : ""
                  }
                >
                  <Link to="/superadmin/parentGuardian">
                    <i className="ri-arrow-right-s-line"></i>Parents & Guardian
                  </Link>
                </li>
                <li
                  className={location == "/superadmin/players" ? "active" : ""}
                >
                  <Link to="/superadmin/players">
                    <i className="ri-arrow-right-s-line"></i>Players
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li
            className={
              location === "/superadmin/merchant-account" ? "active" : ""
            }
            onClick={() => setOpen(false)}
          >
            <Link to="/superadmin/merchant-account">
              <i className="ri-store-2-fill mr-3"></i> Merchant Accounts
            </Link>
          </li>
          <li
            className={location === "/superadmin/Transaction" ? "active" : ""}
            onClick={() => setOpen(false)}
          >
            <Link to="/superadmin/Transaction">
              <i className="ri-repeat-fill mr-3"></i> Transactions
            </Link>
          </li>
          <li
            className={location === "/superadmin/config" ? "active" : ""}
            onClick={() => setOpen(false)}
          >
            <Link to="/superadmin/config">
              <i className="ri-settings-4-fill mr-3"></i> Configurations
            </Link>
          </li>
          <li
            className={location === "/superadmin/sports" ? "active" : ""}
            onClick={() => setOpen(false)}
          >
            <Link to="/superadmin/sports">
              <i className="ri-basketball-line mr-3"></i> Sports
            </Link>
          </li>
          <li
            className={location === "/superadmin/liability" ? "active" : ""}
            onClick={() => setOpen(false)}
          >
            <Link to="/superadmin/liability">
              <i className="ri-file-shield-line mr-3"></i> Liabilities
            </Link>
          </li>
          {/* <li
            onClick={() => {
              setIsLogout(true);
            }}
          >
            <Link to="#">
              <i className="ri-logout-box-line mr-3"></i> Logout
            </Link>
          </li> */}
        </ul>
      </aside>
      <Modal
        size="sm"
        show={isLogOut}
        onHide={() => setIsLogout(false)}
        centered
        className="del-dialogue"
      >
        <Modal.Body>
          <h6 className="text-center">Are you sure you want to Logout?</h6>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Button className="btn-gray" onClick={() => setIsLogout(false)}>
            No
          </Button>
          <Button variant="secondary" onClick={handleLogOut}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProp = (state) => ({
  logoutReducer: state.superAdminRootReducer.authReducers.logout,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  resetLogout: () => dispatch({ type: RESET_LOGOUT }),
});

export default connect(mapStateToProp, mapDispatchToProps)(Sidebar);
