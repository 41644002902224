import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  discountCode,
  getClubTeam,
  getEventDetailsForRegistration,
  getPaymentMethod,
  getRegTeamPayAmount,
  geteventDivision,
  payNow,
  teamRegister,
} from "../../../service/action/Club/clubAction";
import {
  RESET_CHANGE_INVITATION_STATUS,
  RESET_DISCOUNT_CODE_APPLY,
  RESET_PAYMENT_METHOD,
  RESET_PAYNOW,
  RESET_REGISTERED_TEAM,
  RESET_REGISTER_SELECTED_TEAM,
} from "../../../service/constants";

import classNames from "classnames";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import InputMask from "react-input-mask";
import Toaster from "../../../Toaster/Toaster";
import { toast } from "react-toastify";

function EventRegisterPayNow(props) {
  const { id, id1 } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [_state, set_State] = useState({
    organizer: localStorage.getItem("Id"),
    event_id: id1,
    invite_type: 0,
    teams: [],
    useCredit: false,
    discount: "",
  });

  const [error, setError] = useState({
    discount: false,
  });

  const [amountDetails, setAmountDetails] = useState({
    total: 0,
    discount: 0,
    proceesingFees: 0,
    credit: 0,
    totalAmount: 0,
  });

  const initialNewDetails = {
    cardNumber: "",
    expiry: "",
    cvv: "",
    name: "",
    zipCode: "",
  };
  const initialNewDetailsError = {
    cardNumber: false,
    expiry: false,
    cvv: false,
    name: false,
    zipCode: false,
  };

  const [payLoading, setPayLoading] = useState(false);

  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCardCvv, setSelectedCardCvv] = useState("");
  const [cardList, setCardList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCvv, setIsOpenCvv] = useState(false);
  const [isOpenCardDetails, setIsOpenCardDetails] = useState(false);

  const [newCardDetails, setNewCardDetails] = useState(initialNewDetails);
  const [newCardDetailsError, setNewCardDetailsError] = useState(
    initialNewDetailsError
  );
  const teamlist = useSelector((state) => state.teamArrayReducer.user);
  const availableTeam =
    teamlist && location.state.remainingTeam
      ? teamlist.slice(0, location.state.remainingTeam)
      : teamlist;

  const paymentMethodList = useSelector(
    (state) => state.getPaymentMethodReducer
  );
  const eventDetails = useSelector(
    (state) => state.getClubOrgEventDetailsReducer.data
  ).data;

  const allTeam = useSelector((state) => state.getClubAllTeamReducer.user);

  const [liability, setLiability] = useState(false);
  const [regTeamPayAmount, setRegTeamPayAmount] = useState([]);

  const regTeamPayAmountReduce = useSelector(
    (state) => state.getRegTeamPayAmountReducer
  );

  useEffect(() => {
    props.getPaymentMethod();
  }, []);

  useEffect(() => {
    if (paymentMethodList.success) {
      const data = paymentMethodList.data.data;
      setCardList(data);
      dispatch({ type: RESET_PAYMENT_METHOD });
    }
  }, [paymentMethodList]);

  useEffect(() => {
    if (regTeamPayAmountReduce.success) {
      const data = regTeamPayAmountReduce?.user?.data;
      setRegTeamPayAmount(data);
    }
  }, [regTeamPayAmountReduce]);
  useEffect(() => {
    if (!props.getDivision.success) {
      props.getEventDivisionHandler({ id: id1 });
    }
  }, [props.getDivision]);

  useEffect(() => {
    if (!!eventDetails) {
      if (eventDetails.is_paid_event == "1") {
        dispatch(
          getRegTeamPayAmount({
            event_id: id1,
            organizer: eventDetails?.user_id?._id,
            invite_type: location.state.inviteType == "1" ? 1 : 0,
            teams: location.state.teams.map((e) => {
              return {
                team: e.team,
                register_status: 1,
              };
            }),
          })
        );
      }
    }
  }, [eventDetails]);

  useEffect(() => {
    if (!props.getAllTeam.success) {
      props.getClubTeamHandler({ id: id });
    }
  }, [props.getAllTeam]);

  useEffect(() => {
    if (location.state) {
      setLiability(location.state.accept);
      if (!!location.state._state) {
        set_State(location.state._state);
      }
      if (!!location.state.amountDetails) {
        setAmountDetails(location.state.amountDetails);
      }
      if (!!location.state.selectedCard) {
        setSelectedCard(location.state.selectedCard);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (!!location.state) {
      if (!!location.state.teams) {
        const teams = location.state.teams;
        set_State((pre) => ({
          ...pre,
          teams: teams,
        }));
      } else {
        history.push(
          `/notification-accounts/${id}/register-event` + (id1 ? `/${id1}` : "")
        );
      }
    }
  }, [location.state]);

  //onRefresh Page redirect to back
  useEffect(() => {
    if (availableTeam === null) {
      history.push(
        `/notification-accounts/${id}/register-event` + (id1 ? `/${id1}` : "")
      );
      props.resetregisteredSelectedTeamReducer();
    }
  }, []);

  //for discount code
  const payNow = (e) => {
    e.preventDefault();
    if (!!liability) {
      if (eventDetails?.is_paid_event == "1") {
        if (!!selectedCard) {
          setIsOpenCvv(true);
          setIsOpenCardDetails(false);
        } else {
          setIsOpenCvv(false);
          setIsOpenCardDetails(true);
        }
      } else if (eventDetails?.is_paid_event == "0") {
        props.teamRegister({
          event_id: id1,
          organizer: eventDetails.user_id._id,
          teams: _state.teams,
          invite_type: location.state.inviteType == "1" ? 1 : 0,
        });
      }
    } else {
      history.push(`/liability-waiver-terms/${id1}`, {
        ...location.state,
        from: location.pathname,
        _state: _state,
        amountDetails: amountDetails,
        selectedCard: selectedCard,
      });
    }
  };

  useEffect(() => {
    if (props.discountReducer.success) {
      const data = props.discountReducer.user;
      if (!data.status) {
        setError((pre) => ({
          ...pre,
          discount: data?.message,
        }));
        dispatch({ type: RESET_DISCOUNT_CODE_APPLY });
      }
    }
  }, [props.discountReducer]);
  useEffect(() => {
    if (props.teamRegisterReducer.success) {
      history.push(`/notification-accounts/${id}`);
      dispatch({ type: RESET_REGISTERED_TEAM });
    }
  }, [props.teamRegisterReducer]);

  const handleCheckCode = () => {
    if (_state.discount !== "") {
      dispatch(
        discountCode({
          discount_code: _state.discount,
          organizer: eventDetails?.user_id?._id,
          amount: regTeamPayAmount
            .map((a) => a.amount)
            .reduce((a, b) => parseFloat(a) + parseFloat(b)),
          event: id1,
        })
      );
    } else {
      toast.error("Enter discount code.");
    }
  };

  useEffect(() => {
    const total = regTeamPayAmount
      .map((e) => e?.amount)
      .reduce((a, b) => {
        return (parseFloat(a) + parseFloat(b)).toFixed(2);
      }, 0);

    setAmountDetails((pre) => ({
      ...pre,
      totalAmount: total,
      proceesingFees: ((total * 3.9) / 100).toFixed(2),
      credit: parseFloat(regTeamPayAmount[0]?.getCredit).toFixed(2),
      total: (
        parseFloat(total) +
        parseFloat((total * 3.9) / 100) -
        parseFloat(_state.useCredit ? regTeamPayAmount[0]?.getCredit : 0)
      ).toFixed(2),
    }));
  }, [regTeamPayAmount]);

  useEffect(() => {
    setAmountDetails((pre) => ({
      ...pre,
      credit: _state.useCredit
        ? parseFloat(regTeamPayAmount[0]?.getCredit).toFixed(2)
        : 0,
      total: (
        parseFloat(pre.totalAmount) +
        parseFloat((parseFloat(pre.totalAmount) * 3.9) / 100) -
        parseFloat(_state.useCredit ? regTeamPayAmount[0]?.getCredit : 0)
      ).toFixed(2),
    }));
  }, [_state.useCredit]);

  const handleNewCard = (e) => {
    const { name, value } = e.target;
    if (name == "cardNumber") {
      setNewCardDetails((pre) => ({
        ...pre,
        [name]: value.replace(/\s/g, ""),
      }));
    } else if (name == "expiry") {
      setNewCardDetails((pre) => ({
        ...pre,
        [name]: value.replace("/", ""),
      }));
    } else {
      setNewCardDetails((pre) => ({
        ...pre,
        [name]: value,
      }));
    }
    switch (name) {
      case "name":
        if (value == "") {
          newCardDetailsError["name"] = "Required";
        } else {
          newCardDetailsError["name"] = false;
        }
        break;
      case "cardNumber":
        if (value == "") {
          newCardDetailsError["cardNumber"] = "Required";
        } else if (value.length != 19) {
          newCardDetailsError["cardNumber"] = "Required";
        } else {
          newCardDetailsError["cardNumber"] = false;
        }
        break;
      case "expiry":
        if (value == "") {
          newCardDetailsError["expiry"] = "Required";
        } else if (value.length != 5) {
          newCardDetailsError["expiry"] = "Required";
        } else {
          newCardDetailsError["expiry"] = false;
        }
        break;
      case "cvv":
        if (value == "") {
          newCardDetailsError["cvv"] = "Required";
        } else if (value.length != 3) {
          newCardDetailsError["cvv"] = "Required";
        } else {
          newCardDetailsError["cvv"] = false;
        }
        break;
      case "zipCode":
        if (value == "") {
          newCardDetailsError["zipCode"] = "Required";
        } else if (value.length != 5) {
          newCardDetailsError["zipCode"] = "Required";
        } else {
          newCardDetailsError["zipCode"] = false;
        }
        break;

      default:
        break;
    }
  };

  const handlePayWithNewCard = () => {
    if (newCardDetails.name == "") {
      newCardDetailsError["name"] = "required";
    }
    if (newCardDetails.cardNumber == "") {
      newCardDetailsError["cardNumber"] = "required";
    }
    if (newCardDetails.cvv == "") {
      newCardDetailsError["cvv"] = "required";
    }
    if (newCardDetails.expiry == "") {
      newCardDetailsError["expiry"] = "required";
    }
    if (newCardDetails.zipCode == "") {
      newCardDetailsError["zipCode"] = "required";
    }
    if (
      newCardDetails.name !== "" &&
      newCardDetails.cardNumber !== "" &&
      newCardDetails.cvv != "" &&
      newCardDetails.zipCode !== "" &&
      newCardDetails.expiry !== "" &&
      !newCardDetailsError.cardNumber &&
      !newCardDetailsError.cvv &&
      !newCardDetailsError.expiry &&
      !newCardDetailsError.zipCode
    ) {
      props.paynowHandler({
        club: localStorage.getItem("Id"),
        event: id1,
        payment_request: regTeamPayAmount[0]._id,
        organizer: eventDetails?.user_id?._id,
        amount: regTeamPayAmount
          .map((a) => a.amount)
          .reduce((a, b) => parseFloat(a) + parseFloat(b)),
        panding_amount: regTeamPayAmount
          .map((a) => a.pending_amount)
          .reduce((a, b) => parseFloat(a) + parseFloat(b)),
        credit_note: _state.useCredit,
        card_type: 0,
        is_card_payment: 1,
        transaction_type: "sale",
        card_number: newCardDetails.cardNumber,
        card_key: "",
        expiration_date: newCardDetails.expiry,
        name_on_code: newCardDetails.name,
        zip_code: newCardDetails.zipCode,
        card_verification_number: newCardDetails.cvv,
        invite_type: location.state.inviteType == "1" ? 1 : 0,
        team: _state.teams,
      });
      setPayLoading(true);
    }
  };

  const paymentWithSelectedCard = () => {
    props.paynowHandler({
      club: localStorage.getItem("Id"),
      event: id1,
      payment_request: regTeamPayAmount[0]._id,
      organizer: eventDetails?.user_id?._id,
      amount: regTeamPayAmount
        .map((a) => a.amount)
        .reduce((a, b) => parseFloat(a) + parseFloat(b)),
      panding_amount: regTeamPayAmount
        .map((a) => a.pending_amount)
        .reduce((a, b) => parseFloat(a) + parseFloat(b)),
      credit_note: _state.useCredit,
      card_type: 1,
      is_card_payment: 0,
      transaction_type: "sale",
      card_number: selectedCard?.card_number,
      card_key: selectedCard?.card_key,
      expiration_date: selectedCard?.expiration_date,
      name_on_code: selectedCard?.card_name,
      zip_code: selectedCard?.zip_code,
      card_verification_number: selectedCardCvv,
      invite_type: location.state.inviteType == "1" ? 1 : 0,
      team: _state.teams,
    });
    setPayLoading(true);
  };
  useEffect(() => {
    if (props.paynowCheck.success) {
      props.teamRegister({
        event_id: id1,
        organizer: eventDetails.user_id._id,
        teams: _state.teams,
        invite_type: location.state.inviteType == "1" ? 1 : 0,
      });
      setPayLoading(false);
      dispatch({ type: RESET_PAYNOW });
    } else if (props.paynowCheck.error) {
      if (props.paynowCheck.errors?.status == 500) {
        const msg = props.paynowCheck.errors?.data?.message;
        toast.error(msg);
        dispatch({ type: RESET_PAYNOW });
        setPayLoading(false);
      } else {
        dispatch({ type: RESET_PAYNOW });
        setPayLoading(false);
      }
    }
  }, [props.paynowCheck]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="row mx-0">
          <div className="col-12 col-lg-6">
            <div className="content-wrapper">
              <div className="text-left">
                <div className="create-team-head-space">
                  <h5 className="dash-head mr-2">Payment</h5>
                </div>
              </div>
              {teamlist ? (
                <>
                  <div className="team-details mt-4 mt-md-0 justify-content-between">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="teams-tab"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="mt-30">
                          <div className="justify-content-center align-content-center mb-4">
                            <h4 className="h4 text-center fw-m event-name">
                              {location.state?.event}
                            </h4>
                          </div>
                          <div className="row mx-0">
                            <div className="col-12 px-0">
                              {_state.teams.map((e, i) => {
                                const teamName = allTeam.find(
                                  (team) => team._id === e?.team
                                )?.team_name;
                                if (eventDetails?.is_paid_event == "1") {
                                  const amount = regTeamPayAmount.find(
                                    (team) => {
                                      return team.team._id == e.team;
                                    }
                                  )?.amount;
                                  return (
                                    <div
                                      className="row mx-0  border-bottom pb-1"
                                      key={i}
                                    >
                                      <div className="col-8">
                                        <strong>{`${teamName}`}</strong>
                                      </div>
                                      <div className="col-4">
                                        <strong>{`$ ${amount}`}</strong>
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className="row mx-0  border-bottom pb-1"
                                      key={i}
                                    >
                                      <div className="col-8">
                                        <strong>{`${teamName}`}</strong>
                                      </div>
                                      <div className="col-4">
                                        <strong>Free</strong>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                          {eventDetails?.is_paid_event == "1" && (
                            <>
                              <div className="row mx-0 align-items-center border-bottom border-top mt-4 py-2">
                                <div className="col-8">
                                  <input
                                    type="checkbox"
                                    id="credit"
                                    name="credit"
                                    checked={_state.useCredit}
                                    className="mr-2"
                                    onChange={(e) => {
                                      set_State((pre) => ({
                                        ...pre,
                                        useCredit: e.target.checked,
                                      }));
                                    }}
                                  />
                                  <span className="filter-string fw-m fs-14">
                                    <strong>Use Available Credit ?</strong>
                                  </span>
                                </div>
                                <div className="col-4">
                                  <strong>{`$ ${regTeamPayAmount[0]?.getCredit}`}</strong>
                                </div>
                              </div>
                              <div className="mt-4">
                                <div className="text-gray">
                                  Have A Discount Code ?
                                </div>
                                <div className=" d-flex">
                                  <input
                                    type="text"
                                    className={classNames(
                                      "form-control team-name mr-2",
                                      {
                                        requireField: error.discount,
                                      }
                                    )}
                                    placeholder="Enter Discount Code"
                                    name="discount"
                                    value={_state.discount}
                                    onChange={(e) => {
                                      setError((pre) => ({
                                        ...pre,
                                        discount: false,
                                      }));
                                      set_State((pre) => ({
                                        ...pre,
                                        discount: e.target.value,
                                      }));
                                    }}
                                  />

                                  <button
                                    className="mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn"
                                    onClick={handleCheckCode}
                                  >
                                    Apply
                                  </button>
                                </div>
                                {!!error.discount && (
                                  <div className="fs-13 text-red">
                                    {error.discount}
                                  </div>
                                )}
                              </div>
                              <hr className="my-3" />
                              <div className="row mx-0 mb-1">
                                <div className="col-8">
                                  <strong>Total Amount</strong>
                                </div>
                                <div className="col-4">
                                  <strong>{`$ ${amountDetails.totalAmount}`}</strong>
                                </div>
                              </div>
                              <div className="row mx-0 mb-1">
                                <div className="col-8">
                                  <strong>Discount</strong>
                                </div>
                                <div className="col-4">
                                  <strong>{`$ ${amountDetails.discount}`}</strong>
                                </div>
                              </div>
                              <div className="row mx-0 mb-1">
                                <div className="col-8">
                                  <strong>Processing fees (3.90%)</strong>
                                </div>
                                <div className="col-4">
                                  <strong>{`$ ${amountDetails.proceesingFees}`}</strong>
                                </div>
                              </div>
                              {_state.useCredit && (
                                <div className="row mx-0 mb-1">
                                  <div className="col-8">
                                    <strong>Credit</strong>
                                  </div>
                                  <div className="col-4">
                                    <strong>{`$ ${amountDetails.credit}`}</strong>
                                  </div>
                                </div>
                              )}
                              <div className="row mx-0 mb-1">
                                <div className="col-8">
                                  <strong>Amount to Pay</strong>
                                </div>
                                <div className="col-4">
                                  <strong>{`$ ${amountDetails.total}`}</strong>
                                </div>
                              </div>
                              <div
                                style={{
                                  maxHeight: "600px",
                                  overflowY: "auto",
                                }}
                              >
                                {regTeamPayAmount.map((e, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className="text-gray border-bottom pb-3 mt-2"
                                    >
                                      <div>
                                        <i className="mdi mdi-circle-medium"></i>{" "}
                                        <strong>{e?.team.name}</strong>
                                      </div>
                                      <div className="ml-4 mt-1">
                                        <div>Name : {e?.name}</div>
                                        <div>
                                          Type :{" "}
                                          {e?.payment_code?.type == "0"
                                            ? "Event"
                                            : "Custom"}
                                        </div>
                                        <div>Amount : {e?.amount}</div>
                                        <div>
                                          Amount Due :{" "}
                                          {`${
                                            e?.payment_code
                                              ?.payment_terms_type == "0"
                                              ? "$"
                                              : ""
                                          } ${e?.payment_code?.payment_terms} ${
                                            e?.payment_code
                                              ?.payment_terms_type == "1"
                                              ? "%"
                                              : ""
                                          }`}
                                        </div>
                                        <div>
                                          Service Fee : {e?.pass_on_key}
                                        </div>
                                        <div>
                                          Amount to pay : {e?.dueAmount}
                                        </div>
                                        <div>
                                          Payment Due Days :{" "}
                                          {e?.payment_code?.payment_due}
                                        </div>
                                        <div>
                                          Reminder :{" "}
                                          {(e?.payment_code?.reminder).join(
                                            ", "
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                          <table className="table event-table team-table mb-2">
                            <tbody>
                              <tr className="team-row">
                                <td>
                                  <div className="d-flex align-items-center">
                                    <input
                                      type="checkbox"
                                      id="Liability"
                                      name="Liability"
                                      value={liability}
                                      checked={liability}
                                      className="mr-2"
                                      onClick={(e) => {
                                        history.push(
                                          `/liability-waiver-terms/${id1}`,
                                          {
                                            ...location.state,
                                            from: location.pathname,
                                            _state: _state,
                                            amountDetails: amountDetails,
                                            selectedCard: selectedCard,
                                          }
                                        );
                                        setLiability(e.target.checked);
                                      }}
                                      disabled={liability}
                                    />
                                    <span className="filter-string fw-m">
                                      <strong>Accept Liability waiver</strong>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="d-flex justify-content-center align-items-center align-items-md-center justify-content-md-between">
                            <button
                              className="mr-1 btn btn-info btn-lg font-weight-medium auth-form-btn"
                              onClick={payNow}
                            >
                              {eventDetails?.is_paid_event == "1"
                                ? "Pay Now"
                                : "Register"}
                            </button>
                            {eventDetails?.is_paid_event == "1" && (
                              <button
                                className="mr-1 btn border px-0 w-50"
                                onClick={() => {
                                  setIsOpen(true);
                                }}
                              >
                                <div className="d-flex align-items-center justify-content-center">
                                  <i className="mdi mdi-menu-down"></i>
                                  {!selectedCard ? (
                                    <strong>Select Card</strong>
                                  ) : (
                                    <div>
                                      <div className="">
                                        <strong>
                                          {selectedCard?.card_name}
                                        </strong>
                                      </div>
                                      <div className="fs-13">
                                        <strong>
                                          {selectedCard?.card_number} | Secured
                                        </strong>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={isOpen}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <div className="p-3" style={{ background: "#ffffff" }}>
          <div
            style={{
              maxHeight: "450px",
              overflowY: "auto",
            }}
          >
            {cardList.map((e, i) => {
              return (
                <div key={i} className="border p-1 my-1">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <div
                        className="mr-2"
                        style={{
                          width: "35px",
                        }}
                      >
                        <img
                          src={`images/PaymentCards/${
                            e.card_type == "MASTERCARD"
                              ? "Mastercardnew.png"
                              : e.card_type.trim() == "VISA"
                              ? "visacard.png"
                              : "visacard.png"
                          }`}
                          className="w-100"
                        />
                      </div>
                      <div>
                        <div className="fs-13">
                          <strong>{e.card_name}</strong>
                        </div>
                        <div className="fs-13">
                          <strong>{e.card_number}</strong>
                        </div>
                      </div>
                    </div>
                    <div>
                      <input
                        className="mr-3"
                        type="checkbox"
                        name="checkbox"
                        onChange={(v) => setSelectedCard({ ...e })}
                        checked={!!selectedCard && selectedCard?._id == e._id}
                        value={e._id}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              className="text-center border p-1 mt-2 pointer"
              onClick={() => {
                history.push("/payment/payment-method/add-payment-method");
              }}
            >
              <strong>
                <i className="mdi mdi-plus"></i> Add new card
              </strong>
            </div>
          </div>
          <Modal.Footer className="mt-2 justify-content-center border-top-0">
            <button
              onClick={() => {
                setIsOpen(false);
                setSelectedCard(null);
              }}
              className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 rounded"
            >
              cancel
            </button>
            {!!selectedCard && (
              <button
                className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 rounded"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Confirm
              </button>
            )}
          </Modal.Footer>
        </div>
      </Modal>
      <Modal
        show={isOpenCvv}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <div className="p-3" style={{ background: "#ffffff" }}>
          <div
            style={{
              maxHeight: "450px",
              overflowY: "auto",
            }}
          >
            <Modal.Header className="font-bold">Payment Details</Modal.Header>
            <Modal.Body>
              <div className="fs-15 text-gray">
                Enter the 3 digit CVV for your credit card ending with{" "}
                {selectedCard?.card_number}
              </div>
              <div className="mt-4">
                <OtpInput
                  value={selectedCardCvv}
                  onChange={(value) => {
                    setSelectedCardCvv(value);
                  }}
                  numInputs={3}
                  isInputNum={true}
                  inputStyle={"form-control w-100 mx-2 border-2  rounded-lg"}
                />
              </div>
            </Modal.Body>
          </div>
          <Modal.Footer className="mt-2 justify-content-center border-top-0">
            <button
              onClick={() => {
                setIsOpenCvv(false);
                setSelectedCardCvv("");
              }}
              className="text-info border-2 border-blue-400 fs-15 w-[45%] py-2 px-4 px-md-5 rounded"
            >
              Cancel
            </button>
            <button
              onClick={
                payLoading
                  ? () => {}
                  : () => {
                      if (selectedCardCvv.length == 3) {
                        paymentWithSelectedCard();
                      } else {
                        toast.error("Enter 3 digit CVV code");
                      }
                    }
              }
              className="text-white bg-info border-0 fs-15 w-[45%] py-2 px-4 px-md-5 rounded"
            >
              Pay
            </button>
          </Modal.Footer>
        </div>
      </Modal>
      <Modal
        show={isOpenCardDetails}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <div className="p-3" style={{ background: "#ffffff" }}>
          <div
            style={{
              maxHeight: "450px",
              overflowY: "auto",
            }}
          >
            <Modal.Header className="font-bold">Payment Details</Modal.Header>
            <Modal.Body>
              <div className="row mx-0">
                <div className="col-12">
                  <label className="font-bold fs-14">Card Number*</label>
                  <InputMask
                    mask="9999 9999 9999 9999"
                    className={classNames("form-control team-name", {
                      requireField: newCardDetailsError.cardNumber,
                    })}
                    value={newCardDetails.cardNumber}
                    onChange={handleNewCard}
                    maskChar={""}
                    placeholder="XXXX XXXX XXXX XXXX"
                    name="cardNumber"
                  />
                </div>
              </div>
              <div className="row mx-0 mt-3 ">
                <div className="col-6">
                  <label className="font-bold fs-14">Valid Through*</label>

                  <InputMask
                    mask="99/99"
                    className={classNames("form-control team-name", {
                      requireField: newCardDetailsError.expiry,
                    })}
                    value={newCardDetails.expiry}
                    onChange={handleNewCard}
                    maskChar={""}
                    placeholder="MM/YY"
                    name="expiry"
                  />
                </div>
                <div className="col-6 ">
                  <label className="font-bold fs-14">CVV*</label>
                  <InputMask
                    mask="999"
                    className={classNames("form-control team-name", {
                      requireField: newCardDetailsError.cvv,
                    })}
                    value={newCardDetails.cvv}
                    onChange={handleNewCard}
                    maskChar={""}
                    placeholder="123"
                    name="cvv"
                  />
                </div>
              </div>
              <div className="row mx-0 mt-3">
                <div className="col-12">
                  <label className="font-bold fs-14">Name On Card*</label>
                  <input
                    type="text"
                    name="name"
                    value={newCardDetails.name}
                    className={classNames("form-control team-name", {
                      requireField: newCardDetailsError.name,
                    })}
                    onChange={handleNewCard}
                    placeholder="Enter Name"
                  />
                </div>
              </div>
              <div className="row mx-0 mt-3">
                <div className="col-12">
                  <label className="font-bold fs-14">Zip Code*</label>
                  <InputMask
                    mask="99999"
                    className={classNames("form-control team-name", {
                      requireField: newCardDetailsError.zipCode,
                    })}
                    value={newCardDetails.zipCode}
                    onChange={handleNewCard}
                    maskChar={""}
                    placeholder="Zipcode"
                    name="zipCode"
                  />
                </div>
              </div>
            </Modal.Body>
          </div>
          <Modal.Footer className="mt-2 justify-content-center border-top-0">
            <button
              onClick={() => {
                setIsOpenCardDetails(false);
                setNewCardDetails(initialNewDetails);
                setNewCardDetailsError(initialNewDetailsError);
              }}
              className="text-info border-2 border-blue-400 fs-15 w-[45%] py-2 px-4 px-md-5 rounded"
            >
              Cancel
            </button>
            <button
              onClick={payLoading ? () => {} : handlePayWithNewCard}
              className="text-white bg-info border-0 fs-15 w-[45%] py-2 px-4 px-md-5 rounded"
            >
              Pay
            </button>
          </Modal.Footer>
        </div>
      </Modal>

      <Toaster />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  paynowCheck: state.paynowReducer,
  accept: state.acceptInvitationReducer,
  discountReducer: state.applydiscountcodeReducer,
  PaymentMethod: state.paymentMethodReducer,
  getDivision: state.geteventDivisionReducer,
  getAllTeam: state.getClubAllTeamReducer,
  teamRegisterReducer: state.registerTeamReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getClubTeamHandler: (user) => dispatch(getClubTeam(user)),
  // getEventTeamHandler: (user) => dispatch(getOrgEventTeam(user)),
  resetregisteredSelectedTeamReducer: () =>
    dispatch({ type: RESET_REGISTER_SELECTED_TEAM }),
  paynowHandler: (user) => dispatch(payNow(user)),
  getPaymentMethod: (user) => dispatch(getPaymentMethod()),
  // changestatusHandler: (user) => dispatch(Club_changeStatus(user)),
  getEventDivisionHandler: (user) => dispatch(geteventDivision(user)),
  // resetAcceptInvitationReducer: (uset) =>
  //   dispatch({ type: RESET_ACCEPT_INVITATION }),
  // sendPaymentData: (user) => dispatch(sendPaymentInfo(user)),
  // teamRegister: (user) => dispatch(teamRegister(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventRegisterPayNow);
