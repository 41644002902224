import { CREATE_EVENT_ERROR, CREATE_EVENT_SUCCESS, CREATE_EVENT_LOADING, RESET_CREATE_EVENT } from "../constants"

let initialState = {
    error: false,
    success: false,
    user: {},
    loading: false,
    event_id: {},
    event_name: ""


}

export default function createEventReducer(state = initialState, action) {

    switch (action.type) {
        case CREATE_EVENT_LOADING:
            // alert("reducer Loading")
            return { ...state, loading: true, error: false, success: false };

        case CREATE_EVENT_SUCCESS:
            // alert("reducer success")
            return { ...state, success: true, user: action.user, event_id: action.event_id, event_name: action.event_name ,message:action.msg};

        case CREATE_EVENT_ERROR:
            // alert("reducer Error")
            return { ...state, error: true,success:false,loading:false, errors: action.error };
      
        case RESET_CREATE_EVENT:
            return {...state,error:false, loading:false , success:false}

        default:
            return state;
    }

}