import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import { withRouter, Redirect, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { connect, useSelector } from "react-redux";
import {
  editPool,
  eventdivisionpoolAction,
  getAllDivision,
} from "../../../service/action/userAction";
import { RESET_EDIT_POOL } from "../../../service/constants";

const EditPool = (props) => {
  let history = useHistory();
  const { id, id1, id2 } = useParams();
  const [poolData, setpoolData] = useState();
  const [currentpool, setcurrentpool] = useState();
  const [poolname, setpoolname] = useState("");

  const allDivision = useSelector(
    (state) => state.getAllDivisionReducer.user.data
  );
  useEffect(() => {
    if (id) {
      props.getAllDivisionHandler({ id });
    }
  }, []);

  useEffect(() => {
    props.eventdivisionpoolActionhandler({ id });
  }, []);

  useEffect(() => {
    if (props.eventdivisionpollReducer?.success) {
      const data = props.eventdivisionpollReducer?.user;
      setpoolData(data);
    }
  }, [props.eventdivisionpollReducer]);

  useEffect(() => {
    if (!!poolData) {
      const data = poolData?.find((e) => e?._id === id1);
      const poolname = data?.pool?.find((e) => e?._id === id2)?.pool_name;
      setpoolname(poolname);
    }
  }, [poolData]);

  const [errors, setErrors] = useState({
    pool_name: false,
  });

  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    setpoolname(value);
    switch (name) {
      case "pool_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            pool_name: "Division name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            pool_name: false,
          }));
        }

        break;

      default:
    }
  };

  const notify = (msg) => toast(msg);

  const submitRequest = (e) => {
    e.preventDefault();
    // alert("click")

    if (poolname == "") {
      setErrors((prevState) => ({
        ...prevState,
        division_name: "Division name can not be empty",
      }));
    }

    if (poolname !== "") {
      props.editPoolhandler({
        id: id2,
        division_id: id1,
        pool_name: poolname,
      });
      if (props.submit?.success) {
        return <Redirect to={`/event-details/${id}`} />;
      }
      // resetData().then(clearState);
    }
  };

  useEffect(() => {
    if (props.submit?.success) {
      // notify("Pool Updated")

      history.push(`/event-division-details/${id}/${id1}`);
      props.resetEditPoolReducer();
    } else if (props.submit?.error) {
      notify("Pool Update Error");
    }
  }, [props.submit]);
  // if(props.submit?.success){
  //         notify("Season submitted successfully.")
  //         props.resetCreateSeasonReducer()
  //         return <Redirect to="/season" />;
  //     }

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Edit Pool</h5>
          </div>

          <form>
            <div className="row mx-0">
              <div className="col-md-4 col-xl-4">
                <div className="mt-24">
                  {/* <div className="text-left">
                                        <label className="text-gray fs-13">Division Name <span className="text-red fw-m">*</span></label>
                                        {
                                            allDivision?<>
                                            
                                            <select className={classNames("form-control team-name payterm", { "error": errors.division_name })} id="division_name" name="division_name" value={poolname} onChange={eventHandler}>
                                                        <option value="#" >Division *</option>
                                                        {allDivision.map((v, i) => {
                                                        return (
                                                            <option value={v._id}>{v.division_name}</option>
                                                        )
                                                    })}
                                                    </select>
    
                                            {errors.division_name &&
                                                <span className='text-red small d-block'>* {errors.division_name}</span>}
                                            </>:<><h4>Loading...</h4></>
                                        }

                                    </div> */}
                  <div className="text-left">
                    <label className="text-gray fs-13">
                      Pool Name <span className="text-red fw-m">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control team-name"
                      id="pool_name"
                      name="pool_name"
                      value={poolname}
                      placeholder="Pool name"
                      required
                      onChange={eventHandler}
                    />

                    {errors.pool_name && (
                      <span className="text-red small d-block">
                        * {errors.pool_name}
                      </span>
                    )}
                  </div>

                  <div className="mt-3 text-left">
                    <a
                      className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                      onClick={submitRequest}
                    >
                      Submit
                    </a>
                  </div>

                  <Toaster />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

// export default withRouter(Org_CreateDivision)

const mapStateToProp = (state) => ({
  submit: state.editPoolReducer,
  eventdivisionpollReducer: state.eventdivisionpollReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // editDivisionhandler: user => dispatch(editDivision(user)),
  resetEditPoolReducer: () => dispatch({ type: RESET_EDIT_POOL }),
  getAllDivisionHandler: (user) => dispatch(getAllDivision(user)),
  editPoolhandler: (user) => dispatch(editPool(user)),
  eventdivisionpoolActionhandler: (user) =>
    dispatch(eventdivisionpoolAction(user)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(EditPool));
