import { MOVE_TO_FREE_TEAM_PLAYER_ERROR, MOVE_TO_FREE_TEAM_PLAYER_SUCCESS, RESET_MOVE_TO_FREE_TEAM_PLAYER } from "../constants";

  let initialState = {
    loading: false,
    user: [],
    error: false,
    errors: [],
    success: false,
    payload: {},
  };
  
  export default function moveToFreeTeamReducer(state = initialState, action) {
    switch (action.type) {

      case MOVE_TO_FREE_TEAM_PLAYER_SUCCESS:
        return {
          ...state,
          error: false,
          loading: false,
          success: true,
          message: action.msg,
        };
  
      case MOVE_TO_FREE_TEAM_PLAYER_ERROR:
        return {
          ...state,
          error: true,
          loading: false,
          success: false,
          errors: action.errors,
          msg: action.errors,
        };
  
      case RESET_MOVE_TO_FREE_TEAM_PLAYER:
        return {
          ...state,
          error: false,
          loading: false,
          success: false,
          errors: [],
          user: [{}],
        };
      default:
        return state;
    }
  }
  