import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "@dmuy/timepicker/dist/mdtimepicker.css";
import classNames from "classnames";
import { useSelector, connect } from "react-redux";
import { useParams } from "react-router";
import {
  getAllEvent,
  getAllSeason,
  getAllDivision,
  getEventStaff,
  deleteEventStaff,
  getEventSchedule,
  deleteSchedule,
  getFreeTeam,
  getEventTeam,
  deletePaymentCode,
  deleteEventTeam,
  deletePool,
  deleteDivision,
} from "../../../service/action/userAction";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import {
  RESET_CREATE_DIVISION,
  RESET_CREATE_POOL,
  RESET_DELETE_EVENT,
  RESET_DELETE_EVENTTEAM,
  RESET_DELETE_POOL,
  RESET_DELETE_SCHEDULE,
  RESET_EDIT_POOL,
  RESET_EDIT_DIVISION,
  RESET_DELETE_DIVISION,
  RESET_CREATE_TEAM,
  RESET_FREETEAM,
} from "../../../service/constants";
import {
  getEventDetailsForRegistration,
  getOrgEventTeam,
} from "../../../service/action/Club/clubAction";

const RegisterEventPage = (props) => {
  const notify = (msg) => toast(msg);
  const [state, setState] = useState({
    startDate: "",
    EndDate: "",
    event_name: "",
    facility_name: "",
    website: "",
    city: "",
    state: "",
    address: "",
    maxTeam: "",
    availability: "",
    option: "",
    from_time: "",
    to_time: "",
    event_type: "",
  });

  const [errors, setErrors] = useState({
    startDate: false,
    EndDate: false,
    event_name: false,
    facility_name: false,
    website: false,
    city: false,
    state: false,
    address: false,
    maxTeam: false,
    availability: false,
    option: false,
    from_time: false,
    to_time: false,
  });

  const { id, id1 } = useParams();

  useEffect(() => {
    props.getEventDetailsForTeamRegistrationHandler({ id: id });
  }, []);

  // const getdata = props.getEventHandler;      //for get all event

  // useEffect(() => {
  //     getdata()

  // }, [])

  const eventDetails = useSelector(
    (state) => state.getEventDetailsForRegistrationReducer.data.data
  );

  useEffect(() => {
    if (eventDetails) {
    }
  }, []);

  // const allEvent = useSelector(state => state.getAllEventReducer.user)
  const allDivision = useSelector(
    (state) => state.getAllDivisionReducer.user.data
  );
  const getEventStaff = useSelector(
    (state) => state.getEventStaffReducer.user.data
  );

  const deleteEventMsg = useSelector(
    (state) => state.deleteEventReducer.message
  ); //Remove staff
  const deleteScheduleMsg = useSelector(
    (state) => state.deleteScheduleReducer.message
  ); //Remove staff
  const deleteTeamMsg = useSelector(
    (state) => state.deleteEventTeamReducer.message
  );
  const getEventSchedule = useSelector(
    (state) => state.getEventScheduleReducer.user.data
  );
  const getEventTeam = useSelector(
    (state) => state.getEventTeamReducer.user.data
  );
  const getFreeTeam = useSelector(
    (state) => state.getFreeTeamReducer.user.data
  );

  const createDivisionMsg = useSelector(
    (state) => state.createDivisionReducer.message
  );
  const createPoolMsg = useSelector((state) => state.createPoolReducer.message);
  const deletePoolMsg = useSelector((state) => state.deletePoolReducer.message);
  const editPoolMsg = useSelector((state) => state.editPoolReducer.message);
  const editDivisionMsg = useSelector(
    (state) => state.editDivisionReducer.message
  );
  const deleteDivisionMsg = useSelector(
    (state) => state.deleteDivisionReducer.message
  );
  const ScheduleMsg = useSelector((state) => state.createTeamReducer.message);
  const updateFreeTeamMsg = useSelector(
    (state) => state.updateFreeTeamReducer.message
  );

  // const eventDetails = allEvent.find((e) => e._id == id) //find spacific event for Edit

  useEffect(() => {
    if (props.submit?.success) {
      notify(deleteEventMsg.message);
      props.resetdeletePaymentCodeReducer();
      props.getEventStaffHandler({ id: id });

      // history.push(`/event-details/${id}`);
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.submit1?.success) {
      notify(deleteScheduleMsg.message);
      props.resetdeleteScheduleReducer();
      props.getEventScheduleHandler({ id: id });
    }
  }, [props.submit1]);

  useEffect(() => {
    if (props.createDivision?.success) {
      notify(createDivisionMsg.message);
      props.resetcreatedivisionReducer();
    }
  }, [props.createDivision]);

  useEffect(() => {
    if (props.createPool?.success) {
      notify(createPoolMsg.message);
      props.resetCreatePoolReducer();
      props.getAllDivisionHandler({ id: id });
    }
  }, [props.createPool]);

  //Delete Poll Successfull Msg
  useEffect(() => {
    if (props.deletePoolmessage?.success) {
      notify(deletePoolMsg.message);
      props.resetDeletePoolReducer();
      props.getEventHandler();
    }
  }, [props.deletePoolmessage]);

  //Add Schedule Successfull Msg
  useEffect(() => {
    if (props.schedulemessage?.success) {
      notify(ScheduleMsg.message);
      props.resetscheduleReducer();
      props.getEventHandler();
    }
  }, [props.schedulemessage]);

  //Create Team Successfull Msg
  useEffect(() => {
    if (props.createTeamMsg?.success) {
      notify(ScheduleMsg.message);
      props.resetscheduleReducer();
      props.getEventHandler();
    }
  }, [props.createTeamMsg]);

  //edit Poll Successfull Msg
  useEffect(() => {
    if (props.editPoolmessage?.success) {
      notify(editPoolMsg.message);
      props.reseteditPoolReducer();
      props.getEventHandler();
    }
  }, [props.editPoolmessage]);

  //edit Division Successfull Msg
  useEffect(() => {
    if (props.editDivisionmessage?.success) {
      notify(editDivisionMsg.message);
      props.reseteditDivisionReducer();
      props.getEventHandler();
    }
  }, [props.editDivisionmessage]);

  //delete Division Successfull Msg
  useEffect(() => {
    if (props.deleteDivisionmessage?.success) {
      notify(deleteDivisionMsg.message);
      props.resetdeleteDivisionReducer();
      props.getEventHandler();
    }
  }, [props.deleteDivisionmessage]);

  //delete schedule Successfull Msg
  useEffect(() => {
    if (props.deleteDivisionmessage?.success) {
      notify(deleteDivisionMsg.message);
      props.resetdeleteDivisionReducer();
      props.getEventHandler();
    }
  }, [props.deleteDivisionmessage]);

  //Update free Team  Successfull Msg
  useEffect(() => {
    if (props.updateFreeTeam?.success) {
      notify(updateFreeTeamMsg.message);
      props.resetFreetaemReducer();
      props.getEventHandler();
    }
  }, [props.updateFreeTeam]);

  useEffect(() => {
    if (props.Team?.success) {
      notify(deleteTeamMsg.message);
      props.resetdeleteEventTeamReducer();
      props.getEventTeamHandler({ id: id });
    }
  }, [props.Team]);

  const dateCheck = () => {
    if (eventDetails) {
      if (new Date(eventDetails.event_to_date) > new Date()) {
        return "open";
      } else {
        return "close";
      }
    }
  };

  const getToken = localStorage.getItem("token");

  useEffect(() => {
    if (eventDetails) {
      eventDetails.event_facility.map((x, i) => {
        setState((prevState) => ({
          ...prevState,
          // from_time: eventDetails.event_from_time,
          // to_time: eventDetails.event_to_time,
          event_name: eventDetails.event_name,
          facility_name: x.facility_name,
          address: x.facility_address,
          maxTeam: eventDetails.event_max_team,
          city: x.facility_city.name,
          state: x.facility_state.name,
          option: eventDetails.event_type,
          event_access: eventDetails.event_access,
          website: x.facility_website,
          event_Type: eventDetails.event_type,
          payment: "",
          person: "",
          season: eventDetails.event_season,
          numPlayers: eventDetails.show_number_of_player,
          eventFull: eventDetails.show_event_is_full,
          remainingSpots: eventDetails.show_remaining_spots,
          schedual_tab: eventDetails.show_schedule,
        }));
        // setStartDate(new Date(eventDetails.event_from_date))
        // setEndDate(new Date(eventDetails.event_to_date))
      });
    }
  }, [eventDetails]);

  const staff = [
    {
      name: "name",
      email: "xyz@gmail.com",
      mobile: "xxx-xxx-xxxx",
    },
    {
      name: "name",
      email: "xyz@gmail.com",
      mobile: "xxx-xxx-xxxx",
    },
  ];

  const [startDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");

  // const eventHandler = (e) => {
  //     const { id, value, name } = e.target
  //     setState(prevState => ({
  //         ...prevState,
  //         [id]: value
  //     }))

  //     switch (name) {

  //         case "startDate":
  //             setStartDate(value)

  //             if (value.length == 0) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     startDate: "Start date can not be empty"
  //                 }))

  //             }
  //             else {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     startDate: false
  //                 }))
  //             }
  //             break;

  //         case "EndDate":
  //             setEndDate(value)

  //             if (value.length == 0) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     EndDate: "End date can not be empty"
  //                 }))

  //             }
  //             else {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     EndDate: false
  //                 }))
  //             }
  //             break;

  //         case "event_name":
  //             if (value.length == 0) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     event_name: "Event name can not be empty."
  //                 }))
  //             }
  //             else {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     event_name: false
  //                 }))
  //             }
  //             break;

  //         case "facility_name":
  //             if (value.length == 0) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     facility_name: "Facility name can not be empty."
  //                 }))
  //             }
  //             else {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     facility_name: false
  //                 }))
  //             }
  //             break;

  //         case "website":
  //             if (value.length == 0) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     website: "Website can not be empty."
  //                 }))
  //             }
  //             else {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     website: false
  //                 }))
  //             }
  //             break;

  //         case "city":
  //             if (value.length == 0) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     city: "City can not be empty."
  //                 }))
  //             }
  //             else {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     city: false
  //                 }))
  //             }
  //             break;

  //         case "state":
  //             if (value.length == 0) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     state: "State can not be empty."
  //                 }))
  //             }
  //             else {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     State: false
  //                 }))
  //             }
  //             break;

  //         case "address":
  //             if (value.length == 0) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     address: "Address can not be empty."
  //                 }))
  //             }
  //             else {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     address: false
  //                 }))
  //             }
  //             break;

  //         case "max-team":
  //             if (value.length == 0) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     maxTeam: "Max team can not be empty."
  //                 }))
  //             }
  //             else {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     maxTeam: false
  //                 }))
  //             }
  //             break;

  //         case "availability":
  //             if (value.length == 0) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     availability: "Availability can not be empty."
  //                 }))
  //             }
  //             else {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     availability: false
  //                 }))
  //             }
  //             break;

  //         case "event_status":
  //             if (value.length == 0) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     option: "Value can not be empty"
  //                 }))

  //                 setState(prevState => ({
  //                     ...prevState,
  //                     option: value
  //                 }))
  //             }

  //             else {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     option: false
  //                 }))

  //                 setState(prevState => ({
  //                     ...prevState,
  //                     option: value
  //                 }))
  //             }
  //             break;

  //         case "from_time":
  //             if (value.length == 0) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     from_time: "Value can not be empty"
  //                 }))
  //             }
  //             else {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     from_time: false
  //                 }))
  //             }
  //             break;
  //         case "to_time":
  //             if (value.length == 0) {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     to_time: "Value can not be empty"
  //                 }))
  //             }
  //             else {
  //                 setErrors(prevState => ({
  //                     ...prevState,
  //                     to_time: false
  //                 }))
  //             }
  //             break;
  //         default:
  //     }
  // }

  // const saveData = (e) => {
  //     e.preventDefault();
  //     if (startDate == "") {
  //         setErrors(prevState => ({
  //             ...prevState,
  //             startDate: "Start date can not be empty"
  //         }))
  //     }

  //     if (EndDate == "") {
  //         setErrors(prevState => ({
  //             ...prevState,
  //             EndDate: "End date can not be empty"
  //         }))
  //     }

  //     if (state.event_name == "") {
  //         setErrors(prevState => ({
  //             ...prevState,
  //             event_name: "Event name can not be empty."
  //         }))
  //     }

  //     if (state.facility_name == "") {
  //         setErrors(prevState => ({
  //             ...prevState,
  //             facility_name: "Facility name can not be empty."
  //         }))
  //     }

  //     if (state.website == "") {
  //         setErrors(prevState => ({
  //             ...prevState,
  //             website: "Website can not be empty."
  //         }))
  //     }
  //     if (state.city == "") {
  //         setErrors(prevState => ({
  //             ...prevState,
  //             city: "City can not be empty."
  //         }))
  //     }
  //     if (state.state == "") {
  //         setErrors(prevState => ({
  //             ...prevState,
  //             state: "State can not be empty."
  //         }))
  //     }
  //     if (state.address == "") {
  //         setErrors(prevState => ({
  //             ...prevState,
  //             address: "Website can not be empty."
  //         }))
  //     }

  //     if (state.maxTeam == "") {
  //         setErrors(prevState => ({
  //             ...prevState,
  //             maxTeam: "Max team can not be empty."
  //         }))
  //     }
  //     if (state.availability == "") {
  //         setErrors(prevState => ({
  //             ...prevState,
  //             availability: "Availability can not be empty."
  //         }))
  //     }
  //     if (state.option == "") {
  //         setErrors(prevState => ({
  //             ...prevState,
  //             option: "Value can not be empty"
  //         }))
  //     }

  //     if (state.from_time == "") {
  //         setErrors(prevState => ({
  //             ...prevState,
  //             from_time: "Please set a time."
  //         }))
  //     }

  //     if (state.to_time == "") {
  //         setErrors(prevState => ({
  //             ...prevState,
  //             to_time: "Please set a time."
  //         }))
  //     }

  // }

  // useEffect(() => {
  //     mdtimepicker('#from_time');
  //     mdtimepicker('#to_time');
  // });

  // var el = document.getElementById('from_time');
  // if (el) {
  //     el.addEventListener('timechanged', function (e) {
  //         setState(prevState => ({
  //             ...prevState,
  //             from_time: e.target.value
  //         }))

  //         eventHandler(e);
  //     });
  // }

  // var toTimeEl = document.getElementById("to_time");
  // if (toTimeEl) {
  //     {
  //         toTimeEl.addEventListener("timechanged", function (e) {
  //             setState(prevState => ({
  //                 ...prevState,
  //                 to_time: e.target.value
  //             }))
  //             eventHandler(e);
  //         })
  //     }
  // }

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          {eventDetails ? (
            <>
              <div className="text-left">
                <div className="create-team-head-event">
                  <h5 className="dash-head">{state.event_name}</h5>
                  {/* <div className="mt-3 mt-md-0 text-right">
                                            <Link to={`/editEvent/${id}`} >
                                                <button className="btn-save" >Register Now</button>
                                            </Link>
                                        </div> */}
                  <div className="mt-3 text-left align-content-center">
                    <Link to={`/events/${id}/registernow/${id1}`}>
                      <label className="btn btn-primary btn-lg font-weight-medium auth-form-btn">
                        Register Now
                      </label>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="team-details mt-4 mt-md-0">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="details"
                      data-toggle="pill"
                      href="#detail-tab"
                      role="tab"
                      aria-controls="pills-details"
                      aria-selected="true"
                    >
                      Details
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="teams"
                      data-toggle="pill"
                      href="#team-tab"
                      role="tab"
                      aria-controls="pills-teams"
                      aria-selected="false"
                      onClick={() => props.getEventTeamHandler({ id: id })}
                    >
                      Teams
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="division"
                      data-toggle="pill"
                      href="#division-tab"
                      role="tab"
                      aria-controls="pills-division"
                      aria-selected="false"
                    >
                      Division
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="staff"
                      data-toggle="pill"
                      href="#staff-tab"
                      role="tab"
                      aria-controls="pills-staff"
                      aria-selected="false"
                      onClick={() => props.getEventStaffHandler({ id: id })}
                    >
                      Staff
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="schedule"
                      data-toggle="pill"
                      href="#schedule-tab"
                      role="tab"
                      aria-controls="pills-schedule"
                      aria-selected="false"
                    >
                      Schedule
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="accounting"
                      data-toggle="pill"
                      href="#accounting-tab"
                      role="tab"
                      aria-controls="pills-accounting"
                      aria-selected="false"
                    >
                      Accounting
                    </a>
                  </li>
                </ul>

                {/* <<----------------------------   Details  Start  --------------------------->> */}

                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="detail-tab"
                    role="tabpanel"
                    aria-labelledby="pills-details"
                  >
                    <div className="mt-30">
                      <div className="d-flex justify-content-between">
                        <h6 className="text-left payment mt-30">
                          Event Status
                        </h6>
                        <div className="dropdown details-drp">
                          <i
                            className="mdi mdi-dots-vertical"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></i>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <a className="dropdown-item" href="#">
                              Invite Team Action
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          {/* <div className="dropdown event-drp mt-18 text-left">
                                                <label className="text-gray fs-13">Event Status</label>

                                                <a className="form-control dropdown-toggle gender-drp text-decoration-none" href="#" role="button" id="sports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Open
                                                </a>

                                                <div className="dropdown-menu" aria-labelledby="Select Sports">
                                                    <a className="dropdown-item" href="#">Hocky</a>
                                                    <a className="dropdown-item" href="#">Cricket</a>
                                                    <a className="dropdown-item" href="#">Tennis</a>
                                                </div>
                                            </div> */}

                          <div className="form-group event-drp">
                            {/* <select className={classNames("form-control team-name  payterm"
                                                                // , { "error": errors.option }
                                                            )} id="event_status" disabled name="event_status" value={eventDetails.event_from_date > new Date()?<>
                                                            <option value="0" style={{ color: "#000" }} >open</option>
                                                            
                                                            </>:<><option value="0" style={{ color: "#000" }} >close</option></>} */}

                            {/* // onChange={eventHandler} */}
                            {/* > */}
                            {/* <option value="1" style={{ color: "#000" }}>Open</option>
                                                                <option value="2" style={{ color: "#000" }}>Close</option> */}
                            {/* <option value="2" style={{ color: "#000" }}>Account 2</option> */}
                            {/* </select> */}
                            <input
                              type="text"
                              className={classNames(
                                "form-control team-name"
                                // , { "error": errors.event_name }
                              )}
                              placeholder="Event Status"
                              id="event_status"
                              name="event_status"
                              value={dateCheck()}
                              disabled
                            />

                            {/* {errors.option &&
                                                    <span className='text-red small d-block'>* {errors.option}</span>} */}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-between">
                            <div className="text-left mt-18">
                              <label className="text-gray fs-13">
                                Event Type
                              </label>
                              <div>
                                {/* <i className="mdi mdi-lock-outline website-ico"></i> */}
                                <span className="text-blue fs-13 ml-12">
                                  {state.event_type == 0
                                    ? "Club only"
                                    : state.event_type == 1
                                    ? "parent/player"
                                    : state.event_type == 2
                                    ? "Club & Parent"
                                    : ""}{" "}
                                </span>
                                {/* <span className="text-blue fs-13 ml-12">{state.event_type = 0 ? "Club only" : "parent/player"} </span> */}
                              </div>
                              {/* <div className="form-group event-drp">
                                                                    <select className={classNames("form-control team-name payterm")} id="event_type" name="event_type" value={state.paymentcode_type}  disabled>
                                                                        <option value="#" >Event Type *</option>
                                                                        <option value="0">Club only</option>
                                                                        <option value="1">Parent/player only</option>
                                                                        <option value="2">Club & Parent</option>
                                                                    </select>

                                                                </div> */}
                            </div>

                            <div className="text-left mt-18">
                              <label className="text-gray fs-13">
                                Registratin Link
                              </label>
                              <div>
                                <i className="mdi mdi-open-in-new website-ico"></i>
                                <span className="text-blue fs-13 ml-12">
                                  Copy Link
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 col-6">
                          <div className="form-row">
                            <div className="col-md-6">
                              <div className="text-left mt-18">
                                <label className="text-gray fs-13">
                                  From Date
                                </label>

                                <DatePicker
                                  selected={startDate}
                                  placeholderText="Choose start Date"
                                  // value={state.from_date}
                                  // onChange={date => eventHandler({ target: { value: date, name: 'startDate', id: "start-date" } })}
                                  className={classNames(
                                    "form-control team-name"
                                    // , { "error": errors.startDate }
                                  )}
                                  disabled
                                />

                                {/* {errors.startDate &&
                                                            <span className='text-red small d-block'>* {errors.startDate}</span>} */}
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                                                                <div className="text-left mt-18">
                                                                    <label className="text-gray fs-13">From Time</label>
                                                                    <input type="text" data-mintime="now" className={classNames("form-control timepicker team-name"
                                                                        // , { "error": errors.from_time }
                                                                    )} placeholder="Choose start time" id="from_time" value={state.from_time} name="from_time"
                                                                        //  onChange={eventHandler} 
                                                                        disabled />

                                                                    

                                                                </div>
                                                            </div> */}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="text-left mt-18">
                            <label className="text-gray fs-13">
                              Event Name
                            </label>
                            <input
                              type="text"
                              className={classNames(
                                "form-control team-name"
                                // , { "error": errors.event_name }
                              )}
                              placeholder="Event name"
                              id="event_name"
                              name="event_name"
                              value={state.event_name}
                              disabled
                              // onChange={eventHandler}
                            />

                            {/* {errors.event_name &&
                                                    <span className='text-red small d-block'>* {errors.event_name}</span>} */}
                          </div>
                        </div>

                        <div className="col-md-4 col-6">
                          <div className="form-row">
                            <div className="col-md-6">
                              <div className="text-left mt-18">
                                <label className="text-gray fs-13">
                                  To Date
                                </label>
                                <DatePicker
                                  selected={EndDate}
                                  placeholderText="Choose end Date"
                                  // onChange={date => eventHandler({ target: { value: date, name: 'EndDate', id: "End-date" } })}
                                  className={classNames(
                                    "form-control team-name"
                                    // , { "error": errors.EndDate }
                                  )}
                                  disabled
                                />

                                {/* {errors.EndDate &&
                                                            <span className='text-red small d-block'>* {errors.EndDate}</span>} */}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="text-left mt-18">
                                <label className="text-gray fs-13">
                                  To Time
                                </label>
                                {/* <input type="text" data-mintime="now" className="form-control team-name timepicker" placeholder="Choose To Time" id="from_time" /> */}

                                {/* <input type="text" data-mintime="now" className={classNames("form-control timepicker team-name"
                                                                        // , { "error": errors.to_time }
                                                                    )} placeholder="Choose End time" id="to_time" value={state.to_time} name="to_time"
                                                                        // onChange={eventHandler}
                                                                        disabled /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {eventDetails.event_facility.map((x, i) => {
                        return (
                          <React.Fragment key={i}>
                            <div className="d-flex justify-content-between">
                              <h6 className="text-left payment mt-30">
                                Facility
                              </h6>
                            </div>

                            <div className="row" key={x.event_id}>
                              <div className="col-md-4">
                                <div className="text-left mt-18">
                                  <label className="text-gray fs-13">
                                    Name
                                  </label>

                                  <input
                                    type="text"
                                    className={classNames(
                                      "form-control team-name"
                                      // , { "error": errors.facility_name }
                                    )}
                                    placeholder="Facility name"
                                    id="facility_name"
                                    name="facility_name"
                                    value={state.facility_name}
                                    //  onChange={eventHandler}
                                    disabled
                                  />

                                  {/* {errors.facility_name &&
                                                            <span className='text-red small d-block'>* {errors.facility_name}</span>} */}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="text-left mt-18">
                                  <label className="text-gray fs-13">
                                    Address
                                  </label>
                                  <textarea
                                    type="text"
                                    className={classNames(
                                      "form-control team-name text-area"
                                      // , { "error": errors.address }
                                    )}
                                    placeholder="Address"
                                    id="address"
                                    name="address"
                                    value={state.address}
                                    //  onChange={eventHandler}
                                    disabled
                                  />

                                  {/* {errors.address &&
                                                    <span className='text-red small d-block'>* {errors.address}</span>} */}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-3 mt-md-0">
                                <div className="text-left mt-18">
                                  <label className="text-gray fs-13">
                                    City
                                  </label>
                                  <input
                                    type="text"
                                    className={classNames(
                                      "form-control team-name"
                                      // , { "error": errors.city }
                                    )}
                                    placeholder="City"
                                    id="city"
                                    name="website"
                                    value={state.city}
                                    // onChange={eventHandler}
                                    disabled
                                  />
                                  {/* {errors.city &&
                                                    <span className='text-red small d-block'>* {errors.city}</span>} */}
                                </div>
                              </div>
                              <div className="col-md-3 mt-md-0">
                                <div className="text-left mt-18">
                                  <label className="text-gray fs-13">
                                    State
                                  </label>
                                  <input
                                    type="text"
                                    className={classNames(
                                      "form-control team-name"
                                      // , { "error": errors.state }
                                    )}
                                    placeholder="State"
                                    id="state"
                                    name="state"
                                    value={state.state}
                                    // onChange={eventHandler}
                                    disabled
                                  />
                                  {/* {errors.state &&
                                                    <span className='text-red small d-block'>* {errors.state}</span>} */}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 mt-md-0">
                                <div className="text-left mt-18">
                                  <label className="text-gray fs-13">
                                    Website
                                  </label>
                                  <input
                                    type="text"
                                    className={classNames(
                                      "form-control team-name"
                                      // , { "error": errors.website }
                                    )}
                                    placeholder="Website"
                                    id="website"
                                    name="website"
                                    value={state.website}
                                    // onChange={eventHandler}
                                    disabled
                                  />
                                  {/* {errors.website &&
                                                    <span className='text-red small d-block'>* {errors.website}</span>} */}
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}

                      <div className="d-flex justify-content-between">
                        <h6 className="text-left payment mt-30">Teams</h6>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-row">
                            <div className="col-md-12 col-12">
                              <div className="text-left mt-18">
                                <label className="text-gray fs-13">
                                  Max Teams
                                </label>
                                <input
                                  type="text"
                                  className={classNames(
                                    "form-control team-name"
                                    // , { "error": errors.maxTeam }
                                  )}
                                  placeholder="Max teams"
                                  id="maxTeam"
                                  name="max-team"
                                  // onChange={eventHandler}
                                  value={state.maxTeam}
                                  disabled
                                />
                                {/* 
                                                        {errors.maxTeam &&
                                                            <span className='text-red small d-block'>* {errors.maxTeam}</span>} */}
                              </div>
                            </div>
                            {/* <div className="col-md-6 col-6">
                                                    <div className="text-left mt-18">
                                                        <label className="text-gray fs-13">Availability</label>
                                                        <input type="text" className={classNames("form-control team-name", { "error": errors.availability })} placeholder="Availability" id="availability" name="availability" value={state.availability} onChange={eventHandler} />

                                                        {errors.availability &&
                                                            <span className='text-red small d-block'>* {errors.availability}</span>}
                                                    </div>
                                                </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <Toaster /> */}
                  </div>

                  {/* <<<<<<----------------------------   Details End  ---------------------------->>>>>> */}

                  {/* <<<<<<----------------------------   Team Start  ---------------------------->>>>>> */}

                  <div
                    className="tab-pane fade"
                    id="team-tab"
                    role="tabpanel"
                    aria-labelledby="pills-teams"
                  >
                    <div className="d-flex justify-content-between">
                      <h6 className="text-left payment mt-30">Participants</h6>
                    </div>
                    {getEventTeam ? (
                      <>
                        {/* desktop view */}
                        <div className="mt-24 table-responsive d-none d-md-block">
                          <table className="table event-table">
                            <thead>
                              <tr>
                                <th>Team Name</th>
                                <th>Division</th>
                                <th>Registration</th>
                                <th>Pool</th>
                                <th>Status</th>
                                {/* <th>Division</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {getEventTeam.map((v, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <Link
                                        to={`/teams/${id}/${v._id}`}
                                        className="text-blue text-decoration-none"
                                      >
                                        <div className="d-flex align-items-center">
                                          <div className="dashboard-img">
                                            <img src="./images/pch-images/pch.svg" />
                                          </div>
                                          <span className="ml-12">
                                            {v.team_id == null
                                              ? "null"
                                              : v.team_id.team_name}
                                          </span>
                                        </div>
                                      </Link>
                                    </td>
                                    <td>
                                      {v.division == null
                                        ? "null"
                                        : v.division.division_name}{" "}
                                    </td>
                                    <td>
                                      {v.registrationStatus == 1
                                        ? "false"
                                        : "true"}
                                    </td>
                                    <td>
                                      {v.pool == null
                                        ? "null"
                                        : v.pool.pool_name}
                                    </td>
                                    <td>
                                      {v.amountStatus == 0
                                        ? "paid"
                                        : "Not paid"}
                                    </td>
                                    <td>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>

                        {/* mobile view */}
                        <div className="mt-4 d-md-none">
                          {getEventTeam.map((v, i) => {
                            return (
                              <div
                                key={i}
                                className="d-flex justify-content-between reg-details"
                              >
                                <div className="d-flex">
                                  <div className="dashboard-img mr-3">
                                    <img src="./images/pch-images/pch.svg" />
                                  </div>

                                  <div className="reg-content">
                                    <Link
                                      className="text-decoration-none text-blue fw-m"
                                      to={`/teams/${id}/${v._id}`}
                                    >
                                      {v.team_id == []
                                        ? v.team_id.team_name
                                        : "  "}
                                    </Link>
                                    <span className="d-block text-gray mt-2">
                                      Division: {v.club}
                                    </span>
                                    <span className="d-block text-gray mt-2">
                                      Registtration:{" "}
                                      {v.division == null
                                        ? "null"
                                        : v.division.division_name}
                                    </span>
                                    <span className="d-block text-gray mt-2">
                                      Pool:{" "}
                                      {v.pool == null
                                        ? "null"
                                        : v.pool.pool_name}
                                    </span>
                                    <span className="d-block text-gray mt-2">
                                      Status:{" "}
                                      {v.amountStatus == 0
                                        ? "paid"
                                        : "Not paid"}
                                    </span>
                                  </div>
                                </div>
                                <div className="dropdown event-drp">
                                  <i
                                    className="mdi mdi-dots-vertical"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  ></i>

                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink"
                                  >
                                    <Link className="dropdown-item" to="#">
                                      <i className="mdi mdi-border-color"></i>
                                      Edit
                                    </Link>

                                    <Link
                                      className="dropdown-item"
                                      to="/payment/payment-code"
                                      onClick={() =>
                                        props.deleteEventTeamHandler(v._id)
                                      }
                                    >
                                      <i className="fas fa-trash-alt"></i>{" "}
                                      Delete
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* <div className="d-flex justify-content-between">
                                                <h6 className="text-left payment mt-30">Individual Players</h6>
                                            </div>
                                            */}

                    {/* desktop view */}
                    {/* <div className="mt-24 table-responsive d-none d-md-block">
                                                <table className="table event-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Position</th>
                                                            <th>Status</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="dashboard-img">
                                                                        <img src="./images/pch-images/pch.svg" />
                                                                    </div>
                                                                    <span className="ml-12">Players name</span>
                                                                </div>
                                                            </td>
                                                            <td>SF</td>
                                                            <td>Paid</td>
                                                            <td>
                                                                <Link to="#" className="text-decoration-none">
                                                                    <i className="mdi mdi-plus text-blue mr-2"></i>
                                                                    <span className="text-blue">Assign Team</span></Link>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> */}

                    {/* mobile view */}
                    {/* <div className="mt-4 d-md-none">
                                                {
                                                    TeamTbl.map((v, i) => {
                                                        return <div key={i} className="d-flex justify-content-between reg-details">
                                                            <div className="d-flex">
                                                                <div className="dashboard-img mr-3">
                                                                    <img src="./images/pch-images/pch.svg" />
                                                                </div>
                                                                <div className="reg-content">
                                                                    <Link className="text-decoration-none text-blue fw-m" to="/event-details">{v.name}</Link>
                                                                    <span className="d-block text-gray mt-2">Name: {v.club}</span>
                                                                    <span className="d-block text-gray mt-2">Position: {v.players}</span>
                                                                    <span className="d-block text-gray mt-2">Status: {v.status}</span>
                                                                </div>
                                                            </div>

                                                            <div className="dropdown details-drp">
                                                                <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                    <a className="dropdown-item" href="#">
                                                                        <i className="mdi mdi-plus text-blue mr-2 website-ico"></i>Assign Team</a>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    })
                                                }
                                            </div>  */}
                  </div>

                  {/* <<<<<<----------------------------   Team End  ---------------------------->>>>>> */}

                  {/* <<<<<<----------------------------   Division Start  ---------------------------->>>>>> */}

                  <div
                    className="tab-pane fade"
                    id="division-tab"
                    role="tabpanel"
                    aria-labelledby="pills-division"
                  >
                    {eventDetails.event_division !== undefined ? (
                      <>
                        <div className="d-flex justify-content-end  ">
                          <div className="dropdown event-drp details-drp"></div>
                        </div>
                        {eventDetails.event_division.map((v, i) => {
                          return (
                            <React.Fragment key={i}>
                              <div>
                                <div className="d-flex justify-content-between">
                                  <h6 className="text-left payment mt-30 fw-m">
                                    ({i + 1})Division
                                  </h6>
                                </div>
                                <div className="row">
                                  <div className="col-md-4 w-75">
                                    <div className="text-left mt-18 ">
                                      <div className="dropdown event-drp d-flex justify-content-between">
                                        <label
                                          className="payment ml-3"
                                          key={v._id}
                                        >
                                          {v.division_name}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {v.pool.map((a, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    <div className="dropdown event-drp  ">
                                      <div
                                        id="accordion"
                                        className="division w-50 accordian "
                                      >
                                        <div className="card ">
                                          <div className="justify-content-between d-flex align-items-center">
                                            <div
                                              className="card-header pl-3 w-100"
                                              id={`pool-${a._id}`}
                                            >
                                              <h5 className="mb-0">
                                                <button
                                                  className="btn btn-link collapsed text-decoration-none"
                                                  data-toggle="collapse"
                                                  data-target={`#pool-${a.pool_name}`}
                                                  aria-expanded="false"
                                                  aria-controls={`pool-${a.pool_name}`}
                                                >
                                                  {a.pool_name}

                                                  <i className="mdi mdi-chevron-down down-arrow mr-4 ml-4"></i>
                                                </button>
                                              </h5>
                                            </div>
                                          </div>
                                          <div
                                            id={`pool-${a.pool_name}`}
                                            className="collapse"
                                            aria-labelledby={`pool-${a._id}`}
                                            data-parent="#accordion"
                                          >
                                            <div className="card-body p-0">
                                              {/* desktop view */}
                                              <div className="table-responsive d-none d-md-block">
                                                <table className="table event-table">
                                                  <thead>
                                                    <tr>
                                                      <th></th>
                                                      <th>Team Name</th>
                                                      <th>Club Name</th>
                                                      <th>Couch Name</th>
                                                    </tr>
                                                  </thead>
                                                  {a.event_team.map((b, i) => {
                                                    return (
                                                      <React.Fragment key={i}>
                                                        <tbody>
                                                          <tr>
                                                            <td>
                                                              <div className="d-flex align-items-center">
                                                                <div className="dashboard-img">
                                                                  <img src="./images/pch-images/pch.svg" />
                                                                </div>
                                                                <span className="ml-12"></span>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              {b.team.team_name}
                                                            </td>
                                                            <td>
                                                              {b.team.club_name}
                                                            </td>
                                                            <td>
                                                              {
                                                                b.team
                                                                  .coach_name
                                                              }
                                                            </td>
                                                          </tr>
                                                          {/* })
                                                                                                            } */}
                                                        </tbody>
                                                      </React.Fragment>
                                                    );
                                                  })}
                                                </table>
                                              </div>

                                              {/* mobile view */}
                                              <div className="mt-3 d-md-none">
                                                {a.event_team.map((b, i) => {
                                                  return (
                                                    <div
                                                      className="d-flex justify-content-between reg-details"
                                                      key={i}
                                                    >
                                                      <div className="d-flex">
                                                        <div className="dashboard-img mr-3">
                                                          <img src="./images/pch-images/pch.svg" />
                                                        </div>
                                                        <div className="reg-content">
                                                          <Link
                                                            className="text-decoration-none text-blue fw-m"
                                                            to="/event-details"
                                                          >
                                                            {v.name}
                                                          </Link>
                                                          <span className="d-block text-gray mt-2">
                                                            Club Name:{" "}
                                                            {b.team.club_name}
                                                          </span>
                                                          <span className="d-block text-gray mt-2">
                                                            coach Name:{" "}
                                                            {b.team.coach_name}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </React.Fragment>
                          );
                        })}

                        {/* <Toaster /> */}
                      </>
                    ) : (
                      <>Loading...</>
                    )}

                    {getFreeTeam ? (
                      <>
                        <div className="d-flex mt-42">
                          <h6 className="text-left payment mt-30 fw-m">
                            Free Teams
                          </h6>
                        </div>

                        {getFreeTeam.organizerTeam.map((v, i) => {
                          return (
                            <React.Fragment key={i}>
                              <div id="accordion-3" className="division w-50">
                                <div className="card mt-2 mt-md-0">
                                  <div
                                    className="card-header"
                                    id={`pool-${v._id}`}
                                  >
                                    <h5 className="mb-0">
                                      <button
                                        className="btn btn-link collapsed text-decoration-none"
                                        data-toggle="collapse"
                                        data-target={`#pool-${v.team_id._id}`}
                                        aria-expanded="false"
                                        aria-controls={`pool-${v.team_id._id}`}
                                      >
                                        {v.team_id.team_name}
                                        <i className="mdi mdi-chevron-down down-arrow"></i>
                                      </button>
                                    </h5>
                                  </div>
                                  <div
                                    id={`pool-${v.team_id._id}`}
                                    className="collapse"
                                    aria-labelledby="pool-6"
                                    data-parent="#accordion-3"
                                  >
                                    <div className="card-body p-0">
                                      {/* desktop view */}
                                      <div className="table-responsive d-none d-md-block">
                                        <table className="table event-table">
                                          <thead>
                                            <tr>
                                              <th></th>
                                              <th>Team Name</th>
                                              <th>Registration</th>
                                              <th>Status</th>
                                              {/* <th>Payment</th> */}
                                              <th></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr key={i}>
                                              <td>
                                                <div className="d-flex align-items-center">
                                                  <div className="dashboard-img">
                                                    <img src="./images/pch-images/pch.svg" />
                                                  </div>
                                                  <span className="ml-12"></span>
                                                </div>
                                              </td>
                                              <td>
                                                <Link
                                                  to={`/teams/${id}/${v._id}`}
                                                  className="text-blue text-decoration-none"
                                                >
                                                  {v.team_id.team_name}
                                                </Link>
                                              </td>
                                              <td>
                                                {v.registrationStatus == 0
                                                  ? "true"
                                                  : "false"}
                                              </td>
                                              <td>
                                                {v.amountStatus == 0
                                                  ? "paid"
                                                  : "Unpaid"}
                                              </td>
                                              <td></td>
                                              <td>
                                                {/* <Link to={`/updatepooldivision/${id}/${v._id}`} className="text-decoration-none">
                                                                                                        <i className="mdi mdi-plus text-blue mr-2"></i>
                                                                                                        <span className="text-blue">Assign Pool</span></Link> */}
                                              </td>
                                            </tr>
                                            {/* })
                                                                                } */}
                                          </tbody>
                                        </table>
                                      </div>

                                      {/* mobile view */}
                                      <div className="mt-3 d-md-none">
                                        {/* {
                                                                            pool.map((v, i) => {
                                                                                return */}
                                        <div
                                          className="d-flex justify-content-between reg-details"
                                          key={i}
                                        >
                                          <div className="d-flex">
                                            <div className="dashboard-img mr-3">
                                              <img src="./images/pch-images/pch.svg" />
                                            </div>
                                            <div className="reg-content">
                                              <Link
                                                className="text-decoration-none text-blue fw-m"
                                                to="/event-details"
                                              >
                                                {v.name}
                                              </Link>
                                              <span className="d-block text-gray mt-2">
                                                Club Name: {v.club}
                                              </span>
                                              <span className="d-block text-gray mt-2">
                                                Payment: {v.payment}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        {/* })
                                                                        } */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <<<<<<----------------------------   Division End  ---------------------------->>>>>> */}

                  {/* <<<<<<----------------------------   Staff Start  ---------------------------->>>>>> */}

                  <div
                    className="tab-pane fade"
                    id="staff-tab"
                    role="tabpanel"
                    aria-labelledby="pills-staff"
                  >
                    <div className="mt-30">
                      <div className="d-flex justify-content-between">
                        <h6 className="text-left payment mt-30">
                          Staff Members
                        </h6>
                        <div className="dropdown details-drp"></div>
                      </div>

                      {getEventStaff ? (
                        <>
                          {/* desktop view */}
                          <div className="table-responsive d-none d-md-block">
                            <table className="table event-table">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email Address</th>
                                  <th>Phone No.</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {getEventStaff.map((v, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className="dashboard-img">
                                            <img src="./images/pch-images/pch.svg" />
                                          </div>
                                          <span className="ml-12">
                                            {v.user_id.name}
                                          </span>
                                        </div>
                                      </td>
                                      <td>{v.user_id.email}</td>
                                      <td>{v.mobile}</td>
                                      <td>
                                        {/* <div className="dropdown  event-drp">
                                                                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                                        More
                                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">

                                                                                            <Link className="dropdown-item" to="/events" onClick={() => props.deleteEventStaffHandler(v._id)}>
                                                                                                <i className="fas fa-trash-alt"></i>  Delete</Link>
                                                                                        </div>
                                                                                    </div> */}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          {/* mobile view */}
                          <div className="mt-3 d-md-none">
                            {staff.map((v, i) => {
                              return (
                                <div
                                  className="d-flex justify-content-between reg-details"
                                  key={i}
                                >
                                  <div className="d-flex">
                                    <div className="dashboard-img mr-3">
                                      <img src="./images/pch-images/pch.svg" />
                                    </div>
                                    <div className="reg-content align-items-center">
                                      <Link
                                        className="text-decoration-none text-blue"
                                        to="/event-details"
                                      >
                                        {v.name}
                                      </Link>
                                      <a className="d-block text-blue text-decoration-none">
                                        <i className="mdi mdi-message-text"></i>
                                        <span className="text-blue ml-2">
                                          Messages
                                        </span>
                                      </a>
                                      {/* <span className="d-block text-gray mt-2">Club Name: {v.club}</span>
                                                            <span className="d-block text-gray mt-2">Payment: {v.payment}</span> */}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <>
                          <h4>Loading...</h4>
                        </>
                      )}
                    </div>
                  </div>

                  {/* <<<<<<----------------------------   Staff End  ---------------------------->>>>>> */}

                  {/* <<<<<<----------------------------   Schedule Start  ---------------------------->>>>>> */}

                  <div
                    className="tab-pane fade"
                    id="schedule-tab"
                    role="tabpanel"
                    aria-labelledby="pills-schedule"
                  >
                    <div className="d-flex justify-content-between">
                      <h6 className="text-left payment mt-30 fw-m">Uploads</h6>
                      <div className="dropdown details-drp">
                        {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> */}

                        {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <Link className="dropdown-item" to={`/addschedule/${id}`}> <i className="mdi mdi-plus mr-2 website-ico"></i>Add</Link>
                                                    </div> */}
                      </div>
                    </div>

                    {eventDetails ? (
                      <>
                        {eventDetails.event_schedule.map((v, i) => {
                          return (
                            // <div className="row ">
                            <div className="col-md-4 " key={i}>
                              <div className="text-left mt-18 account-type-section">
                                {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> */}
                                <div className=" d-flex ">
                                  <div className="payment">
                                    <a
                                      href={v.text == "" ? v.file : v.text}
                                      target="_blank"
                                      rel="noreferrer noopener"
                                    >
                                      {v.text == "" ? v.file : v.text}{" "}
                                    </a>
                                  </div>
                                </div>
                                {/* <div className="dropdown event-drp">
                                                                            <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">

                                                                                <Link className="dropdown-item" to={`/event-details/${id}`}
                                                                                    onClick={() => props.deleteScheduleHandler(v._id)}>
                                                                                    <i className="fas fa-trash-alt"></i>  Delete</Link>
                                                                            </div>

                                                                        </div> */}

                                {/* <input type="text" className="form-control team-name" placeholder="Division name" /> */}
                              </div>
                            </div>
                            // </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <h4>Loading..</h4>
                      </>
                    )}
                  </div>

                  {/* <<<<<<----------------------------   Schedule End  ---------------------------->>>>>> */}

                  {/* <<<<<<----------------------------   Accounting Start  ---------------------------->>>>>> */}

                  <div
                    className="tab-pane fade"
                    id="accounting-tab"
                    role="tabpanel"
                    aria-labelledby="pills-accounting"
                  >
                    <div className="d-flex justify-content-between ">
                      <h6 className="text-left payment my-auto">
                        Payment Code
                      </h6>
                    </div>
                    <div className="table-responsive d-none d-md-block">
                      <table className="table green-table mt-24">
                        <thead>
                          <tr>
                            <th>Code Name</th>
                            <th>Amount</th>
                            <th>Amount Due($ or %)</th>
                            <th>Balance Due Day</th>
                            <th></th>
                          </tr>
                        </thead>

                        {/* Invoice data callS */}

                        <tbody>
                          {eventDetails.payment_code.map((v, i) => {
                            // alert("Inside")
                            return (
                              <tr className="pay-row" key={i}>
                                <td>
                                  <span className="filter-string">
                                    {v.name}
                                  </span>
                                </td>
                                <td>{v.amount}</td>
                                <td>
                                  {v.payment_terms}
                                  {v.payment_terms_type == 1 ? "%" : "$"}
                                </td>
                                <td>{v.payment_due}</td>
                                <td>
                                  {/* <div className="dropdown event-drp">
                                                                <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                More
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                    <Link className="dropdown-item" to={(`/editpaymentcode/${v._id}`)}  >
                                                                        <i className="mdi mdi-border-color"></i>
                                                                        Edit</Link>

                                                                    <Link className="dropdown-item" to="/payment/payment-code" onClick={() => props.deletePaymentCodeHandler(v._id)}>
                                                                        <i className="fas fa-trash-alt"></i>  Delete</Link>
                                                                </div>
                                                            </div> */}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    {/* Mobile View  */}
                    <div className="mt-30 d-md-none">
                      <div className="mt-4">
                        {eventDetails.payment_code.map((v, i) => {
                          return (
                            <div
                              className="d-flex justify-content-between reg-details"
                              key={i}
                            >
                              <div className="d-flex">
                                <div className="reg-content">
                                  <Link
                                    className="text-decoration-none text-blue fw-m"
                                    to="/event-details"
                                  >
                                    {v.name}
                                  </Link>
                                  <span className="d-block text-gray mt-2">
                                    Amount: {v.amount}
                                  </span>
                                  {/* <span className="d-block text-gray mt-2">ExpirationDate: {v.invoice}</span> */}
                                  <span className="d-block text-gray mt-2">
                                    Balance due day: {v.payment_due}
                                  </span>
                                  {/* <span className="d-block text-gray mt-2">ExpirationDate: {v.payment_id}</span> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </>
          ) : (
            <h4>Loading...</h4>
          )}

          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(Org_eventDetails);

const mapStateToProps = (state) => ({
  submit: state.deleteEventReducer,
  submit1: state.deleteScheduleReducer,
  Team: state.deleteEventTeamReducer,
  createDivision: state.createDivisionReducer,
  createPool: state.createPoolReducer,
  deletePoolmessage: state.deletePoolReducer,
  editPoolmessage: state.editPoolReducer,
  editDivisionmessage: state.editDivisionReducer,
  deleteDivisionmessage: state.deleteDivisionReducer,
  schedulemessage: state.createTeamReducer,
  updateFreeTeam: state.updateFreeTeamReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getEventHandler: (user) => dispatch(getAllEvent(user)),
  getAllDivisionHandler: (user) => dispatch(getAllDivision(user)),
  getEventStaffHandler: (user) => dispatch(getEventStaff(user)),
  getEventScheduleHandler: (user) => dispatch(getEventSchedule(user)),
  getEventTeamHandler: (user) => dispatch(getOrgEventTeam(user)),
  deleteEventStaffHandler: (user) => dispatch(deleteEventStaff(user)),
  deleteScheduleHandler: (user) => dispatch(deleteSchedule(user)),
  resetdeletePaymentCodeReducer: () => dispatch({ type: RESET_DELETE_EVENT }),
  resetdeleteScheduleReducer: () => dispatch({ type: RESET_DELETE_SCHEDULE }),
  resetdeleteEventTeamReducer: () => dispatch({ type: RESET_DELETE_EVENTTEAM }),
  resetcreatedivisionReducer: () => dispatch({ type: RESET_CREATE_DIVISION }),
  resetCreatePoolReducer: () => dispatch({ type: RESET_CREATE_POOL }),
  reseteditPoolReducer: () => dispatch({ type: RESET_EDIT_POOL }),
  reseteditDivisionReducer: () => dispatch({ type: RESET_EDIT_DIVISION }),
  resetdeleteDivisionReducer: () => dispatch({ type: RESET_DELETE_DIVISION }),
  resetDeletePoolReducer: () => dispatch({ type: RESET_DELETE_POOL }),
  resetscheduleReducer: () => dispatch({ type: RESET_CREATE_TEAM }),
  resetFreetaemReducer: () => dispatch({ type: RESET_FREETEAM }),
  getFreeTeamHandler: (user) => dispatch(getFreeTeam(user)),
  deleteEventTeamHandler: (user) => dispatch(deleteEventTeam(user)),
  deletePoolHandler: (user) => dispatch(deletePool(user)),
  deleteDivisionHandler: (user) => dispatch(deleteDivision(user)),
  getEventDetailsForTeamRegistrationHandler: (user) =>
    dispatch(getEventDetailsForRegistration(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RegisterEventPage));
