import { GET_DISCOUNT_CODE_ERROR, GET_DISCOUNT_CODE_LOADING, GET_DISCOUNT_CODE_SUCCESS, RESET_DISCOUNT_CODE } from "../../constants";

let initialState = {
    error: false,
    success: false,
    loading: false,
    errors: [],
    data: [
        //     {

        //         "event": [],
        //         "_id": '',
        //         "organizer": '',
        //         "code_name": '',
        //         "code": '',
        //         "type": '',
        //         "amount": '',
        //         "createdAt": '',
        //         "updatedAt": '',
        //         "__v": ''

        // }
    ]
};

export default function getDiscountCodeReducer(state = initialState, action) {
    switch (action.type) {
        case GET_DISCOUNT_CODE_LOADING:
            return { ...state, error: false, loading: true, success: false }
        case GET_DISCOUNT_CODE_SUCCESS:
            return { ...state, error: false, success: true, loading: false, data: action.payload }
        case GET_DISCOUNT_CODE_ERROR:
            return { ...state, error: true, loading: false, success: false, errors: [] }
        case RESET_DISCOUNT_CODE:
            return { ...state, error: false, loading: false, success: false, errors: [] }
        default:
            return state
    }

}