import React from "react";
import { Link } from "react-router-dom";
const ClubDashboardStatsCard = ({ count, text, pathname, state = "" }) => {
  return (
    <div className="col-md-5 col-6">
      <Link
        to={{
          pathname: pathname,
          state: state,
        }}
        className="text-decoration-none"
      >
        <div className="outstanding">
          <span className="pay-amount">{count}</span>
          <span className="pay-method">{text}</span>
        </div>
      </Link>
    </div>
  );
};

export default ClubDashboardStatsCard;
