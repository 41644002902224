import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import {
  deletePaymentCode,
  getAllPaymentCode,
  getFreeTeam,
} from "../../../service/action/userAction";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import {
  RESET_CREATE_PAYMENT_CODE,
  RESET_DELETE_EVENT,
  RESET_EDIT_PAYMENT_CODE,
} from "../../../service/constants";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import Spinner from "../../../Spinner/Spinner";
import { confirmAlert } from "react-confirm-alert";
import DeletePopUp from "../../../club/components/DeletePopUp/DeletePopUp";
import { Button } from "react-bootstrap";
const PaymentCode = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    if (props.Delete?.success) {
    }
  }, [props.Delete]);

  const getdata = props.getPaymentCodeHandler;
  useEffect(() => {
    getdata();
  }, []);

  let [loading, setLoading] = useState(true);

  const header = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  const allpayment = useSelector(
    (state) => state.getAllPaymentCodeReducer.user
  );

  const deleteEventMsg = useSelector(
    (state) => state.deleteEventReducer.message
  );

  const createCodeMsg = useSelector(
    (state) => state.createPaymentCodeReducer.message
  );

  const editCodeMsg = useSelector(
    (state) => state.editPaymentCodeReducer.message
  );

  const notify = (msg) => toast(msg);

  useEffect(() => {
    if (props.submit?.success) {
      notify(deleteEventMsg.message);
      props.resetdeletePaymentCodeReducer();
      getdata();
      // history.push(`/event-details/${id}`);
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.createCode?.success) {
      notify(createCodeMsg.message);
      props.resetcreatePaymentCodeReducer();
      getdata();

      // history.push(`/event-details/${id}`);
    }
  }, [props.createCode]);

  useEffect(() => {
    if (props.editCode?.success) {
      notify(props.submitRequest?.message?.message);
      notify(editCodeMsg.message);
      props.reseteditPaymentCodeReducer();
      getdata();
      // history.push(`/event-details/${id}`);
    }
  }, [props.editCode]);

  useEffect(() => {
    if (props.getAllPayment?.success) {
      setLoading(false);
    }
  }, [props.getAllPayment]);

  // const green_tbl = [
  //     {
  //         invoicecodename: "Rio",
  //         amout: "200",
  //         amountdue: "20%",
  //         balancedueday: "7"

  //     },
  //     {
  //         invoicecodename: "Jhon",
  //         amout: "500",
  //         amountdue: "25%",
  //         balancedueday: "9"

  //     }
  // ]

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-center">
            {loading ? (
              <div className="w-full h-[60vh] flex flex-col justify-center items-center">
                <Spinner />
              </div>
            ) : null}
          </div>
          {!loading && allpayment !== [{}] ? (
            <>
              <div className="text-right">
                <div>
                  <div className="mt-3 mt-md-0 text-right create-team-head-btn">
                    <Link to="/create-payment-code">
                      <button className="btn-create-new ">+ Create</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="green-section mt-30">
                <h6 className=" dash-head">Payment Code List</h6>

                <hr />

                {allpayment.length == 0 ? (
                  <>
                    <div className="container d-flex justify-content-center align-items-center">
                      <span className="noti-type">No payment codes found</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="table-responsive d-none d-md-block">
                      <table className="table green-table mt-24">
                        <thead>
                          <tr>
                            <th>Code Name</th>
                            <th>Type</th>
                            <th>Payment code type</th>
                            <th>Amount</th>
                            <th>Amount Due</th>
                            <th>Payment Due Days</th>
                            <th></th>
                          </tr>
                        </thead>

                        {/* Invoice data callS */}

                        <tbody>
                          {allpayment.map((v, i) => {
                            // alert("Inside")
                            return (
                              <tr className="pay-row" key={v._id}>
                                <td>
                                  <span className="filter-string">
                                    {v.name}
                                  </span>
                                </td>
                                <td>{v.type == 0 ? "Event" : "custom"}</td>
                                <td>
                                  {v.paymentcode_type == 0
                                    ? "club"
                                    : v.paymentcode_type == 1
                                    ? "parent/player"
                                    : "NA"}
                                </td>
                                <td>{" $ " + v.amount}</td>
                                <td>
                                  {v.payment_terms_type == 1
                                    ? v.payment_terms + " % "
                                    : " $ " + v.payment_terms}
                                </td>
                                <td>{v.payment_due}</td>
                                <td>
                                  <div className="dropdown event-drp">
                                    <i
                                      className="mdi mdi-dots-vertical"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    ></i>
                                    {/* More */}
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuLink"
                                    >
                                      <Link
                                        className="dropdown-item"
                                        to={`/editpaymentcode/${v._id}`}
                                      >
                                        <i className="mdi mdi-border-color"></i>
                                        Edit
                                      </Link>

                                      <Button
                                        className="dropdown-item text-dark"
                                        // to=""
                                        onClick={() => {
                                          setIsDelete(true);
                                          setDeleteId(v._id);
                                        }}
                                      >
                                        <i className="fas fa-trash-alt"></i>{" "}
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

                {/* Mobile View  */}
                <div className="mt-30 d-md-none">
                  <div className="mt-4">
                    {allpayment.map((v, i) => {
                      return (
                        <div
                          className="d-flex justify-content-between reg-details"
                          key={v._id}
                        >
                          <div className="d-flex">
                            <div className="reg-content">
                              <Link
                                className="text-decoration-none text-blue fw-m"
                                to="/event-details"
                              >
                                {v.name}
                              </Link>
                              <span className="d-block text-gray mt-2">
                                Type: {v.type === 1 ? "Custome" : "Event"}
                              </span>
                              <span className="d-block text-gray mt-2">
                                Amount: ${v.amount}
                              </span>
                              <span className="d-block text-gray mt-2">
                                Amount Due:{" "}
                                {v.payment_terms_type == 1
                                  ? v.payment_terms + " % "
                                  : " $ " + v.payment_terms}
                              </span>
                              {/* <span className="d-block text-gray mt-2">ExpirationDate: {v.invoice}</span> */}
                              <span className="d-block text-gray mt-2">
                                Balance due day: {v.payment_due}
                              </span>
                              {/* <span className="d-block text-gray mt-2">ExpirationDate: {v.payment_id}</span> */}
                            </div>
                          </div>
                          <div className="dropdown event-drp">
                            <i
                              className="mdi mdi-dots-vertical"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></i>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              {/* <a className="dropdown-item" href="#">
                                                                Refund(% or $)
                                                            </a> */}
                              <Link
                                className="dropdown-item"
                                to={`/editpaymentcode/${v._id}`}
                              >
                                <i className="mdi mdi-border-color"></i>
                                Edit
                              </Link>

                              <Link
                                className="dropdown-item"
                                to={`/events`}
                                onClick={() => {
                                  setIsDelete(true);
                                  setDeleteId(v._id);
                                }}
                              >
                                <i className="fas fa-trash-alt"></i> Delete
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <Toaster />
        </div>
      </div>
      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => props.deletePaymentCodeHandler(deleteId)}
      />
    </>
  );
};

// export default withRouter(PaymentCode);
const mapStateToProps = (state) => ({
  getAllPayment: state.getAllPaymentCodeReducer,
  submit: state.deleteEventReducer,
  createCode: state.createPaymentCodeReducer,
  editCode: state.editPaymentCodeReducer,
  getFreeTeamReducer: state.getFreeTeamReducer,
});
const mapDispatchToProps = (dispatch) => ({
  getPaymentCodeHandler: (user) => dispatch(getAllPaymentCode(user)),
  deletePaymentCodeHandler: (user) => dispatch(deletePaymentCode(user)),
  resetdeletePaymentCodeReducer: () => dispatch({ type: RESET_DELETE_EVENT }),
  resetcreatePaymentCodeReducer: () =>
    dispatch({ type: RESET_CREATE_PAYMENT_CODE }),
  reseteditPaymentCodeReducer: () =>
    dispatch({ type: RESET_EDIT_PAYMENT_CODE }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentCode));
