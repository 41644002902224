import { EVENT_REGISTRATION_ERROR, EVENT_REGISTRATION_LOADING, EVENT_REGISTRATION_SUCCESS, RESET_EVENT_REGISTRATION } from "../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {}
};

export default function EventRegistrationReducer(state = initialState, action) {
  switch (action.type) {
    case EVENT_REGISTRATION_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case EVENT_REGISTRATION_SUCCESS:
      return { ...state, error: false, loading: false, success: true,message:action.msg };

    case EVENT_REGISTRATION_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: action.errors,msg:action.errors }

    case RESET_EVENT_REGISTRATION:
        return {...state,error:false,loading:false,success:false,errors:[],user:[{}]}
    default:
      return state;
  }
}

