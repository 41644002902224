import { GET_PAYMENT_CODE_SUCCESS,GET_PAYMENT_CODE_LOADING,GET_PAYMENT_CODE_ERROR, RESET_GET_PAYMENTCODE} from "../constants"

let initialState = {
  loading: false,
  user: [
    // {
    // "event": [],
    // "_id": "",
    // "organizer": "",
    // "code_name":"",
    // "code": "",
    // "type": "",
    // "amount": "",
    // "createdAt": "",
    // "updatedAt": "",
    // "__v": ""
    // }
  ],
  error: false,
  errors: [],
  success: false
};
export default function getAllPaymentCodeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENT_CODE_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false,success:false};
     
    case GET_PAYMENT_CODE_SUCCESS:  
      // alert(" reducer Error")
      return { ...state, loading: false ,success:true,user:action.payload};
 
    case GET_PAYMENT_CODE_ERROR:
      // alert(" reducer Loading")
      return { ...state,error: true, errors: action.errors };

    case RESET_GET_PAYMENTCODE:
      // alert(" reducer Loading")
      return { ...state,error: false , errors: action.errors , success :false };

    default:
      return state;
  }
}


