import { GET_PAYMENT_REQUEST_ERROR, GET_PAYMENT_REQUEST_LOADING, GET_PAYMENT_REQUEST_SUCCESS, RESET_GET_PAYMENT_REQUEST } from "../../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false
};

export default function getPaymentRequestReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENT_REQUEST_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false,success:false};
     
    case GET_PAYMENT_REQUEST_SUCCESS:  
      // alert(" reducer Error")
      return { ...state, loading: false,success:true,user:action.payload};
 
    case GET_PAYMENT_REQUEST_ERROR:
      // alert(" reducer Loading")
      return { ...state,error: true, errors: action.errors };
    
    case RESET_GET_PAYMENT_REQUEST:

    return {...state,error:false,user:[],success:false,loading:false}
   
    default:
      return state;
  }
}


