import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Toaster from "../Toaster/Toaster";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";

const Signup = (props) => {
  // console.clear()

  const [resend, setResend] = useState(false);
  let [color, setColor] = useState("#FFFFFF");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // props.resetSigninHandler()
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    option: "",
  });

  const signUpError = useSelector((state) => state.signupReducer.errors);
  const validationError = useSelector((state) => state.signupReducer);

  const [startDate, setStartDate] = useState("");
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    password: false,
    option: false,
    startDate: false,
    hint: false,
  });

  const [showDatePicker, setShowDatePicker] = useState(false);

  function getAge(dateString) {
    var ageInMilliseconds = new Date() - new Date(dateString);
    return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365); // convert to years
  }

  const [age, setAge] = useState(0);

  const eventhandler = (e) => {
    const { id, value, name } = e.target;

    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const validPassword = new RegExp("^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})");

    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    switch (name) {
      case "startDate":
        setStartDate(value);
        setAge(getAge(value));
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            startDate: "Date of birth can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            startDate: false,
          }));
        }
        break;
      case "name":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            name: "Name can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            name: false,
          }));
        }
        break;
      case "email":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Email can not be empty",
          }));
        } else if (!validEmailRegex.test(value)) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Email is not valid !",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;
      case "password":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            password: "Password can not be empty",
            hint: false,
          }));
        } else if (!validPassword.test(value)) {
          setErrors((prevState) => ({
            ...prevState,
            password: false,
            hint: "(Hint:Password must be at least 8 characters long with uppercase, lowercase, and special character)",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            password: false,
            hint: false,
          }));
        }

        break;
      case "account":
        // alert(value)
        // if (value === 2 || value === 3 || value === 5) {
        //     setShowDatePicker(true)
        // } else {
        //     setShowDatePicker(false)
        // }
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            option: "Value can not be empty",
          }));

          setState((prevState) => ({
            ...prevState,
            option: value,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            option: false,
          }));
          if (value == 2 || value == 3 || value == 5) {
            setShowDatePicker(true);
          } else {
            setShowDatePicker(false);
          }
          setState((prevState) => ({
            ...prevState,
            option: value,
          }));
        }
        break;
      default:
    }
  };
  // var strongRegex = new RegExp("^(?=.*[A-Z])(?=.*\W)(?=.{8,})");
  // var strongRegex = new RegExp("^(?=.{8,})");
  // useEffect(()=>{
  //     if(state.option ==2 || state.option ==3 || state.option == 5){
  //         alert("k")
  //         setShowDatePicker(true)
  //     } else {
  //         setShowDatePicker(false)
  //     }
  // },[state.option])

  const signup = (e) => {
    e.preventDefault();
    getAge();
    if (state.email === "") {
      setErrors((prevState) => ({
        ...prevState,
        email: "Email can not be empty",
      }));
    }

    if (state.name === "") {
      setErrors((prevState) => ({
        ...prevState,
        name: "Name can not be empty",
      }));
    }

    if (state.password === "") {
      setErrors((prevState) => ({
        ...prevState,
        password: "Password can not be empty",
        hint: false,
      }));
    }

    if (state.option === "") {
      setErrors((prevState) => ({
        ...prevState,
        option: "Value can not be empty",
      }));
    }
    if (
      (state.option == 2 || state.option == 3 || state.option == 5) &&
      age < 18
    ) {
      setErrors((prevState) => ({
        ...prevState,
        startDate: "Player must be at least 18 years old",
      }));
    }

    if (
      (state.option == 2 || state.option == 3 || state.option == 5) &&
      startDate == ""
    ) {
      setErrors((prevState) => ({
        ...prevState,
        startDate: "Date of birth can not be empty",
      }));
    }

    if (
      state.email !== "" &&
      state.password !== "" &&
      state.option !== "" &&
      state.name !== "" &&
      // age>=18 &&
      errors.password === false &&
      (state.option == 2 || state.option == 3 || state.option == 5) &&
      startDate !== ""
    ) {
      //status = 1 = club, status =2 = organizer
      props.signUpHandler({
        email: state.email,
        password: state.password,
        user_type: state.option,
        name: state.name,
        dob: moment(startDate).format("YYYY-MM-DD"),
      });
      // sessionStorage.setItem("status", 1);
      // history.push("/dashboard");
    }
  };

  const notify = (msg) => toast(msg);
  const [flashMsg, setFlashMsg] = useState(false);

  useEffect(() => {
    if (props.signup?.loading) {
      setLoading(true);
    } else if (props.signup?.success) {
      setLoading(false);
      notify("Register successfully");
      // setFlashMsg(true)
      // props.resetSignup()
      // return <Redirect to="/login" />;
      history.push("/login");
    } else if (props.signup?.error) {
      if (signUpError) {
        setLoading(false);
        notify(signUpError.email);
      } else if (validationError.errorNet) {
        setLoading(false);
        notify(validationError.errorNet.message);
      } else if (validationError) {
        setLoading(false);
        notify(validationError.message);
      }
    }
  }, [props.signup]);

  return (
    <React.Fragment>
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex auth login-section">
          <div className="row flex-grow">
            <div className="col-lg-4 m-auto">
              <div className="auth-form-light text-left p-5">
                <div className="brand-logo text-center">
                  <img
                    src="./images/pch-images/PCH_Logo_Hori_Blue Blue-white.svg"
                    alt="logo"
                  />
                </div>
                <h6 className="text-white">Sign Up</h6>
                <form className="pt-3">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Name *"
                      className={classNames("form-control ", {
                        "login-error": errors.name,
                      })}
                      id="name"
                      name="name"
                      value={state.name}
                      onChange={eventhandler}
                    />
                    {errors.name && (
                      <span className="text-danger small d-block">
                        * {errors.name}
                      </span>
                    )}
                  </div>
                  <div className="form-group event-drp">
                    <select
                      className={classNames("form-control payterm", {
                        "login-error": errors.option,
                      })}
                      id="account"
                      name="account"
                      value={state.option}
                      onChange={eventhandler}
                    >
                      <option style={{ color: "#000" }}>Account Type *</option>
                      <option value="1" style={{ color: "#000" }}>
                        Event Organizer
                      </option>
                      <option value="2" style={{ color: "#000" }}>
                        Staff
                      </option>
                      <option value="4" style={{ color: "#000" }}>
                        Club
                      </option>
                      <option value="3" style={{ color: "#000" }}>
                        Coach
                      </option>
                      <option value="6" style={{ color: "#000" }}>
                        Parent/Guardian
                      </option>
                      <option value="5" style={{ color: "#000" }}>
                        Player
                      </option>
                    </select>

                    {errors.option && (
                      <span className="text-danger small d-block">
                        * {errors.option}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className={classNames("form-control ", {
                        "login-error": errors.email,
                      })}
                      placeholder="Email *"
                      id="email"
                      name="email"
                      value={state.email}
                      onChange={eventhandler}
                      autoComplete="off"
                    />

                    {errors.email && (
                      <span className="text-danger small d-block">
                        * {errors.email}
                      </span>
                    )}
                  </div>

                  {showDatePicker && (
                    <div className="form-group">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        className={classNames(
                          "form-control form-control-lg w-100",
                          {
                            "login-error": errors.startDate,
                          }
                        )}
                        selected={startDate}
                        placeholderText="Date Of Birth *"
                        onChange={(e) =>
                          eventhandler({
                            target: {
                              value: e,
                              name: "startDate",
                              id: "startDate",
                            },
                          })
                        }
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        adjustDateOnChange
                      />
                      {errors.startDate && (
                        <span className="text-danger small d-block">
                          * {errors.startDate}
                        </span>
                      )}
                    </div>
                  )}

                  <div className="input-group  form-group pass-wrapper form-group-1">
                    <input
                      type={show ? "text" : "password"}
                      className={classNames(
                        "form-control form-control-lg w-100",
                        {
                          "login-error": errors.password,
                        }
                      )}
                      placeholder="Password *"
                      id="password"
                      value={state.password}
                      name="password"
                      onChange={eventhandler}
                      aria-label="Password"
                      aria-describedby="button-addon2"
                      style={{ zIndex: "1" }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          signup(e);
                        }
                      }}
                    />
                    <div className=" validation-left">
                      {errors.password && (
                        <span className="text-danger small d-block">
                          * {errors.password}
                        </span>
                      )}
                    </div>
                    {/* <div
                                            className="input-group-append " 
                                            
                                            onClick={() => setShow(!show)} >
                                            {
                                                show ? <i className=" fas fa-eye-slash eye " id="button-addon2" ></i> :
                                                    <i className=" fas fa-eye eye" id="button-addon2"></i>
                                            }
                                        </div> */}
                    <div className="bg-transparent input-group-append position-absolute eye-button mr-2">
                      {show ? (
                        <i
                          className="fas fa-eye-slash eye "
                          aria-describedby="button-addon2"
                          onClick={(e) => {
                            setShow(!show);
                          }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-eye eye"
                          aria-describedby="button-addon2"
                          onClick={(e) => {
                            setShow(!show);
                          }}
                        ></i>
                      )}
                    </div>
                  </div>
                  {errors.hint && (
                    <span className="text-white small d-block">
                      * {errors.hint}
                    </span>
                  )}
                  <div className="mt-3 text-center">
                    <button
                      type="submit"
                      className="btn btn-login font-weight-medium auth-form-btn"
                      disabled={props.signup?.loading}
                      onClick={signup}
                    >
                      Sign up
                    </button>
                  </div>

                  <div className="text-center mt-4 text-white">
                    {" "}
                    Already have an account?{" "}
                    <Link to="/login" className="auth-link fw-m">
                      Log In
                    </Link>
                  </div>
                  <div className="d-flex justify-content-center">
                    {loading ? (
                      <ClipLoader
                        color={color}
                        loading={loading}
                        css={css}
                        size={50}
                      />
                    ) : null}
                  </div>
                  {/* {
                                        flashMsg &&
                                        <div className="text-center mt-4 text-white fs-13">
                                            {" "}
                                            You account has been created but you must verify your email before you can login. We’ve just sent you an email verification. Please check your email to continue. If you did not receive the email, click here to{" "}
                                            <Link to="/signup" className="auth-link fw-m" onClick={signup}>
                                                [resend].
                                            </Link>
                                        </div>
                                    } */}
                </form>
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Signup;
