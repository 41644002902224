import axios from "axios";
import {
  createEventUrl,
  signInUrl,
  signInUserUrl,
  createPaymentCode_Url,
  getAllpaymentCode_Url,
  getAllEvent_Url,
  getUserRoll_Url,
  switch_Account_Url,
  add_account_Url,
  signupByMail_Url,
  state_Url,
  city_Url,
  getAllDiscountCode_Url,
  createDiscountCode_Url,
  AddEventSeasonOrg_Url,
  AddEventSeasonClub_Url,
  EventSeasonOrg_Url,
  EventSeasonClub_Url,
  EditEventData_Url,
  getInvitationData_Url,
  Org_Account_Details_Url,
  Club_Account_Details_Url,
  Org_EditAccount_details_Url,
  Org_CreateDivision_Url,
  Org_GetAllDivision_Url,
  Org_CreatePool_Url,
  Org_EditDivision_Url,
  Org_EditPool_Url,
  CreateTeam_Url,
  GetAllClub_Url,
  Invite_Club_Url,
  GetAllStaff_Url,
  GetEventStaff_Url,
  Invite_Staff_Url,
  cloneEvent_Url,
  cloneEvent_Club_Url,
  Remove_Event_Staff_Url,
  Add_Schedule_Url,
  getAllSchedule_Url,
  deleteSchedule_Url,
  freeTeam_Url,
  Event_Team_Url,
  UpdateFreeTeam_Url,
  create_Club_Event_Url,
  Edit_Club_EventData_Url,
  get_Club_Event_Url,
  Remove_Team_Url,
  Club_Payment_Url,
  Club_DiscountCode_Url,
  Club_Update_Profile_Url,
  sport_Url,
  BookMark_Url,
  CreateTeam_club,
  get_Organizer_Club_List_Url,
  Get_Club_Payment_Url,
  Create_Liability_Url,
  create_Group_Url,
  Add_staff_In_Group_Url,
  get_Permission_Details_Url,
  change_permission_Url,
  signout_Url,
  getRecipient_Url,
  getPendingAmount_Url,
  payment_request_Url,
  getPaymentRequest_Url,
  getLiability_Url,
  getOrgPlayer_Url,
  createTeamGroup_Url,
  add_PlayerToGroup_Url,
  get_Group_Details_Url,
  remove_player_from_group_Url,
  get_paid_Event_Url,
  get_recipient_Url,
  create_Credit_Note_Url,
  get_Credit_Url,
  create_Refund_Url,
  get_payment_Url,
  create_club_refund_Url,
  forgot_password_email_Url,
  verify_otp_Url,
  reset_password_Url,
  change_password_Url,
  get_payement_event_Url,
  club_bulk_recipient_Url,
  get_Club_recipient_Url,
  getClubPendingAmount_Url,
  getPaymentRequest_Club_Url,
  Create_Credit_CLub_Url,
  get_payment_club_Url,
  Org_Club_Details_Url,
  Org_Club_Team_Details_Url,
  Event_Image_Upload_Url,
  Club_Event_Image_Upload_Url,
  OutsideStaff_Url,
  club_team_Image_Url,
  league_bookmark_Url,
  getReceiveRepoet_Url,
  getOutStandingReport_Url,
  getRefundReport_Url,
  getClubReceiveRepoet_Url,
  getClubOutStandingReport_Url,
  getClubRefundReport_Url,
  dashbordPayment_Url,
  Club_dashbordPayment_Url,
  club_Create_pool,
  all_Report_Url,
  all_Club_Report_Url,
  get_payment_Club_Url,
  create_Refund_Club_Url,
  get_filter_Event_Url,
  event_pool_Url,
  getEventBy_id_Url,
  get_single_chat_Url,
  sendBroadcast_Url,
  sendBroadcast_Club_Url,
  published_schedule_Url,
  clubEventDateFilter,
  getEventDateFilter,
  get_bulk_recipient__Url,
  admin_Url,
  reminder_Url,
  Org_RemovePoolTeam_Url,
  DivisionCustomPaymentCode_Url,
  findUser_Url,
  findUserByEmail_Url,
  Team_detail_Url,
  GetActivityLog_Url,
  clubFindUser_Url,
  removeAccount_Url,
  InvitePlayer_Org_Url,
  Get_Player_List_Url,
  accounting_Url,
  removeTeamRoster_Url,
  Team_Event_Url,
  merchant_Organizer_Url,
  merchant_Status_Url,
  Signed_Waiver_Url,
  Liability_Terms_Url,
  addTeamRoster_Url,
  addTeamOrgRoster_Url,
  eventRegistrationON_Url,
  eventRegistrationOFF_Url,
  reqaddpayment_Url,
  getClubOrganizerRefund_Url,
  Player_details_Url,
  club_discount_Url,
  club_credit_Url,
  Org_getallclub_Url,
  Org_balances_due_Url,
  switchAccess_Url,
  eventdivisionpool_Url,
  addteamtodivision_Url,
  UpdateSchedule_Url,
  createfieldmap_Url,
  viewfieldmap_Url,
  editfieldmap_Url,
  clearOrgNotifications_Url,
  create_schedule_message_Url,
  create_fieldmap_Url,
  create_fieldmap_message_Url,
  view_stayandplay_Url,
  create_stayandplay_Url,
  update_stayandplay_Url,
  delete_stayandplay_Url,
  create_stayandplay_message_Url,
  getAdminPermissionList_Url,
  CreateOrgAdminPermissionGroup_Url,
  delete_permission_Url,
  getAdmin_Url,
  GetAllUsersList_Url,
  InviteAdmin_Url,
  getStaffPermissionList_Url,
  CreateOrgStaffPermissionGroup_Url,
  delete_Staff_permission_Url,
  get_Staff_permission_Url,
  set_Staff_permission_Url,
  get_staff_list_Url,
  create_merchat_Url,
  getAllClubList_by_Name_Url,
  getOrgClubById_Url,
  move_to_free_team_player_Url,
  switchPlayerAccess_Url,
  addplayertodivision_Url,
  Org_RemovePoolPlayer_Url,
  delete_fieldmap_message_Url,
  Org_Dms_user_Url,
  getOrgAllInvitation_Url,
  getAllInvitationById_Url,
  Club_Dms_user_Url,
  get_Club_Notification_Url,
  get_Org_notification,
  getAllClubInvitationById_Url,
  switch_Notification_Account_Url,
  Club_Team_detail_Url,
  changeClubInvitation_Url,
  changeOrgInvitation_Url,
  api_liability,
  ReadNotificationUrl_Url,
  apply_discountcode_org_Url,
  getNotificationCount_Url,
  ReadClubInvitation_Url,
  ReadOrgInvitation_Url,
} from "../../apiSheet";
import {
  CLON_EVENT_ERROR,
  CLON_EVENT_LOADING,
  CLON_EVENT_SUCCESS,
  CREATE_DISCOUNT_CODE_ERROR,
  CREATE_DISCOUNT_CODE_LOADING,
  CREATE_DISCOUNT_CODE_SUCCESS,
  CREATE_DIVISION_ERROR,
  CREATE_DIVISION_LOADING,
  CREATE_DIVISION_SUCCESS,
  CREATE_POOL_ERROR,
  CREATE_POOL_LOADING,
  CREATE_POOL_SUCCESS,
  CREATE_TEAM_ERROR,
  CREATE_TEAM_LOADING,
  CREATE_TEAM_SUCCESS,
  DELETE_EVENT_ERROR,
  DELETE_EVENT_LOADING,
  DELETE_EVENT_SUCCESS,
  EDIT_ACCOUNT_DETAILS_ERROR,
  EDIT_ACCOUNT_DETAILS_LOADING,
  EDIT_ACCOUNT_DETAILS_SUCCESS,
  EDIT_DISCOUNT_CODE_ERROR,
  EDIT_DISCOUNT_CODE_LOADING,
  EDIT_DISCOUNT_CODE_SUCCESS,
  EDIT_DIVISION_ERROR,
  EDIT_DIVISION_LOADING,
  EDIT_DIVISION_SUCCESS,
  EDIT_POOL_ERROR,
  EDIT_POOL_LOADING,
  EDIT_POOL_SUCCESS,
  EDIT_SEASON_ERROR,
  EVENT_DETAILS,
  GET_ACCOUNT_DETAILS_ERROR,
  GET_ACCOUNT_DETAILS_LOADING,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_ALLCLUB_ERROR,
  GET_ALLCLUB_LOADING,
  GET_ALLCLUB_SUCCESS,
  GET_ALLDIVISION_ERROR,
  GET_ALLDIVISION_LOADING,
  GET_ALLDIVISION_SUCCESS,
  GET_EVENTSCHEDULE_ERROR,
  GET_EVENTSCHEDULE_LOADING,
  GET_EVENTSCHEDULE_SUCCESS,
  GET_EVENTSTAFF_ERROR,
  GET_EVENTSTAFF_SUCCESS,
  GET_EVENTSTAFF_LOADING,
  SIGNUP_ERROR,
  SIGNUP_LOADING,
  SIGNUP_SUCCESS,
  DELETE_SCHEDULE_ERROR,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_LOADING,
  GET_FREETEAM_LOADING,
  GET_FREETEAM_SUCCESS,
  GET_FREETEAM_ERROR,
  GET_EVENT_TEAM_LOADING,
  GET_EVENT_TEAM_SUCCESS,
  GET_EVENT_TEAM_ERROR,
  UPDATE_FREETEAM_LOADING,
  UPDATE_FREETEAM_SUCCESS,
  UPDATE_FREETEAM_ERROR,
  DELETE_EVENTTEAM_LOADING,
  DELETE_EVENTTEAM_SUCCESS,
  DELETE_EVENTTEAM_ERROR,
  GET_SPORT_LOADING,
  GET_SPORT_SUCCESS,
  GET_SPORT_ERROR,
  BOOKMARK_EVENT_LOADING,
  BOOKMARK_EVENT_SUCCESS,
  BOOKMARK_EVENT_ERROR,
  DELETE_SEASON_LOADING,
  DELETE_SEASON_SUCCESS,
  DELETE_SEASON_ERROR,
  DELETE_POOL_LOADING,
  DELETE_POOL_SUCCESS,
  DELETE_POOL_ERROR,
  DELETE_DIVISION_SUCCESS,
  DELETE_DIVISION_LOADING,
  DELETE_DIVISION_ERROR,
  GET_ORG_CLUB_ERROR,
  GET_ORG_CLUB_SUCCESS,
  GET_ORG_CLUB_LOADING,
  GET_PAYMENTCODE_LOADING,
  GET_PAYMENTCODE_SUCCESS,
  GET_PAYMENTCODE_ERROR,
  CREATE_LIABILITY_LOADING,
  CREATE_LIABILITY_SUCCESS,
  CREATE_LIABILITY_ERROR,
  CREATE_NEW_GROUP_ERROR,
  CREATE_NEW_GROUP_SUCCESS,
  CREATE_NEW_GROUP_LOADING,
  GET_ALL_GROUPS_LOADING,
  GET_ALL_GROUPS_SUCCESS,
  GET_ALL_GROUPS_ERROR,
  ADD_STAFF_GROUP_ERROR,
  ADD_STAFF_GROUP_SUCCESS,
  ADD_STAFF_GROUP_LOADING,
  PERMISSION_LOADING,
  PERMISSION_SUCCESS,
  PERMISSION_ERROR,
  GET_PERMISSION_DETAILS_LOADING,
  GET_PERMISSION_DETAILS_SUCCESS,
  GET_PERMISSION_DETAILS_ERROR,
  GET_EVENT_BY_ID_LOADING,
  GET_EVENT_BY_ID_ERROR,
  GET_EVENT_BY_ID_SUCCESS,
  TEAM_ARRAY_ID,
  SIGNOUT_LOADING,
  SIGNOUT_ERROR,
  SIGNOUT_SUCCESS,
  GET_EVENTS_REGISTER_TEAM_LOADING,
  GET_EVENTS_REGISTER_TEAM_SUCCESS,
  GET_EVENTS_REGISTER_TEAM_ERROR,
  GET_PENDING_AMOUNT_LOADING,
  GET_PENDING_AMOUNT_SUCCESS,
  GET_PENDING_AMOUNT_ERROR,
  PAYMENT_REQUEST_ERROR,
  PAYMENT_REQUEST_SUCCESS,
  PAYMENT_REQUEST_LOADING,
  GET_PAYMENTCODE1_LOADING,
  GET_PAYMENTCODE1_SUCCESS,
  GET_PAYMENTCODE1_ERROR,
  GET_PAYMENT_REQUEST_LOADING,
  GET_PAYMENT_REQUEST_SUCCESS,
  GET_PAYMENT_REQUEST_ERROR,
  GET_LIABILITY_LOADING,
  GET_LIABILITY_SUCCESS,
  GET_LIABILITY_ERROR,
  GET_PLAYER_ACCEPT_LIST_LOADING,
  GET_PLAYER_ACCEPT_LIST_SUCCESS,
  GET_PLAYER_ACCEPT_LIST_ERROR,
  GET_TEAM_GROUPS_ERROR,
  GET_TEAM_GROUPS_SUCCESS,
  GET_TEAM_GROUPS_LOADING,
  ADD_PLAYER_TO_GROUP_SUCCESS,
  ADD_PLAYER_TO_GROUP_LOADING,
  REMOVE_PLAYER_LOADING,
  REMOVE_PLAYER_SUCCESS,
  REMOVE_PLAYER_ERROR,
  EDIT_GROUP_LOADING,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_ERROR,
  DELETE_GROUP_ERROR,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_LOADING,
  GET_PAID_RECIPIENT_SUCCESS,
  GET_PAID_RECIPIENT_ERROR,
  GET_PAID_RECIPIENT_LOADING,
  CREATE_CREDIT_ERROR,
  CREATE_CREDIT_SUCCESS,
  CREATE_CREDIT_LOADING,
  GET_CREDIT_LOADING,
  GET_CREDIT_SUCCESS,
  GET_CREDIT_ERROR,
  GET_REFUND_SUCCESS,
  GET_REFUND_LOADING,
  GET_REFERENCE_NUMBER_ERROR,
  GET_REFERENCE_NUMBER_SUCCESS,
  GET_REFERENCE_NUMBER_LOADING,
  GET_REFUNDS_LOADING,
  GET_REFUNDS_SUCCESS,
  GET_REFUNDS_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_LOADING,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_LOADING,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  GET_ORG_CLUB_DETAILS_ERROR,
  GET_ORG_CLUB_DETAILS_SUCCESS,
  GET_ORG_CLUB_DETAILS_LOADING,
  GET_ORG_CLUB_TEAM_DETAILS_ERROR,
  GET_ORG_CLUB_TEAM_DETAILS_SUCCESS,
  SEND_IMAGE_ERROR,
  SEND_IMAGE_SUCCESS,
  SEND_IMAGE_LOADING,
  LEAGUE_BOOKMARK_LOADING,
  LEAGUE_BOOKMARK_SUCCESS,
  LEAGUE_BOOKMARK_ERROR,
  GET_OUTSTANDING_REPORT_LOADING,
  GET_OUTSTANDING_REPORT_SUCCESS,
  GET_OUTSTANDING_REPORT_ERROR,
  GET_REFUND_REPORT_LOADING,
  GET_REFUND_REPORT_SUCCESS,
  GET_REFUND_REPORT_ERROR,
  GET_RECEIVE_REPORT_LOADING,
  GET_RECEIVE_REPORT_SUCCESS,
  GET_RECEIVE_REPORT_ERROR,
  GET_DASHBOARD_PAYMENT_LOADING,
  GET_DASHBOARD_PAYMENT_SUCCESS,
  GET_DASHBOARD_PAYMENT_ERROR,
  GET_ALL_REPORT_LOADING,
  GET_ALL_REPORT_ERROR,
  GET_ALL_REPORT_SUCCESS,
  GET_ORG_STAFF_LIST_ERROR,
  GET_ORG_STAFF_LIST_SUCCESS,
  GET_ORG_STAFF_LIST_LOADING,
  GET_EVENT_DETAILS_LOADING,
  GET_EVENT_DETAILS_SUCCESS,
  GET_EVENT_DETAILS_ERROR,
  RESET_STORE,
  SEND_INVITATION_EMAIL_SUCCESS,
  SEND_INVITATION_EMAIL_ERROR,
  SEND_INVITATION_EMAIL_LOADING,
  GET_SINGLE_CHAT_LOADING,
  GET_SINGLE_CHAT_SUCCESS,
  GET_SINGLE_CHAT_ERROR,
  SEND_BROADCAST_LOADING,
  SEND_BROADCAST_SUCCESS,
  SEND_BROADCAST_ERROR,
  PUBLISH_SCHEDULE_ERROR,
  PUBLISH_SCHEDULE_SUCCESS,
  PUBLISH_SCHEDULE_LOADING,
  EMAIL_VERIFICATION,
  GET_ADMIN_LIST_LOADING,
  GET_ADMIN_ERROR,
  GET_ADMIN_SUCCESS,
  BULK_DATA_SUCCESS,
  BULK_DATA_ERROR,
  BULK_DATA_LOADING,
  REMINDER_LOADING,
  REMINDER_SUCCESS,
  REMINDER_ERROR,
  REMOVE_POOLTEAM_LOADING,
  REMOVE_POOLTEAM_SUCCESS,
  REMOVE_POOLTEAM_ERROR,
  DIVISIONCUSTOMPAYMENTCODE_SUCCESS,
  DIVISIONCUSTOMPAYMENTCODE_ERROR,
  DIVISIONCUSTOMPAYMENTCODE_LOADING,
  FINDUSER_LOADING,
  FINDUSER_SUCCESS,
  FINDUSER_ERROR,
  FINDUSER_BYEMAIL_LOADING,
  GET_EVENTACTIVITYLOG_LOADING,
  GET_EVENTACTIVITYLOG_SUCCESS,
  GET_EVENTACTIVITYLOG_ERROR,
  REMOVE_ACCOUNT_LOADING,
  REMOVE_ACCOUNT_SUCCESS,
  REMOVE_ACCOUNT_ERROR,
  GET_TEAM_DETAIL_LOADING,
  GET_TEAM_DETAIL_SUCCESS,
  GET_TEAM_DETAIL_ERROR,
  CREATE_ORG_TEAM_LOADING,
  CREATE_ORG_TEAM_SUCCESS,
  CREATE_ORG_TEAM_ERROR,
  GET_ORG_ALL_SEASON_LOADING,
  GET_ORG_ALL_SEASON_SUCCESS,
  GET_ORG_ALL_SEASON_ERROR,
  ACCOUNTING_LOADING,
  ACCOUNTING_SUCCESS,
  ACCOUNTING_ERROR,
  GET_TEAM_EVENT_LOADING,
  GET_TEAM_EVENT_SUCCESS,
  GET_TEAM_EVENT_ERROR,
  REMOVE_ROSTER_LOADING,
  REMOVE_ROSTER_SUCCESS,
  REMOVE_ROSTER_ERROR,
  GET_MERCHANT_ORGANIZER_LOADING,
  GET_MERCHANT_ORGANIZER_SUCCESS,
  GET_MERCHANT_ORGANIZER_ERROR,
  GET_MERCHANT_STATUS_LOADING,
  GET_MERCHANT_STATUS_SUCCESS,
  GET_MERCHANT_STATUS_ERROR,
  GET_SIGNED_WAIVER_LOADING,
  GET_SIGNED_WAIVER_SUCCESS,
  GET_SIGNED_WAIVER_ERROR,
  GET_LIABILITY_TERMS_LOADING,
  GET_LIABILITY_TERMS_SUCCESS,
  GET_LIABILITY_TERMS_ERROR,
  CREATE_ORG_ROSTER_LOADING,
  CREATE_ORG_ROSTER_SUCCESS,
  CREATE_ORG_ROSTER_ERROR,
  EVENT_REGISTRATION_LOADING,
  EVENT_REGISTRATION_SUCCESS,
  EVENT_REGISTRATION_ERROR,
  REQ_ADD_PAYMENT_LOADING,
  REQ_ADD_PAYMENT_SUCCESS,
  REQ_ADD_PAYMENT_ERROR,
  GET_CLUB_ORGANIZER_REFUND_LOADING,
  GET_CLUB_ORGANIZER_REFUND_SUCCESS,
  GET_CLUB_ORGANIZER_REFUND_ERROR,
  REMOVE_PLAYER_ROSTER_LOADING,
  REMOVE_PLAYER_ROSTER_SUCCESS,
  REMOVE_PLAYER_ROSTER_ERROR,
  ORG_PLAYER_DETAILS_LOADING,
  ORG_PLAYER_DETAILS_SUCCESS,
  ORG_PLAYER_DETAILS_ERROR,
  ORG_CLUB_DISCOUNT_LOADING,
  ORG_CLUB_DISCOUNT_SUCCESS,
  ORG_CLUB_DISCOUNT_ERROR,
  ORG_CLUB_CREDIT_LOADING,
  ORG_CLUB_CREDIT_SUCCESS,
  ORG_CLUB_CREDIT_ERROR,
  ORG_GET_ALL_CLUB_LOADING,
  ORG_GET_ALL_CLUB_SUCCESS,
  ORG_GET_ALL_CLUB_ERROR,
  ORG_BALANCE_DUE_LOADING,
  ORG_BALANCE_DUE_SUCCESS,
  ORG_BALANCE_DUE_ERROR,
  ORG_SWITCH_ACCESS_LOADING,
  ORG_SWITCH_ACCESS_SUCCESS,
  ORG_SWITCH_ACCESS_ERROR,
  EVENT_DIVISION_POOL_LOADING,
  EVENT_DIVISION_POOL_SUCCESS,
  EVENT_DIVISION_POOL_ERROR,
  ORG_ADD_TEAM_TO_DIVISION_LOADING,
  ORG_ADD_TEAM_TO_DIVISION_SUCCESS,
  ORG_ADD_TEAM_TO_DIVISION_ERROR,
  UPDATE_SCHEDULE_LOADING,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_ERROR,
  CREATE_FIELD_MAP_LOADING,
  CREATE_FIELD_MAP_SUCCESS,
  CREATE_FIELD_MAP_ERROR,
  VIEW_FIELD_MAP_LOADING,
  VIEW_FIELD_MAP_SUCCESS,
  VIEW_FIELD_MAP_ERROR,
  UPDATE_FIELD_MAP_LOADING,
  UPDATE_FIELD_MAP_SUCCESS,
  UPDATE_FIELD_MAP_ERROR,
  CLEAR_ORG_NOTIFICATIONS_LOADING,
  CLEAR_ORG_NOTIFICATIONS_SUCCESS,
  CLEAR_ORG_NOTIFICATIONS_ERROR,
  CREATE_SCHEDULE_MESSAGE_LOADING,
  CREATE_SCHEDULE_MESSAGE_SUCCESS,
  CREATE_SCHEDULE_MESSAGE_ERROR,
  ORG_CREATE_FIELDMAP_LOADING,
  ORG_CREATE_FIELDMAP_SUCCESS,
  ORG_CREATE_FIELDMAP_ERROR,
  CREATE_FIELDMAP_MESSAGE_LOADING,
  CREATE_FIELDMAP_MESSAGE_SUCCESS,
  CREATE_FIELDMAP_MESSAGE_ERROR,
  VIEW_STAY_AND_PLAY_LOADING,
  VIEW_STAY_AND_PLAY_SUCCESS,
  VIEW_STAY_AND_PLAY_ERROR,
  CREATE_STAY_AND_PLAY_LOADING,
  CREATE_STAY_AND_PLAY_SUCCESS,
  CREATE_STAY_AND_PLAY_ERROR,
  UPDATE_STAY_AND_PLAY_LOADING,
  UPDATE_STAY_AND_PLAY_SUCCESS,
  UPDATE_STAY_AND_PLAY_ERROR,
  DELETE_STAY_AND_PLAY_LOADING,
  DELETE_STAY_AND_PLAY_SUCCESS,
  DELETE_STAY_AND_PLAY_ERROR,
  CREATE_STAY_AND_PLAY_MESSAGE_LOADING,
  CREATE_STAY_AND_PLAY_MESSAGE_SUCCESS,
  CREATE_STAY_AND_PLAY_MESSAGE_ERROR,
  GET_ADMIN_PERMISSION_LIST_SUCCESS,
  GET_ADMIN_PERMISSION_LIST_ERROR,
  CREATE_ADMIN_PERMISSION_GROUP_SUCCESS,
  CREATE_ADMIN_PERMISSION_GROUP_ERROR,
  DELETE_ADMIN_PERMISSION_GROUP_SUCCESS,
  DELETE_ADMIN_PERMISSION_GROUP_ERROR,
  GET_ADMIN_LIST_SUCCESS,
  GET_ADMIN_LIST_ERROR,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  INVAITE_ADMIN_SUCCESS,
  INVAITE_ADMIN_ERROR,
  GET_STAFF_PERMISSION_LIST_SUCCESS,
  GET_STAFF_PERMISSION_LIST_ERROR,
  CREATE_STAFF_PERMISSION_GROUP_SUCCESS,
  CREATE_STAFF_PERMISSION_GROUP_ERROR,
  DELETE_STAFF_PERMISSION_GROUP_ERROR,
  DELETE_STAFF_PERMISSION_GROUP_SUCCESS,
  GET_STAFF_PERMISSION_SUCCESS,
  GET_STAFF_PERMISSION_ERROR,
  SET_STAFF_PERMISSION_SUCCESS,
  SET_STAFF_PERMISSION_ERROR,
  GET_STAFF_LIST_SUCCESS,
  GET_STAFF_LIST_ERROR,
  CREATE_MERCHAT_ACCOUNT_ERROR,
  CREATE_MERCHAT_ACCOUNT_SUCCESS,
  GET_CLUB_LIST_FIND_BY_NAME_SUCCESS,
  GET_CLUB_LIST_FIND_BY_NAME_ERROR,
  ORG_CLUB_BY_ID_LOADING,
  ORG_CLUB_BY_ID_SUCCESS,
  ORG_CLUB_BY_ID_ERROR,
  MOVE_TO_FREE_TEAM_PLAYER_SUCCESS,
  MOVE_TO_FREE_TEAM_PLAYER_ERROR,
  ORG_SWITCH_ACCESS_PLAYER_SUCCESS,
  ORG_SWITCH_ACCESS_PLAYER_ERROR,
  ORG_SWITCH_ACCESS_PLAYER_LOADING,
  ORG_ADD_PLAYER_TO_DIVISION_LOADING,
  ORG_ADD_PLAYER_TO_DIVISION_SUCCESS,
  ORG_ADD_PLAYER_TO_DIVISION_ERROR,
  REMOVE_POOL_PLAYER_LOADING,
  REMOVE_POOL_PLAYER_SUCCESS,
  REMOVE_POOL_PLAYER_ERROR,
  INVITE_CLUB_ORG_LOADING,
  INVITE_CLUB_ORG_SUCCESS,
  INVITE_CLUB_ORG_ERROR,
  DELETE_FIELD_MAP_LOADING,
  DELETE_FIELD_MAP_SUCCESS,
  DELETE_FIELD_MAP_ERROR,
  GET_ORG_CONNECTED_USER_SUCCESS,
  GET_ORG_CONNECTED_USER_ERROR,
  GET_ALL_INVITATION_LOADING,
  GET_ALL_INVITATION_SUCCESS,
  GET_ALL_INVITATION_ERROR,
  SWITCH_NOTIFICATION_USER_LOADING,
  SWITCH_NOTIFICATION_USER_SUCCESS,
  SWITCH_NOTIFICATION_USER_ERROR,
  GET_NOTIFICATION_LOADING,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_ERROR,
  CHANGE_INVITATION_STATUS_LOADING,
  CHANGE_INVITATION_STATUS_SUCCESS,
  CHANGE_INVITATION_STATUS_ERROR,
  TERMS_LOADING,
  TERMS_SUCCESS,
  TERMS_ERROR,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_ERROR,
  REGISTER_SELECTED_TEAM_LOADING,
  REGISTER_SELECTED_TEAM_SUCCESS,
  REGISTER_SELECTED_TEAM_ERROR,
  GET_PAYMENT_METHOD_LOAING,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_ERROR,
  DISCOUNT_CODE_APPLY_LOADING,
  DISCOUNT_CODE_APPLY_SUCCESS,
  DISCOUNT_CODE_APPLY_ERROR,
  NOTIFICATION_COUNT_SUCCESS,
  NOTIFICATION_COUNT_ERROR,
  READ_INVITATION_SUCCESS,
  READ_INVITATION_ERROR,
} from "../constants";
import { history } from "../../helpers/history";
import { SIGNIN_ERROR, SIGNIN_LOADING, SIGNIN_SUCCESS } from "../constants";
import {
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_ERROR,
  CREATE_EVENT_LOADING,
} from "../constants";
import {
  CREATE_PAYMENT_CODE_LOADING,
  CREATE_PAYMENT_CODE_SUCCESS,
  CREATE_PAYMENT_CODE_ERROR,
} from "../constants";
import {
  GET_PAYMENT_CODE_SUCCESS,
  GET_PAYMENT_CODE_ERROR,
  GET_PAYMENT_CODE_LOADING,
} from "../constants";
import {
  EDIT_PAYMENT_CODE_SUCCESS,
  EDIT_PAYMENT_CODE_ERROR,
  EDIT_PAYMENT_CODE_LOADING,
} from "../constants";
import {
  EDIT_EVENT_DATA_LOADING,
  EDIT_EVENT_DATA_SUCCESS,
  EDIT_EVENT_DATA_ERROR,
} from "../constants";
import {
  GET_ALLEVENT_ERROR,
  GET_ALLEVENT_SUCCESS,
  GET_ALLEVENT_LOADING,
} from "../constants";
import { SWITCH_ERROR, SWITCH_LOADING, SWITCH_SUCCESS } from "../constants";
import {
  GET_ALLUSER_ROLL_LOADING,
  GET_ALLUSER_ROLL_SUCCESS,
  GET_ALLUSER_ROLL_ERROR,
} from "../constants";
import {
  ADD_ACCOUNT_ERROR,
  ADD_ACCOUNT_SUCCESS,
  ADD_ACCOUNT_LOADING,
} from "../constants";
import {
  GET_ALL_SEASON_LOADING,
  GET_ALL_SEASON_ERROR,
  GET_ALL_SEASON_SUCCESS,
} from "../constants";
import {
  CREATE_SEASON_LOADING,
  CREATE_SEASON_ERROR,
  CREATE_SEASON_SUCCESS,
} from "../constants";
import {
  EDIT_SEASON_LOADING,
  EDIT_SEASON_SUCCESS,
  _EDIT_SEASON_ERROR,
} from "../constants";
import {
  GET_INVITE_DATA_ERROR,
  GET_INVITE_DATA_SUCCESS,
  GET_INVITE_DATA_LOADING,
} from "../constants";
import {
  SIGNUP_BY_MAIL_LOADING,
  SIGNUP_BY_MAIL_SUCCESS,
  SIGNUP_BY_MAIL_ERROR,
} from "../constants";
import {
  GET_STATE_ERROR,
  GET_STATE_LOADING,
  GET_STATE_SUCCESS,
} from "../constants";
import {
  GET_CITY_ERROR,
  GET_CITY_LOADING,
  GET_CITY_SUCCESS,
} from "../constants";
import {
  GET_DISCOUNT_CODE_LOADING,
  GET_DISCOUNT_CODE_SUCCESS,
  GET_DISCOUNT_CODE_ERROR,
} from "../constants";
import { EVENT_DETAIL } from "../constants";
import { REMOVE_SELECTED_EVENT } from "../constants";
import { ADD_PLAYER_TO_GROUP_ERROR } from "../constants";
import { GET_REFUND_ERROR } from "../constants";
import { staffInvite_By_Mail } from "../../apiSheet";
import { data } from "jquery";

const acctype = localStorage.getItem("type");

// Sign Up Action

export const resetReduxStore = (user) => {
  return (dispatch) => {
    dispatch({
      type: RESET_STORE,
    });
  };
};

export const register = (user) => {
  return (dispatch) => {
    dispatch({
      type: SIGNUP_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // method: "post",
      url: signInUrl,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: SIGNUP_SUCCESS,
          response: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: SIGNUP_ERROR,
            netError: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: SIGNUP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// Sign In Action [POST]
//Reducer:signinReducer

export const signin = (user) => {
  // alert("enter Action")
  // alert("action")
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    url: signInUserUrl,
    data: user,
  };
  return (dispatch) => {
    // alert("action loading")
    dispatch({
      type: SIGNIN_LOADING,
    });
    axios(requestOptions)
      .then((resp) => {
        let msg = resp.data.message;
        let status = resp.data.data.is_organizer;
        let club = resp.data.data.is_club;
        let auth = resp.data.status;
        // let org_name = resp.data.data.organizer[0].name
        // let organizer_id = resp.data.data.organizer[0]._id

        localStorage.setItem("token", resp.data.token);

        dispatch({
          type: SIGNIN_SUCCESS,
          data: resp.user,
          info: resp.data,
          msg: msg,
          status: status,
          club: club,
          auth: auth,
          // org_name: org_name,
          // organizer_id: organizer_id,
          msg: resp.data,
        });
      })
      .catch((error) => {
        // alert("action err")
        let data = error.response;

        if (!error.response) {
          dispatch({
            type: SIGNIN_ERROR,
            error: error,
          });
        }

        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: SIGNIN_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 500) {
          let validationError = data.data.error;
          dispatch({
            type: SIGNIN_ERROR,
            errors: validationError,
          });
        }
        if (data?.status === 401) {
          let datas = error.response;
          dispatch({
            type: EMAIL_VERIFICATION,
            errors: datas,
          });
        }
      });
  };
};

//signOut
//Reducer : sighoutReducer
export const signout = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: SIGNOUT_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: signout_Url,
    };

    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: SIGNOUT_SUCCESS,
          payload: resp.data.data,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: SIGNOUT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//get invite user data by invitation_id [POST]
//Reducer: getInviteDataReducer
export const getInvitationData = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_INVITE_DATA_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      url: getInvitationData_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_INVITE_DATA_SUCCESS,
          payload: resp.user,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_INVITE_DATA_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//post invite user data(name,password,id) [POST]
//Reducer: signupByMailReducer
export const signupByMail = (user) => {
  return (dispatch) => {
    dispatch({
      type: SIGNUP_BY_MAIL_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      url: signupByMail_Url,
      data: user,
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: SIGNUP_BY_MAIL_SUCCESS,
          message: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: SIGNUP_BY_MAIL_ERROR,
            error: validationError,
          });
        }
      });
  };
};

//post invite user data(name,password,id) [POST]
//Reducer: signupByMailReducer
export const sendInvitationBymail = (user) => {
  return (dispatch) => {
    dispatch({
      type: SEND_INVITATION_EMAIL_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: staffInvite_By_Mail,
      data: user,
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: SEND_INVITATION_EMAIL_SUCCESS,
          message: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: SEND_INVITATION_EMAIL_ERROR,
            error: validationError,
          });
        }
      });
  };
};

//Add Account
//Reducer : addAcountReducer
export const addAccount = (user) => {
  return (dispatch) => {
    // alert("add load act")
    dispatch({
      type: ADD_ACCOUNT_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: add_account_Url,
      data: user,
    };

    axios(requestOptions)
      .then((resp) => {
        // alert("add success act")
        localStorage.setItem("token", resp.data.token);

        dispatch({
          type: ADD_ACCOUNT_SUCCESS,
          msg: resp.data,
          // payload: resp.data,
          // data: resp.user
        });
      })
      .catch((error) => {
        // alert("add err act")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: ADD_ACCOUNT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getAllUserRoll = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ALLUSER_ROLL_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getUserRoll_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_ALLUSER_ROLL_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALLUSER_ROLL_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//switch account [post]
//Reducer:switchAccountReducer
export const switchAccountData = (user) => {
  return (dispatch) => {
    // alert("switch load act")
    dispatch({
      type: SWITCH_LOADING,
    });
    const notificationToken = localStorage.getItem("notificationToken");
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
        // Authorization: `Bearer ${localStorage.getItem("notificationToken")}`,
      },
      url: switch_Account_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        localStorage.setItem("token", resp.data.token);
        localStorage.removeItem("notificationToken");
        dispatch({
          type: SWITCH_SUCCESS,
          payload: resp.data.token,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: SWITCH_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const switchAccountDataForNotification = (user) => {
  return (dispatch) => {
    const notificationToken = localStorage.getItem("notificationToken");
    dispatch({
      type: SWITCH_NOTIFICATION_USER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: switch_Notification_Account_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        localStorage.setItem("notificationToken", resp.data.token);
        dispatch({
          type: SWITCH_NOTIFICATION_USER_SUCCESS,
          payload: resp.data.token,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: SWITCH_NOTIFICATION_USER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create Evenet action[POST]
//Reducer: createEventReducer

export const saveEventData = (user) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_EVENT_LOADING,
    });
    // const formData = new FormData()
    // formData.append("event_image", user.event_image)
    const acctype = localStorage.getItem("type");
    const url1 = createEventUrl;
    const url2 = create_Club_Event_Url;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_EVENT_SUCCESS,
          user: resp.data,
          event_id: resp.data.event_id,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_EVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Send Event image [POST]
//Reducer: sendImageReducer

export const sendImage = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: SEND_IMAGE_LOADING,
    });

    const formData = new FormData();
    formData.append("event_image", user.event_image);
    formData.append("event_id", user.event_id);

    const acctype = localStorage.getItem("type");
    const url1 = Event_Image_Upload_Url;
    const url2 = Club_Event_Image_Upload_Url;
    const requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // "Content-Type": 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: formData,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: SEND_IMAGE_SUCCESS,
          user: resp.data,
          event_id: resp.data.event_id,
          msg: resp.data,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: SEND_IMAGE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};


export const createPaymentCode = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_PAYMENT_CODE_LOADING,
    });

    const acctype = localStorage.getItem("type");
    const url1 = createPaymentCode_Url;
    const url2 = Club_Payment_Url;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_PAYMENT_CODE_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_PAYMENT_CODE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//getPayment code [get] for create event payment code listing bt event type
//Reducer:getPaymentCodeReducer

export const getPaymentCode = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PAYMENTCODE_LOADING,
    });
    const id = user.id;
    const acctype = localStorage.getItem("type");
    const url1 = createPaymentCode_Url + `/${id}`;
    const url2 = Club_Payment_Url + `/${id}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };

    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_PAYMENTCODE_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PAYMENTCODE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
//getPayment code [get] for create event payment code listing bt event type
//Reducer:getPaymentCodeReducer

export const getPaymentCode1 = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PAYMENTCODE1_LOADING,
    });
    const id = user.id;
    const acctype = localStorage.getItem("type");
    const url1 = createPaymentCode_Url + `/${id}`;
    const url2 = Club_Payment_Url + `/${id}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };

    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_PAYMENTCODE1_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PAYMENTCODE1_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get All Payment Code [GET]
//Reducer: getAllPaymentCodeReducer

export const getAllPaymentCode = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PAYMENT_CODE_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const url1 = getAllpaymentCode_Url;
    const url2 = Get_Club_Payment_Url;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };

    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_PAYMENT_CODE_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PAYMENT_CODE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Edit Payment Code [PUT]
//Reducer: editPaymentCodeReducer

export const editPaymentCode = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: EDIT_PAYMENT_CODE_LOADING,
    });
    const id = user.id;

    const acctype = localStorage.getItem("type");
    const url1 = createPaymentCode_Url + `/${id}`;
    const url2 = Club_Payment_Url + `/${id}`;

    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: EDIT_PAYMENT_CODE_SUCCESS,
          data: user.data,
          id: user.id,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: EDIT_PAYMENT_CODE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Send selected team in array Component
//Reducer : teamArrayReducer

export const teamArray = (user) => {
  return (dispatch) => {
    dispatch({
      type: TEAM_ARRAY_ID,
      payload: user,
    });
  };
};

//Get All Event[Get]
//Reducer : getAllEventReducer
export const getAllEvent = (user) => {
  return (dispatch) => {
    dispatch({
      type: GET_ALLEVENT_LOADING,
    });
    const id = user.id;
    const acctype = localStorage.getItem("type");
    const url1 = getAllEvent_Url;
    const urla = get_filter_Event_Url + `/week`;
    const urlb = get_filter_Event_Url + `/month`;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // url: acctype == 1 ? url1 : url2
      url: id === 0 ? url1 : id === 1 ? urla : urlb,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_ALLEVENT_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: SIGNIN_ERROR,
            error: error,
          });
        }
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALLEVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
export const getClubFindByName = (user) => {
  return (dispatch) => {
    // const id = user.id;
    // const acctype = localStorage.getItem("type");
    const url1 = getAllClubList_by_Name_Url;
    // const urla = get_filter_Event_Url + `/week`;
    // const urlb = get_filter_Event_Url + `/month`;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // url: id === 0 ? url1 : id === 1 ? urla : urlb,
      url: url1,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_CLUB_LIST_FIND_BY_NAME_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_CLUB_LIST_FIND_BY_NAME_ERROR,
            error: error,
          });
        }
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CLUB_LIST_FIND_BY_NAME_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get All Event[Get]
//Reducer : getAllEventReducer

export const getEventById = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_EVENT_BY_ID_LOADING,
    });
    const id = user.id;
    const acctype = localStorage.getItem("type");
    const url1 = getAllEvent_Url + `/${id}`;
    const url2 = get_Club_Event_Url + `/${id}`;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: GET_EVENT_BY_ID_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_EVENT_BY_ID_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// Call datefilter API
export const eventDateFilter = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_EVENT_BY_ID_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const start_date = data.start_date;
    const end_date = data.end_date;
    const url1 = getEventDateFilter + `/${start_date}/${end_date}`;
    const url2 = clubEventDateFilter + `/${start_date}/${end_date}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: EVENT_DETAIL,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: SIGNIN_ERROR,
            error: error,
          });
        }
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALLEVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Send event Data to /event-details component//first sent to store then use from store
//Reducer: sendEventDetails Reducer
export const eventData = (user) => {
  return (dispatch) => {
    // alert("action")

    dispatch({
      type: EVENT_DETAILS,
      payload: user,
      id: user.id,
      // edituser:editUser
    });
  };
};

//GET EVENT IN EVENT DETAILS COMPONENT
//Reucer:getAll event Reducer //2nd reducer
export const eventDetails = (user) => {
  return (dispatch) => {
    // alert("action")
    dispatch({
      type: EVENT_DETAIL,
      payload: user,
    });
  };
};

//CLEAR STATE OF EVENT IN EVENT DETAIL COMPONENT
export const clearEventDetails = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_SELECTED_EVENT,
    });
  };
};

//Edit Event[PUT]
// Reducer:editEventReducer

export const editEventData = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: EDIT_EVENT_DATA_LOADING,
    });

    const id = user.id;
    const acctype = localStorage.getItem("type");
    const url1 = EditEventData_Url + `/${id}`;
    const url2 = Edit_Club_EventData_Url + `/${id}`;
    // const id = localStorage.getItem("event_id")
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: EDIT_EVENT_DATA_SUCCESS,
          data: user.data,
          id: user.id,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: EDIT_EVENT_DATA_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Club organizer event-details by id, (in  club Event details component)[GET]
//Reducer : getClubOrgEventDertrailsReducer
export const getEventDetails = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_EVENT_DETAILS_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getEventBy_id_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_EVENT_DETAILS_SUCCESS,
          payload: userData,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_EVENT_DETAILS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Listing All season on Season component
//Reducer: getAllSeasonReducer
export const getAllSeason = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ALL_SEASON_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const url1 = EventSeasonOrg_Url;
    const url2 = EventSeasonClub_Url;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_ALL_SEASON_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALL_SEASON_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Delete season [DELETE]
//Reducer: deleteSeasonReducer
export const deleteSeason = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_SEASON_LOADING,
    });
    const id = user;
    const acctype = localStorage.getItem("type");
    const url1 = EventSeasonOrg_Url + `/${id}`;
    const url2 = EventSeasonClub_Url + `/${id}`;

    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_SEASON_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_SEASON_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Edit Season [PUT]
//Reducer: editSeasonReducer

export const editSeason = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: EDIT_SEASON_LOADING,
    });

    // const id = localStorage.getItem("season_id")
    const id = user.id;
    const acctype = localStorage.getItem("type");
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url:
        acctype == 1
          ? EventSeasonOrg_Url + `/${id}`
          : EventSeasonClub_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: EDIT_SEASON_SUCCESS,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: EDIT_SEASON_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create new Season
//Reducer: createSeasonReducer
export const createSeason = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_SEASON_LOADING,
    });

    const acctype = localStorage.getItem("type");
    const url1 = AddEventSeasonOrg_Url;
    const url2 = AddEventSeasonClub_Url;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_SEASON_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_SEASON_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//get allstate
//Reducer: getStateReducer
export const getAllState = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_STATE_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: state_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_STATE_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_STATE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//GET City
//Reducer : getCityReducer
export const getAllCity = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_CITY_LOADING,
    });
    const id = user.id;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // url: city_Url
      // url: `http://192.168.0.33:5555/api/city/${id}`,
      url: city_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_CITY_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CITY_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//GET Sport
//Reducer : getCityReducer
export const getAllSport = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_SPORT_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        // 'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      url: sport_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: GET_SPORT_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_SPORT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get ALl Discount Code [GET]
//Reducer : getAllDiscountCodeReducer

export const getAllDiscountCode = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_DISCOUNT_CODE_LOADING,
    });

    const acctype = localStorage.getItem("type");
    const url1 = getAllDiscountCode_Url;
    const url2 = Club_DiscountCode_Url;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_DISCOUNT_CODE_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_DISCOUNT_CODE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getDiscountCodeById = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_DISCOUNT_CODE_LOADING,
    });

    const id = user.id;

    const acctype = localStorage.getItem("type");
    const url1 = getAllDiscountCode_Url + `/${id}`;
    const url2 = Club_DiscountCode_Url + `/${id}`;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_DISCOUNT_CODE_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_DISCOUNT_CODE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const editDiscountCode = (user) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_DISCOUNT_CODE_LOADING,
    });

    const id = user.id;

    const acctype = localStorage.getItem("type");
    const url1 = createDiscountCode_Url + `/${id}`;
    const url2 = Club_DiscountCode_Url + `/${id}`;

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: EDIT_DISCOUNT_CODE_SUCCESS,
          data: resp.data,
          msg: resp.data,
        });
      })
      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: EDIT_DISCOUNT_CODE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create Discount code [POST]
//Reducer: createDiscountCodeReducer
export const createDiscountCodeAction = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_DISCOUNT_CODE_LOADING,
    });

    const acctype = localStorage.getItem("type");
    const url1 = createDiscountCode_Url;
    const url2 = Club_DiscountCode_Url;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_DISCOUNT_CODE_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_DISCOUNT_CODE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Org Account details [GET]
//Reducer : accountDetailsReducer
export const getAccountDetails = (user) => {
  return (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_DETAILS_LOADING,
    });

    // const id = localStorage.getItem("season_id")
    const acctype = localStorage.getItem("type");
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? Org_Account_Details_Url : Club_Account_Details_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ACCOUNT_DETAILS_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 401) {
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        }

        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ACCOUNT_DETAILS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Edit Org/club Account Details [PUT]
//Reducer= editAccountDetailsReducer

export const editAccountDetails = (user) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_ACCOUNT_DETAILS_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? Org_EditAccount_details_Url : Club_Update_Profile_Url,
      // data: acctype == 1 ? formData : clubData
      data: user,
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: EDIT_ACCOUNT_DETAILS_SUCCESS,
          data: resp.data,
        });
      })
      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: EDIT_ACCOUNT_DETAILS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create Division [POST]
//Reducer: createDivisionReducer
export const createDivision = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_DIVISION_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Org_CreateDivision_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_DIVISION_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_DIVISION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get All Division by event Id [GET]
//Reducer : accountDetailsReducer
export const getAllDivision = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ALLDIVISION_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Org_GetAllDivision_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ALLDIVISION_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALLDIVISION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create Division [POST]
//Reducer: createDivisionReducer
export const createPool = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_POOL_LOADING,
    });

    const acctype = localStorage.getItem("type");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? Org_CreatePool_Url : club_Create_pool,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_POOL_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_POOL_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
//division custom paymentcode
export const divisionCustomPaymentcode = (data) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DIVISIONCUSTOMPAYMENTCODE_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: DivisionCustomPaymentCode_Url,
      data: data,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DIVISIONCUSTOMPAYMENTCODE_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DIVISIONCUSTOMPAYMENTCODE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
export const findUser = (data) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: FINDUSER_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? findUser_Url : clubFindUser_Url,
      data: data,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: FINDUSER_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: FINDUSER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
export const findByEmail = (data) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: FINDUSER_BYEMAIL_LOADING,
    });
    let email = "";
    if (data.email !== "") email = `/${data.email}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: findUserByEmail_Url + "/" + data.type + email,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: FINDUSER_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: FINDUSER_ERROR,
            errors: validationError,
            msg: error.response.data.message,
          });
        }
      });
  };
};

//Edit Pool [PUT]
//Reducer= editPoolReducer
export const editPool = (user) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_POOL_LOADING,
    });
    const id1 = user.id;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url:
        acctype == 1
          ? Org_EditPool_Url + `/${id1}`
          : event_pool_Url + `/${id1}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: EDIT_POOL_SUCCESS,
          data: resp.data,
          msg: resp.data,
        });
      })
      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: EDIT_POOL_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Delete Pool [DELETE]
//Reducer: deletePoolReducer
export const deletePool = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_POOL_LOADING,
    });
    const id = user;
    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url:
        acctype == 1 ? Org_EditPool_Url + `/${id}` : event_pool_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_POOL_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_POOL_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Edit Division [PUT]
//Reducer= editDivisionReducer

export const editDivision = (user) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_DIVISION_LOADING,
    });

    const id1 = user.id1;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Org_EditDivision_Url + `/${id1}`,
      data: user,
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: EDIT_DIVISION_SUCCESS,
          data: resp.data,
          msg: resp.data,
        });
      })
      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: EDIT_DIVISION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Delete Division [DELETE]
//Reducer: deleteDivisionReducer
export const deleteDivision = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_DIVISION_LOADING,
    });
    const id = user;

    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Org_EditDivision_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_DIVISION_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_DIVISION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create Division [POST]
//Reducer: createDivisionReducer
export const createTeam = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_TEAM_LOADING,
    });

    const acctype = localStorage.getItem("type");

    const formData = new FormData();
    formData.append("event_id", user.event_id);
    formData.append("team_name", user.team_name);
    formData.append("division", user.division);
    formData.append("pool", user.pool);
    formData.append("coach_name", user.coach_name);
    formData.append("coach_email", user.coach_email);
    formData.append("coach_mobile", user.coach_mobile);
    formData.append("image", user.image);
    formData.append("club_name", user.club_name);

    // const clubData = new FormData()
    // clubData.append("team_name",user.team_name)
    // clubData.append("division",user.division)
    // clubData.append("image",user.image)
    // clubData.append("season",user.season)
    // clubData.append("gender",user.gender)
    // clubData.append("coach",user.coach)

    const requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? CreateTeam_Url : CreateTeam_club,
      data: acctype == 1 ? formData : user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_TEAM_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create Division [POST]
//Reducer: createDivisionReducer
export const uploadTeamImage = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: SEND_IMAGE_LOADING,
    });

    const acctype = localStorage.getItem("type");

    const id = user.id;

    const type = user.same;

    const formData = new FormData();

    formData.append("image", user.image);

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: club_team_Image_Url + `/${id}`,
      data: formData,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: SEND_IMAGE_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: SEND_IMAGE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get ALl Club list, (in +Add club component)[GET]
//Reducer : getAllClubReducer
export const getAllClub = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ALLCLUB_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: GetAllClub_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ALLCLUB_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALLCLUB_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Invite Club [POST]
//Reducer: createTeamReducer
export const inviteClub = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: INVITE_CLUB_ORG_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Invite_Club_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: INVITE_CLUB_ORG_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: INVITE_CLUB_ORG_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Org Club list, (in  club list component)[GET]
//Reducer : getAllClubReducer
export const getOrgClubList = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ORG_CLUB_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Organizer_Club_List_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ORG_CLUB_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ORG_CLUB_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Org Club list, (in  club list component)[GET]
//Reducer : getAllClubReducer
export const getStaff = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ORG_STAFF_LIST_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: OutsideStaff_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ORG_STAFF_LIST_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ORG_STAFF_LIST_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
export const getAdmin = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ADMIN_LIST_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: admin_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_ADMIN_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ADMIN_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Org Club  Teasm Details, (in  club component)[GET]
//Reducer : clubTeamDetailsReducer
export const clubTeamDetails = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ORG_CLUB_TEAM_DETAILS_ERROR,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Org_Club_Team_Details_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ORG_CLUB_TEAM_DETAILS_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ORG_CLUB_TEAM_DETAILS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get ALL Staff, (in +Add Staff component)[GET]
//Reducer : getAllClubReducer
export const getAllStaff = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ALLCLUB_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: GetAllStaff_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ALLCLUB_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALLCLUB_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get All Division by event Id [GET]
//Reducer : accountDetailsReducer
export const getEventStaff = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_EVENTSTAFF_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: GetEventStaff_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_EVENTSTAFF_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_EVENTSTAFF_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getActivityLog = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_EVENTACTIVITYLOG_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: GetActivityLog_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;

        dispatch({
          type: GET_EVENTACTIVITYLOG_SUCCESS,
          payload: userData,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_EVENTACTIVITYLOG_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Invite Club [POST]
//Reducer: createTeamReducer
export const inviteStaff = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_TEAM_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Invite_Staff_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_TEAM_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Delete Event [DELETE]
//Reducer: deleteEventReducer
export const deleteEvent = (user) => {
  // alert("Action loading")

  return (dispatch) => {
    dispatch({
      type: DELETE_EVENT_LOADING,
    });
    const id = user;
    const acctype = localStorage.getItem("type");
    const url1 = EditEventData_Url + `/${id}`;
    const url2 = Edit_Club_EventData_Url + `/${id}`;

    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_EVENT_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_EVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Bookmark Event [POST]
//Reducer: bookmarkReducer

export const bookmark = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: BOOKMARK_EVENT_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: BookMark_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: BOOKMARK_EVENT_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: BOOKMARK_EVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// league Bookmark  [POST]
//Reducer: leagueBookmarkReducer
export const leagueBookmark = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: LEAGUE_BOOKMARK_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: league_bookmark_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: LEAGUE_BOOKMARK_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: LEAGUE_BOOKMARK_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Delete PaymentCode [DELETE]
//Reducer: deleteEventReducer
export const deletePaymentCode = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_EVENT_LOADING,
    });
    const id = user;

    const acctype = localStorage.getItem("type");
    const url1 = createPaymentCode_Url + `/${id}`;
    const url2 = Club_Payment_Url + `/${id}`;
    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_EVENT_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_EVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Delete DiscountCode [DELETE]
//Reducer: deleteEventReducer
export const deleteDiscountCode = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_EVENT_LOADING,
    });
    const id = user;

    const acctype = localStorage.getItem("type");
    const url1 = getAllDiscountCode_Url + `/${id}`;
    const url2 = Club_DiscountCode_Url + `/${id}`;

    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_EVENT_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_EVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Delete schedule [DELETE]
//Reducer: deleteScheduleReducer
export const deleteSchedule = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_SCHEDULE_LOADING,
    });
    const id = user;
    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: deleteSchedule_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_SCHEDULE_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_SCHEDULE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Delete event Staff [DELETE]
//Reducer: deleteEventReducer
export const deleteEventStaff = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_EVENT_LOADING,
    });
    const id = user;
    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Remove_Event_Staff_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_EVENT_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_EVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Org
//Delete Team [DELETE]
//Reducer: deleteEventTeamReducer
export const deleteEventTeam = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_EVENTTEAM_LOADING,
    });
    const id = user.user?.deletedID;
    const type = user.user?.type;
    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Remove_Team_Url + `/${id}/${type}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_EVENTTEAM_SUCCESS,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_EVENTTEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Clon Event [GET]
//Reducer: clonEventReducer
export const clonEvent = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CLON_EVENT_LOADING,
    });
    const id = user;

    const acctype = localStorage.getItem("type");
    const url1 = cloneEvent_Url + `/${id}`;
    const url2 = cloneEvent_Club_Url + `/${id}`;

    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CLON_EVENT_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CLON_EVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Event schedule [POST]
//Reducer: createTeamReducer
export const addSchedule = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_TEAM_LOADING,
    });
    const formData = new FormData();
    formData.append("file", user.file);
    formData.append("type", user.type);
    formData.append("event_id", user.event_id);
    formData.append("text", user.text);
    const requestOptions = {
      method: "POST",
      headers: {
        // "accept": "application/json",
        // "Content-Type": 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Add_Schedule_Url,
      data: formData,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_TEAM_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get All Event Schedule by Id [GET]
//Reducer : accountDetailsReducer
export const getEventSchedule = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_EVENTSCHEDULE_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getAllSchedule_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_EVENTSCHEDULE_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_EVENTSCHEDULE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get All Free teams by Id [GET]
//Reducer : getFreeTeamReducer
export const getFreeTeam = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_FREETEAM_LOADING,
    });

    const id = user.id;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: freeTeam_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_FREETEAM_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_FREETEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Update Free Team-add Division and Pool [PUT]
//Reducer: updateFreeTeam

export const updateFreeTeam = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FREETEAM_LOADING,
    });
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: UpdateFreeTeam_Url + `/${data.team}`,
      data: { division: data.division, pool: data.pool, team: data?.team },
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: UPDATE_FREETEAM_SUCCESS,
          data: data,
          id: data.id,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: UPDATE_FREETEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
export const removePoolTeam = (data) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: REMOVE_POOLTEAM_LOADING,
    });
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Org_RemovePoolTeam_Url + `/${data.team}`,
      data: data,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: REMOVE_POOLTEAM_SUCCESS,
          data: data,
          id: data.id,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: REMOVE_POOLTEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const removePoolPlayer = (data) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: REMOVE_POOL_PLAYER_LOADING,
    });
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Org_RemovePoolPlayer_Url + `/${data.player}`,
      data: data,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: REMOVE_POOL_PLAYER_SUCCESS,
          data: data,
          id: data.id,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: REMOVE_POOL_PLAYER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get All teams by Id [GET]
//Reducer : getEventTeamReducer
export const getEventTeam = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_EVENT_TEAM_LOADING,
    });
    const id = user.id;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Event_Team_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_EVENT_TEAM_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_EVENT_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Org Club Details, (in  club component)[GET]
//Reducer : getOrgClubDetailsReducer
export const getOrgClubDetails = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ORG_CLUB_DETAILS_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Org_Club_Details_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ORG_CLUB_DETAILS_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ORG_CLUB_DETAILS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const InvitePlayerOrg = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_ORG_TEAM_LOADING,
    });
    const acctype = localStorage.getItem("type");
    // const url1 = InvitePlayer_Org_Url
    // const url2 = InvitePlayer_Url

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: InvitePlayer_Org_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_ORG_TEAM_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_ORG_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getOrgPlayerList = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ORG_ALL_SEASON_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Get_Player_List_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_ORG_ALL_SEASON_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ORG_ALL_SEASON_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getTeamDetails = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_TEAM_DETAIL_LOADING,
    });
    const id = user.id;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Team_detail_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_TEAM_DETAIL_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_TEAM_DETAIL_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getClubTeamDetails = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_TEAM_DETAIL_LOADING,
    });
    const id = user.id;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Club_Team_detail_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_TEAM_DETAIL_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_TEAM_DETAIL_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getTeamEvent = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_TEAM_EVENT_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Team_Event_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_TEAM_EVENT_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_TEAM_EVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const removeRoster = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: REMOVE_ROSTER_LOADING,
    });

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: removeTeamRoster_Url + `/${user.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: REMOVE_ROSTER_SUCCESS,
          msg: resp.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: REMOVE_ROSTER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const removePlayerFromRoster = ({ roster_id, player_id }) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: REMOVE_PLAYER_ROSTER_LOADING,
    });

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: removeTeamRoster_Url + `/${roster_id}/${player_id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: REMOVE_PLAYER_ROSTER_SUCCESS,
          msg: resp.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: REMOVE_PLAYER_ROSTER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create liability
//Reducer: createLiabilityReducer
export const createLiability = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_LIABILITY_LOADING,
    });

    // const acctype = localStorage.getItem("type")
    // const url1= AddEventSeasonOrg_Url
    // const url2 = AddEventSeasonClub_Url
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Create_Liability_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_LIABILITY_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_LIABILITY_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create New Group
//Reducer: createNewGroupReducer
export const createNewGroup = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_NEW_GROUP_LOADING,
    });

    // const acctype = localStorage.getItem("type")
    // const url1= AddEventSeasonOrg_Url
    // const url2 = AddEventSeasonClub_Url
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: create_Group_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_NEW_GROUP_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_NEW_GROUP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create Team Group
//Reducer: createNewGroupReducer
export const createTeamGroup = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_NEW_GROUP_LOADING,
    });

    // const acctype = localStorage.getItem("type")
    // const url1= AddEventSeasonOrg_Url
    // const url2 = AddEventSeasonClub_Url
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: createTeamGroup_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_NEW_GROUP_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_NEW_GROUP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get All Groups [GET]
//Reducer : getAllGroupsReducer
export const getAllGroups = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ALL_GROUPS_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Add_staff_In_Group_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ALL_GROUPS_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALL_GROUPS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Add Staff to group[Post]
//Reducer: addStaffToGroupReducer
export const addStaffToGroup = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: ADD_STAFF_GROUP_LOADING,
    });

    // const acctype = localStorage.getItem("type")
    // const url1= AddEventSeasonOrg_Url
    // const url2 = AddEventSeasonClub_Url
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Add_staff_In_Group_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: ADD_STAFF_GROUP_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ADD_STAFF_GROUP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//permission[Post]
//Reducer: permissionReducer
export const permission = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: PERMISSION_LOADING,
    });

    // const acctype = localStorage.getItem("type")
    // const url1= AddEventSeasonOrg_Url
    // const url2 = AddEventSeasonClub_Url
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: change_permission_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: PERMISSION_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: PERMISSION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Permission details [GET]
//Reducer : getPermissionDetailsReducer
export const getPermissionDetails = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PERMISSION_DETAILS_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Permission_Details_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_PERMISSION_DETAILS_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PERMISSION_DETAILS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Recipient (Register team) [GET]
//Reducer : getRecipientReducer
export const getRecipient = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PERMISSION_DETAILS_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getRecipient_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_PERMISSION_DETAILS_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PERMISSION_DETAILS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
//Get Recipient (craete Credit) [GET]
//Reducer : getPaidRecipientReducer
export const getPaidRecipient = (event) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PAID_RECIPIENT_LOADING,
    });
    const url1 = `${get_recipient_Url}${!!event ? `/${event}` : ""}`;
    const url2 = `${get_Club_recipient_Url}${!!event ? `/${event}` : ""}`;
    const acctype = localStorage.getItem("type");

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_PAID_RECIPIENT_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PAID_RECIPIENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//createCredit [Post]
//Reducer: createCreditReducer
export const createCredit = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_CREDIT_LOADING,
    });

    const url1 = create_Credit_Note_Url;
    const url2 = Create_Credit_CLub_Url;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_CREDIT_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_CREDIT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Event (register Team) [GET]
//Reducer : getEventRegisterTeamReducer
export const getEventsRegisterTeam = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_EVENTS_REGISTER_TEAM_LOADING,
    });

    const url1 = get_paid_Event_Url;
    const url2 = get_payement_event_Url;

    const acctype = localStorage.getItem("type");

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_EVENTS_REGISTER_TEAM_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_EVENTS_REGISTER_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get pending Amount  [GET]
//Reducer : getPendingAmountReducer
export const getPendingAmount = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PENDING_AMOUNT_LOADING,
    });

    const id = user.id;
    const id1 = user.id1;
    const id2 = user.id2;

    const url1 = getPendingAmount_Url + `/${id}` + `/${id1}` + `/${id2}`;
    const url2 = getClubPendingAmount_Url + `/${id}` + `/${id1}` + `/${id2}`;
    const acctype = localStorage.getItem("type");

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_PENDING_AMOUNT_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PENDING_AMOUNT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//payment request [Post]
//Reducer: paymentRequestReducer
export const paymentRequest = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: PAYMENT_REQUEST_LOADING,
    });

    const acctype = localStorage.getItem("type");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: payment_request_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: PAYMENT_REQUEST_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: PAYMENT_REQUEST_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// get bulk recipient
export const getBulkRecipientAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: BULK_DATA_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_bulk_recipient__Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: BULK_DATA_SUCCESS,
          data: resp.data,
          msg: resp.data,
        });
      })
      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: BULK_DATA_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
//Get Payment request [GET]
//Reducer : getPaymentRequestReducer
export const getPaymentRequest = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PAYMENT_REQUEST_LOADING,
    });

    const url1 = getPaymentRequest_Url;
    const url2 = getPaymentRequest_Club_Url;
    const acctype = localStorage.getItem("type");

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_PAYMENT_REQUEST_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PAYMENT_REQUEST_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Liability [GET]
//Reducer : getPrintOrgLiabilityReducer
export const getOrgLiability = (user) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIABILITY_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getLiability_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_LIABILITY_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_LIABILITY_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Player accept list in Club [GET]
//Reducer : playerAcceptListReducer
export const getAcceptedPlayer = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PLAYER_ACCEPT_LIST_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getOrgPlayer_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_PLAYER_ACCEPT_LIST_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PLAYER_ACCEPT_LIST_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Reducer : getAccountingreducer
export const getAccounting = (user) => {
  return (dispatch) => {
    dispatch({
      type: ACCOUNTING_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: accounting_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: ACCOUNTING_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: ACCOUNTING_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Team Groups [GET]
//Reducer : getTeamGroupsReducer
export const getTeamGroups = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_TEAM_GROUPS_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: createTeamGroup_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_TEAM_GROUPS_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_TEAM_GROUPS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Add player to group [POST]
//Reducer: addPlayerToGroupReducer
export const addPlayerToGroup = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: ADD_PLAYER_TO_GROUP_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: add_PlayerToGroup_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: ADD_PLAYER_TO_GROUP_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ADD_PLAYER_TO_GROUP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Team Groups [GET]
//Reducer : getTeamGroupsReducer
export const getGroupDetails = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ALL_GROUPS_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Group_Details_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ALL_GROUPS_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALL_GROUPS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Team Groups [GET]
//Reducer : getTeamGroupsReducer
export const getGroupName = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ALL_GROUPS_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Group_Details_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ALL_GROUPS_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALL_GROUPS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Edit  Groups [PUT]
//Reducer : getTeamGroupsReducer
export const editGroupName = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: EDIT_GROUP_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Group_Details_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: EDIT_GROUP_SUCCESS,
          payload: userData,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: EDIT_GROUP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//remove Player from group [DELETE]
//Reducer : removePlayerReducer
export const removePlayerfromGroup = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: REMOVE_PLAYER_LOADING,
    });

    const id = user.id;
    const id1 = user.id1;

    const requestOptions = {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: remove_player_from_group_Url + `/${id1}` + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: REMOVE_PLAYER_SUCCESS,
          payload: userData,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: REMOVE_PLAYER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Delete group [DELETE]
//Reducer : deleteGroupReducer
export const deleteGroup = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_GROUP_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Group_Details_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: DELETE_GROUP_SUCCESS,
          payload: userData,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: DELETE_GROUP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Credit [GET]
//Reducer : getOrgCreditReducer
export const getOrgCredit = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_CREDIT_LOADING,
    });

    const url1 = get_Credit_Url;
    const url2 = Create_Credit_CLub_Url;

    const acctype = localStorage.getItem("type");

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_CREDIT_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CREDIT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//createCredit [Post]
//Reducer: createCreditReducer
export const createRefund = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_CREDIT_LOADING,
    });

    const url1 = create_Refund_Url;
    const url2 = create_Refund_Club_Url;

    const acctype = localStorage.getItem("type");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_CREDIT_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_CREDIT_ERROR,
            errors: validationError,
          });
        } else if (data?.status == 500) {
          let validationError = data.data?.errorMessage;
          dispatch({
            type: CREATE_CREDIT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//getPayment  [Post] in  issue Refund page
//Reducer: getPaymentReducer
export const getPayment = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: GET_REFUND_LOADING,
    });

    const url1 = get_payment_Url;
    const url2 = get_payment_club_Url;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: GET_REFUND_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: GET_REFUND_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Reference number [GET]
//Reducer : getReferenceNumberReducer
export const getReferenceNumber = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_REFERENCE_NUMBER_LOADING,
    });

    const id = user.id;
    const type = user.type;

    const acctype = localStorage.getItem("type");

    const url1 = get_payment_Url + `/${id}` + `/${type}`;
    const url2 = get_payment_Club_Url + `/${id}` + `/${type}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_REFERENCE_NUMBER_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_REFERENCE_NUMBER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Refunds [GET]
//Reducer : getRefundsReducer
export const getRefunds = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_REFUNDS_LOADING,
    });

    const acctype = localStorage.getItem("type");

    const url1 = create_Refund_Url;
    const url2 = create_club_refund_Url;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_REFUNDS_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_REFUNDS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getClubBulkRecipientAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: BULK_DATA_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: club_bulk_recipient_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: BULK_DATA_SUCCESS,
          data: resp.data,
          msg: resp.data,
        });
      })
      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: BULK_DATA_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//forgot email [POST]
//Reducer : forgotpasswordEmailReducer
export const forgotpasswordEmail = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: forgot_password_email_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
          payload: userData,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: FORGOT_PASSWORD_ERROR,
            errors: validationError,
            msg: error.response.data.message,
          });
        }
      });
  };
};

//verify email [POST]
//Reducer : verifyotpReducer
export const verifyotp = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: VERIFY_OTP_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: verify_otp_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: VERIFY_OTP_SUCCESS,
          payload: userData,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: VERIFY_OTP_ERROR,
            errors: validationError,
            msg: error.response.data.message,
          });
        }
      });
  };
};

// Reset password [POST]
//Reducer : resetPasswordReducer
export const resetPassword = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: reset_password_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: userData,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: RESET_PASSWORD_ERROR,
            errors: validationError,
            msg: error.response.data.message,
          });
        }
      });
  };
};

//Change Password [POST]
//Reducer : resetPasswordReducer
export const changePassword = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: change_password_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: userData,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: RESET_PASSWORD_ERROR,
            errors: validationError,
            msg: error.response.data.message,
          });
        }
      });
  };
};

//report = Receive payment [GET]
//Reducer : getReceiveReportReducer
export const getReceiveReport = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_RECEIVE_REPORT_LOADING,
    });

    const acctype = localStorage.getItem("type");

    const id = user.id;
    const url1 = getReceiveRepoet_Url;
    const url2 = getReceiveRepoet_Url + `/week`;
    const url3 = getReceiveRepoet_Url + `/month`;
    const url4 = getReceiveRepoet_Url + `/quarterly`;

    const urlC1 = getClubReceiveRepoet_Url;
    const urlC2 = getClubReceiveRepoet_Url + `/week`;
    const urlC3 = getClubReceiveRepoet_Url + `/month`;
    const urlC4 = getClubReceiveRepoet_Url + `/quarterly`;

    const url =
      id === 0
        ? url1
        : id === 1
        ? url2
        : id === 2
        ? url3
        : id === 3
        ? url4
        : "";
    const urlC =
      id === 0
        ? urlC1
        : id === 1
        ? urlC2
        : id === 2
        ? urlC3
        : id === 3
        ? urlC4
        : "";

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url : urlC,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_RECEIVE_REPORT_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_RECEIVE_REPORT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//report = OutStanding payment [GET]
//Reducer : getOutstandingReportReducer
export const getOutstandingReport = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_OUTSTANDING_REPORT_LOADING,
    });
    const acctype = localStorage.getItem("type");

    const id = user.id;
    const url1 = getOutStandingReport_Url;
    const url2 = getOutStandingReport_Url + `/week`;
    const url3 = getOutStandingReport_Url + `/month`;
    const url4 = getOutStandingReport_Url + `/quarterly`;

    const urlC1 = getClubOutStandingReport_Url;
    const urlC2 = getClubOutStandingReport_Url + `/week`;
    const urlC3 = getClubOutStandingReport_Url + `/month`;
    const urlC4 = getClubOutStandingReport_Url + `/quarterly`;

    const url =
      id === 0
        ? url1
        : id === 1
        ? url2
        : id === 2
        ? url3
        : id === 3
        ? url4
        : "";
    const urlC =
      id === 0
        ? urlC1
        : id === 1
        ? urlC2
        : id === 2
        ? urlC3
        : id === 3
        ? urlC4
        : "";

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url : urlC,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_OUTSTANDING_REPORT_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_OUTSTANDING_REPORT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//report = refund payment [GET]
//Reducer : getRefundReportReducer
export const getRefundReport = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_REFUND_REPORT_LOADING,
    });
    const acctype = localStorage.getItem("type");

    const id = user.id;
    const url1 = getRefundReport_Url;
    const url2 = getRefundReport_Url + `/week`;
    const url3 = getRefundReport_Url + `/month`;
    const url4 = getRefundReport_Url + `/quarterly`;

    const urlC1 = getClubRefundReport_Url;
    const urlC2 = getClubRefundReport_Url + `/week`;
    const urlC3 = getClubRefundReport_Url + `/month`;
    const urlC4 = getClubRefundReport_Url + `/quarterly`;

    const url =
      id === 0
        ? url1
        : id === 1
        ? url2
        : id === 2
        ? url3
        : id === 3
        ? url4
        : "";
    const urlC =
      id === 0
        ? urlC1
        : id === 1
        ? urlC2
        : id === 2
        ? urlC3
        : id === 3
        ? urlC4
        : "";

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url : urlC,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_REFUND_REPORT_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_REFUND_REPORT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//report = refund payment [GET]
//Reducer : getRefundReportReducer
export const getClubOrganizerRefund = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_CLUB_ORGANIZER_REFUND_LOADING,
    });
    const acctype = localStorage.getItem("type");

    const id = user.id;
    const url1 = getRefundReport_Url;
    const url2 = getRefundReport_Url + `/week`;
    const url3 = getRefundReport_Url + `/month`;
    const url4 = getRefundReport_Url + `/quarterly`;

    const urlC1 = getClubOrganizerRefund_Url;
    const urlC2 = getClubOrganizerRefund_Url + `/week`;
    const urlC3 = getClubOrganizerRefund_Url + `/month`;
    const urlC4 = getClubOrganizerRefund_Url + `/quarterly`;

    const url =
      id === 0
        ? url1
        : id === 1
        ? url2
        : id === 2
        ? url3
        : id === 3
        ? url4
        : "";
    const urlC =
      id === 0
        ? urlC1
        : id === 1
        ? urlC2
        : id === 2
        ? urlC3
        : id === 3
        ? urlC4
        : "";

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url : urlC,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_CLUB_ORGANIZER_REFUND_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CLUB_ORGANIZER_REFUND_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//report = refund payment [GET]
//Reducer : getRefundReportReducer
export const getAllReport = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ALL_REPORT_LOADING,
    });
    const acctype = localStorage.getItem("type");

    const id = user.id;
    const url1 = all_Report_Url;
    const url2 = all_Report_Url + `/week`;
    const url3 = all_Report_Url + `/month`;
    const url4 = all_Report_Url + `/quarterly`;

    const urlC1 = all_Club_Report_Url;
    const urlC2 = all_Club_Report_Url + `/week`;
    const urlC3 = all_Club_Report_Url + `/month`;
    const urlC4 = all_Club_Report_Url + `/quarterly`;

    const url =
      id === 0
        ? url1
        : id === 1
        ? url2
        : id === 2
        ? url3
        : id === 3
        ? url4
        : "";
    const urlC =
      id === 0
        ? urlC1
        : id === 1
        ? urlC2
        : id === 2
        ? urlC3
        : id === 3
        ? urlC4
        : "";

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url : urlC,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ALL_REPORT_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALL_REPORT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
//Get Dahsboard PaymentData [GET]
//Reducer : getDashboardPaymentReducer
export const getDashBoardPayment = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_DASHBOARD_PAYMENT_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const url1 = dashbordPayment_Url;
    const url2 = Club_dashbordPayment_Url;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_DASHBOARD_PAYMENT_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        if (!error.response) {
          dispatch({
            type: GET_DASHBOARD_PAYMENT_ERROR,
            error: error,
          });
        }
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_DASHBOARD_PAYMENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//filter event [get]
//Reducer : getFilterEventReducer

export const getFilterEvent = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_RECEIVE_REPORT_LOADING,
    });

    const id = user.id;
    const url1 = getReceiveRepoet_Url;
    const url2 = getReceiveRepoet_Url + `/week`;
    const url3 = getReceiveRepoet_Url + `/month`;
    const url4 = getReceiveRepoet_Url + `/quarterly`;

    const urlC1 = getClubReceiveRepoet_Url;
    const urlC2 = getClubReceiveRepoet_Url + `/week`;
    const urlC3 = getClubReceiveRepoet_Url + `/month`;
    const urlC4 = getClubReceiveRepoet_Url + `/quarterly`;

    const url =
      id === 0
        ? url1
        : id === 1
        ? url2
        : id === 2
        ? url3
        : id === 3
        ? url4
        : "";
    const urlC =
      id === 0
        ? urlC1
        : id === 1
        ? urlC2
        : id === 2
        ? urlC3
        : id === 3
        ? urlC4
        : "";

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url : urlC,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_RECEIVE_REPORT_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_RECEIVE_REPORT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Single CHAT msg
export const singleChat = (user) => {
  // alert("action loading")
  const notificationToken = localStorage.getItem("notificationToken");
  return (dispatch) => {
    dispatch({
      type: GET_SINGLE_CHAT_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url:
        get_single_chat_Url +
        `user1=${user.senderId}` +
        `&user1type=${user.type1}` +
        `&user2=${user.reciverId}` +
        `&user2type=${user.type2}&page=${user.index}&limit=30`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_SINGLE_CHAT_SUCCESS,
          data: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_SINGLE_CHAT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Send Broadcast[POST]
//Reducer : sendBroadcastReducer
export const sendBroadcast = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: SEND_BROADCAST_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? sendBroadcast_Url : sendBroadcast_Club_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: SEND_BROADCAST_SUCCESS,
          payload: userData,
          msg: resp.data,
          // is_filter: "",
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: SEND_BROADCAST_ERROR,
            errors: validationError,
            msg: error.response.data.message,
          });
        }
      });
  };
};

//Publish schedule[POST]
//Reducer : publishScheduleReducer
export const publishSchedule = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: PUBLISH_SCHEDULE_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: published_schedule_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: PUBLISH_SCHEDULE_SUCCESS,
          payload: userData,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: PUBLISH_SCHEDULE_ERROR,
            errors: validationError,
            msg: error.response.data.message,
          });
        }
      });
  };
};
export const RegistrationAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: EVENT_REGISTRATION_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url:
        user.show_switch === 1
          ? eventRegistrationON_Url + `/${user.event}`
          : eventRegistrationOFF_Url + `/${user.event}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: EVENT_REGISTRATION_SUCCESS,
          payload: userData,
          msg: resp.data.message,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: EVENT_REGISTRATION_ERROR,
            errors: validationError,
            msg: error.response.data.message,
          });
        }
      });
  };
};

export const reminder = (data) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: REMINDER_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: reminder_Url,
      data: data,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: REMINDER_SUCCESS,
          payload: userData,
          msg: resp.data?.amount,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: REMINDER_ERROR,
            errors: validationError,
            msg: error.response.data.message,
          });
        }
      });
  };
};

// 5325

export const removeaccount = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    "hello";
    dispatch({
      type: REMOVE_ACCOUNT_LOADING,
    });
    const id = user;

    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: removeAccount_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: REMOVE_ACCOUNT_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: REMOVE_ACCOUNT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getMerchantOrg = (user) => {
  return (dispatch) => {
    dispatch({
      type: GET_MERCHANT_ORGANIZER_LOADING,
    });
    const id = localStorage.getItem("Id");
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: merchant_Organizer_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_MERCHANT_ORGANIZER_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_MERCHANT_ORGANIZER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getMerchantStatus = (user) => {
  return (dispatch) => {
    dispatch({
      type: GET_MERCHANT_STATUS_LOADING,
    });
    const id = user;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: merchant_Status_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_MERCHANT_STATUS_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_MERCHANT_STATUS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getSignedWaiver = (user) => {
  return (dispatch) => {
    dispatch({
      type: GET_SIGNED_WAIVER_LOADING,
    });
    const id = user;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Signed_Waiver_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_SIGNED_WAIVER_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_SIGNED_WAIVER_LOADING,
            errors: validationError,
          });
        }
      });
  };
};

export const getLiabiltyTerms = (user) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIABILITY_TERMS_LOADING,
    });
    const id = user;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Liability_Terms_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_LIABILITY_TERMS_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_LIABILITY_TERMS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const createOrgRoster = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_ORG_ROSTER_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: addTeamOrgRoster_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_ORG_ROSTER_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_ORG_ROSTER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const reqaddpaymentAction = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: REQ_ADD_PAYMENT_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: reqaddpayment_Url + `/${user.requestId}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: REQ_ADD_PAYMENT_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: REQ_ADD_PAYMENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ................ org player details ..................

export const OrggetPlayerDetails = (user) => {
  return (dispatch) => {
    dispatch({
      type: ORG_PLAYER_DETAILS_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Player_details_Url + `/${user.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: ORG_PLAYER_DETAILS_SUCCESS,
          data: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ORG_PLAYER_DETAILS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ................ club discount action ..................

export const clubdiscountAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: ORG_CLUB_DISCOUNT_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: club_discount_Url + `/${user.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: ORG_CLUB_DISCOUNT_SUCCESS,
          data: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ORG_CLUB_DISCOUNT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ................ club credit action ..................

export const clubcreditAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: ORG_CLUB_CREDIT_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: club_credit_Url + `/${user.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: ORG_CLUB_CREDIT_SUCCESS,
          data: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ORG_CLUB_CREDIT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const OrggetallclubAction = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: ORG_GET_ALL_CLUB_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Org_getallclub_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: ORG_GET_ALL_CLUB_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: ORG_GET_ALL_CLUB_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
export const OrggetallclubByIdAction = (id) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: ORG_GET_ALL_CLUB_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${Org_getallclub_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: ORG_GET_ALL_CLUB_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: ORG_GET_ALL_CLUB_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const orgClubById = ({ id }) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: ORG_CLUB_BY_ID_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getOrgClubById_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: ORG_CLUB_BY_ID_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: ORG_CLUB_BY_ID_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ...........  balances due action ..................

export const OrgbalancesdueAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: ORG_BALANCE_DUE_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Org_balances_due_Url + `/${user.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: ORG_BALANCE_DUE_SUCCESS,
          data: resp.data,
        });
      })
      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ORG_BALANCE_DUE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ...............   switch access ...............................................

export const SwitchacccessAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: ORG_SWITCH_ACCESS_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: switchAccess_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: ORG_SWITCH_ACCESS_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ORG_SWITCH_ACCESS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const SwitchacccessPlayerAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: ORG_SWITCH_ACCESS_PLAYER_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: switchPlayerAccess_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: ORG_SWITCH_ACCESS_PLAYER_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ORG_SWITCH_ACCESS_PLAYER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ...............   event division pool ...............................................

export const eventdivisionpoolAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: EVENT_DIVISION_POOL_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: eventdivisionpool_Url + `/${user?.id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: EVENT_DIVISION_POOL_SUCCESS,
          user: resp.data?.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: EVENT_DIVISION_POOL_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ...............   add team to division ...............................................

export const addteamtodivisionAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: ORG_ADD_TEAM_TO_DIVISION_LOADING,
    });

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: addteamtodivision_Url + `/${user.division}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: ORG_ADD_TEAM_TO_DIVISION_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ORG_ADD_TEAM_TO_DIVISION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const addplayertodivisionAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: ORG_ADD_PLAYER_TO_DIVISION_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: addplayertodivision_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: ORG_ADD_PLAYER_TO_DIVISION_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ORG_ADD_PLAYER_TO_DIVISION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const updateScheduleAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SCHEDULE_LOADING,
    });
    const formData = new FormData();
    formData.append("file", user.file);
    formData.append("type", user.type);
    formData.append("event_id", user.event_id);
    formData.append("text", user.text);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: UpdateSchedule_Url + `/${user.schedule_id}`,
      data: formData,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: UPDATE_SCHEDULE_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_SCHEDULE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ..................   field map   .....................

export const createfieldmap = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_FIELD_MAP_LOADING,
    });
    const formData = new FormData();
    formData.append("file", user.file);
    formData.append("type", user.type);
    formData.append("event_id", user.event_id);
    const requestOptions = {
      method: "POST",
      headers: {
        // "accept": "application/json",
        // "Content-Type": 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: createfieldmap_Url,
      data: formData,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_FIELD_MAP_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_FIELD_MAP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ..........................   view field map  ...........................

export const viewfieldmapAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: VIEW_FIELD_MAP_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: viewfieldmap_Url + `/${user?.id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: VIEW_FIELD_MAP_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: VIEW_FIELD_MAP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const updatefieldmapAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FIELD_MAP_LOADING,
    });
    const formData = new FormData();
    formData.append("file", user.file);
    formData.append("type", user.type);
    formData.append("event_id", user.event_id);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: editfieldmap_Url + `/${user.fieldMapId}`,
      data: formData,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: UPDATE_FIELD_MAP_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_FIELD_MAP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ......................................  clear org notifications  .......................

export const clearOrgnotificationsAction = (id) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ORG_NOTIFICATIONS_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${clearOrgNotifications_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CLEAR_ORG_NOTIFICATIONS_SUCCESS,
          user: resp.data?.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CLEAR_ORG_NOTIFICATIONS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ...................  Create Schedule Message .......................

export const CreateScheduleMessageAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_SCHEDULE_MESSAGE_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: create_schedule_message_Url + `/${user.id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_SCHEDULE_MESSAGE_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_SCHEDULE_MESSAGE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ...................  Field map Message .......................

export const CreateFieldmapMessageAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_FIELDMAP_MESSAGE_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: create_fieldmap_message_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_FIELDMAP_MESSAGE_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_FIELDMAP_MESSAGE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const deleteFileMapAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_FIELD_MAP_LOADING,
    });

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${delete_fieldmap_message_Url}/${user}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_FIELD_MAP_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_FIELD_MAP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ..........................   view stay and play   ...........................

export const viewStayandplayAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: VIEW_STAY_AND_PLAY_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: view_stayandplay_Url + `/${user?.id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: VIEW_STAY_AND_PLAY_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: VIEW_STAY_AND_PLAY_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ......................  create stay and play .........................

export const CreateStayandPlayAction = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_STAY_AND_PLAY_LOADING,
    });
    const formData = new FormData();
    formData.append("file", user.file);
    formData.append("type", user.type);
    formData.append("event_id", user.event_id);
    formData.append("text", user.text);
    const requestOptions = {
      method: "POST",
      headers: {
        // "accept": "application/json",
        // "Content-Type": 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: create_stayandplay_Url,
      data: formData,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_STAY_AND_PLAY_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_STAY_AND_PLAY_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ....................  edit stay and play  ...........................

export const UpdateStayandPlayAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_STAY_AND_PLAY_LOADING,
    });
    const formData = new FormData();
    formData.append("file", user.file);
    formData.append("type", user.type);
    formData.append("event_id", user.event_id);
    formData.append("text", user.text);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: update_stayandplay_Url + `/${user.stayandplay_id}`,
      data: formData,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_STAY_AND_PLAY_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_STAY_AND_PLAY_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ..................  delete stay and play ....................

export const DeleteStayandPlayAction = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_STAY_AND_PLAY_LOADING,
    });
    const id = user;
    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: delete_stayandplay_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_STAY_AND_PLAY_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_STAY_AND_PLAY_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// ................  create stay and play message  ..............

export const CreateStayandPlayMessageAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_STAY_AND_PLAY_MESSAGE_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: create_stayandplay_message_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_STAY_AND_PLAY_MESSAGE_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_STAY_AND_PLAY_MESSAGE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getOrgAdminPermissionList = (user) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getAdminPermissionList_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_ADMIN_PERMISSION_LIST_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: GET_ADMIN_PERMISSION_LIST_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const createOrgAdminGroup = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: CreateOrgAdminPermissionGroup_Url,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_ADMIN_PERMISSION_GROUP_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_ADMIN_PERMISSION_GROUP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const deleteOrgAdminGroup = (id) => {
  return (dispatch) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${delete_permission_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_ADMIN_PERMISSION_GROUP_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_ADMIN_PERMISSION_GROUP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getAdminList = (id) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getAdmin_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_ADMIN_LIST_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: GET_ADMIN_LIST_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getAllUsersList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: GetAllUsersList_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_ALL_USERS_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: GET_ALL_USERS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const AdminInvite = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: InviteAdmin_Url,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: INVAITE_ADMIN_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: INVAITE_ADMIN_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getStaffGroupList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getStaffPermissionList_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_STAFF_PERMISSION_LIST_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: GET_STAFF_PERMISSION_LIST_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const createOrgStaffGroup = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: CreateOrgStaffPermissionGroup_Url,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_STAFF_PERMISSION_GROUP_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_STAFF_PERMISSION_GROUP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const deleteOrgStaffGroup = (id) => {
  return (dispatch) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${delete_Staff_permission_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_STAFF_PERMISSION_GROUP_SUCCESS,
          user: resp.data,
          msg: resp.data.message,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_STAFF_PERMISSION_GROUP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getStaffPermissionDetails = (id) => {
  // alert("action loading")
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Staff_permission_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;

        dispatch({
          type: GET_STAFF_PERMISSION_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_STAFF_PERMISSION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const changeStaffPermission = (details) => {
  // alert("action loading")
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: set_Staff_permission_Url,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: SET_STAFF_PERMISSION_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: SET_STAFF_PERMISSION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getStaffList = () => {
  // alert("action loading")
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_staff_list_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_STAFF_LIST_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_STAFF_LIST_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const createMerchantAccount = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: create_merchat_Url,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: CREATE_MERCHAT_ACCOUNT_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: CREATE_MERCHAT_ACCOUNT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const moveToFreeTeamPlayer = ({ type, id, type2 }) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${move_to_free_team_player_Url}/${id}/${type2}/${type}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: MOVE_TO_FREE_TEAM_PLAYER_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: MOVE_TO_FREE_TEAM_PLAYER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const orgConnectedUser = ({ type, id }) => {
  return (dispatch) => {
    const notificationToken = localStorage.getItem("notificationToken");
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: `${
        type == 1 ? Org_Dms_user_Url : type == 4 ? Club_Dms_user_Url : ""
      }/${id}/${type}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ORG_CONNECTED_USER_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ORG_CONNECTED_USER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getAllInvitation = ({ id, type }) => {
  const notificationToken = localStorage.getItem("notificationToken");
  return (dispatch) => {
    dispatch({
      type: GET_ALL_INVITATION_LOADING,
    });

    const url2 = getAllClubInvitationById_Url;
    const url1 = getAllInvitationById_Url;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: `${type == 1 ? url1 : url2}/${id}/${type}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ALL_INVITATION_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALL_INVITATION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getNotification = ({ type }) => {
  const notificationToken = localStorage.getItem("notificationToken");
  return (dispatch) => {
    dispatch({
      type: GET_NOTIFICATION_LOADING,
    });

    const url2 = get_Club_Notification_Url;
    const url1 = get_Org_notification;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: type == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_NOTIFICATION_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_NOTIFICATION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
export const changeInvitationStatus = ({ type, details }) => {
  const notificationToken = localStorage.getItem("notificationToken");
  return (dispatch) => {
    dispatch({
      type: CHANGE_INVITATION_STATUS_LOADING,
    });

    const url1 = changeOrgInvitation_Url;
    const url2 = changeClubInvitation_Url;

    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: type == 1 ? url1 : url2,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: CHANGE_INVITATION_STATUS_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: CHANGE_INVITATION_STATUS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getTermsAndConditions = (data) => {
  return (dispatch) => {
    dispatch({
      type: TERMS_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      url: api_liability,
      data: JSON.stringify(data),
    };
    axios(requestOptions)
      .then((res) => {
        const data = res.data;
        dispatch({ type: TERMS_SUCCESS, payload: data });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: TERMS_ERROR,
            payload: validationError,
          });
        }
      });
  };
};

export const readNotificationAction = (id) => {
  return (dispatch) => {
    const notificationToken = localStorage.getItem("notificationToken");
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: `${ReadNotificationUrl_Url}/${id}`,
    };
    axios(requestOptions)
      .then((res) => {
        const data = res.data;
        dispatch({ type: READ_NOTIFICATION_SUCCESS, payload: data });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: READ_NOTIFICATION_ERROR,
            payload: validationError,
          });
        }
      });
  };
};

export const getNotificationsCountAction = (userId, userType) => {
  return (dispatch) => {
    const notificationToken = localStorage.getItem("notificationToken");
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: `${getNotificationCount_Url}/${userId}/${userType}`,
      // url: `${
      //   type == 1 ? Org_Dms_user_Url : type == 4 ? Club_Dms_user_Url : ""
      // }/${id}/${type}`,
    };
    axios(requestOptions)
      .then((res) => {
        const data = res.data;
        dispatch({ type: NOTIFICATION_COUNT_SUCCESS, payload: data });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: NOTIFICATION_COUNT_ERROR,
            payload: validationError,
          });
        }
      });
  };
};

export const readInvitationAction = (userType) => {
  return (dispatch) => {
    const notificationToken = localStorage.getItem("notificationToken");
    const url1 = ReadOrgInvitation_Url;
    const url2 = ReadClubInvitation_Url;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: userType == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((res) => {
        const data = res.data;
        dispatch({ type: READ_INVITATION_SUCCESS, payload: data });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: READ_INVITATION_ERROR,
            payload: validationError,
          });
        }
      });
  };
};
