import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { getAccountDetails } from "../../../service/action/userAction";
import { connect } from "react-redux";

const OrgPayment = (props) => {
  // getAccountDetails: user => dispatch(getAccountDetails(user)),
  useEffect(() => {
    props.getAccountDetails();
  }, []);
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head">
              <h5 className="dash-head">Payment</h5>
            </div>
          </div>

          <div className="text-left mt-30 col-md-6 px-0">
            <Link to="/report" className="account-tab">
              <div className="account-type-section">
                Payment Requests
                <i className="mdi mdi-chevron-right right-arrow"></i>
              </div>
            </Link>
            <Link to="/payment-history" className="account-tab">
              <div className="account-type-section">
                Payment History
                <i className="mdi mdi-chevron-right right-arrow"></i>
              </div>
            </Link>
            <Link to="/paymentcode" className="account-tab">
              <div className="account-type-section">
                Payment Codes
                <i className="mdi mdi-chevron-right right-arrow"></i>
              </div>
            </Link>
            {/* <Link to="/credits" className="account-tab">
              <div className="account-type-section">
                Credits
                <i className="mdi mdi-chevron-right right-arrow"></i>
              </div>
            </Link> */}

            <Link to="/discount-code" className="account-tab">
              <div className="account-type-section">
                Discount Codes
                <i className="mdi mdi-chevron-right right-arrow"></i>
              </div>
            </Link>
            <Link to="/refunds" className="account-tab">
              <div className="account-type-section">
                Refunds
                <i className="mdi mdi-chevron-right right-arrow"></i>
              </div>
            </Link>

            {/* <Link to="#" className="account-tab">
                            <div className="account-type-section">
                                Payment term list
                                <i className="mdi mdi-chevron-right right-arrow" ></i>
                            </div>
                        </Link> */}

            {/* <Link to="#" className="account-tab">
                            <div className="account-type-section">
                                Refunds
                                <i className="mdi mdi-chevron-right right-arrow" ></i>
                            </div>
                        </Link> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(OrgPayment);

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getAccountDetails: (user) => dispatch(getAccountDetails(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrgPayment));
