import React, { useState, useEffect } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import Toaster from '../../../Toaster/Toaster';
import { withRouter, Redirect, useLocation } from 'react-router-dom';
import { connect, useSelector, useParams } from 'react-redux';
import { createCredit, createPaymentCode, createRefund, getEventsRegisterTeam, getPaidRecipient, getPayment, getPendingAmount, getRecipient, getReferenceNumber, paymentRequest, postClubPendingamount } from "../../../service/action/userAction"
import { RESET_CREATE_CREDIT, RESET_CREATE_PAYMENT_CODE, RESET_GET_PAID_RECIPIENT, RESET_GET_REFUND, RESET_PAYMENT_REQUEST } from '../../../service/constants';
import DatePicker from "react-datepicker";
import classNames from 'classnames';
import Spinner from '../../../Spinner/Spinner';
import { EVENT, NUMERIC, RECIPIENT, REFUND_ACCOUNT, REFUND_AMOUNT } from '../../../Validation/club';
import BreadcrumbHistory from '../../../BreadcumbHistory/BreadcrumbHistory';




const CreateClubRefund = (props) => {


    useEffect(() => {
        props.getEventHandler()
    }, [])

    let [loading, setLoading] = useState(true);


    const getEvent = useSelector(state => state.getEventRegisterTeamReducer.user.event)

    const location = useLocation()

    const getRecipient = useSelector(state => state.getPaidRecipientReducer.user.data)
    const getPayment = useSelector(state => state.getPaymentReducer.user.data)
    const getReferenceNumber = useSelector(state => state.getReferenceNumberReducer.user.data)



    const initialState = {
        event: "",
        recipient: "",
        amount: "",
        recipient_type: "",
        account: "",
        referenceNumber: ""
    };


    const [datePick, setDatePick] = useState('')
    const [state, setState] = useState({
        event: "",
        recipient: "",
        amount: "",
        recipient_type: "",
        account: "",
        referenceNumber: ""
    })

    const [errors, setErrors] = useState({
        event: false,
        recipient: false,
        amount: false,
        account: false

    })


    //setTimeout form reseting data after submit of form
    function resetData() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    }

    //clear data on submit.
    const clearState = () => {
        setState({ ...initialState });
        setDatePick("");
    };


    const eventHandler = (e) => {
        const { id, value, name } = e.target


        setState(prevState => ({
            ...prevState,
            [id]: value
            // [e.target.name]: e.target.value.trim()
        }))
        switch (name) {

            case "event":
                if (value !== 0) {
                    props.getrecipientHandler({ id: value })
                }
                if (value == 0) {
                    props.resetGetPaidRecipient()
                    props.resetGetRefund()
                    setState(prevState => ({
                        ...prevState,
                        recipient_type: "",
                        account: "",
                        recipient: ""
                    }))
                }
                if (value == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        event: EVENT
                    }))
                    setState(prevState => ({
                        ...prevState,
                        event: value
                    }))
                    document.getElementById("event").classList.add("error")

                }

                else {
                    setErrors(prevState => ({
                        ...prevState,
                        event: false
                    }))
                    setState(prevState => ({
                        ...prevState,
                        event: value
                    }))

                    document.getElementById("event").classList.remove("error")
                }
                break;

            case "recipient":

                if (value == 0) {
                    props.resetGetRefund()
                    setState(prevState => ({
                        ...prevState,
                        account: ""

                    }))
                }


                if (value !== 0) {
                    const recipient = getRecipient.find(e => e._id == value)
                    setState(prevState => ({
                        ...prevState,
                        recipient_type: recipient.type
                    }))

                }
                if (value.length == 0 || value == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        recipient: RECIPIENT
                    }))

                    document.getElementById("recipient").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        recipient: false
                    }))

                    document.getElementById("recipient").classList.remove("error")
                }

                break;
            case "account":

                if (value !== 0) {
                    // const recipient=getRecipient.find(e=>e._id==value)
                    props.getReferenceNumberHandler({ type: state.recipient_type, id: value })
                    setState(prevState => ({
                        ...prevState,
                        account: value,
                        // referenceNumber:getReferenceNumber[0].payment_reference_number
                    }))

                }
                if (value.length == 0 || value == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        account: REFUND_ACCOUNT
                    }))

                    document.getElementById("account").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        account: false
                    }))

                    document.getElementById("account").classList.remove("error")
                }

                break;

            case "amount":


                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        amount: REFUND_AMOUNT
                    }))


                    document.getElementById("amount").classList.add("error")
                }
                else if (!/^[0-9]+$/.test(value)) {
                    setErrors(prevState => ({
                        ...prevState,
                        amount: NUMERIC
                    }))

                    document.getElementById("amount").classList.add("error")
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        amount: false
                    }))


                    document.getElementById("amount").classList.remove("error")
                }
                break;

            default:
        }
    }

    const notify = (msg) => toast(msg);

    useEffect(() => {
        if (state.recipient_type !== "") {

            props.getPaymentHandler({ recipient: state.recipient, type: state.recipient_type })
        }
    }, [state.recipient_type])

    useEffect(() => {
        if (getReferenceNumber) {
            setState(prevState => ({
                ...prevState,
                referenceNumber: getReferenceNumber[0].payment_reference_number
            }))

        }
    }, [getReferenceNumber])

    const submitRequest = (e) => {
        e.preventDefault();
        // alert("click")
        if (state.event == "") {
            setErrors(prevState => ({
                ...prevState,
                event: EVENT
            }))
            document.getElementById("event").classList.add("error")
        }
        if (state.recipient == "") {
            setErrors(prevState => ({
                ...prevState,
                recipient: RECIPIENT

            }))
            document.getElementById("recipient").classList.add("error")
        }

        if (state.amount == "") {
            setErrors(prevState => ({
                ...prevState,
                amount: REFUND_AMOUNT
            }))
            document.getElementById("amount").classList.add("error")
        }

        if (state.account == "") {
            setErrors(prevState => ({
                ...prevState,
                account: REFUND_ACCOUNT
            }))
            document.getElementById("account").classList.add("error")
        }

        if (state.event !== 0 && state.amount !== "" && state.recipient !== 0) {
            props.createRefundHandler({
                event: state.event,
                receipient: state.recipient,
                refund_amount: state.amount,
                receipient_type: state.recipient_type,
                payment_reference_number: state.referenceNumber
            })
        }
    }


    useEffect(() => {
        if (props.credit?.success) {
            notify("Refund Issue successfully")
            props.resetcreatecreditHandler()
            resetData().then(clearState)
        }
    }, [props.credit])



    useEffect(() => {
        if (props.event?.success) {
            setLoading(false)
        }
    }, [props.event])

    return (
        <>
            <div className="main-panel">
                <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />

                <div className="content-wrapper ">
                    <div className="text-left">
                        <h5 className="dash-head">Create Refund</h5>
                    </div>
                    <div className='d-flex justify-content-center'>
                        {loading ? <Spinner /> : null}
                    </div>
                    {
                        getEvent ? <>
                            <form >
                                <div className="row col-md-6 px-0 mx-0">
                                    <div className="col-md-12 mt-24">
                                        <div className="form-group event-drp mt-18">
                                            <label className="text-gray fs-13">Event <span className="text-red fw-m">*</span></label>
                                            <select className={classNames("form-control team-name payterm", { "error": errors.event })} id="event" name="event" value={state.event} onChange={eventHandler}>
                                                <option value="0" >Event *</option>
                                                {
                                                    !loading &&
                                                    getEvent.map((v, i) => {
                                                        return <option value={v.id} key={v._id}>{v.event_name}</option>
                                                    })
                                                }
                                            </select>
                                            {errors.event &&
                                                <span className='text-red small d-block'>* {errors.event}</span>}
                                        </div>
                                        <div className="form-group event-drp mt-18">
                                            <label className="text-gray fs-13">Recipient <span className="text-red fw-m">*</span></label>
                                            <select className={classNames("form-control team-name payterm", { "error": errors.recipient })} id="recipient" name="recipient" value={state.recipient} onChange={eventHandler}>
                                                <option value="0" >Recipient  *</option>
                                                {getRecipient ? <>
                                                    {
                                                        getRecipient.map((v, i) => {
                                                            return <option value={v._id} key={v._id}>{v.name}</option>
                                                        })
                                                    }
                                                </> : <></>}
                                            </select>

                                            {errors.recipient &&
                                                <span className='text-red small d-block'>* {errors.recipient}</span>}
                                        </div>
                                        <div className="form-group event-drp mt-18">
                                            <label className="text-gray fs-13">Account <span className="text-red fw-m">*</span></label>
                                            <select className={classNames("form-control team-name payterm", { "error": errors.account })} id="account" name="account" value={state.account} onChange={eventHandler}>
                                                <option value="0" >Account  *</option>
                                                {getPayment ? <>
                                                    {
                                                        getPayment.map((v, i) => {
                                                            return <option value={v._id} key={v._id}>{v.payment_reference_number}</option>
                                                        })
                                                    }
                                                </> : <></>}
                                            </select>
                                            {errors.account &&
                                                <span className='text-red small d-block'>* {errors.account}</span>}
                                        </div>
                                        <div className="form-group event-drp mt-18">
                                            <label className="text-gray fs-13">Refund <span className="text-red fw-m">*</span></label>
                                            <input
                                                type="number"
                                                className="form-control team-name"
                                                id="amount"
                                                name="amount"
                                                value={state.amount}
                                                placeholder="Enter amount *"
                                                required onChange={eventHandler}
                                            // onKeyPress={numericValue} 
                                            />
                                            {errors.amount &&
                                                <span className='text-red small d-block'>* {errors.amount}</span>}
                                        </div>
                                        <div className="mt-3">
                                            <a className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitRequest} >Send</a>
                                        </div>
                                        <Toaster />

                                    </div>
                                </div>
                            </form>
                        </> : <></>
                    }
                </div>
            </div>

        </>
    )
}
// export default withRouter(CreateInvoice)
const mapStateToProp = state => ({
    submitRequest: state.createPaymentCodeReducer,
    paymentRequest: state.paymentRequestReducer,
    event: state.getEventRegisterTeamReducer,
    credit: state.createcreditReducer
})

const mapDispatchToProps = dispatch => ({
    getEventHandler: user => dispatch(getEventsRegisterTeam(user)),
    getrecipientHandler: user => dispatch(getPaidRecipient(user)),
    getPaymentHandler: user => dispatch(getPayment(user)),
    // paymentRequesthandler: user => dispatch(paymentRequest(user)),
    resetcreatecreditHandler: () => dispatch({ type: RESET_CREATE_CREDIT }),
    createRefundHandler: user => dispatch(createRefund(user)),
    getReferenceNumberHandler: user => dispatch(getReferenceNumber(user)),
    resetGetPaidRecipient: () => dispatch({ type: RESET_GET_PAID_RECIPIENT }),
    resetGetRefund: () => dispatch({ type: RESET_GET_REFUND })
})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(CreateClubRefund));