// Create Event
export const FACILITY_STATE="Please select state "
export const FACILITY_CITY="Please select city "
export const FACILITY_ADDRESS="Address can not be empty"
export const FACILITY_WEBSITE="Website can not be empty"
export const FACILITY_NAME="Name can not be empty"

export const START_DATE='Please set start date'
export const END_DATE='Please set end date'
export const START_TIME='Please set a start time'
export const END_TIME='Please set a end time'
export const REG_START_DATE='Please set a registration start date'
export const REG_END_DATE='Please set a registration end date'
export const REG_START_TIME='Please set a start time'
export const REG_END_TIME='Please set a end time'


export const EVENT_NAME='Event name can not be empty'
export const MAX_TEAM='Max. teams can not be empty'
export const EVENT_TYPE='Please select event type'
export const EVENT_SEASON='Please select season'
export const EVENT_ACCESS='Please select event access type'
export const EVENT_PAYMENTCODE='Please select payment code'
export const EVET_PAYMENT_CODE_TYPE='Please select payment request type'



//payment request
export const RECIPIENT='Please select recipient'
export const AMOUNT='Amount can not be empty'
export const NUMERIC='Only numeric value is accept'
export const PAYMENT_TERM='Please select payment term'
export const DUE_DATE='Please select due date'
export const EVENT='Please select event'
export const REQ_TYPE='Please select type'

//payment code
export const PAYMENT_CODE='Payment code name can not be empty'
export const DISCRIPTION = 'Discription can not be empty'
export const AMOUNT_DUE='Amount due can not be empty'
export const BALANCE_DUE_DAYS='Balance due day value can not be empty'
export const PAYMENT_TERM_CONDITION="Terms & condition can not be empty"

//credit
export const CREDIT_AMOUNT="Credit amount can not be empty"

//Discount Code
export const DISCOUNT_CODE_NAME="Enter discount code name"
export const DISCOUNT_CODE="Enter discount code"
export const DISCOUNT_CODE_BOTH="Discount code include both numbers and alphabet"
export const DISCOUNT_CODE_ALPHA_NUM="Alphabet and number are valid"
export const EXPIRY_DATE="Please select expiry date"

//Refund
export const REFUND_ACCOUNT="Please select account"
export const REFUND_AMOUNT="Refund amount can not be empty"