import { GET_COACH_ACCEPT_LIST_ERROR, GET_COACH_ACCEPT_LIST_LOADING, GET_COACH_ACCEPT_LIST_SUCCESS } from "../../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false
};

export default function coachAcceptListReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COACH_ACCEPT_LIST_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false,success:false};
     
    case GET_COACH_ACCEPT_LIST_SUCCESS:  
      // alert(" reducer Error")
      return { ...state, loading: false ,success:true,user:action.payload};
 
    case GET_COACH_ACCEPT_LIST_ERROR:
      // alert(" reducer Loading")
      return { ...state,error: true, errors: action.errors };
   
    default:
      return state;
  }
}


