import {
  ORG_GET_ALL_CLUB_ERROR,
  ORG_GET_ALL_CLUB_LOADING,
  ORG_GET_ALL_CLUB_SUCCESS,
} from "../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
};

export default function OrgGetallclubReducer(state = initialState, action) {
  switch (action.type) {
    case ORG_GET_ALL_CLUB_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case ORG_GET_ALL_CLUB_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };

    case ORG_GET_ALL_CLUB_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}
