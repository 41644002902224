import axios from "axios";
import { GET_MERCHANT_DETAILS_ERROR, GET_MERCHANT_DETAILS_SUCCESS } from "../constant";
import { merchantUrl } from "../apiSheet";

export const GetMerchantDetails = () => {
 return (dispatch) => {

  const requestOptions = {
   method: "GET",
   headers: {
    "content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
   },
   url: merchantUrl,
  };
  axios(requestOptions)
   .then((resp) => {
    dispatch({
     type: GET_MERCHANT_DETAILS_SUCCESS,
     response: {
      data: resp.data,
     }
    })
   })
   .catch((error) => {
    let data = error.response;
    if (!error.response) {
     dispatch({
      type: GET_MERCHANT_DETAILS_ERROR,
      errorMsg: error,
     });
    } else if (data.status === 422) {
     let validationError = data.data.message;
     dispatch({
      type: GET_MERCHANT_DETAILS_ERROR,
      errorMsg: validationError
     });
    }
    else if (data.status === 401) {
     dispatch({
      type: GET_MERCHANT_DETAILS_ERROR,
      errorMsg: 'UnAuthorized'
     });
    }
   });
 };
}