//Store Reset
export const RESET_STORE = "RESET_STORE";

// sender: sender_id,
// roomId: id1,
// senderUserType: userType,
// receiver: id,
// receiverUserType: id3,
// message: message,
// message_type: 'text',

// SignUp
export const SIGNUP_LOADING = "SIGNUP_LOADING";
export const RESET_SIGNUP = "RESET_SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const SIGNUP_NETWORK_ERROR = "SIGNUP_NETWORK_ERROR";

// Forgot password Email
export const FORGOT_PASSWORD_LOADING = "FORGOT_PASSWORD_LOADING";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_FORGOT_PASSWORD_EMAIL = "RESET_FORGOT_PASSWORD_EMAIL";

// Forgot password Email
export const VERIFY_OTP_LOADING = "VERIFY_OTP_LOADING";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";
export const RESET_VERIFY_OTP = "RESET_VERIFY_OTP";

// Forgot password Email
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_RESET_PASSWORD = "RESET_RESET_PASSWORD";

//SignIn
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const SIGNIN_LOADING = "SIGNIN_LOADING";
export const EMAIL_VERIFICATION = "EMAIL_VERIFICATION";

//SignIn
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNOUT_ERROR = "SIGNOUT_ERROR";
export const SIGNOUT_LOADING = "SIGNOUT_LOADING";
export const RESET_SIGNIN = "RESET_SIGNIN";

//CreateEvent
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_ERROR = "CREATE_EVENT_ERROR";
export const CREATE_EVENT_LOADING = "CREATE_EVENT_LOADING";
export const RESET_CREATE_EVENT = "RESET_CREATE_EVENT";

//Fetch Event data
export const FETCH_EVENT_SUCCESS = "FETCH_EVENT_SUCCESS";
export const FETCH_EVENT_ERROR = "FETCH_EVENT_ERROR";
export const FETCH_EVENT_LOADING = "FECTH_EVENT_LOADING";

//Create Payment cost
export const CREATE_PAYMENT_CODE_SUCCESS = "CREATE_PAYMENT_CODE_SUCCESS";
export const CREATE_PAYMENT_CODE_ERROR = "CREATE_PAYMENT_CODE_ERROR";
export const CREATE_PAYMENT_CODE_LOADING = "CREATE_PAYMENT_CODE_LOADING";
export const RESET_CREATE_PAYMENT_CODE = "RESET_CREATE_PAYMENT_CODE";

//Get all Payment Code
export const GET_PAYMENT_CODE_SUCCESS = "GET_PAYMENT_CODE_SUCCESS";
export const GET_PAYMENT_CODE_ERROR = "GET_PAYMENT_CODE_ERROR";
export const GET_PAYMENT_CODE_LOADING = "GET_PAYMENT_CODE_LOADING";

//Get all Payment Code
export const GET_PAYMENTCODE_SUCCESS = "GET_PAYMENTCODE_SUCCESS";
export const GET_PAYMENTCODE_ERROR = "GET_PAYMENTCODE_ERROR";
export const GET_PAYMENTCODE_LOADING = "GET_PAYMENTCODE_LOADING";
export const RESET_GET_PAYMENTCODE = "RESET_GET_PAYMENTCODE";

//division custom paymentcode
export const DIVISIONCUSTOMPAYMENTCODE_SUCCESS =
  "DIVISIONCUSTOMPAYMENTCODE_SUCCESS";
export const DIVISIONCUSTOMPAYMENTCODE_ERROR =
  "DIVISIONCUSTOMPAYMENTCODE_ERROR";
export const DIVISIONCUSTOMPAYMENTCODE_LOADING =
  "DIVISIONCUSTOMPAYMENTCODE_LOADING";
export const RESET_GET_DIVISIONCUSTOMPAYMENTCODE =
  "RESET_GET_DIVISIONCUSTOMPAYMENTCODE";

//find User
export const FINDUSER_SUCCESS = "FINDUSER_SUCCESS";
export const FINDUSER_ERROR = "FINDUSER_ERROR";
export const FINDUSER_LOADING = "FINDUSER_LOADING";
export const RESET_GET_FINDUSER = "RESET_GET_FINDUSER";

//find User By email
export const FINDUSER_BYEMAIL_SUCCESS = "FINDUSER_BYEMAIL_SUCCESS";
export const FINDUSER_BYEMAIL_ERROR = "FINDUSER_BYEMAIL_ERROR";
export const FINDUSER_BYEMAIL_LOADING = "FINDUSER_BYEMAIL_LOADING";
export const RESET_GET_FINDUSER_BYEMAIL = "RESET_GET_FINDUSER_BYEMAIL";

//Get all Payment Code
export const GET_PAYMENTCODE1_SUCCESS = "GET_PAYMENTCODE1_SUCCESS";
export const GET_PAYMENTCODE1_ERROR = "GET_PAYMENTCODE1_ERROR";
export const GET_PAYMENTCODE1_LOADING = "GET_PAYMENTCODE1_LOADING";

//Edit Payment Code
export const EDIT_PAYMENT_CODE_SUCCESS = "EDIT_PAYMENT_CODE_SUCEESS";
export const EDIT_PAYMENT_CODE_ERROR = "EDIT_PAYMENT_CODE_ERROR";
export const EDIT_PAYMENT_CODE_LOADING = "EDIT_PAYMENT_CODE_LOADING";
export const RESET_EDIT_PAYMENT_CODE = "RESET_EDIT_PAYMENT_CODE";

//Send User data
export const TEAM_ARRAY_ID = "EDIT_USER_DATA";
export const RESET_TEAM_ARRAY_DATA = "RESET_TEAM_ARRAY_DATA";

//Edit Event data
export const EDIT_EVENT_DATA_SUCCESS = "EDIT_EVENT_DATA_SUCCESS";
export const EDIT_EVENT_DATA_ERROR = "EDIT_EVENT_DATA_ERROR";
export const EDIT_EVENT_DATA_LOADING = "EDIT_EVENT_DATA_";
export const RESET_EDIT_EVENT = "RESET_EDIT_EVENT";

//get All event
export const GET_ALLEVENT_SUCCESS = "GET_ALLEVENT_SUCCESS";
export const GET_ALLEVENT_ERROR = "GET_ALLEVENT_ERROR";
export const GET_ALLEVENT_LOADING = "GET_ALLEVENT_LOADING";

// get club find by name
export const GET_CLUB_LIST_FIND_BY_NAME_SUCCESS =
  "GET_CLUB_LIST_FIND_BY_NAME_SUCCESS";
export const GET_CLUB_LIST_FIND_BY_NAME_ERROR =
  "GET_CLUB_LIST_FIND_BY_NAME_ERROR";
export const GET_CLUB_LIST_FIND_BY_NAME_LOADING =
  "GET_CLUB_LIST_FIND_BY_NAME_LOADING";

//get All event
export const GET_EVENT_BY_ID_SUCCESS = "GET_EVENT_BY_ID_SUCCESS";
export const GET_EVENT_BY_ID_ERROR = "GET_EVENT_BY_ID_ERROR";
export const GET_EVENT_BY_ID_LOADING = "GET_EVENT_BY_ID_LOADING";

//Event Details
export const EVENT_DETAILS = "EVENT_DETAILS";

//OnEventClick
export const EVENT_DETAIL = "EVENT_DETAIL";

//remove selected Product
export const REMOVE_SELECTED_EVENT = "REMOVE_SELECTED_EVENT";

//Switch account
export const SWITCH_LOADING = "SWITCH_LOADING";
export const SWITCH_SUCCESS = "SWITCH_SUCCESS";
export const SWITCH_ERROR = "SWITCH_ERROR";
export const RESET_SWITCH = "RESET_SWITCH";

//Switch account
export const SWITCH_NOTIFICATION_USER_LOADING =
  "SWITCH_NOTIFICATION_USER_LOADING";
export const SWITCH_NOTIFICATION_USER_SUCCESS =
  "SWITCH_NOTIFICATION_USER_SUCCESS";
export const SWITCH_NOTIFICATION_USER_ERROR = "SWITCH_NOTIFICATION_USER_ERROR";
export const RESET_SWITCH_NOTIFICATION_USER = "RESET_SWITCH_NOTIFICATION_USER";

//Get All USer Roll(Account)
export const GET_ALLUSER_ROLL_LOADING = "GET_ALLUSER_ROLL_LOADING";
export const GET_ALLUSER_ROLL_SUCCESS = "GET_ALLUSER_ROLL_SUCCESS";
export const GET_ALLUSER_ROLL_ERROR = "GET_ALLUSER_ROLL_ERROR";

//Add Account
export const ADD_ACCOUNT_LOADING = "ADD_ACCOUNT_LOADING";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_ERROR = "ADD_ACCOUNT_ERROR";
export const RESET_ADDACCOUNT = "RESET_ADDACCOUNT";

//GET ALL SEASON
export const GET_ALL_SEASON_LOADING = "GET_ALL_SEASON_LOADING";
export const GET_ALL_SEASON_ERROR = "GET_ALL_SEASON_ERROR";
export const GET_ALL_SEASON_SUCCESS = "GET_ALL_SEASON_SUCCESS";
export const RESET_GET_ALL_SEASON = "RESET_GET_ALL_SEASON";

//CREATE SEASON
export const CREATE_SEASON_LOADING = "CREATE_SEASON_LOADING";
export const CREATE_SEASON_ERROR = "CREATE_SEASON_ERROR";
export const CREATE_SEASON_SUCCESS = "CREATE_SEASON_SUCCESS";
export const RESET_CREATE_SEASON = "RESET_CREATE_SEASON";

//EDIT SEASON
export const EDIT_SEASON_LOADING = "EDIT_SEASON_LOADING";
export const EDIT_SEASON_SUCCESS = "EDIT_SEASON_SUCCESS";
export const EDIT_SEASON_ERROR = "EDIT_SEASON_ERROR";
export const RESET_EDIT_SEASON = "RESET_EDIT_SEASON";

//EDIT SEASON
export const DELETE_SEASON_LOADING = "DELETE_SEASON_LOADING";
export const DELETE_SEASON_SUCCESS = "DELETE_SEASON_SUCCESS";
export const DELETE_SEASON_ERROR = "DELETE_SEASON_ERROR";
export const RESET_DELETE_SEASON = "RESET_DELETE_SEASON";

//GET INVITE DATA[POST method ]
export const GET_INVITE_DATA_LOADING = "GET_INVITE_DATA_LOADING";
export const GET_INVITE_DATA_SUCCESS = "GET_INVITE_DATA_SUCCESS";
export const GET_INVITE_DATA_ERROR = "GET_INVITE_DATA_ERROR";

//POST Invite User Data
export const SIGNUP_BY_MAIL_LOADING = "SIGNUP_BY_MAIL_LOADING";
export const SIGNUP_BY_MAIL_SUCCESS = "SIGNUP_BY_MAIL_SUCCESS";
export const SIGNUP_BY_MAIL_ERROR = "SIGNUP_BY_MAIL_ERROR";
export const RESET_SIGNUP_BY_MAIL = "RESET_SIGNUP_BY_MAIL";

//GET all State by conutry _id
export const GET_STATE_LOADING = "GET_STATE_LOADING";
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";
export const GET_STATE_ERROR = "GET_STATE_ERROR";

//GET all State by conutry _id
export const GET_SPORT_LOADING = "GET_SPORT_LOADING";
export const GET_SPORT_SUCCESS = "GET_SPORT_SUCCESS";
export const GET_SPORT_ERROR = "GET_SPORT_ERROR";

//GET all City
export const GET_CITY_LOADING = "GET_CITY_LOADING";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_ERROR = "GET_CITY_ERROR";

//GET all Discount
export const GET_DISCOUNT_CODE_LOADING = "GET_DISCOUNT_CODE_LOADING";
export const GET_DISCOUNT_CODE_SUCCESS = "GET_DISCOUNT_CODE_SUCCESS";
export const GET_DISCOUNT_CODE_ERROR = "GET_DISCOUNT_CODE_ERROR";
export const RESET_DISCOUNT_CODE = "RESET_DISCOUNT_CODE";

//POST create discount code
export const CREATE_DISCOUNT_CODE_LOADING = "CREATE_DISCOUNT_CODE_LOADING";
export const CREATE_DISCOUNT_CODE_SUCCESS = "CREATE_DISCOUNT_CODE_SUCCESS";
export const CREATE_DISCOUNT_CODE_ERROR = "CREATE_DISCOUNT_CODE_ERROR";
export const RESET_CREATE_DISCOUNT_CODE = "RESET_CREATE_DISCOUNT_CODE";

//POST Edit discount code
export const EDIT_DISCOUNT_CODE_LOADING = "EDIT_DISCOUNT_CODE_LOADING";
export const EDIT_DISCOUNT_CODE_SUCCESS = "EDIT_DISCOUNT_CODE_SUCCESS";
export const EDIT_DISCOUNT_CODE_ERROR = "EDIT_DISCOUNT_CODE_ERROR";
export const RESET_EDIT_DISCOUNT_CODE = "RESET_EDIT_DISCOUNT_CODE";

//GET Account Details
export const GET_ACCOUNT_DETAILS_LOADING = "GET_ACCOUNT_DETAILS_LOADING";
export const GET_ACCOUNT_DETAILS_SUCCESS = "GET_ACCOUNT_DETAILS_SUCCESS";
export const GET_ACCOUNT_DETAILS_ERROR = "GET_ACCOUNT_DETAILS_ERROR";

//Edit Account Details
export const EDIT_ACCOUNT_DETAILS_LOADING = "EDIT_ACCOUNT_DETAILS_LOADING";
export const EDIT_ACCOUNT_DETAILS_SUCCESS = "EDIT_ACCOUNT_DETAILS_SUCCESS";
export const EDIT_ACCOUNT_DETAILS_ERROR = "EDIT_ACCOUNT_DETAILS_ERROR";
export const RESET_ACCOUNT_DETAILS = "RESET_ACCOUNT_DETAILS";

//Create Division
export const CREATE_DIVISION_LOADING = "CREATE_DIVISION_LOADING";
export const CREATE_DIVISION_SUCCESS = "CREATE_DIVISION_SUCCESS";
export const CREATE_DIVISION_ERROR = "CREATE_DIVISION_ERROR";
export const RESET_CREATE_DIVISION = "RESET_CREATE_DIVISION";

//Edit Division
export const EDIT_DIVISION_LOADING = "EDIT_DIVISION_LOADING";
export const EDIT_DIVISION_SUCCESS = "EDIT_DIVISION_SUCCESS";
export const EDIT_DIVISION_ERROR = "EDIT_DIVISION_ERROR";
export const RESET_EDIT_DIVISION = "RESET_EDIT_DIVISION";

//Get All Division
export const GET_ALLDIVISION_LOADING = "GET_ALLDIVISION_LOADING";
export const GET_ALLDIVISION_SUCCESS = "GET_ALLDIVISION_SUCCESS";
export const GET_ALLDIVISION_ERROR = "GET_ALLDIVISION_ERROR";

//Create Pool
export const CREATE_POOL_LOADING = "CREATE_POOL_LOADING";
export const CREATE_POOL_SUCCESS = "CREATE_POOL_SUCCESS";
export const CREATE_POOL_ERROR = "CREATE_POOL_ERROR";
export const RESET_CREATE_POOL = "RESET_CREATE_POOL";

//Edit Pool
export const EDIT_POOL_LOADING = "EDIT_POOL_LOADING";
export const EDIT_POOL_SUCCESS = "EDIT_POOL_SUCCESS";
export const EDIT_POOL_ERROR = "EDIT_POOL_ERROR";
export const RESET_EDIT_POOL = "RESET_EDIT_POOL";

//Create Team
export const CREATE_TEAM_LOADING = "CREATE_TEAM_LOADING";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_ERROR = "CREATE_TEAM_ERROR";
export const RESET_CREATE_TEAM = "RESET_CREATE_TEAM";

//Get All club(club list)
export const GET_ALLCLUB_LOADING = "GET_ALLCLUB_LOADING";
export const GET_ALLCLUB_SUCCESS = "GET_ALLCLUB_SUCCESS";
export const GET_ALLCLUB_ERROR = "GET_ALLCLUB_ERROR";

//Get Event staff(event staff list)
export const GET_EVENTSTAFF_LOADING = "GET_EVENTSTAFF_LOADING";
export const GET_EVENTSTAFF_SUCCESS = "GET_EVENTSTAFF_SUCCESS";
export const GET_EVENTSTAFF_ERROR = "GET_EVENTSTAFF_ERROR";

export const GET_EVENTACTIVITYLOG_LOADING = "GET_EVENTACTIVITYLOG_LOADING";
export const GET_EVENTACTIVITYLOG_SUCCESS = "GET_EVENTACTIVITYLOG_SUCCESS";
export const GET_EVENTACTIVITYLOG_ERROR = "GET_EVENTACTIVITYLOG_ERROR";

//Delete Event Org
export const DELETE_EVENT_LOADING = "DELETE_EVENT_LOADING";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";
export const RESET_DELETE_EVENT = "RESET_DELETE_EVENT";

//Clon Eevent Org
export const CLON_EVENT_LOADING = "CLON_EVENT_LOADING";
export const CLON_EVENT_SUCCESS = "CLON_EVENT_SUCCESS";
export const CLON_EVENT_ERROR = "CLON_EVENT_ERROR";
export const RESET_CLON_EVENT = "RESET_CLON_EVENT";

//Get Event Schedule
export const GET_EVENTSCHEDULE_LOADING = "GET_EVENTSCHEDULE_LOADING";
export const GET_EVENTSCHEDULE_SUCCESS = "GET_EVENTSCHEDULE_SUCCESS";
export const GET_EVENTSCHEDULE_ERROR = "GET_EVENTSCHEDULE_ERROR";

//Delete Schedule Org
export const DELETE_SCHEDULE_LOADING = "DELETE_SCHEDULE_LOADING";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_ERROR = "DELETE_SCHEDULE_ERROR";
export const RESET_DELETE_SCHEDULE = "RESET_DELETE_SCHEDULE";

//Delete Pool Org
export const DELETE_POOL_LOADING = "DELETE_POOL_LOADING";
export const DELETE_POOL_SUCCESS = "DELETE_POOL_SUCCESS";
export const DELETE_POOL_ERROR = "DELETE_POOL_ERROR";
export const RESET_DELETE_POOL = "RESET_DELETE_POOL";

//Delete Division Org
export const DELETE_DIVISION_LOADING = "DELETE_DIVISION_LOADING";
export const DELETE_DIVISION_SUCCESS = "DELETE_DIVISION_SUCCESS";
export const DELETE_DIVISION_ERROR = "DELETE_DIVISION_ERROR";
export const RESET_DELETE_DIVISION = "RESET_DELETE_DIVISION";

//Delete EVENT Team Org
export const DELETE_EVENTTEAM_LOADING = "DELETE_EVENTTEAM_LOADING";
export const DELETE_EVENTTEAM_SUCCESS = "DELETE_EVENTTEAM_SUCCESS";
export const DELETE_EVENTTEAM_ERROR = "DELETE_EVENTTEAM_ERROR";
export const RESET_DELETE_EVENTTEAM = "RESET_DELETE_EVENTTEAM";

//Add to Book mark
export const BOOKMARK_EVENT_LOADING = "BOOKMARK_EVENT_LOADING";
export const BOOKMARK_EVENT_ERROR = "BOOKMARK_EVENT_ERROR";
export const BOOKMARK_EVENT_SUCCESS = "BOOKMARK_EVENT_SUCCESS";
export const RESET_BOOKMARK = "RESET_BOOKMARK";

//GET Free Team
export const GET_FREETEAM_LOADING = "GET_FREETEAM_LOADING";
export const GET_FREETEAM_SUCCESS = "GET_FREETEAM_SUCCESS";
export const GET_FREETEAM_ERROR = "GET_FREETEAM_ERROR";

//GET Event Team
export const GET_EVENT_TEAM_LOADING = "GET_EVENT_TEAM_LOADING";
export const GET_EVENT_TEAM_SUCCESS = "GET_EVENT_TEAM_SUCCESS";
export const GET_EVENT_TEAM_ERROR = "GET_EVENT_TEAM_ERROR";

//Put Update Free team
export const UPDATE_FREETEAM_LOADING = "UPDATE_FREETEAM_LOADING";
export const UPDATE_FREETEAM_SUCCESS = "UPDATE_FREETEAM_SUCCESS";
export const UPDATE_FREETEAM_ERROR = "UPDATE_FREETEAM_ERROR";
export const RESET_FREETEAM = "UPDATE_FREETEAM";

//remove pool Team
export const REMOVE_POOLTEAM_LOADING = "REMOVE_POOLTEAM_LOADING";
export const REMOVE_POOLTEAM_SUCCESS = "REMOVE_POOLTEAM_SUCCESS";
export const REMOVE_POOLTEAM_ERROR = "REMOVE_POOLTEAM_ERROR";
export const RESET_REMOVE_POOLTEAM = "RESET_REMOVE_POOLTEAM";

//remove pool Player
export const REMOVE_POOL_PLAYER_LOADING = "REMOVE_POOL_PLAYER_LOADING";
export const REMOVE_POOL_PLAYER_SUCCESS = "REMOVE_POOL_PLAYER_SUCCESS";
export const REMOVE_POOL_PLAYER_ERROR = "REMOVE_POOL_PLAYER_ERROR";
export const RESET_REMOVE_POOL_PLAYER = "RESET_REMOVE_POOL_PLAYER";

//create liability in organiser
//CREATE Liability
export const CREATE_LIABILITY_LOADING = "CREATE_LIABILITY_LOADING";
export const CREATE_LIABILITY_ERROR = "CREATE_LIABILITY_ERROR";
export const CREATE_LIABILITY_SUCCESS = "CREATE_LIABILITY_SUCCESS";
export const RESET_CREATE_LIABILITY = "RESET_CREATE_LIABILITY";

//CREATE New Group
export const CREATE_NEW_GROUP_LOADING = "CREATE_NEW_GROUP_LOADING";
export const CREATE_NEW_GROUP_ERROR = "CREATE_NEW_GROUP_ERROR";
export const CREATE_NEW_GROUP_SUCCESS = "CREATE_NEW_GROUP_SUCCESS";
export const RESET_CREATE_NEW_GROUP = "RESET_CREATE_NEW_GROUP";

//GET Event Team
export const GET_ALL_GROUPS_LOADING = "GET_ALL_GROUPS_LOADING";
export const GET_ALL_GROUPS_SUCCESS = "GET_ALL_GROUPS_SUCCESS";
export const GET_ALL_GROUPS_ERROR = "GET_ALL_GROUPS_ERROR";

//ADD Staff to Group
export const ADD_STAFF_GROUP_LOADING = "ADD_STAFF_GROUP_LOADING";
export const ADD_STAFF_GROUP_SUCCESS = "ADD_STAFF_GROUP_SUCCESS";
export const ADD_STAFF_GROUP_ERROR = "ADD_STAFF_GROUP_ERROR";
export const RESET_ADD_STAFF_GROUP = "RESET_ADD_STAFF_GROUP";

//ADD Staff to Group
export const PERMISSION_LOADING = "PERMISSION_LOADING";
export const PERMISSION_SUCCESS = "PERMISSION_SUCCESS";
export const PERMISSION_ERROR = "PERMISSION_ERROR";
export const RESET_PERMISSION = "RESET_PERMISSION";

//GET Event Team
export const GET_PERMISSION_DETAILS_LOADING = "GET_PERMISSION_DETAILS_LOADING";
export const GET_PERMISSION_DETAILS_SUCCESS = "GET_PERMISSION_DETAILS_SUCCESS";
export const GET_PERMISSION_DETAILS_ERROR = "GET_PERMISSION_DETAILS_ERROR";

//GET Event Team
export const GET_EVENTS_REGISTER_TEAM_LOADING =
  " GET_EVENTS_REGISTER_TEAM_LOADING";
export const GET_EVENTS_REGISTER_TEAM_SUCCESS =
  " GET_EVENTS_REGISTER_TEAM_SUCCESS";
export const GET_EVENTS_REGISTER_TEAM_ERROR = " GET_EVENTS_REGISTER_TEAM_ERROR";

//GET Pending Amount
export const GET_PENDING_AMOUNT_LOADING = " GET_PENDING_AMOUNT_LOADING";
export const GET_PENDING_AMOUNT_SUCCESS = " GET_PENDING_AMOUNT_SUCCESS";
export const GET_PENDING_AMOUNT_ERROR = " GET_PENDING_AMOUNT_ERROR";

//GET Pending Amount
export const GET_PAYMENT_REQUEST_LOADING = " GET_PAYMENT_REQUEST_LOADING";
export const GET_PAYMENT_REQUEST_SUCCESS = " GET_PAYMENT_REQUEST_SUCCESS";
export const GET_PAYMENT_REQUEST_ERROR = " GET_PAYMENT_REQUEST_ERROR";
export const RESET_GET_PAYMENT_REQUEST = "RESET_GET_PAYMENT_REQUEST";

//GET Liability
export const GET_LIABILITY_LOADING = " GET_LIABILITY_LOADING";
export const GET_LIABILITY_SUCCESS = " GET_LIABILITY_SUCCESS";
export const GET_LIABILITY_ERROR = " GET_LIABILITY_ERROR";

//payment request [post]
export const PAYMENT_REQUEST_LOADING = "PAYMENT_REQUEST_LOADING";
export const PAYMENT_REQUEST_SUCCESS = "PAYMENT_REQUEST_SUCCESS";
export const PAYMENT_REQUEST_ERROR = "PAYMENT_REQUEST_ERROR";
export const RESET_PAYMENT_REQUEST = "RESET_PAYMENT_REQUEST";

//GET Team Groups
export const GET_TEAM_GROUPS_LOADING = "GET_TEAM_GROUPS_LOADING";
export const GET_TEAM_GROUPS_SUCCESS = "GET_TEAM_GROUPS_SUCCESS";
export const GET_TEAM_GROUPS_ERROR = "GET_TEAM_GROUPS_ERROR";

//add Player to Groups
export const ADD_PLAYER_TO_GROUP_LOADING = "ADD_PLAYER_TO_GROUP_LOADING";
export const ADD_PLAYER_TO_GROUP_SUCCESS = "ADD_PLAYER_TO_GROUP_SUCCESS";
export const ADD_PLAYER_TO_GROUP_ERROR = "ADD_PLAYER_TO_GROUP_ERROR";
export const RESET_ADD_PLAYER_GROUP = "RESET_ADD_PLAYER_GROUP";

export const REMOVE_PLAYER_LOADING = "REMOVE_PLAYER_LOADING";
export const REMOVE_PLAYER_SUCCESS = "REMOVE_PLAYER_SUCCESS";
export const REMOVE_PLAYER_ERROR = "REMOVE_PLAYER_ERROR";
export const RESET_DELETE_PLAYER = "RESET_DELETE_PLAYER";

export const EDIT_GROUP_LOADING = "EDIT_GROUP_LOADING";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";
export const EDIT_GROUP_ERROR = "EDIT_GROUP_ERROR";
export const RESET_EDIT_GROUP = "RESET_EDIT_GROUP";

export const DELETE_GROUP_LOADING = "DELETE_GROUP_LOADING";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_ERROR = "DELETE_GROUP_ERROR";
export const RESET_DELETE_GROUP = "RESET_DELETE_GROUP";

export const GET_PAID_RECIPIENT_LOADING = "GET_PAID_RECIPIENT_LOADING";
export const GET_PAID_RECIPIENT_SUCCESS = "GET_PAID_RECIPIENT_SUCCESS";
export const GET_PAID_RECIPIENT_ERROR = "GET_PAID_RECIPIENT_ERROR";
export const RESET_GET_PAID_RECIPIENT = "RESET_GET_PAID_RECIPIENT";

//Create Credit
export const CREATE_CREDIT_LOADING = "CREATE_CREDIT_LOADING";
export const CREATE_CREDIT_SUCCESS = "CREATE_CREDIT_SUCCESS";
export const CREATE_CREDIT_ERROR = "CREATE_CREDIT_ERROR";
export const RESET_CREATE_CREDIT = "RESET_CREATE_CREDIT";

//get Credit
export const GET_CREDIT_LOADING = "GET_CREDIT_LOADING";
export const GET_CREDIT_SUCCESS = "GET_CREDIT_SUCCESS";
export const GET_CREDIT_ERROR = "GET_CREDIT_ERROR";
export const RESET_GET_CREDIT = "RESET_GET_CREDIT";

//get Payment in create Refund
export const GET_REFUND_LOADING = "GET_REFUND_LOADING";
export const GET_REFUND_SUCCESS = "GET_REFUND_SUCCESS";
export const GET_REFUND_ERROR = "GET_REFUND_ERROR";
export const RESET_GET_REFUND = "RESET_GET_REFUND";

//get bulk data
export const BULK_DATA_LOADING = "BULK_DATA_LOADING";
export const BULK_DATA_SUCCESS = "BULK_DATA_SUCCESS";
export const BULK_DATA_ERROR = "BULK_DATA_ERROR";
export const BULK_DATA_REQUEST = "BULK_DATA_REQUEST";

//get parentplayer data
export const BULK_PARENT_PLAYER_DATA_LOADING =
  "BULK_PARENT_PLAYER_DATA_LOADING";
export const BULK_PARENT_PLAYER_DATA_SUCCESS =
  "BULK_PARENT_PLAYER_DATA_SUCCESS";
export const BULK_PARENT_PLAYER_DATA_ERROR = "BULK_PARENT_PLAYER_DATA_ERROR";
export const BULK_PARENT_PLAYER_DATA_REQUEST =
  "BULK_PARENT_PLAYER_DATA_REQUEST";

//get payment reference number
export const GET_REFERENCE_NUMBER_LOADING = "GET_REFERENCE_NUMBER_LOADING";
export const GET_REFERENCE_NUMBER_SUCCESS = "GET_REFERENCE_NUMBER_SUCCESS";
export const GET_REFERENCE_NUMBER_ERROR = "GET_REFERENCE_NUMBER_ERROR";

//get  all Refunds
export const GET_REFUNDS_LOADING = "GET_REFUNDS_LOADING";
export const GET_REFUNDS_SUCCESS = "GET_REFUNDS_SUCCESS";
export const GET_REFUNDS_ERROR = "GET_REFUNDS_ERROR";

//get Organizer Request in Club
export const GET_ORG_REQUEST_LOADING = "GET_ORG_REQUEST_LOADING";
export const GET_ORG_REQUEST_SUCCESS = "GET_ORG_REQUEST_SUCCESS";
export const GET_ORG_REQUEST_ERROR = "GET_ORG_REQUEST_ERROR";

//send Image in Event [POST]
export const SEND_IMAGE_LOADING = "SEND_IMAGE_LOADING";
export const SEND_IMAGE_SUCCESS = "SEND_IMAGE_SUCCESS";
export const SEND_IMAGE_ERROR = "SEND_IMAGE_ERROR";
export const RESET_SEND_IMAGE = "RESET_SEND_IMAGE";

//Receive Payment in Report[Get]
export const GET_RECEIVE_REPORT_LOADING = "GET_RECEIVE_REPORT_LOADING";
export const GET_RECEIVE_REPORT_SUCCESS = "GET_RECEIVE_REPORT_SUCCESS";
export const GET_RECEIVE_REPORT_ERROR = "GET_RECEIVE_REPORT_ERROR";
export const RESET_RECEIVE_REPORT = "RESET_RECEIVE_REPORT";

//Receive Payment in Report[Get]
export const GET_OUTSTANDING_REPORT_LOADING = "GET_OUTSTANDING_REPORT_LOADING";
export const GET_OUTSTANDING_REPORT_SUCCESS = "GET_OUTSTANDING_REPORT_SUCCESS";
export const GET_OUTSTANDING_REPORT_ERROR = "GET_OUTSTANDING_REPORT_ERROR";
export const RESET_OUTSTANDING_REPORT = "RESET_OUTSTANDING_REPORT";

//Refund Payment in Report[Get]
export const GET_REFUND_REPORT_LOADING = "GET_REFUND_REPORT_LOADING";
export const GET_REFUND_REPORT_SUCCESS = "GET_REFUND_REPORT_SUCCESS";
export const GET_REFUND_REPORT_ERROR = "GET_REFUND_REPORT_ERROR";
export const RESET_REFUND_REPORT = "RESET_REFUND_REPORT";

//Refund Payment in Report[Get]
export const GET_CLUB_ORGANIZER_REFUND_LOADING =
  "GET_CLUB_ORGANIZER_REFUND_LOADING";
export const GET_CLUB_ORGANIZER_REFUND_SUCCESS =
  "GET_CLUB_ORGANIZER_REFUND_SUCCESS";
export const GET_CLUB_ORGANIZER_REFUND_ERROR =
  "GET_CLUB_ORGANIZER_REFUND_ERROR";
export const RESET_CLUB_ORGANIZER_REFUND_REPORT =
  "RESET_CLUB_ORGANIZER_REFUND_REPORT";

//get DashBoard Payment[Get]
export const GET_DASHBOARD_PAYMENT_LOADING = "GET_DASHBOARD_PAYMENT_LOADING";
export const GET_DASHBOARD_PAYMENT_SUCCESS = "GET_DASHBOARD_PAYMENT_SUCCESS";
export const GET_DASHBOARD_PAYMENT_ERROR = "GET_DASHBOARD_PAYMENT_ERROR";

//connected user club [GET]
export const GET_CONNECTED_USER_LOADING = "GET_CONNECTED_USER_LOADING";
export const GET_CONNECTED_USER_SUCCESS = "GET_CONNECTED_USER_SUCCESS";
export const GET_CONNECTED_USER_ERROR = "GET_CONNECTED_USER_ERROR";
export const RESET_GET_CONNECTED = "RESET_GET_CONNECTED";

//getAll Payment in Report[Get]
export const GET_ALL_REPORT_LOADING = "GET_ALL_REPORT_LOADING";
export const GET_ALL_REPORT_SUCCESS = "GET_ALL_REPORT_SUCCESS";
export const GET_ALL_REPORT_ERROR = "GET_ALL_REPORT_ERROR";
export const RESET_ALL_REPORT = "RESET_ALL_REPORT";

//get organizer staff [GET]
export const GET_ORG_STAFF_LIST_LOADING = "GET_ORG_STAFF_LIST_LOADING";
export const GET_ORG_STAFF_LIST_SUCCESS = "GET_ORG_STAFF_LIST_SUCCESS";
export const GET_ORG_STAFF_LIST_ERROR = "GET_ORG_STAFF_LIST_ERROR";

//get admin list
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_ERROR = "GET_ADMIN_ERROR";
export const GET_ADMIN_LIST_LOADING = "GET_ADMIN_LIST_LOADING";

//get event details by id
export const GET_EVENT_DETAILS_LOADING = "GET_EVENT_DETAILS_LOADING";
export const GET_EVENT_DETAILS_SUCCESS = "GET_EVENT_DETAILS_SUCCESS";
export const GET_EVENT_DETAILS_ERROR = "GET_EVENT_DETAILS_ERROR";

//send invitaion to staff by mail
export const SEND_INVITATION_EMAIL_LOADING = "SEND_INVITATION_EMAIL_LOADING";
export const SEND_INVITATION_EMAIL_SUCCESS = "SEND_INVITATION_EMAIL_SUCCESS";
export const SEND_INVITATION_EMAIL_ERROR = "SEND_INVITATION_EMAIL_ERROR";
export const RESET_SEND_INVITATION_EMAIL = "RESET_SEND_INVITATION_EMAIL";

//single Chat

export const GET_SINGLE_CHAT_LOADING = "GET_SINGLE_CHAT_LOADING";
export const GET_SINGLE_CHAT_SUCCESS = "GET_SINGLE_CHAT_SUCCESS";
export const GET_SINGLE_CHAT_ERROR = "GET_SINGLE_CHAT_ERROR";
export const GET_SINGLE_CHAT_RESET = "GET_SINGLE_CHAT_RESET";

//send broadcast Chat
export const SEND_BROADCAST_LOADING = "SEND_BROADCAST_LOADING";
export const SEND_BROADCAST_SUCCESS = "SEND_BROADCAST_SUCCESS";
export const SEND_BROADCAST_ERROR = "SEND_BROADCAST_ERROR";
export const RESET_SEND_BROADCAST = "RESET_SEND_BROADCAST";

//Publish schedule
export const PUBLISH_SCHEDULE_LOADING = "PUBLISH_SCHEDULE_LOADING";
export const PUBLISH_SCHEDULE_SUCCESS = "PUBLISH_SCHEDULE_SUCCESS";
export const PUBLISH_SCHEDULE_ERROR = "PUBLISH_SCHEDULE_ERROR";
export const RESET_PUBLISH_SCHEDULE = "RESET_PUBLISH_SCHEDULE";

//reminder payment
export const REMINDER_LOADING = "REMINDER_LOADING";
export const REMINDER_SUCCESS = "REMINDER_SUCCESS";
export const REMINDER_ERROR = "REMINDER_ERROR";
export const REMINDER_RESET = "REMINDER_RESET";

export const GET_TEAM_DETAIL_LOADING = "GET_TEAM_DETAIL_LOADING";
export const GET_TEAM_DETAIL_SUCCESS = "GET_TEAM_DETAIL_SUCCESS";
export const GET_TEAM_DETAIL_ERROR = "GET_TEAM_DETAIL_ERROR";
export const RESET_TEAM_DETAIL_ERROR = "RESET_TEAM_DETAIL_ERROR";

export const ACCOUNTING_LOADING = "ACCOUNTING_LOADING";
export const ACCOUNTING_SUCCESS = "ACCOUNTING_SUCCESS";
export const ACCOUNTING_ERROR = "ACCOUNTING_ERROR";

//   <<<<<<<<<<<<<<<-------------------------Club Constant ------------------------->>>>>>>>>>>>>>>

export const CREATE_ORG_TEAM_LOADING = "CREATE_ORG_TEAM_LOADING";
export const CREATE_ORG_TEAM_SUCCESS = "CREATE_ORG_TEAM_SUCCESS";
export const CREATE_ORG_TEAM_ERROR = "CREATE_ORG_TEAM_ERROR";
export const RESET_CREATE_ORG_TEAM = "RESET_CREATE_ORG_TEAM";

export const GET_ORG_ALL_SEASON_LOADING = "GET_ORG_ALL_SEASON_LOADING";
export const GET_ORG_ALL_SEASON_ERROR = "GET_ORG_ALL_SEASON_ERROR";
export const GET_ORG_ALL_SEASON_SUCCESS = "GET_ORG_ALL_SEASON_SUCCESS";
export const RESET_GET_ORG_ALL_SEASON = "RESET_GET_ORG_ALL_SEASON";

export const CREATE_ORG_SEASON_LOADING = "CREATE_ORG_SEASON_LOADING";
export const CREATE_ORG_SEASON_ERROR = "CREATE_ORG_SEASON_ERROR";
export const CREATE_ORG_SEASON_SUCCESS = "CREATE_ORG_SEASON_SUCCESS";
export const RESET_CREATE_ORG_SEASON = "RESET_CREATE_ORG_SEASON";

export const SEND_ORG_INVITATION_EMAIL_LOADING =
  "SEND_ORG_INVITATION_EMAIL_LOADING";
export const SEND_ORG_INVITATION_EMAIL_SUCCESS =
  "SEND_ORG_INVITATION_EMAIL_SUCCESS";
export const SEND_ORG_INVITATION_EMAIL_ERROR =
  "SEND_ORG_INVITATION_EMAIL_ERROR";
export const RESET_SEND_ORG_INVITATION_EMAIL =
  "RESET_SEND_ORG_INVITATION_EMAIL";

//get All Club Event
export const GET_ALLCLUBEVENT_LOADING = "GET_ALLCLUBEVENT_LOADING";
export const GET_ALLCLUBEVENT_SUCCESS = "GET_ALLCLUBEVENT_SUCCESS";
export const GET_ALLCLUBEVENT_ERROR = "GET_ALLCLUBEVENT_ERROR";

//get All Club Event
export const GET_ALLCLUBSEASON_LOADING = "GET_ALLCLUBSEASON_LOADING";
export const GET_ALLCLUBSEASON_SUCCESS = "GET_ALLCLUBSEASON_SUCCESS";
export const GET_ALLCLUBSEASON_ERROR = "GET_ALLCLUBSEASON_ERROR";

//GET Club All Team
export const GET_CLUB_TEAM_LOADING = "GET_CLUB_TEAM_LOADING";
export const GET_CLUB_TEAM_SUCCESS = "GET_CLUB_TEAM_SUCCESS";
export const GET_CLUB_TEAM_ERROR = "GET_CLUB_TEAM_ERROR";

//GET Club Team by id
export const GET_CLUB_TEAMBY_ID_LOADING = "GET_CLUB_TEAMBY_ID_LOADING";
export const GET_CLUB_TEAMBY_ID_SUCCESS = "GET_CLUB_TEAMBY_ID_SUCCESS";
export const GET_CLUB_TEAMBY_ID_ERROR = "GET_CLUB_TEAMBY_ID_ERROR";

//Edit Club Team by id
export const EDIT_CLUB_TEAM_LOADING = "EDIT_CLUB_TEAM_LOADING";
export const EDIT_CLUB_TEAM_SUCCESS = "EDIT_CLUB_TEAM_SUCCESS";
export const EDIT_CLUB_TEAM_ERROR = "EDIT_CLUB_TEAM_ERROR";
export const RESET_EDIT_CLUB_TEAM = "RESET_EDIT_CLUB_TEAM";

//GET Player invitaion
export const GET_PLAYER_INVITAION_LOADING = "GET_PLAYER_INVITAION_LOADING";
export const GET_PLAYER_INVITAION_SUCCESS = "GET_PLAYER_INVITAION_SUCCESS";
export const GET_PLAYER_INVITAION_ERROR = "GET_PLAYER_INVITAION_ERROR";

//GET ALL Field
export const GET_FIELD_LOADING = "GET_FIELD_LOADING";
export const GET_FIELD_SUCCESS = "GET_FIELD_SUCCESS";
export const GET_FIELD_ERROR = "GET_FIELD_ERROR";

//Create league
export const CREATE_LEAGUE_LOADING = "CREATE_LEAGUE_LOADING";
export const CREATE_LEAGUE_SUCCESS = "CREATE_LEAGUE_SUCCESS";
export const CREATE_LEAGUE_ERROR = "CREATE_LEAGUE_ERROR";
export const RESET_LEAGUE = "RESET_LEAGUE";
//Update league
export const UPDATE_LEAGUE_LOADING = "UPDATE_LEAGUE_LOADING";
export const UPDATE_LEAGUE_SUCCESS = "UPDATE_LEAGUE_SUCCESS";
export const UPDATE_LEAGUE_ERROR = "UPDATE_LEAGUE_ERROR";
export const UPDATE_RESET_LEAGUE = "UPDATE_RESET_LEAGUE";

//get League
export const GET_ALL_LEAGUE_LOADING = "GET_ALL_LEAGUE_LOADING";
export const GET_ALL_LEAGUE_SUCCESS = "GET_ALL_LEAGUE_SUCCESS";
export const GET_ALL_LEAGUE_ERROR = "GET_ALL_LEAGUE_ERROR";

//bank details [Post] club
export const CREATE_BANKDETAILS_LOADING = "CREATE_BANKDETAILS_LOADING";
export const CREATE_BANKDETAILS_SUCCESS = "CREATE_BANKDETAILS_SUCCESS";
export const CREATE_BANKDETAILS_ERROR = "CREATE_BANKDETAILS_ERROR";
export const RESET_BANKDETAILS = "RESET_BANKDETAILS";

// Get all banks[GET] Club
export const GET_BANKS_LOADING = "GET_BANKS_LOADING";
export const GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS";
export const GET_BANKS_ERROR = "GET_BANKS_ERROR";

//Delete bank details
export const DELETE_BANK_LOADING = "DELETE_BANK_LOADING";
export const DELETE_BANK_SUCCESS = "DELETE_BANK_SUCCESS";
export const DELETE_BANK_ERROR = "DELETE_BANK_ERROR";
export const RESET_BANK = "RESET_BANK";

//EDIT BANK details[PUT]
export const EDIT_BANK_LOADING = "EDIT_BANK_LOADING";
export const EDIT_BANK_SUCCESS = "EDIT_BANK_SUCCESS";
export const EDIT_BANK_ERROR = "EDIT_BANK_ERROR";
export const RESET_EDIT_BANK = "RESET_EDIT_BANK";

//send coach invitaion [Post] club
export const SEND_COACH_INVITATION_LOADING = "SEND_COACH_INVITATION_LOADING";
export const SEND_COACH_INVITATION_SUCCESS = "SEND_COACH_INVITATION_SUCCESS";
export const SEND_COACH_INVITATION_ERROR = "SEND_COACH_INVITATION_ERROR";
export const RESET_SEND_COACH_INVITATION = "RESET_SEND_COACH_INVITATION";

//get Team details [Get]club

//accept player [put]club
export const ACCEPT_PLAYER_LOADING = "ACCEPT_PLAYER_LOADING";
export const ACCEPT_PLAYER_SUCCESS = "ACCEPT_PLAYER_SUCCESS";
export const ACCEPT_PLAYER_ERROR = "ACCEPT_PLAYER_ERROR";
export const RESET_ACCEPT_PLAYER = "RESET_ACCEPT_PLAYER";

//accept player [put]club
export const ACCEPT_COACH_LOADING = "ACCEPT_COACH_LOADING";
export const ACCEPT_COACH_SUCCESS = "ACCEPT_COACH_SUCCESS";
export const ACCEPT_COACH_ERROR = "ACCEPT_COACH_ERROR";
export const RESET_ACCEPT_COACH = "RESET_ACCEPT_COACH";

//get Coach Invitation [get]
export const GET_COACH_INVITATION_LOADING = "GET_COACH_INVITATION_LOADING";
export const GET_COACH_INVITATION_SUCCESS = "GET_COACH_INVITATION_SUCCESS";
export const GET_COACH_INVITATION_ERROR = "GET_COACH_INVITATION_ERROR";

//getAllClub [get]
export const GET_ALL_INVITATION_LOADING = "GET_ALL_INVITATION_LOADING";
export const GET_ALL_INVITATION_SUCCESS = "GET_ALL_INVITATION_SUCCESS";
export const GET_ALL_INVITATION_ERROR = "GET_ALL_INVITATION_ERROR";

//getOrgClub [get]
export const GET_ORG_CLUB_LOADING = "GET_ORG_CLUB_LOADING";
export const GET_ORG_CLUB_SUCCESS = "GET_ORG_CLUB_SUCCESS";
export const GET_ORG_CLUB_ERROR = "GET_ORG_CLUB_ERROR";
export const RESET_ORG_CLUB = "RESET_ORG_CLUB";

//getOrgClubDetails [get]
export const GET_ORG_CLUB_DETAILS_LOADING = "GET_ORG_CLUB_DETAILS_LOADING";
export const GET_ORG_CLUB_DETAILS_SUCCESS = "GET_ORG_CLUB_DETAILS_SUCCESS";
export const GET_ORG_CLUB_DETAILS_ERROR = "GET_ORG_CLUB_DETAILS_ERROR";

//getOrgClubDetails [get]
export const GET_ORG_CLUB_TEAM_DETAILS_LOADING =
  "GET_ORG_CLUB_TEAM_DETAILS_LOADING";
export const GET_ORG_CLUB_TEAM_DETAILS_SUCCESS =
  "GET_ORG_CLUB_TEAM_DETAILS_SUCCESS";
export const GET_ORG_CLUB_TEAM_DETAILS_ERROR =
  "GET_ORG_CLUB_TEAM_DETAILS_ERROR";

//GET Club Event Team
export const GET_CLUB_EVENT_TEAM_LOADING = "GET_CLUB_EVENT_TEAM_LOADING";
export const GET_CLUB_EVENT_TEAM_SUCCESS = "GET_CLUB_EVENT_TEAM_SUCCESS";
export const GET_CLUB_EVENT_TEAM_ERROR = "GET_CLUB_EVENT_TEAM_ERROR";

//accept/reject Invitation [Post]
export const ACCEPT_INVITATION_LOADING = "ACCEPT_INVITATION_LOADING";
export const ACCEPT_INVITATION_SUCCESS = "ACCEPT_INVITATION_SUCCESS";
export const ACCEPT_INVITATION_ERROR = "ACCEPT_INVITATION_ERROR";
export const RESET_ACCEPT_INVITATION = "RESET_ACCEPT_INVITATION";

//get coach accept list [get]
export const GET_COACH_ACCEPT_LIST_LOADING = "GET_COACH_ACCEPT_LIST_LOADING";
export const GET_COACH_ACCEPT_LIST_SUCCESS = "GET_COACH_ACCEPT_LIST_SUCCESS";
export const GET_COACH_ACCEPT_LIST_ERROR = "GET_COACH_ACCEPT_LIST_ERROR";

//get coach accept list [get]
export const GET_CLUB_DASHBOARD_LOADING = "GET_CLUB_DASHBOARD_LOADING";
export const GET_CLUB_DASHBOARD_SUCCESS = "GET_CLUB_DASHBOARD_SUCCESS";
export const GET_CLUB_DASHBOARD_ERROR = "GET_CLUB_DASHBOARD_ERROR";

//get clubs org list [get]
export const GET_CLUBS_ORG_LOADING = "GET_CLUBS_ORG_LOADING";
export const GET_CLUBS_ORG_SUCCESS = "GET_CLUBS_ORG_SUCCESS";
export const GET_CLUBS_ORG_ERROR = "GET_CLUBS_ORG_ERROR";

//get clubs org Liability [get]
export const GET_CLUBS_ORG_LIABILITY_LOADING =
  "GET_CLUBS_ORG_LIABILITY_LOADING";
export const GET_CLUBS_ORG_LIABILITY_SUCCESS =
  "GET_CLUBS_ORG_LIABILITY_SUCCESS";
export const GET_CLUBS_ORG_LIABILITY_ERROR = "GET_CLUBS_ORG_LIABILITY_ERROR";

//SIGNATURE SEND[post]
export const SIGNATURE_LOADING = "SIGNATURE_LOADING";
export const SIGNATURE_SUCCESS = "SIGNATURE_SUCCESS";
export const SIGNATURE_ERROR = "SIGNATURE_ERROR";
export const RESET_SIGNATURE = "RESET_SIGNATURE";

//GET CLUB ORG EVENT DETAILS [GET]
export const GET_CLUB_ORG_EVENT_DETAILS_LOADING =
  "GET_CLUB_ORG_EVENT_DETAILS_LOADING";
export const GET_CLUB_ORG_EVENT_DETAILS_SUCCESS =
  "GET_CLUB_ORG_EVENT_DETAILS_SUCCESS";
export const GET_CLUB_ORG_EVENT_DETAILS_ERROR =
  "GET_CLUB_ORG_EVENT_DETAILS_ERROR";
export const RESET_CLUB_ORG_EVENT_DETAIL = "RESET_CLUB_ORG_EVENT_DETAIL";

//GET CLUB ORG EVENT [GET]
export const GET_CLUB_ORG_EVENT_LOADING = "GET_CLUB_ORG_EVENT_LOADING";
export const GET_CLUB_ORG_EVENT_SUCCESS = "GET_CLUB_ORG_EVENT_SUCCESS";
export const GET_CLUB_ORG_EVENT_ERROR = "GET_CLUB_ORG_EVENT_ERROR";
export const RESET_CLUB_ORG_EVENT = "RESET_CLUB_ORG_EVENT";

//Get Staff Invitation in club[GET]
export const GET_STAFF_INVITATION_LOADING = "GET_STAFF_INVITATION_LOADING";
export const GET_STAFF_INVITATION_SUCCESS = "GET_STAFF_INVITATION_SUCCESS";
export const GET_STAFF_INVITATION_ERROR = "GET_STAFF_INVITATION_ERROR";

//get club notification
export const GET_CLUB_NOTIFICATION_LOADING = "GET_CLUB_NOTIFICATION_LOADING";
export const GET_CLUB_NOTIFICATION_SUCCESS = "GET_CLUB_NOTIFICATION_SUCCESS";
export const GET_CLUB_NOTIFICATION_ERROR = "GET_CLUB_NOTIFICATION_ERROR";

//get club notification
export const GET_NOTIFICATION_LOADING = "GET_NOTIFICATION_LOADING";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";

//get event details for registration team
export const GET_EVENT_DETAILS_REGISTRATION_LOADING =
  "GET_EVENT_DETAILS_REGISTRATION_LOADING";
export const GET_EVENT_DETAILS_REGISTRATION_SUCCESS =
  "GET_EVENT_DETAILS_REGISTRATION_SUCCESS";
export const GET_EVENT_DETAILS_REGISTRATION_ERROR =
  "GET_EVENT_DETAILS_REGISTRATION_ERROR";

//register selected team [POST]
export const REGISTER_SELECTED_TEAM_LOADING = "REGISTER_SELECTED_TEAM_LOADING";
export const REGISTER_SELECTED_TEAM_SUCCESS = "REGISTER_SELECTED_TEAM_SUCCESS";
export const REGISTER_SELECTED_TEAM_ERROR = "REGISTER_SELECTED_TEAM_ERROR";
export const RESET_REGISTER_SELECTED_TEAM = "RESET_REGISTER_SELECTED_TEAM";

//register selected team [POST]
export const DISCOUNT_CODE_APPLY_LOADING = "DISCOUNT_CODE_APPLY_LOADING";
export const DISCOUNT_CODE_APPLY_SUCCESS = "DISCOUNT_CODE_APPLY_SUCCESS";
export const DISCOUNT_CODE_APPLY_ERROR = "DISCOUNT_CODE_APPLY_ERROR";
export const RESET_DISCOUNT_CODE_APPLY = "RESET_DISCOUNT_CODE_APPLY";

//register selected team [POST]
export const PAYNOW_LOADING = "PAYNOW_LOADING";
export const PAYNOW_SUCCESS = "PAYNOW_SUCCESS";
export const PAYNOW_ERROR = "PAYNOW_ERROR";
export const RESET_PAYNOW = "RESET_PAYNOW";

//connected user club [GET]
export const GET_CONNECTED_USER_CLUB_LOADING =
  "GET_CONNECTED_USER_CLUB_LOADING";
export const GET_CONNECTED_USER_CLUB_SUCCESS =
  "GET_CONNECTED_USER_CLUB_SUCCESS";
export const GET_CONNECTED_USER_CLUB_ERROR = "GET_CONNECTED_USER_CLUB_ERROR";
export const RESET_GET_CONNECTED_USER = "RESET_GET_CONNECTED_USER";

//get coach accept list [get]
export const GET_PLAYER_ACCEPT_LIST_LOADING = "GET_PLAYER_ACCEPT_LIST_LOADING";
export const GET_PLAYER_ACCEPT_LIST_SUCCESS = "GET_PLAYER_ACCEPT_LIST_SUCCESS";
export const GET_PLAYER_ACCEPT_LIST_ERROR = "GET_PLAYER_ACCEPT_LIST_ERROR";

//Save Payment info
export const SAVE_PAYMENT_INFO = "SAVE_PAYMENT_INFO";
export const RESET_PAYMENT_INFO = "RESET_PAYMENT_INFO";

//create merchand account

export const CREATE_MERCHAND_ACCOUNT_LOADING =
  "CREATE_MERCHAND_ACCOUNT_LOADING";
export const CREATE_MERCHAND_ACCOUNT_SUCCESS =
  "CREATE_MERCHAND_ACCOUNT_SUCCESS";
export const CREATE_MERCHAND_ACCOUNT_ERROR = "CREATE_MERCHAND_ACCOUNT_ERROR";
export const RESET_CREATE_MERCHAND_ACCOUNT = "RESET_CREATE_MERCHAND_ACCOUNT";

//create merchand account

export const LEAGUE_BOOKMARK_LOADING = "LEAGUE_BOOKMARK_LOADING";
export const LEAGUE_BOOKMARK_SUCCESS = "LEAGUE_BOOKMARK_SUCCESS";
export const LEAGUE_BOOKMARK_ERROR = "LEAGUE_BOOKMARK_ERROR";
export const RESET_LEAGUE_BOOKMARK = "RESET_LEAGUE_BOOKMARK";

//get Team Events[GET]
export const GET_TEAM_EVENT_LOADING = "GET_TEAM_EVENT_LOADING";
export const GET_TEAM_EVENT_SUCCESS = "GET_TEAM_EVENT_SUCCESS";
export const GET_TEAM_EVENT_ERROR = "GET_TEAM_EVENT_ERROR";

//add coach to team

export const ASSIGN_COACH_LOADING = "ASSIGN_COACH_LOADING";
export const ASSIGN_COACH_SUCCESS = "ASSIGN_COACH_SUCCESS";
export const ASSIGN_COACH_ERROR = "ASSIGN_COACH_ERROR";
export const RESET_ASSIGN_COACH = "RESET_ASSIGN_COACH";

export const CREATE_EVENT_DIVISION_LOADING = "CREATE_EVENT_DIVISION_LOADING";
export const CREATE_EVENT_DIVISION_SUCCESS = "CREATE_EVENT_DIVISION_SUCCESS";
export const CREATE_EVENT_DIVISION_ERROR = "CREATE_EVENT_DIVISION_ERROR";
export const RESET_CREATE_EVENT_DIVISION = "RESET_CREATE_EVENT_DIVISION";

export const GET_EVENT_DIVISION_LOADING = "GET_EVENT_DIVISION_LOADING";
export const GET_EVENT_DIVISION_SUCCESS = "GET_EVENT_DIVISION_SUCCESS";
export const GET_EVENT_DIVISION_ERROR = "GET_EVENT_DIVISION_ERROR";

export const EDIT_EVENT_DIVISION_LOADING = "EDIT_EVENT_DIVISION_LOADING";
export const EDIT_EVENT_DIVISION_SUCCESS = "EDIT_EVENT_DIVISION_SUCCESS";
export const EDIT_EVENT_DIVISION_ERROR = "EDIT_EVENT_DIVISION_ERROR";
export const RESET_EDIT_EVENT_DIVISION = "RESET_EDIT_EVENT_DIVISING";

//Delete Event Pool Org
export const DELETE_EVENT_POOL_LOADING = "DELETE_EVENT_POOL_LOADING";
export const DELETE_EVENT_POOL_SUCCESS = "DELETE_EVENT_POOL_SUCCESS";
export const DELETE_EVENT_POOL_ERROR = "DELETE_EVENT_POOL_ERROR";
export const RESET_DELETE_EVENT_POOL = "RESET_DELETE_EVENT_POOL";

//active deactive player
export const ACTIVE_PLAYER_LOADING = "ACTIVE_PLAYER_LOADING";
export const ACTIVE_PLAYER_SUCCESS = "ACTIVE_PLAYER_SUCCESS";
export const ACTIVE_PLAYER_ERROR = "ACTIVE_PLAYER_ERROR";
export const RESET_ACTIVE_PLAYER = "RESET_ACTIVE_PLAYER";

//get Parent Invitation[GET]
export const GET_PARENT_INVITATION_LOADING = "GET_PARENT_INVITATION_LOADING";
export const GET_PARENT_INVITATION_SUCCESS = "GET_PARENT_INVITATION_SUCCESS";
export const GET_PARENT_INVITATION_ERROR = "GET_PARENT_INVITATION_ERROR";

//accept Parent [put]club
export const ACCEPT_PARENT_LOADING = "ACCEPT_PARENT_LOADING";
export const ACCEPT_PARENT_SUCCESS = "ACCEPT_PARENT_SUCCESS";
export const ACCEPT_PARENT_ERROR = "ACCEPT_PARENT_ERROR";
export const RESET_ACCEPT_PARENT = "RESET_ACCEPT_PARENT";

// remove account
export const REMOVE_ACCOUNT_LOADING = "REMOVE_ACCOUNT_LOADING";
export const REMOVE_ACCOUNT_SUCCESS = "REMOVE_ACCOUNT_SUCCESS";
export const REMOVE_ACCOUNT_ERROR = "REMOVE_ACCOUNT_ERROR";

// approve liability account
export const APPROVE_LIABILITY_LOADING = "APPROVE_LIABILITY_LOADING";
export const APPROVE_LIABILITY_SUCCESS = "APPROVE_LIABILITY_SUCCESS";
export const APPROVE_LIABILITY_ERROR = "APPROVE_LIABILITY_ERROR";

//  payment method
export const PAYMENT_METHOD_LOAING = "PAYMENT_METHOD_LOAING";
export const PAYMENT_METHOD_SUCCESS = "PAYMENT_METHOD_SUCCESS";
export const PAYMENT_METHOD_ERROR = "PAYMENT_METHOD_ERROR";
export const RESET_PAYMENT_METHOD = "RESET_PAYMENT_METHOD";
//  payment method
export const GET_PAYMENT_METHOD_LOAING = "GET_PAYMENT_METHOD_LOAING";
export const GET_PAYMENT_METHOD_SUCCESS = "GET_PAYMENT_METHOD_SUCCESS";
export const GET_PAYMENT_METHOD_ERROR = "GET_PAYMENT_METHOD_ERROR";
export const RESET_GET_PAYMENT_METHOD = "RESET_GET_PAYMENT_METHOD";

// remove Payment method
export const REMOVE_PAYMENT_METHOD_LOAING = "REMOVE_PAYMENT_METHOD_LOAING";
export const REMOVE_PAYMENT_METHOD_SUCCESS = "REMOVE_PAYMENT_METHOD_SUCCESS";
export const REMOVE_PAYMENT_METHOD_ERROR = "REMOVE_PAYMENT_METHOD_ERROR";
export const REMOVE_RESET_PAYMENT_METHOD = "REMOVE_RESET_PAYMENT_METHOD";

//  team register
export const REGISTERED_TEAM_LOADING = "REGISTERED_TEAM_LOADING";
export const REGISTERED_TEAM_SUCCESS = "REGISTERED_TEAM_SUCCESS";
export const REGISTERED_TEAM_ERROR = "REGISTERED_TEAM_ERROR";
export const RESET_REGISTERED_TEAM = "RESET_REGISTERED_TEAM";

//  create and remove team roster
export const CREATE_ROSTER_LOADING = "CREATE_ROSTER_LOADING";
export const CREATE_ROSTER_SUCCESS = "CREATE_ROSTER_SUCCESS";
export const CREATE_ROSTER_ERROR = "CREATE_ROSTER_ERROR";
export const RESET_CREATE_ROSTER_ERROR = "RESET_CREATE_ROSTER_ERROR";

export const REMOVE_ROSTER_LOADING = "REMOVE_ROSTER_LOADING";
export const REMOVE_ROSTER_SUCCESS = "REMOVE_ROSTER_SUCCESS";
export const REMOVE_ROSTER_ERROR = "REMOVE_ROSTER_ERROR";
export const RESET_REMOVE_ROSTER = "RESET_REMOVE_ROSTER";

export const REMOVE_PLAYER_ROSTER_LOADING = "REMOVE_PLAYER_ROSTER_LOADING";
export const REMOVE_PLAYER_ROSTER_SUCCESS = "REMOVE_PLAYER_ROSTER_SUCCESS";
export const REMOVE_PLAYER_ROSTER_ERROR = "REMOVE_PLAYER_ROSTER_ERROR";
export const RESET_REMOVE_PLAYER_ROSTER = "RESET_REMOVE_PLAYER_ROSTER";

//  Player Details
export const PLAYER_DETAILS_LOADING = "PLAYER_DETAILS_LOADING";
export const PLAYER_DETAILS_SUCCESS = "PLAYER_DETAILS_SUCCESS";
export const PLAYER_DETAILS_ERROR = "PLAYER_DETAILS_ERROR";
export const RESET_PLAYER_DETAILS = "RESET_PLAYER_DETAILS";

//  Add selected roster
export const ADD_ROSTER_LOADING = "ADD_ROSTER_LOADING";
export const ADD_ROSTER_SUCCESS = "ADD_ROSTER_SUCCESS";
export const ADD_ROSTER_ERROR = "ADD_ROSTER_ERROR";
export const RESET_ADD_ROSTER = "RESET_ADD_ROSTER";

//  Update Image profile
export const UPDATE_PROFILE_IMAGE_LOADING = "UPDATE_PROFILE_IMAGE_LOADING";
export const UPDATE_PROFILE_IMAGE_SUCCESS = "UPDATE_PROFILE_IMAGE_SUCCESS";
export const UPDATE_PROFILE_IMAGE_ERROR = "UPDATE_PROFILE_IMAGE_ERROR";
export const RESET_UPDATE_PROFILE_IMAGE = "RESET_UPDATE_PROFILE_IMAGE";

export const GET_MERCHANT_ORGANIZER_LOADING = "GET_MERCHANT_ORGANIZER_LOADING";
export const GET_MERCHANT_ORGANIZER_SUCCESS = "GET_MERCHANT_ORGANIZER_SUCCESS";
export const GET_MERCHANT_ORGANIZER_ERROR = "GET_MERCHANT_ORGANIZER_ERROR";

export const GET_MERCHANT_STATUS_LOADING = "GET_MERCHANT_STATUS_LOADING";
export const GET_MERCHANT_STATUS_SUCCESS = "GET_MERCHANT_STATUS_SUCCESS";
export const GET_MERCHANT_STATUS_ERROR = "GET_MERCHANT_STATUS_ERROR";

export const GET_SIGNED_WAIVER_LOADING = "GET_SIGNED_WAIVER_LOADING";
export const GET_SIGNED_WAIVER_SUCCESS = "GET_SIGNED_WAIVER_SUCCESS";
export const GET_SIGNED_WAIVER_ERROR = "GET_SIGNED_WAIVER_ERROR";

export const GET_LIABILITY_TERMS_LOADING = "GET_LIABILITY_TERMS_LOADING";
export const GET_LIABILITY_TERMS_SUCCESS = "GET_LIABILITY_TERMS_SUCCESS";
export const GET_LIABILITY_TERMS_ERROR = "GET_LIABILITY_TERMS_ERROR";

export const CREATE_ORG_ROSTER_LOADING = "CREATE_ORG_ROSTER_LOADING";
export const CREATE_ORG_ROSTER_SUCCESS = "CREATE_ORG_ROSTER_SUCCESS";
export const CREATE_ORG_ROSTER_ERROR = "CREATE_ORG_ROSTER_ERROR";
export const REMOVE_ORG_ROSTER_LOADING = "REMOVE_ORG_ROSTER_LOADING";
export const REMOVE_ORG_ROSTER_SUCCESS = "REMOVE_ORG_ROSTER_SUCCESS";
export const REMOVE_ORG_ROSTER_ERROR = "REMOVE_ORG_ROSTER_ERROR";
export const RESET_CREATE_ORG_ROSTER = "RESET_CREATE_ORG_ROSTER";

export const GET_PAYMENTREQUEST_LOADING = "GET_PAYMENTREQUEST_LOADING";
export const GET_PAYMENTREQUEST_SUCCESS = "GET_PAYMENTREQUEST_SUCCESS";
export const GET_PAYMENTREQUEST_ERROR = "GET_PAYMENTREQUEST_ERROR";

export const REMINDER_CLUB_LOADING = "REMINDER_CLUB_LOADING";
export const REMINDER_CLUB_SUCCESS = "REMINDER_CLUB_SUCCESS";
export const REMINDER_CLUB_ERROR = "REMINDER_CLUB_ERROR";
export const REMINDER_CLUB_RESET = "REMINDER_CLUB_RESET";

// ................. Event Listing Registration ...............

export const EVENT_REGISTRATION_LOADING = "EVENT_REGISTRATION_LOADING";
export const EVENT_REGISTRATION_SUCCESS = "EVENT_REGISTRATION_SUCCESS";
export const EVENT_REGISTRATION_ERROR = "EVENT_REGISTRATION_ERROR";
export const RESET_EVENT_REGISTRATION = "RESET_EVENT_REGISTRATION";

// ................. Add payment ...............

export const REQ_ADD_PAYMENT_LOADING = "REQ_ADD_PAYMENT_LOADING";
export const REQ_ADD_PAYMENT_SUCCESS = "REQ_ADD_PAYMENT_SUCCESS";
export const REQ_ADD_PAYMENT_ERROR = "REQ_ADD_PAYMENT_ERROR";
export const RESET_REQ_ADD_PAYMENT = "RESET_REQ_ADD_PAYMENT";

export const GET_CLUB_TEAM_EVENT_LOADING = "GET_CLUB_TEAM_EVENT_LOADING";
export const GET_CLUB_TEAM_EVENT_SUCCESS = "GET_CLUB_TEAM_EVENT_SUCCESS";
export const GET_CLUB_TEAM_EVENT_ERROR = "GET_CLUB_TEAM_EVENT_ERROR";
export const RESET_GET_CLUB_TEAM_EVENT = "RESET_GET_CLUB_TEAM_EVENT";

export const REMOVE_TEAM_PLAYER_LOADING = "REMOVE_TEAM_PLAYER_LOADING";
export const REMOVE_TEAM_PLAYER_SUCCESS = "REMOVE_TEAM_PLAYER_SUCCESS";
export const REMOVE_TEAM_PLAYER_ERROR = "REMOVE_TEAM_PLAYER_ERROR";
export const RESET_REMOVE_TEAM_PLAYER = "RESET_REMOVE_TEAM_PLAYER";

export const ADD_TEAM_PLAYER_LOADING = "ADD_TEAM_PLAYER_LOADING";
export const ADD_TEAM_PLAYER_SUCCESS = "ADD_TEAM_PLAYER_SUCCESS";
export const ADD_TEAM_PLAYER_ERROR = "ADD_TEAM_PLAYER_ERROR";
export const RESET_ADD_TEAM_PLAYER = "RESET_ADD_TEAM_PLAYER";

export const GET_PLAYER_LIST_LOADING = "GET_PLAYER_LIST_LOADING";
export const GET_PLAYER_LIST_SUCCESS = "GET_PLAYER_LIST_SUCCESS";
export const GET_PLAYER_LIST_ERROR = "GET_PLAYER_LIST_ERROR";
export const RESET_GET_PLAYER_LIST = "RESET_GET_PLAYER_LIST";

export const GET_ALL_DIVISION_LIST_LOADING = "GET_ALL_DIVISION_LIST_LOADING";
export const GET_ALL_DIVISION_LIST_SUCCESS = "GET_ALL_DIVISION_LIST_SUCCESS";
export const GET_ALL_DIVISION_LIST_ERROR = "GET_ALL_DIVISION_LIST_ERROR";
export const RESET_GET_ALL_DIVISION_LIST = "RESET_GET_ALL_DIVISION_LIST";
// ................. Org player details ...............

export const ORG_PLAYER_DETAILS_LOADING = "ORG_PLAYER_DETAILS_LOADING";
export const ORG_PLAYER_DETAILS_SUCCESS = "ORG_PLAYER_DETAILS_SUCCESS";
export const ORG_PLAYER_DETAILS_ERROR = "ORG_PLAYER_DETAILS_ERROR";
export const RESET_ORG_PLAYER_DETAILS = "RESET_ORG_PLAYER_DETAILS";
// ................. club discount ...............

export const ORG_CLUB_DISCOUNT_LOADING = "ORG_CLUB_DISCOUNT_LOADING";
export const ORG_CLUB_DISCOUNT_SUCCESS = "ORG_CLUB_DISCOUNT_SUCCESS";
export const ORG_CLUB_DISCOUNT_ERROR = "ORG_CLUB_DISCOUNT_ERROR";
export const RESET_ORG_CLUB_DISCOUNT = "RESET_ORG_CLUB_DISCOUNT";
// ................. club credit ...............

export const ORG_CLUB_CREDIT_LOADING = "ORG_CLUB_CREDIT_LOADING";
export const ORG_CLUB_CREDIT_SUCCESS = "ORG_CLUB_CREDIT_SUCCESS";
export const ORG_CLUB_CREDIT_ERROR = "ORG_CLUB_CREDIT_ERROR";
export const RESET_ORG_CLUB_CREDIT = "RESET_ORG_CLUB_CREDIT";
// ................. Org getallclub  ...............

export const ORG_GET_ALL_CLUB_LOADING = "ORG_GET_ALL_CLUB_LOADING";
export const ORG_GET_ALL_CLUB_SUCCESS = "ORG_GET_ALL_CLUB_SUCCESS";
export const ORG_GET_ALL_CLUB_ERROR = "ORG_GET_ALL_CLUB_ERROR";
export const RESET_ORG_GET_ALL_CLUB = "RESET_ORG_GET_ALL_CLUB";

export const ORG_CLUB_BY_ID_LOADING = "ORG_CLUB_BY_ID_LOADING";
export const ORG_CLUB_BY_ID_SUCCESS = "ORG_CLUB_BY_ID_SUCCESS";
export const ORG_CLUB_BY_ID_ERROR = "ORG_CLUB_BY_ID_ERROR";
export const RESET_ORG_CLUB_BY_ID = "RESET_ORG_CLUB_BY_ID";

export const CLEAR_NOTIFICATION_LOADING = "CLEAR_NOTIFICATION_LOADING";
export const CLEAR_NOTIFICATION_SUCCESS = "CLEAR_NOTIFICATION_SUCCESS";
export const CLEAR_NOTIFICATION_ERROR = "CLEAR_NOTIFICATION_ERROR";
export const RESET_CLEAR_NOTIFICATION = "RESET_CLEAR_NOTIFICATION";
// ................. oRG Balances due tab  ...............

export const ORG_BALANCE_DUE_LOADING = "ORG_BALANCE_DUE_LOADING";
export const ORG_BALANCE_DUE_SUCCESS = "ORG_BALANCE_DUE_SUCCESS";
export const ORG_BALANCE_DUE_ERROR = "ORG_BALANCE_DUE_ERROR";
export const RESET_ORG_BALANCE_DUE = "RESET_ORG_BALANCE_DUE";
// ................. Switch access  ...............

export const ORG_SWITCH_ACCESS_LOADING = "ORG_SWITCH_ACCESS_LOADING";
export const ORG_SWITCH_ACCESS_SUCCESS = "ORG_SWITCH_ACCESS_SUCCESS";
export const ORG_SWITCH_ACCESS_ERROR = "ORG_SWITCH_ACCESS_ERROR";
export const RESET_ORG_SWITCH_ACCESS = "RESET_ORG_SWITCH_ACCESS";

export const ORG_SWITCH_ACCESS_PLAYER_LOADING =
  "ORG_SWITCH_ACCESS_PLAYER_LOADING";
export const ORG_SWITCH_ACCESS_PLAYER_SUCCESS =
  "ORG_SWITCH_ACCESS_PLAYER_SUCCESS";
export const ORG_SWITCH_ACCESS_PLAYER_ERROR = "ORG_SWITCH_ACCESS_PLAYER_ERROR";
export const RESET_ORG_SWITCH_ACCESS_PLAYER = "RESET_ORG_SWITCH_ACCESS";
// ................. Switch access  ...............

export const EVENT_DIVISION_POOL_LOADING = "EVENT_DIVISION_POOL_LOADING";
export const EVENT_DIVISION_POOL_SUCCESS = "EVENT_DIVISION_POOL_SUCCESS";
export const EVENT_DIVISION_POOL_ERROR = "EVENT_DIVISION_POOL_ERROR";
export const RESET_EVENT_DIVISION_POOL = "RESET_EVENT_DIVISION_POOL";

// ................. Switch access  ...............

export const ORG_ADD_TEAM_TO_DIVISION_LOADING =
  "ORG_ADD_TEAM_TO_DIVISION_LOADING";
export const ORG_ADD_TEAM_TO_DIVISION_SUCCESS =
  "ORG_ADD_TEAM_TO_DIVISION_SUCCESS";
export const ORG_ADD_TEAM_TO_DIVISION_ERROR = "ORG_ADD_TEAM_TO_DIVISION_ERROR";
export const RESET_ORG_ADD_TEAM_TO_DIVISION = "RESET_ORG_ADD_TEAM_TO_DIVISION";

export const ORG_ADD_PLAYER_TO_DIVISION_LOADING =
  "ORG_ADD_PLAYER_TO_DIVISION_LOADING";
export const ORG_ADD_PLAYER_TO_DIVISION_SUCCESS =
  "ORG_ADD_PLAYER_TO_DIVISION_SUCCESS";
export const ORG_ADD_PLAYER_TO_DIVISION_ERROR =
  "ORG_ADD_PLAYER_TO_DIVISION_ERROR";
export const RESET_ORG_ADD_PLAYER_TO_DIVISION =
  "RESET_ORG_ADD_PLAYER_TO_DIVISION";

// .........................  Update Schedule  ...........................

export const UPDATE_SCHEDULE_LOADING = "UPDATE_SCHEDULE_LOADING";
export const UPDATE_SCHEDULE_SUCCESS = "UPDATE_SCHEDULE_SUCCESS";
export const UPDATE_SCHEDULE_ERROR = "UPDATE_SCHEDULE_ERROR";
export const RESET_UPDATE_SCHEDULE = "RESET_UPDATE_SCHEDULE";

// .............................  field map  .....................

export const VIEW_FIELD_MAP_LOADING = "VIEW_FIELD_MAP_LOADING";
export const VIEW_FIELD_MAP_SUCCESS = "VIEW_FIELD_MAP_SUCCESS";
export const VIEW_FIELD_MAP_ERROR = "VIEW_FIELD_MAP_ERROR";
export const RESET_VIEW_FIELD_MAP = "RESET_VIEW_FIELD_MAP";

export const CREATE_FIELD_MAP_LOADING = "CREATE_FIELD_MAP_LOADING";
export const CREATE_FIELD_MAP_SUCCESS = "CREATE_FIELD_MAP_SUCCESS";
export const CREATE_FIELD_MAP_ERROR = "CREATE_FIELD_MAP_ERROR";
export const RESET_CREATE_FIELD_MAP = "RESET_CREATE_FIELD_MAP";

export const UPDATE_FIELD_MAP_LOADING = "UPDATE_FIELD_MAP_LOADING";
export const UPDATE_FIELD_MAP_SUCCESS = "UPDATE_FIELD_MAP_SUCCESS";
export const UPDATE_FIELD_MAP_ERROR = "UPDATE_FIELD_MAP_ERROR";
export const RESET_UPDATE_FIELD_MAP = "RESET_UPDATE_FIELD_MAP";

export const DELETE_FIELD_MAP_LOADING = "DELETE_FIELD_MAP_LOADING";
export const DELETE_FIELD_MAP_SUCCESS = "DELETE_FIELD_MAP_SUCCESS";
export const DELETE_FIELD_MAP_ERROR = "DELETE_FIELD_MAP_ERROR";
export const RESET_DELETE_FIELD_MAP = "RESET_DELETE_FIELD_MAP";

export const CLUB_GET_RECIPIENT_LOADING = "CLUB_GET_RECIPIENT_LOADING";
export const CLUB_GET_RECIPIENT_SUCCESS = "CLUB_GET_RECIPIENT_SUCCESS";
export const CLUB_GET_RECIPIENT_ERROR = "CLUB_GET_RECIPIENT_ERROR";
export const RESET_CLUB_GET_RECIPIENT = "RESET_CLUB_GET_RECIPIENT";

export const CLEAR_ORG_NOTIFICATIONS_LOADING =
  "CLEAR_ORG_NOTIFICATIONS_LOADING";
export const CLEAR_ORG_NOTIFICATIONS_SUCCESS =
  "CLEAR_ORG_NOTIFICATIONS_SUCCESS";
export const CLEAR_ORG_NOTIFICATIONS_ERROR = "CLEAR_ORG_NOTIFICATIONS_ERROR";
export const RESET_CLEAR_ORG_NOTIFICATIONS = "RESET_CLEAR_ORG_NOTIFICATIONS";

export const GET_PERMISSION_GROUP_LOADING = "GET_PERMISSION_GROUP_LOADING";
export const GET_PERMISSION_GROUP_SUCCESS = "GET_PERMISSION_GROUP_SUCCESS";
export const GET_PERMISSION_GROUP_ERROR = "GET_PERMISSION_GROUP_ERROR";
export const RESET_GET_PERMISSION_GROUP = "RESET_GET_PERMISSION_GROUP";

export const CREATE_ADMIN_GROUP_LOADING = "CREATE_ADMIN_GROUP_LOADING";
export const CREATE_ADMIN_GROUP_SUCCESS = "CREATE_ADMIN_GROUP_SUCCESS";
export const CREATE_ADMIN_GROUP_ERROR = "CREATE_ADMIN_GROUP_ERROR";
export const RESET_CREATE_ADMIN_GROUP = "RESET_CREATE_ADMIN_GROUP";
export const CREATE_SCHEDULE_MESSAGE_LOADING =
  "CREATE_SCHEDULE_MESSAGE_LOADING";
export const CREATE_SCHEDULE_MESSAGE_SUCCESS =
  "CREATE_SCHEDULE_MESSAGE_SUCCESS";
export const CREATE_SCHEDULE_MESSAGE_ERROR = "CREATE_SCHEDULE_MESSAGE_ERROR";
export const RESET_CREATE_SCHEDULE_MESSAGE = "RESET_CREATE_SCHEDULE_MESSAGE";

export const CREATE_FIELDMAP_MESSAGE_LOADING =
  "CREATE_FIELDMAP_MESSAGE_LOADING";
export const CREATE_FIELDMAP_MESSAGE_SUCCESS =
  "CREATE_FIELDMAP_MESSAGE_SUCCESS";
export const CREATE_FIELDMAP_MESSAGE_ERROR = "CREATE_FIELDMAP_MESSAGE_ERROR";
export const RESET_CREATE_FIELDMAP_MESSAGE = "RESET_CREATE_FIELDMAP_MESSAGE";

// ........................  stay and play .........................

export const VIEW_STAY_AND_PLAY_LOADING = "VIEW_STAY_AND_PLAY_LOADING";
export const VIEW_STAY_AND_PLAY_SUCCESS = "VIEW_STAY_AND_PLAY_SUCCESS";
export const VIEW_STAY_AND_PLAY_ERROR = "VIEW_STAY_AND_PLAY_ERROR";
export const RESET_VIEW_STAY_AND_PLAY = "RESET_VIEW_STAY_AND_PLAY";

export const CREATE_STAY_AND_PLAY_LOADING = "CREATE_STAY_AND_PLAY_LOADING";
export const CREATE_STAY_AND_PLAY_SUCCESS = "CREATE_STAY_AND_PLAY_SUCCESS";
export const CREATE_STAY_AND_PLAY_ERROR = "CREATE_STAY_AND_PLAY_ERROR";
export const RESET_CREATE_STAY_AND_PLAY = "RESET_CREATE_STAY_AND_PLAY";

export const UPDATE_STAY_AND_PLAY_LOADING = "UPDATE_STAY_AND_PLAY_LOADING";
export const UPDATE_STAY_AND_PLAY_SUCCESS = "UPDATE_STAY_AND_PLAY_SUCCESS";
export const UPDATE_STAY_AND_PLAY_ERROR = "UPDATE_STAY_AND_PLAY_ERROR";
export const RESET_UPDATE_STAY_AND_PLAY = "RESET_UPDATE_STAY_AND_PLAY";

export const DELETE_STAY_AND_PLAY_LOADING = "DELETE_STAY_AND_PLAY_LOADING";
export const DELETE_STAY_AND_PLAY_SUCCESS = "DELETE_STAY_AND_PLAY_SUCCESS";
export const DELETE_STAY_AND_PLAY_ERROR = "DELETE_STAY_AND_PLAY_ERROR";
export const RESET_DELETE_STAY_AND_PLAY = "RESET_DELETE_STAY_AND_PLAY";

export const CREATE_STAY_AND_PLAY_MESSAGE_LOADING =
  "CREATE_STAY_AND_PLAY_MESSAGE_LOADING";
export const CREATE_STAY_AND_PLAY_MESSAGE_SUCCESS =
  "CREATE_STAY_AND_PLAY_MESSAGE_SUCCESS";
export const CREATE_STAY_AND_PLAY_MESSAGE_ERROR =
  "CREATE_STAY_AND_PLAY_MESSAGE_ERROR";
export const RESET_CREATE_STAY_AND_PLAY_MESSAGE =
  "RESET_CREATE_STAY_AND_PLAY_MESSAGE";

export const GET_ADMIN_PERMISSION_LIST_SUCCESS =
  "GET_ADMIN_PERMISSION_LIST_SUCCESS";
export const GET_ADMIN_PERMISSION_LIST_ERROR =
  "GET_ADMIN_PERMISSION_LIST_ERROR";
export const RESET_GET_ADMIN_PERMISSION_LIST =
  "RESET_GET_ADMIN_PERMISSION_LIST";

export const CREATE_ADMIN_PERMISSION_GROUP_SUCCESS =
  "CREATE_ADMIN_PERMISSION_GROUP_SUCCESS";
export const CREATE_ADMIN_PERMISSION_GROUP_ERROR =
  "CREATE_ADMIN_PERMISSION_GROUP_ERROR";
export const RESET_CREATE_ADMIN_PERMISSION_GROUP =
  "RESET_CREATE_ADMIN_PERMISSION_GROUP";

export const DELETE_ADMIN_PERMISSION_GROUP_SUCCESS =
  "DELETE_ADMIN_PERMISSION_GROUP_SUCCESS";
export const DELETE_ADMIN_PERMISSION_GROUP_ERROR =
  "DELETE_ADMIN_PERMISSION_GROUP_ERROR";
export const RESET_DELETE_ADMIN_PERMISSION_GROUP =
  "RESET_DELETE_ADMIN_PERMISSION_GROUP";

export const GET_ADMIN_LIST_SUCCESS = "GET_ADMIN_LIST_SUCCESS";
export const GET_ADMIN_LIST_ERROR = "GET_ADMIN_LIST_ERROR";
export const RESET_GET_ADMIN_LIST = "RESET_GET_ADMIN_LIST";

export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR";
export const RESET_GET_ALL_USERS = "RESET_GET_ALL_USERS";

export const INVAITE_ADMIN_SUCCESS = "INVAITE_ADMIN_SUCCESS";
export const INVAITE_ADMIN_ERROR = "INVAITE_ADMIN_ERROR";
export const RESET_INVAITE_ADMIN = "RESET_INVAITE_ADMIN";

export const GET_STAFF_PERMISSION_LIST_SUCCESS =
  "GET_STAFF_PERMISSION_LIST_SUCCESS";
export const GET_STAFF_PERMISSION_LIST_ERROR =
  "GET_STAFF_PERMISSION_LIST_ERROR";
export const RESET_GET_STAFF_PERMISSION_LIST =
  "RESET_GET_STAFF_PERMISSION_LIST";

export const CREATE_STAFF_PERMISSION_GROUP_SUCCESS =
  "CREATE_STAFF_PERMISSION_GROUP_SUCCESS";
export const CREATE_STAFF_PERMISSION_GROUP_ERROR =
  "CREATE_STAFF_PERMISSION_GROUP_ERROR";
export const RESET_CREATE_STAFF_PERMISSION_GROUP =
  "RESET_CREATE_STAFF_PERMISSION_GROUP";

export const DELETE_STAFF_PERMISSION_GROUP_SUCCESS =
  "DELETE_STAFF_PERMISSION_GROUP_SUCCESS";
export const DELETE_STAFF_PERMISSION_GROUP_ERROR =
  "DELETE_STAFF_PERMISSION_GROUP_ERROR";
export const RESET_DELETE_STAFF_PERMISSION_GROUP =
  "RESET_DELETE_STAFF_PERMISSION_GROUP";

export const GET_STAFF_PERMISSION_SUCCESS = "GET_STAFF_PERMISSION_SUCCESS";
export const GET_STAFF_PERMISSION_ERROR = "GET_STAFF_PERMISSION_ERROR";
export const RESET_GET_STAFF_PERMISSION = "RESET_GET_STAFF_PERMISSION";

export const SET_STAFF_PERMISSION_SUCCESS = "SET_STAFF_PERMISSION_SUCCESS";
export const SET_STAFF_PERMISSION_ERROR = "SET_STAFF_PERMISSION_ERROR";
export const RESET_SET_STAFF_PERMISSION = "RESET_SET_STAFF_PERMISSION";

export const GET_STAFF_LIST_SUCCESS = "GET_STAFF_LIST_SUCCESS";
export const GET_STAFF_LIST_ERROR = "GET_STAFF_LIST_ERROR";
export const RESET_GET_STAFF_LIST = "RESET_GET_STAFF_LIST";

export const CREATE_MERCHAT_ACCOUNT_SUCCESS = "CREATE_MERCHAT_ACCOUNT_SUCCESS";
export const CREATE_MERCHAT_ACCOUNT_ERROR = "CREATE_MERCHAT_ACCOUNT_ERROR";
export const RESET_CREATE_MERCHAT_ACCOUNT = "RESET_CREATE_MERCHAT_ACCOUNT";

export const MOVE_TO_FREE_TEAM_PLAYER_SUCCESS =
  "MOVE_TO_FREE_TEAM_PLAYER_SUCCESS";
export const MOVE_TO_FREE_TEAM_PLAYER_ERROR = "MOVE_TO_FREE_TEAM_PLAYER_ERROR";
export const RESET_MOVE_TO_FREE_TEAM_PLAYER = "RESET_MOVE_TO_FREE_TEAM_PLAYER";

//Invite Club
export const INVITE_CLUB_ORG_LOADING = "INVITE_CLUB_ORG_LOADING";
export const INVITE_CLUB_ORG_SUCCESS = "INVITE_CLUB_ORG_SUCCESS";
export const INVITE_CLUB_ORG_ERROR = "INVITE_CLUB_ORG_ERROR";
export const RESET_INVITE_CLUB_ORG = "RESET_INVITE_CLUB_ORG";

//connected user Org [GET]
export const GET_ORG_CONNECTED_USER_LOADING = "GET_ORG_CONNECTED_USER_LOADING";
export const GET_ORG_CONNECTED_USER_SUCCESS = "GET_ORG_CONNECTED_USER_SUCCESS";
export const GET_ORG_CONNECTED_USER_ERROR = "GET_ORG_CONNECTED_USER_ERROR";
export const RESET_GET_ORG_CONNECTED = "RESET_GET_ORG_CONNECTED";

//connected user Org [GET]
export const CHANGE_INVITATION_STATUS_LOADING =
  "CHANGE_INVITATION_STATUS_LOADING";
export const CHANGE_INVITATION_STATUS_SUCCESS =
  "CHANGE_INVITATION_STATUS_SUCCESS";
export const CHANGE_INVITATION_STATUS_ERROR = "CHANGE_INVITATION_USER_ERROR";
export const RESET_CHANGE_INVITATION_STATUS = "RESET_CHANGE_INVITATION";

// Terms and conditions

export const TERMS_LOADING = "TERMS_LOADING";
export const TERMS_SUCCESS = "TERMS_SUCCESS";
export const TERMS_ERROR = "TERMS_ERROR";

// register team Payble Amount
export const GET_REG_TEAM_PAY_AMOUNT_SUCCESS =
  "GET_REG_TEAM_PAY_AMOUNT_SUCCESS";
export const GET_REG_TEAM_PAY_AMOUNT_ERROR = "GET_REG_TEAM_PAY_AMOUNT_ERROR";
export const RESET_GET_REG_TEAM_PAY_AMOUNT = "RESET_GET_REG_TEAM_PAY_AMOUNT";

// Read Notification
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";
export const READ_NOTIFICATION_ERROR = "READ_NOTIFICATION_ERROR";
export const RESET_READ_NOTIFICATION = "RESET_READ_NOTIFICATION";

// Read Invitation
export const READ_INVITATION_SUCCESS = "READ_INVITATION_SUCCESS";
export const READ_INVITATION_ERROR = "READ_INVITATION_ERROR";
export const RESET_READ_INVITATION = "RESET_READ_INVITATION";

// Get Notification Count
export const NOTIFICATION_COUNT_LOADING = "NOTIFICATION_COUNT_LOADING";
export const NOTIFICATION_COUNT_SUCCESS = "NOTIFICATION_COUNT_SUCCESS";
export const NOTIFICATION_COUNT_ERROR = "NOTIFICATION_COUNT_ERROR";
export const RESET_NOTIFICATION_COUNT = "RESET_NOTIFICATION_COUNT";


export const removeDuplicates = (array, key) => {
  const seen = new Set();
  const data = array.filter(obj => {
    const value = obj[key];
    if (seen.has(value)) {
      return false;
    }
    seen.add(value);
    return true;
  });
  return data
};
