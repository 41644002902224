// import "../src/SuperAdmin/style/style.scss";
import "../src/SuperAdmin/SuperadminStyle/style.css";
import "@progress/kendo-theme-default/dist/all.css";
import SignIn from "./SuperAdmin/componants/auth/SignIn";

import Dashboard from "./SuperAdmin/componants/Dashboard";
import Transaction from "./SuperAdmin/componants/Transaction";
import { Route, useLocation, useHistory, Switch } from "react-router-dom";

import User from "./SuperAdmin/componants/User";
import EventOrganizer from "./SuperAdmin/componants/user/EventOrganizer";
import Merchant from "./SuperAdmin/componants/user/Merchant";
import SuperAdmin from "./SuperAdmin/componants/user/SuperAdmin";
import SuperSubAdmin from "./SuperAdmin/componants/user/SuperSubAdmin";
import Club from "./SuperAdmin/componants/user/Club";
import Staff from "./SuperAdmin/componants/user/Staff";
import Coach from "./SuperAdmin/componants/user/Coach";
import ParentGuardian from "./SuperAdmin/componants/user/ParentGuardian";
import Players from "./SuperAdmin/componants/user/players";
import Config from "./SuperAdmin/componants/Config";
import NotFound from "./SuperAdmin/componants/NotFound";
import ForgotPass from "./SuperAdmin/componants/auth/ForgotPass";
import Otp from "./SuperAdmin/componants/auth/Otp";
import NewPass from "./SuperAdmin/componants/auth/Newpass";
import ChangePass from "./SuperAdmin/componants/auth/ChangePass";
import MerchantAccount from "./SuperAdmin/componants/user/MerchantAccount";
import { useEffect } from "react";
// import { getUserDetails } from './SuperAdminServices/Actions';
import { connect } from "react-redux";
import Header from "./SuperAdmin/componants/Header";
import Sidebar from "./SuperAdmin/componants/Sidebar";
import Toaster from "./Toaster/Toaster";
import { getUserDetails } from "./SuperAdmin/Services/Actions/userAction";
import Sport from "./SuperAdmin/componants/Sport";
import Liability from "./SuperAdmin/componants/Liability";

function SuperAdminApp(props) {
  const location = useLocation();

  const token = localStorage.getItem("token");

  const history = useHistory();

  useEffect(() => {
    if (!!token) {
      props.getUserDetails();
    }
  }, []);
  useEffect(() => {
    const path = location.pathname;
    if (path == "/superadmin" || path == "/") {
      if (!!token) {
        history.push("/superadmin/dashboard");
      } else {
        history.push("/superadmin/login");
      }
    } else {
      if (
        !!token &&
        (path == "/superadmin/login" ||
          path == "/superadmin/forgotPassword" ||
          path == "/superadmin/otp" ||
          path == "/superadmin/newPassword")
      ) {
        history.push("/superadmin/dashboard");
      } else if (
        token === null &&
        path != "/superadmin/login" &&
        path != "/superadmin/forgotPassword" &&
        path != "/superadmin/otp" &&
        path != "/superadmin/newPassword"
      ) {
        history.push("/superadmin/login");
      }
    }
  }, [token, location.pathname]);

  useEffect(() => {
    if (
      !!token &&
      (location.pathname == "/superadmin" || location.pathname == "/")
    ) {
      history.push("/superadmin/dashboard");
    } else if (
      token == null &&
      (location.pathname == "/superadmin" || location.pathname == "/")
    ) {
      history.push("/superadmin/login");
    }
  }, []);
  return (
    <div className="container-scroller">
      {token == null ? (
        <Switch>
          <Route path="/superadmin/login" exact>
            <SignIn />
          </Route>
          <Route path="/superadmin/forgotPassword" exact>
            <ForgotPass />
          </Route>
          <Route path="/superadmin/otp" exact>
            <Otp />
          </Route>
          <Route path="/superadmin/newPassword" exact>
            <NewPass />
          </Route>
        </Switch>
      ) : (
        <>
          <Header />
          <Sidebar />
          <div className="main-content">
            <main>
              <Switch>
                <Route path="/superadmin/dashboard">
                  <Dashboard />
                </Route>
                <Route exact path="/superadmin">
                  <User />
                </Route>
                <Route path="/superadmin/superAdmin">
                  <SuperAdmin />
                </Route>
                <Route path="/superadmin/superSubAdmin">
                  <SuperSubAdmin />
                </Route>
                <Route path="/superadmin/event-organizer">
                  <EventOrganizer />
                </Route>
                <Route path="/superadmin/user/merchant">
                  <Merchant />
                </Route>
                <Route path="/superadmin/club">
                  <Club />
                </Route>
                <Route path="/superadmin/staff">
                  <Staff />
                </Route>
                <Route path="/superadmin/coach">
                  <Coach />
                </Route>
                <Route path="/superadmin/parentGuardian">
                  <ParentGuardian />
                </Route>
                <Route path="/superadmin/players">
                  <Players />
                </Route>
                <Route path="/superadmin/merchant-account">
                  <MerchantAccount />
                </Route>
                <Route path="/superadmin/Transaction">
                  <Transaction />
                </Route>
                <Route path="/superadmin/config">
                  <Config />
                </Route>
                <Route path="/superadmin/sports">
                  <Sport />
                </Route>
                <Route path="/superadmin/liability">
                  <Liability />
                </Route>
                <Route path="/superadmin/changePassword">
                  <ChangePass />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </main>
          </div>
        </>
      )}
      <Toaster />
    </div>
  );
}

const mapStateToProp = (state) => ({
  loginReducer: state.superAdminRootReducer.authReducers.loginDetails,
  userDetailsReducer: state.superAdminRootReducer.authReducers.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: (user) => dispatch(getUserDetails(user)),
});

export default connect(mapStateToProp, mapDispatchToProps)(SuperAdminApp);
