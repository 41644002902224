import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getAllUserRoll,
  switchAccountData,
} from "../service/action/userAction";
import { Link, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Toaster from "../Toaster/Toaster";
import { toast } from "react-toastify";
import { RESET_SWITCH } from "../service/constants";
import classNames from "classnames";

const SwitchAccount = (props) => {
  const history = useHistory();
  const icon = {
    color: "#8A9EA5",
  };
  const divider = {
    borderColor: "rgba(255,255,255, 0.4)",
  };
  const getdata = props.getUserRollHandler;
  useEffect(() => {
    getdata();
  }, [getdata]);

  const currentId = localStorage.getItem("Id");

  const userRollClub = useSelector(
    (state) => state.getUserRollReducer.user.clubs
  );
  const userRoll = useSelector((state) => state.getUserRollReducer.user);
  const userRollOrg = useSelector(
    (state) => state.getUserRollReducer.user.organizer
  );
  const userRollStaff = useSelector(
    (state) => state.getUserRollReducer.user.staffs
  );
  const userRollcoaches = useSelector(
    (state) => state.getUserRollReducer.user.coaches
  );
  const userRollplayer = useSelector(
    (state) => state.getUserRollReducer.user.player_parent
  );
  const userRollparent = useSelector(
    (state) => state.getUserRollReducer.user.parent
  );

  //onCLick Event on account click
  const switchAcc = (type, id, name) => {
    localStorage.setItem("Id", id);
    localStorage.setItem("type", type);
    props.switchAccountHandler({
      type: type,
      id: id,
    });
    if (type && name !== undefined) {
      localStorage.setItem("type", type); //type for App.js file component load
      localStorage.setItem("username", name); //for Header name
      // localStorage.setItem("Id",id)
    }
  };

  useEffect(() => {
    if (props.switchAccountReducer?.success) {
      history.push(sessionStorage.getItem("link") || "/dashboard");
      props.resetSwitch();
    }
  }, [props.switchAccountReducer]);

  return (
    // <div className="main-panel">
    <div className="container-fluid page-body-wrapper full-page-wrapper">
      <div className="content-wrapper d-flex auth login-section">
        <div className="row flex-grow">
          {getdata.loading === true ? (
            <h1>Loading</h1>
          ) : (
            <div className="col-lg-4 mx-auto mt-24">
              {userRoll ? (
                <>
                  <div className="auth-form-light text-left p-5">
                    <div className="row mx-0">
                      {!!currentId && (
                        <div
                          className="col-2 pointer mt-1"
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          <i
                            className="fas fa-chevron-left mr-2   d-inline"
                            style={icon}
                          ></i>
                          <span className="mt-1 mb-4 text-white-50 text-center">
                            Back
                          </span>
                        </div>
                      )}
                      <div
                        className={classNames({
                          "col-8": !!currentId,
                          "col-12": !currentId,
                        })}
                      >
                        <h3 className="mt-1 mb-4 text-white-50 text-center">
                          {props.isNotification
                            ? "Notifications"
                            : "Select Account"}
                        </h3>
                      </div>
                      <div className="col-2"></div>
                    </div>
                    {userRollOrg ? (
                      <>
                        {userRollOrg.length === 0 ? (
                          <>
                            {/* <span className='noti-type'><Link to={"/createaccount"} className="d-flex justify-content-between text-decoration-none" >Create Organizer account</Link></span> */}
                          </>
                        ) : (
                          <>
                            {userRollOrg.map((v, i) => {
                              return (
                                <div key={v._id} className="py-3 border-bottom">
                                  <div
                                    className="d-flex justify-content-between text-decoration-none pointer"
                                    onClick={() => switchAcc(1, v._id, v.name)}
                                  >
                                    <h5 className=" mb-0 mt-0 text-white">
                                      {v.name}
                                    </h5>
                                    <i
                                      className="fas fa-chevron-right mr-2   d-inline"
                                      style={icon}
                                    ></i>
                                  </div>
                                  <small className="noti-type">
                                    Event Organizer
                                  </small>
                                  {/* <hr style={divider} /> */}
                                </div>
                              );
                            })}
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {userRollStaff ? (
                      <>
                        {userRollStaff.length === 0 ? (
                          <>
                            {/* <span className='noti-type'>Create staff account</span> */}
                          </>
                        ) : (
                          <>
                            {userRollStaff.map((v, i) => {
                              // {club == 1 ?
                              return (
                                <div key={v._id} className="py-3 border-bottom">
                                  <Link
                                    to="/downloadapp"
                                    className="d-flex justify-content-between text-decoration-none"
                                    onClick={() => switchAcc(2, v._id, v.name)}
                                  >
                                    <h5 className="text-white mb-0 mt-0">
                                      {v.first_name}
                                    </h5>
                                    <i
                                      className="fas fa-chevron-right mr-2   d-inline"
                                      style={icon}
                                    ></i>
                                  </Link>
                                  <small className="noti-type">Staff</small>
                                  {/* <hr style={divider} /> */}
                                </div>
                              );
                            })}
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {userRollcoaches ? (
                      <>
                        {userRollcoaches.length === 0 ? (
                          <>
                            {/* <span className='noti-type'>Create coach account</span> */}
                          </>
                        ) : (
                          <>
                            {userRollcoaches.map((v, i) => {
                              // {club == 1 ?
                              return (
                                <div key={v._id} className="py-3 border-bottom">
                                  <Link
                                    to="/downloadapp"
                                    className="d-flex justify-content-between"
                                    onClick={() => switchAcc(3, v._id, v.name)}
                                  >
                                    <h5 className="text-white mb-0 mt-0">
                                      {v.first_name}
                                    </h5>
                                    <i
                                      className="fas fa-chevron-right mr-2  d-inline"
                                      style={icon}
                                    ></i>
                                  </Link>
                                  <small className="noti-type">Coach</small>
                                  {/* <hr style={divider} /> */}
                                </div>
                              );
                            })}
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {userRollClub ? (
                      <>
                        {userRollClub.length === 0 ? (
                          <>
                            {/* <span className='noti-type'><Link to={"/createaccount"} className="d-flex justify-content-between text-decoration-none" >Create club account</Link></span> */}
                          </>
                        ) : (
                          <>
                            {userRollClub.map((v, i) => {
                              // {club == 1 ?
                              return (
                                <div key={v._id} className="py-3 border-bottom">
                                  <div
                                    className="d-flex justify-content-between text-decoration-none pointer"
                                    onClick={() => switchAcc(4, v._id, v.name)}
                                  >
                                    <h5 className="text-white mb-0 mt-0">
                                      {v.name}
                                    </h5>
                                    {/* <i className="mdi mdi-logout mr-2 text-primary d-inline"></i> */}
                                    <i
                                      className="fas fa-chevron-right mr-2   d-inline"
                                      style={icon}
                                    ></i>
                                  </div>
                                  <small className="noti-type">Club</small>
                                  {/* <hr style={divider} /> */}
                                </div>
                              );
                            })}
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {userRollplayer ? (
                      <>
                        {userRollplayer.length === 0 ? (
                          <>
                            {/* <span className='noti-type'>Create player account</span> */}
                          </>
                        ) : (
                          <>
                            {userRollplayer.map((v, i) => {
                              // {club == 1 ?
                              return (
                                <div key={v._id} className="py-3 border-bottom">
                                  <Link
                                    to="/downloadapp"
                                    className="d-flex justify-content-between"
                                    onClick={() => switchAcc(5, v._id, v.name)}
                                  >
                                    <h5 className="text-white mb-0 mt-0">
                                      {v.first_name}
                                    </h5>
                                    <i
                                      className="fas fa-chevron-right mr-2 d-inline"
                                      style={icon}
                                    ></i>
                                  </Link>
                                  <small className="noti-type">Player</small>
                                  {/* <hr style={divider} /> */}
                                </div>
                              );
                            })}
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {userRollparent ? (
                      <>
                        <div>
                          {userRollparent.length === 0 ? (
                            <>
                              {/* <span className='noti-type'>Create parent account</span> */}
                            </>
                          ) : (
                            <>
                              {userRollparent.map((v, i) => {
                                // {club == 1 ?
                                return (
                                  <div
                                    key={v._id}
                                    className="py-3 border-bottom"
                                  >
                                    <Link
                                      to="/downloadapp"
                                      className="d-flex justify-content-between"
                                      onClick={() =>
                                        switchAcc(6, v._id, v.name)
                                      }
                                    >
                                      <h5 className="text-white mb-0 mt-0">
                                        {v.first_name}
                                      </h5>
                                      <i
                                        className="fas fa-chevron-right mr-2   d-inline"
                                        style={icon}
                                      ></i>
                                    </Link>
                                    <small className="noti-type">
                                      Parent/Guardian
                                    </small>
                                    {/* <hr style={divider} /> */}
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : null}
            </div>
          )}
        </div>
        <Toaster />
      </div>
    </div>
  );
};

// export default withRouter(SwitchAccount);
const mapStateToProps = (state) => ({
  token: state.switchAccountReducer.token,
  switchAccountReducer: state.switchAccountReducer,
  signin: state.signinReducer,
});

const mapDispatchToProps = (dispatch) => ({
  switchAccountHandler: (user) => dispatch(switchAccountData(user)),
  getUserRollHandler: (user) => dispatch(getAllUserRoll(user)),
  resetSwitch: (user) => dispatch({ type: RESET_SWITCH }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SwitchAccount));
// 252
