import React, { useEffect, useState } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  getOrgClubList,
  getOrgCredit,
  getRefunds,
} from "../../../service/action/userAction";
import Spinner from "../../../Spinner/Spinner";
import moment from "moment";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";

const ClubRefund = (props) => {
  const [loading, setLoading] = useState(true);

  const refund = useSelector((state) => state.getRefundsReducer.user.data);
  const location = useLocation();

  useEffect(() => {
    props.refundHandler();
  }, []);

  useEffect(() => {
    if (props.refund?.success) {
      setLoading(false);
    }
  }, [props.refund]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>
          {!loading && (
            <>
              <div className="text-left">
                <div className="create-team-head-space">
                  <h5 className="dash-head">Refunds</h5>
                  <Link
                    to="/payment/club-refunds/create-club-refund"
                    className="text-decoaration-none"
                  >
                    <button className="btn-create-new">+Add More</button>
                  </Link>
                </div>
              </div>
              {/* <div className="text-left"> */}
              {/* <div className="create-team-head-event"> */}
              {/* <h5 className="dash-head">Credit</h5> */}
              {/* <div className="dropdown details-drp"> */}
              {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add More</i> */}
              {/* <Link className="dropdown-item" to={`/newcredit`}> <i className="mdi mdi-plus mr-2 website-ico"></i>Create New</Link> */}

              {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <Link className="dropdown-item" to={`/add-staff`}> <i className="mdi mdi-plus mr-2 website-ico"></i>Add Staff</Link>
                                </div> */}
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}

              {refund && refund.length == 0 ? (
                <>
                  <div className="container d-flex justify-content-center align-items-center">
                    <span className="noti-type">No refunds found</span>
                  </div>
                </>
              ) : (
                <>
                  {/* desktop view */}
                  <div className="table-responsive d-none d-md-block">
                    <table className="table event-table mt-30">
                      <thead>
                        <tr>
                          {/* <th></th> */}
                          <th>First Name</th>
                          <th>Date/ Days Paid</th>
                          <th>Recepient</th>
                          <th>Refund Amount</th>
                          <th>Payment Status</th>
                          {/* <th>Payment</th>
                                    <th>Status</th> */}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {refund.length == 0 ? (
                          <>
                            <span className="noti-type">
                              You Don't issue Refund yet
                            </span>
                          </>
                        ) : (
                          <>
                            {refund.map((v, i) => {
                              return (
                                <tr key={i}>
                                  {/* <td>
                                                                    <div className="dashboard-img mr-3">
                                                                        <img src="./images/pch-images/pch.svg" />
                                                                    </div>
                                                                </td> */}
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <Link
                                        className="ml-12 text-blue text-decoration-none"
                                        to="#"
                                      >
                                        {v.receipient.first_name}
                                      </Link>
                                    </div>
                                  </td>
                                  <td>
                                    {moment(v.refund_date, "yyyy-MM-DD").format(
                                      "MM-DD-yyyy"
                                    )}
                                  </td>
                                  <td>{v.receipient.name}</td>
                                  <td>${v.refund_amount}</td>
                                  <td>Refunded</td>
                                  {/* <td>{v.payment}</td>
                                            <td>{v.status}</td> */}
                                  {/* <td>
                                                <div className="dropdown event-drp">
                                                    <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                    More
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <a className="dropdown-item" href="#">
                                                            <i className="mdi mdi-bookmark-outline"></i>
                                                            Bookmark</a>
                                                        <a className="dropdown-item" href="#">
                                                            <i className="mdi mdi-plus"></i>
                                                            Invite</a>
                                                    </div>
                                                </div>
                                            </td> */}
                                </tr>
                              );
                            })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* mobile view */}

                  <div className="mt-30 d-md-none">
                    {refund &&
                      refund.map((v, i) => {
                        return (
                          <div
                            className="d-flex justify-content-between reg-details"
                            key={i}
                          >
                            <div className="d-flex">
                              <div className="dashboard-img mr-3">
                                <img src="./images/pch-images/pch.svg" />
                              </div>
                              <div className="reg-content">
                                <Link
                                  className=" text-blue text-decoration-none"
                                  to="#"
                                >
                                  {v.receipient.first_name}
                                </Link>
                                <span className="d-block text-gray mt-2">{`Date/Days Paid: ${moment(
                                  v.refund_date,
                                  "yyyy-MM-DD"
                                ).format("MM-DD-yyyy")}`}</span>
                                <span className="d-block text-gray mt-2">{`Recipient: ${v.receipient.name}`}</span>
                                <span className="d-block text-gray mt-2">{`Refund Amount: $${v.refund_amount}`}</span>
                                <span className="d-block text-gray mt-2">{`Payment Status: Refunded`}</span>
                                {/* <span className="d-block text-gray mt-2">Status: {v.status}</span> */}
                              </div>
                            </div>
                            {/* <div className="dropdown event-drp">
                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-bookmark-outline"></i>
                                                Bookmark</a>
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-plus"></i>
                                                Invite</a>                                           
                                        </div>
                                    </div> */}
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(Club);

const mapStateToProps = (state) => ({
  refund: state.getRefundsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getOrg_ClubListHandler: (user) => dispatch(getOrgClubList(user)),
  getCreditHandler: (user) => dispatch(getOrgCredit(user)),
  refundHandler: (user) => dispatch(getRefunds(user)),
  // userDataHandler: user => dispatch(userData(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClubRefund));
