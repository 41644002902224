import { RESET_SWITCH, SWITCH_ERROR, SWITCH_LOADING, SWITCH_SUCCESS } from "../constants"

let initialState = {
    error: false,
    errors: [],
    success: false,
    loading: false,
    token: "" ,
    user: {}
}

export default function switchAccountReducer(state = initialState, action) {
    switch (action.type) {
        case SWITCH_LOADING:
            // alert("switch load reduc")
            return {
                ...state, loading: true, success: false, error: false
            }
        case SWITCH_SUCCESS:
            // alert("switch succ reduc")
            return {
                ...state, success: true, loading: false, error: false, token: action.payload
            }
        case SWITCH_ERROR:
            // alert("switch err reduc")
            return {
                ...state, success: false, loading: false,error: true,errors: action.errors
            }
        case RESET_SWITCH:
            return {
                ...state,success:false,loading:false , error:false ,token:null
            }
        default:
            return state
    }
}