import {
  REMOVE_POOL_PLAYER_ERROR,
  REMOVE_POOL_PLAYER_LOADING,
  REMOVE_POOL_PLAYER_SUCCESS,
  RESET_REMOVE_POOL_PLAYER,
} from "../../../../constants";
let initialState = {
  success: false,
  error: false,
  loading: false,
  error: [],
};
export default function deletePoolPlayerReducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_POOL_PLAYER_LOADING:
      return { ...state, success: false, loading: true, error: false };
    case REMOVE_POOL_PLAYER_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        message: action.msg,
      };
    case REMOVE_POOL_PLAYER_ERROR:
      return { ...state, success: false, loading: false, error: false };
    case RESET_REMOVE_POOL_PLAYER:
      return { ...state, success: false, loading: false, error: false };
    default:
      return state;
  }
}
