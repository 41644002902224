import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router";

// import PaymentOption from './PaymentOption'
import Toaster from "../../../Toaster/Toaster";
import {
  getOrgRequest,
  sendPaymentInfo,
} from "../../../service/action/Club/clubAction";
import teamArrayReducer from "../../../service/reducers/Club-Reducer/Registration/teamArrayReducer";

const Request_Pay = (props, team) => {
  useEffect(() => {
    props.getRequestHandler();
  }, []);


  const { id } = useParams();
  const history = useHistory();

  const [paymentRequest, setPaymentRequest] = useState(null);


  const teamlist = useSelector((state) => state.teamArrayReducer.user);

  const payment = useSelector(
    (state) => state.registerSelectedTeamReducer.message
  );
  const allTeam = useSelector((state) => state.getEventTeamReducer.user.data);
  const discount = useSelector(
    (state) => state.applydiscountcodeReducer.message
  );
  const orgRequest = useSelector(
    (state) => state.getOrgRequestReducer.user.data
  );

  useEffect(() => {
    if (orgRequest) {
      const singleReq = orgRequest.find((e) => e._id);
      setPaymentRequest(singleReq);
    }
  }, [orgRequest]);

  const [selectedTeam, setSelectedTeam] = useState([]);


  //for filter team array from selected team id
  useEffect(() => {
    if (teamlist) {
      var result = allTeam.filter(function (e) {
        return teamlist.includes(e._id);
      });
      setSelectedTeam(result);


    }
  }, [teamlist]);

  const [check, setCheck] = useState(false);

  const [state, setState] = useState({
    payment_id: "",
    organizer_id: "",
    discountCode: "",
    team: teamlist !== null ? teamlist.length : null,
    amount: "",
    totalAmount: "",
    discountAmount: 0,
    amountToPay: "",
    payment_terms_type: "",
    pendingAmount: "",
    credit: 0,
    credit_payment: 0,
    payment_terms: "",
  });

  //Calidation for discount code
  const [errors, setErrors] = useState({
    discountCode: false,
  });

  //setValue n state for discount code api call
  useEffect(() => {
    if (paymentRequest) {
      setState((prevState) => ({
        ...prevState,
        payment_id: paymentRequest.invoice_code,
        organizer_id: paymentRequest.organizer.id,
        amount: paymentRequest.amount,
        // payment_terms_type: payment.data[0].payment_terms_type,
        credit:
          paymentRequest.credit[0] !== undefined
            ? payment.data[0].credit[0].credit_amount
            : 0,
        onlyForShowCredit: 0,
        totalAmount: paymentRequest.amount,
        payment_terms: paymentRequest.payment_terms,
        // credit:paymentRequest !==undefined ? payment.credit:0
      }));
    }
    // if(payment.credit){
    //     setState(prevState => ({
    //         ...prevState,
    //         credit: payment !==undefined ? payment.credit.credit_amount:0 ,
    //     }))
    // }
  }, [paymentRequest]);

  //Set Total payment value with discount amount
  // useEffect(() => {
  //     if (discount) {
  //         setState(prevState => ({
  //             ...prevState,
  //             totalAmount: discount.data !== undefined ? discount.data[0].amount : state.amount * teamlist.length,
  //             discountAmount: discount.data !== undefined ? discount.data[0].discount : 0,
  //             amountToPay: discount.data !== undefined ? discount.data[0].after_discount : state.amount * teamlist.length,
  //             pendingAmount: discount.data !== undefined ? discount.data[0].panding_amount : ""
  //         }))
  //     }
  // }, [discount])

  // useEffect(() => {
  //     if (discount) {
  //         setState(prevState => ({
  //             ...prevState,
  //             totalAmount: discount.data !== undefined ? discount.data[0].amount : state.amount * teamlist.length,
  //             discountAmount: discount.data !== undefined ? discount.data[0].discount : 0,
  //             amountToPay: discount.data !== undefined ? discount.data[0].after_discount : state.amount * teamlist.length,
  //             pendingAmount: discount.data !== undefined ? discount.data[0].panding_amount : ""
  //         }))
  //     }
  // }, [discount])

  const creditCheck = (e) => {
    // e.preventDefault();
    setCheck(!check);
    if (check == false) {
      const finalPay = state.amountToPay - state.credit;
      setState((prevState) => ({
        ...prevState,
        // amountToPay: finalPay,
        credit_payment: 1,
        onlyForShowCredit: state.credit,
      }));
    } else if (check == true) {
      // const finalPay=state.amountToPay+state.credit
      setState((prevState) => ({
        ...prevState,
        // amountToPay: discount.data !== undefined ? discount.data[0].after_discount : state.amount * teamlist.length,
        credit_payment: 0,
        onlyForShowCredit: 0,
      }));
    }
  };

  const notify = (msg) => toast(msg);

  //Call Discount code api on Page load
  // useEffect(() => {

  //     if (state.payment_id) {

  //         props.discountCodehandler({
  //             discount_code: discountCode === null ? "" : discountCode,
  //             payment_code: state.payment_id,
  //             organizer: state.organizer_id,
  //             team: state.team
  //         })
  //     }

  // }, [state.payment_id])

  //On Apply discount code button
  const applydiscountcode = (e) => {
    e.preventDefault();
    if (state.discountCode == "") {
      setErrors((prevState) => ({
        ...prevState,
        discountCode: "Please Enter Discount Code",
      }));
      document.getElementById("discountCode").classList.add("error");
    }
    if (state.discountCode.length < 8) {
      setErrors((prevState) => ({
        ...prevState,
        discountCode: " Discount code must be 8 digit",
      }));
      document.getElementById("discountCode").classList.add("error");
    }
    if (state.discountCode.length > 8) {
      setErrors((prevState) => ({
        ...prevState,
        discountCode: " Discount code must be 8 digit",
      }));
      document.getElementById("discountCode").classList.add("error");
    }

    if (state.discountAmount !== "" && state.discountCode.length == 8) {
      props.discountCodehandler({
        discount_code: state.discountCode,
        payment_code: state.payment_id,
        organizer: state.organizer_id,
        team: state.team,
      });
    }
  };

  //onRefresh Page redirect to back
  // useEffect(() => {
  //     if (teamlist === null) {
  //         history.push(`/registernow/${id}`+(id1?`/${id1}`:""))
  //         // props.resetregisteredSelectedTeamReducer()
  //     }
  // }, [])

  //for discount code
  const [unable, setUnable] = useState(false);

  //Enter discount code
  const discountCodeChange = (e) => {
    const { id, value, name } = e.target;
    setState((prevState) => ({
      ...prevState,
      discountCode: e.target.value,
    }));
    switch (name) {
      case "discountCode":
        if (e.target.value.length == 8) {
          setErrors((prevState) => ({
            ...prevState,
            discountCode: false,
          }));

          setUnable(true);

          document.getElementById("discountCode").classList.remove("error");
        }

        if (e.target.value.length > 8 || e.target.value.length < 8) {
          setUnable(false);
        }
        break;

      default:
        return state;
    }
  };

  const [send, setSend] = useState(null);
  const Id = localStorage.getItem("Id");

  const payNow = () => {
    props.sendPaymentData({
      club: Id,
      event: paymentRequest.event.id,
      payment_request: id,
      payment_code: state.payment_id,
      organizer: state.organizer_id,
      amount: state.amount,
      payment_term: paymentRequest.payment_term,
      credit_note: check,
      transaction_type: "sale",
      payment_term: state.payment_terms,
    });
  };

  // useEffect(()=>{

  //     if(props.discount.message?.status && state.discountCode !==""){
  //     }

  // },[props.discount])

  const [not, setNot] = useState(false);


  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head-space">
              <h5 className="dash-head mr-2">Payment</h5>
              {/* <Link to="/paynow"> */}
              {/* <div className="mt-2 mt-md-0 text-right">
                                    <button className="btn-save" >Paynow</button>
                                </div> */}
              {/* </Link> */}
              {/* <div className="d-md-flex align-items-center d-none"> */}
              {/* <div className="search-section mt-3 mt-md-0">
                                    <i className="mdi mdi-magnify search-ico"></i>
                                    <input type="text" className="form-control" id="search" name="search" value={state.search} placeholder="Search" onChange={Searchval} autoComplete="off" />
                                </div> */}

              {/* <div className="mt-md-0 mt-3">
                                    <i className="mdi mdi-filter-outline filter-ico"></i>
                                </div> */}
              {/* </div> */}
            </div>
          </div>
          {paymentRequest ? (
            <>
              <div className="team-details mt-4 mt-md-0 justify-content-between">
                {/* {not ? <PaymentOption value={send} /> : null} */}

                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="teams-tab"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="mt-30">
                      <div className="d-flex justify-content-center align-content-center">
                        <h6
                          className="text-left mb-3 text-blue"
                          style={{ fontWeight: "bold", fontSize: "25px" }}
                        >
                          null
                        </h6>
                      </div>

                      {/* desktop view */}

                      {paymentRequest ? (
                        <>
                          <div className="table-responsive d-none d-md-block shadow p-4 mb-4 bg-white">
                            {/* <table className="table event-table team-table mb-5">
                              <thead>
                                <tr>
                                  <th>Event</th>
                                  <th>Gender</th>
                                  <th>Amount</th>
                                  <th>Club</th>
                                  <th>season</th>
                                  <th></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr className="team-row">
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <Link
                                        className="ml-12 heading text-decoration-none"
                                        to={`/team-details/${v._id}`}
                                      >
                                        <span className="filter-string">
                                          {paymentRequest.event.event_name}
                                        </span>
                                      </Link>
                                    </div>
                                  </td>
                                  <td>{v.gender}</td>
                                  <td>{paymentRequest.amount}</td>
                                  <td>
                                    {v.team_id !== null
                                      ? v.team_id.club_name
                                      : "null"}
                                  </td>
                                  <td>
                                    {v.season !== null
                                      ? v.season.season_name
                                      : "null"}
                                  </td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table> */}
                            <hr />

                            <table className="table event-table team-table mb-2">
                              <thead>
                                <tr>
                                  <th></th>

                                  <th></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr className="team-row">
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        id="credit"
                                        name="credit"
                                        value={check}
                                        className="mr-2"
                                        onClick={creditCheck}
                                      />
                                      <span className="filter-string">
                                        Use Available Credit ?
                                      </span>
                                    </div>
                                  </td>

                                  <td> $ {state.credit} </td>
                                </tr>
                              </tbody>
                            </table>

                            {/* <div className=" w-50 d-flex ">
                              <input
                                type="text"
                                className="form-control team-name mr-2"
                                placeholder="Enter Discount Code"
                                name="discountCode"
                                id="discountCode"
                                value={state.discountCode}
                                onChange={discountCodeChange}
                              />
                              <button
                                className="mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn"
                                onClick={applydiscountcode}
                              >
                                Apply
                              </button>
                            </div> */}
                            {errors.discountCode && (
                              <span className="text-red small d-block">
                                * {errors.discountCode}
                              </span>
                            )}
                            <hr />
                            <div>
                              <div className="row">
                                <span className="col-6">Total Amount :</span>
                                <span className="col-6 d-flex justify-content-end">
                                  $ {" " + state.totalAmount}
                                </span>
                              </div>
                              <div className="row">
                                <span className="col-6">Amount to pay</span>
                                <span className="col-6 d-flex justify-content-end">
                                  $
                                  {" " +
                                    state.amountToPay -
                                    state.onlyForShowCredit}
                                </span>
                              </div>
                            </div>
                            {/* <table className="table event-table team-table mb-1">
                              <thead>
                                <tr>
                                  <th></th>

                                  <th></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr className="team-row">
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <span className="filter-string">
                                        Total Amount :
                                      </span>
                                    </div>
                                  </td>

                                  <td> $ {state.totalAmount} </td>
                                </tr>

                                <tr className="team-row">
                                                                    <td>
                                                                        <div className="d-flex align-items-center">

                                                                            <span className="filter-string" >Discount:</span>

                                                                        </div>
                                                                    </td>

                                                                    <td>{state.payment_terms_type === 0 ? "$" : "%"}{state.discountAmount} </td>

                                                                </tr>

                                <tr className="team-row">
                                                                    <td>
                                                                        <div className="d-flex align-items-center">

                                                                            <span className="filter-string" >Amount to Pay :</span>

                                                                        </div>
                                                                    </td>

                                                                    <td>$ {state.amountToPay - state.onlyForShowCredit} </td>

                                                                </tr>
                              </tbody>
                            </table> */}
                            <div className="d-flex justify-content-center align-content-center align-content-md-center justify-content-md-center mt-3">
                              <Link to={`/request_pay_option/${id}`}>
                                <button
                                  className="mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn"
                                  onClick={payNow}
                                >
                                  PayNow
                                </button>
                              </Link>
                            </div>

                            {/* <input type="text" value={teamId}/> */}
                          </div>

                          {/*<<<<<<<<<<<<----------- mobile view ------------>>>>>>>>>>*/}
                          <div className="table-responsive d-md-none shadow p-4 mb-4 bg-white">
                            <table className="table event-table team-table mb-5">
                              <thead>
                                <tr>
                                  <th>Teams</th>
                                  {/* <th>Gender</th> */}
                                  <th>Amount</th>
                                  {/* <th>Club</th> */}
                                  {/* <th>season</th> */}
                                  {/* <th></th> */}
                                </tr>
                              </thead>

                              <tbody>
                                {selectedTeam.map((v, i) => {
                                  return (
                                    <tr key={v._id} className="team-row">
                                      <td>
                                        <div className="d-flex align-items-center">
                                          {/* <Link className="ml-12 heading text-decoration-none" to={`/team-details/${v._id}`}> */}
                                          <span className="filter-string">
                                            {v.team_id.team_name}
                                          </span>
                                          {/* </Link> */}
                                        </div>
                                      </td>
                                      {/* <td>{v.gender}</td> */}
                                      <td>{state.amount}</td>
                                      {/* <td>{v.team_id !== null ? v.team_id.club_name : "null"}</td> */}
                                      {/* <td>{v.season !== null ? v.season.season_name : "null"}</td> */}
                                      {/* <td>

                                                                    </td> */}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                            <hr />
                            <div className="row">
                              <div className=" w-100 d-flex col-8">
                                <input
                                  type="text"
                                  className="form-control-sm team-name mr-1"
                                  placeholder="Discount Code"
                                  maxLength="8"
                                />
                              </div>
                              <div className="col-2 col-md-2">
                                <button className="mr-1 btn btn-primary btn-sm font-weight-medium auth-form-btn">
                                  Apply
                                </button>
                              </div>
                            </div>
                            <hr />
                            <table className="table event-table team-table mb-1">
                              <thead>
                                <tr>
                                  <th></th>
                                  {/* <th>Gender</th> */}
                                  <th></th>
                                  {/* <th>Amount to Pay</th> */}
                                  {/* <th>season</th> */}
                                  {/* <th></th> */}
                                </tr>
                              </thead>

                              <tbody>
                                <tr className="team-row">
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <span className="filter-string">
                                        Total Amount :
                                      </span>
                                    </div>
                                  </td>

                                  <td> 200 </td>
                                </tr>

                                <tr className="team-row">
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <span className="filter-string">
                                        Discount:
                                      </span>
                                    </div>
                                  </td>

                                  <td> 20 </td>
                                </tr>

                                <tr className="team-row">
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <span className="filter-string">
                                        Amount to Pay :
                                      </span>
                                    </div>
                                  </td>

                                  <td> 200 </td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="d-flex justify-content-center align-content-center align-content-md-center justify-content-md-center">
                              <button className="mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn ">
                                PayNow
                              </button>
                            </div>

                            {/* <input type="text" value={teamId}/> */}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};

// export default TeamsCoches;

const mapStateToProps = (state) => ({
  // paynowCheck: state.paynowReducer,
  // accept: state.acceptInvitationReducer,
  // discount:state.applydiscountcodeReducer
  teamArray: state.teamArrayReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // discountCodehandler: user => dispatch(discountCode(user)),
  // resetregisteredSelectedTeamReducer: () => dispatch({ type: RESET_REGISTER_SELECTED_TEAM }),
  // paynowHandler: user => dispatch(payNow(user)),
  // changestatusHandler: user => dispatch(Club_changeStatus(user)),
  // resetAcceptInvitationReducer: uset => dispatch({ type: RESET_ACCEPT_INVITATION }),
  sendPaymentData: (user) => dispatch(sendPaymentInfo(user)),
  getRequestHandler: (user) => dispatch(getOrgRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Request_Pay);
