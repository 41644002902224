import moment from "moment";
import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const EventList = ({
  v,
  i,
  getClubOrganizerEvent,
  bookmarkHandler,
  filteredEvents,
  loading,
}) => {
  const { _id, event_name, event_max_team, infinity_max_team } = v;
  return (
    <>
      <tr key={i} className="reg-row">
        <td>{i + 1}</td>
        <td>
          <div className="d-flex align-items-center">
            <div className="dashboard-img">
              <img
                src={
                  v.event_image !== ""
                    ? v.event_image
                    : "./images/pch-images/pch.svg"
                }
              />
            </div>
            <Link
              className="ml-12 text-decoration-none heading register-table filter-string"
              to={
                v.type === "club" ? `/event-details/${_id}` : `/events/${_id}`
              }
            >
              {event_name}
            </Link>
          </div>
        </td>
        <td>
          <div className="py-1">
            <Moment date={new Date(v.event_start_date)} format="MM/DD/YYYY" />
          </div>
        </td>
        <td>
          {moment(v.registration_end_date) >= moment() ? "Open" : "Closed"}
        </td>
        <td>{moment(v.event_end_date) >= moment() ? "Open" : "Closed"}</td>
        <td>{infinity_max_team ? "Unlimited" : event_max_team}</td>
        <td>
          {v.is_bookmarked === true ? (
            <>
              <i
                className="fas fa-flag m-1"
                style={{
                  color: "#DB1F26",
                }}
              ></i>
            </>
          ) : (
            <></>
          )}
        </td>
        <td>
          <div className="dropdown event-drp-club">
            {/* {v.is_bookmarked ==true ?<><i className="fas fa-bookmark m-2" style={{"color":"red"}}></i></>:<></>} */}
            <i
              className="mdi mdi-dots-vertical"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="dropdownMenuLink"
            ></i>
            {filteredEvents?.is_bookmarked === true ? (
              <>
                <span className="badge badge-light"></span>
              </>
            ) : (
              <></>
            )}
            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <Link
                className="dropdown-item"
                to="/events"
                onClick={() => {
                  getClubOrganizerEvent();
                  bookmarkHandler({
                    bookmarkType: "EVENT",
                    id: v._id,
                  });
                }}
              >
                <i className="mdi mdi-bookmark-outline"></i>
                {v.is_bookmarked === true ? "Remove bookmark" : "Add Bookmark"}
              </Link>
              {moment(v.event_end_date) > moment() && (
                <Link
                  className="dropdown-item"
                  to={
                    v.type === "club"
                      ? `registerteam/${v._id}`
                      : `/events/${v._id}/registernow`
                  }
                >
                  <i className="mdi mdi-account-multiple"></i> Register Teams
                </Link>
              )}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default EventList;
