import { GET_TRANSACTION_DETAILS_ERROR, GET_TRANSACTION_DETAILS_SUCCESS, RESET_GET_TRANSACTION_DETAILS } from "../constant";

let initialState = {
 transactionDetails: {
  data: null,
  error: false,
  success: false,
  errorMsg: ''
 },
};
export default function TransactionReducer(state = initialState, action) {
 switch (action.type) {
  //  login details
  case GET_TRANSACTION_DETAILS_SUCCESS:
   return { ...state, transactionDetails: { ...state.transactionDetails, success: true, data: action.response.data, error: false } };

  case GET_TRANSACTION_DETAILS_ERROR:
   return { ...state, transactionDetails: { ...state.transactionDetails, error: true, success: false, data: null, errorMsg: action.errorMsg } };

  case RESET_GET_TRANSACTION_DETAILS:
   return { ...state, transactionDetails: { ...state.transactionDetails, error: false, success: false, errorMsg: '' } };

  default:
   return state;
 }
}
