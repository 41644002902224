import {
  CREATE_ADMIN_PERMISSION_GROUP_ERROR,
  CREATE_ADMIN_PERMISSION_GROUP_SUCCESS,
  CREATE_STAFF_PERMISSION_GROUP_ERROR,
  CREATE_STAFF_PERMISSION_GROUP_SUCCESS,
  DELETE_ADMIN_PERMISSION_GROUP_ERROR,
  DELETE_ADMIN_PERMISSION_GROUP_SUCCESS,
  DELETE_STAFF_PERMISSION_GROUP_ERROR,
  DELETE_STAFF_PERMISSION_GROUP_SUCCESS,
  GET_ADMIN_LIST_ERROR,
  GET_ADMIN_LIST_SUCCESS,
  GET_ADMIN_PERMISSION_LIST_ERROR,
  GET_ADMIN_PERMISSION_LIST_SUCCESS,
  GET_ALL_USERS_ERROR,
  GET_ALL_USERS_SUCCESS,
  GET_STAFF_LIST_ERROR,
  GET_STAFF_LIST_SUCCESS,
  GET_STAFF_PERMISSION_ERROR,
  GET_STAFF_PERMISSION_LIST_ERROR,
  GET_STAFF_PERMISSION_LIST_SUCCESS,
  GET_STAFF_PERMISSION_SUCCESS,
  INVAITE_ADMIN_ERROR,
  INVAITE_ADMIN_SUCCESS,
  RESET_CREATE_ADMIN_PERMISSION_GROUP,
  RESET_CREATE_STAFF_PERMISSION_GROUP,
  RESET_DELETE_ADMIN_PERMISSION_GROUP,
  RESET_DELETE_STAFF_PERMISSION_GROUP,
  RESET_GET_ADMIN_LIST,
  RESET_GET_ADMIN_PERMISSION_LIST,
  RESET_GET_ALL_USERS,
  RESET_GET_STAFF_LIST,
  RESET_GET_STAFF_PERMISSION,
  RESET_GET_STAFF_PERMISSION_LIST,
  RESET_INVAITE_ADMIN,
  RESET_SET_STAFF_PERMISSION,
  SET_STAFF_PERMISSION_ERROR,
  SET_STAFF_PERMISSION_SUCCESS,
} from "../../constants";

let initialState = {
  getAdminPermissionList: {
    success: false,
    error: false,
    data: null,
    errorMsg: false,
  },
  createAdminGroup: {
    success: false,
    error: false,
    data: null,
    errorMsg: false,
  },
  deleteAdminGroup: {
    success: false,
    error: false,
    data: null,
    errorMsg: false,
  },
  getAdminList: {
    success: false,
    error: false,
    data: null,
    errorMsg: false,
  },
  getAllUsersList: {
    success: false,
    error: false,
    data: null,
    errorMsg: false,
  },
  invaiteAdmin: {
    success: false,
    error: false,
    data: null,
    errorMsg: false,
  },
  getStaffPermissionList: {
    success: false,
    error: false,
    data: null,
    errorMsg: false,
  },
  createStaffGroup: {
    success: false,
    error: false,
    data: null,
    errorMsg: false,
  },
  deleteStaffGroup: {
    success: false,
    error: false,
    data: null,
    errorMsg: false,
  },
  getStaffPermission: {
    success: false,
    error: false,
    data: null,
    errorMsg: false,
  },
  changeStaffPermission: {
    success: false,
    error: false,
    data: null,
    errorMsg: false,
  },
  getStaffList: {
    success: false,
    error: false,
    data: null,
    errorMsg: false,
  },
};

export default function OrgPermissionReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN_PERMISSION_LIST_SUCCESS:
      return {
        ...state,
        getAdminPermissionList: {
          ...state.getAdminPermissionList,
          success: true,
          data: action.user,
        },
      };

    case GET_ADMIN_PERMISSION_LIST_ERROR:
      return {
        ...state,
        getAdminPermissionList: {
          ...state.getAdminPermissionList,
          error: true,
          errorMsg: action.errors,
        },
      };

    case RESET_GET_ADMIN_PERMISSION_LIST:
      return {
        ...state,
        getAdminPermissionList: {
          ...state.getAdminPermissionList,
          error: false,
          errorMsg: false,
          success: false,
        },
      };

    //  create group
    case CREATE_ADMIN_PERMISSION_GROUP_SUCCESS:
      return {
        ...state,
        createAdminGroup: {
          ...state.createAdminGroup,
          success: true,
          data: action.user,
        },
      };

    case CREATE_ADMIN_PERMISSION_GROUP_ERROR:
      return {
        ...state,
        createAdminGroup: {
          ...state.createAdminGroup,
          error: true,
          errorMsg: action.errors,
        },
      };

    case RESET_CREATE_ADMIN_PERMISSION_GROUP:
      return {
        ...state,
        createAdminGroup: {
          ...state.createAdminGroup,
          error: false,
          errorMsg: false,
          success: false,
        },
      };

    //  delete group
    case DELETE_ADMIN_PERMISSION_GROUP_SUCCESS:
      return {
        ...state,
        deleteAdminGroup: {
          ...state.deleteAdminGroup,
          success: true,
          data: action.user,
        },
      };

    case DELETE_ADMIN_PERMISSION_GROUP_ERROR:
      return {
        ...state,
        deleteAdminGroup: {
          ...state.deleteAdminGroup,
          error: true,
          errorMsg: action.errors,
        },
      };

    case RESET_DELETE_ADMIN_PERMISSION_GROUP:
      return {
        ...state,
        deleteAdminGroup: {
          ...state.deleteAdminGroup,
          error: false,
          errorMsg: false,
          success: false,
        },
      };

    //  get Admin List
    case GET_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        getAdminList: {
          ...state.getAdminList,
          success: true,
          data: action.user,
        },
      };

    case GET_ADMIN_LIST_ERROR:
      return {
        ...state,
        getAdminList: {
          ...state.getAdminList,
          error: true,
          errorMsg: action.errors,
        },
      };

    case RESET_GET_ADMIN_LIST:
      return {
        ...state,
        getAdminList: {
          ...state.getAdminList,
          error: false,
          errorMsg: false,
          success: false,
        },
      };

    //  get all Users List
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        getAllUsersList: {
          ...state.getAllUsersList,
          success: true,
          data: action.user,
        },
      };

    case GET_ALL_USERS_ERROR:
      return {
        ...state,
        getAllUsersList: {
          ...state.getAllUsersList,
          error: true,
          errorMsg: action.errors,
        },
      };

    case RESET_GET_ALL_USERS:
      return {
        ...state,
        getAllUsersList: {
          ...state.getAllUsersList,
          error: false,
          errorMsg: false,
          success: false,
        },
      };

    //  Invite Admin
    case INVAITE_ADMIN_SUCCESS:
      return {
        ...state,
        invaiteAdmin: {
          ...state.invaiteAdmin,
          success: true,
          data: action.user,
        },
      };

    case INVAITE_ADMIN_ERROR:
      return {
        ...state,
        invaiteAdmin: {
          ...state.invaiteAdmin,
          error: true,
          errorMsg: action.errors,
        },
      };

    case RESET_INVAITE_ADMIN:
      return {
        ...state,
        invaiteAdmin: {
          ...state.invaiteAdmin,
          error: false,
          errorMsg: false,
          success: false,
        },
      };

    //  Staff Permiossion List
    case GET_STAFF_PERMISSION_LIST_SUCCESS:
      return {
        ...state,
        getStaffPermissionList: {
          ...state.getStaffPermissionList,
          success: true,
          data: action.user,
        },
      };

    case GET_STAFF_PERMISSION_LIST_ERROR:
      return {
        ...state,
        getStaffPermissionList: {
          ...state.getStaffPermissionList,
          error: true,
          errorMsg: action.errors,
        },
      };

    case RESET_GET_STAFF_PERMISSION_LIST:
      return {
        ...state,
        getStaffPermissionList: {
          ...state.getStaffPermissionList,
          error: false,
          errorMsg: false,
          success: false,
        },
      };

    //  Staff Permiossion Create
    case CREATE_STAFF_PERMISSION_GROUP_SUCCESS:
      return {
        ...state,
        createStaffGroup: {
          ...state.createStaffGroup,
          success: true,
          data: action.user,
        },
      };

    case CREATE_STAFF_PERMISSION_GROUP_ERROR:
      return {
        ...state,
        createStaffGroup: {
          ...state.createStaffGroup,
          error: true,
          errorMsg: action.errors,
        },
      };

    case RESET_CREATE_STAFF_PERMISSION_GROUP:
      return {
        ...state,
        createStaffGroup: {
          ...state.createStaffGroup,
          error: false,
          errorMsg: false,
          success: false,
        },
      };

    //  Staff Permiossion Delete
    case DELETE_STAFF_PERMISSION_GROUP_SUCCESS:
      return {
        ...state,
        deleteStaffGroup: {
          ...state.deleteStaffGroup,
          success: true,
          data: action.user,
        },
      };

    case DELETE_STAFF_PERMISSION_GROUP_ERROR:
      return {
        ...state,
        deleteStaffGroup: {
          ...state.deleteStaffGroup,
          error: true,
          errorMsg: action.errors,
        },
      };

    case RESET_DELETE_STAFF_PERMISSION_GROUP:
      return {
        ...state,
        deleteStaffGroup: {
          ...state.deleteStaffGroup,
          error: false,
          errorMsg: false,
          success: false,
        },
      };

    //  Staff Permiossion
    case GET_STAFF_PERMISSION_SUCCESS:
      return {
        ...state,
        getStaffPermission: {
          ...state.getStaffPermission,
          success: true,
          data: action.payload,
        },
      };

    case GET_STAFF_PERMISSION_ERROR:
      return {
        ...state,
        getStaffPermission: {
          ...state.getStaffPermission,
          error: true,
          errorMsg: action.errors,
        },
      };

    case RESET_GET_STAFF_PERMISSION:
      return {
        ...state,
        getStaffPermission: {
          ...state.getStaffPermission,
          error: false,
          errorMsg: false,
          success: false,
        },
      };

    //  Staff Permiossion change
    case SET_STAFF_PERMISSION_SUCCESS:
      return {
        ...state,
        changeStaffPermission: {
          ...state.changeStaffPermission,
          success: true,
          data: action.user,
        },
      };

    case SET_STAFF_PERMISSION_ERROR:
      return {
        ...state,
        changeStaffPermission: {
          ...state.changeStaffPermission,
          error: true,
          errorMsg: action.errors,
        },
      };

    case RESET_SET_STAFF_PERMISSION:
      return {
        ...state,
        changeStaffPermission: {
          ...state.changeStaffPermission,
          error: false,
          errorMsg: false,
          success: false,
        },
      };

    //  Staff list
    case GET_STAFF_LIST_SUCCESS:
      return {
        ...state,
        getStaffList: {
          ...state.getStaffList,
          success: true,
          data: action.user,
        },
      };

    case GET_STAFF_LIST_ERROR:
      return {
        ...state,
        getStaffList: {
          ...state.getStaffList,
          error: true,
          errorMsg: action.errors,
        },
      };

    case RESET_GET_STAFF_LIST:
      return {
        ...state,
        getStaffList: {
          ...state.getStaffList,
          error: false,
          errorMsg: false,
          success: false,
        },
      };
    default:
      return state;
  }
}
