import React, { useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Toaster from '../../../Toaster/Toaster';
import { withRouter } from 'react-router-dom';

const OrgBankInfo = () => {

    const [state, setState] = useState({
        bank_name: "",
        account_name: "",
        account_no: ""

    })

    const [errors, setErrors] = useState({
        bank_name: false,
        account_name: false,
        account_no: false
    })

    const initialState = {
        bank_name: "",
        account_name: "",
        account_no: ""
    };


    const eventHandler = (e) => {

        const { id, name, value } = e.target;

        setState(prevState => ({
            ...prevState,
            [id]: value
        }))

        switch (name) {
            case "bank_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        bank_name: "Bank name can not be empty."
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        bank_name: false
                    }))
                }
                break;

            case "account_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        account_name: "Account name can not be empty."
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        account_name: false
                    }))
                }
                break;

            case "account_no":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        account_no: "Account number can not be empty."
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        account_no: false
                    }))
                }
                break;

            default:
        }

    }

    const savedetails = (e) => {
        e.preventDefault();

        if (state.account_name == "") {

            setErrors(prevState => ({
                ...prevState,
                account_name: "Account name can not be empty."
            }))
        }

        if (state.bank_name == "") {
            setErrors(prevState => ({
                ...prevState,
                bank_name: "Bank name can not be empty."
            }))
        }

        if (state.account_no == "") {
            setErrors(prevState => ({
                ...prevState,
                account_no: "Account can not be empty."
            }))
        }

        if (state.account_name !== "" && state.bank_name !== "" && state.account_no !== "") {
            notify("Data submitted successfully.")
            resetData().then(clearState);
        }
    }

    const notify = (msg) => toast(msg);

    const clearState = () => {
        setState({ ...initialState });
    };

    function resetData() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    }


    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <div className="create-team-head">
                            <h5 className="dash-head">Banking Info</h5>
                            <button className="btn-save" onClick={savedetails} >Save Changes</button>
                        </div>
                    </div>


                    <div className="bank-info-tab">
                        <div className="tab-content" id="pills-tabContent">

                            <h5 className="payment text-left mt-42">Bank</h5>
                            <div className="row">
                                <div className="col-md-4 mt-24">
                                    <input type="text" className={classNames("form-control team-name", { "error": errors.bank_name })} placeholder="Bank Name" id="bank_name" name="bank_name" value={state.bank_name} onChange={eventHandler} />

                                    {errors.bank_name &&
                                        <span className='text-red small d-block'>* {errors.bank_name}</span>}
                                </div>
                                <div className="col-md-4 mt-24">
                                    <input type="text" className={classNames("form-control team-name", { "error": errors.account_name })} placeholder="Account Name" id="account_name" name="account_name" value={state.account_name} onChange={eventHandler} />
                                    {errors.account_name &&
                                        <span className='text-red small d-block'>* {errors.account_name}</span>}
                                </div>
                                <div className="col-md-4 mt-24">
                                    <input type="text" className={classNames("form-control team-name", { "error": errors.account_no })} placeholder="Account No." id="account_no" name="account_no" value=
                                        {state.account_no} onChange={eventHandler} />

                                    {errors.account_no &&
                                        <span className='text-red small d-block'>* {errors.account_no}</span>}
                                </div>
                            </div>
                        </div>
                        <Toaster />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(OrgBankInfo)