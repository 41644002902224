import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./sidebar.css";
import { useLocation } from "react-router-dom";
import $ from "jquery";

const Sidebar = () => {
  const location = useLocation();
  const [name, setname] = useState("");
  const [redirect, setval] = useState("");
  useEffect(() => {
    if (localStorage.getItem("type") == 1) {
      setname("Clubs");
      setval("/clubs");
    } else {
      setname("Teams");
      setval("/teams");
    }
  }, [name, redirect]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          $("#sidebar").removeClass("active");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const sidebar = localStorage.getItem("type");
  return (
    <React.Fragment>
      {sidebar ? (
        <>
          <div ref={wrapperRef}>
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
              <Link to="/dashboard" className="logo-link">
                <img
                  src="./images/pch-images/PCH_Logo_Hori_Blue Blue-white.svg"
                  alt="logo"
                />
              </Link>
              <ul className="nav space-y-2">
                {sidebar == 4 ? (
                  <>
                    <li
                      className={
                        location.pathname === "/dashboard"
                          ? "active nav-item"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to="/dashboard">
                        <i className="mdi mdi-home menu-icon"></i>
                        <span className="menu-title">Dashboard</span>
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === "/events"
                          ? "active nav-item"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to="/events">
                        <i className="mdi mdi-calendar-check menu-icon"></i>
                        <span className="menu-title">Events</span>
                      </Link>
                    </li>
                    {/* <li className={location.pathname === "/leagues" ? "active nav-item" : "nav-item"}>
                                            <Link className="nav-link" to="/leagues">
                                                <i className="mdi mdi-calendar-check menu-icon"></i>
                                                <span className="menu-title">Leagues</span>
                                            </Link>
                                        </li> */}
                    <li
                      className={
                        location.pathname === "/payment"
                          ? "active nav-item"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to="/payment">
                        <i className="mdi mdi-credit-card menu-icon"></i>
                        <span className="menu-title">Payment</span>
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === "/teams"
                          ? "active nav-item"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to={redirect}>
                        <i className="mdi mdi-account-multiple menu-icon"></i>
                        <span className="menu-title">{name}</span>
                      </Link>
                    </li>
                    {/* <li
                      className={
                        location.pathname === "/season"
                          ? "active nav-item"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to="/season">
                        <i className="mdi mdi-plus menu-icon"></i>
                        <span className="menu-title">Seasons</span>
                      </Link>
                    </li> */}
                    {/* <li
                      className={
                        location.pathname === "/leagues"
                          ? "active nav-item"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to="/leagues">
                        <i className="mdi mdi-plus menu-icon"></i>
                        <span className="menu-title">Leagues</span>
                      </Link>
                    </li> */}
                    {/* <li
                      className={
                        location.pathname === "/field"
                          ? "active nav-item"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to="/field">
                        <i className="mdi mdi-plus menu-icon"></i>
                        <span className="menu-title">Fields</span>
                      </Link>
                    </li> */}
                    {/* <li
                      className={
                        location.pathname === "/division"
                          ? "active nav-item"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to="/division">
                        <i className="mdi mdi-plus menu-icon"></i>
                        <span className="menu-title">Divisions</span>
                      </Link>
                    </li> */}
                    {/* <li
                      className={
                        location.pathname === "/liability-waiver"
                          ? "active nav-item"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to="/liability-waiver">
                        <i className="mdi mdi-plus menu-icon"></i>
                        <span className="menu-title">Liabilitie Waiver</span>
                      </Link>
                    </li> */}
                    <li
                      className={
                        location.pathname === "#"
                          ? "active nav-item"
                          : "nav-item"
                      }
                    >
                      <a
                        className="nav-link"
                        href="https://www.google.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="mdi mdi-information-variant menu-icon"></i>
                        <span className="menu-title"> About Us</span>{" "}
                      </a>
                    </li>
                    {/* <li
                      className={
                        location.pathname === "#"
                          ? "active nav-item"
                          : "nav-item"
                      }
                    >
                      <a
                        className="nav-link"
                        href="https://www.google.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="mdi mdi-information-variant menu-icon"></i>
                        <span className="menu-title">Contact Us</span>{" "}
                      </a>
                    </li> */}
                    {/* <li
                      className={
                        location.pathname === "#"
                          ? "active nav-item"
                          : "nav-item"
                      }
                    >
                      <a
                        className="nav-link"
                        href="https://www.google.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="mdi mdi-information-variant menu-icon"></i>
                        <span className="menu-title"> Help</span>{" "}
                      </a>
                    </li> */}
                  </>
                ) : (
                  <>
                    {sidebar == 1 ? (
                      <>
                        <li
                          className={
                            location.pathname === "/dashboard"
                              ? "active nav-item"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/dashboard">
                            <i className="mdi mdi-home menu-icon"></i>
                            <span className="menu-title">Dashboard</span>
                          </Link>
                        </li>
                        <li
                          className={
                            location.pathname === "/events"
                              ? "active nav-item"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/events">
                            <i className="mdi mdi-calendar-check menu-icon"></i>
                            <span className="menu-title">Events</span>
                          </Link>
                        </li>
                        <li
                          className={
                            location.pathname === "/payment"
                              ? "active nav-item"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/payment">
                            <i className="mdi mdi-credit-card menu-icon"></i>
                            <span className="menu-title">Payment</span>
                          </Link>
                        </li>
                        <li
                          className={
                            location.pathname === redirect
                              ? "active nav-item"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to={redirect}>
                            <i className="mdi mdi-account-multiple menu-icon"></i>
                            <span className="menu-title">{name}</span>
                          </Link>
                        </li>
                        {/* <li
                          className={
                            location.pathname === "/season"
                              ? "active nav-item"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/season">
                            <i className="mdi mdi-plus menu-icon"></i>
                            <span className="menu-title">Season</span>
                          </Link>
                        </li> */}
                        <li
                          className={
                            location.pathname === "#"
                              ? "active nav-item"
                              : "nav-item"
                          }
                        >
                          <a
                            className="nav-link"
                            href="https://www.google.com/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <i className="mdi mdi-information-variant menu-icon"></i>
                            <span className="menu-title"> About Us</span>{" "}
                          </a>
                        </li>
                        {/* <li
                          className={
                            location.pathname === "#"
                              ? "active nav-item"
                              : "nav-item"
                          }
                        >
                          <a
                            className="nav-link"
                            href="https://www.google.com/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <i className="mdi mdi-information-variant menu-icon"></i>
                            <span className="menu-title">Contact Us</span>{" "}
                          </a>
                        </li> */}
                        {/* <li
                          className={
                            location.pathname === "#"
                              ? "active nav-item"
                              : "nav-item"
                          }
                        >
                          <a
                            className="nav-link"
                            href="https://www.google.com/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <i className="mdi mdi-information-variant menu-icon"></i>
                            <span className="menu-title"> Help</span>{" "}
                          </a>
                        </li> */}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </ul>
            </nav>
          </div>
        </>
      ) : null}
    </React.Fragment>
  );
};
export default Sidebar;
