
import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { getOrgClubList, getOrgCredit } from '../../../service/action/userAction'
import Spinner from '../../../Spinner/Spinner';

const ClubCredit = (props) => {


    const credit = useSelector(state => state.getOrgCreditReducer.user.data)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        props.getCreditHandler()
    }, [])

    const EventTbl = [
        {
            name: "Club name",
            Phone: "xxx-xxx-xxxx",
            payment: "Good",
            status: "Active",
        },
        {
            name: "Club name",
            Phone: "xxx-xxx-xxxx",
            payment: "Good",
            status: "Invited",
        },
        {
            name: "Club name",
            Phone: "xxx-xxx-xxxx",
            payment: "Good",
            status: "Active",
        },
        {
            name: "Club name",
            Phone: "xxx-xxx-xxxx",
            payment: "Good",
            status: "Active",
        },
    ];

    useEffect(() => {
        if (props.credit?.success) {
            setLoading(false)
        }
    }, [props.credit])


    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className='d-flex justify-content-center'>

                        {loading ? <Spinner /> : null}
                    </div>
                    {
                        !loading && <>


                            <div className="text-left">
                                <div className="create-team-head-space">
                                    <h5 className="dash-head">Credits</h5>
                                    <Link to="/createclubcredit" className='text-decoaration-none'>
                                        <button className="btn-create-new"> Create New</button>
                                    </Link>
                                </div>
                            </div>
                            {/* <div className="text-left"> */}
                            {/* <div className="create-team-head-event"> */}
                            {/* <h5 className="dash-head">Credit</h5> */}
                            {/* <div className="dropdown details-drp"> */}
                            {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add More</i> */}
                            {/* <Link className="dropdown-item" to={`/newcredit`}> <i className="mdi mdi-plus mr-2 website-ico"></i>Create New</Link> */}

                            {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <Link className="dropdown-item" to={`/add-staff`}> <i className="mdi mdi-plus mr-2 website-ico"></i>Add Staff</Link>
                                </div> */}
                            {/* </div> */}
                            {/* </div> */}
                            {/* </div> */}
                            {
                                credit && credit.length == 0 ?
                                    <>
                                        <div className='container d-flex justify-content-center align-items-center'>
                                            <span className='noti-type'>No Credits to display</span>
                                        </div>
                                    </>
                                    :
                                    <>

                                        {/* desktop view */}
                                        <div className="table-responsive d-none d-md-block">
                                            <table className="table event-table mt-30">
                                                <thead>
                                                    <tr>
                                                        <th>Recipient Type</th>
                                                        <th>Recipient Name</th>
                                                        <th>Event Name</th>
                                                        <th>Credit Amount</th>
                                                        {/* <th>Payment</th>
                                    <th>Status</th> */}
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {credit.length == 0 ? <> <span className='noti-type'></span></> : <>{

                                                        credit.map((v, i) => {
                                                            return <tr key={i}>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        {/* <div className="dashboard-img">
                                                       <img src="./images/pch-images/pch.svg" />
                                                   </div> */}
                                                                        {v.receipient_type == 4 ? "Club" : v.receipient_type == 5 ? "Player" : ""}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        {/* <div className="dashboard-img">
                                                       <img src="./images/pch-images/pch.svg" />
                                                   </div> */}
                                                                        {v.receipient.first_name}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        {/* <div className="dashboard-img">
                                                       <img src="./images/pch-images/pch.svg" />
                                                   </div> */}
                                                                        {v.event.event_name}
                                                                    </div>
                                                                </td>
                                                                <td>{v.credit_amount}</td>
                                                                {/* <td>{v.payment}</td>
                                           <td>{v.status}</td> */}
                                                                {/* <td>
                                               <div className="dropdown event-drp">
                                                   <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                   More
                                                   <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                       <a className="dropdown-item" href="#">
                                                           <i className="mdi mdi-bookmark-outline"></i>
                                                           Bookmark</a>
                                                       <a className="dropdown-item" href="#">
                                                           <i className="mdi mdi-plus"></i>
                                                           Invite</a>
                                                   </div>
                                               </div>
                                           </td> */}
                                                            </tr>
                                                        })

                                                    }</>
                                                    }


                                                </tbody>
                                            </table>
                                        </div>

                                        {/* mobile view */}

                                        <div className="mt-30 d-md-none">
                                            {credit &&
                                                credit.map((v, i) => {
                                                    return <div className="d-flex justify-content-between reg-details" key={i}>
                                                        <div className="d-flex">
                                                            <div className="dashboard-img mr-3">
                                                                <img src="./images/pch-images/pch.svg" />
                                                            </div>
                                                            <div className="reg-content">
                                                                <Link className="text-decoration-none text-blue fw-m" to="#">{v.receipient_type == 4 ? "Club" : ""}</Link>
                                                                <span className="d-block text-gray mt-2">Credit issue: {v.credit_amount}</span>
                                                                {/* <span className="d-block text-gray mt-2">Status: {v.status}</span> */}

                                                            </div>
                                                        </div>
                                                        {/* <div className="dropdown event-drp">
                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-bookmark-outline"></i>
                                                Bookmark</a>
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-plus"></i>
                                                Invite</a>                                           
                                        </div>
                                    </div> */}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </>
                            }

                        </>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

// export default withRouter(Club);

const mapStateToProps = (state) => ({
    submit: state.createTeamReducer,
    credit: state.getOrgCreditReducer

})

const mapDispatchToProps = dispatch => ({
    getOrg_ClubListHandler: user => dispatch(getOrgClubList(user)),
    getCreditHandler: user => dispatch(getOrgCredit(user))
    // userDataHandler: user => dispatch(userData(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClubCredit))