import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router";
import { connect } from "react-redux";
import classNames from "classnames";
import { CreateStayandPlayAction } from "../../../service/action/userAction";
import { RESET_CREATE_STAY_AND_PLAY } from "../../../service/constants";
import { useHistory } from "react-router-dom";
import { Button, Form } from "react-bootstrap";

const Addstayandplay = (props) => {
  let history = useHistory();
  const { id } = useParams();
  const [prevImg, setPrevImg] = useState();
  const [checkRadiovalue, setcheckRadiovalue] = useState("text");
  const [Image, setImage] = useState(null);

  const [state, setstate] = useState({
    URLtext: "",
    image: "",
  });

  const [error, seterror] = useState({
    URLtext: false,
    image: false,
  });

  useEffect(() => {
    if (props.createStayandPlayReducer?.success) {
      history.push(`/event-details/${id}`);
    }
  }, [props.createStayandPlayReducer]);

  const handleRadio = (e) => {
    const { value } = e.target;
    setcheckRadiovalue(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "image") {
      setstate((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setImage(e.target.files[0]);
      setPrevImg(
        e.target.files?.length !== 0 && URL.createObjectURL(e.target.files[0])
      );
    } else {
      setstate((preve) => ({
        ...preve,
        [name]: value,
      }));
    }

    switch (e.target.name) {
      case "image":
        if (value == "") {
          seterror((prevState) => ({
            ...prevState,
            image: "image can not be empty",
          }));
        } else if (!RegExp(/^image/gim).test(e.target.files[0].type)) {
          seterror((prevState) => ({
            ...prevState,
            image: "Only accept image file",
          }));
        } else {
          seterror((prevState) => ({
            ...prevState,
            image: false,
          }));
          // document.getElementById("image").classList.remove("error");
        }
        break;
      case "URLtext":
        if (value == "") {
          seterror((prevState) => ({
            ...prevState,
            URLtext: "image can not be empty",
          }));
        } else if (!RegExp(/http:|https:/).test(value)) {
          seterror((prevState) => ({
            ...prevState,
            URLtext: "image can not be Valid",
          }));
        } else {
          seterror((prevState) => ({
            ...prevState,
            URLtext: false,
          }));
          // document.getElementById("image").classList.remove("error");
        }
        break;
      default:
    }
  };
  const imgDefault = (e) => {
    e.target.src = "/images/menu-placeholder.jpg";
    e.target.onerror = "/images/menu-placeholder.jpg";
  };

  const handlecreate = () => {
    if (checkRadiovalue == "text") {
      if (state.URLtext == "") {
        seterror((pre) => ({
          ...pre,
          URLtext: "Required",
        }));
      }
      if (state.URLtext != "" && !error.URLtext) {
        props.CreateStayandPlayAction({
          text: state.URLtext,
          event_id: id ? id : "",
          type: 0,
          file: Image,
        });
      }
    } else if (checkRadiovalue == "image") {
      if (state.image == "") {
        seterror((pre) => ({
          ...pre,
          image: "Required",
        }));
      }
      if (state.image != "" && !error.image) {
        props.CreateStayandPlayAction({
          text: state.URLtext,
          event_id: id ? id : "",
          type: 1,
          file: Image,
        });
      }
    }
  };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Add Schedule</h5>
          </div>
          <div className="row">
            <div className="form-check form-check-inline col-1">
              <input
                className="form-check-input fs-5 d-none"
                type="radio"
                name="inlineRadioOptions"
                id="selectText"
                value="text"
                checked={checkRadiovalue === "text"}
                onChange={handleRadio}
              />
              <label
                className="form-check-label mt-1"
                for="selectText"
                style={{ fontWeight: "bold" }}
              >
                URL
              </label>
            </div>
            <div className="form-check form-check-inline col-1">
              <input
                className="form-check-input fs-5 d-none"
                type="radio"
                name="inlineRadioOptions"
                id="selectImage"
                value="image"
                onChange={handleRadio}
              />
              <label
                className="form-check-label mt-1"
                for="selectImage"
                style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
              >
                Upload Document
              </label>
            </div>
          </div>
          <div>
            {checkRadiovalue === "text" && (
              <div className="col-md-6 mt-18">
                <input
                  type="url"
                  className={classNames("form-control team-name", {
                    errorBorder: error.URLtext,
                  })}
                  placeholder="Event URL"
                  id="URLtext"
                  value={state.URLtext}
                  name="URLtext"
                  onChange={handleChange}
                />
              </div>
            )}
            {checkRadiovalue === "image" && (
              <div className="">
                <Form.Group className="mb-3">
                  <Form.Label style={{ fontWeight: "bold" }}>
                    Document
                  </Form.Label>
                  <div
                    className={classNames("custom-file-upload", {
                      errorBorder: error.image,
                    })}
                  >
                    <input
                      type="file"
                      name="image"
                      id="image"
                      accept="image/*"
                      label="image"
                      onChange={handleChange}
                    />
                    <label htmlFor="image">
                      {prevImg ? (
                        <div className="uploader-prev">
                          <img
                            className="img-fluid"
                            alt="droped image"
                            src={prevImg}
                          />
                          <span className="replace-btn">Replace</span>
                        </div>
                      ) : (
                        <div className="upload-placeholder text-center">
                          <i
                            className="fa fa-cloud-upload fa-3x"
                            aria-hidden="true"
                          ></i>
                          <h3>Upload Document</h3>
                        </div>
                      )}
                    </label>
                  </div>
                </Form.Group>
              </div>
            )}
          </div>
          <button className="mt-4 btn-save" onClick={handlecreate}>
            Submit
          </button>

          <Toaster />
        </div>
      </div>
    </>
  );
};

// export default withRouter(Org_CreateDivision)

const mapStateToProp = (state) => ({
  createStayandPlayReducer: state.StayandPlayReducer.createStayandPlay,
});

const mapDispatchToProps = (dispatch) => ({
  CreateStayandPlayAction: (user) => dispatch(CreateStayandPlayAction(user)),
  resetstayandplay: () => dispatch({ type: RESET_CREATE_STAY_AND_PLAY }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Addstayandplay));
