import {connect} from "react-redux"
// import { register } from "../service/action/userAction";
import {signin} from "../service/action/userAction"
import Login from "../login/Login";
import { RESET_RESET_PASSWORD, RESET_SIGNIN, RESET_SIGNUP ,RESET_SIGNUP_BY_MAIL} from "../service/constants";


const mapStateToProp = state => ({
    signin:state.signinReducer,
    signup:state.signupReducer,
    orgStatus:state.signinReducer.status,
    clubStatus:state.signinReducer.club,
    // token:state.signinReducer.token,
    info:state.signinReducer.info,
    auth:state.signinReducer.auth,
    loading:state.signinReducer.loading,
    reset:state.resetPasswordReducer,
    signUpByMail:state.signupByMailRducer
})

const mapDispatchToProps = dispatch => ({
    signInHandler:user => dispatch(signin(user)),
    resetPasswordHandler:()=>dispatch({type:RESET_RESET_PASSWORD}),
    resetSignup:() => dispatch({type:RESET_SIGNUP}),
    resetSignupBymailReducer:() => dispatch({type:"RESET_SIGNUP_BY_MAIL"}),
    resetSigninReducer:()=>dispatch({type:"RESET_SIGNIN"})
    // resetSignin:() => dispatch({type:RESET_SIGNIN})

}) 

export default connect(mapStateToProp,mapDispatchToProps)(Login)
// export default Home; 