import { DELETE_EVENT_ERROR, DELETE_EVENT_LOADING, DELETE_EVENT_SUCCESS, RESET_DELETE_EVENT } from "../../constants";

let initialState={
    success:false,
    error:false,
    loading:false,
    error:[]
}


export default function deleteEventReducer(state=initialState,action){
    switch(action.type){
        case DELETE_EVENT_LOADING:
            return {...state,success:false,loading:true,error:false}
        case DELETE_EVENT_SUCCESS:
            return {...state,success:true,loading:false,error:false,message:action.msg}
        case DELETE_EVENT_ERROR:
            return {...state,success:false,loading:false,error:false}
        case RESET_DELETE_EVENT:
            return {...state,success:false,loading:false,error:false}
        default :
        return state
    }
}