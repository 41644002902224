import { RESET_TEAM_ARRAY_DATA, TEAM_ARRAY_ID } from '../../../constants'

let initialState = {
    success: false,
    loading:false,
    error:false,
    user: null,
}

export default function teamArrayReducer(state = initialState, action) {

    switch (action.type) {

        case TEAM_ARRAY_ID:

            // alert("reducer success")
            return { ...state, success: true, user: action.payload, data: action.data };

        case RESET_TEAM_ARRAY_DATA:
            return { ...state, success: false, error: false, loading: false }


        default:
            return state;
    }

}