// import {
//   GET_PAYMENTREQUEST_ERROR,
//   GET_PAYMENTREQUEST_LOADING,
//   GET_PAYMENTREQUEST_SUCCESS,
// } from "../../../constants";

// let initialState = {
//   loading: false,
//   user: [],
//   error: false,
//   errors: [],
//   success: false,
// };

// export default function getPaymentReqSendReducer(state = initialState, action) {
//   switch (action.type) {
//     case GET_PAYMENTREQUEST_LOADING:
//       // alert(" reducer succesfull")
//       return { ...state, success: false, success: false };

//     case GET_PAYMENTREQUEST_SUCCESS:
//       // alert(" reducer Error")
//       return { ...state, loading: false, success: true, user: action.user };

//     case GET_PAYMENTREQUEST_ERROR:
//       // alert(" reducer Loading")
//       return { ...state, error: true, errors: action.errors };

//     default:
//       return state;
//   }
// }

import { GET_PAYMENTREQUEST_ERROR, GET_PAYMENTREQUEST_LOADING, GET_PAYMENTREQUEST_SUCCESS } from "../../../constants";
// import {
//   GET_STATE_ERROR,
//   GET_STATE_LOADING,
//   GET_STATE_SUCCESS,
// } from "../../constants";

let initialState = {
  error: false,
  loading: false,
  errors: [],
  success: false,
  states: [],
};

export default function getPaymentReqSendReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENTREQUEST_LOADING:
      return { ...state, error: false, loading: true, success: false };
    case GET_PAYMENTREQUEST_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        states: action.payload,
      };
    case GET_PAYMENTREQUEST_ERROR:
      return {
        ...state,
        error: true,
        errors: [],
        success: false,
        loading: false,
      };
    default:
      return state;
  }
}
