import { GET_PLAYER_INVITAION_ERROR, GET_PLAYER_INVITAION_LOADING, GET_PLAYER_INVITAION_SUCCESS } from "../../constants";


let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function getPlayerInvitationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PLAYER_INVITAION_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case GET_PLAYER_INVITAION_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };
     
    case GET_PLAYER_INVITAION_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}
