import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import userProfile from "../assets/images/user.png";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { getUserDetails } from "../Services/Actions/userAction";
import { useEffect } from "react";
import { RESET_LOGOUT, removeStorage } from "../Services/constant";
import { logout } from "../Services/Actions";
function Header(props) {
  const history = useHistory();
  const userDetails = useSelector(
    (state) => state.superAdminRootReducer.authReducers.userDetails
  )?.data?.data;

  const [isLogOut, setIsLogout] = useState(false);

  useEffect(() => {
    if (!props.userDetailsReducer.success) {
      props.getUserDetails();
    }
  }, []);

  const handleLogOut = () => {
    props.logout();
  };
  useEffect(() => {
    if (props.logoutReducer.success) {
      props.resetLogout();
      removeStorage();
      history.push("/superadmin/login");
    }
  }, [props.logoutReducer]);

  return (
    <header>
      <Dropdown className="notification-menu ml-4">
        <Dropdown.Toggle id="dropdown-basic">
          <i className="fa-solid fa-bell"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu className="notification">
          <div className="notification-header">Notification</div>
          <div className="notification-body">
            <Dropdown.Item as={Link} to="#_">
              <img
                src={require("../assets/images/user.png")}
                className="noti-img"
                alt="User"
              />
              <div className="noti-content">
                <h6 className="d-flex justify-content-between">
                  John Doe <small className="text-muted">2:30 pm</small>
                </h6>
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer ...
                </p>
              </div>
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="#_">
              <img
                src={require("../assets/images/user.png")}
                className="noti-img"
                alt="User"
              />
              <div className="noti-content">
                <h6 className="d-flex justify-content-between">
                  John Doe <small className="text-muted">2:30 pm</small>
                </h6>
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer ...
                </p>
              </div>
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="#_">
              <img
                src={require("../assets/images/user.png")}
                className="noti-img"
                alt="User"
              />
              <div className="noti-content">
                <h6 className="d-flex justify-content-between">
                  John Doe <small className="text-muted">2:30 pm</small>
                </h6>
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer ...
                </p>
              </div>
            </Dropdown.Item>
          </div>
          <div className="notification-footer">
            <Dropdown.Item
              as={Link}
              to="#_"
              className="bg-white px-0 text-secondary"
            >
              See all Notification
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="super-admin-profile-menu ml-4">
        <Dropdown.Toggle className="super-admin-profile" id="dropdown-basic">
          <h5 className="mb-0" style={{ fontSize: "20px" }}>
            {userDetails?.first_name}&nbsp;{userDetails?.last_name}
          </h5>
          <div className=" pl-2">
            <img src={userProfile} className="img-fluid" alt="User ico" />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/superadmin/changePassword">
            <i className="ri-lock-2-fill me-2"></i> Change Password
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to="#"
            onClick={() => {
              setIsLogout(true);
            }}
          >
            <i className="ri-logout-box-line mr-3"></i> Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Modal
        size="sm"
        show={isLogOut}
        onHide={() => setIsLogout(false)}
        centered
        className="del-dialogue"
      >
        <Modal.Body>
          <h6 className="text-center">Are you sure you want to Logout?</h6>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Button className="btn-gray" onClick={() => setIsLogout(false)}>
            No
          </Button>
          <Button variant="secondary" onClick={handleLogOut}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </header>
  );
}

const mapStateToProp = (state) => ({
  userDetailsReducer: state.superAdminRootReducer.authReducers.userDetails,
  logoutReducer: state.superAdminRootReducer.authReducers.logout,
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: (user) => dispatch(getUserDetails(user)),
  logout: () => dispatch(logout()),
  resetLogout: () => dispatch({ type: RESET_LOGOUT }),
});

export default connect(mapStateToProp, mapDispatchToProps)(Header);
