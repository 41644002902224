import {
  ORG_CLUB_CREDIT_ERROR,
  ORG_CLUB_CREDIT_LOADING,
  ORG_CLUB_CREDIT_SUCCESS,
  RESET_ORG_CLUB_CREDIT,
} from "../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
};

export default function clubcreditReducer(state = initialState, action) {
  switch (action.type) {
    case ORG_CLUB_CREDIT_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case ORG_CLUB_CREDIT_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.data };

    case ORG_CLUB_CREDIT_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };
    case RESET_ORG_CLUB_CREDIT:
      // alert(" reducer Loading")
      return { ...state, error: null, errors: null, user: [] };

    default:
      return state;
  }
}
