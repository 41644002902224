import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  getTeamDetails,
  getTeamEvent,
  getTeamEventsDetails,
  removeRoster,
  removeTeamPlayer,
} from "../../../service/action/Club/clubAction";
import Spinner from "../../../Spinner/Spinner";
import moment from "moment";
import DeletePopUp from "../../components/DeletePopUp/DeletePopUp";
import {
  RESET_ADD_TEAM_PLAYER,
  RESET_CREATE_ROSTER,
  RESET_CREATE_ROSTER_ERROR,
  RESET_GET_CLUB_TEAM_EVENT,
  RESET_REMOVE_ROSTER,
  RESET_REMOVE_TEAM_PLAYER,
  RESET_TEAM_DETAIL_ERROR,
} from "../../../service/constants";
import PlayerImagePopup from "../../components/PlayerImagePopup/PlayerImagePopup";
import Toaster from "../../../Toaster/Toaster";
import { toast } from "react-toastify";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const TeamDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedProfileImage, setSelectedProfileImage] = useState(null);
  const [isRosterDelete, setIsRosterDelete] = useState(false);
  const [isPlayerDelete, setIsPlayerDelete] = useState(false);
  const [removeRosterId, setRemoveRosterId] = useState(null);
  const [removePlayerId, setRemovePlayerId] = useState(null);

  const [eventList, setEventList] = useState([])
  const location = useLocation()
  const { id, id1, id2, id4 } = useParams();
  const history = useHistory();
  const notify = (msg) => toast(msg)
  useEffect(() => {
    props.getTeamDetails({ id: id });
    props.getTeamEventsDetails({ id: id })
    setLoading(true)
    // props.getTeamEventHandler({ id: id });
  }, []);


  useEffect(() => {
    if (props.eventDetails.success) {
      const data = props.eventDetails.data
      setEventList(data)
      setLoading1(false)
    }
  }, [props.eventDetails])

  const teamDetail = useSelector(
    (state) => state.getTeamDetailsReducer.user.data
  );

  const teamEvent = useSelector(
    (state) => state.getTeamEventsReducer.user.allEvent
  );
  const accountDetails = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );

  useEffect(() => {
    if (props.team_details?.success) {
      setLoading(false);
      props.resetCreate();
      props.resetRemoveRoster();
      // props.resetTeamDetails();
    }
  }, [props.team_details]);


  useEffect(() => {
    if (props.removeTeamPlayerReducer?.success) {
      notify("Remove Player ")
      props.getTeamDetails({ id: id })
      props.resetRemovePlayer()
    }
  }, [props.removeTeamPlayerReducer]);


  useEffect(() => {
    if (props.removeRosterReducer.success) {
      notify("Remove Roster ")
      props.getTeamDetails({ id: id });
    }
  }, [props.removeRosterReducer]);
  useEffect(() => {
    if (props.roster.success) {
      notify("Create Roster ")
      props.getTeamDetails({ id: id });

    }
  }, [props.roster]);

  const getEventStatus = (start, end) => {
    const startDate = moment(start)
    const endDate = moment(end)
    if (moment().isBefore(startDate) && moment().isBefore(endDate)) {
      return "Upcoming"
    } else if (moment().isAfter(startDate) && moment().isBefore(endDate)) {
      return "Open"
    } else {
      return "Close"
    }
  }


  useEffect(() => {
    if (props.addTeamPlayerReducer?.success) {
      notify("Player Added")
      props.resetcreateTeamReducer()
      props.getTeamDetails({ id: id });
    }

  }, [props.addTeamPlayerReducer])

  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />
        <div className="content-wrapper">
          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          ) : (
            <>
              {teamDetail && accountDetails ? (
                <div className="mb-5">
                  <div
                    className="d-flex align-items-center justify-content-center w-100"
                    style={{ height: "150px" }}
                  >
                    <img
                      src={
                        teamDetail.image !== ""
                          ? teamDetail.image
                          : `images/pch-images/PCH_Logo.png`
                      }
                      className="h-100"
                    />
                  </div>
                </div>
              ) : null}
              <div className="text-left">
                <h5 className="dash-head">
                  {teamDetail ? teamDetail.team_name : ""}
                </h5>
              </div>
              <div className="team-details">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="details"
                      data-toggle="pill"
                      href="#details-tab"
                      role="tab"
                      aria-controls="pills-details"
                      aria-selected="true"
                    >
                      Details
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="players"
                      data-toggle="pill"
                      href="#players-tab"
                      role="tab"
                      aria-controls="pills-players"
                      aria-selected="false"
                    >
                      Players
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="rosters"
                      data-toggle="pill"
                      href="#roster-tab"
                      role="tab"
                      aria-controls="pills-rosters"
                      aria-selected="false"
                    >
                      Rosters
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="events"
                      data-toggle="pill"
                      href="#event-tab"
                      role="tab"
                      aria-controls="pills-event"
                      aria-selected="false"
                    >
                      Events
                    </a>
                  </li>
                </ul>

                <div className="tab-content" id="pills-tabContent">
                  <div className="d-flex justify-content-center">
                    {loading ? <Spinner /> : null}
                  </div>

                  {!loading && teamDetail ? (
                    <>
                      <div
                        className="tab-pane fade show active"
                        id="details-tab"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="d-flex justify-content-between">
                          <h4 className="text-left mb-3">Details</h4>
                          <div className="dropdown details-drp">
                            <i
                              className="mdi mdi-dots-vertical"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></i>

                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <Link
                                className="dropdown-item"
                                to={`/assigncoach/${id}`}
                              >
                                Assign coaches
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="text-left ">
                          <div className="d-flex align-items-center mb-2">
                            <p className="text-left text-gray mb-0 fs-14">
                              Season :{" "}
                            </p>
                            <h6 className="text-left text-gray ml-1 m-0">
                              {teamDetail.season !== undefined
                                ? teamDetail.season.season_name
                                : "N/A"}
                            </h6>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <p className="text-left text-gray mb-0 fs-14">
                              Division :{" "}
                            </p>
                            <h6 className="text-left text-gray  ml-1 m-0">
                              {teamDetail.division !== undefined
                                ? teamDetail.division.division_name
                                : "N/A"}
                            </h6>
                          </div>
                        </div>

                        <div className="mt-42">
                          <h4 className="text-left mb-3">Coach</h4>
                          {teamDetail.coach.length !== 0 ? (
                            teamDetail.coach.map((v, i) => {
                              return (
                                <>
                                  <div className="basic-info">
                                    <label className="change-pic">
                                      <img
                                        src={
                                          v.profile_image !== ""
                                            ? v.profile_image
                                            : "./images/pch-images/pch.svg"
                                        }
                                        className="img-fluid"
                                      />
                                    </label>

                                    <div className="ml-2 ">
                                      <span className="d-block coach-name">
                                        {v.name}
                                      </span>
                                      <Link to="/notifications" className="msg">
                                        <i className="mdi mdi-message-text"></i>
                                        <span>Message</span>
                                      </Link>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <div className="text-gray text-center mt-3">
                              Coach is not available
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div
                    className="tab-pane fade"
                    id="players-tab"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="d-flex justify-content-center">
                      {loading ? <Spinner /> : null}
                    </div>
                    {!loading && (
                      <>
                        <div className="d-flex justify-content-between">
                          <h4 className="text-left mb-3">Player</h4>

                          <div className="d-flex align-items-center">
                            <div className="dropdown players-drp">
                              <i
                                className="mdi mdi-dots-vertical"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              ></i>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <Link
                                  className="dropdown-item"
                                  to={`/invite-player/${id}/2`}
                                >
                                  Send invitation
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to={`/addplayertoteam/${id}`}
                                >
                                  Add Players
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        {teamDetail && teamDetail.player.length == 0 ? (
                          <>
                            <div className="container d-flex justify-content-center align-items-center">
                              <span className="noti-type">
                                Player is not available
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <table className="table event-table text-left players-tbl">
                              {teamDetail ? (
                                <>
                                  {teamDetail.player ? (
                                    <>
                                      <tbody>
                                        {teamDetail.player.map((v, i) => {
                                          return (
                                            <tr
                                              key={v._id}
                                              className="player-row"
                                            >
                                              <td>
                                                <div className="d-flex align-items-center justify-content-between">
                                                  <div className="d-flex align-items-center">
                                                    <div className="dashboard-img">
                                                      <img
                                                        src={
                                                          v.profile_image !== ""
                                                            ? v.profile_image
                                                            : "./images/pch-images/pch.svg"
                                                        }
                                                        onClick={() => {
                                                          setOpenImageModal(
                                                            true
                                                          );
                                                          setSelectedProfileImage(
                                                            v.profile_image !==
                                                              ""
                                                              ? v.profile_image
                                                              : "./images/pch-images/pch.svg"
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                    <Link
                                                      className="ml-12 heading text-decoration-none filter-string"
                                                      to={`/player-detail/${v._id}`}
                                                    >{`${v.first_name} ${v.last_name}`}</Link>
                                                  </div>
                                                  <div className="d-flex align-item-center justify-content-center">
                                                    <Link
                                                      className="d-block text-danger ml-18 fs-18 pointer"
                                                      to={`/chat/${teamDetail._id}`}
                                                    >
                                                      <a className="d-block text-info ml-12 fs-18 pointer">
                                                        <i className="mdi mdi-message-text"></i>
                                                      </a>
                                                    </Link>

                                                    <Link
                                                      className="d-block text-danger ml-18 fs-18 pointer"
                                                      to={`/teams/${teamDetail._id}`}
                                                      onClick={() => {
                                                        setIsPlayerDelete(true)
                                                        setRemovePlayerId(v._id);
                                                      }
                                                      }
                                                    >
                                                      <i className="fas fa-trash-alt"></i>
                                                    </Link>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </table>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div
                    className="tab-pane fade"
                    id="roster-tab"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <div className="d-flex justify-content-center">
                      {loading ? <Spinner /> : null}
                    </div>
                    {!loading && (
                      <>
                        <div className="d-flex justify-content-between">
                          <h4 className="text-left mb-3">Rosters</h4>

                          <div className="dropdown roster-drp">
                            <i
                              className="mdi mdi-dots-vertical"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></i>

                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <Link
                                className="dropdown-item"
                                to={`/addroster/${id}`}
                              >
                                Create Roster
                              </Link>
                            </div>
                          </div>
                        </div>

                        {teamDetail && teamDetail.roster_player.length == 0 ? (
                          <>
                            <div className="container d-flex justify-content-center align-items-center">
                              <span className="noti-type">
                                No roster found{" "}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="mt-3">
                              {teamDetail && teamDetail.roster_player
                                ? teamDetail.roster_player.map((player) => {
                                  return (
                                    <div
                                      className="border p-2 rounded mb-3"
                                      key={player._id}
                                    >
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h4
                                          className="text-left flex-grow-1 pointer"
                                          onClick={() =>
                                            history.push(
                                              `/team-roster/${id}/${player._id}`
                                            )
                                          }
                                        >
                                          {player.roster_name}
                                        </h4>
                                        <div className="dropdown event-drp p-2">
                                          <i
                                            className="mdi mdi-dots-vertical"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          ></i>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuLink"
                                          >
                                            <Link
                                              className="dropdown-item"
                                              to={`/addroster/${id}/${player._id}`}
                                            >
                                              Add Player
                                            </Link>
                                            <div
                                              className="dropdown-item"
                                              onClick={() => {
                                                setIsRosterDelete(true);
                                                setRemoveRosterId(player._id);
                                              }}
                                            >
                                              Delete
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                                : ""}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div
                    className="tab-pane fade"
                    id="event-tab"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <div className="d-flex justify-content-center">
                      {loading1 ? <Spinner /> : null}
                    </div>

                    {!loading1 && (
                      <>
                        <div className="d-flex justify-content-between">
                          <h4 className="text-left mb-3">Events</h4>
                        </div>
                        {eventList && eventList.length === 0 ? (
                          <>
                            <div className="container d-flex justify-content-center align-items-center">
                              <span className="noti-type">
                                Events is not available
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* desktop section */}

                            <div className="mt-30 table-responsive d-none d-md-block">
                              <table className="table event-table register-table">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Event Name</th>
                                    <th>Event Date</th>
                                    <th>Registration Status</th>
                                    <th>Event Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {eventList &&
                                    eventList?.map((v, i) => {
                                      return <tr key={v._id} className="team-row">
                                        <td>{i + 1}</td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <div className="dashboard-img">
                                              <img
                                                src={v?.event?.event_image !== '' ? v?.event?.event_image : './images/pch-images/PCH_Logo.png'}
                                              />
                                            </div>
                                            <Link
                                              className="ml-12 text-decoration-none heading register-table filter-string"
                                              // onClick={() => { eventData(_id, event_facility)  }}
                                              to={`/events/${v?.event?._id}`}
                                            >
                                              {v?.event?.event_name}
                                            </Link>
                                          </div>

                                        </td>
                                        <td>{moment(v?.event?.event_start_date).format('MM-DD-yyyy')}</td>
                                        <td>{getEventStatus(v?.event?.registration_start_date, v?.event?.registration_end_date)}</td>
                                        <td>{getEventStatus(v?.event?.event_start_date, v?.event?.event_end_date)}</td>
                                      </tr>

                                    })}
                                </tbody>
                              </table>
                            </div>

                            {/* mobile section */}

                            <div className="mt-30 d-md-none">
                              <div className="mt-4">
                                {!loading1 && eventList &&
                                  eventList.map((v, i) => {
                                    return (
                                      <div className="d-flex justify-content-between reg-details" key={i}>
                                        <div className="d-flex">
                                          <div className="dashboard-img mr-3">
                                            <img src={v?.event?.event_image !== '' ? v?.event?.event_image : './images/pch-images/PCH_Logo.png'} />
                                          </div>
                                          <div className="reg-content">
                                            <Link className="text-decoration-none text-black fw-m" to={`/events/${v?.event?._id}`}>{v?.event?.event_name}</Link>
                                            {/* {v.is_bookmarked === true ? <span className='ml-2'><i className="fas fa-flag m-1" style={{ "color": "#DB1F26" }}></i></span> : <></>} */}
                                            <span className="d-block text-gray mt-2">Event Date:<span className='py-1 ml-2'>{moment(v?.event?.event_start_date).format('MM-DD-yyyy')}</span></span>
                                            <span className="d-block text-gray mt-2">Registration Status:<span className='py-1 ml-2'>{getEventStatus(v?.event?.registration_start_date, v?.event?.registration_end_date)}</span></span>
                                            <span className="d-block text-gray mt-2">Event Status:<span className='py-1 ml-2'>{getEventStatus(v?.event?.event_start_date, v?.event?.event_end_date)}</span></span>
                                          </div>
                                        </div>
                                        {/* <div className="dropdown event-drp">
                                          <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <Link className="dropdown-item" to="/events" onClick={() => props.bookmarkHandler({ bookmarkType: "EVENT", id: v._id })}>
                                              <i className="mdi mdi-bookmark-outline"></i>
                                              {v.is_bookmarked === true ? "Remove bookmark" : "Add Bookmark"}</Link>
                                            <Link className="dropdown-item" to={v.type === "club" ? `registerteam/${v._id}` : `registernow/${v._id}`}>
                                              <i className="mdi mdi-account-multiple"></i> Register Teams
                                            </Link>
                                          </div>
                                        </div> */}
                                      </div>)
                                  })
                                }
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <DeletePopUp
        isOpen={isRosterDelete}
        isClose={setIsRosterDelete}
        handleEvent={() => props.removeRoster({ id: removeRosterId })}
      />
      <DeletePopUp
        isOpen={isPlayerDelete}
        isClose={setIsPlayerDelete}
        handleEvent={() => props.removeTeamPlayer({ team: id, player: removePlayerId })}
      />
      <PlayerImagePopup
        isOpen={openImageModal}
        isClose={setOpenImageModal}
        selectedImage={selectedProfileImage}
        resetImage={setSelectedProfileImage}
      />
      <Toaster />
    </React.Fragment>
  );
};

// export default TeamDetails;

const mapStateToProps = (state) => ({
  // submit: state.deleteEventReducer,
  // submit_1: state.clonEventReducer,
  // bookmark: state.bookmarkReducer
  teams: state.getClubAllTeamReducer,
  team_details: state.getTeamDetailsReducer,
  events: state.getTeamEventsReducer,
  roster: state.createRosterReducer,
  eventDetails: state.getTeamEventDetails,
  removeRosterReducer: state.removeRosterReducer,
  removeTeamPlayerReducer: state.removeTeamPlayerReducer,
  addTeamPlayerReducer: state.addTeamPlayerReducer
});

const mapDispatchToProps = (dispatch) => ({
  getTeamDetails: (user) => dispatch(getTeamDetails(user)),
  getTeamEventsDetails: (user) => dispatch(getTeamEventsDetails(user)),
  removeRoster: (user) => dispatch(removeRoster(user)),
  removeTeamPlayer: (user) => dispatch(removeTeamPlayer(user)),
  resetTeamDetails: () => dispatch({ type: RESET_TEAM_DETAIL_ERROR }),
  resetRemoveRoster: () => dispatch({ type: RESET_REMOVE_ROSTER }),
  resetEventList: () => dispatch({ type: RESET_GET_CLUB_TEAM_EVENT }),
  resetCreate: () => dispatch({ type: RESET_CREATE_ROSTER_ERROR }),
  resetRemovePlayer: () => dispatch({ type: RESET_REMOVE_TEAM_PLAYER }),
  resetcreateTeamReducer: () => dispatch({ type: RESET_ADD_TEAM_PLAYER }),

});

export default connect(mapStateToProps, mapDispatchToProps)(TeamDetails);
