import { BOOKMARK_EVENT_ERROR, BOOKMARK_EVENT_LOADING, BOOKMARK_EVENT_SUCCESS, RESET_BOOKMARK } from "../../constants";

let initialState={
    success:false,
    error:false,
    loading:false,
    error:[]
}


export default function bookmarkReducer(state=initialState,action){
    switch(action.type){
        case BOOKMARK_EVENT_LOADING:
            return {...state,success:false,loading:true,error:false}
        case BOOKMARK_EVENT_SUCCESS:
            return {...state,success:true,loading:false,error:false,message:action.msg}
        case BOOKMARK_EVENT_ERROR:
            return {...state,success:false,loading:false,error:false,errors:action.errors}
        case RESET_BOOKMARK:
            return {...state,success:false,loading:false,error:false}
        default :
        return state
    }
}