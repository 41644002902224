import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import { connect, useSelector } from "react-redux";
import {Select, MenuItem} from '@mui/material';
import {
  editAccountDetails,
  getAllCity,
  getAllSport,
  getAllState,
} from "../../../service/action/userAction";
import {
  RESET_ACCOUNT_DETAILS,
  RESET_UPDATE_PROFILE_IMAGE,
} from "../../../service/constants";
import { withRouter } from "react-router-dom";
import Spinner from "../../../Spinner/Spinner";
import InputMask from "react-input-mask";
import { ThemeProvider } from "react-bootstrap";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import materialTheme from "../../../materialUi";
import { updateProfileImage } from "../../../service/action/Club/clubAction";
import moment from "moment";

const AccountDetails = (props) => {
  const validateString = new RegExp(/^[0-9\b]+$/);
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const notify = (msg) => toast(msg);

  const getAllState = props.getAllStateHandler;
  const getAllCity = props.getAllCityHandler;
  const getAllSport = props.getAllSportHandler;

  const accountDetails = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );


  const allstate = useSelector((state) => state.getStateReducer.states);
  const allcity = useSelector((state) => state.getCityReducer.citys);
  const allsport = useSelector((state) => state.allSportReducer.SPORTs);

  

  const [loading, setLoading] = useState(true);

  // states
  // const [addSport, setAddSport] = useState([]);
  // const [addContact, setAddContact] = useState([]);
  const [isToggle, setIsToggle] = useState(false);
  const [state, setState] = useState({
    profile_image: "",
    first_name: "",
    last_name: "",
    name: "",
    address: "",
    dob: null,
    state: "",
    city: "",
    zip: "",
    email: "",
    mobile_number: "",
    website: "",
    sport: [{ sport: "" }],
    director_name: "",
    director_email: "",
    director_mobile_number: "",
    primary_contact: [],
  });
  const [image, setImage] = useState("");

  const [errors, setErrors] = useState({
    first_name: false,
    last_name: false,
    name: false,
    address: false,
    dob: false,
    state: false,
    city: false,
    zip: false,
    email: false,
    mobile_number: false,
    sport: [],
    director_name: false,
    director_email: false,
    director_mobile_number: false,
    primary_contact: [],
  });

  // State : save concate person input values

  useEffect(() => {
    const getDetails = async () => {
      await getAllState();
      await getAllSport();
    };
    getDetails();
  }, []);

  useEffect(() => {
    if (accountDetails?.status === "" || accountDetails?.status === 0) {
      setIsToggle(false);
    } else if (accountDetails?.status === 1) {
      setIsToggle(true);
    }
  }, [accountDetails, accountDetails?.status]);

  useEffect(() => {
    const id = allstate.find((v) => v.name === state.state)?._id;
    if (id !== undefined) {
      getAllCity({ id: id });
    } 
  }, [accountDetails, allstate, state.state]);

  useEffect(() => {
    if (accountDetails && accountDetails.sport !== undefined && allcity) {
      setLoading(false);
      setState((prevState) => ({
        ...prevState,
        profile_image: accountDetails.profile_image,
        first_name: accountDetails.first_name,
        last_name: accountDetails.last_name,
        name: accountDetails.name,
        address: accountDetails.address,
        dob: accountDetails.dob == "" ? null : moment(accountDetails.dob),
        state: accountDetails.state.name,
        city: allcity.find((e) => e._id === accountDetails.city)?.name,
        zip: accountDetails.zip,
        email: accountDetails.email,
        mobile_number: accountDetails.mobile_number,
        website: accountDetails.website,
        sport: accountDetails.sport,
        director_name: accountDetails.director_name,
        director_email: accountDetails.director_email,
        director_mobile_number: accountDetails.director_mobile_number,
        primary_contact: accountDetails.primary_contact,
      }));
      setImage(accountDetails.profile_image);
      setErrors((pre) => ({
        ...pre,
        primary_contact: accountDetails.primary_contact.map((e) => {
          return {
            first_name: false,
            last_name: false,
            primary_email: false,
          };
        }),
      }));
    }
  }, [accountDetails, allcity]);
  useEffect(() => {
    if (props.editAccount?.success) {
      if (image !== accountDetails.profile_image) {
        props.updateProfileImage({
          image: image,
          user_id: accountDetails._id,
        });
        props.resetAccountDetails();
      } else {
        notify("Profile Updates Successfully");
      }
    } else if (props.editAccount?.error) {
      notify("Data Submit Error.");
    }
  }, [props.editAccount]);

  useEffect(() => {
    if (props.updateProfileImageReducer.success) {
      notify("Profile Updates Successfully");
      props.resetProfileImage();
    } else if (props.updateProfileImageReducer.error) {
      notify("Image Updated error");
    }
  }, [props.updateProfileImageReducer]);

  // useEffect(() => {
  //   if (accountDetails?.sport) {
  //     setAddSport(
  //       accountDetails.sport.map((sport) => {
  //         return { sport: sport.sport };
  //       })
  //     );
  //   }
  //   if (accountDetails?.primary_contact) {
  //     setAddContact(
  //       accountDetails.primary_contact.map((v) => {
  //         return {
  //           first_name: v.first_name,
  //           last_name: v.last_name,
  //           middle_name: v.middle_name,
  //           primary_email: v.primary_email,
  //         };
  //       })
  //     );
  //   }
  // }, [accountDetails]);
  const dobHandler = (id, value) => {
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    if (value?.length === 0) {
      setErrors((prevState) => ({
        ...prevState,
        dob: "Date of Birth can not be empty",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        dob: false,
      }));
    }
  };
  const eventhandler = (e) => {
    const { id, value, name } = e.target;

    setState((prevState) => ({
      ...prevState,
      [id]: value
    }));

    switch (name) {
      case "first_name":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            first_name: "First name can not be emptys",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            first_name: false,
          }));
        }
        break;

      case "last_name":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            last_name: "Last name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            last_name: false,
          }));
        }
        break;

      case "name":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            name: "Club name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            name: false,
          }));
        }
        break;

      case "address":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            address: "Address can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            address: false,
          }));
        }
        break;

      case "dob":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            dob: "Date of birth can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            dob: false,
          }));
        }
        break;

      case "state":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            state: "Please select state",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            state: false,
          }));
        }
        break;

      case "city":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            city: "Please select city",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            city: false,
          }));
        }
        break;

      case "zip":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            zip: "Zip can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            zip: false,
          }));
        }
        break;

      case "email":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Email can not be empty",
          }));
        } else if (!validEmailRegex.test(value)) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Email is not valid !",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "mobile_number":
        const _value = value.replaceAll("-", "");
        if (_value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            mobile_number: "Mobile number can not be empty",
          }));
        } else if (!validateString.test(_value)) {
          setErrors((prevState) => ({
            ...prevState,
            mobile_number: "Mobile number can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            mobile_number: false,
          }));
        }
        break;

      case "director_name":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            director_name: "Director name can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            director_name: false,
          }));
        }
        break;

      case "director_email":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            director_email: "Email can not be empty",
          }));
        } else if (!validEmailRegex.test(value)) {
          setErrors((prevState) => ({
            ...prevState,
            director_email: "Email is not valid !",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            director_email: false,
          }));
        }
        break;

      case "director_mobile_number":
        const _director_mobile_number = value.replaceAll("-", "");
        if (_director_mobile_number.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            director_mobile_number: "Mobile number can not be empty",
          }));
        } else if (!validateString.test(_director_mobile_number)) {
          setState((prevState) => ({
            ...prevState,
            director_mobile_number: "Mobile number can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            director_mobile_number: false,
          }));
        }
        break;
      default:
    }
  };

  const handleSportChange = (e, index) => {
    const { name, value } = e.target;

    const _sport = [...state.sport];
    _sport[index]["sport"] = value;
    // setAddSport(_sport);
    setState((prevState) => ({
      ...prevState,
      sport: [..._sport],
    }));

    switch (name) {
      case "sport":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            sport: [
              ...prevState.sport,
              (prevState.sport[index] = {
                ...prevState.sport[index],
                sport: "Sport can not be empty",
              }),
            ],
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            sport: [
              ...prevState.sport,
              (prevState.sport[index] = {
                ...prevState.sport[index],
                sport: false,
              }),
            ],
          }));
        }
      default:
    }
  };

  const handleAddSport = () => {
    // setAddSport((prevState) => [
    //   ...prevState,
    //   {
    //     sport: "",
    //   },
    // ]);
    setState((prevState) => ({
      ...prevState,
      sport: [
        ...state.sport,
        {
          sport: "",
        },
      ],
    }));
  };

  const handleRemoveSport = (index) => {
    const list = [...state.sport];
    list.splice(index, 1);
    // setAddSport(list);

    setState((prevState) => ({
      ...prevState,
      sport: [...list],
    }));
  };
  const handleContactPerson = (e, index) => {
    const { name, value } = e.target;
    const contact = [...state.primary_contact];
    contact[index][name] = value;
    // setAddContact(contact);

    setState((prevState) => ({
      ...prevState,
      primary_contact: [...contact],
    }));

    switch (name) {
      case "first_name":
        if (value.length === 0) {
          const errorContact = errors.primary_contact;
          const currentContact = errorContact[index];
          currentContact["first_name"] = "* Please enter first name";
          errorContact.splice(index, 1, currentContact);
          setErrors((pre) => ({
            ...pre,
            primary_contact: [...errorContact],
          }));
        } else {
          const errorContact = errors.primary_contact;
          const currentContact = errorContact[index];
          currentContact["first_name"] = false;
          errorContact.splice(index, 1, currentContact);
          setErrors((pre) => ({
            ...pre,
            primary_contact: [...errorContact],
          }));
        }
        break;
      // case "middle_name":
      //   if (value.length == 0) {
      //     const errorContact = errors.primary_contact;
      //     const currentContact = errorContact[index];
      //     currentContact["middle_name"] = false;
      //     errorContact.splice(index, 1, currentContact);
      //     setErrors((pre) => ({
      //       ...pre,
      //       primary_contact: [...errorContact],
      //     }));
      //   } else {
      //     const errorContact = errors.primary_contact;
      //     const currentContact = errorContact[index];
      //     currentContact["middle_name"] = false;
      //     errorContact.splice(index, 1, currentContact);
      //     setErrors((pre) => ({
      //       ...pre,
      //       primary_contact: [...errorContact],
      //     }));
      //   }
      //   break;
      case "last_name":
        if (value.length === 0) {
          const errorContact = errors.primary_contact;
          const currentContact = errorContact[index];
          currentContact["last_name"] = "* Please enter last name";
          errorContact.splice(index, 1, currentContact);
          setErrors((pre) => ({
            ...pre,
            primary_contact: [...errorContact],
          }));
        } else {
          const errorContact = errors.primary_contact;
          const currentContact = errorContact[index];
          currentContact["last_name"] = false;
          errorContact.splice(index, 1, currentContact);
          setErrors((pre) => ({
            ...pre,
            primary_contact: [...errorContact],
          }));
        }
        break;
      case "primary_email":
        if (value.length === 0) {
          const errorContact = errors.primary_contact;
          const currentContact = errorContact[index];
          currentContact["primary_email"] = "Please enter email";
          errorContact.splice(index, 1, currentContact);
          setErrors((pre) => ({
            ...pre,
            primary_contact: [...errorContact],
          }));
        } else if (!validEmailRegex.test(value)) {
          const errorContact = errors.primary_contact;
          const currentContact = errorContact[index];
          currentContact["primary_email"] = "Email is not valid";
          errorContact.splice(index, 1, currentContact);
          setErrors((pre) => ({
            ...pre,
            primary_contact: [...errorContact],
          }));
        } else {
          const errorContact = errors.primary_contact;
          const currentContact = errorContact[index];
          currentContact["primary_email"] = false;
          errorContact.splice(index, 1, currentContact);
          setErrors((pre) => ({
            ...pre,
            primary_contact: [...errorContact],
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleAddContact = () => {
    setState((pre) => ({
      ...pre,
      primary_contact: [
        ...pre.primary_contact,
        {
          first_name: "",
          last_name: "",
          // middle_name: "",
          primary_email: "",
        },
      ],
    }));
    // setAddContact((prevState) => [
    //   ...prevState,
    //   {
    //     first_name: "",
    //     last_name: "",
    //     middle_name: "",
    //     primary_email: "",
    //   },
    // ]);

    setErrors((pre) => ({
      ...pre,
      primary_contact: [
        ...pre.primary_contact,
        {
          first_name: false,
          last_name: false,
          // middle_name: false,
          primary_email: false,
        },
      ],
    }));
  };

  const handleRemoveContact = (index) => {
    const _primaryContact = [...state.primary_contact];
    const filterConatct = _primaryContact.filter((e, i) => {
      return index != i;
    });
    setState((pre) => ({
      ...pre,
      primary_contact: [...filterConatct],
    }));
    const _primaryErrorContact = [...errors.primary_contact];
    const filterErrorConatct = _primaryErrorContact.filter((e, i) => {
      return index != i;
    });
    setErrors((pre) => ({
      ...pre,
      primary_contact: [...filterErrorConatct],
    }));
  };

  const savedata = (e) => {
    e.preventDefault();
    if (state.first_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        first_name: "First name can not be empty",
      }));
    }
    if (state.last_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        last_name: "Last name can not be empty",
      }));
    }
    if (state.name === "") {
      setErrors((prevState) => ({
        ...prevState,
        name: "Club name can not be empty",
      }));
    }
    if (state.mobile_number === "") {
      setErrors((prevState) => ({
        ...prevState,
        mobile_number: "Mobile number can not be empty",
      }));
    }
    if (state.address === "") {
      setErrors((prevState) => ({
        ...prevState,
        address: "Address can not be empty",
      }));
    }
    if (state.dob === "") {
      setErrors((prevState) => ({
        ...prevState,
        dob: "Date of birth can not be empty",
      }));
    }
    if (state.state === "" || state.state === undefined) {
      setErrors((prevState) => ({
        ...prevState,
        state: "Please select state",
      }));
    }
    if (state.city === "" || state.city === undefined) {
      setErrors((prevState) => ({
        ...prevState,
        city: "Please select city",
      }));
    }
    if (state.zip === "" || state.zip === undefined) {
      setErrors((prevState) => ({
        ...prevState,
        zip: "Zip can not be empty",
      }));
    } else if (state.zip.length < 5) {
      setErrors((prevState) => ({
        ...prevState,
        zip: "Zip can not be less then 5 charactor",
      }));
    }
    if (state.email === "") {
      setErrors((prevState) => ({
        ...prevState,
        email: "Email can not be empty",
      }));
    }

    if (state.director_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        director_name: "Director name can not be empty",
      }));
    }
    if (state.director_email === "") {
      setErrors((prevState) => ({
        ...prevState,
        director_email: "Director email can not be empty",
      }));
    }
    if (state.director_mobile_number === "") {
      setErrors((prevState) => ({
        ...prevState,
        director_mobile_number: "Director mobile number can not be empty",
      }));
    }

    const isEmptyPrimaryContact = state.primary_contact.map((c) => {
      return Object.values(c).some((v) => v === "");
    });

    if (isEmptyPrimaryContact.length > 0) {
      const emptyContact = state.primary_contact.map((c) => {
        if (Object.values(c).some((v) => v === "")) {
          return c;
        } else return false;
      });
      const list = [];
      emptyContact.map((v, i) => {
        if (v) {
          list.push({
            first_name:
              v["first_name"] === "" ? "Please enter first name" : false,
            last_name: v["last_name"] === "" ? "Please enter last name" : false,
            primary_email:
              v["primary_email"] === "" ? "Email can not be empty" : false,
          });
        } else list.push(false);
      });
      setErrors((prevState) => ({
        ...prevState,
        primary_contact: [...list],
      }));
    }

    const isSportValid = state.sport.every((v) => v.sport !== "");
    const isPrimaryContactValid = errors.primary_contact
      .map((contacts) => {
        const isValid = Object.values(contacts).every((e) => !e);
        return isValid;
      })
      .every((e) => !!e);

      
    
    if (
      state.first_name !== "" &&
      state.last_name !== "" &&
      state.name !== "" &&
      state.mobile_number !== "" &&
      state.address !== "" &&
      state.dob !== "" &&
      state.state !== "" &&
      state.city !== "" &&
      state.zip !== "" &&
      state.email !== "" &&
      state.director_email !== "" &&
      state.director_mobile_number !== "" &&
      isSportValid &&
      isPrimaryContactValid
    ) {
      localStorage.setItem("username", state.name);

      const stateId = allstate.find((e) => e.name === state.state);
      const cityId = allcity.find((e) => e.name === state.city);

      const sportList = state.sport.map((sport) => {
        const currentSport = allsport.find((e) => e.sport === sport.sport);
        return currentSport._id;
      });



        const updatedUser = {
          ...state,
          state: stateId,
          cityName: cityId,
          sport: sportList,
          profile_image: image,
          status: isToggle ? 1 : 0,
          director_name: state.first_name + " " + state.last_name
        }
      
        props.editOrgDetailsHandler(updatedUser)
        

    }

  };
  
  const onChangePicture = (event) => {
    setState((prevState) => ({
      ...prevState,
      profile_image: URL.createObjectURL(event.target.files[0]),
    }));
    setImage(event.target.files[0]);
  };



  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>
          {!loading && (
            <>
              <div className="text-left">
                <div className="d-flex justify-content-end">
                  <label className="switch mb-3">
                    <input
                      type="checkbox"
                      value={isToggle}
                      checked={isToggle}
                      onChange={(e) => setIsToggle(e.target.checked)}
                    />
                    <span className="slider order-0 ml-0 mr-2"></span>
                    <p className="on mb-0 order-1 ml-1">
                      {isToggle ? "Public" : "Private"}
                    </p>
                  </label>
                </div>
                <div className="create-team-head-space">
                  <h5 className="dash-head">Club Info</h5>
                  <div className="mt-2 mt-md-0 text-right">
                    <button className="btn-save" onClick={savedata}>
                      Save Changes
                    </button>
                  </div>
                </div>

                <div className="text-left mt-30">
                  <div className="basic-info">
                    <input
                      type="file"
                      className="input-file file-upload"
                      id="file-upload"
                      onChange={onChangePicture}
                    />
                    <label className="change-pic" htmlFor="file-upload">
                      <img
                        src={
                          state.profile_image === ""
                            ? "./images/faces/face3.jpg"
                            : state.profile_image
                        }
                        className="img-fluid"
                      />
                    </label>
                    <span className="ml-12">Change Picture</span>
                  </div>
                </div>
              </div>

              <div className=" text-left row col-md-6 px-0 mx-0">
                {/*      first name and last name */}
                {/* <div className="col-md-12 mt-18">
                  <div className="form-row">
                    <div className="col-md-6 col-6 mt-18">
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          error: errors.first_name,
                        })}
                        placeholder="First Name *"
                        id="first_name"
                        value={state.first_name}
                        name="first_name"
                        onChange={eventhandler}
                        maxLength="5"
                      />
                      {errors.first_name && (
                        <span className="text-red small d-block">
                          * {errors.first_name}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 mt-18">
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          error: errors.last_name,
                        })}
                        placeholder="Last Name *"
                        id="last_name"
                        value={state.last_name}
                        name="last_name"
                        onChange={eventhandler}
                      />
                      {errors.last_name && (
                        <span className="text-red small d-block">
                          * {errors.last_name}
                        </span>
                      )}
                    </div>
                  </div>
                </div> */}

                {/*          club name */}
                <div className="col-md-12 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      error: errors.name,
                    })}
                    placeholder="Club Name *"
                    id="name"
                    name="name"
                    value={state.name}
                    onChange={eventhandler}
                  />
                  {errors.name && (
                    <span className="text-red small d-block">
                      * {errors.name}
                    </span>
                  )}
                </div>

                {/*    address */}
                <div className="col-md-12 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      error: errors.address,
                    })}
                    placeholder="Address *"
                    value={state.address}
                    id="address"
                    name="address"
                    onChange={eventhandler}
                  />
                  {errors.address && (
                    <span className="text-red small d-block">
                      * {errors.address}
                    </span>
                  )}
                </div>

                {/* dob */}
                {/* <div className="col-md-12 mt-18">
                  <div className="desk-datepicker">
                    <ThemeProvider theme={materialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          placeholder="DOB(MM-DD-YY)"
                          format="MM/dd/yyyy"
                          id={"dob"}
                          mask="__/__/____"
                          inputVariant="outlined"
                          InputAdornmentProps={{ position: "end" }}
                          className={classNames("form-control team-name", {
                            error: errors.dob,
                          })}
                          invalidDateMessage="Invalid Date Format"
                          onChange={(data) => {
                            dobHandler("dob", data);
                          }}
                          value={state.dob}
                        />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                    {errors.dob && (
                      <span className="text-red small d-block">
                        * {errors.dob}
                      </span>
                    )}
                  </div>
                </div> */}

                {/*    state and city , zip and email */}
                <div className="col-md-12 mt-18">
                  <div className="form-row">
                    <div className="col-md-6 col-6 ">
                      <select
                        className={classNames("form-control team-name", {
                          error: errors.state,
                        })}
                        placeholder="State *"
                        id="state"
                        name="state"
                        value={state.state}
                        onChange={eventhandler}
                      >
                        <option value="">State *</option>
                        {allstate.map((v, i) => {
                          return (
                            <option key={v._id} value={v.name}>
                              {v.name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.state && (
                        <span className="text-red small d-block">
                          * {errors.state}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                  <input
                    type="text"
                    list="cityNameList"
                    className={classNames("form-control team-name", {
                      error: errors?.city,
                    })}
                    placeholder="City *"
                    name="city"
                    id="city"
                    value={state.city}
                    onChange={eventhandler}
                    autoComplete="off"
                  />
                   {errors?.city && (
                    <span className="text-red small d-block">
                      Enter city
                    </span>
                  )}

                <datalist id="cityNameList">
                        {allcity.map((e, i) => {
                          return <option value={e.name} key={i} />;
                        })}
                      </datalist>
                </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-6 col-6 mt-18">
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          error: errors.zip,
                        })}
                        placeholder="Zip Code*"
                        id="zip"
                        value={state.zip}
                        name="zip"
                        onChange={eventhandler}
                        maxLength="5"
                      />
                      {errors.zip && (
                        <span className="text-red small d-block">
                          * {errors.zip}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 mt-18">
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          error: errors.email,
                        })}
                        placeholder="Email *"
                        id="email"
                        value={state.email}
                        name="email"
                        onChange={eventhandler}
                      />
                      {errors.email && (
                        <span className="text-red small d-block">
                          * {errors.email}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* Main Mobile No. */}
                <div className="col-md-12 mt-18">
                  <InputMask
                    mask="999-999-9999"
                    className={classNames("form-control team-name", {
                      error: errors.mobile_number,
                    })}
                    value={state.mobile_number}
                    onChange={eventhandler}
                    maskChar={""}
                    placeholder="Main Mobile No.*"
                    name="mobile_number"
                    id="mobile_number"
                  />
                  {errors.mobile_number && (
                    <span className="text-red small d-block">
                      * {errors.mobile_number}
                    </span>
                  )}
                </div>

                {/*   website */}
                <div className="col-md-12 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name")}
                    placeholder="Website"
                    id="website"
                    value={state.website}
                    name="website"
                    onChange={eventhandler}
                  />
                </div>

                {/* Add Sport Dynamically */}

                <div className="mt-18 col-md-12">
                  <div className="form-group event-drp">
                    {state.sport ? (
                      <>
                        {state.sport.map((sport, i) => {
                          return (
                            <div key={i} className="mb-2">
                              <div className="form-group event-drp">
                                <select
                                  className={classNames(
                                    "form-control team-name payterm",
                                    { error: errors.sport[i]?.sport }
                                  )}
                                  id="sport"
                                  name="sport"
                                  value={sport.sport}
                                  onChange={(e) => handleSportChange(e, i)}
                                >
                                  <option value="">Select Sport *</option>
                                  {allsport ? (
                                    <>
                                      {allsport.map((v, i) => {
                                        return (
                                          <option key={v._id} value={v.sport} onChange={()=> {
                                          }}>
                                            {v.sport}
                                          </option>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <>No data</>
                                  )}
                                </select>
                              </div>
                              <div className="d-flex justify-content-between">
                                {errors.sport[i]?.sport ? (
                                  <span className="text-red small d-block">
                                    * {errors.sport[i]?.sport}
                                  </span>
                                ) : (
                                  <div style={{ width: "50%" }}></div>
                                )}
                                {state.sport.length > 1 && (
                                  <div className="text-right add-more">
                                    <div
                                      className="text-decoration-none fw-m text-red pointer fs-14"
                                      onClick={() => handleRemoveSport(i)}
                                    >
                                      - Remove
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}

                        <div
                          className="text-left add-more"
                          style={{ marginTop: "14px" }}
                        >
                          <div
                            className="text-decoration-none fw-m text-info pointer"
                            onClick={handleAddSport}
                          >
                            + Add More
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              {/* Director */}
              <div className="d-flex justify-content-between mt-30">
                <h4 className="text-left">Director Details</h4>
              </div>

              {/* Director Details */}
              <div className="row col-md-6 px-0 mx-0">
                {/* First and last name */}

                <div className="col-md-12 mt-18">
                  <div className="form-row">
                    <div className="col-md-6 col-6 mt-18">
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          error: errors.first_name,
                        })}
                        placeholder="First Name *"
                        id="first_name"
                        value={state.first_name}
                        name="first_name"
                        onChange={eventhandler}
                        maxLength="5"
                      />
                      {errors.first_name && (
                        <span className="text-red small d-block">
                          * {errors.first_name}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 mt-18">
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          error: errors.last_name,
                        })}
                        placeholder="Last Name *"
                        id="last_name"
                        value={state.last_name}
                        name="last_name"
                        onChange={eventhandler}
                      />
                      {errors.last_name && (
                        <span className="text-red small d-block">
                          * {errors.last_name}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-18">
                  <input
                    type="email"
                    className={classNames("form-control team-name", {
                      error: errors.director_email,
                    })}
                    placeholder="Email Address *"
                    id="director_email"
                    name="director_email"
                    value={state.director_email}
                    onChange={eventhandler}
                  />
                  {errors.director_email && (
                    <span className="text-red small d-block">
                      * {errors.director_email}
                    </span>
                  )}
                </div>

                {/* dob */}
                <div className="col-md-12 mt-18">
                  <div className="desk-datepicker">
                    <ThemeProvider theme={materialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          placeholder="DOB(MM-DD-YY)"
                          format="MM/dd/yyyy"
                          id={"dob"}
                          mask="__/__/____"
                          inputVariant="outlined"
                          InputAdornmentProps={{ position: "end" }}
                          className={classNames("form-control team-name", {
                            error: errors.dob,
                          })}
                          invalidDateMessage="Invalid Date Format"
                          onChange={(data) => {
                            dobHandler("dob", data);
                          }}
                          value={state.dob}
                        />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                    {errors.dob && (
                      <span className="text-red small d-block">
                        * {errors.dob}
                      </span>
                    )}
                  </div>
                </div>

                {/*Director Mobile Number */}

                <div className="col-md-12 mt-18">
                  <InputMask
                    mask="999-999-9999"
                    className={classNames("form-control team-name", {
                      error: errors.director_mobile_number,
                    })}
                    value={state.director_mobile_number}
                    onChange={eventhandler}
                    maskChar={""}
                    placeholder="Mobile No.*"
                    name="director_mobile_number"
                    id="director_mobile_number"
                  />
                  {errors.director_mobile_number && (
                    <span className="text-red small d-block">
                      * {errors.director_mobile_number}
                    </span>
                  )}
                </div>
              </div>            
            </>
          )}
        </div>
      </div>
      <Toaster />
    </React.Fragment>
  );
};
// export default AccountDetails;
const mapStateToProp = (state) => ({
  editAccount: state.editAccountDetailsReducer,
  updateProfileImageReducer: state.updateProfileImageReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAllStateHandler: (user) => dispatch(getAllState()),
  getAllCityHandler: (user) => dispatch(getAllCity(user)),
  getAllSportHandler: (user) => dispatch(getAllSport(user)),
  editOrgDetailsHandler: (user) => dispatch(editAccountDetails(user)),
  updateProfileImage: (user) => dispatch(updateProfileImage(user)),
  resetAccountDetails: () => dispatch({ type: RESET_ACCOUNT_DETAILS }),
  resetProfileImage: () => dispatch({ type: RESET_UPDATE_PROFILE_IMAGE }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(AccountDetails));
