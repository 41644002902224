import { GET_EVENTSTAFF_LOADING, GET_EVENTSTAFF_SUCCESS, GET_EVENTSTAFF_ERROR } from "../../constants"


let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {}

};

export default function getEventStaffReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EVENTSTAFF_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };
      
      case GET_EVENTSTAFF_SUCCESS:
        // alert(" reducer Error")
        return { ...state, loading: false, success: true, user: action.payload };
        
        case GET_EVENTSTAFF_ERROR:
          // alert(" reducer Loading")
          return { ...state, error: true, errors: action.errors };
          
          default:
            return state;
          }
        }
