import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import classes from "./AccountUsers.module.css";
import BreadcrumbHistory from "../../BreadcumbHistory/BreadcrumbHistory";
import { getAdminGroupPermission } from "../../service/action/Club/clubAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
const AccountUsers = (props) => {
  const { id } = useParams();
  const location = useLocation();

  const [userGroupData, setUserGroupData] = useState({});

  const { group_name } = userGroupData;

  // Fecthing Group Data

  useEffect(() => {
    props.getAdminGroupPermission();
  }, []);

  useEffect(() => {
    if (props.GetPermiossionAdminGroupReducer.success) {
      const data = props.GetPermiossionAdminGroupReducer.data;
      const groupData = data.data.find((group) => group._id === id);
      setUserGroupData(groupData);
    }
  }, [props.GetPermiossionAdminGroupReducer]);

  return (
    <div className="main-panel container">
      <BreadcrumbHistory
        url={location.pathname !== "/" ? location.pathname : ""}
      />
      <div>
        <div className="border pointer p-2 my-2 d-flex justify-content-between align-items-center admin_group">
          <div className=" text-danger">{group_name}</div>

          <div className="dropdown event-drp">
            <i
              className="mdi mdi-dots-vertical"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></i>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <span className="dropdown-item">
              <i className="fa-solid fa-gear"></i>
                {' '}Manage Permission
              </span>
              <span className="dropdown-item">
              <i className="fa-solid fa-plus"></i> Add Users
              </span>
              <span className="dropdown-item">
                <i className="fas fa-trash-alt"></i> Delete
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProp = (state) => ({
  GetPermiossionAdminGroupReducer: state.GetPermiossionAdminGroupReducer,
  // CreateAdminGroupReducer: state.CreateAdminGroup,
});

const mapDispatchToProps = (dispatch) => ({
  // getAdminGroupPermission: user => dispatch(getAdminGroupPermission()),
  getAdminGroupPermission: (user) => dispatch(getAdminGroupPermission()),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(AccountUsers));
