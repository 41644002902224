import React from 'react'
import BreadcrumbHistory from '../../BreadcumbHistory/BreadcrumbHistory'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { event } from 'jquery'

function SetGroupPermission() {
 const location = useLocation()

 const [state, setState] = useState({
  event: {
   detail: {
    add: 0,
    edit: 0,
    delete: 0
   },
   team: {
    add: 0,
    edit: 0,
    delete: 0
   },
   schedule: {
    add: 0,
    edit: 0,
    delete: 0
   },
  },
  payment: {
   payment_request: {
    add: 0,
    edit: 0,
    delete: 0
   },
   payment_code: {
    add: 0,
    edit: 0,
    delete: 0
   },
   credit: {
    add: 0,
    edit: 0,
    delete: 0
   },
   discount_code: {
    add: 0,
    edit: 0,
    delete: 0
   },
   refund: {
    add: 0,
    edit: 0,
    delete: 0
   },
  },
  profile: {
   account_detail: {
    add: 0,
    edit: 0,
    delete: 0
   },
   seasons: {
    add: 0,
    edit: 0,
    delete: 0
   },
   leagues: {
    add: 0,
    edit: 0,
    delete: 0
   },
   fields: {
    add: 0,
    edit: 0,
    delete: 0
   },
   division: {
    add: 0,
    edit: 0,
    delete: 0
   },
   bank_info: {
    add: 0,
    edit: 0,
    delete: 0
   },
   liability: {
    add: 0,
    edit: 0,
    delete: 0
   },
  },
 })
 const eventMenus = ["detail", "team", "schedule"]
 const paymentMenus = ["payment_request", "payment_code", "credit", "discount_code", "refund"]
 const profileMenus = ["account_detail", "seasons", "leagues", "fields", "division", "bank_info", "liability"]
 return (
  <React.Fragment>
   <div className="main-panel">
    <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />
    <div className="content-wrapper">
     <div className="green-section ">
      <div className="table-responsive d-none d-md-block">
       <table className="table green-table ">
        <thead>
         <tr>
          <th>Event</th>
          <th>Read</th>
          <th>Write</th>
          <th>Delete</th>

          {/* <th>Payment Id</th> */}
         </tr>
        </thead>
       </table>
      </div>
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

export default SetGroupPermission
