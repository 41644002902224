import {
  ORG_ADD_TEAM_TO_DIVISION_ERROR,
  ORG_ADD_TEAM_TO_DIVISION_LOADING,
  ORG_ADD_TEAM_TO_DIVISION_SUCCESS,
  RESET_ORG_ADD_TEAM_TO_DIVISION,
} from "../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
};

export default function addteamtodivisionReducer(state = initialState, action) {
  switch (action.type) {
    case ORG_ADD_TEAM_TO_DIVISION_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case ORG_ADD_TEAM_TO_DIVISION_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        user: action?.user,
        message: action.msg,
      };

    case ORG_ADD_TEAM_TO_DIVISION_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        errors: action.errors,
        msg: action.errors,
      };

    case RESET_ORG_ADD_TEAM_TO_DIVISION:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        errors: [],
        user: [{}],
      };
    default:
      return state;
  }
}
