import React, { useState } from "react";
import { Breadcrumb, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
function Merchant() {

      const [xlShow, setXlShow] = useState(false);

      const handleClose = () => setXlShow(false);
      const handleShow = () => setXlShow(true);

      const [delShow, setDelShow] = useState(false);
      const delDialClose = () => setDelShow(false);
      const delDialShow = () => setDelShow(true);

      const [successShow, setSuccessShow] = useState(false);
      const successDialClose = () => setSuccessShow(false);
      const successDialShow = () => {
            setDelShow(false);
            setSuccessShow(true);
      }

      return (
            <>
                  <div className="d-flex justify-content-between">
                        <h2 className="page-heading">Merchant</h2>
                        <Breadcrumb>
                              <Breadcrumb.Item href="/superadmin/Dashboard">Home</Breadcrumb.Item>
                              <Breadcrumb.Item active className="text-primary">User</Breadcrumb.Item>
                              <Breadcrumb.Item href="/superadmin/user/eventOrganizer">Event Organization</Breadcrumb.Item>
                              <Breadcrumb.Item active>Merchant</Breadcrumb.Item>
                        </Breadcrumb>
                  </div>
                  <Row className="justify-content-between mx-0">
                        <Col md="4" className="search px-0">
                              <i className="ri-search-line"></i>
                              <Form.Control type="text" placeholder="Search" />
                        </Col>
                        <Col md="6" className="text-end px-0">
                              <Button variant="secondary" onClick={handleShow}>
                                    Add Merchant
                              </Button>
                        </Col>
                  </Row>
                  <div className="table-card">
                        <Table responsive hover className="main-table">
                              <thead>
                                    <tr>
                                          <th>#</th>
                                          <th>Username</th>
                                          <th>full name</th>
                                          <th>email</th>
                                          <th>Phone number</th>
                                          <th>Date of birth</th>
                                          <th>social security number</th>
                                          <th>drivers license number</th>
                                          <th>comp. legal name</th>
                                          <th>DBA</th>
                                          <th>ownership type</th>
                                          <th>federal tax id</th>
                                          <th>address</th>
                                          <th>city</th>
                                          <th>state</th>
                                          <th>zipcode</th>
                                          <th>Bank routing NO.</th>
                                          <th>Bank A/c no.</th>
                                          <th>status</th>
                                          <th className="text-center">Action</th>
                                    </tr>
                              </thead>
                              <tbody>
                                    <tr>
                                          <td>1</td>
                                          <td>JohnRichard</td>
                                          <td>John doe</td>
                                          <td>Test@gmail.com</td>
                                          <td>(323) 653-0880</td>
                                          <td>30 August, 1994</td>
                                          <td>AAA-GG-SSSS</td>
                                          <td>S-514-778-616-977</td>
                                          <td>Lorem Ipsum</td>
                                          <td>Lorem Ipsum</td>
                                          <td>Lorem Ipsum</td>
                                          <td>Lorem Ipsum</td>
                                          <td>8490 Beverly Blvd</td>
                                          <td>Los Angeles</td>
                                          <td>California</td>
                                          <td>92571</td>
                                          <td>021000021</td>
                                          <td>091000019</td>
                                          <td>
                                              <Button type="button" className="btn-status  btn-primary active">
                                                Active
                                              </Button>
                                          </td>
                                          <td className="text-center">
                                                <button onClick={handleShow} className="btn-link btn text-secondary p-0 mx-2"><i className="ri-pencil-fill"></i></button>
                                                <button onClick={delDialShow} className="btn-link btn text-danger p-0 mx-2"><i className="ri-delete-bin-fill"></i></button>
                                          </td>
                                    </tr>

                              </tbody>
                        </Table>
                  </div>
                  <Modal size="xl" show={xlShow} onHide={handleClose} scrollable="true">
                        <Modal.Header closeButton>
                              <Modal.Title>Merchant</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                              <Form>
                                    <Row>
                                          <Form.Group as={Col} className="mb-4" controlId="">
                                                <Form.Label>Username</Form.Label>
                                                <Form.Control type="text" placeholder="" />
                                          </Form.Group>

                                          <Form.Group as={Col} className="mb-4" controlId="">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control type="email" placeholder="" />
                                          </Form.Group>

                                    </Row>
                                    <fieldset>
                                          <legend>Company </legend>
                                          <Row>
                                                <Form.Group as={Col} className="mb-4">
                                                      <Form.Label>Legal Name</Form.Label>
                                                      <Form.Control type="text" placeholder="" />
                                                </Form.Group>

                                                <Form.Group as={Col} className="mb-4">
                                                      <Form.Label>DBA Name</Form.Label>
                                                      <Form.Control type="email" placeholder="" />
                                                </Form.Group>
                                          </Row>
                                          <Row>
                                                <Form.Group as={Col} className="mb-4">
                                                      <Form.Label>Ownership Type</Form.Label>
                                                      <Form.Control type="text" placeholder="" />
                                                </Form.Group>

                                                <Form.Group as={Col} className="mb-4">
                                                      <Form.Label>Federal TAX ID</Form.Label>
                                                      <Form.Control type="email" placeholder="" />
                                                </Form.Group>
                                          </Row>
                                    </fieldset>
                                    <fieldset>
                                          <legend>Legal Address </legend>

                                          <Form.Group as={Col} className="mb-4">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control as="textarea" rows={3} />
                                          </Form.Group>
                                          <Row>
                                                <Form.Group as={Col} className="mb-4" controlId="">
                                                      <Form.Label>City</Form.Label>
                                                      <Form.Select >
                                                            <option>select</option>
                                                      </Form.Select>
                                                </Form.Group>

                                                <Form.Group as={Col} className="mb-4" controlId="">
                                                      <Form.Label>State</Form.Label>
                                                      <Form.Select >
                                                            <option>select</option>
                                                      </Form.Select>
                                                </Form.Group>

                                                <Form.Group as={Col} className="mb-4" controlId="">
                                                      <Form.Label>Zipcode</Form.Label>
                                                      <Form.Control type="text" placeholder="" />
                                                </Form.Group>
                                          </Row>
                                    </fieldset>
                                    <fieldset>
                                          <legend>Owner Principal</legend>                                       
                                          <Row>
                                                <Form.Group as={Col} className="mb-4" controlId="">
                                                      <Form.Label>First Name</Form.Label>
                                                      <Form.Control type="text" placeholder="" />
                                                </Form.Group>
                                                <Form.Group as={Col} className="mb-4" controlId="">
                                                      <Form.Label>Last Name</Form.Label>
                                                      <Form.Control type="text" placeholder="" />
                                                </Form.Group>
                                                <Form.Group as={Col} className="mb-4" controlId="">
                                                      <Form.Label>Email</Form.Label>
                                                      <Form.Control type="email" placeholder="" />
                                                </Form.Group>
                                                <Form.Group as={Col} className="mb-4" controlId="">
                                                      <Form.Label>Phone Number</Form.Label>
                                                      <Form.Control type="text" placeholder="" />
                                                </Form.Group>
                                          </Row>
                                          <Row>
                                                <Form.Group as={Col} className="mb-4" controlId="">
                                                      <Form.Label>Date of Birth</Form.Label>
                                                      <Form.Control type="text" placeholder="" />
                                                </Form.Group>
                                                <Form.Group as={Col} className="mb-4" controlId="">
                                                      <Form.Label>Social Security No.</Form.Label>
                                                      <Form.Control type="text" placeholder="" />
                                                </Form.Group>
                                                <Form.Group as={Col} className="mb-4" controlId="">
                                                      <Form.Label>Driver's License No.</Form.Label>
                                                      <Form.Control type="email" placeholder="" />
                                                </Form.Group>
                                          </Row>
                                    </fieldset>

                                    <fieldset>
                                          <legend>Bank</legend>                                       
                                          <Row>
                                                <Form.Group as={Col} className="mb-4" controlId="">
                                                      <Form.Label>Routing No.</Form.Label>
                                                      <Form.Control type="text" placeholder="" />
                                                </Form.Group>
                                                <Form.Group as={Col} className="mb-4" controlId="">
                                                      <Form.Label>Account No.</Form.Label>
                                                      <Form.Control type="text" placeholder="" />
                                                </Form.Group>
                                          </Row>
                                    </fieldset>

                                    <Form.Group className="mb-4">
                                          <Form.Label>Address</Form.Label>
                                          <Form.Control as="textarea" rows={3} />
                                    </Form.Group>

                                    <div className="text-end">
                                          <Button variant="secondary" type="submit" onClick={handleClose}>
                                                submit
                                          </Button>
                                    </div>
                              </Form>
                        </Modal.Body>
                  </Modal>

                  {/* Delete confirmation */}
                  <Modal size="sm" show={delShow} onHide={delDialClose} centered className="del-dialogue">
                        {/* <Modal.Header closeButton>
                              <Modal.Title>delete</Modal.Title>
                        </Modal.Header> */}
                        <Modal.Body>
                              <h6 className="text-center">Are you sure you want to delete
                                    this record?</h6>
                        </Modal.Body>
                        <Modal.Footer className="p-0">
                              <Button className="btn-gray" onClick={delDialClose}>
                                    No
                              </Button>
                              <Button variant="secondary" onClick={successDialShow}>
                                    Yes
                              </Button>
                        </Modal.Footer>
                  </Modal>

                  {/* delete success */}
                  <Modal size="sm" show={successShow} onHide={successDialClose} centered className="del-dialogue success">
                       
                        <Modal.Body className="text-center">
                              <i className="ri-close-circle-line fa-4x text-secondary"></i>
                              <h5 className="text-center">Record deleted Successfully !!</h5>
                        </Modal.Body>
                  </Modal>
            </>
      );
}

export default Merchant;