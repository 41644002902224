import {PAYMENT_REQUEST_ERROR, PAYMENT_REQUEST_LOADING, PAYMENT_REQUEST_SUCCESS, RESET_PAYMENT_REQUEST } from "../../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function paymentRequestReducer(state = initialState, action) {
  switch (action.type) {
    case PAYMENT_REQUEST_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case PAYMENT_REQUEST_SUCCESS:
      return { ...state, error: false, loading: false, success: true, message:action.msg };

    case PAYMENT_REQUEST_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: [] }

    case RESET_PAYMENT_REQUEST:
        return {...state,error:false,loading:false,success:false,errors:[]}  

    default:
      return state;
  }
}



