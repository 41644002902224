import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";
import { withRouter, useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";
import { connect, useSelector, useDispatch } from "react-redux";

// import { createSeason } from '../../service/action/userAction';
// import { RESET_CREATE_SEASON } from '../../service/constants';
import classNames from "classnames";
import {
  OrggetallclubAction,
  OrggetallclubByIdAction,
  findByEmail,
  findUser,
  getAccountDetails,
  inviteClub,
  sendInvitationBymail,
} from "../../service/action/userAction";
import Spinner from "../../Spinner/Spinner";
import {
  RESET_CREATE_TEAM,
  RESET_INVITE_CLUB_ORG,
  RESET_SEND_INVITATION_EMAIL,
} from "../../service/constants";
import { createDynamicUrl } from "../../helpers/createDynamicUrl";

const Org_findUser = (props) => {
  const [dLink, setDLink] = useState("");
  let history = useHistory();
  const dispatch = useDispatch();
  const { id, id1 } = useParams();
  const accountDetails = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );
  const eventID = id;
  const currentUserID = accountDetails?.user_id?.id;
  const dynamicLink = "https://playersclearinghouse.page.link";
  const route = "/SwitchScreenLink";
  const para = `?invitationFor=4&id=${eventID}&hostId=${currentUserID}&invitationType=joinClub`;
  const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;

  const REQUEST_URL = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseApiKey}`;

  const BODY = {
    dynamicLinkInfo: {
      domainUriPrefix: "https://playersclearinghouse.page.link",
      link: dynamicLink + route + para,
      androidInfo: {
        androidPackageName: "com.playersclearinghouse.app",
      },
    },
  };

  useEffect(() => {
    getDeepLink();
  }, []);

  const getDeepLink = async () => {
    const res = await createDynamicUrl(REQUEST_URL, BODY);
    if (res) {
      setDLink(res?.shortLink);
    }
  };

  const [loading, setLoading] = useState(true);
  const [receipientList, setReceipientList] = useState([]);
  const [email, setEmail] = useState([]);

  const [error, setError] = useState(false);
  const [allOrgClub, setallOrgClub] = useState();
  const [emailsList, setEmailsList] = useState([]);

  useEffect(() => {
    if (!!id) {
      props.OrggetallclubByIdActionHandler(id);
    } else {
      props.OrggetallclubActionHandler();
    }
  }, [id]);

  useEffect(() => {
    dispatch(getAccountDetails());
  }, []);

  useEffect(() => {
    if (props.OrgGetallclubReducer?.success) {
      const data = props.OrgGetallclubReducer?.user?.data;
      setallOrgClub(data);
    }
  }, [props.OrgGetallclubReducer]);

  // useEffect(() => {
  //   if (id) {
  //     props.findUserHandler({ type: 4, event: id, email: [], invite_type: 4 });
  //   }
  // }, []);
  useEffect(() => {
    if (!props.receipient.loading) {
      setLoading(false);
      setReceipientList(props.receipient.user);
    }
  }, [props.receipient]);

  useEffect(() => {
    if (props.sendByMail?.success) {
      notify("Invitation send successfully");
      props.resetInvitationByMailReducer();
      setTimeout(() => {
        setEmail("");
      }, 1000);
      setError(false);
    }
  }, [props.sendByMail]);

  useEffect(() => {
    if (props.submit?.success) {
      notify("Club Invite Successfull");
      if (!!id) {
        props.OrggetallclubByIdActionHandler(id);
      } else {
        props.OrggetallclubActionHandler();
      }
      props.resetInvite();
      props.resetcreateTeamReducer();

      // history.push(`/event-details/${id}`);
    } else if (props.submit?.error) {
      notify("Club Invite fail ");
    }
  }, [props.submit]);

  const notify = (msg) => toast(msg);
  useEffect(() => {
    setLoading(false);
  }, [props.receipient]);

  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    setEmail(e.target.value);

    switch (name) {
      case "email":
        if (value.length == 0) {
          setError("Email can not be empty");
        } else if (!validEmailRegex.test(value)) {
          setError("Email is not valid !");
        } else {
          setError(false);
        }
        break;
      default:
    }
  };

  const addEmails = () => {
    if (email === "") {
      notify("Please Check Email");
    } else {
      const validEmailRegex = RegExp(
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      );
      const list = email.split(/,|, /);
      const errorList = [];
      const isValid = list.map((v) => {
        if (!validEmailRegex.test(v.trim())) {
          errorList.push(v);
          return false;
        } else if (list.length === 0 || v.trim().length === 0) {
          return false;
        } else {
          setError(false);
          return v;
        }
      });
      const _list = isValid.filter((e) => !!e);
      const combainArray = [...emailsList, ..._list];
      setEmailsList([...new Set(combainArray)]);
      if (errorList.length != 0) {
        setEmail(errorList.join(", "));
        setError("Email is not valid !");
      } else {
        setEmail("");
      }
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (emailsList.length == 0) {
      setError(true);
      notify("Please add email");
    }
    if (emailsList.length !== 0 && error == false) {
      props.sendInvitationBymailHandler({
        invite_user_email: emailsList + '',
        invite_user_type: "2",
        invitation_for: id,
      });
    }
  };

  const invite = (_id, type) => {
    if (id == null) {
      props.inviteclub({
        club_id: _id,
        event_invite: 0,
        invite_type: 0,
        invitation_type: 0,
        is_send: type,
      });
    } else {
      if (id1 == null) {
        props.inviteclub({
          event_id: id,
          club_id: _id,
          event_invite: 1,
          is_send: type,
          invite_type: 0,
          invitation_type: 0,
        });
      } else if (id1 !== null) {
        props.inviteclub({
          event_id: id,
          club_id: _id,
          event_invite: 1,
          is_send: type,
          invite_type: 1,
          invitation_type: 0,
          division: id1,
        });
      }
    }
  };

  const [copied, setCopied] = useState(false);
  function copy() {
    navigator.clipboard.writeText(dLink).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    });
  }

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head">
              <h5 className="dash-head mr-3 mb-0">Invite Clubs</h5>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-md-6">
              <div className="text-left invite-email mt-42">
                <i
                  className="fas fa-circle-info pointer"
                  title="You can add multiple Emails separated by comma."
                  style={{
                    fontSize: "10px",
                    top: "18px",
                  }}
                ></i>
                <i
                  className="mdi mdi-email"
                  style={{
                    left: "28px",
                  }}
                ></i>
                <input
                  type="email"
                  className={classNames("form-control team-name", {
                    error: error,
                  })}
                  style={{
                    paddingLeft: "52px",
                  }}
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={eventHandler}
                />
              </div>

              {error && <span className="text-red small d-block">{error}</span>}
              <div className="mt-3 fw-m text-red pointer" onClick={addEmails}>
                Add Email
              </div>
              {emailsList.length != 0 ? (
                <div className="mt-2">
                  {emailsList.map((e, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div className="row mx-0">
                          <div className="col-10 text-gray">{e}</div>
                          <div
                            className="col-2 text-right pointer"
                            onClick={() => {
                              const list = emailsList.filter(
                                (list, index) => index != i
                              );
                              setEmailsList(list);
                            }}
                          >
                            <i className="fas fa-close"></i>
                          </div>
                        </div>
                        <hr className="my-1" />
                      </React.Fragment>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}

              <div className="mt-3 text-left">
                <a
                  className="btn btn-primary font-weight-medium auth-form-btn"
                  onClick={sendEmail}
                >
                  Send
                </a>
              </div>
            </div>
          </div>
          <button
            className="btn  p-0 ml-1 mt-3"
            style={{ color: "#143D4A" }}
            onClick={copy}
          >
            <i
              className="mdi mdi-open-in-new website-ico"
              style={{ color: "#80bdff" }}
            ></i>{" "}
            {!copied ? "Copy Invite link" : "Copied!"}
          </button>

          {
            <div className="d-flex justify-content-center">
              {loading ? <Spinner /> : null}
            </div>
          }

          {!loading && (
            <div className="table-responsive">
              <table className="table event-table mt-30">
                <thead>
                  <tr>
                    <th>Club Name</th>
                    <th>Phone No.</th>
                    {/* <th>Payment</th> */}
                    <th>Location</th>
                    <th></th>
                  </tr>
                </thead>
                {allOrgClub ? (
                  <>
                    <tbody>
                      {allOrgClub
                        .filter((v, i) => v?.status === 0)
                        .map((v, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="dashboard-img">
                                    <img
                                      src={
                                        v.profile_image == ""
                                          ? "./images/pch-images/pch.svg"
                                          : v.profile_image
                                      }
                                    />
                                  </div>
                                  <span className="ml-12">{v.name}</span>
                                </div>
                              </td>
                              <td>
                                {v.mobile_number ? v.mobile_number : "NA"}
                              </td>
                              {/* <td>{v.Phone}</td> */}
                              <td>{v.address}</td>
                              <td>
                                <Link
                                  to="#"
                                  className="text-decoration-none"
                                  onClick={() => invite(v._id, v?.is_send)}
                                >
                                  <i className="mdi mdi-plus text-blue mr-2 website-ico"></i>
                                  {v?.is_send == true ? (
                                    <span className="text-blue">Resend</span>
                                  ) : (
                                    <span className="text-blue">Invite</span>
                                  )}
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </>
                ) : (
                  <>
                    <div className="container d-flex justify-content-center align-items-center">
                      <span className="noti-type">No club to display</span>
                    </div>
                  </>
                )}
              </table>
            </div>
          )}
        </div>
      </div>
      <Toaster />
    </React.Fragment>
  );
};
// export default withRouter(Org_CreateDivision)
const mapStateToProp = (state) => ({
  receipient: state.findUserReducer,
  submit: state.InviteClubReducer,
  findByReceipient: state.findByEmailUserReducer,
  OrgGetallclubReducer: state.OrgGetallclubReducer,
  sendByMail: state.sendInvitationByMailReducer,
});
const mapDispatchToProps = (dispatch) => ({
  findUserHandler: (data) => dispatch(findUser(data)),
  findByEmailHandler: (data) => dispatch(findByEmail(data)),
  sendInvitationBymailHandler: (user) => dispatch(sendInvitationBymail(user)),
  resetcreateTeamReducer: () => dispatch({ type: RESET_CREATE_TEAM }),
  inviteclub: (user) => dispatch(inviteClub(user)),
  OrggetallclubByIdActionHandler: (data) =>
    dispatch(OrggetallclubByIdAction(data)),
  OrggetallclubActionHandler: (data) => dispatch(OrggetallclubAction(data)),
  resetInvitationByMailReducer: () =>
    dispatch({ type: RESET_SEND_INVITATION_EMAIL }),
  resetInvite: () => dispatch({ type: RESET_INVITE_CLUB_ORG }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Org_findUser));
