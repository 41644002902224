import React from "react";
import { Modal } from "react-bootstrap";

function DeletePopUp({ isOpen, isClose, handleEvent }) {
  const deleteEvent = () => {
    handleEvent && handleEvent();
    isClose(false);
  };
  return (
    <Modal
      show={isOpen}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <div className="" style={{ backgroundColor: "#143d4a" }}>
        <div className="text-white text-center py-4">
          Are you sure you want to Delete?
        </div>
        <div className="row mx-0 justify-content-center border-top-0">
          <button
            onClick={() => isClose(false)}
            className="text-white col-6  border-0 fs-15 p-3 px-md-5 "
            style={{
              backgroundColor: "#54656b",
            }}
          >
            cancel
          </button>
          <button
            onClick={deleteEvent}
            className="text-white col-6 border-0 fs-15 p-3 px-md-5 "
            style={{
              backgroundColor: "#198ae3",
            }}
          >
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeletePopUp;
