import { PUBLISH_SCHEDULE_ERROR, PUBLISH_SCHEDULE_LOADING, PUBLISH_SCHEDULE_SUCCESS, RESET_PUBLISH_SCHEDULE} from "../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function publishScheduleRedcuer(state = initialState, action) {
  switch (action.type) {
    case PUBLISH_SCHEDULE_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case PUBLISH_SCHEDULE_SUCCESS:
      return { ...state, error: false, loading: false, success: true,message:action.msg };

    case PUBLISH_SCHEDULE_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: action.errors,msg:action.errors }

    case RESET_PUBLISH_SCHEDULE:
        return {...state,error:false,loading:false,success:false,errors:[],user:[{}]}
    default:
      return state;
  }
}

