import moment from "moment";
import React, { useState } from "react";
import $ from "jquery";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getClubOrganizerEvent } from "../../../service/action/Club/clubAction";
import {
  RESET_BOOKMARK,
  RESET_CLUB_ORG_EVENT,
} from "../../../service/constants";
import { bookmark } from "../../../service/action/userAction";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";

function FindEvent(props) {
  const notify = (msg) => toast(msg);
  let [loading, setLoading] = useState(true);
  const [statusText, setStatusText] = useState("Open Events");
  const [events, setEvents] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  // new Logic
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [search, setSearch] = useState("");

  const location = useLocation();

  // get Open Events

  const getOpenEventsHandelar = () => {
    setStatusText("Open Events");
    const openEvents = getOpenEvents();
    setFilteredEvents(openEvents);
  };

  const getOpenEvents = () => {
    const openEvents = allEvents?.filter((ev) => {
      const today = new Date();
      const evStartDate = new Date(ev.event_start_date);
      const evEndDate = new Date(ev.event_end_date);
      if (evStartDate <= today && evEndDate >= today) {
        return ev;
      } else {
        return null;
      }
    });
    return openEvents;
  };

  const getPastEvents = () => {
    const openEvents = allEvents?.filter((ev) => {
      const today = new Date();
      const evStartDate = new Date(ev.event_start_date);
      const evEndDate = new Date(ev.event_end_date);
      if (evStartDate < today && evEndDate < today) {
        return ev;
      } else {
        return null;
      }
    });
    setFilteredEvents(openEvents);
    setStatusText("Past Events");
  };

  useEffect(() => {
    getOpenEvents();
  }, []);

  // Search

  const allEvents = useSelector(
    (state) => state.getClubOrgEventReducer.data.data
  );
  const bookmarkMsg = useSelector((state) => state.bookmarkReducer.message);

  // Test

  useEffect(() => {
    setLoading(true);
    if (events) {
      setFilteredEvents(events);
      setLoading(false);
    }
  }, [events]);

  useEffect(() => {
    if (search !== "") {
      const allFilteredEvents = filteredEvents.filter((ev) => {
        const eventName = ev.event_name.toLowerCase();
        return eventName.includes(search.toLowerCase());
      });

      setFilteredEvents(allFilteredEvents);
    } else {
      setFilteredEvents(events);
    }
  }, [search]);

  useEffect(() => {
    props.getClubOrganizerEvent();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (allEvents) {
      setLoading(false);
      props.resetEvents();
    }
  }, [allEvents]);

  useEffect(() => {
    const openEvents = getOpenEvents();
    setEvents(openEvents);
  }, [allEvents]);

  useEffect(() => {
    if (props.getClubOrgEventReducer?.success) {
      setLoading(false);
      props.resetEvents();
    }
  }, [props.getClubOrgEventReducer]);

  useEffect(() => {
    if (props.bookmark?.success) {
      notify(bookmarkMsg.message);
      props.resetbookmarkReducer();
      props.getClubOrganizerEvent();
      setLoading(true);
    }
  }, [props.bookmark]);

  useEffect(() => {
    if (statusText === "Open Events") {
      const openEvents = getOpenEvents();
      setEvents(openEvents);
    } else if (statusText === "Past Events") {
      const filter = allEvents?.filter(
        (e) => moment(e.registration_end_date) < moment()
      );
      setEvents(filter);
    }
  }, [statusText]);

  return (
    <div className="main-panel">
      <BreadcrumbHistory
        url={location.pathname !== "/" ? location.pathname : ""}
      />

      <h3 className="text-center text-2xl font-bold">Register Teams</h3>

      <div className="content-wrapper">
        <div className="text-left">
          <div className="create-team-head flex-wrap flex-gap-3">
            <div className="d-flex align-items-center">
              <div className="search-section">
                <div className="search-components">
                  <i className="mdi mdi-magnify search-ico"></i>
                  <input
                    type="text"
                    className="form-control"
                    id="search"
                    name="search"
                    placeholder="Search"
                    autoComplete="off"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <strong className="eventText">{statusText}</strong>
              </div>

              <div className="dropdown event-drp mt-md-0">
                <i
                  className="mdi mdi-filter-outline filter-ico"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <div
                    className="dropdown-item"
                    onClick={getOpenEventsHandelar}
                  >
                    Open Events
                  </div>
                  <div className="dropdown-item" onClick={getPastEvents}>
                    Past Events
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="d-flex justify-content-center mt-4">
            <Spinner />
          </div>
        ) : (
          <>
            {/* desktop */}

            {events && (
              <div className="mt-4">
                <div className="table-responsive d-none d-md-block">
                  {events.length === 0 ? (
                    <>
                      <span className="noti-type text-center display-1">
                        No Events to display
                      </span>
                    </>
                  ) : (
                    <table className="table event-table register-table mt-5 mb-5">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Registration Status</th>
                          <th>Event Status</th>
                          <th></th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredEvents?.map((event, i) => {
                          const {
                            event_name,
                            event_image,
                            event_start_date,
                            registration_end_date,
                            event_end_date,
                            _id,
                            is_bookmarked,
                          } = event;
                          return (
                            <tr key={event._id} className="event-row">
                              <td>{i + 1}</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="dashboard-img">
                                    <img
                                      src={
                                        event_image !== ""
                                          ? event_image
                                          : "./images/pch-images/pch.svg"
                                      }
                                    />
                                  </div>
                                  <Link
                                    className="ml-12 text-decoration-none heading register-table filter-string"
                                    to={`/events/${_id}`}
                                  >
                                    {event_name}
                                  </Link>
                                </div>
                              </td>
                              <td>
                                {moment(event_start_date).format("MM/DD/YYYY")}
                              </td>
                              <td>
                                {moment(registration_end_date) > moment()
                                  ? "Open"
                                  : "Close"}
                              </td>
                              <td>
                                {moment(event_start_date) > moment()
                                  ? "Upcoming"
                                  : moment(event_end_date) > moment()
                                  ? "Open"
                                  : "Close"}
                              </td>
                              <td>
                                {is_bookmarked ? (
                                  <>
                                    <i
                                      className="fas fa-flag m-1"
                                      style={{ color: "#DB1F26" }}
                                    ></i>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>
                                <div className="dropdown event-drp-club">
                                  <i
                                    className="mdi mdi-dots-vertical"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    id="dropdownMenuLink"
                                  ></i>
                                  {is_bookmarked === true ? (
                                    <>
                                      <span className="badge badge-light"></span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink"
                                  >
                                    <Link
                                      className="dropdown-item"
                                      to="/events/find-event"
                                      onClick={() =>
                                        props.bookmarkHandler({
                                          bookmarkType: "EVENT",
                                          id: _id,
                                        })
                                      }
                                    >
                                      <i className="mdi mdi-bookmark-outline"></i>
                                      {is_bookmarked === true
                                        ? "Remove bookmark"
                                        : "Add Bookmark"}
                                    </Link>
                                    {statusText === "Open Events" && (
                                      <Link
                                        className="dropdown-item"
                                        to={`/events/${_id}/registernow`}
                                      >
                                        <i className="mdi mdi-account-multiple"></i>{" "}
                                        Register Teams
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>

                {/* mobile section */}

                <div className="d-md-none">
                  {events.length === 0 ? (
                    <>
                      <span className="noti-type mt-5">
                        No Events to display
                      </span>
                    </>
                  ) : (
                    <>
                      <div className="event-table">
                        {filteredEvents.map((event, i) => {
                          const {
                            event_name,
                            event_image,
                            event_start_date,
                            registration_end_date,
                            event_end_date,
                            _id,
                            is_bookmarked,
                            registration_start_date,
                          } = event;
                          return (
                            <div className="event-row" key={i}>
                              <div
                                className="d-flex justify-content-between reg-details"
                                key={_id}
                              >
                                <div className="d-flex">
                                  <div className="dashboard-img mr-3">
                                    <img
                                      src={
                                        event_image !== ""
                                          ? event_image
                                          : "./images/pch-images/pch.svg"
                                      }
                                    />
                                  </div>
                                  <div className="reg-content">
                                    <div className="d-flex">
                                      <Link
                                        className="text-decoration-none text-black fw-m filter-string"
                                        to={`/events/${_id}`}
                                      >
                                        {event_name}
                                      </Link>
                                      <div className="mx-2">
                                        {is_bookmarked ? (
                                          <>
                                            <i
                                              className="fas fa-flag m-1"
                                              style={{ color: "#DB1F26" }}
                                            ></i>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center mb-1 flex-wrap">
                                      <p className="text-left text-gray mb-0 fs-14">
                                        Date :{" "}
                                      </p>
                                      <p className="text-left text-gray mb-0 fs-14 ml-2">
                                        {moment(event_start_date).format(
                                          "MM/DD/YYYY"
                                        )}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center mb-1 flex-wrap">
                                      <p className="text-left text-gray mb-0 fs-14">
                                        Registration Status :{" "}
                                      </p>
                                      <p className="text-left text-gray mb-0 fs-14 ml-2">
                                        {moment(registration_start_date) >
                                        moment()
                                          ? "Upcoming"
                                          : moment(registration_end_date) >
                                            moment()
                                          ? "Open"
                                          : "Close"}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center mb-1 flex-wrap">
                                      <p className="text-left text-gray mb-0 fs-14">
                                        Event Status :{" "}
                                      </p>
                                      <p className="text-left text-gray mb-0 fs-14 ml-2">
                                        {moment(event_start_date) > moment()
                                          ? "Upcoming"
                                          : moment(event_end_date) > moment()
                                          ? "Open"
                                          : "Close"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="dropdown event-drp-club">
                                    <i
                                      className="mdi mdi-dots-vertical"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      id="dropdownMenuLink"
                                    ></i>
                                    {is_bookmarked === true ? (
                                      <>
                                        <span className="badge badge-light"></span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuLink"
                                    >
                                      <Link
                                        className="dropdown-item"
                                        to="/events/find-event"
                                        onClick={() =>
                                          props.bookmarkHandler({
                                            bookmarkType: "EVENT",
                                            id: _id,
                                          })
                                        }
                                      >
                                        <i className="mdi mdi-bookmark-outline"></i>
                                        {is_bookmarked === true
                                          ? "Remove bookmark"
                                          : "Add Bookmark"}
                                      </Link>
                                      {statusText === "Open Events" && (
                                        <Link
                                          className="dropdown-item"
                                          to={`/events/${_id}/registernow`}
                                        >
                                          <i className="mdi mdi-account-multiple"></i>{" "}
                                          Register Teams
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Toaster />
    </div>
  );
}

const mapStateToProps = (state) => ({
  bookmark: state.bookmarkReducer,
  getClubOrgEventReducer: state.getClubOrgEventReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getClubOrganizerEvent: () => {
    dispatch(getClubOrganizerEvent());
  },
  bookmarkHandler: (user) => dispatch(bookmark(user)),
  resetEvents: () => dispatch({ type: RESET_CLUB_ORG_EVENT }),
  resetbookmarkReducer: () => dispatch({ type: RESET_BOOKMARK }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FindEvent);
