import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import DeletePopUp from "../../../club/components/DeletePopUp/DeletePopUp";
import PlayerImagePopup from "../../../club/components/PlayerImagePopup/PlayerImagePopup";
import {
  clubTeamDetails,
  getOrgClubDetails,
  getTeamDetails,
  getTeamEvent,
} from "../../../service/action/userAction";

const TeamClubDetails = (props) => {
  const { id, id1 } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(true);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [isRosterDelete, setIsRosterDelete] = useState(false);
  const [selectedProfileImage, setSelectedProfileImage] = useState(null);
  const [removeRosterId, setRemoveRosterId] = useState(null);

  useEffect(() => {
    props.getTeamDetails({ id: id });
  }, []);

  const clubDetails = useSelector(
    (state) => state.clubTeamDetailsReducer.user.data
  );
  const teamDetail = useSelector(
    (state) => state.getTeamDetailsReducer.user.data
  );
  const teamEvent = useSelector(
    (state) => state.getTeamEventsReducer.user.allEvent
  );
  const accountDetails = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );

  const clubTeam = useSelector(
    (state) => state.clubTeamDetailsReducer.user.data
  );

  const [rosterPlayer, setRosterPlayer] = useState([]);

  useEffect(() => {
    props.clubTeamDetailsHandler({ id: id });
  }, []);

  useEffect(() => {
    props.clubdetailsHandler({ id: id });
  }, []);

  useEffect(() => { }, [clubDetails]);

  useEffect(() => {
    const result = [];
    if (clubTeam) {
      clubTeam.map((v, i) => {
        v.roster_player.length !== 0 &&
          v.roster_player.map((x, i) => {
            result.push(x);
          });
      });
    }
    setRosterPlayer(result);
  }, [clubTeam]);

  var teamplayer = [];

  clubDetails?.map((v, i) => {
    teamplayer?.push(v);
  });
  const [details, setDetails] = useState();

  useEffect(() => {
    if (clubDetails) {
      clubDetails?.map((v, i) => {
        return setDetails(v?.club);
      });
    }
  }, [clubDetails]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          ) : (
            <>
              {clubDetails && clubDetails ? (
                <div className="mb-5">
                  <div
                    className="d-flex align-items-center justify-content-center w-100"
                    style={{ height: "150px" }}
                  >
                    {clubDetails?.map((team, i) => {
                      if (team._id === id1) {
                        return (
                          <img
                            height={100}
                            width={100}
                            src={
                              team.image !== ""
                                ? team.image
                                : "./images/pch-images/pch.svg"
                            }
                            className="h-100"
                          />
                        );
                      }
                    })}
                  </div>
                </div>
              ) : null}
              <div className="team-details">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="details"
                      data-toggle="pill"
                      href="#details-tab"
                      role="tab"
                      aria-controls="pills-details"
                      aria-selected="true"
                    >
                      Details
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="players"
                      data-toggle="pill"
                      href="#players-tab"
                      role="tab"
                      aria-controls="pills-players"
                      aria-selected="false"
                    >
                      Players
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="rosters"
                      data-toggle="pill"
                      href="#roster-tab"
                      role="tab"
                      aria-controls="pills-rosters"
                      aria-selected="false"
                    >
                      Rosters
                    </a>
                  </li>
                </ul>

                <div className="tab-content" id="pills-tabContent">
                  <div className="d-flex justify-content-center">
                    {loading ? <Spinner /> : null}
                  </div>

                  {!loading && clubDetails ? (
                    <>
                      <div
                        className="tab-pane fade show active"
                        id="details-tab"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="d-flex justify-content-between">
                          <h4 className="text-left mb-3">Details</h4>
                          <div className="dropdown details-drp">
                            <i
                              className="mdi mdi-dots-vertical"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></i>
                          </div>
                        </div>

                        <div className="text-left ">
                          <div className="d-flex align-items-center mb-2">
                            <p className="text-left text-gray mb-0 fs-14">
                              {clubDetails?.map((team, i) => {
                                if (team._id === id1) {
                                  return (
                                    <>
                                      {" "}
                                      Season:{" "}
                                      {team?.season?.season_name
                                        ? team?.season?.season_name
                                        : "NA"}
                                    </>
                                  );
                                }
                              })}
                            </p>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <p className="text-left text-gray mb-0 fs-14">
                              {clubDetails?.map((team, i) => {
                                if (team._id === id1) {
                                  return (
                                    <>
                                      {" "}
                                      Division:{" "}
                                      {team?.division?.division_name
                                        ? team?.division?.division_name
                                        : "NA"}
                                    </>
                                  );
                                }
                              })}
                            </p>
                          </div>
                          <div className=" mb-2">
                            <p className=" mb-0 fs-14">
                              {clubDetails?.map((team, i) => {
                                return team?.coach?.length !== 0
                                  ? team._id === id1 && (
                                    <>
                                      <h4 className="text-left mb-3">
                                        Coach :
                                      </h4>
                                      <div
                                        className="d-flex justify-content-end"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "20px",
                                        }}
                                      >
                                        {" "}
                                        {team?.coach?.length !== 0
                                          ? team?.coach[0]?.name
                                          : "Null"}
                                      </div>
                                    </>
                                  )
                                  : team._id === id1 && (
                                    <div className="noti-type mt-3">
                                      <h4 className="text-left mb-3">
                                        Coach :{" "}
                                      </h4>
                                      <div className="d-flex justify-content-center">
                                        Coach is not available
                                      </div>
                                    </div>
                                  );

                                // (
                                //   team._id === id1 && (
                                //     <div className="d-flex justify-content-center">
                                //       Coach is not available
                                //     </div>
                                //   )
                                // );
                              })}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <Spinner />
                    </div>
                  )}

                  <div
                    className="tab-pane fade"
                    id="players-tab"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="d-flex justify-content-center">
                      {loading ? <Spinner /> : null}
                    </div>
                    {!loading && (
                      <>
                        {clubDetails && clubDetails.length === 0 ? (
                          <>
                            <div className="container d-flex justify-content-center align-items-center">
                              <span className="noti-type">
                                Player is not available
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <table className="table event-table text-left players-tbl">
                              {teamplayer ? (
                                <>
                                  {teamplayer ? (
                                    <>
                                      <tbody>
                                        {teamplayer?.map((v, i) => {
                                          if (
                                            v?.player.length !== 0 &&
                                            v?._id === id1
                                          ) {
                                            return v?.player?.map(
                                              (player, i) => {
                                                return (
                                                  <tr
                                                    key={i}
                                                    className="player-row"
                                                  >
                                                    <td>
                                                      <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                          <div className="dashboard-img">
                                                            <img
                                                              src={
                                                                player?.profile_image !==
                                                                  ""
                                                                  ? player?.profile_image
                                                                  : "./images/pch-images/pch.svg"
                                                              }
                                                              onClick={() => {
                                                                setOpenImageModal(
                                                                  true
                                                                );
                                                                setSelectedProfileImage(
                                                                  player?.profile_image !==
                                                                    ""
                                                                    ? player?.profile_image
                                                                    : "./images/pch-images/pch.svg"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                          <Link
                                                            className="ml-12 heading text-decoration-none filter-string"
                                                            to={`/Org/player/${player?._id}`}
                                                          >{`${player?.first_name} ${player?.last_name}`}</Link>
                                                        </div>
                                                        {/* <div className="d-flex align-item-center justify-content-center">
                                                          <Link
                                                            className="d-block text-danger ml-18 fs-18 pointer"
                                                            to={`/chat/${player._id}`}
                                                          >
                                                            <a className="d-block text-info ml-12 fs-18 pointer">
                                                              <i className="mdi mdi-message-text"></i>
                                                            </a>
                                                          </Link>

                                                          <Link
                                                            className="d-block text-danger ml-18 fs-18 pointer"
                                                            to={`/team-details/${clubDetails._id}`}
                                                            onClick={() =>
                                                              setIsRosterDelete(
                                                                true
                                                              )
                                                            }
                                                          >
                                                            <i className="mdi mdi-message-text"></i>
                                                          </Link>
                                                        </div> */}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            );
                                          } else {
                                            return (
                                              i === 1 &&
                                              v?.player?.length == 0 && (
                                                <div
                                                  className="d-flex justify-content-center align-items-center text-gray"
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  Player is not available
                                                </div>
                                              )
                                            );
                                          }
                                        })}
                                      </tbody>
                                    </>
                                  ) : (
                                    <>ffff</>
                                  )}
                                </>
                              ) : (
                                <>ddd</>
                              )}
                            </table>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div
                    className="tab-pane fade"
                    id="roster-tab"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <div className="d-flex justify-content-center">
                      {loading ? <Spinner /> : null}
                    </div>
                    {!loading && (
                      <>
                        <div className="d-flex justify-content-between">
                          <>
                            {clubDetails && clubDetails.length === 0 ? (
                              <>
                                <div className="container d-flex justify-content-center align-items-center">
                                  <span
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Roaster is not available
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <table className="table event-table text-left players-tbl">
                                  {teamplayer ? (
                                    <>
                                      {teamplayer ? (
                                        <>
                                          <tbody>
                                            {teamplayer?.map((v, i) => {
                                              if (
                                                v?.roster_player &&
                                                v?._id === id1
                                              ) {
                                                return v?.roster_player?.map(
                                                  (roster_player, i) => {
                                                    return (
                                                      <tr
                                                        key={i}
                                                        className="player-row"
                                                      >
                                                        <td>
                                                          <div className="d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                              <Link
                                                                className="ml-12 heading text-decoration-none filter-string"
                                                                style={{
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                                to={`/Org/roasterDetails/${v?._id}/${roster_player?._id}`}
                                                              >{`${roster_player?.roster_name
                                                                ? roster_player?.roster_name
                                                                : "N/A"
                                                                }`}</Link>
                                                            </div>
                                                            {/* <div className="d-flex align-item-center justify-content-center">
                                                          <Link
                                                            className="d-block text-danger ml-18 fs-18 pointer"
                                                            to={`/chat/${player._id}`}
                                                          >
                                                            <a className="d-block text-info ml-12 fs-18 pointer">
                                                              <i className="mdi mdi-message-text"></i>
                                                            </a>
                                                          </Link>

                                                          <Link
                                                            className="d-block text-danger ml-18 fs-18 pointer"
                                                            to={`/team-details/${clubDetails._id}`}
                                                            onClick={() =>
                                                              setIsRosterDelete(
                                                                true
                                                              )
                                                            }
                                                          >
                                                            <i className="mdi mdi-message-text"></i>
                                                          </Link>
                                                        </div> */}
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                );
                                              } else {
                                                return (
                                                  i === 1 &&
                                                  v?.roster_player?.length ==
                                                  0 && (
                                                    <div>No roaster found</div>
                                                  )
                                                );
                                              }
                                            })}
                                          </tbody>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </>
                            )}
                          </>
                          {/* <div className="dropdown roster-drp">
                            <i
                              className="mdi mdi-dots-vertical"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></i>

                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <Link
                                className="dropdown-item"
                                to={`/addroster/${id}`}
                              >
                                Create Roster
                              </Link>
                            </div>
                          </div> */}
                        </div>

                        {clubDetails && clubDetails.roster_player == 0 ? (
                          <>
                            <div className="container d-flex justify-content-center align-items-center">
                              <span className="noti-type">
                                No roster players to display{" "}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="mt-3">
                              {clubDetails && clubDetails.roster_player
                                ? clubDetails.roster_player.map((player) => {
                                  return (
                                    <div
                                      className="border p-2 rounded mb-3"
                                      key={player._id}
                                    >
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h4
                                          className="text-left flex-grow-1 pointer"
                                          onClick={() =>
                                            history.push(
                                              `/team-roster/${id}/${player._id}`
                                            )
                                          }
                                        >
                                          {player.roster_name}
                                        </h4>
                                        <div className="dropdown event-drp p-2">
                                          <i
                                            className="mdi mdi-dots-vertical"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          ></i>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuLink"
                                          >
                                            <Link
                                              className="dropdown-item"
                                              to={`/addroster/${id}/${player._id}`}
                                            >
                                              Add Player
                                            </Link>
                                            <div
                                              className="dropdown-item"
                                              onClick={() => {
                                                setIsRosterDelete(true);
                                                setRemoveRosterId(player._id);
                                              }}
                                            >
                                              Delete
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                                : ""}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <DeletePopUp
        isOpen={isRosterDelete}
        isClose={setIsRosterDelete}
        handleEvent={() => props.removeRoster({ id: removeRosterId })}
      />
      <PlayerImagePopup
        isOpen={openImageModal}
        isClose={setOpenImageModal}
        selectedImage={selectedProfileImage}
        resetImage={setSelectedProfileImage}
      />
    </React.Fragment>
  );
};

// export default TeamDetails;

const mapStateToProps = (state) => ({
  team_details: state.getTeamDetailsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getTeamDetails: (user) => dispatch(getTeamDetails(user)),
  // getTeamEventHandler: (user) => dispatch(getTeamEvent(user)),
  clubTeamDetailsHandler: (user) => dispatch(clubTeamDetails(user)),
  clubdetailsHandler: (user) => dispatch(getOrgClubDetails(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamClubDetails);
