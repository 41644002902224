import {
  EVENT_DIVISION_POOL_ERROR,
  EVENT_DIVISION_POOL_LOADING,
  EVENT_DIVISION_POOL_SUCCESS,
  RESET_EVENT_DIVISION_POOL,
} from "../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
};

export default function eventdivisionpollReducer(state = initialState, action) {
  switch (action.type) {
    case EVENT_DIVISION_POOL_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case EVENT_DIVISION_POOL_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        message: action.msg,
        user: action.user,
      };

    case EVENT_DIVISION_POOL_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        errors: action.errors,
        msg: action.errors,
      };

    case RESET_EVENT_DIVISION_POOL:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        errors: [],
        user: [{}],
      };
    default:
      return state;
  }
}
