
import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { getOrgClubList, getStaff } from '../../../service/action/userAction'
// import { SIGNATURE_LOADING } from '../../../service/constants';
import Spinner from '../../../Spinner/Spinner';

const Account_staff_List = (props) => {

    const [loading, setLoading] = useState(true)

    const getStaffList = props.getStaffListHandler
    useEffect(() => {
        getStaffList()
    }, [])


    const StaffList = useSelector(state => state.staffListReducer.user.data)


    useEffect(() => {
        if (props.staffList?.success) {
            setLoading(false)
        }
    }, [props.staffList])




    const EventTbl = [
        {
            name: "Staff name",
            Phone: "xxx-xxx-xxxx",
            payment: "Good",
            status: "Active",
        },
        {
            name: "Staff name",
            Phone: "xxx-xxx-xxxx",
            payment: "Good",
            status: "Invited",
        },
        {
            name: "Staff name",
            Phone: "xxx-xxx-xxxx",
            payment: "Good",
            status: "Active",
        },
        {
            name: "Staff name",
            Phone: "xxx-xxx-xxxx",
            payment: "Good",
            status: "Active",
        },
    ];



    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className='d-flex justify-content-center'>

                        {loading ? <Spinner /> : null}
                    </div>

                    {
                        !loading && <>

                            <div className="text-left">
                                <div className="create-team-head-event">
                                    <h5 className="dash-head mb-0">Staffs</h5>
                                    <div className="dropdown details-drp">
                                        {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add More</i> */}
                                        <Link className="dropdown-item" to={`/account-staff`}> <i className="mdi mdi-plus mr-2 website-ico"></i>Add Staff</Link>

                                        {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <Link className="dropdown-item" to={`/add-staff`}> <i className="mdi mdi-plus mr-2 website-ico"></i>Add Staff</Link>
                                </div> */}
                                    </div>
                                </div>
                            </div>
                            {
                                StaffList.length == 0 ?
                                    <>
                                        <div className='container d-flex justify-content-center align-items-center'>
                                            <span className='noti-type'>No staff to display</span>
                                        </div>
                                    </>
                                    :
                                    <>

                                        {/* desktop view */}
                                        <div className="table-responsive d-md-block">
                                            <table className="table event-table mt-30">
                                                <thead>
                                                    <tr>
                                                        <th>Staff Name</th>
                                                        {/* <th>Phone No.</th>
                                    <th>Payment</th>
                                    <th>Status</th> */}
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {StaffList.length == 0 ? <><span className='noti-type'>You Don't have any Staff yet</span></> : <>{


                                                        StaffList.map((v, i) => {
                                                            return <tr key={i}>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="dashboard-img">
                                                                            <img src="./images/pch-images/pch.svg" />
                                                                        </div>
                                                                        <Link className="ml-12 text-blue text-decoration-none" to="#">{v.name}</Link>
                                                                    </div>
                                                                </td>
                                                                {/* <td>{v.Phone}</td>
                                            <td>{v.payment}</td>
                                            <td>{v.status}</td> */}
                                                                <td>
                                                                    {/* <div className="dropdown event-drp">
                                                    <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                    More
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <a className="dropdown-item" href="#">
                                                            <i className="mdi mdi-bookmark-outline"></i>
                                                            Bookmark</a>
                                                        <a className="dropdown-item" href="#">
                                                            <i className="mdi mdi-plus"></i>
                                                            Invite</a>
                                                    </div>
                                                </div> */}
                                                                </td>
                                                            </tr>
                                                        })
                                                    }</>
                                                    }


                                                </tbody>
                                            </table>
                                        </div>

                                        {/* mobile view */}

                                        {/* <div className="mt-30 d-md-none">
                                            {StaffList && StaffList.length == 0 ? <>
                                            </>
                                                :
                                                <>
                                                    {

                                                        StaffList.map((v, i) => {
                                                            return <div className="d-flex justify-content-between reg-details" key={i}>
                                                                <div className="d-flex">
                                                                    <div className="dashboard-img mr-3">
                                                                        <img src="./images/pch-images/pch.svg" />
                                                                    </div>
                                                                    <div className="reg-content">
                                                                        <Link className="text-decoration-none text-blue fw-m" to="/club_details">{v.name}</Link>
                                                                       

                                                                    </div>
                                                                </div>
                                                              
                                                            </div>
                                                        })
                                                    }
                                                </>
                                            }
                                        </div> */}
                                    </>
                            }

                        </>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

// export default withRouter(Club);

const mapStateToProps = (state) => ({

    staffList: state.staffListReducer

})

const mapDispatchToProps = dispatch => ({
    getStaffListHandler: user => dispatch(getStaff(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Account_staff_List))