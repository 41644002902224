import { RESET_SIGNUP_BY_MAIL, SIGNUP_BY_MAIL_ERROR,SIGNUP_BY_MAIL_LOADING,SIGNUP_BY_MAIL_SUCCESS } from "../../constants";

const initialState={
    error:false,
    success:false,
    userL:{},
    loading:false,
    errors:[]
}

export default function signupByMailReducer(state=initialState,action){
    switch(action.type){
        case SIGNUP_BY_MAIL_LOADING:
            return{...state,error:false,success:false,loading:true}
        
        case SIGNUP_BY_MAIL_SUCCESS:
            return {...state,error:false,loading:false,success:true, message:action.message}

        case SIGNUP_BY_MAIL_ERROR:
            return { ...state,error:true,errors:[],success:false,loading:false}

        case RESET_SIGNUP_BY_MAIL:
            return {...state,error:false, loading :false, success :false}
        default:
            return state
    }
}