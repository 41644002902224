import React from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getOrgAdminPermissionList } from "../../service/action/userAction";
import { useEffect } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { useState } from "react";

function Org_Permission(props) {
  const history = useHistory();

  const [state, setState] = useState([]);

  useEffect(() => {
    props.getOrgAdminPermissionList();
  }, []);

  useEffect(() => {
    if (props.getAdminPermissionListReducer.success) {
      const data = props.getAdminPermissionListReducer.data.data;
      setState(data);
    }
  }, [props.getAdminPermissionListReducer]);
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-right">
            <div>
              <div className="mt-3 mt-md-0 text-right create-team-head-btn flex-wrap">
                <Link to="/admin-permission/create-group" className="mr-2 mt-2">
                  <button className="btn-create-new ">Create Group</button>
                </Link>
                <Link to="/admin-permission/invite-admin" className="mr-2 mt-2">
                  <button className="btn-create-new ">Invite Admin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="mt-3">
            {state.length == 0 ? (
              <> no group </>
            ) : (
              <>
                <div className="d-flex flex-wrap justify-content-start">
                  {state.map((e, i) => {
                    return (
                      <div
                        key={i}
                        className="border pointer p-2 m-2 d-flex justify-content-between align-items-center admin_group"
                        onClick={() =>
                          history.push(`/admin-permission/${e._id}`)
                        }
                      >
                        <div className=" text-danger">{e.group_name}</div>
                        <i className="mdi mdi-chevron-right"></i>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          <hr />
          <div>
            <strong>Admin Users</strong>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProp = (state) => ({
  getAdminPermissionListReducer:
    state.OrgPermissionReducer.getAdminPermissionList,
});

const mapDispatchToProps = (dispatch) => ({
  getOrgAdminPermissionList: (user) =>
    dispatch(getOrgAdminPermissionList(user)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Org_Permission));
