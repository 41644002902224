import React, { useContext } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import {
  SwitchacccessAction,
  SwitchacccessPlayerAction,
  deleteDivision,
  deletePool,
  getAllDivision,
  getEventDetails,
  moveToFreeTeamPlayer,
  removePoolPlayer,
  removePoolTeam,
} from "../../../service/action/userAction";
import { useState } from "react";
import { useEffect } from "react";
import { Modal, Table } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import DeletePopUp from "../../../club/components/DeletePopUp/DeletePopUp";
import {
  RESET_DELETE_DIVISION,
  RESET_DELETE_POOL,
  RESET_MOVE_TO_FREE_TEAM_PLAYER,
  RESET_ORG_SWITCH_ACCESS,
  RESET_ORG_SWITCH_ACCESS_PLAYER,
  RESET_REMOVE_POOLTEAM,
  RESET_REMOVE_POOL_PLAYER,
} from "../../../service/constants";
import { toast } from "react-toastify";
import classNames from "classnames";
import Toaster from "../../../Toaster/Toaster";

function EventDvisionDetails(props) {
  const { id, id1 } = useParams();
  const notify = (msg) => toast(msg);

  const history = useHistory();

  const [currentEvent, setCurrentEvent] = useState(null);
  const [currentDivision, setCurrentDivision] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  const [removeDivisionId, setRemoveDivisionId] = useState(null);
  const [isRemoveDivision, setIsRemoveDivision] = useState(false);

  useEffect(() => {
    props.getEventDetails({ id: id });
    props.getAllDivisionHandler({ id: id });
  }, []);

  useEffect(() => {
    if (props.getOrgEventDetailsByIdReducer.success) {
      const data = props.getOrgEventDetailsByIdReducer.user;
      setCurrentEvent(data.data);
    }
  }, [props.getOrgEventDetailsByIdReducer]);
  useEffect(() => {
    if (props.getAllDivisionReducer.success) {
      const data = props.getAllDivisionReducer.user;
      const current = data.data.find((e) => e._id == id1);
      setCurrentDivision(current);
    }
  }, [props.getAllDivisionReducer]);

  useEffect(() => {
    if (props.deleteDivisionReducer?.success) {
      notify(props.deleteDivisionReducer.message);
      props.resetdeleteDivisionReducer();
      props.getEventDetails({ id: id });
      history.push(`/event-details/${id}`);
    }
  }, [props.deleteDivisionReducer]);

  const CustomEvent = ({ children, eventKey, callback, ...props }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey =
      !!activeEventKey && activeEventKey[0] === eventKey ? true : false;

    return (
      <div
        style={{ color: isCurrentEventKey ? "red" : "black" }}
        onClick={decoratedOnClick}
        className={props.className}
      >
        {children}
        {isCurrentEventKey ? (
          <span>
            <i className="fas fa-angle-up"></i>
          </span>
        ) : (
          <span>
            <i className="fas fa-angle-down"></i>
          </span>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (props.switchAccessReducer.success) {
      toast.success("Switch to wait list successfully");
      props.getEventDetails({ id: id });
      props.getAllDivisionHandler({ id: id });
      props.resetSwitchToWaitList();
    }
  }, [props.switchAccessReducer]);
  useEffect(() => {
    if (props.switchPlayerAccessReducer.success) {
      toast.success("Switch to wait list successfully");
      props.getEventDetails({ id: id });
      props.getAllDivisionHandler({ id: id });
      props.resetSwitchToWaitListPlayer();
    }
  }, [props.switchPlayerAccessReducer]);

  useEffect(() => {
    if (props.moveToFreeTeamReducer.success) {
      toast.success("Move to free team Successfully");
      props.getEventDetails({ id: id });
      props.getAllDivisionHandler({ id: id });
      setIsRemoveDivision(false);
      setRemoveDivisionId(null);
      props.resetMoveToFreeTeam();
    }
  }, [props.moveToFreeTeamReducer]);

  useEffect(() => {
    if (props.deletePoolTeamReducer.success) {
      toast.success("Remove from Pool Successfully");
      props.getEventDetails({ id: id });
      props.getAllDivisionHandler({ id: id });
      props.resetremovePool();
    }
  }, [props.deletePoolTeamReducer]);

  useEffect(() => {
    if (props.deletePoolPlayerReducer.success) {
      toast.success("Remove from Pool Successfully");
      props.getEventDetails({ id: id });
      props.getAllDivisionHandler({ id: id });
      props.resetremovePoolPlayer();
    }
  }, [props.deletePoolPlayerReducer]);

  useEffect(() => {
    if (props.deletePoolReducer.success) {
      toast.success("Pool deleted Successfully");
      props.getEventDetails({ id: id });
      props.getAllDivisionHandler({ id: id });
      props.resetDeletePool();
    }
  }, [props.deletePoolReducer]);
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          {!!currentDivision && !!currentEvent && (
            <>
              <div className="row mx-0 align-items-center">
                <div className="col-11">
                  {" "}
                  <strong className="fs-18">
                    {currentDivision?.division_name}
                  </strong>
                  <div className="mt-2">
                    <p className="text-gray mb-1">
                      Max teams :{" "}
                      {currentDivision.type == 0
                        ? `${currentDivision.totalRegTeam} / ${currentDivision.max_participated}`
                        : `${currentDivision.totalRegPlayer} / ${currentDivision.max_participated}`}
                    </p>
                    {!!currentDivision?.payment_code && (
                      <p className="text-gray mb-1">
                        Payment Code :{" "}
                        {
                          currentEvent?.payment_code.find(
                            (e) => e._id == currentDivision?.payment_code
                          )?.name
                        }
                      </p>
                    )}
                  </div>
                </div>
                <i
                  className="mdi mdi-dots-vertical pointer fs-18"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div
                  className="dropdown-menu pointer"
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link
                    className="dropdown-item"
                    to={`/event-details/${currentDivision.event_id}/${currentDivision._id}`}
                  >
                    <i className="mdi mdi-border-color text-blue mr-2"></i>
                    Edit
                  </Link>

                  <Link
                    className="dropdown-item"
                    to={`#`}
                    onClick={() => {
                      setIsDelete(true);
                      setDeleteId(currentDivision._id);
                    }}
                  >
                    <i className="fas fa-trash-alt text-blue mr-2"></i>
                    Delete
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={`/addpool/${id}/${currentDivision._id}`}
                  >
                    <i className="fas fa-plus text-blue mr-2"></i>
                    Add Pool
                  </Link>
                  {!currentDivision.payment_code &&
                    currentEvent?.is_paid_event == 1 && (
                      <Link
                        className="dropdown-item"
                        to={`/event/paymentcode/${id}/${currentDivision._id}`}
                      >
                        <i className="fas fa-plus text-blue mr-2"></i>
                        Use Custom Payment Code
                      </Link>
                    )}
                  {/* {finalPaymentArray[i] ? <></> : <></>} */}

                  <Link
                    className="dropdown-item"
                    to={
                      currentDivision.type == 0
                        ? `/club-invite/${id}/${currentDivision._id}`
                        : `/org-invite-player/${id}/${currentDivision._id}`
                    }
                  >
                    <i className="fas fa-plus text-blue mr-2"></i>
                    {currentDivision.type == 0
                      ? "Invite club"
                      : "Invite Parent/Palyer"}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={`/addteam/${id}/${currentDivision._id}`}
                  >
                    <i className="fas fa-plus text-blue mr-2"></i>
                    {currentDivision.type == 0
                      ? "Add team"
                      : "Add Parent/Palyer"}
                  </Link>
                  {!!currentDivision.payment_code && (
                    <Link
                      className="dropdown-item"
                      to={`/addteam/${id}/${currentDivision._id}`}
                    >
                      <i className="far fa-copy text-blue mr-2"></i>
                      Copy Invite Link
                    </Link>
                  )}
                </div>
              </div>

              {currentDivision.type == 0 && (
                <>
                  {currentDivision.team.length == 0 ? (
                    <>
                      <div className="mt-4 text-gray fs-18 text-center">
                        No teams Found
                      </div>
                    </>
                  ) : (
                    <div className="mt-4">
                      <Table className="table event-table">
                        <thead>
                          <tr>
                            <th>Team</th>
                            <th>Registration fot event</th>
                            <th>Registration fot division</th>
                            <th>Payment</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentDivision?.team?.map((v, i) => {
                            const amount = !!v.amount
                              ? v.amount.toString()
                              : false;
                            const panding_amount = !!v.panding_amount
                              ? v.panding_amount.toString()
                              : false;
                            return (
                              <tr key={i} className="border-top border-bottom">
                                <Link
                                  className="text-decoration-none text-dark"
                                  to={`/team-details/${v?._id}`}
                                >
                                  <td className="text-gray">
                                    {v?.team?.team_name}
                                  </td>
                                </Link>
                                <td className="text-gray">
                                  {v?.registrationStatus === 0
                                    ? "Waitlist"
                                    : "Registered"}
                                </td>
                                <td className="text-gray">
                                  {v?.divisionRegistrationStatus === 0
                                    ? "Waitlist"
                                    : "Registered"}
                                </td>
                                <td className="text-gray">
                                  {!!amount &&
                                  !!panding_amount &&
                                  panding_amount == 0 &&
                                  amount == 0
                                    ? "Free"
                                    : (amount != 0 && panding_amount != 0) ||
                                      (!amount && !panding_amount)
                                    ? "Partially paid"
                                    : !!amount &&
                                      !!panding_amount &&
                                      amount > 0 &&
                                      panding_amount == 0
                                    ? "Paid"
                                    : "Unpaid"}
                                </td>
                                <td className="text-gray">
                                  {/* <div className="dropdown event-drp details-drp"> */}
                                  {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> */}
                                  {/* <i className="mdi mdi-plus website-ico mr-2 " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add More</i> */}
                                  <i
                                    className="mdi mdi-dots-horizontal text-dark pointer"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  ></i>

                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink"
                                  >
                                    {" "}
                                    {v.registrationStatus == 1 && (
                                      <div className="py-2 px-3">
                                        <Link
                                          to={{
                                            pathname: `/updatepooldivision/${id}/${currentDivision._id}/${v._id}`,
                                            state: {
                                              type: "pool",
                                            },
                                          }}
                                          className="text-decoration-none pointer"
                                        >
                                          <i className="mdi mdi-plus text-blue mr-2"></i>
                                          <span className="text-dark">
                                            Add to Pool
                                          </span>
                                        </Link>
                                        <hr className="my-1" />
                                      </div>
                                    )}{" "}
                                    {v.registrationStatus == 1 && (
                                      <div className="py-2 px-3">
                                        <Link
                                          to={`#`}
                                          className="text-decoration-none pointer"
                                          onClick={() => {
                                            props.SwitchacccessActionhandler({
                                              team: v._id,
                                              from: 0,
                                              type: v.type,
                                            });
                                          }}
                                        >
                                          <i className="mdi mdi-repeat text-blue mr-2"></i>
                                          <span className="text-dark">
                                            Switch to wait List
                                          </span>
                                        </Link>
                                        <hr className="my-1" />
                                      </div>
                                    )}
                                    <div className="py-2 px-3">
                                      <Link
                                        to={`#`}
                                        className="text-decoration-none pointer"
                                        onClick={() => {
                                          props.moveToFreeTeamPlayer({
                                            id: v._id,
                                            type: v.type,
                                            type2: 0,
                                          });
                                        }}
                                      >
                                        <i className="mdi mdi-minus text-blue mr-2"></i>
                                        <span className="text-dark">
                                          Remove From division
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                  {/* </div> */}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </>
              )}
              {currentDivision.type == 1 && (
                <>
                  {currentDivision.team_player.length == 0 ? (
                    <>
                      <div className="mt-4 text-gray fs-18 text-center">
                        No teams Found
                      </div>
                    </>
                  ) : (
                    <div className="mt-4">
                      <Table className="table event-table">
                        <thead>
                          <tr>
                            <th>Team</th>
                            <th>Registration fot event</th>
                            <th>Registration fot division</th>
                            <th>Payment</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentDivision?.team_player?.map((v, i) => {
                            const currentPlayer = currentEvent.player.find(
                              (p) => p._id === v._id
                            );
                            const amount = currentPlayer?.amount?.toString();
                            const panding_amount =
                              currentPlayer?.panding_amount?.toString();
                            return (
                              <tr key={i} className="border-top border-bottom">
                                <Link
                                  className="text-decoration-none text-dark"
                                  to={`/team-details/${v?._id}`}
                                >
                                  <td className="text-gray">
                                    {`${v?.first_name} ${v?.last_name}`}
                                  </td>
                                </Link>
                                <td className="text-gray">
                                  {v?.registrationStatus === 0
                                    ? "Waitlist"
                                    : "Registered"}
                                </td>
                                <td className="text-gray">
                                  {v?.divisionRegistrationStatus === 0
                                    ? "Waitlist"
                                    : "Registered"}
                                </td>
                                <td className="text-gray">
                                  {!!amount &&
                                  !!panding_amount &&
                                  panding_amount == 0 &&
                                  amount == 0
                                    ? "Free"
                                    : (amount != 0 && panding_amount != 0) ||
                                      (!amount && !panding_amount)
                                    ? "Partially paid"
                                    : !!amount &&
                                      !!panding_amount &&
                                      amount > 0 &&
                                      panding_amount == 0
                                    ? "Paid"
                                    : "Unpaid"}
                                </td>
                                <td className="text-gray">
                                  {/* <div className="dropdown event-drp details-drp"> */}
                                  {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> */}
                                  {/* <i className="mdi mdi-plus website-ico mr-2 " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add More</i> */}
                                  <i
                                    className="mdi mdi-dots-horizontal text-dark pointer"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  ></i>

                                  <div
                                    className="dropdown-menu py-0"
                                    aria-labelledby="dropdownMenuLink"
                                  >
                                    {" "}
                                    {v.registrationStatus == 1 && (
                                      <div className="py-2 px-3 border-bottom py-3">
                                        <Link
                                          to={"#"}
                                          className="text-decoration-none pointer"
                                        >
                                          <i className="mdi mdi-plus text-blue mr-2"></i>
                                          <span className="text-dark">
                                            Add to Internal Teams
                                          </span>
                                        </Link>
                                        {/* <hr className="my-1" /> */}
                                      </div>
                                    )}
                                    {v.registrationStatus == 1 && (
                                      <div className="py-2 px-3 border-bottom py-3">
                                        <Link
                                          to={{
                                            pathname: `/event-freeAgents/${id}/${currentDivision._id}/${v._id}`,
                                            state: {
                                              type: "pool",
                                            },
                                          }}
                                          className="text-decoration-none pointer"
                                        >
                                          <i className="mdi mdi-plus text-blue mr-2"></i>
                                          <span className="text-dark">
                                            Add to Pool
                                          </span>
                                        </Link>
                                        {/* <hr className="my-1" /> */}
                                      </div>
                                    )}
                                    {v.registrationStatus == 1 && (
                                      <div className="py-2 px-3 border-bottom py-3">
                                        <Link
                                          to={`#`}
                                          className="text-decoration-none pointer"
                                          onClick={() => {
                                            props.SwitchacccessPlayerAction({
                                              player: v._id,
                                              from: 0,
                                              type: 1,
                                            });
                                          }}
                                        >
                                          <i className="mdi mdi-repeat text-blue mr-2"></i>
                                          <span className="text-dark">
                                            Switch to wait List
                                          </span>
                                        </Link>
                                        {/* <hr className="my-1" /> */}
                                      </div>
                                    )}
                                    <div className="py-2 px-3 border-bottom py-3">
                                      <Link
                                        to={`#`}
                                        className="text-decoration-none pointer"
                                        onClick={() => {
                                          props.moveToFreeTeamPlayer({
                                            id: v._id,
                                            type: v.type,
                                            type2: 1,
                                          });
                                        }}
                                      >
                                        <i className="mdi mdi-minus text-blue mr-2"></i>
                                        <span className="text-dark">
                                          Remove From division
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                  {/* </div> */}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </>
              )}

              {currentDivision.pool.length == 0 ? (
                <>
                  <div className="mt-4 text-gray fs-18 text-center">
                    No pools Found
                  </div>
                </>
              ) : (
                <>
                  <div className="mt-5 text-gray">
                    <strong>Pools</strong>
                  </div>
                  <div className="ml-4 mt-3">
                    {currentDivision.pool.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          {/* <div className="row mx-0 my-3">
                            <div className="col-8"><strong>{e.pool_name}</strong></div>
                            <div className="col-4"></div>

                          </div> */}
                          <Accordion alwaysOpen>
                            <div className="row mx-0 align-items-center">
                              <CustomEvent
                                eventKey={i}
                                className={
                                  "col-10 d-flex align-items-center justify-content-between pointer"
                                }
                              >
                                <div className="my-3">
                                  <strong>{e.pool_name}</strong>
                                </div>
                              </CustomEvent>
                              <div className="col-2">
                                <i
                                  className="mdi mdi-dots-vertical text-dark pointer"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                ></i>

                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuLink"
                                >
                                  <div className="py-2 px-3">
                                    <Link
                                      to={`/edit-pool/${id}/${id1}/${e._id}`}
                                      className="text-decoration-none pointer"
                                    >
                                      <i className="mdi mdi-border-color text-blue mr-2"></i>
                                      <span className="text-dark">Edit</span>
                                    </Link>
                                  </div>
                                  <hr className="my-1" />
                                  <div className="py-2 px-3">
                                    <Link
                                      to={`#`}
                                      className="text-decoration-none pointer"
                                      onClick={() => {
                                        props.deletePool(e._id);
                                      }}
                                    >
                                      <i className="fas fa-trash-alt text-blue mr-2"></i>
                                      <span className="text-dark">Delete</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Club... */}
                            {currentDivision.type == 0 && (
                              <Accordion.Collapse eventKey={i}>
                                <div>
                                  {e.event_team.length != 0 ? (
                                    <>
                                      {e.event_team.map((team, i) => {
                                        const amount = !!team.amount
                                          ? team?.amount?.toString()
                                          : false;
                                        const panding_amount =
                                          !!team.panding_amount
                                            ? team?.panding_amount?.toString()
                                            : false;
                                        return (
                                          <React.Fragment key={i}>
                                            <div className="m-3">
                                              <div className="row mx-0 justify-content-between">
                                                <div className="col-8">
                                                  <strong>
                                                    Team : {team.team.team_name}
                                                  </strong>
                                                  <div className="text-gray my-2">
                                                    Registration :{" "}
                                                    {team.registrationStatus ==
                                                    1
                                                      ? "Registered"
                                                      : "Wait List"}
                                                  </div>
                                                  <div className="text-gray my-2">
                                                    Division Registration :{" "}
                                                    {team.divisionRegistrationStatus ==
                                                    1
                                                      ? "Registered"
                                                      : "Wait List"}
                                                  </div>
                                                  <div className="text-gray my-2">
                                                    Payment :{" "}
                                                    {!!amount &&
                                                    !!panding_amount &&
                                                    panding_amount == 0 &&
                                                    amount == 0
                                                      ? "Free"
                                                      : (amount != 0 &&
                                                          panding_amount !=
                                                            0) ||
                                                        (!amount &&
                                                          !panding_amount)
                                                      ? "Partially paid"
                                                      : !!amount &&
                                                        !!panding_amount &&
                                                        amount > 0 &&
                                                        panding_amount == 0
                                                      ? "Paid"
                                                      : "Unpaid"}
                                                  </div>
                                                </div>
                                                <div className="col-4">
                                                  <i
                                                    className="mdi mdi-dots-vertical text-dark pointer"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  ></i>

                                                  <div
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuLink"
                                                  >
                                                    <div className="py-2 px-3">
                                                      {" "}
                                                      <Link
                                                        to={{
                                                          pathname: `/updatepooldivision/${id}/${currentDivision._id}/${team._id}`,
                                                          // for change pass eventId, divisionId, ad PoolId and send teamId as State for Edit
                                                          state: {
                                                            editedPool:
                                                              team._id,
                                                          },
                                                        }}
                                                        className="text-decoration-none pointer"
                                                      >
                                                        <i className="mdi mdi-plus text-blue mr-2"></i>
                                                        <span className="text-dark">
                                                          Change Pool
                                                        </span>
                                                      </Link>
                                                    </div>
                                                    <hr className="my-1" />
                                                    <div className="py-2 px-3">
                                                      {" "}
                                                      <Link
                                                        to={`#`}
                                                        className="text-decoration-none pointer"
                                                        onClick={() => {
                                                          props.SwitchacccessActionhandler(
                                                            {
                                                              team: team._id,
                                                              from: 0,
                                                              type: 1,
                                                            }
                                                          );
                                                        }}
                                                      >
                                                        <i className="mdi mdi-repeat text-blue mr-2"></i>
                                                        <span className="text-dark">
                                                          Switch to wait List
                                                        </span>
                                                      </Link>
                                                    </div>
                                                    <hr className="my-1" />
                                                    <div className="py-2 px-3">
                                                      <Link
                                                        to={`#`}
                                                        className="text-decoration-none pointer"
                                                        onClick={() => {
                                                          props.removePoolTeam({
                                                            pool: e._id,
                                                            team: team._id,
                                                            event: id,
                                                          });
                                                        }}
                                                      >
                                                        <i className="mdi mdi-minus text-blue mr-2"></i>
                                                        <span className="text-dark">
                                                          Remove From Pool
                                                        </span>
                                                      </Link>
                                                    </div>
                                                    <hr className="my-1" />
                                                    <div className="py-2 px-3">
                                                      <Link
                                                        to={`#`}
                                                        className="text-decoration-none pointer"
                                                        onClick={() => {
                                                          setIsRemoveDivision(
                                                            true
                                                          );
                                                          setRemoveDivisionId(
                                                            team._id
                                                          );
                                                        }}
                                                      >
                                                        <i className="mdi mdi-minus text-blue mr-2"></i>
                                                        <span className="text-dark">
                                                          Remove From division
                                                        </span>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <hr />
                                            </div>
                                          </React.Fragment>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Accordion.Collapse>
                            )}
                            {/* Club.... */}

                            {/* Free Agent... */}
                            {currentDivision.type == 1 && (
                              <Accordion.Collapse eventKey={i}>
                                <div>
                                  {e.players.length != 0 ? (
                                    <>
                                      {e.players.map((player, i) => {
                                        const currentPlayer =
                                          currentEvent.player.find(
                                            (p) => p._id === player._id
                                          );
                                        const amount =
                                          currentPlayer?.amount?.toString();
                                        const panding_amount =
                                          currentPlayer?.panding_amount?.toString();
                                        return (
                                          <React.Fragment key={i}>
                                            <div className="m-3">
                                              <div className="row mx-0 justify-content-between">
                                                <div className="col-8">
                                                  <strong>
                                                    Player :{" "}
                                                    {`${player.first_name} ${player.last_name}`}
                                                  </strong>
                                                  <div className="text-gray my-2">
                                                    Registration :{" "}
                                                    {player?.registrationStatus ==
                                                    1
                                                      ? "Registered"
                                                      : "Wait List"}
                                                  </div>
                                                  <div className="text-gray my-2">
                                                    Division Registration :{" "}
                                                    {player?.divisionRegistrationStatus ==
                                                    1
                                                      ? "Registered"
                                                      : "Wait List"}
                                                  </div>
                                                  <div className="text-gray my-2">
                                                    Payment :{" "}
                                                    {!!amount &&
                                                    !!panding_amount &&
                                                    panding_amount == 0 &&
                                                    amount == 0
                                                      ? "Free"
                                                      : (amount != 0 &&
                                                          panding_amount !=
                                                            0) ||
                                                        (!amount &&
                                                          !panding_amount)
                                                      ? "Partially paid"
                                                      : !!amount &&
                                                        !!panding_amount &&
                                                        amount > 0 &&
                                                        panding_amount == 0
                                                      ? "Paid"
                                                      : "Unpaid"}
                                                  </div>
                                                </div>
                                                <div className="col-4">
                                                  <i
                                                    className="mdi mdi-dots-vertical text-dark pointer"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  ></i>

                                                  <div
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuLink"
                                                  >
                                                    <div className="py-2 px-3">
                                                      {" "}
                                                      <Link
                                                        to={"#"}
                                                        className="text-decoration-none pointer"
                                                      >
                                                        <i className="mdi mdi-plus text-blue mr-2"></i>
                                                        <span className="text-dark">
                                                          Add to Internal Teams
                                                        </span>
                                                      </Link>
                                                    </div>
                                                    <hr className="my-1" />
                                                    <div className="py-2 px-3">
                                                      {" "}
                                                      <Link
                                                        to={{
                                                          pathname: `/event-freeAgents/${id}/${currentDivision._id}/${player._id}`,
                                                          // for change pass eventId, divisionId, ad PoolId and send teamId as State for Edit
                                                          state: {
                                                            editedPool:
                                                              player._id,
                                                          },
                                                        }}
                                                        className="text-decoration-none pointer"
                                                      >
                                                        <i className="mdi mdi-plus text-blue mr-2"></i>
                                                        <span className="text-dark">
                                                          Change Pool
                                                        </span>
                                                      </Link>
                                                    </div>
                                                    <hr className="my-1" />
                                                    <div className="py-2 px-3">
                                                      {" "}
                                                      <Link
                                                        to={`#`}
                                                        className="text-decoration-none pointer"
                                                        onClick={() => {
                                                          props.SwitchacccessPlayerAction(
                                                            {
                                                              player:
                                                                player._id,
                                                              from: 0,
                                                              type: 1,
                                                            }
                                                          );
                                                        }}
                                                      >
                                                        <i className="mdi mdi-repeat text-blue mr-2"></i>
                                                        <span className="text-dark">
                                                          {player?.registrationStatus ==
                                                          1
                                                            ? "Switch to wait List"
                                                            : "Switch to Registered"}
                                                        </span>
                                                      </Link>
                                                    </div>
                                                    <hr className="my-1" />
                                                    <div className="py-2 px-3">
                                                      <Link
                                                        to={`#`}
                                                        className="text-decoration-none pointer"
                                                        onClick={() => {
                                                          props.removePoolPlayer(
                                                            {
                                                              pool: e._id,
                                                              player:
                                                                player._id,
                                                              event: id,
                                                            }
                                                          );
                                                        }}
                                                      >
                                                        <i className="mdi mdi-minus text-blue mr-2"></i>
                                                        <span className="text-dark">
                                                          Remove From Pool
                                                        </span>
                                                      </Link>
                                                    </div>
                                                    <hr className="my-1" />
                                                    <div className="py-2 px-3">
                                                      <Link
                                                        to={`#`}
                                                        className="text-decoration-none pointer"
                                                        onClick={() => {
                                                          setIsRemoveDivision(
                                                            true
                                                          );
                                                          setRemoveDivisionId(
                                                            player._id
                                                          );
                                                        }}
                                                      >
                                                        <i className="mdi mdi-minus text-blue mr-2"></i>
                                                        <span className="text-dark">
                                                          Remove From division
                                                        </span>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <hr />
                                            </div>
                                          </React.Fragment>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Accordion.Collapse>
                            )}
                            {/* Free Agent... */}
                          </Accordion>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => props.deleteDivisionHandler(deleteId)}
      />

      <Modal
        show={isRemoveDivision}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <div className="p-3" style={{ backgroundColor: "#ffffff" }}>
          <div className="d-flex justify-content-center my-4">
            <i
              className="fas fa-arrow-right-from-bracket "
              style={{
                fontSize: "40px",
                color: "red",
              }}
            ></i>
          </div>
          <div className="text-center">
            This transfer comes with diff payment term, would you wish charge
            for it?
          </div>
          <div className="mt-4 d-flex justify-content-center border-top-0">
            <button
              onClick={() => {
                props.moveToFreeTeamPlayer({
                  id: removeDivisionId,
                  type: 0,
                  type2: 1,
                });
              }}
              className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 mx-3 rounded"
            >
              No
            </button>
            <button
              onClick={() => {
                props.moveToFreeTeamPlayer({
                  id: removeDivisionId,
                  type: 1,
                  type2: 1,
                });
              }}
              className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 mx-3 rounded"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
      <Toaster />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  getOrgEventDetailsByIdReducer: state.getOrgEventDetailsByIdReducer,
  getAllDivisionReducer: state.getAllDivisionReducer,
  deleteDivisionReducer: state.deleteDivisionReducer,
  switchAccessReducer: state.switchAccessReducer,
  switchPlayerAccessReducer: state.switchPlayerAccessReducer,
  moveToFreeTeamReducer: state.moveToFreeTeamReducer,
  deletePoolTeamReducer: state.deletePoolTeamReducer,
  deletePoolPlayerReducer: state.deletePoolPlayerReducer,
  deletePoolReducer: state.deletePoolReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getEventDetails: (user) => dispatch(getEventDetails(user)),
  getAllDivisionHandler: (user) => dispatch(getAllDivision(user)),
  deleteDivisionHandler: (user) => dispatch(deleteDivision(user)),
  SwitchacccessActionhandler: (user) => dispatch(SwitchacccessAction(user)),
  moveToFreeTeamPlayer: (user) => dispatch(moveToFreeTeamPlayer(user)),
  removePoolTeam: (user) => dispatch(removePoolTeam(user)),
  removePoolPlayer: (user) => dispatch(removePoolPlayer(user)),
  deletePool: (user) => dispatch(deletePool(user)),
  SwitchacccessPlayerAction: (user) =>
    dispatch(SwitchacccessPlayerAction(user)),

  resetdeleteDivisionReducer: () => dispatch({ type: RESET_DELETE_DIVISION }),
  resetSwitchToWaitList: () => dispatch({ type: RESET_ORG_SWITCH_ACCESS }),
  resetSwitchToWaitListPlayer: () =>
    dispatch({ type: RESET_ORG_SWITCH_ACCESS_PLAYER }),
  resetMoveToFreeTeam: () => dispatch({ type: RESET_MOVE_TO_FREE_TEAM_PLAYER }),
  resetremovePool: () => dispatch({ type: RESET_REMOVE_POOLTEAM }),
  resetremovePoolPlayer: () => dispatch({ type: RESET_REMOVE_POOL_PLAYER }),
  resetDeletePool: () => dispatch({ type: RESET_DELETE_POOL }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventDvisionDetails));
