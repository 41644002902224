import {
  EDIT_EVENT_DATA_SUCCESS,
  EDIT_EVENT_DATA_LOADING,
  EDIT_EVENT_DATA_ERROR,
  RESET_EDIT_EVENT,
} from "../constants";

const initialState = {
  error: false,
  success: false,
  user: {
    amount: "",
    name: "",
    payment_terms: "",
    payment_terms_type: "",
  },
  loading: false,
};

export default function editEventReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_EVENT_DATA_LOADING:
      return { ...state, loading: true, error: false, success: false };

    case EDIT_EVENT_DATA_SUCCESS:
      // alert("edit reducer success")
      return {
        ...state,
        success: true,
        user: action.data,
        message: action.msg,
        loading: false,
      };

    case EDIT_EVENT_DATA_ERROR:
      // alert("edit reducer Error")
      return { ...state, error: true, error: action.error, loading: false };

    case RESET_EDIT_EVENT:
      return {
        ...state,
        error: false,
        success: false,
        loading: false,
        errors: [],
      };

    default:
      return state;
  }
}
