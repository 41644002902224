import { ASSIGN_COACH_ERROR, ASSIGN_COACH_LOADING, ASSIGN_COACH_SUCCESS,  RESET_ASSIGN_COACH} from "../../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function assigncoachReducer(state = initialState, action) {
  switch (action.type) {

    case ASSIGN_COACH_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case ASSIGN_COACH_SUCCESS:
      return { ...state, error: false, loading: false, success: true,message:action.msg };

    case ASSIGN_COACH_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: action.errors,msg:action.errors }

    case RESET_ASSIGN_COACH:
        return {...state,error:false,loading:false,success:false,errors:[],user:[{}]}  

    default:
      return state;
  }
}

