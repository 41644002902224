import { history } from "../../helpers/history";
import { SIGNIN_ERROR, SIGNIN_LOADING, SIGNIN_SUCCESS ,RESET_SIGNIN, EMAIL_VERIFICATION} from "../constants";

let initialState = {
  loading: false,
  user: {},
  error: false,
  errors: [],
  status:"",
  club:"",
  success: false,
  token:"",
  info:{
    "status": '',
    "message": "",
    "data": {
        "_id": "",
        "email": "",
        "is_organizer": '',
        "organizer": [
            {
                "_id": "",
                "user_id": "",
                "name": "",
                "createdAt": "",
                "updatedAt": "",
                "__v": ''
            }
        ],
        "is_staff": '',
        "staffs": [
            {
                "_id": "",
                "user_id": "",
                "name": "",
                "createdAt": "",
                "updatedAt": "",
                "__v": ''
            }
        ],
        "is_coach": '',
        "coaches": [],
        "is_club": '',
        "clubs": [
            {
                "sport": [],
                "_id": "",
                "user_id": "",
                "name": "",
                "createdAt": "",
                "updatedAt": "",
                "__v": ''
            }
           
        ],
        "is_player_parent": '',
        "status":'',
        "createdAt": "",
        "updatedAt": ""
    },
    "token": ""
},

  auth:false,
  org_name:"",
  organizer_id:""
  
};

export default function signinReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNIN_SUCCESS:
      // alert("reducer succesfull")
      return { ...state, loading: false, error: false, errors: [], emailError: false,success: true, user: action.user,status:action.status ,club:action.club ,token:action.token,info:action.info,auth:action.auth,org_name:action.org_name, organizer_id:action.organizer_id,message:action.msg};
    case SIGNIN_ERROR:  
      // alert("reducer Error")
      return { ...state, loading: false, error: true, emailError: false,errors:action.errors, success: false,neterror:action.error };
    case SIGNIN_LOADING:
      // alert("reducer Loading")
      return { ...state, loading: true, error: false,emailError: false , errors: [], };
    case RESET_SIGNIN:
      // alert("reducer reset")
      return { ...state, loading: false, error: false, emailError: false, errors: [], success: false };
      break;
    case EMAIL_VERIFICATION:
      // alert("reducer reset")
      return { ...state, loading: false, emailError: true, errors: [], success: false ,verifyError:action.errors};
      break;

    default:
      return state;
  }
}


