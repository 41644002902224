import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import {
  getAllInvitation,
  getAllUserRoll,
  switchAccountData,
  switchAccountDataForNotification,
} from "../../../service/action/userAction";
import { useEffect } from "react";
import Toaster from "../../../Toaster/Toaster";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";

function NotificationAccounts(props) {
  const history = useHistory();
  const location = useLocation();
  const icon = {
    color: "#8A9EA5",
  };
  const divider = {
    borderColor: "rgba(255,255,255, 0.4)",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "auto",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    props.getUserRollHandler();
  }, []);

  const userRollClub = useSelector(
    (state) => state.getUserRollReducer.user.clubs
  );
  const userRoll = useSelector((state) => state.getUserRollReducer.user);
  const userRollOrg = useSelector(
    (state) => state.getUserRollReducer.user.organizer
  );

  const [accountName, setAccountName] = useState("");
  const [userId, setUserId] = useState(localStorage.getItem("Id"));
  const [userType, setUserType] = useState(+localStorage.getItem("type"));
  const changeNotificationStatus = useSelector(
    (state) => state.changeNotificationStatusReducer
  );

  useEffect(() => {
    if (changeNotificationStatus.success) {
      dispatch(
        getAllInvitation({
          id: userId,
          type: userType,
        })
      );
    }
  }, [changeNotificationStatus]);

  useEffect(() => {
    const userName = localStorage.getItem("username");
    setAccountName(userName);
  }, []);

  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Button
        onClick={handleOpen}
        sx={{ textTransform: "unset" }}
        className="text-2xl border"
      >
        <div className="flex justify-center items-center space-x-1">
          <div> {accountName}</div>
          <div>
            <IoIosArrowDown />
          </div>
        </div>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            {!!userRoll ? (
              <>
                <div className="bg-[#143D4A] text-left overflow-auto h-full w-full px-4 py-2">
                  <h3 className="mt-1 mb-4 text-white-50 text-center">
                    Notifications
                  </h3>
                  {!!userRollOrg ? (
                    <>
                      {userRollOrg.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          {userRollOrg.map((v, i) => {
                            return (
                              <div
                                key={v._id}
                                className="py-3 border-bottom"
                                onClick={() => {
                                  setAccountName(v.name);
                                  handleClose();
                                  setUserId(v._id);
                                  setUserType(1);
                                }}
                              >
                                <Link
                                  to={{
                                    pathname:
                                      props.type == "1"
                                        ? `/notification-accounts/${v._id}`
                                        : `/account-notifications/${v._id}`,
                                    state: {
                                      id: v._id,
                                      type: 1,
                                    },
                                  }}
                                  className="d-flex justify-content-between text-decoration-none"
                                >
                                  <div className="row mx-0 justify-content-between w-100">
                                    <div>
                                      <div className="text-white">
                                        <strong>{v.name}</strong>
                                      </div>
                                      <div>
                                        <small className="noti-type">
                                          Event Organizer
                                        </small>
                                      </div>
                                    </div>
                                    <div className="row mx-0">
                                      {parseInt(v?.notification) +
                                        parseInt(v?.dm_unread_msg) +
                                        parseInt(v?.invitation_unread_msg) >
                                        0 && (
                                        <div className="font-bold bg-white mr-3 border rounded-full w-7 h-7 flex justify-center items-center">
                                          {parseInt(v?.notification) +
                                            parseInt(v?.dm_unread_msg) +
                                            parseInt(v?.invitation_unread_msg)}
                                        </div>
                                      )}
                                      <div>
                                        <i
                                          className="fas fa-chevron-right mr-2   d-inline"
                                          style={icon}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {userRollClub ? (
                    <>
                      {userRollClub.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          {userRollClub.map((v, i) => {
                            // {club == 1 ?
                            return (
                              <div
                                key={v._id}
                                className="py-3 border-bottom"
                                onClick={() => {
                                  handleClose();
                                  setAccountName(v.name);
                                  setUserId(v._id);
                                  setUserType(4);
                                }}
                              >
                                <Link
                                  to={{
                                    pathname:
                                      props.type == "1"
                                        ? `/notification-accounts/${v._id}`
                                        : `/account-notifications/${v._id}`,
                                    state: {
                                      id: v._id,
                                      type: 4,
                                    },
                                  }}
                                  className="d-flex justify-content-between text-decoration-none"
                                >
                                  <div className="row mx-0 justify-content-between w-100">
                                    <div>
                                      <div className="text-white">
                                        <strong>{v.name}</strong>
                                      </div>
                                      <div>
                                        <small className="noti-type">
                                          Club
                                        </small>
                                      </div>
                                    </div>
                                    <div className="row mx-0">
                                      {parseInt(v?.notification) +
                                        parseInt(v?.dm_unread_msg) +
                                        parseInt(v?.invitation_unread_msg) >
                                        0 && (
                                        <div className="font-bold bg-white mr-3 border rounded-full w-7 h-7 flex justify-center items-center">
                                          {parseInt(v?.notification) +
                                            parseInt(v?.dm_unread_msg) +
                                            parseInt(v?.invitation_unread_msg)}
                                        </div>
                                      )}
                                      <div>
                                        <i
                                          className="fas fa-chevron-right mr-2   d-inline"
                                          style={icon}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : null}
          </div>
        </Box>
      </Modal>
      <Toaster />
    </React.Fragment>
  );
}

// export default withRouter(SwitchAccount);
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getUserRollHandler: (user) => dispatch(getAllUserRoll(user)),
  switchAccountHandler: (user) =>
    dispatch(switchAccountDataForNotification(user)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotificationAccounts));
// 252
