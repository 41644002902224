import axios from "axios";
import { liabiltyUrl } from "../apiSheet";
import {
  ADD_LIABILITY_ERROR,
  ADD_LIABILITY_SUCCESS,
  GET_LIABILITY_ERROR,
  GET_LIABILITY_SUCCESS,
  UPDATE_LIABILITY_ERROR,
  UPDATE_LIABILITY_SUCCESS,
} from "../constant";

export const GetLiabilityDetails = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: liabiltyUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_LIABILITY_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_LIABILITY_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_LIABILITY_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: GET_LIABILITY_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const AddLiabilityDetails = ({ details, type }) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${liabiltyUrl}/${type}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_LIABILITY_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_LIABILITY_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADD_LIABILITY_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: ADD_LIABILITY_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const UpdateLiabilityDetails = ({ details, id }) => {
  return (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${liabiltyUrl}/${id}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_LIABILITY_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_LIABILITY_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPDATE_LIABILITY_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: UPDATE_LIABILITY_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};
