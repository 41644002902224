import {  GET_RECEIVE_REPORT_ERROR, GET_RECEIVE_REPORT_LOADING, GET_RECEIVE_REPORT_SUCCESS,RESET_RECEIVE_REPORT } from "../../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
  data:[]


};

export default function getReceiveReportReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RECEIVE_REPORT_LOADING:
      return { ...state, success: false, success: false };

    case GET_RECEIVE_REPORT_SUCCESS:
      return { ...state, loading: false, success: true, user: action.payload };
     
    case GET_RECEIVE_REPORT_ERROR:
      return { ...state, error: true, errors: action.errors };

    case RESET_RECEIVE_REPORT:
      return { ...state, error: false, success:false,loading:false};

    default:
      return state;
  }
}
