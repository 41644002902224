import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  getAllPaymentCode,
  getBulkRecipientAction,
  getEventsRegisterTeam,
  getPaidRecipient,
  getPaymentCode,
  paymentRequest,
} from "../../../service/action/userAction";
import { RESET_PAYMENT_REQUEST } from "../../../service/constants";
import classNames from "classnames";
import Spinner from "../../../Spinner/Spinner";
import { PAYMENT_CODE, REQ_TYPE } from "../../../Validation/organizer";

import { useHistory, useParams } from "react-router";
import { Form } from "react-bootstrap";

const NewPaymentReq = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [custompaymentcodedata, setcustompaymentcodedata] = useState();
  let [loading, setLoading] = useState(true);
  let [allCheck, setAllCheck] = useState(false);
  let [poolCheck, setPoolCheck] = useState(false);
  let [divisionCheck, setDivisionCheck] = useState(false);
  let [clubCheck, setClubCheck] = useState(false);
  let [parentCheck, setParentCheck] = useState(false);
  let [playerCheck, setPlayerCheck] = useState(false);
  let [teamCheck, setTeamCheck] = useState(false);
  const [customepayment, setcustomepayment] = useState();
  const getEvent = useSelector(
    (state) => state.getEventRegisterTeamReducer.user.event
  );
  const getPaymentCode = useSelector((state) => state.paymentcode);
  const notify = (msg) => toast(msg);
  const getRecipient = useSelector((state) => state.parentPlayerRecipient);
  const clubpending = useSelector(
    (state) => state.getPendingAmountReducer.user.pandingAmount
  );
  let [teamCheckBoxes, setTeamCheckBox] = useState([]);

  const initialState = {
    event: "",
    recipient: "",
    payterm: "",
    amount: "",
    recipient_type: "",
    invoice_code: "",
    pending_amount: "",
  };
  const [type, setType] = useState([]);
  const [state, setState] = useState({
    event: "",
    payment_code: "",
    request_type: "", // 0 event , 1 custom
    division: "",
    pool: "",
    player: "",
    parent: "",
    team: [],
    // custome_payment_code: "",
  });
  const [errors, setErrors] = useState({
    event: false,
    request_type: false,
    payment_code: false,
  });

  useEffect(() => {
    props.getEventHandler();
    props.getrecipientHandler();
    props.getPaymentCode({ id: 0 });
  }, []);

  const submitRequest = (e) => {
    e.preventDefault();
    if (state.request_type == "") {
      setErrors((prevState) => ({
        ...prevState,
        request_type: REQ_TYPE,
      }));
      document.getElementById("request_type").classList.add("error");
    }
    if (state.request_type == 1 && state.payment_code == "") {
      setErrors((prevState) => ({
        ...prevState,
        payment_code: PAYMENT_CODE,
      }));
      document.getElementById("payment_code").classList.add("error");
    }
    if (state.request_type != "") {
      let data = {
        request_type: state.request_type,
        club: clubCheckBoxes,
        parent: parentCheckBoxes,
        player: playerCheckBoxes,
        division: divisionCheckBoxes,
        pool: poolCheckBoxes,
        team: teamCheckBoxes,
      };
      if (state.event != "") data.event = state.event;
      if (state.payment_code != "") data.payment_code = state.payment_code;
      props.paymentRequesthandler(data);
    }
  };
  const [showPaymentCode, setShowPaymentCode] = useState(false);
  const [showEvent, setShowEvent] = useState(false);

  const eventHandler = (e) => {
    const { type, id, value, name } = e.target;
    if (name === "payment_code") {
      const data = custompaymentcodedata?.find((v, i) => {
        return v?._id === value;
      });
      setcustomepayment(data);
    }
    if (type === "checkbox") {
      setState((preve) => ({
        ...preve,
        team: teamCheckBoxes,
      }));
    }
    switch (name) {
      case "event":
        if (value != 0 || value != "#") {
          props.getrecipientHandler(value);
          setState((prevState) => ({
            ...prevState,
            event: value,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            event: "",
          }));
        }
        break;
      case "request_type":
        if (value === "") {
          setErrors((prevState) => ({
            ...prevState,
            request_type: REQ_TYPE,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            request_type: false,
          }));
          if (value == 1) {
            setShowPaymentCode(true);
            setShowEvent(false);
          } else {
            setShowPaymentCode(false);
            setShowEvent(true);
          }

          setState((prevState) => ({
            ...prevState,
            request_type: value,
          }));
          //
        }
      case "payment_code":
        if (value == "" && state.request_type == 1) {
          setErrors((prevState) => ({
            ...prevState,
            payment_code: REQ_TYPE,
          }));
          document.getElementById("payment_code").classList.add("error");
        } else {
          setState((prevState) => ({
            ...prevState,
            payment_code: value,
          }));
        }
      default:
    }
  };
  const clearState = () => {
    setState({ ...initialState });
  };

  useEffect(() => {
    setAllCheck(false);
    setDivisionCheck(false);
    setClubCheck(false);
    setTeamCheck(false);
    setParentCheck(false);
    setPoolCheck(false);
    setPlayerCheck(false);
    setState((pr) => ({
      ...pr,
      event: "",
    }));
  }, [state.request_type]);

  useEffect(() => {
    if (props.paymentRequest?.success) {
      resetData().then(clearState);
      setAllCheck(false);
      setDivisionCheck(false);
      setPoolCheck(false);
      setTeamCheck(false);
      setParentCheck(false);
      setPlayerCheck(false);
      history.push("/report");
    }
  }, [props.paymentRequest]);
  useEffect(() => {
    if (props.event?.success) {
      setLoading(false);
    }
  }, [props.event]);
  const [paymentcode, setPaymentcode] = useState([{}]);
  useEffect(() => {
    if (props.paymentcode.success) setPaymentcode(props.paymentcode.user);
  }, props.paymentcode);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (allCheck) {
      setType([0]);
      setDivisionCheck(false);
      setPoolCheck(false);
      setTeamCheck(false);
      setParentCheck(false);
      setPlayerCheck(false);
      setClubCheck(false);
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [allCheck]);
  useEffect(() => {
    if (poolCheck) {
      if (!type.find((el) => el == 3)) setType([...type, 3]);
    } else {
      const filtered = type.filter((obj) => {
        return obj !== 3;
      });
      setType(filtered);
    }
  }, [poolCheck]);
  useEffect(() => {
    if (divisionCheck) {
      if (!type.find((el) => el == 1)) setType([...type, 1]);
    } else {
      const filtered = type.filter((obj) => {
        return obj !== 1;
      });
      setType(filtered);
    }
  }, [divisionCheck]);
  useEffect(() => {
    if (teamCheck) {
      if (!type.find((el) => el == 2)) setType([...type, 2]);
    } else {
      const filtered = type.filter((obj) => {
        return obj !== 2;
      });
      setType(filtered);
    }
  }, [teamCheck]);

  let [poolCheckBoxes, setPoolCheckBox] = useState([]);
  let [divisionCheckBoxes, setDivisionCheckBox] = useState([]);
  let [clubCheckBoxes, setClubCheckBox] = useState([]);
  let [playerCheckBoxes, setPlayerCheckBox] = useState([]);
  let [parentCheckBoxes, setParentCheckBox] = useState([]);
  const [clubRecipient, setClubRecipient] = useState([]);
  const [playerRecipient, setPlayerRecipient] = useState([]);
  const [parentRecipient, setParentRecipient] = useState([]);
  const checkedAllcheckbox = (e) => {
    const { name } = e.target;
    switch (name) {
      case "sub-team":
        if (e.target.checked) setTeamCheckBox(team?.map((t) => t.id));
        else setTeamCheckBox([]);
        break;
      case "sub-pool":
        if (e.target.checked) setPoolCheckBox(pool?.map((t) => t._id));
        else setPoolCheckBox([]);
        break;
      case "sub-division":
        if (e.target.checked) setDivisionCheckBox(division?.map((t) => t._id));
        else setDivisionCheckBox([]);
        break;
      case "sub-club":
        if (e.target.checked) setClubCheckBox(clubRecipient?.map((t) => t._id));
        else setClubCheckBox([]);
        break;
      case "sub-player":
        if (e.target.checked)
          setPlayerCheckBox(playerRecipient?.map((t) => t._id));
        else setPlayerCheckBox([]);
        break;
      case "sub-parent":
        if (e.target.checked)
          setParentCheckBox(parentRecipient?.map((t) => t._id));
        else setParentCheckBox([]);
        break;
      default:
        break;
    }
  };
  //Team
  const handleOnChange = (event) => {
    if (teamCheckBoxes.find((id) => id == event.target.value)) {
      setTeamCheckBox(teamCheckBoxes.filter((id) => id != event.target.value));
    } else {
      setTeamCheckBox([...teamCheckBoxes, event.target.value]);
    }
  };
  //Division
  const handleDivisionOnChange = (event) => {
    if (divisionCheckBoxes.find((id) => id == event.target.value)) {
      setDivisionCheckBox(
        divisionCheckBoxes.filter((id) => id != event.target.value)
      );
    } else {
      setDivisionCheckBox([...divisionCheckBoxes, event.target.value]);
    }
  };
  //Pool
  const handlePoolOnChange = (event) => {
    if (poolCheckBoxes.find((id) => id == event.target.value)) {
      setPoolCheckBox(poolCheckBoxes.filter((id) => id != event.target.value));
    } else {
      setPoolCheckBox([...poolCheckBoxes, event.target.value]);
    }
  };
  //club
  const handleClubOnChange = (event) => {
    if (clubCheckBoxes.find((id) => id == event.target.value)) {
      setClubCheckBox(clubCheckBoxes.filter((id) => id != event.target.value));
    } else {
      setClubCheckBox([...clubCheckBoxes, event.target.value]);
    }
  };
  //player
  const handlePlayerOnChange = (event) => {
    if (playerCheckBoxes.find((id) => id == event.target.value)) {
      setPlayerCheckBox(
        playerCheckBoxes.filter((id) => id != event.target.value)
      );
    } else {
      setPlayerCheckBox([...playerCheckBoxes, event.target.value]);
    }
  };
  //parent
  const handleParentOnChange = (event) => {
    if (parentCheckBoxes.find((id) => id == event.target.value)) {
      setParentCheckBox(
        parentCheckBoxes.filter((id) => id != event.target.value)
      );
    } else {
      setParentCheckBox([...parentCheckBoxes, event.target.value]);
    }
  };

  useEffect(() => {
    if (type.length > 0) props.getBulkRecipient({ type, event: state.event });
  }, [type, state.event]);
  const [division, setDivisionArray] = useState([]);
  const [pool, setPoolArray] = useState([]);
  const [team, setTeamArray] = useState([]);
  useEffect(() => {
    if (props.recipient.success) {
      setDivisionArray(props.recipient.data.division);
      setPoolArray(props.recipient.data.pool);
      setTeamArray(props.recipient.data.team);
    }
  }, [props.recipient]);
  //display
  const displayRecipientBlock = (e) => {
    const { name, checked } = e.target;
    switch (name) {
      case "all":
        setAllCheck(checked);
        break;
      case "pool":
        setPoolCheck(checked);
        break;
      case "player":
        setPlayerCheck(checked);
        break;
      case "division":
        setDivisionCheck(checked);
        break;
      case "club":
        setClubCheck(checked);
        break;
      case "parent":
        setParentCheck(checked);
        break;
      case "team":
        setTeamCheck(checked);
        break;
      default:
    }
  };
  //setTimeout form reseting data after submit of form
  function resetData() {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }
  //clear data on submit.

  const [recipient, setRecipient] = useState([]);
  useEffect(() => {
    if (props.parentPlayerRecipient.success) {
      if (props.parentPlayerRecipient.data.data) {
        let clubs = [];
        let player = [];
        let parent = [];
        props.parentPlayerRecipient.data.data.map((v) => {
          if (v.type == 4) {
            clubs.push(v);
          } else if (v.type == 5) {
            player.push(v);
          } else if (v.type == 6) {
            parent.push(v);
          }
        });
        setClubRecipient(clubs);
        setPlayerRecipient(player);
        setParentRecipient(parent);
      } else setRecipient([]);
    }
  }, [props.parentPlayerRecipient]);

  useEffect(() => {
    props.getPaymentCodeHandler();
  }, []);

  useEffect(() => {
    if (props.getAllPayment?.success) {
      const data = props.getAllPayment?.user;
      setcustompaymentcodedata(data);
    }
  }, [props.getAllPayment]);

  useEffect(() => {
    setcustomepayment();
  }, [state.request_type]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Payment Request</h5>
          </div>
          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>
          {getEvent ? (
            <>
              <form>
                <div className="row col-md-6 px-0 mt-24 mx-0  ">
                  <div className="col-md-12">
                    <div className="form-group event-drp mb-3">
                      <select
                        className={classNames(
                          "form-control team-name payterm",
                          { error: errors.request_type }
                        )}
                        id="request_type"
                        name="request_type"
                        value={state.request_type}
                        onChange={eventHandler}
                      >
                        <option value="" disabled>
                          Payment Code Type*
                        </option>
                        <option value="0">Event</option>
                        <option value="1">Custom</option>
                      </select>
                      {errors.request_type && (
                        <span className="text-red small d-block">
                          * {errors.request_type}
                        </span>
                      )}
                    </div>

                    {state.request_type !== "" && (
                      <>
                        {" "}
                        <div
                          className={classNames(
                            "form-group event-drp event-type mb-3",
                            { "d-none": !showEvent }
                          )}
                        >
                          <label className="text-gray fs-13">
                            Event <span className="text-red fw-m">*</span>
                          </label>
                          <select
                            className={classNames(
                              "form-control team-name payterm",
                              { error: errors.event }
                            )}
                            id="event"
                            name="event"
                            value={state.event}
                            onChange={eventHandler}
                          >
                            <option value="" disabled>
                              Event Name*
                            </option>
                            {getEvent.map((v, i) => {
                              return (
                                <option value={v._id} key={v._id}>
                                  {v.event_name}
                                </option>
                              );
                            })}
                          </select>
                          {errors.event && (
                            <span className="text-red small d-block">
                              * {errors.event}
                            </span>
                          )}
                        </div>
                        {state.request_type === "0" && (
                          <div
                            className="mt-3 p-3 border border-5 text-gray"
                            style={{
                              backgroundColor: "#ffff",
                              overflowY: "auto",
                              height: "110px",
                              resize: "none",
                              textAlign: "justify",
                            }}
                          >
                            This payment request will use the Payment Code
                            selected in the event. If you would like to use a
                            different payment code, please select one below:
                          </div>
                        )}
                        {(state.request_type === "0" ||
                          state.request_type === "1") && (
                          <>
                            <div
                              className={classNames(
                                "form-group event-drp event-type mb-3"
                              )}
                            >
                              <select
                                className={classNames(
                                  "form-control team-name payterm",
                                  { error: errors.payment_code }
                                )}
                                id="payment_code"
                                name="payment_code"
                                value={state.payment_code}
                                onChange={eventHandler}
                              >
                                <option value="">
                                  Select Custom payment code *
                                </option>
                                {custompaymentcodedata?.map((v, i) => {
                                  if (v?.type == 1) {
                                    return (
                                      <option value={v._id} key={v._id}>
                                        {v.name}
                                      </option>
                                    );
                                  }
                                })}
                              </select>
                              {errors.event && (
                                <span className="text-red small d-block">
                                  * {errors.payment_code}
                                </span>
                              )}
                            </div>
                            {customepayment !== undefined && (
                              <>
                                <div className="text-gray mr-3">
                                  <div className="">
                                    Amount:{" "}
                                    {customepayment?.amount
                                      ? "$" + customepayment?.amount
                                      : "N/A"}
                                  </div>
                                  <div className="text-gray">
                                    Amount due:{" "}
                                    {customepayment?.dueAmount
                                      ? customepayment?.dueAmount + " %"
                                      : "N/A"}
                                  </div>
                                  <div className="text-gray">
                                    Payment due days:{" "}
                                    {customepayment?.payment_due
                                      ? customepayment?.payment_due
                                      : "N/A"}
                                  </div>
                                </div>
                                <hr />
                              </>
                            )}
                          </>
                        )}
                        {(state.event !== "" || state.request_type == "1") && (
                          <>
                            {" "}
                            <strong className="mb-4">Recipient</strong>
                            <div className="form-group row mx-0">
                              <div className="col-md-4">
                                <div className="mt-2 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    className="mr-2 bulk-checkbox"
                                    value="all"
                                    id="all"
                                    name="all"
                                    onChange={displayRecipientBlock}
                                  />
                                  <label htmlFor="all" className="mb-0">
                                    All
                                  </label>
                                </div>
                                <div className="mt-2 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    value="division"
                                    disabled={disabled}
                                    className="mr-2 bulk-checkbox"
                                    id="division"
                                    name="division"
                                    checked={divisionCheck}
                                    onChange={displayRecipientBlock}
                                  />
                                  <label htmlFor="division" className="mb-0">
                                    Division
                                  </label>
                                </div>
                                <div className="mt-2  d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    disabled={disabled}
                                    className="mr-2  bulk-checkbox"
                                    value="team"
                                    id="team"
                                    name="team"
                                    checked={teamCheck}
                                    onChange={displayRecipientBlock}
                                  />
                                  <label htmlFor="team" className="mb-0">
                                    Team
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mt-2 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    disabled={disabled}
                                    className="mr-2 bulk-checkbox"
                                    id="pool"
                                    value="pool"
                                    name="pool"
                                    onChange={displayRecipientBlock}
                                    checked={poolCheck}
                                  />
                                  <label htmlFor="pool" className="mb-0">
                                    Pool
                                  </label>
                                </div>
                                <div className="mt-2 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    disabled={disabled}
                                    className="mr-2 bulk-checkbox"
                                    id="club"
                                    name="club"
                                    value="club"
                                    checked={clubCheck}
                                    onChange={displayRecipientBlock}
                                  />
                                  <label htmlFor="club" className="mb-0">
                                    Club
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mt-2  d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    disabled={disabled}
                                    className="mr-2 bulk-checkbox"
                                    id="player"
                                    name="player"
                                    value="player"
                                    checked={playerCheck}
                                    onChange={displayRecipientBlock}
                                  />
                                  <label htmlFor="player" className="mb-0">
                                    Player
                                  </label>
                                </div>
                                <div className="mt-2 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    disabled={disabled}
                                    className="mr-2 bulk-checkbox"
                                    id="parent"
                                    name="parent"
                                    value="parent"
                                    checked={parentCheck}
                                    onChange={displayRecipientBlock}
                                  />
                                  <label htmlFor="parent" className="mb-0">
                                    Parent
                                  </label>
                                </div>
                              </div>
                            </div>
                            {(!!divisionCheck ||
                              !!teamCheck ||
                              !!poolCheck ||
                              !!clubCheck ||
                              !!playerCheck ||
                              !!parentCheck) && (
                              <>
                                <div
                                  className="mt-3 p-3 border border-5 text-gray"
                                  style={{
                                    backgroundColor: "#ffff",
                                    // overflowY: "auto",
                                    // resize: "none",
                                    // textAlign: "justify",
                                  }}
                                >
                                  This payment request will be sent to the{" "}
                                  {!!divisionCheck && "division, "}
                                  {!!teamCheck && "team, "}
                                  {!!poolCheck && "pool, "}
                                  {!!clubCheck && "club, "}
                                  {!!playerCheck && "player, "}
                                  {!!parentCheck && "parent, "} account
                                  manager(s) only
                                </div>
                                <div className="mt-3 div-tab border px-3 pb-3">
                                  {!!divisionCheck && division.length > 0 && (
                                    <div className="pt-3">
                                      <input
                                        type="checkbox"
                                        className="mr-2 bulk-checkbox"
                                        id="sub-division"
                                        checked={
                                          division.length ==
                                          divisionCheckBoxes.length
                                        }
                                        onChange={checkedAllcheckbox}
                                        name="sub-division"
                                      />
                                      <label
                                        htmlFor="sub-division"
                                        className="mb-0"
                                      >
                                        <strong>Division</strong>
                                      </label>

                                      {division.map((v) => {
                                        return (
                                          <div className="mt-1 ml-3 d-flex align-items-center text-gray">
                                            <input
                                              type="checkbox"
                                              className="mr-2 bulk-checkbox"
                                              id={v._id + "division"}
                                              value={v._id}
                                              onChange={(e) =>
                                                handleDivisionOnChange(e)
                                              }
                                              name={v._id + "division"}
                                              checked={
                                                divisionCheckBoxes.find(
                                                  (id) => id == v._id
                                                ) != undefined
                                              }
                                            />
                                            <label
                                              htmlFor={v._id + "division"}
                                              className="mb-0"
                                            >
                                              {v.division_name}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                  {!!teamCheck && team.length > 0 && (
                                    <div className="pt-3">
                                      <input
                                        type="checkbox"
                                        className="mr-2 bulk-checkbox"
                                        checked={
                                          team.length == teamCheckBoxes.length
                                        }
                                        onChange={checkedAllcheckbox}
                                        id="sub-team"
                                        name="sub-team"
                                      />
                                      <label
                                        htmlFor="sub-team"
                                        className="mb-0"
                                      >
                                        <strong>Team</strong>
                                      </label>

                                      {team.map((v, index) => {
                                        return (
                                          <div
                                            className="mt-1 ml-3 d-flex align-items-center text-gray"
                                            key={index}
                                          >
                                            <input
                                              type="checkbox"
                                              className="mr-2 bulk-checkbox"
                                              onChange={(e) =>
                                                handleOnChange(e)
                                              }
                                              checked={
                                                teamCheckBoxes.find(
                                                  (id) => id == v.id
                                                ) != undefined
                                              }
                                              id={v.id + "team"}
                                              value={v.id}
                                              name="team"
                                            />
                                            <label
                                              htmlFor={v.id + "team"}
                                              className="mb-0"
                                            >
                                              {v.name}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                  {!!poolCheck && pool.length > 0 && (
                                    <div className="pt-3">
                                      <input
                                        type="checkbox"
                                        className="mr-2 bulk-checkbox"
                                        id="sub-pool"
                                        checked={
                                          pool.length == poolCheckBoxes.length
                                        }
                                        onChange={checkedAllcheckbox}
                                        name="sub-pool"
                                      />
                                      <label
                                        htmlFor="sub-pool"
                                        className="mb-0"
                                      >
                                        <strong> Pool </strong>
                                      </label>

                                      {pool.map((v) => {
                                        return (
                                          <div className="mt-1 ml-3 d-flex align-items-center text-gray">
                                            <input
                                              type="checkbox"
                                              className="mr-2 bulk-checkbox"
                                              id={v._id}
                                              value={v._id}
                                              onChange={(e) =>
                                                handlePoolOnChange(e)
                                              }
                                              checked={
                                                poolCheckBoxes.find(
                                                  (id) => id == v._id
                                                ) != undefined
                                              }
                                              name={v._id + "pool"}
                                            />
                                            <label
                                              htmlFor={v._id + "pool"}
                                              className="mb-0"
                                            >
                                              {v.pool_name}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                  {!!clubCheck && clubRecipient.length > 0 && (
                                    <div className="pt-3">
                                      <input
                                        type="checkbox"
                                        className="mr-2 bulk-checkbox"
                                        checked={
                                          clubRecipient.length ==
                                          clubCheckBoxes.length
                                        }
                                        onChange={checkedAllcheckbox}
                                        id="sub-club"
                                        name="sub-club"
                                      />
                                      <label
                                        htmlFor="sub-club"
                                        className="mb-0"
                                      >
                                        <strong>club</strong>
                                      </label>

                                      {clubRecipient.map((v, index) => {
                                        return (
                                          <div
                                            className="mt-1 ml-3 d-flex align-items-center text-gray"
                                            key={index}
                                          >
                                            <input
                                              type="checkbox"
                                              className="mr-2 bulk-checkbox"
                                              onChange={(e) =>
                                                handleClubOnChange(e)
                                              }
                                              checked={
                                                clubCheckBoxes.find(
                                                  (id) => id == v._id
                                                ) != undefined
                                              }
                                              id={v._id + "club"}
                                              value={v._id}
                                              name="club"
                                            />
                                            <label
                                              htmlFor={v._id + "club"}
                                              className="mb-0"
                                            >
                                              {v.name}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                  {!!playerCheck &&
                                    playerRecipient.length > 0 && (
                                      <div className="pt-3">
                                        <input
                                          type="checkbox"
                                          className="mr-2 bulk-checkbox"
                                          checked={
                                            playerRecipient.length ==
                                            playerCheckBoxes.length
                                          }
                                          onChange={checkedAllcheckbox}
                                          id="sub-player"
                                          name="sub-player"
                                        />
                                        <label
                                          htmlFor="sub-player "
                                          className="mb-0"
                                        >
                                          <strong> player </strong>
                                        </label>

                                        {playerRecipient.map((v, index) => {
                                          return (
                                            <div
                                              className="mt-1 ml-3 d-flex align-items-center text-gray"
                                              key={index}
                                            >
                                              <input
                                                type="checkbox"
                                                className="mr-2 bulk-checkbox"
                                                onChange={(e) =>
                                                  handlePlayerOnChange(e)
                                                }
                                                checked={
                                                  playerCheckBoxes.find(
                                                    (id) => id == v._id
                                                  ) != undefined
                                                }
                                                id={v._id + "player"}
                                                value={v._id}
                                                name="player"
                                              />
                                              <label
                                                htmlFor={v._id + "player"}
                                                className="mb-0"
                                              >
                                                {v.name}
                                              </label>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  {!!parentCheck &&
                                    parentRecipient.length > 0 && (
                                      <div className="pt-3">
                                        <input
                                          type="checkbox"
                                          className="mr-2 bulk-checkbox"
                                          checked={
                                            parentRecipient.length ==
                                            parentCheckBoxes.length
                                          }
                                          onChange={checkedAllcheckbox}
                                          id="sub-parent"
                                          name="sub-parent"
                                        />
                                        <label
                                          htmlFor="sub-parent"
                                          className="mb-0"
                                        >
                                          <strong> parent </strong>
                                        </label>

                                        {parentRecipient.map((v, index) => {
                                          return (
                                            <div
                                              className="mt-1 ml-3 d-flex align-items-center text-gray"
                                              key={index}
                                            >
                                              <input
                                                type="checkbox"
                                                className="mr-2 bulk-checkbox"
                                                onChange={(e) =>
                                                  handleParentOnChange(e)
                                                }
                                                checked={
                                                  parentCheckBoxes.find(
                                                    (id) => id == v._id
                                                  ) != undefined
                                                }
                                                id={v._id + "parent"}
                                                value={v.id}
                                                name="parent"
                                              />
                                              <label
                                                htmlFor={v._id + "parent"}
                                                className="mb-0"
                                              >
                                                {v.name}
                                              </label>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                </div>
                              </>
                            )}
                          </>
                        )}
                        <div className="mt-3">
                          <Form.Group className="mb-3 ">
                            <Form.Control
                              type="text"
                              placeholder="Enter Note"
                              name="note"
                              label="note"
                              // value={state.note}
                              // disabled={isContactAvailable}
                              // onChange={handleInput}
                              id="note"
                              as="textarea"
                              rows={3}
                              // className={classNames("mb-3", {
                              //   requireField: error.note,
                              // })}
                            />
                          </Form.Group>
                        </div>
                      </>
                    )}

                    <>
                      <div className="mt-24">
                        <a
                          className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                          onClick={submitRequest}
                        >
                          Send
                        </a>
                      </div>
                    </>

                    <div
                      className={classNames("form-group event-drp mb-3", {
                        "d-none": !showPaymentCode,
                      })}
                    ></div>

                    <Toaster />
                  </div>
                </div>
              </form>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
// export default withRouter(CreateInvoice)
const mapStateToProp = (state) => ({
  paymentRequest: state.paymentRequestReducer,
  event: state.getEventRegisterTeamReducer,
  paymentcode: state.getPaymentcodeReducer,
  recipient: state.getBulkRecipientReducer,
  parentPlayerRecipient: state.getPaidRecipientReducer,
  getAllPayment: state.getAllPaymentCodeReducer,
});
const mapDispatchToProps = (dispatch) => ({
  getEventHandler: (user) => dispatch(getEventsRegisterTeam(user)),
  getPaymentCodeHandler: (user) => dispatch(getAllPaymentCode(user)),
  getrecipientHandler: (user) => dispatch(getPaidRecipient(user)),
  paymentRequesthandler: (user) => dispatch(paymentRequest(user)),
  getBulkRecipient: (type) => dispatch(getBulkRecipientAction(type)),
  getPaymentCode: (user) => dispatch(getPaymentCode(user)),
  resetPaymentRequestHandler: () => dispatch({ type: RESET_PAYMENT_REQUEST }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(NewPaymentReq));
