import { ORG_SWITCH_ACCESS_ERROR, ORG_SWITCH_ACCESS_LOADING, ORG_SWITCH_ACCESS_SUCCESS, RESET_ORG_SWITCH_ACCESS } from "../constants";

  
  let initialState = {
    loading: false,
    user: [],
    error: false,
    errors: [],
    success: false,
    payload: {},
  };
  
  export default function switchAccessReducer(state = initialState, action) {
    switch (action.type) {
      case ORG_SWITCH_ACCESS_LOADING:
        return { ...state, error: false, loading: true, success: false };
  
      case ORG_SWITCH_ACCESS_SUCCESS:
        return {
          ...state,
          error: false,
          loading: false,
          success: true,
          message: action.msg,
        };
  
      case ORG_SWITCH_ACCESS_ERROR:
        return {
          ...state,
          error: true,
          loading: false,
          success: false,
          errors: action.errors,
          msg: action.errors,
        };
  
      case RESET_ORG_SWITCH_ACCESS:
        return {
          ...state,
          error: false,
          loading: false,
          success: false,
          errors: [],
          user: [{}],
        };
      default:
        return state;
    }
  }
  