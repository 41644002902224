import { GET_CITY_LOADING, GET_CITY_SUCCESS, GET_CITY_ERROR } from "../../constants";

let initialState={
    error:false,
    loading:false,
    success:false,
    errors:[],
    citys:[]
}

export default function(state=initialState,action){
    switch(action.type){
        case GET_CITY_LOADING:
            return {...state,error:false,loading:true,success:false}
        case GET_CITY_SUCCESS:
            return {...state,error:false,loading:false,success:true,citys:action.payload}
        case GET_CITY_ERROR:
            return {...state,error:true,loading:false,success:false}
        default:
            return state;
    }
}