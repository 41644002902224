import { GET_EVENT_DIVISION_ERROR, GET_EVENT_DIVISION_LOADING, GET_EVENT_DIVISION_SUCCESS } from "../../../../constants";

let initialState={
    loading:false,
    error:false,
    success:false,
    error:[],
    user:[]
}
export default function geteventDivisionReducer(state=initialState,action){
    switch(action.type){
        case GET_EVENT_DIVISION_LOADING:
            return {...state,loading:true,error:false,loading:false,success:false}
        case GET_EVENT_DIVISION_SUCCESS:
            return {...state,loading:false,error:false,success:true,user:action.payload}
        case GET_EVENT_DIVISION_ERROR:
            return {...state,loading:false,error:true,success:false,errors:[action.errors]}
        default :
            return state;
    }
}