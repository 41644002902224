import { PLAYER_DETAILS_ERROR, PLAYER_DETAILS_LOADING, PLAYER_DETAILS_SUCCESS, RESET_PLAYER_DETAILS } from "../../constants";

let initialState = {
 loading: false,
 data: {},
 error: false,
 errors: [],
 success: false,


};

export default function playerDetailsReducer(state = initialState, action) {
 switch (action.type) {

  case PLAYER_DETAILS_LOADING:
   return { ...state, error: false, loading: true, success: false };

  case PLAYER_DETAILS_SUCCESS:
   return { ...state, error: false, loading: false, success: true, data: action.data };

  case PLAYER_DETAILS_ERROR:
   return { ...state, error: true, loading: false, success: false, errors: action.errors }

  case RESET_PLAYER_DETAILS:
   return { ...state, error: false, loading: false, success: false, errors: [] }

  default:
   return state;
 }
}


