import React, { useState ,useEffect} from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { createSeason } from '../../service/action/userAction';
import { RESET_CREATE_PAYMENT_CODE } from '../../service/constants';
import classNames from 'classnames';
import { createField } from '../../service/action/Club/clubAction';
import { toast } from 'react-toastify';
import Toaster from '../../Toaster/Toaster';
import BreadcrumbHistory from '../../BreadcumbHistory/BreadcrumbHistory';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';



const CreateField = (props) => {

    const history=useHistory()
    const location = useLocation()

    const [state, setState] = useState({
        season_name: ""
    })

    const [errors, setErrors] = useState({

        season_name: false

    })

    const initialState = {
        season_name:""
    };

    
    //setTimeout form reseting data after submit of form
    function resetData() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    }

    //clear data on submit.
    const clearState = () => {
        setState({ ...initialState });
    };


    // const [checked, setChecked] = useState(false)
    // const onClickCheck = () => setChecked(!checked)
    const notify = (msg) => toast(msg);

    const fieldMsg = useSelector(state => state.createPaymentCodeReducer.message)

    const eventHandler = (e) => {
        const { id, value, name } = e.target


        setState(prevState => ({
            ...prevState,
            [id]: value
            // [e.target.name]: e.target.value.trim()
        }))
        switch (name) {
            case "season_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        season_name: "Field name can not be empty"
                    }))

                    document.getElementById("season_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        season_name: false
                    }))

                    document.getElementById("season_name").classList.remove("error")
                }

                break;

            default:
        }
    }


    const submitRequest = (e) => {
        e.preventDefault();


        if (state.season_name == "") {
            setErrors(prevState => ({
                ...prevState,
                season_name: "Field name can not be empty"
            }))
            document.getElementById("season_name").classList.add("error")
        }

        if (state.season_name !== "") {
            props.createFieldhandler({
                name: state.season_name
            })
            // if (props.submitRequest?.success) {
            //     notify("Data submitted successfully.")
            // }
            // resetData().then(clearState);

        }


        // alert(state.checkbox)
    }
    // if(props.submit?.success){
    //         notify("Season submitted successfully.")
    //         props.resetCreateSeasonReducer()
    //         return <Redirect to="/season" />;
    //     }

    useEffect(() => {
        // alert("a")
        if (props.submit?.success) {
            // alert("b")
            resetData().then(clearState);
            // notify(fieldMsg.message)
            // props.resetCreateFieldReducer()


            history.push(`/field`);
        }

    }, [props.submit])

    return (
        <>
            <div className="main-panel">
            <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />

                <div className="content-wrapper">
                    <div className="text-left">
                        <h5 className="dash-head">Create New Field</h5>
                    </div>

                    <form >
                        <div className="row mx-0">
                            <div className="col-md-4 col-xl-4">
                                <div className="mt-24">
                                    <div className="text-left">
                                        <label className="text-gray fs-13">Field Name <span className="text-red fw-m">*</span></label>
                                        <input type="text" className="form-control team-name" id="season_name" name="season_name" value={state.season_name} placeholder="Field Name *" required onChange={eventHandler} />

                                        {errors.season_name &&
                                            <span className='text-red small d-block'>* {errors.season_name}</span>}

                                    </div>

                                    <div className="mt-3 text-left">
                                        <a className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitRequest} >Submit</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                    <Toaster />
                </div>
            </div>

        </>
    )
}

// export default withRouter(CreateInvoice)



const mapStateToProp = state => ({
    submit: state.createPaymentCodeReducer,

})

const mapDispatchToProps = dispatch => ({
    createFieldhandler: user => dispatch(createField(user)),
    resetCreateFieldReducer:()=>dispatch({type:RESET_CREATE_PAYMENT_CODE})



})
export default connect(mapStateToProp, mapDispatchToProps)(CreateField);