import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
const EventsList = ({ AllEvent }) => {
  return (
    <>
      {AllEvent ? (
        <div className="text-left">
          {AllEvent.length !== 0 ? (
            AllEvent.filter((item, index) => index < 4).map((v, i) => {
              // const formatYmd = date => date.toISOString().slice(0, 10)
              return (
                <div className="basic-info" key={v._id}>
                  <div className="dashboard-img">
                    <img
                      src={
                        v.event_image !== ""
                          ? v.event_image
                          : "./images/pch-images/pch.svg"
                      }
                    />
                  </div>
                  <div className="ml-12">
                    <Link
                      className="heading text-decoration-none"
                      to={
                        v.type === "club"
                          ? `/event-details/${v._id}`
                          : `/events/${v._id}`
                      }
                    >
                      {v.event_name}
                    </Link>
                    {/* <span className="noti-type">Date   : {formatYmd(new Date(v.event_from_date))}</span> */}
                    <span className="noti-type">
                      Date:{" "}
                      <Moment
                        date={new Date(v.event_start_date)}
                        format="MM/DD/YYYY"
                      />
                    </span>
                    {/* <span className="noti-type">Status: {v.status === 0 ? "Closed" : "Open"}</span> */}
                    <span className="noti-type">
                      Registration Status:{" "}
                      {moment(v.registration_end_date) >= moment()
                        ? "Open"
                        : "Close"}
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <span className="noti-type">No events to display</span>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default EventsList;
