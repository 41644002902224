import axios from "axios";
import {
  AddConfigUrl,
  ChangeStatusUrl,
  CreateOrgUrl,
  DeleteClubUrl,
  DeleteCoachUrl,
  DeleteConfigUrl,
  DeleteOrgUrl,
  DeleteParent_GaurdianListUrl,
  DeletePlayerUrl,
  DeleteStaff_Url,
  GetClubListUrl,
  GetCoachListUrl,
  GetConfigUrl,
  GetOrgListUrl,
  GetParent_GaurdianListUrl,
  GetPlayerListUrl,
  GetStaffListUrl,
  OrgLogin_Url,
  UpdateClubUrl,
  UpdateCoachUrl,
  UpdateConfigUrl,
  UpdateOrgUrl,
  UpdateParent_GaurdianListUrl,
  UpdatePlayerUrl,
  UpdateStaff_Url,
  UserStatusUrl,
  addSuperAdminUrl,
  changePassword_Url,
  dashboardUrl,
  deleteSuperadminList_Url,
  getSuperadminList_Url,
  updateSuperadminList_Url,
  userDetails_Url,
} from "../apiSheet";
import {
  ADD_CONFIG_ERROR,
  ADD_CONFIG_SUCCESS,
  ADD_SUB_SUPER_ADMIN_ERROR,
  ADD_SUB_SUPER_ADMIN_SUCCESS,
  ADD_SUPER_ADMIN_ERROR,
  ADD_SUPER_ADMIN_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_STATUS_ERROR,
  CHANGE_STATUS_SUCCESS,
  CREATE_CLUB_ERROR,
  CREATE_CLUB_SUCCESS,
  CREATE_COACH_ERROR,
  CREATE_COACH_SUCCESS,
  CREATE_ORG_ERROR,
  CREATE_ORG_SUCCESS,
  CREATE_PARENT_ERROR,
  CREATE_PARENT_SUCCESS,
  CREATE_PLAYER_ERROR,
  CREATE_PLAYER_SUCCESS,
  CREATE_STAFF_ERROR,
  CREATE_STAFF_SUCCESS,
  DELETE_CLUB_ERROR,
  DELETE_CLUB_SUCCESS,
  DELETE_COACH_ERROR,
  DELETE_COACH_SUCCESS,
  DELETE_CONFIG_ERROR,
  DELETE_CONFIG_SUCCESS,
  DELETE_ORG_ERROR,
  DELETE_ORG_SUCCESS,
  DELETE_PARENT_ERROR,
  DELETE_PARENT_SUCCESS,
  DELETE_PLAYER_ERROR,
  DELETE_PLAYER_SUCCESS,
  DELETE_STAFF_ERROR,
  DELETE_STAFF_SUCCESS,
  DELETE_SUB_SUPERADMIN_LIST_ERROR,
  DELETE_SUB_SUPERADMIN_LIST_SUCCESS,
  DELETE_SUPERADMIN_LIST_ERROR,
  DELETE_SUPERADMIN_LIST_SUCCESS,
  GET_CLUB_LIST_ERROR,
  GET_CLUB_LIST_SUCCESS,
  GET_COACHES_LIST_ERROR,
  GET_COACHES_LIST_SUCCESS,
  GET_CONFIG_ERROR,
  GET_CONFIG_SUCCESS,
  GET_DASHBOARD_DETAILS_ERROR,
  GET_DASHBOARD_DETAILS_SUCCESS,
  GET_ORG_LIST_ERROR,
  GET_ORG_LIST_SUCCESS,
  GET_PARENT_LIST_ERROR,
  GET_PARENT_LIST_SUCCESS,
  GET_PLAYER_LIST_ERROR,
  GET_PLAYER_LIST_SUCCESS,
  GET_STAFF_LIST_ERROR,
  GET_STAFF_LIST_SUCCESS,
  GET_SUB_SUPERADMIN_LIST_ERROR,
  GET_SUB_SUPERADMIN_LIST_SUCCESS,
  GET_SUPERADMIN_LIST_ERROR,
  GET_SUPERADMIN_LIST_SUCCESS,
  GET_USER_DETAILS_ERROR,
  GET_USER_DETAILS_SUCCESS,
  ORG_LOGIN_BTN_ERROR,
  ORG_LOGIN_BTN_SUCCESS,
  UPDATE_CLUB_ERROR,
  UPDATE_CLUB_SUCCESS,
  UPDATE_COACH_ERROR,
  UPDATE_COACH_SUCCESS,
  UPDATE_CONFIG_ERROR,
  UPDATE_CONFIG_SUCCESS,
  UPDATE_ORG_ERROR,
  UPDATE_ORG_SUCCESS,
  UPDATE_PARENT_ERROR,
  UPDATE_PARENT_SUCCESS,
  UPDATE_PLAYER_ERROR,
  UPDATE_PLAYER_SUCCESS,
  UPDATE_STAFF_ERROR,
  UPDATE_STAFF_SUCCESS,
  UPDATE_SUB_SUPERADMIN_LIST_ERROR,
  UPDATE_SUB_SUPERADMIN_LIST_SUCCESS,
  UPDATE_SUPERADMIN_LIST_ERROR,
  UPDATE_SUPERADMIN_LIST_SUCCESS,
  USER_STATUS_ERROR,
  USER_STATUS_SUCCESS,
} from "../constant";

export const getUserDetails = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: userDetails_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_USER_DETAILS_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_USER_DETAILS_ERROR,
            errorMsg: error.message == "Network Error" ? error.message : error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_USER_DETAILS_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: GET_USER_DETAILS_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};
export const GetDashBoardDetails = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: dashboardUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_DASHBOARD_DETAILS_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_DASHBOARD_DETAILS_ERROR,
            errorMsg: error.message == "Network Error" ? error.message : error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_DASHBOARD_DETAILS_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: GET_DASHBOARD_DETAILS_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};
export const getOrgList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: GetOrgListUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_ORG_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_ORG_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_ORG_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: GET_ORG_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const creatOrg = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: CreateOrgUrl,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_ORG_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CREATE_ORG_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_ORG_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: CREATE_ORG_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const updateOrgAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: UpdateOrgUrl + `/${details.OrgID}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_ORG_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_ORG_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_ORG_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: UPDATE_ORG_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const deleteOrgAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${DeleteOrgUrl}/${details.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_ORG_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_ORG_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_ORG_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: DELETE_ORG_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const updateClubAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: UpdateClubUrl + `/${details.ClubID}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_CLUB_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_CLUB_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_CLUB_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: UPDATE_CLUB_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const deleteClubAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${DeleteClubUrl}/${details.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_CLUB_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_CLUB_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_CLUB_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: DELETE_CLUB_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const getClubList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: GetClubListUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_CLUB_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_CLUB_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_CLUB_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: GET_CLUB_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};
export const createClub = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: CreateOrgUrl,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_CLUB_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CREATE_CLUB_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_CLUB_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: CREATE_CLUB_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const getStaffList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: GetStaffListUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_STAFF_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_STAFF_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_STAFF_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: GET_STAFF_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};
export const getCoachList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: GetCoachListUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_COACHES_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_COACHES_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_COACHES_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: GET_COACHES_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};
export const getPlayerList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: GetPlayerListUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_PLAYER_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_PLAYER_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_PLAYER_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: GET_PLAYER_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};
export const getParentList = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: GetParent_GaurdianListUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_PARENT_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_PARENT_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_PARENT_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: GET_PARENT_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

// .,....................................  Org login btn  .......................................

export const OrgLoginAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: OrgLogin_Url,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ORG_LOGIN_BTN_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ORG_LOGIN_BTN_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ORG_LOGIN_BTN_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: ORG_LOGIN_BTN_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

// .............................................   Staff Action  ......................................

export const CreateStaffAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: CreateOrgUrl,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_STAFF_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CREATE_STAFF_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_STAFF_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: CREATE_STAFF_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const UpdateStaffAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: UpdateStaff_Url + `/${details.StaffID}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_STAFF_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_STAFF_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_STAFF_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: UPDATE_STAFF_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const DeleteStaffAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${DeleteStaff_Url}/${details.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_STAFF_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_STAFF_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_STAFF_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: DELETE_STAFF_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

// ............................  Coach Action  ...............................

export const CreateCoachAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: CreateOrgUrl,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_COACH_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CREATE_COACH_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_COACH_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: CREATE_COACH_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const UpdateCoachAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${UpdateCoachUrl}/${details.CoachID}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_COACH_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_COACH_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_COACH_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: UPDATE_COACH_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const DeleteCoachAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${DeleteCoachUrl}/${details.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_COACH_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_COACH_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_COACH_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: DELETE_COACH_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

// ............................  parent Action  ...............................

export const CreateParentAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: CreateOrgUrl,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_PARENT_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CREATE_PARENT_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_PARENT_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: CREATE_PARENT_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const UpdateParentAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: UpdateParent_GaurdianListUrl + `/${details.ParentID}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_PARENT_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_PARENT_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_PARENT_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: UPDATE_PARENT_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const DeleteParentAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${DeleteParent_GaurdianListUrl}/${details.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_PARENT_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_PARENT_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_PARENT_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: DELETE_PARENT_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

// ............................  player Action  ...............................

export const CreatePlayerAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: CreateOrgUrl,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_PLAYER_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CREATE_PLAYER_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message; // for error player must be 18 years old
          dispatch({
            type: CREATE_PLAYER_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: CREATE_PLAYER_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const UpdatePlayerAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: UpdatePlayerUrl + `/${details.ParentID}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_PLAYER_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_PLAYER_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_PLAYER_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: UPDATE_PLAYER_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const DeletePlayerAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${DeletePlayerUrl}/${details.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_PLAYER_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_PLAYER_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_PLAYER_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: DELETE_PLAYER_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};
export const changePasswordAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${changePassword_Url}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error?.response;
        if (!error.response) {
          dispatch({
            type: CHANGE_PASSWORD_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;

          dispatch({
            type: CHANGE_PASSWORD_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: CHANGE_PASSWORD_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const createSuperAdmin = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: addSuperAdminUrl,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_SUPER_ADMIN_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_SUPER_ADMIN_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ADD_SUPER_ADMIN_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: ADD_SUPER_ADMIN_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const getSuperadminList = (role) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getSuperadminList_Url}/${role}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_SUPERADMIN_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SUPERADMIN_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: GET_SUPERADMIN_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: GET_SUPERADMIN_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const updateSuperadminList = ({ details, id }) => {
  return (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${updateSuperadminList_Url}/${id}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_SUPERADMIN_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_SUPERADMIN_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_SUPERADMIN_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: UPDATE_SUPERADMIN_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const deleteSuperadminList = (id) => {
  return (dispatch) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deleteSuperadminList_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_SUPERADMIN_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_SUPERADMIN_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_SUPERADMIN_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: DELETE_SUPERADMIN_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const createSubSuperAdmin = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: addSuperAdminUrl,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_SUB_SUPER_ADMIN_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_SUB_SUPER_ADMIN_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ADD_SUB_SUPER_ADMIN_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: ADD_SUB_SUPER_ADMIN_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const getSubSuperadminList = (role) => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${getSuperadminList_Url}/${role}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_SUB_SUPERADMIN_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SUB_SUPERADMIN_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: GET_SUB_SUPERADMIN_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: GET_SUB_SUPERADMIN_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const updateSubSuperadminList = ({ details, id }) => {
  return (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${updateSuperadminList_Url}/${id}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_SUB_SUPERADMIN_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_SUB_SUPERADMIN_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_SUB_SUPERADMIN_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: UPDATE_SUB_SUPERADMIN_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const deleteSubSuperadminList = (id) => {
  return (dispatch) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${deleteSuperadminList_Url}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_SUB_SUPERADMIN_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_SUB_SUPERADMIN_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_SUB_SUPERADMIN_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: DELETE_SUB_SUPERADMIN_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const ChangeStatusAction = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: ChangeStatusUrl + `/${details}`,
      // data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CHANGE_STATUS_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: CHANGE_STATUS_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CHANGE_STATUS_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: CHANGE_STATUS_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const changeUserStatus = ({ type, id }) => {
  return (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${UserStatusUrl}/${type}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: USER_STATUS_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: USER_STATUS_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: USER_STATUS_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: USER_STATUS_ERROR,
            errorMsg: "UnAuthorized",
          });
        } else {
          dispatch({
            type: USER_STATUS_ERROR,
            errorMsg: data.data,
          });
        }
      });
  };
};

export const getConfig = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: GetConfigUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_CONFIG_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_CONFIG_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: GET_CONFIG_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: GET_CONFIG_ERROR,
            errorMsg: "UnAuthorized",
          });
        } else {
          dispatch({
            type: GET_CONFIG_ERROR,
            errorMsg: data.data,
          });
        }
      });
  };
};

export const addConfig = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: AddConfigUrl,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_CONFIG_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_CONFIG_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ADD_CONFIG_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: ADD_CONFIG_ERROR,
            errorMsg: "UnAuthorized",
          });
        } else {
          dispatch({
            type: ADD_CONFIG_ERROR,
            errorMsg: data.data,
          });
        }
      });
  };
};

export const deleteConfig = (id) => {
  return (dispatch) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${DeleteConfigUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_CONFIG_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_CONFIG_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_CONFIG_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: DELETE_CONFIG_ERROR,
            errorMsg: "UnAuthorized",
          });
        } else {
          dispatch({
            type: DELETE_CONFIG_ERROR,
            errorMsg: data.data,
          });
        }
      });
  };
};

export const updateConfig = ({ details, id }) => {
  return (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${UpdateConfigUrl}/${id}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_CONFIG_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_CONFIG_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_CONFIG_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: UPDATE_CONFIG_ERROR,
            errorMsg: "UnAuthorized",
          });
        } else {
          dispatch({
            type: UPDATE_CONFIG_ERROR,
            errorMsg: data.data,
          });
        }
      });
  };
};
