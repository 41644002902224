import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import moment from "moment";

const style = {
  position: "absolute",
  bottom: "0%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const PaymentTypeModal = ({
  open,
  handleClose,
  selectedValue,
  handleChange,
  paymentProcess,
  paymentDetails,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="rounded-md"
      >
        <Box sx={style} className="space-y-2">
          <div
            className={
              selectedValue === "a"
                ? "border-2 border-[#189AD8] px-4 py-2 rounded-md"
                : "border border-[#189AD8] px-4 py-2 rounded-md"
            }
          >
            <div className="flex items-center flex-row">
              <Radio
                checked={selectedValue === "a"}
                onChange={handleChange}
                value="a"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
              <h2 className="text-lg mb-2 mr-4">Pay full amount</h2>
            </div>
            <div className="ml-4">
              <h2>${paymentDetails?.total_amount?.toFixed(2)}</h2>
            </div>
          </div>
          {paymentDetails?.initial_total_amount != 0 && (
            <div
              className={
                selectedValue === "b"
                  ? "border-2 border-[#189AD8] px-4 py-2 rounded-md"
                  : "border border-[#189AD8] px-4 py-2 rounded-md"
              }
            >
              <div className="flex items-center flex-row">
                <Radio
                  checked={selectedValue === "b"}
                  onChange={handleChange}
                  value="b"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "B" }}
                />
                <h2 className="text-lg mb-2 mr-4">Pay initial amount</h2>
              </div>
              <div className="ml-4">
                <h2>${paymentDetails?.initial_total_amount?.toFixed(2)}</h2>
                <p>
                  Remaining amount $
                  {paymentDetails?.remaining_total_amount?.toFixed(2)} will be
                  auto debited from payment on the day{" "}
                  {moment(paymentDetails?.auto_debit_date).format("MM/DD/yyyy")}
                </p>
              </div>
            </div>
          )}

          <div className="w-full flex justify-center items-center mt-5">
            <Button
              variant="contained"
              sx={{ width: 300, textTransform: "capitalize" }}
              onClick={paymentProcess}
            >
              Proceed
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PaymentTypeModal;
