import { GET_EVENT_DETAILS_REGISTRATION_ERROR, GET_EVENT_DETAILS_REGISTRATION_LOADING, GET_EVENT_DETAILS_REGISTRATION_SUCCESS } from "../../../constants";

let initialState={
    loading:false,
    error:false,
    success:false,
    error:[],
    data:[]
}
export default function getEventDetailsForRegistrationReducer(state=initialState,action){
    switch(action.type){
        case GET_EVENT_DETAILS_REGISTRATION_LOADING:
            return {...state,loading:true,error:false,loading:false,success:false}
        case GET_EVENT_DETAILS_REGISTRATION_SUCCESS:
            return {...state,loading:false,error:false,success:true,data:action.data}
        case GET_EVENT_DETAILS_REGISTRATION_ERROR:
            return {...state,loading:false,error:true,success:false,error:[action.errors]}
        default :
            return state;
    }
}