import {  RESET_VERIFY_OTP, RESET_VERIFY_OTP_EMAIL, VERIFY_OTP_ERROR, VERIFY_OTP_LOADING, VERIFY_OTP_SUCCESS } from "../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function verifyotpReducer(state = initialState, action) {
  switch (action.type) {

    case VERIFY_OTP_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case VERIFY_OTP_SUCCESS:
      return { ...state, error: false, loading: false, success: true,message:action.msg , data:action.payload};

    case VERIFY_OTP_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: action.errors,msg:action.msg }

    case RESET_VERIFY_OTP:
        return {...state,error:false,loading:false,success:false,errors:[],user:[{}]}  

    default:
      return state;
  }
}

