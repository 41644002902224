import React from 'react'
import BreadcrumbHistory from '../../BreadcumbHistory/BreadcrumbHistory'
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom'
import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { getAdminGroupPermission } from '../../service/action/Club/clubAction'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import classes from './AdminPermission.module.css'


function AdminPermission(props) {
  const location = useLocation()
  const [isCreateNew, setIsCreateNew] = useState(false)
  const [groupName, setGroupName] = useState('')
  const [groupError, setGroupError] = useState(false)
  const [group, setGroup] = useState([])
  const notify = (msg) => toast(msg)
  const history = useHistory()
  useEffect(() => {
    props.getAdminGroupPermission()
  }, [])

  useEffect(() => {
    if (props.GetPermiossionAdminGroupReducer.success) {
      const data = props.GetPermiossionAdminGroupReducer.data
      
      
      setGroup(data.data)
    }
  }, [props.GetPermiossionAdminGroupReducer])

  // useEffect(() => {
  //   if (props.CreateAdminGroupReducer.success) {
  //     notify('Group Created Successfully')
  //   }
  // }, [props.CreateAdminGroupReducer])



  const onClickHandelar = (id)=> {
    history.push(`/admin/${id}`)
  }


  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />
        <div className="content-wrapper">
          <div className="text-right">
            <div >

              <div className="mt-3 mt-md-0 text-right create-team-head-btn flex-wrap">
                <Link to="/admin/create-group" className='mr-2 mt-2'>
                  <button className="btn-create-new ">Create Group</button>
                </Link>
                <Link to="/admin/invite-admin" className='mr-2 mt-2'>
                  <button className="btn-create-new ">Invite Admin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className='mt-3'>
            {
              group.length == 0 ? <> no group </> :
                <>
                  <div className='d-flex flex-wrap justify-content-between' >
                    {
                      group.map((e, i) => {
                        const { _id } = e
                       
                        return (
                        
                        <div key={i} className='border pointer p-2 my-2 d-flex justify-content-between align-items-center admin_group'>
                          <div className=' text-danger'>{e.group_name}</div>
                          <i className="mdi mdi-chevron-right" onClick={()=> onClickHandelar(_id)}></i>
                        </div>
                     
                       
                        )
                      })
                    }
                  </div>
                </>
            }
          </div>
        </div>
      </div>


    </React.Fragment>
  )
}

const mapStateToProp = state => ({
  GetPermiossionAdminGroupReducer: state.GetPermiossionAdminGroupReducer,
  // CreateAdminGroupReducer: state.CreateAdminGroup,
})

const mapDispatchToProps = dispatch => ({
  getAdminGroupPermission: user => dispatch(getAdminGroupPermission()),
})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(AdminPermission)); 