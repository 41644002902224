import {
  ADD_SPORT_LIST_ERROR,
  ADD_SPORT_LIST_SUCCESS,
  DELETE_SPORT_LIST_ERROR,
  DELETE_SPORT_LIST_SUCCESS,
  GET_SPORT_LIST_ERROR,
  GET_SPORT_LIST_SUCCESS,
  RESET_ADD_SPORT_LIST,
  RESET_DELETE_SPORT_LIST,
  RESET_GET_SPORT_LIST,
  RESET_UPDATE_SPORT_LIST,
  UPDATE_SPORT_LIST_ERROR,
  UPDATE_SPORT_LIST_SUCCESS,
} from "../constant";

let initialState = {
  getSportDetails: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  updateSportDetails: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  addSportDetails: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  deleteSportDetails: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
};
export default function SportReducer(state = initialState, action) {
  switch (action.type) {
    //  get details
    case GET_SPORT_LIST_SUCCESS:
      return {
        ...state,
        getSportDetails: {
          ...state.getSportDetails,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_SPORT_LIST_ERROR:
      return {
        ...state,
        getSportDetails: {
          ...state.getSportDetails,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_SPORT_LIST:
      return {
        ...state,
        getSportDetails: {
          ...state.getSportDetails,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    //  add details
    case ADD_SPORT_LIST_SUCCESS:
      return {
        ...state,
        addSportDetails: {
          ...state.addSportDetails,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case ADD_SPORT_LIST_ERROR:
      return {
        ...state,
        addSportDetails: {
          ...state.addSportDetails,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_ADD_SPORT_LIST:
      return {
        ...state,
        addSportDetails: {
          ...state.addSportDetails,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    //  update details
    case UPDATE_SPORT_LIST_SUCCESS:
      return {
        ...state,
        updateSportDetails: {
          ...state.updateSportDetails,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case UPDATE_SPORT_LIST_ERROR:
      return {
        ...state,
        updateSportDetails: {
          ...state.updateSportDetails,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_UPDATE_SPORT_LIST:
      return {
        ...state,
        updateSportDetails: {
          ...state.updateSportDetails,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    //  delte details
    case DELETE_SPORT_LIST_SUCCESS:
      return {
        ...state,
        deleteSportDetails: {
          ...state.deleteSportDetails,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case DELETE_SPORT_LIST_ERROR:
      return {
        ...state,
        deleteSportDetails: {
          ...state.deleteSportDetails,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_DELETE_SPORT_LIST:
      return {
        ...state,
        deleteSportDetails: {
          ...state.deleteSportDetails,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    default:
      return state;
  }
}
