import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '@dmuy/timepicker/dist/mdtimepicker.css'
import mdtimepicker from '@dmuy/timepicker';
import classNames from 'classnames';
import { Link, withRouter, Redirect, useParams } from 'react-router-dom';
import { getDatas, getPaymentCode, getPaymentCode1, sendImage } from "../../../service/action/userAction"
import { editEventData } from "../../../service/action/userAction";
import { connect } from "react-redux"
import { eventDetails, getAllEvent, getAllPaymentCode, getAllSeason, getAllState, getAllCity } from '../../../service/action/userAction';
import { toast } from 'react-toastify';
import { RESET_EDIT_EVENT } from '../../../service/constants';
import Toaster from '../../../Toaster/Toaster';
import { getAllClubEvent, getClubOrgEventDetails } from '../../../service/action/Club/clubAction';
import { useHistory } from 'react-router-dom';
import { END_DATE, END_TIME, EVENT_ACCESS, EVENT_NAME, EVENT_PAYMENTCODE, EVENT_SEASON, EVENT_TYPE, FACILITY_ADDRESS, FACILITY_CITY, FACILITY_NAME, FACILITY_STATE, FACILITY_WEBSITE, MAX_TEAM, START_DATE, START_TIME } from '../../../Validation/club';
import { ThemeProvider } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import materialTheme from '../../../materialUi';
import moment from 'moment';

const Edit_Event = (props) => {
    const notify = (msg) => toast(msg);
    const { id } = useParams();

    useEffect(() => {
        props.getOrgEventDetailsHandler({ id: id })
    }, [])

    const history = useHistory();

    const [loading, setLoading] = useState(true)

    // const {id}=useParams()

    {/* //-----------=====-----------Add contact person change Start-----------====----------- */ }


    const [contactPerson, setContectPerson] = useState([{ person: "" }])
    const result = contactPerson.map(a => a.person)
    const result1 = result.filter(a => a)

    // handle click event of the Remove button(contect person)
    const handleRemoveContact = index => {
        const list = [...contactPerson];
        list.splice(index, 1);
        setContectPerson(list);
    };

    // handle input change(contact person)
    const handleContactChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...contactPerson];
        list[index][name] = value;
        setContectPerson(list);
    };

    // handle click event of the Add button(contact person)
    const handleAddContact = () => {
        setContectPerson([...contactPerson, {
            person: ""
        }]);
    };

    {/* //-----------=====-----------Add contact person change End-----------====----------- */ }



    {/* //-----------=====-----------Add facility Info Start-----------====----------- */ }

    // 
    const [inputList, setInputList] = useState([
        {
            facility_name: "",
            facility_address: "",
            facility_website: "",
            facility_state: "",
            facility_city: ""
        }])

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value, id } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
        switch (name) {
            case "facility_state":
                if (value !== "") {
                    props.getAllCityHandler({ id: value })
                }
                if (value.length == 0 || value == "") {
                    setErrors(prevState => ({
                        ...prevState,
                        facility_state: FACILITY_STATE
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        facility_state: false
                    }))
                }
                break;

            case "facility_city":
                if (value.length == 0 || value == "") {
                    setErrors(prevState => ({
                        ...prevState,
                        facility_city: FACILITY_CITY
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        facility_city: false
                    }))

                }

                break;
            case "facility_website":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        facility_website: FACILITY_WEBSITE
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        facility_website: false
                    }))
                }
                break;
            case "facility_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        facility_name: FACILITY_NAME
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        facility_name: false
                    }))
                }

                break;
            case "facility_address":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        facility_address: FACILITY_ADDRESS
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        facility_address: false
                    }))

                }
                break;
            default:
                break;
        }
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, {
            facility_name: "",
            facility_address: "",
            facility_website: "",
            facility_state: "",
            facility_city: ""
        }]);
    };

    {/* //-----------=====-----------Add Facility Info End-----------====----------- */ }


    {/* //-----------=====-----------Add Event Date Times Start -----------====----------- */ }

    // Start date/ start time +end time field
    const [eventDate, setEventDate] = useState([
        {
            date: null,
            start_time: "",
            end_time: "",

        }])

    // handle click event of the Add button
    const handleAddEventDate = () => {

        setEventDate([...eventDate, {
            date: null,
            start_time: "",
            end_time: "",

        }]);
    };

    // handle click event of the Remove button
    const handleRemovedate = index => {
        const list = [...eventDate];
        list.splice(index, 1);
        setEventDate(list);
    };

    // handle input change
    const handleEventDateChange = (e, i) => {
        const { name, value, id } = e.target;
        // alert("hello")


        const list = [...eventDate];

        list[i][name] = value;
        setEventDate(list);

        switch (name) {
            case "date":
                if (value == "" || value == null) {
                    setErrors(prevState => ({
                        ...prevState,
                        startDate: START_DATE
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        startDate: false
                    }))
                }
                break;

            case "start_time":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        start_time: START_TIME
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        start_time: false
                    }))
                }
                break;
            case "end_time":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        end_time: END_TIME
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        end_time: false
                    }))
                }
                break;
            default:
                break;
        }
    };



    {/* //-----------=====-----------Add Event Date Times  End-----------====----------- */ }




    const [startDate, setStartDate] = useState("")
    const [EndDate, setEndDate] = useState("")
    const [count, setcount] = useState();
    const [personcount, setPersoncount] = useState();
    const [counter, setCounter] = useState(1);
    const [personcounter, setPersonCounter] = useState(1);
    const fields = [];
    const person = [];

    // const formatYmd = date => date.toISOString().slice(0, 10);
    const [state, setState] = useState({
        // startDate: "",
        // EndDate: "",

        from_time: "",
        to_time: "",
        event_name: "",
        facility_name: "",
        address: "",
        maxTeam: "",
        city: "",
        state: "",
        option: "",
        event_access: "",
        website: "",
        payment: "",
        payment1: "",
        person: "",
        season: "",
        numPlayers: "",
        eventFull: "",
        remainingSpots: "",
        schedual_tab: "",
        auto_payment: ""

    })

    const checkVal = (e) => {
        const { id, value, name } = e.target
        setState((prevState => ({
            ...prevState,
            auto_payment: value == 0 ? 1 : value == 1 ? 0 : 1
        })))
    }


    const [image, setImage] = useState("")
    const [pitcher, setPitcher] = useState({
        file: {}
    })



    const [errors, setErrors] = useState({
        startDate: false,
        EndDate: false,
        from_time: false,
        start_time: false,
        end_time: false,
        to_time: false,
        event_name: false,
        facility_name: false,
        address: false,
        maxTeam: false,
        city: false,
        state: false,
        option: false,
        event_access: false,
        website: false,
        payment: false,
        payment1: false,
        person: false,
        season: false


    })
    // const getdata = props.getEventHandler;  //for get all Event
    const getseason = props.getSeasonHandler; //for getAllSeason
    const getAllState = props.getAllStateHandler;
    useEffect(() => {
        // getdata({id:0})
        getseason()
        getAllState()
    }, [])
    const editEventMsg = useSelector(state => state.editEventReducer)
    const allpayment = useSelector(state => state.getPaymentcodeReducer.user)   //for listing all payment code

    const paycode1 = useSelector(state => state.getPaymentcode1Reducer.user)

    const allseason = useSelector(state => state.getAllSeasonReducer.user)        //for listing all season
    const allstate = useSelector(state => state.getStateReducer.states)             //for get All country states
    const allcity = useSelector(state => state.getCityReducer.citys)                //for get all state citys
    const eventDetails = useSelector(state => state.getClubOrgEventDetailsReducer.data.data)






    const dispatch = useDispatch()

    useEffect(() => {
        window.select2()
        // mdtimepicker('#from_time');
        // mdtimepicker('#to_time');

    }, [])

    useEffect(() => {

        window.select2()
        mdtimepicker('.timepicker');
        mdtimepicker('.timepicker');
    })



    // const getToken = localStorage.getItem("token")
    // const header = { headers: { "Authorization": `Bearer ${getToken}` } }

    const allEvent = useSelector(state => state.getAllEventReducer.user)
    // const getEvent = allEvent.find((e) => e._id == id)


    useEffect(() => {
        // if (id) {
        //     fetchEventDetails();
        // }

        if (eventDetails) {
            setLoading(false)
            eventDetails.event_facility.map((x, i) => {
                props.getAllCityHandler({ id: x.facility_state._id })
                setState(prevState => ({
                    ...prevState,
                    event_name: eventDetails.event_name,
                    from_time: eventDetails.event_from_time,
                    to_time: eventDetails.event_to_time,
                    // facility_name: x.facility_name,
                    // address: x.facility_address,
                    maxTeam: eventDetails.event_max_team,
                    // state: x.facility_state._id,
                    // city: x.facility_city._id,
                    option: eventDetails.event_type,
                    event_access: eventDetails.event_access,
                    // website: x.facility_website,
                    payment: eventDetails.payment_code[0] !== undefined ? eventDetails.payment_code[0]._id : "",
                    payment1: eventDetails.payment_code[1] !== undefined ? eventDetails.payment_code[1]._id : "",
                    person: "",
                    season: eventDetails.event_season,
                    numPlayers: eventDetails.show_number_of_player,
                    eventFull: eventDetails.show_event_is_full,
                    remainingSpots: eventDetails.show_remaining_spots,
                    schedual_tab: eventDetails.show_schedule,
                    auto_payment: eventDetails.auto_payment
                }))

                setImage(eventDetails.event_image)
                setPitcher({ file: eventDetails.event_image })

                // eventDetails.event_facility.map((x, i) => {
                //     props.getAllCityHandler({ id: x.facility_state._id })


                //     setInputList([{
                //         facility_name: x.facility_name,
                //         facility_address: x.facility_address,
                //         facility_website: x.facility_website,
                //         facility_state: x.facility_state._id,
                //         facility_city: x.facility_city._id
                //     }]);
                // })
                var facility = []

                eventDetails.event_facility.map((x, i) => {
                    // props.getAllCityHandler({ id: x.facility_state._id })
                    facility.push({
                        facility_name: x.facility_name,
                        facility_address: x.facility_address,
                        facility_website: x.facility_website,
                        facility_state: x.facility_state._id,
                        facility_city: x.facility_city._id
                    })
                })
                setInputList(facility)

                let dateArray = eventDetails.event_date_time

                var dates = dateArray.map((v, i) => ({
                    date: new Date(v.date),
                    start_time: v.start_time,
                    end_time: v.end_time

                }))

                setEventDate(dates)
            })
            // setStartDate(new Date(eventDetails.event_from_date))
            // setEndDate(new Date(eventDetails.event_to_date))
        }

    }, [eventDetails])



    const onChangePicture = event => {
        setImage(URL.createObjectURL(event.target.files[0]));
        if (event.target.files && event.target.files[0]) {
            setPitcher({ file: event.target.files[0] });
        }
    };

    const eventHandler = (e) => {
        const { id, value, name } = e.target

        setState(prevState => ({
            ...prevState,
            [id]: value
        }))

        switch (name) {
            case "startDate":
                setStartDate(value)

                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        startDate: START_DATE
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        startDate: false
                    }))
                }
                break;
            case "EndDate":
                setEndDate(value)

                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        EndDate: END_DATE
                    }))

                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        EndDate: false
                    }))
                }
                break;

            case "event_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        event_name: EVENT_NAME
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        event_name: false
                    }))
                }
                break;

            // case "facility_name":
            //     if (value.length == 0) {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             facility_name: "Facility name can not be empty."
            //         }))
            //     }
            //     else {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             facility_name: false
            //         }))
            //     }
            //     break;
            // case "website":
            //     if (value.length == 0) {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             website: "Website can not be empty."
            //         }))
            //     }
            //     else {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             website: false
            //         }))
            //     }
            //     break;
            // case "address":
            //     if (value.length == 0) {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             address: "Address can not be empty."
            //         }))
            //     }
            //     else {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             address: false
            //         }))
            //     }
            //     break;
            case "from_time":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        from_time: START_TIME
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        from_time: false
                    }))
                }
                break;
            case "to_time":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        to_time: END_TIME
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        to_time: false
                    }))
                }
                break;
            case "max-team":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        maxTeam: MAX_TEAM
                    }))
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        maxTeam: false
                    }))
                }
                break;
            // case "city":

            //     if (value.length == 0) {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             city: "City can not be empty."
            //         }))

            //     }
            //     else {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             city: false
            //         }))
            //     }

            //     break;
            // case "state":
            //     if (value !== "") {
            //         props.getAllCityHandler({ id: value })
            //     }
            //     if (value.length == 0) {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             state: "State can not be empty."
            //         }))
            //     }
            //     else {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             state: false
            //         }))
            //     }
            //     break;
            case "event_type":
                if (value == 0 && value !== "") {
                    props.getPaymentCodeHandler({ id: 0 })
                }
                if (value == 1 && value !== "") {
                    props.getPaymentCodeHandler({ id: 1 })
                }
                if (value == 2 && value !== "") {
                    props.getPaymentCodeHandler({ id: 0 })
                    props.getPaymentCodeHandler1({ id: 1 })
                }
                if (value.length == 0 || value == "") {
                    setErrors(prevState => ({
                        ...prevState,
                        option: EVENT_TYPE
                    }))

                    setState(prevState => ({
                        ...prevState,
                        option: value
                    }))
                }

                else {
                    setErrors(prevState => ({
                        ...prevState,
                        option: false
                    }))

                    setState(prevState => ({
                        ...prevState,
                        option: value
                    }))

                }
                break;

            case "season":
                if (value.length == 0 || value == "") {
                    setErrors(prevState => ({
                        ...prevState,
                        season: EVENT_SEASON
                    }))

                    setState(prevState => ({
                        ...prevState,
                        season: value
                    }))
                }

                else {
                    setErrors(prevState => ({
                        ...prevState,
                        season: false
                    }))

                    setState(prevState => ({
                        ...prevState,
                        season: value
                    }))

                }
                break;



            case "event_access":
                if (value == "") {
                    setErrors(prevState => ({
                        ...prevState,
                        event_access: EVENT_ACCESS
                    }))

                    // setState(prevState => ({
                    //     ...prevState,
                    //     event_access: value
                    // }))
                }

                else {
                    setErrors(prevState => ({
                        ...prevState,
                        event_access: false
                    }))

                    // setState(prevState => ({
                    //     ...prevState,
                    //     event_access: value
                    // }))
                }
                break;
            case "payment":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        payment: EVENT_PAYMENTCODE
                    }))

                    setState(prevState => ({
                        ...prevState,
                        payment: value
                    }))
                }

                else {
                    setErrors(prevState => ({
                        ...prevState,
                        payment: false
                    }))

                    setState(prevState => ({
                        ...prevState,
                        payment: value
                    }))
                }
                break;
            case "payment1":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        payment1: EVENT_PAYMENTCODE
                    }))

                }

                else {
                    setErrors(prevState => ({
                        ...prevState,
                        payment1: false
                    }))

                }
                break;
            case "person":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        person: "Value can not be empty"
                    }))

                    setState(prevState => ({
                        ...prevState,
                        person: value
                    }))
                }

                else {
                    setErrors(prevState => ({
                        ...prevState,
                        person: false
                    }))

                    setState(prevState => ({
                        ...prevState,
                        person: value
                    }))
                }
                break;
            case "numPlayers":
                if (value == 0) {
                    setState(prevState => ({
                        ...prevState,
                        numPlayers: 1
                    }))
                } else {
                    setState(prevState => ({
                        ...prevState,
                        numPlayers: 0
                    }))
                }
                break;
            case "remainingSpots":
                if (value == 0) {
                    setState(prevState => ({
                        ...prevState,
                        remainingSpots: 1
                    }))
                } else {
                    setState(prevState => ({
                        ...prevState,
                        remainingSpots: 0
                    }))
                }
                break;
            case "eventFull":
                if (value == 0) {
                    setState(prevState => ({
                        ...prevState,
                        eventFull: 1
                    }))
                } else {
                    setState(prevState => ({
                        ...prevState,
                        eventFull: 0
                    }))
                }
                break;
            case "schedual_tab":
                if (value == 0) {
                    setState(prevState => ({
                        ...prevState,
                        schedual_tab: 1
                    }))
                } else {
                    setState(prevState => ({
                        ...prevState,
                        schedual_tab: 0
                    }))
                }
                break;

            default:
        }
    }






    const saveData = (e) => {
        // alert("click")
        e.preventDefault();

        const dateFormateChange = eventDate.map((v, i) => ({
            // date: v.date.toISOString().slice(0, 10),
            // date: v.date,
            date: moment(v.date).format('MM/DD/YYYY'),
            start_time: v.start_time,
            end_time: v.end_time
        }))


        // if (startDate == "") {
        //     setErrors(prevState => ({
        //         ...prevState,
        //         startDate: START_DATE
        //     }))
        // }

        // if (EndDate == "") {
        //     setErrors(prevState => ({
        //         ...prevState,
        //         EndDate: END_DATE
        //     }))
        // }

        if (eventDate[0].date == "") {
            setErrors(prevState => ({
                ...prevState,
                startDate: START_DATE
            }))
        }


        if (eventDate[0].start_time == "") {
            setErrors(prevState => ({
                ...prevState,
                start_time: START_TIME
            }))
        }

        if (eventDate[0].end_time == "") {
            setErrors(prevState => ({
                ...prevState,
                end_time: END_TIME
            }))
        }


        if (state.event_name == "") {
            setErrors(prevState => ({
                ...prevState,
                event_name: EVENT_NAME
            }))
        }

        if (inputList[0].facility_name == "") {
            setErrors(prevState => ({
                ...prevState,
                facility_name: FACILITY_NAME
            }))
        }

        if (inputList[0].facility_website == "") {
            setErrors(prevState => ({
                ...prevState,
                facility_website: FACILITY_WEBSITE
            }))
        }
        if (inputList[0].facility_address == "") {
            setErrors(prevState => ({
                ...prevState,
                facility_address: FACILITY_ADDRESS
            }))
        }


        if (state.maxTeam == "") {
            setErrors(prevState => ({
                ...prevState,
                maxTeam: MAX_TEAM
            }))
        }

        if (state.option == "#") {
            setErrors(prevState => ({
                ...prevState,
                option: EVENT_TYPE
            }))
        }
        if (state.season == "") {
            setErrors(prevState => ({
                ...prevState,
                season: EVENT_SEASON
            }))
        }
        if (state.event_access == "" && state.event_access !== 0 && state.event_access !== 1) {
            setErrors(prevState => ({
                ...prevState,
                event_access: EVENT_ACCESS
            }))
        }
        if (state.payment == "") {
            setErrors(prevState => ({
                ...prevState,
                payment: EVENT_PAYMENTCODE
            }))
        }
        // if (state.person == "") {
        //     setErrors(prevState => ({
        //         ...prevState,
        //         person: "Value can not be empty"
        //     }))
        // }


        if (inputList[0].facility_city == "") {
            setErrors(prevState => ({
                ...prevState,
                facility_city: FACILITY_CITY
            }))
        }
        if (inputList[0].facility_state == "") {
            setErrors(prevState => ({
                ...prevState,
                facility_state: FACILITY_STATE
            }))
        }



        if (eventDate[0].date !== "" && eventDate[0].start_time !== "" && eventDate[0].end_time !== "" && state.event_name !== "" && inputList[0].facility_name !== "" && inputList[0].facility_address !== "" && inputList[0].facility_website !== "" && state.maxTeam !== "" && state.availability !== "" && state.option !== "" && state.event_access !== "" && state.payment !== "" && inputList[0].facility_city !== "" && inputList[0].facility_state !== "" && state.season !== "") {


            props.editEventHandler({

                event_name: state.event_name,
                event_season: state.season,
                event_type: state.option,
                // event_start_date: startDate.toISOString().slice(0, 10),
                // event_end_date: EndDate.toISOString().slice(0, 10),
                // event_start_time: state.from_time,
                // event_end_time: state.to_time,
                event_payment_code: state.payment1 !== "" ? [state.payment, state.payment1] : [state.payment],
                event_facility_info: inputList,
                event_max_team: state.maxTeam,
                event_access: state.event_access,
                show_number_of_player: state.numPlayers,
                show_remaining_spots: state.remainingSpots,
                show_event_is_full: state.eventFull,
                show_schedule: state.schedual_tab,
                // person:  result1[0].length !== 0 ? result : [],
                id: id,
                auto_payment: state.auto_payment,
                event_date_time: dateFormateChange
                // event_image: pitcher.file
            })

        }
    };

    // if (props.editrequest?.success) {
    //     notify("Data Edited successfully.")
    //     props.resetEditEvent()
    //     return <Toaster />
    // }



    const itemsRef = useRef([]);
    const itemsRefEnd = useRef([]);

    var el = document.querySelector(".timepicker");
    useEffect(() => {
        itemsRef?.current.forEach((e) => {
            e.addEventListener('timechanged', function (element, i) {
                const list = [...eventDate];
                // handleEventDateChange(element,element.target.dataset.id)


                list[element.target.dataset.id][element.target.name] = element.target.value;
                setEventDate(list);
            })
        })
    })
    useEffect(() => {
        itemsRefEnd?.current.forEach((e) => {
            e.addEventListener('timechanged', function (element, i) {
                const list = [...eventDate];
                // handleEventDateChange(element,element.target.dataset.id)


                list[element.target.dataset.id][element.target.name] = element.target.value;
                setEventDate(list);
            })
        })
    })



    useEffect(() => {
        if (loading == false) {
            window.select2()
            mdtimepicker('#from_time');
            mdtimepicker('#to_time');
        }
    }, [loading])







    useEffect(() => {
        if (props.editrequest?.success) {
            props.sendImageHandler({ event_image: pitcher.file, event_id: editEventMsg.message.event_id })
            history.push("/events")
        }
        else if (props.editrequest?.error) {
            notify("Event Update Error")
        }
    }, [props.editrequest])

    const Today = new Date().toISOString().slice(0, 10);



    return (

        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    {
                        eventDetails ?
                            <>

                                <div className="text-left">

                                    <div className="create-team-head-event">
                                        <h5 className="dash-head">Edit Event Details</h5>
                                        <button className="btn-save " onClick={saveData}>Save Changes</button>
                                    </div>


                                    <div className="text-left mt-30">
                                        <h6 className="payment">Basic Info</h6>
                                        <div className="basic-info">
                                            <input type="file" className="input-file file-upload" id="file-upload" onChange={onChangePicture} />
                                            <label className="change-pic" htmlFor="file-upload">
                                                <img src={image == "" ? "./images/faces/face3.jpg" : image} className="img-fluid" />
                                            </label>
                                            <span className="ml-12">Upload Picture</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row ">
                                    <div className="col-md-4">
                                        <div className="text-left">
                                            <div className="mt-18">
                                                <input type="text" className={classNames("form-control team-name", { "error": errors.event_name })} placeholder="Event Name *" id="event_name" value={state.event_name} name="event_name" onChange={eventHandler} />
                                                {errors.event_name &&
                                                    <span className='text-red small d-block'>* {errors.event_name}</span>}

                                            </div>
                                            <div className="form-row mt-18">
                                                <div className="col-md-6 ">
                                                    {/* <input type="text" className="form-control team-name" placeholder="From Date" /> */}
                                                    {/* <div className="desk-datepicker">
                                                        <DatePicker
                                                            selected={startDate}
                                                            // dateFormat="dd-MM-yyyy"
                                                            // dateFormat="yyyy-MM-dd"
                                                            placeholderText="Start Date *"
                                                            onChange={date => eventHandler({ target: { value: date, name: 'startDate', id: "start-date" } })}
                                                            className={classNames("form-control team-name", { "error": errors.startDate })}
                                                        />

                                                        {errors.startDate &&
                                                            <span className='text-red small d-block'>* {errors.startDate}</span>}
                                                    </div> */}
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <input type="text" data-mintime="now" className={classNames("form-control timepicker team-name", { "error": errors.from_time })} placeholder="Start time *" id="from_time" value={state.from_time} name="from_time" onChange={eventHandler} />

                                                    {errors.from_time &&
                                                        <span className='text-red small d-block'>* {errors.from_time}</span>}

                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ">
                                        <div className="text-left">
                                            {/* <div className="dropdown event-drp">
                                    <a className="form-control dropdown-toggle gender-drp text-decoration-none" href="#" role="button" id="events" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Event Type
                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="Select Sports">
                                        <a className="dropdown-item" href="#">Type 1</a>
                                        <a className="dropdown-item" href="#">Type 2</a>
                                        <a className="dropdown-item" href="#">Type 3</a>
                                    </div>
                                </div> */}

                                            <div className=" event-drp mt-18">
                                                <select className={classNames("form-control team-name payterm", { "error": errors.option })} id="event_type" name="event_type" value={state.option} onChange={eventHandler}>
                                                    <option value="" >Event Type *</option>
                                                    <option value="0">Club only</option>
                                                    <option value="1">Parent/Player only</option>
                                                    <option value="2">Club & Parent </option>
                                                </select>

                                                {errors.option &&
                                                    <span className='text-red small d-block'>* {errors.option}</span>}
                                            </div>

                                            <div className="form-row mt-18">
                                                {/* <div className="col-md-6 ">
                                                    <DatePicker
                                                        selected={EndDate}
                                                        placeholderText="End Date *"
                                                        onChange={date => eventHandler({ target: { value: date, name: 'EndDate', id: "End-date" } })}
                                                        className={classNames("form-control team-name", { "error": errors.EndDate })}
                                                    />

                                                    {errors.EndDate &&
                                                        <span className='text-red small d-block'>* {errors.EndDate}</span>}
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="text" data-mintime="now" className={classNames("form-control timepicker team-name", { "error": errors.to_time })} placeholder="End time *" id="to_time" value={state.to_time} name="to_time" onChange={eventHandler} />

                                                    {errors.to_time &&
                                                        <span className='text-red small d-block'>* {errors.to_time}</span>}
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>




                                </div>

                                {/* //-----------=====-----------Event Date/Time start-----------====----------- */}

                                {
                                    eventDate.map((x, i) => {
                                        return (
                                            <div key={i} >

                                                {eventDate.length !== 1 && (
                                                    <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                                        <Link
                                                            to="#"
                                                            className="text-decoration-none"
                                                            onClick={() => handleRemovedate(i)}
                                                        >
                                                            -Remove
                                                        </Link>
                                                    </div>
                                                )}
                                                <div className="row" key={i}  >
                                                    <div className="col-md-6">
                                                        <div className="mt-18">
                                                            {/* <input type="date" id="date" name="date" value={x.date} onChange={(e)=> handleEventDateChange(e, i)} minLength="8" min={Today} className={classNames("form-control team-name ", {
                                                                error: errors.startDate,
                                                            })}/> */}
                                                            <ThemeProvider theme={materialTheme}>


                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardDatePicker
                                                                        value={x.date}
                                                                        placeholder="mm/dd/yyyy"
                                                                        format="mm/dd/yyyy"
                                                                        // InputProps={{ disableUnderline: true }}
                                                                        mask="__/__/____"
                                                                        // variant="inline"
                                                                        inputVariant="outlined"
                                                                        autoOk="true"
                                                                        minDate={Today}
                                                                        InputAdornmentProps={{ position: "end" }}
                                                                        className={classNames(" form-control team-name")}
                                                                        // error={errors.startDate}
                                                                        invalidDateMessage="Invalid Date Format"


                                                                        onChange={(e) =>
                                                                            handleEventDateChange({
                                                                                target: {
                                                                                    value: e,
                                                                                    name: "date",
                                                                                    id: "date",
                                                                                },
                                                                            }, i)}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </ThemeProvider>


                                                            {/* <DatePicker
                                                                selected={x.date}
                                                                // dateFormat="dd-MM-yyyy"
                                                                // dateFormat="yyyy-MM-dd"
                                                                minDate={new Date()}
                                                                placeholderText="Start Date *"
                                                                onChange={(e) =>
                                                                    handleEventDateChange({
                                                                        target: {
                                                                            value: e,
                                                                            name: "date",
                                                                            id: "date",
                                                                        },
                                                                    }, i)
                                                                }
                                                                className={classNames("form-control team-name", {
                                                                    error: errors.startDate,
                                                                })}
                                                            /> */}

                                                            {errors.startDate && (
                                                                <span className="text-red small d-block">
                                                                    * {errors.startDate}
                                                                </span>
                                                            )}
                                                        </div>

                                                    </div>


                                                    <div className="col-md-3">
                                                        <div className="mt-18">
                                                            <input
                                                                type="text"
                                                                data-mintime="now"
                                                                className={classNames("form-control timepicker team-name", {
                                                                    error: errors.from_time,
                                                                })}
                                                                placeholder="Start Time *"
                                                                // id="start_time"
                                                                data-id={`${i}`}
                                                                ref={el => itemsRef.current[i] = el}
                                                                value={x.start_time}
                                                                name="start_time"
                                                                onChange={(e) => handleEventDateChange(e, i)}
                                                                autoComplete="off"
                                                            />


                                                            {errors.start_time && (
                                                                <span className="text-red small d-block">
                                                                    * {errors.start_time}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="mt-18">
                                                            <input
                                                                type="text"
                                                                data-mintime="now"
                                                                className={classNames("form-control timepicker team-name", {
                                                                    error: errors.end_time,
                                                                })}
                                                                placeholder="End Time *"
                                                                data-id={`${i}`}
                                                                ref={el => itemsRefEnd.current[i] = el}
                                                                value={x.end_time}
                                                                name="end_time"
                                                                onChange={e => handleEventDateChange(e, i)}
                                                                autoComplete="off"
                                                            />

                                                            {errors.end_time && (
                                                                <span className="text-red small d-block">
                                                                    * {errors.end_time}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* {count} */}
                                                {eventDate.length - 1 == i && (
                                                    <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                                        <Link
                                                            to="#"
                                                            className="text-decoration-none"
                                                            onClick={handleAddEventDate}
                                                        >
                                                            + Add More
                                                        </Link>
                                                    </div>
                                                )}
                                                <hr />
                                            </div>
                                        );
                                    })
                                }


                                {/* //-----------=====-----------Event Date/Time End-----------====----------- */}

                                {/* //-----------=====-----------Event Season Start-----------====----------- */}


                                <div className='row'>
                                    <div className="col-md-4">
                                        <div className="text-left mt-18">
                                            {/* js-example-placeholder-single */}
                                            <div className="text-left mt-18">
                                                {/* js-example-placeholder-single */}
                                                <select className={classNames("form-control team-name payterm", { "error": errors.season })} id="season" name="season" value={state.season} onChange={eventHandler}>
                                                    <option value="">Season *</option>
                                                    {allseason.map((v, i) => {
                                                        return (
                                                            <option key={v._id} value={v._id}>{v.season_name}</option>
                                                        )
                                                    })}
                                                </select>

                                                {errors.season &&
                                                    <span className='text-red small d-block'>* {errors.season}</span>}

                                            </div>
                                        </div>
                                    </div>

                                </div>


                                {/* //-----------=====-----------Event Season End-----------====----------- */}

                                {/* //-------=====-------Facility Info Start--------====---- */}


                                <div className="text-left mt-42">
                                    <h6 className="payment">Facility Info</h6>
                                </div>
                                {
                                    inputList.map((x, i) => {
                                        return (
                                            <div key={i}>
                                                {inputList.length !== 1 &&
                                                    <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                                        <Link to="#" className="text-decoration-none" onClick={() => handleRemoveClick(i)}>-Remove</Link>
                                                    </div>}
                                                <div className="row">

                                                    <div className="col-md-4">

                                                        <div className="mt-24">
                                                            <input type="text" className={classNames("form-control team-name", { "error": errors.facility_name })} placeholder="Facility Name *" id="facility_name" name="facility_name"
                                                                // value={state.facility_name} 
                                                                // onChange={eventHandler} 
                                                                value={x.facility_name}
                                                                onChange={e => handleInputChange(e, i)}
                                                            />

                                                            {errors.facility_name &&
                                                                <span className='text-red small d-block'>* {errors.facility_name}</span>}
                                                        </div>

                                                        <div className="mt-18">
                                                            <input type="text" className={classNames("form-control team-name", { "error": errors.facility_website })} placeholder="Website *" id="facility_website"
                                                                // value={state.website}
                                                                value={x.facility_website}
                                                                name="facility_website"
                                                                // onChange={eventHandler}
                                                                onChange={e => handleInputChange(e, i)}
                                                            />

                                                            {errors.facility_website &&
                                                                <span className='text-red small d-block'>* {errors.facility_website}</span>
                                                            }
                                                        </div>

                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="mt-24">
                                                            <input type="text" className={classNames("form-control team-name", { "error": errors.facility_address })} placeholder=" Facility Address *" id="facility_address" name="facility_address"
                                                                value={x.facility_address}
                                                                onChange={e => handleInputChange(e, i)}
                                                            />

                                                            {errors.facility_address &&
                                                                <span className='text-red small d-block'>* {errors.facility_address}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="mt-24">
                                                                    <select className={classNames("form-control team-name", { "error": errors.facility_state })} placeholder="State *" id="facility_state" name="facility_state"
                                                                        // value={state.state}
                                                                        value={x.facility_state}
                                                                        // onSelect={getstatecity(x.state)}
                                                                        onChange={e => handleInputChange(e, i) && props.getAllCityHandler({ id: x.state })}
                                                                    >
                                                                        <option value="">State</option>
                                                                        {allstate.map((v, i) => {
                                                                            return (
                                                                                <option key={v._id} value={v._id}  >{v.name}</option>
                                                                            )
                                                                        })}
                                                                    </select>

                                                                    {errors.facility_state &&
                                                                        <span className='text-red small d-block'>* {errors.facility_state}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mt-24">
                                                                    <select className={classNames("form-control team-name", { "error": errors.facility_city })} placeholder="City *" id="facility_city" name="facility_city"
                                                                        // value={state.city} 
                                                                        // onChange={eventHandler} 
                                                                        value={x.facility_city}
                                                                        onChange={e => handleInputChange(e, i)}
                                                                    >
                                                                        <option value="">City</option>
                                                                        {allcity.map((v, i) => {
                                                                            return (
                                                                                <option key={v._id} value={v._id} >{v.name}</option>
                                                                            )
                                                                        })}
                                                                    </select>

                                                                    {errors.facility_city &&
                                                                        <span className='text-red small d-block'>* {errors.facility_city}</span>}
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                {/* {count} */}
                                                {
                                                    inputList.length - 1 == i &&
                                                    <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                                        <Link to="#" className="text-decoration-none" onClick={handleAddClick}>+ Add More</Link>
                                                    </div>
                                                }
                                                <hr />
                                            </div>
                                        )
                                    })
                                }

                                {/* //-----------=====-----------Facility Info End-----------====----------- */}



                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="text-left mt-42">
                                            <h6 className="payment">Event Access *</h6>

                                            <div className="form-group event-drp">
                                                <select className={classNames("form-control team-name payterm", { "error": errors.event_access })} id="event_access" name="event_access" value={state.event_access} onChange={eventHandler}>
                                                    <option value="" >Event Access Type *</option>
                                                    <option value="0">Public</option>
                                                    <option value="1">Private</option>
                                                    {/* <option value="1">Type 3</option> */}
                                                </select>

                                                {errors.event_access &&
                                                    <span className='text-red small d-block'>* {errors.event_access}</span>}
                                            </div>
                                            {/* 
                                            <div className="text-left" style={{ marginTop: "14px" }}>
                                                <input type="checkbox" defaultChecked />
                                                <label className="ml-2 text-blue fs-13">Allow players to register directly </label>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className={`${state.option == 2 ? "col-md-3" : "col-md-4"}`}>
                                        <div className="text-left mt-42">
                                            <h6 className="payment">{`${state.option == 2 ? "Club Payment Code *" : "Payment Code *"}`}</h6>



                                            <div className="form-group event-drp">
                                                <select className={classNames("form-control team-name payterm", { "error": errors.payment })} id="payment" name="payment" value={state.payment} onChange={eventHandler}>
                                                    <option value="" >{state.option == 0 ? "Club Payment Code" : state.option == 1 ? "Parent/Player Payment Code" : state.option == 2 ? "Club Payment Code" : (state.option == "#" ? "Payment Code" : state.option == "" ? "Payment Code" : "Payment Code")} *</option>
                                                    {allpayment.map((v, i) => {
                                                        return (
                                                            <option key={v._id} value={v._id}>{v.name}</option>
                                                        )
                                                    })}
                                                </select>

                                                {errors.payment &&
                                                    <span className='text-red small d-block'>* {errors.payment}</span>}
                                            </div>

                                            <div className='d-flex justify-content-between'>


                                                <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                                    <Link to="/season/create-season" className="text-decoration-none" >+ Add Payment Code</Link>
                                                </div>

                                                {state.option == 0 || state.option == 1 ? <>
                                                    <div className="text-left" style={{ marginTop: "14px" }}>
                                                        <input type="checkbox" name="auto_payment" id="auto_payment" value={state.auto_payment} onChange={checkVal} checked={state.auto_payment == 0 ? false : state.auto_payment == 1 ? true : false} />
                                                        <label className="ml-2 text-blue fs-13">Auto payment request </label>
                                                    </div>
                                                </> : <></>}
                                            </div>

                                            {/* <div className="text-left" style={{ marginTop: "14px" }}>
                                                <input type="checkbox" name="auto_payment" id="auto_payment" value={state.auto_payment} onChange={checkVal} checked={state.auto_payment == 0 ? false : state.auto_payment == 1 ? true : false} />
                                                <label className="ml-2 text-blue fs-13">Auto payment request </label>
                                            </div> */}

                                        </div>
                                    </div>
                                    {
                                        state.option == 2 &&
                                        <div className="col-md-3 ">
                                            <div className="text-left mt-42">
                                                <h6 className="payment">Parent/Player Payment Code *</h6>



                                                <div className="form-group event-drp">
                                                    <select className={classNames("form-control team-name payterm", { "error": errors.payment1 })} id="payment1" name="payment1" value={state.payment1} onChange={eventHandler}>
                                                        <option value="" >Parent/Player Payment Code *</option>
                                                        {paycode1.map((v, i) => {
                                                            return (
                                                                <option key={v._id} value={v._id}>{v.name}</option>
                                                            )
                                                        })}

                                                        {/* <option value="2">Type 2</option>
                                        <option value="3">Type 3</option> */}
                                                    </select>

                                                    {errors.payment1 &&
                                                        <span className='text-red small d-block'>* {errors.payment1}</span>}
                                                </div>

                                                {
                                                    state.option == 2 && <>

                                                        <div className="text-left add-more d-flex justify-content-end" style={{ marginTop: "22px" }}>
                                                            <input type="checkbox" name="auto_payment" id="auto_payment" value={state.auto_payment} onChange={checkVal} checked={state.auto_payment == 0 ? false : state.auto_payment == 1 ? true : false} />
                                                            <label className="ml-2 text-blue fs-13">Auto payment request </label>
                                                        </div>
                                                    </>
                                                }



                                            </div>
                                        </div>
                                    }
                                    {/* // Team start >>>>>>>>>>>>>>>>>>>>>>>>> */}
                                    {
                                        (state.option == 0 && state.option !== "") || state.option == 2 ?
                                            <div className="col-md-4">
                                                <div className="text-left mt-42">
                                                    <h6 className="payment">Teams</h6>
                                                </div>
                                                <input type="text" className={classNames("form-control team-name", { "error": errors.maxTeam })} placeholder="Max. Teams *" id="maxTeam" name="max-team" onChange={eventHandler} value={state.option == 0 || state.option == 2 ? state.maxTeam : state.option == 1 ? state.maxPlayer : ""} />


                                            </div>
                                            : state.option == 1 ?
                                                <div className="col-md-4">
                                                    <div className="text-left mt-42">
                                                        <h6 className="payment">Players</h6>
                                                    </div>
                                                    <input type="text" className={classNames("form-control team-name", { "error": errors.maxPlayer })} placeholder="Max. Players *" id="maxPlayer" name="max-player" onChange={eventHandler} value={state.maxTeam} />


                                                </div> : ""
                                    }
                                    {
                                        state.option == 2 &&
                                        <div className="col-md-4">
                                            <div className="text-left mt-42">
                                                <h6 className="payment">Players</h6>
                                            </div>
                                            <input type="text" className={classNames("form-control team-name", { "error": errors.maxPlayer })} placeholder="Max. Player *" id="maxPlayer" name="max-player" onChange={eventHandler} value={state.maxPlayer} />


                                        </div>
                                    }
                                </div>


                                <div className="row">
                                    <div className="col-md-6 text-left mt-24">
                                        <div className="custom-control custom-switch  mt-24">
                                            <input type="checkbox" className="custom-control-input " id="customSwitch1" name="numPlayers" value={state.numPlayers} onChange={eventHandler} checked={state.numPlayers} />
                                            <label className="custom-control-label payment" htmlFor="customSwitch1">Show number of players</label>
                                        </div>
                                        <div className="custom-control custom-switch mt-24">
                                            <input type="checkbox" className="custom-control-input payment " id="customSwitch2" name="remainingSpots" value={state.remainingSpots} onChange={eventHandler} checked={state.remainingSpots} />
                                            <label className="custom-control-label" htmlFor="customSwitch2">Show number of remaining spots</label>
                                        </div>
                                    </  div>
                                    <div className="col-md-6 col-md-6 text-left mt-24">
                                        <div className="custom-control custom-switch  mt-24 ">
                                            <input type="checkbox" className="custom-control-input payment " id="customSwitch3"
                                                name="eventFull" value={state.eventFull} onChange={eventHandler} checked={state.eventFull} />
                                            <label className="custom-control-label" htmlFor="customSwitch3">Show when event is full</label>
                                        </div>
                                        <div className="custom-control custom-switch mt-24">
                                            <input type="checkbox" className="custom-control-input payment " id="customSwitch4"
                                                name="schedual_tab" value={state.schedual_tab} checked={state.schedual_tab} onChange={eventHandler}
                                            />
                                            <label className="custom-control-label" htmlFor="customSwitch4">Show the scheduale tab</label>
                                        </div>
                                    </div>

                                </div>

                            </> : <h3>Loading...</h3>
                    }

                    <Toaster />
                </div>
            </div>
        </React.Fragment>
    )
}


//Connection between component to store

const mapStateToProp = state => ({
    editrequest: state.editEventReducer,
    allseason: state.getAllSeasonReducer,
})

const mapDispatchToProps = dispatch => ({
    editEventHandler: user => dispatch(editEventData(user)),
    // getEventDataHandler: user => dispatch(getDatas(user)),
    // getEventHandler: user => dispatch(getAllEvent(user)),
    getSeasonHandler: (user) => dispatch(getAllSeason(user)),
    getAllStateHandler: user => dispatch(getAllState()),
    getAllCityHandler: user => dispatch(getAllCity(user)),
    resetEditEventReducer: () => dispatch({ type: RESET_EDIT_EVENT }),
    getOrgEventDetailsHandler: user => dispatch(getClubOrgEventDetails(user)),
    getPaymentCodeHandler: user => dispatch(getPaymentCode(user)),
    getPaymentCodeHandler1: user => dispatch(getPaymentCode1(user)),
    sendImageHandler: user => dispatch(sendImage(user))
})
export default connect(mapStateToProp, mapDispatchToProps)((Edit_Event));