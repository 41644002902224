import { ADD_ACCOUNT_ERROR, ADD_ACCOUNT_LOADING, ADD_ACCOUNT_SUCCESS, RESET_ADDACCOUNT } from "../constants";

const initialState = {
    error: false,
    success: false,
    loading: false,
    errors: [],
    user: {}
}

export default function addAccountReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_ACCOUNT_LOADING:
        // alert("add load reduc")
            return { ...state, error:false,success:false,loading:true}
        case ADD_ACCOUNT_SUCCESS:
            // alert("add success reduc")
            return { ...state, error: false, success: true, loading: false,user:action.payload,message:action.msg}
        case ADD_ACCOUNT_ERROR:
            // alert("add err reduc")
            return { ...state, error: true, success: false, loading: false, errors: [] }
        case RESET_ADDACCOUNT:
            return {...state,error:false, success:false, loading:false,errors:[]}

        default:
            return state
    }
}