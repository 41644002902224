import React, { useState, useEffect } from "react";
import { connect, useSelector , useDispatch} from "react-redux";
import { toast } from "react-toastify";
import {
  createBankDetails,
  createMerchandAccount,
  getBanks,
} from "../../../service/action/Club/clubAction";
import {
  RESET_BANK,
  RESET_BANKDETAILS,
  RESET_EDIT_BANK,
} from "../../../service/constants";
import Toaster from "../../../Toaster/Toaster";
import classNames from "classnames";
import { getAllCity, getAllState, getAccountDetails } from "../../../service/action/userAction";
import axios from "axios";
import { create_merchand_account_Url } from "../../../apiSheet";
import InputMask from "react-input-mask";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactDatePicker from "react-datepicker";

const MerchantAccount = (props) => {
  const type = localStorage.getItem("type");
  const userId = localStorage.getItem("Id");
  // const allBank = useSelector(state => state.getBanksReducer.user)
  const deleteMessage = useSelector((state) => state.deleteBankReducer.message);
  const Msg = useSelector(
    (state) => state.createMerchandAccountReducer.message
  );
  const allstate = useSelector(state => state.getStateReducer.states)
  const allcity = useSelector(state => state.getCityReducer.citys)
  const accountDetails = useSelector(state => state.accountDetailsReducer)
  
  // const preferred = allBank.find(e => e.default == 1)
  // const alternet = allBank.filter((e) => e.default !== 1)

  const [isOpen, setIsOpen] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [stateID, setStateID] = useState('')
  const dispatch = useDispatch()
  

  const initialState = {
    reseller_username: "",
    reseller_password: "",
    reseller_key: "",
    correlation_id: "",
    application_template_id: "a0R0x0000029lhoEAA",
    terminal_template_ids: "a140x00000TcN00",
    company_legal_name: "",
    company_dba_name: "",
    company_ownership_type: "",
    company_url: "",
    company_federal_tax_id: "",
    company_soft_descriptor: "",
    Customer_Support_Number: "",
    client_merchant_id: "",
    company_phone_number: "",
    legal_address: "",
    legal_postal_code: "",
    legal_city: "",
    legal_state: "",
    dba_address: "",
    dba_postal_code: "",
    dba_city: "",
    dba_state: "",
    dba_phone_number: "",
    owner_Principal_first_name: "",
    owner_Principal_middle_name: "",
    owner_Principal_last_name: "",
    owner_Principal_email_address: "",
    owner_Principal_phone: "",
    owner_Principal_date_of_birth: "",
    owner_Principal_social_security_number: "",
    owner_Principal_drivers_license_number: "",
    owner_Principal_address: "",
    owner_Principal_postal_code: "",
    owner_Principal_city: "",
    owner_Principal_state: "",
    bank_routing_number: "",
    bank_account_number: "",
    amex_sen: "",
    average_transaction_amount: "",
    highest_ticket: "",
    monthly_processing_volume: "",
    business_start_date: "",
    return_user_activation_URL: "",
  };

  const [bank, setBank] = useState({
    reseller_username: "215purefacapi",
    reseller_password: "5LECXCyZ",
    reseller_key: 215,
    correlation_id: "",
    application_template_id: "a0R0x0000029lhoEAA",
    terminal_template_ids: "a140x00000TcN00",
    company_legal_name: "",
    company_dba_name: "",
    company_ownership_type: "",
    company_url: "",
    company_federal_tax_id: "",
    company_soft_descriptor: "",
    Customer_Support_Number: "",
    client_merchant_id: "",
    company_phone_number: "",
    legal_address: "",
    legal_postal_code: "",
    legal_city: "",
    legal_state: "",
    dba_address: "",
    dba_postal_code: "",
    dba_city: "",
    dba_state: "",
    dba_phone_number: "",
    owner_Principal_first_name: "",
    owner_Principal_middle_name: "",
    owner_Principal_last_name: "",
    owner_Principal_email_address: "",
    owner_Principal_phone: "",
    owner_Principal_date_of_birth: "",
    owner_Principal_social_security_number: "",
    owner_Principal_drivers_license_number: "",
    owner_Principal_address: "",
    owner_Principal_postal_code: "",
    owner_Principal_city: "",
    owner_Principal_state: "",
    bank_routing_number: "",
    bank_account_number: "",
    amex_sen: "",
    average_transaction_amount: "",
    highest_ticket: "",
    monthly_processing_volume: "",
    business_start_date: "",
    return_user_activation_URL: "",
  });

  const [errors, setErrors] = useState({
    reseller_username: false,
    reseller_password: false,
    reseller_key: false,
    correlation_id: false,
    application_template_id: false,
    terminal_template_ids: false,
    company_legal_name: false,
    company_dba_name: false,
    company_ownership_type: false,
    company_url: false,
    company_federal_tax_id: false,
    company_soft_descriptor: false,
    Customer_Support_Number: false,
    client_merchant_id: false,
    company_phone_number: false,
    legal_address: false,
    legal_postal_code: false,
    legal_city: false,
    legal_state: false,
    dba_address: false,
    dba_postal_code: false,
    dba_city: false,
    dba_state: false,
    dba_phone_number: false,
    owner_Principal_first_name: false,
    owner_Principal_middle_name: false,
    owner_Principal_last_name: false,
    owner_Principal_email_address: false,
    owner_Principal_phone: false,
    owner_Principal_date_of_birth: false,
    owner_Principal_social_security_number: false,
    owner_Principal_drivers_license_number: false,
    owner_Principal_address: false,
    owner_Principal_postal_code: false,
    owner_Principal_city: false,
    owner_Principal_state: false,
    bank_routing_number: false,
    bank_account_number: false,
    amex_sen: false,
    average_transaction_amount: false,
    highest_ticket: false,
    monthly_processing_volume: false,
    business_start_date: false,
    return_user_activation_URL: false,
  });


  useEffect(()=> {
      dispatch(getAllState())
    }, [])


    useEffect(()=> {
      dispatch(getAllCity({id: stateID}))
    }, [stateID])

    
    useEffect(()=> {
      const state = allstate.find(s => s.name === bank.legal_state)
      setStateID(state?._id)
    }, [bank.legal_state])




  const bankMsg = useSelector(
    (state) => state.createBankDetailsReducer.message
  );
  const editbankMsg = useSelector((state) => state.editBankReducer.message);
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    setBank((prevState) => ({
      ...prevState,
      [name]: value,
      // [e.target.name]: e.target.value.trim()
    }));
    switch (name) {
      case "company_legal_name":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            company_legal_name: "Comapany legal can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            company_legal_name: false,
          }));
        }
        break;

      case "company_dba_name":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            company_dba_name: "Company dba name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            company_dba_name: false,
          }));
        }
        break;

      case "company_ownership_type":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            company_ownership_type: "Company ownership type can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            company_ownership_type: false,
          }));
        }
        break;

      case "company_url":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            company_url: "Company dba name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            company_url: false,
          }));
        }
        break;

      case "company_federal_tax_id":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            company_federal_tax_id: "Company federal tax id can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            company_federal_tax_id: false,
          }));
        }
        break;

      case "company_soft_descriptor":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            company_soft_descriptor: "Company dba name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            company_soft_descriptor: false,
          }));
        }
        break;

      case "Customer_Support_Number":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            Customer_Support_Number: "Company dba name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            Customer_Support_Number: false,
          }));
        }
        break;

      case "company_phone_number":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            company_phone_number: "Company dba name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            company_phone_number: false,
          }));
        }
        break;

      case "legal_address":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            legal_address: "legal_address can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            legal_address: false,
          }));
        }
        break;

      case "legal_postal_code":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            legal_postal_code: "Postal code can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            legal_postal_code: false,
          }));
        }
        break;

      case "legal_state":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            legal_state: "legal_state can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            legal_state: false,
          }));
        }
        break;

      case "legal_city":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            legal_city: "legal_city can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            legal_city: false,
          }));
        }
        break;

      case "dba_address":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            dba_address: "dba_address can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            dba_address: false,
          }));
        }
        break;

      case "dba_postal_code":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            dba_postal_code: "Postal code can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            dba_postal_code: false,
          }));
        }
        break;

      case "dba_state":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            dba_state: "dba_state can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            dba_state: false,
          }));
        }
        break;

      case "dba_city":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            dba_city: "dba_city can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            dba_city: false,
          }));
        }
        break;

      case "dba_phone_number":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            dba_phone_number: "Company dba name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            dba_phone_number: false,
          }));
        }
        break;

      case "owner_Principal_first_name":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_first_name: "First name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_first_name: false,
          }));
        }
        break;

      case "owner_Principal_middle_name":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_middle_name: "First name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_middle_name: false,
          }));
        }
        break;

      case "owner_Principal_last_name":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_last_name: "Last name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_last_name: false,
          }));
        }
        break;

      case "owner_Principal_email_address":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_email_address: "Email address can not be empty",
          }));
        } else if (!validEmailRegex.test(value)) {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_email_address: "Email is not valid !",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_email_address: false,
          }));
        }
        break;

      case "owner_Principal_phone":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_phone:
              "owner_Principal_phone number can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_phone: false,
          }));
        }
        break;

      case "owner_Principal_date_of_birth":
        setDob(value);
        if (value) {
          const y = new Date(value);
          const c = new Date();
          const selectYear = y.getFullYear();
          const currentYear = c.getFullYear();
          const diff = currentYear - selectYear;
          if (diff < 18) {
            setErrors((prevState) => ({
              ...prevState,
              owner_Principal_date_of_birth: "Age must be 18 or above",
            }));
          } else {
            setErrors((prevState) => ({
              ...prevState,
              owner_Principal_date_of_birth: false,
            }));
          }
        }
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_date_of_birth: "Please select date of birth",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_date_of_birth: false,
          }));
        }
        break;

      case "owner_Principal_social_security_number":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_social_security_number:
              "Social security number can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_social_security_number: false,
          }));
        }
        break;

      case "owner_Principal_drivers_license_number":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_drivers_license_number:
              "Driving license number can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_drivers_license_number: false,
          }));
        }
        break;

      case "owner_Principal_address":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_address: "owner_Principal_address can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_address: false,
          }));
        }
        break;

      case "owner_Principal_postal_code":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_postal_code: "Postal code can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_postal_code: false,
          }));
        }
        break;

      case "owner_Principal_city":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_city: "owner_Principal_city can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_city: false,
          }));
        }
        break;

      case "owner_Principal_state":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_state: "owner_Principal_state can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            owner_Principal_state: false,
          }));
        }
        break;

      case "bank_routing_number":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            bank_routing_number: "Bank routing number can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            bank_routing_number: false,
          }));
        }
        break;

      case "bank_account_number":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            bank_account_number: "Bank account number can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            bank_account_number: false,
          }));
        }
        break;

      case "amex_sen":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            amex_sen: "amex_sen can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            amex_sen: false,
          }));
        }
        break;

      case "average_transaction_amount":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            average_transaction_amount:
              "average_transaction_amount can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            average_transaction_amount: false,
          }));
        }
        break;

      case "highest_ticket":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            highest_ticket: "highest_ticket can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            highest_ticket: false,
          }));
        }
        break;

      case "monthly_processing_volume":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            monthly_processing_volume:
              "monthly_processing_volume can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            monthly_processing_volume: false,
          }));
        }
        break;

      case "business_start_date":
        setDob(value);
        if (value) {
          const y = new Date(value);
          const c = new Date();
          const selectYear = y.getFullYear();
          const currentYear = c.getFullYear();
          const diff = currentYear - selectYear;
          if (diff < 18) {
            setErrors((prevState) => ({
              ...prevState,
              business_start_date: "Age must be 18 or above",
            }));
          } else {
            setErrors((prevState) => ({
              ...prevState,
              business_start_date: false,
            }));
          }
        }
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            business_start_date: "Please select date of birth",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            business_start_date: false,
          }));
        }
        break;

      case "return_user_activation_URL":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            return_user_activation_URL:
              "return_user_activation_URL can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            return_user_activation_URL: false,
          }));
        }
        break;

      default:
    }
  };

  const [details, setDetails] = useState({
    reseller_username: "",
    reseller_password: "",
    reseller_key: "",
    correlation_id: "",
    application_template_id: "a0R0x0000029lhoEAA",
    terminal_template_ids: "a140x00000TcN00",
    company: {
      company_legal_name: "",
      company_dba_name: "",
      company_ownership_type: "",
      company_url: "",
      company_federal_tax_id: "",
      company_soft_descriptor: "",
      Customer_Support_Number: "",
      client_merchant_id: "",
      company_phone_number: "",
      legal_address: {
        address: "",
        postal_code: "",
        city: "",
        state: "",
      },
      dba_address: {
        address: "",
        postal_code: "",
        city: "",
        state: "",
        dba_phone_number: "",
      },
    },
    owner_Principal: {
      first_name: "",
      middle_name: "",
      last_name: "",
      email_address: "",
      phone: "",
      date_of_birth: "",
      social_security_number: "",
      drivers_license_number: "",
      address: {
        address: "",
        postal_code: "",
        city: "",
        state: "",
      },
    },
    bank: {
      bank_routing_number: "",
      bank_account_number: "",
    },
    payment_types: {
      amex_sen: "",
    },
    risk_profile: {
      average_transaction_amount: "",
      highest_ticket: "",
      monthly_processing_volume: "",
      business_start_date: "",
    },
    return_user_activation_URL: "",
  });
  const submit = (e) => {
    e.preventDefault();
    if (bank.company_legal_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        company_legal_name: "Company legal name can not be empty",
      }));
    }

    if (bank.company_dba_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        company_dba_name: "Company dba name can not be empty",
      }));
    }

    if (bank.company_ownership_type === "") {
      setErrors((prevState) => ({
        ...prevState,
        company_ownership_type: "Please select owenership type",
      }));
    }

    if (bank.company_url === "") {
      setErrors((prevState) => ({
        ...prevState,
        company_url: "company url can not be empty",
      }));
    }

    if (bank.company_federal_tax_id === "") {
      setErrors((prevState) => ({
        ...prevState,
        company_federal_tax_id: "Company federal tax id can not be empty",
      }));
    }
    if (bank.company_federal_tax_id.length < 9) {
      setErrors((prevState) => ({
        ...prevState,
        company_federal_tax_id: "Company federal tax id must be 9 characters",
      }));
    }

    if (bank.company_soft_descriptor === "") {
      setErrors((prevState) => ({
        ...prevState,
        company_soft_descriptor: "company url can not be empty",
      }));
    }

    if (bank.Customer_Support_Number === "") {
      setErrors((prevState) => ({
        ...prevState,
        Customer_Support_Number: "Company legal name can not be empty",
      }));
    }

    if (bank.company_phone_number === "") {
      setErrors((prevState) => ({
        ...prevState,
        company_phone_number: "Company legal name can not be empty",
      }));
    }

    if (bank.legal_address === "") {
      setErrors((prevState) => ({
        ...prevState,
        legal_address: "legal_address can not be empty",
      }));
    }

    if (bank.legal_postal_code === "") {
      setErrors((prevState) => ({
        ...prevState,
        legal_postal_code: "Postal code can not be empty",
      }));
    }
    if (bank.legal_postal_code < 5) {
      setErrors((prevState) => ({
        ...prevState,
        legal_postal_code: "Postal code must be 5 character long",
      }));
    }

    if (bank.legal_state === "") {
      setErrors((prevState) => ({
        ...prevState,
        legal_state: "legal_state can not be empty",
      }));
    }

    if (bank.legal_city === "") {
      setErrors((prevState) => ({
        ...prevState,
        legal_city: "legal_city can not be empty",
      }));
    }

    if (bank.dba_address === "") {
      setErrors((prevState) => ({
        ...prevState,
        dba_address: "dba_address can not be empty",
      }));
    }

    if (bank.dba_postal_code === "") {
      setErrors((prevState) => ({
        ...prevState,
        dba_postal_code: "Postal code can not be empty",
      }));
    }
    if (bank.legal_postal_code < 5) {
      setErrors((prevState) => ({
        ...prevState,
        legal_postal_code: "Postal code must be 5 character long",
      }));
    }

    if (bank.dba_state === "") {
      setErrors((prevState) => ({
        ...prevState,
        dba_state: "dba_state can not be empty",
      }));
    }

    if (bank.dba_city === "") {
      setErrors((prevState) => ({
        ...prevState,
        dba_city: "dba_city can not be empty",
      }));
    }

    if (bank.dba_phone_number === "") {
      setErrors((prevState) => ({
        ...prevState,
        dba_phone_number: "dba_phone_number can not be empty",
      }));
    }

    if (bank.owner_Principal_first_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        owner_Principal_first_name: "First name can not be empty",
      }));
    }

    if (bank.owner_Principal_middle_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        owner_Principal_middle_name: "First name can not be empty",
      }));
    }

    if (bank.owner_Principal_last_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        owner_Principal_last_name: "Last name can not be empty",
      }));
    }

    if (bank.owner_Principal_email_address === "") {
      setErrors((prevState) => ({
        ...prevState,
        owner_Principal_email_address: "Email address can not be empty",
      }));
    }

    if (bank.owner_Principal_phone === "") {
      setErrors((prevState) => ({
        ...prevState,
        owner_Principal_phone: "owner_Principal_phone can not be empty",
      }));
    }

    if (bank.owner_Principal_date_of_birth === "") {
      setErrors((prevState) => ({
        ...prevState,
        owner_Principal_date_of_birth: "Date of birth can not be empty",
      }));
    }

    if (bank.owner_Principal_social_security_number === "") {
      setErrors((prevState) => ({
        ...prevState,
        owner_Principal_social_security_number:
          "Social security number can not be empty",
      }));
    }

    if (
      (bank.owner_Principal_social_security_number.length < 9 ||
        bank.owner_Principal_social_security_number.length > 9) &&
      bank.owner_Principal_social_security_number !== ""
    ) {
      setErrors((prevState) => ({
        ...prevState,
        owner_Principal_social_security_number:
          "Social security number must be 9 character long",
      }));
    }

    if (bank.owner_Principal_address === "") {
      setErrors((prevState) => ({
        ...prevState,
        owner_Principal_address: "owner_Principal_address can not be empty",
      }));
    }

    if (bank.owner_Principal_postal_code === "") {
      setErrors((prevState) => ({
        ...prevState,
        owner_Principal_postal_code: "Postal code can not be empty",
      }));
    }
    if (bank.owner_Principal_postal_code < 5) {
      setErrors((prevState) => ({
        ...prevState,
        owner_Principal_postal_code: "Postal code must be 5 character long",
      }));
    }

    if (bank.owner_Principal_state === "") {
      setErrors((prevState) => ({
        ...prevState,
        owner_Principal_state: "owner_Principal_state can not be empty",
      }));
    }

    if (bank.owner_Principal_city === "") {
      setErrors((prevState) => ({
        ...prevState,
        owner_Principal_city: "owner_Principal_city can not be empty",
      }));
    }

    if (bank.owner_Principal_drivers_license_number === "") {
      setErrors((prevState) => ({
        ...prevState,
        owner_Principal_drivers_license_number:
          "Drivers license number can not be empty",
      }));
    }

    if (bank.bank_routing_number === "") {
      setErrors((prevState) => ({
        ...prevState,
        bank_routing_number: "Bank routing number can not be empty",
      }));
    }

    if (
      (bank.bank_routing_number.length > 9 ||
        bank.bank_routing_number.length < 9) &&
      bank.bank_routing_number !== ""
    ) {
      setErrors((prevState) => ({
        ...prevState,
        bank_routing_number: "Bank routing number must be 9 characters",
      }));
    }

    if (bank.bank_account_number === "") {
      setErrors((prevState) => ({
        ...prevState,
        bank_account_number: "Bank account number can not be empty",
      }));
    }

    if (bank.amex_sen === "") {
      setErrors((prevState) => ({
        ...prevState,
        amex_sen: "Bank routing number can not be empty",
      }));
    }

    if (bank.average_transaction_amount === "") {
      setErrors((prevState) => ({
        ...prevState,
        average_transaction_amount: "Bank routing number can not be empty",
      }));
    }

    if (bank.highest_ticket === "") {
      setErrors((prevState) => ({
        ...prevState,
        highest_ticket: "Bank routing number can not be empty",
      }));
    }

    if (bank.monthly_processing_volume === "") {
      setErrors((prevState) => ({
        ...prevState,
        monthly_processing_volume: "Bank routing number can not be empty",
      }));
    }

    if (bank.business_start_date === "") {
      setErrors((prevState) => ({
        ...prevState,
        business_start_date: "Bank routing number can not be empty",
      }));
    }

    if (bank.return_user_activation_URL === "") {
      setErrors((prevState) => ({
        ...prevState,
        return_user_activation_URL: "Bank routing number can not be empty",
      }));
    }

    if (bank.phone?.length > 10) {
      var ret = bank.phone.replace(
        /[`~!@#$%^&*()_|+\-=?;:'",.<>{}\[\]\\\/]/gi,
        ""
      );
      var mobileNum = ret;
    } else {
      var mobileNum = bank.phone;
    }

    if (
      bank.company_legal_name !== "" &&
      bank.company_dba_name !== "" &&
      bank.company_ownership_type !== "" &&
      bank.company_federal_tax_id !== "" &&
      bank.address !== "" &&
      bank.postal_code !== "" &&
      bank.state !== "" &&
      bank.city !== "" &&
      bank.first_name !== "" &&
      bank.last_name !== "" &&
      bank.email_address !== "" &&
      bank.phone !== "" &&
      bank.social_security_number !== "" &&
      !(bank.social_security_number?.length < 9) &&
      bank.drivers_license_number !== "" &&
      bank.bank_routing_number !== "" &&
      !(bank.bank_routing_number < 9) &&
      bank.bank_account_number !== ""
    ) {
      props.createMerchandAccountHandler({
        username: "TestUser_y1Huh6atLE-API",
        password: "Bcy1rOfNxq5k448zNnPV62xdV",
      });
      setDetails((prevState) => ({
        ...prevState,
        reseller_username: "215purefacapi",
        reseller_password: "5LECXCyZ",
        reseller_key: 215,
        correlation_id: "",
        application_template_id: "a0R0x0000029lhoEAA",
        terminal_template_ids: "a140x00000TcN00",
        company: {
          company_legal_name: bank.company_legal_name,
          company_dba_name: bank.company_dba_name,
          company_ownership_type: bank.company_ownership_type,
          company_url: bank?.company_url,
          company_federal_tax_id: bank.company_federal_tax_id,
          company_soft_descriptor: bank?.company_soft_descriptor,
          Customer_Support_Number: bank?.Customer_Support_Number,
          client_merchant_id: "",
          company_phone_number: bank?.company_phone_number,
          legal_address: {
            address: bank?.legal_address,
            postal_code: bank?.legal_postal_code,
            city: bank?.legal_city,
            state: bank?.legal_state,
          },
          dba_address: {
            address: bank?.dba_address,
            postal_code: bank?.dba_postal_code,
            city: bank?.dba_city,
            state: bank?.dba_state,
            dba_phone_number: bank?.dba_phone_number,
          },
        },
        owner_Principal: {
          first_name: bank?.owner_Principal_first_name,
          middle_name: bank?.owner_Principal_middle_name,
          last_name: bank.owner_Principal_last_name,
          email_address: bank.owner_Principal_email_address,
          phone: bank?.owner_Principal_phone,
          date_of_birth: moment(bank.owner_Principal_date_of_birth).format(
            "MM/DD/YYYY"
          ),
          social_security_number: bank.owner_Principal_social_security_number,
          drivers_license_number: bank.owner_Principal_drivers_license_number,
          address: {
            address: bank?.owner_Principal_address,
            postal_code: bank?.owner_Principal_postal_code,
            city: bank?.owner_Principal_city,
            state: bank?.owner_Principal_state,
          },
        },
        bank: {
          bank_routing_number: bank.bank_routing_number,
          bank_account_number: bank.bank_account_number,
        },
        payment_types: {
          amex_sen: bank?.amex_sen,
        },
        risk_profile: {
          average_transaction_amount: bank?.average_transaction_amount,
          highest_ticket: bank?.highest_ticket,
          monthly_processing_volume: bank?.monthly_processing_volume,
          business_start_date: bank?.business_start_date,
        },
        return_user_activation_URL: bank?.return_user_activation_URL,
      }));
    }
  };

  useEffect(() => {
    // if(success){
    //     reseller_username: "215purefacapi",
    //     reseller_password: "5LECXCyZ",
    //     reseller_key: 215,
    //     application_template_id: bank.application_template_id,
    //     correlation_id: "",
    //     terminal_template_ids: bank.terminal_template_ids,
    //     company: {
    //         company_legal_name: bank.company_legal_name,
    //         company_dba_name: bank.company_dba_name,
    //         company_ownership_type:  bank.company_ownership_type,
    //         company_federal_tax_id: bank.company_federal_tax_id,
    //         legal_address: {
    //             address: bank.address,
    //             postal_code:  bank.postal_code,
    //             city: bank.city,
    //             state:bank.state
    //         }
    //     },
    //     owner_Principal: {
    //         first_name: bank.first_name,
    //         last_name: bank.last_name,
    //         email_address: bank.email_address,
    //         phone: bank.phone,
    //         date_of_birth: dob,
    //         social_security_number: bank.social_security_number,
    //         drivers_license_number: bank.drivers_license_number,
    //     },
    //     bank: {
    //         bank_routing_number: bank.bank_routing_number,
    //         bank_account_number: bank.bank_account_number
    //     }
    // }
  }, []);

  const notify = (msg) => toast(msg);
  function resetData() {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }

  //clear data on submit.
  const clearState = () => {
    setBank({ ...initialState });
  };

  useEffect(() => {
    if (props.submit?.success) {
      notify(bankMsg.message);
      resetData().then(clearState);
      props.resetCreateBankdetailsReducer();
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.deleteMsg?.success) {
      notify(deleteMessage.message);
      props.resetDeleteBankReducer();
    }
  }, [props.deleteMsg]);

  useEffect(() => {
    // alert("a")
    if (props.merchand?.success) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${Msg.access_token}`,
        },
        // url: create_merchand_account_Url,
        url: "https://stage.paragonsolutions.com/api/v2/%7BversionName%7D/correlationId?versionName=pdf",
      };

      axios(requestOptions)
        .then((resp) => {
          setDetails((prevState) => ({
            ...prevState,
            correlation_id: resp.data.correlationId,
          }));

          // const requestOptions = {
          //     method: "POST",
          //     headers: {
          //         "Content-Type": "application/json",
          //     },
          //     url: "https://stage.leadapi.paragonsolutions.com/v2/lead",
          //     data: details
          // };
          // if (details.correlation_id !== "") {
          //     axios(requestOptions).then(resp => {
          //     }).catch(err => {
          //     })
          // }
        })
        .catch((err) => {
          // Handle Error Here
          console.error(err);
        });
      notify(Msg.message);
      // props.resetCreatemerchandaccountHandler()
      // resetData().then(clearState)
    }
  }, [props.merchand]);

  useEffect(() => {
    if (details.correlation_id) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url: "https://stage.leadapi.paragonsolutions.com/v2/lead",
        data: details,
      };

      axios(requestOptions)
        .then((resp) => {
          const localServerData = {
            ...resp.data,
            user_type: type,
            user_id: userId,
          };
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // 'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            url: create_merchand_account_Url,
            data: localServerData,
          };
          axios(requestOptions)
            .then((resp) => {
              notify("Merchant Account Created Successfully");
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    }
  }, [details.correlation_id]);

  // const [selectedDate, handleDateChange] = useState(null)

  
  const [dob, setDob] = useState(null);
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head-event">
              <h5 className="dash-head">Merchant Account</h5>
              <div className="mt-3 mt-md-0 text-right">
                <button className="btn-save" onClick={submit}>
                  Save Changes
                </button>
              </div>
            </div>
          </div>

          <div className="bank-info-tab mt-4 mt-md-0">
            <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-10 px-0">
              <h5 className="payment text-left mt-42">Company</h5>
              <div className="row">
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      error: errors?.company_legal_name,
                    })}
                    placeholder=" Company Legal Name *"
                    name="company_legal_name"
                    id="company_legal_name"
                    value={bank.company_legal_name}
                    onChange={eventHandler}
                    autoComplete="off"
                    maxLength="50"
                  />
                  {errors?.company_legal_name && (
                    <span className="text-red small d-block">
                      Enter company legal name
                    </span>
                  )}
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      error: errors?.company_dba_name,
                    })}
                    placeholder="Company DBA Name *"
                    name="company_dba_name"
                    id="company_dba_name"
                    value={bank.company_dba_name}
                    onChange={eventHandler}
                    maxLength="50"
                  />
                  {errors?.company_dba_name && (
                    <span className="text-red small d-block">
                      Enter company DBA name
                    </span>
                  )}
                </div>
                <div className="col-md-6 mt-18">
                  {/* <input type="text" className="form-control team-name" placeholder="Company Ownership Type" name="company_ownership_type" id="company_ownership_type" value={bank.company_ownership_type} onChange={eventHandler} />
                                        {errors.company_ownership_type && <span className='text-red small d-block'>* {errors.company_ownership_type}</span>} */}
                  <select
                    className={classNames("form-control team-name payterm", {
                      error: errors?.company_ownership_type,
                    })}
                    id="company_ownership_type"
                    name="company_ownership_type"
                    value={bank.company_ownership_type}
                    onChange={eventHandler}
                  >
                    <option value="">Company Ownership Type *</option>
                    <option value="Sole Proprietorship">
                      Sole Proprietorship
                    </option>
                    <option value="Corporation">Corporation</option>
                    <option value="Partnership">Partnership</option>
                    <option value="LLC">LLC</option>
                    <option value="Non-Profit"> Non-Profit</option>
                    {/* <option value="Government "> Government </option> */}
                  </select>
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      error: errors?.company_federal_tax_id,
                    })}
                    placeholder="Company Federal Tax Id *"
                    name="company_federal_tax_id"
                    id="company_federal_tax_id"
                    value={bank.company_federal_tax_id}
                    onChange={eventHandler}
                    minLength="9"
                    maxLength="9"
                  />
                  {errors?.company_federal_tax_id && (
                    <span className="text-red small d-block">
                      Enter company fedral tax id
                    </span>
                  )}
                </div>
              </div>

              <h5 className="payment text-left mt-42">Legal Address</h5>
              <div className="row">
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      error: errors?.legal_address,
                    })}
                    placeholder="Legal Address *"
                    name="legal_address"
                    id="legal_address"
                    value={bank.legal_address}
                    onChange={eventHandler}
                    autoComplete="off"
                    maxLength="50"
                  />
                     {errors?.legal_address && (
                    <span className="text-red small d-block">
                      Enter legal address
                    </span>
                  )}
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="tel"
                    className={classNames("form-control team-name", {
                      error: errors?.legal_postal_code,
                    })}
                    placeholder="Legal Postal Code *"
                    name="legal_postal_code"
                    id="legal_postal_code"
                    value={bank.legal_postal_code}
                    onChange={eventHandler}
                    maxLength="5"
                  />
                   {errors?.legal_postal_code && (
                    <span className="text-red small d-block">
                      Enter legal address
                    </span>
                  )}
                </div>
                <div className="col-md-6 mt-18">
                  <select className={classNames("form-control team-name", {
                      error: errors?.legal_state,
                    })} name="legal_state" id="legal_state" onChange={eventHandler}>
                    <option>Select satate</option>
                   {allstate && allstate.map((state, index)=> {
                    return <option key={index}>{state?.name}</option>
                   })
                    
                   }
                   {errors?.legal_state && (
                    <span className="text-red small d-block">
                      Enter legal state
                    </span>
                  )}
                  </select>
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    list="cityNameList"
                    className={classNames("form-control team-name", {
                      error: errors?.legal_city,
                    })}
                    placeholder="Legal City *"
                    name="legal_city"
                    id="legal_city"
                    value={bank.legal_city}
                    onChange={eventHandler}
                    autoComplete="off"
                  />
                   {errors?.legal_city && (
                    <span className="text-red small d-block">
                      Enter legal city
                    </span>
                  )}

                <datalist id="cityNameList">
                        {allcity.map((e, i) => {
                          return <option value={e.name} key={i} />;
                        })}
                      </datalist>
                </div>
              </div>

              <h5 className="payment text-left mt-42">Owner Principal</h5>
              <div className="row">
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      error: errors?.owner_Principal_first_name,
                    })}
                    placeholder=" Owner Principal First Name *"
                    name="owner_Principal_first_name"
                    id="owner_Principal_first_name"
                    value={bank.owner_Principal_first_name}
                    onChange={eventHandler}
                    autoComplete="off"
                    maxLength="40"
                  />
                   {errors?.owner_Principal_first_name && (
                    <span className="text-red small d-block">
                      Enter first name
                    </span>
                  )}
                </div>

                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      error: errors?.owner_Principal_last_name,
                    })}
                    placeholder="Owner Principal Last Name *"
                    name="owner_Principal_last_name"
                    id="owner_Principal_last_name"
                    value={bank.owner_Principal_last_name}
                    onChange={eventHandler}
                    maxLength="50"
                  />
                  {errors?.owner_Principal_last_name && (
                    <span className="text-red small d-block">
                      Enter last name
                    </span>
                  )}
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="email"
                    className={classNames("form-control team-name", {
                      error: errors?.owner_Principal_email_address,
                    })}
                    placeholder="Owner Principal Email Address *"
                    name="owner_Principal_email_address"
                    id="owner_Principal_email_address"
                    value={bank.owner_Principal_email_address}
                    onChange={eventHandler}
                  />
                  {errors?.owner_Principal_email_address && (
                    <span className="text-red small d-block">
                      Enter email address
                    </span>
                  )}
                </div>
                <div className="col-md-6 mt-18">
                  <InputMask
                    mask="999-999-9999"
                    className={classNames("form-control team-name", {
                      error: errors.owner_Principal_phone,
                    })}
                    value={bank.owner_Principal_phone}
                    onChange={eventHandler}
                    maskChar={"_"}
                    placeholder="Owner Principal Phone * "
                    name="owner_Principal_phone"
                    id="owner_Principal_phone"
                  />
                  {errors?.owner_Principal_phone && (
                    <span className="text-red small d-block">
                      Enter phone number
                    </span>
                  )}
                  {/* <input type="number" className="form-control team-name" placeholder="Phone *" name="phone" id="phone" value={bank.phone} onChange={eventHandler} maxLength="10" /> */}
                </div>
                <div className="col-md-6 mt-18">
                  <ReactDatePicker
                    showYearDropdown
                    autoComplete="off"
                    name="owner_Principal_date_of_birth"
                    placeholderText="Owner Principal Date Of Birth"
                    dateFormat={"MM-dd-yyyy"}
                    selected={bank?.owner_Principal_date_of_birth}
                    className={classNames("form-control mt-1", {
                      requireField: errors.owner_Principal_date_of_birth,
                    })}
                    maxDate={new Date()}
                    onChange={(date) => {
                      setBank((prevState) => ({
                        ...prevState,
                        owner_Principal_date_of_birth: date,
                      }));
                      if (date === null || date === "") {
                        setErrors((prevState) => ({
                          ...prevState,
                          owner_Principal_date_of_birth:
                            "Enter Valid Date of Birth",
                        }));
                      } else {
                        setErrors((prevState) => ({
                          ...prevState,
                          owner_Principal_date_of_birth: false,
                        }));
                      }
                    }}
                  />
                  {errors.owner_Principal_date_of_birth && (
                    <span className="text-red small d-block">
                      Enter date in MM-DD-YYYY formate
                    </span>
                  )}
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      error: errors?.owner_Principal_social_security_number,
                    })}
                    placeholder="Owner Pricipal Social Security Number *"
                    name="owner_Principal_social_security_number"
                    id="owner_Principal_social_security_number"
                    value={bank.owner_Principal_social_security_number}
                    onChange={eventHandler}
                    maxLength="9"
                    minLength="9"
                  />
                  {errors.owner_Principal_social_security_number && (
                    <span className="text-red small d-block">
                      Enter social security number
                    </span>
                  )}
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      error: errors?.owner_Principal_drivers_license_number,
                    })}
                    placeholder="Owner Principal Drivers License Number *"
                    name="owner_Principal_drivers_license_number"
                    id="owner_Principal_drivers_license_number"
                    value={bank.owner_Principal_drivers_license_number}
                    onChange={eventHandler}
                    maxLength="20"
                  />
                   {errors.owner_Principal_drivers_license_number && (
                    <span className="text-red small d-block">
                      Enter driver license number
                    </span>
                  )}
                </div>
              </div>

              <h5 className="payment text-left mt-42">Bank</h5>
              <div className="row">
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      error: errors?.bank_routing_number,
                    })}
                    placeholder="Bank Routing Number *"
                    name="bank_routing_number"
                    id="bank_routing_number"
                    value={bank.bank_routing_number}
                    onChange={eventHandler}
                    autoComplete="off"
                    minLength="9"
                    maxLength="9"
                  />

              {errors.bank_routing_number && (
                    <span className="text-red small d-block">
                      Enter bank routing number
                    </span>
                  )}


                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      error: errors?.bank_account_number,
                    })}
                    placeholder="Bank Account Number *"
                    name="bank_account_number"
                    id="bank_account_number"
                    value={bank.bank_account_number}
                    onChange={eventHandler}
                    maxLength="17"
                  />
                  {
                    errors.bank_account_number && (
                      <span className="text-red small d-block">
                        Enter bank routing number
                      </span>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};

// export default BankInfo;
const mapStateToProp = (state) => ({
  submit: state.createBankDetailsReducer,
  deleteMsg: state.deleteBankReducer,
  editMsg: state.editBankReducer,
  merchand: state.createMerchandAccountReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // createFieldhandler: user => dispatch(createField(user)),
  // resetCreateBankdetailsReducer: () => dispatch({ type: RESET_BANKDETAILS }),
  // reseteditBankdetailsReducer: () => dispatch({ type: RESET_EDIT_BANK }),
  // resetDeleteBankReducer: () => dispatch({ type: RESET_BANK }),
  // bankDetailshandler: user => dispatch(createBankDetails(user)),
  // deleteBankdetailsHandler: user => dispatch(deleteBankdetail(user)),
  getAllStateHandler: (user) => dispatch(getAllState()),
  getAllCityHandler: (user) => dispatch(getAllCity(user)),
  createMerchandAccountHandler: (user) => dispatch(createMerchandAccount(user)),
});
export default connect(mapStateToProp, mapDispatchToProps)(MerchantAccount);
// 1209
