import { TERMS_ERROR, TERMS_SUCCESS, TERMS_LOADING } from "../constants";

let initialState = {
  loading: false,
  errors: false,
  success: false,
  payload: {},
};

export default function termsAndConditionsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case TERMS_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case TERMS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        payload: action.payload,
      };

    case TERMS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}
