import classNames from "classnames";
import React from "react";
import { useState } from "react";
import { changePasswordAction } from "../../Services/Actions/userAction";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { RESET_CHANGE_PASSWORD, removeStorage } from "../../Services/constant";
import { connect } from "react-redux";

function ChangePass(props) {
  const history = useHistory();
  const [visible, setvisible] = useState(false);
  const [newpassvisible, setnewpassvisible] = useState(false);
  const [confirmpassvisible, setconfirmpassvisible] = useState(false);
  const [state, setState] = useState({
    oldPassword: "",
    password: "",
    confirm_password: "",
  });
  const [error, setError] = useState({
    oldPassword: false,
    password: false,
    confirm_password: false,
  });

  const handlePassword = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));

    switch (name) {
      case "oldPassword":
        if (value == "") {
          error["oldPassword"] = "Required";
        } else {
          error["oldPassword"] = false;
        }
        break;

      case "password":
        if (value == "") {
          error["password"] = "Required";
        } else {
          error["password"] = false;
        }
        break;

      case "confirm_password":
        if (value == "") {
          error["confirm_password"] = "Required";
        } else if (value != state.password) {
          error["confirm_password"] =
            "New Password and Confirm Password Not Matched";
        } else {
          error["confirm_password"] = false;
        }
        break;
      default:
        break;
    }
  };

  const handleChange = (e) => {
    e.preventDefault();

    if (state.oldPassword == "") {
      setError((prev) => ({
        ...prev,
        oldPassword: "Required",
      }));
    }
    if (state.password == "") {
      setError((prev) => ({
        ...prev,
        password: "Required",
      }));
    }
    if (state.confirm_password == "") {
      setError((prev) => ({
        ...prev,
        confirm_password: "Required",
      }));
    }

    if (
      state.oldPassword !== "" &&
      state.password !== "" &&
      state.confirm_password !== "" &&
      state.password === state.confirm_password
    ) {
      props.changePasswordAction({
        ...state,
      });
    }
  };

  useEffect(() => {
    if (props.changePasswordReducer.success) {
      toast.success("Password changed successfully");
      history.push("/superadmin");
      props.resetchangepass();
    } else if (props.changePasswordReducer.error) {
      const errMsg = props.changePasswordReducer?.errorMsg;
      toast.error(errMsg);
      if (!!errMsg) {
        setError((prev) => ({
          ...prev,
          oldPassword: "Required",
        }));
      }
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      }
      props.resetchangepass();
    }
  }, [props.changePasswordReducer]);

  const passwordvisible = () => {
    setvisible(!visible);
  };
  const newPasswordvisible = () => {
    setnewpassvisible(!newpassvisible);
  };
  const confirmPasswordvisible = () => {
    setconfirmpassvisible(!confirmpassvisible);
  };

  return (
    <>
      {/* Login form */}
      <div className="login-form">
        <h1
          className="superAdmin-heading text-center"
          style={{ color: "#0F3D49 " }}
        >
          Change Password
        </h1>
        <form className="mt-4">
          {/* <p className="text-white text-center mt-4 mb-0">Please enter the 4 Digit code sent to <span className="h5 text-secondary"> yourname@company.com</span></p> */}
          <div className="form-group mt-5" style={{ position: "relative" }}>
            <input
              type={!!visible ? "text" : "password"}
              className={classNames("form-control", {
                errorBorder: error.oldPassword,
              })}
              placeholder="Old Password"
              name="oldPassword"
              value={state.oldPassword}
              onChange={handlePassword}
            />
            <div
              className="form-control-icon pointer"
              onClick={passwordvisible}
            >
              {visible ? (
                <i className="fa fa-eye"></i>
              ) : (
                <i className="fa fa-eye-slash"></i>
              )}
            </div>
          </div>
          <div className="form-group" style={{ position: "relative" }}>
            <input
              type={!!newpassvisible ? "text" : "password"}
              className={classNames("form-control", {
                errorBorder: error.password,
              })}
              placeholder="New Password"
              name="password"
              value={state.password}
              onChange={handlePassword}
            />
            <div
              className="form-control-icon pointer"
              onClick={newPasswordvisible}
            >
              {newpassvisible ? (
                <i className="fa fa-eye"></i>
              ) : (
                <i className="fa fa-eye-slash"></i>
              )}
            </div>
          </div>
          <div className="form-group" style={{ position: "relative" }}>
            <input
              type={!!confirmpassvisible ? "text" : "password"}
              className={classNames("form-control", {
                errorBorder: error.confirm_password,
              })}
              placeholder="Confirm Password"
              name="confirm_password"
              value={state.confirm_password}
              onChange={handlePassword}
            />
            <div
              className="form-control-icon pointer"
              onClick={confirmPasswordvisible}
            >
              {confirmpassvisible ? (
                <i className="fa fa-eye"></i>
              ) : (
                <i className="fa fa-eye-slash"></i>
              )}
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-secondary"
              onClick={handleChange}
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  changePasswordReducer:
    state.superAdminRootReducer.authReducers.changePassword,
});

const mapDispatchToProps = (dispatch) => ({
  changePasswordAction: (details) => dispatch(changePasswordAction(details)),
  resetchangepass: () => dispatch({ type: RESET_CHANGE_PASSWORD }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePass);
