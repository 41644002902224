import { DELETE_SEASON_ERROR, DELETE_SEASON_LOADING, DELETE_SEASON_SUCCESS, RESET_DELETE_SEASON } from "../../constants";

let initialState={
    success:false,
    error:false,
    loading:false,
    error:[]
}


export default function deleteSeasonReducer(state=initialState,action){
    switch(action.type){
        case DELETE_SEASON_LOADING:
            return {...state,success:false,loading:true,error:false}
        case DELETE_SEASON_SUCCESS:
            return {...state,success:true,loading:false,error:false,message:action.msg}
        case DELETE_SEASON_ERROR:
            return {...state,success:false,loading:false,error:false}
        case RESET_DELETE_SEASON:
            return {...state,success:false,loading:false,error:false}
        default :
        return state
    }
}