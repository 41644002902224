import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import $ from "jquery";
import { coachAcceptList } from "../../service/action/Club/clubAction";
import Spinner from "../../Spinner/Spinner";

const Coach = (props) => {
  const [loading, setLoading] = useState(true);

  const ClubList = useSelector(
    (state) => state.getOrgClubListReducer.user.data
  );

  const getCoachAcceptList = props.getCoachAcceptListHandler;

  useEffect(() => {
    getCoachAcceptList();
  }, []);

  const coachList = useSelector(
    (state) => state.coachAcceptListReducer.user.data
  );

  useEffect(() => {
    if (props.club?.success) {
      setLoading(false);
    }
  }, [props.club]);

  const EventTbl = [
    {
      name: "Club name",
      Phone: "xxx-xxx-xxxx",
      payment: "Good",
      status: "Active",
    },
    {
      name: "Club name",
      Phone: "xxx-xxx-xxxx",
      payment: "Good",
      status: "Invited",
    },
    {
      name: "Club name",
      Phone: "xxx-xxx-xxxx",
      payment: "Good",
      status: "Active",
    },
    {
      name: "Club name",
      Phone: "xxx-xxx-xxxx",
      payment: "Good",
      status: "Active",
    },
  ];

  const [state, setState] = useState({
    search: "",
  });

  const Searchval = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    UpdateArray(e.target.value);
  };

  const UpdateArray = (search) => {
    var query = search;

    $(".filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".pay-row").addClass("d-none");
      } else {
        $(v).parents(".pay-row").removeClass("d-none");
      }
    });
  };

  useEffect(() => {
    if (props.coach?.success) {
      setLoading(false);
    }
  }, [props.coach]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>
          {!loading && (
            <>
              <div className="text-left">
                <div className="create-team-head-event">
                  <div className="d-flex align-items-center">
                    <h5 className="dash-head pr-2">Coaches</h5>

                    <div className="d-md-flex align-items-center d-none">
                      {/* <div className="search-section">
                                                <i className="mdi mdi-magnify search-ico"></i>
                                                <input type="text" className="form-control" id="search" name="search" value={state.search} placeholder="Search" onChange={Searchval} autoComplete="off" />
                                            </div> */}
                      {/* <div>
                                                <i className="mdi mdi-filter-outline filter-ico"></i>
                                            </div> */}
                    </div>
                  </div>
                  <div className="dropdown details-drp">
                    {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add More</i> */}
                    <Link className="dropdown-item " to={`/teams/add-coach`}>
                      {" "}
                      <i className="mdi mdi-plus mr-2 website-ico"></i>Add Coach
                    </Link>

                    {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <Link className="dropdown-item" to={`/add-staff`}> <i className="mdi mdi-plus mr-2 website-ico"></i>Add Staff</Link>
                                </div> */}
                  </div>
                </div>
              </div>
              {coachList && coachList.length == 0 ? (
                <>
                  <div className="container d-flex justify-content-center align-items-center">
                    <span className="noti-type">No coaches to display</span>
                  </div>
                </>
              ) : (
                <>
                  {/* desktop view */}
                  <div className="table-responsive d-none d-md-block">
                    <table className="table event-table mt-30">
                      <thead>
                        <tr>
                          <th>Name</th>
                          {/* <th>Phone No.</th>
                                    <th>Payment</th>
                                    <th>Status</th> */}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {coachList ? (
                          <>
                            {coachList.length == 0 ? (
                              <>
                                <span className="noti-type">
                                  No coaches to display
                                </span>
                              </>
                            ) : (
                              <>
                                {coachList.map((v, i) => {
                                  return (
                                    <tr key={v._id} className="pay-row">
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className="dashboard-img">
                                            <img
                                              src={
                                                v.coach.profile_image !== ""
                                                  ? v.coach.profile_image
                                                  : "./images/pch-images/pch.svg"
                                              }
                                            />
                                          </div>
                                          <span className="ml-12 filter-string">
                                            {v.coach.name}
                                          </span>
                                        </div>
                                      </td>
                                      {/* <td>{v.Phone}</td>
                                            <td>{v.payment}</td>
                                            <td>{v.status}</td> */}
                                      {/* <td>
                                                <div className="dropdown event-drp">
                                                    <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                    More
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <a className="dropdown-item" href="#">
                                                            <i className="mdi mdi-bookmark-outline"></i>
                                                            Bookmark</a>
                                                        <a className="dropdown-item" href="#">
                                                            <i className="mdi mdi-plus"></i>
                                                            Invite</a>
                                                    </div>
                                                </div>
                                            </td> */}
                                    </tr>
                                  );
                                })}
                              </>
                            )}
                          </>
                        ) : (
                          <>null </>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* mobile view */}

                  <div className="mt-30 d-md-none">
                    {coachList &&
                      coachList.map((v, i) => {
                        return (
                          <div
                            className="d-flex justify-content-between reg-details"
                            key={v._id}
                          >
                            <div className="d-flex">
                              <div className="dashboard-img mr-3">
                                <img src="./images/pch-images/pch.svg" />
                              </div>
                              <div className="reg-content">
                                <Link
                                  className="text-decoration-none text-blue fw-m"
                                  to="/club_details"
                                >
                                  {v.name}
                                </Link>
                                {/* <span className="d-block text-gray mt-2">Payment: {v.payment}</span>
                                            <span className="d-block text-gray mt-2">Status: {v.status}</span> */}
                              </div>
                            </div>
                            {/* <div className="dropdown event-drp">
                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-bookmark-outline"></i>
                                                Bookmark</a>
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-plus"></i>
                                                Invite</a>                                           
                                        </div>
                                    </div> */}
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(Club);

const mapStateToProps = (state) => ({
  submit: state.createTeamReducer,
  club: state.getOrgClubListReducer,
  coach: state.coachAcceptListReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getCoachAcceptListHandler: (user) => dispatch(coachAcceptList(user)),
  // userDataHandler: user => dispatch(userData(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Coach));
