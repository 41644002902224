import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import $ from "jquery";
import { useEffect } from 'react';
import { getAllStaff, inviteStaff } from '../../../service/action/userAction';
import { connect, useSelector } from 'react-redux';
import Toaster from '../../../Toaster/Toaster';
import { toast } from 'react-toastify';
import { RESET_CREATE_TEAM } from '../../../service/constants';
import Spinner from '../../../Spinner/Spinner';

const AccountStaff = (props) => {

    const notify = (msg) => toast(msg);

    useEffect(() => {
        props.getAllStaffHandler()

    }, [])

    const [loading, setLoading] = useState(true)

    const allstaff = useSelector(state => state.getAllClubReducer.user.data)

    const msg = useSelector(state => state.createTeamReducer.message)



    const staff = [
        {
            name: 'xyz',
            email: "xyz@gmail.com",
            phone: "xxx-xxx-xxxx",
            status: "Active"
        },
        {
            name: 'abc',
            email: "abc@gmail.com",
            phone: "xxx-xxx-xxxx",
            status: "Active"
        },
        {
            name: 'xyz',
            email: "xyz@gmail.com",
            phone: "xxx-xxx-xxxx",
            status: "Active"
        },
        {
            name: 'abc',
            email: "abc@gmail.com",
            phone: "xxx-xxx-xxxx",
            status: "Active"
        }
    ]

    const [state, setState] = useState({
        search: ""
    })

    useEffect(() => {
        if (props.staff?.success) {
            setLoading(false)
        }
    }, [props.staff])

    const Searchval = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
        UpdateArray(e.target.value);
    }
    const invite = (_id) => {
        props.invitestaff({
            invitation_to: _id,
            //    invitation_for:id,
            invitation_as: 2
        })
    }

    useEffect(() => {
        if (props.submit?.success) {
            notify(msg.message)
            props.resetcreateTeamReducer()

            // history.push(`/event-details/${id}`);
        } else if (props.submit?.error) {
            notify("Event staff already added ")
            props.resetcreateTeamReducer()
        }
    }, [props.submit])


    const UpdateArray = (search) => {
        var query = search;

        $(" .filter-string").each(function (i, v) {
            if ($(v).text().toLowerCase().indexOf(query) === -1) {
                $(v).parents(".staff-row").addClass("d-none");
            } else {
                $(v).parents(".staff-row").removeClass("d-none");
            }
        });

    }


    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">

                    <div className='d-flex justify-content-center'>

                        {loading ? <Spinner /> : null}
                    </div>
                    {
                        !loading &&
                        <>
                            <div className="create-team-head">
                                <h5 className="dash-head mr-4 mb-0">Search staff</h5>
                                <div className="d-flex align-items-center">
                                    <div className="search-section">
                                        <i className="mdi mdi-magnify search-ico"></i>
                                        <input type="text" className="form-control" id="search" name="search" value={state.search} placeholder="Search" onChange={Searchval} autoComplete="off" />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-30">
                                {
                                    allstaff ? <>

                                        {/* desktop view */}
                                        <div className="table-responsive d-none d-md-block">
                                            <table className="table event-table">
                                                <thead>
                                                    <tr>
                                                        <th>Staff Name</th>
                                                        <th>Email</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        allstaff.map((v, i) => {
                                                            return <tr key={v._id} className='staff-row'>
                                                                <td>
                                                                    <div className="d-flex align-items-center"> <div className="dashboard-img">
                                                                        <img src="./images/pch-images/pch.svg" />
                                                                    </div><span className="ml-12 filter-string">{v.name}</span></div>
                                                                </td>
                                                                <td>{v.user_id.email}</td>
                                                                <td>
                                                                    <Link to="/account-staff" className="text-decoration-none" onClick={() => invite(v._id)}>
                                                                        <i className="mdi mdi-plus text-blue mr-2 website-ico"></i>
                                                                        <span className="text-blue">Invite</span></Link>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        {/* mobile view */}
                                        <div className="mt-3 d-md-none">
                                            {
                                                allstaff.map((v, i) => {
                                                    return <div className="d-flex justify-content-between reg-details" key={v._id}>
                                                        <div className="d-flex w-100">
                                                            <div className="dashboard-img mr-3">
                                                                <img src="./images/pch-images/pch.svg" />
                                                            </div>
                                                            <div className="reg-content w-100">
                                                                <div className="d-flex justify-content-between">
                                                                    <Link className="text-decoration-none text-blue fw-m" to="/event-details">{v.name}</Link>

                                                                    <Link to="/
                                                    account-staff" className="text-decoration-none text-blue fs-13 fw-m" onClick={() => invite(v._id)}><i className="mdi mdi-plus text-blue mr-1 website-ico"></i>Invite</Link>

                                                                </div>
                                                                <span className="d-block text-gray mt-2">Email: {v.user_id.email}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </>
                                        : <></>
                                }
                            </div>
                        </>
                    }
                    <Toaster />
                </div>
            </div>
        </React.Fragment>
    )
}

// export default withRouter(AccountStaff);

const mapStateToProps = (state) => ({
    submit: state.createTeamReducer,
    staff: state.getAllClubReducer

})

const mapDispatchToProps = dispatch => ({
    getAllStaffHandler: user => dispatch(getAllStaff(user)),
    invitestaff: user => dispatch(inviteStaff(user)),
    resetcreateTeamReducer: () => dispatch({ type: RESET_CREATE_TEAM })
    // userDataHandler: user => dispatch(userData(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountStaff))