import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createBankDetails, editBankDetails, getBanks } from '../../../service/action/Club/clubAction';
import { RESET_BANK, RESET_BANKDETAILS, RESET_EDIT_BANK } from '../../../service/constants';
import Toaster from '../../../Toaster/Toaster';
import { deleteBankdetail } from '../../../service/action/Club/clubAction';
import { useHistory, useParams } from 'react-router';

const EditBankInfo = (props) => {
    const {id}=useParams()

    const history=useHistory()


    const getAllBank = props.getBanksHandler

    useEffect(() => {
        getAllBank()
    }, [])

    const allBank = useSelector(state => state.getBanksReducer.user)
    const bankinfo = allBank.find(e => e._id == id)

    const initialState = {
        bank_name: "",
        acc_name: "",
        acc_number: "",
        payrequired: 0,
        paymentchecked: false

    };


    const [bank, setBank] = useState({
        bank_name: "",
        acc_name: "",
        acc_number: "",
        payrequired: 0,
        paymentchecked: false
    })

    useEffect(()=>{
        if(bankinfo){
            setBank(prevState=>({
                prevState,
                bank_name:bankinfo.bank_name,
                acc_name: bankinfo.account_name,
                acc_number: bankinfo.account_number,
                payrequired: bankinfo.default,
                paymentchecked: bankinfo.default,
            

            }))
        }
    },[bankinfo])

    const [errors, setErrors] = useState({
        bank_name: false,
        acc_name: false,
        acc_number: false
    })

    const paycheck = () => {
        if (bank.payrequired == 0 && bank.paymentchecked == false) {
            setBank(prevState => ({
                ...prevState,
                payrequired: 1,
                paymentchecked: true
            }))
        } else if (bank.payrequired == 1 && bank.paymentchecked == true) {
            setBank(prevState => ({
                ...prevState,
                payrequire: 0,
                paymentchecked: false
            }))
        }
    }

    const bankMsg = useSelector(state => state.editBankReducer.message)

    const eventHandler = (e) => {
        const { id, value, name } = e.target
        setBank(prevState => ({
            ...prevState,
            [id]: value
            // [e.target.name]: e.target.value.trim()
        }))
        switch (name) {
            case "bank_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        bank_name: "Bank name can not be empty"
                    }))

                    document.getElementById("bank_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        bank_name: false
                    }))

                    document.getElementById("bank_name").classList.remove("error")
                }

                break;

            case "acc_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        acc_name: "Account name can not be empty"
                    }))

                    document.getElementById("acc_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        acc_name: false
                    }))

                    document.getElementById("acc_name").classList.remove("error")
                }

                break;

            case "acc_number":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        acc_number: "Account number can not be empty"
                    }))

                    document.getElementById("acc_number").classList.add("error")
                }
                else
                    if (!/^[0-9]+$/.test(value)) {
                        setErrors(prevState => ({
                            ...prevState,
                            acc_number: "Only numeric value is accept"
                        }))

                        document.getElementById("acc_number").classList.add("error")
                    }
                    else {

                        setErrors(prevState => ({
                            ...prevState,
                            acc_number: false
                        }))

                        document.getElementById("acc_number").classList.remove("error")
                    }

                break;

            case "payrequired":
                if (value == 0) {
                    setBank(prevState => ({
                        ...prevState,
                        payrequired: 1,
                        //     paymentchecked:true

                    }))

                } else {
                    setBank(prevState => ({
                        ...prevState,
                        payrequired: 0,
                        // paymentchecked:false
                    }))
                }

                break;

            default:

        }
    }


    const submit = (e) => {
        e.preventDefault();

        if (bank.bank_name == "") {
            setErrors(prevState => ({
                ...prevState,
                bank_name: "Bank name can not be empty"
            }))
            document.getElementById("bank_name").classList.add("error")
        }
        if (bank.acc_name == "") {
            setErrors(prevState => ({
                ...prevState,
                acc_name: "Account name can not be empty"
            }))
            document.getElementById("acc_name").classList.add("error")
        }
        if (bank.acc_number == "") {
            setErrors(prevState => ({
                ...prevState,
                acc_number: "Account number can not be empty"
            }))
            document.getElementById("acc_number").classList.add("error")
        }
        if (bank.bank_name !== "" && bank.acc_name !== "" && bank.acc_number !== "") {

            props.editbankDetailshandler({
                bank_name: bank.bank_name,
                account_name: bank.acc_name,
                account_number: bank.acc_number,
                default: bank.payrequired,
                id:id
            })
        }
    }

    const notify = (msg) => toast(msg);
    function resetData() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    }

    //clear data on submit.
    const clearState = () => {
        setBank({ ...initialState });
    };


    useEffect(() => {
        // alert("a")
        if (props.submit?.success) {
            // notify(bankMsg.message)
            // props.reseteditBankdetailsReducer()
            history.push("/bank-info")
            // getAllBank()

        }
    }, [props.submit])

    // useEffect(() => {
    //     // alert("a")
    //     if (props.deleteMsg?.success) {
    //         notify(deleteMessage.message)
    //         props.resetDeleteBankReducer()
    //         getAllBank()
            
    //     }
    // }, [props.deleteMsg])


    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <div className="create-team-head-event">
                            <h5 className="dash-head">Edit Bank Info</h5>
                            <div className="mt-3 mt-md-0 text-right">
                                <button className="btn-save" onClick={submit}>Save Changes</button>
                            </div>
                        </div>
                    </div>

                    <div className="bank-info-tab mt-4 mt-md-0">
                        
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">

                                <div>
                                    <h5 className="payment text-left mt-42">Bank</h5>
                                    <div className="row">
                                        <div className="col-md-4 mt-24">
                                            <input type="text" className="form-control team-name" placeholder="Bank Name" name="bank_name" id="bank_name" value={bank.bank_name} onChange={eventHandler} />

                                            {errors.bank_name &&
                                                <span className='text-red small d-block'>* {errors.bank_name}</span>}

                                        </div>
                                        <div className="col-md-4 mt-24">
                                            <input type="text" className="form-control team-name" placeholder="Account Name"
                                                name="acc_name" id="acc_name" value={bank.acc_name} onChange={eventHandler} />


                                            {errors.acc_name &&
                                                <span className='text-red small d-block'>* {errors.acc_name}</span>}
                                        </div>
                                        <div className="col-md-4 mt-24">
                                            <input type="number" className="form-control team-name" placeholder="Account No." name="acc_number" id="acc_number" value={bank.acc_number} onChange={eventHandler} />


                                            {errors.acc_number &&
                                                <span className='text-red small d-block'>* {errors.acc_number}</span>}
                                        </div>

                                    </div>
                                    <div className="text-left mt-18">
                                        <input type="checkbox" className="mr-2"
                                            id="payrequired"
                                            name="payrequired"
                                            value={bank.payrequired}
                                            onChange={eventHandler}
                                            checked={bank.paymentchecked}
                                            // defaultChecked={state.paymentchecked}    
                                            onClick={paycheck}
                                        // onClick={() => state.payrequired = (!state.payrequired)}

                                        />
                                        <label className="text-gray fs-13">Mark as Preferred payment method</label>



                                    </div>
                                </div>

                                


                            </div>
                        </div>

                    </div>
                    <Toaster />
                </div>
            </div>
        </React.Fragment>
    )
}

// export default BankInfo;

const mapStateToProp = state => ({
    submit: state.editBankReducer,
    

})

const mapDispatchToProps = dispatch => ({
    // createFieldhandler: user => dispatch(createField(user)),
    reseteditBankdetailsReducer: () => dispatch({ type: RESET_EDIT_BANK }),
 
    editbankDetailshandler: user => dispatch(editBankDetails(user)),
    getBanksHandler: user => dispatch(getBanks(user)),
    deleteBankdetailsHandler: user => dispatch(deleteBankdetail(user))



})
export default connect(mapStateToProp, mapDispatchToProps)(EditBankInfo);