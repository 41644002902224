import React from "react";

const EventName = ({ name, value }) => {
  return (
    <div className="grid grid-cols-2 py-2">
      <div className="text-md text-[rgb(59,124,146)] flex flex-col justify-center">
        {name} :{" "}
      </div>
      <h2 className="text-md font-bold break-words">{value}</h2>
    </div>
  );
};

export default EventName;
