import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Toaster from "../Toaster/Toaster";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { getAllCity, getAllSport, getAllState, register } from "../service/action/userAction";
import { RESET_SIGNIN, RESET_SIGNUP } from "../service/constants";
import SignupMerchant from "./SignupMerchant";
import InputMask from "react-input-mask";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const SignupSteps = (props) => {
    useEffect(() => {
        props.getAllStateHandler();
        props.getAllSportHandler();
    }, [])
    const validPassword = new RegExp("^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})");
    const allstate = useSelector(state => state.getStateReducer.states)
    const allcity = useSelector(state => state.getCityReducer.citys)
    const allsport = useSelector(state => state.allSportReducer.SPORTs)
    const [formStep, setFormStep] = useState(0)
    let [color] = useState("#FFFFFF");
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)
    const [selectedDate, handleDateChange] = useState(null)
    const initialState = {
        club_name: "",
        gender: "",
        address: "",
        state: "",
        city: "",
        zip: "",
        website: "",
        telephone: "",
        sport: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: "",
        email: "",
        password: "",
        confirmPassword: "",
        terms_condition: ""
    };

    const initialError = {
        club_name: false,
        gender: false,
        address: false,
        state: false,
        city: false,
        zip: false,
        website: false,
        telephone: false,
        emailforclub: false,
        sport: false,
        firstName: false,
        middleName: false,
        lastName: false,
        dateOfBirth: false,
        email: false,
        password: false,
        confirmPassword: false,
        option: false,
        startDate: false,
        hint: false,
        contactFirstName: false,
        contactMiddleName: false,
        contactLastName: false,
        primaryEmail: false,
    }

    const [state, setState] = useState({
        club_name: "",
        gender: "",
        address: "",
        state: "",
        city: "",
        zip: "",
        website: "",
        telephone: "",
        emailforclub: "",
        sport: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: "",
        email: "",
        password: "",
        merchant: false,
        contactPerson: false,
        terms_condition: 0,
        option: "", //type of account
        contactFirstName: "",
        contactMiddleName: "",
        contactLastName: "",
        primaryEmail: "",
    });

    // Contact person array
    const [moreContacts, setMoreContacts] = useState([{
        contactFirstName: "",
        contactMiddleName: "",
        contactLastName: "",
        primaryEmail: "",
    }])
    // Contact person array error
    const [ErrorMoreContacts, setErrorMoreContacts] = useState([{
        contactFirstName: false,
        contactMiddleName: false,
        contactLastName: false,
        primaryEmail: false,
    }])

    // handle click event of the Add button
    const addContact = () => {
        if (moreContacts[moreContacts.length - 1].contactFirstName === "") {
            setErrorMoreContacts((prevState) => ({
                ...prevState,
                contactFirstName: "First name can not be empty",
            }));
        }

        if (moreContacts[moreContacts.length - 1].contactLastName === "") {
            setErrorMoreContacts((prevState) => ({
                ...prevState,
                contactLastName: "Last name can not be empty",
            }));
        }

        if (moreContacts[moreContacts.length - 1].primaryEmail === "") {
            setErrorMoreContacts((prevState) => ({
                ...prevState,
                primaryEmail: "Email can not be empty"
            }))
        }
        if (moreContacts[moreContacts.length - 1].contactFirstName !== "" && moreContacts[moreContacts.length - 1].contactLastName !== "" && moreContacts[moreContacts.length - 1].primaryEmail !== "" && (validEmailRegex.test(moreContacts[moreContacts.length - 1].primaryEmail))) {
            setMoreContacts([...moreContacts, {
                contactFirstName: "",
                contactMiddleName: "",
                contactLastName: "",
                primaryEmail: "",
            }]);
        }
    };

    // handle click event of the Remove button
    const RemoveContact = index => {
        const list = [...moreContacts];
        list.splice(index, 1);
        setMoreContacts(list);
        // setErrorMoreContacts(list);
    };

    const clearState = () => {
        setState({ ...initialState });
        setErrors({ ...initialError })
    };

    const signUpError = useSelector(state => state.signupReducer.errors)
    const validationError = useSelector(state => state.signupReducer)

    const [startDate, setStartDate] = useState('');
    const [errors, setErrors] = useState({
        club_name: false,
        gender: false,
        address: false,
        state: false,
        city: false,
        zip: false,
        website: false,
        telephone: false,
        emailforclub: false,
        sport: false,
        firstName: false,
        middleName: false,
        lastName: false,
        dateOfBirth: false,
        email: false,
        password: false,
        confirmPassword: false,
        option: false,
        startDate: false,
        hint: false,
        terms_condition: false,
    });

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [orgClubDOB, setOrgClubDOB] = useState(null);
    const [age, setAge] = useState(0)
    const validEmailRegex = RegExp(
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    function getAge(dateString) {
        var ageInMilliseconds = new Date() - new Date(dateString);
        return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365); // convert to years
    }

    // Accept terms and condition check box
    const checkVal = (e) => {
        const { value } = e.target
        setState((prevState => ({
            ...prevState,
            terms_condition: value === 0 ? 1 : value === 1 ? 0 : 1
        })))
        if (value === 0) {
            setErrors((prevState => ({
                ...prevState,
                terms_condition: false
            })))
        }
        if (value === 1) {
            setErrors((prevState => ({
                ...prevState,
                terms_condition: "Please accept terms and condition"
            })))
        }
    }

    const eventhandler = (e) => {
        const { id, value, name } = e.target;
        const validEmailRegex = RegExp(
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        );
        const validPassword = new RegExp("^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})");
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        switch (name) {
            case "club_name":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        club_name: state.option === 4 ? "Club Name can not be empty." : "Organizer Name can not be empty.",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        club_name: false,
                    }));
                }
                break;
            case "gender":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        gender: "Name can not be empty.",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        gender: false,
                    }));
                }
                break;
            case "dateOfBirth":
                setOrgClubDOB(value)
                setAge(getAge(value))
                if (value.length < 1) {
                    setErrors((prevState) => ({
                        ...prevState,
                        dateOfBirth: "Date of birth can not be empty",
                    }))
                } else if (age < 18) {
                    setErrors((prevState) => ({
                        ...prevState,
                        dateOfBirth: "You are not eligible to create an account",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        dateOfBirth: false,
                    }))
                }
                break;

            case "address":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        address: "Address can not be empty.",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        address: false,
                    }));
                }
                break;
            case "state":
                if (value !== "" || value !== "#") {
                    props.getAllCityHandler({ id: value })
                }
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        state: "Please select state.",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        state: false,
                    }));
                }
                break;
            case "city":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        city: "Please select city.",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        city: false,
                    }));
                }
                break;
            case "zip":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        zip: "Zip code can not be empty.",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        zip: false,
                    }));
                }
                break;
            case "telephone":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        telephone: "Telephone can not be empty.",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        telephone: false,
                    }));
                }
                break;
            case "sport":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        sport: "Please select sport",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        sport: false,
                    }));
                }
                break;
            case "email":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        email: "Email can not be empty",
                    }));
                } else if (!validEmailRegex.test(value)) {
                    setErrors((prevState) => ({
                        ...prevState,
                        email: "Email is not valid !",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        email: false,
                    }));
                }
                break;
            case "firstName":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        firstName: "First name can not be empty.",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        firstName: false,
                    }));
                }
                break;
            case "lastName":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        lastName: "Last name can not be empty.",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        lastName: false,
                    }));
                }
                break;
            case "terms_condition":
                if (value === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        terms_condition: "Please accept terms and conditions to continue",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        terms_condition: false,
                    }));
                }
                break;
            case "password":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        password: "Password can not be empty",
                        hint: false
                    }));
                }
                else if (!validPassword.test(value)) {
                    setErrors((prevState) => ({
                        ...prevState,
                        password: false,
                        hint: "(Hint:Password must be at least 8 characters long with uppercase, lowercase, and special character)"
                    }));
                }
                else {
                    setErrors((prevState) => ({
                        ...prevState,
                        password: false,
                        hint: false
                    }));
                }
                break;
            case "confirmPassword":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        confirmPassword: "Password can not be empty",
                        hint: false
                    }));
                }
                else if (!validPassword.test(value)) {
                    setErrors((prevState) => ({
                        ...prevState,
                        confirmPassword: false,
                        hint: "(Hint:Password must be at least 8 characters long with uppercase, lowercase, and special character)"
                    }));
                }
                else {
                    setErrors((prevState) => ({
                        ...prevState,
                        confirmPassword: false,
                        hint: false
                    }));
                }
                break;
            case "account":
                clearState()
                setOrgClubDOB(null)
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        option: "Account can not be empty",
                    }));
                    setState((prevState) => ({
                        ...prevState,
                        option: value,
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        option: false,
                    }));
                    if (value === 2 || value === 3 || value === 5 || value === 6) {
                        setShowDatePicker(true)
                    } else {
                        setShowDatePicker(false)
                    }
                    setState((prevState) => ({
                        ...prevState,
                        option: value,
                    }));
                }
                break;
            default:
        }
    };

    const signup = (e) => {
        e.preventDefault();
        if (state.club_name === "") {
            setErrors((prevState) => ({
                ...prevState,
                club_name: state.option === 4 ? "Club name can not be empty" : "Organizer name can not be empty",
            }));
        }
        if (state.address === "") {
            setErrors((prevState) => ({
                ...prevState,
                address: "Address can not be empty",
            }));
        }
        if (state.state === "") {
            setErrors((prevState) => ({
                ...prevState,
                state: "Please can not be empty",
            }));
        }
        if (state.city === "") {
            setErrors((prevState) => ({
                ...prevState,
                city: "Please can not be empty",
            }));
        }
        if (state.zip === "") {
            setErrors((prevState) => ({
                ...prevState,
                zip: "Postel code can not be empty",
            }));
        }
        if (state.telephone === "") {
            setErrors((prevState) => ({
                ...prevState,
                telephone: "Telephone can not be empty",
            }));
        }
        if (state.sport === "" && state.option === 4) {
            setErrors((prevState) => ({
                ...prevState,
                sport: "Please select sport",
            }));
        }
        if (state.telephone.length > 10) {
            var ret = state.telephone.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}\[\]\\\/]/gi, '')
            setState((prevState) => ({
                ...prevState,
                telephone: ret
            }))
        }

        if ((state.option === 2 || state.option === 3 || state.option === 5) && age < 18) {
            setErrors((prevState) => ({
                ...prevState,
                startDate: "Player must be at least 18 years old",
            }));
        }

        if ((state.option === 2 || state.option === 3 || state.option === 5) && selectedDate === null) {
            setErrors((prevState) => ({
                ...prevState,
                startDate: "Date of birth can not be empty",
            }));
        }

        if (
            state.email !== "" &&
            state.password !== "" &&
            state.option !== "" &&
            state.name !== "" &&
            errors.password === false
            && validPassword.test(state.confirmPassword) && validPassword.test(state.password) && state.password === state.confirmPassword
            // && ((state.option === 2 || state.option === 3 || state.option === 5) && startDate !== "")
        ) {
            //status.option = 1 = club, status.option =2 = organizer
            if (state.option === 4 || state.option === 1) {
                setFormStep(2)
            }
        }
    };

    // handle input change
    const handleContactChange = (e, i) => {
        const { name, value, id } = e.target;

        let list = [...moreContacts];
        // let newFormValues = [...formValues];
        list[i][name] = value;
        setMoreContacts(list);

        switch (name) {
            case "contactFirstName":
                if (value.length === 0) {
                    setErrorMoreContacts((prevState) => ({
                        ...prevState,
                        contactFirstName: "First name can not be empty",
                    }));
                } else {
                    setErrorMoreContacts((prevState) => ({
                        ...prevState,
                        contactFirstName: false,
                    }));
                }
                break;

            case "contactLastName":
                if (value.length === 0) {
                    setErrorMoreContacts((prevState) => ({
                        ...prevState,
                        contactLastName: "Last name can not be empty",
                    }))
                } else {
                    setErrorMoreContacts((prevState) => ({
                        ...prevState,
                        contactLastName: false,
                    }))
                }
                break;

            case "primaryEmail":
                if (value.length === 0) {
                    setErrorMoreContacts((prevState) => ({
                        ...prevState,
                        primaryEmail: "Last name can not be empty",
                    }))
                } else if (!validEmailRegex.test(value)) {
                    setErrorMoreContacts((prevState) => ({
                        ...prevState,
                        primaryEmail: "Email is not valid !",
                    }));
                } else {
                    setErrorMoreContacts((prevState) => ({
                        ...prevState,
                        primaryEmail: false,
                    }))
                }
                break;
            default:
                break;
        }
    };

    // Contact Person
    const contactPerson = (e) => {
        e.preventDefault();
        if (moreContacts[moreContacts.length - 1].contactFirstName === "") {
            setErrorMoreContacts((prevState) => ({
                ...prevState,
                contactFirstName: "First name can not be empty",
            }));
        }

        if (moreContacts[moreContacts.length - 1].contactLastName === "") {
            setErrorMoreContacts((prevState) => ({
                ...prevState,
                contactLastName: "Last name can not be empty",
            }));
        }

        if (moreContacts[moreContacts.length - 1].primaryEmail === "") {
            setErrorMoreContacts((prevState) => ({
                ...prevState,
                primaryEmail: "Email can not be empty"
            }))
        }

        if (moreContacts[moreContacts.length - 1].contactFirstName !== "" && moreContacts[moreContacts.length - 1].contactLastName !== "" && moreContacts[moreContacts.length - 1].primaryEmail !== "" && (validEmailRegex.test(moreContacts[moreContacts.length - 1].primaryEmail))) {
            props.signUpHandler({
                name: state.club_name,
                email: state.email,
                user_type: state.option,
                password: state.password,
                address: state.address,
                city: state.city,
                state: state.state,
                zip: state.zip,
                website: state.website,
                // mobile_number: mobileNum1,
                mobile_number: state.telephone,
                first_name: state.firstName,
                last_name: state.lastName,
                primary_email: state.emailforclub,
                sport: state.sport,
                dob: moment(startDate).format('YYYY-MM-DD'),
                primary_contact: moreContacts,
                // primary_contact: [{
                //     primary_email: state.primaryEmail,
                //     first_name: state.contactFirstName,
                //     middle_name: state.contactLastName,
                //     last_name: state.last_name
                // }],
            });
            // sessionStorage.setItem("status", 1);
            // history.push("/login");
        }
    }

    const contactSkip = (e) => {
        props.signUpHandler({
            name: state.club_name,
            email: state.email,
            user_type: state.option,
            password: state.password,
            address: state.address,
            city: state.city,
            state: state.state,
            zip: state.zip,
            website: state.website,
            // mobile_number: mobileNum1,
            mobile_number: state.telephone,
            first_name: state.firstName,
            last_name: state.lastName,
            primary_email: state.emailforclub,
            sport: state.sport,
            dob: moment(startDate).format('YYYY-MM-DD')
        });
        sessionStorage.setItem("status", 1);
        // history.push("/dashboard");
        history.push("/login");
    }

    //signup for staff==2 and coach ==3 && parent==6 and player==5
    const staffSignup = (e) => {
        e.preventDefault();
        if (state.firstName === "") {
            setErrors((prevState) => ({
                ...prevState,
                firstName: "First name can not be empty",
            }));
        }
        if (state.lastName === "") {
            setErrors((prevState) => ({
                ...prevState,
                lastName: "Last name can not be empty",
            }));
        }
        if (state.email === "") {
            setErrors((prevState) => ({
                ...prevState,
                email: "Email can not be empty",
            }));
        }
        if (state.dateOfBirth === "") {
            setErrors((prevState) => ({
                ...prevState,
                dateOfBirth: "Date of birth can not be empty",
            }));
        } else if (age < 18) {
            setErrors((prevState) => ({
                ...prevState,
                dateOfBirth: "You are not eligible to create an account",
            }));
        }
        if (state.telephone === "") {
            setErrors((prevState) => ({
                ...prevState,
                telephone: "Telephone can not be empty",
            }));
        }
        if (state.telephone.length > 10) {
            var ret = state.telephone.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}\[\]\\\/]/gi, '')
            var mobileNum = ret;
        }
        else {
            var mobileNum = state.telephone
        }

        if (state.password === "") {
            setErrors((prevState) => ({
                ...prevState,
                password: "Password can not be empty",
                hint: false
            }));
        }
        if (state.confirmPassword === "") {
            setErrors((prevState) => ({
                ...prevState,
                confirmPassword: "Confirm password can not be empty",
                hint: false
            }));
        } else
            if (state.password !== state.confirmPassword) {
                setErrors((prevState) => ({
                    ...prevState,
                    confirmPassword: "password & confirm password is not same",
                }));
            } else
                if (!validPassword.test(state.confirmPassword) || !validPassword.test(state.password)) {
                    setErrors((prevState) => ({
                        ...prevState,
                        confirmPassword: "(Hint:Password must be at least 8 characters long with uppercase, lowercase, and special character)",
                    }));
                }
        if (state.option === "") {
            setErrors((prevState) => ({
                ...prevState,
                option: "Value can not be empty",
            }));
        }

        if ((state.option === 5 || state.option === 6) && age < 18) {
            setErrors((prevState) => ({
                ...prevState,
                startDate: "Player must be at least 18 years old",
            }));
        }

        if ((state.option === 2 || state.option === 3 || state.option === 5 || state.option === 6) && selectedDate === null) {
            setErrors((prevState) => ({
                ...prevState,
                startDate: "Date of birth can not be empty",
            }));
        }

        if ((state.option === 5 || state.option === 6) && state.gender === "") {
            setErrors((prevState) => ({
                ...prevState,
                gender: "Please select gender",
            }));
        }
        if ((state.option === 5 || state.option === 6) && state.terms_condition === 0) {
            setErrors((prevState) => ({
                ...prevState,
                terms_condition: "Please accept terms and condition",
            }));
        }

        if (
            state.email !== "" &&
            state.password !== "" &&
            state.option !== "" &&
            state.telephone !== "" &&
            state.firstName !== "" &&
            state.lastName !== "" &&
            // age>=18 &&
            errors.password === false
            && validPassword.test(state.confirmPassword) && validPassword.test(state.password) && state.password === state.confirmPassword
            // && ((state.option === 2 || state.option === 3 || state.option === 5) && startDate !== "")
        ) {
            if (state.option === 2 || state.option === 3) {
                props.signUpHandler({
                    // name: state.club_name,
                    email: state.email,
                    user_type: state.option,
                    password: state.password,
                    mobile_number: mobileNum,
                    first_name: state.firstName,
                    last_name: state.lastName,
                    middle_name: state.middleName,
                    dob: moment(startDate).format('YYYY-MM-DD')
                });
            }

            if ((state.option === 5 || state.option === 6) && state.gender !== "" && state.terms_condition === 1) {
                props.signUpHandler({
                    // name: state.club_name,
                    email: state.email,
                    user_type: state.option,
                    password: state.password,
                    mobile_number: mobileNum,
                    first_name: state.firstName,
                    last_name: state.lastName,
                    middle_name: state.middleName,
                    gender: state.gender === "male" ? 0 : 1,
                    dob: moment(startDate).format('YYYY-MM-DD'),
                    terms_condition: true
                });
            }
            // sessionStorage.setItem("status", 1);
            // history.push("/dashboard");
        }
    };

    //Next step funtion for club and Organizer
    //If Any field is empty then we  provide validation in this
    const next = (e) => {
        e.preventDefault();
        getAge(state.dateOfBirth)

        if (state.firstName === "") {
            setErrors((prevState) => ({
                ...prevState,
                firstName: "First name can not be empty",
            }));
        }
        if (state.lastName === "") {
            setErrors((prevState) => ({
                ...prevState,
                lastName: "Last name can not be empty",
            }));
        }
        if (state.dateOfBirth === "") {
            setErrors((prevState) => ({
                ...prevState,
                dateOfBirth: "Date of birth can not be empty",
            }))
        }
        if (state.email === "") {
            setErrors((prevState) => ({
                ...prevState,
                email: "Email can not be empty",
            }));
        }
        if (state.password === "") {
            setErrors((prevState) => ({
                ...prevState,
                password: "Password can not be empty",
                hint: false
            }));
        }
        if (state.confirmPassword === "") {
            setErrors((prevState) => ({
                ...prevState,
                confirmPassword: "Confirm password can not be empty",
                hint: false
            }));
        } else
            if (state.password !== state.confirmPassword) {
                setErrors((prevState) => ({
                    ...prevState,
                    confirmPassword: "password & confirm password is not same",
                }));
            } else
                if (!validPassword.test(state.confirmPassword) || !validPassword.test(state.password)) {
                    setErrors((prevState) => ({
                        ...prevState,
                        confirmPassword: "(Hint:Password must be at least 8 characters long with uppercase, lowercase, and special character)",
                    }));
                }
        if (state.option === "") {
            setErrors((prevState) => ({
                ...prevState,
                option: "Value can not be empty",
            }));
        }
        // if (formStep === 0 && (state.emailforclub !== "" && state.club_name !== "" && state.address !== "" && state.state !== "" && state.city !== "" && state.zip !== "" && state.telephone !== "")) {
        if (formStep === 0 && (state.firstName !== "" && state.lastName !== "" && state.email !== "" && state.dateOfBirth !== "" && state.password !== "")) {
            if (state.option === 4 || state.option === 1) {
                setFormStep(1)
            }
        }
    }

    const back = () => {
        if (formStep === 1) {
            setFormStep(0)
        }
    }

    const backToAc = () => {
        setState(prevState => ({
            ...prevState,
            option: ""
        }))
    }
    const notify = (msg) => toast(msg);

    useEffect(() => {
        if (props.signup?.loading) {
            setLoading(true)
        }
        else if (props.signup?.success) {
            setLoading(false)
            if (state.option === 2 || state.option === 3 || state.option === 5 || state.option === 6) {
                notify("Register successfully")
                history.push("/login")
            }
            if (state.option === 1 || state.option === 4) {
                setState(prevState => ({
                    ...prevState,
                    merchant: true
                }))
            }
        } else if (props.signup?.error) {
            if (signUpError) {
                setLoading(false)
                notify(signUpError.email)
            }
            else if (validationError.errorNet) {
                setLoading(false)
                notify(validationError.errorNet.message)
            }
            else if (validationError) {
                setLoading(false)
                notify(validationError.message)
            }
        }
    }, [props.signup])

    return (
        <React.Fragment>
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex auth login-section">
                    <div className="row flex-grow">
                        <div className="col-lg-5 mx-auto my-auto">
                            <div className="auth-form-light text-left p-5">
                                {
                                    state.option === "" &&
                                    <div className="brand-logo">
                                        <img src="./images/pch-images/PCH_Logo_Hori_Blue Blue-white.svg" alt="logo" />
                                    </div>
                                }
                                <div className="position-relative">
                                    {
                                        state.option !== "" && formStep === 0 &&
                                        <button className="btn btn-link selectAc py-0 px-2" onClick={backToAc}>
                                            <i className="far fa-angle-left fa-2x"></i>
                                        </button>
                                    }
                                    {
                                        !state.merchant &&
                                        <h3 className="text-white text-center">
                                            Sign Up
                                            {state.option === 1 && " for Organizer"}
                                            {state.option === 2 && " for Staff"}
                                            {state.option === 4 && " for Club"}
                                            {state.option === 3 && " for Coach"}
                                            {state.option === 6 && " for Parents / Guardian"}
                                            {state.option === 5 && " for Player"}
                                        </h3>
                                    }
                                </div>

                                <form className="pt-3">
                                    {!state.merchant && formStep === 0 && state.option === "" &&
                                        <>
                                            {/* <div className="form-group event-drp">
                                            <select className={classNames("form-control payterm", { "login-error": errors.option, })} id="account" name="account" value={state.option} onChange={eventhandler} >
                                                <option value="" hidden disabled style={{ color: "#000" }}>Please choose an Account Type *</option>
                                                <option value="1" style={{ color: "#000" }}>Event Organizer</option>
                                                <option value="2" style={{ color: "#000" }}>Staff</option>
                                                <option value="4" style={{ color: "#000" }}>Club</option>
                                                <option value="3" style={{ color: "#000" }}>Coach</option>
                                                <option value="6" style={{ color: "#000" }}>Parent/Guardian</option>
                                                <option value="5" style={{ color: "#000" }}>Player 18+</option>
                                            </select>
                                            
                                            {errors.option && (
                                                <span className="text-danger small d-block">* {errors.option}</span>
                                            )}
                                        </div> */}

                                            <div className="">
                                                <p style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.2)", padding:"15px", color:"#9c9fa6" }}>
                                                    You can Create Single Account for Staff, Coach, Parent, player and multiple accounts for Club & Organizer .
                                                </p>
                                                <div className="accList">
                                                    <input type="radio" name="account" id="org" value="1" onChange={eventhandler} />
                                                    <label htmlFor="org" className="text-white">I’m an Event Organizer <i className="far fa-angle-right"></i>
                                                        <small className="text-muted d-block">I’m creating an account for my Organization.</small>
                                                    </label>
                                                </div>
                                                <div className="accList">
                                                    <input type="radio" name="account" id="staff" value="2" onChange={eventhandler} />
                                                    <label htmlFor="staff" className="text-white">I’m event Staff<i className="far fa-angle-right"></i>
                                                        <small className="text-muted d-block">I’m Registering to work an event. (working an event? Create your account here).</small>
                                                    </label>
                                                </div>
                                                <div className="accList">
                                                    <input type="radio" name="account" id="club" value="4" onChange={eventhandler} />
                                                    <label htmlFor="club" className="text-white">I’m a Club Director <i className="far fa-angle-right"></i>
                                                        <small className="text-muted d-block">I’m creating an account for our club  (this can also be done within your existing account).</small>
                                                    </label>
                                                </div>
                                                <div className="accList">
                                                    <input type="radio" name="account" id="coach" value="3" onChange={eventhandler} />
                                                    <label htmlFor="coach" className="text-white">I’m a Coach<i className="far fa-angle-right"></i>
                                                        <small className="text-muted font-weight-bold d-block">  </small>
                                                        <small className="text-muted d-block">I’m Connecting with my team and Players</small>
                                                    </label>
                                                </div>
                                                <div className="accList">
                                                    <input type="radio" name="account" id="parent" value="6" onChange={eventhandler} />
                                                    <label htmlFor="parent" className="text-white">I’m a parent/guardian <i className="far fa-angle-right"></i>
                                                        <small className="text-muted d-block">I’m registering kids under the age of 18 (you’ll create your kids profiles w/in your account)</small>
                                                    </label>
                                                </div>
                                                <div className="accList">
                                                    <input type="radio" name="account" id="player" value="5" onChange={eventhandler} />
                                                    <label htmlFor="player" className="text-white">I’m a player <i className="far fa-angle-right"></i>
                                                        <small className="text-muted d-block">I’m over the age of 18 creating my own profile. ( also a parent? You can use one account as a parent and player)</small>
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        state.option !== "" && formStep === 0 ?
                                            <p className="text-white text-center mt-5">Contact info for the User</p> : null

                                    }
                                    {/* <<<<<<<<<<<<<<------------- Step 1 --- Club && Organizer ------------>>>>>>>>>>>>> */}
                                    {
                                        state.option !== "" && (state.option === 4 || state.option === 1) && formStep === 0 &&
                                        <>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="First Name *" className={classNames("form-control ", { "login-error": errors.firstName, })} id="firstName" name="firstName" value={state.firstName} onChange={eventhandler} />
                                                        {errors.firstName && (<span className="text-danger small d-block"> * {errors.firstName}</span>)}
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="Middle Name" className={classNames("form-control ", { "login-error": errors.middleName, })} id="middleName" name="middleName" value={state.middleName} onChange={eventhandler} />
                                                    </div>
                                                </div> */}
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="Last Name *" className={classNames("form-control ", { "login-error": errors.lastName, })} id="lastName" name="lastName" value={state.lastName} onChange={eventhandler} />
                                                        {errors.lastName && (<span className="text-danger small d-block"> * {errors.lastName} </span>)}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                value={orgClubDOB}
                                                                placeholder="Date of Birth *"
                                                                format="MM/dd/yyyy"
                                                                // InputProps={{ disableUnderline: true }}
                                                                mask="__/__/____"
                                                                // variant="inline"
                                                                inputVariant="outlined"
                                                                autoOk="true"
                                                                InputAdornmentProps={{ position: "end" }}
                                                                className={classNames("form-control text-white signUp-dob", { "login-error": errors.dateOfBirth })}
                                                                // error={errors.startDate}
                                                                invalidDateMessage="Invalid Date Format"
                                                                onChange={(e) =>
                                                                    eventhandler({
                                                                        target: {
                                                                            value: e,
                                                                            name: "dateOfBirth",
                                                                            id: "dateOfBirth",
                                                                        },
                                                                    })}
                                                                maxDate={new Date()}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                        {errors.dateOfBirth && (<span className="text-danger small d-block">* {errors.dateOfBirth}</span>)}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            placeholder=" Email *"
                                                            className={classNames("form-control ", {
                                                                "login-error": errors.email,
                                                            })}
                                                            id="email"
                                                            name="email"
                                                            value={state.email}
                                                            onChange={eventhandler}
                                                        />
                                                        {errors.email && (<span className="text-danger small d-block">* {errors.email}</span>)}
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            className={classNames("form-control ", {
                                                                "login-error": errors.emailforclub,
                                                            })}
                                                            placeholder={state.option === 4 ? "Email For The Club*" : state.option === 1 ? "Email For The Event Organizer*" : ""}
                                                            id="emailforclub"
                                                            name="emailforclub"
                                                            value={state.emailforclub}
                                                            onChange={eventhandler}
                                                            autoComplete="off"
                                                        />
                                                        {errors.emailforclub && (
                                                            <span className="text-danger small d-block">
                                                                * {errors.emailforclub}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div> */}
                                                <div className="col-md-6">
                                                    <div className="input-group  form-group pass-wrapper form-group-1">
                                                        <input
                                                            type={show1 ? "text" : "password"}
                                                            className={classNames("form-control form-control-lg w-100", {
                                                                "login-error": errors.password,
                                                            })}
                                                            placeholder="Password *"
                                                            id="password"
                                                            value={state.password}
                                                            name="password"
                                                            onChange={eventhandler}
                                                            aria-label="Password" aria-describedby="button-addon2"
                                                            style={{ "zIndex": "1" }}
                                                            onKeyPress={(e) => {
                                                                if (e.key === "Enter") {
                                                                    signup(e);
                                                                }
                                                            }}
                                                        />
                                                        <div className=" validation-left">
                                                            {errors.password && (
                                                                <span className="text-danger small d-block">
                                                                    * {errors.password}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="bg-transparent input-group-append position-absolute eye-button mr-2" >
                                                            {
                                                                show1 ? <i className="fas fa-eye-slash eye " aria-describedby="button-addon2" onClick={(e) => { setShow1(!show1) }}></i> :
                                                                    <i className="fas fa-eye eye" aria-describedby="button-addon2" onClick={(e) => { setShow1(!show1) }}></i>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-group  form-group pass-wrapper form-group-1">
                                                        <input
                                                            type={show2 ? "text" : "password"}
                                                            className={classNames("form-control form-control-lg w-100", {
                                                                "login-error": errors.confirmPassword,
                                                            })}
                                                            placeholder="Confirm Password *"
                                                            id="confirmPassword"
                                                            value={state.confirmPassword}
                                                            name="confirmPassword"
                                                            onChange={eventhandler}
                                                            aria-label="Password" aria-describedby="button-addon2"
                                                            style={{ "zIndex": "1" }}
                                                            onKeyPress={(e) => {
                                                                if (e.key === "Enter") {
                                                                    signup(e);
                                                                }
                                                            }}
                                                        />
                                                        <div className=" validation-left">
                                                            {errors.confirmPassword && (
                                                                <span className="text-danger small d-block">
                                                                    * {errors.confirmPassword}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="bg-transparent input-group-append position-absolute eye-button mr-2" >
                                                            {
                                                                show2 ? <i className="fas fa-eye-slash eye " aria-describedby="button-addon2" onClick={(e) => { setShow2(!show2) }}></i> :
                                                                    <i className="fas fa-eye eye" aria-describedby="button-addon2" onClick={(e) => { setShow2(!show2) }}></i>
                                                            }
                                                        </div>
                                                    </div>
                                                    {errors.hint && (
                                                        <span className="text-white small d-block">
                                                            * {errors.hint}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {/* <<<<<<<<<<<<<<------------- Coach, Staff && parent/player ------------>>>>>>>>>>>>> */}
                                    {
                                        state.option !== "" && formStep === 0 && (state.option === 2 || state.option === 3 || state.option === 5 || state.option === 6) &&
                                        <>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="First Name *" className={classNames("form-control ", { "login-error": errors.firstName, })} id="firstName" name="firstName" value={state.firstName} onChange={eventhandler} />
                                                        {errors.firstName && (<span className="text-danger small d-block"> * {errors.firstName} </span>)}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="Middle Name" className={classNames("form-control ", { "login-error": errors.middleName, })} id="middleName" name="middleName" value={state.middleName} onChange={eventhandler} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="Last Name *" className={classNames("form-control ", { "login-error": errors.lastName, })} id="lastName" name="lastName" value={state.lastName} onChange={eventhandler} />
                                                        {errors.lastName && (<span className="text-danger small d-block"> * {errors.lastName} </span>)}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    {
                                                        showDatePicker &&
                                                        (<div className="form-group">

                                                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardDatePicker
                                                                        placeholder="Date of Birth *"
                                                                        format="MM/dd/yyyy"
                                                                        // InputProps={{ disableUnderline: true }}
                                                                        mask="__/__/____"
                                                                        inputVariant="outlined"
                                                                        autoOk="true"
                                                                        InputAdornmentProps={{ position: "end" }}
                                                                        className={classNames("form-control form-control-lg w-100 text-white signUp-dob", { "login-error": errors.startDate })}
                                                                        invalidDateMessage="Invalid Date Format"
                                                                        onChange={date => handleDateChange(date)}
                                                                        value={selectedDate}
                                                                        minDate={new Date()}
                                                                    />
                                                                </MuiPickersUtilsProvider> */}


                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardDatePicker
                                                                    value={orgClubDOB}
                                                                    placeholder="Date of Birth *"
                                                                    format="MM/dd/yyyy"
                                                                    // InputProps={{ disableUnderline: true }}
                                                                    mask="__/__/____"
                                                                    // variant="inline"
                                                                    inputVariant="outlined"
                                                                    autoOk="true"
                                                                    InputAdornmentProps={{ position: "end" }}
                                                                    className={classNames("form-control text-white signUp-dob", { "login-error": errors.dateOfBirth })}
                                                                    // error={errors.startDate}
                                                                    invalidDateMessage="Invalid Date Format"
                                                                    onChange={(e) =>
                                                                        eventhandler({
                                                                            target: {
                                                                                value: e,
                                                                                name: "dateOfBirth",
                                                                                id: "dateOfBirth",
                                                                            },
                                                                        })}
                                                                    maxDate={new Date()}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                            {errors.dateOfBirth && (<span className="text-danger small d-block">* {errors.dateOfBirth}</span>)}

                                                            {/* {errors.startDate && ( <span className="text-danger small d-block"> * {errors.startDate} </span> )} */}
                                                        </div>
                                                        )
                                                    }
                                                </div>
                                                {
                                                    (state.option === 5 || state.option === 6) &&
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            {/* <input type="text" placeholder=" gender *" className={classNames("form-control ", { "login-error": errors.gender, })} id="gender" name="gender" value={state.gender} onChange={eventhandler} /> */}
                                                            <select className={classNames("form-control team-name", { "login-error": errors.gender })} placeholder="Gender *" id="gender" name="gender" value={state.gender} onChange={eventhandler} >
                                                                <option value="" hidden className="text-dark">Gender *</option>
                                                                <option value={"male"} className="text-dark" >Male</option>
                                                                <option value={"female"} className="text-dark" >Female</option>
                                                            </select>
                                                            {errors.gender && (<span className="text-danger small d-block"> * {errors.gender} </span>)}
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <InputMask mask="999-999-9999" className={classNames("form-control ", { "login-error": errors.telephone })} value={state.telephone} onChange={eventhandler} maskChar={"_"} placeholder="Telephone* " name="telephone" id="telephone" />
                                                        {errors.telephone && (
                                                            <span className="text-danger small d-block">
                                                                * {errors.telephone}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" placeholder=" Email *" className={classNames("form-control ", { "login-error": errors.email, })} id="email" name="email" value={state.email} onChange={eventhandler} />
                                                        {errors.email && (<span className="text-danger small d-block"> * {errors.email} </span>)}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-group  form-group pass-wrapper form-group-1">
                                                        <input type={show1 ? "text" : "password"} className={classNames("form-control form-control-lg w-100", { "login-error": errors.password, })} placeholder="Password *" id="password" value={state.password} name="password" onChange={eventhandler} aria-label="Password" aria-describedby="button-addon2" style={{ "zIndex": "1" }} onKeyPress={(e) => { if (e.key === "Enter") { signup(e); } }} />
                                                        <div className=" validation-left">
                                                            {errors.password && (<span className="text-danger small d-block"> * {errors.password} </span>)}
                                                        </div>
                                                        <div className="bg-transparent input-group-append position-absolute eye-button mr-2" >
                                                            {
                                                                show1 ? <i className="fas fa-eye-slash eye " aria-describedby="button-addon2" onClick={(e) => { setShow1(!show1) }}></i> :
                                                                    <i className="fas fa-eye eye" aria-describedby="button-addon2" onClick={(e) => { setShow1(!show1) }}></i>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-group form-group pass-wrapper form-group-1">
                                                        <input type={show2 ? "text" : "password"} className={classNames("form-control form-control-lg w-100", { "login-error": errors.confirmPassword, })} placeholder="Confirm Password *" id="confirmPassword" value={state.confirmPassword} name="confirmPassword" onChange={eventhandler} aria-label="Password" aria-describedby="button-addon2" style={{ "zIndex": "1" }} onKeyPress={(e) => { if (e.key === "Enter") { signup(e); } }} />
                                                        <div className=" validation-left">
                                                            {errors.confirmPassword && (<span className="text-danger small d-block"> * {errors.confirmPassword} </span>)}
                                                        </div>
                                                        <div className="bg-transparent input-group-append position-absolute eye-button mr-2" >
                                                            {
                                                                show2 ? <i className="fas fa-eye-slash eye " aria-describedby="button-addon2" onClick={(e) => { setShow2(!show2) }}></i> :
                                                                    <i className="fas fa-eye eye" aria-describedby="button-addon2" onClick={(e) => { setShow2(!show2) }}></i>
                                                            }
                                                        </div>
                                                    </div>
                                                    {errors.hint && (<span className="text-white small d-block"> * {errors.hint} </span>)}
                                                </div>
                                            </div>
                                            {
                                                (state.option === 5 || state.option === 6) &&
                                                <div className="text-left" style={{ marginTop: "14px" }}>
                                                    <input type="checkbox" name="terms_condition" id="terms_condition" value={state.terms_condition} checked={state.terms_condition === 0 ? false : state.terms_condition === 1 ? true : false} onChange={checkVal} />
                                                    <label className="ml-2 text-white fs-13">
                                                        {/* <a href="https://www.google.com/" rel="noopener noreferrer" target="_blank" > */}
                                                        <span className="fs-13 auth-link fw-m"> Terms & Condition</span>
                                                        {/* </a> */}
                                                    </label>
                                                    {errors.terms_condition && (<span className="text-danger small d-block">* {errors.terms_condition}</span>
                                                    )}
                                                </div>
                                            }
                                        </>
                                    }
                                    <div className="mt-3 text-center">
                                        {
                                            formStep === 0 && (state.option === 2 || state.option === 3 || state.option === 5 || state.option === 6) &&
                                            <button type="submit" className="btn btn-login font-weight-medium auth-form-btn ml-2" disabled={props.signup?.loading} onClick={staffSignup} > Sign up </button>
                                        }
                                    </div>

                                    {/*<<<<<<<<<<<<<<----------------Step 2--- Club && Organizer --------------->>>>>>>>>>>>>> */}
                                    {!state.merchant &&
                                        state.option !== "" && formStep === 1 &&
                                        <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" placeholder={state.option === 4 ? "Club Name *" : state.option === 1 ? "Event Organizer Name *" : ""} className={classNames("form-control ", { "login-error": errors.club_name, })} id="club_name" name="club_name" value={state.club_name} onChange={eventhandler} />
                                                        {errors.club_name && (<span className="text-danger small d-block"> * {errors.club_name} </span>)}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="Address *" className={classNames("form-control ", { "login-error": errors.address, })} id="address" name="address" value={state.address} onChange={eventhandler} />
                                                        {errors.address && (<span className="text-danger small d-block"> * {errors.address} </span>)}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        {/* <input
                                                            type="text"
                                                            placeholder="State *"
                                                            className={classNames("form-control ", {
                                                                "login-error": errors.state,
                                                            })}
                                                            id="state"
                                                            name="state"
                                                            value={state.state}
                                                            onChange={eventhandler}
                                                        /> */}
                                                        <select className={classNames("form-control team-name", { "login-error": errors.state })} placeholder="State *" id="state" name="state"
                                                            // value={state.state}
                                                            value={state.state}
                                                            // onSelect={getstatecity(x.state)}
                                                            onChange={eventhandler}
                                                        >
                                                            <option value="" className="text-dark">State *</option>
                                                            {allstate.map((v, i) => {
                                                                return (
                                                                    <option key={v._id} value={v._id} className="text-dark" >{v.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        {errors.state && (
                                                            <span className="text-danger small d-block">
                                                                * {errors.state}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        {/* <input
                                                            type="text"
                                                            className={classNames("form-control ", {
                                                                "login-error": errors.city,
                                                            })}
                                                            placeholder="City*"
                                                            id="city"
                                                            name="city"
                                                            value={state.city}
                                                            onChange={eventhandler}
                                                            autoComplete="off"
                                                        /> */}
                                                        <select className={classNames("form-control team-name", { "login-error": errors.city })} placeholder="City *" id="city" name="city"
                                                            // value={state.city} 
                                                            // onChange={eventHandler} 
                                                            value={state.city}
                                                            onChange={eventhandler}
                                                        >
                                                            <option value="" className="text-dark">City *</option>
                                                            {allcity.map((v, i) => {
                                                                return (
                                                                    <option key={v._id} value={v._id} className="text-dark">{v.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        {errors.city && (
                                                            <span className="text-danger small d-block">
                                                                * {errors.city}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="tel"
                                                            className={classNames("form-control ", {
                                                                "login-error": errors.zip,
                                                            })}
                                                            placeholder="Zip Code*"
                                                            id="zip"
                                                            name="zip"
                                                            value={state.zip}
                                                            onChange={eventhandler}
                                                            autoComplete="off"
                                                            maxLength="5"
                                                            minLength="5"
                                                        />
                                                        {errors.zip && (
                                                            <span className="text-danger small d-block">
                                                                * {errors.zip}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            placeholder="Website"
                                                            className={classNames("form-control ", {
                                                                "login-error": errors.website,
                                                            })}
                                                            id="website"
                                                            name="website"
                                                            value={state.website}
                                                            onChange={eventhandler}
                                                        />
                                                        {errors.website && (
                                                            <span className="text-danger small d-block">
                                                                * {errors.website}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <InputMask mask="999-999-9999" className={classNames("form-control ", { "login-error": errors.telephone })} value={state.telephone} onChange={eventhandler} maskChar={"_"} placeholder="Telephone* " name="telephone" id="telephone" />
                                                        {errors.telephone && (<span className="text-danger small d-block">* {errors.telephone} </span>)}
                                                    </div>
                                                </div>
                                                {
                                                    state.option !== "" && state.option === 4 &&
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <select className={classNames("form-control team-name payterm", { "login-error": errors.sport })} id="sport" name="sport" value={state.sport} onChange={e => eventhandler(e)}>
                                                                <option value="" hidden className="text-dark">Select Sport *</option>
                                                                {allsport ? <>
                                                                    {allsport.map((v, i) => {
                                                                        return (
                                                                            <option key={v._id} value={v._id} className="text-dark" >{v.sport}</option>
                                                                        )
                                                                    })}
                                                                </> : <>No data</>}
                                                            </select>
                                                            {errors.sport && (
                                                                <span className="text-danger small d-block">
                                                                    * {errors.sport}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }
                                    <div className="mt-3 text-center">
                                        {
                                            formStep === 0 && (state.option === 1 || state.option === 4) &&
                                            <button type="submit" className="btn btn-login font-weight-medium auth-form-btn" disabled={state.option === ""} onClick={next}>Save And Next </button>
                                        }
                                        {!state.merchant &&
                                            formStep === 1 && <div>
                                                <button type="submit" className="btn btn-login font-weight-medium auth-form-btn ml-2" disabled={props.signup?.loading} onClick={back} > Back </button>
                                                <button type="submit" className="btn btn-login font-weight-medium auth-form-btn ml-2" disabled={props.signup?.loading} onClick={signup}> Save and Next</button>
                                            </div>
                                        }
                                    </div>

                                    {/* <<<<<<<<<<<<---------------------- Step 3 Contact person (Club && Organizer) --------------------->>>>>>>>>>>> */}
                                    {
                                        !state.merchant &&
                                        state.option !== "" && (state.option === 4 || state.option === 1) && formStep === 2 &&
                                        <>
                                            <p className="text-center text-white">Contact person details</p>
                                            <div className="text-right">
                                                <button className="btn btn-link auth-link px-0" onClick={contactSkip}>Skip</button>
                                            </div>

                                            {/* moreContacts */}
                                            {
                                                moreContacts?.map((v, i) => {
                                                    return (<div key={i}>
                                                        {moreContacts.length !== 1 &&
                                                            <div className="text-right add-more mt-3">
                                                                <button type="button" className="btn btn-link auth-link px-0 text-decoration-none" onClick={() => RemoveContact(i)}>-Remove</button>
                                                            </div>
                                                        }
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input type="text" placeholder="First Name *" className={classNames("form-control ", { "login-error": moreContacts.length - 1 === i && ErrorMoreContacts.contactFirstName, })} readOnly={moreContacts.length - 1 > i} name="contactFirstName" value={v.contactFirstName} onChange={e => handleContactChange(e, i)} />
                                                                    {
                                                                        moreContacts.length - 1 === i &&
                                                                        ErrorMoreContacts.contactFirstName && (<span className="text-danger small d-block"> * {ErrorMoreContacts.contactFirstName} </span>)
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input type="text" placeholder="Middle Name" className="form-control" name="contactMiddleName" value={v.contactMiddleName} onChange={e => handleContactChange(e, i)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group mb-0">
                                                                    <input type="text" placeholder="Last Name*" className={classNames("form-control ", { "login-error": moreContacts.length - 1 === i && ErrorMoreContacts.contactLastName, })} readOnly={moreContacts.length - 1 > i} name="contactLastName" value={v.contactLastName} onChange={e => handleContactChange(e, i)} />
                                                                    {
                                                                        moreContacts.length - 1 === i &&
                                                                        ErrorMoreContacts.contactLastName && (<span className="text-danger small d-block"> * {ErrorMoreContacts.contactLastName} </span>)}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group mb-0">
                                                                    <input type="text" placeholder="Email *" className={classNames("form-control ", { "login-error": moreContacts.length - 1 === i && ErrorMoreContacts.primaryEmail, })} readOnly={moreContacts.length - 1 > i} name="primaryEmail" value={v.primaryEmail} onChange={e => handleContactChange(e, i)} />
                                                                    {
                                                                        moreContacts.length - 1 === i &&
                                                                        ErrorMoreContacts.primaryEmail && (<span className="text-danger small d-block"> * {ErrorMoreContacts.primaryEmail}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                                })
                                            }
                                            <div className="text-right">
                                                <button type="button" onClick={addContact} className="btn btn-link auth-link px-0"><span className=""> + </span> Add more</button>
                                            </div>
                                            <div className="col-md-12 text-center">
                                                {/* <button type="submit" className="btn btn-login font-weight-medium auth-form-btn ml-2" disabled={props.signup?.loading} onClick={staffSignup} > Sign up </button> */}
                                                <button type="submit" className="btn btn-login font-weight-medium auth-form-btn ml-2" disabled={props.signup?.loading} onClick={contactPerson}> Save and Next</button>
                                            </div>
                                        </>
                                    }

                                    {
                                        !state.merchant &&
                                        <>
                                            <div className="text-center mt-42 text-white"> Already have an account ?
                                                <Link to="/login" className="auth-link fw-m pl-2"> Log In </Link>
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                {loading ? <ClipLoader color={color} loading={loading} css={css} size={50} /> : null}
                                            </div>
                                        </>
                                    }
                                </form>

                                {/* <<<<<<<------ step 4 (For Event Organizer and club------>>>>>>> */}
                                {
                                    state.merchant &&
                                    <SignupMerchant />
                                }
                            </div>
                        </div>
                    </div>
                    <Toaster />
                </div>
            </div >
        </React.Fragment >
    );
};

// export default SignupSteps;
const mapStateToProp = state => ({
    signup: state.signupReducer
})

const mapDispatchToProps = dispatch => ({
    signUpHandler: user => dispatch(register(user)),
    resetSignup: () => dispatch({ type: RESET_SIGNUP }),
    resetSigninHandler: () => dispatch({ type: RESET_SIGNIN }),
    getAllStateHandler: () => dispatch(getAllState()),
    getAllCityHandler: user => dispatch(getAllCity(user)),
    getAllSportHandler: user => dispatch(getAllSport(user)),
})
export default connect(mapStateToProp, mapDispatchToProps)(SignupSteps)
// 1725