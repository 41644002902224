import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  getSelectedState,
  Grid,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import "react-datepicker/dist/react-datepicker.css";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  OrgLoginAction,
  changeUserStatus,
  creatOrg,
  deleteOrgAction,
  getOrgList,
  updateOrgAction,
} from "../../Services/Actions/userAction";
import {
  RESET_CHECK_MAIL_EXIST,
  RESET_CREATE_ORG,
  RESET_DELETE_ORG,
  RESET_ORG_LOGIN_BTN,
  RESET_UPDATE_ORG,
  RESET_USER_STATUS,
  removeStorage,
  validEmailRegex,
  validatePhoneString,
} from "../../Services/constant";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { CityList, StateList, checkMailIsExist } from "../../Services/Actions";
import classNames from "classnames";
import MaskedInput from "react-text-mask";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { error } from "jquery";

const DATA_ITEM_KEY = "_id";

function EventOrganizer(props) {
  const history = useHistory();
  const [state, setState] = useState([]);
  const [status, setStatus] = useState({
    status: "",
    isChanged: false,
  });
  const [InitialState, setInitialState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [selectedID, setSelectedId] = useState("");
  const [deletedID, setdeletedID] = useState();

  const [loginAccount, setLoaginAccount] = useState(null);

  const InitialForm = {
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    name: "",
    website: "",
    mobile_number: "",
    description: "",
    primary_contact: [
      {
        primary_email: "",
        first_name: "",
        last_name: "",
        dob: null,
      },
    ],
  };

  const InitialError = {
    email: false,
    first_name: false,
    last_name: false,
    address: false,

    city: false,
    state: false,
    zip: false,
    name: false,
    website: false,
    mobile_number: false,
    description: false,
    primary_contact: [
      {
        primary_email: false,
        dob: false,
        first_name: false,
        last_name: false,
      },
    ],
  };

  const [detailsForm, setDetailsForm] = useState(InitialForm);
  const [errorForm, setErrorForm] = useState(InitialError);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  // const [show, setShow] = useState(false);
  const [xlShow, setXlShow] = useState(false);
  const handleClose = () => setXlShow(false);
  const handleShow = () => setXlShow(true);

  const [delShow, setDelShow] = useState(false);
  const delDialClose = () => setDelShow(false);
  const delDialShow = () => setDelShow(true);

  const [successShow, setSuccessShow] = useState(false);
  const successDialClose = () => setSuccessShow(false);
  const successDialShow = () => {
    setDelShow(false);
    // setSuccessShow(true);
    props.deleteOrgHandler({ id: deletedID });
  };
  const [isMailExist, setIsMailExist] = useState(false);

  const [pageDetails, setPageDetails] = useState({
    skip: 0,
    take: 10,
  });
  useEffect(() => {
    props.getOrgList();
    props.StateList();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (detailsForm.state !== "") {
      props.CityList(detailsForm.state);
      setDetailsForm((pre) => ({
        ...pre,
        city: !!selectedID ? pre.city : "",
      }));
    }
  }, [detailsForm?.state]);

  useEffect(() => {
    if (props.stateListReducer.success) {
      const data = props.stateListReducer.data;
      setStateList(data.data);
    }
  }, [props.stateListReducer]);

  useEffect(() => {
    if (props.cityListReducer.success) {
      const data = props.cityListReducer.data;
      setCityList(data.data);
    }
  }, [props.cityListReducer]);

  useEffect(() => {
    if (props.orgListReducer.success) {
      const data = props.orgListReducer.data;
      const _data = data.data.map((e) => {
        return {
          ...e,
          Orgname:
            e.primary_contact[0]?.first_name != "" || e.primary_contact[0]?.last_name != ""
              ? `${e.primary_contact[0]?.first_name} ${e.primary_contact[0]?.last_name}`
              : "N/A",
          name: e.name !== "" ? e.name : "N/A",
          email: !!e.email && e.email !== "" ? e.email : "N/A",
          address: !!e.address && e.address !== "" ? e.address : "N/A",
          city: e.city && e.city !== "" ? e.city : "N/A",
          state_name: !!e.state ? e.state.name : "N/A",
          state: !!e.state ? e.state._id : "N/A",
          zip: e.zip !== "" ? e.zip : "N/A",
          website: !!e.website && e.website !== "" ? e.website : "N/A",
          mobile_number:
            !!e.mobile_number && e.mobile_number !== ""
              ? e.mobile_number
              : "N/A",
        };
      });
      setState(_data);
      setInitialState(_data);
      setLoading(false);
    } else if (props.orgListReducer.error) {
      const errMsg = props.orgListReducer.errorMsg;
      !!errMsg && toast.error(errMsg);
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      }
    }
  }, [props.orgListReducer]);

  useEffect(() => {
    if (props.orgListReducer.success) {
      if (searchVal !== "") {
        const data = [...InitialState];
        const filter = data.filter(
          (e) =>
            e.Orgname.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.first_name.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.last_name.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.email.toLowerCase().includes(searchVal.toLowerCase())
        );
        setState(filter);
      } else {
        const data = [...InitialState];
        setState(data);
      }
    }
  }, [searchVal]);

  const customHeader = (cell) => {
    return <b title={cell.title}>{cell.title}</b>;
  };
  const customStatus = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <Button
          type="button"
          className={classNames("btn-status  btn-primary", {
            active: !cell.dataItem.status,
          })}
          onClick={() => {
            setSelectedId(cell.dataItem._id);
            setStatus({
              status: cell.dataItem.status == 1 ? "deactivate" : "activate",
              isChanged: true,
            });
          }}
        >
          {cell.dataItem.status == 1 ? "Active" : "Inactive"}
          {/* Active */}
        </Button>
      </td>
    );
  };

  useEffect(() => {
    if (props.changeUserStatusReducer.success) {
      const _data = state.map((e) => {
        if (e._id == selectedID) {
          return {
            ...e,
            status: e.status == 0 ? 1 : 0,
          };
        } else
          return {
            ...e,
          };
      });

      setState(_data);
      toast.success("Status Changed Successfuly");
      setStatus({
        status: "",
        isChanged: false,
      });

      setSelectedId("");
      props.resetStatus();
    }
  }, [props.changeUserStatusReducer]);

  const handlelogin = (ID) => {
    props.OrgLoginHandler({
      id: ID,
      type: 1,
    });
  };

  useEffect(() => {
    if (props.orgLoginReducer?.success) {
      const data = props.orgLoginReducer.data;
      localStorage.setItem("token", data.token);
      localStorage.setItem("type", 1);
      localStorage.setItem("Id", loginAccount._id);
      localStorage.setItem("isAdmin", false);
      props.resetOrgLogin();
      setTimeout(() => {
        history.push("/switch");
        setLoaginAccount(null);
      }, 1000);
    }
  }, [props.orgLoginReducer]);

  const customAction = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <button
          onClick={() => {
            const details = cell.dataItem;
            setSelectedId(details._id);
            setDetailsForm({
              ...details,
              mobile_number:
                details.mobile_number == "N/A" ? "" : details.mobile_number,
              dob:
                !!details.dob && details.dob !== ""
                  ? moment(details.dob, "MM-DD-yyyy").toDate()
                  : null,
              email: details.email == "N/A" ? "" : details.email,
              zip: details.zip == "N/A" ? "" : details.zip,
              website: details.website == "N/A" ? "" : details.website,
              address: details.address == "N/A" ? "" : details.address,
            });
            setErrorForm({
              ...InitialError,
              primary_contact: details.primary_contact.map((e) => {
                return {
                  primary_email: false,
                  first_name: false,
                  last_name: false,
                  middle_name: false,
                };
              }),
            });
            handleShow();
          }}
          className="btn-link btn text-secondary p-0 mx-2"
        >
          <i className="ri-pencil-fill"></i>
        </button>
        <button
          onClick={() => {
            const details = cell.dataItem;
            delDialShow();
            setdeletedID(details?._id);
          }}
          className="btn-link btn text-danger p-0 mx-2"
        >
          <i className="ri-delete-bin-fill"></i>
        </button>
      </td>
    );
  };

  const customelogin = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <button
          onClick={() => {
            handlelogin(cell?.dataItem?._id);
            setLoaginAccount(cell?.dataItem);
          }}
          className="btn-link btn text-secondary p-0 mx-2"
        >
          <i className="fa-solid fa-right-to-bracket"></i>
        </button>
      </td>
    );
  };
  const customCell = (cell) => {
    return (
      <td {...cell.tdProps} title={cell.children}>
        {cell.children}
      </td>
    );
  };

  const grid = (
    <Grid
      data={state.slice(pageDetails.skip, pageDetails.skip + pageDetails.take)}
      skip={pageDetails.skip}
      take={pageDetails.take}
      total={state.length}
      pageable={{
        buttonCount: 3,
        pageSizes: [5, 10, 15],
      }}
      onPageChange={(e) => {
        setPageDetails(() => ({
          ...e.page,
          take: e.page.take,
        }));
      }}
      dataItemKey={DATA_ITEM_KEY}
    >
      <GridNoRecords>No records are available</GridNoRecords>
      <GridColumn
        field="name"
        title="Organization Name"
        width="160"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="Orgname"
        title="Organizer Name"
        width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="email"
        title="Email"
        width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="address"
        title="Address"
        width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="city"
        title="City"
        width="100"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="state_name"
        title="State"
        width="100"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="zip"
        title="Zip Code"
        width="80"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="website"
        title="Website"
        width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="mobile_number"
        title="Phone"
        width="110"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      {/* <GridColumn
        field="OrgEmail"
        title="Organization email"
        width="150"
        headerCell={customHeader}
      /> */}
      <GridColumn
        field="status"
        title="Status"
        width="100"
        headerCell={customHeader}
        cell={customStatus}
      />
      <GridColumn
        field="action"
        title="Actions"
        width="100"
        headerCell={customHeader}
        cell={customAction}
      />
      <GridColumn
        field="login"
        title="Login"
        width="100"
        headerCell={customHeader}
        cell={customelogin}
      />
    </Grid>
  );

  const handleForm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "zip") {
      if (value.length < 6 && !isNaN(value)) {
        setDetailsForm((prev) => ({
          ...prev,
          zip: value,
        }));
      } else {
        return;
      }
    } else if (name == "mobile_number") {
      if (value.length <= 14) {
        setDetailsForm((prev) => ({
          ...prev,
          mobile_number: value,
        }));
      } else {
        return;
      }
    } else {
      setDetailsForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    switch (name) {
      case "name":
        if (value == "") {
          errorForm["name"] = "Required";
        } else {
          errorForm["name"] = false;
        }
        break;
      case "email":
        if (value == "") {
          errorForm["email"] = "Required";
        } else if (!validEmailRegex.test(value)) {
          errorForm["Email"] = "Enter Valid Email";
        } else {
          errorForm["email"] = false;
        }
        break;
      case "dob":
        if (value == "") {
          errorForm["dob"] = "Required";
        } else {
          errorForm["dob"] = false;
        }
        break;
      case "website":
        if (value == "") {
          errorForm["website"] = "Required";
        } else if (!RegExp(/http:|https:/).test(value)) {
          errorForm["website"] = "Enter Valid website";
        } else {
          errorForm["website"] = false;
        }
        break;

      case "first_name":
        if (value == "") {
          errorForm["first_name"] = "Required";
        } else {
          errorForm["first_name"] = false;
        }
        break;
      case "last_name":
        if (value == "") {
          errorForm["last_name"] = "Required";
        } else {
          errorForm["last_name"] = false;
        }
        break;
      case "address":
        if (value == "") {
          errorForm["address"] = "Required";
        } else {
          errorForm["address"] = false;
        }
        break;
      case "city":
        if (value == "") {
          errorForm["city"] = "Required";
        } else {
          errorForm["city"] = false;
        }
        break;
      case "state":
        if (value == "") {
          errorForm["state"] = "Required";
        } else {
          errorForm["state"] = false;
        }
        break;
      case "zip":
        if (value == "") {
          errorForm["zip"] = "Required";
        } else {
          errorForm["zip"] = false;
        }
        break;
      case "mobile_number":
        if (!validatePhoneString.test(value) && value != "") {
          errorForm["mobile_number"] = "Enter Valid Phone Number";
        } else if (value.length != 0 && value.length != 12) {
          errorForm["mobile_number"] = "Enter Valid Phone Number";
        } else {
          errorForm["mobile_number"] = false;
        }
        break;

      default:
        break;
    }
  };

  const handleContactForm = (e, index) => {
    const { name, value } = e.target;
    const contactDetails = detailsForm.primary_contact.map((detail, Dindex) => {
      if (index == Dindex) {
        return {
          ...detail,
          [name]: value,
        };
      } else return { ...detail };
    });
    setDetailsForm((pre) => ({
      ...pre,
      primary_contact: [...contactDetails],
    }));
    switch (name) {
      case "primary_email":
        if (value == "") {
          errorForm.primary_contact[index].primary_email = false;
        } else if (!validEmailRegex.test(value)) {
          errorForm.primary_contact[index].primary_email = "Enter Valid Email";
        } else {
          errorForm.primary_contact[index].primary_email = false;
        }
        break;

        case "first_name":
          if(value == ""){
            errorForm.primary_contact[index].first_name = true
          }else {
            errorForm.primary_contact[index].first_name = false
          }
          break

          case "last_name": 
          if(value == ""){
            errorForm.primary_contact[index].last_name = true
          }else {
            errorForm.primary_contact[index].last_name = false
          }
          break

      case "dob":
        if (value == "" || value == null) {
          errorForm.primary_contact[0].dob = "Required";
        } else if (value.toString() == "Invalid Date") {
          errorForm.primary_contact[0].dob = "Enter Valid Date";
        } else if (moment(value).isBefore(moment("01-01-1947", "MM-DD-yyyy"))) {
          errorForm.primary_contact[0].dob =
            "Date should not be before minimal date";
        } else if (
          moment(value).isSameOrAfter(moment().subtract(18, "years"))
        ) {
          errorForm.primary_contact[0].dob = "Age must be 18 years old";
        } else {
          errorForm.primary_contact[0].dob = false;
        }
        break;

      default:
        break;
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    if (detailsForm.email == "") {
      setErrorForm((prev) => ({
        ...prev,
        email: "Required",
      }));
    }
    if (detailsForm.name == "") {
      setErrorForm((prev) => ({
        ...prev,
        name: "Required",
      }));
    }
    if (detailsForm.dob == "") {
      setErrorForm((prev) => ({
        ...prev,
        dob: "Required",
      }));
    }
    if (detailsForm.first_name == "") {
      setErrorForm((prev) => ({
        ...prev,
        first_name: "Required",
      }));
    }
    if (detailsForm.last_name == "") {
      setErrorForm((prev) => ({
        ...prev,
        last_name: "Required",
      }));
    }
    if (detailsForm.address == "") {
      setErrorForm((prev) => ({
        ...prev,
        address: "Required",
      }));
    }
    if (detailsForm.city == "") {
      setErrorForm((prev) => ({
        ...prev,
        city: "Required",
      }));
    }
    if (detailsForm.state == "") {
      setErrorForm((prev) => ({
        ...prev,
        state: "Required",
      }));
    }
    if (detailsForm.zip == "") {
      setErrorForm((prev) => ({
        ...prev,
        zip: "Required",
      }));
    }
    if (
      detailsForm?.website !== "" &&
      !RegExp(/http:|https:/).test(detailsForm.website)
    ) {
      setErrorForm((prev) => ({
        ...prev,
        website: "Required",
      }));
    }

    const {dob, first_name, last_name, primary_email} = detailsForm.primary_contact[0];
    
    if(first_name == "") {
      errorForm.primary_contact[0].first_name = true
    }else {
      errorForm.primary_contact[0].first_name = false
    }

    if(last_name == ""){
      errorForm.primary_contact[0].last_name = true
    }else {
      errorForm.primary_contact[0].last_name = false
    }

    

    if(primary_email.length == ""){
      errorForm.primary_contact[0].primary_email = true
    }else {
      errorForm.primary_contact[0].primary_email = false
    }


    const is18Plus =
      detailsForm.primary_contact[0].dob !== null
        ? moment().diff(detailsForm.primary_contact[0].dob, "years", false) >=
          18
        : false;

    if (!is18Plus) {
      const _primaryContact = errorForm.primary_contact;
      _primaryContact[0].dob = "Age must be 18 years old";
      setErrorForm((prev) => ({
        ...prev,
        primary_contact: _primaryContact,
      }));
      toast.error("Age must be 18 years old");
    }

    const isValidPrimaryContact = errorForm.primary_contact
      .map((e) => {
        return e.primary_email == false ? true : false;
      })
      .every((e) => e == true);

    if (
      detailsForm.email !== "" &&
      detailsForm.name &&
      detailsForm.address &&
      detailsForm.state &&
      detailsForm.city &&
      detailsForm.zip &&
      isValidPrimaryContact &&
      is18Plus &&
      dob && primary_email && first_name && last_name && validEmailRegex.test(primary_email)
    ) {
      const details = {
        OrgID: selectedID,
        email: detailsForm.email,
        user_type: 1,
        address: detailsForm.address,
        city: detailsForm.city,
        state: detailsForm.state,
        zip: detailsForm.zip,
        name: detailsForm.name,
        description: detailsForm.description,
        password: 123456789,
        mobile_number: detailsForm.mobile_number,
        website: detailsForm.website,
        primary_contact: [...detailsForm.primary_contact],
      };

      selectedID == "" ? props.creatOrg(details) : props.UpdateOrg(details);
    }
  };

  useEffect(() => {
    if (detailsForm.email !== "") {
      if (selectedID != "") {
        const q = InitialState.find((e) => e._id == selectedID).email;
        if (q != detailsForm.email) {
          setTimeout(() => {
            props.checkMailIsExist(detailsForm.email);
          }, 500);
        } else {
          props.resetisCheckEmail();
          setIsMailExist(false);
        }
      } else {
        setTimeout(() => {
          props.checkMailIsExist(detailsForm.email);
        }, 500);
      }
    } else {
      props.resetisCheckEmail();
      setIsMailExist(false);
    }
  }, [detailsForm?.email]);

  useEffect(() => {
    if (props.isMailExistReducer.success) {
      setIsMailExist(props.isMailExistReducer.data.status);
    } else if (props.isMailExistReducer.error) {
      const errMsg = props.isMailExistReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.isMailExistReducer]);

  useEffect(() => {
    if (props.orgCreateReducer.success) {
      handleClose();
      toast.success("Organizer created successfully");
      props.resetCreateOrg();
      props.getOrgList();
      setLoading(true);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
      props.resetisCheckEmail();
    } else if (props.orgCreateReducer.error) {
      const errMsg = props.orgCreateReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.orgCreateReducer]);

  useEffect(() => {
    if (props.orgUpdateReducer.success) {
      handleClose();
      toast.success("Organizer updated successfully");
      props.resetUpdateOrg();
      props.getOrgList();
      setLoading(true);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
      setSelectedId("");
      props.resetisCheckEmail();
    } else if (props.orgUpdateReducer.error) {
      const errMsg = props.orgUpdateReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.orgUpdateReducer]);

  useEffect(() => {
    if (props.orgDeleteReducer.success) {
      handleClose();
      toast.success("Organizer deleted successfully");
      props.resetdeleteOrg();
      props.getOrgList();
      setLoading(true);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
    } else if (props.orgDeleteReducer.error) {
      const errMsg = props.orgDeleteReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.orgDeleteReducer]);

  const handleAdd = (e) => {
    e.preventDefault();
    setDetailsForm((pre) => ({
      ...pre,
      primary_contact: [
        ...pre.primary_contact,
        {
          primary_email: "",
          first_name: "",
          last_name: "",
          middle_name: "",
        },
      ],
    }));
    setErrorForm((pre) => ({
      ...pre,
      primary_contact: [
        ...pre.primary_contact,
        {
          primary_email: false,
          first_name: false,
          last_name: false,
          middle_name: false,
        },
      ],
    }));
  };
  const handleRemove = (e, i) => {
    e.preventDefault();
    const detail = [...detailsForm.primary_contact];
    const errorDetail = [...errorForm.primary_contact];
    detail.splice(i, 1);
    errorDetail.splice(i, 1);
    setDetailsForm((pre) => ({
      ...pre,
      primary_contact: [...detail],
    }));
    setErrorForm((pre) => ({
      ...pre,
      primary_contact: [...errorDetail],
    }));
  };
  return (
    <>
      {loading ? (
        <>Loading.... </>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <h2 className="page-heading">Event Organization</h2>
            {/* <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} /> */}
          </div>

          <Row className="justify-content-between mx-0">
            <Col md="4" className="search px-0">
              <i className="ri-search-line"></i>
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchVal}
                onChange={(e) => {
                  setSearchVal(e.target.value);
                }}
              />
            </Col>
            <Col md="6" className="text-end px-0">
              <button
                className="btn-secondary px-4 py-2"
                onClick={() => {
                  handleShow();
                  setSelectedId("");
                }}
              >
                Add Organization
              </button>
            </Col>
          </Row>
          <div className="table-card">{grid}</div>
          <Modal
            size="xl"
            show={xlShow}
            onHide={() => {
              setDetailsForm(InitialForm);
              setErrorForm(InitialError);
              handleClose();
              setIsMailExist(false);
            }}
          >
            <div
              style={{
                height: "85vh",
                overflowY: "auto",
              }}
            >
              <Modal.Header className="p-4">
                <Modal.Title>Event Organizer</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-4">
                {/* <Form> */}
                {/* 
                <Row className="ml-auto">
                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>
                      First Name<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="first_name"
                      value={detailsForm.first_name}
                      className={classNames({
                        errorBorder: errorForm.first_name,
                      })}
                      onChange={handleForm}
                    />
                  </Form.Group>

                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>
                      Last Name<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="last_name"
                      className={classNames({
                        errorBorder: errorForm.last_name,
                      })}
                      value={detailsForm.last_name}
                      onChange={handleForm}
                    />
                  </Form.Group>
                </Row> */}
                <Row className="ml-auto">
                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>
                      Organization Name<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="name"
                      className={classNames({
                        errorBorder: errorForm.name,
                      })}
                      value={detailsForm?.name}
                      onChange={handleForm}
                    />
                  </Form.Group>

                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>
                      Organization Email<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder=""
                      name="email"
                      className={classNames({
                        errorBorder: errorForm.email,
                      })}
                      value={detailsForm.email}
                      onChange={handleForm}
                      disabled={selectedID !== ""}
                    />
                    {isMailExist && (
                      <div
                        className="mt-2"
                        style={{
                          fontSize: "14px",
                          color: "red",
                          lineHeight: "23px",
                        }}
                      >
                        <u>
                          <b>Please note:</b>
                        </u>
                        &nbsp;This email ID has been used to create another
                        account. Please proceed if you'd like to add this
                        account underneath that account. Otherwise, please enter
                        a new email ID.
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4 p-0" controlId="">
                    <Form.Label>
                      Address<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="address"
                      className={classNames({
                        errorBorder: errorForm.address,
                      })}
                      value={detailsForm.address}
                      onChange={handleForm}
                    />
                  </Form.Group>
                </Row>

                <Row className="ml-auto">
                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>
                      State<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Select
                      onChange={handleForm}
                      value={detailsForm.state}
                      name="state"
                      className={classNames("form-control", {
                        errorBorder: errorForm.state,
                      })}
                    >
                      <option value={""}>Select State</option>
                      {stateList.map((e, i) => {
                        return (
                          <option key={i} value={e._id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>
                      City<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      list="cityNameList"
                      placeholder=""
                      name="city"
                      className={classNames("form-control", {
                        errorBorder: errorForm.city,
                      })}
                      value={detailsForm.city}
                      onChange={handleForm}
                    />
                    <datalist id="cityNameList">
                      {cityList.map((e, i) => {
                        return <option value={e.name} key={i} />;
                      })}
                    </datalist>
                  </Form.Group>

                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>
                      Zipcode<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="zip"
                      className={classNames({
                        errorBorder: errorForm.zip,
                      })}
                      value={detailsForm.zip}
                      onChange={handleForm}
                    />
                  </Form.Group>
                </Row>
                <Row className="ml-auto">
                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>Phone</Form.Label>
                    <MaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                      ]}
                      name="mobile_number"
                      placeholder=""
                      guide={true}
                      value={detailsForm.mobile_number}
                      onChange={handleForm}
                      className={classNames("form-control mb-3", {
                        errorBorder: errorForm.mobile_number,
                      })}
                    />
                  </Form.Group>

                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>Website</Form.Label>
                    <Form.Control
                      type="url"
                      placeholder=""
                      name="website"
                      value={detailsForm.website}
                      onChange={handleForm}
                      className={classNames({
                        errorBorder: errorForm.website,
                      })}
                    />
                  </Form.Group>

                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="url"
                      placeholder=""
                      name="description"
                      value={detailsForm.description}
                      onChange={handleForm}
                      className={classNames({
                        errorBorder: errorForm.description,
                      })}
                    />
                  </Form.Group>
                </Row>

                <hr />

                <div className="h4 my-3">Event Organizer Details</div>
                <hr />
                {detailsForm?.primary_contact?.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Row className="ml-auto">
                        <Form.Group as={Col} className="mb-4" controlId="">
                          <Form.Label>First Name <sup className="text-danger">*</sup></Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="first_name"
                            className={classNames({
                              errorBorder:
                                errorForm?.primary_contact[i]?.first_name,
                            })}
                            value={e.first_name}
                            onChange={(e) => handleContactForm(e, i)}
                          />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-4" controlId="">
                          <Form.Label>Last Name<sup className="text-danger">*</sup></Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="last_name"
                            className={classNames({
                              errorBorder:
                                errorForm?.primary_contact[i]?.last_name,
                            })}
                            value={e.last_name}
                            onChange={(e) => handleContactForm(e, i)}
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          className="mb-4 d-flex flex-column"
                        >
                          <Form.Label>
                            Date of Birth<sup className="text-danger">*</sup>
                          </Form.Label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              value={detailsForm.primary_contact[0].dob}
                              clearable
                              placeholder=""
                              minDate={moment("01-01-1947", "MM-DD-yyyy")}
                              maxDate={moment().subtract(18, "years")}
                              onChange={(e) => {
                                handleContactForm(
                                  {
                                    target: {
                                      name: "dob",
                                      value: e,
                                    },
                                  },
                                  i
                                );
                              }}
                              format="MM/dd/yyyy"
                              mask="__/__/____"
                              inputVariant="outlined"
                              autoOk={true}
                              InputAdornmentProps={{ position: "end" }}
                              error={Boolean(errorForm.primary_contact[0].dob)}
                              helperText={errorForm.primary_contact[0].dob}
                              className={classNames("form-control team-name", {
                                errorBorder: errorForm.primary_contact[0].dob,
                              })}
                            />
                          </MuiPickersUtilsProvider>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-4" controlId="">
                          <Form.Label>Email <sup className="text-danger">*</sup></Form.Label>
                          <Form.Control
                            type="email"
                            placeholder=""
                            name="primary_email"
                            className={classNames({
                              errorBorder:
                                errorForm?.primary_contact[i]?.primary_email,
                            })}
                            value={e.primary_email}
                            onChange={(e) => handleContactForm(e, i)}
                            disabled={selectedID !== ""}
                          />
                        </Form.Group>
                      </Row>
                    </React.Fragment>
                  );
                })}

                <div className="text-end ml-auto">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      setDetailsForm(InitialForm);
                      setErrorForm(InitialError);
                      handleClose();
                      setIsMailExist(false);
                    }}
                    style={{ fontSize: "18px" }}
                    className="mr-3 pointer"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="secondary"
                    type="submit"
                    onClick={handleCreate}
                  >
                    {selectedID == "" ? "Submit" : "Update"}
                  </Button>
                </div>
                {/* </Form> */}
              </Modal.Body>
            </div>
          </Modal>

          {/* Delete confirmation */}
          <Modal
            size="sm"
            show={delShow}
            onHide={delDialClose}
            centered
            className="del-dialogue"
          >
            <Modal.Body>
              <h6 className="text-center">
                Are you sure you want to delete this account?
              </h6>
            </Modal.Body>
            <Modal.Footer className="p-0">
              <Button className="btn-gray" onClick={delDialClose}>
                No
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  successDialShow();
                }}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Status Changed  */}
          <Modal
            size="sm"
            show={status.isChanged}
            onHide={() =>
              setStatus({
                isChanged: false,
                status: "",
              })
            }
            centered
            className="del-dialogue"
          >
            <Modal.Body>
              <h6 className="text-center">
                Are you sure you want to <strong>{status.status}</strong> this
                account?
              </h6>
            </Modal.Body>
            <Modal.Footer className="p-0">
              <Button
                className="btn-gray"
                onClick={() =>
                  setStatus({
                    isChanged: false,
                    status: "",
                  })
                }
              >
                No
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  props.changeUserStatus({
                    type: 1,
                    id: selectedID,
                  });
                }}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          {/* delete success */}
          <Modal
            size="sm"
            show={successShow}
            onHide={successDialClose}
            centered
            className="del-dialogue success"
          >
            <Modal.Body className="text-center">
              <i className="ri-close-circle-line fa-4x text-secondary"></i>
              <h5 className="text-center">Record deleted Successfully !!</h5>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

const mapStateToProp = (state) => ({
  orgListReducer: state.superAdminRootReducer.UserReducer.orgList,
  orgCreateReducer: state.superAdminRootReducer.UserReducer.orgCreate,
  orgUpdateReducer: state.superAdminRootReducer.UserReducer.orgUpdate,
  orgDeleteReducer: state.superAdminRootReducer.UserReducer.orgDelete,
  changeUserStatusReducer:
    state.superAdminRootReducer.UserReducer.changeUserStatus,
  orgLoginReducer: state.superAdminRootReducer.UserReducer.OrgLogin,
  stateListReducer: state.superAdminRootReducer.authReducers.stateList,
  cityListReducer: state.superAdminRootReducer.authReducers.cityList,
  isMailExistReducer: state.superAdminRootReducer.authReducers.isMailExist,
});

const mapDispatchToProps = (dispatch) => ({
  getOrgList: () => dispatch(getOrgList()),
  StateList: () => dispatch(StateList()),
  CityList: (state) => dispatch(CityList(state)),
  creatOrg: (details) => dispatch(creatOrg(details)),
  UpdateOrg: (details) => dispatch(updateOrgAction(details)),
  OrgLoginHandler: (details) => dispatch(OrgLoginAction(details)),
  deleteOrgHandler: (details) => dispatch(deleteOrgAction(details)),
  changeUserStatus: (details) => dispatch(changeUserStatus(details)),
  checkMailIsExist: (email) => dispatch(checkMailIsExist(email)),
  resetCreateOrg: () => dispatch({ type: RESET_CREATE_ORG }),
  resetUpdateOrg: () => dispatch({ type: RESET_UPDATE_ORG }),
  resetOrgLogin: () => dispatch({ type: RESET_ORG_LOGIN_BTN }),
  resetdeleteOrg: () => dispatch({ type: RESET_DELETE_ORG }),
  resetStatus: () => dispatch({ type: RESET_USER_STATUS }),
  resetisCheckEmail: () => dispatch({ type: RESET_CHECK_MAIL_EXIST }),
});

export default connect(mapStateToProp, mapDispatchToProps)(EventOrganizer);
