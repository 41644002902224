import React, { useEffect, useState } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import {
  deletePaymentCode,
  getAllPaymentCode,
} from "../../../service/action/userAction";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import {
  RESET_CREATE_PAYMENT_CODE,
  RESET_DELETE_EVENT,
  RESET_EDIT_PAYMENT_CODE,
} from "../../../service/constants";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import Spinner from "../../../Spinner/Spinner";
import DeletePopUp from "../../components/DeletePopUp/DeletePopUp";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";

const Payment_Code = (props) => {
  const getdata = props.getPaymentCodeHandler;
  useEffect(() => {
    getdata();
  }, []);

  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const allpayment = useSelector(
    (state) => state.getAllPaymentCodeReducer.user
  );
  const deleteEventMsg = useSelector(
    (state) => state.deleteEventReducer.message
  );
  const editPaymentCodeMsg = useSelector(
    (state) => state.editPaymentCodeReducer.message
  );
  const location = useLocation();

  const notify = (msg) => toast(msg);

  useEffect(() => {
    if (props.submit?.success) {
      notify(deleteEventMsg.message);
      props.resetdeletePaymentCodeReducer();
      getdata();

      // history.push(`/event-details/${id}`);
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.editMsg?.success) {
      notify(editPaymentCodeMsg.message);
      props.reseteditPaymentCodeReducer();
      getdata();

      // history.push(`/event-details/${id}`);
    }
  }, [props.editMsg]);
  useEffect(() => {
    if (props.submitRequest?.success) {
      notify("Paymet code Created Successfully");
      props.resetCreatePaymentCode();
      // history.push(`/payment/payment-code`);
    }
  }, [props.submitRequest]);
  // const green_tbl = [
  //     {
  //         invoicecodename: "Rio",
  //         amout: "200",
  //         amountdue: "20%",
  //         balancedueday: "7"

  //     },
  //     {
  //         invoicecodename: "Jhon",
  //         amout: "500",
  //         amountdue: "25%",
  //         balancedueday: "9"

  //     }
  // ]
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.getAllPayment?.success) {
      setLoading(false);
    }
  }, [props.getAllPayment]);

  return (
    <>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          <div className="text-left ">
            <div className="create-team-head-space flex-wrap">
              <div className="d-flex align-items-center">
                <h5 className="dash-head mr-3 mb-0">Payment Code List</h5>
              </div>

              <div className="mt-2 mt-md-0 text-right">
                <Link to="/payment/payment-code/create-payment-code">
                  <button className="btn-save">Create</button>
                </Link>
              </div>
            </div>
            <div>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Spinner />
                </div>
              ) : (
                <>
                  {!allpayment || (!!allpayment && allpayment.length === 0) ? (
                    <div className="container d-flex justify-content-center align-items-center">
                      <span className="noti-type">
                        No payment code to display{" "}
                      </span>
                    </div>
                  ) : (
                    <>
                      {/* desktop */}
                      <div className="mt-30 table-responsive d-none d-md-block">
                        <table className="table event-table register-table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Code Name</th>
                              <th>Amount</th>
                              <th>Payment Due Days</th>
                              <th>Amount Due</th>
                              <th>Payment Required</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allpayment.map((v, i) => {
                              const {
                                _id,
                                name,
                                amount,
                                payment_terms_type,
                                payment_required,
                                payment_due,
                                payment_terms,
                              } = v;
                              return (
                                <tr key={_id} className="reg-row">
                                  <td>{i + 1}</td>
                                  <td>{name}</td>
                                  <td>{`${amount}`}</td>
                                  <td>{payment_due}</td>
                                  <td>{`${
                                    payment_terms_type === 0 ? "$" : ""
                                  }${payment_terms} ${
                                    payment_terms_type === 1 ? "%" : ""
                                  }`}</td>
                                  <td>
                                    {payment_required === 1 ? "Yes" : "No"}
                                  </td>
                                  <td>
                                    <div className="dropdown event-drp">
                                      <i
                                        className="mdi mdi-dots-vertical"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      ></i>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuLink"
                                      >
                                        <Link
                                          className="dropdown-item"
                                          to={`/payment/payment-code/${_id}`}
                                        >
                                          <i className="mdi mdi-border-color"></i>
                                          Edit
                                        </Link>

                                        <Link
                                          className="dropdown-item"
                                          to="/payment/payment-code"
                                          onClick={() => {
                                            setIsDelete(true);
                                            setDeleteId(_id);
                                          }}
                                        >
                                          <i className="fas fa-trash-alt"></i>{" "}
                                          Delete
                                        </Link>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      {/* for mobile  */}
                      <div className="mt-30 d-md-none">
                        <div className="mt-4">
                          {allpayment.map((v, i) => {
                            const {
                              _id,
                              name,
                              amount,
                              payment_terms_type,
                              payment_required,
                              payment_due,
                              payment_terms,
                            } = v;
                            return (
                              <div
                                className="d-flex justify-content-between reg-details"
                                key={_id}
                              >
                                <div className="d-flex">
                                  <div className="reg-content">
                                    <div className="fw-m">{name}</div>
                                    <div className=" text-gray mt-2">
                                      Amount: {`${amount}`}
                                    </div>
                                    <div className=" text-gray mt-2">
                                      Payment Due Days: {payment_due} Days
                                    </div>
                                    <div className=" text-gray mt-2">
                                      Amount Due:{" "}
                                      {`${
                                        payment_terms_type === 0 ? "$" : ""
                                      }${payment_terms} ${
                                        payment_terms_type === 1 ? "%" : ""
                                      }`}
                                    </div>
                                    <div className=" text-gray mt-2">
                                      Payment Required:{" "}
                                      {payment_required === 1 ? "Yes" : "No"}
                                    </div>
                                  </div>
                                </div>
                                <div className="dropdown event-drp">
                                  <i
                                    className="mdi mdi-dots-vertical"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  ></i>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink"
                                  >
                                    <Link
                                      className="dropdown-item"
                                      to={`/payment/payment-code/${_id}`}
                                    >
                                      <i className="mdi mdi-border-color"></i>
                                      Edit
                                    </Link>
                                    <Link
                                      className="dropdown-item"
                                      to="/payment/payment-code"
                                      onClick={() => {
                                        setIsDelete(true);
                                        setDeleteId(_id);
                                      }}
                                    >
                                      <i className="fas fa-trash-alt"></i>{" "}
                                      Delete
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => props.deletePaymentCodeHandler(deleteId)}
      />
      <Toaster />
    </>
  );
};

// export default withRouter(PaymentCode);

const mapStateToProps = (state) => ({
  getAllPayment: state.getAllPaymentCodeReducer,
  submit: state.deleteEventReducer,
  editMsg: state.editPaymentCodeReducer,
  submitRequest: state.createPaymentCodeReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentCodeHandler: (user) => dispatch(getAllPaymentCode(user)),
  deletePaymentCodeHandler: (user) => dispatch(deletePaymentCode(user)),
  resetdeletePaymentCodeReducer: () => dispatch({ type: RESET_DELETE_EVENT }),
  reseteditPaymentCodeReducer: () =>
    dispatch({ type: RESET_EDIT_PAYMENT_CODE }),
  resetCreatePaymentCode: () => dispatch({ type: RESET_CREATE_PAYMENT_CODE }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Payment_Code));
