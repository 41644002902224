import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { connect, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import DeletePopUp from '../../components/DeletePopUp/DeletePopUp'
import { toast } from 'react-toastify'
import { removePlayerFromRoster } from '../../../service/action/userAction'
import { RESET_CREATE_TEAM, RESET_REMOVE_PLAYER_ROSTER } from '../../../service/constants'
import { getTeamById, getTeamDetails, getTeamEventsDetails } from '../../../service/action/Club/clubAction'
import Toaster from '../../../Toaster/Toaster'

function RosterPlayer(props) {

 const { id, id1 } = useParams()
 const history = useHistory()

 const [isDelete, setIsDelete] = useState(false)
 const [deleteId, setDeleteId] = useState(null)

 const teamDetail = useSelector(state => state.getTeamDetailsReducer.user.data)
 const player = teamDetail?.roster_player.find(e => e._id === id1)
 const InvitedMsg = useSelector(state => state.createTeamReducer.message)
 const notify = (msg) => toast(msg);

 useEffect(() => {
  if (!teamDetail) {
   history.push(`/teams/${id}`)
  }
 }, [])

 useEffect(() => {
  if (props.submit?.success) {
   notify(InvitedMsg.message)
   props.resetcreateTeamReducer()
  }

 }, [props.submit])

 useEffect(() => {
  if (props.removePayerFromRosterReducer.success) {
   notify("Player removed from roster")
   props.resetDelete()
   props.geṭTeamByidHandler({ id: id })
  }
 }, [props.removePayerFromRosterReducer])

 return (
  <React.Fragment>
   <div className="main-panel">
    <div className="content-wrapper">
     <div className="text-left ">
      <div className="create-team-head-space">
       <div className="d-md-flex align-items-center d-none">
        <h5 className="dash-head mr-3 mb-0">Roster Player List</h5>

       </div>

       <div className="mt-2 mt-md-0 text-right">
        <Link to={`/addroster/${id}/${id1}`}>
         <button className="btn-save">Add</button>
        </Link>
       </div>
      </div>
     </div>

     <div className="team-details mt-4">

      <div className="green-section mt-30">
       {
        player?.player.length === 0 ? <>
         <div className='container d-flex justify-content-center align-items-center'>
          <span className='noti-type'>No Player to display</span>
         </div>
        </> : <>
         {
          player?.player.map((v, i) => {
           const details = teamDetail.player.find(player => player._id === v.player)
           return (
            <div className='d-flex align-items-center justify-content-between mt-3' key={i}>
             <div className="d-flex align-items-center">
              <div className="dashboard-img">
               <img src={details?.profile_image !== "" ? details.profile_image : "./images/pch-images/pch.svg"} />
              </div>
              <Link className="ml-12 heading text-decoration-none filter-string" to={`/player-detail/${v._id}`} >{`${details.first_name} ${details.last_name}`}</Link>
             </div>
             <div className='d-flex align-item-center justify-content-center'>
              <a className="d-block text-info ml-12 fs-18 pointer">
               <i className="mdi mdi-message-text"></i>
              </a>
              <a className="d-block text-danger ml-18 fs-18 pointer" onClick={() => {
               setIsDelete(true)
               setDeleteId(v?.player)
              }}>
               <i className="fas fa-trash-alt"></i>
              </a>
             </div>
            </div>
           )
          })
         }
        </>
       }
      </div>

     </div>
    </div>
   </div>
   <DeletePopUp
    isOpen={isDelete}
    isClose={setIsDelete}
    handleEvent={() => {
     props.removePlayerFromRoster({
      player_id: deleteId,
      roster_id: id1
     })
    }}
   />

   <Toaster />
  </React.Fragment>
 )
}

const mapStateToProps = (state) => ({
 submit: state.createTeamReducer,
 removePayerFromRosterReducer: state.removePayerFromRosterReducer,
 getTeamDetailsReducer: state.getTeamDetailsReducer,
})

const mapDispatchToProps = dispatch => ({
 geṭTeamByidHandler: user => dispatch(getTeamDetails(user)),
 removePlayerFromRoster: user => dispatch(removePlayerFromRoster(user)),
 resetcreateTeamReducer: () => dispatch({ type: RESET_CREATE_TEAM }),
 resetDelete: () => dispatch({ type: RESET_REMOVE_PLAYER_ROSTER }),
})

export default connect(mapStateToProps, mapDispatchToProps)(RosterPlayer)