import React, { useState, useEffect } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { withRouter, Redirect, useParams, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
// import { createDivision, createSeason } from '../../service/action/userAction';
// import { RESET_CREATE_PAYMENT_CODE, RESET_CREATE_SEASON } from '../../service/constants';
import classNames from 'classnames';
// import { createField, create_Division } from '../../service/action/Club/clubAction';
import { toast } from 'react-toastify';
import Toaster from '../../../Toaster/Toaster';
import { create_Division, create_event_Division, getAllDivision_club } from '../../../service/action/Club/clubAction';
// import Toaster from '../../Toaster/Toaster';




const Create_Event_Division = (props) => {

    const getDivision = props.getDivisionHandler

    const eventDivision = useSelector(state => state.getAllSeasonReducer.user)

    useEffect(() => {
        getDivision()
    }, [])

    const history = useHistory()

    const { id ,id1} = useParams()

    const [state, setState] = useState({
        division_name: ""
    })

    const [errors, setErrors] = useState({

        division_name: false

    })

    const initialState = {
        division_name: ""
    };


    //setTimeout form reseting data after submit of form
    function resetData() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    }

    //clear data on submit.
    const clearState = () => {
        setState({ ...initialState });
    };


    // const [checked, setChecked] = useState(false)
    // const onClickCheck = () => setChecked(!checked)
    const notify = (msg) => toast(msg);

    // const DivisionMsg = useSelector(state => state.createEventDivisionReducer.message)

    const eventHandler = (e) => {
        const { id, value, name } = e.target


        setState(prevState => ({
            ...prevState,
            [id]: value
            // [e.target.name]: e.target.value.trim() 
        }))
        switch (name) {
            case "division_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        division_name: "Division name can not be empty"
                    }))

                    document.getElementById("division_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        division_name: false
                    }))

                    document.getElementById("division_name").classList.remove("error")
                }

                break;

            default:
        }
    }


    const submitRequest = (e) => {
        e.preventDefault();
        // alert("click")

        if (state.division_name == "") {
            setErrors(prevState => ({
                ...prevState,
                division_name: "Division name can not be empty"
            }))
            document.getElementById("division_name").classList.add("error")
        }

        if (state.division_name !== "") {
            props.createEventDivisionhandler({
                division: state.division_name,
                event: id,
                team:id1
            })
            // if (props.submitRequest?.success) {
            //     notify("Data submitted successfully.")
            // }
            // resetData().then(clearState);

        }


        // alert(state.checkbox)
    }
    // if(props.submit?.success){
    //         notify("Season submitted successfully.")
    //         props.resetCreateSeasonReducer()
    //         return <Redirect to="/season" />;
    //     }

    useEffect(() => {
        // alert("a")
        if (props.submit?.success) {
            // alert("b")
            resetData().then(clearState);
            // notify(DivisionMsg.message)
            // props.resetCreateDivisionReducer()


            history.push(`/events/${id}`);
        }

    }, [props.submit])

    return (
        <>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <h5 className="dash-head">Add Team Division</h5>
                    </div>

                    <form >
                        <div className="row">
                            <div className="col-md-4 col-xl-4">
                                <div className="mt-24">
                                    <div className="text-left">
                                        <label className="text-gray fs-13">Division Name <span className="text-red fw-m">*</span></label>
                                        {
                                            eventDivision ? <>

                                                <select className={classNames("form-control team-name payterm", { "error": errors.division_name })} id="division_name" name="division_name" value={state.division_name} onChange={eventHandler}>
                                                    <option value="#" >Division *</option>
                                                    {eventDivision.map((v, i) => {
                                                        return (
                                                            <option value={v._id} key={v._id}>{v.division_name}</option>
                                                        )
                                                    })}
                                                </select>

                                                {errors.division_name &&
                                                    <span className='text-red small d-block'>* {errors.division_name}</span>}
                                            </> : <><h4>Loading...</h4></>
                                        }


                                    </div>

                                    <div className="mt-3 text-left">
                                        <a className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitRequest} >Submit</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                    <Toaster />
                </div>
            </div>

        </>
    )
}

// export default withRouter(CreateInvoice)



const mapStateToProp = state => ({
    submit: state.createEventDivisionReducer,

})

const mapDispatchToProps = dispatch => ({
    createEventDivisionhandler: user => dispatch(create_event_Division(user)),
    // resetCreateDivisionReducer:()=>dispatch({type:RESET_CREATE_PAYMENT_CODE}),
    getDivisionHandler: (user) => dispatch(getAllDivision_club(user)),



})
export default connect(mapStateToProp, mapDispatchToProps)(Create_Event_Division);