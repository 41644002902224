import DateFnsUtils from "@date-io/date-fns";
import { Checkbox } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import classNames from "classnames";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getTermsAndConditions } from "../../service/action/userAction";
import { useEffect } from "react";
import TermsPop from "./TermsPop";

const PlayerSingupForm = ({ state, eventhandler, errors, handleSubmit }) => {
  //   Handeling Terms and conditions
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [agree, setAgree] = useState(false);
  const [termText, setTermText] = useState("");
  const termsAndConditions = useSelector(
    (state) => state.termsAndConditionsReducer?.payload?.data?.liability
  );

  const handleClickOpen = () => {
    if (agree) {
      setAgree(false);
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setAgree(true);
    setOpen(false);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const checked = { checked: agree ? true : false };

  useEffect(() => {
    dispatch(getTermsAndConditions({ title: "players accept terms of use" }));
  }, []);

  useEffect(() => {
    if (termsAndConditions) {
      setTermText(termsAndConditions);
    }
  }, [termsAndConditions, termText]);

  //   End Terms and conditions state and logics

  return (
    <>
      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {
            error: errors.first_name,
          })}
          placeholder="First Name*"
          id="first_name"
          name="first_name"
          value={state.first_name}
          onChange={(e) => eventhandler(e)}
        />
        {errors.first_name && (
          <span className="text-red small d-block">Enter first name</span>
        )}
      </div>
      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {
            // error: errors.first_name,
          })}
          placeholder="Middle Name"
          id="middle_name"
          name="middle_name"
          value={state.middle_name}
          onChange={(e) => eventhandler(e)}
        />
      </div>
      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {
            error: errors.last_name,
          })}
          placeholder="Last Name*"
          id="last_name"
          name="last_name"
          // value={x.last_name}
          onChange={(e) => eventhandler(e)}
        />
        {errors.last_name && (
          <span className="text-red small d-block">Enter last name</span>
        )}
      </div>

      <div className="mt-18">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            value={state.DOB}
            clearable
            placeholder="DOB"
            onChange={(e) =>
              eventhandler({
                target: {
                  value: e,
                  name: "DOB",
                  id: "DOB",
                },
              })
            }
            maxDate={new Date("01-01-2005")}
            format="MM/dd/yyyy"
            mask="__/__/____"
            inputVariant="outlined"
            autoOk={true}
            InputAdornmentProps={{ position: "end" }}
            className={classNames("form-control team-name", {
              //   error: DOB,
            })}
          />
        </MuiPickersUtilsProvider>
        {errors.DOB == "required" && (
          <span className="text-red small d-block">Enter birth date</span>
        )}
      </div>

      <div className="mt-18">
        <select
          className="form-select form-control team-name"
          aria-label="Default select example"
          onChange={eventhandler}
          name="gender"
        >
          <option selected disabled>
            Gender
          </option>
          <option value={0}>Male</option>
          <option value={1}>Female</option>
        </select>
      </div>

      <div className="mt-18">
        <input
          type="number"
          className={classNames("form-control team-name", {
            error: errors.telephone,
          })}
          placeholder="Phone *"
          id="telephone"
          name="telephone"
          // value={x.tele}
          onChange={(e) => eventhandler(e)}
        />
        {errors.telephone && (
          <span className="text-red small d-block">Enter phone number.</span>
        )}
      </div>

      <div className="mt-18">
        <input
          type="email"
          className={classNames("form-control team-name", {
            error: errors.email,
          })}
          placeholder="Email *"
          id="email"
          name="email"
          // value={x.tele}
          onChange={(e) => eventhandler(e)}
        />
        {errors.email && (
          <span className="text-red small d-block">Enter email.</span>
        )}
      </div>

      <div className="mt-18">
        <input
          type="password"
          className={classNames("form-control team-name", {
            error: errors.email,
          })}
          placeholder="Password *"
          id="password"
          name="password"
          // value={x.tele}
          onChange={(e) => eventhandler(e)}
        />
        {errors.password && (
          <span className="text-red small d-block">Enter password.</span>
        )}
      </div>

      <div className="mt-18">
        <input
          type="password"
          className={classNames("form-control team-name", {
            error: errors.email,
          })}
          placeholder="Confirm password *"
          id="confirmPassword"
          name="confirmPassword"
          // value={x.tele}
          onChange={(e) => eventhandler(e)}
        />
        {errors.confirmPassword && (
          <span className="text-red small d-block">Password doesn't match</span>
        )}
      </div>

      <div className="mt-18">
        <div className="form-check flex items-center">
          <div onClick={handleClickOpen}>
            <Checkbox {...label} {...checked} />
          </div>
          <label className="form-check-label text-light" for="flexCheckDefault">
            Terms & Condition
          </label>
        </div>
      </div>

      <TermsPop
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        termText={termText}
      />

      <Button
        className="mt-3"
        style={{ backgroundColor: "#189AD6" }}
        onClick={handleSubmit}
      >
        Create
      </Button>
    </>
  );
};

export default PlayerSingupForm;
