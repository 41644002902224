import { REMOVE_ACCOUNT_ERROR, REMOVE_ACCOUNT_LOADING, REMOVE_ACCOUNT_SUCCESS } from "../constants";

let initialState = {
  success: false,
  error: false,
  loading: false,
  error: []
};
export default function removeAccountReducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_ACCOUNT_LOADING:
      return { ...state, success: false, loading: true, error: false }
    case REMOVE_ACCOUNT_SUCCESS:
      return { ...state, success: true, loading: false, error: false, message: action.msg }
    case REMOVE_ACCOUNT_ERROR:
      return { ...state, success: false, loading: false, error: false }
    default:
      return state;
  }
}
