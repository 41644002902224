import classNames from "classnames";
import React, { useState } from "react";
import {
  createMerchantAccount,
  getAllState,
} from "../../../service/action/userAction";
import { useEffect } from "react";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import ReactDatePicker from "react-datepicker";
import { createMerchandAccount } from "../../../service/action/Club/clubAction";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import axios from "axios";
import moment from "moment";
import { RESET_CREATE_MERCHAT_ACCOUNT } from "../../../service/constants";
import { useHistory } from "react-router-dom";

function MerchandAccountOrg(props) {
  const [stateList, setStateList] = useState([]);

  const history = useHistory();

  const [details, setDetails] = useState({
    company_legal_name: "",
    company_dba_name: "",
    company_ownership_type: "",
    company_federal_tax_id: "",
    legal_address: "",
    legal_postal_code: "",
    legal_state: "",
    legal_city: "",
    owner_principal_first_name: "",
    owner_principal_last_name: "",
    owner_principal_email: "",
    owner_principal_phone: "",
    owner_principal_dob: "",
    owner_principal_social_security: "",
    owner_principal_driver_license: "",
    bank_routing_number: "",
    bank_account_number: "",
  });
  const [errorDetails, setErrorDetails] = useState({
    company_legal_name: false,
    company_dba_name: false,
    company_ownership_type: false,
    company_federal_tax_id: false,
    legal_address: false,
    legal_postal_code: false,
    legal_state: false,
    legal_city: false,
    owner_principal_first_name: false,
    owner_principal_last_name: false,
    owner_principal_email: false,
    owner_principal_phone: false,
    owner_principal_dob: false,
    owner_principal_social_security: false,
    owner_principal_driver_license: false,
    bank_routing_number: false,
    bank_account_number: false,
  });

  useEffect(() => {
    props.getAllStateHandler();
  }, []);

  useEffect(() => {
    if (props.stateList.success) {
      const data = props.stateList.states;
      setStateList(data);
    }
  }, [props.stateList]);

  const eventHandler = (e) => {
    const { name, value } = e.target;
    if (
      name == "company_federal_tax_id" ||
      name == "owner_principal_social_security" ||
      name == "bank_routing_number"
    ) {
      if (!isNaN(value) && value.length < 10) {
        setDetails((pre) => ({
          ...pre,
          [name]: value,
        }));
      } else return;
    } else if (name == "legal_postal_code") {
      if (!isNaN(value) && value.length < 6) {
        setDetails((pre) => ({
          ...pre,
          [name]: value,
        }));
      } else return;
    } else if (name == "bank_account_number") {
      if (!isNaN(value) && value.length < 18) {
        setDetails((pre) => ({
          ...pre,
          [name]: value,
        }));
      } else return;
    } else {
      setDetails((pre) => ({
        ...pre,
        [name]: value,
      }));
    }
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    switch (name) {
      case "company_legal_name":
        if (value == "") {
          errorDetails["company_legal_name"] = "Required";
        } else {
          errorDetails["company_legal_name"] = false;
        }
        break;
      case "company_dba_name":
        if (value == "") {
          errorDetails["company_dba_name"] = "Required";
        } else {
          errorDetails["company_dba_name"] = false;
        }
        break;
      case "company_ownership_type":
        if (value == "") {
          errorDetails["company_ownership_type"] = "Required";
        } else {
          errorDetails["company_ownership_type"] = false;
        }
        break;
      case "company_federal_tax_id":
        if (value == "") {
          errorDetails["company_federal_tax_id"] = "Required";
        } else {
          errorDetails["company_federal_tax_id"] = false;
        }
        break;

      case "legal_address":
        if (value == "") {
          errorDetails["legal_address"] = "Required";
        } else {
          errorDetails["legal_address"] = false;
        }
        break;
      case "legal_postal_code":
        if (value == "") {
          errorDetails["legal_postal_code"] = "Required";
        } else {
          errorDetails["legal_postal_code"] = false;
        }
        break;
      case "legal_state":
        if (value == "") {
          errorDetails["legal_state"] = "Required";
        } else {
          errorDetails["legal_state"] = false;
        }
        break;
      case "legal_city":
        if (value == "") {
          errorDetails["legal_city"] = "Required";
        } else {
          errorDetails["legal_city"] = false;
        }
        break;

      case "owner_principal_first_name":
        if (value == "") {
          errorDetails["owner_principal_first_name"] = "Required";
        } else {
          errorDetails["owner_principal_first_name"] = false;
        }
        break;
      case "owner_principal_last_name":
        if (value == "") {
          errorDetails["owner_principal_last_name"] = "Required";
        } else {
          errorDetails["owner_principal_last_name"] = false;
        }
        break;
      case "owner_principal_email":
        if (value == "") {
          errorDetails["owner_principal_email"] = "Required";
        } else if (!validEmailRegex.test(value)) {
          errorDetails["owner_principal_email"] = "Required";
        } else {
          errorDetails["owner_principal_email"] = false;
        }
        break;
      case "owner_principal_phone":
        const _value = value.replaceAll("_", "");
        if (_value == "") {
          errorDetails["owner_principal_phone"] = "Required";
        } else if (_value.length < 12) {
          errorDetails["owner_principal_phone"] = "Required";
        } else {
          errorDetails["owner_principal_phone"] = false;
        }
        break;

      case "owner_principal_social_security":
        if (value == "") {
          errorDetails["owner_principal_social_security"] = "Required";
        } else {
          errorDetails["owner_principal_social_security"] = false;
        }
        break;
      case "owner_principal_driver_license":
        if (value == "") {
          errorDetails["owner_principal_driver_license"] = "Required";
        } else {
          errorDetails["owner_principal_driver_license"] = false;
        }
        break;
      case "bank_routing_number":
        if (value == "") {
          errorDetails["bank_routing_number"] = "Required";
        } else {
          errorDetails["bank_routing_number"] = false;
        }
        break;
      case "bank_account_number":
        if (value == "") {
          errorDetails["bank_account_number"] = "Required";
        } else {
          errorDetails["bank_account_number"] = false;
        }
        break;

      default:
        break;
    }
  };

  const handleCreateMerchant = () => {
    if (details.company_legal_name == "") {
      setErrorDetails((pre) => ({
        ...pre,
        company_legal_name: "Required",
      }));
    }
    if (details.company_dba_name == "") {
      setErrorDetails((pre) => ({
        ...pre,
        company_dba_name: "Required",
      }));
    }
    if (details.company_ownership_type == "") {
      setErrorDetails((pre) => ({
        ...pre,
        company_ownership_type: "Required",
      }));
    }
    if (details.company_federal_tax_id == "") {
      setErrorDetails((pre) => ({
        ...pre,
        company_federal_tax_id: "Required",
      }));
    }
    if (details.legal_address == "") {
      setErrorDetails((pre) => ({
        ...pre,
        legal_address: "Required",
      }));
    }
    if (details.legal_postal_code == "") {
      setErrorDetails((pre) => ({
        ...pre,
        legal_postal_code: "Required",
      }));
    }
    if (details.legal_state == "") {
      setErrorDetails((pre) => ({
        ...pre,
        legal_state: "Required",
      }));
    }
    if (details.legal_city == "") {
      setErrorDetails((pre) => ({
        ...pre,
        legal_city: "Required",
      }));
    }
    if (details.owner_principal_first_name == "") {
      setErrorDetails((pre) => ({
        ...pre,
        owner_principal_first_name: "Required",
      }));
    }
    if (details.owner_principal_last_name == "") {
      setErrorDetails((pre) => ({
        ...pre,
        owner_principal_last_name: "Required",
      }));
    }
    if (details.owner_principal_email == "") {
      setErrorDetails((pre) => ({
        ...pre,
        owner_principal_email: "Required",
      }));
    }
    if (details.owner_principal_phone == "") {
      setErrorDetails((pre) => ({
        ...pre,
        owner_principal_phone: "Required",
      }));
    }
    if (details.owner_principal_dob == "") {
      setErrorDetails((pre) => ({
        ...pre,
        owner_principal_dob: "Required",
      }));
    }
    if (details.owner_principal_social_security == "") {
      setErrorDetails((pre) => ({
        ...pre,
        owner_principal_social_security: "Required",
      }));
    }
    if (details.owner_principal_driver_license == "") {
      setErrorDetails((pre) => ({
        ...pre,
        owner_principal_driver_license: "Required",
      }));
    }
    if (details.bank_routing_number == "") {
      setErrorDetails((pre) => ({
        ...pre,
        bank_routing_number: "Required",
      }));
    }
    if (details.bank_account_number == "") {
      setErrorDetails((pre) => ({
        ...pre,
        bank_account_number: "Required",
      }));
    }

    if (
      details.company_legal_name !== "" &&
      details.company_dba_name !== "" &&
      details.company_ownership_type !== "" &&
      details.company_federal_tax_id !== "" &&
      details.legal_address !== "" &&
      details.legal_postal_code !== "" &&
      details.legal_state !== "" &&
      details.legal_city !== "" &&
      details.owner_principal_first_name !== "" &&
      details.owner_principal_last_name !== "" &&
      details.owner_principal_email !== "" &&
      details.owner_principal_phone !== "" &&
      details.owner_principal_dob !== "" &&
      details.owner_principal_social_security !== "" &&
      details.owner_principal_driver_license !== "" &&
      details.bank_routing_number !== "" &&
      details.bank_account_number !== "" &&
      !errorDetails.company_federal_tax_id &&
      !errorDetails.owner_principal_social_security &&
      !errorDetails.bank_routing_number &&
      !errorDetails.legal_postal_code &&
      !errorDetails.owner_principal_email &&
      !errorDetails.owner_principal_phone &&
      !errorDetails.owner_principal_dob
    ) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url: "https://platform.paragonsolutions.com/api/v2/identity",
        data: {
          username: process.env.REACT_APP_RESELLER_API_USERNAME,
          password: process.env.REACT_APP_RESELLER_PASSWORD,
        },
      };

      axios(requestOptions)
        .then((resp) => {
          const token = resp.data.access_token;
          const requestOptions2 = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            url: "https://platform.paragonsolutions.com/api/v2/correlationId",
          };

          axios(requestOptions2)
            .then((response) => {
              const relationId = response.data.correlationId;
              const requestOptions3 = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                },
                url: "https://platform.paragonsolutions.com/api/v2/AcceptTermsAndConditions",
                data: {
                  termsAndConditionsAccepted: true,
                  correlationId: relationId,
                  termsAndConditionsId: "6",
                  resellerKey: 215,
                },
              };
              axios(requestOptions3)
                .then((resp3) => {
                  const requestOptions4 = {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    url: "https://leadapi.paragonsolutions.com/v2/lead",
                    data: {
                      reseller_username:
                        process.env.REACT_APP_RESELLER_API_USERNAME,
                      reseller_password:
                        process.env.REACT_APP_RESELLER_PASSWORD,
                      reseller_key: process.env.REACT_APP_RESELLER_KEY,
                      correlation_id: relationId,
                      application_template_id:
                        process.env.REACT_APP_APPLICATION_TEMPLATE_ID,
                      terminal_template_ids:
                        process.env.REACT_APP_TERMINAL_TEMPLATE_ID,
                      company: {
                        company_legal_name: details.company_legal_name,
                        company_dba_name: details.company_dba_name,
                        company_ownership_type: details.company_ownership_type,
                        company_federal_tax_id: details.company_federal_tax_id,
                        legal_address: {
                          address: details.legal_address,
                          postal_code: details.legal_postal_code,
                          city: details.legal_city,
                          state: details.legal_state,
                        },
                      },
                      owner_Principal: {
                        first_name: details.owner_principal_first_name,
                        // middle_name: "m name",
                        last_name: details.owner_principal_last_name,
                        email_address: details.owner_principal_email,
                        phone: details.owner_principal_phone.replaceAll(
                          "-",
                          ""
                        ),
                        date_of_birth: moment(
                          details.owner_principal_dob
                        ).format("MM/DD/yyyy"),
                        social_security_number:
                          details.owner_principal_social_security,
                        drivers_license_number:
                          details.owner_principal_driver_license,
                      },
                      bank: {
                        bank_routing_number: details.bank_routing_number,
                        bank_account_number: details.bank_account_number,
                      },
                      return_user_activation_URL:
                        "https://stage.leadapi.paragonsolutions.com/index.html",
                    },
                  };
                  axios(requestOptions4)
                    .then((resp4) => {
                      props.createMerchantAccount({
                        merchant_key: resp4.data.merchant_key,
                        api_username: resp4.data.api_username,
                        api_password: resp4.data.api_password,
                        username: resp4.data.username,
                        password: resp4.data.password,
                        user_id: localStorage.getItem("Id"),
                        user_type: "1",
                      });
                    })
                    .catch((error4) => {
                      toast.error("Somthing went wrong");
                    });
                })
                .catch((error3) => {
                  toast.error("Somthing went wrong");
                });
            })
            .catch((error) => {
              toast.error("Somthing went wrong");
            });
        })
        .catch((err) => {
          toast.error("Somthing went wrong");
        });
    }
  };

  useEffect(() => {
    if (props.createMerchantReducer.success) {
      props.resetCreate();
      toast("Merchant Account Create Successfully");
      setTimeout(() => {
        history.push("/merchant");
      }, 2500);
    }
  }, [props.createMerchantReducer]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head-event">
              <h5 className="dash-head">Create Merchant Account</h5>
              <div
                className="mt-3 mt-md-0 text-right"
                onClick={handleCreateMerchant}
              >
                <button className="btn-save">Save Changes</button>
              </div>
            </div>
          </div>

          <div className="bank-info-tab mt-4 mt-md-0">
            <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-10 px-0">
              <h5 className="payment text-left mt-42">Company</h5>
              <div className="row">
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      requireField: errorDetails?.company_legal_name,
                    })}
                    placeholder=" Company Legal Name *"
                    name="company_legal_name"
                    id="company_legal_name"
                    value={details?.company_legal_name}
                    onChange={eventHandler}
                    autoComplete="off"
                    maxLength="50"
                  />
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      requireField: errorDetails?.company_dba_name,
                    })}
                    placeholder="Company DBA Name *"
                    name="company_dba_name"
                    id="company_dba_name"
                    value={details?.company_dba_name}
                    onChange={eventHandler}
                    maxLength="50"
                  />
                </div>
                <div className="col-md-6 mt-18">
                  <select
                    className={classNames("form-control team-name payterm", {
                      requireField: errorDetails?.company_ownership_type,
                    })}
                    id="company_ownership_type"
                    name="company_ownership_type"
                    value={details?.company_ownership_type}
                    onChange={eventHandler}
                  >
                    <option value="" disabled>
                      Company Ownership Type *
                    </option>
                    <option value="Sole Proprietorship">
                      Sole Proprietorship
                    </option>
                    <option value="Corporation">Corporation</option>
                    <option value="Partnership">Partnership</option>
                    <option value="LLC">LLC</option>
                    <option value="Non-Profit"> Non-Profit</option>
                    {/* <option value="Government "> Government </option> */}
                  </select>
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      requireField: errorDetails?.company_federal_tax_id,
                    })}
                    placeholder="Company Federal Tax Id *"
                    name="company_federal_tax_id"
                    id="company_federal_tax_id"
                    value={details?.company_federal_tax_id}
                    onChange={eventHandler}
                    minLength="9"
                    maxLength="9"
                  />
                </div>
              </div>

              <h5 className="payment text-left mt-42">Legal Address</h5>
              <div className="row">
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      requireField: errorDetails?.legal_address,
                    })}
                    placeholder="Legal Address *"
                    name="legal_address"
                    id="legal_address"
                    value={details?.legal_address}
                    onChange={eventHandler}
                    autoComplete="off"
                    maxLength="50"
                  />
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="tel"
                    className={classNames("form-control team-name", {
                      requireField: errorDetails?.legal_postal_code,
                    })}
                    placeholder="Legal Postal Code *"
                    name="legal_postal_code"
                    id="legal_postal_code"
                    value={details?.legal_postal_code}
                    onChange={eventHandler}
                    maxLength="5"
                  />
                </div>
                <div className="col-md-6 mt-18">
                  <select
                    className={classNames("form-control team-name payterm", {
                      requireField: errorDetails?.legal_state,
                    })}
                    id="legal_state"
                    name="legal_state"
                    value={details?.legal_state}
                    onChange={eventHandler}
                  >
                    <option value="" disabled>
                      State *
                    </option>
                    {stateList.map((e, i) => {
                      return (
                        <option value={e.isoCode} key={i}>
                          {e.name}
                        </option>
                      );
                    })}
                    {/* <option value="Government "> Government </option> */}
                  </select>
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      requireField: errorDetails?.legal_city,
                    })}
                    placeholder="Legal City *"
                    name="legal_city"
                    id="legal_city"
                    value={details?.legal_city}
                    onChange={eventHandler}
                    autoComplete="off"
                  />
                </div>
              </div>

              <h5 className="payment text-left mt-42">Owner Principal</h5>
              <div className="row">
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      requireField: errorDetails?.owner_principal_first_name,
                    })}
                    placeholder=" Owner Principal First Name *"
                    name="owner_principal_first_name"
                    id="owner_principal_first_name"
                    value={details?.owner_principal_first_name}
                    onChange={eventHandler}
                    autoComplete="off"
                    maxLength="40"
                  />
                </div>

                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      requireField: errorDetails?.owner_principal_last_name,
                    })}
                    placeholder="Owner Principal Last Name *"
                    name="owner_principal_last_name"
                    id="owner_principal_last_name"
                    value={details?.owner_principal_last_name}
                    onChange={eventHandler}
                    maxLength="50"
                  />
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="email"
                    className={classNames("form-control team-name", {
                      requireField: errorDetails?.owner_principal_email,
                    })}
                    placeholder="Owner Principal Email Address *"
                    name="owner_principal_email"
                    id="owner_principal_email"
                    value={details?.owner_principal_email}
                    onChange={eventHandler}
                  />
                </div>
                <div className="col-md-6 mt-18">
                  <InputMask
                    mask="999-999-9999"
                    className={classNames("form-control team-name", {
                      requireField: errorDetails?.owner_principal_phone,
                    })}
                    value={details?.owner_principal_phone}
                    onChange={eventHandler}
                    maskChar={"_"}
                    placeholder="Owner Principal Phone * "
                    name="owner_principal_phone"
                    id="owner_principal_phone"
                  />
                  {/* <input type="number" className="form-control team-name" placeholder="Phone *" name="phone" id="phone" value={details?.phone} onChange={eventHandler} maxLength="10" /> */}
                </div>
                <div className="col-md-6 mt-18">
                  <ReactDatePicker
                    showYearDropdown
                    autoComplete="off"
                    name="owner_principal_dob"
                    placeholderText="Owner Principal Date Of Birth"
                    dateFormat={"MM-dd-yyyy"}
                    selected={details.owner_principal_dob}
                    className={classNames("form-control mt-1", {
                      requireField: errorDetails?.owner_principal_dob,
                    })}
                    maxDate={new Date()}
                    onChange={(date) => {
                      setDetails((prevState) => ({
                        ...prevState,
                        owner_principal_dob: date,
                      }));
                      if (date === null || date === "") {
                        setErrorDetails((prevState) => ({
                          ...prevState,
                          owner_principal_dob: "Enter Valid Date of Birth",
                        }));
                      } else {
                        setErrorDetails((prevState) => ({
                          ...prevState,
                          owner_principal_dob: false,
                        }));
                      }
                    }}
                  />
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      requireField:
                        errorDetails?.owner_principal_social_security,
                    })}
                    placeholder="Owner Pricipal Social Security Number *"
                    name="owner_principal_social_security"
                    id="owner_principal_social_security"
                    value={details?.owner_principal_social_security}
                    onChange={eventHandler}
                    maxLength="9"
                    minLength="9"
                  />
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      requireField:
                        errorDetails?.owner_principal_driver_license,
                    })}
                    placeholder="Owner Principal Drivers License Number *"
                    name="owner_principal_driver_license"
                    id="owner_principal_driver_license"
                    value={details?.owner_principal_driver_license}
                    onChange={eventHandler}
                    maxLength="20"
                  />
                </div>
              </div>

              <h5 className="payment text-left mt-42">Bank</h5>
              <div className="row">
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      requireField: errorDetails?.bank_routing_number,
                    })}
                    placeholder="Bank Routing Number *"
                    name="bank_routing_number"
                    id="bank_routing_number"
                    value={details?.bank_routing_number}
                    onChange={eventHandler}
                    autoComplete="off"
                    minLength="9"
                    maxLength="9"
                  />
                </div>
                <div className="col-md-6 mt-18">
                  <input
                    type="text"
                    className={classNames("form-control team-name", {
                      requireField: errorDetails?.bank_account_number,
                    })}
                    placeholder="Bank Account Number *"
                    name="bank_account_number"
                    id="bank_account_number"
                    value={details?.bank_account_number}
                    onChange={eventHandler}
                    maxLength="17"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </React.Fragment>
  );
}
const mapStateToProp = (state) => ({
  stateList: state.getStateReducer,
  createMerchantReducer: state.MerchantReducer.createMerchant,
  // deleteMsg: state.deleteBankReducer,
  // editMsg: state.editBankReducer,
  // merchand: state.createMerchandAccountReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createMerchantAccount: (user) => dispatch(createMerchantAccount(user)),
  resetCreate: () => dispatch({ type: RESET_CREATE_MERCHAT_ACCOUNT }),
  // reseteditBankdetailsReducer: () => dispatch({ type: RESET_EDIT_BANK }),
  // resetDeleteBankReducer: () => dispatch({ type: RESET_BANK }),
  // bankDetailshandler: user => dispatch(createBankDetails(user)),
  // deleteBankdetailsHandler: user => dispatch(deleteBankdetail(user)),
  getAllStateHandler: (user) => dispatch(getAllState()),
  //   createMerchandAccountHandler: (user) => dispatch(createMerchandAccount(user)),
});
export default connect(mapStateToProp, mapDispatchToProps)(MerchandAccountOrg);
