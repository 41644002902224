import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  changeUserStatus,
  createClub,
  deleteClubAction,
  getClubList,
  updateClubAction,
} from "../../Services/Actions/userAction";
import { toast } from "react-toastify";
import {
  RESET_CHECK_MAIL_EXIST,
  RESET_CREATE_CLUB,
  RESET_DELETE_CLUB,
  RESET_UPDATE_CLUB,
  RESET_USER_STATUS,
  getDoubleDigitNumber,
  removeStorage,
  validEmailRegex,
  validatePhoneString,
} from "../../Services/constant";
import BreadcrumbHistory from "../BreadcrumbHistory/BreadcrumbHistory";
import {
  CityList,
  SportList,
  StateList,
  checkMailIsExist,
} from "../../Services/Actions";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import classNames from "classnames";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import moment from "moment";
import MaskedInput from "react-text-mask";
import ReactDatePicker from "react-datepicker";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";

const DATA_ITEM_KEY = "_id";

function Club(props) {
  const history = useHistory();
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [cityList, setCityList] = useState([]);

  const [status, setStatus] = useState({
    status: "",
    isChanged: false,
  });

  const [xlShow, setXlShow] = useState(false);
  const handleClose = () => setXlShow(false);
  const handleShow = () => setXlShow(true);

  const [delShow, setDelShow] = useState(false);
  const delDialClose = () => setDelShow(false);
  const delDialShow = () => setDelShow(true);

  const [deletedID, setdeletedID] = useState();

  const [successShow, setSuccessShow] = useState(false);
  const successDialClose = () => setSuccessShow(false);
  const successDialShow = () => {
    setDelShow(false);
    // setSuccessShow(true);
    props.DeleteClub({ id: deletedID });
  };
  const [InitialState, setInitialState] = useState([]);
  const [selectedID, setSelectedId] = useState("");
  const [isMailExist, setIsMailExist] = useState(false);
  const [pageDetails, setPageDetails] = useState({
    skip: 0,
    take: 10,
  });

  const InitialForm = {
    email: "",
    // .............
    club_name: "",
    mobile_number: "",
    // .......................
    contact_info_address: "",
    contact_info_state: "",
    contact_info_city: "",
    contact_info_zip: "",
    contact_info_sport: "",
    contact_info_website: "",
    primary_contact: {
      first_name: "",
      last_name: "",
      dob: null,
      primary_email: "",
      mobile_number: "",
    },
  };

  const InitialError = {
    email: false,
    // .............
    club_name: false,
    mobile_number: false,
    // .......................
    contact_info_address: false,
    contact_info_state: false,
    contact_info_city: false,
    contact_info_zip: false,
    contact_info_sport: false,
    contact_info_website: false,
    primary_contact: {
      first_name: false,
      last_name: false,
      dob: false,
      primary_email: false,
      mobile_number: false,
    },
  };

  const [detailsForm, setDetailsForm] = useState(InitialForm);
  const [errorForm, setErrorForm] = useState(InitialError);

  const [stateList, setStateList] = useState([]);
  const [sportList, setSportList] = useState([]);
  useEffect(() => {
    props.StateList();
    props.SportList();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (detailsForm.contact_info_state !== "") {
      props.CityList(detailsForm.contact_info_state);
      setDetailsForm((pre) => ({
        ...pre,
        contact_info_city: !!selectedID ? pre.contact_info_city : "",
      }));
    }
  }, [detailsForm.contact_info_state]);

  useEffect(() => {
    if (props.cityListReducer.success) {
      const data = props.cityListReducer.data;
      setCityList(data.data);
    }
  }, [props.cityListReducer]);

  useEffect(() => {
    if (props.stateListReducer.success) {
      const data = props.stateListReducer.data;
      setStateList(data.data);
    }
  }, [props.stateListReducer]);

  useEffect(() => {
    if (props.sportListReducer.success) {
      const data = props.sportListReducer.data;
      props.getClubList();
      setSportList(data.data);
    }
  }, [props.sportListReducer]);

  const getSportList = useCallback(
    (sports) => {
      if (
        !!sports &&
        sports.length !== 0 &&
        Array.isArray(sports) &&
        sportList.length !== 0
      ) {
        const list = sports?.map((e) => {
          const find = sportList?.find((list) => list._id == e)?.sport;
          return find;
        });
        return list.join(", ");
      } else {
        return "N/A";
      }
    },
    [sportList, props.sportListReducer.data]
  );

  useEffect(() => {
    if (props.clubListReducer.success) {
      const data = props.clubListReducer.data;
      const _data = data.data.map((e) => {
        if (e.sport)
          return {
            ...e,
            ClubName: e.name !== "" ? e.name : "N/A",
            name: e.first_name != "" ? `${e.first_name} ` : "N/A",
            email: !!e.email && e.email !== "" ? e.email : "N/A",
            address: !!e.address && e.address !== "" ? e.address : "N/A",
            city: e.city && e.city !== "" ? e.city : "N/A",
            state: !!e.state ? e.state.name : "N/A",
            stateID: !!e.state ? e.state._id : "N/A",
            sportID: !!e.sport ? e.sport[0] : "N/A",
            zip: e.zip !== "" ? e.zip : "N/A",
            sport: e.sport?.length !== 0 ? e.sport[0] : "N/A",
            website: !!e.website && e.website !== "" ? e.website : "N/A",
            mobile_number:
              !!e.mobile_number && e.mobile_number !== ""
                ? e.mobile_number
                : "N/A",
            sport: getSportList(e.sport),
            primary_contact:
              e.primary_contact.length !== 0
                ? {
                    first_name:
                      !!e.primary_contact?.[0].first_name &&
                      e.primary_contact[0].first_name != ""
                        ? e.primary_contact[0].first_name
                        : "N/A",
                    last_name:
                      !!e.primary_contact[0].last_name &&
                      e.primary_contact[0].last_name != ""
                        ? e.primary_contact[0].last_name
                        : "N/A",
                    dob:
                      !!e.primary_contact[0].primary_dob &&
                      e.primary_contact[0].primary_dob !== ""
                        ? e.primary_contact[0].primary_dob
                        : null,
                    primary_email:
                      !!e.primary_contact[0].primary_email &&
                      e.primary_contact[0].primary_email !== ""
                        ? e.primary_contact[0].primary_email
                        : "N/A",
                    mobile_number:
                      !!e.primary_contact[0].primary_mobile &&
                      e.primary_contact[0].primary_mobile !== ""
                        ? e.primary_contact[0].primary_mobile
                        : "",
                  }
                : {
                    first_name: "",
                    last_name: "",
                    dob: null,
                    primary_email: "",
                    mobile_number: "",
                  },
          };
      });
      setState(_data);
      setInitialState(_data);
      setLoading(false);
    } else if (props.clubListReducer.error) {
      const errMsg = props.clubListReducer.errorMsg;
      !!errMsg && toast.error(errMsg);
      setLoading(false);
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      }
    }
  }, [props.clubListReducer]);

  useEffect(() => {
    if (props.clubListReducer.success) {
      if (searchVal !== "") {
        const data = [...InitialState];
        const filter = data.filter(
          (e) =>
            e.ClubName.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.first_name.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.last_name.toLowerCase().includes(searchVal.toLowerCase())
        );
        setState(filter);
      } else {
        const data = [...InitialState];
        setState(data);
      }
    }
  }, [searchVal]);

  const customHeader = (cell) => {
    return <b title={cell.title}>{cell.title}</b>;
  };
  const customStatus = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <Button
          type="button"
          className={classNames("btn-status  btn-primary", {
            active: cell.dataItem.isActive == 1,
          })}
          onClick={() => {
            setSelectedId(cell.dataItem._id);
            setStatus({
              status: cell.dataItem.isActive == 1 ? "deactivate" : "activate",
              isChanged: true,
            });
          }}
        >
          {cell.dataItem.isActive == 1 ? "Active" : "Inactive"}
        </Button>
      </td>
    );
  };

  useEffect(() => {
    if (props.changeUserStatusReducer.success) {
      toast.success("Status Changed Successfuly");
      const _data = state.map((e) => {
        if (e._id == selectedID) {
          return {
            ...e,
            isActive: e.isActive == 0 ? 1 : 0,
          };
        } else
          return {
            ...e,
          };
      });

      setState(_data);

      setStatus({
        status: "",
        isChanged: false,
      });

      setSelectedId("");
      props.resetStatus();
    }
  }, [props.changeUserStatusReducer]);

  const customAction = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <button
          onClick={() => {
            const details = cell.dataItem;
            setSelectedId(details._id);
            setDetailsForm({
              ...details,
              first_name:
                details.first_name !== "N/A" ? details.first_name : "",
              last_name: details.last_name !== "N/A" ? details.last_name : "",
              dob:
                !!details.dob && details.dob !== ""
                  ? moment(details.dob, "MM-DD-yyyy").toDate()
                  : null,
              email: details.email !== "N/A" ? details.email : "",
              club_name: details.ClubName !== "N/A" ? details.ClubName : "",
              mobile_number:
                details?.mobile_number !== "N/A" ? details?.mobile_number : "",
              contact_info_address:
                details.address !== "N/A" ? details.address : "",
              contact_info_state:
                details.stateID !== "N/A" ? details.stateID : "",
              contact_info_city: details.city !== "N/A" ? details.city : "",
              contact_info_zip: details.zip !== "N/A" ? details.zip : "",
              contact_info_sport:
                details.sportID !== "N/A" ? details.sportID : "",
              contact_info_website:
                details.website !== "N/A" ? details.website : "",
            });
            setErrorForm({
              ...InitialError,
              primary_contact: {
                first_name: false,
                last_name: false,
                dob: false,
                primary_email: false,
                mobile_number: false,
              },
            });
            handleShow();
          }}
          className="btn-link btn text-secondary p-0 mx-2"
        >
          <i className="ri-pencil-fill"></i>
        </button>
        <button
          onClick={() => {
            const details = cell.dataItem;
            delDialShow();
            setdeletedID(details?._id);
          }}
          className="btn-link btn text-danger p-0 mx-2"
        >
          <i className="ri-delete-bin-fill"></i>
        </button>
      </td>
    );
  };

  const customCell = (cell) => {
    return (
      <td {...cell.tdProps} title={cell.children}>
        {cell.children}
      </td>
    );
  };
  const grid = (
    <Grid
      data={state.slice(pageDetails.skip, pageDetails.skip + pageDetails.take)}
      skip={pageDetails.skip}
      take={pageDetails.take}
      total={state.length}
      pageable={{
        buttonCount: 3,
        pageSizes: [5, 10, 15],
      }}
      onPageChange={(e) => {
        setPageDetails(() => ({
          ...e.page,
          take: e.page.take,
        }));
      }}
      dataItemKey={DATA_ITEM_KEY}
    >
      <GridNoRecords>No records are available</GridNoRecords>
      <GridColumn
        field="ClubName"
        title="Club Name"
        width="160"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      {/* <GridColumn
        field="name"
        title="Name"
        width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      /> */}
      <GridColumn
        field="email"
        title="Email"
        width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="address"
        title="Address"
        width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="city"
        title="City"
        width="100"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="state"
        title="State"
        width="100"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="zip"
        title="Zip Code"
        width="80"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="sport"
        title="Sport"
        width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="website"
        title="Website"
        width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="mobile_number"
        title="Phone"
        width="110"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="status"
        title="Status"
        width="100"
        headerCell={customHeader}
        cell={customStatus}
      />
      <GridColumn
        field="action"
        title="Actions"
        width="100"
        headerCell={customHeader}
        cell={customAction}
      />
    </Grid>
  );

  const handleForm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "contact_info_zip") {
      if (value.length < 6 && !isNaN(value)) {
        setDetailsForm((prev) => ({
          ...prev,
          contact_info_zip: value,
        }));
      } else {
        return;
      }
    } else if (name == "mobile_number") {
      if (value.length <= 14) {
        setDetailsForm((prev) => ({
          ...prev,
          mobile_number: value,
        }));
      } else {
        return;
      }
    } else if (name == "owner_telephone") {
      if (value.length <= 10 && !isNaN(value)) {
        setDetailsForm((prev) => ({
          ...prev,
          owner_telephone: value,
        }));
      } else {
        return;
      }
    } else {
      setDetailsForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    switch (name) {
      case "email":
        if (value == "") {
          errorForm["email"] = "Required";
        } else if (!validEmailRegex.test(value)) {
          errorForm["Email"] = "Enter Valid Email";
        } else {
          errorForm["email"] = false;
        }
        break;
      case "club_name":
        if (value == "") {
          errorForm["club_name"] = "Required";
        } else {
          errorForm["club_name"] = false;
        }
        break;
      case "mobile_number":
        if (!validatePhoneString.test(value) && value != "") {
          errorForm["mobile_number"] = "Enter Valid Phone Number";
        } else if (value.length != 0 && value.length != 12) {
          errorForm["mobile_number"] = "Enter Valid Phone Number";
        } else if (value == "") {
          errorForm["mobile_number"] = "Enter Valid Phone Number";
        } else {
          errorForm["mobile_number"] = false;
        }
        break;
      case "contact_info_address":
        if (value == "") {
          errorForm["contact_info_address"] = "Required";
        } else {
          errorForm["contact_info_address"] = false;
        }
        break;
      case "contact_info_state":
        if (value == "") {
          errorForm["contact_info_state"] = "Required";
        } else {
          errorForm["contact_info_state"] = false;
        }
        break;
      case "contact_info_city":
        if (value == "") {
          errorForm["contact_info_city"] = "Required";
        } else {
          errorForm["contact_info_city"] = false;
        }
        break;
      case "contact_info_zip":
        if (value == "") {
          errorForm["contact_info_zip"] = "Required";
        } else if (value.length < 5) {
          errorForm["contact_info_zip"] = "Required";
        } else {
          errorForm["contact_info_zip"] = false;
        }
        break;
      case "contact_info_website":
        if (value == "") {
          errorForm["contact_info_website"] = false;
        } else if (!RegExp(/http:|https:/).test(value)) {
          errorForm["contact_info_website"] =
            "Enter Valid contact_info_website";
        } else {
          errorForm["contact_info_website"] = false;
        }
        break;
      case "contact_info_sport":
        if (value == "") {
          errorForm["contact_info_sport"] = "Required";
        } else {
          errorForm["contact_info_sport"] = false;
        }
        break;

      default:
        break;
    }
  };

  const handleContactForm = (e) => {
    const { name, value } = e.target;

    if (name == "mobile_number") {
      if (value.length <= 14) {
        setDetailsForm((prev) => ({
          ...prev,
          primary_contact: {
            ...prev.primary_contact,
            mobile_number: value,
          },
        }));
      } else {
        return;
      }
    } else {
      setDetailsForm((prev) => ({
        ...prev,
        primary_contact: {
          ...prev.primary_contact,
          [name]: value,
        },
      }));
    }
    switch (name) {
      case "first_name":
        if (value == "") {
          errorForm.primary_contact.first_name = "Required";
        } else {
          errorForm.primary_contact.first_name = false;
        }
        break;
      case "last_name":
        if (value == "") {
          errorForm.primary_contact.last_name = "Required";
        } else {
          errorForm.primary_contact.last_name = false;
        }
        break;
      case "primary_email":
        if (value == "") {
          errorForm.primary_contact.primary_email = "Required";
        } else if (!validEmailRegex.test(value)) {
          errorForm.primary_contact.primary_email = "Enter Valid Email";
        } else {
          errorForm.primary_contact.primary_email = false;
        }
        break;

      case "dob":
        if (value == "" || value == null) {
          errorForm.primary_contact.dob = false;
        } else if (value.toString() == "Invalid Date") {
          errorForm.primary_contact.dob = "Enter Valid Date";
        } else if (moment(value).isBefore(moment("01-01-1947", "MM-DD-yyyy"))) {
          errorForm.primary_contact.dob =
            "Date should not be before minimal date";
        } else if (
          moment(value).isSameOrAfter(moment().subtract(18, "years"))
        ) {
          errorForm.primary_contact.dob = "Age must be 18 years old";
        } else {
          errorForm.primary_contact.dob = false;
        }
        break;

      case "mobile_number":
        if ((!validatePhoneString.test(value) && value != "") || value == "") {
          errorForm.primary_contact.mobile_number = "Enter Valid Phone Number";
        } else if (value.length > 0 && value.length != 12) {
          errorForm.primary_contact.mobile_number = "Enter Valid Phone Number";
        } else {
          errorForm.primary_contact.mobile_number = false;
        }
        break;
      default:
        break;
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    if (detailsForm.email == "") {
      setErrorForm((prev) => ({
        ...prev,
        email: "Required",
      }));
    }
    if (detailsForm.club_name == "") {
      setErrorForm((prev) => ({
        ...prev,
        club_name: "Required",
      }));
    }
    if (detailsForm.mobile_number == "") {
      setErrorForm((prev) => ({
        ...prev,
        mobile_number: "Required",
      }));
    }
    if (detailsForm.contact_info_address == "") {
      setErrorForm((prev) => ({
        ...prev,
        contact_info_address: "Required",
      }));
    }
    if (detailsForm.contact_info_city == "") {
      setErrorForm((prev) => ({
        ...prev,
        contact_info_city: "Required",
      }));
    }
    if (detailsForm.contact_info_state == "") {
      setErrorForm((prev) => ({
        ...prev,
        contact_info_state: "Required",
      }));
    }
    if (detailsForm.contact_info_zip == "") {
      setErrorForm((prev) => ({
        ...prev,
        contact_info_zip: "Required",
      }));
    }
    if (detailsForm.contact_info_sport == "") {
      setErrorForm((prev) => ({
        ...prev,
        contact_info_sport: "Required",
      }));
    }

    if (
      detailsForm?.contact_info_website !== "" &&
      !RegExp(/http:|https:/).test(detailsForm.contact_info_website)
    ) {
      setErrorForm((prevState) => ({
        ...prevState,
        contact_info_website: "Required",
      }));
    }
    if (detailsForm.primary_contact.first_name == "") {
      setErrorForm((prev) => ({
        ...prev,
        primary_contact: {
          ...prev.primary_contact,
          first_name: "Required",
        },
      }));
    }
    if (detailsForm.primary_contact.last_name == "") {
      setErrorForm((prev) => ({
        ...prev,
        primary_contact: {
          ...prev.primary_contact,
          last_name: "Required",
        },
      }));
    }
    if (detailsForm.primary_contact.primary_email == "") {
      setErrorForm((prev) => ({
        ...prev,
        primary_contact: {
          ...prev.primary_contact,
          primary_email: "Required",
        },
      }));
    }
    if (detailsForm.primary_contact.mobile_number == "") {
      setErrorForm((prev) => ({
        ...prev,
        primary_contact: {
          ...prev.primary_contact,
          mobile_number: "Required",
        },
      }));
    }

    // const isValidPrimaryContact = errorForm.primary_contact
    //   .map((e) => {
    //     return e.primary_email == false && e.dob == false ? true : false;
    //   })
    //   .every((e) => e == true);

    // const is18Plus =
    //   detailsForm.dob !== null
    //     ? moment().diff(detailsForm.dob, "years", false) >= 18
    //     : false;

    // if (!is18Plus) {
    //   setErrorForm((prev) => ({
    //     ...prev,
    //     dob: "Required",
    //   }));
    //   toast.error("Age must be 18 years old");
    // }

    if (
      detailsForm.email !== "" &&
      detailsForm.name !== "" &&
      detailsForm.address !== "" &&
      detailsForm.city !== "" &&
      detailsForm.state !== "" &&
      detailsForm.contact_info_zip !== "" &&
      detailsForm.primary_contact.first_name !== "" &&
      detailsForm.primary_contact.last_name !== "" &&
      detailsForm.primary_contact.primary_email !== "" &&
      detailsForm.primary_contact.mobile_number !== "" &&
      detailsForm.mobile_number !== "" &&
      !errorForm.email &&
      !errorForm.mobile_number &&
      !errorForm.primary_contact.dob &&
      !errorForm.contact_info_zip
      // isValidPrimaryContact
    ) {
      const details = {
        ClubID: selectedID,
        email: detailsForm.email !== "N/A" ? detailsForm.email : "",
        name: detailsForm.club_name !== "N/A" ? detailsForm.club_name : "",
        address:
          detailsForm.contact_info_address !== "N/A"
            ? detailsForm.contact_info_address
            : "",
        city:
          detailsForm.contact_info_city !== "N/A"
            ? detailsForm.contact_info_city
            : "",
        state:
          detailsForm.contact_info_state !== "N/A"
            ? detailsForm.contact_info_state
            : "",
        zip:
          detailsForm.contact_info_zip !== "N/A"
            ? detailsForm.contact_info_zip
            : "",
        sport:
          detailsForm?.contact_info_sport !== "N/A"
            ? detailsForm?.contact_info_sport
            : "",
        website:
          detailsForm.contact_info_website !== "N/A"
            ? detailsForm.contact_info_website
            : "",
        cityName:
          detailsForm.contact_info_city !== "N/A"
            ? detailsForm.contact_info_city
            : "",
        password: 123456789,
        mobile_number:
          detailsForm.mobile_number !== "N/A" ? detailsForm.mobile_number : "",
        user_type: 4,
        primary_contact: [
          {
            first_name: detailsForm.primary_contact.first_name,
            last_name: detailsForm.primary_contact.last_name,
            mobile_number: detailsForm.primary_contact.mobile_number,
            primary_email: detailsForm.primary_contact.primary_email,
            primary_dob:
              detailsForm.primary_contact.dob !== null &&
              detailsForm.primary_contact.dob !== ""
                ? moment(detailsForm.primary_contact.dob).format("MM/DD/yyyy")
                : "",
          },
        ],
      };
      selectedID == "" ? props.createClub(details) : props.updateClub(details);
    }
  };
  useEffect(() => {
    if (detailsForm.email !== "") {
      if (selectedID != "") {
        const q = InitialState.find((e) => e._id == selectedID).email;
        if (q != detailsForm.email) {
          setTimeout(() => {
            props.checkMailIsExist(detailsForm.email);
          }, 500);
        } else {
          props.resetisCheckEmail();
          setIsMailExist(false);
        }
      } else {
        setTimeout(() => {
          props.checkMailIsExist(detailsForm.email);
        }, 500);
      }
    } else {
      props.resetisCheckEmail();
      setIsMailExist(false);
    }
  }, [detailsForm.email]);

  useEffect(() => {
    if (props.isMailExistReducer.success) {
      setIsMailExist(props.isMailExistReducer.data.status);
    } else if (props.isMailExistReducer.error) {
      const errMsg = props.isMailExistReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.isMailExistReducer]);
  useEffect(() => {
    if (props.createClubReducer.success) {
      handleClose();
      toast.success("Club created successfully");
      props.resetCreateClub();
      props.getClubList();
      setLoading(true);
      props.resetisCheckEmail();
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
    } else if (props.createClubReducer.error) {
      const errMsg = props.createClubReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg?.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.createClubReducer]);

  useEffect(() => {
    if (props.UpdateClubReducer.success) {
      handleClose();
      toast.success("Club updated successfully");
      props.resetUpdateClub();
      props.getClubList();
      setLoading(true);
      props.resetisCheckEmail();
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
    } else if (props.UpdateClubReducer.error) {
      const errMsg = props.UpdateClubReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg?.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.UpdateClubReducer]);

  useEffect(() => {
    if (props.DeleteClubReducer.success) {
      handleClose();
      toast.success("Club deleted successfully");
      props.resetdeleteclub();
      props.getClubList();
      setLoading(true);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
    } else if (props.DeleteClubReducer.error) {
      const errMsg = props.DeleteClubReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg?.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.DeleteClubReducer]);

  const handleAdd = (e) => {
    e.preventDefault();
    setDetailsForm((pre) => ({
      ...pre,
      primary_contact: [
        ...pre.primary_contact,
        {
          primary_email: "",
          first_name: "",
          middle_name: "",
          last_name: "",
        },
      ],
    }));
    setErrorForm((pre) => ({
      ...pre,
      primary_contact: [
        ...pre.primary_contact,
        {
          primary_email: false,
          first_name: false,
          middle_name: false,
          last_name: false,
        },
      ],
    }));
  };
  const handleRemove = (e, i) => {
    e.preventDefault();
    const detail = [...detailsForm.primary_contact];
    const errorDetail = [...errorForm.primary_contact];
    detail.splice(i, 1);
    errorDetail.splice(i, 1);
    setDetailsForm((pre) => ({
      ...pre,
      primary_contact: [...detail],
    }));
    setErrorForm((pre) => ({
      ...pre,
      primary_contact: [...errorDetail],
    }));
  };

  return (
    <>
      {loading ? (
        <>Loading .... </>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <h2 className="page-heading">Clubs</h2>
          </div>
          <Row className="justify-content-between mx-0">
            <Col md="4" className="search px-0">
              <i className="ri-search-line"></i>
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchVal}
                onChange={(e) => {
                  setSearchVal(e.target.value);
                }}
              />
            </Col>
            <Col md="6" className="text-end px-0">
              <button
                className="btn-secondary px-4 py-2"
                onClick={() => {
                  handleShow();
                  setSelectedId("");
                }}
              >
                Add Clubs
              </button>
            </Col>
          </Row>
          <div className="table-card">{grid}</div>

          <Modal
            size="xl"
            show={xlShow}
            onHide={() => {
              setDetailsForm(InitialForm);
              setErrorForm(InitialError);
              handleClose();
              setIsMailExist(false);
            }}
          >
            <div
              style={{
                height: "85vh",
                overflowY: "auto",
              }}
            >
              <Modal.Header>
                <Modal.Title>Create Club</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* .........................................     Contact info for the club    .............................................. */}

                <h4 className="mb-4" style={{ fontWeight: "bold" }}>
                  Contact Info for the club
                </h4>
                <Row className="ml-auto mx-0">
                  <Form.Group as={Col} className="mb-4">
                    <Form.Label>
                      Club Name<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="club_name"
                      className={classNames({
                        errorBorder: errorForm.club_name,
                      })}
                      value={detailsForm.club_name}
                      onChange={handleForm}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4">
                    <Form.Label>
                      Address<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={1}
                      name="contact_info_address"
                      className={classNames({
                        errorBorder: errorForm.contact_info_address,
                      })}
                      value={detailsForm.contact_info_address}
                      onChange={handleForm}
                    />
                    {/*  */}
                  </Form.Group>
                </Row>
                {/* <Form.Group className="mb-4">
                  <Form.Label>
                    Address<sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="contact_info_address"
                    className={classNames({
                      errorBorder: errorForm.contact_info_address,
                    })}
                    value={detailsForm.contact_info_address}
                    onChange={handleForm}
                  />
                </Form.Group> */}
                <Row className="ml-auto mx-0">
                  <Form.Group as={Col} className="mb-4">
                    <Form.Label>
                      State<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Select
                      onChange={handleForm}
                      value={detailsForm.contact_info_state}
                      name="contact_info_state"
                      className={classNames("form-control", {
                        errorBorder: errorForm.contact_info_state,
                      })}
                    >
                      <option value={""} disabled>
                        select state
                      </option>
                      {stateList.map((e, i) => {
                        return (
                          <option key={i} value={e._id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="">
                    <Form.Label>
                      City<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      list="cityNameList"
                      placeholder=""
                      name="contact_info_city"
                      className={classNames("form-control", {
                        errorBorder: errorForm.contact_info_city,
                      })}
                      value={detailsForm.contact_info_city}
                      onChange={handleForm}
                    />
                    <datalist id="cityNameList">
                      {cityList.map((e, i) => {
                        return <option value={e.name} key={i} />;
                      })}
                    </datalist>
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4">
                    <Form.Label>
                      Zipcode<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="contact_info_zip"
                      className={classNames({
                        errorBorder: errorForm.contact_info_zip,
                      })}
                      value={detailsForm.contact_info_zip}
                      onChange={handleForm}
                    />
                  </Form.Group>
                </Row>
                <Row className="ml-auto mx-0">
                  <Form.Group as={Col} className="mb-4">
                    <Form.Label>Website</Form.Label>
                    <Form.Control
                      type="url"
                      placeholder=""
                      name="contact_info_website"
                      value={detailsForm.contact_info_website}
                      onChange={handleForm}
                      className={classNames({
                        errorBorder: errorForm.contact_info_website,
                      })}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4">
                    <Form.Label>
                      Phone<sup className="text-danger">*</sup>
                    </Form.Label>

                    <MaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                      ]}
                      name="mobile_number"
                      placeholder=""
                      guide={true}
                      value={detailsForm.mobile_number}
                      onChange={handleForm}
                      className={classNames("form-control", {
                        errorBorder: errorForm.mobile_number,
                      })}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4">
                    <Form.Label>
                      Sport<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Select
                      onChange={handleForm}
                      value={detailsForm.contact_info_sport}
                      name="contact_info_sport"
                      className={classNames("form-control", {
                        errorBorder: errorForm.contact_info_sport,
                      })}
                    >
                      <option value={""} disabled>
                        select Sport
                      </option>
                      {sportList.map((e, i) => {
                        return (
                          <option key={i} value={e._id}>
                            {e.sport}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="ml-auto mx-0">
                  <Form.Group as={Col} className="mb-4">
                    <Form.Label>
                      Club Email <sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder=""
                      name="email"
                      className={classNames({
                        errorBorder: errorForm.email,
                      })}
                      value={detailsForm.email}
                      disabled={selectedID !== ""}
                      onChange={handleForm}
                    />
                    {isMailExist && (
                      <div
                        className="mt-2"
                        style={{
                          fontSize: "14px",
                          color: "red",
                          lineHeight: "23px",
                        }}
                      >
                        <u>
                          <b>Please note:</b>
                        </u>
                        &nbsp;This email ID has been used to create another
                        account. Please proceed if you'd like to add this
                        account underneath that account. Otherwise, please enter
                        a new email ID.
                      </div>
                    )}
                  </Form.Group>
                </Row>

                {/* .........................................  Director Details   ....................................... */}

                <h4 className="mb-4" style={{ fontWeight: "bold" }}>
                  Director Details
                </h4>
                <Row className="ml-auto mx-0">
                  <Form.Group as={Col} className="mb-4">
                    <Form.Label>First Name</Form.Label>
                    <sup className="text-danger">*</sup>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="first_name"
                      value={detailsForm.primary_contact.first_name}
                      className={classNames({
                        errorBorder: errorForm.primary_contact.first_name,
                      })}
                      onChange={handleContactForm}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4">
                    <Form.Label>Last Name</Form.Label>
                    <sup className="text-danger">*</sup>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="last_name"
                      value={detailsForm.primary_contact.last_name}
                      className={classNames({
                        errorBorder: errorForm.primary_contact.last_name,
                      })}
                      onChange={handleContactForm}
                    />
                  </Form.Group>
                </Row>
                <Row className="ml-auto mx-0">
                  <Form.Group as={Col} className="mb-4 ">
                    <Form.Label>Date of Birth</Form.Label>
                    {/* <sup className="text-danger">*</sup> */}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        value={detailsForm.primary_contact.dob}
                        clearable
                        placeholder=""
                        minDate={moment("01-01-1947", "MM-DD-yyyy")}
                        maxDate={moment().subtract(18, "years")}
                        onChange={(date) =>
                          handleContactForm({
                            target: {
                              name: "dob",
                              value: date,
                            },
                          })
                        }
                        format="MM/dd/yyyy"
                        mask="__/__/____"
                        inputVariant="outlined"
                        autoOk={true}
                        InputAdornmentProps={{ position: "end" }}
                        error={Boolean(errorForm.primary_contact.dob)}
                        helperText={errorForm.primary_contact.dob}
                        className={classNames("form-control team-name", {
                          errorBorder: errorForm.primary_contact.dob,
                        })}
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4">
                    <Form.Label>Email</Form.Label>
                    <sup className="text-danger">*</sup>
                    <Form.Control
                      type="email"
                      placeholder=""
                      name="primary_email"
                      value={detailsForm.primary_contact.primary_email}
                      className={classNames({
                        errorBorder: errorForm.primary_contact.primary_email,
                      })}
                      disabled={selectedID !== ""}
                      onChange={handleContactForm}
                    />
                  </Form.Group>

                  <Form.Group as={Col} className="mb-4">
                    <Form.Label>Phone</Form.Label>
                    <sup className="text-danger">*</sup>

                    <MaskedInput
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        "-",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                      ]}
                      name="mobile_number"
                      placeholder=""
                      guide={true}
                      value={detailsForm.primary_contact.mobile_number}
                      onChange={handleContactForm}
                      className={classNames("form-control", {
                        errorBorder: errorForm.primary_contact.mobile_number,
                      })}
                    />
                  </Form.Group>
                </Row>
                <div className="text-end ml-auto">
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setDetailsForm(InitialForm);
                      setErrorForm(InitialError);
                      handleClose();
                      setIsMailExist(false);
                    }}
                    style={{ fontSize: "18px" }}
                    className="mr-3 pointer"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="secondary"
                    type="submit"
                    onClick={handleCreate}
                  >
                    {selectedID == "" ? "Submit" : "Update"}
                  </Button>
                </div>
              </Modal.Body>
            </div>
          </Modal>
          {/* Status Changed  */}
          <Modal
            size="sm"
            show={status.isChanged}
            onHide={() =>
              setStatus({
                isChanged: false,
                status: "",
              })
            }
            centered
            className="del-dialogue"
          >
            <Modal.Body>
              <h6 className="text-center">
                Are you sure you want to <strong>{status.status}</strong> this
                account?
              </h6>
            </Modal.Body>
            <Modal.Footer className="p-0">
              <Button
                className="btn-gray"
                onClick={() =>
                  setStatus({
                    isChanged: false,
                    status: "",
                  })
                }
              >
                No
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  props.changeUserStatus({
                    type: 4,
                    id: selectedID,
                  });
                }}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            size="sm"
            show={delShow}
            onHide={delDialClose}
            centered
            className="del-dialogue"
          >
            {/* <Modal.Header closeButton>
                              <Modal.Title>delete</Modal.Title>
                        </Modal.Header> */}
            <Modal.Body>
              <h6 className="text-center">
                Are you sure you want to delete this account?
              </h6>
            </Modal.Body>
            <Modal.Footer className="p-0">
              <Button className="btn-gray" onClick={delDialClose}>
                No
              </Button>
              <Button variant="secondary" onClick={successDialShow}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            size="sm"
            show={successShow}
            onHide={successDialClose}
            centered
            className="del-dialogue success"
          >
            <Modal.Body className="text-center">
              <i className="ri-close-circle-line fa-4x text-secondary"></i>
              <h5 className="text-center">Record deleted Successfully !!</h5>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

const mapStateToProp = (state) => ({
  clubListReducer: state.superAdminRootReducer.UserReducer.clubList,
  createClubReducer: state.superAdminRootReducer.UserReducer.createClub,
  UpdateClubReducer: state.superAdminRootReducer.UserReducer.UpdateClub,
  stateListReducer: state.superAdminRootReducer.authReducers.stateList,
  sportListReducer: state.superAdminRootReducer.authReducers.sportList,
  DeleteClubReducer: state.superAdminRootReducer.UserReducer.ClubDelete,
  cityListReducer: state.superAdminRootReducer.authReducers.cityList,
  changeUserStatusReducer:
    state.superAdminRootReducer.UserReducer.changeUserStatus,
  isMailExistReducer: state.superAdminRootReducer.authReducers.isMailExist,
});

const mapDispatchToProps = (dispatch) => ({
  getClubList: () => dispatch(getClubList()),
  StateList: () => dispatch(StateList()),
  CityList: (state) => dispatch(CityList(state)),
  SportList: () => dispatch(SportList()),
  createClub: (details) => dispatch(createClub(details)),
  updateClub: (details) => dispatch(updateClubAction(details)),
  DeleteClub: (details) => dispatch(deleteClubAction(details)),
  changeUserStatus: (details) => dispatch(changeUserStatus(details)),
  checkMailIsExist: (email) => dispatch(checkMailIsExist(email)),

  resetCreateClub: () => dispatch({ type: RESET_CREATE_CLUB }),
  resetUpdateClub: () => dispatch({ type: RESET_UPDATE_CLUB }),
  resetdeleteclub: () => dispatch({ type: RESET_DELETE_CLUB }),
  resetStatus: () => dispatch({ type: RESET_USER_STATUS }),
  resetisCheckEmail: () => dispatch({ type: RESET_CHECK_MAIL_EXIST }),
});

export default connect(mapStateToProp, mapDispatchToProps)(Club);
