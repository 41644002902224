import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { coachAcceptList, getAllDivision_club } from '../../../service/action/Club/clubAction';
import { connect, useSelector } from 'react-redux';
import { getAllSeason_club } from '../../../service/action/Club/clubAction';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { createTeam, uploadTeamImage } from '../../../service/action/userAction';
import Toaster from '../../../Toaster/Toaster';
import { RESET_CREATE_PAYMENT_CODE, RESET_CREATE_TEAM } from '../../../service/constants';
import Spinner from "../../../Spinner/Spinner"
import BreadcrumbHistory from '../../../BreadcumbHistory/BreadcrumbHistory';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const CreateTeam = (props) => {

    const division = props.getAllDivisionHandler
    const season = props.getAllSeasonHandler
    const getCoachAcceptList = props.getCoachAcceptListHandler

    const history = useHistory()
    const [loading, setLoading] = useState(true)

    const alldivision = useSelector(state => state.getAllClubDivision.user)
    const allseason = useSelector(state => state.getAllClubSeasonReducer.user)
    const coachAcceptList = useSelector(state => state.coachAcceptListReducer.user.data)
    const accountDetails = useSelector(state => state.accountDetailsReducer.user.data)
    const msg = useSelector(state => state.createTeamReducer.message)

    const location = useLocation()
    useEffect(() => {
        division()
        season()
        getCoachAcceptList()
    }, [])

    useEffect(() => {
        if (props.season?.success) {
            setLoading(false)
        }
    }, [props.season])

    const [image, setImage] = useState("")
    const [pitcher, setPitcher] = useState(null)

    const onChangePicture = event => {
        setImage(URL.createObjectURL(event.target.files[0]));
        if (event.target.files && event.target.files[0]) {
            setPitcher(event.target.files[0]);
        }
    };

    {/* //-----------=====-----------Add Coach Info Start-----------====----------- */ }

    // Try this demo
    const [inputList, setInputList] = useState([])
    const [coachError, setCoachError] = useState([])

    const result = inputList.map(a => a.coach_name);
    const result1 = result.filter(a => a)

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        const errorList = [...coachError]
        list.splice(index, 1);
        errorList.splice(index, 1);
        setInputList(list);
        setCoachError(errorList);
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value, id } = e.target;
        const list = [...inputList];
        list[index][name] = e.target.value;
        setInputList(list);
        switch (name) {


            case "coach_name":
                if (value === '') {
                    coachError[index]["coach_name"] = 'Select Coach name'
                }
                else {
                    coachError[index]["coach_name"] = false
                }

                break;

            default:
                break;
        }

    };

    // handle click event of the Add button
    const handleAddClick = () => {
        const isEmpty = inputList.map(e => {
            if (e.coach_name !== '') {
                return true
            } else {
                return false
            }
        })

        if (inputList.length === 0 || isEmpty.every((e) => e === true)) {
            setInputList([...inputList, {
                coach_name: ""
            }]);
            setCoachError([...coachError, {
                coach_name: false
            }]);
        } else {
            isEmpty.forEach((e, i) => {
                if (e === false) {
                    coachError[i].coach_name = 'Select Coach name'
                }
            })
        }
    };
    {/* //-----------=====-----------Add Coach Info End-----------====----------- */ }


    const [state, setState] = useState({
        season_name: "",
        team_name: "",
        division: "",
        gender: "",
    })



    const [errors, setErrors] = useState({
        season_name: false,
        team_name: false,
        division: false,
        gender: false,
        // coach_name: false,

    })


    const eventHandler = (e) => {
        const { id, value, name } = e.target

        setState(prevState => ({
            ...prevState,
            [id]: value
            // [e.target.name]: e.target.value.trim()
        }))
        switch (name) {
            case "team_name":
                if (value.length === 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        team_name: "Please enter team name *"
                    }))

                    document.getElementById("team_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        team_name: false
                    }))

                    document.getElementById("team_name").classList.remove("error")
                }
                break;

            case "division":

                if (value === 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        division: "Division can not be empty"
                    }))

                    document.getElementById("division").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        division: false
                    }))

                    document.getElementById("division").classList.remove("error")
                }
                break;
            case "season_name":

                if (value === 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        season_name: "Season can not be empty"
                    }))

                    document.getElementById("season_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        season_name: false
                    }))

                    document.getElementById("season_name").classList.remove("error")
                }
                break;
            // case "gender":

            //     if (value === 0) {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             gender: "gender can not be empty"
            //         }))

            //         document.getElementById("gender").classList.add("gender")
            //     }
            //     else {

            //         setErrors(prevState => ({
            //             ...prevState,
            //             gender: false
            //         }))

            //         document.getElementById("gender").classList.remove("error")
            //     }
            //     break;

            default:
                return state
        }
    }

    const notify = (msg) => toast(msg);

    const submitRequest = (e) => {
        e.preventDefault();
        // alert("click")

        if (state.team_name === "") {
            setErrors(prevState => ({
                ...prevState,
                team_name: "Please enter team name *"
            }))
            document.getElementById("team_name").classList.add("error")
        }

        // if (state.division === 0) {
        //     setErrors(prevState => ({
        //         ...prevState,
        //         division: "Division can not be empty"
        //     }))
        //     document.getElementById("division").classList.add("error")
        // }

        // if (state.season_name === 0) {
        //     setErrors(prevState => ({
        //         ...prevState,
        //         season_name: "Season can not be empty"
        //     }))
        //     document.getElementById("season_name").classList.add("error")
        // }
        if (state.team_name !== "") {
            props.createTeamClubhandler((state.division === "" && state.season_name === "" && result1.length === 0) ? {
                team_name: state.team_name,
            } : {
                team_name: state.team_name,
                division: state.division,
                season: state.season_name,
                coach: result1,
            })
        }
    }


    useEffect(() => {
        const UploadImage = async () => {
            if (props.submit?.success) {
                if (pitcher) {
                    await props.uploadTeamImageHandler({ id: msg.data.id, image: pitcher })
                }
                notify(msg.message.message)
                // await props.resetCreateTeamReducer()
                history.push('/teams')
            }
        }
        UploadImage()
    }, [props.submit])

    useEffect(() => {
        if (props.divisionCreate?.success) {
            notify("Division created successfully.")
            props.resetCreateDivisionReducer()
        }


    }, [props.divisionCreate])


    return (
        <React.Fragment>
            <div className="main-panel">
                <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />
                <div className="content-wrapper">
                    <div className='d-flex justify-content-center'>
                        {loading ? <Spinner /> : null}
                    </div>
                    {
                        !loading && accountDetails && <>
                            <div className="text-left">
                                <div className="create-team-head-event">
                                    <h5 className="dash-head">Create Team</h5>
                                    <button className="btn-done" onClick={submitRequest}>Done</button>
                                </div>
                                <div className="text-left mt-30">
                                    <h6 className="payment">Basic Info</h6>
                                    {/* <div className="basic-info">
                                        <input type="file" className="input-file file-upload" id="file-upload" name="files" onChange={onChangePicture} />
                                        <label className="change-pic" htmlFor="file-upload">
                                            <img src={image === "" ? `${accountDetails.profile_image}` : image} className="img-fluid" />
                                        </label>
                                        <span className="text-blue ml-2">Change Picture</span>
                                    </div> */}
                                    <div className="basic-info justify-content-center flex-column">
                                        <div>
                                            <input type="file" className="input-file file-upload" id="file-upload" name="files" onChange={onChangePicture} />
                                            <label className="change-pic" htmlFor="file-upload" style={{ width: '175px', height: '175px' }}>
                                                <img src={image == "" ? `${accountDetails?.profile_image}` : image} className="img-fluid" />
                                            </label>
                                        </div>
                                        <div className="text-blue ml-2 pointer">Upload Picture</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='row mx-0'>
                                    <div className='col-12 col-md-4'>
                                        <div className="text-left mt-30 me-3">
                                            <div className="mt-18">
                                                <input type="text" className="form-control team-name" placeholder="Team Name" id="team_name" name="team_name" value={state.team_name} onChange={eventHandler} />
                                            </div>
                                            {errors.team_name &&
                                                <span className='text-red small d-block'>* {errors.team_name}</span>}
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <div className="text-left mt-30">
                                            <div className="mt-18">
                                                {
                                                    alldivision ? <>

                                                        <select className={classNames("form-control team-name payterm", { "error": errors.division })} id="division" name="division" value={state.division} onChange={eventHandler}>
                                                            <option value="#" >Division *</option>
                                                            {alldivision.map((v, i) => {
                                                                return (
                                                                    <option key={v._id} value={v._id}>{v.division_name}</option>
                                                                )
                                                            })}
                                                        </select>

                                                        {errors.division &&
                                                            <span className='text-red small d-block'>* {errors.division}</span>}
                                                    </> : <><h4>Loading...</h4></>
                                                }
                                            </div>
                                        </div>
                                        <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                                <Link to="/division/create-division" className="text-decoration-none">+ Add Division</Link>
                                            </div>
                                    </div>
                                   
                                    {/* <div className='col-12 col-md-4'>
                                        <div className="text-left mt-30">
                                            <div className="mt-18">

                                                <select className={classNames("form-control team-name payterm", { "error": errors.gender })} id="gender" name="gender" value={state.gender} onChange={eventHandler}>
                                                    <option value="0" >Gender *</option>

                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>

                                                </select>

                                                {errors.gender &&
                                                    <span className='text-red small d-block'>* {errors.gender}</span>}
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='col-12 col-md-4'>
                                        <div className="text-left mt-30">
                                            {
                                                allseason ? <>

                                                    <select className={classNames("form-control team-name payterm", { "error": errors.season_name })} id="season_name" name="season_name" value={state.season_name} onChange={eventHandler}>
                                                        <option value="0" >Season *</option>
                                                        {allseason.map((v, i) => {
                                                            return (
                                                                <option key={v._id} value={v._id}>{v.season_name}</option>
                                                            )
                                                        })}
                                                    </select>

                                                    {errors.season_name &&
                                                        <span className='text-red small d-block'>* {errors.season_name}</span>}
                                                </> : <><h4></h4></>
                                            }
                                        </div>
                                        <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                                <Link to="/season/create-season" className="text-decoration-none">+ Add Season</Link>
                                            </div>
                                    </div>
                                </div>

                            </div>
                            
                            <div className="text-left mt-42">
                                <div>
                                    <div className="text-left mt-42">
                                        <h6 className="payment">Coach</h6>
                                        {
                                            inputList.map((x, i) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        <div className='row mx-0 align-items-center'>
                                                            <div className='col-8'>
                                                                <div className="mt-24">
                                                                    <div className="form-group event-drp">
                                                                        <select className={classNames("form-control team-name payterm", { "error": coachError[i].coach_name })} id="coach_name" name="coach_name" value={x.coach_name} onChange={e => handleInputChange(e, i)}>
                                                                            <option key={i} value="" >Select value</option>
                                                                            {
                                                                                coachAcceptList && coachAcceptList.map((v, i) => {
                                                                                    return <option value={v.coach._id} >{v.coach.name}</option>

                                                                                })
                                                                            }
                                                                            {/* {
                                                                            test && test.map((v, i) => {
                                                                                return <option value={i} >{v.coach_name}</option>

                                                                            })
                                                                        } */}

                                                                        </select>



                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className='col-4'>
                                                                <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                                                    <Link to="#" className="text-decoration-none text-danger" onClick={() => handleRemoveClick(i)}>-Remove</Link>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {coachError[i]?.coach_name &&
                                                            <span className='text-red small d-block'>* {coachError[i]?.coach_name}</span>}
                                                        {/* {count} */}

                                                        <hr />
                                                    </React.Fragment>
                                                )
                                            })
                                        }

                                        {

                                            <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                                <Link to="#" className="text-decoration-none" onClick={handleAddClick}>+ Add More</Link>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {/* <div className="text-left add-more" style={{ marginTop: "6px" }}>
                                    <Link to="#" className="text-decoration-none" onClick={handleAddClick}>+ Add More</Link>
                                </div> */}
                                <div className="text-left add-more" style={{ marginTop: "14px" }}>
                                    <Link to="/teams/add-coach" className="text-decoration-none" >+ Invite Coach</Link>
                                </div>
                            </div>
                        </>
                    }
                    <Toaster />
                </div>
            </div>
        </React.Fragment>
    )
}

// export default CreateTeam;
const mapStateToProps = (state) => ({
    submit: state.createTeamReducer,
    season: state.getAllClubDivision,
    divisionCreate: state.createPaymentCodeReducer,
})

const mapDispatchToProps = dispatch => ({
    getAllDivisionHandler: user => dispatch(getAllDivision_club(user)),
    getAllSeasonHandler: user => dispatch(getAllSeason_club(user)),
    createTeamClubhandler: user => dispatch(createTeam(user)),
    uploadTeamImageHandler: user => dispatch(uploadTeamImage(user)),
    resetCreateTeamReducer: () => dispatch({ type: RESET_CREATE_TEAM }),
    getCoachAcceptListHandler: user => dispatch(coachAcceptList(user)),
    resetCreateDivisionReducer: () => dispatch({ type: RESET_CREATE_PAYMENT_CODE })

})

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeam)