import {  GET_OUTSTANDING_REPORT_ERROR, GET_OUTSTANDING_REPORT_LOADING, GET_OUTSTANDING_REPORT_SUCCESS, RESET_OUTSTANDING_REPORT } from "../../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
  data:[]


};

export default function getOutStandingReportReducer(state = initialState, action) {
  switch (action.type) {
    case GET_OUTSTANDING_REPORT_LOADING:
      return { ...state, success: false, loading: true };

    case GET_OUTSTANDING_REPORT_SUCCESS:
      return { ...state, loading: false, success: true, user: action.payload ,error:false};
     
    case GET_OUTSTANDING_REPORT_ERROR:
      return { ...state, error: true, errors: action.errors };

    case RESET_OUTSTANDING_REPORT:
      return {...state,error:false ,success:false ,loading:false }

    default:
      return state;
  }
}
