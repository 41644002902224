import { REMOVE_PLAYER_ROSTER_ERROR, REMOVE_PLAYER_ROSTER_LOADING, REMOVE_PLAYER_ROSTER_SUCCESS, RESET_REMOVE_PLAYER_ROSTER } from "../../constants";

let initialState = {
 loading: false,
 user: [{}],
 error: false,
 errors: [],
 success: false,
 payload: {},
 message: "",
};

export default function removePayerFromRosterReducer(state = initialState, action) {
 switch (action.type) {

  case REMOVE_PLAYER_ROSTER_LOADING:
   return {
    ...state,
    error: false,
    loading: false,
    success: true,
    message: action.msg,
   };

  case REMOVE_PLAYER_ROSTER_SUCCESS:
   return {
    ...state,
    error: true,
    loading: false,
    errors: [],
   };

  case REMOVE_PLAYER_ROSTER_ERROR:
   return {
    ...state,
    error: true,
    loading: false,
    user: [{}],
    success: false,
    errors: [],
   };

  case RESET_REMOVE_PLAYER_ROSTER:
   return {
    ...state,
    error: false,
    loading: false,
    success: false,
    errors: [],
   };

  default:
   return state;
 }
}
