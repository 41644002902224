import { CLEAR_NOTIFICATION_ERROR, CLEAR_NOTIFICATION_LOADING, CLEAR_NOTIFICATION_SUCCESS, RESET_CLEAR_NOTIFICATION } from "../../../constants";

let initialState = {
 loading: false,
 user: {},
 error: false,
 errors: [],
 success: false


};

export default function clearAllNotification(state = initialState, action) {
 switch (action.type) {
  case CLEAR_NOTIFICATION_LOADING:
   // alert(" reducer succesfull")
   return { ...state, success: false, success: false };

  case CLEAR_NOTIFICATION_SUCCESS:
   // alert(" reducer Error")
   return { ...state, loading: false, success: true, user: action.payload };

  case CLEAR_NOTIFICATION_ERROR:
   // alert(" reducer Loading")
   return { ...state, error: true, errors: action.errors };

  case RESET_CLEAR_NOTIFICATION:
   return { ...state, error: false, loading: false, success: false, user: {} }
  default:
   return state;
 }
}


