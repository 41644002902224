import { GET_ALL_LEAGUE_ERROR, GET_ALL_LEAGUE_LOADING, GET_ALL_LEAGUE_SUCCESS, GET_BANKS_ERROR, GET_BANKS_LOADING, GET_BANKS_SUCCESS } from "../../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function getBnaksReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BANKS_LOADING:
      return { ...state, success: false, success: false };

    case GET_BANKS_SUCCESS:
      return { ...state, loading: false, success: true, user: action.payload };
     
    case GET_BANKS_ERROR:
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}


