import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom"
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RESET_CREATE_NEW_GROUP } from '../../../service/constants';
import Toaster from '../../../Toaster/Toaster';
import { getAllGroups } from '../../../service/action/userAction';
import Spinner from "../../../Spinner/Spinner"

const OrgAccountUser = (props) => {

    const notify = (msg) => toast(msg);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        props.getAllGroupsHandler()
    }, [])

    const getGroups = useSelector(state => state.getAllGroupsReducer.user.data)


    var userData = [
        { name: "username", img: "" },
        { name: "username", img: "" },
        { name: "Username", img: "" },
        { name: "Username", img: "" },
        { name: "Username", img: "" },
    ];

    const creategroupMsg = useSelector(state => state.createNewGroupReducer.message)

    useEffect(() => {
        if (props.staffGroup?.success) {
            setLoading(false)
        }
    }, [props.staffGroup])


    useEffect(() => {
        // alert("a")
        if (props.submit?.success) {
            notify(creategroupMsg.message)
            props.resetCreateGroupReducer()

        } else if (props.submit?.error) {
        }
    }, [props.submit])

    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className='d-flex justify-content-center'>
                        {loading ? <Spinner /> : null}
                    </div>
                    {
                        !loading && <>
                            <div className="text-left">
                                <div className="create-team-head-space">
                                    <h5 className="dash-head">Account Users</h5>
                                    <Link to="/account-add-staff">
                                        <button className="btn-create-new">Add Staff</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="text-left mt-18">
                                <div className="create-team-head-space">
                                    <h5 className="dash-head"></h5>
                                    {/* <button className="btn-create-new">Add New Group</button> */}
                                    <div className="dropdown details-drp">
                                        <Link className="dropdown-item" to={`/add-group`}> <i className="mdi mdi-plus mr-2 website-ico"></i>Add Group</Link>
                                    </div>
                                </div>
                            </div>
                            {
                                getGroups && getGroups.length !== 0 ?

                                    <>
                                        {
                                            getGroups.map((v, i) => {
                                                return (
                                                    <div key={v._id}>
                                                        <div className="row mt-18" >
                                                            <Link className='text-decoration-none' to={`/groupaccount/${v._id}`} >
                                                                <div className="col-md-12 text-left user-content rep">
                                                                    <div>{v.group_name}</div>
                                                                    <i className="mdi mdi-chevron-right" ></i>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="d-flex flex-wrap">
                                                            {
                                                                v.user.map((x, i) => {
                                                                    return (
                                                                        

                                                                            <Link to={`/permission/${v._id}`} className='text-decoration-none' key={i} >
                                                                                <div className="user-profile mt-18 mr-18">
                                                                                    <div>
                                                                                        <div className="img-section">
                                                                                            <img src={x.profile_image !== "" ? x.profile_image : "./images/faces/face3.jpg"} className="img-fluid" />
                                                                                        </div>
                                                                                        <span className="text-center">{x.name}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>

                                                                        
                                                                    )
                                                                })
                                                            }
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }

                                    </> : <> <div className='container d-flex justify-content-center align-items-center'>
                                        <span className='noti-type'>No records to display</span>
                                    </div></>

                            }
                        </>
                    }
                    <Toaster />
                </div>
            </div>

        </React.Fragment>
    )
}

// export default withRouter(OrgAccountUser);

const mapStateToProp = state => ({
    submit: state.createNewGroupReducer,
    staffGroup: state.getAllGroupsReducer

})

const mapDispatchToProps = dispatch => ({
    getAllGroupsHandler: user => dispatch(getAllGroups(user)),
    resetCreateGroupReducer: () => dispatch({ type: RESET_CREATE_NEW_GROUP })



})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(OrgAccountUser));
