import React, { useState } from "react";
import {
  addteamtodivisionAction,
  eventdivisionpoolAction,
} from "../../../service/action/userAction";
import { useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import {
  useHistory,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import classNames from "classnames";
import { toast } from "react-toastify";
import { RESET_ORG_ADD_TEAM_TO_DIVISION } from "../../../service/constants";

const ChangePool = (props) => {
  const { id, id1, id2 } = useParams();
  const [pooldata, setpooldata] = useState();
  const [state, setstate] = useState();
  const [error, seterror] = useState(false);
  const notify = (msg) => toast(msg);
  const history = useHistory();

  useEffect(() => {
    props.eventdivisionpoolActionhandler({ id: id });
  }, []);

  useEffect(() => {
    if (props.eventdivisionpollReducer?.success) {
      const data = props.eventdivisionpollReducer?.user;
      const _data = data?.find((e) => e?._id === id1);
      setpooldata(_data);
    }
  }, [props.eventdivisionpollReducer]);

  useEffect(() => {
    if (props.addteamtodivisionReducer?.success) {
      history.push(`/event-division-details/${id}/${id1}`);
    } else if (props?.addteamtodivisionReducer?.error) {
      notify(props.addteamtodivisionReducer?.errors);
      props.resetteamtodivision();
    }
  }, [props.addteamtodivisionReducer]);

  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    setstate(value);
    if (value == "") {
      seterror(true);
    } else {
      seterror(false);
    }
  };

  const submitRequest = (e) => {
    e.preventDefault();
    if (state == "") {
      seterror(true);
    } else {
      props.addteamtodivisionActionhandler({
        division: id1,
        pool: state,
        team: [id2],
        isCalculate: 0,
        onCharge: 0,
      });
    }
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <select
            className={classNames("form-control team-name payterm col-6", {
              error: error,
            })}
            id="pool_name"
            name="pool_name"
            value={state}
            onChange={eventHandler}
          >
            <option value="">pool name *</option>
            {pooldata?.pool?.map((v, i) => {
              return (
                <option key={v._id} value={v._id}>
                  {v?.pool_name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mt-3 text-left">
          <a
            className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
            onClick={submitRequest}
          >
            Submit
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  eventdivisionpollReducer: state.eventdivisionpollReducer,
  addteamtodivisionReducer: state.addteamtodivisionReducer,
});

const mapDispatchToProps = (dispatch) => ({
  eventdivisionpoolActionhandler: (user) =>
    dispatch(eventdivisionpoolAction(user)),
  addteamtodivisionActionhandler: (user) =>
    dispatch(addteamtodivisionAction(user)),
  resetteamtodivision: () => dispatch({ type: RESET_ORG_ADD_TEAM_TO_DIVISION }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChangePool));
