import {
  DELETE_DIVISION_ERROR,
  DELETE_DIVISION_LOADING,
  DELETE_DIVISION_SUCCESS,
  RESET_DELETE_DIVISION,
} from "../../constants";

let initialState = {
  success: false,
  error: false,
  loading: false,
  error: [],
};

export default function deleteDivisionReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_DIVISION_LOADING:
      return { ...state, success: false, loading: true, error: false };
    case DELETE_DIVISION_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        message: action.msg,
      };
    case DELETE_DIVISION_ERROR:
      return { ...state, success: false, loading: false, error: false };
    case RESET_DELETE_DIVISION:
      return { ...state, success: false, loading: false, error: false };
    default:
      return state;
  }
}
