import {
  ADD_CONFIG_ERROR,
  ADD_CONFIG_SUCCESS,
  ADD_SUB_SUPER_ADMIN_ERROR,
  ADD_SUB_SUPER_ADMIN_SUCCESS,
  ADD_SUPER_ADMIN_ERROR,
  ADD_SUPER_ADMIN_SUCCESS,
  CHANGE_STATUS_ERROR,
  CHANGE_STATUS_SUCCESS,
  CREATE_CLUB_ERROR,
  CREATE_CLUB_SUCCESS,
  CREATE_COACH_ERROR,
  CREATE_COACH_SUCCESS,
  CREATE_ORG_ERROR,
  CREATE_ORG_SUCCESS,
  CREATE_PARENT_ERROR,
  CREATE_PARENT_SUCCESS,
  CREATE_PLAYER_ERROR,
  CREATE_PLAYER_SUCCESS,
  CREATE_STAFF_ERROR,
  CREATE_STAFF_SUCCESS,
  DELETE_CLUB_ERROR,
  DELETE_CLUB_SUCCESS,
  DELETE_COACH_ERROR,
  DELETE_COACH_SUCCESS,
  DELETE_CONFIG_ERROR,
  DELETE_CONFIG_SUCCESS,
  DELETE_ORG_ERROR,
  DELETE_ORG_SUCCESS,
  DELETE_PARENT_ERROR,
  DELETE_PARENT_SUCCESS,
  DELETE_PLAYER_ERROR,
  DELETE_PLAYER_SUCCESS,
  DELETE_STAFF_ERROR,
  DELETE_STAFF_SUCCESS,
  DELETE_SUB_SUPERADMIN_LIST_ERROR,
  DELETE_SUB_SUPERADMIN_LIST_SUCCESS,
  DELETE_SUPERADMIN_LIST_ERROR,
  DELETE_SUPERADMIN_LIST_SUCCESS,
  GET_CLUB_LIST_ERROR,
  GET_CLUB_LIST_SUCCESS,
  GET_COACHES_LIST_ERROR,
  GET_COACHES_LIST_SUCCESS,
  GET_CONFIG_ERROR,
  GET_CONFIG_SUCCESS,
  GET_DASHBOARD_DETAILS_ERROR,
  GET_DASHBOARD_DETAILS_SUCCESS,
  GET_ORG_LIST_ERROR,
  GET_ORG_LIST_SUCCESS,
  GET_PARENT_LIST_ERROR,
  GET_PARENT_LIST_SUCCESS,
  GET_PLAYER_LIST_ERROR,
  GET_PLAYER_LIST_SUCCESS,
  GET_STAFF_LIST_ERROR,
  GET_STAFF_LIST_SUCCESS,
  GET_SUB_SUPERADMIN_LIST_ERROR,
  GET_SUB_SUPERADMIN_LIST_SUCCESS,
  GET_SUPERADMIN_LIST_ERROR,
  GET_SUPERADMIN_LIST_SUCCESS,
  ORG_LOGIN_BTN_ERROR,
  ORG_LOGIN_BTN_SUCCESS,
  RESET_ADD_CONFIG,
  RESET_ADD_SUB_SUPER_ADMIN,
  RESET_ADD_SUPER_ADMIN,
  RESET_CHANGE_STATUS,
  RESET_CREATE_CLUB,
  RESET_CREATE_COACH,
  RESET_CREATE_ORG,
  RESET_CREATE_PARENT,
  RESET_CREATE_PLAYER,
  RESET_CREATE_STAFF,
  RESET_DELETE_CLUB,
  RESET_DELETE_COACH,
  RESET_DELETE_CONFIG,
  RESET_DELETE_ORG,
  RESET_DELETE_PARENT,
  RESET_DELETE_PLAYER,
  RESET_DELETE_STAFF,
  RESET_DELETE_SUB_SUPERADMIN_LIST,
  RESET_DELETE_SUPERADMIN_LIST,
  RESET_GET_CLUB_LIST,
  RESET_GET_COACHES_LIST,
  RESET_GET_CONFIG,
  RESET_GET_DASHBOARD_DETAILS,
  RESET_GET_ORG_LIST,
  RESET_GET_PARENT_LIST,
  RESET_GET_PLAYER_LIST,
  RESET_GET_STAFF_LIST,
  RESET_GET_SUB_SUPERADMIN_LIST,
  RESET_GET_SUPERADMIN_LIST,
  RESET_ORG_LOGIN_BTN,
  RESET_UPDATE_CLUB,
  RESET_UPDATE_COACH,
  RESET_UPDATE_CONFIG,
  RESET_UPDATE_ORG,
  RESET_UPDATE_PARENT,
  RESET_UPDATE_PLAYER,
  RESET_UPDATE_STAFF,
  RESET_UPDATE_SUB_SUPERADMIN_LIST,
  RESET_UPDATE_SUPERADMIN_LIST,
  RESET_USER_STATUS,
  UPDATE_CLUB_ERROR,
  UPDATE_CLUB_SUCCESS,
  UPDATE_COACH_ERROR,
  UPDATE_COACH_SUCCESS,
  UPDATE_CONFIG_ERROR,
  UPDATE_CONFIG_SUCCESS,
  UPDATE_ORG_ERROR,
  UPDATE_ORG_SUCCESS,
  UPDATE_PARENT_ERROR,
  UPDATE_PARENT_SUCCESS,
  UPDATE_PLAYER_ERROR,
  UPDATE_PLAYER_SUCCESS,
  UPDATE_STAFF_ERROR,
  UPDATE_STAFF_SUCCESS,
  UPDATE_SUB_SUPERADMIN_LIST_ERROR,
  UPDATE_SUB_SUPERADMIN_LIST_SUCCESS,
  UPDATE_SUPERADMIN_LIST_ERROR,
  UPDATE_SUPERADMIN_LIST_SUCCESS,
  USER_STATUS_ERROR,
  USER_STATUS_SUCCESS,
} from "../constant";

let initialState = {
  dashBoardDetails: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  orgList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  orgCreate: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  orgUpdate: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  orgDelete: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  clubList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  createClub: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  UpdateClub: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  ClubDelete: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  staffList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  coachList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  playerList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  parentList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  OrgLogin: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  createstaff: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  updatestaff: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  deletestaff: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  createcoach: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  updatecoach: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  deletecoach: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  createparent: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  updateparent: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  deleteparent: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  createplayer: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  updateplayer: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  deleteplayer: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  createSuperAdmin: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  getSuperAdminList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  updateSuperAdminList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  deleteSuperAdminList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  createSubSuperAdmin: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  getSubSuperAdminList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  updateSubSuperAdminList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  deleteSubSuperAdminList: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  changestatus: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  changeUserStatus: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  getConfig: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  addConfig: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  deleteConfig: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
  updateConfig: {
    data: null,
    error: false,
    success: false,
    errorMsg: "",
  },
};
export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    //  login details
    case GET_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        dashBoardDetails: {
          ...state.dashBoardDetails,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_DASHBOARD_DETAILS_ERROR:
      return {
        ...state,
        dashBoardDetails: {
          ...state.dashBoardDetails,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_DASHBOARD_DETAILS:
      return {
        ...state,
        dashBoardDetails: {
          ...state.dashBoardDetails,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // Org List
    case GET_ORG_LIST_SUCCESS:
      return {
        ...state,
        orgList: {
          ...state.orgList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_ORG_LIST_ERROR:
      return {
        ...state,
        orgList: {
          ...state.orgList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_ORG_LIST:
      return {
        ...state,
        orgList: {
          ...state.orgList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // Org Create
    case CREATE_ORG_SUCCESS:
      return {
        ...state,
        orgCreate: {
          ...state.orgCreate,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case CREATE_ORG_ERROR:
      return {
        ...state,
        orgCreate: {
          ...state.orgCreate,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_CREATE_ORG:
      return {
        ...state,
        orgCreate: {
          ...state.orgCreate,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    case UPDATE_ORG_SUCCESS:
      return {
        ...state,
        orgUpdate: {
          ...state.orgUpdate,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case UPDATE_ORG_ERROR:
      return {
        ...state,
        orgUpdate: {
          ...state.orgUpdate,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_UPDATE_ORG:
      return {
        ...state,
        orgUpdate: {
          ...state.orgUpdate,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    case DELETE_ORG_SUCCESS:
      return {
        ...state,
        orgDelete: {
          ...state.orgDelete,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case DELETE_ORG_ERROR:
      return {
        ...state,
        orgDelete: {
          ...state.orgDelete,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_DELETE_ORG:
      return {
        ...state,
        orgDelete: {
          ...state.orgDelete,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    case UPDATE_CLUB_SUCCESS:
      return {
        ...state,
        UpdateClub: {
          ...state.UpdateClub,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case UPDATE_CLUB_ERROR:
      return {
        ...state,
        UpdateClub: {
          ...state.UpdateClub,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_UPDATE_CLUB:
      return {
        ...state,
        UpdateClub: {
          ...state.UpdateClub,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    case DELETE_CLUB_SUCCESS:
      return {
        ...state,
        ClubDelete: {
          ...state.ClubDelete,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case DELETE_CLUB_ERROR:
      return {
        ...state,
        ClubDelete: {
          ...state.ClubDelete,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_DELETE_CLUB:
      return {
        ...state,
        ClubDelete: {
          ...state.ClubDelete,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // club List
    case GET_CLUB_LIST_SUCCESS:
      return {
        ...state,
        clubList: {
          ...state.clubList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_CLUB_LIST_ERROR:
      return {
        ...state,
        clubList: {
          ...state.clubList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_CLUB_LIST:
      return {
        ...state,
        clubList: {
          ...state.clubList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };
    // club Create
    case CREATE_CLUB_SUCCESS:
      return {
        ...state,
        createClub: {
          ...state.createClub,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case CREATE_CLUB_ERROR:
      return {
        ...state,
        createClub: {
          ...state.createClub,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_CREATE_CLUB:
      return {
        ...state,
        createClub: {
          ...state.createClub,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // staff List
    case GET_STAFF_LIST_SUCCESS:
      return {
        ...state,
        staffList: {
          ...state.staffList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_STAFF_LIST_ERROR:
      return {
        ...state,
        staffList: {
          ...state.staffList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_STAFF_LIST:
      return {
        ...state,
        staffList: {
          ...state.staffList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // coach List
    case GET_COACHES_LIST_SUCCESS:
      return {
        ...state,
        coachList: {
          ...state.coachList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_COACHES_LIST_ERROR:
      return {
        ...state,
        coachList: {
          ...state.coachList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_COACHES_LIST:
      return {
        ...state,
        coachList: {
          ...state.coachList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // player List
    case GET_PLAYER_LIST_SUCCESS:
      return {
        ...state,
        playerList: {
          ...state.playerList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_PLAYER_LIST_ERROR:
      return {
        ...state,
        playerList: {
          ...state.playerList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_PLAYER_LIST:
      return {
        ...state,
        playerList: {
          ...state.playerList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // parent List
    case GET_PARENT_LIST_SUCCESS:
      return {
        ...state,
        parentList: {
          ...state.parentList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_PARENT_LIST_ERROR:
      return {
        ...state,
        parentList: {
          ...state.parentList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_PARENT_LIST:
      return {
        ...state,
        parentList: {
          ...state.parentList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // ......... org login  .......

    case ORG_LOGIN_BTN_SUCCESS:
      return {
        ...state,
        OrgLogin: {
          ...state.OrgLogin,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case ORG_LOGIN_BTN_ERROR:
      return {
        ...state,
        OrgLogin: {
          ...state.OrgLogin,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_ORG_LOGIN_BTN:
      return {
        ...state,
        OrgLogin: {
          ...state.OrgLogin,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // ...........................................  staff reducer  .......................................................

    case CREATE_STAFF_SUCCESS:
      return {
        ...state,
        createstaff: {
          ...state.createstaff,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case CREATE_STAFF_ERROR:
      return {
        ...state,
        createstaff: {
          ...state.createstaff,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_CREATE_STAFF:
      return {
        ...state,
        createstaff: {
          ...state.createstaff,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    case UPDATE_STAFF_SUCCESS:
      return {
        ...state,
        updatestaff: {
          ...state.updatestaff,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case UPDATE_STAFF_ERROR:
      return {
        ...state,
        updatestaff: {
          ...state.updatestaff,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_UPDATE_STAFF:
      return {
        ...state,
        updatestaff: {
          ...state.updatestaff,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    case DELETE_STAFF_SUCCESS:
      return {
        ...state,
        deletestaff: {
          ...state.deletestaff,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case DELETE_STAFF_ERROR:
      return {
        ...state,
        deletestaff: {
          ...state.deletestaff,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_DELETE_STAFF:
      return {
        ...state,
        deletestaff: {
          ...state.deletestaff,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // ...........................  Coach Reducer  ..........................

    case CREATE_COACH_SUCCESS:
      return {
        ...state,
        createcoach: {
          ...state.createcoach,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case CREATE_COACH_ERROR:
      return {
        ...state,
        createcoach: {
          ...state.createcoach,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_CREATE_COACH:
      return {
        ...state,
        createcoach: {
          ...state.createcoach,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    case UPDATE_COACH_SUCCESS:
      return {
        ...state,
        updatecoach: {
          ...state.updatecoach,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case UPDATE_COACH_ERROR:
      return {
        ...state,
        updatecoach: {
          ...state.updatecoach,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_UPDATE_COACH:
      return {
        ...state,
        updatecoach: {
          ...state.updatecoach,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    case DELETE_COACH_SUCCESS:
      return {
        ...state,
        deletecoach: {
          ...state.deletecoach,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case DELETE_COACH_ERROR:
      return {
        ...state,
        deletecoach: {
          ...state.deletecoach,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_DELETE_COACH:
      return {
        ...state,
        deletecoach: {
          ...state.deletecoach,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // ...........................  Parent Reducer  ..........................

    case CREATE_PARENT_SUCCESS:
      return {
        ...state,
        createparent: {
          ...state.createparent,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case CREATE_PARENT_ERROR:
      return {
        ...state,
        createparent: {
          ...state.createparent,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_CREATE_PARENT:
      return {
        ...state,
        createparent: {
          ...state.createparent,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    case UPDATE_PARENT_SUCCESS:
      return {
        ...state,
        updateparent: {
          ...state.updateparent,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case UPDATE_PARENT_ERROR:
      return {
        ...state,
        updateparent: {
          ...state.updateparent,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_UPDATE_PARENT:
      return {
        ...state,
        updateparent: {
          ...state.updateparent,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    case DELETE_PARENT_SUCCESS:
      return {
        ...state,
        deleteparent: {
          ...state.deleteparent,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case DELETE_PARENT_ERROR:
      return {
        ...state,
        deleteparent: {
          ...state.deleteparent,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_DELETE_PARENT:
      return {
        ...state,
        deleteparent: {
          ...state.deleteparent,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // ...........................  Parent Reducer  ..........................

    case CREATE_PLAYER_SUCCESS:
      return {
        ...state,
        createplayer: {
          ...state.createplayer,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case CREATE_PLAYER_ERROR:
      return {
        ...state,
        createplayer: {
          ...state.createplayer,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_CREATE_PLAYER:
      return {
        ...state,
        createplayer: {
          ...state.createplayer,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    case UPDATE_PLAYER_SUCCESS:
      return {
        ...state,
        updateplayer: {
          ...state.updateplayer,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case UPDATE_PLAYER_ERROR:
      return {
        ...state,
        updateplayer: {
          ...state.updateplayer,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_UPDATE_PLAYER:
      return {
        ...state,
        updateplayer: {
          ...state.updateplayer,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    case DELETE_PLAYER_SUCCESS:
      return {
        ...state,
        deleteplayer: {
          ...state.deleteplayer,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case DELETE_PLAYER_ERROR:
      return {
        ...state,
        deleteplayer: {
          ...state.deleteplayer,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_DELETE_PLAYER:
      return {
        ...state,
        deleteplayer: {
          ...state.deleteplayer,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // super admin

    case ADD_SUPER_ADMIN_SUCCESS:
      return {
        ...state,
        createSuperAdmin: {
          ...state.createSuperAdmin,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case ADD_SUPER_ADMIN_ERROR:
      return {
        ...state,
        createSuperAdmin: {
          ...state.createSuperAdmin,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_ADD_SUPER_ADMIN:
      return {
        ...state,
        createSuperAdmin: {
          ...state.createSuperAdmin,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // Get  Superadmin

    case GET_SUPERADMIN_LIST_SUCCESS:
      return {
        ...state,
        getSuperAdminList: {
          ...state.getSuperAdminList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_SUPERADMIN_LIST_ERROR:
      return {
        ...state,
        getSuperAdminList: {
          ...state.getSuperAdminList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_SUPERADMIN_LIST:
      return {
        ...state,
        getSuperAdminList: {
          ...state.getSuperAdminList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // update  Superadmin

    case UPDATE_SUPERADMIN_LIST_SUCCESS:
      return {
        ...state,
        updateSuperAdminList: {
          ...state.updateSuperAdminList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case UPDATE_SUPERADMIN_LIST_ERROR:
      return {
        ...state,
        updateSuperAdminList: {
          ...state.updateSuperAdminList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_UPDATE_SUPERADMIN_LIST:
      return {
        ...state,
        updateSuperAdminList: {
          ...state.updateSuperAdminList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // delete  Superadmin

    case DELETE_SUPERADMIN_LIST_SUCCESS:
      return {
        ...state,
        deleteSuperAdminList: {
          ...state.deleteSuperAdminList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case DELETE_SUPERADMIN_LIST_ERROR:
      return {
        ...state,
        deleteSuperAdminList: {
          ...state.deleteSuperAdminList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_DELETE_SUPERADMIN_LIST:
      return {
        ...state,
        deleteSuperAdminList: {
          ...state.deleteSuperAdminList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    //create sub super admin

    case ADD_SUB_SUPER_ADMIN_SUCCESS:
      return {
        ...state,
        createSubSuperAdmin: {
          ...state.createSubSuperAdmin,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case ADD_SUB_SUPER_ADMIN_ERROR:
      return {
        ...state,
        createSubSuperAdmin: {
          ...state.createSubSuperAdmin,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_ADD_SUB_SUPER_ADMIN:
      return {
        ...state,
        createSubSuperAdmin: {
          ...state.createSubSuperAdmin,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // Get  Sub Superadmin

    case GET_SUB_SUPERADMIN_LIST_SUCCESS:
      return {
        ...state,
        getSubSuperAdminList: {
          ...state.getSubSuperAdminList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_SUB_SUPERADMIN_LIST_ERROR:
      return {
        ...state,
        getSubSuperAdminList: {
          ...state.getSubSuperAdminList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_SUB_SUPERADMIN_LIST:
      return {
        ...state,
        getSubSuperAdminList: {
          ...state.getSubSuperAdminList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // update sub Superadmin

    case UPDATE_SUB_SUPERADMIN_LIST_SUCCESS:
      return {
        ...state,
        updateSubSuperAdminList: {
          ...state.updateSubSuperAdminList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case UPDATE_SUB_SUPERADMIN_LIST_ERROR:
      return {
        ...state,
        updateSubSuperAdminList: {
          ...state.updateSubSuperAdminList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_UPDATE_SUB_SUPERADMIN_LIST:
      return {
        ...state,
        updateSubSuperAdminList: {
          ...state.updateSubSuperAdminList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // delete  sub Superadmin

    case DELETE_SUB_SUPERADMIN_LIST_SUCCESS:
      return {
        ...state,
        deleteSubSuperAdminList: {
          ...state.deleteSubSuperAdminList,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case DELETE_SUB_SUPERADMIN_LIST_ERROR:
      return {
        ...state,
        deleteSubSuperAdminList: {
          ...state.deleteSubSuperAdminList,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_DELETE_SUB_SUPERADMIN_LIST:
      return {
        ...state,
        deleteSubSuperAdminList: {
          ...state.deleteSubSuperAdminList,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    // ................  Change Status  .........................

    case CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        changestatus: {
          ...state.changestatus,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case CHANGE_STATUS_ERROR:
      return {
        ...state,
        changestatus: {
          ...state.changestatus,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_CHANGE_STATUS:
      return {
        ...state,
        changestatus: {
          ...state.changestatus,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    //  change status
    case USER_STATUS_SUCCESS:
      return {
        ...state,
        changeUserStatus: {
          ...state.changeUserStatus,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case USER_STATUS_ERROR:
      return {
        ...state,
        changeUserStatus: {
          ...state.changeUserStatus,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_USER_STATUS:
      return {
        ...state,
        changeUserStatus: {
          ...state.changeUserStatus,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    //  get config
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        getConfig: {
          ...state.getConfig,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case GET_CONFIG_ERROR:
      return {
        ...state,
        getConfig: {
          ...state.getConfig,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_GET_CONFIG:
      return {
        ...state,
        getConfig: {
          ...state.getConfig,
          error: false,
          success: false,
          errorMsg: "",
        },
      };
    //  add config
    case ADD_CONFIG_SUCCESS:
      return {
        ...state,
        addConfig: {
          ...state.addConfig,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case ADD_CONFIG_ERROR:
      return {
        ...state,
        addConfig: {
          ...state.addConfig,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_ADD_CONFIG:
      return {
        ...state,
        addConfig: {
          ...state.addConfig,
          error: false,
          success: false,
          errorMsg: "",
        },
      };
    //  delete config
    case DELETE_CONFIG_SUCCESS:
      return {
        ...state,
        deleteConfig: {
          ...state.deleteConfig,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case DELETE_CONFIG_ERROR:
      return {
        ...state,
        deleteConfig: {
          ...state.deleteConfig,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_DELETE_CONFIG:
      return {
        ...state,
        deleteConfig: {
          ...state.deleteConfig,
          error: false,
          success: false,
          errorMsg: "",
        },
      };
    //  Update config
    case UPDATE_CONFIG_SUCCESS:
      return {
        ...state,
        updateConfig: {
          ...state.updateConfig,
          success: true,
          data: action.response.data,
          error: false,
        },
      };

    case UPDATE_CONFIG_ERROR:
      return {
        ...state,
        updateConfig: {
          ...state.updateConfig,
          error: true,
          success: false,
          data: null,
          errorMsg: action.errorMsg,
        },
      };

    case RESET_UPDATE_CONFIG:
      return {
        ...state,
        updateConfig: {
          ...state.updateConfig,
          error: false,
          success: false,
          errorMsg: "",
        },
      };

    default:
      return state;
  }
}
