import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  getAccountDetails,
  getMerchantOrg,
  getMerchantStatus,
  getOrgClubList,
} from "../../../service/action/userAction";
import Spinner from "../../../Spinner/Spinner";
import $ from "jquery";
import MerchandAccountOrg from "./MerchandAccountOrg";

const MerchandOrg = (props) => {
  const [isMerchant, setIsMerchant] = useState(false);

  const [loading, setLoading] = useState(true);

  const ClubList = useSelector(
    (state) => state.getOrgClubListReducer.user.data
  );

  useEffect(() => {
    props.getMerchantOrgHandler();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (props.getMerchantOrgReducer.success) {
      const data = props.getMerchantOrgReducer.user;
      if (data.data.length !== 0) {
        setIsMerchant(false);
        props.getMerchantStatusHandler(data.data[0]?.merchant_key);
      } else {
        setIsMerchant(true);
        setLoading(false);
      }
    } else if (props.getMerchantOrgReducer.error) {
      setLoading(false);
    }
  }, [props.getMerchantOrgReducer]);

  useEffect(() => {
    if (props.getMerchantStatusReducer.success) {
      const data = props.getMerchantStatusReducer.user;
      setLoading(false);
    } else if (props.getMerchantStatusReducer.error) {
      setLoading(false);
    }
  }, [props.getMerchantStatusReducer]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="text-left">
                <div className="">
                  <div className="d-flex align-items-center">
                    <h5 className="dash-head pr-2">Merchant Account</h5>
                  </div>

                  {isMerchant ? (
                    <div className="dropdown details-drp">
                      <div className="mt-3 mt-md-0 text-right">
                        <Link to="/merchant/create-account">
                          <button className="btn-save">Create Account</button>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row d-flex justify-content-center align-item-center mt-3">
                        <div
                          className="col-6  p-5"
                          style={{
                            backgroundColor: "#E1F5FE",
                          }}
                        >
                          <p className="text-blue">
                            Thank you ! Your application has been submitted.
                            plrase allow up to 48hrs response time. in the mean
                            time. if you have any quetions please contacr us at:
                          </p>
                          <p>
                            <a
                              href="https://www.google.com/search?q=support%40playersclearinghouse.com&rlz=1C1CHBF_enIN947IN947&oq=&aqs=chrome.0.35i39i362l8.30029j0j7&sourceid=chrome&ie=UTF-8"
                              className="text-blue text-decoration-none pointer"
                            >
                              Support@playersclearinghouse.com
                            </a>
                          </p>
                        </div>
                      </div>
                      <p>
                        <p className="text-center mt-3">
                          Current Status :{" "}
                          {/* {currentStatus ? currentStatus : "N/A"} */}
                        </p>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(Club);

const mapStateToProps = (state) => ({
  submit: state.createTeamReducer,
  club: state.getOrgClubListReducer,
  getMerchantOrgReducer: state.getMerchantOrgReducer,
  getMerchantStatusReducer: state.getMerchantStatusReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getOrg_ClubListHandler: (user) => dispatch(getOrgClubList(user)),
  getAccountDetails: (user) => dispatch(getAccountDetails(user)),
  getMerchantOrgHandler: (user) => dispatch(getMerchantOrg(user)),
  getMerchantStatusHandler: (user) => dispatch(getMerchantStatus(user)),
  // userDataHandler: user => dispatch(userData(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MerchandOrg));
