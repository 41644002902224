import React from "react";

const SuccessPopup = props => {
    const style={"height":"100vh"}
  return (
    <div className="popup-box mt-30" style={style}>
     
       
        {props.content}
      
    </div>
  );
};

export default SuccessPopup;
