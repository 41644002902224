import { BULK_DATA_ERROR, BULK_DATA_LOADING, BULK_DATA_REQUEST, BULK_DATA_SUCCESS, PAID_RECIIPIENT_DATA_ERROR, PAID_RECIIPIENT_DATA_LOADING, PAID_RECIIPIENT_DATA_SUCCESS, PAYMENT_REQUEST_ERROR, PAYMENT_REQUEST_LOADING, PAYMENT_REQUEST_SUCCESS, RESET_PAYMENT_REQUEST } from "../../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}
};
export default function getBulkRecipientReducer(state = initialState, action) {
  switch (action.type) {
    case BULK_DATA_LOADING:
      return { ...state, error: false, loading: true, success: false , data : [{}]};

    case BULK_DATA_SUCCESS:
      return { ...state, error: false, loading: false, success: true, message:action.msg , data : action.data };

    case BULK_DATA_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: [] , data : [{}]}

    case BULK_DATA_REQUEST:
        return {...state,error:false,loading:false,success:false,errors:[] , data : [{}]}  

    default:
      return state;
  }
}





