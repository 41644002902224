import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { Link, useParams, withRouter } from "react-router-dom";
import { getLiabiltyTerms } from "../../../service/action/userAction";

const LiabilityTermsList = (props) => {
  const { id } = useParams();
  let [loading, setLoading] = useState(true);

  const AllLiabilityTermsList = useSelector(
    (state) => state.getLiabilityTermsReducer.user.data
  );

  useEffect(() => {
    props.getLiabilityTermsHandler();
  }, []);

  useEffect(() => {
    if (props.getLiabilityTermsReducer.success) {
      props.getLiabilityTermsHandler({ id: id });
    }
  }, [props.success]);

  //   useEffect(() => {
  //     if (props.getLiabilityTermsReducer.success) {
  //       setLoading(false);
  //     }
  //   }, [props.success]);


  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div>
          {loading && AllLiabilityTermsList !== null ? (
            <>
              {AllLiabilityTermsList && AllLiabilityTermsList.length !== 0 ? (
                <>
                  <div className="d-flex justify-content-end">
                    <Link to={"/createliability"}>
                      <Button>+ New</Button>
                    </Link>
                  </div>
                  {AllLiabilityTermsList?.map((v, i) => {

                    return (
                      <>
                        <Link
                          to={"#"}
                          className="text-decoration-none text-dark"
                        >
                          <div className="row ml-5 mb-3">
                            <div className="col-6 border-color">{v?.title}</div>
                            <div className="col-6">
                              {" "}
                              <i
                                className="mdi mdi-chevron-right right-arrow"
                                style={{ fontSize: "30px" }}
                              ></i>
                            </div>
                          </div>
                        </Link>
                      </>
                    );
                  })}
                </>
              ) : (
                <div className="d-flex justify-content-center">
                  <Spinner />
                </div>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  getLiabilityTermsReducer: state.getLiabilityTermsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getLiabilityTermsHandler: (user) => dispatch(getLiabiltyTerms(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LiabilityTermsList));
