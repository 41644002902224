import React, { useEffect, useState } from 'react'
import $ from "jquery";
import { useHistory } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';
// import { editPaymentCode, getAllPaymentCode } from '../../../service/action/userAction';
import { connect, useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { AddEventSeasonClub_Url, EventSeasonOrg_Url } from '../../apiSheet';
import { deleteDivision, getAllDivision_club } from '../../service/action/Club/clubAction';
import { toast } from 'react-toastify';
import Toaster from '../../Toaster/Toaster';
import { RESET_DELETE_EVENT, RESET_EDIT_SEASON } from '../../service/constants';
import Spinner from '../../Spinner/Spinner';





const Division = (props) => {

    const getDivision = props.getDivisionHandler;

    // const getToken = localStorage.getItem("token")
    // const getToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjEyZjI2NzQ2NzAwYmI0M2M4NzJmMzgxIiwiaWF0IjoxNjMzOTI1MTA5LCJleHAiOjE2MzQwMTE1MDl9.oX20FJakkiUNO8gOgEI1n_t7AwDC__TVQVo7yNcrAgE"
    // alert("1")
    const header = { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } }
    const acctype = localStorage.getItem("type")

    const notify = (msg) => toast(msg);
    const [loading, setLoading] = useState(true)

    const allDivision = useSelector(state => state.getAllClubDivision.user)

    const editMsg = useSelector(state => state.editSeasonReducer.message)
    const deleteDivisiondMsg = useSelector(state => state.deleteEventReducer.message)
    useEffect(() => {
        getDivision()
    }, [])

    useEffect(() => {
        if (props.submit?.success) {
            notify(deleteDivisiondMsg.message)
            props.resetdeletePaymentCodeReducer()
            getDivision()

            // history.push(`/event-details/${id}`);
        }

    }, [props.submit])

    useEffect(() => {
        if (props.Msg?.success) {
            notify(editMsg.message)
            props.resetEditSeason()
        }
    }, [props.Msg])

    useEffect(() => {
        if (props.division?.success) {
            setLoading(false)
        }
    }, [props.division])




    // alert("OutSide")

    const green_tbl = [
        {
            name: "Rio"
        },
        {
            name: "Jhon"
        }
    ]

    return (
        <>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className='d-flex justify-content-center'>

                        {loading ? <Spinner /> : null}
                    </div>

                    {
                                                allDivision ? <>
                                                    <div className="d-flex justify-content-end  ">

                                                        {/* <h6 className="text-left payment mt-30 fw-m"><i className="mdi mdi-plus website-ico mr-2"></i>Add More</h6> */}
                                                        <div className="dropdown event-drp details-drp">
                                                            {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> */}
                                                            {/* <i className="mdi mdi-plus website-ico mr-2 " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add More</i> */}
                                                            <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>

                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                <Link className="dropdown-item" to={`/division/create-division`}>
                                                                    <i className="mdi mdi-plus website-ico mr-2"></i>
                                                                    Add Division
                                                                </Link>
                                                                <Link className="dropdown-item" to={`/addpool`}>
                                                                    <i className="mdi mdi-plus website-ico mr-2"></i>
                                                                    Add Pool
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <<<<-----Division info ---->>> */}
                                                    {
                                                        allDivision.length == 0 ? <><div className='container d-flex justify-content-center align-items-center h-50'>
                                                            <span className='noti-type'>No divisions to display</span>
                                                        </div></> : <>

                                                            {
                                                                allDivision.map((v, i) => {

                                                                 
                                                                    return (<div key={v._id}>
                                                                        <div >

                                                                            <div className="d-flex justify-content-between">
                                                                                <h6 className="text-left payment mt-30 fw-m">({i + 1})Division</h6>

                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-4 w-75">
                                                                                    <div className="text-left mt-18 ">
                                                                                    
                                                                                        <div className="dropdown event-drp d-flex justify-content-between">
                                                                                            <label className="payment ml-3" key={v._id}>{v.division_name}</label>
                                                                                            <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>

                                                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                                                <Link className="dropdown-item" to={(`/edit-division/${v._id}`)}  >
                                                                                                    <i className="mdi mdi-border-color"></i>
                                                                                                    Edit</Link>

                                                                                                {/* <Link className="dropdown-item" to={`/division-pool`}
                                                                                                    onClick={v.pool.length !== 0 ? () => notify("First Delete pools") : () => props.deleteDivisionHandler(v._id)
                                                                                                    }>
                                                                                                    <i className="fas fa-trash-alt"></i>  Delete</Link> */}
                                                                                            </div>

                                                                                        </div>

                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                        {/* <<<<<<-----Pools Info ------>>>>> */}


                                                                        <div className="d-flex mt-24">
                                                                            <h6 className="text-left payment mt-30 fw-m ml-3">Pools</h6>
                                                                        </div>
                                                                      

                                                                      
                                                                    </div>
                                                                    )

                                                                })
                                                            }
                                                        </>
                                                    }


                                                    {/* <Toaster /> */}
                                                </>

                                                    : <><div className='d-flex justify-content-center'>

                                                        <Spinner />
                                                    </div></>
                                            }
                    <Toaster />
                </div>
            </div>

        </>
    )
}

// export default withRouter(PaymentCode);


const mapStateToProps = (state) => ({
    submit: state.deleteEventReducer,
    Msg: state.editSeasonReducer,
    division: state.getAllClubDivision

})

const mapDispatchToProps = dispatch => ({
    getDivisionHandler: (user) => dispatch(getAllDivision_club(user)),
    deleteDivisionHandler: user => dispatch(deleteDivision(user)),
    resetdeletePaymentCodeReducer: () => dispatch({ type: RESET_DELETE_EVENT }),
    resetEditSeason: () => dispatch({ type: RESET_EDIT_SEASON }),

    // userDataHandler: user => dispatch(userData(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Division))