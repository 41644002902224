import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function TermsPop({ handleClose, open, termText }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ width: "100vw" }}
      >
        <DialogTitle id="alert-dialog-title" className="text-center">
          {"Accept Terms Of Use"}
        </DialogTitle>
        <DialogContent sx={{ width: "30rem", height: "50vh" }}>
          <DialogContentText id="alert-dialog-description">
            <div dangerouslySetInnerHTML={{ __html: termText }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleClose}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
          >
            OK
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
