import React, { useState, useEffect } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
// import Toaster from '../../../Toaster/Toaster';
import { useParams, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { editSeason } from '../../service/action/userAction';
import { RESET_EDIT_SEASON } from '../../service/constants';
// import { RESET_CREATE_SEASON } from '../../service/constants';
// import classNames from 'classnames';
import Toaster from '../../Toaster/Toaster';
import { editField, getField } from '../../service/action/Club/clubAction';
import BreadcrumbHistory from '../../BreadcumbHistory/BreadcrumbHistory';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';



const EditField = (props) => {

    const { id } = useParams();
    const history = useHistory()

    const location = useLocation()

    //all season
    const getFieled = props.getFieledHandler;
    useEffect(() => {
        getFieled()
    }, [])

    const allfield = useSelector(state => state.getFieldReducer.user.data)




    const [state, setState] = useState({
        season_name: ""
    })

    const [errors, setErrors] = useState({

        season_name: false

    })

    useEffect(() => {
        if (allfield) {
            const editField = allfield.find((e) => e._id == id)
            setState(prevState => ({
                ...prevState,
                season_name: editField.name
            }))
        }
    }, [allfield])

    const eventHandler = (e) => {
        const { id, value, name } = e.target


        setState(prevState => ({
            ...prevState,
            [id]: value
            // [e.target.name]: e.target.value.trim()
        }))
        switch (name) {
            case "season_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        season_name: "Field name can not be empty"
                    }))

                    document.getElementById("season_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        season_name: false
                    }))

                    document.getElementById("season_name").classList.remove("error")
                }

                break;

            default:
        }
    }

    const notify = (msg) => toast(msg);

    useEffect(() => {
        if (editSeason) {
            setState(prevState => ({
                ...prevState,
                season_name: editSeason.season_name
            }))
        }

    }, [editSeason])

    const submitRequest = (e) => {
        e.preventDefault();

        if (state.season_name == "") {
            setErrors(prevState => ({
                ...prevState,
                season_name: "Field name can not be empty"
            }))
            document.getElementById("season_name").classList.add("error")
        }

        if (state.season_name !== "") {
            props.editFieldhandler({
                name: state.season_name,
                id: id
            })

            // const datas = { season_name: state.season_name }
            // const headers = {
            //     "Content-Type": "application/json",
            //     'Authorization': `Bearer ${localStorage.getItem("token")}`
            // }

            // if (acctype == 1) {

            //     const requestOptions = {
            //         method: "PUT",
            //         headers: {
            //             'content-type': 'application/json',
            //             'Authorization': `Bearer ${localStorage.getItem("token")}`
            //         },
            //         url: `http://192.168.0.33:5555/api/organizer/event-season/${id}`, data: datas
            //     };
            //     axios(requestOptions)

            //     return <Redirect to="/switch" />
            // } else {
            //     const requestOptions = {
            //         method: "PUT",
            //         headers: {
            //             'content-type': 'application/json',
            //             'Authorization': `Bearer ${localStorage.getItem("token")}`
            //         },
            //         url: `http://192.168.0.33:5555/api/club/event-season/${id}`, data: datas
            //     };

            // }
            //  axios.put(acctype==1?`http://192.168.0.33:5555/api/organizer/event-season/${id}`:`http://192.168.0.33:5555/api/club/event-season/${id}`,datas, headers)


        }

    }
    useEffect(() => {

        if (props.submit?.success) {
            history.push('/field')

        }
    }, [props.submit])


    return (
        <>
            <div className="main-panel">
                <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />
                <div className="content-wrapper">
                    <div className="text-left">
                        <h5 className="dash-head">Edit Field</h5>
                    </div>
                    <div className="row mx-0">
                        <div className="col-md-4 col-xl-4">
                            <div className="mt-24">
                                <div className="text-left">
                                    <label className="text-gray fs-13">Field Name <span className="text-red fw-m">*</span></label>
                                    <input type="text" className="form-control team-name" id="season_name" name="season_name" value={state.season_name} placeholder="Field Name *" required onChange={eventHandler} />

                                    {errors.season_name &&
                                        <span className='text-red small d-block'>* {errors.season_name}</span>}

                                </div>

                                <div className="mt-3 text-left">
                                    <a className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitRequest} >Submit</a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <Toaster />
                </div>
            </div>

        </>
    )
}

// export default withRouter(CreateInvoice)



const mapStateToProp = state => ({
    submit: state.editSeasonReducer,

})

const mapDispatchToProps = dispatch => ({
    getFieledHandler: (user) => dispatch(getField(user)),

    resetEditSeason: () => dispatch({ type: RESET_EDIT_SEASON }),
    // getSeasonHandler: user => dispatch(getAllSeason(user)),
    editFieldhandler: (user) => dispatch(editField(user))



})
export default connect(mapStateToProp, mapDispatchToProps)(EditField);