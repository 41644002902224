import { GET_INVITE_DATA_ERROR, GET_INVITE_DATA_LOADING, GET_INVITE_DATA_SUCCESS } from "../../constants"

let initialState={
    error:false,
    errors:[],
    user:{},
    loading:false,
    success:false,
    data:{
        "status": false,
        "message": "",
        "data": {
            "invitation_status": '',
            "_id": "",
            "invitation_by": "",
            "invitation_by_user_type": '',
            "invitation_to": "",
            "invitation_as": "",
            "invitation_for": "",
            "createdAt": "",
            "updatedAt": "",
            "__v": ""
        }
    }
}

export default function getInviteDataReducer(state=initialState,action){
    switch(action.type){
        case GET_INVITE_DATA_LOADING:
            return{...state,error:false,loading:true, success:false};
        case GET_INVITE_DATA_SUCCESS:
            return{...state,error:false,loading:false, success:true, data:action.data};
        case GET_INVITE_DATA_ERROR:
            return {...state, error:true, errors:[] , success:false, loading:false}
        default:
            return state;
    }

}