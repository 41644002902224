import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
// import Toaster from '../../../Toaster/Toaster';
import { withRouter, Redirect, useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  createSeason,
  editSeason,
  getAllSeason,
} from "../../service/action/userAction";
import { RESET_EDIT_SEASON } from "../../service/constants";
// import { RESET_CREATE_SEASON } from '../../service/constants';
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import BreadcrumbHistory from "../../BreadcumbHistory/BreadcrumbHistory";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const EditSeason = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  //all season
  const getdata = props.getSeasonHandler;
  useEffect(() => {
    getdata();
  }, []);

  const acctype = localStorage.getItem("type");

  const getAllSeason = useSelector((state) => state.getAllSeasonReducer.user);

  const editSeason = getAllSeason.find((e) => e._id == id);

  const [state, setState] = useState({
    season_name: "",
  });

  const [errors, setErrors] = useState({
    season_name: false,
  });

  const eventHandler = (e) => {
    const { id, value, name } = e.target;

    setState((prevState) => ({
      ...prevState,
      [id]: value,
      // [e.target.name]: e.target.value.trim()
    }));
    switch (name) {
      case "season_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            season_name: "Please enter season",
          }));

          document.getElementById("season_name").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            season_name: false,
          }));

          document.getElementById("season_name").classList.remove("error");
        }

        break;

      default:
    }
  };

  const notify = (msg) => toast(msg);

  useEffect(() => {
    if (editSeason) {
      setState((prevState) => ({
        ...prevState,
        season_name: editSeason.season_name,
      }));
    }
  }, [editSeason]);

  const submitRequest = (e) => {
    e.preventDefault();

    if (state.season_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        season_name: "Please enter season",
      }));
      document.getElementById("season_name").classList.add("error");
    }

    if (state.season_name !== "") {
      props.editSeasonhandler({
        season_name: state.season_name,
        id: id,
      });

      // const datas = { season_name: state.season_name }
      // const headers = {
      //     "Content-Type": "application/json",
      //     'Authorization': `Bearer ${localStorage.getItem("token")}`
      // }

      // if (acctype == 1) {

      //     const requestOptions = {
      //         method: "PUT",
      //         headers: {
      //             'content-type': 'application/json',
      //             'Authorization': `Bearer ${localStorage.getItem("token")}`
      //         },
      //         url: `http://192.168.0.33:5555/api/organizer/event-season/${id}`, data: datas
      //     };
      //     axios(requestOptions)

      //     return <Redirect to="/switch" />
      // } else {
      //     const requestOptions = {
      //         method: "PUT",
      //         headers: {
      //             'content-type': 'application/json',
      //             'Authorization': `Bearer ${localStorage.getItem("token")}`
      //         },
      //         url: `http://192.168.0.33:5555/api/club/event-season/${id}`, data: datas
      //     };

      // }
      //  axios.put(acctype==1?`http://192.168.0.33:5555/api/organizer/event-season/${id}`:`http://192.168.0.33:5555/api/club/event-season/${id}`,datas, headers)
    }
  };
  if (props.submit?.success) {
    // notify("Season Edit successfully.")
    // props.resetEditSeason()
    // localStorage.removeItem("season_id")
    return <Redirect to="/season" />;
  }

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Edit Season</h5>
          </div>

          <form>
            <div className="row mx-0">
              <div className="col-md-4 col-xl-4">
                <div className="mt-24">
                  <div className="text-left">
                    <label className="text-gray fs-13">
                      Season Name <span className="text-red fw-m">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control team-name"
                      id="season_name"
                      name="season_name"
                      value={state.season_name}
                      placeholder="Season Name *"
                      required
                      onChange={eventHandler}
                    />

                    {errors.season_name && (
                      <span className="text-red small d-block">
                        * {errors.season_name}
                      </span>
                    )}
                  </div>

                  <div className="mt-3 text-left">
                    <a
                      className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                      onClick={submitRequest}
                    >
                      Submit
                    </a>
                  </div>

                  {/* <Toaster /> */}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

// export default withRouter(CreateInvoice)

const mapStateToProp = (state) => ({
  submit: state.editSeasonReducer,
});

const mapDispatchToProps = (dispatch) => ({
  resetEditSeason: () => dispatch({ type: RESET_EDIT_SEASON }),
  getSeasonHandler: (user) => dispatch(getAllSeason(user)),
  editSeasonhandler: (user) => dispatch(editSeason(user)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(EditSeason));
