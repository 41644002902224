import { DELETE_EVENTTEAM_ERROR, DELETE_EVENTTEAM_LOADING, DELETE_EVENTTEAM_SUCCESS, RESET_DELETE_EVENTTEAM } from "../../constants";

let initialState={
    success:false,
    error:false,
    loading:false,
    error:[]
}


export default function deleteEventTeamReducer(state=initialState,action){
    switch(action.type){
        case DELETE_EVENTTEAM_LOADING:
            return {...state,success:false,loading:true,error:false}
        case DELETE_EVENTTEAM_SUCCESS:
            return {...state,success:true,loading:false,error:false,message:action.msg}
        case DELETE_EVENTTEAM_ERROR:
            return {...state,success:false,loading:false,error:false}
        case RESET_DELETE_EVENTTEAM:
            return {...state,success:false,loading:false,error:false}
        default :
        return state
    }
}