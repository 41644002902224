export const createDynamicUrl = async (firebaseRequestUrl, body) => {
  const res = await fetch(firebaseRequestUrl, {
    method: "POST",
    body: JSON.stringify(body),
    "Content-Type": "application/json",
  });

  const data = await res.json();

  return await data;
};
