import "./App.css";
import "./index.css";
import { Redirect, useHistory } from "react-router-dom";
// import { useParams } from 'react-router';
import Sidebar from "./club/components/Sidebar/Sidebar";
import Header from "./club/components/Header/Header";
import ClubDashBoard from "./club/components/Dashboard/ClubDashBoard";
import CreateTeam from "./club/Team/Create-team/Create-team";
import TeamDetails from "./club/Team/Team-details/Team-Details";
import AccountDetails from "./club/Account/Account-details/Account-details";
import AccountUser from "./club/Account/Account-users/Account-users";
import PaymentReq from "./club/payments/Payment-req/Payment-req";
import Events from "./club/Events/Events/Events";
import NotificationDm from "./club/Notification/Notification_dm/Notification_dm";
import TeamsCoches from "./club/Team/Teams-coches/TeamsCoches";
import Account from "./club/Account/Account/Account";
import EventDetails from "./club/Events/Event-details/Event-details";
import BankInfo from "./club/Bank-info/Account-bank-info/Account-bank-info";
import Payment from "./club/payments/payment/Payment";
import OrgDashboard from "./organiser/dashboard/Dashboard";
import OrgEvent from "./organiser/Events/Event/Event";
import CreateEvent from "./organiser/Events/create-event/CreateEvent";
import OrgEventDetails from "./organiser/Events/Event-details/Event-details";
import OrgPaymentReq from "./organiser/payment/payment-req/PaymentReq";
import Notification from "./organiser/notification/notification/Notification";
import OrgPayment from "./organiser/payment/payment/Payment";
import OrgPaymentCode from "./organiser/payment/invoiceCode/PaymentCode";
import OrgPaymentDiscount from "./organiser/payment/discountCode/Discountcode";
import OrgAllPaymentReq from "./organiser/payment/payment-req/AllPaymentReq";
import OrgPaymentOutSta from "./organiser/payment/payment-req/PaymentOutSta";
import OrgPaymentReceived from "./organiser/payment/payment-req/PaymentReceived";
import OrgAccount from "./organiser/Account/Account/Account";
import OrgAccountUser from "./organiser/Account/Account_user/Account_user";
import OrgAccountDetail from "./organiser/Account/Account-details/Account-details";
import OrgBankInfo from "./organiser/Account/Account-bank0info/Account-bank-info";
import AddStaff from "./organiser/staff/add-staff/Add-staff";
import AccountStaff from "./organiser/Account/Account_staff/Account-staff";
import EventTeamCreate from "./organiser/team/create-team/Create-team";
import EventTeamDetails from "./organiser/team/team-details/Team-details";
import Club from "./organiser/clubs/Clubs/Club";
import ClubNew from "./organiser/clubs/Clubs_new/Clubs_new";
import ClubInfo from "./organiser/clubs/club_info/Club_info";
// import PageNoteFound from './PageNoteFound'
// import Login from './login/Login';
import InvitePlayer from "./club/Team/Invite-player/InvitePlayer";
// import Signup from './signup/Signup';
// import CreatePaymentReq from './organiser/payment/create-payment-req/CreatePaymentReq';
import CreatePaymentCode from "./organiser/payment/invoiceCode/CreatePaymentCode";
import CreateDiscountCode from "./organiser/payment/discountCode/CreateDiscountCode";
import CreateClubEvent from "./club/Events/create-event/CreateEvent";
import PageNoteFound from "./PageNoteFound";
import EditClubEvent from "./club/Events/edit-event/Edit_Event";
import Org_findUser from "./organiser/payment/findUser";
import { Switch, Route } from "react-router-dom";

import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
// import CreateDiscount from './organiser/payment/discountCode/CreateDiscountCode';
import OrgPaymentRef from "./organiser/payment/payment-req/PaymentRef";
import SignupContainer from "./containers/SignupContainer";
import SigninContainer from "./containers/SigninContainer";
import EditPaymentCode from "./organiser/payment/invoiceCode/EditPaymentCode";
// import EditEvent from './organiser/Events/edit-event/EditEvent';
import SwitchAccount from "./Switch/SwitchAccount";
import AddAccount from "./AddAccount/AddAccount";
import Season from "./organiser/Season/Season";
import CreateSeason from "./organiser/Season/CreateSeason";
import SignupByMail from "./signup/SignupByMail";
import DownloadAppPage from "./Switch/DownloadAppPage";
import EditSeason from "./organiser/Season/EditSeason";
import EditDiscountCode from "./organiser/payment/discountCode/EditDiscountCode";
import OrgCreateDivision from "./organiser/Events/Event-Division/Create-division";
import OrgCreatePool from "./organiser/Events/Event-pool/Create-pool";
import OrgEditDivision from "./organiser/Events/Event-Division/Edit-division";
import EditPool from "./organiser/Events/Event-pool/Edit-Pool";
import AddSchedule from "./organiser/Events/Event-Schedule/Add-Schedule";
import UpdatePoolDivision from "./organiser/Events/FreeTeam/UpdatePooDivision";
import PaymentCode from "./club/payments/payment/Payment_Code";
import CreatePaymentClubCode from "./club/payments/payment/CreatePayment_Code";
import EditPaymentClubCode from "./club/payments/payment/EditPayment_Code";
import DiscountCode from "./club/DiscountCode/Discount_Code";
import CreateClubDiscountCode from "./club/DiscountCode/CreateDiscount_Code";
import Filed from "./club/Field/Filed";
import CreateField from "./club/Field/CreateField";
import EditField from "./club/Field/EditField";
import Clubdivision from "./club/Division/Division";
import CreateDivision from "./club/Division/Create_Division";
import Editdivision from "./club/Division/Edit_division";
import Org_paymentCode from "./organiser/payment/paymentcodeSelection";
// import EditDivision from './organiser/Events/Event-Division/Edit-division';
import League from "./club/League/League";
import CreateLeague from "./club/League/CreateLeague";
import EditLeague from "./club/League/EditLeague";
import EditBankInfo from "./club/Bank-info/Account-bank-info/EditBankInfo";
import AddCoach from "./club/Coach/Add-Coach";
import Createliability from "./organiser/liability/Createliability";
import OrgList from "./club/Liability/OrgList";
import LiabilityTerms from "./club/Liability/LiabilityTerms";
import Signature from "./club/Liability/Signature";
import ClubOrgeventDetails from "./club/Events/Event-details/Club_Org_eventDetails";
import ClubLiability from "./club/Liability/Club_Liability";
import EditTeam from "./club/Team/Edit-team/EditTeam";
import AccountStaffList from "./organiser/Account/Account_staff/Account_staff_List";
import CreateNewGroup from "./organiser/Account/Account_user/CreateNewGroup";
import AccountGroup from "./organiser/Account/Account_user/Account_Group";
import AccountAddStaff from "./organiser/Account/Account_staff/Account-add-staff";
import Permission from "./organiser/Account/Account_user/Permission";
import RegisterEventPage from "./club/Events/Event-details/RegisterEventPage";
import TeamListing from "./club/Registration/TeamListing";
import PayNow from "./club/Registration/PayNow";
import PlayerNew from "./organiser/Player/Player_new";
import ChatComponent from "./club/Notification/Notification_dm/ChatComponent";
import NewPaymentReq from "./organiser/payment/payment-req/NewPaymentReq";
import PaymentOption from "./club/Registration/PaymentOption";
import Pay from "./club/Registration/Pay";
import CreateTeamGroup from "./organiser/team/team-details/CreateTeamGroup";
// import PlayerAddToGroup from './organiser/team/team-details/PlayerAddToGroup';
import GroupDetails from "./organiser/team/team-details/GroupDetails";
import EditTeamGroup from "./organiser/team/team-details/EditTeamGroup";
import Credit from "./organiser/payment/Credit/Credit";
import CreateCredit from "./organiser/payment/Credit/CreateCredit";
import Refund from "./organiser/payment/Refunds/Refund";
import CreateRefund from "./organiser/payment/Refunds/CreateRefund";
import Chat from "./organiser/notification/Dm/Chat";
import ClubRefunds from "./club/payments/Refunds/ClubRefunds";
import CreateClubRefund from "./club/payments/Refunds/CreateClubRefund";
import ClubCredit from "./club/payments/Credit/ClubCredit";
import CreateClubCredit from "./club/payments/Credit/CreateClubCredit";
import ForgotPassword from "./login/ForgotPassword";
import VerifyOtp from "./login/VerifyOtp";
import ResetPassword from "./login/ResetPassword";
import ChangePassword from "./Change_password/ChangePassword";
import CreatePaymentRequest from "./club/payments/Payment-req/CreatePaymentRequest";
import RequestClubPay from "./club/Notification/Request-Payment/Request_Pay";
import RequestPayOption from "./club/Notification/Request-Payment/Request_Pay_Option";
import RequestPayCarddetails from "./club/Notification/Request-Payment/Request_Pay_Carddetails";
import AddRoaster from "./club/Team/Team-details/AddRoaster";
import AddPlayerToTeam from "./club/Team/Team-details/AddPlayerToTeam";
import Merchandaccount from "./club/Bank-info/Account-bank-info/Merchandaccount";
import ClubRegisterTeam from "./club/Events/RegisterTeam/ClubRegisterTeam";
import Reports from "./organiser/Account/Reports/Reports";
import ClubReport from "./club/Account/Report/ClubReport";
import CreatePool from "./club/Events/Event-details/CreatePool";
import { connect } from "react-redux";
import {
  addAccount,
  getAccountDetails,
  getAllEvent,
  getDashBoardPayment,
} from "./service/action/userAction";
import AssignCoach from "./club/Team/Team-details/AssignCoach";
import EditOrgTeam from "./organiser/team/create-team/Edit-Team";
import MerchandAccountOrg from "./organiser/Account/Account-bank0info/MerchandAccountOrg";
import Merchand from "./club/Bank-info/Account-bank-info/Merchand";
import MerchandOrg from "./organiser/Account/Account-bank0info/MerchandOrg";
import Coach from "./club/Coach/Coach";
// import PushNotification from './PushNotification';
// import {  onMessage } from "firebase/messaging"
import CreateEventDivision from "./club/Events/Event-division/Create-Event-Division";
import EditEventDivision from "./club/Events/Event-division/EditEventDivision";
import EditEventPool from "./club/Events/Event-details/EditEventPool";
// import Demo from './organiser/Events/edit-event/EditOrgEvent';
// import EditClubEvent from './organiser/Events/edit-event/EditOrgEvent';
import EditOrgEvent from "./organiser/Events/edit-event/EditOrgEvent";
import DivisionPool from "./club/Division/Division-pool";
import CreateAccount from "./AddAccount/CreateAccount";
import AddCoachToTeam from "./club/Team/Team-details/AddCoachToTeam";
import SendBroadcast from "./organiser/notification/Dm/SendBroadcast";
import {
  getAllClubEvent,
  getClubNotification,
} from "./service/action/Club/clubAction";
import { RESET_GET_PAYMENT_REQUEST } from "./service/constants";
import SignupSteps from "./signup/SignupSteps";
import Discount_Code from "./club/DiscountCode/Discount_Code";
import EventPlayer from "./organiser/team/EventPlayer";
import LiabilityWaiver from "./club/Liability/LiabilityWaiver";
import FindEvent from "./club/Events/find-event/FindEvent";
import PlayerDetails from "./club/Team/PlayerDetails/PlayerDetails";
import PaymentMethod from "./club/payments/payment-method/PaymentMethod";
import AddPaymentMethod from "./club/payments/payment-method/AddPaymentMethod";
import EditPaymentMethod from "./club/payments/payment-method/EditPaymentMethod";
import EditDiscount_Code from "./club/DiscountCode/EditDiscount_Code";
import CreateRoster from "./club/Team/Team-details/CreateRoster";
import RosterPlayer from "./club/Team/Team-details/RosterPlayer";
import RemoveAccount from "./organiser/Account/Account/RemoveAccount";
import AddAccountForm from "./AddAccount/AddAccountForm";
import SearchTeam from "./organiser/team/team-details/SearchTeam";
import OrgInvitePlayer from "./organiser/team/team-details/OrgInvitePlayer";
import EventDvisionDetails from "./organiser/Events/Event-division-details/EventDvisionDetails";
import TeamClubDetails from "./organiser/team/team-details/TeamClubDetails";
import SignedWaiver from "./organiser/Account/Account_Liability/SignedWaiver";
import LiabilityTermsList from "./organiser/Account/Account_Liability/LiabilityTermsList";
import CreateRosterOrg from "./organiser/team/Create-Roster/CreateRosterOrg";
import EventfreeAgents from "./organiser/Events/Event-FreeAgents/EventfreeAgents";
import Request_Pay from "./club/Notification/Request-Payment/Request_Pay";
import Create_Division from "./club/Division/Create_Division";
import Teaminsideteam from "./organiser/Events/TeaminsideTeam/Teaminsideteam";
import Payment_History from "./club/payments/payment/Payment_History";
import RefundDetails from "./organiser/payment/Refunds/RefundDetails";
import PaymentHistory from "./organiser/payment/payment/PaymentHistory";
import OrgPlayerDetails from "./organiser/team/team-details/OrgPlayerDetails";
import RoasterDetails from "./organiser/team/RoasterDetails/RoasterDetails";
import RoasterPersonDetails from "./organiser/team/RoasterDetails/RoasterPersonDetails";
import BreadcrumbHistory from "./BreadcumbHistory/BreadcrumbHistory";
import AddTeam from "./organiser/team/AddTeam";
import ChangePool from "./organiser/Events/Event-pool/ChangePool";
import Addfieldmap from "./organiser/Events/Field-Map/Addfieldmap";
import EditSchedule from "./organiser/Events/Event-Schedule/EditSchedule";
import EditFfeldmap from "./organiser/Events/Field-Map/Editfieldmap";
import Editfieldmap from "./organiser/Events/Field-Map/Editfieldmap";
import ClubAddAccountForm from "./club/Account/ClubAddAccountForm";
import AdminPermission from "./club/Permissions/AdminPermission";
import InviteAdmin from "./club/Permissions/InviteAdmin";
import CreateAdminGroup from "./club/Permissions/CreateAdminGroup";
import SetGroupPermission from "./club/Permissions/SetGroupPermission";
import AddStayandPlay from "./organiser/Events/stay and play/AddStayandPlay";
import EditStayandPlay from "./organiser/Events/stay and play/EditStayandPlay";
import SuperAdminApp from "./SuperAdminApp";
import "@progress/kendo-theme-default/dist/all.css";
import ModifiedPassword from "./Change_password/ModifiedPassword";
import Org_Permission from "./organiser/Permission/Org_Permission";
import CreateGroup from "./organiser/Permission/CreateGroup";
import GroupUserDetails from "./organiser/Permission/GroupUserDetails";
import Org_InviteAdmin from "./organiser/Permission/Org_InviteAdmin";
import Staff_Permission from "./organiser/Permission/Staff_Permission";
import CreateStaffGroup from "./organiser/Permission/CreateStaffGroup";
import StaffUserDetails from "./organiser/Permission/StaffUserDetails";
import StaffPermission from "./organiser/Permission/StaffPermission";
import AddStaffList from "./organiser/Permission/AddStaffList";
import Club_Season from "./club/Season/Club_Season";
import Club_createSeason from "./club/Season/Club_createSeason";
import Club_editSeason from "./club/Season/Club_editSeason";
import AccountUsers from "./club/Permissions/AccountUsers";
import NotificationAccounts from "./organiser/notification/notification/NotificationAccounts";
import AccountNotifications from "./club/Account/Account-notifications/AccountNotifications";
import NewCreditRequest from "./organiser/payment/payment-req/NewCreditRequest";
import RegisterEvent from "./organiser/notification/notification/RegisterEvent";
import EventRegisterTeamListing from "./organiser/notification/notification/EventRegisterTeamListing";
import RegisterEventNotification from "./club/Notification/Notification_dm/RegisterEventNotification";
import EventRegisterPayNow from "./organiser/notification/notification/EventRegisterPayNow";
import EventRegisterLiabilityWavier from "./organiser/notification/notification/EventRegisterLiabilityWavier";
import SingupOption from "./signup/components/SingupOption";
import SingupFormContainer from "./signup/components/SingupFormContainer";
const App = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [splitPanel, setvalue] = useState("");
  const status = localStorage.getItem("status");
  // const club = localStorage.getItem("club")
  const type = localStorage.getItem("type");
  const isAuth = localStorage.getItem("auth");
  const typeValue = localStorage.getItem("type");
  const token = localStorage.getItem("token");
  const isAdmin = localStorage.getItem("isAdmin");
  const userId = localStorage.getItem("Id");

  const ProtectedRoute = ({ isAuth, component: Component, path, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          return isAuth ? (
            <Component />
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }}
      />
    );
  };

  useEffect(() => {
    if (status === 0) {
      setvalue(true);
    } else {
      setvalue(false);
    }
  }, [splitPanel]);

  const getdata = props.getEventHandler;
  const dashboardPayment = props.getDashBoardPaymentHandler;
  const getNotification = props.getNotificationhandler;
  const getClubdata = props.getClubEventHandler;
  const dashboardPaymentClub = props.getDashBoardPaymentClubHandler;

  useEffect(() => {
    if (isAuth !== null) {
      props.getAccountDetails();
    }
  }, []);

  useEffect(() => {
    if (isAdmin == "false" && type === null) {
      localStorage.clear();
      history.push("/login");
    }
  }, [isAdmin, location.pathname]);

  return (
    <div className="App">
      {(location.pathname.startsWith("/superadmin") && isAdmin == "true") ||
      (location.pathname.startsWith("/superadmin") && type === null) ||
      (!location.pathname.startsWith("/superadmin") && isAdmin == "true") ? (
        <>
          <SuperAdminApp />
        </>
      ) : (
        <>
          <div className="container-scroller">
            <Switch></Switch>
            {location.pathname !== "/" &&
              location.pathname !== "/login" &&
              location.pathname !== "/signup" &&
              location.pathname !== "/register-by-invitation/:id" &&
              location.pathname !== "/downloadapp" &&
              location.pathname !== "/switch" &&
              location.pathname !== "/modifiedpassword" &&
              location.pathname !== "/createaccount" && (
                <Route isAuth={isAuth} component={Header} />
              )}
            {/* <Header /></ProtectedRoute> } */}
            <div className="container-fluid page-body-wrapper">
              {location.pathname !== "/" &&
                location.pathname !== "/login" &&
                location.pathname !== "/signup" &&
                location.pathname !== "/register-by-invitation/:id" &&
                location.pathname !== "/downloadapp" &&
                location.pathname !== "/switch" &&
                location.pathname !== "/modifiedpassword" &&
                location.pathname !== "/createaccount" && (
                  <Route isAuth={isAuth} component={Sidebar} />
                )}
              {token == null ? (
                <>
                  <Switch>
                    <Route path="/" exact>
                      <SigninContainer />
                    </Route>
                    <Route path="/login" exact>
                      <SigninContainer />
                    </Route>
                    <Route path="/signup" exact>
                      <SingupOption />
                      {/* <SignupContainer /> */}
                      {/* <SignupSteps /> */}
                    </Route>
                    <Route path="/signup/:id" exact>
                      <SingupFormContainer />
                      {/* <SignupContainer /> */}
                      {/* <SignupSteps /> */}
                    </Route>
                    {/* <Route path="/register-by-invitation/:id" exact>
                  <SignupByMail />
                </Route> */}
                  </Switch>
                </>
              ) : (
                <>
                  <Switch>
                    <Route path="/" exact>
                      <Redirect to="/dashboard" />
                    </Route>
                    <Route path="/login" exact>
                      <Redirect to="/dashboard" />
                    </Route>
                    <Route path="/signup" exact>
                      <Redirect to="/dashboard" />
                    </Route>
                    <Route path="/createaccount" exact>
                      {type == null ||
                      type == 2 ||
                      type == 5 ||
                      type == 6 ||
                      type == 3 ? (
                        <>
                          <CreateAccount />
                        </>
                      ) : (
                        <></>
                      )}
                    </Route>
                  </Switch>
                </>
              )}
              <Switch>
                <Route isAuth={isAuth} path="/downloadapp" exact>
                  <DownloadAppPage />
                </Route>

                {/* <Route path="/register-by-invitation/:id" exact>
                <SignupByMail />
              </Route> */}

                <Route path="/forgotpassword" exact>
                  <ForgotPassword />
                </Route>

                <Route path="/verifyotp/:id" exact>
                  <VerifyOtp />
                </Route>

                <Route path="/resetpassword" exact>
                  <ResetPassword />
                </Route>
                <ProtectedRoute
                  path="/switch"
                  isAuth={isAuth}
                  component={SwitchAccount}
                  exact
                />
                <ProtectedRoute
                  path="/modifiedpassword"
                  isAuth={isAuth}
                  component={ModifiedPassword}
                  exact
                />
                <Route path="/register-by-invitation/:id" exact>
                  {token == null && type == null ? <SignupByMail /> : ""}
                </Route>
                {/* <SwitchAccount />
              </ProtectedRoute> */}
                {/* <ProtectedRoute path="/addaccount" isAuth={isAuth} type={typeValue} component={AddAccount} exact /> */}
                {/* <Route path="/registernow/:id" exact>
                {sessionStorage.setItem("link",location.pathname)}
                {
                }
                {token == null && type == null ? <Redirect to="/login" /> : ""}
              </Route> */}

                <Route path="/registernow/:id" exact>
                  {token == null && type == null ? <SigninContainer /> : ""}
                </Route>

                <Route path="*">
                  {token == null && type == null ? (
                    <Redirect to="/login" />
                  ) : (
                    ""
                  )}
                </Route>
              </Switch>

              {type == 4 ? (
                <>
                  <Switch key={status}>
                    {/* <ProtectedRoute path="/dashboard" isAuth={isAuth} component={Dashboard} /> */}
                    <Route path="/dashboard" exact>
                      {token == null || type == null ? (
                        <Redirect to="/login" />
                      ) : (
                        <ClubDashBoard />
                      )}
                    </Route>

                    {/* 
                 <Route path="/addaccount" exact>
                   <AddAccount />
                 </Route> */}

                    <Route path="/teams/create-team" exact>
                      <CreateTeam />
                    </Route>

                    <Route path="/teams/invite-player" exact>
                      <InvitePlayer />
                    </Route>

                    <Route path="/teams/add-coach" exact>
                      <AddCoach />
                    </Route>

                    <Route path="/coach" exact>
                      <Coach />
                    </Route>

                    <Route path="/invite-player/:id/:id1" exact>
                      <InvitePlayer />
                    </Route>

                    <Route path="/teams/:id/edit-team" exact>
                      <EditTeam />
                    </Route>

                    <Route path="/teams/:id/:id1" exact>
                      <TeamDetails />
                    </Route>
                    <Route path="/division/create-division" exact>
                      <Create_Division />
                    </Route>

                    <Route path="/teams/:id" exact>
                      <TeamDetails />
                    </Route>

                    <Route path="/team-roster/:id/:id1" exact>
                      <RosterPlayer />
                    </Route>

                    <Route path="/account-details" exact>
                      <AccountDetails />
                    </Route>

                    <Route path="/account-users" exact>
                      <AccountUser />
                    </Route>

                    <Route path="/leagues/create-league" exact>
                      <CreateLeague />
                    </Route>

                    <Route path="/leagues/:id" exact>
                      <EditLeague />
                    </Route>

                    <Route path="/payment-request" exact>
                      <PaymentReq />
                    </Route>

                    <Route path="/events" exact>
                      <Events />
                    </Route>
                    <Route path="/events/find-event" exact>
                      <FindEvent />
                    </Route>

                    <Route path="/event-details/:id" exact>
                      <EventDetails />
                    </Route>

                    <Route path="/events/:id" exact>
                      <ClubOrgeventDetails />
                    </Route>

                    <Route path="/register-team-event/:id/:id1" exact>
                      <RegisterEventPage />
                    </Route>
                    <Route path="/liability-waiver-terms/:id" exact>
                      <LiabilityWaiver />
                    </Route>

                    <Route path="/events/:id/registernow/:id1" exact>
                      <TeamListing />
                    </Route>

                    {/* testing */}

                    <Route path="/events/:id/registernow" exact>
                      <TeamListing />
                    </Route>

                    <Route path="/events/:id/paynow/:id1" exact>
                      <PayNow />
                    </Route>

                    {/* testing */}
                    <Route path="/events/:id/paynow" exact>
                      <PayNow />
                    </Route>

                    <Route path="/addroster/:id" exact>
                      <CreateRoster />
                    </Route>
                    <Route path="/addroster/:id/:id1" exact>
                      <AddRoaster />
                    </Route>

                    <Route path="/selectpaymentoption/:id/:id1" exact>
                      <PaymentOption />
                    </Route>

                    {/* testing */}
                    <Route path="/selectpaymentoption/:id" exact>
                      <PaymentOption />
                    </Route>

                    <Route path="/carddetails/:id/:id1/:id2" exact>
                      <Pay />
                    </Route>

                    {/* testing */}
                    <Route path="/carddetails/:id/:id1/:id2" exact>
                      <Pay />
                    </Route>

                    <Route path="/leagues" exact>
                      <League />
                    </Route>

                    <Route path="/account-notifications" exact>
                      <AccountNotifications />
                    </Route>
                    <Route path="/account-notifications/:id" exact>
                      <NotificationDm />
                    </Route>
                    <Route
                      path="/account-notifications/:id/register-event"
                      exact
                    >
                      <RegisterEventNotification />
                    </Route>

                    <Route path="/chat/:id/:id1?/:id2/:id3" exact>
                      <ChatComponent />
                    </Route>

                    <Route path="/chat/:id" exact>
                      <ChatComponent />
                    </Route>

                    <Route path="/send-broadcast-messages" exact>
                      <SendBroadcast />
                    </Route>

                    <Route path="/teams" exact>
                      <TeamsCoches />
                    </Route>

                    <Route path="/field" exact>
                      <Filed />
                    </Route>

                    <Route path="/field/create-field" exact>
                      <CreateField />
                    </Route>

                    <Route path="/field/:id" exact>
                      <EditField />
                    </Route>

                    <Route path="/division" exact>
                      <Clubdivision />
                    </Route>
                    <Route path="/division-pool" exact>
                      <DivisionPool />
                    </Route>

                    <Route path="/createdivision" exact>
                      <CreateDivision />
                    </Route>

                    <Route path="/division/:id" exact>
                      <Editdivision />
                    </Route>

                    <Route path="/account" exact>
                      <Account />
                    </Route>

                    <Route path="/bank-info" exact>
                      <BankInfo />
                    </Route>

                    <Route path="/editbankinfo/:id" exact>
                      <EditBankInfo />
                    </Route>

                    <Route path="/payment" exact>
                      <Payment />
                    </Route>

                    <Route path="/payment/history" exact>
                      <Payment_History />
                    </Route>

                    <Route path="/request_pay_carddetails/:id/:id1" exact>
                      <RequestPayCarddetails />
                    </Route>

                    <Route path="/request_pay/:id" exact>
                      <Request_Pay />
                    </Route>

                    <Route path="/payment/club-refunds" exact>
                      <ClubRefunds />
                    </Route>

                    <Route
                      path="/payment/club-refunds/create-club-refund"
                      exact
                    >
                      <CreateClubRefund />
                    </Route>

                    <Route path="/payment/payment-code" exact>
                      <PaymentCode />
                    </Route>

                    <Route
                      path="/payment/payment-code/create-payment-code"
                      exact
                    >
                      <CreatePaymentClubCode />
                    </Route>

                    <Route path="/payment/payment-code/:id" exact>
                      <EditPaymentClubCode />
                    </Route>

                    <Route path="/payment/discount-code" exact>
                      <DiscountCode />
                    </Route>

                    <Route path="/payment/discount-code/create-discount" exact>
                      <CreateClubDiscountCode />
                    </Route>

                    <Route path="/assigncoach/:id" exact>
                      <AssignCoach />
                    </Route>

                    <Route path="/addcoachtoteam/:id" exact>
                      <AddCoachToTeam />
                    </Route>

                    <Route path="/payment/report/create-payment-request" exact>
                      <CreatePaymentRequest />
                    </Route>

                    <Route path="/player-detail/:id" exact>
                      <PlayerDetails />
                    </Route>

                    <Route path="/create-event" exact>
                      <CreateClubEvent />
                    </Route>

                    <Route path="/editclubevent/:id" exact>
                      <EditClubEvent />
                    </Route>

                    <Route path="/season" exact>
                      <Club_Season />
                    </Route>

                    <Route path="/season/create-season" exact>
                      <Club_createSeason />
                    </Route>

                    <Route path="/season/:id" exact>
                      <Club_editSeason />
                    </Route>

                    <Route path="/liability-waiver" exact>
                      <OrgList />
                    </Route>

                    <Route path="/clubcredit" exact>
                      <ClubCredit />
                    </Route>

                    <Route path="/discount_code" exact>
                      <Discount_Code />
                    </Route>

                    {/* <Route path="/clubs" exact>
                      <Club />
                    </Route> */}

                    <Route path="/payment/discount-code/:id" exact>
                      <EditDiscount_Code />
                    </Route>

                    <Route path="/requestpay/:id" exact>
                      <RequestClubPay />
                    </Route>

                    <Route path="/registerteam/:id" exact>
                      <ClubRegisterTeam />
                    </Route>

                    <Route path="/request_pay_option/:id" exact>
                      <RequestPayOption />
                    </Route>

                    <Route path="/createclubcredit" exact>
                      <CreateClubCredit />
                    </Route>

                    <Route path="/liabilityterms/:id" exact>
                      <LiabilityTerms />
                    </Route>

                    <Route path="/club-liability" exact>
                      <ClubLiability />
                    </Route>

                    <Route path="/addplayertoteam/:id" exact>
                      <AddPlayerToTeam />
                    </Route>

                    <Route path="/merchant" exact>
                      <Merchand />
                    </Route>
                    <Route path="/merchantdetails" exact>
                      <Merchandaccount />
                    </Route>

                    <Route path="/signature/:id" exact>
                      <Signature />
                    </Route>

                    <Route path="/payment/report" exact>
                      <ClubReport />
                    </Route>

                    <Route path="/createpool/:id/:id1" exact>
                      <CreatePool />
                    </Route>

                    <Route path="/edit-event-pool/:id/:id1/:id2" exact>
                      <EditEventPool />
                    </Route>

                    <Route path="/create-club-division/:id/:id1" exact>
                      <CreateEventDivision />
                    </Route>

                    {/* <Route path="/edit-event-division/:id/:id1" exact>
                   <EditEventDivision />
                 </Route> */}

                    <Route path="/changepassword" exact>
                      <ChangePassword />
                    </Route>

                    <Route path="/modifiedpassword" exact>
                      <Redirect to="/modifiedpassword" />
                    </Route>

                    <Route path="/switch">
                      <Redirect to="/switch" />
                    </Route>

                    <Route path="/downloadapp">
                      <Redirect to="/downloadapp" />
                    </Route>

                    <Route path="/register-by-invitation/:id" exact>
                      <SignupByMail />
                    </Route>

                    <Route path="/payment/payment-method" exact>
                      <PaymentMethod />
                    </Route>

                    <Route
                      path="/payment/payment-method/add-payment-method"
                      exact
                    >
                      <AddPaymentMethod />
                    </Route>

                    <Route path="/payment/payment-method/:id" exact>
                      <EditPaymentMethod />
                    </Route>

                    <ProtectedRoute
                      path="/add-account"
                      isAuth={isAuth}
                      type={typeValue}
                      component={AddAccount}
                      exact
                    />
                    <ProtectedRoute
                      path="/add-account/:id"
                      isAuth={isAuth}
                      type={typeValue}
                      component={AddAccountForm}
                      exact
                    />
                    <Route path="/admin" exact>
                      <AdminPermission />
                    </Route>

                    <Route path="/admin/create-group" exact>
                      <CreateAdminGroup />
                    </Route>
                    <Route path="/admin/invite-admin" exact>
                      <InviteAdmin />
                    </Route>
                    <Route path="/admin/:id" exact>
                      <AccountUsers />
                    </Route>
                    <Route path="/admin/:id/permission" exact>
                      <SetGroupPermission />
                    </Route>

                    <Route path="/createaccount" exact>
                      <CreateAccount />
                    </Route>
                    {/* <Route path="/add-new-account-form/:id" exact>
                    <ClubAddAccountForm />
                  </Route> */}

                    <Route path="*">
                      {/* <Redirect to ="/dashboard"/> */}
                      <PageNoteFound />
                    </Route>
                  </Switch>
                </>
              ) : (
                <>
                  {type == 1 ? (
                    [
                      <Switch key={status}>
                        {/* <ProtectedRoute path="/dashboard" isAuth={isAuth} component={Org_Dashboard} /> */}
                        <Route path="/dashboard" exact>
                          <OrgDashboard />
                        </Route>
                        <Route path="/dashboard" exact>
                          {token == null || type == null ? (
                            <Redirect to="/login" />
                          ) : (
                            <OrgDashboard />
                          )}
                        </Route>

                        {/* <ProtectedRoute path="/addaccount" exact>
                        <AddAccount />
                      </ProtectedRoute> */}

                        {/* <ProtectedRoute path="/events" isAuth={isAuth} component={Org_Event} /> */}
                        <Route path="/events" exact>
                          <OrgEvent />
                        </Route>

                        <Route path="/Teaminsideteam/:id" exact>
                          <Teaminsideteam />
                        </Route>

                        <Route path="/remove-account" exact>
                          <RemoveAccount />
                        </Route>

                        <Route path="/team-club-details/:id/:id1" exact>
                          <TeamClubDetails />
                        </Route>

                        <Route path="/addAccount-form/:id" exact>
                          <AddAccountForm />
                        </Route>

                        {/* <ProtectedRoute path="/create-event" isAuth={isAuth} component={CreateEvent} /> */}
                        <Route path="/create-event" exact>
                          <CreateEvent />
                        </Route>

                        {/* <ProtectedRoute path="/event-details/:id" isAuth={isAuth} component={Org_eventDetails} /> */}
                        <Route path="/event-details/:id" exact>
                          <OrgEventDetails />
                        </Route>

                        {/* id = eventid, id1: division id */}
                        <Route path="/event-division-details/:id/:id1" exact>
                          <EventDvisionDetails />
                        </Route>

                        {/* id = eventid, id1: division id */}

                        <Route path="/adddivision/:id" exact>
                          <OrgCreateDivision />
                        </Route>

                        <Route path="/addTeam/:id/:id1" exact>
                          <AddTeam />
                        </Route>

                        <Route path="/addpool/:id/:id1" exact>
                          <OrgCreatePool />
                        </Route>

                        {/* id = eventId, id1 = divisionId, id2: poolId */}
                        <Route path="/edit-pool/:id/:id1/:id2" exact>
                          <EditPool />
                        </Route>

                        <Route path="/event/paymentcode/:id/:id1" exact>
                          <Org_paymentCode />
                        </Route>

                        <Route path="/org-invite-player/:id" exact>
                          <OrgInvitePlayer />
                        </Route>

                        <Route path="/org-invite-player/:id/:id1" exact>
                          <OrgInvitePlayer />
                        </Route>

                        {/* <ProtectedRoute path="/club-new" isAuth={isAuth} component={ClubNew} /> */}
                        {/* id = eventId id1 = divisionId */}
                        <Route path="/club-invite" exact>
                          <Org_findUser />
                        </Route>
                        <Route path="/club-invite/:id" exact>
                          <Org_findUser />
                        </Route>
                        <Route path="/club-invite/:id/:id1" exact>
                          <Org_findUser />
                        </Route>

                        {/*  id= eventId, id1: divisionId or freeTeamId, id2: if id1== division id && teamId */}
                        <Route path="/updatepooldivision/:id/:id1" exact>
                          <UpdatePoolDivision />
                        </Route>
                        <Route path="/updatepooldivision/:id/:id1/:id2" exact>
                          <UpdatePoolDivision />
                        </Route>
                        {/* --- */}

                        <Route path="/event-freeAgents/:id/:id1" exact>
                          <EventfreeAgents />
                        </Route>
                        <Route path="/event-freeAgents/:id/:id1/:id2" exact>
                          <EventfreeAgents />
                        </Route>

                        <Route path="/event-details/:id/:id1" exact>
                          <OrgEditDivision />
                        </Route>

                        <Route path="/change-pool/:id/:id1/:id2" exact>
                          <ChangePool />
                        </Route>

                        {/* <ProtectedRoute path="/payment-req" isAuth={isAuth} component={OrgPaymentReq} /> */}
                        <Route path="/payment-req" exact>
                          <OrgPaymentReq />
                        </Route>

                        <Route path="/new-payment-request" exact>
                          <NewPaymentReq />
                        </Route>
                        <Route path="/new-credit-request" exact>
                          <NewCreditRequest />
                        </Route>

                        {/* <ProtectedRoute path="/allpaymentreq" isAuth={isAuth} component={OrgAllPaymentReq} /> */}
                        <Route path="/allpaymentrequests" exact>
                          <OrgAllPaymentReq />
                        </Route>

                        {/* <ProtectedRoute path="/paymentoutsta" isAuth={isAuth} component={Org_PaymentOutSta} /> */}
                        <Route path="/paymentoutsta" exact>
                          <OrgPaymentOutSta />
                        </Route>
                        <Route path="/payment-history" exact>
                          <PaymentHistory />
                        </Route>

                        {/* <ProtectedRoute path="/paymentreceived" isAuth={isAuth} component={Org_PaymentReceived} /> */}
                        <Route path="/paymentreceived" exact>
                          <OrgPaymentReceived />
                        </Route>

                        {/* <ProtectedRoute path="/paymentrefunded" isAuth={isAuth} component={Org_PaymentRef} /> */}
                        <Route path="/paymentrefunded" exact>
                          <OrgPaymentRef />
                        </Route>

                        {/* <ProtectedRoute path="/notification" isAuth={isAuth} component={Notification} /> */}
                        <Route path="/notification-accounts" exact>
                          <NotificationAccounts />
                        </Route>
                        <Route path="/notification-accounts/:id" exact>
                          <Notification />
                        </Route>
                        <Route path="/account-notifications/:id" exact>
                          <Notification />
                        </Route>
                        <Route
                          path="/notification-accounts/:id/register-event"
                          exact
                        >
                          <RegisterEvent />
                        </Route>

                        {/* id = accountId and id1 = eventId */}
                        <Route
                          path="/notification-accounts/:id/register-event/:id1"
                          exact
                        >
                          <EventRegisterTeamListing />
                        </Route>

                        <Route
                          path="/notification-accounts/:id/register-event/:id1/paynow"
                          exact
                        >
                          <EventRegisterPayNow />
                        </Route>
                        <Route path="/liability-waiver-terms/:id" exact>
                          <EventRegisterLiabilityWavier />
                        </Route>

                        <Route path="/chat/:id/:id1?/:id2/:id3" exact>
                          <Chat />
                        </Route>
                        <Route path="/chat/:id" exact>
                          <Chat />
                        </Route>

                        <Route path="/send-broadcast-messages" exact>
                          <SendBroadcast />
                        </Route>

                        {/* <ProtectedRoute path="/payment" isAuth={isAuth} component={OrgPayment} /> */}
                        <Route path="/payment" exact>
                          <OrgPayment />
                        </Route>

                        {/* <ProtectedRoute path="/paymentcode" isAuth={isAuth} component={OrgPaymentCode} /> */}
                        <Route path="/paymentcode" exact>
                          <OrgPaymentCode />
                        </Route>

                        {/* <ProtectedRoute path="/discountcode" isAuth={isAuth} component={OrgPaymentDiscount} /> */}
                        <Route path="/discount-code" exact>
                          <OrgPaymentDiscount />
                        </Route>

                        <Route path="/discount-code/create-discount" exact>
                          <CreateDiscountCode />
                        </Route>

                        <Route path="/discount-code/:id" exact>
                          <EditDiscountCode />
                        </Route>

                        {/* <ProtectedRoute path="/creatediscount" isAuth={isAuth} component={CreateDiscountCode} /> */}

                        <Route path="/createliability" exact>
                          <Createliability />
                        </Route>

                        {/* <ProtectedRoute path="/createinvoice" isAuth={isAuth} component={CreateInvoice} /> */}
                        <Route path="/create-payment-code" exact>
                          <CreatePaymentCode />
                        </Route>

                        {/* <ProtectedRoute path="/editpaymentcode/:id" isAuth={isAuth} component={EditPaymentCode} /> */}
                        <Route path="/editpaymentcode/:id" exact>
                          <EditPaymentCode />
                        </Route>

                        {/* <ProtectedRoute path="/editEvent/:id" isAuth={isAuth} component={EditEvent} /> */}

                        <Route path="/editEvent/:id" exact>
                          <EditOrgEvent />
                        </Route>

                        {/* <ProtectedRoute path="/create-payment-req" isAuth={isAuth} component={CreatePaymentReq} /> */}
                        {/* <Route path="/create-payment-req" exact>
                    <CreatePaymentReq />
                  </Route> */}

                        {/* <ProtectedRoute path="/account" isAuth={isAuth} component={OrgAccount} /> */}
                        <Route path="/account" exact>
                          <OrgAccount />
                        </Route>

                        {/* <ProtectedRoute path="/account-user" isAuth={isAuth} component={OrgAccountUser} /> */}
                        <Route path="/account-users" exact>
                          <OrgAccountUser />
                        </Route>

                        <Route path="/groupaccount/:id" exact>
                          <AccountGroup />
                        </Route>

                        <Route path="/add-group" exact>
                          <CreateNewGroup />
                        </Route>

                        <Route path="/createteamgroup/:id/:id1" exact>
                          <CreateTeamGroup />
                        </Route>

                        <Route path="/editteamgroup/:id/:id1/:id2" exact>
                          <EditTeamGroup />
                        </Route>

                        <Route path="/teamgroupdetails/:id" exact>
                          <GroupDetails />
                        </Route>

                        <Route path="/admin-permission" exact>
                          <Org_Permission />
                        </Route>

                        <Route path="/admin-permission/create-group" exact>
                          <CreateGroup />
                        </Route>
                        <Route path="/admin-permission/invite-admin" exact>
                          <Org_InviteAdmin />
                        </Route>

                        <Route path="/admin-permission/:id" exact>
                          <GroupUserDetails />
                        </Route>

                        <Route path="/admin-permission/:id/permission" exact>
                          <Permission />
                        </Route>

                        <Route path="/staff-permission" exact>
                          <Staff_Permission />
                        </Route>

                        <Route path="/staff-permission/create-group" exact>
                          <CreateStaffGroup />
                        </Route>

                        <Route path="/staff-permission/add-staff" exact>
                          <AddStaffList />
                        </Route>

                        <Route path="/staff-permission/:id" exact>
                          <StaffUserDetails />
                        </Route>

                        <Route path="/staff-permission/:id/permission" exact>
                          <StaffPermission />
                        </Route>

                        {/* <Route path="/permission/:id" exact>
                          <Permission />
                        </Route> */}

                        {/* <ProtectedRoute path="/account-details" isAuth={isAuth} component={OrgAccountDetail} /> */}
                        <Route path="/account-details" exact>
                          <OrgAccountDetail />
                        </Route>

                        {/* <ProtectedRoute path="/bank-info" isAuth={isAuth} component={OrgBankInfo} /> */}
                        <Route path="/bank-info" exact>
                          <OrgBankInfo />
                        </Route>

                        {/* <ProtectedRoute path="/add-staff" isAuth={isAuth} component={AddStaff} /> */}
                        <Route path="/add-staff/:id" exact>
                          <AddStaff />
                        </Route>

                        <Route path="/CreateRosterOrg/:id" exact>
                          <CreateRosterOrg />
                        </Route>

                        {/* <ProtectedRoute path="/add-staff" isAuth={isAuth} component={AddStaff} /> */}
                        <Route path="/addSchedule/:id" exact>
                          <AddSchedule />
                        </Route>

                        <Route path="/EditSchedule/:id/:id1" exact>
                          <EditSchedule />
                        </Route>

                        <Route path="/addfieldmap/:id" exact>
                          <Addfieldmap />
                        </Route>

                        <Route path="/addstayandplay/:id" exact>
                          <AddStayandPlay />
                        </Route>

                        <Route path="/editstayandplay/:id/:id1" exact>
                          <EditStayandPlay />
                        </Route>

                        <Route path="/editfieldmap/:id/:id1" exact>
                          <Editfieldmap />
                        </Route>

                        {/* <ProtectedRoute path="/account-staff" isAuth={isAuth} component={AccountStaff} /> */}
                        <Route path="/account-staff-list" exact>
                          <AccountStaffList />
                        </Route>

                        <Route path="/account-staff" exact>
                          <AccountStaff />
                        </Route>

                        {/* //In Account_user==>AddStaff */}
                        <Route path="/account-add-staff" exact>
                          <AccountAddStaff />
                        </Route>

                        {/* <ProtectedRoute path="/create-team" isAuth={isAuth} component={EventTeamCreate} /> */}

                        <Route path="/create-team/:id" exact>
                          <EventTeamCreate />
                        </Route>

                        {/* <ProtectedRoute path="/team-details" isAuth={isAuth} component={EventTeamDetails} /> */}
                        <Route path="/team-details/:id" exact>
                          <EventTeamDetails />
                        </Route>

                        <Route path="/team-details/player/:id1" exact>
                          <EventPlayer />
                        </Route>

                        <Route path="/Org/player/:id" exact>
                          <OrgPlayerDetails />
                        </Route>
                        <Route path="/Org/roasterDetails/:id/:id1" exact>
                          <RoasterDetails />
                        </Route>
                        <Route path="/roasterpersondetails/:id" exact>
                          <RoasterPersonDetails />
                        </Route>

                        <Route path="/search-team" exact>
                          <SearchTeam />
                        </Route>

                        <Route path="/search-team/:id" exact>
                          <SearchTeam />
                        </Route>

                        {/* <ProtectedRoute path="/club" isAuth={isAuth} component={Club} /> */}

                        <Route path="/clubs" exact>
                          <Club />
                        </Route>
                        {/* <Route path="/clubs/club-new" exact>
                          <ClubNew />
                        </Route> */}
                        <Route path="/clubs/:id" exact>
                          <ClubInfo />
                        </Route>

                        <Route path="/player-invite/:id" exact>
                          <PlayerNew />
                        </Route>

                        <Route path="/season" exact>
                          <Season />
                        </Route>

                        <Route path="/createseason" exact>
                          <CreateSeason />
                        </Route>

                        <Route path="/season/:id" exact>
                          <EditSeason />
                        </Route>

                        {/* <ProtectedRoute path="/club_details" isAuth={isAuth} component={ClubInfo} /> */}

                        <Route path="/credits" exact>
                          <Credit />
                        </Route>

                        <Route path="/createcredit" exact>
                          <CreateCredit />
                        </Route>

                        <Route path="/refunds" exact>
                          <Refund />
                        </Route>

                        <Route path="/refund-details" exact>
                          <RefundDetails />
                        </Route>

                        <Route path="/create-refund" exact>
                          <CreateRefund />
                        </Route>

                        <Route path="/report" exact>
                          <Reports />
                        </Route>

                        <Route path="/editteam/:id1/:id" exact>
                          <EditOrgTeam />
                        </Route>

                        <Route path="/changepassword" exact>
                          <ChangePassword />
                        </Route>

                        <Route path="/merchant" exact>
                          <MerchandOrg />
                        </Route>

                        <Route path="/SignedWaiver" exact>
                          <SignedWaiver />
                        </Route>

                        <Route path="/LiablityTerms" exact>
                          <LiabilityTermsList />
                        </Route>

                        <Route path="/merchant/create-account" exact>
                          <MerchandAccountOrg />
                          {/* <Merchandaccount /> */}
                        </Route>

                        {/* <Route path="*">
                        <PageNoteFound/>
                      </Route> */}

                        <Route path="/registernow/:id" exact>
                          <React.Fragment>
                            <div className="main-panel">
                              <div className="content-wrapper">
                                <div className="justify-content-center align-item-center d-flex">
                                  <h3>
                                    For this link you have switch to Club
                                    Account.
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        </Route>
                        <Route path="/switch">
                          <Redirect to="/switch" />
                        </Route>
                        <Route path="/modifiedpassword">
                          <Redirect to="/modifiedpassword" />
                        </Route>

                        <Route path="/register-by-invitation/:id" exact>
                          <SignupByMail />
                        </Route>

                        <ProtectedRoute
                          path="/add-account"
                          isAuth={isAuth}
                          type={typeValue}
                          component={AddAccount}
                          exact
                        />
                        <ProtectedRoute
                          path="/add-account/:id"
                          isAuth={isAuth}
                          type={typeValue}
                          component={AddAccountForm}
                          exact
                        />

                        <Route path="/createaccount" exact>
                          <CreateAccount />
                        </Route>

                        <Route path="*">
                          {/* <Redirect to ="/dashboard"/> */}
                          <PageNoteFound />
                        </Route>

                        {/* <Route component={PageNoteFound}/>   */}
                      </Switch>,
                    ]
                  ) : (
                    <>
                      {type == 2
                        ? [
                            // <Switch>
                            //   <Route path="/createaccount" exact>
                            //     <CreateAccount />
                            //   </Route>
                            // </Switch>
                          ]
                        : ""}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

// export default App;
const mapStateToProp = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getAccountDetails: (user) => dispatch(getAccountDetails(user)),
  getEventHandler: (user) => dispatch(getAllEvent(user)),
  getNotificationhandler: (user) => dispatch(getClubNotification(user)),
  getDashBoardPaymentHandler: (user) => dispatch(getDashBoardPayment(user)),
  getClubEventHandler: (user) => dispatch(getAllClubEvent(user)),
  getDashBoardPaymentClubHandler: (user) => dispatch(getDashBoardPayment(user)),
  resetGetPaymentRequest: () => dispatch({ type: RESET_GET_PAYMENT_REQUEST }),
  getNotificationHandler: (user) => dispatch(getClubNotification(user)),
});
export default connect(mapStateToProp, mapDispatchToProps)(App);
// 1155
