import React, { useState, useEffect } from 'react'
import { Link, useHistory, Redirect, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Toaster from '../Toaster/Toaster';
import Spinner from '../Spinner/Spinner';
// import { connect } from 'react-redux';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { forgotpasswordEmail } from '../service/action/userAction';
import { RESET_FORGOT_PASSWORD_EMAIL } from '../service/constants';




const ForgotPassword = (props) => {
    const notify = (msg) => toast(msg);
    const [loading, setLoading] = useState(false)
    let [color, setColor] = useState("#FFFFFF");

    const history = useHistory();

    const [state, setState] = useState({
        email: "",
        
    })

    const [errors, setErrors] = useState(
        {
            emailEr: false,
            
        }
    )

    const forgotpassword=useSelector(state=>state.forgotpasswordEmailReducer)
 

    const signin = (e) => {
        e.preventDefault();
        if (state.email == "") {
            setErrors(prevState => ({
                ...prevState,
                emailEr: "Email can not be empty"
            }))
        }

        if (state.email !== "" ) {
            props.forgotpasswordHandler({
                email: state.email,
            })
        }

    }

    useEffect(() => {
        if (props.action?.loading) {
            setLoading(true)
        } else if (props.action?.success) {
            setLoading(false)
            history.push(`/verifyotp/${state.email}`)

        }else if(props.action?.error){
            setLoading(false)
            notify(forgotpassword.msg)
            props.resetForgotpasswordHandler()
        }

    }, [props.action])




    const eventHandler = (e) => {
        const { id, value, name } = e.target

        const validEmailRegex =
            RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
        switch (name) {
            case "email":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        emailEr: "Email can not be empty"
                    }))
                } else if (!validEmailRegex.test(value)) {
                    setErrors(prevState => ({
                        ...prevState,
                        emailEr: "Email is not valid !"
                    }))
                } else {
                    setErrors(prevState => ({
                        ...prevState,
                        emailEr: false
                    }))
                }
                // setEmailVal(false);
                break;
            // case "password":
            //     if (value.length == 0) {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             passwordEr: "Password can not be empty"
            //         }))
            //     } else {
            //         setErrors(prevState => ({
            //             ...prevState,
            //             passwordEr: false
            //         }))
            //     }
            //     break;
        }
    }


    return (
        <React.Fragment>
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex auth login-section">
                    <div className="row flex-grow">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left p-5">
                                <div className="brand-logo text-center">
                                    <img src="./images/pch-images/PCH_Logo_Hori_Blue Blue-white.svg" alt="logo" />
                                </div>
                                <h6 className="text-white">Forgot Password</h6>
                                <form className="pt-3">
                                    <div className="form-group">
                                        <input type="email" className={classNames("form-control form-control-lg", { "login-error": errors.emailEr })} id="email" name="email" value={state.email} placeholder="Email Address" required onChange={eventHandler} autoComplete="off" />
                                        {errors.emailEr &&
                                            <span className='text-danger small d-block'>* {errors.emailEr}</span>}
                                    </div>
                                    
                                    <div className="mt-3 text-center">
                                        <button type="submit" className="btn btn-login btn-lg font-weight-medium auth-form-btn" onClick={signin} disabled={props.action?.loading} >Submit</button>
                                    </div>
                                   

                                    <div className='d-flex justify-content-center'>

                                        {loading ?<ClipLoader color={color} loading={loading} css={css} size={50} />: null}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Toaster />
                </div>
            </div>

        </React.Fragment>
    )
}

// export default ForgotPassword;

const mapStateToProp = state => ({
    action:state.forgotpasswordEmailReducer
})

const mapDispatchToProps = dispatch => ({
    forgotpasswordHandler:user=>dispatch(forgotpasswordEmail(user)),
    resetForgotpasswordHandler:()=>dispatch({type:RESET_FORGOT_PASSWORD_EMAIL})

})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(ForgotPassword));
