import React, { useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { getAllClub, getGroupDetails, inviteClub, removePlayerfromGroup } from '../../../service/action/userAction';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import Toaster from '../../../Toaster/Toaster';
import { RESET_CREATE_TEAM, RESET_DELETE_PLAYER } from '../../../service/constants';
import { useHistory } from 'react-router-dom';



const GroupDetails = (props) => {
    const history = useHistory()

    const notify = (msg) => toast(msg);


    const { id } = useParams()

    useEffect(() => {
        props.getGroupDetailsHandler({ id: id })
    }, [])


    const removeMsg = useSelector(state => state.removePlayerReducer.message)

    const getGroupPlayer = useSelector(state => state.getAllGroupsReducer.user.data)



    useEffect(() => {
        if (props.submit?.success) {
            notify(removeMsg.message)

            props.resetremovePlayerReducer()
            props.getGroupDetailsHandler({ id: id })

        } else if (props.submit?.error) {
            notify("Error ")
        }
    }, [props.submit])


    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <div className="create-team-head">
                            <h5 className="dash-head mr-3">{getGroupPlayer !== undefined ? getGroupPlayer.group_name : null}</h5>
                            <div className="d-flex align-items-center">
                                <div className="search-section">
                                    <i className="mdi mdi-magnify search-ico"></i>
                                    <input type="text" className="form-control" placeholder="Search" />
                                </div>

                                <div>
                                    <i className="mdi mdi-filter-outline filter-ico"></i>
                                </div>
                            </div>

                        </div>
                    </div>


                    {/* <<<<<<<----------desktop view--------->>>>>>> */}

                    <div className="table-responsive d-none d-md-block">
                        <table className="table event-table mt-30">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Phone No.</th>
                                    <th>Email</th>
                                    <th>DOB</th>
                                    <th></th>
                                </tr>
                            </thead>
                            {getGroupPlayer ? <>

                                <tbody>
                                    {
                                        getGroupPlayer.player.map((v, i) => {
                                            return <tr key={i}>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="dashboard-img">
                                                            <img src={v.profile_image !== "" ? v.profile_image : "./images/pch-images/pch.svg"} />
                                                        </div>
                                                        <span className="ml-12">{v.first_name}</span>
                                                    </div>
                                                </td>
                                                <td>{v.mobile_number}</td>
                                                <td>{v.email}</td>
                                                <td>{v.dob}</td>
                                                <td>
                                                    <Link to="#" className="text-decoration-none" onClick={() => props.removePlayerHandler({ id: id, id1: v._id })}>
                                                        <i className="mdi mdi-minus text-red mr-2 website-ico"></i>
                                                        <span className="text-red" >Remove</span></Link>
                                                </td>
                                            </tr>
                                        })
                                    }


                                </tbody>
                            </>
                                : <><h4>Loading...</h4></>}
                        </table>

                    </div>


                    {/* mobile view  */}
                    <div className="mt-30 d-md-none">
                        {
                            getGroupPlayer !== undefined ? <>

                                <div className="mt-4">
                                    {
                                        getGroupPlayer.player.map((v, i) => {

                                            return <div className="d-flex justify-content-between reg-details" key={v._id}>
                                                <div className="d-flex">
                                                    <div className="dashboard-img mr-3">
                                                        <img src={v.profile_image !== "" ? v.profile_image : "./images/pch-images/pch.svg"} />
                                                    </div>
                                                    <div className="reg-content">
                                                        <Link className="text-decoration-none text-blue fw-m" to="/event-details">{v.first_name}</Link>
                                                        <span className="d-block text-gray mt-2">Phone No.: {v.mobile_number} </span>
                                                        <span className="d-block text-gray mt-2">Email: {v.email}</span>
                                                        <span className="d-block text-gray mt-2">DOB: {v.dob}</span>

                                                    </div>
                                                </div>
                                                <div className="dropdown event-drp">
                                                    <Link to="#" className="text-decoration-none" onClick={() => props.removePlayerHandler({ id: id, id1: v._id })}>
                                                        <i className="mdi mdi-minus text-red mr-2 website-ico" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                    </Link>

                                                </div>

                                            </div>
                                        })
                                    }

                                </div>
                            </> : null
                        }
                    </div>
                    <Toaster />
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    submit: state.removePlayerReducer
    // getAllPayment: state.getAllPaymentCodeReducer,
})

const mapDispatchToProps = dispatch => ({
    getAllClubHandler: user => dispatch(getAllClub(user)),
    inviteclub: user => dispatch(inviteClub(user)),
    resetremovePlayerReducer: () => dispatch({ type: RESET_DELETE_PLAYER }),
    getGroupDetailsHandler: user => dispatch(getGroupDetails(user)),
    removePlayerHandler: user => dispatch(removePlayerfromGroup(user))
    // userDataHandler: user => dispatch(userData(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GroupDetails))