import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { connect, useSelector } from "react-redux";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import {
  getAccountDetails,
  getMerchantOrg,
  resetReduxStore,
  signout,
} from "../../../service/action/userAction";
import {
  RESET_ACCOUNT_DETAILS,
  RESET_ALL_REPORT,
  RESET_GET_CREDIT,
  RESET_GET_PAYMENT_REQUEST,
  RESET_OUTSTANDING_REPORT,
  RESET_RECEIVE_REPORT,
  RESET_REFUND_REPORT,
  RESET_SIGNIN,
} from "../../../service/constants";
import Spinner from "../../../Spinner/Spinner";

const OrgAccount = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const getUserDetails = props.getAccountDetails;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserDetails({ id: id });
  }, [getUserDetails]);


  const profileImage = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (props.accountDetails?.success) {
      setLoading(false);
    }
  }, [props.accountDetails]);

  useEffect(() => {
    if (profileImage) {
      setImage(
        profileImage.profile_image !== ""
          ? profileImage.profile_image
          : "./images/pch-images/pch.svg"
      );
    }
  }, [props.accountDetails]);

  const submit = () => {
    confirmAlert({
      title: "Confirm to sign out",
      message: "Are you sure you want to sign out?",
      buttons: [
        {
          label: "Yes",
          onClick: () => alert("Click Yes"),
          //   style:{backgroud-co    lor:"#143D4A"}
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
      customUI: ({ onClose }) => {
        return (
          <div id="react-confirm-alert">
            <div
              className="react-confirm-alert-overlay"
              style={{ background: "rgba(255, 255, 255, 0.1)" }}
            >
              <div className="react-confirm-alert">
                <div className="react-confirm-alert-body">
                  <h2 style={{ color: "#143D4A" }}>Confirm to sign out</h2>
                  Are you sure you want to sign out?
                  <div className="react-confirm-alert-button-group">
                    <button
                      style={{ background: "#143D4A" }}
                      onClick={() => {
                        signout();
                        onClose();
                      }}
                    >
                      Yes
                    </button>
                    <button
                      style={{ background: "#143D4A" }}
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const signout = () => {
    // alert("outer logout")
    // if (props.signout?.success) {
    // alert("logout")

    props.resetAccountDetails();
    props.signoutHandler();

    localStorage.removeItem("token");
    localStorage.removeItem("status");
    localStorage.removeItem("club");
    localStorage.removeItem("PaymentCodeId");
    localStorage.removeItem("auth");
    localStorage.clear();
    sessionStorage.removeItem("link");
    sessionStorage.clear();
    props.resetSigninHandler();
    props.resetRedux();
    props.resetGetPaymentRequest();

    history.push("/");
  };

  useEffect(() => {
    if (props.signout?.success) {
    }
  }, [props.signout]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head">
              <h5 className="dash-head">Account</h5>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>

          {!loading && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="text-left mt-30">
                    <div className="basic-info">
                      {/* <input type="file" className="input-file file-upload" id="file-upload" /> */}
                      <label className="change-pic" htmlFor="file-upload">
                        {/* <img src={image ==null || "" ? "./images/faces/face3.jpg": image  } className="img-fluid" /> */}
                        <img src={image} className="img-fluid" />
                      </label>
                      <div className="ml-18 account">
                        <span className="d-block account-name">
                          {localStorage.getItem("username")}
                        </span>
                        <span className="d-block account-type">
                          Account Owner
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="text-left mt-30">
                    <Link to="/account-details" className="account-tab">
                      <div className="account-type-section">
                        Account Details
                        <i className="mdi mdi-chevron-right"></i>
                      </div>
                    </Link>

                    {/* <Link to="/permission/:id" className="account-tab"> */}

                    <div
                      // className="col-md-6"
                      style={{
                        padding: "15px",
                        borderBottom: "1px solid #eaeaea",
                        paddingLeft: "0px",
                      }}
                    >
                      <div className="btn-group">
                        <div
                          type="button"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          id="dropdownMenuButton"
                        >
                          Permissions
                        </div>
                        <div className="dropdown-menu " id="dropdownMenuButton">
                          {/* <button className="dropdown-item" type="button">Action</button> */}
                          <Link
                            to="/admin-permission"
                            className="text-decoration-none"
                          >
                            <button className="dropdown-item" type="button">
                              Admin
                            </button>
                          </Link>
                          <Link
                            to="/staff-permission"
                            className="text-decoration-none"
                          >
                            <button className="dropdown-item" type="button">
                              Staff
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* </Link> */}

                    {/* <Link to="/account-users" className="account-tab">
                                            <div className="account-type-section">
                                                Account Users
                                                <i className="mdi mdi-chevron-right" ></i>
                                            </div>
                                        </Link> */}

                    <Link to={`/merchant`} className="account-tab">
                      <div className="account-type-section">
                        Merchant Account
                        <i className="mdi mdi-chevron-right"></i>
                      </div>
                    </Link>

                    {/* <Link className="account-tab" to="/merchant">
                                            <div className="account-type-section">
                                                Merchant Account
                                                <i className="mdi mdi-chevron-right" ></i>
                                            </div>
                                        </Link> */}

                    <Link to="/season" className="account-tab">
                      <div className="account-type-section">
                        Season
                        <i className="mdi mdi-chevron-right"></i>
                      </div>
                    </Link>

                    {/* <Link className="account-tab" to="/account-staff-list">
                                            <div className="account-type-section">
                                                Staff
                                                <i className="mdi mdi-chevron-right" ></i>
                                            </div>
                                        </Link> */}
                    {/* <Link className="account-tab" to="#">
                                            <div className="account-type-section">
                                                Permission
                                                <i className="mdi mdi-chevron-right" ></i>
                                            </div>
                                        </Link> */}
                    {/* <Link className="account-tab" to="/createliability">
                                            <div className="account-type-section">
                                                Liability waiver
                                                <i className="mdi mdi-chevron-right" ></i>
                                            </div>
                                        </Link> */}

                    <div
                      // className="col-md-6"
                      style={{
                        padding: "15px",
                        borderBottom: "1px solid #eaeaea",
                        paddingLeft: "0px",
                      }}
                    >
                      <div className="btn-group">
                        <div
                          type="button"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          id="dropdownMenuButton"
                        >
                          Liability waiver
                        </div>
                        <div className="dropdown-menu " id="dropdownMenuButton">
                          {/* <button className="dropdown-item" type="button">Action</button> */}
                          <Link to={`/SignedWaiver`}>
                            <button className="dropdown-item" type="button">
                              Signed Waiver
                            </button>
                          </Link>
                          <Link to="/LiablityTerms">
                            <button className="dropdown-item" type="button">
                              Libility Terms List
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>

                    {/* <Link className="account-tab" to="/report">
                                            <div className="account-type-section">
                                                Reports
                                                <i className="mdi mdi-chevron-right" ></i>
                                            </div>
                                        </Link> */}

                    <Link className="account-tab" to="/changepassword">
                      <div className="account-type-section">
                        Change Password
                        <i className="mdi mdi-chevron-right"></i>
                      </div>
                    </Link>
                    <Link to="/remove-account" className="account-tab">
                      <div className="account-type-section">
                        Settings
                        {/* <i className="mdi mdi-chevron-right" ></i> */}
                      </div>
                    </Link>
                    {/* <Link to="/account-details" className="account-tab"> */}
                    <div
                      className="account-type-section"
                      onClick={submit}
                      style={{ cursor: "pointer" }}
                    >
                      Sign out
                      {/* <i className="mdi mdi-chevron-right" ></i> */}
                    </div>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(OrgAccount);

const mapStateToProp = (state) => ({
  accountDetails: state.accountDetailsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAccountDetails: (user) => dispatch(getAccountDetails(user)),
  signoutHandler: (user) => dispatch(signout(user)),
  resetSigninHandler: () => dispatch({ type: RESET_SIGNIN }),
  getAccountDetails: (user) => dispatch(getAccountDetails(user)),
  resetAccountDetails: () => dispatch({ type: RESET_ACCOUNT_DETAILS }),
  resetRedux: () => dispatch(resetReduxStore()),
  reset_getCreditReducer: () => dispatch({ type: RESET_GET_CREDIT }),
  resetReceive: () => dispatch({ type: RESET_RECEIVE_REPORT }),
  resetOutStanding: () => dispatch({ type: RESET_OUTSTANDING_REPORT }),
  resetRefund: () => dispatch({ type: RESET_REFUND_REPORT }),
  resetAllreport: () => dispatch({ type: RESET_ALL_REPORT }),
  resetGetPaymentRequest: () => dispatch({ type: RESET_GET_PAYMENT_REQUEST }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(OrgAccount));
