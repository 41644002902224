import React from "react";

const RefundDetails = (props) => {
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">React</div>
      </div>
    </React.Fragment>
  );
};

export default RefundDetails;
