import { FINDUSER_BYEMAIL_SUCCESS, FINDUSER_ERROR, FINDUSER_LOADING, FINDUSER_SUCCESS, RESET_GET_FINDUSER } from "../../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
  data:[]
};
export default function findUserReducer(state = initialState, action) {
  switch (action.type) {
    case FINDUSER_LOADING:
      return { ...state, success: false, loading: true  ,success: false };
      
    case FINDUSER_SUCCESS:
      return { ...state, loading: false, success: true, user: action.user.data };

    case FINDUSER_ERROR:
      return { ...state, error: true, loading: true  , success : false ,errors: action.errors };

    case RESET_GET_FINDUSER:
      return { ...state, error: false, success:false, loading:false };

    default:
      return state;
  }
}
// export function findByEmailUserReducer(state = initialState, action) {
//   switch (action.type) {
//     case FINDUSER_LOADING:
//       return { ...state,loading: true, success: false, success: false };

//     case FINDUSER_SUCCESS:
//       return { ...state, loading: false, success: true, user: action.payload.data };
     
//     case FINDUSER_ERROR:
//       return { ...state, error: true,loading :true , success : false ,errors: action.errors };

//     case RESET_GET_FINDUSER:
//       return { ...state, error: false, success:false, loading:false };

//     default:
//       return state;
//   }
// }
