import React from "react";
import Toaster from "../../../Toaster/Toaster";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  updatefieldmapAction,
  viewfieldmapAction,
} from "../../../service/action/userAction";
import { RESET_UPDATE_FIELD_MAP } from "../../../service/constants";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Form } from "react-bootstrap";

const Editfieldmap = (props) => {
  const { id, id1 } = useParams();
  const notify = (msg) => toast(msg);
  const [Image, setImage] = useState(null);
  const [prevImg, setPrevImg] = useState();
  const history = useHistory();
  const [currentfieldmap, setcurrentfieldmap] = useState();

  const [state, setstate] = useState({
    URLtext: "",
    image: "",
  });

  const [error, seterror] = useState({
    image: false,
  });
  useEffect(() => {
    props.viewfieldmapActionhandler({ id: id });
  }, []);

  useEffect(() => {
    if (props.viewfieldmapReducer?.success) {
      const data = props.viewfieldmapReducer?.user?.find((e) => e?._id === id1);
      setcurrentfieldmap(data);
      setstate((preve) => ({
        ...preve,
        image: data?.file ? data?.file : "",
      }));
      setImage(data?.file);
      setPrevImg(data.file);
    }
  }, [props.viewfieldmapReducer]);

  useEffect(() => {
    if (props.updatefieldmapReducer?.success) {
      notify(props.updatefieldmapReducer?.message?.message);
      history.push(`/event-details/${id}`);
    }
  }, [props.updatefieldmapReducer]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "image") {
      setstate((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setImage(e.target.files[0]);
      setPrevImg(
        e.target.files?.length !== 0 && URL.createObjectURL(e.target.files[0])
      );
    }

    switch (e.target.name) {
      case "image":
        if (value == "") {
          seterror((prevState) => ({
            ...prevState,
            image: "image can not be empty",
          }));
        } else if (!RegExp(/^image/gim).test(e.target.files[0].type)) {
          seterror((prevState) => ({
            ...prevState,
            image: "Only accept image file",
          }));
        } else {
          seterror((prevState) => ({
            ...prevState,
            image: false,
          }));
          // document.getElementById("image").classList.remove("error");
        }
        break;

      default:
    }
  };
  const urlToFile = (url, filename, mimeType) => {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => new File([blob], filename, { type: mimeType }));
  };

  const handlecreate = () => {
    if (state.image === "") {
      seterror((preve) => ({
        ...preve,
        image: "Enter Text",
      }));
    }

    if (state.image !== "") {
      if (prevImg == Image) {
        const fileName = Image.substring(Image.lastIndexOf("/") + 1);
        urlToFile(Image, fileName, "image/jpeg").then((file) => {
          props.updatefieldmapActionhandler({
            event_id: id ? id : "",
            file: file,
            type: 1,
            fieldMapId: id1,
          });
        });
      } else {
        props.updatefieldmapActionhandler({
          event_id: id ? id : "",
          file: Image,
          type: 1,
          fieldMapId: id1,
        });
      }
    }
  };

  return (
    <>
      {" "}
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="">
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: "bold" }}>Document </Form.Label>
              <div
                className={classNames("custom-file-upload", {
                  errorBorder: error.image,
                })}
              >
                <input
                  type="file"
                  name="image"
                  id="image"
                  accept="image/*"
                  label="image"
                  onChange={handleChange}
                />
                <label htmlFor="image">
                  {prevImg ? (
                    <div className="uploader-prev">
                      <img
                        className="img-fluid"
                        alt="droped image"
                        src={prevImg}
                      />
                      <span className="replace-btn">Replace</span>
                    </div>
                  ) : (
                    <div className="upload-placeholder text-center">
                      <i
                        className="fa fa-cloud-upload fa-3x"
                        aria-hidden="true"
                      ></i>
                      <h3>Upload Document</h3>
                    </div>
                  )}
                </label>
              </div>
            </Form.Group>
          </div>
          <button className="mt-4 btn-save" onClick={handlecreate}>
            Submit
          </button>
        </div>
      </div>
      <Toaster />
    </>
  );
};

const mapStateToProp = (state) => ({
  updatefieldmapReducer: state.updatefieldmapReducer,
  viewfieldmapReducer: state.viewfieldmapReducer,
});

const mapDispatchToProps = (dispatch) => ({
  updatefieldmapActionhandler: (user) => dispatch(updatefieldmapAction(user)),
  viewfieldmapActionhandler: (user) => dispatch(viewfieldmapAction(user)),
  resetupdatefieldmap: () => dispatch({ type: RESET_UPDATE_FIELD_MAP }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Editfieldmap));
