import React from 'react';
import { Link, withRouter } from 'react-router-dom';

const OrgPaymentReq = () => {

    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <div className="create-team-head-space">
                            <h5 className="dash-head">Payment</h5>
                            <Link to="/new-payment-request" className='text-decoaration-none'>
                                <button className="btn-create-new"> Create New</button>
                            </Link>
                        </div>
                    </div>

                    <div className="text-left mt-30">
                        <Link to="/allpaymentrequests" className="account-tab">
                            <div className="account-type-section">
                                All Payment
                                <i className="mdi mdi-chevron-right right-arrow" ></i>
                            </div>
                        </Link>

                        {/* <Link to="/paymentoutsta" className="account-tab">
                            <div className="account-type-section">
                                Payment Outstanding
                                <i className="mdi mdi-chevron-right right-arrow" ></i>
                            </div>
                        </Link>

                        <Link to="/paymentreceived" className="account-tab">
                            <div className="account-type-section">
                                Payment Receive
                                <i className="mdi mdi-chevron-right right-arrow" ></i>
                            </div>
                        </Link>

                        <Link to="/paymentrefunded" className="account-tab">
                            <div className="account-type-section">
                                Payment Refund
                                <i className="mdi mdi-chevron-right right-arrow" ></i>
                            </div>
                        </Link> */}

                        {/* <Link to="#" className="account-tab">
                            <div className="account-type-section">
                                Payment term list
                                <i className="mdi mdi-chevron-right right-arrow" ></i>
                            </div>
                        </Link> */}

                        {/* <Link to="#" className="account-tab">
                            <div className="account-type-section">
                                Refunds
                                <i className="mdi mdi-chevron-right right-arrow" ></i>
                            </div>
                        </Link> */}
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default withRouter(OrgPaymentReq);