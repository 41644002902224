import {  RESET_SEND_COACH_INVITATION, SEND_COACH_INVITATION_ERROR, SEND_COACH_INVITATION_LOADING, SEND_COACH_INVITATION_SUCCESS} from "../../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function sendInvitationToCoachReducer(state = initialState, action) {
  switch (action.type) {

    case SEND_COACH_INVITATION_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case SEND_COACH_INVITATION_SUCCESS:
      return { ...state, error: false, loading: false, success: true,message:action.msg };

    case SEND_COACH_INVITATION_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: action.errors,msg:action.errors }

    case RESET_SEND_COACH_INVITATION:
        return {...state,error:false,loading:false,success:false,errors:[],user:[{}]}  


    default:
      return state;
  }
}

