import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  createLiability,
  getOrgLiability,
} from "../../service/action/userAction";
import { RESET_CREATE_LIABILITY } from "../../service/constants";
import Toaster from "../../Toaster/Toaster";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import classNames from "classnames";

const Createliability = (props) => {
  const history = useHistory();
  const [state, setState] = useState({
    title: "",
    liability: "",
    mark_default: 0,
  });
  const [errors, setErrors] = useState({
    title: false,
    liability: false,
    mark_default: false,
  });

  useEffect(() => {
    props.getLiabilityHandler();
  }, []);

  const dataChange = (e) => {
    const { value, name } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    switch (name) {
      case "title":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            title: "Title can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            title: false,
          }));
        }
        break;

      default:
    }
  };

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setState((prev) => ({
      ...prev,
      liability: data,
    }));

    if (data == "") {
      setErrors((prevState) => ({
        ...prevState,
        liability: "liability can not be empty",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        liability: false,
      }));
    }
  };

  const msg = useSelector((state) => state.createLiabilityReducer.message);
  const liability = useSelector(
    (state) => state.getPrintOrgLiabilityReducer.user.data
  );

  useEffect(() => {
    if (liability) {
      setState((prev) => ({
        ...prev,
        liability: liability.liability ?? "",
      }));
    }
  }, [liability]);

  const notify = (msg) => toast(msg);

  const submitRequest = (e) => {
    e.preventDefault();
    if (state.title == "") {
      setErrors((prevState) => ({
        ...prevState,
        title: "Title can not be empty",
      }));
      notify("Title can not be empty");
    } else if (state.liability == "") {
      setErrors((prevState) => ({
        ...prevState,
        liability: "liability can not be empty",
      }));
      notify("liability can not be empty");
    }

    if (
      state.title != "" &&
      state.liability != "" &&
      !errors.title &&
      !errors.liability
    ) {
      props.createLiabilityHandler({
        title: state.title,
        liability: state.liability,
        mark_as_default: state.mark_default,
      });
    }
  };

  useEffect(() => {
    if (props.submit?.success) {
      notify(msg.message);
      props.resetCreateLiabilityReducer();
      history.goBack();
    }
  }, [props.submit]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Create New Liability</h5>
          </div>
          <form>
            <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-10 px-0">
              <div className="mt-24">
                <input
                  type="text"
                  className={classNames("form-control team-name", {
                    error: errors.title,
                  })}
                  placeholder="Title *"
                  id="title"
                  value={state.title}
                  name="title"
                  onChange={dataChange}
                />
                {/* {errors.title && (
                    <span className="text-red small d-block">
                      * {errors.title}
                    </span>
                  )} */}
              </div>

              <div className="mt-24">
                <div
                  name="terms_condition"
                  id="terms_condition"
                  className={classNames({
                    error: errors.liability,
                  })}
                >
                  <CKEditor
                    editor={ClassicEditor}
                    data={state.liability}
                    onChange={handleChange}
                  />
                  {/* {errors.terms_condition && (
                      <span className="text-red small d-block">
                        * {errors.terms_condition}
                      </span>
                    )} */}
                </div>
              </div>
              <div className="mt-4">
                <input
                  type="checkbox"
                  // className={classNames("mr-2", {
                  //   error: errors.mark_default,
                  // })}
                  className="mr-2"
                  name="mark_default"
                  value={state.mark_default}
                  onChange={dataChange}
                />
                <label htmlFor="paymentProcessing">Mark as default</label>
              </div>
              <div className="mt-3 text-left">
                <a
                  className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                  onClick={submitRequest}
                >
                  Submit
                </a>
              </div>
            </div>
          </form>
          <Toaster />
        </div>
      </div>
    </>
  );
};

// export default withRouter(CreateInvoice)

const mapStateToProp = (state) => ({
  submit: state.createLiabilityReducer,
  liability: state.getPrintOrgLiabilityReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createLiabilityHandler: (user) => dispatch(createLiability(user)),
  resetCreateLiabilityReducer: () => dispatch({ type: RESET_CREATE_LIABILITY }),
  getLiabilityHandler: () => dispatch(getOrgLiability()),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Createliability));
