import { CREATE_TEAM_ERROR, CREATE_TEAM_LOADING, CREATE_TEAM_SUCCESS, RESET_CREATE_TEAM } from "../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}

};

export default function createTeamReducer(state = initialState, action) {
  switch (action.type) {

    case CREATE_TEAM_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case CREATE_TEAM_SUCCESS:
      return { ...state, error: false, loading: false, success: true, message: action.msg };

    case CREATE_TEAM_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: action.errors, msg: action.errors }

    case RESET_CREATE_TEAM:
      return { ...state, error: false, loading: false, success: false, errors: [], user: [{}] }

    default:
      return state;
  }
}

