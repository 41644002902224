import { CREATE_SEASON_LOADING, CREATE_SEASON_ERROR, CREATE_SEASON_SUCCESS, GET_ALL_SEASON_ERROR, GET_ALL_SEASON_LOADING, GET_ALL_SEASON_SUCCESS, RESET_GET_ALL_SEASON } from "../../constants"

let initialState = {
  loading: false,
  user: [{
    "status": '',
    "isDeleted": '',
    "deletedAt": "",
    "_id": "",
    "user_id": "",
    "user_type": '',
    "season_name": "",
    "createdAt": "",
    "updatedAt": "",
    "__v": ''
  }],
  error: false,
  errors: [],
  success: false,
  payload: {}
};

export default function getAllSeasonReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SEASON_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false};
      // return { ...state, success: false, success: false };

    case GET_ALL_SEASON_SUCCESS:
      // alert(" Season reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };

    case GET_ALL_SEASON_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    case RESET_GET_ALL_SEASON:
      return { ...state, error: false, loading: false, success: false , data: [], payload:[]}

    case CREATE_SEASON_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case CREATE_SEASON_SUCCESS:
      return { ...state, error: false, loading: false, success: true };

    case CREATE_SEASON_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: [] }

    default:
      return state;
  }
}


