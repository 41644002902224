import { RESET_VIEW_STAY_AND_PLAY, VIEW_STAY_AND_PLAY_ERROR, VIEW_STAY_AND_PLAY_LOADING, VIEW_STAY_AND_PLAY_SUCCESS } from "../../constants";

    let initialState = {
    loading: false,
    user: [],
    error: false,
    errors: [],
    success: false,
  };
  
  export default function ViewStayandPlayReducer(state = initialState, action) {
    switch (action.type) {
      case VIEW_STAY_AND_PLAY_LOADING:
        return { ...state, success: false, success: false };
  
      case VIEW_STAY_AND_PLAY_SUCCESS:
        return { ...state, loading: false, success: true, user: action.user.data };
  
      case VIEW_STAY_AND_PLAY_ERROR:
        return { ...state, error: true, errors: action.errors };
  
      case RESET_VIEW_STAY_AND_PLAY:
        return {
          ...state,
          loading: false,
          user: [],
          error: false,
          errors: [],
          success: false,
        };
  
      default:
        return state;
    }
  }
  