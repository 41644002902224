import {
  RESET_UPDATE_SCHEDULE,
  UPDATE_SCHEDULE_ERROR,
  UPDATE_SCHEDULE_LOADING,
  UPDATE_SCHEDULE_SUCCESS,
} from "../constants";

let initialState = {
  error: false,
  success: false,
  loading: false,
  errors: [],
};

export default function updateScheduleReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SCHEDULE_LOADING:
      return { ...state, error: false, loading: true, success: false };
    case UPDATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        message: action.msg,
      };
    case UPDATE_SCHEDULE_ERROR:
      return {
        ...state,
        error: true,
        errors: [],
        success: false,
        loading: false,
      };
    case RESET_UPDATE_SCHEDULE:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        errors: [],
      };
    default:
      return state;
  }
}
