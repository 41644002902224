import { EDIT_POOL_ERROR, EDIT_POOL_LOADING, EDIT_POOL_SUCCESS, RESET_EDIT_POOL } from "../../constants";

let initialState={
    error:false,
    success:false,
    loading:false,
    errors:[]
}

export default function editPoolReducer(state=initialState,action){
    switch(action.type){
        case EDIT_POOL_LOADING:
            return{...state,error:false,loading:true,success:false};
        case EDIT_POOL_SUCCESS:
            return{...state,error:false,success:true,loading:false,message:action.msg};
        case EDIT_POOL_ERROR:
            return{...state,error:true,success:false,loading:false};
        case RESET_EDIT_POOL:
                return { ...state, error: false, success: false, loading: false, errors: [] }
        default:
            return state;
    }
}