import { GET_CLUB_TEAMBY_ID_ERROR, GET_CLUB_TEAMBY_ID_LOADING, GET_CLUB_TEAMBY_ID_SUCCESS } from "../../../constants";


let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function getTeamByIdReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLUB_TEAMBY_ID_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case GET_CLUB_TEAMBY_ID_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };
     
    case GET_CLUB_TEAMBY_ID_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}
