import { ADD_ROSTER_ERROR, ADD_ROSTER_LOADING, ADD_ROSTER_SUCCESS, RESET_ADD_ROSTER } from "../../../constants";

let initialState = {
 error: false,
 success: false,
 loading: false,
 errors: []
}

export default function addSelectedRosterReducer(state = initialState, action) {
 switch (action.type) {
  case ADD_ROSTER_LOADING:
   return { ...state, error: false, loading: true, success: false };
  case ADD_ROSTER_SUCCESS:
   return { ...state, error: false, success: true, loading: false, message: action.data };
  case ADD_ROSTER_ERROR:
   return { ...state, error: true, success: false, loading: false };
  case RESET_ADD_ROSTER:
   return { ...state, error: false, success: false, loading: false };
  default:
   return state;
 }
}