import { ACCEPT_COACH_ERROR, ACCEPT_COACH_LOADING, ACCEPT_COACH_SUCCESS,RESET_ACCEPT_COACH } from "../../../constants";

let initialState={
    error:false,
    success:false,
    loading:false,
    errors:[]
}

export default function acceptCoachReducer(state=initialState,action){
    switch(action.type){
        case ACCEPT_COACH_LOADING:
            return{...state,error:false,loading:true,success:false}
        case ACCEPT_COACH_SUCCESS:
            return {...state,error:false,loading:false,success:true,message:action.msg}
        case ACCEPT_COACH_ERROR:
            return {...state,error:true,errors:[],success:false,loading:false}
        case RESET_ACCEPT_COACH:
            return {...state,error:false,loading:false,success:false,errors:[]}  
        default:
            return state;
    }
}