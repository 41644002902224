import React from 'react';

const AccountUser = () => {

    var userData = [
        { name: "Username", img: "" },
        { name: "Username", img: "" },
        { name: "Username", img: "" },
        { name: "Username", img: "" },
        { name: "Username", img: "" },
    ];
    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <div className="create-team-head">
                            <h5 className="dash-head">Account Users</h5>
                            <div className="mt-3 mt-md-0 text-right">
                                {/* <button className="btn-create-new">  New</button> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 text-left user-content mt-md-0 mt-4">
                            <span className="rep">Rep. Type 1</span>
                        </div>
                    </div>

                    <div className="d-flex">                      
                        <div className="row">
                            {
                                userData.map((v, i) => {
                                    return <div className="col-lg-2 col-md-3 col-6" key={i}>
                                        <div className="user-profile mt-18 mr-18">
                                            <div >
                                                <div className="img-section">
                                                    <img src="./images/faces/face3.jpg" className="img-fluid" />
                                                </div>
                                                <span className="text-center">{v.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>


                    <div className="row mt-30">
                        <div className="col-md-4 text-left user-content">
                            <span className="rep">Rep. Type 2</span>
                        </div>
                    </div>

                    <div className="user-profile mt-18 mr-18">
                        <div>
                            <div className="img-section">
                                <img src="./images/faces/face3.jpg" className="img-fluid" />
                            </div>
                            <span className="text-center">username</span>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default AccountUser;