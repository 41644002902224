import React, { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";
import { connect } from "react-redux";
import { createOrgAdminGroup } from "../../service/action/userAction";
import { RESET_CREATE_ADMIN_PERMISSION_GROUP } from "../../service/constants";
import classNames from "classnames";

function CreateGroup(props) {
  const location = useLocation();

  const [groupName, setGroupName] = useState("");
  const [groupError, setGroupError] = useState(false);

  const history = useHistory();
  const notify = (msg) => toast(msg);

  const handleCreateGroup = () => {
    if (groupName === "") {
      setGroupError("Required");
    }
    if (groupName !== "") {
      props.createOrgAdminGroup({
        group_name: groupName,
      });
    }
  };

  useEffect(() => {
    if (props.CreateAdminGroupReducer.success) {
      const data = props.CreateAdminGroupReducer.data;
      notify("Group created Successfully");
      props.resetCreateGroup();
      setTimeout(() => {
        history.push(`/admin-permission/${data.group}/permission`);
      }, 3000);
    }
  }, [props.CreateAdminGroupReducer]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="p-3 py-4" style={{ background: "#ffffff" }}>
            <div>
              <div className="" style={{ fontWeight: "bold" }}>
                Add Group Name
              </div>
            </div>
            <div>
              <div className="row mx-0">
                <div className="col-md-5 col-12">
                  <Form.Group className="mb-3">
                    <Form.Label
                      style={{
                        fontWeight: "bold",
                      }}
                    ></Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => {
                        setGroupName(e.target.value);
                        if (e.target.value === "") {
                          setGroupError("Required");
                        } else {
                          setGroupError(false);
                        }
                      }}
                      value={groupName}
                      placeholder="Add Group name"
                      className={classNames({
                        requireField: groupError,
                      })}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="mt-2 justify-content-center border-top-0">
              {/* <button
                onClick={() => {
                  history.push("/admin");
                }}
                className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 rounded mr-2"
              >
                cancel
              </button> */}
              <button
                className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 rounded mr-2"
                onClick={handleCreateGroup}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </React.Fragment>
  );
}

const mapStateToProp = (state) => ({
  CreateAdminGroupReducer: state.OrgPermissionReducer.createAdminGroup,
});

const mapDispatchToProps = (dispatch) => ({
  createOrgAdminGroup: (details) => dispatch(createOrgAdminGroup(details)),
  resetCreateGroup: (user) =>
    dispatch({ type: RESET_CREATE_ADMIN_PERMISSION_GROUP }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(CreateGroup));
