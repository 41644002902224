import { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import { connect } from "react-redux";
import {
  CreatePlayerAction,
  DeletePlayerAction,
  UpdatePlayerAction,
  changeUserStatus,
  getPlayerList,
} from "../../Services/Actions/userAction";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  RESET_CHECK_MAIL_EXIST,
  RESET_CREATE_PLAYER,
  RESET_DELETE_PLAYER,
  RESET_USER_STATUS,
  getDoubleDigitNumber,
  removeStorage,
  validEmailRegex,
  validatePhoneString,
} from "../../Services/constant";
import moment from "moment";
import { CityList, StateList, checkMailIsExist } from "../../Services/Actions";
import classNames from "classnames";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import MaskedInput from "react-text-mask";
import ReactDatePicker from "react-datepicker";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";

function Players(props) {
  const DATA_ITEM_KEY = "_id";
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [xlShow, setXlShow] = useState(false);
  const handleClose = () => setXlShow(false);
  const handleShow = () => setXlShow(true);
  const [selectedDay, setSelectedDay] = useState(null);
  const [GenderStatus, setGenderStatus] = useState();
  const [status, setStatus] = useState({
    status: "",
    isChanged: false,
  });
  const formatInputValue = () => {
    if (!selectedDay) return "";
    return `${selectedDay.day}/${selectedDay.month}/${selectedDay.year} `;
  };

  const [delShow, setDelShow] = useState(false);
  const delDialClose = () => setDelShow(false);
  const delDialShow = () => setDelShow(true);

  const [successShow, setSuccessShow] = useState(false);
  const successDialClose = () => setSuccessShow(false);
  const successDialShow = () => {
    setDelShow(false);
    //     setSuccessShow(true);
    props.deleteplayerHandler({ id: deletedID });
  };
  const [isMailExist, setIsMailExist] = useState(false);
  const [pageDetails, setPageDetails] = useState({
    skip: 0,
    take: 10,
  });

  const [isSameAddress, setIsSameAddress] = useState(false);

  const InitialError = {
    first_name: false,
    middle_name: false,
    last_name: false,
    dob: selectedDay,
    mobile_number: false,
    email: false,
    home_address: {
      address: false,
      cityName: false,
      state: false,
      zip_code: false,
    },
    shipping_address: {
      address: false,
      cityName: false,
      country: false,
      state: false,
      zip_code: false,
    },
    discription: false,
    gender: false,
    secondary_email: false,
    secondary_mobile_number: false,
  };

  const InitialForm = {
    first_name: "",
    middle_name: "",
    last_name: "",
    dob: selectedDay,
    mobile_number: "",
    email: "",
    home_address: {
      address: "",
      cityName: "",
      state: "",
      zip_code: "",
    },
    shipping_address: {
      address: "",
      cityName: "",
      country: "",
      state: "",
      zip_code: "",
    },
    discription: "",
    gender: "",
    secondary_email: "",
    secondary_mobile_number: "",
  };
  const [detailsForm, setDetailsForm] = useState(InitialForm);
  const [errorForm, setErrorForm] = useState(InitialError);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [state, setState] = useState([]);
  const [InitialState, setInitialState] = useState([]);
  const [selectedID, setSelectedId] = useState("");
  const [deletedID, setdeletedID] = useState();

  useEffect(() => {
    props.getPlayerList();
    props.StateList();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (props.stateListReducer.success) {
      const data = props.stateListReducer.data;
      setStateList(data.data);
    }
  }, [props.stateListReducer]);

  useEffect(() => {
    if (
      !!detailsForm?.home_address?.state &&
      detailsForm?.home_address?.state !== ""
    ) {
      props.CityList(detailsForm?.home_address?.state);
    }
  }, [detailsForm?.home_address?.state]);

  useEffect(() => {
    if (
      !!detailsForm?.shipping_address?.state &&
      detailsForm?.shipping_address?.state !== ""
    ) {
      props.CityList(detailsForm?.shipping_address?.state);
    }
  }, [detailsForm?.shipping_address?.state]);

  useEffect(() => {
    if (props.cityListReducer.success) {
      const data = props.cityListReducer.data;
      setCityList(data.data);
    }
  }, [props.cityListReducer]);

  useEffect(() => {
    if (!!isSameAddress) {
      setDetailsForm((pre) => ({
        ...pre,
        shipping_address: {
          address: pre.home_address.address,
          cityName: pre.home_address.cityName,
          state: pre.home_address.state,
          zip_code: pre.home_address.zip_code,
        },
      }));
    } else {
      setDetailsForm((pre) => ({
        ...pre,
        shipping_address: {
          address: "",
          cityName: "",
          state: "",
          zip_code: "",
        },
      }));
    }
  }, [isSameAddress]);

  useEffect(() => {
    if (props.playerListReducer.success) {
      const data = props.playerListReducer.data;
      const _data = data.data.map((e) => {
        return {
          ...e,
          first_name: e.first_name != "" ? `${e.first_name}` : "N/A",
          middle_name: e.middle_name != "" ? `${e.middle_name} ` : "N/A",
          last_name: e.last_name != "" ? ` ${e.last_name}` : "N/A",
          email: !!e.email && e.email !== "" ? e.email : "N/A",
          address: !!e.address && e.address !== "" ? e.address : "N/A",
          city: e.city && e.city !== "" ? e.city : "N/A",
          state: !!e.state ? e.state.name : "N/A",
          stateID: !!e.state ? e.state._id : "N/A",
          zip: e.zip !== "" ? e.zip : "N/A",
          gender: e.gender !== "" ? (e.gender == 0 ? "Male" : "Female") : "N/A",
          mobile_number:
            !!e.mobile_number && e.mobile_number !== ""
              ? e.mobile_number
              : "N/A",
          secondary_mobile_number:
            !!e.secondary_mobile_number && e.secondary_mobile_number !== ""
              ? e.secondary_mobile_number
              : "N/A",
          secondary_email:
            !!e.secondary_email && e.secondary_email !== ""
              ? e.secondary_email
              : "N/A",
        };
      });
      setState(_data);
      setInitialState(_data);
      setLoading(false);
    } else if (props.playerListReducer.error) {
      const errMsg = props.playerListReducer.errorMsg;
      !!errMsg && toast.error(errMsg);
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      }
    }
  }, [props.playerListReducer]);

  useEffect(() => {
    if (props.playerListReducer.success) {
      if (searchVal !== "") {
        const data = props.playerListReducer.data.data;
        const filter = data.filter(
          (e) =>
            e?.name?.toLowerCase().includes(searchVal.toLowerCase()) ||
            e?.email?.toLowerCase().includes(searchVal.toLowerCase()) ||
            e?.first_name?.toLowerCase().includes(searchVal.toLowerCase()) ||
            e?.last_name?.toLowerCase().includes(searchVal.toLowerCase()) ||
            e?.middle_name?.toLowerCase().includes(searchVal.toLowerCase())
        );
        setState(filter);
      } else {
        const data = props.playerListReducer.data.data;
        setState(data);
      }
    }
  }, [searchVal]);

  const customAction = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <button
          onClick={() => {
            const details = cell.dataItem;

            setSelectedId(details._id);
            setDetailsForm({
              ...details,
              first_name:
                details.first_name !== "N/A" ? details.first_name : "",
              middle_name:
                details.middle_name !== "N/A" ? details.middle_name : "",
              last_name: details.last_name !== "N/A" ? details.last_name : "",
              dob:
                !!details.dob && details.dob !== ""
                  ? moment(details.dob, "MM-DD-yyyy").toDate()
                  : null,
              email: details.email !== "N/A" ? details.email : "",
              mobile_number:
                details.mobile_number !== "N/A" ? details.mobile_number : "",
              gender: details.gender == "Male" ? "0" : "1",
              discription:
                details.discription !== "N/A" ? details.discription : "",
              secondary_mobile_number:
                details.secondary_mobile_number !== "N/A"
                  ? details.secondary_mobile_number
                  : "",
              secondary_email:
                details.secondary_email !== "N/A"
                  ? details.secondary_email
                  : "",
            });
            setErrorForm(InitialError);
            handleShow();
          }}
          className="btn-link btn text-secondary p-0 mx-2"
        >
          <i className="ri-pencil-fill"></i>
        </button>
        <button
          onClick={() => {
            const details = cell.dataItem;
            delDialShow();
            setdeletedID(details?._id);
          }}
          className="btn-link btn text-danger p-0 mx-2"
        >
          <i className="ri-delete-bin-fill"></i>
        </button>
      </td>
    );
  };

  const customHeader = (cell) => {
    return <b title={cell.title}>{cell.title}</b>;
  };

  const customStatus = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <Button
          type="button"
          className={classNames("btn-status  btn-primary", {
            active: cell.dataItem.status == 1,
          })}
          onClick={() => {
            setStatus({
              status: cell.dataItem.status == 1 ? "deactivate" : "activate",
              isChanged: true,
            });
            setSelectedId(cell.dataItem._id);
          }}
        >
          {cell.dataItem.status == 1 ? "Active" : "Inactive"}
        </Button>
      </td>
    );
  };

  useEffect(() => {
    if (props.changeUserStatusReducer.success) {
      const _data = state.map((e) => {
        if (e._id == selectedID) {
          return {
            ...e,
            status: e.status == 0 ? 1 : 0,
          };
        } else
          return {
            ...e,
          };
      });
      setStatus({
        status: "",
        isChanged: false,
      });
      setSelectedId("");
      setState(_data);
      toast.success("Status Changed Successfuly");
      props.resetStatus();
    } else if (props.changeUserStatusReducer.error) {
      setSelectedId("");
      toast.success("Get Error To change Status");
      props.resetStatus();
    }
  }, [props.changeUserStatusReducer]);

  const customCell = (cell) => {
    return (
      <td {...cell.tdProps} title={cell.children}>
        {cell.children}
      </td>
    );
  };
  const grid = (
    <Grid
      style={
        {
          // height: "60vh",
        }
      }
      data={state.slice(pageDetails.skip, pageDetails.skip + pageDetails.take)}
      skip={pageDetails.skip}
      take={pageDetails.take}
      total={state.length}
      pageable={{
        buttonCount: 3,
        pageSizes: [5, 10, 15],
      }}
      onPageChange={(e) => {
        setPageDetails(() => ({
          ...e.page,
          take: e.page.take,
        }));
      }}
      dataItemKey={DATA_ITEM_KEY}
    >
      <GridNoRecords>No records are available</GridNoRecords>
      <GridColumn
        field="first_name"
        title="First Name"
        //   width="160"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="middle_name"
        title="Middle Name"
        //   width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="last_name"
        title="Last Name"
        //   width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="email"
        title="Email"
        //   width="150"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="mobile_number"
        title="Phone"
        //   width="110"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="gender"
        title="Gender"
        //   width="110"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="status"
        title="Status"
        width="100"
        headerCell={customHeader}
        cell={customStatus}
      />
      <GridColumn
        field="action"
        title="Actions"
        width="100"
        headerCell={customHeader}
        cell={customAction}
      />
    </Grid>
  );

  const handleForm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "zip") {
      if (value.length <= 6 && !isNaN(value)) {
        setDetailsForm((prev) => ({
          ...prev,
          zip: value,
        }));
      } else {
        return;
      }
    } else if (name == "secondary_mobile_number") {
      if (value.length <= 14) {
        setDetailsForm((prev) => ({
          ...prev,
          secondary_mobile_number: value,
        }));
      } else return;
    } else if (name == "mobile_number") {
      if (value.length <= 14) {
        setDetailsForm((prev) => ({
          ...prev,
          mobile_number: value,
        }));
      } else {
        return;
      }
    } else {
      setDetailsForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    switch (name) {
      case "first_name":
        if (value == "") {
          errorForm["first_name"] = "Required";
        } else {
          errorForm["first_name"] = false;
        }
        break;
      // case "middle_name":
      //   if (value == "") {
      //     errorForm["middle_name"] = "Required";
      //   } else {
      //     errorForm["middle_name"] = false;
      //   }
      //   break;
      case "last_name":
        if (value == "") {
          errorForm["last_name"] = "Required";
        } else {
          errorForm["last_name"] = false;
        }
        break;
      case "email":
        if (value == "") {
          errorForm["email"] = "Required";
        } else if (!validEmailRegex.test(value)) {
          errorForm["Email"] = "Enter Valid Email";
        } else {
          errorForm["email"] = false;
        }
        break;
      case "mobile_number":
        if (value === "") {
          errorForm["mobile_number"] = "Enter Phone Number";
        } else if (!validatePhoneString.test(value) && value != "") {
          errorForm["mobile_number"] = "Enter Valid Phone Number";
        } else if (value.length != 0 && value.length != 12) {
          errorForm["mobile_number"] = "Enter Valid Phone Number";
        } else {
          errorForm["mobile_number"] = false;
        }
        break;
      case "address":
        if (value == "") {
          errorForm["address"] = "Required";
        } else {
          errorForm["address"] = false;
        }
        break;
      case "city":
        if (value == "") {
          errorForm["city"] = "Required";
        } else {
          errorForm["city"] = false;
        }
        break;
      case "state":
        if (value == "") {
          errorForm["state"] = "Required";
        } else {
          errorForm["state"] = false;
        }
        break;
      case "discription":
        if (value == "") {
          errorForm["discription"] = "Required";
        } else {
          errorForm["discription"] = false;
        }
        break;

      case "secondary_email":
        if (value == "") {
          errorForm["secondary_email"] = false;
        } else if (!validEmailRegex.test(value)) {
          errorForm["secondary_email"] = "Enter Valid Email";
        } else {
          errorForm["secondary_email"] = false;
        }
        break;

      case "secondary_mobile_number":
        if (value == "") {
          errorForm["secondary_mobile_number"] = false;
        } else if (value.length < 10) {
          errorForm["secondary_mobile_number"] = "Enter Valid Number";
        } else {
          errorForm["secondary_mobile_number"] = false;
        }
        break;

      default:
        break;
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    if (detailsForm.first_name == "") {
      setErrorForm((prev) => ({
        ...prev,
        first_name: "Required",
      }));
    }
    // if (detailsForm.middle_name == "") {
    //   setErrorForm((prev) => ({
    //     ...prev,
    //     middle_name: "Required",
    //   }));
    // }
    if (detailsForm.mobile_number == "") {
      setErrorForm((prev) => ({
        ...prev,
        mobile_number: "Required",
      }));
    }
    if (detailsForm.last_name == "") {
      setErrorForm((prev) => ({
        ...prev,
        last_name: "Required",
      }));
    }
    if (detailsForm.email == "") {
      setErrorForm((prev) => ({
        ...prev,
        email: "Required",
      }));
    }
    if (detailsForm.address == "") {
      setErrorForm((prev) => ({
        ...prev,
        address: "Required",
      }));
    }
    if (detailsForm.city == "") {
      setErrorForm((prev) => ({
        ...prev,
        city: "Required",
      }));
    }
    if (detailsForm.state == "") {
      setErrorForm((prev) => ({
        ...prev,
        state: "Required",
      }));
    }
    if (detailsForm.discription == "" || detailsForm.discription == undefined) {
      setErrorForm((prev) => ({
        ...prev,
        discription: "Required",
      }));
    }
    if (detailsForm.dob == null) {
      setErrorForm((prev) => ({
        ...prev,
        dob: "Required",
      }));
    }
    const is18Plus =
      detailsForm.dob !== null
        ? moment().diff(detailsForm.dob, "years", false) >= 18
        : false;

    if (!is18Plus) {
      setErrorForm((prev) => ({
        ...prev,
        dob: "Required",
      }));
      toast.error("Age must be 18 years old");
    }

    if (
      detailsForm.first_name !== "" &&
      detailsForm.last_name !== "" &&
      detailsForm.email !== "" &&
      detailsForm.mobile_number !== "" &&
      detailsForm.dob !== null &&
      !errorForm.dob &&
      is18Plus &&
      !errorForm.email &&
      !errorForm.mobile_number &&
      {
        // ...(selectedID &&
        //   detailsForm.address !== "" &&
        //   detailsForm.city !== "" &&
        //   detailsForm.state !== "" &&
        //   detailsForm.discription !== ""),
      }
    ) {
      const details = {
        ParentID: selectedID,
        user_type: 5,
        dob: moment(detailsForm?.dob).format("MM-DD-yyyy"),
        first_name:
          detailsForm.first_name !== "N/A" ? detailsForm.first_name : "",
        middle_name:
          detailsForm.middle_name !== "" ? detailsForm.middle_name : "",
        last_name: detailsForm.last_name !== "N/A" ? detailsForm.last_name : "",
        email: detailsForm.email !== "N/A" ? detailsForm.email : "",
        password: 123456789,
        gender:
          detailsForm.gender !== "N/A" ? parseInt(detailsForm.gender) : "",
        mobile_number:
          detailsForm.mobile_number !== "N/A" ? detailsForm.mobile_number : "",
        ...(selectedID && {
          home_address: detailsForm.home_address.address,
          home_address_city_name: detailsForm.home_address.cityName,
          home_address_state: detailsForm.home_address.state,
          home_address_zip_code: detailsForm.home_address.zip_code,
          shipping_address_address: detailsForm.shipping_address.address,
          shipping_address_state: detailsForm.shipping_address.state,
          shipping_address_city_name: detailsForm.shipping_address.cityName,
          shipping_address_zip_code: detailsForm.shipping_address.zip_code,
          secondary_email: detailsForm.secondary_email,
          secondary_mobile_number: detailsForm.secondary_mobile_number,
        }),
        gender:
          detailsForm.gender !== "N/A" ? parseInt(detailsForm.gender) : "",
        description:
          detailsForm.discription !== "N/A" ? detailsForm.discription : "",
      };
      selectedID == ""
        ? props.createplayerHandler(details)
        : props.updateplayerHandler(details);
    }
  };

  useEffect(() => {
    if (detailsForm.email !== "") {
      if (selectedID != "") {
        const q = InitialState.find((e) => e._id == selectedID).email;
        if (q != detailsForm.email) {
          setTimeout(() => {
            props.checkMailIsExist(detailsForm.email);
          }, 500);
        } else {
          props.resetisCheckEmail();
          setIsMailExist(false);
        }
      } else {
        setTimeout(() => {
          props.checkMailIsExist(detailsForm.email);
        }, 500);
      }
    } else {
      props.resetisCheckEmail();
      setIsMailExist(false);
    }
  }, [detailsForm.email]);

  useEffect(() => {
    if (props.isMailExistReducer.success) {
      setIsMailExist(props.isMailExistReducer.data.status);
    } else if (props.isMailExistReducer.error) {
      const errMsg = props.isMailExistReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.isMailExistReducer]);

  useEffect(() => {
    if (props.createplayerReducer.success) {
      handleClose();
      toast.success("Player created successfully");
      props.ResetCreatePlayer();
      props.getPlayerList();
      setLoading(true);
      setDetailsForm(InitialForm);
      props.resetisCheckEmail();
      setErrorForm(InitialError);
    } else if (props.createplayerReducer.error) {
      const errMsg = props.createplayerReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg?.email) {
          toast.error(errMsg?.email);
        } else {
          toast.error(errMsg);
        }
      }
    }
  }, [props.createplayerReducer]);

  useEffect(() => {
    if (props.updateplayerReducer.success) {
      handleClose();
      toast.success("Parent updated successfully");
      props.ResetCreatePlayer();
      props.getPlayerList();
      setLoading(true);
      setIsSameAddress(false);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
      props.resetisCheckEmail();
    } else if (props.updateplayerReducer.error) {
      const errMsg = props.updateplayerReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.updateplayerReducer]);

  useEffect(() => {
    if (props.deleteplayerReducer.success) {
      handleClose();
      toast.success("Player deleted successfully");
      props.ResetDeletePlayer();
      props.getPlayerList();
      setLoading(true);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
    } else if (props.deleteplayerReducer.error) {
      const errMsg = props.deleteplayerReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.deleteplayerReducer]);

  const handleHomeAddress = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "zip_code") {
      if (value.length <= 5 && !isNaN(value)) {
        setDetailsForm((prev) => ({
          ...prev,
          home_address: {
            ...prev.home_address,
            zip_code: value,
          },
        }));
        if (isSameAddress) {
          setDetailsForm((prev) => ({
            ...prev,
            shipping_address: {
              ...prev.shipping_address,
              zip_code: value,
            },
          }));
        }
      } else return;
    } else {
      setDetailsForm((prev) => ({
        ...prev,
        home_address: {
          ...prev.home_address,
          [name]: value,
        },
      }));
      if (isSameAddress) {
        setDetailsForm((prev) => ({
          ...prev,
          shipping_address: {
            ...prev.shipping_address,
            [name]: value,
          },
        }));
      }
    }
  };

  const handleShippingAddress = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setDetailsForm((prev) => ({
      ...prev,
      shipping_address: {
        ...prev.shipping_address,
        [name]: value,
      },
    }));
  };

  return (
    <>
      {loading ? (
        <>Loading.... </>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <h2 className="page-heading">Players</h2>
          </div>

          <Row className="justify-content-between mx-0">
            <Col md="4" className="search px-0">
              <i className="ri-search-line"></i>
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchVal}
                onChange={(e) => {
                  setSearchVal(e.target.value);
                }}
              />
            </Col>
            <Col md="6" className="text-end px-0">
              <button
                className="btn-secondary px-4 py-2"
                onClick={() => {
                  handleShow();
                  setSelectedId("");
                }}
              >
                Add Player
              </button>
            </Col>
          </Row>
          <div className="table-card">{grid}</div>
          <Modal
            size="xl"
            show={xlShow}
            onHide={() => {
              setDetailsForm(InitialForm);
              setErrorForm(InitialError);
              handleClose();
              setIsMailExist(false);
            }}
          >
            <div
              style={{
                maxHeight: "85vh",
                overflowY: !!selectedID ? "auto" : "initial",
              }}
            >
              <Modal.Header>
                <Modal.Title>Players</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row className="mx-0">
                    <Form.Group as={Col} className="mb-4" controlId="">
                      <Form.Label>
                        First Name<sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="first_name"
                        value={detailsForm.first_name}
                        className={classNames({
                          errorBorder: errorForm.first_name,
                        })}
                        onChange={handleForm}
                      />
                    </Form.Group>

                    <Form.Group as={Col} className="mb-4" controlId="">
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="middle_name"
                        className={classNames({
                          errorBorder: errorForm.middle_name,
                        })}
                        value={detailsForm.middle_name}
                        onChange={handleForm}
                      />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-4" controlId="">
                      <Form.Label>
                        Last Name<sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="last_name"
                        className={classNames({
                          errorBorder: errorForm.last_name,
                        })}
                        value={detailsForm.last_name}
                        onChange={handleForm}
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mx-0">
                    <Form.Group
                      as={Col}
                      className="mb-4 d-flex flex-column"
                      controlId=""
                    >
                      <Form.Label>
                        Date of Birth<sup className="text-danger">*</sup>
                      </Form.Label>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          value={detailsForm.dob}
                          clearable
                          placeholder=""
                          // onChange={date => startDateChange(date)}
                          onChange={(date) => {
                            setDetailsForm((prevState) => ({
                              ...prevState,
                              dob: date,
                            }));
                            if (date === null) {
                              setErrorForm((prevState) => ({
                                ...prevState,
                                dob: "Enter Valid Date of Birth",
                              }));
                            } else if (
                              moment().diff(date, "years", false) < 18
                            ) {
                              setErrorForm((prevState) => ({
                                ...prevState,
                                dob: "Age Should be greter then 18 years",
                              }));
                            } else {
                              setErrorForm((prevState) => ({
                                ...prevState,
                                dob: false,
                              }));
                            }
                          }}
                          format="MM/dd/yyyy"
                          mask="__/__/____"
                          inputVariant="outlined"
                          autoOk={true}
                          InputAdornmentProps={{ position: "end" }}
                          className={classNames("form-control team-name", {
                            errorBorder: errorForm.dob,
                          })}
                        />
                      </MuiPickersUtilsProvider>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-4" controlId="">
                      <Form.Label>
                        Player Email<sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder=""
                        name="email"
                        className={classNames({
                          errorBorder: errorForm.email,
                        })}
                        value={detailsForm.email}
                        onChange={handleForm}
                        disabled={selectedID !== ""}
                      />
                      {isMailExist && (
                        <div
                          className="mt-2"
                          style={{
                            fontSize: "14px",
                            color: "red",
                            lineHeight: "23px",
                          }}
                        >
                          <u>
                            <b>Please note:</b>
                          </u>
                          &nbsp;This email ID has been used to create another
                          account. Please proceed if you'd like to add this
                          account underneath that account. Otherwise, please
                          enter a new email ID.
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} className="mb-4" controlId="">
                      <Form.Label>
                        Telephone<sup className="text-danger">*</sup>
                      </Form.Label>
                      <MaskedInput
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          "-",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          "-",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                        name="mobile_number"
                        placeholder=""
                        guide={true}
                        value={detailsForm.mobile_number}
                        onChange={handleForm}
                        className={classNames("form-control", {
                          errorBorder: errorForm.mobile_number,
                        })}
                      />
                    </Form.Group>
                  </Row>
                  <Form.Label>Gender:</Form.Label>
                  <Form.Group>
                    <Form.Select
                      className="formSelect col-2"
                      name="gender"
                      value={detailsForm?.gender}
                      onChange={handleForm}
                    >
                      <option value={"0"}>Male</option>
                      <option value={"1"}>Female</option>
                    </Form.Select>
                  </Form.Group>

                  {selectedID && (
                    <>
                      <hr />

                      <div className="h4"> Contact Info</div>
                      <hr />
                      <div
                        style={{
                          fontSize: "15px",
                          color: "#BBBCBD",
                        }}
                        className="mb-2"
                      >
                        Home Address
                      </div>
                      <Form.Group as={Col} className="mb-4" controlId="">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="address"
                          className={classNames({
                            errorBorder: errorForm.home_address.address,
                          })}
                          value={detailsForm?.home_address?.address}
                          onChange={handleHomeAddress}
                        />
                      </Form.Group>

                      <Row className="mx-0">
                        <Form.Group as={Col} className="mb-4" controlId="">
                          <Form.Label>State</Form.Label>
                          <Form.Select
                            onChange={handleHomeAddress}
                            value={detailsForm?.home_address?.state}
                            name="state"
                            className={classNames("form-control", {
                              errorBorder: errorForm.home_address.state,
                            })}
                          >
                            <option value={""}>select state</option>
                            {stateList.map((e, i) => {
                              return (
                                <option key={i} value={e._id}>
                                  {e.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-4" controlId="">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            list="cityNameList"
                            placeholder=""
                            value={detailsForm?.home_address?.cityName}
                            name="cityName"
                            className={classNames("form-control", {
                              errorBorder: errorForm.home_address.cityName,
                            })}
                            // disabled={isSameAddress}
                            onChange={handleHomeAddress}
                          />
                          <datalist id="cityNameList">
                            {cityList.map((e, i) => {
                              return <option value={e.name} key={i} />;
                            })}
                          </datalist>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-4" controlId="">
                          <Form.Label>Zipcode</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="zip_code"
                            className={classNames({
                              errorBorder: errorForm.home_address.zip_code,
                            })}
                            value={detailsForm?.home_address?.zip_code}
                            onChange={handleHomeAddress}
                          />
                        </Form.Group>
                      </Row>

                      <div>
                        <input
                          type="checkbox"
                          name=""
                          value={isSameAddress}
                          checked={isSameAddress}
                          className="mr-2 mb-3"
                          onChange={(e) => {
                            setIsSameAddress(e.target.checked);
                          }}
                        />{" "}
                        <span>
                          <strong>Shipping Address same as Home Address</strong>
                        </span>
                      </div>

                      <div
                        style={{
                          fontSize: "15px",
                          color: "#BBBCBD",
                        }}
                        className="mb-2"
                      >
                        Shipping Address (if Different)
                      </div>

                      <Form.Group as={Col} className="mb-4" controlId="">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="address"
                          className={classNames({
                            errorBorder: errorForm.shipping_address.address,
                          })}
                          value={detailsForm?.shipping_address?.address}
                          onChange={handleShippingAddress}
                          disabled={isSameAddress}
                        />
                      </Form.Group>

                      <Row className="mx-0">
                        <Form.Group as={Col} className="mb-4" controlId="">
                          <Form.Label>State</Form.Label>
                          <Form.Select
                            onChange={handleShippingAddress}
                            value={detailsForm?.shipping_address?.state}
                            name="state"
                            className={classNames("form-control", {
                              errorBorder: errorForm.shipping_address.state,
                            })}
                            disabled={isSameAddress}
                          >
                            <option value={""}>select state</option>
                            {stateList.map((e, i) => {
                              return (
                                <option key={i} value={e._id}>
                                  {e.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-4" controlId="">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            list="cityNameList"
                            placeholder=""
                            value={detailsForm?.shipping_address?.cityName}
                            name="cityName"
                            className={classNames("form-control", {
                              errorBorder: errorForm.shipping_address.cityName,
                            })}
                            disabled={isSameAddress}
                            onChange={handleShippingAddress}
                          />
                          <datalist id="cityNameList">
                            {cityList.map((e, i) => {
                              return <option value={e.name} key={i} />;
                            })}
                          </datalist>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-4" controlId="">
                          <Form.Label>Zipcode</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="zip_code"
                            className={classNames({
                              errorBorder: errorForm.shipping_address.zip_code,
                            })}
                            value={detailsForm?.shipping_address?.zip_code}
                            onChange={handleShippingAddress}
                            disabled={isSameAddress}
                          />
                        </Form.Group>
                      </Row>

                      <div
                        style={{
                          fontSize: "15px",
                          color: "#BBBCBD",
                        }}
                        className="mb-2"
                      >
                        Secondary Parent/Guardian
                      </div>

                      <Row className="mx-0">
                        <Form.Group as={Col} className="mb-4" controlId="">
                          <Form.Label>Phone</Form.Label>

                          <MaskedInput
                            mask={[
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              "-",
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              "-",
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                            ]}
                            name="secondary_mobile_number"
                            placeholder=""
                            guide={true}
                            value={detailsForm.secondary_mobile_number}
                            onChange={handleForm}
                            className={classNames("form-control", {
                              errorBorder: errorForm.secondary_mobile_number,
                            })}
                          />
                        </Form.Group>

                        <Form.Group as={Col} className="mb-4" controlId="">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="secondary_email"
                            className={classNames({
                              errorBorder: errorForm.secondary_email,
                            })}
                            value={detailsForm.secondary_email}
                            onChange={handleForm}
                            disabled={selectedID !== ""}
                          />
                        </Form.Group>
                      </Row>
                    </>
                  )}
                  <div className="text-end">
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        setDetailsForm(InitialForm);
                        setErrorForm(InitialError);
                        handleClose();
                        setIsMailExist(false);
                      }}
                      style={{ fontSize: "18px" }}
                      className="mr-3 pointer"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="secondary"
                      type="submit"
                      onClick={handleCreate}
                    >
                      {selectedID == "" ? "Submit" : "Update"}
                    </Button>
                  </div>
                </Form>
              </Modal.Body>
            </div>
          </Modal>
          <Modal
            size="sm"
            show={delShow}
            onHide={delDialClose}
            centered
            className="del-dialogue"
          >
            {/* <Modal.Header closeButton>
                              <Modal.Title>delete</Modal.Title>
                        </Modal.Header> */}
            <Modal.Body>
              <h6 className="text-center">
                Are you sure you want to delete this account?
              </h6>
            </Modal.Body>
            <Modal.Footer className="p-0">
              <Button className="btn-gray" onClick={delDialClose}>
                No
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  successDialShow();
                }}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          {/* <Modal
            size="sm"
            show={loginshow}
            onHide={handleloginClose}
            centered
            className="del-dialogue"
          >
            <Modal.Body>
            </Modal.Body>
            <Modal.Footer className="p-0 d-flex justify-content-center align-items-center">
              <Button className="btn-gray " onClick={handleloginClose}>
                Login
              </Button>
            </Modal.Footer>
          </Modal> */}
          <Modal
            size="sm"
            show={successShow}
            onHide={successDialClose}
            centered
            className="del-dialogue success"
          >
            <Modal.Body className="text-center">
              <i className="ri-close-circle-line fa-4x text-secondary"></i>
              <h5 className="text-center">Record deleted Successfully !!</h5>
            </Modal.Body>
          </Modal>

          {/* Status Changed  */}
          <Modal
            size="sm"
            show={status.isChanged}
            onHide={() =>
              setStatus({
                isChanged: false,
                status: "",
              })
            }
            centered
            className="del-dialogue"
          >
            <Modal.Body>
              <h6 className="text-center">
                Are you sure you want to <strong>{status.status}</strong> this
                account?
              </h6>
            </Modal.Body>
            <Modal.Footer className="p-0">
              <Button
                className="btn-gray"
                onClick={() =>
                  setStatus({
                    isChanged: false,
                    status: "",
                  })
                }
              >
                No
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  props.changeUserStatus({
                    type: 5,
                    id: selectedID,
                  });
                }}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

const mapStateToProp = (state) => ({
  playerListReducer: state.superAdminRootReducer.UserReducer.playerList,
  createplayerReducer: state.superAdminRootReducer.UserReducer.createplayer,
  updateplayerReducer: state.superAdminRootReducer.UserReducer.updateplayer,
  deleteplayerReducer: state.superAdminRootReducer.UserReducer.deleteplayer,
  stateListReducer: state.superAdminRootReducer.authReducers.stateList,
  cityListReducer: state.superAdminRootReducer.authReducers.cityList,
  changeUserStatusReducer:
    state.superAdminRootReducer.UserReducer.changeUserStatus,
  isMailExistReducer: state.superAdminRootReducer.authReducers.isMailExist,
});

const mapDispatchToProps = (dispatch) => ({
  getPlayerList: () => dispatch(getPlayerList()),
  StateList: () => dispatch(StateList()),
  CityList: (state) => dispatch(CityList(state)),
  createplayerHandler: (Details) => dispatch(CreatePlayerAction(Details)),
  updateplayerHandler: (Details) => dispatch(UpdatePlayerAction(Details)),
  deleteplayerHandler: (Details) => dispatch(DeletePlayerAction(Details)),
  changeUserStatus: (details) => dispatch(changeUserStatus(details)),
  checkMailIsExist: (email) => dispatch(checkMailIsExist(email)),
  ResetCreatePlayer: () => dispatch({ type: RESET_CREATE_PLAYER }),
  ResetDeletePlayer: () => dispatch({ type: RESET_DELETE_PLAYER }),
  resetStatus: () => dispatch({ type: RESET_USER_STATUS }),
  resetisCheckEmail: () => dispatch({ type: RESET_CHECK_MAIL_EXIST }),
});

export default connect(mapStateToProp, mapDispatchToProps)(Players);
