import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import BreadcrumbHistory from '../../../BreadcumbHistory/BreadcrumbHistory';

const Payment = () => {
    const location = useLocation()
    return (
        <React.Fragment>
            <div className="main-panel">
                <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />

                <div className="content-wrapper">

                    <div className="text-left d-flex justify-content-between">
                        <div className="create-team-head">
                            <h5 className="dash-head">Payment</h5>
                        </div>
                    </div>
                    <div className="text-left mt-30 col-lg-6 px-0">
                        <Link to="/payment/report" className="account-tab">
                            <div className="account-type-section fw-m">
                                Payment Requests
                                <i className="mdi mdi-chevron-right" ></i>
                            </div>
                        </Link>
                        <Link to="/payment/history" className="account-tab">
                            <div className="account-type-section fw-m">
                                Payment History
                                <i className="mdi mdi-chevron-right" ></i>
                            </div>
                        </Link>

                        <Link to="/payment/payment-code" className="account-tab">
                            <div className="account-type-section fw-m">
                                Payment Codes
                                <i className="mdi mdi-chevron-right" ></i>
                            </div>
                        </Link>
                        <Link to="/payment/discount-code" className="account-tab">
                            <div className="account-type-section fw-m">
                                Discount codes
                                <i className="mdi mdi-chevron-right" ></i>
                            </div>
                        </Link>
                        <Link to="/payment/club-refunds" className="account-tab">
                            <div className="account-type-section fw-m">
                                Refunds
                                <i className="mdi mdi-chevron-right" ></i>
                            </div>
                        </Link>
                        <Link to="/payment/payment-method" className="account-tab">
                            <div className="account-type-section fw-m">
                                Payment Methods
                                <i className="mdi mdi-chevron-right" ></i>
                            </div>
                        </Link>
                    </div>

                </div>
            </div>

        </React.Fragment>
    )
}

export default Payment;