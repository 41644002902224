import { GET_PERMISSION_GROUP_ERROR, GET_PERMISSION_GROUP_LOADING, GET_PERMISSION_GROUP_SUCCESS, RESET_GET_PERMISSION_GROUP } from "../constants";

let initialState = {
 loading: false,
 data: [],
 error: false,
 errors: [],
 success: false,
 payload: {},
};

export default function GetPermiossionAdminGroupReducer(state = initialState, action) {
 switch (action.type) {
   case GET_PERMISSION_GROUP_LOADING:
     return { ...state, error: false, loading: true, success: false };

   case GET_PERMISSION_GROUP_SUCCESS:
     return {
       ...state,
       error: false,
       loading: false,
       success: true,
       message: action.msg,
       data: action.data,
     };

   case GET_PERMISSION_GROUP_ERROR:
     return {
       ...state,
       error: true,
       loading: false,
       success: false,
       errors: action.errors,
       msg: action.errors,
     };

   case RESET_GET_PERMISSION_GROUP:
     return {
       ...state,
       error: false,
       loading: false,
       success: false,
       errors: [],
       data: [{}],
     };
   default:
     return state;
 }
}
