import { DELETE_BANK_ERROR, DELETE_BANK_LOADING, DELETE_BANK_SUCCESS, RESET_BANK } from "../../../constants";

let initialState={
    success:false,
    error:false,
    loading:false,
    error:[]
}


export default function deleteBankReducer(state=initialState,action){
    switch(action.type){
        case DELETE_BANK_LOADING:
            return {...state,success:false,loading:true,error:false}
        case DELETE_BANK_SUCCESS:
            return {...state,success:true,loading:false,error:false,message:action.msg}
        case DELETE_BANK_ERROR:
            return {...state,success:false,loading:false,error:false}
        case RESET_BANK:
            return {...state,success:false,loading:false,error:false}
        default :
        return state
    }
}