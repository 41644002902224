import React from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

function PlayerImagePopup({isOpen, isClose, resetImage, selectedImage}) {
 const history = useHistory()
 return (
  <Modal
   show={isOpen}
   aria-labelledby="contained-modal-title-vcenter"
   centered
   onHide={() => {
    isClose(false)
    resetImage(null)
   }}
  >
   <div>
    <img src={selectedImage} className='w-100' />
    <div className='position-absolute' style={{ top: '10px', right: '20px' }} onClick={() => history.push()}>
     <a className="d-block text-info ml-12 pointer" style={{ fontSize: '25px' }}>
      <i className="mdi mdi-message-text"></i>
     </a>
    </div>
   </div>
  </Modal>
 )
}

export default PlayerImagePopup