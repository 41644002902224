import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router";
const PaymentOption = (props, value) => {
  const { id, id1 } = useParams();
  const history = useHistory();

  const [val, setVal] = useState(null);

  useEffect(() => {
    setVal(value.send);
  }, [val]);

  const teamlist = useSelector((state) => state.teamArrayReducer.user);
  const payment = useSelector(
    (state) => state.registerSelectedTeamReducer.message
  );

  const discount = useSelector(
    (state) => state.applydiscountcodeReducer.message
  );

  const [state, setState] = useState({
    is_credit_payment: "",
  });

  //Calidation for discount code
  const [errors, setErrors] = useState({
    discountCode: false,
  });

  const notify = (msg) => toast(msg);

  //Call Discount code api on Page load
  useEffect(() => {
    if (state.payment_id) {
      // props.discountCodehandler({
      //     discount_code: discountCode === null ? "" : discountCode,
      //     payment_code: state.payment_id,
      //     organizer: state.organizer_id,
      //     team: state.team
      // })
    }
  }, [state.payment_id]);

  //onRefresh Page redirect to back
  useEffect(() => {
    if (teamlist === null) {
      history.push(`/events/${id}/registernow` + (id1 ? `/${id1}` : ""));
      // props.resetregisteredSelectedTeamReducer()
    }
  }, []);

  return (
    <React.Fragment>
      <div className="main-panel">
        {/* <div className="content-wrapper"> */}
        <div className="container-fluid">
          <div className="content-wrapper d-flex  p-4 mb-4 bg-white my-4">
            <div className="row flex-grow">
              <div className="mx-auto mt-24">
                <div className=" text-left p-md-5 p-4 shadow">
                  <h6 className=" text-center dash-head">
                    Select Payment Method
                  </h6>
                  <form className="pt-3">
                    <div className="form-group"></div>

                    <div className="mt-3 text-center">
                      <Link
                        to={
                          `/carddetails/${id}` +
                          (id1 ? `/${id1}` : `/721`) +
                          `/1`
                        }
                        className="text-decoration-none"
                      >
                        <button className="mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn card-btn">
                          Credit / Debit Card
                        </button>
                      </Link>
                    </div>
                    <div className="mt-3 text-center">
                      <Link
                        to={`/carddetails`}
                        className="text-decoration-none"
                      >
                        <button className="mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn card-btn">
                          Google Pay
                        </button>
                      </Link>
                    </div>
                    <div className="mt-3 text-center">
                      <Link
                        to={`/carddetails`}
                        className="text-decoration-none"
                      >
                        <button className="mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn card-btn">
                          Apple Pay
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="auth-form-light text-left p-5"> */}
        {/* </div> */}
        {/* </div> */}
      </div>
      {/* <div className="main-panel">
                <div className="content-wrapper  ">
                    <div className="text-left">
                    </div> */}

      {/* <div className="  d-none d-md-block table-responsive shadow p-4 mb-4 bg-white w-50 ">
                        <div className='d-flex justify-content-center'>

                            <div className='row'>

                                <div className="create-team-head-space justify-content-center">
                                    <h5 className="dash-head  ">Select Payment Method</h5>
                                </div>

                                <div className='d-flex justify-content-center col-12 my-3'>
                                    <Link to={`/carddetails`} className='text-decoration-none'>
                                        <button className='mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn card-btn'  >
                                            Credit / Debit Card
                                        </button>
                                    </Link>
                                </div>
                                <div className='d-flex justify-content-center col-12 my-3'>

                                    <Link to="#">
                                        <button className='mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn card-btn' >
                                            Google Pay
                                        </button>
                                    </Link>
                                </div>
                                <div className='d-flex justify-content-center col-12 my-3'>
                                    <Link to="#">
                                        <button className='mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn card-btn' >
                                            Apple Pay
                                        </button>
                                    </Link>

                                </div>

                            </div>
                        </div>
                    </div> */}

      {/* mobile view */}
      {/* <div className="table-responsive d-md-none shadow p-4 mb-4 bg-white d-flex justify-content-center"> */}

      {/* <div className='row'>

                            <div className=' w-100 d-flex col-8'>
                                <input type="text" className="form-control-sm team-name mr-1" placeholder='Discount Code' />
                            </div>
                            <div className="col-2 col-md-2">
                                <button className='mr-1 btn btn-primary btn-sm font-weight-medium auth-form-btn' >
                                    Apply
                                </button>

                            </div>
                        </div> */}
      {/* <div className='row justify-content-center'>


                            <div className='d-flex justify-content-center row-4 row-md-4 my-2'>
                                <Link to={`/carddetails`} className='text-decoration-none'>
                                    <button className='mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn '  >
                                        Credit / Debit Card
                                    </button>
                                </Link>
                            </div>
                            <div className='d-flex justify-content-center  row-4 row-md-4 my-2'>

                                <Link to="#">
                                    <button className='mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn ' >
                                        Google Pay
                                    </button>
                                </Link>
                            </div>
                            <div className='d-flex justify-content-center  row-4 row-md-4 my-2'>
                                <Link to="#">
                                    <button className='mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn ' >
                                        Apple Pay
                                    </button>
                                </Link>

                            </div>

                        </div>

                    </div> */}

      {/* <Toaster />
                </div>
            </div> */}
    </React.Fragment>
  );
};

// export default TeamsCoches;

const mapStateToProps = (state) => ({
  // paynowCheck: state.paynowReducer,
  // accept: state.acceptInvitationReducer
});

const mapDispatchToProps = (dispatch) => ({
  // discountCodehandler: user => dispatch(discountCode(user)),
  // resetregisteredSelectedTeamReducer: () => dispatch({ type: RESET_REGISTER_SELECTED_TEAM }),
  // paynowHandler: user => dispatch(payNow(user)),
  // changestatusHandler: user => dispatch(Club_changeStatus(user)),
  // resetAcceptInvitationReducer: uset => dispatch({ type: RESET_ACCEPT_INVITATION }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOption);
