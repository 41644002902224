import React, { useEffect, useRef, useState } from "react";
import {
  getNotificationsCountAction,
  orgConnectedUser,
  singleChat,
} from "../../../service/action/userAction";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

import $ from "jquery";
import { useDebounce } from "use-debounce";
import io from "socket.io-client";
import Spinner from "../../../Spinner/Spinner";
import { baseUrl } from "../../../apiSheet";
import classNames from "classnames";
import moment from "moment";
import ChatUserList from "../../../club/Notification/Notification_dm/ChatUserList";
import {
  GET_SINGLE_CHAT_RESET,
  removeDuplicates,
} from "../../../service/constants";
import InfiniteScroll from "react-infinite-scroll-component";

function Chat(props) {
  const location = useLocation();
  const historyStyle = {
    height: "calc(68vh - 86.5px)",
  };

  const [currentIndex, setCurrentIndex] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [firstScroll, setFirstScroll] = useState(true);
  const [_scrollHeight, set_ScrollHeight] = useState(0);

  const history = useHistory();
  const [socket, setSocket] = useState();

  const [online, setOnline] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const { id, id1, id2, id3 } = useParams();
  // const [profile, setProfile] = useState(null);

  const sender_id = location?.state?.id;
  // const sender_id = localStorage.getItem("Id");
  const userType = location?.state?.type;

  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [msgList, setMsgList] = useState([]);
  const [msgLoader, setMsgLoader] = useState(false);

  const [roomId, setRoomId] = useState(null);

  const connected_user = useSelector(
    (state) => state.OrgConnectedUserReducer.user.data
  );

  useEffect(() => {
    props.singleChatHandler({
      senderId: sender_id,
      reciverId: id,
      type1: userType,
      type2: id3,
      index: currentIndex,
    });
  }, [currentIndex]);

  useEffect(() => {
    props.getconnectedUserHandler({
      id: location.state.id,
      type: location.state.type,
    });
  }, []);

  useEffect(() => {
    if (props.users.success) {
      setUsers(connected_user);
    }
  }, [props.users]);

  useEffect(() => {
    if (props.chatReducer.success && !id1) {
      const data = props.chatReducer.data;
      setRoomId(data.room._id);
      props.resetChat();
    }
  }, [props.chatReducer]);

  useEffect(() => {
    if ((!!id, props.users.success)) {
      const coachDetails = !!connected_user.coach ? connected_user.coach : [];
      const organizerDetails = !!connected_user.organizer
        ? connected_user.organizer
        : [];
      const playerDetails = !!connected_user.player
        ? connected_user.player
        : [];
      const clubDetails = !!connected_user.club ? connected_user.club : [];
      const staffDetails = !!connected_user.staff ? connected_user.staff : [];
      const parentDetails = !!connected_user.parent
        ? connected_user.parent
        : [];
      const merge = [
        ...coachDetails,
        ...organizerDetails,
        ...playerDetails,
        ...clubDetails,
        ...staffDetails,
        ...parentDetails,
      ];
      const find = merge.find((e) => e._id == id);
      setCurrentUser(find);
    }
  }, [id, props.users]);

  useEffect(() => {
    setTimeout(() => {
      if (!location.state) {
        history.push(
          userType == 1
            ? "/notification-accounts"
            : userType == 4
            ? "/account-notifications"
            : "/dashboard"
        );
      }
    }, 500);
  }, [location]);

  const singleChatMsg = useSelector((state) => state.singleChatReducer);

  useEffect(() => {
    if (singleChatMsg.success) {
      const getdata = async () => {
        const data = singleChatMsg.data.data;
        if (data.length != 0) {
          const allData = [...msgList, ...data];
          const removeDublicate = removeDuplicates(allData, "_id");
          const sortData = removeDublicate.sort((a, b) => {
            const aDate = moment(a.createdAt);
            const bDate = moment(b.createdAt);
            return aDate.diff(bDate);
          });
          setMsgList([...sortData]);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      };
      getdata().then((res) => {
        setTimeout(() => {
          setMsgLoader(false);
        }, 3000);
      });
    }
  }, [singleChatMsg]);

  const connectionOptions = {
    "force new connection": true,
    reconnectionAttempts: "Infinity",
    timeout: 10000,
    transports: ["websocket"],
  };

  useEffect(() => {
    const socket = io.connect(baseUrl, connectionOptions);
    setSocket(socket);

    socket.emit("subscribe", id1);

    socket.on("connect", (userType) => {
      socket.emit("subscribe", id1);
    });
    socket.emit("join", { userId: sender_id, userType: userType });

    socket.on("newJoin", (data) => {
      if (data?._id === id) {
        setOnline(true);
      }
      props.getconnectedUserHandler({
        id: location.state.id,
        type: location.state.type,
      });
    });

    ///Detecting status of error connection
    socket.on("connect_error", (err) => {});

    ///Detecting status of disconnection
    socket.on("disconnect", () => {
      socket.connect();
    });

    ///To get event
    socket.on("allReded", (data) => {});

    socket.on("receive_message", (data) => {
      // props.getOrgconnectedUserHandler()
      if (id1 === data.roomId) {
        socket.emit("read", { roomId: id1, message: data.id });
        // singleChatMsg.data.push()
      }
      props.getconnectedUserHandler({
        id: location.state.id,
        type: location.state.type,
      });
      props.singleChatHandler({
        senderId: sender_id,
        reciverId: id,
        type1: userType,
        type2: id3,
        index: currentIndex,
      });
    });

    socket.on("reded", (data) => {
    });

    socket.on("typing", (data) => {
      setIsTyping(data);
    });

    socket.on("UserDisconnected", (data) => {
      socket.connect();
    });

    // socket.emit("UserDisconnect", { userId: sender_id, userType: userType });

    return () => {
      socket.disconnect();
    };
  }, []);

  const [message, setMessage] = useState("");

  const debouncedSearchTerm = useDebounce(message, 1000);

  const handleChange = (e) => {
    setMessage(e.target.value);
    //
    socket.emit("typingStart", { roomId: !!id1 ? id1 : roomId, userType: id3 });
  };

  useEffect(() => {
    setTimeout(() => {
      if (socket) {
        socket.emit("typingEnd", {
          roomId: !!id1 ? id1 : roomId,
          userType: id3,
        });
      }
    }, 1000);
  }, [debouncedSearchTerm]);

  // const [image, setImage] = useState("");

  const [type, setType] = useState("text");

  const [base64, setBase64] = useState("");

  const onChangePicture = async (event) => {
    setType("image");
    const _file = await event.target.files[0];

    // setImage(URL.createObjectURL(_file));
    const reader = new FileReader();
    reader.readAsDataURL(_file);
    reader.onload = () => {
      const data = reader.result;
      setBase64(data);
    };
  };

  //send Msg to opp. user
  const sendMessage = (e) => {
    if (message.length !== 0) {
      if (type === "text") {
        const messageObj = {
          sender: sender_id,
          roomId: !!id1 ? id1 : roomId,
          senderUserType: userType,
          receiver: id,
          receiverUserType: id3,
          message: message,
          message_type: "text",
        };
        socket.emit("message", messageObj);

        props.singleChatHandler({
          senderId: sender_id,
          reciverId: id,
          type1: userType,
          type2: id3,
          index: currentIndex,
        });
        setMessage("");
      }
    }

    if (type === "image") {
      const messageObj = {
        sender: sender_id,
        roomId: !!id1 ? id1 : roomId,
        senderUserType: userType,
        receiver: id,
        receiverUserType: id3,
        message: base64,
        message_type: "image",
      };
      socket.emit("message", messageObj);
      props.singleChatHandler({
        senderId: sender_id,
        reciverId: id,
        type1: userType,
        type2: id3,
        index: currentIndex,
      });
      setType("text");
      setBase64("");
    }
  };


  const [state, setState] = useState({
    search: "",
  });

  const Searchval = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    UpdateArray(e.target.value);
  };

  const UpdateArray = (search) => {
    let query = search.toLowerCase();
    if (query == "") {
      setUsers(connected_user);
    } else {
      const filterCoach = !!connected_user.coach
        ? connected_user.coach.filter((e) => {
            return e.name.trim().toLowerCase().includes(query.trim());
          })
        : [];
      const filterOrg = !!connected_user.organizer
        ? connected_user.organizer.filter((e) => {
            return e.name.trim().toLowerCase().includes(query.trim());
          })
        : [];
      const filterPlayer = !!connected_user.player
        ? connected_user.player.filter((e) => {
            return e.name.trim().toLowerCase().includes(query.trim());
          })
        : [];
      const filterStaff = !!connected_user.staff
        ? connected_user.staff.filter((e) => {
            return e.name.trim().toLowerCase().includes(query.trim());
          })
        : [];
      const filterClub = !!connected_user.club
        ? connected_user.club.filter((e) => {
            return e.name.trim().toLowerCase().includes(query.trim());
          })
        : [];
      const filterParent = !!connected_user.parent
        ? connected_user.parent.filter((e) => {
            return e.name.trim().toLowerCase().includes(query.trim());
          })
        : [];
      setUsers({
        coach: [...filterCoach],
        organizer: [...filterOrg],
        player: [...filterPlayer],
        club: [...filterClub],
        staff: [...filterStaff],
        parent: [...filterParent],
      });
    }
  };

  const handleChangeUser = (userId, user_Type, lastMessage, userName) => {
    setMsgLoader(true);
    setMsgList([]);
    setCurrentIndex(1);
    setHasMore(true);
    setFirstScroll(true);
    set_ScrollHeight(0);
    props.singleChatHandler({
      senderId: sender_id,
      reciverId: userId,
      type1: userType,
      type2: user_Type,
      index: 1,
    });
    history.push(
      lastMessage !== null
        ? `/chat/${userId}/${lastMessage.roomId}/${userName}/${user_Type}`
        : `/chat/${userId}/${userName}/${user_Type}`,
      {
        id: location.state.id,
        type: location.state.type,
      }
    );
  };

  const getNotifiCationCount = () => {
    dispatch(
      getNotificationsCountAction(location.state.id, location.state.type)
    );
  };

  const dispatch = useDispatch();

  useEffect(() => {
    getNotifiCationCount();
  }, []);

  const scrollRef = useRef(null);

  $(document).ready(() => {
    if (firstScroll) {
      var ChatDiv = $(".chat_container");
      var height = ChatDiv[0]?.scrollHeight;
      ChatDiv.scrollTop(height);
      if (ChatDiv[0]?.scrollHeight !== ChatDiv[0]?.clientHeight) {
        setFirstScroll(false);
        set_ScrollHeight(ChatDiv[0]?.scrollHeight);
      }
    }
  });

  useEffect(() => {
    const { scrollHeight } = scrollRef.current;
    if (_scrollHeight !== scrollHeight) {
      set_ScrollHeight(scrollHeight);
      var ChatDiv = $(".chat_container");
      ChatDiv.scrollTop(scrollHeight - _scrollHeight);
    }
  }, [$(".chat_container")]);

  const handleScroll = () => {
    const { scrollTop } = scrollRef.current;
    if (scrollTop == 0 && hasMore) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper px-0 py-0" style={{ height: "80vh" }}>
          <div className="messaging">
            <div className="inbox_msg">
              <div className="inbox_people">
                <div className="headind_srch">
                  {/* <div className="recent_heading"> */}
                  {/* <h4>Recent</h4> */}
                  {/* </div> */}
                  <div className="srch_bar">
                    {/* <div className="stylish-input-group"> */}

                    <input
                      type="text"
                      className="search-bar"
                      id="search"
                      name="search"
                      value={state.search}
                      placeholder="Search"
                      onChange={Searchval}
                      autoComplete="off"
                    />
                    {/* <hr/> */}
                    {/* </div> */}
                  </div>
                </div>
                <div className="inbox_chat scroll ">
                  {users.organizer &&
                    users.organizer.map((v, i) => {
                      return (
                        <React.Fragment key={i}>
                          <ChatUserList
                            id={id}
                            currentUser={v}
                            senderId={sender_id}
                            handleChangeUser={handleChangeUser}
                          />
                        </React.Fragment>
                      );
                    })}
                  {users.staff &&
                    users.staff.map((v, i) => {
                      return (
                        <React.Fragment key={i}>
                          <ChatUserList
                            id={id}
                            currentUser={v}
                            senderId={sender_id}
                            handleChangeUser={handleChangeUser}
                          />
                        </React.Fragment>
                      );
                    })}
                  {users.coach &&
                    users.coach.map((v, i) => {
                      return (
                        <React.Fragment key={i}>
                          <ChatUserList
                            id={id}
                            currentUser={v}
                            senderId={sender_id}
                            handleChangeUser={handleChangeUser}
                          />
                        </React.Fragment>
                      );
                    })}
                  {users.club &&
                    users.club.map((v, i) => {
                      return (
                        <React.Fragment key={i}>
                          <ChatUserList
                            id={id}
                            currentUser={v}
                            senderId={sender_id}
                            handleChangeUser={handleChangeUser}
                          />
                        </React.Fragment>
                      );
                    })}
                  {users.player &&
                    users.player.map((v, i) => {
                      return (
                        <React.Fragment key={i}>
                          <ChatUserList
                            id={id}
                            currentUser={v}
                            senderId={sender_id}
                            handleChangeUser={handleChangeUser}
                          />
                        </React.Fragment>
                      );
                    })}
                  {users.parent &&
                    users.parent.map((v, i) => {
                      return (
                        <React.Fragment key={i}>
                          <ChatUserList
                            id={id}
                            currentUser={v}
                            senderId={sender_id}
                            handleChangeUser={handleChangeUser}
                          />
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
              <div className="mesgs">
                <div className="d-flex align-items-center border-bottom pb-3">
                  <div
                    className="dashboard-img "
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <img
                      src={
                        !!currentUser?.profile_image &&
                        currentUser?.profile_image !== ""
                          ? currentUser?.profile_image
                          : "./images/faces/face16.jpg"
                      }
                      className="img-fluid"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                    {/* <span className="availability-status online">1</span> */}
                  </div>
                  <div className="ml-12">
                    <strong className="mb-1">{currentUser?.name}</strong>

                    {/* {currentUser?.is_online ? ( */}
                    {online ? (
                      <span className="noti-type-online ">
                        {isTyping ? "Typing..." : "Online"}
                      </span>
                    ) : (
                      <span className="noti-type">Offline</span>
                    )}

                    {/* <span className="noti-type">{online ? "Online" : "Offline"}</span> */}
                  </div>
                </div>
                <div
                  ref={scrollRef}
                  onScroll={handleScroll}
                  id="scrollableDiv"
                  className="msg_history scroll chat_container p-3"
                  style={base64 !== "" ? historyStyle : {}}
                >
                  <InfiniteScroll
                    dataLength={msgList.length}
                    // next={handleFatchData}
                    initialScroll={true}
                    hasMore={hasMore}
                    scrollableTarget="scrollableDiv"
                  >
                    {msgLoader ? (
                      <div className="w-full h-full flex flex-col justify-center items-center overflow-hidden">
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        {msgList.map((msg, index) => {
                          return (
                            <React.Fragment key={index}>
                              {msg.sender != id ? (
                                <>
                                  <div className="outgoing_msg">
                                    <div className="sent_msg">
                                      {msg.message_type === "image" ? (
                                        <img
                                          src={msg.message}
                                          className="sent_msg_img"
                                        />
                                      ) : (
                                        <p
                                          style={{
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          {msg.message}
                                        </p>
                                      )}

                                      <div className="d-flex align-items-center justify-content-end">
                                        <span
                                          className="time_date_out"
                                          style={{ marginRight: "5px" }}
                                        >
                                          {" "}
                                          {/* {time(msg.createdAt)} */}
                                          {moment(msg.createdAt).format(
                                            "hh:mm A"
                                          )}
                                        </span>
                                        <i
                                          className={`mdi ${
                                            msg?.readAt !== "NA"
                                              ? "mdi-check-all"
                                              : "mdi-check"
                                          }`}
                                          style={{
                                            color:
                                              msg?.readAt !== "NA"
                                                ? "#189ad6"
                                                : "#ffffff",
                                            marginTop: "8px",
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="incoming_msg">
                                    {/* <div className="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div> */}
                                    <div
                                      className="received_msg"
                                      // style={{
                                      //   backgroundColor: "#dcf1fb",
                                      // }}
                                    >
                                      {msg.message_type === "image" ? (
                                        <img
                                          src={msg.message}
                                          className="sent_msg_img"
                                        />
                                      ) : (
                                        <p
                                          style={{
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          {msg.message}
                                        </p>
                                      )}
                                      <div className="d-flex align-items-center justify-content-end">
                                        <span
                                          className="time_date_out"
                                          style={{
                                            marginRight: "5px",
                                            color: "#143d4a",
                                          }}
                                        >
                                          {" "}
                                          {/* {time(msg.createdAt)} */}
                                          {moment(msg.createdAt).format(
                                            "hh:mm A"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </>
                    )}
                  </InfiniteScroll>
                </div>
                {base64 !== "" && (
                  <div className="bg-slate-100 py-2 pl-2">
                    <div
                      style={{
                        width: "75px",
                        height: "70px",
                      }}
                      className="position-relative"
                    >
                      <img
                        src={base64}
                        alt=""
                        className="h-full border rounded-[12px]"
                      />
                      <div
                        className="position-absolute"
                        style={{
                          right: "-8px",
                          top: "-15px",
                        }}
                        onClick={() => {
                          // setImage("");
                          setType("text");
                          setBase64("");
                        }}
                      >
                        <i className="mdi mdi-close-circle-outline fs-25 pointer"></i>
                      </div>
                    </div>
                  </div>
                )}

                <div className="type_msg">
                  <div className="input_msg_write">
                    <input
                      type="text"
                      className="write_msg"
                      placeholder="Type a message"
                      name="message"
                      value={message}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          sendMessage();
                        }
                      }}
                      required
                      autoComplete="off"
                      autoFocus
                    />

                    <div className="select-img mr-3 pointer">
                      <input
                        type="file"
                        className="file-upload-chat form-group pass-wrapper form-group-1"
                        id="file-upload"
                        onChange={onChangePicture}
                        accept="image/*"
                      />
                      <label
                        className=" pin-icon input-group-append position-absolute mr-2 "
                        htmlFor="file-upload"
                      >
                        <i className="mdi mdi-link-variant my-auto mx-auto "></i>
                      </label>
                    </div>

                    <button
                      className="msg_send_btn pointer"
                      type="button"
                      onClick={sendMessage}
                    >
                      <i className="mdi mdi-send" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  submit: state.createTeamReducer,
  users: state.OrgConnectedUserReducer,
  chatReducer: state.singleChatReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getconnectedUserHandler: (user) => dispatch(orgConnectedUser(user)),
  singleChatHandler: (user) => dispatch(singleChat(user)),
  resetChat: (user) => dispatch({ type: GET_SINGLE_CHAT_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Chat));
