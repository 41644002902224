import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import classNames from "classnames";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import { Link, withRouter } from "react-router-dom";
import {
  editAccountDetails,
  getAccountDetails,
  getAllCity,
  getAllState,
} from "../../../service/action/userAction";
import { isIfStatement } from "@babel/types";
import { RESET_ACCOUNT_DETAILS } from "../../../service/constants";
import Spinner from "../../../Spinner/Spinner";
import InputMask from "react-input-mask";
import { ThemeProvider } from "react-bootstrap";
import materialTheme from "../../../materialUi";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";

const OrgAccountDetail = (props) => {
  const getUserDetails = props.getAccountDetails;
  const getAllState = props.getAllStateHandler;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllState();
  }, []);

  const accountDetails = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );

  const updateAccountDetails = useSelector(
    (state) => state.editAccountDetailsReducer.message
  );
  const allstate = useSelector((state) => state.getStateReducer.states);
  const allcity = useSelector((state) => state.getCityReducer.citys);

  useEffect(() => {
    if (props.accountDetails?.success) {
      setLoading(false);
    }
  }, [props.accountDetails]);

  const [btn, setBtn] = useState(false);
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    account_name: "",
    city: "",
    state: "",
    website: "",
    address: "",
    mobile: "",
    desc: "",
    zip: "",
    email: "",
    dob: null,
    primary_contact: [],
  });
  const [errors, setErrors] = useState({
    account_name: false,
    first_name: false,
    last_name: false,
    middle_name: false,
    email: false,
    city: false,
    state: false,
    website: false,
    address: false,
    mobile: false,
    desc: false,
    zip: false,
    email: false,
    dob: false,
    primary_contact: [],
  });

  const [image, setImage] = useState("");
  const [pitcher, setPitcher] = useState({
    file: {},
  });

  const onChangePicture = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    if (e.target.files && e.target.files[0]) {
      setPitcher({ file: e.target.files[0] });
    }
    setBtn(true);
    // if (accountDetails.profile_image !== image) { setBtn(true) } else { setBtn(false) }
  };

  useEffect(() => {
    if (accountDetails) {
      if (accountDetails.state?._id !== "" || accountDetails.state !== null) {
        props.getAllCityHandler({ id: accountDetails.state?._id });
      }
      setState((prevState) => ({
        ...prevState,
        account_name: accountDetails.name ? accountDetails.name : "",
        first_name: accountDetails.first_name ? accountDetails.first_name : "",
        last_name: accountDetails.last_name ? accountDetails.last_name : "",
        middle_name: accountDetails.middle_name
          ? accountDetails.middle_name
          : "",
        email: accountDetails.email ? accountDetails.email : "",
        address: accountDetails.address ? accountDetails.address : "",
        website: accountDetails.website ? accountDetails.website : "",
        mobile:
          accountDetails.mobile_number == 0 ? "" : accountDetails.mobile_number,
        desc: accountDetails.description ? accountDetails.description : "",
        city: accountDetails.city ? accountDetails.city : "",
        state: accountDetails.state?._id ? accountDetails.state?._id : "",
        zip: accountDetails.zip ? accountDetails.zip : "",
        email: accountDetails.email ? accountDetails.email : "",
        dob: accountDetails.dob
          ? moment(accountDetails.dob, "MM-DD-yyyy").format("MM/DD/yyyy")
          : null,
        primary_contact: accountDetails?.primary_contact ?? [],
      }));
      setImage(
        accountDetails.profile_image ? accountDetails.profile_image : ""
      );
      setPitcher({
        file: accountDetails.profile_image ? accountDetails.profile_image : "",
      });
    }
  }, [accountDetails]);

  const eventHandler = (e) => {
    const { id, value, name } = e.target;

    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    switch (name) {
      case "account_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            account_name: "Account name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            account_name: false,
          }));
        }
        if (accountDetails.name !== value) {
          setBtn(true);
        } else {
          setBtn(false);
        }
        break;
      case "first_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            first_name: "First name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            first_name: false,
          }));
        }

        break;
      case "last_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            last_name: "Last name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            last_name: false,
          }));
        }

        break;
      case "middle_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            middle_name: "Middle name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            middle_name: false,
          }));
        }

        break;
      case "email":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Email can not be empty",
          }));
        } else if (!validEmailRegex.test(value)) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Email is not valid !",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;
      case "email":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Primary email can not be empty",
          }));
        } else if (!validEmailRegex.test(value)) {
          setErrors((prevState) => ({
            ...prevState,
            email: "Primary email is not valid !",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            email: false,
          }));
        }
        break;

      case "zip":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            zip: "Zip code can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            zip: false,
          }));
        }
        break;
      case "city":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            city: "Please select city",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            city: false,
          }));
        }
        if (accountDetails.city._id !== value) {
          setBtn(true);
        } else {
          setBtn(false);
        }
        break;
      case "state":
        if (value !== "" || value !== "#") {
          props.getAllCityHandler({ id: value });
        }
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            state: "Please select state",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            state: false,
          }));
        }
        if (accountDetails.state._id !== value) {
          setBtn(true);
        } else {
          setBtn(false);
        }
        break;
      case "website":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            website: "Website can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            website: false,
          }));
        }
        if (accountDetails.website !== value) {
          setBtn(true);
        } else {
          setBtn(false);
        }
        break;
      case "address":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            address: "Address can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            address: false,
          }));
        }
        if (accountDetails.address !== value) {
          setBtn(true);
        } else {
          setBtn(false);
        }
        break;
      case "mobile":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            mobile: "Mobile number can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            mobile: false,
          }));
        }
        break;

      case "desc":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            desc: "Description can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            desc: false,
          }));
        }
        break;
      default:
    }
  };

  const dobHandler = (id, value) => {
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    if (value?.length === 0) {
      setErrors((prevState) => ({
        ...prevState,
        dob: "Date of Birth can not be empty",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        dob: false,
      }));
    }
  };

  const savedata = (e) => {
    e.preventDefault();

    if (state.account_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        account_name: "Account name can not be empty",
      }));
    }
    if (state.first_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        first_name: "First name can not be empty",
      }));
    }
    if (state.last_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        last_name: "Last name can not be empty",
      }));
    }

    if (state.email == "") {
      setErrors((prevState) => ({
        ...prevState,
        email: "Email can not be empty",
      }));
    }
    if (state.email == "") {
      setErrors((prevState) => ({
        ...prevState,
        email: "Primary email can not be empty",
      }));
    }
    if (state.zip == "") {
      setErrors((prevState) => ({
        ...prevState,
        zip: "Zip can not be empty",
      }));
    }

    if (state.city == "" || state.city == undefined || state.city == "#") {
      setErrors((prevState) => ({
        ...prevState,
        city: "Please select city",
      }));
    }

    if (state.state == "" || state.state == undefined || state.state == "#") {
      setErrors((prevState) => ({
        ...prevState,
        state: "Please select state",
      }));
    }

    if (state.address == "") {
      setErrors((prevState) => ({
        ...prevState,
        address: "Address can not be empty",
      }));
    }

    if (state.mobile.length == 0) {
      setErrors((prevState) => ({
        ...prevState,
        mobile: "Mobile number can not be empty",
      }));
    }

    if (state.desc == "") {
      setErrors((prevState) => ({
        ...prevState,
        desc: "Description can not be empty",
      }));
    }

    if (state.mobile.length > 10) {
      var ret = state.mobile.replace(
        /[`~!@#$%^&*()_|+\-=?;:'",.<>{}\[\]\\\/]/gi,
        ""
      );
      var mobileNum = ret;
    } else {
      var mobileNum = state.mobile;
    }
    if (
      state.account_name !== "" &&
      state.first_name !== "" &&
      state.last_name !== "" &&
      state.address !== "" &&
      state.city !== "" &&
      state.state !== "" &&
      state.mobile !== "" &&
      state.desc !== "" &&
      state.email !== "" &&
      state.zip !== ""
    ) {
      const cityId = allcity.find((e) => e._id == state.city);
      localStorage.setItem("username", state.account_name);
      props.editOrgDetailsHandler({
        // id: state.id,
        first_name: state.first_name,
        last_name: state.last_name,
        middle_name: state.middle_name,
        name: state.account_name,
        address: state.address,
        // cityName: cityId.name,
        cityName: state.city,
        city: state.city,
        state: state.state,
        mobile_number: mobileNum,
        website: state.website,
        description: state.desc,
        profile_image: pitcher.file,
        email: state.email,
        zip: state.zip,
        primary_contact: [...state.primary_contact],
      });
    }
  };

  const notify = (msg) => toast(msg);

  useEffect(() => {
    if (props.editAccount?.success) {
      notify("Profile updated successfully");
      props.resetEditAccountDetails();
      props.getAccountDetails();
    } else if (props.editAccount?.error) {
      notify("Profile Update Error");
    }
  }, [props.editAccount]);

  const handleAdd = () => {
    const details = [
      ...state.primary_contact,
      {
        first_name: "",
        last_name: "",
        middle_name: "",
        email: "",
      },
    ];

    const errorDetails = [
      ...errors.primary_contact,
      {
        first_name: false,
        last_name: false,
        middle_name: false,
        email: false,
      },
    ];
    setState((pre) => ({
      ...pre,
      primary_contact: [...details],
    }));

    setErrors((pre) => ({
      ...pre,
      primary_contact: [...errorDetails],
    }));
  };

  const handleRemove = (index) => {
    const details = [...state.primary_contact];
    details.splice(index, 1);
    const errorDetails = [...errors.primary_contact];
    errorDetails.splice(index, 1);
    setState((pre) => ({
      ...pre,
      primary_contact: [...details],
    }));

    setErrors((pre) => ({
      ...pre,
      primary_contact: [...errorDetails],
    }));
  };

  const handleprimary_contact = (e, index) => {
    const { name, value } = e.target;

    const details = state.primary_contact.map((e, i) => {
      if (i == index) {
        return {
          ...e,
          [name]: value,
        };
      } else return e;
    });
    setState((pre) => ({
      ...pre,
      primary_contact: [...details],
    }));
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>
          {!loading && (
            <>
              <div className="text-left">
                <div className="create-team-head-space">
                  <h5 className="dash-head">Account Details</h5>
                  <button className="btn-save" onClick={savedata}>
                    Save Changes
                  </button>
                </div>

                <div className="text-left mt-30">
                  <h6 className="payment">Organizer Info</h6>
                  <div className="basic-info">
                    <input
                      type="file"
                      className="input-file file-upload"
                      id="file-upload"
                      name="file-upload"
                      onChange={onChangePicture}
                    />
                    <label className="change-pic" htmlFor="file-upload">
                      <img
                        src={
                          image == "" ? "./images/pch-images/pch.svg" : image
                        }
                        className="img-fluid"
                      />
                    </label>
                    <span className="ml-12">Upload Picture</span>
                  </div>
                </div>
              </div>

              <div className="row mx-0">
                <div className="col-md-6">
                  <div className="text-left">
                    <div className="row mx-0 justify-content-between">
                      <div className="mt-18 col-md-6">
                        <input
                          type="text"
                          className={classNames("form-control team-name", {
                            error: errors.first_name,
                          })}
                          placeholder="Account Name"
                          id="first_name"
                          name="first_name"
                          value={state.first_name}
                          onChange={eventHandler}
                          autoComplete="off"
                        />

                        {errors.first_name && (
                          <span className="text-red small d-block">
                            * {errors.first_name}
                          </span>
                        )}
                      </div>
                      <div className="mt-18 col-md-6">
                        <input
                          type="text"
                          className={classNames("form-control team-name", {
                            error: errors.last_name,
                          })}
                          placeholder="Account Name"
                          id="last_name"
                          name="last_name"
                          value={state.last_name}
                          onChange={eventHandler}
                          autoComplete="off"
                        />

                        {errors.last_name && (
                          <span className="text-red small d-block">
                            * {errors.last_name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="mt-18">
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          error: errors.account_name,
                        })}
                        placeholder="Account Name"
                        id="account_name"
                        name="account_name"
                        value={state.account_name}
                        onChange={eventHandler}
                        autoComplete="off"
                      />

                      {errors.account_name && (
                        <span className="text-red small d-block">
                          * {errors.account_name}
                        </span>
                      )}
                    </div>
                    <div className=" mt-18">
                      <ThemeProvider theme={materialTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            placeholder="MM/DD/YYYY"
                            format="MM/dd/yyyy"
                            id={"dob"}
                            mask="__/__/____"
                            inputVariant="outlined"
                            InputAdornmentProps={{ position: "end" }}
                            className={classNames("form-control team-name", {
                              error: errors.dob,
                            })}
                            onChange={(data) => {
                              dobHandler("dob", data);
                            }}
                            style={{
                              fontSize: "0.8125rem",
                            }}
                            value={state.dob}
                          />
                        </MuiPickersUtilsProvider>
                      </ThemeProvider>
                      {errors.dob && (
                        <span className="text-red small d-block">
                          * {errors.dob}
                        </span>
                      )}
                    </div>

                    <div className="row mt-18 mx-0">
                      <div className="col-md-12 mb-3">
                        <div className="">
                          <input
                            type="text"
                            className={classNames("form-control team-name", {
                              error: errors.address,
                            })}
                            placeholder="Address *"
                            id="address"
                            name="address"
                            value={state.address}
                            onChange={eventHandler}
                            autoComplete="off"
                          />
                          {errors.address && (
                            <span className="text-red small d-block">
                              * {errors.address}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mt-18">
                        <select
                          className={classNames("form-control team-name", {
                            error: errors.state,
                          })}
                          placeholder="State *"
                          id="state"
                          name="state"
                          value={state.state}
                          onChange={eventHandler}
                        >
                          <option value="">State *</option>
                          {allstate.map((v, i) => {
                            return (
                              <option key={v._id} value={v._id}>
                                {v.name}
                              </option>
                            );
                          })}
                        </select>

                        {errors.state && (
                          <span className="text-red small d-block">
                            * {errors.state}
                          </span>
                        )}
                      </div>

                      <div className="col-md-6 mt-18">
                        <input
                          type="text"
                          list="cityNameList"
                          placeholder="City"
                          id="city"
                          name="city"
                          className={classNames("form-control team-name", {
                            error: errors.city,
                          })}
                          value={state.city}
                          onChange={eventHandler}
                        />
                        {errors.city && (
                          <span className="text-red small d-block">
                            * {errors.city}
                          </span>
                        )}
                        <datalist id="cityNameList">
                          {allcity.map((e, i) => {
                            return <option value={e.name} key={i} />;
                          })}
                        </datalist>
                      </div>
                      <div className="col-md-6 mt-18">
                        <input
                          type="tel"
                          className={classNames("form-control team-name", {
                            error: errors.zip,
                          })}
                          placeholder="Zip Code *"
                          id="zip"
                          name="zip"
                          value={state.zip}
                          onChange={eventHandler}
                          autoComplete="off"
                          maxLength="5"
                        />

                        {errors.zip && (
                          <span className="text-red small d-block">
                            * {errors.zip}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="mt-18">
                      <input
                        type="email"
                        className={classNames("form-control team-name", {
                          error: errors.email,
                        })}
                        placeholder="Email*"
                        id="email"
                        name="email"
                        value={state.email}
                        onChange={eventHandler}
                        autoComplete="off"
                      />

                      {errors.email && (
                        <span className="text-red small d-block">
                          * {errors.email}
                        </span>
                      )}
                    </div>
                    <div className="mt-18">
                      {/* <input type="text" className={classNames("form-control team-name", { "error": errors.mobile })} placeholder="Mobile No. *" id="mobile" name="mobile" value={state.mobile} onChange={eventHandler} autoComplete="off"/> */}
                      <InputMask
                        mask="999-999-9999"
                        className={classNames("form-control team-name", {
                          error: errors.mobile,
                        })}
                        value={state.mobile}
                        onChange={eventHandler}
                        maskChar={"_"}
                        placeholder="Mobile No. *"
                        name="mobile"
                        id="mobile"
                      />

                      {errors.mobile && (
                        <span className="text-red small d-block">
                          * {errors.mobile}
                        </span>
                      )}
                    </div>

                    <div className="mt-18">
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          error: errors.website,
                        })}
                        placeholder="Website"
                        id="website"
                        name="website"
                        value={state.website}
                        onChange={eventHandler}
                        autoComplete="off"
                      />

                      {errors.website && (
                        <span className="text-red small d-block">
                          * {errors.website}
                        </span>
                      )}
                    </div>
                    <div className="mt-18">
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          error: errors.desc,
                        })}
                        placeholder="Description *"
                        id="desc"
                        value={state.desc}
                        name="desc"
                        onChange={eventHandler}
                        autoComplete="off"
                      />
                      {errors.desc && (
                        <span className="text-red small d-block">
                          * {errors.desc}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="payment mt-4">
                <strong>Contact Person Details</strong>
              </h6>

              <div className="row col-md-6 px-0 mx-0">
                {state.primary_contact.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="mt-18 col-md-12">
                        <input
                          type="text"
                          className={classNames("form-control team-name", {
                            error: errors.primary_contact[i]?.first_name,
                          })}
                          placeholder="First Name"
                          name="first_name"
                          value={e.first_name}
                          onChange={(e) => handleprimary_contact(e, i)}
                          autoComplete="off"
                        />
                        {errors.primary_contact[i]?.first_name && (
                          <span className="text-red small d-block">
                            * {errors.primary_contact[i]?.first_name}
                          </span>
                        )}
                      </div>
                      <div className="mt-18 col-md-12">
                        <input
                          type="text"
                          className={classNames("form-control team-name", {
                            error: errors.primary_contact[i]?.middle_name,
                          })}
                          placeholder="Middle Name"
                          id="middle_name"
                          name="middle_name"
                          value={e.middle_name}
                          onChange={(e) => handleprimary_contact(e, i)}
                          autoComplete="off"
                        />
                        {/* {errors.middle_name && <span className='text-red small d-block'>* {errors.middle_name}</span>} */}
                      </div>
                      <div className="mt-18 col-md-12">
                        <input
                          type="text"
                          className={classNames("form-control team-name", {
                            error: errors.primary_contact[i]?.last_name,
                          })}
                          placeholder="Last Name"
                          id="last_name"
                          name="last_name"
                          value={e.last_name}
                          onChange={(e) => handleprimary_contact(e, i)}
                          autoComplete="off"
                        />
                        {errors.primary_contact[i]?.last_name && (
                          <span className="text-red small d-block">
                            * {errors.primary_contact[i]?.last_name}
                          </span>
                        )}
                      </div>
                      <div className="mt-18 col-md-12">
                        <input
                          type="email"
                          className={classNames("form-control team-name", {
                            error: errors.primary_contact[i]?.email,
                          })}
                          placeholder="Email"
                          id="email"
                          name="email"
                          value={e.email}
                          onChange={(e) => handleprimary_contact(e, i)}
                          autoComplete="off"
                        />
                        {errors.primary_contact[i]?.email && (
                          <span className="text-red small d-block">
                            * {errors.primary_contact[i]?.email}
                          </span>
                        )}
                      </div>
                      {state.primary_contact.length > 1 && (
                        <div
                          className="text-right col-12 "
                          style={{ marginTop: "14px" }}
                          onClick={() => handleRemove(i)}
                        >
                          <Link
                            to="#"
                            className="text-decoration-none text-danger"
                          >
                            - Remove
                          </Link>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
                <div
                  className="text-right col-12 add-more"
                  style={{ marginTop: "14px" }}
                  onClick={handleAdd}
                >
                  <Link to="#" className="text-decoration-none">
                    + Add More
                  </Link>
                </div>
              </div>

              <Toaster />
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(OrgAccountDetail)

const mapStateToProp = (state) => ({
  editAccount: state.editAccountDetailsReducer,
  accountDetails: state.accountDetailsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // editPaymentCodeHandler: user => dispatch(editPaymentCode(user)),
  getAccountDetails: (user) => dispatch(getAccountDetails(user)),
  getAllStateHandler: (user) => dispatch(getAllState()),
  getAllCityHandler: (user) => dispatch(getAllCity(user)),
  editOrgDetailsHandler: (user) => dispatch(editAccountDetails(user)),
  resetEditAccountDetails: () => dispatch({ type: RESET_ACCOUNT_DETAILS }),
  // getAccountDetails: (user) => dispatch(getAccountDetails(user)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(OrgAccountDetail));
