import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
// import $ from 'jquery'
// import classNames from "classnames";
import { connect, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useParams } from 'react-router';
import InputMask from "react-input-mask";
import SuccessPopup from '../../Registration/SuccessPopup';
import { RESET_PAYNOW } from '../../../service/constants';
import { payNow, requested_Payment } from '../../../service/action/Club/clubAction';
import Toaster from '../../../Toaster/Toaster';

const Request_Pay_Carddetails = (props) => {

    const [credit, setCredit] = useState(0)

    let [loading, setLoading] = useState(true);
    const { id, id1, id2 } = useParams();
    const history = useHistory()

    const [state, setState] = useState({
        card_number: "",
        expiry_date: "",
        cvv: "",
        card_name: "",
        is_credit_payment: ""
    })

    const [errors, setErrors] = useState({
        card_number: false,
        expiry_date: false,
        cvv: false,
        card_name: false
    })

    const handleChange = (e) => {
        const { value, id, name } = e.target

        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
        switch (name) {
            case "card_number":

                if (e.target.value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        card_number: "please enter card number"
                    }))

                    document.getElementById("card_number").classList.add("card_number")
                } else {
                    setErrors(prevState => ({
                        ...prevState,
                        card_number: false
                    }))


                }
                break;

            default:
                return state
        }


    }


    const teamlist = useSelector(state => state.teamArrayReducer.user)

    const collectionData = useSelector(state => state.sendPaymentInfoReducer.user)
  

    const notify = (msg) => toast(msg);

  

    // onRefresh Page redirect to back
    useEffect(() => {
        if (collectionData === null) {
            history.push(`/requestpay/${id}`)
        }
    }, [])



    const paynow = (e) => {
        e.preventDefault()
        // setShow(!show)

        props.paynowHandler({
            club: collectionData.club,
            event: collectionData.event,
            payment_code: collectionData.payment_code,
            organizer: collectionData.organizer,
            payment_request: collectionData.payment_request,
            transaction_type:"sale",
            amount: collectionData.amount,
            payment_term:collectionData.payment_term,

            credit_note: collectionData.credit_note,

            card_number: state.card_number,
            expiration_date: state.expiry_date,
            name_on_code: state.card_name,
            card_verification_number: state.cvv,
            is_card_payment: id1,
            card_type: credit
        })
    }

    useEffect(() => {



        if (props.paynowCheck?.success) {
            props.resetPaynowReducer()
            setShow(!show)


        }
        else if (props.paynowCheck?.error) {
            notify("error")
        }

    }, [props.paynowCheck])

    const [show, setShow] = useState(false)

    useEffect(() => {
        if (props.accept?.success) {
            notify("accept succesfull")

            props.resetAcceptInvitationReducer()
            // history.push("/dashboard")
            setShow(!show)

        }
    }, [props.accept])






    return (


        <React.Fragment>
            <div className="main-panel">
                {/* <div className="content-wrapper"> */}
                <div className="container-fluid page-body-wrapper  full-page-wrapper">
                    <div className="content-wrapper d-flex  p-4 mb-4 bg-white my-4">

                        <div className="row flex-grow">
                            <div className="col-lg-6 col-md-12  mx-auto mt-24">
                                {show && <SuccessPopup
                                    content={<>
                                        {/* <div className="container"> */}
                                        <div className="row">
                                            <div className="col-md-6 mx-auto mt-5">
                                                <div className="payment-1">
                                                    <div className="payment_header-1">
                                                        <div className="check-1"><i className="fa fa-check" aria-hidden="true"></i></div>
                                                    </div>
                                                    <div className="content-1">
                                                        <h1>Payment Successfull !</h1>

                                                        <Link to="/dashboard">Go to Dashboard</Link>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* </div> */}
                                        </div></>} />}
                                {
                                    !show && <>
                                        <div className=" text-left pl-5 pr-5 pb-3 shadow pt-3">
                                            <div className="team-details">

                                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">

                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link active" id="dm" data-toggle="pill" href="#dm-tab" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => setCredit(0)}>Credit Card</a>
                                                    </li>

                                                    {/* <li className="nav-item" role="presentation">
                                                        <a className="nav-link" id="=notification" data-toggle="pill" href="#notification-tab" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => setCredit(1)}>Debit Card</a>
                                                    </li> */}

                                                </ul>
                                            </div>

                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="dm-tab" role="tabpanel" aria-labelledby="pills-home-tab">

                                                    <h6 className=" text-center dash-head">Credit Card Details</h6>
                                                    <form className="pt-3">
                                                        {/* <div className="form-group">

                                        </div> */}



                                                        <label className="text-gray fs-13">Card Number<span className="text-red fw-m">*</span></label>

                                                        <div className="mb-3 text-center">
                                                            <InputMask mask="9999 9999 9999 9999" className='form-control team-name quantity' value={state.card_number} onChange={handleChange} maskChar={"_"} placeholder="xxxx xxxx xxxx 8888 " name="card_number" id="card_number" />
                                                            {/* <input type="number" minLength="8" maxLength="8" className="form-control team-name quantity" id="card_number" name="card_number" placeholder="xxxx xxxx xxxx 8888 " required /> */}
                                                        </div>

                                                        <div className='row'>
                                                            <div className=' col-md-4 col-sm-6'>

                                                                <label className="text-gray fs-13">Valid Through <span className="text-red fw-m">*</span></label>
                                                                <div className="mb-3 text-center">
                                                                    <InputMask mask="9999" className='form-control team-name quantity' value={state.expiry_date} onChange={handleChange} maskChar={"_"} placeholder="MM/YY " name="expiry_date" id="expiry_date" />

                                                                    {/* <input type="number" className="form-control team-name quantity" id="valid_through" name="season_name" placeholder="MM/YY " required /> */}
                                                                </div>
                                                            </div>
                                                            <div className=' col-md-4  col-sm-6 '>

                                                                <label className="text-gray fs-13">CVV <span className="text-red fw-m">*</span></label>
                                                                <div className="mb-3 text-center">
                                                                    <InputMask mask="999" className='form-control team-name quantity' value={state.cvv} onChange={handleChange} maskChar={"_"} placeholder="cvv " name="cvv" id="cvv" />
                                                                    {/* <input type="number" className="form-control team-name quantity" id="cvv" name="cvv" placeholder="cvv " required /> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <label className="text-gray fs-13">Card Holder Name<span className="text-red fw-m">*</span></label>
                                                        <div className="mb-3 text-center">
                                                            <input type="text" className="form-control team-name" id="card_name" name="card_name" placeholder="Card Holder Name " required onChange={handleChange} value={state.card_name} />
                                                        </div>


                                                        <div className="mt-3 text-center">
                                                            {/* <Link to={`/carddetails`} className='text-decoration-none'> */}
                                                            <button className='mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn ' onClick={paynow}  >
                                                                Pay
                                                            </button>
                                                            {/* </Link> */}
                                                        </div>

                                                    </form>
                                                </div>

                                                <div className="tab-pane fade" id="notification-tab" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                    <h6 className=" text-center dash-head">Debit Card Details</h6>
                                                    <form className="pt-3">
                                                        {/* <div className="form-group">

                                        </div> */}



                                                        <label className="text-gray fs-13">Card Number<span className="text-red fw-m">*</span></label>

                                                        <div className="mb-3 text-center">
                                                            <InputMask mask="9999 9999 9999 9999" className='form-control team-name quantity' value={state.card_number} onChange={handleChange} maskChar={"_"} placeholder="xxxx xxxx xxxx 8888 " name="card_number" id="card_number" />
                                                            {/* <input type="number" minLength="8" maxLength="8" className="form-control team-name quantity" id="card_number" name="card_number" placeholder="xxxx xxxx xxxx 8888 " required /> */}
                                                        </div>

                                                        <div className='row'>
                                                            <div className=' col-md-4 col-sm-6'>

                                                                <label className="text-gray fs-13">Valid Through <span className="text-red fw-m">*</span></label>
                                                                <div className="mb-3 text-center">
                                                                    <InputMask mask="9999" className='form-control team-name quantity' value={state.expiry_date} onChange={handleChange} maskChar={"_"} placeholder="MM/YY " name="expiry_date" id="expiry_date" />

                                                                    {/* <input type="number" className="form-control team-name quantity" id="valid_through" name="season_name" placeholder="MM/YY " required /> */}
                                                                </div>
                                                            </div>
                                                            <div className=' col-md-4  col-sm-6 '>

                                                                <label className="text-gray fs-13">CVV <span className="text-red fw-m">*</span></label>
                                                                <div className="mb-3 text-center">
                                                                    <InputMask mask="999" className='form-control team-name quantity' value={state.cvv} onChange={handleChange} maskChar={"_"} placeholder="cvv " name="cvv" id="cvv" />
                                                                    {/* <input type="number" className="form-control team-name quantity" id="cvv" name="cvv" placeholder="cvv " required /> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <label className="text-gray fs-13">Card Holder Name<span className="text-red fw-m">*</span></label>
                                                        <div className="mb-3 text-center">
                                                            <input type="text" className="form-control team-name" id="card_name" name="card_name" placeholder="Card Holder Name " required onChange={handleChange} value={state.card_name} />
                                                        </div>


                                                        <div className="mt-3 text-center">
                                                            {/* <Link to={`/carddetails`} className='text-decoration-none'> */}
                                                            <button className='mr-1 btn btn-primary btn-lg font-weight-medium auth-form-btn ' onClick={paynow}  >
                                                                Pay
                                                            </button>
                                                            {/* </Link> */}
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>



                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <Toaster />
                    </div>
                </div>
                <div className="auth-form-light text-left p-5">
                </div>
                {/* </div> */}
            </div>






        </React.Fragment>
    )
}

// export default TeamsCoches;

const mapStateToProps = (state) => ({
    paynowCheck: state.paynowReducer,
    accept: state.acceptInvitationReducer
})

const mapDispatchToProps = dispatch => ({
 
    // discountCodehandler: user => dispatch(discountCode(user)),
    // resetregisteredSelectedTeamReducer: () => dispatch({ type: RESET_REGISTER_SELECTED_TEAM }),
    paynowHandler: user => dispatch(requested_Payment(user)),
    // changestatusHandler: user => dispatch(Club_changeStatus(user)),
    // resetAcceptInvitationReducer: () => dispatch({ type: RESET_ACCEPT_INVITATION }),
    resetPaynowReducer: () => dispatch({ type: RESET_PAYNOW })

})

export default connect(mapStateToProps, mapDispatchToProps)((Request_Pay_Carddetails))