import { GET_CONNECTED_USER_CLUB_ERROR, GET_CONNECTED_USER_CLUB_LOADING, GET_CONNECTED_USER_CLUB_SUCCESS, RESET_GET_CONNECTED_USER} from "../../../constants";


let initialState = {
  loading: false,
  user: {},
  error: false,
  errors: [],
  success: false
  
  
};

export default function connectedUserClubReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONNECTED_USER_CLUB_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false,success:false};
     
    case GET_CONNECTED_USER_CLUB_SUCCESS:  
      // alert(" reducer Error")
      return { ...state, loading: false ,success:true,user:action.payload};
 
    case GET_CONNECTED_USER_CLUB_ERROR:
      // alert(" reducer Loading")
      return { ...state,error: true, errors: action.errors };
   
      case RESET_GET_CONNECTED_USER:
        return{ ...state,error:false,loading:false,success:false,user:{}}
    default:
      return state;
  }
}


