
import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
// import { getOrgClubList } from '../../service/action/userAction';
import { getClubsOrgList } from '../../service/action/Club/clubAction';
import Spinner from '../../Spinner/Spinner';
import BreadcrumbHistory from '../../BreadcumbHistory/BreadcrumbHistory';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
// import {getOrgClubList} from '../../../service/action/userAction'

const OrgList = (props) => {

    const getOrgList = props.getClub_OrgListHandler

    const [loading, setLoading] = useState(true)
    const location = useLocation()
    // const ClubList=useSelector(state=>state.getOrgClubListReducer.user.data)
    const Org_List = useSelector(state => state.getClubsOrgListReducer.user.data)

    useEffect(() => {
        if (props.OrgList?.success) {
            setLoading(false)
        }
    }, [props.OrgList])

    useEffect(() => {
        getOrgList()
    }, [])


    return (
        <React.Fragment>
            <div className="main-panel">
                <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />

                <div className="content-wrapper">

                    <div className='d-flex justify-content-center'>

                        {loading ? <Spinner /> : null}
                    </div>
                    {
                        !loading && <>

                            <div className="text-left">

                                <div className="create-team-head-event mb-3">
                                    <h5 className="dash-head">Liability Waiver List</h5>
                                    {/* <div className="dropdown details-drp"> */}
                                    {/* <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add More</i> */}
                                    {/* <Link className="dropdown-item" to={`/club-new`}> <i className="mdi mdi-plus mr-2 website-ico"></i>Add Staff</Link> */}

                                    {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <Link className="dropdown-item" to={`/add-staff`}> <i className="mdi mdi-plus mr-2 website-ico"></i>Add Staff</Link>
                                </div> */}
                                    {/* </div> */}
                                </div>

                                {/* <button className="btn-save " >Create</button> */}
                                {/* <div className="dropdown details-drp" style={{ "width": "200px" }}>
                                    <Link className="dropdown-item" style={{ "padding": "8px" }} to={`/club-liability`}> <i className="mdi mdi-plus website-ico"></i>Add Liability Terms</Link>
                                </div> */}
                            </div>

                            {/* desktop view */}
                            <div className="table-responsive d-none d-md-block">
                                {
                                    Org_List.length == 0 ? <>
                                        <div className='container d-flex justify-content-center align-items-center'>
                                            <span className='noti-type'>No liability to display</span>
                                        </div></> : <>

                                        <table className="table event-table mt-22">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    {/* <th>Phone No.</th>
                                                        <th>Payment</th>
                                                        <th>Status</th> */}
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Org_List &&
                                                    Org_List.map((v, i) => {
                                                        return <tr key={v._id}>
                                                            <td>
                                                                <div className="d-flex align-items-center justify-content-start">
                                                                    <div className="dashboard-img">
                                                                        <img src={v?.organizer?.profile_image !== "" ? v?.organizer?.profile_image : "./images/pch-images/pch.svg"} />

                                                                    </div>
                                                                    <div className='ml-12'>
                                                                        {/* <Link className="fw-m  text-black text-decoration-none" to={`/liabilityterms/${v.organizer._id}`}>{v.title}</Link> */}
                                                                        <div className="fw-m text-black text-decoration-none">{v.title}</div>
                                                                        <p className='text-gray mb-0'>{v?.organizer?.name}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td><a className="text-black" href={v.url !== "" ? v.url : ""} target="_blank" > {v.url !== "" ? <><i className="fas fa-download mr-1 text-decoration-none " style={{ "color": "#143D4A" }}></i>Download</> : ""}</a></td>
                                                            <td>{v.is_approve == true ? "Accepted" : "Not Accepted"}</td>
                                                        </tr>
                                                    })
                                                }


                                            </tbody>
                                        </table>
                                    </>

                                }
                            </div>

                            {/* mobile view */}

                            <div className="mt-30 d-md-none">
                                {Org_List &&
                                    Org_List.map((v, i) => {
                                        return <div className="reg-details" key={v._id}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex">
                                                    <div className="dashboard-img mr-3">
                                                        <img src={v.organizer?.profile_image !== "" ? v.organizer?.profile_image : "./images/pch-images/pch.svg"} />

                                                    </div>
                                                    <div className="reg-content">
                                                        {/* <Link className="fw-m  text-black text-decoration-none" to={`/liabilityterms/${v.organizer._id}`}>{v.title}</Link> */}
                                                        <div className="fw-m text-black text-decoration-none">{v.title}</div>
                                                        <p className='text-gray mb-0'>{v?.organizer?.name}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a className="" href={v.url !== "" ? v.url : false} target={v.url !== "" && "_blank"} >{<i className="mdi mdi-file-check text-black" style={{ fontSize: '25px' }}></i>}</a>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

// export default withRouter(Club);

const mapStateToProps = (state) => ({

    OrgList: state.getClubsOrgListReducer

})

const mapDispatchToProps = dispatch => ({
    getClub_OrgListHandler: user => dispatch(getClubsOrgList(user))
    // userDataHandler: user => dispatch(userData(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrgList))