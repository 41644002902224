import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getClubDashBoard,
  getClubTeam,
  playerAcceptList,
} from "../../../../service/action/Club/clubAction";
import ClubDashboardStatsCard from "./ClubDashboardStatsCard";

const DashboardStats = ({ setLoading }) => {
  const [pendingAmount, setPendingAmount] = useState(null);
  const [receiveAmount, setReceiveAmount] = useState(null);
  const [playerMember, setPlayerMember] = useState([]);
  const dispatch = useDispatch();

  //   State Selectors

  const getClubReducer = useSelector((state) => state.clubDashBoardReducer);
  const getClubDashboard = useSelector(
    (state) => state.clubDashBoardReducer.user.data
  );

  const acceptPlayerList = useSelector(
    (state) => state.playerAcceptListReducer.user.data
  );
  const getClubUser = useSelector((state) => state.clubDashBoardReducer.user);
  const allTeam = useSelector((state) => state.getClubAllTeamReducer.user);

  // Side Effects

  //   fetching intial club dashboard data

  useEffect(() => {
    dispatch(getClubDashBoard());
    dispatch(playerAcceptList());
  }, []);

  useEffect(() => {
    if (getClubReducer.success) {
      setLoading(false);
      dispatch(getClubTeam({ id: getClubUser.user._id }));
    }
  }, [getClubReducer]);

  useEffect(() => {
    if (getClubDashboard) {
      setReceiveAmount(getClubDashboard.received);
      setPendingAmount(getClubDashboard.totalPandingAmount);
    }
  }, [getClubDashboard]);

  useEffect(() => {
    if (acceptPlayerList) {
      const list = acceptPlayerList.filter((e) => e.club_player === true);
      setPlayerMember(list);
    }
  }, [acceptPlayerList]);

  return (
    <div className="row mx-0 space-y-2">
      <div className="col-md-6">
        <div className="text-left mt-30">
          <h6 className="payment">Payments</h6>
          <div className="payment-content form-row">
            <ClubDashboardStatsCard
              pathname={"/payment/report"}
              state={"=outStanding"}
              count={pendingAmount}
              text={"Outstanding"}
            />
            <ClubDashboardStatsCard
              pathname={"/payment/report"}
              state={"=recevied"}
              count={receiveAmount}
              text={"Received"}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        {getClubDashBoard && (
          <div className="text-left payment-head">
            <h6 className="payment">My Club</h6>
            <div className="payment-content form-row">
              {getClubDashboard ? (
                <>
                  <ClubDashboardStatsCard
                    count={allTeam.length}
                    pathname={"/teams"}
                    text={"Teams"}
                  />
                  <ClubDashboardStatsCard
                    count={playerMember.length}
                    text={"Players"}
                    pathname={"/teams"}
                    state="=players"
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardStats;
