import {
  GET_PAYMENT_METHOD_ERROR,
  GET_PAYMENT_METHOD_LOAING,
  GET_PAYMENT_METHOD_SUCCESS,
  RESET_GET_PAYMENT_METHOD,
} from "../../constants";

let initialState = {
  loading: false,
  data: [],
  error: false,
  errors: [],
  success: false,
};
export default function getPaymentMethodReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENT_METHOD_LOAING:
      return { ...state, loading: true };

    case GET_PAYMENT_METHOD_SUCCESS:
      return { ...state, loading: false, success: true, data: action.data };

    case GET_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        errors: action.errors,
      };

    case RESET_GET_PAYMENT_METHOD:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        errors: [],
      };

    default:
      return state;
  }
}
