import { EDIT_PAYMENT_CODE_ERROR, EDIT_PAYMENT_CODE_LOADING, EDIT_PAYMENT_CODE_SUCCESS, RESET_EDIT_PAYMENT_CODE } from "../constants"

const initialState = {
    error: false,
    success: false,
    user: {
        amount: '',
        name: '',
        payment_terms: '',
        payment_terms_type: ''
    },
    loading: false
}

export default function editPaymentCodeReducer(state = initialState, action) {
    switch (action.type) {
        case EDIT_PAYMENT_CODE_LOADING:
            // alert("edit reducer Loading")
            return { ...state, loading: true, error: false, success: false };
        
        case EDIT_PAYMENT_CODE_SUCCESS:
            // alert("edit reducer success")
            return { ...state, success: true, user: action.data , message:action.msg };
     
        case EDIT_PAYMENT_CODE_ERROR:
            // alert("edit reducer Error")
            return { ...state, error: true, error: action.error };
        
        case RESET_EDIT_PAYMENT_CODE:
            return {...state,error:false,success:false,loading:false}

        default:
            return state;
    }
}