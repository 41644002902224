import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import { withRouter, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { connect, useSelector } from "react-redux";
import {
  editDivision,
  getAllDivision,
  getEventDetails,
} from "../../../service/action/userAction";
import { RESET_EDIT_DIVISION } from "../../../service/constants";
import classNames from "classnames";

const Org_EditDivision = (props) => {
  let history = useHistory();
  const { id, id1 } = useParams();

  const [currentEvent, setCurrentEvent] = useState(null);
  const [currentDivision, setCurrentDivision] = useState(null);
  const [alldivisionteam, setalldivisionteam] = useState();
  const [alldivisionplayer, setalldivisionplayer] = useState();
  const [state, setState] = useState({
    division_name: "",
    division_type: "",
    max_participated: "",
    max_team: "",
  });


  const [errors, setErrors] = useState({
    division_name: false,
    division_type: false,
    max_participated: false,
    max_team: false,
  });

  useEffect(() => {
    if (id) {
      props.getAllDivisionHandler({ id: id });
      props.getEventDetails({ id: id });
    }
  }, []);

  useEffect(() => {
    if (props.getOrgEventDetailsByIdReducer.success) {
      const data = props.getOrgEventDetailsByIdReducer.user;
      setCurrentEvent(data.data);
    }
  }, [props.getOrgEventDetailsByIdReducer]);

  useEffect(() => {
    if (props.getAllDivisionReducer.success) {
      const data = props.getAllDivisionReducer.user;
      const current = data.data.find((e) => e._id == id1);
      const _dataTeam = data.data
        .filter((e) => e.type == 0 && e._id != id1)
        ?.map((a) => {
          return a?.max_participated;
        });
      const _dataPlayer = data.data
        .filter((e) => e.type == 1 && e._id != id1)
        ?.map((a) => {
          return a?.max_participated;
        });
      setalldivisionteam(
        _dataTeam.length != 0 ? _dataTeam?.reduce((a, b) => a + b) : 0
      );
      setalldivisionplayer(
        _dataPlayer.length != 0 ? _dataPlayer?.reduce((a, b) => a + b) : 0
      );
      setCurrentDivision(current);
    }
  }, [props.getAllDivisionReducer]);

  useEffect(() => {
    if (!!currentDivision) {
      const type = currentDivision?.type;
      const name = currentDivision?.division_name;
      const max_participated = currentDivision?.max_participated;
      setState((pre) => ({
        ...pre,
        division_type: type,
        division_name: !!name ? name : "",
        max_participated: !!max_participated ? max_participated : "",
      }));
    }
  }, [currentDivision]);

  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    if (name == "max_participated") {
      if (!isNaN(value)) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    switch (name) {
      case "division_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            division_name: "Division name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            division_name: false,
          }));
          // document.getElementById("division_name").classList.remove("error");
        }
        break;
      case "division_type":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            division_type: "Division name can not be empty",
          }));

          // document.getElementById("division_type").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            division_type: false,
          }));
          // document.getElementById("division_type").classList.remove("error");
        }
        break;

      case "max_participated":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            max_participated: "Division name can not be empty",
          }));
        } else if (state.division_type == 0) {
          if (
            currentEvent?.event_max_team - alldivisionteam <
            parseInt(value)
          ) {
            setErrors((preve) => ({
              ...preve,
              max_participated: `Max team is less than  ${
                currentEvent?.event_max_team - alldivisionteam
              }`,
            }));
          } else {
            setErrors((prevState) => ({
              ...prevState,
              max_participated: false,
            }));
          }
        } else if (state.division_type == 1) {
          if (
            currentEvent?.event_max_player - alldivisionplayer <
            parseInt(value)
          ) {
            setErrors((preve) => ({
              ...preve,
              max_participated: `Max palyer is less than  ${
                currentEvent?.event_max_player - alldivisionplayer
              }`,
            }));
          } else {
            setErrors((prevState) => ({
              ...prevState,
              max_participated: false,
            }));
          }
        } else {
          setErrors((prevState) => ({
            ...prevState,
            max_participated: false,
          }));
          // document.getElementById("max_participated").classList.remove("error");
        }
        break;

      default:
    }
  };

  const notify = (msg) => toast(msg);

  const submitRequest = (e) => {
    e.preventDefault();
    if (state.division_name == "") {
      setErrors((preve) => ({
        ...preve,
        division_name: "Enter Division Name",
      }));
    }
    if (state.division_type === "") {
      setErrors((preve) => ({
        ...preve,
        division_type: "Error",
      }));
    }
    if (state.max_participated === "") {
      setErrors((preve) => ({
        ...preve,
        max_participated:
          state.division_type === 0 ? "Enter Max Team" : "Enter Max Player",
      }));
    }

    if (state.division_type === 0) {
      if (
        currentEvent?.event_max_team - alldivisionteam <
        parseInt(state.max_participated)
      ) {
        setErrors((preve) => ({
          ...preve,
          max_participated: `Max team is less than  ${
            currentEvent?.event_max_team - alldivisionteam
          }`,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          max_participated: false,
        }));
      }
    }

    if (state.division_type === 1) {
      if (
        currentEvent?.event_max_player - alldivisionplayer <
        parseInt(state.max_participated)
      ) {
        setErrors((preve) => ({
          ...preve,
          max_participated: `Max palyer is less than  ${
            currentEvent?.event_max_player - alldivisionplayer
          }`,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          max_participated: false,
        }));
      }
    }

    if (
      state.division_name !== "" &&
      state.division_type !== "" &&
      state.max_participated !== "" &&
      !errors?.max_team &&
      !errors?.max_participated
    ) {
      props.editDivisionhandler({
        id1,
        event_id: id,
        division_name: state.division_name,
        type: state.division_type,
        max_team: state.max_team,
        max_participated: state.max_participated,
      });
      // if (props.submitRequest?.success) {
      //     notify("Data submitted successfully.")
      // }
      // resetData().then(clearState);
    }
    // eventHandler(e);
  };

  useEffect(() => {
    if (props.submit?.success) {
      history.push(`/event-division-details/${id}/${id1}`);
      props.resetEditDivisionReducer();
    } else if (props.submit?.error) {
      notify("Division Update Error");
    }
  }, [props.submit]);

  // useEffect(() => {
  //   if (state.division_type == 0 && state.max_participated !== "") {
  //     if (
  //       currentEvent?.event_max_team - alldivisionteam <
  //       parseInt(state.max_participated)
  //     ) {
  //       setErrors((preve) => ({
  //         ...preve,
  //         max_participated: `Max team is less than  ${
  //           currentEvent?.event_max_team - alldivisionteam
  //         }`,
  //       }));
  //     } else {
  //       setErrors((prevState) => ({
  //         ...prevState,
  //         max_participated: false,
  //       }));
  //     }
  //   } else if (state.division_type == 1 && state.max_participated !== "") {
  //     if (
  //       currentEvent?.event_max_player - alldivisionplayer <
  //       parseInt(state.max_participated)
  //     ) {
  //       setErrors((preve) => ({
  //         ...preve,
  //         max_participated: `Max palyer is less than  ${
  //           currentEvent?.event_max_player - alldivisionplayer
  //         }`,
  //       }));
  //     } else {
  //       setErrors((prevState) => ({
  //         ...prevState,
  //         max_participated: false,
  //       }));
  //     }
  //   }
  // }, [state.division_type]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Edit Division</h5>
          </div>

          {!!currentEvent && (
            <div>
              <div className="row mx-0">
                <div className="col-md-4 col-xl-4">
                  <div className="mt-24">
                    <div className="text-left mb-3">
                      <select
                        // className="form-control"
                        value={state.division_type}
                        id="division_type"
                        name="division_type"
                        onChange={eventHandler}
                        className={classNames("form-control", {
                          error: errors.division_type,
                        })}
                      >
                        <option disabled value={""}>
                          Division Type
                        </option>
                        {(currentEvent?.event_type == 0 ||
                          currentEvent?.event_type == 2) && (
                          <option value={"0"}>Club</option>
                        )}
                        {(currentEvent?.event_type == 1 ||
                          currentEvent?.event_type == 2) && (
                          <option value={"1"}>Parent/player</option>
                        )}
                      </select>
                    </div>
                    {errors.division_type && (
                      <span className="text-red small d-block">
                        * {errors.division_type}
                      </span>
                    )}
                    <>
                      <div className="text-left mb-3">
                        {/* <label className="text-gray fs-13">Division Name <span className="text-red fw-m">*</span></label> */}
                        <input
                          type="text"
                          // className=""
                          id="division_name"
                          name="division_name"
                          value={state.division_name}
                          placeholder="Division name"
                          required
                          onChange={eventHandler}
                          className={classNames("form-control team-name", {
                            error: errors.division_name,
                          })}
                        />

                        {errors.division_name && (
                          <span className="text-red small d-block">
                            * {errors.division_name}
                          </span>
                        )}
                      </div>
                      <div className="text-left mb-3">
                        <input
                          type="text"
                          id="max_participated"
                          name="max_participated"
                          placeholder={
                            state.division_type == 0
                              ? "Max Teams *"
                              : "Max Players *"
                          }
                          value={state.max_participated}
                          onChange={eventHandler}
                          className={classNames("form-control team-name", {
                            error: errors.max_participated,
                          })}
                        />
                        {errors.max_participated && (
                          <span className="text-red small d-block">
                            * {errors.max_participated}
                          </span>
                        )}
                      </div>

                      <div className="mt-3 text-left">
                        <a
                          className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                          onClick={submitRequest}
                        >
                          Submit
                        </a>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Toaster />
    </>
  );
};

// export default withRouter(Org_CreateDivision)

const mapStateToProp = (state) => ({
  submit: state.editDivisionReducer,
  getAllDivisionReducer: state.getAllDivisionReducer,
  getOrgEventDetailsByIdReducer: state.getOrgEventDetailsByIdReducer,
});

const mapDispatchToProps = (dispatch) => ({
  editDivisionhandler: (user) => dispatch(editDivision(user)),
  getEventDetails: (user) => dispatch(getEventDetails(user)),
  resetEditDivisionReducer: () => dispatch({ type: RESET_EDIT_DIVISION }),
  getAllDivisionHandler: (user) => dispatch(getAllDivision(user)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Org_EditDivision));
