import {
  GET_NOTIFICATION_ERROR,
  GET_NOTIFICATION_LOADING,
  GET_NOTIFICATION_SUCCESS,
} from "../../../constants";

let initialState = {
  loading: false,
  user: {},
  error: false,
  errors: [],
  success: false,
};

export default function getOrgNotificationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_NOTIFICATION_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case GET_NOTIFICATION_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };

    case GET_NOTIFICATION_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}
