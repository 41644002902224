import { combineReducers } from "redux";
import { RESET_STORE } from "./constant";

import authReducers from "./Reducer/authReducers";
import UserReducer from "./Reducer/UserReducer";
import MerchantReducer from "./Reducer/MerchantReducer";
import SportReducer from "./Reducer/SportReducer";
import TransactionReducer from "./Reducer/TransactionReducer";
import LiabilityRecucer from "./Reducer/LiabilityRecucer";

const appReducer = combineReducers({
  authReducers,
  UserReducer,
  MerchantReducer,
  SportReducer,
  TransactionReducer,
  LiabilityRecucer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export default rootReducer;
