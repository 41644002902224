import React, { useState, useEffect } from 'react'
import { Link, useHistory, Redirect, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Toaster from '../Toaster/Toaster';
import Spinner from '../Spinner/Spinner';
// import { connect } from 'react-redux';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { forgotpasswordEmail, resetPassword } from '../service/action/userAction';
import { RESET_FORGOT_PASSWORD_EMAIL, RESET_VERIFY_OTP } from '../service/constants';




const ResetPassword = (props) => {

    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)

    const notify = (msg) => toast(msg);
    const [loading, setLoading] = useState(false)
    let [color, setColor] = useState("#FFFFFF");

    const history = useHistory();

    const [state, setState] = useState({
        password: "",
        confirm_password: ""

    })

    const [errors, setErrors] = useState(
        {
            password: false,
            confirm_password: false,
            confirm_password1: false


        }
    )

    const resetPassword = useSelector(state => state.resetPasswordReducer.message)
    const resetPasswordErrors = useSelector(state => state.resetPasswordReducer.errors)
    const verifyOtp = useSelector(state => state.verifyotpReducer.message)

    const validPassword = new RegExp("^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})");


    const signin = (e) => {
        e.preventDefault();
        if (state.password == "") {
            setErrors(prevState => ({
                ...prevState,
                password: "Password can not be empty"
            }))
        }

        if (state.confirm_password == "") {
            setErrors(prevState => ({
                ...prevState,
                confirm_password: "Confirm password can not be empty"
            }))
        }

        if (!validPassword.test(state.confirm_password) || !validPassword.test(state.password)) {
            setErrors((prevState) => ({
                ...prevState,
                confirm_password1: "(Hint:Password must be at least 8 characters long with uppercase, lowercase, and special character)",
            }));
        }

        if (state.password !== "" && state.confirm_password !== "" && validPassword.test(state.confirm_password) && validPassword.test(state.password)) {
            props.ResetPasswordHandler({
                password: state.password,
                confirm_password: state.confirm_password,
                token: verifyOtp.token
            })
        }

    }

    // useEffect(() => {
    //     if (props.action?.loading) {
    //         setLoading(true)
    //     } else if (props.action?.success) {
    //         setLoading(false)
    //         history.push(`/verifyotp/${state.email}`)

    //     }else if(props.action?.error){
    //         setLoading(false)
    //         notify(forgotpassword.msg)
    //         props.resetForgotpasswordHandler()
    //     }

    // }, [props.action])

    useEffect(() => {
        if (props.reset?.loading) {
            setLoading(true)

        } else if (props.reset?.success) {
            setLoading(false)
            // forgotpasswordEmailReducer()
            props.resetVerifyotphandler()
            history.push("/")

        } else if (props.reset?.error) {
            setLoading(false)
            notify(resetPasswordErrors.confirm_password)
            // setState(prevState=>({
            //     password:"",
            //     confirm_password:""
            // }))
        }
    }, [props.reset])


    useEffect(() => {
        if (verifyOtp == undefined) {
            history.push("/forgotpassword")
        }
    }, [])




    const eventHandler = (e) => {
        const { id, value, name } = e.target

        const validEmailRegex =
            RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
        switch (name) {
            case "password":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        password: "Password can not be empty"
                    }))
                }

                else {
                    setErrors(prevState => ({
                        ...prevState,
                        password: false
                    }))
                }
                // setEmailVal(false);
                break;
            case "confirm_password":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        confirm_password: "Confirm password can not be empty",
                        confirm_password1:false
                    }))
                } else {
                    setErrors(prevState => ({
                        ...prevState,
                        confirm_password: false
                    }))
                }
                break;
        }
    }


    return (
        <React.Fragment>
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex auth login-section">
                    <div className="row flex-grow">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left p-5">
                                <div className="brand-logo text-center">
                                    <img src="./images/pch-images/PCH_Logo_Hori_Blue Blue-white.svg" alt="logo" />
                                </div>
                                <h6 className="text-white">Reset Password</h6>
                                <form className="pt-3">
                                    <div className="form-group input-group  pass-wrapper form-group-1">
                                        <input type={show1 ? "text" : "password"} className={classNames("form-control form-control-lg w-100 ", { "login-error": errors.password })} id="password" name="password" placeholder="Password" value={state.password} required onChange={eventHandler} aria-label="password" aria-describedby="button-addon2" autoComplete='off' style={{ "z-index": "1" }} />

                                        <button className="bg-transparent input-group-append position-absolute eye-button mr-1" onClick={(e) => { e.preventDefault(); setShow1(!show1) }}>
                                            {
                                                show1 ? <i className="fas fa-eye-slash eye " aria-describedby="button-addon2" ></i> :
                                                    <i className="fas fa-eye eye" aria-describedby="button-addon2"></i>
                                            }
                                        </button>
                                        {/* <input type="password" className={classNames("form-control form-control-lg", { "login-error": errors.password })} id="password" name="password" value={state.password} placeholder="Password" required onChange={eventHandler} autoComplete="off" /> */}
                                        {errors.password &&
                                            <span className='text-danger small d-block'>* {errors.password}</span>}
                                    </div>
                                    <div className="form-group input-group  pass-wrapper form-group-1">
                                        <input type={show2 ? "text" : "password"} className={classNames("form-control form-control-lg w-100 ", { "login-error": errors.confirm_password })} id="confirm_password" name="confirm_password" placeholder="Confirm Password" value={state.confirm_password} required onChange={eventHandler} aria-label="confirm_password" aria-describedby="button-addon2" autoComplete='off' style={{ "z-index": "1" }} />

                                        <button className="bg-transparent input-group-append position-absolute eye-button mr-1" onClick={(e) => { e.preventDefault(); setShow2(!show2) }}>
                                            {
                                                show2 ? <i className="fas fa-eye-slash eye " aria-describedby="button-addon2" ></i> :
                                                    <i className="fas fa-eye eye" aria-describedby="button-addon2"></i>
                                            }
                                        </button>
                                        {/* <input type="password" className={classNames("form-control form-control-lg", { "login-error": errors.confirm_password })} id="confirm_password" name="confirm_password" value={state.confirm_password} placeholder="Confirm password" required onChange={eventHandler} autoComplete="off" /> */}
                                        {errors.confirm_password &&
                                            <span className='text-danger small d-block'>* {errors.confirm_password}</span>}
                                        {errors.confirm_password1 &&
                                            <span className='text-white small d-block'>* {errors.confirm_password1}</span>}
                                    </div>

                                    <div className="mt-3 text-center">
                                        <button type="submit" className="btn btn-login btn-lg font-weight-medium auth-form-btn" onClick={signin} disabled={props.action?.loading} >Forgot</button>
                                    </div>


                                    <div className='d-flex justify-content-center'>

                                        {loading ? <ClipLoader color={color} loading={loading} css={css} size={50} /> : null}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <Toaster />
                </div>
            </div>

        </React.Fragment>
    )
}

// export default ForgotPassword;

const mapStateToProp = state => ({
    action: state.forgotpasswordEmailReducer,
    reset: state.resetPasswordReducer
})

const mapDispatchToProps = dispatch => ({
    forgotpasswordHandler: user => dispatch(forgotpasswordEmail(user)),
    resetForgotpasswordHandler: () => dispatch({ type: RESET_FORGOT_PASSWORD_EMAIL }),
    ResetPasswordHandler: user => dispatch(resetPassword(user)),
    resetVerifyotphandler: () => ({ type: RESET_VERIFY_OTP })

})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(ResetPassword));
