import classNames from "classnames";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "../../../Toaster/Toaster";
import {
  createCredit,
  getBulkRecipientAction,
  getEventsRegisterTeam,
  getPaidRecipient,
} from "../../../service/action/userAction";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function NewCreditRequest() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState({
    event: "",
    credit: "",
    request_type: "", // 0 event , 1 custom
    division: "",
    pool: "",
    player: "",
    parent: "",
    team: [],
  });

  const [errors, setErrors] = useState({
    event: false,
    credit: false,
    request_type: false,
  });

  let [allCheck, setAllCheck] = useState(false);
  let [poolCheck, setPoolCheck] = useState(false);
  let [divisionCheck, setDivisionCheck] = useState(false);
  let [clubCheck, setClubCheck] = useState(false);
  let [parentCheck, setParentCheck] = useState(false);
  let [playerCheck, setPlayerCheck] = useState(false);
  let [teamCheck, setTeamCheck] = useState(false);
  let [teamCheckBoxes, setTeamCheckBox] = useState([]);
  const [type, setType] = useState([]);

  let [poolCheckBoxes, setPoolCheckBox] = useState([]);
  let [divisionCheckBoxes, setDivisionCheckBox] = useState([]);
  let [clubCheckBoxes, setClubCheckBox] = useState([]);
  let [playerCheckBoxes, setPlayerCheckBox] = useState([]);
  let [parentCheckBoxes, setParentCheckBox] = useState([]);
  const [clubRecipient, setClubRecipient] = useState([]);
  const [playerRecipient, setPlayerRecipient] = useState([]);
  const [parentRecipient, setParentRecipient] = useState([]);
  const [division, setDivisionArray] = useState([]);
  const [pool, setPoolArray] = useState([]);
  const [team, setTeamArray] = useState([]);

  const [disabled, setDisabled] = useState(false);

  const getEvent = useSelector(
    (state) => state.getEventRegisterTeamReducer.user.event
  );
  const parentPlayerRecipient = useSelector(
    (state) => state.getPaidRecipientReducer
  );
  const recipientSelector = useSelector(
    (state) => state.getBulkRecipientReducer
  );

  const createcreditReducer = useSelector((state) => state.createcreditReducer);

  useEffect(() => {
    dispatch(getEventsRegisterTeam());
    dispatch(getPaidRecipient());
  }, []);

  useEffect(() => {
    if (type.length > 0) {
      dispatch(getBulkRecipientAction({ type, event: state.event }));
    }
  }, [type, state.event]);

  useEffect(() => {
    if (recipientSelector.success) {
      setDivisionArray(recipientSelector.data.division);
      setPoolArray(recipientSelector.data.pool);
      setTeamArray(recipientSelector.data.team);
    }
  }, [recipientSelector]);

  useEffect(() => {
    if (createcreditReducer.success) {
      history.push("/report", "=credit");
    }
  }, [createcreditReducer]);

  useEffect(() => {
    if (allCheck) {
      setType([0]);
      setDivisionCheck(false);
      setPoolCheck(false);
      setTeamCheck(false);
      setParentCheck(false);
      setPlayerCheck(false);
      setClubCheck(false);
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [allCheck]);

  useEffect(() => {
    if (poolCheck) {
      if (!type.find((el) => el == 3)) setType([...type, 3]);
    } else {
      const filtered = type.filter((obj) => {
        return obj !== 3;
      });
      setType(filtered);
    }
  }, [poolCheck]);
  useEffect(() => {
    if (divisionCheck) {
      if (!type.find((el) => el == 1)) setType([...type, 1]);
    } else {
      const filtered = type.filter((obj) => {
        return obj !== 1;
      });
      setType(filtered);
    }
  }, [divisionCheck]);
  useEffect(() => {
    if (teamCheck) {
      if (!type.find((el) => el == 2)) setType([...type, 2]);
    } else {
      const filtered = type.filter((obj) => {
        return obj !== 2;
      });
      setType(filtered);
    }
  }, [teamCheck]);

  useEffect(() => {
    if (parentPlayerRecipient.success) {
      if (parentPlayerRecipient.data.data) {
        let clubs = [];
        let player = [];
        let parent = [];
        parentPlayerRecipient.data.data.map((v) => {
          if (v.type == 4) {
            clubs.push(v);
          } else if (v.type == 5) {
            player.push(v);
          } else if (v.type == 6) {
            parent.push(v);
          }
        });
        setClubRecipient(clubs);
        setPlayerRecipient(player);
        setParentRecipient(parent);
      } else return;
    }
  }, [parentPlayerRecipient]);

  const eventHandler = (e) => {
    const { name, value } = e.target;

    if (name == "credit") {
      if (!isNaN(value)) {
        setState((pre) => ({
          ...pre,
          [name]: value,
        }));
      } else return;
    } else {
      setState((pre) => ({
        ...pre,
        [name]: value,
      }));
    }

    switch (name) {
      case "credit":
        if (value == "") {
          errors["credit"] = "Required";
        } else {
          errors["credit"] = false;
        }
        break;
      default:
        break;
    }
  };

  const displayRecipientBlock = (e) => {
    const { name, checked } = e.target;
    switch (name) {
      case "all":
        setAllCheck(checked);
        break;
      case "pool":
        setPoolCheck(checked);
        break;
      case "player":
        setPlayerCheck(checked);
        break;
      case "division":
        setDivisionCheck(checked);
        break;
      case "club":
        setClubCheck(checked);
        break;
      case "parent":
        setParentCheck(checked);
        break;
      case "team":
        setTeamCheck(checked);
        break;
      default:
    }
  };

  const checkedAllcheckbox = (e) => {
    const { name } = e.target;
    switch (name) {
      case "sub-team":
        if (e.target.checked) setTeamCheckBox(team?.map((t) => t.id));
        else setTeamCheckBox([]);
        break;
      case "sub-pool":
        if (e.target.checked) setPoolCheckBox(pool?.map((t) => t._id));
        else setPoolCheckBox([]);
        break;
      case "sub-division":
        if (e.target.checked) setDivisionCheckBox(division?.map((t) => t._id));
        else setDivisionCheckBox([]);
        break;
      case "sub-club":
        if (e.target.checked) setClubCheckBox(clubRecipient?.map((t) => t._id));
        else setClubCheckBox([]);
        break;
      case "sub-player":
        if (e.target.checked)
          setPlayerCheckBox(playerRecipient?.map((t) => t._id));
        else setPlayerCheckBox([]);
        break;
      case "sub-parent":
        if (e.target.checked)
          setParentCheckBox(parentRecipient?.map((t) => t._id));
        else setParentCheckBox([]);
        break;
      default:
        break;
    }
  };

  //Team
  const handleOnChange = (event) => {
    if (teamCheckBoxes.find((id) => id == event.target.value)) {
      setTeamCheckBox(teamCheckBoxes.filter((id) => id != event.target.value));
    } else {
      setTeamCheckBox([...teamCheckBoxes, event.target.value]);
    }
  };
  //Division
  const handleDivisionOnChange = (event) => {
    if (divisionCheckBoxes.find((id) => id == event.target.value)) {
      setDivisionCheckBox(
        divisionCheckBoxes.filter((id) => id != event.target.value)
      );
    } else {
      setDivisionCheckBox([...divisionCheckBoxes, event.target.value]);
    }
  };
  //Pool
  const handlePoolOnChange = (event) => {
    if (poolCheckBoxes.find((id) => id == event.target.value)) {
      setPoolCheckBox(poolCheckBoxes.filter((id) => id != event.target.value));
    } else {
      setPoolCheckBox([...poolCheckBoxes, event.target.value]);
    }
  };
  //club
  const handleClubOnChange = (event) => {
    if (clubCheckBoxes.find((id) => id == event.target.value)) {
      setClubCheckBox(clubCheckBoxes.filter((id) => id != event.target.value));
    } else {
      setClubCheckBox([...clubCheckBoxes, event.target.value]);
    }
  };
  //player
  const handlePlayerOnChange = (event) => {
    if (playerCheckBoxes.find((id) => id == event.target.value)) {
      setPlayerCheckBox(
        playerCheckBoxes.filter((id) => id != event.target.value)
      );
    } else {
      setPlayerCheckBox([...playerCheckBoxes, event.target.value]);
    }
  };
  //parent
  const handleParentOnChange = (event) => {
    if (parentCheckBoxes.find((id) => id == event.target.value)) {
      setParentCheckBox(
        parentCheckBoxes.filter((id) => id != event.target.value)
      );
    } else {
      setParentCheckBox([...parentCheckBoxes, event.target.value]);
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    if (state.event == "") {
      setErrors((pre) => ({
        ...pre,
        event: "Required",
      }));
    }
    if (state.credit == "") {
      setErrors((pre) => ({
        ...pre,
        credit: "Required",
      }));
    }
    if (state.event !== "" && state.credit !== "") {
      const checked = {
        all: allCheck ? 0 : null,
        division: divisionCheck
          ? divisionCheckBoxes.length != 0
            ? divisionCheckBoxes
            : null
          : null,
        club: clubCheck
          ? clubCheckBoxes.length != 0
            ? clubCheckBoxes
            : null
          : null,
        pool: poolCheck
          ? poolCheckBoxes.length != 0
            ? poolCheckBoxes
            : null
          : null,
        team: teamCheck
          ? teamCheckBoxes.length != 0
            ? teamCheckBoxes
            : null
          : null,
        player: playerCheck
          ? playerCheckBoxes.length != 0
            ? playerCheckBoxes
            : null
          : null,
        parent: parentCheck
          ? parentCheckBoxes.length != 0
            ? parentCheckBoxes
            : null
          : null,
      };
      const filterNull = Object.fromEntries(
        Object.entries(checked).filter(([key, value]) => value !== null)
      );

      const details = {
        credit_amount: state.credit,
        event: state.event,
        ...filterNull,
      };
      dispatch(createCredit(details));
    }
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Credit Request</h5>
          </div>
          {!!getEvent ? (
            <>
              <form>
                <div className="row col-md-6 px-0 mt-24 mx-0  ">
                  <div className="col-md-12">
                    <>
                      <div
                        className={classNames(
                          "form-group event-drp event-type mb-3"
                        )}
                      >
                        <strong className="mb-4">
                          Event <span className="text-red fw-m">*</span>
                        </strong>

                        <select
                          className={classNames(
                            "form-control team-name payterm",
                            { error: errors.event }
                          )}
                          id="event"
                          name="event"
                          value={state.event}
                          onChange={eventHandler}
                        >
                          <option value="" disabled>
                            Event Name*
                          </option>
                          {getEvent?.map((v, i) => {
                            return (
                              <option value={v._id} key={v._id}>
                                {v.event_name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.event && (
                          <span className="text-red small d-block">
                            * {errors.event}
                          </span>
                        )}
                      </div>
                    </>

                    {state.event !== "" && (
                      <>
                        <div>
                          <strong className="mb-4">Recipient</strong>
                          <div className="form-group row mx-0">
                            <div className="col-md-4">
                              <div className="mt-2 d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  className="mr-2 bulk-checkbox"
                                  value="all"
                                  id="all"
                                  name="all"
                                  onChange={displayRecipientBlock}
                                />
                                <label htmlFor="all" className="mb-0">
                                  All
                                </label>
                              </div>
                              <div className="mt-2 d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  value="division"
                                  disabled={disabled}
                                  className="mr-2 bulk-checkbox"
                                  id="division"
                                  name="division"
                                  checked={divisionCheck}
                                  onChange={displayRecipientBlock}
                                />
                                <label htmlFor="division" className="mb-0">
                                  Division
                                </label>
                              </div>
                              <div className="mt-2  d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  disabled={disabled}
                                  className="mr-2  bulk-checkbox"
                                  value="team"
                                  id="team"
                                  name="team"
                                  checked={teamCheck}
                                  onChange={displayRecipientBlock}
                                />
                                <label htmlFor="team" className="mb-0">
                                  Team
                                </label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mt-2 d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  disabled={disabled}
                                  className="mr-2 bulk-checkbox"
                                  id="pool"
                                  value="pool"
                                  name="pool"
                                  onChange={displayRecipientBlock}
                                  checked={poolCheck}
                                />
                                <label htmlFor="pool" className="mb-0">
                                  Pool
                                </label>
                              </div>
                              <div className="mt-2 d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  disabled={disabled}
                                  className="mr-2 bulk-checkbox"
                                  id="club"
                                  name="club"
                                  value="club"
                                  checked={clubCheck}
                                  onChange={displayRecipientBlock}
                                />
                                <label htmlFor="club" className="mb-0">
                                  Club
                                </label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mt-2  d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  disabled={disabled}
                                  className="mr-2 bulk-checkbox"
                                  id="player"
                                  name="player"
                                  value="player"
                                  checked={playerCheck}
                                  onChange={displayRecipientBlock}
                                />
                                <label htmlFor="player" className="mb-0">
                                  Player
                                </label>
                              </div>
                              <div className="mt-2 d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  disabled={disabled}
                                  className="mr-2 bulk-checkbox"
                                  id="parent"
                                  name="parent"
                                  value="parent"
                                  checked={parentCheck}
                                  onChange={displayRecipientBlock}
                                />
                                <label htmlFor="parent" className="mb-0">
                                  Parent
                                </label>
                              </div>
                            </div>
                          </div>
                          {(!!divisionCheck ||
                            !!teamCheck ||
                            !!poolCheck ||
                            !!clubCheck ||
                            !!playerCheck ||
                            !!parentCheck) && (
                            <>
                              <div className="mt-3 div-tab border px-3 pb-3">
                                {!!divisionCheck && division.length > 0 && (
                                  <div className="pt-3">
                                    <input
                                      type="checkbox"
                                      className="mr-2 bulk-checkbox"
                                      id="sub-division"
                                      checked={
                                        division.length ==
                                        divisionCheckBoxes.length
                                      }
                                      onChange={checkedAllcheckbox}
                                      name="sub-division"
                                    />
                                    <label
                                      htmlFor="sub-division"
                                      className="mb-0"
                                    >
                                      <strong>Division</strong>
                                    </label>

                                    {division.map((v) => {
                                      return (
                                        <div className="mt-1 ml-3 d-flex align-items-center text-gray">
                                          <input
                                            type="checkbox"
                                            className="mr-2 bulk-checkbox"
                                            id={v._id + "division"}
                                            value={v._id}
                                            onChange={(e) =>
                                              handleDivisionOnChange(e)
                                            }
                                            name={v._id + "division"}
                                            checked={
                                              divisionCheckBoxes.find(
                                                (id) => id == v._id
                                              ) != undefined
                                            }
                                          />
                                          <label
                                            htmlFor={v._id + "division"}
                                            className="mb-0"
                                          >
                                            {v.division_name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                                {!!teamCheck && team.length > 0 && (
                                  <div className="pt-3">
                                    <input
                                      type="checkbox"
                                      className="mr-2 bulk-checkbox"
                                      checked={
                                        team.length == teamCheckBoxes.length
                                      }
                                      onChange={checkedAllcheckbox}
                                      id="sub-team"
                                      name="sub-team"
                                    />
                                    <label htmlFor="sub-team" className="mb-0">
                                      <strong>Team</strong>
                                    </label>

                                    {team.map((v, index) => {
                                      return (
                                        <div
                                          className="mt-1 ml-3 d-flex align-items-center text-gray"
                                          key={index}
                                        >
                                          <input
                                            type="checkbox"
                                            className="mr-2 bulk-checkbox"
                                            onChange={(e) => handleOnChange(e)}
                                            checked={
                                              teamCheckBoxes.find(
                                                (id) => id == v.id
                                              ) != undefined
                                            }
                                            id={v.id + "team"}
                                            value={v.id}
                                            name="team"
                                          />
                                          <label
                                            htmlFor={v.id + "team"}
                                            className="mb-0"
                                          >
                                            {v.name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                                {!!poolCheck && pool.length > 0 && (
                                  <div className="pt-3">
                                    <input
                                      type="checkbox"
                                      className="mr-2 bulk-checkbox"
                                      id="sub-pool"
                                      checked={
                                        pool.length == poolCheckBoxes.length
                                      }
                                      onChange={checkedAllcheckbox}
                                      name="sub-pool"
                                    />
                                    <label htmlFor="sub-pool" className="mb-0">
                                      <strong> Pool </strong>
                                    </label>

                                    {pool.map((v) => {
                                      return (
                                        <div className="mt-1 ml-3 d-flex align-items-center text-gray">
                                          <input
                                            type="checkbox"
                                            className="mr-2 bulk-checkbox"
                                            id={v._id}
                                            value={v._id}
                                            onChange={(e) =>
                                              handlePoolOnChange(e)
                                            }
                                            checked={
                                              poolCheckBoxes.find(
                                                (id) => id == v._id
                                              ) != undefined
                                            }
                                            name={v._id + "pool"}
                                          />
                                          <label
                                            htmlFor={v._id + "pool"}
                                            className="mb-0"
                                          >
                                            {v.pool_name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                                {!!clubCheck && clubRecipient.length > 0 && (
                                  <div className="pt-3">
                                    <input
                                      type="checkbox"
                                      className="mr-2 bulk-checkbox"
                                      checked={
                                        clubRecipient.length ==
                                        clubCheckBoxes.length
                                      }
                                      onChange={checkedAllcheckbox}
                                      id="sub-club"
                                      name="sub-club"
                                    />
                                    <label htmlFor="sub-club" className="mb-0">
                                      <strong>club</strong>
                                    </label>

                                    {clubRecipient.map((v, index) => {
                                      return (
                                        <div
                                          className="mt-1 ml-3 d-flex align-items-center text-gray"
                                          key={index}
                                        >
                                          <input
                                            type="checkbox"
                                            className="mr-2 bulk-checkbox"
                                            onChange={(e) =>
                                              handleClubOnChange(e)
                                            }
                                            checked={
                                              clubCheckBoxes.find(
                                                (id) => id == v._id
                                              ) != undefined
                                            }
                                            id={v._id + "club"}
                                            value={v._id}
                                            name="club"
                                          />
                                          <label
                                            htmlFor={v._id + "club"}
                                            className="mb-0"
                                          >
                                            {v.name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                                {!!playerCheck &&
                                  playerRecipient.length > 0 && (
                                    <div className="pt-3">
                                      <input
                                        type="checkbox"
                                        className="mr-2 bulk-checkbox"
                                        checked={
                                          playerRecipient.length ==
                                          playerCheckBoxes.length
                                        }
                                        onChange={checkedAllcheckbox}
                                        id="sub-player"
                                        name="sub-player"
                                      />
                                      <label
                                        htmlFor="sub-player "
                                        className="mb-0"
                                      >
                                        <strong> player </strong>
                                      </label>

                                      {playerRecipient.map((v, index) => {
                                        return (
                                          <div
                                            className="mt-1 ml-3 d-flex align-items-center text-gray"
                                            key={index}
                                          >
                                            <input
                                              type="checkbox"
                                              className="mr-2 bulk-checkbox"
                                              onChange={(e) =>
                                                handlePlayerOnChange(e)
                                              }
                                              checked={
                                                playerCheckBoxes.find(
                                                  (id) => id == v._id
                                                ) != undefined
                                              }
                                              id={v._id + "player"}
                                              value={v._id}
                                              name="player"
                                            />
                                            <label
                                              htmlFor={v._id + "player"}
                                              className="mb-0"
                                            >
                                              {v.name}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                {!!parentCheck &&
                                  parentRecipient.length > 0 && (
                                    <div className="pt-3">
                                      <input
                                        type="checkbox"
                                        className="mr-2 bulk-checkbox"
                                        checked={
                                          parentRecipient.length ==
                                          parentCheckBoxes.length
                                        }
                                        onChange={checkedAllcheckbox}
                                        id="sub-parent"
                                        name="sub-parent"
                                      />
                                      <label
                                        htmlFor="sub-parent"
                                        className="mb-0"
                                      >
                                        <strong> parent </strong>
                                      </label>

                                      {parentRecipient.map((v, index) => {
                                        return (
                                          <div
                                            className="mt-1 ml-3 d-flex align-items-center text-gray"
                                            key={index}
                                          >
                                            <input
                                              type="checkbox"
                                              className="mr-2 bulk-checkbox"
                                              onChange={(e) =>
                                                handleParentOnChange(e)
                                              }
                                              checked={
                                                parentCheckBoxes.find(
                                                  (id) => id == v._id
                                                ) != undefined
                                              }
                                              id={v._id + "parent"}
                                              value={v.id}
                                              name="parent"
                                            />
                                            <label
                                              htmlFor={v._id + "parent"}
                                              className="mb-0"
                                            >
                                              {v.name}
                                            </label>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="mt-4">
                          <strong className="mb-4">Credit</strong>
                          <Form.Group className="mb-3 ">
                            <Form.Control
                              type="text"
                              placeholder="Enter Credit"
                              name="credit"
                              label="credit"
                              id="credit"
                              value={state.credit}
                              onChange={eventHandler}
                              className={classNames(
                                "form-control team-name payterm",
                                { errorBorder: errors.credit }
                              )}
                            />
                          </Form.Group>
                        </div>

                        <div className="">
                          <div className="create-team-head-event">
                            <div className="mt-5">
                              <button
                                className="btn-save"
                                onClick={handleCreate}
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* <>
                      <div className="mt-24">
                        <a
                          className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                          onClick={submitRequest}
                        >
                          Send
                        </a>
                      </div>
                    </>

                    <div
                      className={classNames("form-group event-drp mb-3", {
                        "d-none": !showPaymentCode,
                      })}
                    ></div> */}

                    <Toaster />
                  </div>
                </div>
              </form>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default NewCreditRequest;
