import React, { useEffect, useState } from 'react';
import $ from "jquery";
import { useHistory, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { getPaymentRequest } from '../../../service/action/userAction';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import Spinner from '../../../Spinner/Spinner';

const OrgAllPaymentReq = (props) => {


    let [loading, setLoading] = useState(true);


    let history = useHistory();

    useEffect(() => {
        props.getPaymentRequestHandler()
    }, [])

    const allpaymentReq = useSelector(state => state.getPaymentRequestReducer.user.data)

    const [outStanding, setOutStanding] = useState(null)
    const [received, setReceived] = useState(null)


    useEffect(() => {
        if (allpaymentReq !== undefined) {
            setReceived(allpaymentReq.filter(e => e.status == 1))
            setOutStanding(allpaymentReq.filter(e => e.status == 0))

        }
    }, [allpaymentReq])

    useEffect(() => {
        if (props.allpayment?.success) {
            setLoading(false)
        }
    }, [props.allpayment])



    const [state, setState] = useState({
        search: ""
    })

    const Searchval = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
        UpdateArray(e.target.value);
    }


    const UpdateArray = (search) => {
        var query = search;

        $(".filter-string").each(function (i, v) {
            if ($(v).text().toLowerCase().indexOf(query) === -1) {
                $(v).parents(".pay-row").addClass("d-none");
            } else {
                $(v).parents(".pay-row").removeClass("d-none");
            }
        });


    }
    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <div className="create-team-head-space " >

                            <div className='d-flex align-items-center'>
                                <h5 className="dash-head mr-4 mb-0 align-item-center">Payment Requests</h5>
                                <div className="search-section mt-4 mt-md-0">
                                    <i className="mdi mdi-magnify search-ico"></i>
                                    <input type="text" className="form-control" id="search" name="search" value={state.search} placeholder="Search" onChange={Searchval} autoComplete="off" />
                                </div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="create-team-head-space">
                                    {/* <h5 className="dash-head">Payment</h5> */}
                                    <Link to="/new-payment-request" className='text-decoaration-none'>
                                        <button className="btn-create-new"> Create New</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="team-details mt-4 mt-md-0 justify-content-between ">
                        <ul className="nav nav-pills mb-3 mt-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="teams" data-toggle="pill" href="#teams-tab" role="tab" aria-controls="pills-home" aria-selected="true">All Requests</a>
                            </li>
                            <li className="nav-item " role="presentation" >
                                <a className="nav-link " id="=players" data-toggle="pill" href="#players-tab" role="tab" aria-controls="pills-profile" aria-selected="false" >Outstanding</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="=coches" data-toggle="pill" href="#coaches-tab" role="tab" aria-controls="pills-profile" aria-selected="false">Received</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="=refund" data-toggle="pill" href="#Refund-tab" role="tab" aria-controls="pills-profile" aria-selected="false">Refunded</a>
                            </li>
                        </ul>
                    </div>

                    <div className="tab-content" id="pills-tabContent">
                    {/* <<<<<<<<<<<<<<<<-----------------------All Request Start------------------>>>>>>>>>>>>>>>> */}
                    <div className="tab-pane fade show active" id="teams-tab" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="green-section mt-30">
                                <div className='d-flex justify-content-center'>

                                    {loading ? <Spinner /> : null}
                                </div>

                                {/* <h6 className="text-left green-head">Full Payment</h6> */}
                                {
                                    allpaymentReq ?
                                        <>
                                            {
                                                allpaymentReq.length == 0 ?
                                                    <>
                                                        <div className='container d-flex justify-content-center align-items-center'>
                                                            <span className='noti-type'>No payment request to display</span>
                                                        </div>
                                                    </> :
                                                    <>

                                                        <div className="table-responsive d-none d-md-block">
                                                            <table className="table green-table mt-24">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Recipients</th>
                                                                        <th>Events</th>
                                                                        <th>Discount Applied</th>
                                                                        <th>Date/Days Paid</th>
                                                                        <th>Credit Issue Applied</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        allpaymentReq.length === 0 ? <> <span className='noti-type'>You Don't have Payment request yet</span></> : <>
                                                                            {
                                                                                allpaymentReq.map((v, i) => {
                                                                                    
                                                                                    return <tr className="pay-row" key={v._id}>
                                                                                        <td>
                                                                                            {/* <div className="dashboard-img">
                                                                        <img src="./images/pch-images/pch.svg" />
                                                                    </div> */}
                                                                                            <span className="filter-string">{v.recipient !== null ? v.recipient.name : ""}</span>
                                                                                        </td>
                                                                                        <td>{v?.event?.event_name}</td>
                                                                                        <td>{v.amount}</td>
                                                                                        <td>{v.due_date}</td>
                                                                                        <td></td>
                                                                                        {/* <td>
                                                                    <div className="dropdown event-drp">
                                                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                        More
                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                            <a className="dropdown-item" href="#">
                                                                                Refund(% or $)
                                                                            </a>
                                                                            <a className="dropdown-item" href="#">
                                                                                Issue Credit(% or $)</a>
                                                                            <a className="dropdown-item" href="#">
                                                                                Apply Credit(% or $)</a>
                                                                        </div>
                                                                    </div>
                                                                </td> */}
                                                                                    </tr>
                                                                                })
                                                                            }
                                                                        </>
                                                                    }


                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>
                                            }

                                            {/* Mobile view */}
                                            <div className="mt-30 d-md-none">
                                                <div className="mt-4">
                                                    {
                                                        allpaymentReq.map((v, i) => {
                                                            return <div className="d-flex justify-content-between reg-details" key={v._id}>
                                                                <div className="d-flex">
                                                                    <div className="reg-content">
                                                                        <span className="d-block text-decoration-none text-blue fw-m" >{v.recipient !== null ? v.recipient.name : ""}</span>
                                                                        <span className="d-block text-gray mt-2">event: {v?.event?.event_name}</span>
                                                                        <span className="d-block text-gray mt-2">Discount Applied: {v.amount}</span>
                                                                        <span className="d-block text-gray mt-2">Date/Days Paid: {v.due_date}</span>
                                                                        <span className="d-block text-gray mt-2">Credit Issue Applied: </span>


                                                                    </div>
                                                                </div>
                                                                {/* <div className="dropdown event-drp">
                                                                <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                    <a className="dropdown-item" href="#">
                                                                        Refund(% or $)
                                                                    </a>
                                                                    <a className="dropdown-item" href="#">
                                                                        Issue Credit(% or $)</a>
                                                                    <a className="dropdown-item" href="#">
                                                                        Apply Credit(% or $)</a>
                                                                </div>
                                                            </div> */}


                                                            </div>
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        </> : <></>
                                }

                            </div>
                            <div className="yellow-section mt-30">
                                {/* <h6 className="text-left yellow-head">Down Payment</h6>
                                <div className="table-responsive d-none d-md-block">
                                    <table className="table yellow-table mt-24">
                                        <thead>
                                            <tr>
                                                <th>Recipient</th>
                                                <th>Event</th>
                                                <th>Invoice</th>
                                                <th>Date</th>
                                                <th>Payment Id</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                yellow_tbl.map((v, i) => {
                                                    return <tr key={i} className="pay-row">
                                                        <td><span className="filter-string">{v.recipient}</span></td>
                                                        <td>{v.event}</td>
                                                        <td>{v.invoice}</td>
                                                        <td>{v.date}1</td>
                                                        <td>{v.payment_id}</td>
                                                        <td>
                                                            <div className="dropdown event-drp">
                                                                <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                More
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                    <a className="dropdown-item" href="#">
                                                                        Refund(% or $)
                                                                    </a>
                                                                    <a className="dropdown-item" href="#">
                                                                        Issue Credit(% or $)</a>
                                                                    <a className="dropdown-item" href="#">
                                                                        Apply Credit(% or $)</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div> */}

                                {/* Mobile view For Downpayment */}

                                {/* <div className="mt-30 d-md-none">
                                    <div className="mt-4">
                                        {
                                            yellow_tbl.map((v, i) => {
                                                return <div className="d-flex justify-content-between reg-details" key={i}>
                                                    <div className="d-flex">
                                                        <div className="reg-content">
                                                            <Link className="text-decoration-none text-blue fw-m" to="/event-details">{v.recipient}</Link>
                                                            <span className="d-block text-gray mt-2">Amount: {v.event}</span>
                                                            <span className="d-block text-gray mt-2">ExpirationDate: {v.invoice}</span>
                                                            <span className="d-block text-gray mt-2">ExpirationDate: {v.date}</span>
                                                            <span className="d-block text-gray mt-2">ExpirationDate: {v.payment_id}</span>

                                                        </div>
                                                    </div>
                                                    <div className="dropdown event-drp">
                                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                            <a className="dropdown-item" href="#">
                                                                Refund(% or $)
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Issue Credit(% or $)</a>
                                                            <a className="dropdown-item" href="#">
                                                                Apply Credit(% or $)</a>
                                                        </div>
                                                    </div>


                                                </div>
                                            })
                                        }

                                    </div>
                                </div> */}



                            </div>

                            <div className="red-section mt-30">
                                {/* <h6 className="text-left red-head">No Payment</h6>
                                <div className="table-responsive d-none d-md-block">
                                    <table className="table red-table mt-24">
                                        <thead>
                                            <tr>
                                                <th>Recipient</th>
                                                <th>Event</th>
                                                <th>Invoice</th>
                                                <th>Date</th>
                                                <th>Payment Id</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                red_tbl.map((v, i) => {
                                                    return <tr key={i} className="pay-row">
                                                        <td><span className="filter-string">{v.recipient}</span></td>
                                                        <td>{v.event}</td>
                                                        <td>{v.invoice}</td>
                                                        <td>{v.date}1</td>
                                                        <td>{v.payment_id}</td>
                                                        <td>
                                                            <div className="dropdown event-drp">
                                                                <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                More
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                    <a className="dropdown-item" href="#">
                                                                        Refund(% or $)
                                                                    </a>
                                                                    <a className="dropdown-item" href="#">
                                                                        Issue Credit(% or $)</a>
                                                                    <a className="dropdown-item" href="#">
                                                                        Apply Credit(% or $)</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div> */}

                                {/* Mobile view For No payment */}
                                <div className="mt-30 d-md-none">
                                    {/* <div className="mt-4">
                                        {
                                            red_tbl.map((v, i) => {
                                                return <div className="d-flex justify-content-between reg-details" key={i}>
                                                    <div className="d-flex">
                                                        <div className="reg-content">
                                                            <Link className="text-decoration-none text-blue fw-m" to="/event-details">{v.recipient}</Link>
                                                            <span className="d-block text-gray mt-2">Amount: {v.event}</span>
                                                            <span className="d-block text-gray mt-2">ExpirationDate: {v.invoice}</span>
                                                            <span className="d-block text-gray mt-2">ExpirationDate: {v.date}</span>
                                                            <span className="d-block text-gray mt-2">ExpirationDate: {v.payment_id}</span>

                                                        </div>
                                                    </div>
                                                    <div className="dropdown event-drp">
                                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                            <a className="dropdown-item" href="#">
                                                                Refund(% or $)
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Issue Credit(% or $)</a>
                                                            <a className="dropdown-item" href="#">
                                                                Apply Credit(% or $)</a>
                                                        </div>
                                                    </div>


                                                </div>
                                            })
                                        }

                                    </div> */}
                                </div>


                            </div>
                        </div>

                        {/* <<<<<<<<<<<<<<<<-----------------------All Request End------------------>>>>>>>>>>>>>>>> */}


                        {/* <<<<<<<<<<<<<<<<-----------------------OutStanding Start------------------>>>>>>>>>>>>>>>> */}

                        <div className="tab-pane fade" id="players-tab" role="tabpanel" aria-labelledby="pills-profile-tab">
                            {
                                outStanding ? <>
                                    <div className="yellow-section mt-30">
                                        {/* <h6 className="text-left yellow-head">OutStanding</h6> */}

                                        {
                                            outStanding.length === 0 ?
                                                <>
                                                    <div className='container d-flex justify-content-center align-items-center'>
                                                        <span className='noti-type'>No outstanding payment to display</span>
                                                    </div>
                                                </>

                                                : <>

                                                    <div className="table-responsive d-none d-md-block">
                                                        <table className="table yellow-table mt-24">
                                                            <thead>
                                                                <tr>
                                                                    <th>Recipients</th>
                                                                    <th>Events</th>
                                                                    <th>Discount Applied</th>
                                                                    <th>Date</th>
                                                                    <th>Creadit Issue Applied</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    outStanding.length == 0 ? <><span className='noti-type'>You Don't have Any outstanding yet</span></> : <>
                                                                        {
                                                                            outStanding.map((v, i) => {
                                                                                return <tr key={i} className="pay-row">
                                                                                    <td><span className="filter-string">{v.recipient !== null ? v.recipient.name : ""}</span></td>
                                                                                    <td>{v?.event?.event_name}</td>
                                                                                    <td>{v.amount}</td>
                                                                                    <td>{v.due_date}1</td>
                                                                                    <td></td>
                                                                                    {/* <td>
                                                                    <div className="dropdown event-drp">
                                                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                        More
                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                            <a className="dropdown-item" href="#">
                                                                                Refund(% or $)
                                                                            </a>
                                                                            <a className="dropdown-item" href="#">
                                                                                Issue Credit(% or $)</a>
                                                                            <a className="dropdown-item" href="#">
                                                                                Apply Credit(% or $)</a>
                                                                        </div>
                                                                    </div>
                                                                </td> */}
                                                                                </tr>
                                                                            })
                                                                        }

                                                                    </>
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                        }


                                        {/* Mobile view For Downpayment */}

                                        <div className="mt-30 d-md-none">
                                            <div className="mt-4">
                                                {
                                                    outStanding.map((v, i) => {
                                                        return <div className="d-flex justify-content-between reg-details" key={i}>
                                                            <div className="d-flex">
                                                                <div className="reg-content">
                                                                    <span className="text-decoration-none text-blue fw-m" >{v.recipient !== null ? v.recipient.name : ""}</span>
                                                                    <span className="d-block text-gray mt-2">Event: {v?.event?.event_name}</span>
                                                                    <span className="d-block text-gray mt-2">Discount Applied: {v.amount}</span>
                                                                    <span className="d-block text-gray mt-2">Date/Days Paid: {v.due_date}</span>
                                                                    <span className="d-block text-gray mt-2">Credit Issue Applied:</span>

                                                                </div>
                                                            </div>
                                                            {/* <div className="dropdown event-drp">
                                                                <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                    <a className="dropdown-item" href="#">
                                                                        Refund(% or $)
                                                                    </a>
                                                                    <a className="dropdown-item" href="#">
                                                                        Issue Credit(% or $)</a>
                                                                    <a className="dropdown-item" href="#">
                                                                        Apply Credit(% or $)</a>
                                                                </div>
                                                            </div> */}


                                                        </div>
                                                    })
                                                }

                                            </div>
                                        </div>



                                    </div>
                                </> : <></>
                            }
                        </div>

                        {/* <<<<<<<<<<<<<<<<-----------------------OutStanding End------------------>>>>>>>>>>>>>>>> */}

                        {/* <<<<<<<<<<<<<<<<-----------------------Received Start------------------>>>>>>>>>>>>>>>> */}


                        <div className="tab-pane fade" id="coaches-tab" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className="red-section mt-30">
                                {/* <h6 className="text-left red-head">No Payment</h6> */}
                                {
                                    received ? <>
                                        {
                                            received.length == 0 ?
                                                <>
                                                    <div className='container d-flex justify-content-center align-items-center'>
                                                        <span className='noti-type'>No received payment to display</span>
                                                    </div>
                                                </> : <>


                                                    <div className="table-responsive d-none d-md-block">
                                                        <table className="table red-table mt-24">
                                                            <thead>
                                                                <tr>
                                                                    <th>Recipients</th>
                                                                    <th>Events</th>
                                                                    <th>Discount Applied</th>
                                                                    <th>Date/Days Paid</th>
                                                                    <th>Credit Issue Applied</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    received.length == 0 ? <><span className='noti-type'>You Don't have Received payment</span></> : <>{
                                                                        received.map((v, i) => {
                                                                            return <tr key={i} className="pay-row">
                                                                                <td><span className="filter-string">{v.recipient !== null ? v.recipient.name : ""}</span></td>
                                                                                <td>{v.event.event_name}</td>
                                                                                <td>{v.amount}</td>
                                                                                <td>{v.due_date}1</td>
                                                                                <td></td>
                                                                                {/* <td>
                                                                <div className="dropdown event-drp">
                                                                    <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                    More
                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                        <a className="dropdown-item" href="#">
                                                                            Refund(% or $)
                                                                        </a>
                                                                        <a className="dropdown-item" href="#">
                                                                            Issue Credit(% or $)</a>
                                                                        <a className="dropdown-item" href="#">
                                                                            Apply Credit(% or $)</a>
                                                                    </div>
                                                                </div>
                                                            </td> */}
                                                                            </tr>
                                                                        })
                                                                    }   </>
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                        }

                                        {/* Mobile view For No payment */}
                                        <div className="mt-30 d-md-none">
                                            <div className="mt-4">
                                                {
                                                    received.map((v, i) => {
                                                        return <div className="d-flex justify-content-between reg-details" key={i}>
                                                            <div className="d-flex">
                                                                <div className="reg-content">
                                                                    <span className="text-decoration-none text-blue fw-m" >{v.recipient !== null ? v.recipient.name : ""}</span>
                                                                    <span className="d-block text-gray mt-2">Event: {v.event.event_name}</span>
                                                                    <span className="d-block text-gray mt-2">Discount Applied: {v.amount}</span>
                                                                    <span className="d-block text-gray mt-2">Date/Days Paid: {v.due_date}</span>
                                                                    <span className="d-block text-gray mt-2">Credit Issue Applied: </span>

                                                                </div>
                                                            </div>
                                                            {/* <div className="dropdown event-drp">
                                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                            <a className="dropdown-item" href="#">
                                                                Refund(% or $)
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Issue Credit(% or $)</a>
                                                            <a className="dropdown-item" href="#">
                                                                Apply Credit(% or $)</a>
                                                        </div>
                                                    </div> */}


                                                        </div>
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </> : <></>
                                }


                            </div>
                        </div>

                        {/* <<<<<<<<<<<<<<<<-----------------------Received End------------------>>>>>>>>>>>>>>>> */}


                        {/* <<<<<<<<<<<<<<<<-----------------------Refund start------------------>>>>>>>>>>>>>>>> */}

                        <div className="tab-pane fade" id="Refund-tab" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className="red-section mt-30">
                                {/* <h6 className="text-left red-head">No Payment</h6> */}
                                {
                                    received ? <>

                                        <div className="table-responsive d-none d-md-block">
                                            <table className="table red-table mt-24">
                                                <thead>
                                                    <tr>
                                                        <th>Recipients</th>
                                                        <th>Events</th>
                                                        {/* <th>Discount Applied</th>
                                                        <th>Date/Days Paid</th>
                                                        <th>Credit Issue Applied</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        received.map((v, i) => {
                                                            return <tr key={i} className="pay-row">
                                                                <td><span className="filter-string">{v.recipient !== null ? v.recipient.first_name : ""}</span></td>
                                                                <td>{v.event.event_name}</td>
                                                                {/* <td>{v.amount}</td>
                                                                <td>{v.due_date}1</td> */}
                                                                <td></td>
                                                                {/* <td>
                                                            <div className="dropdown event-drp">
                                                                <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                More
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                    <a className="dropdown-item" href="#">
                                                                        Refund(% or $)
                                                                    </a>
                                                                    <a className="dropdown-item" href="#">
                                                                        Issue Credit(% or $)</a>
                                                                    <a className="dropdown-item" href="#">
                                                                        Apply Credit(% or $)</a>
                                                                </div>
                                                            </div>
                                                        </td> */}
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        {/* Mobile view For No payment */}
                                        <div className="mt-30 d-md-none">
                                            <div className="mt-4">
                                                {
                                                    received.map((v, i) => {
                                                        return <div className="d-flex justify-content-between reg-details" key={i}>
                                                            <div className="d-flex">
                                                                <div className="reg-content">
                                                                    <span className="text-decoration-none text-blue fw-m" >{v.recipient !== null ? v.recipient.name : ""}</span>
                                                                    <span className="d-block text-gray mt-2">Event: {v.event.event_name}</span>
                                                                    <span className="d-block text-gray mt-2">Discount Applied: {v.amount}</span>
                                                                    <span className="d-block text-gray mt-2">Date/Days Paid: {v.due_date}</span>
                                                                    <span className="d-block text-gray mt-2">Credit Issue Applied: </span>

                                                                </div>
                                                            </div>
                                                            {/* <div className="dropdown event-drp">
                                                        <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                            <a className="dropdown-item" href="#">
                                                                Refund(% or $)
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                Issue Credit(% or $)</a>
                                                            <a className="dropdown-item" href="#">
                                                                Apply Credit(% or $)</a>
                                                        </div>
                                                    </div> */}


                                                        </div>
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </> : <></>
                                }


                            </div>
                        </div>


                        {/* <<<<<<<<<<<<<<<<-----------------------Refund End------------------>>>>>>>>>>>>>>>> */}

                    </div>



                </div>

            </div>
        </React.Fragment>
    )
}

// export default withRouter(OrgAllPaymentReq)

const mapStateToProps = (state) => ({
    allpayment: state.getPaymentRequestReducer

})

const mapDispatchToProps = dispatch => ({
    getPaymentRequestHandler: user => dispatch(getPaymentRequest(user))


})

export default connect(mapStateToProps, mapDispatchToProps)((OrgAllPaymentReq))