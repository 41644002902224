import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getStaffList } from "../../service/action/userAction";
import Toaster from "../../Toaster/Toaster";

function AddStaffList(props) {
  const [searchVal, setSearchVal] = useState("");
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    props.getStaffList();
  }, []);

  useEffect(() => {
    if (props.getStaffListReducer.success) {
      const data = props.getStaffListReducer.data;
      setUserList(data.data);
    }
  }, [props.getStaffListReducer]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div>
            <div className="w-md-75 w-100 p-2 border mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <div className="flex-grow-1 d-flex align-items-center">
                  <i className="mdi mdi-magnify mx-2"></i>
                  <input
                    type="text"
                    className="border-0 p-1 flex-grow-1 w-100"
                    name="searchVal"
                    placeholder="Search"
                    value={searchVal}
                    onChange={(e) => {
                      setSearchVal(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <>
              {userList.length == 0 ? (
                <div className="mt-4 text-center">
                  <strong>No List Available </strong>
                </div>
              ) : (
                <>
                  <div>List</div>
                </>
              )}
            </>
          </div>
        </div>
      </div>
      <Toaster />
    </React.Fragment>
  );
}

const mapStateToProp = (state) => ({
  getStaffListReducer: state.OrgPermissionReducer.getStaffList,
});

const mapDispatchToProps = (dispatch) => ({
  getStaffList: (details) => dispatch(getStaffList(details)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(AddStaffList));
