import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useHistory, useLocation } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
// import { editPaymentCode, getAllPaymentCode } from '../../../service/action/userAction';
import { connect, useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { deleteSeason, getAllSeason } from "../../service/action/userAction";
import { AddEventSeasonClub_Url, EventSeasonOrg_Url } from "../../apiSheet";
import {
  RESET_CREATE_SEASON,
  RESET_DELETE_SEASON,
  RESET_EDIT_SEASON,
} from "../../service/constants";
import { toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";
import Spinner from "../../Spinner/Spinner";
import DeletePopUp from "../../club/components/DeletePopUp/DeletePopUp";
import BreadcrumbHistory from "../../BreadcumbHistory/BreadcrumbHistory";

const Season = (props) => {
  const getseason = props.getSeasonHandler;
  let [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  // const getToken = localStorage.getItem("token")
  // const getToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjEyZjI2NzQ2NzAwYmI0M2M4NzJmMzgxIiwiaWF0IjoxNjMzOTI1MTA5LCJleHAiOjE2MzQwMTE1MDl9.oX20FJakkiUNO8gOgEI1n_t7AwDC__TVQVo7yNcrAgE"
  // alert("1")
  const header = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  const acctype = localStorage.getItem("type");
  const notify = (msg) => toast(msg);
  const allseason = useSelector((state) => state.getAllSeasonReducer.user);
  const allseasonMsg = useSelector((state) => state.getAllSeasonReducer);
  const deleteMsg = useSelector((state) => state.deleteSeasonReducer.message);
  const editMsg = useSelector((state) => state.editSeasonReducer.message);
  const msg = useSelector((state) => state.createSeasonReducer.message);

  const location = useLocation();
  useEffect(() => {
    getseason();
  }, []);

  useEffect(() => {
    if (props.deleteMsg?.success) {
      notify(deleteMsg.message);
      props.resetdeleteSeasonReducer();
      getseason();
    }
  }, [props.deleteMsg]);

  useEffect(() => {
    if (props.editSeasonMsg?.success) {
      notify(editMsg.message);
      localStorage.removeItem("season_id");
      props.resetEditSeason();
      getseason();
    }
  }, [props.editSeasonMsg]);

  useEffect(() => {
    if (props.ErrorMsg?.success) {
      setLoading(false);
    }
  }, [props.ErrorMsg]);

  useEffect(() => {
    // alert("a")
    if (props.submit?.success) {
      notify(msg.message);
      props.resetCreateSeasonReducer();
    }
  }, [props.submit]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    $(".team-table .filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(e.target.value) === -1) {
        $(v).parents(".team-row").addClass("d-none");
      } else {
        $(v).parents(".team-row").removeClass("d-none");
      }
    });
  };

  return (
    <>
      <div className="main-panel">
        {/* <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} /> */}
        <div className="content-wrapper">
          <div className="d-flex justify-content-center">
            {loading ? (
              <div className="w-full h-[60vh] flex flex-col justify-center items-center">
                <Spinner />
              </div>
            ) : null}
          </div>
          {!loading && allseason ? (
            <>
              <h5 className="dash-head mr-3 mb-0">Seasons</h5>
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center">
                  <div className="search-section mt-3 mt-md-0">
                    <i className="mdi mdi-magnify search-ico"></i>
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      name="search"
                      value={searchValue}
                      onChange={handleSearch}
                      placeholder="Search"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="text-right">
                  <div>
                    <div className="mt-3 mt-md-0 text-right create-team-head-btn">
                      <Link to="/createseason">
                        <button className="btn-create-new ">Create New</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="green-section mt-30">
                {/* <h6 className="text-left green-head">Season List</h6> */}
                {allseason.length == 0 ? (
                  <>
                    <div className="container d-flex justify-content-center align-items-center">
                      <span className="noti-type">
                        You Don't have any Season yet
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table className="table green-table mt-24 team-table">
                        <thead>
                          <tr>
                            <th>Season Name</th>
                            <th></th>
                          </tr>
                        </thead>
                        {/* Season data callS */}
                        <tbody>
                          {allseason.length == 0 ? (
                            <>
                              <span className="noti-type">
                                No records to display
                              </span>
                            </>
                          ) : (
                            <>
                              {allseason.map((v, i) => {
                                // alert("Inside")
                                return (
                                  <tr className="pay-row team-row" key={v._id}>
                                    <td>
                                      <span className="filter-string">
                                        {v.season_name}
                                      </span>
                                    </td>
                                    <td>
                                      <div className="dropdown event-drp">
                                        <i
                                          className="mdi mdi-dots-vertical"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        ></i>
                                        {/* More */}
                                        <div
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuLink"
                                        >
                                          <Link
                                            className="dropdown-item"
                                            to={`/season/${v._id}`}
                                            onClick={localStorage.setItem(
                                              "season_id",
                                              v._id
                                            )}
                                          >
                                            <i className="mdi mdi-border-color"></i>
                                            Edit
                                          </Link>
                                          <Link
                                            className="dropdown-item"
                                            to="/season"
                                            onClick={() => {
                                              setIsDelete(true);
                                              setDeleteId(v._id);
                                            }}
                                          >
                                            <i className="fas fa-trash-alt"></i>{" "}
                                            Delete
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : null}
          <Toaster />
        </div>
      </div>
      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => props.deleteSeasonHandler(deleteId)}
      />
    </>
  );
};

// export default withRouter(PaymentCode);
const mapStateToProps = (state) => ({
  deleteMsg: state.deleteSeasonReducer,
  editSeasonMsg: state.editSeasonReducer,
  ErrorMsg: state.getAllSeasonReducer,
  submit: state.createSeasonReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getSeasonHandler: (user) => dispatch(getAllSeason(user)),
  deleteSeasonHandler: (user) => dispatch(deleteSeason(user)),
  resetdeleteSeasonReducer: (user) => dispatch({ type: RESET_DELETE_SEASON }),
  resetEditSeason: () => dispatch({ type: RESET_EDIT_SEASON }),
  resetCreateSeasonReducer: () => dispatch({ type: RESET_CREATE_SEASON }),
  // userDataHandler: user => dispatch(userData(user))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Season));
// 249
