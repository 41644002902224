// Create Event
export const FACILITY_STATE="Please select state "
export const FACILITY_CITY="Please select city "
export const FACILITY_ADDRESS="Facility address can not be empty"
export const FACILITY_WEBSITE="Facility website can not be empty"
export const FACILITY_NAME="Facility name can not be empty"

export const START_DATE='Please select start date'
export const END_DATE='Please select end date'
export const START_TIME='Please set a start time'
export const END_TIME='Please set a end time'
export const EVENT_NAME='Event name can not be empty'
export const MAX_TEAM='Max. teams can not be empty'
export const EVENT_TYPE='Please select event type'
export const EVENT_SEASON='Please select season'
export const EVENT_ACCESS='Please select event access type'
export const EVENT_PAYMENTCODE='Please select payment code'


//payment request
export const RECIPIENT='Please select recipient'
export const AMOUNT='Amount can not be empty'
export const NUMERIC='Only numeric value is accept'
export const PAYMENT_TERM='Please select payment term'
export const DUE_DATE='Please select due date'
export const EVENT='Please select event'

//payment code
export const PAYMENT_CODE='Payment code name can not be empty'
export const AMOUNT_DUE='Amount due can not be empty'
export const BALANCE_DUE_DAYS='Balance due day value can not be empty'
export const PAYMENT_TERM_CONDITION="Terms & condition can not be empty"

//credit
export const CREDIT_AMOUNT="Credit amount can not be empty"


//Refund
export const REFUND_ACCOUNT="Please select account"
export const REFUND_AMOUNT="Refund amount can not be empty"

//Credit Card Details
export const CARD_NUMBER="please enter card number"
export const EXPIRY_DATE="please enter expiry date"
export const CARD_CVV="please enter cvv"
export const CARD_NAME="please enter card holder name"