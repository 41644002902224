import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import { withRouter, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { connect, useSelector } from "react-redux";
import classNames from "classnames";
import {
  addplayertodivisionAction,
  addteamtodivisionAction,
  getAllDivision,
  updateFreeTeam,
} from "../../../service/action/userAction";
import {
  RESET_FREETEAM,
  RESET_ORG_ADD_PLAYER_TO_DIVISION,
  RESET_ORG_ADD_TEAM_TO_DIVISION,
} from "../../../service/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Modal } from "react-bootstrap";

const EventfreeAgents = (props) => {
  let history = useHistory();
  const { id, id1, id2 } = useParams();

  const location = useLocation();

  const [isPool, setIsPool] = useState(false);

  const [addData, setAddData] = useState({
    show: false,
    id: null,
  });

  useEffect(() => {
    const type =
      !!location.state &&
      ((!!location.state.type && location.state.type == "pool") ||
        !!location.state.editedPool);
    if (type) {
      setIsPool(true);
    } else {
      setIsPool(false);
    }
  }, [location]);

  // const getdivision= props.getAllDivisionHandler({id})
  const allDivision = useSelector(
    (state) => state.getAllDivisionReducer.user.data
  );

  useEffect(() => {
    if (id) {
      props.getAllDivisionHandler({ id });
    }
  }, []);

  useEffect(() => {
    if (props.addplayertodivisionReducer?.success) {
      props.resetaddPlayerToDivisionReducer();
      history.push(
        `/event-division-details/${id}/${!isPool ? state.division_name : id1}`
      );
    }
  }, [props.addplayertodivisionReducer]);

  const [state, setState] = useState({
    division_name: "",
    pool: "",
  });

  const [pool, setPool] = useState([]);

  const [errors, setErrors] = useState({
    division_name: false,
    pool: false,
  });

  useEffect(() => {
    if (!!isPool && props.getAllDivisionReducer.success) {
      const data = props.getAllDivisionReducer.user.data;
      const pool = data.find((e) => e._id == id1);
      const poolList = pool.pool;
      setPool(poolList);
      setState((pre) => ({
        ...pre,
        division_name: pool._id,
      }));
    }
  }, [isPool, props.getAllDivisionReducer]);

  const eventHandler = (e) => {
    const { id, value, name } = e.target;

    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    switch (name) {
      case "division_name":
        if (value !== "") {
          const pool = allDivision.find((e) => e._id == value);
          const poolList = pool.pool;
          setPool(poolList);
        }
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            division_name: "Division name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            division_name: false,
          }));
        }

        break;
      case "pool":
        if (!!isPool) {
          if (value == "") {
            setErrors((prevState) => ({
              ...prevState,
              pool: "Please Select Pool",
            }));
          } else {
            setErrors((prevState) => ({
              ...prevState,
              pool: false,
            }));
          }
        } else {
          setErrors((prevState) => ({
            ...prevState,
            pool: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const notify = (msg) => toast(msg);

  const submitRequest = (e) => {
    e.preventDefault();

    if (state.division_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        division_name: "Division name can not be empty",
      }));
    }
    if (state.division_name !== "") {
      // props.addplayertodivisionAction({
      //   event: id,
      //   division: state.division_name,
      //   pool: state.pool,
      //   player: !isPool ? [id1] : [id2],
      //   isCalculate: 0,
      //   onCharge: 0,
      // });
      setAddData((pre) => ({
        ...pre,
        show: true,
        id: !isPool ? [id1] : [id2],
      }));
    }
  };
  const submitPoolRequest = (e) => {
    e.preventDefault();

    if (state.pool == "") {
      setErrors((prevState) => ({
        ...prevState,
        pool: "Please Select Pool",
      }));
    }
    if (state.pool !== "") {
      // props.addplayertodivisionAction({
      //   event: id,
      //   division: state.division_name,
      //   pool: state.pool,
      //   player: [id2],
      //   isCalculate: 0,
      //   onCharge: 0,
      // });
      setAddData((pre) => ({
        ...pre,
        show: true,
        id: [id2],
      }));
    }
  };
  useEffect(() => {
    if (props.submit?.success) {
      notify("Pool Created");
      props.resetUpdateFreeTeamReducer();
      history.push(`/event-division-details/${id}/${state.division_name}`);
    }
    // else if(props.submit?.error){
    //     notify("Pool Error")
    // }
  }, [props.submit]);

  useEffect(() => {
    setState((pre) => ({
      ...pre,
      pool: "",
    }));
  }, [state.division_name]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Add Event Division</h5>
          </div>

          <div>
            <div className="row mx-0">
              <div className="col-md-4 col-xl-4">
                <div className="mt-24">
                  {!isPool && (
                    <div className="text-left">
                      <label className="text-gray fs-13">
                        Division Name <span className="text-red fw-m">*</span>
                      </label>
                      {allDivision ? (
                        <>
                          <select
                            className={classNames(
                              "form-control team-name payterm",
                              { error: errors.division_name }
                            )}
                            id="division_name"
                            name="division_name"
                            value={state.division_name}
                            onChange={eventHandler}
                          >
                            <option value="" disabled>
                              Division
                            </option>
                            {allDivision
                              .filter((e) => e.type == 1)
                              .map((v, i) => {
                                return (
                                  <option value={v._id}>
                                    {v.division_name}
                                  </option>
                                );
                              })}
                          </select>

                          {errors.division_name && (
                            <span className="text-red small d-block">
                              * {errors.division_name}
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          <h4>Loading...</h4>
                        </>
                      )}
                    </div>
                  )}
                  <div className="text-left">
                    <label className="text-gray fs-13">
                      Pool Name <span className="text-red fw-m">*</span>
                    </label>
                    <select
                      className={classNames("form-control team-name payterm", {
                        error: errors.pool,
                      })}
                      id="pool"
                      name="pool"
                      value={state.pool}
                      disabled={
                        !isPool && state.division_name == "" ? true : false
                      }
                      onChange={eventHandler}
                    >
                      <option value="" disabled>
                        Pool
                      </option>
                      {pool.map((v, i) => {
                        return (
                          <option key={v._id} value={v._id}>
                            {v.pool_name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.pool && (
                      <span className="text-red small d-block">
                        * {errors.pool}
                      </span>
                    )}
                  </div>

                  <div className="mt-3 text-left">
                    <a
                      className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                      onClick={!isPool ? submitRequest : submitPoolRequest}
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={addData.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <div className="p-3" style={{ backgroundColor: "#ffffff" }}>
          <div className="d-flex justify-content-center my-4">
            <i
              className="fas fa-arrow-right-from-bracket "
              style={{
                fontSize: "40px",
                color: "red",
              }}
            ></i>
          </div>
          <div className="text-center">
            This transfer comes with diff payment term, would you wish charge
            for it?
          </div>
          <div className="mt-4 d-flex justify-content-center border-top-0">
            <button
              onClick={() => {
                props.addplayertodivisionAction({
                  event: id,
                  division: state.division_name,
                  pool: state.pool,
                  player: !isPool ? [id1] : [id2],
                  isCalculate: 0,
                });
              }}
              className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 mx-3 rounded"
            >
              No
            </button>
            <button
              onClick={() => {
                props.addplayertodivisionAction({
                  event: id,
                  division: state.division_name,
                  pool: state.pool,
                  player: !isPool ? [id1] : [id2],
                  isCalculate: 1,
                });
              }}
              className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 mx-3 rounded"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
      <Toaster />
    </>
  );
};

// export default withRouter(Org_CreateDivision)
const mapStateToProp = (state) => ({
  submit: state.updateFreeTeamReducer,
  addplayertodivisionReducer: state.addplayertodivisionReducer,
  getAllDivisionReducer: state.getAllDivisionReducer,
});
const mapDispatchToProps = (dispatch) => ({
  updateTeam: (user) => dispatch(updateFreeTeam(user)),
  resetUpdateFreeTeamReducer: () => dispatch({ type: RESET_FREETEAM }),
  resetaddPlayerToDivisionReducer: () =>
    dispatch({ type: RESET_ORG_ADD_PLAYER_TO_DIVISION }),
  getAllDivisionHandler: (user) => dispatch(getAllDivision(user)),
  addplayertodivisionAction: (user) =>
    dispatch(addplayertodivisionAction(user)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(EventfreeAgents));
