import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import { getEventDetailsForRegistration } from "../../../service/action/Club/clubAction";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";

function RegisterEventNotification() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentEvent, setCurrentEvent] = useState(null);

  const eventList = useSelector(
    (state) => state.getEventDetailsForRegistrationReducer
  );

  useEffect(() => {
    if (!!location.state) {
    } else {
      history.goBack();
    }
  }, [location]);

  useEffect(() => {
    dispatch(
      getEventDetailsForRegistration({
        id: location.state.eventId,
      })
    );
  }, []);

  useEffect(() => {
    if (eventList.success) {
      setCurrentEvent(eventList?.data?.data);
    }
  }, [eventList.success]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />
        <div className="content-wrapper">
          {!!currentEvent ? (
            <>
              <div className="row mx-0">
                <div className="col-1" style={{ float: "left" }}>
                  <div
                    className="border pt-2 pb-2 text-center text-light"
                    style={{ backgroundColor: "#ADD8E6", borderRadius: "10px" }}
                  >
                    <div>
                      {moment(currentEvent.event_start_date)
                        .format("MMM")
                        .toUpperCase()}
                    </div>
                    <div style={{ fontWeight: "bold", fontSize: "25px" }}>
                      {moment(currentEvent.event_start_date)
                        .format("D")
                        .toUpperCase()}
                    </div>
                  </div>
                </div>

                <div style={{ paddingTop: "20px" }} className="col">
                  <h5
                    className="dash-head text-capitalize"
                    style={{
                      // marginLeft: "120px",
                      color: "#143D4A",
                      fontWeight: "bold",
                    }}
                  >
                    {!!currentEvent.event_name && currentEvent.event_name !== ""
                      ? currentEvent.event_name
                      : "N/A"}
                  </h5>
                  <div className="row mx-0">
                    <div className="col-6">
                      <div className="d-flex">
                        <div>
                          <i
                            className="mdi mdi-clock"
                            style={{
                              color: "#ADD8E6",
                            }}
                          ></i>
                          <span className="mx-2" style={{ color: "#ADD8E6" }}>
                            Start :
                          </span>
                          <span style={{ color: "#ADD8E6" }}>
                            {currentEvent.event_from_time
                              ? currentEvent.event_from_time
                              : "N/A"}
                          </span>
                        </div>
                        <div className="ms-5">
                          <i
                            className="mdi mdi-clock"
                            style={{
                              color: "#ADD8E6",
                            }}
                          ></i>
                          <span className="mx-2" style={{ color: "#ADD8E6" }}>
                            End :
                          </span>
                          <span style={{ color: "#ADD8E6" }}>
                            {currentEvent.event_to_time
                              ? currentEvent.event_to_time
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-gray mt-2">{currentEvent.event_name}</div>

              <div className="mt-3 team-details">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="details"
                      data-toggle="pill"
                      href="#detail-tab"
                      role="tab"
                      aria-controls="pills-details"
                      aria-selected="true"
                    >
                      Details
                    </a>
                  </li>
                </ul>

                <div className="tab-content" id="pills-tabContent">
                  {/* details */}

                  <div
                    className="tab-pane fade show active "
                    id="detail-tab"
                    role="tabpanel"
                    aria-labelledby="pills-details"
                  >
                    <div className="mt-3">
                      <div className="row col-xl-8 mx-0">
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Event Name :{" "}
                          </label>

                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {currentEvent.event_name != ""
                              ? currentEvent.event_name
                              : "N/A"}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Event Status :{" "}
                          </label>

                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {moment(currentEvent.event_start_date).isAfter(
                              moment()
                            )
                              ? "Upcoming"
                              : moment(currentEvent.event_end_date).isBefore(
                                  moment()
                                )
                              ? "Close"
                              : "Open"}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0 ">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Event Type :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {currentEvent.event_type == 0
                              ? "Club only"
                              : currentEvent.event_type == 1
                              ? "Parent/Player"
                              : currentEvent.event_type == 2
                              ? "Club & Parent/Player"
                              : ""}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Start Date :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {moment(currentEvent.event_start_date).format(
                              "MM-DD-yyyy"
                            )}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            End Date :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {moment(currentEvent.event_end_date).format(
                              "MM-DD-yyyy"
                            )}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Start Time :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {currentEvent.event_from_time}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            End Time :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {currentEvent.event_to_time}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Registration Start Date :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {moment(
                              currentEvent.registration_start_date
                            ).format("MM-DD-yyyy")}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Registration End Date :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {moment(currentEvent.registration_end_date).format(
                              "MM-DD-yyyy"
                            )}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Registration Start Time :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {currentEvent.registration_start_time}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Registration End Time :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {currentEvent.registration_end_time}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Facility Name :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {currentEvent.event_facility[0].facility_name}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Facility Address :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {`${currentEvent.event_facility[0].facility_address}, ${currentEvent.event_facility[0].facility_city_name}, ${currentEvent.event_facility[0].facility_state.name}`}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Facility Website :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {currentEvent.event_facility[0].facility_website}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Max Teams :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {currentEvent.event_max_team}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Max Players :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {currentEvent.event_max_player}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Conatct Name :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {currentEvent.personal_detail.name}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Conatct Email :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {currentEvent.personal_detail.email}
                          </span>
                        </div>
                        <div className="col-12 border-bottom py-1 row mx-0">
                          <label
                            className=" col-6"
                            style={{ color: "#189ad6" }}
                          >
                            Conatct Phone :{" "}
                          </label>
                          <span
                            className="col-6 d-inline-block"
                            style={{ fontWeight: "bold", color: "#143D4A" }}
                          >
                            {currentEvent.personal_detail.phone}
                          </span>
                        </div>
                      </div>
                      {moment(currentEvent.event_end_date) >= moment() ? (
                        <div className="mt-4">
                          <button
                            className="btn-save"
                            onClick={() => {
                              history.push(
                                `/events/${currentEvent._id}/registernow`,
                                {
                                  inviteType: 1,
                                }
                              );
                            }}
                          >
                            Register Team
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default RegisterEventNotification;
