import { INVITE_CLUB_ORG_ERROR, INVITE_CLUB_ORG_LOADING, INVITE_CLUB_ORG_SUCCESS, RESET_INVITE_CLUB_ORG } from "../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
  data: [],
};

export default function InviteClubReducer(state = initialState, action) {
  switch (action.type) {
    case INVITE_CLUB_ORG_LOADING:
      return { ...state, success: false, success: false };

    case INVITE_CLUB_ORG_SUCCESS:
      return { ...state, loading: false, success: true, user: action.payload };

    case INVITE_CLUB_ORG_ERROR:
      return { ...state, error: true, errors: action.errors };

    case RESET_INVITE_CLUB_ORG:
      return { ...state, error: false, errors: [], success: false };

    default:
      return state;
  }
}
