import React from "react";
import logo from "../../assets/images/logo.svg";
import { useHistory } from "react-router-dom";

export default function NewPass() {

      let history = useHistory();

      const newPass = () => {
            history.push('/superadmin/signin');
      }


      return (
            <div className="signin">
                  <div className="signin-logo">
                        <img src={logo} className="img-fluid logo-img" alt="logo" />
                  </div>

                  {/* Login form */}
                  <div className="login-form">
                        <h1 className="heading text-white text-center">Set new password</h1>
                        <form className="mt-4">
                              {/* <p className="text-white text-center mt-4 mb-0">Please enter the 4 Digit code sent to <span className="h5 text-secondary"> yourname@company.com</span></p> */}
                              <div className="form-group mt-5">
                                    <input type="password" className="form-control" placeholder="New Password" />
                              </div>
                              <div className="form-group">
                                    <input type="password" className="form-control" placeholder="Confirm Password" />
                              </div>
                              <div className="text-center">
                                    <button type="submit" className="btn btn-secondary" onClick={newPass}>Change Password</button>
                              </div>
                        </form>
                  </div>
            </div>
      );
}