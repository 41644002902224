import React from 'react'
import BreadcrumbHistory from '../../BreadcumbHistory/BreadcrumbHistory'
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom'
import { useEffect } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { useState } from 'react'
import InputMask from "react-input-mask";
import { ThemeProvider } from 'react-bootstrap'
import materialTheme from '../../materialUi'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from "@date-io/date-fns";
import { addAccount, getAllState } from '../../service/action/userAction'
import { connect, useSelector } from 'react-redux'

function ClubAddAccountForm(props) {
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams()

  useEffect(() => {
    if (!((id).toString() === '1' || (id).toString() === '2' || (id).toString() === '3' || (id).toString() === '4' || (id).toString() === '5' || (id).toString() === '6')) {
      history.push('/add-account')
    }
    if (id == 1 || id == 4) {
      props.getAllStateHandler()
    }
  }, [id])

  const allstate = useSelector(state => state.getStateReducer.states)
  const [state, setState] = useState({
    fname: '',
    mname: '',
    lname: '',
    dob: '',
    gender: '',
    telephone: '',
    state: '',
    city: '',
    address: '',
    zip: '',
    org_name: '',
    club_name: '',
    website: '',
    terms_condition: 0
  })

  const [error, setError] = useState({
    fname: false,
    mname: false,
    lname: false,
    dob: false,
    gender: false,
    telephone: false,
    terms_condition: false,
    state: false,
    city: false,
    address: false,
    zip: false,
    org_name: false,
    club_name: false,
    website: false,
  })

  const eventhandler = (e) => {
    const { name, value } = e.target

    if (name == 'zip') {
      if (value.length <= 5 && !isNaN(value)) {
        setState(pre => ({
          ...pre,
          [name]: value
        }))
      }
    } else {
      setState(pre => ({
        ...pre,
        [name]: value
      }))
    }
    switch (name) {
      case "org_name":
        if (value === '') {
          error['org_name'] = 'Required'
        } else {
          error['org_name'] = false
        }
        break;
      case "club_name":
        if (value === '') {
          error['club_name'] = 'Required'
        } else {
          error['club_name'] = false
        }
        break;
      case "fname":
        if (value === '') {
          error['fname'] = 'Required'
        } else {
          error['fname'] = false
        }
        break;
      case "mname":
        if (value === '') {
          error['mname'] = 'Required'
        } else {
          error['mname'] = false
        }
        break;
      case "lname":
        if (value === '') {
          error['lname'] = 'Required'
        } else {
          error['lname'] = false
        }
        break;
      case "gender":
        if (value === '') {
          error['gender'] = 'Required'
        } else {
          error['gender'] = false
        }
        break;
      case "address":
        if (value === '') {
          error['address'] = 'Required'
        } else {
          error['address'] = false
        }
        break;
      case "state":
        if (value === '') {
          error['state'] = 'Required'
        } else {
          error['state'] = false
        }
        break;
      case "city":
        if (value === '') {
          error['city'] = 'Required'
        } else {
          error['city'] = false
        }
        break;
      case "zip":
        if (value === '') {
          error['zip'] = 'Required'
        } else {
          error['zip'] = false
        }
        break;
      case "website":
        if (value === '') {
          error['website'] = 'Required'
        } else {
          error['website'] = false
        }
        break;
      case "telephone":
        if (value === '') {
          error['telephone'] = 'Required'
        } else if (value.length != 12) {
          error['telephone'] = 'Required'
        } else {
          error['telephone'] = false
        }
        break;
      default: break
    }
  }

  const handleCretePlayer = () => {
    if (state.fname === '') {
      setError(e => ({
        ...e,
        fname: 'Please enter first name'
      }))
    }
    if (state.mname === '') {
      setError(e => ({
        ...e,
        mname: 'Please enter middle name'
      }))
    }
    if (state.lname === '') {
      setError(e => ({
        ...e,
        lname: 'Please enter last name'
      }))
    }
    if (state.gender === '') {
      setError(e => ({
        ...e,
        gender: 'Required'
      }))
    }
    if (state.telephone === '') {
      setError(e => ({
        ...e,
        telephone: 'Please enter telephone no.'
      }))
    }
    if (state.terms_condition == 0) {
      setError(e => ({
        ...e,
        terms_condition: 'Accept Terms and Conditions'
      }))
    }
    if (state.address == 0) {
      setError(e => ({
        ...e,
        address: 'Required'
      }))
    }
    if (state.state == 0) {
      setError(e => ({
        ...e,
        state: 'Required'
      }))
    }
    if (state.city == 0) {
      setError(e => ({
        ...e,
        city: 'Required'
      }))
    }
    if (state.zip == 0) {
      setError(e => ({
        ...e,
        zip: 'Required'
      }))
    }
    if (state.website == 0) {
      setError(e => ({
        ...e,
        website: 'Required'
      }))
    }
    if (state.org_name == 0) {
      setError(e => ({
        ...e,
        org_name: 'Required'
      }))
    }
    if (state.club_name == 0) {
      setError(e => ({
        ...e,
        club_name: 'Required'
      }))
    }


    if (id == 5 || id == 6) {
      if (state.fname !== '' &&
        state.mname !== '' &&
        state.lname !== '' &&
        state.gender !== '' &&
        state.telephone !== '' &&
        state.terms_condition != 0 &&
        !error.telephone
      ) {
        props.addAccount({
          type: id,
          name: state.fname + " " + state.lname,
          first_name: state.fname,
          last_name: state.lname,
          middle_name: state.mname,
          gender: state.gender === 'male' ? 1 : 0,
          mobile_number: state.telephone.replace(/-/g, '')
        })
      }
    } else if (id == 2 || id == 3) {
      if (state.fname !== '' &&
        state.mname !== '' &&
        state.lname !== '' &&
        state.telephone !== '' &&
        !error.telephone
      ) {
        props.addAccount({
          type: id,
          name: state.fname + " " + state.lname,
          first_name: state.fname,
          last_name: state.lname,
          middle_name: state.mname,
          mobile_number: state.telephone.replace(/-/g, '')
        })
      }
    } else if (id == 1) {
      const stateId = allstate?.find(e => e.name === state.state)
      if (state.fname !== '' &&
        state.mname !== '' &&
        state.lname !== '' &&
        state.telephone !== '' &&
        state.org_name !== '' &&
        state.state !== '' &&
        state.city !== '' &&
        state.zip !== '' &&
        state.website !== '' &&
        !error.telephone
      ) {
        props.addAccount({
          type: id,
          name: state.fname + " " + state.lname,
          first_name: state.fname,
          last_name: state.lname,
          middle_name: state.mname,
          name: state.org_name,
          address: state.address,
          state: stateId,
          city: state.city,
          zip: state.zip,
          mobile_number: state.telephone.replace(/-/g, '')
        })
      }
    } else if (id == 4) {
      const stateId = allstate?.find(e => e.name === state.state)
      if (state.fname !== '' &&
        state.mname !== '' &&
        state.lname !== '' &&
        state.telephone !== '' &&
        state.club_name !== '' &&
        state.state !== '' &&
        state.city !== '' &&
        state.zip !== '' &&
        state.website !== '' &&
        !error.telephone
      ) {
        props.addAccount({
          type: id,
          name: state.fname + " " + state.lname,
          first_name: state.fname,
          last_name: state.lname,
          middle_name: state.mname,
          name: state.club_name,
          address: state.address,
          state: stateId,
          city: state.city,
          zip: state.zip,
          mobile_number: state.telephone.replace(/-/g, '')
        })
      }
    }
  }

  useEffect(() => {
    if (props.addAccountReducer.success) {
      history.push('/add-account')
    }
  }, [props.addAccountReducer])

  return (
    <div className="main-panel">
      <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex auth my-4">
          <div className="row flex-grow">
            <div className="col-lg-5 mx-auto mt-24">
              <div className="auth-form-light text-left p-5">
                <h4 className="text-white text-center">Create Account</h4>
                <h6 className="mt-2 text-center" style={{ color: "#989898" }}>
                  {(id === 1 || id === '1') && "I'm Organizer"}
                  {(id === 2 || id === '2') && "I'm Staff"}
                  {(id === 3 || id === '3') && "I'm Coach"}
                  {(id === 4 || id === '4') && "I'm Club"}
                  {(id === 5 || id === '5') && "I'm a Player"}
                  {(id === 6 || id === '6') && "I'm Parent/guardian"}
                </h6>

                <div className='mt-4'>
                  {/* org name */}
                  {id == 1 && <div className="form-group">
                    <input
                      type="text"
                      placeholder="Organizer Name*"
                      className={classNames("form-control ",
                        {
                          "login-error": error.org_name,
                        }
                      )}
                      id="org_name"
                      name="org_name"
                      value={state.org_name}
                      onChange={eventhandler}
                    />

                    {error.org_name && (
                      <span className="text-danger small d-block">
                        * {error.org_name}
                      </span>
                    )}
                  </div>}
                  {/* club name */}
                  {id == 4 && <div className="form-group">
                    <input
                      type="text"
                      placeholder="Club Name*"
                      className={classNames("form-control ",
                        {
                          "login-error": error.club_name,
                        }
                      )}
                      id="club_name"
                      name="club_name"
                      value={state.club_name}
                      onChange={eventhandler}
                    />

                    {error.club_name && (
                      <span className="text-danger small d-block">
                        * {error.club_name}
                      </span>
                    )}
                  </div>}
                  {/* fname */}
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="First Name*"
                      className={classNames("form-control ",
                        {
                          "login-error": error.fname,
                        }
                      )}
                      id="fname"
                      name="fname"
                      value={state.fname}
                      onChange={eventhandler}
                    />

                    {error.fname && (
                      <span className="text-danger small d-block">
                        * {error.fname}
                      </span>
                    )}
                  </div>
                  {/* mname */}
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Middle Name*"
                      className={classNames("form-control ",
                        {
                          "login-error": error.mname,
                        }
                      )}
                      id="mname"
                      name="mname"
                      value={state.mname}
                      onChange={eventhandler}
                    />

                    {error.mname && (
                      <span className="text-danger small d-block">
                        * {error.mname}
                      </span>
                    )}
                  </div>
                  {/* lname */}
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Last Name*"
                      className={classNames("form-control ",
                        {
                          "login-error": error.lname,
                        }
                      )}
                      id="lname"
                      name="lname"
                      value={state.lname}
                      onChange={eventhandler}
                    />

                    {error.lname && (
                      <span className="text-danger small d-block">
                        * {error.lname}
                      </span>
                    )}
                  </div>
                  {/* dob */}
                  <div className='mb-2'>
                  <div className="desk-datepicker">
            <ThemeProvider theme={materialTheme}>
             <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
               placeholder="dd/mm/yyyy"
               format="dd/mm/yyyy"
               id={'dob'}
               mask="__/__/____"
               inputVariant="outlined"
               InputAdornmentProps={{ position: "end" }}
               className={classNames("form-control team-name", { "error": error.dob })}
               invalidDateMessage={true}
               value={state.dob}
               maxDate={new Date("01-01-2005")}
               onChange={(value)=> {
                setState((prev)=> {
                  const newState = {...prev, dob: value}
                  return newState
                })
               }}
              />
             </MuiPickersUtilsProvider>
            </ThemeProvider>
            {error.dob &&
             <span className='text-red small d-block'>* {error.dob}</span>}
           </div>
                  </div>

                  {/* <div className='form-group'>
                  <input type="date" className={classNames("form-control ",
                        {
                          "login-error": error.dob,
                        }
                      )} />
                  </div> */}
                  
                  {/* gender */}
                  {(id == '5' || id == '6') && <div className="form-group">
                    <select
                      className={classNames("form-control",
                        {
                          "login-error": error.gender,
                        }
                      )}
                      style={{ color: '#000' }}
                      id="gender"
                      name="gender"
                      value={state.gender}
                      onChange={eventhandler}
                    >
                      <option value=''>Select Gender*</option>
                      <option value="male" >
                        Male
                      </option>
                      <option value="female" >
                        Female
                      </option>
                    </select>

                    {error.gender && (
                      <span className="text-danger small d-block">
                        * {error.gender}
                      </span>
                    )}
                  </div>}
                  {/* phone */}
                  {<div className="form-group">
                    <InputMask mask="999-999-9999" className={classNames("form-control", { "error": error.telephone })} value={state.telephone} onChange={eventhandler} maskChar={""} placeholder="Phone *" name="telephone" id="telephone" />

                    {error.telephone && (
                      <span className="text-danger small d-block">
                        * {error.telephone}
                      </span>
                    )}
                  </div>}



                  {/* address */}
                  {(id == 1 || id == 4 ) &&
                    <>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Address*"
                          className={classNames("form-control ",
                            {
                              "login-error": error.address,
                            }
                          )}
                          id="address"
                          name="address"
                          value={state.address}
                          onChange={eventhandler}
                        />

                        {error.address && (
                          <span className="text-danger small d-block">
                            * {error.address}
                          </span>
                        )}
                      </div>
                      {(id == 1 || id == 4) && <div className="form-group">
                        <select
                          className={classNames("form-control",
                            {
                              "login-error": error.state,
                            }
                          )}
                          style={{ color: '#000' }}
                          id="state"
                          name="state"
                          value={state.state}
                          onChange={eventhandler}
                        >
                          <option value="">State *</option>
                          {allstate?.map((v, i) => {
                            return (<option key={v?._id} value={v?.name}>{v?.name}</option>)
                          })}
                        </select>

                        {error.state && (
                          <span className="text-danger small d-block">
                            * {error.state}
                          </span>
                        )}
                      </div>}
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="City*"
                          className={classNames("form-control ",
                            {
                              "login-error": error.city,
                            }
                          )}
                          id="city"
                          name="city"
                          value={state.city}
                          onChange={eventhandler}
                        />

                        {error.city && (
                          <span className="text-danger small d-block">
                            * {error.city}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Zip Code*"
                          className={classNames("form-control ",
                            {
                              "login-error": error.zip,
                            }
                          )}
                          id="zip"
                          name="zip"
                          value={state.zip}
                          onChange={eventhandler}
                        />

                        {error.zip && (
                          <span className="text-danger small d-block">
                            * {error.zip}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Website*"
                          className={classNames("form-control ",
                            {
                              "login-error": error.website,
                            }
                          )}
                          id="website"
                          name="website"
                          value={state.website}
                          onChange={eventhandler}
                        />

                        {error.website && (
                          <span className="text-danger small d-block">
                            * {error.website}
                          </span>
                        )}
                      </div>
                    </>}

                  {(id == '5' || id == '6') && <div className="text-left" style={{ marginTop: "14px" }}>
                    <input type="checkbox" name="terms_condition" id="terms_condition" value={state.terms_condition} checked={state.terms_condition == 0 ? false : true} onChange={() => {
                      setState(pre => ({
                        ...pre,
                        terms_condition: state.terms_condition == 0 ? 1 : 0
                      }))

                      error['terms_condition'] = false

                    }} />
                    <label className="ml-2 text-white fs-13">
                      <span className="fs-13 auth-link fw-m"> Terms & Condition</span>
                    </label>
                    {error.terms_condition && (<span className="text-danger small d-block">* {error.terms_condition}</span>
                    )}
                  </div>}
                  <div className='d-flex justify-content-center'>
                    <div className="btn btn-login font-weight-medium auth-form-btn ml-2 pointer mt-3" onClick={handleCretePlayer}>
                      Create
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProp = state => ({
  addAccountReducer: state.addAccountReducer,
})

const mapDispatchToProps = dispatch => ({
  addAccount: user => dispatch(addAccount(user)),
  getAllStateHandler: user => dispatch(getAllState()),
})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(ClubAddAccountForm)); 