import React, { useEffect, useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  OrgbalancesdueAction,
  clubTeamDetails,
  clubcreditAction,
  clubdiscountAction,
  getOrgClubDetails,
  getOrgClubList,
  orgClubById,
} from "../../../service/action/userAction";
import Spinner from "../../../Spinner/Spinner";

const ClubInfo = (props) => {
  const { id } = useParams();
  const [clubTeamDetails, setclubTeamDetails] = useState([]);
  const [clubdiscountdata, setclubdiscountdata] = useState([]);
  const [clubcreditdata, setclubcreditdata] = useState([]);
  const [clubbalancesduedata, setclubbalancesduedata] = useState([]);
  const [details, setDetails] = useState({});
  useEffect(() => {
    if (!!id) {
      props.orgClubById({ id: id });
      props.clubTeamDetailsHandler({ id: id });
      props.clubdiscountActionHandler({ id: id });
      props.clubcreditActionHandler({ id: id });
      props.OrgbalancesdueActionHandler({ id: id });
    }
  }, [id]);

  useEffect(() => {
    if (props.getClubByIdReducer?.success) {
      const data = props.getClubByIdReducer?.user;
      setDetails(data?.data);
    }
  }, [props.getClubByIdReducer]);

  useEffect(() => {
    if (props.clubTeamDetailsReducer?.success) {
      const data = props.clubTeamDetailsReducer?.user?.data;
      setclubTeamDetails(data);
    }
  }, [props.clubTeamDetailsReducer]);

  useEffect(() => {
    if (props.clubdiscountReducer?.success) {
      const data = props.clubdiscountReducer?.user?.data;
      setclubdiscountdata(data);
    }
  }, [props.clubdiscountReducer]);

  useEffect(() => {
    if (props.clubcreditReducer?.success) {
      const data = props.clubcreditReducer?.user?.data;
      setclubcreditdata(data);
    }
  }, [props.clubcreditReducer]);

  useEffect(() => {
    if (props.OrgbalancedueReducer?.success) {
      const data = props.OrgbalancedueReducer?.user?.data;
      setclubbalancesduedata(data);
    }
  }, [props.OrgbalancedueReducer]);

  return (
    // <></>
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="d-flex justify-content-center mb-3">
              <img
                src={
                  details?.profile_image
                    ? details?.profile_image
                    : "./images/pch-images/pch.svg"
                }
                height="150px"
                width="200px"
              />
            </div>
            <div className="create-team-head">
              {
                <span
                  className="dash-head"
                  style={{ fontWeight: "bold", color: "#38A6DD" }}
                >
                  {details?.name ? details?.name : "N/A"}
                </span>
              }
            </div>
          </div>

          <div className="team-details">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="details"
                  data-toggle="pill"
                  href="#details-tab"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Details
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="teams"
                  data-toggle="pill"
                  href="#teams-tab"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Teams
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="teams"
                  data-toggle="pill"
                  href="#discount-tab"
                  role="tab"
                  aria-controls="pills-discount"
                  aria-selected="false"
                >
                  Discount
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="teams"
                  data-toggle="pill"
                  href="#credit-tab"
                  role="tab"
                  aria-controls="pills-credit"
                  aria-selected="false"
                >
                  Credit
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="teams"
                  data-toggle="pill"
                  href="#balances-due-tab"
                  role="tab"
                  aria-controls="pills-balances-due"
                  aria-selected="false"
                >
                  Balances Due
                </a>
              </li>
              {/* <li className="nav-item" role="presentation">
                                <a className="nav-link" id="roster" data-toggle="pill" href="#roster-tab" role="tab" aria-controls="pills-contact" aria-selected="false">Rosters</a>
                            </li> */}
            </ul>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="details-tab"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {!!details ? (
                  <>
                    <div className="row mx-0">
                      <div className="col-md-4">
                        <strong>Club Info</strong>
                        <div className="text-left mt-2">
                          <div className="text-gray mb-1">
                            Club Name:{" "}
                            {`${details?.first_name} ${details?.last_name}`}
                          </div>
                          <div className="text-gray mb-1">
                            Zip Code:{" "}
                            {details?.zip != "" ? details?.zip : "N/A"}
                          </div>
                          <div className="text-gray mb-1">
                            Email:{" "}
                            {details?.email != "" ? details?.email : "N/A"}
                          </div>
                          <div className="text-gray mb-1">
                            Mobile Number:{" "}
                            {details?.mobile_number != ""
                              ? details?.mobile_number
                              : "N/A"}
                          </div>
                          <div className="text-gray mb-1">
                            Address:{" "}
                            {details?.address != "" ? details?.address : "N/A"}
                          </div>
                        </div>
                        <div className="mt-4">
                          <strong>Directors</strong>

                          <div className="text-left mt-2">
                            <div className="text-gray mb-1">
                              Director Name:{" "}
                              {details?.director_name != ""
                                ? details?.director_name
                                : "N/A"}
                            </div>
                            <div className="text-gray mb-1">
                              Mobile Number:{" "}
                              {details?.director_mobile_number != ""
                                ? details?.director_mobile_number
                                : "N/A"}
                            </div>
                            <div className="text-gray mb-1">
                              Email:{" "}
                              {details?.director_email != ""
                                ? details?.director_email
                                : "N/A"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Spinner />
                  </div>
                )}
              </div>

              <div
                className="tab-pane fade"
                id="teams-tab"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                {/* <h6 className="text-left payment mt-30 fw-m">Teams</h6> */}

                {clubTeamDetails.length == 0 ? (
                  <>
                    <span className="noti-type">No Teams in this club</span>
                  </>
                ) : (
                  <>
                    <div className="mt-30 ">
                      <>
                        {clubTeamDetails.map((v, i) => {
                          return (
                            <div key={i} className="mb-3">
                              <div className="d-flex mx-0">
                                <div className="dashboard-img mr-3">
                                  <img src="./images/pch-images/pch.svg" />
                                </div>
                                <div className="reg-content">
                                  <Link
                                    to={{
                                      pathname: `/team-details/${v._id}`,
                                      state: {
                                        path: 'club'
                                      }
                                    }}
                                    style={{ color: "#38A6DD" }}
                                  >
                                    <strong className="pointer">
                                      {v.team_name}
                                    </strong>
                                  </Link>
                                  <div className="text-gray mb-1">
                                    Season:{" "}
                                    {!!v.season && v.season.season_name != ""
                                      ? v.season.season_name
                                      : "N/A"}
                                  </div>
                                  <div className="text-gray mb-1">
                                    Division:{" "}
                                    {!!v.division &&
                                    v?.division?.division_name != ""
                                      ? v?.division?.division_name
                                      : "N/A"}
                                  </div>
                                </div>
                              </div>
                              <hr className="my-1" />
                            </div>
                          );
                        })}
                      </>
                    </div>
                  </>
                )}
              </div>

              <div
                className="tab-pane"
                id="discount-tab"
                role="tabpanel"
                aria-labelledby="pills-discount"
              >
                {clubdiscountdata?.length == 0 ? (
                  <>
                    <span className="noti-type">Discount is not available</span>
                  </>
                ) : (
                  <>
                    {clubdiscountdata.map((e, i) => {
                      return (
                        <div key={i}>
                          <div className="d-flex align-items-center mb-2 py-2">
                            <p className="text-left  mb-0  fs-14">Code : </p>
                            <div className="text-left ml-1 m-0">
                              {(e.code_name = "" ? e?.code_name : "N/A")}
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-2 py-2 text-gray">
                            <p className="text-left  mb-0  fs-14">Credit : </p>
                            <h6 className="text-left  ml-1 text-gray m-0">
                              {e?.amount != "" ? e?.amount : "N/A"}
                            </h6>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>

              <div
                className="tab-pane"
                id="credit-tab"
                role="tabpanel"
                aria-labelledby="pills-credit"
              >
                {clubcreditdata?.length == 0 ? (
                  <>
                    <span className="noti-type">Credit is not available</span>
                  </>
                ) : (
                  <>
                    {clubcreditdata.map((e, i) => {
                      return (
                        <div key={i}>
                          <div
                            className="d-flex align-items-center mb-2 py-2"
                            style={{ fontWeight: "bold" }}
                          >
                            <p className="text-left  mb-0  fs-14">Code : </p>
                            <div className="text-left ml-1 m-0">
                              {e.receipient?.name != ""
                                ? e.receipient?.name
                                : "N/A"}
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-2 py-2 text-gray">
                            <p className="text-left  mb-0  fs-14">Credit : </p>
                            <h6 className="text-left  ml-1 text-gray m-0">
                              {e.credit_amount != "  "
                                ? e.credit_amount
                                : "N/A"}
                            </h6>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>

              <div
                className="tab-pane"
                id="balances-due-tab"
                role="tabpanel"
                aria-labelledby="pills-balances-due"
              >
                {clubbalancesduedata.length == 0 ? (
                  <>
                    <span className="noti-type">
                      Balance due is not available
                    </span>
                  </>
                ) : (
                  <>
                    {clubbalancesduedata.map((e, i) => {
                      return (
                        <div key={i}>
                          <strong className="mb-2">
                            Team:{" "}
                            {!!e.team && e.team.team_name !== ""
                              ? e.team.team_name
                              : "N/A"}
                          </strong>
                          <div className="text-gray mb-2">
                            Amount: {e.amount}
                          </div>
                          <div className="text-gray mb-2">
                            Pending: {e.panding_amount}
                          </div>
                          <hr className="my-2" />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(ClubInfo);

const mapStateToProps = (state) => ({
  submit: state.createTeamReducer,
  clubdiscountReducer: state.clubdiscountReducer,
  clubcreditReducer: state.clubcreditReducer,
  OrgbalancedueReducer: state.OrgbalancedueReducer,
  clubTeamDetailsReducer: state.clubTeamDetailsReducer,
  getClubByIdReducer: state.getClubByIdReducer,
});

const mapDispatchToProps = (dispatch) => ({
  orgClubById: (user) => dispatch(orgClubById(user)),
  clubTeamDetailsHandler: (user) => dispatch(clubTeamDetails(user)),
  clubdiscountActionHandler: (user) => dispatch(clubdiscountAction(user)),
  clubcreditActionHandler: (user) => dispatch(clubcreditAction(user)),
  OrgbalancesdueActionHandler: (user) => dispatch(OrgbalancesdueAction(user)),

  // userDataHandler: user => dispatch(userData(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClubInfo));
