import classNames from 'classnames'
import React from 'react'
import { useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import InputMask from "react-input-mask";
import { createPaymentMethod, editPaymentMethod } from '../../../service/action/Club/clubAction';
import { connect, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { RESET_PAYMENT_METHOD } from '../../../service/constants';
import { getAllCity, getAllState } from '../../../service/action/userAction';
import BreadcrumbHistory from '../../../BreadcumbHistory/BreadcrumbHistory';

function EditPaymentMethod(props) {

 const { id } = useParams()


 const [state, setState] = useState({
  card_name: '',
  card_no: '',
  // expiry_month: '',
  // expiry_year: '',
  expiration_date: '',
  address: '',
  zip: '',
  country: '',
  state: '',
  city: '',
  save_payment_method: false,
  preferred_payment: false,
  delete_from_payment: false
 })

 const [errors, setErrors] = useState({
  card_name: false,
  card_no: false,
  // expiry_month: false,
  // expiry_year: false,
  expiration_date: false,
  address: false,
  zip: false,
  country: false,
  state: false,
  city: false,
 })

 const location = useLocation()

 const [selectedCard, setSelectedCard] = useState(null)

 const history = useHistory()
 const getAllState = props.getAllStateHandler;
 const getAllCity = props.getAllCityHandler

 const allstate = useSelector(state => state.getStateReducer.states)
 const allcity = useSelector(state => state.getCityReducer.citys)
 const cardDetails = useSelector((state) => state.paymentMethodReducer.data.data)

 useEffect(() => {
  if (!cardDetails) {
   history.push('/payment/payment-method')
  } else {
   setSelectedCard(cardDetails.find(e => e._id === id))
  }
 }, [])

 useEffect(() => {
  if (selectedCard !== null) {
   setState({
    card_name: selectedCard.card_name,
    card_no: selectedCard.card_number,
    // expiry_month: selectedCard.expiry_month,
    // expiry_year: selectedCard.expiry_year,
    expiration_date: selectedCard.expiration_date,
    address: selectedCard.billing_address.address,
    zip: selectedCard.billing_address.zip_code,
    country: selectedCard.billing_address.country,
    state: selectedCard.billing_address.state.name,
    city: selectedCard.billing_address.city.name,
    save_payment_method: selectedCard.default.consent_payment === 1 ? true : false,
    preferred_payment: selectedCard.preferred_payment === 1 ? true : false,
    delete_from_payment: selectedCard.default.delete_payment === 1 ? true : false
   })
  }
 }, [selectedCard])



 useEffect(() => {
  const getDetails = async () => {
   await getAllState()
  }
  getDetails()
 }, [])


 // useEffect()

 useEffect(() => {
  const id = allstate.find(v => v.name === state.state)?._id
  if (id !== undefined) {
   getAllCity({ id: id })
  }
 }, [allstate, state.state])

 useEffect(() => {
  if (props.PaymentMethod?.success) {
   history.push('/payment/payment-method')
   props.resetPaymentMethod()
  }
 }, [props.PaymentMethod])

 const eventhandler = (e) => {
  const { name, value, type, checked } = e.target

  if (type !== 'checkbox') {
   setState(prevState => ({
    ...prevState,
    [name]: value
   }))
  } else {
   setState(prevState => ({
    ...prevState,
    [name]: checked
   }))
  }

  switch (name) {
   case "card_name":
    if (value === '') {
     setErrors(prevState => ({
      ...prevState,
      card_name: 'Enter Valid Card name'
     }))
    } else {
     setErrors(prevState => ({
      ...prevState,
      card_name: false
     }))
    }
    break;

   case "card_no":
    if (value === '') {
     setErrors(prevState => ({
      ...prevState,
      card_no: 'Enter Valid Card Number'
     }))
    } else {
     setErrors(prevState => ({
      ...prevState,
      card_no: false
     }))
    }
    break;

   // case "expiry_month":
   //  if (value === '') {
   //   setErrors(prevState => ({
   //    ...prevState,
   //    expiry_month: 'Enter Expiry month'
   //   }))
   //  } else {
   //   setErrors(prevState => ({
   //    ...prevState,
   //    expiry_month: false
   //   }))
   //  }
   //  break;

   case "expiration_date":
    if (value === '') {
     setErrors(prevState => ({
      ...prevState,
      expiration_date: 'Enter Expiration Date'
     }))
    } else {
     setErrors(prevState => ({
      ...prevState,
      expiration_date: false
     }))
    }
    break;

   case "address":
    if (value === '') {
     setErrors(prevState => ({
      ...prevState,
      address: 'Enter Address'
     }))
    } else {
     setErrors(prevState => ({
      ...prevState,
      address: false
     }))
    }
    break;


   case "zip":
    if (value === '') {
     setErrors(prevState => ({
      ...prevState,
      zip: 'Enter Zip code'
     }))
    } else {
     setErrors(prevState => ({
      ...prevState,
      zip: false
     }))
    }
    break;

   case "country":
    if (value === '') {
     setErrors(prevState => ({
      ...prevState,
      country: 'Enter country name'
     }))
    } else {
     setErrors(prevState => ({
      ...prevState,
      country: false
     }))
    }
    break;

   case "state":
    if (value === '') {
     setErrors(prevState => ({
      ...prevState,
      state: 'Enter state name'
     }))
    } else {
     setErrors(prevState => ({
      ...prevState,
      state: false
     }))
    }
    break;

   case "city":
    if (value === '') {
     setErrors(prevState => ({
      ...prevState,
      city: 'Enter city name'
     }))
    } else {
     setErrors(prevState => ({
      ...prevState,
      city: false
     }))
    }
    break;
   default: break;
  }



 }

 const createMethod = (e) => {
  e.preventDefault()
  if (state.card_name === '') {
   setErrors(prevState => ({
    ...prevState,
    card_name: 'Enter Valid Card name'
   }))
  }

  if (state.card_no === '') {
   setErrors(prevState => ({
    ...prevState,
    card_no: 'Enter Valid Card Number'
   }))
  }

  // if (state.expiry_month === '') {
  //  setErrors(prevState => ({
  //   ...prevState,
  //   expiry_month: 'Enter Expiry month'
  //  }))
  // }

  if (state.expiration_date === '') {
   setErrors(prevState => ({
    ...prevState,
    expiration_date: 'Enter Expiration Date'
   }))
  }

  if (state.address === '') {
   setErrors(prevState => ({
    ...prevState,
    address: 'Enter Address'
   }))
  }

  if (state.zip === '') {
   setErrors(prevState => ({
    ...prevState,
    zip: 'Enter Zip code'
   }))
  }


  if (state.country === '') {
   setErrors(prevState => ({
    ...prevState,
    country: 'Enter country name'
   }))
  }

  if (state.state === '') {
   setErrors(prevState => ({
    ...prevState,
    state: 'Enter state name'
   }))
  }

  if (state.city === '') {
   setErrors(prevState => ({
    ...prevState,
    city: 'Enter city name'
   }))
  }

  if (
   state.card_name !== '' &&
   state.card_no !== '' &&
   // state.expiry_year !== '' &&
   // state.expiry_month !== '' &&
   state.expiration_date !== '' &&
   state.address !== '' &&
   state.zip !== '' &&
   state.country !== '' &&
   state.state !== '' &&
   state.city !== ''
  ) {
   const details = {
    card_name: state.card_name,
    card_number: state.card_no.split('-').join(''),
    // expiry_month: state.expiry_month,
    // expiry_year: state.expiry_year,
    expiration_date: state.expiration_date,
    billing_address: {
     address: state.address,
     city: allcity.find(e => e.name === state.city)?._id,
     state: allstate.find(e => e.name === state.state)?._id,
     zip_code: state.zip,
     country: state.country
    },
    default: {
     consent_payment: state.save_payment_method ? 1 : 0,
     delete_payment: state.delete_from_payment ? 1 : 0,
     preferred_payment: state.preferred_payment ? 1 : 0,
    }
   }
   props.editPaymentMethod({
    id: id,
    data: details
   })
  }

 }

 return (
  <React.Fragment>
   <div className="main-panel">
    <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />

    <div className="content-wrapper">
     <div className="text-left d-flex justify-content-between align-items-center flex-wrap">
      <div className="create-team-head">
       <h5 className="dash-head">Edit Payment Method</h5>
      </div>
      <div className="mt-2 mt-md-0 text-right  rounded">
       <Link to="/payment/payment-method/add-payment-method" className='text-decoration-none '>
        <button className="btn-create-new " onClick={createMethod}>Edit</button>
       </Link>
      </div>
     </div>


     {/* details */}
     <div>
      <div className="d-flex justify-content-between mt-30">
       <h4 className="text-left">Card Details</h4>

      </div>
      <div className='row mx-0'>
       <div className="text-left col-12 col-md-7 mt-18">

        {/* card name */}
        <div className='form-row'>
         <div className="col-md-6 col-12 mt-18">
          <input type="text" className={classNames("form-control team-name", { "error": errors.card_name })} placeholder="Card Name *" id="card_name" value={state.card_name} name="card_name" onChange={eventhandler} />
          {errors.card_name && <span className='text-red small d-block'>* {errors.card_name}</span>}
         </div>
        </div>

        {/* card number */}

        <div className='form-row'>
         <div className="col-md-6 col-12 mt-18">
          <InputMask mask="9999-9999-9999-9999" className={classNames("form-control team-name", { "error": errors.card_no })} placeholder="Card No. *" id="card_no" value={state.card_no} name="card_no" onChange={eventhandler} maskChar={"_"} />
          {errors.card_no && <span className='text-red small d-block'>* {errors.card_no}</span>}
         </div>
        </div>

        {/* expiry date and expiry_year */}


        <div className='form-row'>

         {/* <div className="col-md-6 mt-18">
          <InputMask mask="99" className={classNames("form-control team-name", { "error": errors.expiry_month })} placeholder="ExpMonth *" id="expiry_month" value={state.expiry_month} name="expiry_month" onChange={(e) => eventhandler(e)} maskChar={"_"} />
          {errors.expiry_month && <span className='text-red small d-block'>* {errors.expiry_month}</span>}
         </div> */}


         <div className="col-md-6 mt-18">

          <InputMask mask="9999" className={classNames("form-control team-name", { "error": errors.expiration_date })} placeholder="Expiration date (MMYY)" id="expiration_date" value={state.expiration_date} name="expiration_date" onChange={eventhandler} maskChar={"_"} />
          {errors.expiration_date && <span className='text-red small d-block'>* {errors.expiration_date}</span>}
         </div>
        </div>




       </div>
      </div>
     </div>

     {/* billing Addres */}

     <div>
      <div className="d-flex justify-content-between mt-30">
       <h4 className="text-left">Billing Address</h4>

      </div>
      <div className='row mx-0'>
       <div className="text-left col-12 col-md-7 mt-18">

        {/* address */}
        <div className='form-row'>
         <div className="col-12 mt-18">
          <input type="text" className={classNames("form-control team-name", { "error": errors.address })} placeholder="Address *" id="address" value={state.address} name="address" onChange={eventhandler} />
          {errors.address && <span className='text-red small d-block'>* {errors.address}</span>}
         </div>
        </div>

        {/* zip code and country */}

        <div className='form-row'>

         <div className="col-md-6 mt-18">
          <InputMask mask="999999" className={classNames("form-control team-name", { "error": errors.zip })} placeholder="Zip Code *" id="zip" value={state.zip} name="zip" onChange={eventhandler} maskChar={"_"} />
          {errors.zip && <span className='text-red small d-block'>* {errors.zip}</span>}
         </div>


         <div className="col-md-6 mt-18">
          <input type="text" className={classNames("form-control team-name", { "error": errors.country })} placeholder="Country *" id="country" value={state.country} name="country" onChange={eventhandler} />
          {errors.country && <span className='text-red small d-block'>* {errors.country}</span>}
         </div>
        </div>

        {/* state and city */}


        <div className='form-row'>

         <div className="col-md-6 mt-18">
          <select className={classNames("form-control team-name", { "error": errors.state })} placeholder="State *" id="state" name="state"
           value={state.state}
           onChange={eventhandler}
          >
           <option value="" disabled>State *</option>
           {allstate.map((v, i) => {
            return (<option key={v._id} id={v._id} value={v.name}>{v.name}</option>)
           })}
          </select>
          {errors.state && <span className='text-red small d-block'>* {errors.state}</span>}
         </div>

         <div className="col-md-6 mt-18">
          <select className={classNames("form-control team-name", { "error": errors.city })} placeholder="City *" id="city" name="city"
           value={state.city}
           onChange={eventhandler}
          >
           <option value="" disabled>City *</option>
           {allcity.map((v, i) => {
            return (<option key={v._id} value={v.name} >{v.name}</option>)
           })}
          </select>
          {errors.city && <span className='text-red small d-block'>* {errors.city}</span>}
         </div>
        </div>

       </div>
      </div>
     </div>


     {/* options */}

     <div className='mt-30'>
      <div className='mt-18'>
       <div className='d-flex align-items-center'>
        <input type={'checkbox'} name='save_payment_method' id='save_payment_method' onChange={eventhandler} checked={state.save_payment_method} />
        <div className='fw-m ml-3'>Save Payment Method</div>
       </div>
      </div>

      <div className='mt-18'>
       <div className='d-flex align-items-center'>
        <input type={'checkbox'} name='preferred_payment' id='preferred_payment' onChange={eventhandler} checked={state.preferred_payment} />
        <div className='fw-m ml-3'>Make preferred form of payment</div>
       </div>
      </div>

      <div className='mt-18'>
       <div className='d-flex align-items-center'>
        <input type={'checkbox'} name='delete_from_payment' id='delete_from_payment' onChange={eventhandler} checked={state.delete_from_payment} />
        <div className='fw-m ml-3'>Delete this form of payment</div>
       </div>
      </div>

     </div>

    </div>
   </div>
  </React.Fragment>
 )
}

const mapStateToProps = (state) => ({
 PaymentMethod: state.paymentMethodReducer

})

const mapDispatchToProps = dispatch => ({
 editPaymentMethod: user => dispatch(editPaymentMethod(user)),
 getAllStateHandler: user => dispatch(getAllState()),
 getAllCityHandler: user => dispatch(getAllCity(user)),
 resetPaymentMethod: () => dispatch({ type: RESET_PAYMENT_METHOD })
})

export default connect(mapStateToProps, mapDispatchToProps)((EditPaymentMethod))