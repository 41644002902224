import { APPROVE_LIABILITY_ERROR, APPROVE_LIABILITY_LOADING, APPROVE_LIABILITY_SUCCESS } from "../../constants";

let initialState = {
 loading: false,
 error: false,
 success: false,
 payload: {}


};

export default function approveLiabilityReducer(state = initialState, action) {

 switch (action.type) {

  case APPROVE_LIABILITY_LOADING:
   return { ...state, error: false, loading: true, success: false };

  case APPROVE_LIABILITY_SUCCESS:
   return { ...state, error: false, loading: false, success: true, message: action.msg };

  case APPROVE_LIABILITY_ERROR:
   return { ...state, error: true, loading: false, success: false, errors: action.errors, msg: action.errors }

  default:
   return state;
 }
}

