import {GET_CLUB_ORG_EVENT_DETAILS_ERROR, GET_CLUB_ORG_EVENT_DETAILS_LOADING, GET_CLUB_ORG_EVENT_DETAILS_SUCCESS, RESET_CLUB_ORG_EVENT_DETAIL } from "../../../constants";

let initialState={
    loading:false,
    error:false,
    success:false,
    error:[],
    data:[]
}
export default function getClubOrgEventDetailsReducer(state=initialState,action){
    switch(action.type){
        case GET_CLUB_ORG_EVENT_DETAILS_LOADING:
            return {...state,loading:true,error:false,loading:false,success:false}
        case GET_CLUB_ORG_EVENT_DETAILS_SUCCESS:
            return {...state,loading:false,error:false,success:true,data:action.data}
        case GET_CLUB_ORG_EVENT_DETAILS_ERROR:
            return {...state,loading:false,error:true,success:false,error:[action.errors]}
        case RESET_CLUB_ORG_EVENT_DETAIL:
            return {...state, loading: false, error: false, success: false}
        default :
            return state;
    }
}