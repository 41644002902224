import {   GET_ALL_REPORT_ERROR, GET_ALL_REPORT_LOADING, GET_ALL_REPORT_SUCCESS, GET_REFUND_REPORT_ERROR, GET_REFUND_REPORT_LOADING, GET_REFUND_REPORT_SUCCESS, RESET_ALL_REPORT, RESET_REFUND_REPORT } from "../../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
  data:[]


};

export default function gegtAllReportReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_REPORT_LOADING:
      return { ...state, success: false, success: false };

    case GET_ALL_REPORT_SUCCESS:
      return { ...state, loading: false, success: true, user: action.payload };
     
    case GET_ALL_REPORT_ERROR:
      return { ...state, error: true, errors: action.errors };

    case RESET_ALL_REPORT:
      return { ...state, error: false, success:false, loading:false };

    default:
      return state;
  }
}
