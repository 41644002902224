import { CLUB_GET_RECIPIENT_ERROR, CLUB_GET_RECIPIENT_LOADING, CLUB_GET_RECIPIENT_SUCCESS, RESET_CLUB_GET_RECIPIENT } from "../../constants";

let initialState = {
 error: false,
 success: false,
 loading: false,
 errors: [],
 data: []
}

export default function getClubRecipientReducer(state = initialState, action) {
  switch (action.type) {
    case CLUB_GET_RECIPIENT_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, error: false, loading: true };

    case CLUB_GET_RECIPIENT_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };

    case CLUB_GET_RECIPIENT_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors, success: false };

    case RESET_CLUB_GET_RECIPIENT:
      // alert(" reducer Loading")
      return { ...state, error: false, loading: false, success: false };

    default:
      return state;
  }
}