import {
  DELETE_EVENT_POOL_ERROR,
  DELETE_EVENT_POOL_LOADING,
  DELETE_EVENT_POOL_SUCCESS,
  REMOVE_POOLTEAM_ERROR,
  REMOVE_POOLTEAM_LOADING,
  REMOVE_POOLTEAM_SUCCESS,
  REMOVE_POOL_PLAYER_ERROR,
  REMOVE_POOL_PLAYER_LOADING,
  REMOVE_POOL_PLAYER_SUCCESS,
  RESET_DELETE_EVENT_POOL,
  RESET_REMOVE_POOLTEAM,
  RESET_REMOVE_POOL_PLAYER,
} from "../../../../constants";
let initialState = {
  success: false,
  error: false,
  loading: false,
  error: [],
};
export default function deleteEventPoolReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_EVENT_POOL_LOADING:
      return { ...state, success: false, loading: true, error: false };
    case DELETE_EVENT_POOL_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        message: action.msg,
      };
    case DELETE_EVENT_POOL_ERROR:
      return { ...state, success: false, loading: false, error: false };
    case RESET_DELETE_EVENT_POOL:
      return { ...state, success: false, loading: false, error: false };
    default:
      return state;
  }
}
export function deletePoolTeamReducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_POOLTEAM_LOADING:
      return { ...state, success: false, loading: true, error: false };
    case REMOVE_POOLTEAM_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        message: action.msg,
      };
    case REMOVE_POOLTEAM_ERROR:
      return { ...state, success: false, loading: false, error: false };
    case RESET_REMOVE_POOLTEAM:
      return { ...state, success: false, loading: false, error: false };
    default:
      return state;
  }
}