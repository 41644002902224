import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { connect, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { removeAccount } from '../../../service/action/Club/clubAction'

const Account = (props) => {


    const profileImage = useSelector(state => state.accountDetailsReducer.user.data)

    const [image, setImage] = useState(null)
    const [isActive, setIsActive] = useState(false)
    const [liabilityActive, setLiabilityActive] = useState(false)

    const [isRemoveAccount, setRemoveAccount] = useState(false)
    const [deleteValue, setDeleteValue] = useState('')
    const [error, setError] = useState(false)
    const [status, setStatus] = useState(0)


    useEffect(() => {
        if (profileImage) {
            setImage(profileImage.profile_image !== "" ? profileImage.profile_image : "./images/pch-images/pch.svg")
            setStatus(profileImage.status)
        }
    }, [props.accountDetails])


    useEffect(() => {
        if (props.removeAccountDetails?.success) {
            setRemoveAccount(false)
            setError(false)
        }
    }, [props.removeAccountDetails])


    const handleRemoveAccount = () => {
        if (deleteValue === 'delete') {
            props.removeAccount()
        } else {
            setError('value is not empty')
        }
    }

    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    {/* <div className="text-left d-flex justify-content-between align-items-center flex-wrap">
                        <div className="create-team-head">
                            <h5 className="dash-head">Accounts</h5>
                        </div>
                        <label className="switch flex-wrap">
                            <input type="checkbox" checked={status == 1} onChange={(e) => {
                                setStatus(e.target.checked ? 1 : 0)
                            }} />
                            <span className="slider order-1"></span>
                            <p className='off mb-0 order-0'>Public</p>
                            <p className="on mb-0 order-2">Private</p>
                        </label>
                    </div> */}

                    <div className="row">
                        <div className="col-md-6">
                            <div className="text-left mt-30">
                                <div className="basic-info">
                                    <label className="change-pic" htmlFor="file-upload">
                                        <img src={image} className="img-fluid" />
                                    </label>

                                    <div className="ml-18 account">
                                        <span className="d-block account-name mb-0">{localStorage.getItem("username")}</span>
                                        <span className="d-block account-type">Account Owner</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="text-left mt-30">
                                <Link to="/account-details" className="account-tab">
                                    <div className="account-type-section">
                                        Account Details
                                        <i className="mdi mdi-chevron-right" ></i>
                                    </div>
                                </Link>
                                <div>
                                    <div className={`account-type-section pointer ${isActive && 'border-bottom-0 fw-m'}`} onClick={() => setIsActive(!isActive)}>
                                        Permissions
                                        <i className={isActive ? "mdi mdi-chevron-down" : "mdi mdi-chevron-right"} ></i>
                                    </div>
                                    {isActive && <Link to="/admin" className="account-tab fw-m">
                                        <div className="account-type-section">
                                            Admin
                                        </div>
                                    </Link>}
                                </div>
                                <Link className="account-tab" to="/merchant">
                                    <div className="account-type-section">
                                        Merchant Account
                                        <i className="mdi mdi-chevron-right" ></i>
                                    </div>
                                </Link>

                                <Link className="account-tab" to="/season">
                                    <div className="account-type-section">
                                        Seasons
                                        <i className="mdi mdi-chevron-right" ></i>
                                    </div>
                                </Link>
                                <Link className="account-tab" to="/leagues">
                                    <div className="account-type-section">
                                        Leagues
                                        <i className="mdi mdi-chevron-right" ></i>
                                    </div>
                                </Link>
                                <Link className="account-tab" to="/field">
                                    <div className="account-type-section">
                                        Fields
                                        <i className="mdi mdi-chevron-right" ></i>
                                    </div>
                                </Link>
                                <Link className="account-tab" to="/division">
                                    <div className="account-type-section">
                                        Divisions
                                        <i className="mdi mdi-chevron-right" ></i>
                                    </div>
                                </Link>
                                <div>
                                    <div className={`account-type-section ${liabilityActive && 'border-bottom-0 fw-m'}`} onClick={() => setLiabilityActive(!liabilityActive)}>
                                        Liability Waivers
                                        <i className={liabilityActive ? "mdi mdi-chevron-down" : "mdi mdi-chevron-right"} ></i>
                                    </div>
                                    {liabilityActive && <Link to="/liability-waiver" className="account-tab fw-m">
                                        <div className="account-type-section">
                                            Signed Waivers
                                        </div>
                                    </Link>}
                                </div>
                                <div>
                                    <div className={`account-type-section pointer`} onClick={() => setRemoveAccount(true)}>
                                        Remove Your Account
                                        <i className="mdi mdi-chevron-right" ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={isRemoveAccount}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <div className='p-3' style={{ backgroundColor: '#ffffff' }}>
                    <div className='text-gray'>
                        Are you sure you want to Remove your Account?
                    </div>
                    <div className='text-gray mt-2'>
                        All of your account data, history, and information will be removed from our system and will not be recoverable
                    </div>
                    <div className='text-gray mt-3'>
                        Type 'delete'
                        <input type="email" className={"form-control team-name mt-2"} value={deleteValue} onChange={(e) => {
                            setDeleteValue(e.target.value)
                            if (e.target.value === '') {
                                setError('value is not empty')
                            } else {
                                setError(false)
                            }
                        }} />
                        {error && <span className='text-red small d-block'>* {error}</span>}

                    </div>
                    <div className="mt-4 d-flex justify-content-center border-top-0">
                        <button onClick={() => {
                            setRemoveAccount(false)
                            setError(false)
                        }} className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 mx-3 rounded">
                            Cancel
                        </button>
                        <button className="text-white bg-info border-0 fs-15 py-2 px-4 px-md-5 mx-3 rounded" onClick={handleRemoveAccount}>
                            Confirm
                        </button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

// export default Account;


const mapStateToProp = state => ({
    accountDetails: state.accountDetailsReducer,
    removeAccountDetails: state.removeAccountReducer
})

const mapDispatchToProps = dispatch => ({
    removeAccount: user => dispatch(removeAccount()),
})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(Account));