import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import BreadcrumbHistory from "../BreadcrumbHistory/BreadcrumbHistory";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { GetMerchantDetails } from "../../Services/Actions/merchantAction";
import { toast } from "react-toastify";
import { removeStorage } from "../../Services/constant";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";

const DATA_ITEM_KEY = "_id";

function MerchantAccount(props) {
  const location = useLocation();
  const history = useHistory();

  const [xlShow, setXlShow] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleClose = () => setXlShow(false);
  const handleShow = () => setXlShow(true);

  const [currentData, setCurrentData] = useState(null);
  const [delShow, setDelShow] = useState(false);
  const delDialClose = () => setDelShow(false);
  const delDialShow = () => setDelShow(true);

  const [InitialState, setInitialState] = useState([]);

  const [successShow, setSuccessShow] = useState(false);
  const successDialClose = () => setSuccessShow(false);
  const successDialShow = () => {
    setDelShow(false);
    setSuccessShow(true);
  };

  const [pageDetails, setPageDetails] = useState({
    skip: 0,
    take: 10,
  });

  useEffect(() => {
    props.GetMerchantDetails();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (props.merchantDetailsReducer.success) {
      if (searchVal !== "") {
        const data = [...InitialState];
        const filter = data.filter(
          (e) =>
            e.owner_Principal.first_name
              .toLowerCase()
              .includes(searchVal.toLowerCase()) ||
            e.owner_Principal.last_name
              .toLowerCase()
              .includes(searchVal.toLowerCase()) ||
            e.username.toLowerCase().includes(searchVal.toLowerCase())
        );
        setState(filter);
      } else {
        const data = [...InitialState];
        setState(data);
      }
    }
  }, [searchVal]);

  useEffect(() => {
    if (props.merchantDetailsReducer.success) {
      const data = props.merchantDetailsReducer.data;
      const _data = data.data.map((e) => {
        return {
          ...e,
          first_name: e.user.first_name !== "" ? e.user.first_name : "N/A",
          last_name: e.user.last_name !== "" ? e.user.last_name : "N/A",
          user_email: e.user.email !== "" ? e.user.email : "N/A",
        };
      });
      setState(_data);
      setInitialState(_data);
      setLoading(false);
    } else if (props.merchantDetailsReducer.error) {
      const msg = props.merchantDetailsReducer.errorMsg;
      toast.error(msg);
      if (msg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      }
    }
  }, [props.merchantDetailsReducer]);

  const customHeader = (cell) => {
    return <b>{cell.title}</b>;
  };
  const customStatus = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <Button type="button" className="btn-status  btn-primary active">
          Active
        </Button>
      </td>
    );
  };

  const customAction = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <button
          onClick={() => {
            const details = cell.dataItem;
            setCurrentData(details);
            handleShow();
          }}
          className="btn-link btn text-secondary p-0 mx-2"
        >
          <i className="ri-eye-line"></i>
        </button>
      </td>
    );
  };

  const grid = (
    <Grid
      data={state.slice(pageDetails.skip, pageDetails.skip + pageDetails.take)}
      skip={pageDetails.skip}
      take={pageDetails.take}
      total={state.length}
      pageable={{
        buttonCount: 3,
        pageSizes: [5, 10, 15],
      }}
      onPageChange={(e) => {
        setPageDetails(() => ({
          ...e.page,
          take: e.page.take,
        }));
      }}
      dataItemKey={DATA_ITEM_KEY}
    >
      <GridNoRecords>There are no merchant accounts available</GridNoRecords>
      <GridColumn
        field="first_name"
        title="First Name"
        headerCell={customHeader}
      />
      <GridColumn
        field="last_name"
        title="Last Name"
        headerCell={customHeader}
      />
      <GridColumn field="user_email" title="Email" headerCell={customHeader} />
      <GridColumn field="username" title="username" headerCell={customHeader} />
      <GridColumn
        field="merchant_key"
        title="Merchant Key"
        headerCell={customHeader}
      />
      <GridColumn
        field="action"
        title="Action"
        headerCell={customHeader}
        cell={customAction}
      />
    </Grid>
  );

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2 className="page-heading">Merchant</h2>
        {/* <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} /> */}
      </div>
      <Row className="justify-content-between mx-0">
        <Col md="4" className="search px-0">
          <i className="ri-search-line"></i>
          <Form.Control
            type="text"
            placeholder="Search"
            value={searchVal}
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
          />
        </Col>
      </Row>

      <div className="table-card">{grid}</div>
      <Modal
        size="xl"
        show={xlShow}
        onHide={() => {
          handleClose();
          setCurrentData(null);
        }}
        scrollable="true"
      >
        <Modal.Header>
          <Modal.Title>
            <strong>Merchant Account Details</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="h3">User Details</div>
          <div className="row mx-0 mt-12">
            <div className="col-md-4 col-sm-6 mt-2 col-12">
              <strong>First Name: </strong>
              {currentData?.user?.first_name}
            </div>
            <div className="col-md-4 col-sm-6 mt-2 col-12">
              <strong>Last Name: </strong>
              {currentData?.user?.last_name}
            </div>
            <div className="col-md-4 col-sm-6 mt-2 col-12">
              <strong>Email: </strong>
              {currentData?.user?.email != ""
                ? currentData?.user?.email
                : "N/A"}
            </div>
            <div className="col-md-4 col-sm-6 mt-2 col-12">
              <strong>Merchant Key: </strong>
              {currentData?.merchant_key}
            </div>
            <div className="col-md-4 col-sm-6 mt-2 col-12">
              <strong>Username: </strong>
              {currentData?.username}
            </div>
            <div className="col-md-4 col-sm-6 mt-2 col-12">
              <strong>Usertype: </strong>
              {currentData?.user_type == 1
                ? "Organizer"
                : currentData?.user_type == 4
                ? "Club"
                : currentData?.user_type}
            </div>
          </div>

          {/*  compony */}
          <hr />
          <div className="h3 mt-18">Company Details</div>

          <div className="row mx-0 mt-12">
            <div className="col-sm-6 mt-2 col-12">
              <strong>Company Legal Name: </strong>
              {currentData?.company?.company_legal_name !== ""
                ? currentData?.company?.company_legal_name
                : "N/A"}
            </div>
            <div className="col-sm-6 mt-2 col-12">
              <strong>Company DBA Name: </strong>
              {currentData?.company?.company_dba_name !== ""
                ? currentData?.company?.company_dba_name
                : "N/A"}
            </div>
            <div className="col-sm-6 mt-2 col-12">
              <strong>Company Federal Tax Id: </strong>
              {currentData?.company?.company_federal_tax_id !== ""
                ? currentData?.company?.company_federal_tax_id
                : "N/A"}
            </div>
            <div className="col-sm-6 mt-2 col-12">
              <strong>Company Ownership Type: </strong>
              {currentData?.company?.company_ownership_type !== ""
                ? currentData?.company?.company_ownership_type
                : "N/A"}
            </div>
          </div>

          {/*  legal Address */}

          <hr />
          <div className="h3 mt-18">Legal Address</div>

          <div className="row mx-0 mt-12">
            <div className="col-sm-6 mt-2 col-12">
              <strong>Address: </strong>
              {currentData?.company?.legal_address?.address !== ""
                ? currentData?.company?.legal_address?.address
                : "N/A"}
            </div>
            <div className="col-sm-6 mt-2 col-12">
              <strong>State: </strong>
              {currentData?.company?.legal_address?.state !== ""
                ? currentData?.company?.legal_address?.state
                : "N/A"}
            </div>
            <div className="col-sm-6 mt-2 col-12">
              <strong>City: </strong>
              {currentData?.company?.legal_address?.city !== ""
                ? currentData?.company?.legal_address?.city
                : "N/A"}
            </div>
            <div className="col-sm-6 mt-2 col-12">
              <strong>Postal Code: </strong>
              {currentData?.company?.legal_address?.postal_code !== ""
                ? currentData?.company?.legal_address?.postal_code
                : "N/A"}
            </div>
          </div>

          {/*  Owner Principal */}

          <hr />
          <div className="h3 mt-18">Owner Principal</div>

          <div className="row mx-0 mt-12">
            <div className="col-sm-6 mt-2 col-12">
              <strong>First Name: </strong>
              {currentData?.owner_Principal?.first_name !== ""
                ? currentData?.owner_Principal?.first_name
                : "N/A"}
            </div>
            <div className="col-sm-6 mt-2 col-12">
              <strong>Last Name: </strong>
              {currentData?.owner_Principal?.last_name !== ""
                ? currentData?.owner_Principal?.last_name
                : "N/A"}
            </div>
            <div className="col-sm-6 mt-2 col-12">
              <strong>Date of Birth: </strong>
              {currentData?.owner_Principal?.date_of_birth !== ""
                ? currentData?.owner_Principal?.date_of_birth
                : "N/A"}
            </div>
            <div className="col-sm-6 mt-2 col-12">
              <strong>Email: </strong>
              {currentData?.owner_Principal?.email_address !== ""
                ? currentData?.owner_Principal?.email_address
                : "N/A"}
            </div>
            <div className="col-sm-6 mt-2 col-12">
              <strong>Phone: </strong>
              {currentData?.owner_Principal?.phone !== ""
                ? currentData?.owner_Principal?.phone
                : "N/A"}
            </div>
            {/* <div className="col-sm-6 mt-2 col-12">
              <strong>Driver Lisence: </strong>
              {currentData?.owner_Principal?.drivers_license_number !== ""
                ? currentData?.owner_Principal?.drivers_license_number
                : "N/A"}
            </div> */}
            {/* <div className="col-sm-6 mt-2 col-12">
              <strong>Social Security Number: </strong>
              {currentData?.owner_Principal?.social_security_number !== ""
                ? currentData?.owner_Principal?.social_security_number
                : "N/A"}
            </div> */}
          </div>

          {/*  Bank */}

          <hr />
          {/* <div className="h3 mt-18">Bank Detail</div> */}

          {/* <div className="row mx-0 mt-12">
            <div className="col-sm-6 mt-2 col-12">
              <strong>Bank RoutingNumber: </strong>
              {currentData?.bank?.bank_routing_number !== ""
                ? currentData?.bank?.bank_routing_number
                : "N/A"}
            </div>
            <div className="col-sm-6 mt-2 col-12">
              <strong>Bank Account Number: </strong>
              {currentData?.bank?.bank_account_number !== ""
                ? currentData?.bank?.bank_account_number
                : "N/A"}
            </div>
          </div> */}

          <div className="text-end ml-auto mt-4">
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleClose();
                setCurrentData(null);
              }}
              style={{ fontSize: "18px" }}
              className="mr-3 pointer"
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete confirmation */}
      <Modal
        size="sm"
        show={delShow}
        onHide={delDialClose}
        centered
        className="del-dialogue"
      >
        <Modal.Body>
          <h6 className="text-center">
            Are you sure you want to delete this account?
          </h6>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Button className="btn-gray" onClick={delDialClose}>
            No
          </Button>
          <Button variant="secondary" onClick={successDialShow}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete success */}
      <Modal
        size="sm"
        show={successShow}
        onHide={successDialClose}
        centered
        className="del-dialogue success"
      >
        <Modal.Body className="text-center">
          <i className="ri-close-circle-line fa-4x text-secondary"></i>
          <h5 className="text-center">Record deleted Successfully !!</h5>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProp = (state) => ({
  merchantDetailsReducer:
    state.superAdminRootReducer.MerchantReducer.merchantDetails,
});

const mapDispatchToProps = (dispatch) => ({
  GetMerchantDetails: () => dispatch(GetMerchantDetails()),
});

export default connect(mapStateToProp, mapDispatchToProps)(MerchantAccount);
