import {
  NOTIFICATION_COUNT_SUCCESS,
  NOTIFICATION_COUNT_LOADING,
  NOTIFICATION_COUNT_ERROR,
  RESET_NOTIFICATION_COUNT,
} from "../../constants";

let initialState = {
  loading: false,
  data: {},
  error: false,
  errors: [],
  success: false,
};

export default function notificationCountReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_COUNT_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case NOTIFICATION_COUNT_SUCCESS:
      return { ...state, loading: false, success: true, data: action.payload };

    case NOTIFICATION_COUNT_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    case RESET_NOTIFICATION_COUNT:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        data: {},
      };
    default:
      return state;
  }
}
