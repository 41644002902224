import {
  CREATE_STAY_AND_PLAY_ERROR,
  CREATE_STAY_AND_PLAY_LOADING,
  CREATE_STAY_AND_PLAY_MESSAGE_ERROR,
  CREATE_STAY_AND_PLAY_MESSAGE_LOADING,
  CREATE_STAY_AND_PLAY_MESSAGE_SUCCESS,
  CREATE_STAY_AND_PLAY_SUCCESS,
  DELETE_STAY_AND_PLAY_ERROR,
  DELETE_STAY_AND_PLAY_LOADING,
  DELETE_STAY_AND_PLAY_SUCCESS,
  RESET_CREATE_STAY_AND_PLAY,
  RESET_CREATE_STAY_AND_PLAY_MESSAGE,
  RESET_DELETE_STAY_AND_PLAY,
  RESET_UPDATE_STAY_AND_PLAY,
  UPDATE_STAY_AND_PLAY_ERROR,
  UPDATE_STAY_AND_PLAY_LOADING,
  UPDATE_STAY_AND_PLAY_SUCCESS,
} from "../../constants";

let initialState = {
  createStayandPlay: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  updateStayandPlay: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  deleteStayandPlay: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
  createStayandPlayMessage: {
    loading: false,
    data: null,
    error: false,
    errors: null,
    success: false,
  },
};

export default function StayandPlayReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_STAY_AND_PLAY_LOADING:
      return {
        ...state,
        createStayandPlay: {
          ...state.createStayandPlay,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case CREATE_STAY_AND_PLAY_SUCCESS:
      return {
        ...state,
        createStayandPlay: {
          ...state.createStayandPlay,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.user,
        },
      };

    case CREATE_STAY_AND_PLAY_ERROR:
      return {
        ...state,
        createStayandPlay: {
          ...state.createStayandPlay,
          loading: false,
          error: true,
          errors: action.msg,
          success: false,
          data: null,
        },
      };

    case RESET_CREATE_STAY_AND_PLAY:
      return {
        ...state,
        createStayandPlay: {
          ...state.createStayandPlay,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };

    //   ............................  update Reducer   ..............

    case UPDATE_STAY_AND_PLAY_LOADING:
      return {
        ...state,
        updateStayandPlay: {
          ...state.updateStayandPlay,
          loading: true,
          error: false,
          errors: [],
          success: false,
        },
      };

    case UPDATE_STAY_AND_PLAY_SUCCESS:
      return {
        ...state,
        updateStayandPlay: {
          ...state.updateStayandPlay,
          loading: false,
          error: false,
          errors: [],
          success: true,
          data: action.user,
        },
      };

    case UPDATE_STAY_AND_PLAY_ERROR:
      return {
        ...state,
        updateStayandPlay: {
          ...state.updateStayandPlay,
          loading: false,
          error: true,
          errors: action.msg,
          success: false,
          data: null,
        },
      };

    case RESET_UPDATE_STAY_AND_PLAY:
      return {
        ...state,
        updateStayandPlay: {
          ...state.updateStayandPlay,
          loading: false,
          error: false,
          errors: null,
          success: false,
        },
      };


    //   ..........................   delete reducer   ..................

    case DELETE_STAY_AND_PLAY_LOADING:
        return {
          ...state,
          deleteStayandPlay: {
            ...state.deleteStayandPlay,
            loading: true,
            error: false,
            errors: [],
            success: false,
          },
        };
  
      case DELETE_STAY_AND_PLAY_SUCCESS:
        return {
          ...state,
          deleteStayandPlay: {
            ...state.deleteStayandPlay,
            loading: false,
            error: false,
            errors: [],
            success: true,
            data: action.msg,
          },
        };
  
      case DELETE_STAY_AND_PLAY_ERROR:
        return {
          ...state,
          deleteStayandPlay: {
            ...state.deleteStayandPlay,
            loading: false,
            error: true,
            errors: action.errors,
            success: false,
            data: null,
          },
        };
  
      case RESET_DELETE_STAY_AND_PLAY:
        return {
          ...state,
          deleteStayandPlay: {
            ...state.deleteStayandPlay,
            loading: false,
            error: false,
            errors: null,
            success: false,
          },
        };



        // ................   message reducer   .................

        case CREATE_STAY_AND_PLAY_MESSAGE_LOADING:
            return {
              ...state,
              createStayandPlayMessage: {
                ...state.createStayandPlayMessage,
                loading: true,
                error: false,
                errors: [],
                success: false,
              },
            };
      
          case CREATE_STAY_AND_PLAY_MESSAGE_SUCCESS:
            return {
              ...state,
              createStayandPlayMessage: {
                ...state.createStayandPlayMessage,
                loading: false,
                error: false,
                errors: [],
                success: true,
                data: action.user,
              },
            };
      
          case CREATE_STAY_AND_PLAY_MESSAGE_ERROR:
            return {
              ...state,
              createStayandPlayMessage: {
                ...state.createStayandPlayMessage,
                loading: false,
                error: true,
                errors: action.msg,
                success: false,
                data: null,
              },
            };
      
          case RESET_CREATE_STAY_AND_PLAY_MESSAGE:
            return {
              ...state,
              createStayandPlayMessage: {
                ...state.createStayandPlayMessage,
                loading: false,
                error: false,
                errors: null,
                success: false,
              },
            };

    default:
      return state;
  }
}
