import { RESET_PAYMENT_INFO, SAVE_PAYMENT_INFO } from '../../../../constants';

let initialState = {
    success: false,
    loading:false,
    error:false,
    user: null,
}

export default function sendPaymentInfoReducer(state = initialState, action) {

    switch (action.type) {

        case SAVE_PAYMENT_INFO:
            return { ...state, success: true, user: action.payload, data: action.data };

        case RESET_PAYMENT_INFO:
            return { ...state, success: false, error: false, loading: false }


        default:
            return state;
    }  

}