import axios from "axios";
import { sportUrl } from "../apiSheet";
import {
  ADD_SPORT_LIST_ERROR,
  ADD_SPORT_LIST_SUCCESS,
  DELETE_SPORT_LIST_ERROR,
  DELETE_SPORT_LIST_SUCCESS,
  GET_SPORT_LIST_ERROR,
  GET_SPORT_LIST_SUCCESS,
  UPDATE_SPORT_LIST_ERROR,
  UPDATE_SPORT_LIST_SUCCESS,
} from "../constant";

export const GetSportDetails = () => {
  return (dispatch) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: sportUrl,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_SPORT_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: GET_SPORT_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: GET_SPORT_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: GET_SPORT_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const AddSportDetails = (details) => {
  return (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: sportUrl,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ADD_SPORT_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: ADD_SPORT_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: ADD_SPORT_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: ADD_SPORT_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const UpdateSportDetails = ({ details, id }) => {
  return (dispatch) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${sportUrl}/${id}`,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: UPDATE_SPORT_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: UPDATE_SPORT_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: UPDATE_SPORT_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: UPDATE_SPORT_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};

export const DeleteSportDetails = (id) => {
  return (dispatch) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${sportUrl}/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_SPORT_LIST_SUCCESS,
          response: {
            data: resp.data,
          },
        });
      })
      .catch((error) => {
        let data = error.response;
        if (!error.response) {
          dispatch({
            type: DELETE_SPORT_LIST_ERROR,
            errorMsg: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.message;
          dispatch({
            type: DELETE_SPORT_LIST_ERROR,
            errorMsg: validationError,
          });
        } else if (data.status === 401) {
          dispatch({
            type: DELETE_SPORT_LIST_ERROR,
            errorMsg: "UnAuthorized",
          });
        }
      });
  };
};
