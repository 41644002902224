import { CREATE_ORG_ROSTER_ERROR, CREATE_ORG_ROSTER_SUCCESS, CREATE_ROSTER_SUCCESS, RESET_CREATE_ORG_ROSTER, RESET_CREATE_ROSTER_ERROR } from "../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {},
  message: "",
};

export default function createRosterReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ORG_ROSTER_SUCCESS:
      return { ...state, error: false, loading: true, success: false };

    case CREATE_ROSTER_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        message: action.msg,
      };

    case CREATE_ORG_ROSTER_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        errors: [],
      };

    case RESET_CREATE_ORG_ROSTER:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        errors: [],
      };
    case RESET_CREATE_ROSTER_ERROR:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        errors: [],
      };

    default:
      return state;
  }
}
