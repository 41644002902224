import { GET_DASHBOARD_PAYMENT_ERROR, GET_DASHBOARD_PAYMENT_LOADING, GET_DASHBOARD_PAYMENT_SUCCESS } from "../../constants"

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {}

};

export default function getDashboardPaymentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_PAYMENT_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case GET_DASHBOARD_PAYMENT_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };
     
    case GET_DASHBOARD_PAYMENT_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors, error:action.error };

    default:
      return state;
  }
}
