import { CREATE_FIELDMAP_MESSAGE_ERROR, CREATE_FIELDMAP_MESSAGE_LOADING, CREATE_FIELDMAP_MESSAGE_SUCCESS, RESET_CREATE_FIELDMAP_MESSAGE } from "../constants";

  
  let initialState = {
    loading: false,
    user: [],
    error: false,
    errors: [],
    success: false,
    payload: {},
  };
  
  export default function CreateFieldmapMessageReducer(state = initialState, action) {
    switch (action.type) {
      case CREATE_FIELDMAP_MESSAGE_LOADING:
        return { ...state, error: false, loading: true, success: false };
  
      case CREATE_FIELDMAP_MESSAGE_SUCCESS:
        return {
          ...state,
          error: false,
          loading: false,
          success: true,
          message: action.msg,
        };
  
      case CREATE_FIELDMAP_MESSAGE_ERROR:
        return {
          ...state,
          error: true,
          loading: false,
          success: false,
          errors: action.errors,
          msg: action.errors,
        };
  
      case RESET_CREATE_FIELDMAP_MESSAGE:
        return {
          ...state,
          error: false,
          loading: false,
          success: false,
          errors: [],
          user: [{}],
        };
      default:
        return state;
    }
  }
  