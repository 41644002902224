import { GET_STAFF_INVITATION_ERROR, GET_STAFF_INVITATION_LOADING, GET_STAFF_INVITATION_SUCCESS } from "../../../constants";


let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function getStaffInvitationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STAFF_INVITATION_LOADING:
      return { ...state, success: false, success: false };

    case GET_STAFF_INVITATION_SUCCESS:
      return { ...state, loading: false, success: true, user: action.payload };
     
    case GET_STAFF_INVITATION_ERROR:
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}
