import { GET_MERCHANT_DETAILS_ERROR, GET_MERCHANT_DETAILS_SUCCESS, RESET_GET_MERCHANT_DETAILS } from "../constant";

let initialState = {
 merchantDetails: {
  data: null,
  error: false,
  success: false,
  errorMsg: ''
 },
};
export default function MerchantReducer(state = initialState, action) {
 switch (action.type) {
  //  login details
  case GET_MERCHANT_DETAILS_SUCCESS:
   return { ...state, merchantDetails: { ...state.merchantDetails, success: true, data: action.response.data, error: false } };

  case GET_MERCHANT_DETAILS_ERROR:
   return { ...state, merchantDetails: { ...state.merchantDetails, error: true, success: false, data: null, errorMsg: action.errorMsg } };

  case RESET_GET_MERCHANT_DETAILS:
   return { ...state, merchantDetails: { ...state.merchantDetails, error: false, success: false, errorMsg: '' } };

  default:
   return state;
 }
}
