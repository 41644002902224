import React, { useEffect, useState } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import {
  getAcceptedPlayer,
  getClubTeamDetails,
  getEventTeam,
  getTeamDetails,
} from "../../../service/action/userAction";
import { connect, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Modal, Table } from "react-bootstrap";
import { Icon, Zoom } from "@material-ui/core";
import Spinner from "../../../Spinner/Spinner";
import { fontSize } from "@mui/system";
import { confirmAlert } from "react-confirm-alert";

// import TeamDetails from "../../../club/Team/Team-details/Team-Details";
const EventTeamDetails = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { id, id1 } = useParams();

  useEffect(() => {
    if (!!location.state.path) {
      if (location.state.path == "event") {
        props.getTeamDetailsHandler({ id: id });
      } else if (location.state.path == "club") {
        props.getClubTeamDetails({ id: id });
      }
    } else {
      history.goBack();
    }
  }, [location]);

  useEffect(() => {
    props.getAcceptedPlayerhandler({ id: id });
  }, []);

  const getTeamdetails = useSelector(
    (state) => state.getTeamDetailsReducer.user.data
  );

  useEffect(() => {
    if (props.submit?.success) {
      // notify(deleteEventMsg.message);
      // props.resetdeletePaymentCodeReducer();
      props.getAcceptedPlayerhandler({ id: id });
      // history.push(`/event-details/${id}`);
    }
  }, [props.submit]);

  const getEventuser = useSelector((state) => state.getEventTeamReducer.user);

  const loading = useSelector((state) => state.getTeamDetailsReducer.loading);

  // const [showModal, setShowModal] = useState(false);
  // const [state, setState] = useState({
  //   team: "",
  //   division: "",
  //   pool: "",
  //   image: "",
  //   player: [],
  // });

  // useEffect(() => {
  //   if (!loading) {
  //     setState((prevState) => ({
  //       ...prevState,
  //       team: getTeamdetails?.club?.team_name,
  //       division:
  //         getTeamdetails?.division == null
  //           ? "null"
  //           : getTeamdetails?.division?.division_name,
  //       pool:
  //         getTeamdetails?.pool == null
  //           ? "null"
  //           : getTeamdetails?.pool?.pool_name,
  //       image: getTeamdetails?.team?.image,
  //       player: getTeamdetails?.player,
  //     }));
  //   }
  // }, [!loading]);

  const val = () => {
    confirmAlert({
      title: "You Want To Delete This Data ?",
      message: "Are You Sure You Want To Delete This Data ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => alert("Click Yes"),
          //   style:{backgroud-co    lor:"#143D4A"}
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
      customUI: ({ onClose }) => {
        return (
          <div id="react-confirm-alert">
            <div
              className="react-confirm-alert-overlay"
              style={{ background: "rgba(255, 255, 255, 0.1)" }}
            >
              <div className="react-confirm-alert">
                <div className="react-confirm-alert-body">
                  <h2 style={{ color: "#143D4A" }}>
                    Are you sure you want to Delete ?
                  </h2>
                  {/* Are You Sure You Want To Delete This Data ? */}
                  <div className="react-confirm-alert-button-group">
                    <button
                      style={{ background: "#143D4A" }}
                      onClick={() => {
                        Delete();
                        onClose();
                      }}
                    >
                      Yes
                    </button>
                    <button
                      style={{ background: "#143D4A" }}
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
    const Delete = () => {
      // const history = useHistory();
      // alert("outer logout")
      // if (props.Delete?.success) {
      // alert("logout")

      history.push(`/event-details/${id}`);
    };
  };

  useEffect(() => {
    if (props.Delete?.success) {
    }
  }, [props.Delete]);

  return (
    <React.Fragment>
      <React.Fragment>
        <div className="main-panel">
          <div className="content-wrapper">
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            ) : (
              <>
                <div className="text-left">
                  <div className="text-left my-4">
                    <img
                      src={
                        getTeamdetails?.team?.image
                          ? getTeamdetails?.team?.image
                          : "./images/pch-images/pch.svg"
                      }
                      className="img-fluid event-img"
                    />
                  </div>
                  <div className="create-team-head">
                    <h5 className="dash-head pr-3">
                      {getTeamdetails?.team?.team_name}
                    </h5>
                    {/* <div className="d-flex align-items-center">
                                            <div className="reg-team">
                                                <Link className="text-decoration-none" to="/teams">
                                                    <i className="mdi mdi-account-multiple"></i>
                                                    <span>Register Teams</span>
                                                </Link>
                                            </div>
                                        </div> */}
                  </div>
                </div>

                <div className="team-details">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="details"
                        data-toggle="pill"
                        href="#details-tab"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Details
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="teams"
                        data-toggle="pill"
                        href="#players-tab"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Players
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="payment"
                        data-toggle="pill"
                        href="#payment-tab"
                        role="tab"
                        aria-controls="pills-payment"
                        aria-selected="false"
                      >
                        Rosters
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="details-tab"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <h6 className="text-left payment mt-30 fw-m">
                        Team Details
                      </h6>
                      <div className="text-left details-content">
                        <span>
                          Team Name :{" "}
                          {location.state.path == "event"
                            ? getTeamdetails?.team?.team_name
                            : getTeamdetails?.team_name}
                        </span>
                        <span>
                          Division:{" "}
                          {getTeamdetails?.division?.division_name
                            ? getTeamdetails?.division?.division_name
                            : "NA"}
                        </span>
                        <span>
                          Pool :{" "}
                          {getTeamdetails?.pool?.pool_name
                            ? getTeamdetails?.pool?.pool_name
                            : "NA"}
                        </span>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="players-tab"
                      role="tabpanel"
                      aria-labelledby="pills-players-tab"
                    >
                      <div>
                        {/* <div className="row mx-0">
                          <div className="col-md-12  mt-3 d-flex justify-content-end">
                            <Link to={`/team-details/player/${id}`}>
                              <button className="btn-create-new ">
                                Add players
                              </button>
                            </Link>
                          </div>
                        </div> */}
                        {!!getTeamdetails &&
                        getTeamdetails?.player?.length !== 0 ? (
                          <>
                            <div className="row mx-0">
                              <table className="col-md-6 table event-table mt-3">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>email</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {getTeamdetails?.player?.length > 0 &&
                                    getTeamdetails?.player?.map((v, i) => {
                                      return (
                                        <tr key={i}>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <div className="dashboard-img">
                                                <img src="./images/pch-images/pch.svg" />
                                              </div>
                                              <span className="ml-12">
                                                {v.name + " " + v.last_name}
                                              </span>
                                            </div>
                                          </td>
                                          <td>{v.email ? v.email : "NA"}</td>
                                          <td>
                                            <div className="dropdown event-drp details-drp">
                                              <i
                                                className="mdi mdi-dots-vertical"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              ></i>
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                              >
                                                <button className="dropdown-item">
                                                  <i className="mdi mdi-plus website-ico mr-2"></i>
                                                  Add to roster
                                                </button>
                                                <button
                                                  className="dropdown-item"
                                                  onClick={props.onHide}
                                                >
                                                  <i className="mdi mdi-minus website-ico mr-2"></i>
                                                  Remove from team
                                                </button>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          <>
                            <span className="d-flex justify-content-center text-gray">
                              No Players Found
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="payment-tab"
                      role="tabpanel"
                      aria-labelledby="pills-payment-tab"
                    >
                      <div className="text-left">
                        {/* <div className="row mx-0">
                          <div className="col-md-12  mt-3 d-flex justify-content-end">
                            <Link to={`/CreateRosterOrg/${id}`}>
                              <button className="btn-create-new ">
                                Add Roaster
                              </button>
                            </Link>
                          </div>
                        </div> */}
                        <Table responsive="sm">
                          <tbody>
                            {getTeamdetails?.roster_player?.length > 0 ? (
                              <>
                                {getTeamdetails?.roster_player?.map((v, i) => {
                                  return (
                                    <tr key={v._id}>
                                      {/* <td>{v?.player[i]?.player?.first_name}</td> */}
                                      <td>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div>
                                            <p>{v.roster_name}</p>
                                          </div>
                                          <div className="">
                                            <div className="dropdown event-drp">
                                              <button
                                                className="btn btn-link text-dark p-0"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <i className="mdi mdi-dots-vertical action-ico"></i>
                                                {/* More/ */}
                                              </button>
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                              >
                                                <li>
                                                  <Link
                                                    to={`/editteam/${v.team_id?._id}/${id}`}
                                                    className="text-decoration-none"
                                                  >
                                                    <span className="text-dark">
                                                      Add Players
                                                    </span>
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to={`/editteam/${v.team_id?._id}/${id}`}
                                                    className="text-decoration-none"
                                                  >
                                                    <span className="text-dark">
                                                      Edit
                                                    </span>
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to={`/editteam/${v.team_id?._id}/${id}`}
                                                    className="text-decoration-none"
                                                  >
                                                    <span className="text-dark">
                                                      Delete
                                                    </span>
                                                  </Link>
                                                </li>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          {getTeamdetails?.roster_player[i]
                                            ?.player?.length !== 0 ? (
                                            <>
                                              <div>
                                                <img
                                                  src={
                                                    v?.player[i]?.player
                                                      ?.profile_image !== ""
                                                      ? v?.player[i]?.player
                                                          ?.profile_image
                                                      : "./images/pch-images/pch.svg"
                                                  }
                                                  alt="Event image"
                                                />
                                                <span
                                                  style={{ marginLeft: "20px" }}
                                                >
                                                  {v?.player[i]?.player
                                                    ?.first_name +
                                                  " " +
                                                  v?.player[i]?.player
                                                    ?.last_name
                                                    ? v?.player[i]?.player
                                                        ?.first_name +
                                                      " " +
                                                      v?.player[i]?.player
                                                        ?.last_name
                                                    : "Roster tab is not available"}
                                                </span>
                                              </div>
                                              <div className="">
                                                <Link
                                                  className="text-light"
                                                  style={{
                                                    textDecoration: "none",
                                                  }}
                                                  to={`/adddivision/${id}`}
                                                >
                                                  <i
                                                    className="fas fa-envelope text-blue"
                                                    style={{
                                                      marginRight: "15px",
                                                      fontSize: "20px",
                                                    }}
                                                  ></i>
                                                </Link>
                                                <Link
                                                  to={`/event-details/${id}`}
                                                  onClick={val}
                                                >
                                                  <i
                                                    className="fas fa-trash-alt text-danger"
                                                    style={{
                                                      fontSize: "20px",
                                                    }}
                                                  ></i>
                                                </Link>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <span className="d-flex justify-content-center text-gray">
                                                Roster is not available
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                <span className="d-flex justify-content-center text-gray">
                                  No Rosters Found
                                </span>
                              </>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* <Modal
          show={showModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
              id="contained-modal-title-vcenter"
            >
              {props.title}
              <Icon
                className="ml-auto cursor"
                icon="eva:close-fill"
                onClick={() => {
                  props.onHide();
                }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="video_modal">
            <p>{props.description}</p>
          </Modal.Body>
          <Modal.Footer className="video_modal justify-content-between">
            <button onClick={props.onHide} className="sign_btn remove_btn">
              cancel
            </button>
            <button onClick={props.actionHandler} className="sign_btn">
              {props.confirmBtnText ? props.confirmBtnText : "Okay"}
            </button>
          </Modal.Footer>
        </Modal> */}
      </React.Fragment>
    </React.Fragment>
  );
};

// export default withRouter(EventTeamDetails);
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getTeamDetailsHandler: (user) => dispatch(getTeamDetails(user)),
  getClubTeamDetails: (user) => dispatch(getClubTeamDetails(user)),
  getAcceptedPlayerhandler: (user) => dispatch(getAcceptedPlayer(user)),
  // userDataHandler: user => dispatch(userData(user)),
  // deletePaymentCodeHandler: user => dispatch(deletePaymentCode(user)),
  // resetdeletePaymentCodeReducer: () => dispatch({ type: RESET_DELETE_EVENT }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventTeamDetails));
// 239
