import React, { useState, useEffect } from "react";
// import DatePicker from "react-datepicker";
import classNames from "classnames";
// import moment from "moment";
import { connect, useSelector } from "react-redux";
import { addAccount, changePassword } from "../service/action/userAction";
// import { history } from "../helpers/history";
// import { Redirect } from "react-router";
import { RESET_ADDACCOUNT, RESET_RESET_PASSWORD } from "../service/constants";
import { toast } from "react-toastify";
import Toaster from "../Toaster/Toaster";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ChangePassword = (props) => {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const notify = (msg) => toast(msg);
  const history = useHistory();
  const changepasswordMsg = useSelector(
    (state) => state.resetPasswordReducer.message
  );
  const changepasswordError = useSelector(
    (state) => state.resetPasswordReducer
  );

  const validPassword = new RegExp("^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})");

  const [state, setState] = useState({
    oldPassword: "",
    password: "",
    confirm_password: "",
  });

  // const [startDate, setStartDate] = useState();
  // const [showDatePicker, setShowDatePicker] = useState(false);
  const [errors, setErrors] = useState({
    oldPassword: false,
    password: false,
    confirm_password: false,
  });

  const eventhandler = (e) => {
    const { id, value, name } = e.target;

    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    switch (name) {
      case "oldPassword":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            oldPassword: "oldPassword can not be empty.",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            oldPassword: false,
          }));
        }
        break;

      case "password":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            password: "Password can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            password: false,
          }));
        }
        break;
      case "confirm_password":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            confirm_password: "Confirm Password can not be empty",
          }));
        }
        // else if (!validPassword.test(value)) {
        //         setErrors((prevState) => ({
        //             ...prevState,
        //             confirm_password: "(Hint:Password must be at least 8 characters long with uppercase, lowercase, and special character)",
        //         }));
        //     }
        else {
          setErrors((prevState) => ({
            ...prevState,
            confirm_password: false,
          }));
        }
        break;
      default:
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (state.oldPassword === "") {
      setErrors((prevState) => ({
        ...prevState,
        oldPassword: "old password can not be empty",
      }));
    }

    if (state.password === "") {
      setErrors((prevState) => ({
        ...prevState,
        password: "password can not be empty",
      }));
    }

    if (state.confirm_password == "") {
      setErrors((prevState) => ({
        ...prevState,
        confirm_password: "confirm password can not be empty",
      }));
    }
    if (state.password !== state.confirm_password) {
      setErrors((prevState) => ({
        ...prevState,
        confirm_password: "password & confirm password is not same",
      }));
    } else if (
      !validPassword.test(state.confirm_password) ||
      !validPassword.test(state.password)
    ) {
      setErrors((prevState) => ({
        ...prevState,
        confirm_password:
          "(Hint:Password must be at least 8 characters long with uppercase, lowercase, and special character)",
      }));
    }

    if (
      state.oldPassword !== "" &&
      state.password !== "" &&
      state.confirm_password !== "" &&
      validPassword.test(state.confirm_password) &&
      validPassword.test(state.password)
    ) {
      props.changePasswordHandler({
        oldPassword: state.oldPassword,
        password: state.password,
        confirm_password: state.confirm_password,
      });
    }
  };

  useEffect(() => {
    if (props.changepassword?.loading) {
    } else if (props.changepassword?.success) {
      notify(changepasswordMsg.message);

      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          oldPassword: "",
          password: "",
          confirm_password: "",
        }));
      }, 2000);
      history.push("/switch");
      props.resetChangePasswordhandler();
    } else if (props.changepassword?.error) {
      notify(changepasswordError.errors.confirm_password);
    }
  }, [props.changepassword]);

  return (
    <div className="main-panel">
      {/* <div className="content-wrapper"> */}
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex auth my-4">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto mt-24">
              <div className="auth-form-light text-left p-5">
                <h6 className="text-white text-center">Change password</h6>
                <form className="pt-3">
                  <div className="form-group input-group  pass-wrapper form-group-1">
                    <input
                      type={show1 ? "text" : "password"}
                      className={classNames(
                        "form-control form-control-lg w-100 ",
                        { "login-error": errors.oldPassword }
                      )}
                      id="oldPassword"
                      name="oldPassword"
                      placeholder="Old Password"
                      value={state.oldPassword}
                      required
                      onChange={eventhandler}
                      aria-label="oldPassword"
                      aria-describedby="button-addon2"
                      autoComplete="off"
                      style={{ zIndex: "1" }}
                    />

                    <button
                      className="bg-transparent input-group-append position-absolute eye-button mr-1"
                      onClick={(e) => {
                        e.preventDefault();
                        setShow1(!show1);
                      }}
                    >
                      {show1 ? (
                        <i
                          className="fas fa-eye-slash eye "
                          aria-describedby="button-addon2"
                        ></i>
                      ) : (
                        <i
                          className="fas fa-eye eye"
                          aria-describedby="button-addon2"
                        ></i>
                      )}
                    </button>
                    {/* <input
                                            type="password"
                                            placeholder="oldPassword"
                                            className={classNames("form-control ",
                                                {
                                                    "login-error": errors.oldPassword,
                                                }
                                            )}
                                            id="oldPassword"
                                            name="oldPassword"
                                            value={state.oldPassword}
                                            onChange={eventhandler}
                                        /> */}

                    {errors.oldPassword && (
                      <span className="text-danger small d-block">
                        * {errors.oldPassword}
                      </span>
                    )}
                  </div>
                  <div className="form-group input-group  pass-wrapper form-group-1">
                    <input
                      type={show2 ? "text" : "password"}
                      className={classNames(
                        "form-control form-control-lg w-100 ",
                        { "login-error": errors.password }
                      )}
                      id="password"
                      name="password"
                      placeholder="New Password"
                      value={state.password}
                      required
                      onChange={eventhandler}
                      aria-label="password"
                      aria-describedby="button-addon2"
                      autoComplete="off"
                      style={{ zIndex: "1" }}
                    />

                    <button
                      className="bg-transparent input-group-append position-absolute eye-button mr-1"
                      onClick={(e) => {
                        e.preventDefault();
                        setShow2(!show2);
                      }}
                    >
                      {show2 ? (
                        <i
                          className="fas fa-eye-slash eye "
                          aria-describedby="button-addon2"
                        ></i>
                      ) : (
                        <i
                          className="fas fa-eye eye"
                          aria-describedby="button-addon2"
                        ></i>
                      )}
                    </button>
                    {/* <input
                                            type="password"
                                            placeholder="password"
                                            className={classNames("form-control ",
                                                {
                                                    "login-error": errors.password,
                                                }
                                            )}
                                            id="password"
                                            name="password"
                                            value={state.password}
                                            onChange={eventhandler}
                                        /> */}

                    {errors.password && (
                      <span className="text-danger small d-block">
                        * {errors.password}
                      </span>
                    )}
                  </div>
                  <div className="form-group input-group  pass-wrapper form-group-1">
                    <input
                      type={show3 ? "text" : "password"}
                      className={classNames(
                        "form-control form-control-lg w-100 ",
                        { "login-error": errors.confirm_password }
                      )}
                      id="confirm_password"
                      name="confirm_password"
                      placeholder="Confirm Password"
                      value={state.confirm_password}
                      required
                      onChange={eventhandler}
                      aria-label="confirm_password"
                      aria-describedby="button-addon2"
                      autoComplete="off"
                      style={{ zIndex: "1" }}
                    />

                    <button
                      className="bg-transparent input-group-append position-absolute eye-button mr-1"
                      onClick={(e) => {
                        e.preventDefault();
                        setShow3(!show3);
                      }}
                    >
                      {show3 ? (
                        <i
                          className="fas fa-eye-slash eye "
                          aria-describedby="button-addon2"
                        ></i>
                      ) : (
                        <i
                          className="fas fa-eye eye"
                          aria-describedby="button-addon2"
                        ></i>
                      )}
                    </button>
                    {/* <input
                                            type="password"
                                            placeholder="confirm password"
                                            className={classNames("form-control ",
                                                {
                                                    "login-error": errors.confirm_password,
                                                }
                                            )}
                                            id="confirm_password"
                                            name="confirm_password"
                                            value={state.confirm_password}
                                            onChange={eventhandler}
                                        /> */}

                    {errors.confirm_password && (
                      <span className="text-white small d-block">
                        * {errors.confirm_password}
                      </span>
                    )}
                  </div>

                  <div className="mt-3 text-center">
                    <button
                      type="submit"
                      className="btn btn-login font-weight-medium auth-form-btn"
                      disabled={props.onSubmit?.loading}
                      onClick={onSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
      <div className="auth-form-light text-left p-5"></div>
      {/* </div> */}
    </div>
  );
};

// export default AddAccount;

const mapStateToProp = (state) => ({
  changepassword: state.resetPasswordReducer,
});

const mapDispatchToProps = (dispatch) => ({
  addAccountHandler: (user) => dispatch(addAccount(user)),
  resetAddAccount: () => dispatch({ type: RESET_ADDACCOUNT }),
  changePasswordHandler: (user) => dispatch(changePassword(user)),
  resetChangePasswordhandler: () => dispatch({ type: RESET_RESET_PASSWORD }),
});

export default connect(mapStateToProp, mapDispatchToProps)(ChangePassword);
