import { CREATE_MERCHAND_ACCOUNT_ERROR,CREATE_MERCHAND_ACCOUNT_LOADING,CREATE_MERCHAND_ACCOUNT_SUCCESS,RESET_CREATE_MERCHAND_ACCOUNT} from "../../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}
};

export default function createMerchandAccountReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_MERCHAND_ACCOUNT_LOADING:
      return { ...state, error: false, loading: true, success: false };
      
      case CREATE_MERCHAND_ACCOUNT_SUCCESS:
      return { ...state, error: false, loading: false, success: true,message:action.msg };
      
      case CREATE_MERCHAND_ACCOUNT_ERROR:
        return { ...state, error: true, loading: false, success: false, errors: action.errors,msg:action.errors ,networkErr:action.error }
        
        case RESET_CREATE_MERCHAND_ACCOUNT:
        return {...state,error:false,loading:false,success:false,errors:[],user:[{}]}  

   
    default:
      return state;
  }
}

