import React, { useState } from "react";
import logo from "../../assets/images/logo.svg";

import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { validEmailRegex } from "../../Services/constant";

export default function ForgotPass() {
  let history = useHistory();

  const [state, setState] = useState({
    email: "",
  });
  const [error, setError] = useState({
    email: false,
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));

    switch (name) {
      case "email":
        if (value == "") {
          error["email"] = "Required";
        } else if (!validEmailRegex.test(value)) {
          error["email"] = "Enter Valid Email";
        } else {
          error["email"] = false;
        }
        break;
      default:
        break;
    }
  };

  const codeSent = (e) => {
    e.preventDefault();
    if (state.email == "") {
      setError((prev) => ({
        ...prev,
        email: "Required",
      }));
    }
    if (state.email !== "" && !error.email) {
      history.push("/superadmin/otp");
    } else {
    }
  };
  return (
    <div className="signin">
      <div className="signin-logo">
        <img src={logo} className="img-fluid logo-img" alt="logo" />
      </div>

      {/* Login form */}
      <div className="login-form">
        <h1 className="heading text-white text-center">
          Forgot your password ?
        </h1>
        <form className="mt-4">
          <p className="text-white text-center mt-4">
            Please enter your email address to Recive a Varification Code.
          </p>
          <div className="form-group mt-5">
            <input
              type="email"
              name="email"
              placeholder="Enter your Email"
              className={classNames("form-control", {
                errorBorder: error.email,
              })}
              value={state.email}
              onChange={handleChange}
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-secondary"
              onClick={codeSent}
            >
              Send Code
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
