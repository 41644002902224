import {  GET_ALLUSER_ROLL_ERROR, GET_ALLUSER_ROLL_LOADING,GET_ALLUSER_ROLL_SUCCESS } from "../constants"

const initialState={
    error:false,
    success:false,
    loading:false,
    errors:[],
    user:[]
}

export default function getUserRollReducer(state=initialState,action){
    switch(action.type){
        case GET_ALLUSER_ROLL_LOADING:
            // alert("loading reducer")
            return {...state,error:false,success:false,loading:true}
            break;
        case GET_ALLUSER_ROLL_SUCCESS:
            // alert("success reducer")
            return {...state,error:false,success:true,loading:false,user:action.payload}
            break;
        case GET_ALLUSER_ROLL_ERROR:
            // alert("Error reducer")
            return {...state,error:true,success:false,loading:false,errors:[]}
            break;
        default:
        return state;
    }
}