import React, { useState, useEffect } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
// import Toaster from '../../../Toaster/Toaster';
import { withRouter, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { connect, useSelector } from 'react-redux';
// import { createSeason } from '../../service/action/userAction';
// import { RESET_CREATE_SEASON } from '../../service/constants';
import classNames from 'classnames';
import { createPool, getAllDivision } from '../../service/action/userAction';
import { RESET_CREATE_POOL, RESET_SIGNATURE } from '../../service/constants';
import SignaturePad from "react-signature-canvas";
import { signature } from '../../service/action/Club/clubAction';
import Toaster from '../../Toaster/Toaster';
import { Redirect } from 'react-router-dom';



const Signature = (props) => {
    let history = useHistory();
    const { id } = useParams()
    const pdf = useSelector(state => state.signaturePostReducer.message)


    const initialState = {
        child_name: "",
        name: ""
    }

    const [state, setState] = useState({
        child_name: "",
        name: ""
    })

    const [errors, setErrors] = useState({

        child_name: false,
        name: false

    })
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const [sigPad, setSigPad] = useState({});
    const clearState = () => {
        setState({ ...initialState });
        // sigPad.clear()
    };

    function resetData() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    }

    // const [checked, setChecked] = useState(false)
    // const onClickCheck = () => setChecked(!checked)

    const eventHandler = (e) => {
        const { id, value, name } = e.target


        setState(prevState => ({
            ...prevState,
            [id]: value
            // [e.target.name]: e.target.value.trim()
        }))
        switch (name) {
            case "child_name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        child_name: "child's name can not be empty"
                    }))

                    document.getElementById("child_name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        child_name: false
                    }))

                    document.getElementById("child_name").classList.remove("error")
                }

                break;
            case "name":
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        name: " Name can not be empty"
                    }))

                    document.getElementById("name").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        name: false
                    }))

                    document.getElementById("name").classList.remove("error")
                }

                break;

            default:
        }
    }

    const notify = (msg) => toast(msg);

    const clear = () => {
        // sigPad.clear();
        setSigPad(()=>({}))
    };
    const trim = () => {
        setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL("image/png"));
    };

    const submitRequest = (e) => {
        e.preventDefault();
        alert("click")

        setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL("image/png"));

        if (state.child_name == "") {
            setErrors(prevState => ({
                ...prevState,
                child_name: "Child's Name can not be empty"
            }))
            document.getElementById("child_name").classList.add("error")
        }
        if (state.name == "") {
            setErrors(prevState => ({
                ...prevState,
                name: "Name can not be empty"
            }))
            document.getElementById("name").classList.add("error")
        }

        if (state.child_name !== "" && state.name !== "") {
            props.signatureHandler({
                childs_name: state.child_name,
                name: state.name,
                organizer: id,
                signature: trimmedDataURL
            })

            // if (props.submitRequest?.success) {
            //     notify("Data submitted successfully.")
            // }
            // resetData().then(clearState);
        }
    }
    // if(props.submit?.success){
    //     return <Redirect to={pdf.url} />
    // }

    useEffect(() => {
        if (props.submit?.success) {
            notify(pdf.message)
            resetData().then(clearState);
            clear()
            // return <Redirect to={pdf.url} />
            window.open(`${pdf.url}`)
            props.resetsignatureReducer()
            // history.push(`${pdf.url}`);
        } else if (props.submit?.error) {
            notify("Error")
        }
    }, [props.submit])


    return (
        <>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <h5 className="dash-head">Signature</h5>
                    </div>

                    <form >
                        <div className="row">
                            <div className="col-md-4 col-xl-4">
                                <div className="mt-24">
                                    <div className="text-left">
                                        <label className="text-gray fs-13">Name <span className="text-red fw-m">*</span></label>
                                        <input type="text" className="form-control team-name" id="name" name="name" value={state.name} placeholder="Name" required onChange={eventHandler} />

                                        {errors.name &&
                                            <span className='text-red small d-block'>* {errors.name}</span>}


                                    </div>
                                    <div className="text-left">
                                        <label className="text-gray fs-13">Child's Name <span className="text-red fw-m">*</span></label>
                                        <input type="text" className="form-control team-name" id="child_name" name="child_name" value={state.child_name} placeholder="Child's name" required onChange={eventHandler} />

                                        {errors.child_name &&
                                            <span className='text-red small d-block'>* {errors.child_name}</span>}

                                    </div>
                                    <div className="my-2 text-left " >
                                        {/* <div className="sigContainer"> */}

                                        <SignaturePad
                                            canvasProps={{ className: "sigPad form-control" }}
                                            ref={(ref) => {
                                                setSigPad(ref);
                                            }}
                                            onEnd={trim}

                                        />
                                        <div className='mt-2'>

                                            <button className="buttons btn-save mb-2 mr-2" onClick={clear}>
                                                Clear
                                            </button>
                                            {/* <div className='buttons btn-save text-left'>

                                            <a className="buttons btn-save btn btn-lg auth-form-btn font-weight-medium" onClick={trim}>
                                            Save
                                        </a>
                                            </div> */}
                                        </div>
                                        {/* </div> */}
                                        {/* <div>{sigPad.isEmpty && sigPad.isEmpty() ? "empty" : "filled"}</div> */}
                                        {/* <div> */}
                                        {/* </div>   */}
                                        {/* {trimmedDataURL ? (
                                            <img className="sigImage" src={trimmedDataURL} />
                                        ) : null} */}
                                    </div>

                                    <div className="mt-3 text-left">
                                        <a className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitRequest} >Submit</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                    <Toaster />
                </div>
            </div>

        </>
    )
}

// export default withRouter(Org_CreateDivision)



const mapStateToProp = state => ({
    submit: state.signaturePostReducer,

})

const mapDispatchToProps = dispatch => ({
    signatureHandler: user => dispatch(signature(user)),
    resetsignatureReducer: () => dispatch({ type: RESET_SIGNATURE }),
    getAllDivisionHandler: user => dispatch(getAllDivision(user))



})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(Signature));