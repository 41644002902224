import React, { useState } from "react";
// import DatePicker from "react-datepicker";
import classNames from "classnames";
// import moment from "moment";
import { connect } from "react-redux";
import { addAccount } from "../service/action/userAction";
// import { history } from "../helpers/history";
import { Redirect } from "react-router";
import { RESET_ADDACCOUNT } from "../service/constants";

const CreateAccount = (props) => {

    // const addAccount = useSelector(state => state.addAccountReducer)

    const [state, setState] = useState({
        name: "",
        option: "",
    });

    // const [startDate, setStartDate] = useState();
    // const [showDatePicker, setShowDatePicker] = useState(false);
    const [errors, setErrors] = useState({
        name: false,
        option: false,
    });

    const eventhandler = (e) => {
        const { id, value, name } = e.target;

        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));

        switch (name) {
            case "name":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        name: "Name can not be empty.",
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        name: false,
                    }));
                }
                break;

            case "account":
                if (value.length === 0) {
                    setErrors((prevState) => ({
                        ...prevState,
                        option: "Value can not be empty",
                    }));

                    setState((prevState) => ({
                        ...prevState,
                        option: value,
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        option: false,
                    }));
                    // if (value === 2 || value === 3 || value === 5) {
                    //     setShowDatePicker(true)
                    // } else {
                    //     setShowDatePicker(false)
                    // }
                    setState((prevState) => ({
                        ...prevState,
                        option: value,
                    }));
                }
                break;
            default:
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (state.name === "") {
            setErrors((prevState) => ({
                ...prevState,
                name: "Name can not be empty",
            }));
        }

        if (state.option === "") {
            setErrors((prevState) => ({
                ...prevState,
                option: "Value can not be empty",
            }));
        }

        if (state.option !== "" && state.name !== "") {
            props.addAccountHandler({
                name: state.name,
                type: state.option
                // dob: moment(startDate).format('YYYY-MM-DD')

            });
        }

    };

    //For redirect page to /switch account after add account succesfully
    if (props.addaccount?.success) {
        props.resetAddAccount()
        return <Redirect to="/switch" />
    }

    return (
        <>

            {/* <div className="main-panel"> */}
                {/* <div className="content-wrapper"> */}
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex auth my-4">
                        <div className="row flex-grow">
                            <div className="col-lg-4 mx-auto mt-24">
                                <div className="auth-form-light text-left p-5">
                                    <h6 className="text-white text-center">Add Account</h6>
                                    <form className="pt-3">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                className={classNames("form-control ",
                                                    {
                                                        "login-error": errors.name,
                                                    }
                                                )}
                                                id="name"
                                                name="name"
                                                value={state.name}
                                                onChange={eventhandler}
                                            />

                                            {errors.name && (
                                                <span className="text-danger small d-block">
                                                    * {errors.name}
                                                </span>
                                            )}
                                        </div>
                                        <div className="form-group event-drp">
                                            <select
                                                className={classNames("form-control payterm",
                                                    {
                                                        "login-error": errors.option,
                                                    }
                                                )}
                                                id="account"
                                                name="account"
                                                value={state.option}
                                                onChange={eventhandler}
                                            >
                                                <option style={{ color: "#000" }}>Account Type</option>
                                                <option value="1" style={{ color: "#000" }}>
                                                    organizer
                                                </option>
                                                {/* <option value="2" style={{ color: "#000" }}>
                                                staff
                                            </option>
                                            <option value="3" style={{ color: "#000" }}>
                                                coach
                                            </option> */}
                                                <option value="4" style={{ color: "#000" }}>
                                                    club
                                                </option>
                                                {/* <option value="5" style={{ color: "#000" }}>
                                                parent or player
                                            </option> */}
                                            </select>

                                            {errors.option && (
                                                <span className="text-danger small d-block">
                                                    * {errors.option}
                                                </span>
                                            )}
                                        </div>

                                        {/* {
                                        showDatePicker &&
                                        (<div className="form-group">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control"
                                                selected={startDate}
                                                placeholderText="dob"
                                                onChange={(date) => setStartDate(date)}
                                                dropdownMode="select"
                                                showMonthDropdown
                                                showYearDropdown
                                                adjustDateOnChange
                                            />
                                        </div>)
                                    } */}


                                        <div className="mt-3 text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-login font-weight-medium auth-form-btn"
                                                disabled={props.onSubmit?.loading}
                                                onClick={onSubmit}
                                            >
                                                Add Account
                                            </button>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="auth-form-light text-left p-5">
                </div>
                {/* </div> */}
            {/* </div> */}
        </>
    )
}

// export default AddAccount;

const mapStateToProp = state => ({
    addaccount: state.addAccountReducer

})

const mapDispatchToProps = dispatch => ({
    addAccountHandler: user => dispatch(addAccount(user)),
    resetAddAccount: () => dispatch({ type: RESET_ADDACCOUNT })
})

export default connect(mapStateToProp, mapDispatchToProps)(CreateAccount)
