import { GET_PAID_RECIPIENT_ERROR, GET_PAID_RECIPIENT_LOADING, GET_PAID_RECIPIENT_SUCCESS, RESET_GET_PAID_RECIPIENT } from "../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false
};
export default function getPaidRecipientReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAID_RECIPIENT_LOADING:
      return { ...state, success: false,success:false};
     
    case GET_PAID_RECIPIENT_SUCCESS:  
      return { ...state, loading: false,success:true,data:action.payload};
 
    case GET_PAID_RECIPIENT_ERROR:
      return { ...state,error: true, errors: action.errors };
    
    default:
      return state;
  }
}


