import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";

import { useEffect } from "react";
import { useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  RESET_ADD_CONFIG,
  RESET_DELETE_CONFIG,
  RESET_UPDATE_CONFIG,
  removeStorage,
} from "../Services/constant";
import { useHistory } from "react-router-dom";
import {
  addConfig,
  deleteConfig,
  getConfig,
  updateConfig,
} from "../Services/Actions/userAction";
import classNames from "classnames";

const DATA_ITEM_KEY = "_id";

function Config(props) {
  const history = useHistory();
  const [xlShow, setXlShow] = useState(false);
  const handleClose = () => setXlShow(false);
  const handleShow = () => setXlShow(true);

  const [delShow, setDelShow] = useState(false);
  const delDialClose = () => setDelShow(false);
  const delDialShow = () => setDelShow(true);

  const [successShow, setSuccessShow] = useState(false);
  const successDialClose = () => setSuccessShow(false);
  const successDialShow = () => {
    setDelShow(false);
    // setSuccessShow(true);
    props.deleteConfig(deletedID);
  };

  const [state, setState] = useState([]);
  const [InitialState, setInitialState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [deletedID, setdeletedID] = useState();
  const [selectedID, setSelectedId] = useState("");
  const InitialForm = {
    key: "",
    value: "",
  };

  const InitialError = {
    key: false,
    value: false,
  };
  const [detailsForm, setDetailsForm] = useState(InitialForm);
  const [errorForm, setErrorForm] = useState(InitialError);

  const [pageDetails, setPageDetails] = useState({
    skip: 0,
    take: 10,
  });

  useEffect(() => {
    props.getConfig();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (props.getConfigReducer.success) {
      const data = props.getConfigReducer.data;
      const _data = data.data.map((e) => {
        return {
          ...e,
        };
      });
      setState(_data);
      setInitialState(_data);
      setLoading(false);
    } else if (props.getConfigReducer.error) {
      const errMsg = props.getConfigReducer.errorMsg;
      !!errMsg && toast.error(errMsg);
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      }
    }
  }, [props.getConfigReducer]);

  useEffect(() => {
    if (props.updateConfigReducer.success) {
      handleClose();
      toast.success("Config updated successfully");
      props.resetUpdateConfig();
      props.getConfig();
      setLoading(true);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
      setSelectedId("");
    } else if (props.updateConfigReducer.error) {
      const errMsg = props.updateConfigReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.updateConfigReducer]);

  useEffect(() => {
    if (props.deleteConfigReducer.success) {
      handleClose();
      toast.success("Config deleted successfully");
      props.resetdeleteConfig();
      props.getConfig();
      setLoading(true);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
    } else if (props.deleteConfigReducer.error) {
      const errMsg = props.deleteConfigReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.deleteConfigReducer]);

  useEffect(() => {
    if (props.getConfigReducer.success) {
      if (searchVal !== "") {
        const data = [...InitialState];
        const filter = data.filter(
          (e) =>
            e.key.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.value.toLowerCase().includes(searchVal.toLowerCase())
        );
        setState(filter);
      } else {
        const data = [...InitialState];
        setState(data);
      }
    }
  }, [searchVal]);

  const customHeader = (cell) => {
    return <b>{cell.title}</b>;
  };

  const customAction = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <button
          onClick={() => {
            const details = cell.dataItem;
            setSelectedId(details._id);
            setDetailsForm({
              ...details,
              key: details.key == "N/A" ? "" : details.key,
              value: details.value == "N/A" ? "" : details.value,
            });
            setErrorForm({
              ...InitialError,
              key: false,
              value: false,
            });
            handleShow();
          }}
          className="btn-link btn text-secondary p-0 mx-2"
        >
          <i className="ri-pencil-fill"></i>
        </button>
        <button
          onClick={() => {
            const details = cell.dataItem;
            delDialShow();
            setdeletedID(details?._id);
          }}
          className="btn-link btn text-danger p-0 mx-2"
        >
          <i className="ri-delete-bin-fill"></i>
        </button>
      </td>
    );
  };

  const grid = (
    <Grid
      data={state.slice(pageDetails.skip, pageDetails.skip + pageDetails.take)}
      skip={pageDetails.skip}
      take={pageDetails.take}
      total={state.length}
      pageable={{
        buttonCount: 3,
        pageSizes: [5, 10, 15],
      }}
      onPageChange={(e) => {
        setPageDetails(() => ({
          ...e.page,
          take: e.page.take,
        }));
      }}
      dataItemKey={DATA_ITEM_KEY}
    >
      <GridNoRecords>No records are available</GridNoRecords>
      <GridColumn field="key" title="Key" headerCell={customHeader} />
      <GridColumn field="value" title="Value" headerCell={customHeader} />

      <GridColumn
        field="action"
        title="Actions"
        width="100"
        headerCell={customHeader}
        cell={customAction}
      />
    </Grid>
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    setDetailsForm((pre) => ({
      ...pre,
      [name]: value,
    }));

    switch (name) {
      case "key":
        if (value == "") {
          errorForm["key"] = "Required";
        } else {
          errorForm["key"] = false;
        }
        break;

      case "value":
        if (value == "") {
          errorForm["value"] = "Required";
        } else {
          errorForm["value"] = false;
        }
        break;

      default:
        break;
    }
  };
  const handleCreate = (e) => {
    e.preventDefault();
    if (detailsForm.key == "") {
      setErrorForm((prev) => ({
        ...prev,
        key: "Required",
      }));
    }
    if (detailsForm.value == "") {
      setErrorForm((prev) => ({
        ...prev,
        value: "Required",
      }));
    }

    if (detailsForm.key !== "" && detailsForm.value !== "") {
      selectedID == ""
        ? props.addConfig({
            key: detailsForm.key,
            value: detailsForm.value,
          })
        : props.updateConfig({
            details: {
              key: detailsForm.key,
              value: detailsForm.value,
            },
            id: selectedID,
          });
    }
  };

  useEffect(() => {
    if (props.addConfigReducer.success) {
      handleClose();
      toast.success("Configuration created successfully");
      props.resetAddConfig();
      props.getConfig();
      setLoading(true);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
    } else if (props.addConfigReducer.error) {
      const errMsg = props.addConfigReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.addConfigReducer]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2 className="page-heading">Configuration</h2>
      </div>

      <Row className="justify-content-between mx-0">
        <Col md="4" className="search px-0">
          <i className="ri-search-line"></i>
          <Form.Control
            type="text"
            placeholder="Search"
            value={searchVal}
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
          />
        </Col>
        <Col md="6" className="text-end px-0">
          <Button
            variant="secondary"
            onClick={() => {
              handleShow();
              setSelectedId("");
            }}
          >
            Add Configuration
          </Button>
        </Col>
      </Row>
      <div className="table-card">{grid}</div>
      <Modal
        size="md"
        show={xlShow}
        oonHide={() => {
          setDetailsForm(InitialForm);
          setErrorForm(InitialError);
          handleClose();
        }}
      >
        <Modal.Header>
          <Modal.Title>Configuration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Col} className="mb-4" controlId="">
              <Form.Label>Key</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="key"
                value={detailsForm.key}
                className={classNames({
                  errorBorder: errorForm.key,
                })}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} className="mb-4" controlId="">
              <Form.Label>Value</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="value"
                value={detailsForm.value}
                className={classNames({
                  errorBorder: errorForm.value,
                })}
                onChange={handleChange}
              />
            </Form.Group>

            <div className="text-end ml-auto">
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setDetailsForm(InitialForm);
                  setErrorForm(InitialError);
                  handleClose();
                }}
                style={{ fontSize: "18px" }}
                className="mr-3 pointer"
              >
                Cancel
              </Button>
              <Button variant="secondary" type="submit" onClick={handleCreate}>
                {selectedID == "" ? "Submit" : "Update"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete confirmation */}
      <Modal
        size="sm"
        show={delShow}
        onHide={delDialClose}
        centered
        className="del-dialogue"
      >
        {/* <Modal.Header closeButton>
                              <Modal.Title>delete</Modal.Title>
                        </Modal.Header> */}
        <Modal.Body>
          <h6 className="text-center">
            Are you sure you want to delete this record?
          </h6>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Button className="btn-gray" onClick={successDialClose}>
            No
          </Button>
          <Button variant="secondary" onClick={successDialShow}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete success */}
      <Modal
        size="sm"
        show={successShow}
        onHide={successDialClose}
        centered
        className="del-dialogue success"
      >
        <Modal.Body className="text-center">
          <i className="ri-close-circle-line fa-4x text-secondary"></i>
          <h5 className="text-center">Record deleted Successfully !!</h5>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProp = (state) => ({
  getConfigReducer: state.superAdminRootReducer.UserReducer.getConfig,
  addConfigReducer: state.superAdminRootReducer.UserReducer.addConfig,
  deleteConfigReducer: state.superAdminRootReducer.UserReducer.deleteConfig,
  updateConfigReducer: state.superAdminRootReducer.UserReducer.updateConfig,
});

const mapDispatchToProps = (dispatch) => ({
  getConfig: () => dispatch(getConfig()),
  addConfig: (details) => dispatch(addConfig(details)),
  deleteConfig: (details) => dispatch(deleteConfig(details)),
  updateConfig: (details) => dispatch(updateConfig(details)),
  resetAddConfig: () => dispatch({ type: RESET_ADD_CONFIG }),
  resetdeleteConfig: () => dispatch({ type: RESET_DELETE_CONFIG }),
  resetUpdateConfig: () => dispatch({ type: RESET_UPDATE_CONFIG }),
});

export default connect(mapStateToProp, mapDispatchToProps)(Config);
