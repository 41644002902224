import axios from "axios";
import { history } from "../../helpers/history";
import { RESET_SIGNUP, SIGNUP_ERROR, SIGNUP_LOADING, SIGNUP_NETWORK_ERROR, SIGNUP_SUCCESS } from "../constants";
let initialState = {
  loading: false,
  user: {},
  error: false,
  errors: [],
  success:false
};

export default function signupReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_LOADING:
      return { ...state, loading: true, error: false, errors: [] ,success: false};
      break;
    case SIGNUP_SUCCESS:
      return { ...state, loading: false, error: false, errors:[] ,success:true,response:action.response};
      break;
    case SIGNUP_ERROR:
      return { ...state, loading: false, error: true, errors: action.errors, errorNet:action.netError};
      break;
    case SIGNUP_NETWORK_ERROR:
      return { ...state, loading: false, error: true, errorNet:action.netError};
      break;
    // case SIGNUP_ERROR:
    //   return {...state,loading:false , error:true , errors:action.errors}
    case RESET_SIGNUP:
      return { ...state, loading: false, error: false, errors:[] ,success:false };
      break;
    default:
      return state;
  }
}
