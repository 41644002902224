import React, { useEffect, useState } from "react";
import classes from './clubReport.module.css'
import $, { type } from "jquery";
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  getAllReport,
  getClubBulkRecipientAction,
  getClubOrganizerRefund,
  getOutstandingReport,
  getPaymentRequest,
  getReceiveReport,
  getRefundReport,
} from "../../../service/action/userAction";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import Spinner from "../../../Spinner/Spinner";
import {
  REMINDER_CLUB_RESET,
  RESET_OUTSTANDING_REPORT,
  RESET_RECEIVE_REPORT,
  RESET_REFUND_REPORT,
} from "../../../service/constants";
import moment from "moment";
import {
  clubreminder,
  getPaymentReq,
} from "../../../service/action/Club/clubAction";
import { Button } from "react-bootstrap";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";

const ClubReport = (props) => {
  let [loading, setLoading] = useState(true);
  let [loading1, setLoading1] = useState(true);
  let [loading2, setLoading2] = useState(true);
  let [loading3, setLoading3] = useState(true);

  // useEffect(() => {
  //     props.resetReceive()
  //     props.resetOutStanding()
  //     props.resetRefund()
  // }, [])

  const { id } = useParams;

  const location = useLocation();

  useEffect(() => {
    const currentTab = document.getElementById(location.state);
    if (currentTab) {
      currentTab.click();
    }
  }, [location.state]);

  useEffect(() => {
    props.getReceiveReportHandler({ id: 0 });
    props.getOutstandingHandler({ id: 0 });
    props.getRefundedHandler({ id: 0 });
    props.getAllReportHandler({ id: 0 });
    props.getPaymentReqHandler({ id: 0 });
    props.getClubOrganizerRefund({ id: 0 });
  }, []);

  let history = useHistory();

  // useEffect(() => {
  //     props.getPaymentRequestHandler()
  // }, [])

  const allpaymentReq = useSelector(
    (state) => state.getPaymentRequestReducer.user.data
  );

  const allpaymentReqSend = useSelector(
    (state) => state.getPaymentReqSendReducer.states.reportArray
  );

  const allReportReq = useSelector(
    (state) => state.getAllReportReducer.user.reportArray
  );

  const receivePayment = useSelector(
    (state) => state.getReceiveReportReducer.user
  );
  const outStandingPayment = useSelector(
    (state) => state.getOutStandingReportReducer.user
  );

  const refundPayment = useSelector(
    (state) => state.getRefundReportReducer.user.data
  );

  const organizerRefundPayment = useSelector(
    (state) => state.getRefundRecivedReducer.user.data
  );

  const [outStanding, setOutStanding] = useState(null);
  const [received, setReceived] = useState(null);
  const [refund, setRefund] = useState(null);
  const [reports, setReports] = useState(null);

  useEffect(() => {
    if (outStandingPayment) {
      setOutStanding(outStandingPayment);
    }
  }, [outStandingPayment]);

  useEffect(() => {
    if (refundPayment) {
      setRefund(refundPayment);
    }
  }, [refundPayment]);

  useEffect(() => {
    if (allReportReq) {
      setReports(allReportReq);
    }
  }, [allReportReq]);

  useEffect(() => {
    if (allpaymentReq !== undefined) {
      // setReceived(allpaymentReq.filter(e => e.status === 1))
      setOutStanding(allpaymentReq.filter((e) => e.status === 0));
    }
  }, [allpaymentReq]);

  const allReport = ({ id }) => {
    props.getReceiveReportHandler({ id: id });
    props.getOutstandingHandler({ id: id });
    props.getRefundedHandler({ id: id });
    props.getAllReportHandler({ id: id });
  };

  useEffect(() => {
    if (props.allReport?.success) {
      setLoading(false);
    }
  }, [props.allReport]);

  useEffect(() => {
    if (props.getPaymentReqSendReducer?.success) {
      setLoading(false);
    }
  }, [props.getPaymentReqSendReducer]);

  useEffect(() => {
    if (props.outStanding?.success) {
      setLoading1(false);
    }
  }, [props.outStanding]);

  useEffect(() => {
    if (props.received?.success) {
      setLoading2(false);
    }
  }, [props.received]);

  useEffect(() => {
    if (props.refund?.success) {
      setLoading3(false);
    }
  }, [props.refund]);

  const [state, setState] = useState({
    search: "",
  });

  const Searchval = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    UpdateArray(e.target.value);
  };

  const UpdateArray = (search) => {
    var query = search;

    $(".filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".pay-row").addClass("d-none");
      } else {
        $(v).parents(".pay-row").removeClass("d-none");
      }
    });
  };
  const reminderResponse = useSelector((state) => state.reminderResponse);

  useEffect(() => {
    if (props.reminderResponse?.success) {
      props.getAllReportHandler({ id: 0 });
      props.restReminder();
    }
  }, reminderResponse);

  const reminder = (v) => {
    props.receipientReminder({
      payment_request_id: v._id,
      recipient_type: v.type,
    });
    props.restReminder();
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          <div className="text-left">
            <div className="">
              <h5 className="dash-head  mr-4 mb-0"> Reports </h5>

              <div className="mt-3 mt-md-0 text-right ">
                <Link to="/payment/report/create-payment-request">
                  <button className={classes.sendBtn}>+New</button>
                </Link>
              </div>

              <div className="d-flex align-items-center">
                {/* <div className="search-section mt-4 mt-md-0">
                  <i className="mdi mdi-magnify search-ico"></i>
                  <input
                    type="text"
                    className="form-control"
                    id="search"
                    name="search"
                    value={state.search}
                    placeholder="Search"
                    onChange={Searchval}
                    autoComplete="off"
                  />
                </div> */}

                {/* <div className="mt-4 mt-md-0">
                                    <i className="mdi mdi-filter-outline filter-ico"></i>
                                </div> */}

                {/* <div className="dropdown event-drp mt-4 mt-md-0">
                  <i
                    className="mdi mdi-filter-outline filter-ico"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  ></i>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenu2"
                  >
                    <Link
                      className="dropdown-item"
                      to="/report"
                      onClick={() => allReport({ id: 0 })}
                    >
                      All
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/report"
                      onClick={() => allReport({ id: 1 })}
                    >
                      Weekly
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/report"
                      onClick={() => allReport({ id: 2 })}
                    >
                      Monthly
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/report"
                      onClick={() => allReport({ id: 3 })}
                    >
                      Quarterly
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="team-details mt-4 mt-md-0 justify-content-between ">
            <ul
              className="nav nav-pills mb-3 mt-3"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active text-nowrap"
                  id="teams"
                  data-toggle="pill"
                  href="#request-tab"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Requests
                </a>
              </li>
              <li className="nav-item " role="presentation">
                <a
                  className="nav-link text-nowrap"
                  id="=outStanding"
                  data-toggle="pill"
                  href="#outStanding-tab"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Outstanding
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link text-nowrap"
                  id="=recevied"
                  data-toggle="pill"
                  href="#recevied-tab"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Received
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link text-nowrap"
                  id="=refund"
                  data-toggle="pill"
                  href="#Refund-tab"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Refunded
                </a>
              </li>
            </ul>
          </div>

          <div className="tab-content" id="pills-tabContent">
            {/* 
                        <<<<<<<<<<<<<<<<-----------------------All Request Start------------------>>>>>>>>>>>>>>>> */}

            <div
              className="tab-pane fade show active"
              id="request-tab"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="team-details mt-4 justify-content-between">
                <ul
                  className="nav nav-pills mb-3 mt-3"
                  id="pills-request-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="send"
                      data-toggle="pill"
                      href="#send-tab"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Send
                    </a>
                  </li>
                  <li className="nav-item " role="presentation">
                    <a
                      className="nav-link "
                      id="recevie"
                      data-toggle="pill"
                      href="#recevie-tab"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Recevie
                    </a>
                  </li>
                </ul>

                <div className="tab-content" id="pills-tabContent">
                  {/* Send */}
                  <div
                    className="tab-pane fade show active"
                    id="send-tab"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {/* desktop */}
                    <div className="table-responsive d-none d-md-block">
                      {!!allpaymentReqSend &&
                        allpaymentReqSend?.length === 0 && (
                          <>
                            <div className="d-flex justify-content-center noti-type mt-4">
                              No payment request found
                            </div>
                          </>
                        )}

                      {!!allpaymentReqSend &&
                        allpaymentReqSend?.length !== 0 && (
                          <table className="table event-table team-table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Due Date/Days</th>
                                <th>Payment Status</th>
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>
                              {allpaymentReqSend
                                ?.filter((e) => e.type === "payment_request")
                                ?.map((v, i) => {
                                  return (
                                    <tr key={v._id} className="team-row">
                                      <td>{v?.first_name}</td>
                                      <td>${v?.amount}</td>
                                      <td>{v?.due_date}</td>
                                      <td>
                                        {v?.status === 0
                                          ? "Outstanding"
                                          : "Recived"}
                                      </td>
                                      <td>
                                        {v.status === 0 && (
                                          <div className="col-6 text-right">
                                            {" "}
                                            <Button
                                              onClick={() => {
                                                reminder({
                                                  type: 5,
                                                  _id: v.id,
                                                });
                                              }}
                                            >
                                              Remind
                                            </Button>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        )}
                    </div>
                    {/* mobile */}
                    <div className="d-md-none">
                      {!!allpaymentReqSend ? (
                        <>
                          {allpaymentReqSend.length !== 0 ? (
                            <>
                              {allpaymentReqSend
                                ?.filter((e) => e.type === "payment_request")
                                ?.map((v, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <div className="d-flex justify-content space-between mb-3">
                                        <div className="col-6 noti-type">
                                          <p
                                            style={{ fontWeight: "bold" }}
                                            className="text-dark mb-1"
                                          >
                                            {v?.first_name}
                                          </p>
                                          {/* <p>Event:{"  " + "NA"}</p> */}
                                          <p className="mb-1">{`Amount: $${v?.amount}`}</p>
                                          <p className="mb-1">{`Due Date/Days: ${v?.due_date}`}</p>
                                          <p className="mb-1">
                                            {`Payment Status: ${
                                              v?.status === 0
                                                ? "Outstanding"
                                                : "Recived"
                                            }`}
                                          </p>
                                        </div>
                                        {v.status === 0 && (
                                          <div className="col-6 text-right">
                                            {" "}
                                            <Button
                                              onClick={() => {
                                                reminder({
                                                  type: 5,
                                                  _id: v.id,
                                                });
                                              }}
                                            >
                                              Remind
                                            </Button>
                                          </div>
                                        )}
                                      </div>
                                      <hr />
                                    </React.Fragment>
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-center noti-type">
                                No payment request found
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center">
                            <Spinner />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Recive */}
                  <div
                    className="tab-pane fade"
                    id="recevie-tab"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {/* desktop */}
                    <div className="table-responsive d-none d-md-block">
                      {!!allReportReq && allReportReq.length !== 0 ? (
                        <table className="table event-table team-table">
                          <thead>
                            <tr>
                              <th>Organizer</th>
                              <th>Event</th>
                              <th>Amount</th>
                              <th>Due Date/Days</th>
                              <th>Payment Status</th>
                              <th></th>
                            </tr>
                          </thead>

                          <tbody>
                            {allReportReq?.map((v, i) => {
                              return (
                                <tr key={v._id} className="team-row">
                                  <td>{v?.organizer}</td>
                                  <td>
                                    {!!v.event_name ? v.event_name : "Null"}
                                  </td>
                                  <td>${v?.amount}</td>
                                  <td>
                                    {moment(v?.createdAt).format("MM-DD-yyyy")}
                                  </td>
                                  <td>
                                    {v?.status === 0
                                      ? v?.type === "outstanding"
                                        ? "Outstanding"
                                        : "Paid"
                                      : "Paid"}
                                  </td>
                                  <td>
                                    {v.type === "outstanding" &&
                                      v?.status === 0 && (
                                        <div className="col-6 text-right">
                                          {" "}
                                          <Button
                                            onClick={() => {
                                              reminder({
                                                type: 5,
                                                _id: v.id,
                                              });
                                            }}
                                          >
                                            Pay Now
                                          </Button>
                                        </div>
                                      )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="d-flex justify-content-center noti-type mt-4">
                          No payment request found
                        </div>
                      )}
                    </div>

                    {/* mobile */}
                    <div className="d-md-none">
                      {!!allReportReq ? (
                        <>
                          {allReportReq.length !== 0 ? (
                            <>
                              {allReportReq?.map((v, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    <div className="d-flex justify-content space-between mb-3">
                                      <div className="col-6 noti-type">
                                        <p
                                          style={{ fontWeight: "bold" }}
                                          className="text-dark mb-1"
                                        >
                                          {v?.organizer}
                                        </p>
                                        {/* <p>Event:{"  " + "NA"}</p> */}
                                        <p className="mb-1">{`Event: ${
                                          !!v.event_name ? v.event_name : "Null"
                                        }`}</p>
                                        <p className="mb-1">{`Amount: $${v?.amount}`}</p>
                                        <p className="mb-1">{`Due Date/Days: ${moment(
                                          v?.createdAt
                                        ).format("MM-DD-yyyy")}`}</p>
                                        <p className="mb-1">
                                          {`Payment Status: ${
                                            v?.status === 0
                                              ? v?.type === "outstanding"
                                                ? "Outstanding"
                                                : "Paid"
                                              : "Paid"
                                          }`}
                                        </p>
                                      </div>
                                      {v.type === "outstanding" &&
                                        v?.status === 0 && (
                                          <div className="col-6 text-right">
                                            {" "}
                                            <Button
                                              onClick={() => {
                                                reminder({
                                                  type: 5,
                                                  _id: v.id,
                                                });
                                              }}
                                            >
                                              Pay Now
                                            </Button>
                                          </div>
                                        )}
                                    </div>
                                    <hr />
                                  </React.Fragment>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-center noti-type">
                                No payment request found
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center">
                            <Spinner />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <<<<<<<<<<<<<<<<-----------------------All Request End------------------>>>>>>>>>>>>>>>> */}

            {/* <<<<<<<<<<<<<<<<-----------------------OutStanding Start------------------>>>>>>>>>>>>>>>> */}

            <div
              className="tab-pane fade"
              id="outStanding-tab"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="team-details mt-4 justify-content-between">
                <ul
                  className="nav nav-pills mb-3 mt-3"
                  id="pills-outStanding-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="outStanding-send"
                      data-toggle="pill"
                      href="#outStanding-send-tab"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Send
                    </a>
                  </li>
                  <li className="nav-item " role="presentation">
                    <a
                      className="nav-link "
                      id="outStanding-recevie"
                      data-toggle="pill"
                      href="#outStanding-recevie-tab"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Recevie
                    </a>
                  </li>
                </ul>

                <div className="tab-content" id="pills-tabContent">
                  {/* Send */}
                  <div
                    className="tab-pane fade show active"
                    id="outStanding-send-tab"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {/* desktop */}
                    <div className="table-responsive d-none d-md-block">
                      {!!allpaymentReqSend &&
                        allpaymentReqSend?.filter(
                          (e) => e.type === "payment_request" && e.status === 0
                        ).length !== 0 && (
                          <table className="table event-table team-table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Payment Status</th>
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>
                              {allpaymentReqSend
                                ?.filter(
                                  (e) =>
                                    e.type === "payment_request" &&
                                    e.status === 0
                                )
                                ?.map((v, i) => {
                                  return (
                                    <tr key={v._id} className="team-row">
                                      <td>{v?.first_name}</td>
                                      <td>${v?.amount}</td>
                                      <td>{v?.due_date}</td>
                                      <td>
                                        {v?.status === 0
                                          ? "Outstanding"
                                          : "Recived"}
                                      </td>
                                      <td>
                                        {v.status === 0 &&
                                          v.type === "payment_request" && (
                                            <div className="col-6 text-right">
                                              {" "}
                                              <Button
                                                onClick={() => {
                                                  reminder({
                                                    type: 5,
                                                    _id: v.id,
                                                  });
                                                }}
                                              >
                                                Remind
                                              </Button>
                                            </div>
                                          )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        )}
                      {!!allpaymentReqSend &&
                        allpaymentReqSend?.filter(
                          (e) => e.type === "payment_request" && e.status === 0
                        ).length === 0 && (
                          <div className="d-flex justify-content-center noti-type mt-4">
                            No payment request found
                          </div>
                        )}
                    </div>
                    {/* mobile */}
                    <div className="d-md-none">
                      {!!allpaymentReqSend ? (
                        <>
                          {allpaymentReqSend?.filter(
                            (e) =>
                              e.type === "payment_request" && e.status === 0
                          ).length !== 0 ? (
                            <>
                              {allpaymentReqSend
                                ?.filter(
                                  (e) =>
                                    e.type === "payment_request" &&
                                    e.status === 0
                                )
                                ?.map((v, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <div className="d-flex justify-content space-between mb-3">
                                        <div className="col-6 noti-type">
                                          <p
                                            style={{ fontWeight: "bold" }}
                                            className="text-dark mb-1"
                                          >
                                            {v?.first_name}
                                          </p>
                                          {/* <p>Event:{"  " + "NA"}</p> */}
                                          <p className="mb-1">{`Amount: $${v?.amount}`}</p>
                                          <p className="mb-1">{`Due Date/Days: ${v?.due_date}`}</p>
                                          <p className="mb-1">
                                            {`Payment Status: ${
                                              v?.status === 0
                                                ? "Outstanding"
                                                : "Recived"
                                            }`}
                                          </p>
                                        </div>
                                        {v.status === 0 &&
                                          v.type === "payment_request" && (
                                            <div className="col-6 text-right">
                                              {" "}
                                              <Button
                                                onClick={() => {
                                                  reminder({
                                                    type: 5,
                                                    _id: v.id,
                                                  });
                                                }}
                                              >
                                                Remind
                                              </Button>
                                            </div>
                                          )}
                                      </div>
                                      <hr />
                                    </React.Fragment>
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-center noti-type">
                                No payment request found
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center">
                            <Spinner />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Recive */}
                  <div
                    className="tab-pane fade"
                    id="outStanding-recevie-tab"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {/* desktop */}
                    <div className="table-responsive d-none d-md-block">
                      {!!allReportReq &&
                      allReportReq?.filter(
                        (e) => e.type === "outstanding" && e?.status === 0
                      ).length !== 0 ? (
                        <table className="table event-table team-table">
                          <thead>
                            <tr>
                              <th>Organizer</th>
                              <th>Event</th>
                              <th>Amount</th>
                              <th>Due Date/Days</th>
                              <th>Payment Status</th>
                              <th></th>
                            </tr>
                          </thead>

                          <tbody>
                            {allReportReq
                              ?.filter(
                                (e) =>
                                  e.type === "outstanding" && e?.status === 0
                              )
                              ?.map((v, i) => {
                                return (
                                  <tr key={v._id} className="team-row">
                                    <td>{v?.organizer}</td>
                                    <td>
                                      {!!v.event_name ? v.event_name : "Null"}
                                    </td>
                                    <td>${v?.amount}</td>
                                    <td>
                                      {moment(v?.createdAt).format(
                                        "MM-DD-yyyy"
                                      )}
                                    </td>
                                    <td>
                                      {v?.status === 0
                                        ? v?.type === "outstanding"
                                          ? "Outstanding"
                                          : "Paid"
                                        : "Paid"}
                                    </td>
                                    <td>
                                      {v.type === "outstanding" &&
                                        v?.status === 0 && (
                                          <div className="col-6 text-right">
                                            {" "}
                                            <Button
                                              onClick={() => {
                                                reminder({
                                                  type: 5,
                                                  _id: v.id,
                                                });
                                              }}
                                            >
                                              Pay Now
                                            </Button>
                                          </div>
                                        )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="d-flex justify-content-center noti-type">
                          No payment request found
                        </div>
                      )}
                    </div>
                    {/* mobile */}
                    <div className="d-md-none">
                      {!!allReportReq ? (
                        <>
                          {allReportReq.length !== 0 ? (
                            <>
                              {allReportReq
                                ?.filter(
                                  (e) =>
                                    e.type === "outstanding" && e?.status === 0
                                )
                                ?.map((v, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <div className="d-flex justify-content space-between mb-3">
                                        <div className="col-6 noti-type">
                                          <p
                                            style={{ fontWeight: "bold" }}
                                            className="text-dark mb-1"
                                          >
                                            {v?.organizer}
                                          </p>
                                          {/* <p>Event:{"  " + "NA"}</p> */}
                                          <p className="mb-1">{`Event: ${
                                            !!v.event_name
                                              ? v.event_name
                                              : "Null"
                                          }`}</p>
                                          <p className="mb-1">{`Amount: ${v?.amount}`}</p>
                                          <p className="mb-1">{`Due Date/Days: ${moment(
                                            v?.createdAt
                                          ).format("MM-DD-yyyy")}`}</p>
                                          <p className="mb-1">
                                            {`Payment Status: ${
                                              v?.status === 0
                                                ? v?.type === "outstanding"
                                                  ? "Outstanding"
                                                  : "Paid"
                                                : "Paid"
                                            }`}
                                          </p>
                                        </div>
                                        {v.type === "outstanding" &&
                                          v?.status === 0 && (
                                            <div className="col-6 text-right">
                                              {" "}
                                              <Button
                                                onClick={() => {
                                                  reminder({
                                                    type: 5,
                                                    _id: v.id,
                                                  });
                                                }}
                                              >
                                                Pay Now
                                              </Button>
                                            </div>
                                          )}
                                      </div>
                                      <hr />
                                    </React.Fragment>
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-center noti-type">
                                No payment request found
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center">
                            <Spinner />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <<<<<<<<<<<<<<<<-----------------------OutStanding End------------------>>>>>>>>>>>>>>>> */}

            {/* <<<<<<<<<<<<<<<<-----------------------Received Start------------------>>>>>>>>>>>>>>>> */}

            <div
              className="tab-pane fade"
              id="recevied-tab"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="team-details mt-4 justify-content-between">
                <ul
                  className="nav nav-pills mb-3 mt-3"
                  id="pills-recevied-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="recevied-send"
                      data-toggle="pill"
                      href="#recevied-send-tab"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Send
                    </a>
                  </li>
                  <li className="nav-item " role="presentation">
                    <a
                      className="nav-link "
                      id="recevied-recevie"
                      data-toggle="pill"
                      href="#recevied-recevie-tab"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Recevie
                    </a>
                  </li>
                </ul>

                <div className="tab-content" id="pills-tabContent">
                  {/* Send */}
                  <div
                    className="tab-pane fade show active"
                    id="recevied-send-tab"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {/* desktop */}
                    <div className="table-responsive d-none d-md-block">
                      {!!allpaymentReqSend &&
                      allpaymentReqSend?.filter(
                        (e) => e.type === "received_payment"
                      ).length !== 0 ? (
                        <table className="table event-table team-table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Amount</th>
                              <th>Due Date/Days</th>
                              <th>Email</th>
                              {/* <th></th> */}
                            </tr>
                          </thead>

                          <tbody>
                            {allpaymentReqSend
                              ?.filter((e) => e.type === "received_payment")
                              ?.map((v, i) => {
                                return (
                                  <tr key={v._id} className="team-row">
                                    <td>{v?.first_name}</td>
                                    <td>${v?.amount}</td>
                                    <td>
                                      {moment(v?.createdAt).format(
                                        "MM-DD-yyyy"
                                      )}
                                    </td>
                                    <td>{v?.email}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="d-flex justify-content-center noti-type">
                          No payment request found
                        </div>
                      )}
                    </div>
                    {/* mobile */}
                    <div className="d-md-none">
                      {!!allpaymentReqSend ? (
                        <>
                          {allpaymentReqSend.length !== 0 ? (
                            <>
                              {allpaymentReqSend
                                ?.filter((e) => e.type === "received_payment")
                                ?.map((v, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <div className="d-flex justify-content space-between mb-3">
                                        <div className="col-6 noti-type">
                                          <p
                                            style={{ fontWeight: "bold" }}
                                            className="text-dark mb-1"
                                          >
                                            {v?.first_name}
                                          </p>
                                          {/* <p>Event:{"  " + "NA"}</p> */}
                                          <p className="mb-1">{`Amount: $${v?.amount}`}</p>
                                          <p className="mb-1">{`Due Date/Days: ${moment(
                                            v?.createdAt
                                          ).format("MM-DD-yyyy")}`}</p>
                                          <p className="mb-1">
                                            {`Email: ${v?.email}`}
                                          </p>
                                        </div>
                                      </div>
                                      <hr />
                                    </React.Fragment>
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-center noti-type">
                                No payment request found
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center">
                            <Spinner />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Recive */}

                  <div
                    className="tab-pane fade"
                    id="recevied-recevie-tab"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {/* desktop */}
                    <div className="table-responsive d-none d-md-block">
                      {!!allReportReq &&
                      allReportReq?.filter((e) => e.type === "received_payment")
                        .length !== 0 ? (
                        <table className="table event-table team-table">
                          <thead>
                            <tr>
                              <th>Organizer</th>
                              <th>Event</th>
                              <th>Amount</th>
                              <th>Due Date/Days</th>
                              <th>Payment Status</th>
                              {/* <th></th> */}
                            </tr>
                          </thead>

                          <tbody>
                            {allReportReq
                              ?.filter((e) => e.type === "received_payment")
                              ?.map((v, i) => {
                                return (
                                  <tr key={v._id} className="team-row">
                                    <td>{v?.organizer}</td>
                                    <td>
                                      {v.event_name !== ""
                                        ? v.event_name
                                        : "Null"}
                                    </td>
                                    <td>${v?.amount}</td>
                                    <td>
                                      {moment(v?.createdAt).format(
                                        "MM-DD-yyyy"
                                      )}
                                    </td>
                                    <td>
                                      {v?.type === "received_payment"
                                        ? "Paid"
                                        : "Outstanding"}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="d-flex justify-content-center noti-type">
                          No payment request found
                        </div>
                      )}
                    </div>
                    {/* mobile */}
                    <div className="d-md-none">
                      {!!allReportReq ? (
                        <>
                          {allReportReq.length !== 0 ? (
                            <>
                              {allReportReq
                                ?.filter((e) => e.type === "received_payment")
                                ?.map((v, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <div className="d-flex justify-content space-between mb-3">
                                        <div className="col-6 noti-type">
                                          <p
                                            style={{ fontWeight: "bold" }}
                                            className="text-dark mb-1"
                                          >
                                            {v?.organizer}
                                          </p>
                                          {/* <p>Event:{"  " + "NA"}</p> */}
                                          <p className="mb-1">{`Event: ${
                                            v.event_name !== ""
                                              ? v.event_name
                                              : "Null"
                                          }`}</p>
                                          <p className="mb-1">{`Amount: ${v?.amount}`}</p>
                                          <p className="mb-1">{`Due Date/Days: ${moment(
                                            v?.createdAt
                                          ).format("MM-DD-yyyy")}`}</p>
                                          <p className="mb-1">
                                            {`Payment Status: ${
                                              v?.type === "received_payment"
                                                ? "Paid"
                                                : "Outstanding"
                                            }`}
                                          </p>
                                        </div>
                                      </div>
                                      <hr />
                                    </React.Fragment>
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-center noti-type">
                                No payment request found
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center">
                            <Spinner />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <<<<<<<<<<<<<<<<-----------------------Received End------------------>>>>>>>>>>>>>>>> */}

            {/* <<<<<<<<<<<<<<<<-----------------------Refund start------------------>>>>>>>>>>>>>>>> */}

            <div
              className="tab-pane fade"
              id="Refund-tab"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="team-details mt-4 justify-content-between">
                <ul
                  className="nav nav-pills mb-3 mt-3"
                  id="pills-Refund-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="refund-send"
                      data-toggle="pill"
                      href="#refund-send-tab"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Send
                    </a>
                  </li>
                  <li className="nav-item " role="presentation">
                    <a
                      className="nav-link "
                      id="refund-recevie"
                      data-toggle="pill"
                      href="#refund-recevie-tab"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Recevie
                    </a>
                  </li>
                </ul>

                <div className="tab-content" id="pills-tabContent">
                  {/* Send */}
                  <div
                    className="tab-pane fade show active"
                    id="refund-send-tab"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {/* desktop */}
                    <div className="table-responsive d-none d-md-block">
                      {!!refund && refund.length !== 0 ? (
                        <table className="table event-table team-table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Refund Date</th>
                              <th>Refund Amount</th>
                              <th>Payment Status</th>
                              {/* <th></th> */}
                            </tr>
                          </thead>

                          <tbody>
                            {refund?.map((v, i) => {
                              return (
                                <tr key={v._id} className="team-row">
                                  <td>{v?.receipient?.first_name}</td>
                                  <td>{v?.refund_date}</td>
                                  <td>${v?.refund_amount}</td>
                                  <td>Refunded</td>
                                  {/* <td>
                                  {v.status === 0 && <div className="col-6 text-right">
                                    {" "}
                                    <Button
                                      onClick={() => {
                                        reminder({
                                          type: 5,
                                          _id: v.id,
                                        });
                                      }}
                                    >
                                      Remind
                                    </Button>
                                  </div>}
                                </td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="d-flex justify-content-center noti-type">
                          No payment request found
                        </div>
                      )}
                    </div>
                    {/* mobile */}
                    <div className="d-md-none">
                      {!!refund ? (
                        <>
                          {refund.length !== 0 ? (
                            <>
                              {refund?.map((v, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    <div className="d-flex justify-content space-between mb-3">
                                      <div className="col-6 noti-type">
                                        <p
                                          style={{ fontWeight: "bold" }}
                                          className="text-dark mb-1"
                                        >
                                          {v?.receipient?.first_name}
                                        </p>
                                        {/* <p>Event:{"  " + "NA"}</p> */}
                                        <p className="mb-1">{`Due Date/Days: ${v?.refund_date}`}</p>
                                        <p className="mb-1">{`Refund Amount: $${v?.refund_amount}`}</p>
                                        <p className="mb-1">
                                          {`Payment Status: Refunded`}
                                        </p>
                                      </div>
                                    </div>
                                    <hr />
                                  </React.Fragment>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-center noti-type">
                                No payment request found
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center">
                            <Spinner />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Recive */}

                  <div
                    className="tab-pane fade"
                    id="refund-recevie-tab"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {/* desktop */}
                    <div className="table-responsive d-none d-md-block">
                      {!!organizerRefundPayment &&
                      organizerRefundPayment.length !== 0 ? (
                        <table className="table event-table team-table">
                          <thead>
                            <tr>
                              <th>Organizer</th>
                              <th>Due Date / Days</th>
                              <th>Event</th>
                              <th>Amount</th>
                              <th>Payment Status</th>
                              {/* <th></th> */}
                            </tr>
                          </thead>

                          <tbody>
                            {organizerRefundPayment?.map((v, i) => {
                              return (
                                <tr key={v._id} className="team-row">
                                  <td>{v?.organizer?.first_name}</td>
                                  <td>
                                    {moment(v?.createdAt).format("MM-DD-yyyy")}
                                  </td>
                                  <td>
                                    {v.event.event_name !== ""
                                      ? v.event.event_name
                                      : "Null"}
                                  </td>
                                  <td>${v?.refund_amount}</td>
                                  <td>
                                    {v?.type === "received_payment"
                                      ? "Paid"
                                      : "Outstanding"}
                                  </td>
                                  {/* <td>
                                  {v.type === "outstanding" && v?.status === 0 && <div className="col-6 text-right">
                                    {" "}
                                    <Button
                                      onClick={() => {
                                        reminder({
                                          type: 5,
                                          _id: v.id,
                                        });
                                      }}
                                    >
                                      Pay Now
                                    </Button>
                                  </div>}
                                </td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="d-flex justify-content-center noti-type">
                          No payment request found
                        </div>
                      )}
                    </div>

                    {/* mobile */}
                    <div className="d-md-none">
                      {!!organizerRefundPayment ? (
                        <>
                          {organizerRefundPayment.length !== 0 ? (
                            <>
                              {organizerRefundPayment?.map((v, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    <div className="d-flex justify-content space-between mb-3">
                                      <div className="col-6 noti-type">
                                        <p
                                          style={{ fontWeight: "bold" }}
                                          className="text-dark mb-1"
                                        >
                                          {v?.organizer?.first_name}
                                        </p>
                                        {/* <p>Event:{"  " + "NA"}</p> */}
                                        <p className="mb-1">{`Date: ${moment(
                                          v?.createdAt
                                        ).format("MM-DD-yyyy")}`}</p>
                                        <p className="mb-1">{`Event Name: ${
                                          v.event.event_name !== ""
                                            ? v.event.event_name
                                            : "Null"
                                        }`}</p>
                                        <p className="mb-1">{`Refund Amount: $${v?.refund_amount}`}</p>
                                        <p className="mb-1">
                                          {`Payment Status: ${
                                            v?.type === "received_payment"
                                              ? "Paid"
                                              : "Outstanding"
                                          }`}
                                        </p>
                                      </div>
                                      {/* {v.type === "outstanding" && v?.status === 0 && <div className="col-6 text-right">
                                      {" "}
                                      <Button
                                        onClick={() => {
                                          reminder({
                                            type: 5,
                                            _id: v.id,
                                          });
                                        }}
                                      >
                                        Pay Now
                                      </Button>
                                    </div>} */}
                                    </div>
                                    <hr />
                                  </React.Fragment>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-center noti-type">
                                No payment request found
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center">
                            <Spinner />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <<<<<<<<<<<<<<<<-----------------------Refund End------------------>>>>>>>>>>>>>>>> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(OrgAllPaymentReq)

const mapStateToProps = (state) => ({
  allpayment: state.getPaymentRequestReducer,
  outStanding: state.getOutStandingReportReducer,
  received: state.getReceiveReportReducer,
  refund: state.getRefundReportReducer,
  allReport: state.getAllReportReducer,
  getPaymentReq: state.getPaymentReqSendReducer,
  reminderResponse: state.reminderReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // getPaymentRequestHandler: user => dispatch(getPaymentRequest(user)),
  getReceiveReportHandler: (user) => dispatch(getReceiveReport(user)),
  getOutstandingHandler: (user) => dispatch(getOutstandingReport(user)),
  getRefundedHandler: (user) => dispatch(getRefundReport(user)),
  getClubOrganizerRefund: (user) => dispatch(getClubOrganizerRefund(user)),
  resetReceive: () => dispatch({ type: RESET_RECEIVE_REPORT }),
  resetOutStanding: () => dispatch({ type: RESET_OUTSTANDING_REPORT }),
  resetRefund: () => dispatch({ type: RESET_REFUND_REPORT }),
  getAllReportHandler: (user) => dispatch(getAllReport(user)),
  getPaymentReqHandler: (user) => dispatch(getPaymentReq(user)),
  receipientReminder: (data) => dispatch(clubreminder(data)),
  restReminder: () => dispatch({ type: REMINDER_CLUB_RESET })
});

export default connect(mapStateToProps, mapDispatchToProps)(ClubReport);
