import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";


// import { persistStore, persistReducer } from 'redux-persist';
// import { PersistGate } from 'redux-persist/integration/react';
// import storage from 'redux-persist/lib/storage';
// import sessionStorage from 'redux-persist/es/storage/session';

import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./service/reducers/index";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import "react-datepicker/dist/react-datepicker.css";
// import store from "./Store/store"
// import getAllPaymentCodeReducer from './service/reducers/getAllPaymentCodeReducer';
// import editPaymentCodeReducer from './service/reducers/editPaymentCodeReducer';
// import { getAllPaymentCode } from './service/action/userAction';
// const persistConfig = {
//   key: 'root',
//   storage,
//   whiteList: ['sendEditUserDataReducer']
// }

const theme = createTheme();

// const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
// export const persistor = persistStore(store);

ReactDOM.render(
  <ThemeProvider theme={theme}>
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <Router>
      <App />
    </Router>
    {/* </PersistGate> */}
  </Provider>
  </ThemeProvider>
  ,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
