import React, { useState, useEffect } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import Toaster from '../../../Toaster/Toaster';
import { withRouter, Redirect } from 'react-router-dom';
import { connect, useSelector, useParams } from 'react-redux';
import { getAllPaymentCode, getClubBulkRecipientAction, getPaidRecipient, getPendingAmount, paymentRequest } from "../../../service/action/userAction"
import { RESET_PAYMENT_REQUEST } from '../../../service/constants';
import classNames from 'classnames';
import Spinner from '../../../Spinner/Spinner';
import BreadcrumbHistory from '../../../BreadcumbHistory/BreadcrumbHistory';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { getClubRecipient } from '../../../service/action/Club/clubAction';

const CreatePaymentRequest = (props) => {
    useEffect(() => {
        props.getPaymentCodeHandler()
        // props.getrecipientHandler()
    }, [])

    let [loading, setLoading] = useState(true);

    const [codeList, setCodeList] = useState([])

    const location = useLocation()

    const [type, setType] = useState([])

    const clubpending = useSelector(state => state.getPendingAmountReducer.user.pandingAmount)
    const initialState = {
        paymentCode: ""
    };

    const [state, setState] = useState({
        paymentCode: ""
    })

    const [errors, setErrors] = useState({
        paymentCode: false

    })

    useEffect(() => {
        if (props.getAllPayment.success) {
            setCodeList(props.getAllPayment.user)
            setLoading(false)
        }
    }, [props.getAllPayment])
    useEffect(() => {
        if (clubpending) {

            setState(prevState => ({
                ...prevState,
                // recipient_type: clubpending.payment_code.paymentcode_type,
                invoice_code: clubpending.payment_code._id

            }))
        }
    }, [clubpending])

    //setTimeout form reseting data after submit of form
    function resetData() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    }

    //clear data on submit.
    const clearState = () => {
        setState({ ...initialState });
    };


    const eventHandler = (e) => {
        const { value, name } = e.target

        setState(prevState => ({
            ...prevState,
            [name]: value
            // [e.target.name]: e.target.value.trim()
        }))
        switch (name) {


            default:
        }
    }


    const notify = (msg) => toast(msg);

    const submitRequest = (e) => {
        e.preventDefault();
       const data =  {request_type: type, payment_code: state.paymentCode, reminder: '', all: 0}
        props.getClubBulkRecipientReducer(data)
    }


    useEffect(() => {
        if (props.paymentRequest?.success) {
            notify("Successfully Send")
            props.resetPaymentRequestHandler()
            resetData().then(clearState)
        }
    }, [props.paymentRequest])

    const displayRecipientBlock = (e) => {
        const { value } = e.target
        if (type.includes(value)) {
            let _type = [...type]
            if (value == '0') {
                setType([])
            } else {
                if (_type.includes('0')) {
                    const arr = ['1', '2', '3', '4']
                    const index = arr.findIndex(e => e == value)
                    arr.splice(index, 1)
                    setType(arr)

                } else {
                    const index = _type.findIndex(e => e == value)
                    _type.splice(index, 1)
                    setType(_type)
                }
            }
        } else {
            let _type = [...type]
            if (value == '0') {
                setType(['0', '1', '2', '3', '4'])
            } else {
                _type.push(value)
                if (_type.includes('1') && _type.includes('2') && _type.includes('3') && _type.includes('4')) {
                    setType(['0', '1', '2', '3', '4'])
                }else {
                    setType(_type)
                }
            }
        }
    }

    useEffect(() => {
        props.getClubRecipient({
            type: [0],
            request_type: 1
        })
    }, [type])
    // useEffect(() => {
    //     if (type !== '') {
    //         props.getClubRecipient({
    //             type: [type],
    //             request_type: 1
    //         })
    //     }
    // }, [type])

    return (
        <>
            <div className="main-panel">
                <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />

                <div className="content-wrapper">
                    <div className="text-left">
                        <h5 className="dash-head">Payment Request</h5>
                    </div>
                    <div className='d-flex justify-content-center'>
                        {loading ? <Spinner /> : null}
                    </div>
                    {!loading && <div >
                        <div className="">
                            <div className="col-md-4 col-xl-4">
                                <div className="mt-24">
                                    <div className="form-group event-drp mb-3">
                                        <label className="text-gray fs-13">Payment Code <span className="text-red fw-m">*</span></label>
                                        <select className={classNames("form-control team-name payterm", { "error": errors.paymentCode })} name="paymentCode" value={state.paymentCode} onChange={eventHandler}>
                                            <option value='' disabled>Custom Payment Code</option>
                                            {
                                                codeList?.map((v, i) => {
                                                    return <option value={v?._id} key={v?._id}>{v?.name}</option>
                                                })
                                            }
                                        </select>
                                        {errors.paymentCode &&
                                            <span className='text-red small d-block'>* {errors.paymentCode}</span>}
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <div className='h5'>Recipient Type</div>
                            <div className="form-group row mx-0">
                                <div className="mt-2 col-md-4">
                                    <input
                                        type="checkbox"
                                        className="mr-2 bulk-checkbox"
                                        value="0"
                                        id="all"
                                        name="all"
                                        checked={type.includes('0')}
                                        onChange={displayRecipientBlock}
                                    />
                                    <label htmlFor="all" style={{ verticalAlign: 'bottom' }}>All</label>
                                </div>
                                <div className="mt-2 col-md-4">
                                    <input
                                        type="checkbox"
                                        value="1"
                                        disabled={type === '0'}
                                        className="mr-2 bulk-checkbox"
                                        id="division"
                                        name="division"
                                        checked={type.includes('1')}
                                        onChange={displayRecipientBlock}
                                    />
                                    <label htmlFor="division" style={{ verticalAlign: 'bottom' }}>Division</label>
                                </div>
                                <div className="mt-2 col-md-4">
                                    <input
                                        type="checkbox"
                                        disabled={type === '0'}
                                        className="mr-2  bulk-checkbox"
                                        value="2"
                                        id="team"
                                        name="team"
                                        checked={type.includes('2')}
                                        onChange={displayRecipientBlock}
                                    />
                                    <label htmlFor="team" style={{ verticalAlign: 'bottom' }}>Team</label>
                                </div>
                                <div className="mt-2 col-md-4">
                                    <input
                                        type="checkbox"
                                        disabled={type === '0'}
                                        className="mr-2 bulk-checkbox"
                                        id="player"
                                        name="player"
                                        value="3"
                                        checked={type.includes('3')}
                                        onChange={displayRecipientBlock}
                                    />
                                    <label htmlFor="player" style={{ verticalAlign: 'bottom' }}>Player</label>
                                </div>
                                <div className="mt-2 col-md-4">
                                    <input
                                        type="checkbox"
                                        disabled={type === '0'}
                                        className="mr-2 bulk-checkbox"
                                        id="parent"
                                        name="parent"
                                        value="4"
                                        checked={type.includes('4')}
                                        onChange={displayRecipientBlock}
                                    />
                                    <label htmlFor="parent" style={{ verticalAlign: 'bottom' }}>Parent</label>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 text-left">
                            <a className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitRequest} >Send</a>
                        </div>

                        <Toaster />

                    </div>}
                </div>
            </div>

        </>
    )
}

// export default withRouter(CreateInvoice)
const mapStateToProp = state => ({
    submitRequest: state.createPaymentCodeReducer,
    paymentRequest: state.paymentRequestReducer,
    getAllPayment: state.getAllPaymentCodeReducer,
    getRecipient: state.getPaidRecipientReducer,
})
const mapDispatchToProps = dispatch => ({
    getPaymentCodeHandler: user => dispatch(getAllPaymentCode(user)),
    // getrecipientHandler: user => dispatch(getPaidRecipient(user)),
    getPendingAmountHandler: user => dispatch(getPendingAmount(user)),
    paymentRequesthandler: user => dispatch(paymentRequest(user)),
    getClubRecipient: user => dispatch(getClubRecipient(user)),
    resetPaymentRequestHandler: () => dispatch({ type: RESET_PAYMENT_REQUEST }),
    getClubBulkRecipientReducer: (data)=> dispatch(getClubBulkRecipientAction(data))
})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(CreatePaymentRequest));