import { RESET_SEND_BROADCAST, SEND_BROADCAST_ERROR, SEND_BROADCAST_LOADING, SEND_BROADCAST_SUCCESS } from "../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function sendBroadcastReducer(state = initialState, action) {
  switch (action.type) {

    case SEND_BROADCAST_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case SEND_BROADCAST_SUCCESS:
      return { ...state, error: false, loading: false, success: true,message:action.msg };

    case SEND_BROADCAST_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: action.errors,msg:action.errors }

    case RESET_SEND_BROADCAST:
        return {...state,error:false,loading:false,success:false,errors:[],user:[]}  

    default:
      return state;
  }
}

