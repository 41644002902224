import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  deleteDiscountCode,
  getAllDiscountCode,
} from "../../../service/action/userAction";
import axios from "axios";
import { createDiscountCode_Url } from "../../../apiSheet";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import {
  RESET_CREATE_DISCOUNT_CODE,
  RESET_DELETE_EVENT,
  RESET_EDIT_DISCOUNT_CODE,
} from "../../../service/constants";
import Spinner from "../../../Spinner/Spinner";
import Moment from "react-moment";

const Discountcode = (props) => {
  const [loading, setLoading] = useState(true);

  const getdata = props.getAllDiscountcodehandler;
  useEffect(() => {
    getdata();
  }, []);

  const header = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  const alldiscountcode = useSelector(
    (state) => state.getDiscountCodeReducer.data
  );
  const alldiscountcodeStatus = useSelector(
    (state) => state.getDiscountCodeReducer
  );

  const deleteEventMsg = useSelector(
    (state) => state.deleteEventReducer.message
  );

  const createcodeMsg = useSelector(
    (state) => state.createDiscountCodeReducer.message
  );

  const editcodeMsg = useSelector(
    (state) => state.editDiscountCodeReducer.message
  );

  const notify = (msg) => toast(msg);

  //delete discountcode success toast
  useEffect(() => {
    if (props.submit?.success) {
      notify(deleteEventMsg.message);
      props.resetdeleteDiscountCodeReducer();
      getdata();

      // history.push(`/event-details/${id}`);
    }
  }, [props.submit]);

  //create oayment code success toast
  useEffect(() => {
    if (props.createCode?.success) {
      notify(createcodeMsg.message);

      props.resetcreatecodeReducer();
      // getdata()
    }
  }, [props.createCode]);
  //edit oayment code success toast
  useEffect(() => {
    if (props.editCode?.success) {
      notify(editcodeMsg.message);
      props.reseteditcodeReducer();
      // getdata()

      // history.push(`/event-details/${id}`);
    }
  }, [props.editCode]);
  useEffect(() => {
    if (props.discountcode?.success) {
      setLoading(false);
    }
  }, [props.discountcode]);
  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-center">
            {loading ? (
              <div className="w-full h-[60vh] flex flex-col justify-center items-center">
                <Spinner />
              </div>
            ) : null}
          </div>
          {!loading && (
            <>
              <div className="text-right">
                <div className="mt-3 mt-md-0 text-right create-team-head-btn">
                  <Link to="/discount-code/create-discount">
                    <button className="btn-create-new">Create New</button>
                  </Link>
                </div>
              </div>
              <div className="green-section mt-30">
                {alldiscountcode && alldiscountcode !== undefined ? (
                  <>
                    <h6 className="dash-head">Discount List</h6>
                    {alldiscountcode.length == 0 ? (
                      <>
                        <div className="container d-flex justify-content-center align-items-center">
                          <span className="noti-type">
                            No discount codes found
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="table-responsive d-none d-md-block">
                          <table className="table green-table mt-24">
                            <thead>
                              <tr>
                                <th>Discount Code Name</th>
                                <th>Type</th>
                                <th>Amount(% or $)</th>
                                <th>Discount Code</th>
                                <th>Expire Date</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {alldiscountcode.length == 0 ? (
                                <>
                                  <span className="noti-type">
                                    You Don't have any Discount Code yet
                                  </span>
                                </>
                              ) : (
                                <>
                                  {alldiscountcode.map((v, i) => {
                                    const formatYmd = (date) =>
                                      date.toISOString().slice(0, 10);
                                    return (
                                      <tr key={i}>
                                        <td>{v.code_name}</td>
                                        <td>
                                          {v.discount_type == 0
                                            ? "Standard"
                                            : v.discount_type == 1
                                            ? "Club"
                                            : "NA"}
                                        </td>
                                        <td>
                                          {v.type == 1
                                            ? v.amount + " % "
                                            : " $ " + v.amount}
                                        </td>
                                        <td>
                                          {!!v.code && v.code !== ""
                                            ? v.code
                                            : "N/A"}{" "}
                                        </td>
                                        {/* <td>{v.code}</td> */}
                                        <td>{v.expire_date}</td>
                                        <td>
                                          <div className="dropdown event-drp">
                                            <i
                                              className="mdi mdi-dots-vertical"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            ></i>
                                            More
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuLink"
                                            >
                                              <Link
                                                className="dropdown-item"
                                                to={`/discount-code/${v._id}`}
                                              >
                                                <i className="mdi mdi-border-color"></i>
                                                Edit
                                              </Link>
                                              <Link
                                                className="dropdown-item"
                                                to="/discount-code"
                                                onClick={() =>
                                                  props.deleteDiscountCodeHandler(
                                                    v._id
                                                  )
                                                }
                                              >
                                                <i className="fas fa-trash-alt"></i>{" "}
                                                Delete
                                              </Link>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}

                    {/* mobile view  */}
                    <div className="mt-30 d-md-none">
                      <div className="mt-4">
                        {alldiscountcode.map((v, i) => {
                          return (
                            <div
                              className="d-flex justify-content-between reg-details"
                              key={i}
                            >
                              <div className="d-flex">
                                <div className="reg-content">
                                  <strong>{v.code_name}</strong>
                                  <span className="d-block text-gray mt-2">
                                    Type:{" "}
                                    {v.discount_type == 0
                                      ? "Standard"
                                      : v.discount_type == 1
                                      ? "Club"
                                      : "NA"}
                                  </span>
                                  <span className="d-block text-gray mt-2">
                                    Amount : {v.amount}
                                  </span>
                                  <span className="d-block text-gray mt-2">
                                    Discount Code :{" "}
                                    {!!v.code && v.code !== "" ? v.code : "N/A"}
                                  </span>
                                  <span className="d-block text-gray mt-2">
                                    Expire Date: {v.expire_date}
                                  </span>
                                </div>
                              </div>
                              <div className="dropdown event-drp">
                                <i
                                  className="mdi mdi-dots-vertical"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                ></i>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuLink"
                                >
                                  <Link
                                    className="dropdown-item"
                                    to={`/discount-code/${v._id}`}
                                  >
                                    <i className="mdi mdi-border-color"></i>
                                    Edit
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="/discount-code"
                                    onClick={() =>
                                      props.deleteDiscountCodeHandler(v._id)
                                    }
                                  >
                                    <i className="fas fa-trash-alt"></i> Delete
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <h2>Loading...</h2>
                  </>
                )}
              </div>
            </>
          )}
          <Toaster />
        </div>
      </div>
    </>
  );
};

// export default withRouter(Discountcode);

const mapStateToProps = (state) => ({
  submit: state.deleteEventReducer,
  createCode: state.createDiscountCodeReducer,
  editCode: state.editDiscountCodeReducer,
  discountcode: state.getDiscountCodeReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAllDiscountcodehandler: (user) => dispatch(getAllDiscountCode(user)),
  deleteDiscountCodeHandler: (user) => dispatch(deleteDiscountCode(user)),
  resetdeleteDiscountCodeReducer: () => dispatch({ type: RESET_DELETE_EVENT }),
  resetcreatecodeReducer: () => dispatch({ type: RESET_CREATE_DISCOUNT_CODE }),
  reseteditcodeReducer: () => dispatch({ type: RESET_EDIT_DISCOUNT_CODE }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Discountcode));
