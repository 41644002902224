import React,{useEffect} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {useHistory,withRouter} from "react-router-dom"
import { getAccountDetails, resetReduxStore, signout } from "../service/action/userAction";
import { RESET_ACCOUNT_DETAILS, RESET_ALL_REPORT, RESET_GET_CREDIT, RESET_GET_PAYMENT_REQUEST, RESET_OUTSTANDING_REPORT, RESET_RECEIVE_REPORT, RESET_REFUND_REPORT, RESET_SIGNIN } from "../service/constants";

// import DatePicker from "react-datepicker";
// import classNames from "classnames";
// import moment from "moment";

const DownloadAppPage = (props) => {
    
    // console.clear()
    const history=useHistory()
    const signout = (e) => {
        
        e.preventDefault();
        // alert("outer logout")
        // if (props.signout?.success) {
        // alert("logout")  

        props.resetAccountDetails()
        props.signoutHandler()

        localStorage.removeItem("token")
        localStorage.removeItem("status")
        localStorage.removeItem("club")
        localStorage.removeItem("PaymentCodeId")
        localStorage.removeItem("auth")
        localStorage.clear();
        sessionStorage.removeItem("link")
        sessionStorage.clear()
        props.resetSigninHandler()
        props.resetRedux()
        props.resetGetPaymentRequest()

        history.push('/')

        
    }
    
    useEffect(() => {
        if (props.signout?.success) {
            
        }
    }, [props.signout])






    return (
        <React.Fragment>
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex auth login-section">
                    <div className="row flex-grow">
                        <div className="col-lg-4 mx-auto my-auto">
                            <div className="auth-form-light text-center p-5">

                                <h6 className="text-white">This account is vissible only in Mobile App So you have to download App or goback to select account page.</h6>
                                <form className="pt-3">
                                    <div className="mt-3 text-center">
                                        <Link to="/switch" className="auth-link fw-m">
                                            <button
                                                type="submit"
                                                className="btn btn-login font-weight-medium auth-form-btn"
                                                
                                            >
                                                Go Back
                                            </button>
                                        </Link>
                                    </div>

                                    <div className="text-center mt-4 text-white">
                                        {" "}
                                        Download the Mobile app{" "}
                                        <Link to="/switch" className="auth-link fw-m">
                                            Here
                                        </Link>
                                    </div>

                                    <div className="text-center mt-4 text-white">
                                        {" "}
                                        Or{" "}

                                    </div>
                                    <div className="mt-3 text-center">
                                        {/* <Link to="/switch" className="auth-link fw-m"> */}
                                            <button
                                                type="submit"
                                                className="btn btn-login font-weight-medium auth-form-btn" onClick={signout}
                                            >
                                                Sign out
                                            </button>
                                        {/* </Link> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

// export default DownloadAppPage;

const mapStateToProps = (state) => ({
    signout: state.signoutReducer,


})

const mapDispatchToProps = dispatch => ({
    // getEventDataHandler:user=>dispatch(getDatas(user))
    //     // reset:(e) => dispatch(reSet(e))
    signoutHandler: user => dispatch(signout(user)),
    resetSigninHandler: () => dispatch({ type: RESET_SIGNIN }),
    getAccountDetails: user => dispatch(getAccountDetails(user)),
    resetAccountDetails: () => dispatch({ type: RESET_ACCOUNT_DETAILS }),
    resetRedux: () => dispatch(resetReduxStore()),
    reset_getCreditReducer:()=>dispatch({type:RESET_GET_CREDIT}),
    resetReceive: () => dispatch({ type: RESET_RECEIVE_REPORT }),
    resetOutStanding: () => dispatch({ type: RESET_OUTSTANDING_REPORT }),
    resetRefund: () => dispatch({ type: RESET_REFUND_REPORT }),
    resetAllreport:()=>dispatch({type:RESET_ALL_REPORT}),
    resetGetPaymentRequest:()=>dispatch({type:RESET_GET_PAYMENT_REQUEST})


})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DownloadAppPage))
