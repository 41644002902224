import { REMOVE_ROSTER_ERROR, REMOVE_ROSTER_LOADING, REMOVE_ROSTER_SUCCESS, RESET_CREATE_ROSTER, RESET_REMOVE_ROSTER } from "../../constants";

let initialState = {
 loading: false,
 user: [{}],
 error: false,
 errors: [],
 success: false,
 payload: {},
 message: "",
};

export default function removeRosterReducer(state = initialState, action) {
 switch (action.type) {

  case REMOVE_ROSTER_LOADING:
   return {
    ...state,
    error: false,
    loading: true,
    success: false,
    message: action.msg,
   };

  case REMOVE_ROSTER_SUCCESS:
   return {
    ...state,
    error: false,
    loading: false,
    errors: [],
    success: true,
   };

  case REMOVE_ROSTER_ERROR:
   return {
    ...state,
    error: false,
    loading: false,
    success: false,
    errors: [],
   };
  case RESET_REMOVE_ROSTER:
   return {
    ...state,
    error: false,
    loading: false,
    success: false,
    errors: [],
   };

  default:
   return state;
 }
}
