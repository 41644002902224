import { GET_CLUB_TEAM_EVENT_ERROR, GET_CLUB_TEAM_EVENT_LOADING, GET_CLUB_TEAM_EVENT_SUCCESS, RESET_GET_CLUB_TEAM_EVENT } from "../../../constants";

let initialState = {
 error: false,
 success: false,
 loading: false,
 errors: [],
 data: []
}

export default function getTeamEventDetails(state = initialState, action) {
 switch (action.type) {
  case GET_CLUB_TEAM_EVENT_LOADING:
   return { ...state, error: false, loading: true, success: false };
  case GET_CLUB_TEAM_EVENT_SUCCESS:
   return { ...state, error: false, success: true, loading: false, data: action.data.allEvent };
  case GET_CLUB_TEAM_EVENT_ERROR:
   return { ...state, error: true, success: false, loading: false };
  case RESET_GET_CLUB_TEAM_EVENT:
   return { ...state, error: false, success: false, loading: false, errors: [] }
  default:
   return state;
 }
}