import React, { useState, useEffect } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import Toaster from '../../../Toaster/Toaster';
import { withRouter, Redirect } from 'react-router-dom';
import { connect, useSelector, useParams } from 'react-redux';
import { createCredit, createPaymentCode, getEventsRegisterTeam, getPaidRecipient, getPendingAmount, getRecipient, paymentRequest, postClubPendingamount } from "../../../service/action/userAction"
import { RESET_CREATE_CREDIT, RESET_CREATE_PAYMENT_CODE, RESET_GET_PAID_RECIPIENT, RESET_PAYMENT_REQUEST } from '../../../service/constants';
import DatePicker from "react-datepicker";
import classNames from 'classnames';
import Spinner from '../../../Spinner/Spinner';
import { CREDIT_AMOUNT, EVENT, NUMERIC, RECIPIENT } from '../../../Validation/club';

const CreateClubCredit = (props) => {
    useEffect(() => {
        props.getEventHandler()
    }, [])
    let [loading, setLoading] = useState(false);
    const getEvent = useSelector(state => state.getEventRegisterTeamReducer.user.event)
    const getRecipient = useSelector(state => state.getPaidRecipientReducer.user.data)
    const initialState = {
        event: "",
        recipient: "",
        amount: "",
    };
    const [datePick, setDatePick] = useState('')
    const [state, setState] = useState({
        event: "",
        recipient: "",
        amount: "",
        recipient_type:""
    })

    const [errors, setErrors] = useState({
        event: false,
        recipient: false,
        amount: false

    })


    //setTimeout form reseting data after submit of form
    function resetData() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    }

    //clear data on submit.
    const clearState = () => {
        setState({ ...initialState });
        setDatePick("");
    };


    const eventHandler = (e) => {
        const { id, value, name } = e.target


        setState(prevState => ({
            ...prevState,
            [id]: value
            // [e.target.name]: e.target.value.trim()
        }))
        switch (name) {
   
            case "event":
                if (value !== 0) {
                    props.getrecipientHandler({ id: value }) 
                }
                if (value == 0 || value == "#") {
                    setErrors(prevState => ({
                        ...prevState,
                        event: EVENT
                    }))
                    props.resetrecipientHandler()
                    setState(prevState => ({
                        ...prevState,
                        recipient: "",
                        recipient_type:"",
                        event:""
                    }))
                    document.getElementById("event").classList.add("error")

                }

                else {
                    setErrors(prevState => ({
                        ...prevState,
                        event: false
                    }))
                    setState(prevState => ({
                        ...prevState,
                        event: value
                    }))

                    document.getElementById("event").classList.remove("error")
                }
                break;

            case "recipient":

                if (value !== 0) {
                    const recipient=getRecipient.find(e=>e._id==value)
                    setState(prevState=>({
                        ...prevState,
                        recipient_type:recipient.type
                    }))

                }
                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        recipient: RECIPIENT
                    }))

                    document.getElementById("recipient").classList.add("error")
                }
                else {

                    setErrors(prevState => ({
                        ...prevState,
                        recipient: false
                    }))

                    document.getElementById("recipient").classList.remove("error")
                }

                break;

            case "amount":

                if (value.length == 0) {
                    setErrors(prevState => ({
                        ...prevState,
                        amount: CREDIT_AMOUNT
                    }))


                    document.getElementById("amount").classList.add("error")
                }
                else if (!/^[0-9]+$/.test(value)) {
                    setErrors(prevState => ({
                        ...prevState,
                        amount: NUMERIC
                    }))

                    document.getElementById("amount").classList.add("error")
                }
                else {
                    setErrors(prevState => ({
                        ...prevState,
                        amount: false
                    }))


                    document.getElementById("amount").classList.remove("error")
                }
                break;

            default:
        }
    }

    const notify = (msg) => toast(msg);

    const submitRequest = (e) => {
        e.preventDefault();
        // alert("click")
        if (state.event == "") {
            setErrors(prevState => ({
                ...prevState,
                event: EVENT
            }))
            document.getElementById("event").classList.add("error")
        }
        if (state.recipient == "") {
            setErrors(prevState => ({
                ...prevState,
                recipient: RECIPIENT
            }))
            document.getElementById("recipient").classList.add("error")
        }

        if (state.amount == "") {
            setErrors(prevState => ({
                ...prevState,
                amount:CREDIT_AMOUNT
            }))
            document.getElementById("amount").classList.add("error")
        }

        if (state.event !== 0 && state.amount !== "" && state.recipient !== 0 ) {
            props.createCreditHandler({
                event: state.event,
                receipient: state.recipient,
                credit_amount: state.amount,
                receipient_type:state.recipient_type
            })
        }
    }


    useEffect(() => {
        if (props.credit?.success) {
            notify("Successfully generate")
            props.resetcreatecreditHandler()
            resetData().then(clearState)
        }
    }, [props.credit])



    useEffect(() => {
        if (props.event?.success) {
            setLoading(false)
        }
    }, [props.event])

    return (
        <>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <h5 className="dash-head">Create Credit</h5>
                    </div>
                    <div className='d-flex justify-content-center'>

                        {loading ? <Spinner /> : null}
                    </div>

                    {
                        getEvent ? <>
                            <form >
                                <div className="row">
                                    <div className="col-md-4 col-xl-4">
                                        <div className="mt-24">
                                            <div className="form-group event-drp mt-18">
                                                <label className="text-gray fs-13">Event <span className="text-red fw-m">*</span></label>
                                                <select className={classNames("form-control team-name payterm", { "error": errors.event })} id="event" name="event" value={state.event} onChange={eventHandler}>
                                                    <option value="0" >Event *</option>
                                                    {
                                                        getEvent.map((v, i) => {
                                                            return <option value={v._id} key={v._id}>{v.event_name}</option>
                                                        })
                                                    }
                                                </select>
                                                {errors.event &&
                                                    <span className='text-red small d-block'>* {errors.event}</span>}
                                            </div>
                                            <div className="form-group event-drp">
                                                <label className="text-gray fs-13">Recipient <span className="text-red fw-m">*</span></label>
                                                <select className={classNames("form-control team-name payterm", { "error": errors.recipient })} id="recipient" name="recipient" value={state.recipient} onChange={eventHandler}>
                                                    <option value="#" >Recipient *</option>
                                                    {getRecipient ? <>
                                                        {
                                                            getRecipient.map((v, i) => {
                                                                return <option value={v._id} key={v._id}>{v.name}</option>
                                                            })
                                                        }
                                                    </> : <></>}

                                                </select>

                                                {errors.recipient &&
                                                    <span className='text-red small d-block'>* {errors.recipient}</span>}
                                            </div>




                                            <div className="form-group event-drp">
                                                <label className="text-gray fs-13">Credit <span className="text-red fw-m">*</span></label>

                                                <input
                                                    type="number"
                                                    className="form-control team-name"
                                                    id="amount"
                                                    name="amount"
                                                    value={state.amount}
                                                    placeholder="Enter Amount *"
                                                    required onChange={eventHandler}
                                                // onKeyPress={numericValue} 
                                                />

                                                {errors.amount &&
                                                    <span className='text-red small d-block'>* {errors.amount}</span>}
                                            </div>


                                            <div className="mt-3 text-left">
                                                <a className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitRequest} >Send</a>
                                            </div>

                                            <Toaster />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </> : <></>
                    }
                </div>
            </div>

        </>
    )
}

// export default withRouter(CreateInvoice)



const mapStateToProp = state => ({
    submitRequest: state.createPaymentCodeReducer,
    paymentRequest: state.paymentRequestReducer,
    event: state.getEventRegisterTeamReducer,
    credit:state.createcreditReducer
})

const mapDispatchToProps = dispatch => ({
    getEventHandler: user => dispatch(getEventsRegisterTeam(user)),
    getrecipientHandler: user => dispatch(getPaidRecipient(user)),
    // getPendingAmountHandler: user => dispatch(getPendingAmount(user)),
    // paymentRequesthandler: user => dispatch(paymentRequest(user)),
    resetcreatecreditHandler: () => dispatch({ type: RESET_CREATE_CREDIT }),
    createCreditHandler:user=>dispatch(createCredit(user)),
    resetrecipientHandler:()=>dispatch({type:RESET_GET_PAID_RECIPIENT})




})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(CreateClubCredit));   