import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";
import { withRouter, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { connect, useSelector } from "react-redux";
// import { createSeason } from '../../service/action/userAction';
// import { RESET_CREATE_SEASON } from '../../service/constants';
import classNames from "classnames";
import { findUser, getAcceptedPlayer } from "../../service/action/userAction";
import Spinner from "../../Spinner/Spinner";

const EventPlayer = (props) => {
  let history = useHistory();
  const { id, id1 } = useParams(); // id : team id , id1 : event id

  const [loading, setLoading] = useState(true);
  const [ReceipientList, setReceipientList] = useState([]);
  const [state, setState] = useState({
    email: "",
  });
  useEffect(() => {
    if (id) {
      props.AcceptedPlayerHandeler({ id: id1 });
    }
  }, []);

  useEffect(() => {
    setLoading(false);
    setReceipientList(props.players?.user?.data);
  }, !props.players.loading);

  function resetData() {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }
  const Searchval = (e) => {
    const { value } = e.target;
    props.findByEmailHandler(value);
  };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="text-left">
                <h5 className="dash-head">Add player</h5>
              </div>

              <div className="card-body p-0">
                {/* desktop view */}
                <div className="table-responsive d-none d-md-block">
                  <div className="search-section">
                    <i className="mdi mdi-magnify search-ico"></i>
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      name="search"
                      value={state.search}
                      placeholder="Search"
                      onChange={Searchval}
                      autoComplete="off"
                    />
                  </div>

                  <div>
                    {ReceipientList && ReceipientList.length !== 0 ? (
                      <>
                        <table className="table event-table">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Name</th>
                              <th>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!loading &&
                              ReceipientList?.map((v) => {
                                return (
                                  <tr>
                                    <td>
                                      <img
                                        src={
                                          v.player.profile_image !== ""
                                            ? v.player.profile_image
                                            : "./images/pch-images/pch.svg"
                                        }
                                        className="img-fluid event-img"
                                      />
                                    </td>
                                    <td>{v.player.name}</td>
                                    <td>{v.player.email}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center mt-5">No Parent/Players Found</div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          <Toaster />
        </div>
      </div>
    </>
  );
};
// export default withRouter(Org_CreateDivision)
const mapStateToProp = (state) => ({
  players: state.playerAcceptListReducer,
});
const mapDispatchToProps = (dispatch) => ({
  AcceptedPlayerHandeler: (data) => dispatch(getAcceptedPlayer(data)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(EventPlayer));
