import axios from "axios";
import {
  accept_player_list,
  Accept_Reject_Coach_Url,
  active_deactive_Player_Url,
  addRoaster_Url,
  addSelectedRoster_Url,
  addTeamRoster_Url,
  add_team_Division_Url,
  apply_discountcode_Url,
  approveLiability_Url,
  assignCoach_Url,
  bank_details_Url,
  clubreminder_Url,
  club_all_team_Url,
  Club_change_request_status_Url,
  club_event_team_Url,
  club_register_Team_Url,
  coach_Invitation_Url,
  Coach_InviteClick_Url,
  connected_user_Url,
  Create_Division_Url,
  create_event_division_Url,
  Create_league_Url,
  create_merchand_account_Url,
  delete_event_pool_Url,
  Division_Url,
  edit_Event_Division_Url,
  edit_team_Url,
  EventSeasonClub_Url,
  event_pool_Url,
  Field_Url,
  getAllInvitation_Url,
  getClubOrganizerEvent_Url,
  getClubs_Org_List_Url,
  getClubTeam_Url,
  getClub_DashBoard_Url,
  getCoachInvitation_Url,
  getFiled_Url,
  getLeague_Url,
  getMainLeague_Url,
  getParent_Invitation_Url,
  getPaymentMethod_Url,
  getpaymentreqSend_Url,
  getPlayerDetails_Url,
  getPlayer_Invitation_Url,
  getTeamDetails_Url,
  get_Club_Event_Url,
  get_Club_Notification_Url,
  get_Club_Org_Event_Details_Url,
  get_Club_Org_Liability_Url,
  get_Coach_Accept_List_Url,
  get_Division_Url,
  get_Event_Details_for_RegisterTeam_Url,
  get_Event_Division_Url,
  get_filter_Club_Event_Url,
  get_Org_Event_Team_Url,
  get_Org_notification,
  get_Org_Request_Url,
  Get_Player_List_Url,
  get_Staff_Join_request_Url,
  InvitePlayer_Org_Url,
  InvitePlayer_Url,
  Org_connected_user_Url,
  parent_accept_Reject_Url,
  payNow_Url,
  Player_Invitation_Url,
  register_selected_team_Url,
  removeAccount_Url,
  removeTeamRoster_Url,
  requested_Payment_Url,
  save_club_liability,
  signature_Post_Url,
  Team_Event_Url,
  team_register_Url,
  updateProfileImage_Url,
  Upload_excel_Url,
  getPaymentHistory_Url,
  Club_Team_Event_Url,
  removeTeamPlayer_Url,
  addPlayer_Url,
  Player_details_Url,
  clearClubNotification_Url,
  get_Club_Recipient_Url,
  getPermissionAdminGroup_Url,
  getRegisterTeamPaybleAmount_Url,
} from "../../../apiSheet";

import {
  ACCEPT_COACH_ERROR,
  ACCEPT_COACH_LOADING,
  ACCEPT_COACH_SUCCESS,
  ACCEPT_INVITATION_ERROR,
  ACCEPT_INVITATION_LOADING,
  ACCEPT_INVITATION_SUCCESS,
  ACCEPT_PLAYER_ERROR,
  ACCEPT_PLAYER_LOADING,
  ACCEPT_PLAYER_SUCCESS,
  ACTIVE_PLAYER_ERROR,
  ACTIVE_PLAYER_LOADING,
  ACTIVE_PLAYER_SUCCESS,
  ADD_ROSTER_ERROR,
  ADD_ROSTER_LOADING,
  ADD_ROSTER_SUCCESS,
  ADD_TEAM_PLAYER_ERROR,
  ADD_TEAM_PLAYER_LOADING,
  ADD_TEAM_PLAYER_SUCCESS,
  APPROVE_LIABILITY_ERROR,
  APPROVE_LIABILITY_LOADING,
  APPROVE_LIABILITY_SUCCESS,
  ASSIGN_COACH_ERROR,
  ASSIGN_COACH_LOADING,
  ASSIGN_COACH_SUCCESS,
  CLEAR_NOTIFICATION_ERROR,
  CLEAR_NOTIFICATION_LOADING,
  CLEAR_NOTIFICATION_SUCCESS,
  CLUB_GET_RECIPIENT_ERROR,
  CLUB_GET_RECIPIENT_LOADING,
  CLUB_GET_RECIPIENT_SUCCESS,
  CREATE_ADMIN_GROUP_ERROR,
  CREATE_ADMIN_GROUP_LOADING,
  CREATE_ADMIN_GROUP_SUCCESS,
  CREATE_BANKDETAILS_ERROR,
  CREATE_BANKDETAILS_LOADING,
  CREATE_BANKDETAILS_SUCCESS,
  CREATE_DIVISION_ERROR,
  CREATE_DIVISION_LOADING,
  CREATE_DIVISION_SUCCESS,
  CREATE_EVENT_DIVISION_ERROR,
  CREATE_EVENT_DIVISION_LOADING,
  CREATE_EVENT_DIVISION_SUCCESS,
  CREATE_LEAGUE_ERROR,
  CREATE_LEAGUE_LOADING,
  CREATE_LEAGUE_SUCCESS,
  CREATE_LIABILITY_ERROR,
  CREATE_LIABILITY_LOADING,
  CREATE_LIABILITY_SUCCESS,
  CREATE_MERCHAND_ACCOUNT_ERROR,
  CREATE_MERCHAND_ACCOUNT_LOADING,
  CREATE_MERCHAND_ACCOUNT_SUCCESS,
  CREATE_PAYMENT_CODE_ERROR,
  CREATE_PAYMENT_CODE_LOADING,
  CREATE_PAYMENT_CODE_SUCCESS,
  CREATE_ROSTER_ERROR,
  CREATE_ROSTER_LOADING,
  CREATE_ROSTER_SUCCESS,
  CREATE_TEAM_ERROR,
  CREATE_TEAM_LOADING,
  CREATE_TEAM_SUCCESS,
  DELETE_BANK_ERROR,
  DELETE_BANK_LOADING,
  DELETE_BANK_SUCCESS,
  DELETE_DIVISION_ERROR,
  DELETE_DIVISION_LOADING,
  DELETE_DIVISION_SUCCESS,
  DELETE_EVENTTEAM_ERROR,
  DELETE_EVENTTEAM_LOADING,
  DELETE_EVENTTEAM_SUCCESS,
  DELETE_EVENT_ERROR,
  DELETE_EVENT_LOADING,
  DELETE_EVENT_POOL_ERROR,
  DELETE_EVENT_POOL_LOADING,
  DELETE_EVENT_POOL_SUCCESS,
  DELETE_EVENT_SUCCESS,
  DELETE_PAYMENT_METHOD,
  DISCOUNT_CODE_APPLY_ERROR,
  DISCOUNT_CODE_APPLY_LOADING,
  DISCOUNT_CODE_APPLY_SUCCESS,
  EDIT_BANK_ERROR,
  EDIT_BANK_LOADING,
  EDIT_BANK_SUCCESS,
  EDIT_CLUB_TEAM_ERROR,
  EDIT_CLUB_TEAM_LOADING,
  EDIT_CLUB_TEAM_SUCCESS,
  EDIT_DIVISION_ERROR,
  EDIT_DIVISION_LOADING,
  EDIT_DIVISION_SUCCESS,
  EDIT_EVENT_DIVISION_ERROR,
  EDIT_EVENT_DIVISION_LOADING,
  EDIT_EVENT_DIVISION_SUCCESS,
  EDIT_SEASON_ERROR,
  EDIT_SEASON_LOADING,
  EDIT_SEASON_SUCCESS,
  GET_ALLCLUBEVENT_ERROR,
  GET_ALLCLUBEVENT_LOADING,
  GET_ALLCLUBEVENT_SUCCESS,
  GET_ALLCLUBSEASON_ERROR,
  GET_ALLCLUBSEASON_LOADING,
  GET_ALLCLUBSEASON_SUCCESS,
  GET_ALL_DIVISION_LIST_ERROR,
  GET_ALL_DIVISION_LIST_LOADING,
  GET_ALL_DIVISION_LIST_SUCCESS,
  GET_ALL_INVITATION_ERROR,
  GET_ALL_INVITATION_LOADING,
  GET_ALL_INVITATION_SUCCESS,
  GET_ALL_LEAGUE_ERROR,
  GET_ALL_LEAGUE_LOADING,
  GET_ALL_LEAGUE_SUCCESS,
  GET_ALL_SEASON_ERROR,
  GET_ALL_SEASON_LOADING,
  GET_ALL_SEASON_SUCCESS,
  GET_CLUBS_ORG_ERROR,
  GET_CLUBS_ORG_LIABILITY_ERROR,
  GET_CLUBS_ORG_LIABILITY_LOADING,
  GET_CLUBS_ORG_LIABILITY_SUCCESS,
  GET_CLUBS_ORG_LOADING,
  GET_CLUBS_ORG_SUCCESS,
  GET_CLUB_DASHBOARD_ERROR,
  GET_CLUB_DASHBOARD_LOADING,
  GET_CLUB_DASHBOARD_SUCCESS,
  GET_CLUB_EVENT_TEAM_ERROR,
  GET_CLUB_EVENT_TEAM_LOADING,
  GET_CLUB_EVENT_TEAM_SUCCESS,
  GET_CLUB_NOTIFICATION_ERROR,
  GET_CLUB_NOTIFICATION_LOADING,
  GET_CLUB_NOTIFICATION_SUCCESS,
  GET_CLUB_ORG_EVENT_DETAILS_ERROR,
  GET_CLUB_ORG_EVENT_DETAILS_LOADING,
  GET_CLUB_ORG_EVENT_DETAILS_SUCCESS,
  GET_CLUB_ORG_EVENT_ERROR,
  GET_CLUB_ORG_EVENT_LOADING,
  GET_CLUB_ORG_EVENT_SUCCESS,
  GET_CLUB_TEAMBY_ID_ERROR,
  GET_CLUB_TEAMBY_ID_LOADING,
  GET_CLUB_TEAMBY_ID_SUCCESS,
  GET_CLUB_TEAM_ERROR,
  GET_CLUB_TEAM_EVENT_ERROR,
  GET_CLUB_TEAM_EVENT_LOADING,
  GET_CLUB_TEAM_EVENT_SUCCESS,
  GET_CLUB_TEAM_LOADING,
  GET_CLUB_TEAM_SUCCESS,
  GET_COACH_ACCEPT_LIST_ERROR,
  GET_COACH_ACCEPT_LIST_LOADING,
  GET_COACH_ACCEPT_LIST_SUCCESS,
  GET_COACH_INVITATION_ERROR,
  GET_COACH_INVITATION_LOADING,
  GET_COACH_INVITATION_SUCCESS,
  GET_CONNECTED_USER_CLUB_ERROR,
  GET_CONNECTED_USER_CLUB_LOADING,
  GET_CONNECTED_USER_CLUB_SUCCESS,
  GET_CONNECTED_USER_ERROR,
  GET_CONNECTED_USER_LOADING,
  GET_CONNECTED_USER_SUCCESS,
  GET_EVENT_DETAILS_REGISTRATION_ERROR,
  GET_EVENT_DETAILS_REGISTRATION_LOADING,
  GET_EVENT_DETAILS_REGISTRATION_SUCCESS,
  GET_EVENT_DIVISION_ERROR,
  GET_EVENT_DIVISION_LOADING,
  GET_EVENT_DIVISION_SUCCESS,
  GET_EVENT_TEAM_ERROR,
  GET_EVENT_TEAM_LOADING,
  GET_EVENT_TEAM_SUCCESS,
  GET_FIELD_ERROR,
  GET_FIELD_LOADING,
  GET_FIELD_SUCCESS,
  GET_LIABILITY_ERROR,
  GET_LIABILITY_LOADING,
  GET_LIABILITY_SUCCESS,
  GET_ORG_REQUEST_ERROR,
  GET_ORG_REQUEST_LOADING,
  GET_ORG_REQUEST_SUCCESS,
  GET_PARENT_INVITATION_ERROR,
  GET_PARENT_INVITATION_LOADING,
  GET_PARENT_INVITATION_SUCCESS,
  GET_PAYMENTREQUEST_ERROR,
  GET_PAYMENTREQUEST_LOADING,
  GET_PAYMENTREQUEST_SUCCESS,
  GET_PAYMENT_METHOD_ERROR,
  GET_PAYMENT_METHOD_LOAING,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PERMISSION_GROUP_ERROR,
  GET_PERMISSION_GROUP_LOADING,
  GET_PERMISSION_GROUP_SUCCESS,
  GET_PLAYER_ACCEPT_LIST_ERROR,
  GET_PLAYER_ACCEPT_LIST_LOADING,
  GET_PLAYER_ACCEPT_LIST_SUCCESS,
  GET_PLAYER_INVITAION_ERROR,
  GET_PLAYER_INVITAION_LOADING,
  GET_PLAYER_INVITAION_SUCCESS,
  GET_PLAYER_LIST_ERROR,
  GET_PLAYER_LIST_LOADING,
  GET_PLAYER_LIST_SUCCESS,
  GET_REG_TEAM_PAY_AMOUNT_ERROR,
  GET_REG_TEAM_PAY_AMOUNT_SUCCESS,
  GET_STAFF_INVITATION_ERROR,
  GET_STAFF_INVITATION_LOADING,
  GET_STAFF_INVITATION_SUCCESS,
  GET_TEAM_DETAIL_ERROR,
  GET_TEAM_DETAIL_LOADING,
  GET_TEAM_DETAIL_SUCCESS,
  GET_TEAM_EVENT_ERROR,
  GET_TEAM_EVENT_LOADING,
  GET_TEAM_EVENT_SUCCESS,
  ORG_PLAYER_DETAILS_ERROR,
  ORG_PLAYER_DETAILS_LOADING,
  ORG_PLAYER_DETAILS_SUCCESS,
  PAYMENT_HISTORY_ERROR,
  PAYMENT_HISTORY_LOADING,
  PAYMENT_HISTORY_SUCCESS,
  PAYMENT_METHOD_DELETE_SUCCESS,
  PAYMENT_METHOD_ERROR,
  PAYMENT_METHOD_LOAING,
  PAYMENT_METHOD_SUCCESS,
  PAYNOW_ERROR,
  PAYNOW_LOADING,
  PAYNOW_SUCCESS,
  PLAYER_DETAILS_ERROR,
  PLAYER_DETAILS_LOADING,
  PLAYER_DETAILS_SUCCESS,
  REGISTERED_TEAM_ERROR,
  REGISTERED_TEAM_LOADING,
  REGISTERED_TEAM_SUCCESS,
  REGISTER_SELECTED_TEAM_ERROR,
  REGISTER_SELECTED_TEAM_LOADING,
  REGISTER_SELECTED_TEAM_SUCCESS,
  REMINDER_CLUB_ERROR,
  REMINDER_CLUB_LOADING,
  REMINDER_CLUB_SUCCESS,
  REMOVE_ACCOUNT_ERROR,
  REMOVE_ACCOUNT_LOADING,
  REMOVE_ACCOUNT_SUCCESS,
  REMOVE_PAYMENT_METHOD_ERROR,
  REMOVE_PAYMENT_METHOD_LOAING,
  REMOVE_PAYMENT_METHOD_SUCCESS,
  REMOVE_ROSTER_ERROR,
  REMOVE_ROSTER_LOADING,
  REMOVE_ROSTER_SUCCESS,
  REMOVE_TEAM_PLAYER_ERROR,
  REMOVE_TEAM_PLAYER_LOADING,
  REMOVE_TEAM_PLAYER_SUCCESS,
  SAVE_PAYMENT_INFO,
  SEND_COACH_INVITATION_ERROR,
  SEND_COACH_INVITATION_LOADING,
  SEND_COACH_INVITATION_SUCCESS,
  SIGNATURE_ERROR,
  SIGNATURE_LOADING,
  SIGNATURE_SUCCESS,
  UPDATE_LEAGUE_ERROR,
  UPDATE_LEAGUE_LOADING,
  UPDATE_LEAGUE_SUCCESS,
  UPDATE_PROFILE_IMAGE_ERROR,
  UPDATE_PROFILE_IMAGE_LOADING,
  UPDATE_PROFILE_IMAGE_SUCCESS,
} from "../../constants";
import {
  GET_BANKS_LOADING,
  GET_BANKS_SUCCESS,
  GET_BANKS_ERROR,
} from "../../constants";

export const getAllClubEvent = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ALLCLUBEVENT_LOADING,
    });
    const id = user.id;

    const url1 = get_Club_Event_Url;
    const url2 = get_filter_Club_Event_Url + `/week`;
    const url3 = get_filter_Club_Event_Url + `/month`;

    // const getToken = localStorage.getItem("token")

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: id === 0 ? url1 : id === 1 ? url2 : url3,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_ALLCLUBEVENT_SUCCESS,
          data: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALLCLUBEVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getClubOrganizerEvent = () => {
  return (dispatch) => {
    dispatch({
      type: GET_CLUB_ORG_EVENT_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getClubOrganizerEvent_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_CLUB_ORG_EVENT_SUCCESS,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: GET_CLUB_ORG_EVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get All teams in Club[GET]
//Reducer : getCLubAllReducer
export const getClubAllTeam = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_CLUB_TEAM_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: club_all_team_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_CLUB_TEAM_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CLUB_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getClubTeam = (user) => {
  return (dispatch) => {
    dispatch({
      type: GET_CLUB_TEAM_LOADING,
    });
    const id = user?.id ?? "";
    const notificationToken = localStorage.getItem("notificationToken");
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: getClubTeam_Url + `/${id}`,
    };

    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data.team;

        dispatch({
          type: GET_CLUB_TEAM_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CLUB_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get team by id in Club[GET]
//Reducer : getTeamByIdReducer
export const getTeamById = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_CLUB_TEAMBY_ID_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: club_all_team_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_CLUB_TEAMBY_ID_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CLUB_TEAMBY_ID_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Edit Club Team [PUT]
//Reducer: editClubTeamReducer

export const editClubTeam = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: EDIT_CLUB_TEAM_LOADING,
    });

    // const id = localStorage.getItem("season_id")
    const id = user.id;
    const acctype = localStorage.getItem("type");

    const formData = new FormData();
    formData.append("event_id", user.event_id);
    formData.append("team_name", user.team_name);
    formData.append("division", user.division);
    formData.append("pool", user.pool);
    formData.append("coach_name", user.coach_name);
    formData.append("coach_email", user.coach_email);
    formData.append("coach_mobile", user.coach_mobile);
    formData.append("image", user.image);
    formData.append("club_name", user.club_name);

    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },

      url:
        acctype == 1 ? edit_team_Url + `/${id}` : club_all_team_Url + `/${id}`,
      data: acctype == 1 ? user : user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: EDIT_CLUB_TEAM_SUCCESS,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: EDIT_CLUB_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Club
//Delete club Team [DELETE]
//Reducer: deleteEventTeamReducer
export const deleteClubTeam = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_EVENTTEAM_LOADING,
    });
    const id = user;
    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: club_all_team_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_EVENTTEAM_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")

        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_EVENTTEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Player Invitaion in Club[GET]
//Reducer : getPlayerInvitaionReducer
export const getPlayerInvitation = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PLAYER_INVITAION_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Player_Invitation_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_PLAYER_INVITAION_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PLAYER_INVITAION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Parent Invitaion in Club[GET]
//Reducer : getParentInvitationReducer
export const getParentInvitation = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PARENT_INVITATION_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getParent_Invitation_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_PARENT_INVITATION_SUCCESS,
          payload: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PARENT_INVITATION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Field Club[GET]
//Reducer : getFieldReducer
export const getField = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_FIELD_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getFiled_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_FIELD_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_FIELD_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Delete Field [DELETE]
//Reducer: deleteEventReducer
export const deleteField = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_EVENT_LOADING,
    });
    const id = user;

    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Field_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_EVENT_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_EVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create Filed [POST]
//Reducer : createFieldReducer
export const createField = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_PAYMENT_CODE_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Field_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_PAYMENT_CODE_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_PAYMENT_CODE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Edit Field [PUT]
//Reducer: editSeasonReducer

export const editField = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: EDIT_SEASON_LOADING,
    });

    // const id = localStorage.getItem("season_id")
    const id = user.id;
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Field_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: EDIT_SEASON_SUCCESS,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: EDIT_SEASON_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Listing All division in division component
//Reducer: getAllSeasonReducer
export const getAllDivision_club = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ALL_DIVISION_LIST_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Division_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_ALL_DIVISION_LIST_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALL_DIVISION_LIST_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Listing event division in event details component
//Reducer: geteventDivisionReducer
export const geteventDivision = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_EVENT_DIVISION_LOADING,
    });

    const id = user.id;
    const notificationToken = localStorage.getItem("notificationToken");
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: get_Event_Division_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_EVENT_DIVISION_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_EVENT_DIVISION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get all season club
//Reducer: getAllSeasonReducer
export const getAllSeason_club = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ALLCLUBSEASON_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: EventSeasonClub_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_ALLCLUBSEASON_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALLCLUBSEASON_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create Division [POST]
//Reducer : createFieldReducer
export const create_Division = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_DIVISION_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Create_Division_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_DIVISION_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_DIVISION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create event Division [POST]
//Reducer : createFieldReducer
export const create_event_Division = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_EVENT_DIVISION_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: add_team_Division_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_EVENT_DIVISION_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_EVENT_DIVISION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Delete Division [DELETE]
//Reducer: deleteEventReducer
export const deleteDivision = (user) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_DIVISION_LOADING,
    });
    const id = user;

    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Division_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DELETE_DIVISION_SUCCESS,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_DIVISION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
//Delete Division [DELETE]
//Reducer: deleteEventReducer
export const deleteEventDivision = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_EVENT_LOADING,
    });
    const id = user;

    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: edit_Event_Division_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_EVENT_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_EVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Edit Field [PUT]
//Reducer: editSeasonReducer

export const editDivision = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: EDIT_DIVISION_LOADING,
    });

    // const id = localStorage.getItem("season_id")
    const id = user.id;
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Division_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: EDIT_DIVISION_SUCCESS,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: EDIT_DIVISION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Edit event division [PUT]
//Reducer: editeventDivisionReducer

export const editeventDivision = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: EDIT_EVENT_DIVISION_LOADING,
    });

    // const id = localStorage.getItem("season_id")
    const id = user.id;
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: edit_Event_Division_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: EDIT_EVENT_DIVISION_SUCCESS,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: EDIT_EVENT_DIVISION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Listing All division in division component
//Reducer: getAllSeasonReducer
export const getPlayerList = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PLAYER_LIST_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Get_Player_List_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_PLAYER_LIST_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PLAYER_LIST_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Invite Club [POST]
//Reducer: createTeamReducer
export const InvitePlayerClub = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_TEAM_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const url1 = InvitePlayer_Org_Url;
    const url2 = InvitePlayer_Url;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_TEAM_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Add Roaster player [POST]
//Reducer: createTeamReducer
export const addRoaster = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_TEAM_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: addRoaster_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_TEAM_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create League [POST]
//Reducer : createLeagueReducer
export const createLeague = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_LEAGUE_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getMainLeague_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_LEAGUE_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_LEAGUE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Edit League [Put]
//Reducer : createLeagueReducer
export const editLeague = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: UPDATE_LEAGUE_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getMainLeague_Url + `/${id}`,
      // url: Create_league_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: UPDATE_LEAGUE_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_LEAGUE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create League upload excel [POST]
//Reducer : createLeagueReducer
export const createLeagueUploadExcel = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_LEAGUE_LOADING,
    });

    const formData = new FormData();
    formData.append("file", user.file);

    const requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Upload_excel_Url,
      data: formData,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_LEAGUE_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_LEAGUE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Listing League in League component
//Reducer: getAllSeasonReducer
export const getLeague = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ALL_LEAGUE_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getMainLeague_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_ALL_LEAGUE_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALL_LEAGUE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Delete PaymentCode [DELETE]
//Reducer: deleteEventReducer
export const deleteLeague = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_EVENT_LOADING,
    });
    const id = user;

    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // url: getLeague_Url + `/${id}`,
      url: getMainLeague_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_EVENT_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_EVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// create BankDetails [POST]
//Reducer : createLeagueReducer
export const createBankDetails = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_BANKDETAILS_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: bank_details_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_BANKDETAILS_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_BANKDETAILS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Listing League in League component
//Reducer: getAllSeasonReducer
export const getBanks = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_BANKS_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: bank_details_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")

        dispatch({
          type: GET_BANKS_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_BANKS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Delete Bank details [DELETE]
//Reducer: deleteBankdetailReducer
export const deleteBankdetail = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_BANK_LOADING,
    });
    const id = user;

    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: bank_details_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_BANK_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_BANK_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Edit League [Put]
//Reducer : createLeagueReducer
export const editBankDetails = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: EDIT_BANK_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: bank_details_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: EDIT_BANK_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: EDIT_BANK_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Player Invitaion in Club[GET]
//Reducer : getPlayerInvitaionReducer
export const getCoachForInvitation = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PLAYER_INVITAION_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: coach_Invitation_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_PLAYER_INVITAION_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PLAYER_INVITAION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// Send coach invitation [POST]
//Reducer : createLeagueReducer
export const Coach_Invite = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: SEND_COACH_INVITATION_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Coach_InviteClick_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: SEND_COACH_INVITATION_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: SEND_COACH_INVITATION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get All teams in Club[GET]
//Reducer : getCLubAllReducer
export const getTeamDetails = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_TEAM_DETAIL_LOADING,
    });

    const id = user.id;
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getTeamDetails_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_TEAM_DETAIL_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_TEAM_DETAIL_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//accept player request [put]
//reducer: acceptPlayerReducer
export const acceptPlayer = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: ACCEPT_PLAYER_LOADING,
    });

    // const id = localStorage.getItem("season_id")
    const id = user.id;
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Player_Invitation_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ACCEPT_PLAYER_SUCCESS,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: ACCEPT_PLAYER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Coach invitaion in Club[GET]
//Reducer : getCoachInvitaionReducer
export const getCoachInvitationList = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_COACH_INVITATION_LOADING,
    });

    // const id=user.id

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getCoachInvitation_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_COACH_INVITATION_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_COACH_INVITATION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//accept Coach request [put]
//reducer: acceptCoachReducer
export const acceptCoach = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: ACCEPT_COACH_LOADING,
    });

    // const id = localStorage.getItem("season_id")
    const id = user.id;
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Accept_Reject_Coach_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ACCEPT_COACH_SUCCESS,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: ACCEPT_COACH_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Coach accept list in Club [GET]
//Reducer : coachAcceptListReducer
export const coachAcceptList = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_COACH_ACCEPT_LIST_LOADING,
    });

    // const id=user.id

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Coach_Accept_List_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_COACH_ACCEPT_LIST_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_COACH_ACCEPT_LIST_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get All Invitation in Club[GET]
//Reducer : getAllInvitaionReducer
export const getAllInvitation = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ALL_INVITATION_LOADING,
    });

    // const id=user.id

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getAllInvitation_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ALL_INVITATION_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ALL_INVITATION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get All Invitation in Club[GET]
//Reducer : getAllInvitaionReducer
export const getStaffInvitation = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_STAFF_INVITATION_LOADING,
    });

    // const id=user.id

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Staff_Join_request_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_STAFF_INVITATION_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_STAFF_INVITATION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
//accept staff Invitation in Club[Put]
//Reducer : getAllInvitaionReducer
export const acceptStaffInvitation = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: ACCEPT_INVITATION_LOADING,
    });

    const id = user.invitation_id;

    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Staff_Join_request_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: ACCEPT_INVITATION_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: ACCEPT_INVITATION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//accept Invitation request [put]
//reducer: acceptCoachReducer
export const Club_changeStatus = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: ACCEPT_INVITATION_LOADING,
    });

    // const id = localStorage.getItem("season_id")
    const id = user.invitation_id;
    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Club_change_request_status_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ACCEPT_INVITATION_SUCCESS,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: ACCEPT_INVITATION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Club dashboard in Club[GET]
//Reducer : getAllInvitaionReducer
export const getClubDashBoard = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_CLUB_DASHBOARD_LOADING,
    });

    // const id=user.id

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getClub_DashBoard_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_CLUB_DASHBOARD_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;

        // if(data?.status==401){
        //   localStorage.clear()

        //   setTimeout(() => {
        //     window.location.reload(false);

        //   }, 1000);
        // }
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CLUB_DASHBOARD_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Club's accepted organiser1 list, (in  club liability component)[GET]
//Reducer : getAllClubReducer
export const getClubsOrgList = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_CLUBS_ORG_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getClubs_Org_List_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_CLUBS_ORG_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CLUBS_ORG_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get perticular organiser laibility, (in club liability component)[GET]
//Reducer : getAllClubReducer
export const getLiability = (user) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIABILITY_LOADING,
    });

    const id = user.id;
    const notificationToken = localStorage.getItem("notificationToken");
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: get_Club_Org_Liability_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_LIABILITY_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_LIABILITY_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// signature save [POST]
//Reducer : signatureReducer
export const signature = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: SIGNATURE_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: signature_Post_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: SIGNATURE_SUCCESS,
          user: resp.data,
          msg: resp.data,
          url: resp.data.url,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: SIGNATURE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Club organizer event-details by id, (in  club Event details component)[GET]
//Reducer : getClubOrgEventDertrailsReducer
export const getClubOrgEventDetails = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_CLUB_ORG_EVENT_DETAILS_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Club_Org_Event_Details_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_CLUB_ORG_EVENT_DETAILS_SUCCESS,
          payload: userData,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CLUB_ORG_EVENT_DETAILS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get All teams by Id [GET]
//Reducer : getEventTeamReducer
export const getOrgEventTeam = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_EVENT_TEAM_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Org_Event_Team_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_EVENT_TEAM_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_EVENT_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get club event teams by id[GET]
//Reducer : getClubEventTeamReducer
export const getClubEventTeam = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_CLUB_EVENT_TEAM_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: club_event_team_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_CLUB_EVENT_TEAM_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CLUB_EVENT_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get All notiofication in Club[GET]
//Reducer : getClubNotificationReducer
export const getClubNotification = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_CLUB_NOTIFICATION_LOADING,
    });

    // const id=user.id

    const url2 = get_Club_Notification_Url;
    const url1 = get_Org_notification;

    const acctype = localStorage.getItem("type");

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: acctype == 1 ? url1 : url2,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_CLUB_NOTIFICATION_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CLUB_NOTIFICATION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Club organizer event-details For Registration by id, (in  club Event details component)[GET]
//Reducer : getEventDetailsForRegistrationReducer
export const getEventDetailsForRegistration = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_EVENT_DETAILS_REGISTRATION_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Event_Details_for_RegisterTeam_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_EVENT_DETAILS_REGISTRATION_SUCCESS,
          payload: userData,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_EVENT_DETAILS_REGISTRATION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// Register Team[POST]
//Reducer : registerSelectedTeamReducer
export const registerSelectedTeam = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    const notificationToken = localStorage.getItem("notificationToken");
    dispatch({
      type: REGISTER_SELECTED_TEAM_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: register_selected_team_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: REGISTER_SELECTED_TEAM_SUCCESS,
          user: resp.data,
          msg: resp.data,
          url: resp.data.url,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: REGISTER_SELECTED_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// club event Register Team[POST]
//Reducer : registerSelectedTeamReducer
export const clubRegisterSelectedTeam = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: REGISTER_SELECTED_TEAM_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: club_register_Team_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: REGISTER_SELECTED_TEAM_SUCCESS,
          user: resp.data,
          msg: resp.data,
          url: resp.data.url,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: REGISTER_SELECTED_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// Register Team[POST]
//Reducer : registerSelectedTeamReducer
export const discountCode = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    const notificationToken = localStorage.getItem("notificationToken");
    dispatch({
      type: DISCOUNT_CODE_APPLY_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: apply_discountcode_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: DISCOUNT_CODE_APPLY_SUCCESS,
          user: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DISCOUNT_CODE_APPLY_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

// Paynow [POST]
//Reducer : paynowReducer
export const payNow = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: PAYNOW_LOADING,
    });
    const notificationToken = localStorage.getItem("notificationToken");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: payNow_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: PAYNOW_SUCCESS,
          user: resp.data,
          msg: resp.data,
          url: resp.data.url,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: PAYNOW_ERROR,
            errors: validationError,
          });
        } else if (data?.status === 500) {
          let validationError = data;
          dispatch({
            type: PAYNOW_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//  Requested payment by club [POST]
//Reducer : paynowReducer
export const requested_Payment = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: PAYNOW_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: requested_Payment_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: PAYNOW_SUCCESS,
          user: resp.data,
          msg: resp.data,
          url: resp.data.url,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: PAYNOW_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//User for Chat component[GET]
//Reducer : connectedUserClubReducer
export const connectedUserClub = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_CONNECTED_USER_CLUB_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: connected_user_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_CONNECTED_USER_CLUB_SUCCESS,
          payload: userData,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CONNECTED_USER_CLUB_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//User for Chat[GET]
//Reducer : connectedUserReducer
export const connectedUser = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_CONNECTED_USER_LOADING,
    });

    const acctype = localStorage.getItem("type");

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Org_connected_user_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_CONNECTED_USER_SUCCESS,
          payload: userData,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CONNECTED_USER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Player accept list in Club [GET]
//Reducer : playerAcceptListReducer
export const playerAcceptList = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PLAYER_ACCEPT_LIST_LOADING,
    });

    // const id=user.id

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: accept_player_list,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_PLAYER_ACCEPT_LIST_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PLAYER_ACCEPT_LIST_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Create liability
//Reducer: createLiabilityReducer
export const saveClubLiability = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_LIABILITY_LOADING,
    });

    // const acctype = localStorage.getItem("type")
    // const url1= AddEventSeasonOrg_Url
    // const url2 = AddEventSeasonClub_Url
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: save_club_liability,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_LIABILITY_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_LIABILITY_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Liability [GET]
//Reducer : getPrintOrgLiabilityReducer
export const getClubLiability = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_LIABILITY_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: save_club_liability,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_LIABILITY_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_LIABILITY_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Orgnizer Request  [GET]
//Reducer : getOrgRequestReducer
export const getOrgRequest = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_ORG_REQUEST_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Org_Request_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_ORG_REQUEST_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_ORG_REQUEST_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Send selected team in array Component
//Reducer : sendPaymentInfoReducer

export const sendPaymentInfo = (user) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_PAYMENT_INFO,
      payload: user,
    });
  };
};

//create merchand account in club
//Reducer: createMerchandAccountReducer
export const createMerchandAccount = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_MERCHAND_ACCOUNT_LOADING,
    });

    // const acctype = localStorage.getItem("type")
    // const url1= AddEventSeasonOrg_Url
    // const url2 = AddEventSeasonClub_Url
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': `Bearer ${localStorage.getItem("token")}`
      },
      // url: create_merchand_account_Url,
      url: "https://stage.paragonsolutions.com/api/v2/identity",
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_MERCHAND_ACCOUNT_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;

        if (!error.response) {
          dispatch({
            type: CREATE_MERCHAND_ACCOUNT_ERROR,
            error: error,
          });
        } else if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_MERCHAND_ACCOUNT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Team Events[GET]
//Reducer : getTeamEventReducer
export const getTeamEvent = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_TEAM_EVENT_LOADING,
    });

    const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: Team_Event_Url + `/${id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_TEAM_EVENT_SUCCESS,
          payload: userData,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_TEAM_EVENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//assignCoach to team
//Reducer: assigncoachReducer
export const assignCoach = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: ASSIGN_COACH_LOADING,
    });

    // const acctype = localStorage.getItem("type")
    // const url1= AddEventSeasonOrg_Url
    // const url2 = AddEventSeasonClub_Url
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: assignCoach_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: ASSIGN_COACH_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ASSIGN_COACH_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Delete Event Pool [DELETE]
//Reducer: deleteEventPoolReducer
export const deleteClubEventPool = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: DELETE_EVENT_POOL_LOADING,
    });
    const id = user;

    const requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: delete_event_pool_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: DELETE_EVENT_POOL_SUCCESS,
          msg: resp.data,
          // msg:resp.data
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: DELETE_EVENT_POOL_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//active deactive player to team
//Reducer: activeDeactivePlayerReducer
export const activeDeactivePlayer = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: ACTIVE_PLAYER_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: active_deactive_Player_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: ACTIVE_PLAYER_SUCCESS,
          user: resp.data,
          msg: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ACTIVE_PLAYER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//accept Parent request [put]
//reducer: acceptParentReducer
export const acceptParent = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: ACCEPT_PLAYER_LOADING,
    });

    // const id = localStorage.getItem("season_id")
    const id = user.id;
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: parent_accept_Reject_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: ACCEPT_PLAYER_SUCCESS,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: ACCEPT_PLAYER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const removeAccount = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ACCOUNT_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: removeAccount_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: REMOVE_ACCOUNT_SUCCESS,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: REMOVE_ACCOUNT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const approveLiability = (user) => {
  return (dispatch) => {
    dispatch({
      type: APPROVE_LIABILITY_LOADING,
    });

    const notificationToken = localStorage.getItem("notificationToken");

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: approveLiability_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: APPROVE_LIABILITY_SUCCESS,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: APPROVE_LIABILITY_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getPaymentMethod = () => {
  return (dispatch) => {
    const notificationToken = localStorage.getItem("notificationToken");
    dispatch({
      type: GET_PAYMENT_METHOD_LOAING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: getPaymentMethod_Url,
    };

    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_PAYMENT_METHOD_SUCCESS,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PAYMENT_METHOD_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const deletePaymentMethod = (user) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_PAYMENT_METHOD_LOAING,
    });
    const id = user;

    const requestOptions = {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getPaymentMethod_Url + `/${id}`,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: REMOVE_PAYMENT_METHOD_SUCCESS,
          data: resp.data,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: REMOVE_PAYMENT_METHOD_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const createPaymentMethod = (user) => {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_METHOD_LOAING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getPaymentMethod_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: PAYMENT_METHOD_SUCCESS,
          data: resp.data,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: PAYMENT_METHOD_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const editPaymentMethod = (user) => {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_METHOD_LOAING,
    });
    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getPaymentMethod_Url + `/${user.id}`,
      data: user.data,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: PAYMENT_METHOD_SUCCESS,
          data: resp.data,
        });
      })

      .catch((error) => {
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: PAYMENT_METHOD_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const teamRegister = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: REGISTERED_TEAM_LOADING,
    });
    const notificationToken = localStorage.getItem("notificationToken");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: team_register_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: REGISTERED_TEAM_SUCCESS,
          user: resp.data,
          msg: resp.data,
          url: resp.data.url,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: REGISTERED_TEAM_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const createRoster = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: CREATE_ROSTER_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: addTeamRoster_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: CREATE_ROSTER_SUCCESS,
          msg: resp.message,
          roster: resp.roster,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_ROSTER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const removeRoster = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: REMOVE_ROSTER_LOADING,
    });

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: removeTeamRoster_Url + `/${user.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: REMOVE_ROSTER_SUCCESS,
          msg: resp.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: REMOVE_ROSTER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getPlayerDetails = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: PLAYER_DETAILS_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getPlayerDetails_Url + `/${user.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: PLAYER_DETAILS_SUCCESS,
          data: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: PLAYER_DETAILS_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const addSelectedRoster = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: ADD_ROSTER_LOADING,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: addSelectedRoster_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: ADD_ROSTER_SUCCESS,
          data: resp.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ADD_ROSTER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const updateProfileImage = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROFILE_IMAGE_LOADING,
    });

    const formData = new FormData();
    formData.append("profile_image", user.image);
    formData.append("user_id", user.user_id);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: updateProfileImage_Url,
      data: formData,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: UPDATE_PROFILE_IMAGE_SUCCESS,
          data: resp.message,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: UPDATE_PROFILE_IMAGE_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

//Get Club organizer event-details by id, (in  club Event details component)[GET]
//Reducer : getClubOrgEventDertrailsReducer
export const getPaymentReq = (user) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_PAYMENTREQUEST_LOADING,
    });

    // const id = user.id;

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getpaymentreqSend_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: GET_PAYMENTREQUEST_SUCCESS,
          payload: userData,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_PAYMENTREQUEST_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const clubreminder = (data) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: REMINDER_CLUB_LOADING,
    });
    const acctype = localStorage.getItem("type");
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: clubreminder_Url,
      data: data,
    };
    axios(requestOptions)
      .then((resp) => {
        let userData = resp.data;
        dispatch({
          type: REMINDER_CLUB_SUCCESS,
          payload: userData,
          msg: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: REMINDER_CLUB_ERROR,
            errors: validationError,
            msg: error.response.data.message,
          });
        }
      });
  };
};

export const getTeamEventsDetails = (data) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: GET_CLUB_TEAM_EVENT_LOADING,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${Club_Team_Event_Url}/${data.id}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_CLUB_TEAM_EVENT_SUCCESS,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: GET_CLUB_TEAM_EVENT_ERROR,
            errors: validationError,
            msg: error.response.data.message,
          });
        }
      });
  };
};

export const removeTeamPlayer = ({ team, player }) => {
  // alert("action loading")
  return (dispatch) => {
    dispatch({
      type: REMOVE_TEAM_PLAYER_LOADING,
    });
    const requestOptions = {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: `${removeTeamPlayer_Url}/${team}/${player}`,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: REMOVE_TEAM_PLAYER_SUCCESS,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          // alert("action  Error")
          dispatch({
            type: REMOVE_TEAM_PLAYER_ERROR,
            errors: validationError,
            msg: error.response.data.message,
          });
        }
      });
  };
};

export const addTeamPlayer = (user) => {
  // alert("Action loading")
  return (dispatch) => {
    dispatch({
      type: ADD_TEAM_PLAYER_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: addPlayer_Url,
      data: user,
    };
    axios(requestOptions)
      .then((resp) => {
        // alert("action success")
        dispatch({
          type: ADD_TEAM_PLAYER_SUCCESS,
          user: resp.data,
        });
      })

      .catch((error) => {
        // alert("action Error")
        let data = error.response;
        if (data.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: ADD_TEAM_PLAYER_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const clearAllNotification = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_NOTIFICATION_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: clearClubNotification_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CLEAR_NOTIFICATION_SUCCESS,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CLEAR_NOTIFICATION_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getClubRecipient = (details) => {
  return (dispatch) => {
    dispatch({
      type: CLUB_GET_RECIPIENT_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: get_Club_Recipient_Url,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CLUB_GET_RECIPIENT_SUCCESS,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CLUB_GET_RECIPIENT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const getAdminGroupPermission = () => {
  return (dispatch) => {
    dispatch({
      type: GET_PERMISSION_GROUP_LOADING,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getPermissionAdminGroup_Url,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_PERMISSION_GROUP_SUCCESS,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: GET_PERMISSION_GROUP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};

export const createAdminGroup = (details) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_ADMIN_GROUP_LOADING,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      url: getPermissionAdminGroup_Url,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: CREATE_ADMIN_GROUP_SUCCESS,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: CREATE_ADMIN_GROUP_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
export const getRegTeamPayAmount = (details) => {
  return (dispatch) => {
    const notificationToken = localStorage.getItem("notificationToken");
    const requestOptions = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${
          !!notificationToken
            ? notificationToken
            : localStorage.getItem("token")
        }`,
      },
      url: getRegisterTeamPaybleAmount_Url,
      data: details,
    };
    axios(requestOptions)
      .then((resp) => {
        dispatch({
          type: GET_REG_TEAM_PAY_AMOUNT_SUCCESS,
          data: resp.data,
        });
      })
      .catch((error) => {
        let data = error.response;
        if (data?.status === 422) {
          let validationError = data.data.error;
          dispatch({
            type: GET_REG_TEAM_PAY_AMOUNT_ERROR,
            errors: validationError,
          });
        }
      });
  };
};
