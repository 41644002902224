import React from "react";
import { connect, useSelector } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  getAllUserRoll,
  switchAccountData,
  switchAccountDataForNotification,
} from "../../../service/action/userAction";
import { useEffect } from "react";
import Toaster from "../../../Toaster/Toaster";

function NotificationAccounts(props) {
  const history = useHistory();
  const icon = {
    color: "#8A9EA5",
  };
  const divider = {
    borderColor: "rgba(255,255,255, 0.4)",
  };
  useEffect(() => {
    props.getUserRollHandler();
  }, []);

  const userRollClub = useSelector(
    (state) => state.getUserRollReducer.user.clubs
  );
  const userRoll = useSelector((state) => state.getUserRollReducer.user);
  const userRollOrg = useSelector(
    (state) => state.getUserRollReducer.user.organizer
  );

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex auth">
            <div className="row flex-grow">
              <div className="col-lg-5 col-10 mx-auto mt-24">
                {!!userRoll ? (
                  <>
                    <div className="auth-form-light text-left p-5">
                      <div className="row mx-0">
                        <div
                          className="col-2 pointer mt-1"
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          <i
                            className="fas fa-chevron-left mr-2   d-inline"
                            style={icon}
                          ></i>
                          <span className="mt-1 mb-4 text-white-50 text-center">
                            Back
                          </span>
                        </div>
                        <div className="col-8">
                          <h3 className="mt-1 mb-4 text-white-50 text-center">
                            Notifications
                          </h3>
                        </div>
                        <div className="col-2"></div>
                      </div>
                      {!!userRollOrg ? (
                        <>
                          {userRollOrg?.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              {userRollOrg.map((v, i) => {
                                return (
                                  <div
                                    key={v._id}
                                    className="py-3 border-bottom"
                                  >
                                    <Link
                                      to={{
                                        pathname: `/notification-accounts/${v._id}`,
                                        state: {
                                          id: v._id,
                                          type: 1,
                                        },
                                      }}
                                      className="d-flex justify-content-between text-decoration-none"
                                    >
                                      <div className="row mx-0 justify-content-between w-100">
                                        <div>
                                          <div className="text-white">
                                            <strong>{v.name}</strong>
                                          </div>
                                          <div>
                                            <small className="noti-type">
                                              Event Organizer
                                            </small>
                                          </div>
                                        </div>
                                        <div className="row mx-0">
                                          {parseInt(v?.notification) +
                                            parseInt(v?.dm_unread_msg) +
                                            parseInt(v?.invitation_unread_msg) >
                                            0 && (
                                            <div className="font-bold bg-white mr-3 border rounded-full w-7 h-7 flex justify-center items-center">
                                              {parseInt(v?.notification) +
                                                parseInt(v?.dm_unread_msg) +
                                                parseInt(
                                                  v?.invitation_unread_msg
                                                )}
                                            </div>
                                          )}
                                          <div>
                                            <i
                                              className="fas fa-chevron-right mr-2   d-inline"
                                              style={icon}
                                            ></i>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {userRollClub ? (
                        <>
                          {userRollClub.length === 0 ? (
                            <>
                              {/* <span className='noti-type'><Link to={"/createaccount"} className="d-flex justify-content-between text-decoration-none" >Create club account</Link></span> */}
                            </>
                          ) : (
                            <>
                              {userRollClub.map((v, i) => {
                                // {club == 1 ?
                                return (
                                  <div
                                    key={v._id}
                                    className="py-3 border-bottom"
                                  >
                                    <Link
                                      to={{
                                        pathname: `/notification-accounts/${v._id}`,
                                        state: {
                                          id: v._id,
                                          type: 4,
                                        },
                                      }}
                                      className="d-flex justify-content-between text-decoration-none"
                                    >
                                      <div className="row mx-0 justify-content-between w-100">
                                        <div>
                                          <div className="text-white">
                                            <strong>{v.name}</strong>
                                          </div>
                                          <div>
                                            <small className="noti-type">
                                              Club
                                            </small>
                                          </div>
                                        </div>
                                        <div className="row mx-0">
                                          {parseInt(v?.notification) +
                                            parseInt(v?.dm_unread_msg) +
                                            parseInt(v?.invitation_unread_msg) >
                                            0 && (
                                            <div className="font-bold bg-white mr-3 border rounded-full w-7 h-7 flex justify-center items-center">
                                              {parseInt(v?.notification) +
                                                parseInt(v?.dm_unread_msg) +
                                                parseInt(
                                                  v?.invitation_unread_msg
                                                )}
                                            </div>
                                          )}
                                          <div>
                                            <i
                                              className="fas fa-chevron-right mr-2   d-inline"
                                              style={icon}
                                            ></i>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <Toaster />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

// export default withRouter(SwitchAccount);
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getUserRollHandler: (user) => dispatch(getAllUserRoll(user)),
  switchAccountHandler: (user) =>
    dispatch(switchAccountDataForNotification(user)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotificationAccounts));
// 252
