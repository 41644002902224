import {SIGNATURE_LOADING, SIGNATURE_SUCCESS, SIGNATURE_ERROR, RESET_SIGNATURE} from "../../constants"

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {},
  message:{}


};

export default function signaturePostReducer(state = initialState, action) {
  switch (action.type) {

    case SIGNATURE_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case SIGNATURE_SUCCESS:
      return { ...state, error: false, loading: false, success: true, message:action.msg , url:action.url};

    case SIGNATURE_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: [] }

    case RESET_SIGNATURE:
        return {...state,error:false,loading:false,success:false,errors:[]}  

    default:
      return state;
  }
}


