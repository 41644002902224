import React, { useEffect } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import { toast } from "react-toastify";
import {
  createDiscountCodeAction,
  deleteDiscountCode,
  getAllDiscountCode,
} from "../../service/action/userAction";
import {
  RESET_CREATE_DISCOUNT_CODE,
  RESET_DELETE_EVENT,
  RESET_EDIT_DISCOUNT_CODE,
} from "../../service/constants";
import { useState } from "react";
import DeletePopUp from "../components/DeletePopUp/DeletePopUp";
import moment from "moment";
import Spinner from "../../Spinner/Spinner";
import Toaster from "../../Toaster/Toaster";
import BreadcrumbHistory from "../../BreadcumbHistory/BreadcrumbHistory";

const Discount_Code = (props) => {
  const notify = (msg) => toast(msg);
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const getAllDiscountCode = useSelector(
    (state) => state.getDiscountCodeReducer.data
  );
  const deleteEventMsg = useSelector(
    (state) => state.deleteEventReducer.message
  );

  useEffect(() => {
    props.getAllDiscountcodehandler();
  }, []);

  useEffect(() => {
    setLoading(props.discountCodeReducer?.loading);
  }, [props.discountCodeReducer]);

  useEffect(() => {
    if (props.submit?.success) {
      notify(deleteEventMsg.message);
      props.resetdeleteDiscountCodeReducer();
      props.getAllDiscountcodehandler();
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.createDiscountCode?.success) {
      notify("Code created successfully.");
      props.resetCreateDiscountCode();
    }
  }, [props.createDiscountCode]);
  useEffect(() => {
    if (props.editDiscountCodeReducer?.success) {
      notify("Code Edited successfully.");
      props.resetEditCode();
    }
  }, [props.editDiscountCodeReducer]);

  return (
    <>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          <div className="text-left ">
            <div className="create-team-head-space flex-wrap">
              <div className="d-flex align-items-center">
                <h5 className="dash-head mr-3 mb-0">Discount Code List</h5>
              </div>

              <div className="mt-2 mt-md-0 text-right">
                <Link to="/payment/discount-code/create-discount">
                  <button className="btn-save">+Add More</button>
                </Link>
              </div>
            </div>
            <div></div>
          </div>

          {loading ? (
            <div className="w-full h-[50vh] flex flex-col justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <>
              {getAllDiscountCode.length === 0 ? (
                <div className="container d-flex justify-content-center align-items-center">
                  <span className="noti-type">No Discount codes found </span>
                </div>
              ) : (
                <>
                  {/* desktop */}
                  <div className="mt-30 table-responsive d-none d-md-block">
                    <table className="table event-table register-table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Code Name</th>
                          <th>Amount</th>
                          <th>Discount Code</th>
                          <th>Expiry Date</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {getAllDiscountCode.map((v, i) => {
                          const {
                            _id,
                            code_name,
                            type,
                            amount,
                            code,
                            expire_date,
                          } = v;
                          return (
                            <tr key={_id} className="reg-row">
                              <td>{i + 1}</td>
                              <td>{code_name}</td>
                              <td>{`${type === 0 ? "$" : ""}${amount} ${
                                type === 1 ? "%" : ""
                              }`}</td>
                              <td>{code}</td>
                              <td>
                                {moment(expire_date).format("MM/DD/YYYY")}
                              </td>
                              <td>
                                <div className="dropdown event-drp">
                                  <i
                                    className="mdi mdi-dots-vertical"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  ></i>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink"
                                  >
                                    <Link
                                      className="dropdown-item"
                                      to={`/payment/discount-code/${_id}`}
                                    >
                                      <i className="mdi mdi-border-color"></i>
                                      Edit
                                    </Link>

                                    <Link
                                      className="dropdown-item"
                                      to="/payment/discount-code"
                                      onClick={() => {
                                        setIsDelete(true);
                                        setDeleteId(_id);
                                      }}
                                    >
                                      <i className="fas fa-trash-alt"></i>{" "}
                                      Delete
                                    </Link>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  {/* mobile */}

                  <div className="mt-30 d-md-none">
                    <div className="mt-4">
                      {getAllDiscountCode.map((v, i) => {
                        const {
                          _id,
                          code_name,
                          type,
                          amount,
                          code,
                          expire_date,
                        } = v;
                        return (
                          <div
                            className="d-flex justify-content-between reg-details"
                            key={_id}
                          >
                            <div className="d-flex">
                              <div className="reg-content">
                                <div className="fw-m">{code_name}</div>
                                <div className=" text-gray mt-2">
                                  Amount:{" "}
                                  {`${type === 0 ? "$" : ""}${amount} ${
                                    type === 1 ? "%" : ""
                                  }`}
                                </div>
                                <div className=" text-gray mt-2">
                                  Code: {code}
                                </div>
                                <div className=" text-gray mt-2">
                                  Expiry Date:{" "}
                                  {moment(expire_date).format("MM/DD/YYYY")}
                                </div>
                              </div>
                            </div>
                            <div className="dropdown event-drp">
                              <i
                                className="mdi mdi-dots-vertical"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              ></i>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <Link
                                  className="dropdown-item"
                                  to={`/payment/discount-code/${_id}`}
                                >
                                  <i className="mdi mdi-border-color"></i>
                                  Edit
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/payment/discount-code"
                                  onClick={() => {
                                    setIsDelete(true);
                                    setDeleteId(_id);
                                  }}
                                >
                                  <i className="fas fa-trash-alt"></i> Delete
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => props.deleteDiscountCodeHandler(deleteId)}
      />
      <Toaster />
    </>
  );
};

// export default withRouter(Discountcode);

const mapStateToProps = (state) => ({
  submit: state.deleteEventReducer,
  discountCodeReducer: state.getDiscountCodeReducer,
  createDiscountCode: state.createDiscountCodeReducer,
  editDiscountCodeReducer: state.editDiscountCodeReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAllDiscountcodehandler: (user) => dispatch(getAllDiscountCode(user)),
  deleteDiscountCodeHandler: (user) => dispatch(deleteDiscountCode(user)),
  resetdeleteDiscountCodeReducer: () => dispatch({ type: RESET_DELETE_EVENT }),

  resetCreateDiscountCode: () => dispatch({ type: RESET_CREATE_DISCOUNT_CODE }),
  resetEditCode: () => dispatch({ type: RESET_EDIT_DISCOUNT_CODE }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Discount_Code));
