import classNames from "classnames";
import React from "react";
import { getNotificationsCountAction } from "../../../service/action/userAction";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

function ChatUserList(props) {
  const { id, currentUser, senderId, handleChangeUser } = props;
  const location = useLocation();
  const dispatch = useDispatch();

  const getNotifiCationCount = () => {
    dispatch(
      getNotificationsCountAction(location.state.id, location.state.type)
    );
  };

  const getLastMessage = (type, msg) => {
    if (type === "text") {
      return msg;
    } else if (type === "image" || type === "file") {
      return "Photo";
    }
  };

  return (
    <div
      className={classNames("chat_list pay-row, pointer", {
        active_chat: currentUser.id === id,
      })}
      onClick={() => {
        handleChangeUser(
          currentUser.id,
          1,
          currentUser.lastMessage,
          currentUser.name
        );

        getNotifiCationCount();
      }}
    >
      <div className="chat_people d-flex justify-content-between">
        <div className="d-flex justify-content-start align-items-center">
          <div
            className={classNames("dashboard-img", {
              onlineUser: !!currentUser.is_online,
            })}
            style={{
              width: "45px",
              height: "45px",
            }}
          >
            <img
              style={{
                width: "100px",
                height: "40px",
              }}
              src={`${
                currentUser.profile_image.length !== 0
                  ? currentUser.profile_image
                  : "./images/faces/face16.jpg"
              }`}
            />
          </div>
          <div className="ml-12">
            <strong className="fs-15">{currentUser.name}</strong>
            <span
              className="noti-type overflow-hidden pe-3"
              style={{
                textOverflow: "ellipsis",
                fontSize: "15px",
              }}
            >
              {currentUser?.lastMessage?.sender === senderId && (
                <span
                  style={{
                    marginRight: "5px",
                    color:
                      currentUser?.lastMessage?.readAt !== "NA"
                        ? "#143D4A"
                        : "inherit ",
                  }}
                >
                  <i
                    className={`mdi ${
                      currentUser?.lastMessage?.readAt !== "NA"
                        ? "mdi-check-all"
                        : "mdi-check"
                    }`}
                  ></i>
                </span>
              )}
              {getLastMessage(
                currentUser?.lastMessage?.message_type,
                currentUser?.lastMessage?.message
              )}
            </span>
          </div>
        </div>
        <div className="justify-content-end">
          {currentUser.unreadMessage === 0 ? (
            ""
          ) : (
            <span className="badge rounded-pill bg-danger text-light">
              {currentUser.unreadMessage}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatUserList;
