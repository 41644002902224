import { GET_ALL_LEAGUE_ERROR, GET_ALL_LEAGUE_LOADING, GET_ALL_LEAGUE_SUCCESS } from "../../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function getLeagueReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_LEAGUE_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case GET_ALL_LEAGUE_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };
     
    case GET_ALL_LEAGUE_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}


