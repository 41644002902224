import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, getDate } from "date-fns";
import $, { event } from "jquery";
import { connect, useSelector } from "react-redux";
import {
  deleteLeague,
  getAllClubEvent,
  getClubOrganizerEvent,
  getLeague,
} from "../../../service/action/Club/clubAction";
import {
  bookmark,
  clonEvent,
  deleteEvent,
} from "../../../service/action/userAction";
import {
  RESET_BOOKMARK,
  RESET_CLON_EVENT,
  RESET_CREATE_EVENT,
  RESET_DELETE_EVENT,
  RESET_EDIT_EVENT,
  RESET_LEAGUE,
} from "../../../service/constants";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import Spinner from "../../../Spinner/Spinner";
import Moment from "react-moment";
import moment from "moment";
import DeletePopUp from "../../components/DeletePopUp/DeletePopUp";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";
import EventList from "./components/EventList";
import MobileEventList from "./components/MobileEventList";

const Events = (props) => {
  let [loading, setLoading] = useState(true);
  const [filterEvent, setFilterEvent] = useState(0);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    setShowSearch(true);
  }, []);

  const getdata = props.getEventHandler;
  const getClubEvent = useSelector(
    (state) => state.getAllClubEventReducer.data
  );

  const allEvents = props.getClubOrgEventReducer?.data?.data;
  const bookMarkedEvents = allEvents?.filter((event) => event?.is_bookmarked)
    ? allEvents?.filter((event) => event?.is_bookmarked)
    : [];

  useEffect(() => {
    setLoading(true);
    props.getClubOrganizerEvent();
  }, []);

  const deleteEventMsg = useSelector(
    (state) => state.deleteEventReducer.message
  );
  const clonEventMsg = useSelector((state) => state.clonEventReducer.message);
  const bookmarkMsg = useSelector((state) => state.bookmarkReducer.message);
  const successMsg = useSelector((state) => state.editEventReducer.message);
  const eventCreateMsg = useSelector(
    (state) => state.createEventReducer.message
  );
  const league = useSelector((state) => state.getLeagueReducer.user);

  useEffect(() => {
    setLoading(true);
    if (!props.event.success) {
      getdata({ id: 0 });
    }
    props.getLeagueHandler();
  }, []);

  const bookmark =
    getClubEvent?.length !== 0
      ? getClubEvent.filter((e) => e.is_bookmarked === true)
      : [];
  const notify = (msg) => toast(msg);

  useEffect(() => {
    setLoading(true);
    if (props.event?.success && props.league.success) {
      // setLoading(false);
    }
  }, [props.event, props.league]);

  useEffect(() => {
    if (props.submit?.success) {
      notify(deleteEventMsg.apiMessage);
      props.resetdeleteEventReducer();
      props.getEventHandler({ id: 0 });
      setLoading(true);
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.submit_1?.success) {
      notify(clonEventMsg.message);
      props.resetclonEventReducer();
      props.getEventHandler({ id: 0 });
      setLoading(true);
    }
  }, [props.submit_1]);

  useEffect(() => {
    if (props.bookmark?.success) {
      notify(bookmarkMsg.message);
      props.resetbookmarkReducer();
      props.getEventHandler({ id: 0 });
      setLoading(true);
    }
  }, [props.bookmark]);

  useEffect(() => {
    if (props.EditMsg?.success) {
      notify(successMsg.message);
      props.resetEditEventReducer();
      props.getEventHandler({ id: 0 });
      setLoading(true);
    }
  }, [props.EditMsg]);

  useEffect(() => {
    if (props.CreateEvent?.success) {
      notify(eventCreateMsg.message);
      props.resetCreateEventReducer();
      props.getEventHandler({ id: 0 });
      setLoading(true);
    }
  }, [props.CreateEvent]);

  const [startDate, setStartDate] = useState(new Date());
  const [weekStart, setWeekStartDate] = useState(new Date());
  const [monthEvent, setMonthEvent] = useState([]);
  const [weekEvent, setWeekEvent] = useState([]);
  const [dayEvent, setDayEvent] = useState([]);
  const [state, setState] = useState({
    search: "",
  });
  const Searchval = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    UpdateArray(e.target.value);
  };

  const [openEvents, setOpenEvents] = useState([]);

  useEffect(() => {
    setLoading(true);
    const events = getOpenEvents();
    if (events) {
      setOpenEvents(events);
      setLoading(false);
    }
  }, [allEvents]);

  const UpdateArray = (search) => {
    var query = search;
    $(".register-table .filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".reg-row").addClass("d-none");
      } else {
        $(v).parents(".reg-row").removeClass("d-none");
      }
    });

    $(".bookmark-table .filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".bookmark-row").addClass("d-none");
      } else {
        $(v).parents(".bookmark-row").removeClass("d-none");
      }
    });
  };

  useEffect(() => {
    const filterEvent = getClubEvent.filter((v) => {
      return (
        moment(v.event_start_date).format("MMMM YYYY") ===
        moment().format("MMMM YYYY")
      );
    });
    setMonthEvent(filterEvent);
  }, [getClubEvent]);

  const dateHandler = (date, type) => {
    const dateList = monthEvent.filter((e) => {
      const startDate = e.event_start_date;
      const endDate = e.event_end_date;
      return (
        moment(startDate) <= moment(date) &&
        moment(date) <= moment(endDate).add(1, "days")
      );
    });
    setDayEvent([...dateList]);

    if (type === "month") {
      setStartDate(date);
      const filterEvent = getClubEvent.filter((v) => {
        return (
          moment(v.event_start_date).format("MMMM YYYY") ===
          moment(date).format("MMMM YYYY")
        );
      });
      setMonthEvent(filterEvent);
    } else if (type === "week") {
      setWeekStartDate(date);
      const filterEvent = getClubEvent.filter((v) => {
        return (
          moment(v.event_start_date).format("DD MMMM YYYY") ===
          moment(date).format("DD MMMM YYYY")
        );
      });
      setWeekEvent(filterEvent);
    }
  };

  const coustomDate = (day, date) => {
    const dateList = [];
    monthEvent.map((e) => {
      const startDate = e.event_start_date;
      const endDate = e.event_end_date;

      if (
        moment(startDate).subtract(1, "days") <= moment(date) &&
        moment(date) <= moment(endDate)
      ) {
        dateList.push(moment(date).format("MM/DD/YYYY"));
      }
    });
    return (
      <span className="position-relative">
        <div>{getDate(date)}</div>
        {dateList.includes(moment(date).format("MM/DD/YYYY")) && (
          <div
            className="position-absolute"
            style={{
              top: "18px",
              left: "-5px",
            }}
          >
            <i
              className="mdi mdi-circle text-black"
              style={{ fontSize: "10px" }}
            ></i>
          </div>
        )}
      </span>
    );
  };

  // new Logic
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [search, setSearch] = useState("");

  const location = useLocation();
  const [statusText, setStatusText] = useState("Open Events");

  // get Open Events

  const getOpenEventsHandelar = () => {
    setStatusText("Open Events");
    const openEvents = getOpenEvents();
    setFilteredEvents(openEvents);
  };

  const getOpenEvents = () => {
    const openEvents = allEvents?.filter((ev) => {
      const today = new Date();
      const evStartDate = new Date(ev.registration_start_date);
      const evEndDate = new Date(ev.registration_end_date);
      if (evStartDate <= today && evEndDate >= today) {
        return ev;
      } else {
        return null;
      }
    });
    return openEvents;
  };

  const getPastEvents = () => {
    const openEvents = allEvents?.filter((ev) => {
      const today = new Date();
      const evStartDate = new Date(ev.registration_start_date);
      const evEndDate = new Date(ev.registration_end_date);
      if (evStartDate < today && evEndDate < today) {
        return ev;
      } else {
        return null;
      }
    });
    setFilteredEvents(openEvents);
    setStatusText("Past Events");
  };

  useEffect(() => {
    setLoading(true);
    const events = getOpenEvents();
    if (events) {
      setFilteredEvents(events);
      setLoading(false);
    }
  }, [allEvents]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head">
              <div className="d-md-flex align-items-center d-none">
                <h5 className="dash-head mr-3 mb-2">Events</h5>

                <div className="team-details d-flex justify-content-end ml-auto">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <Link
                        className="nav-link border-bottom-0 py-0"
                        id="register"
                        data-toggle="pill"
                        to={"/events/find-event"}
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="true"
                      >
                        <i className="mdi mdi-plus ml-15 fs-18"></i>
                      </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link border-bottom-0 py-0"
                        id="calendar"
                        data-toggle="pill"
                        href="#cal-tab"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        <i className="mdi mdi-calendar-blank ml-15 fs-18"></i>
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active border-bottom-0 py-0"
                        id="grid"
                        data-toggle="pill"
                        href="#grid-tab"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        <i className="mdi mdi-format-list-bulleted ml-15 fs-18"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-content" id="pills-dateContent">
            <div
              className="tab-pane fade"
              id="cal-tab"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="team-details">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="months"
                      data-toggle="pill"
                      href="#months-tab"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Months
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="week"
                      data-toggle="pill"
                      href="#week-tab"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Weeks
                    </a>
                  </li>
                </ul>
              </div>

              <div className="tab-content" id="pills-dateContent">
                <div
                  className="tab-pane fade show active"
                  id="months-tab"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div
                    className="mt-42 event-datepicker"
                    style={{ overflow: "auto" }}
                  >
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => dateHandler(date, "month")}
                      className="form-control"
                      inline
                      id="dates"
                      onMonthChange={(date) => dateHandler(date, "month")}
                      renderDayContents={coustomDate}
                    />
                  </div>
                  <hr />

                  {dayEvent.length !== 0 && (
                    <div>
                      <div className="text-left payment-head notification-head">
                        <h6 className="payment">
                          {moment(startDate).format("MM/DD/YYYY")} Events
                        </h6>
                      </div>
                      <div>
                        {
                          <div className="mt-3">
                            {dayEvent.map((v, i) => {
                              return (
                                <div className="d-flex" key={i}>
                                  <div className="dashboard-img mr-3">
                                    <img
                                      src={
                                        v.event_image !== ""
                                          ? v.event_image
                                          : "./images/pch-images/pch.svg"
                                      }
                                    />
                                  </div>
                                  <div className="reg-content">
                                    <Link
                                      className="text-decoration-none text-black fw-m"
                                      to={
                                        v.type === "club"
                                          ? `/event-details/${v._id}`
                                          : `/events/${v._id}`
                                      }
                                    >
                                      {v.event_name}
                                    </Link>
                                    {v.is_bookmarked === true ? (
                                      <span className="ml-2">
                                        <i
                                          className="fas fa-flag m-1"
                                          style={{ color: "#DB1F26" }}
                                        ></i>
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    <span className="d-block text-gray mt-1">
                                      Event Date:
                                      <span className="py-1 ml-2">
                                        <Moment
                                          date={new Date(v.event_start_date)}
                                          format="MM/DD/YYYY"
                                        />
                                      </span>
                                    </span>
                                    <span className="d-block text-gray mt-1">
                                      Registration Status:
                                      <span className="py-1 ml-2">
                                        {moment(v.registration_end_date) >=
                                        moment()
                                          ? "Open"
                                          : "Closed"}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        }
                      </div>
                    </div>
                  )}

                  <div>
                    <div className="text-left payment-head notification-head">
                      <h6 className="payment">This Month </h6>
                      <Link
                        className="see-all text-decoration-none"
                        to={"/events"}
                      >
                        See all
                      </Link>
                    </div>
                    <div>
                      {monthEvent.length === 0 ? (
                        <div className="text-center text-gray mt-3">
                          No events are found
                        </div>
                      ) : (
                        <div className="mt-3">
                          {monthEvent.map((v, i) => {
                            const { _id, is_bookmarked, event_end_date } = v;
                            return (
                              <div
                                className="d-flex justify-content-between"
                                key={i}
                              >
                                <div className="d-flex">
                                  <div className="dashboard-img mr-3">
                                    <img
                                      src={
                                        v.event_image !== ""
                                          ? v.event_image
                                          : "./images/pch-images/pch.svg"
                                      }
                                    />
                                  </div>
                                  <div className="reg-content">
                                    <Link
                                      className="text-decoration-none text-black fw-m"
                                      to={
                                        v.type === "club"
                                          ? `/event-details/${v._id}`
                                          : `/events/${v._id}`
                                      }
                                    >
                                      {v.event_name}
                                    </Link>
                                    {v.is_bookmarked === true ? (
                                      <span className="ml-2">
                                        <i
                                          className="fas fa-flag m-1"
                                          style={{ color: "#DB1F26" }}
                                        ></i>
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    <span className="d-block text-gray mt-1">
                                      Event Date:
                                      <span className="py-1 ml-2">
                                        <Moment
                                          date={new Date(v.event_start_date)}
                                          format="MM/DD/YYYY"
                                        />
                                      </span>
                                    </span>
                                    <span className="d-block text-gray mt-1">
                                      Registration Status:
                                      <span className="py-1 ml-2">
                                        {moment(v.registration_end_date) >=
                                        moment()
                                          ? "Open"
                                          : "Closed"}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <div className="dropdown event-drp-club">
                                    <i
                                      className="mdi mdi-dots-vertical"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      id="dropdownMenuLink"
                                    ></i>
                                    {is_bookmarked === true ? (
                                      <>
                                        <span className="badge badge-light"></span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuLink"
                                    >
                                      <Link
                                        className="dropdown-item"
                                        to="/events/find-event"
                                        onClick={() =>
                                          props.bookmarkHandler({
                                            bookmarkType: "EVENT",
                                            id: _id,
                                          })
                                        }
                                      >
                                        <i className="mdi mdi-bookmark-outline"></i>
                                        {is_bookmarked === true
                                          ? "Remove bookmark"
                                          : "Add Bookmark"}
                                      </Link>
                                      {moment(event_end_date) > moment() && (
                                        <Link
                                          className="dropdown-item"
                                          to={{
                                            pathname: `/events/${_id}/registernow`,
                                            state: {
                                              inviteType: 0,
                                            },
                                          }}
                                        >
                                          <i className="mdi mdi-account-multiple"></i>{" "}
                                          Register Teams
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="week-tab"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div
                    className="mt-42 event-datepicker"
                    style={{ overflow: "auto" }}
                  >
                    <DatePicker
                      selected={weekStart}
                      onChange={(date) => dateHandler(date, "week")}
                      minDate={new Date()}
                      maxDate={addDays(new Date(), 6)}
                      inline
                      placeholderText="Select a date before 5 days in the future"
                      id="week"
                      // renderDayContents={coustomDate}
                    />
                  </div>
                  <hr />
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade show active"
              id="grid-tab"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="team-details">
                {/* <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="events"
                      data-toggle="pill"
                      href="#eventTab"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Events
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="leagues"
                      data-toggle="pill"
                      href="#leagueTab"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Leagues
                    </a>
                  </li>
                </ul> */}

                <div className="tab-content" id="pills-tabContent">
                  {/* events */}
                  <div
                    className="tab-pane fade show active"
                    id="eventTab"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="grid-tab"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="team-details">
                        <ul
                          className="nav nav-pills mb-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li
                            className="nav-item"
                            role="presentation"
                            onClick={() => {
                              setShowSearch(true);
                            }}
                          >
                            <a
                              className="nav-link active"
                              id="registered"
                              data-toggle="pill"
                              href="#registered-tab"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              Find Event
                            </a>
                          </li>
                          <li
                            className="nav-item"
                            role="presentation"
                            onClick={() => {
                              setShowSearch(false);
                            }}
                          >
                            <a
                              className="nav-link"
                              id="registered"
                              data-toggle="pill"
                              href="#main-tab"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="false"
                            >
                              Main Event
                            </a>
                          </li>
                          <li
                            className="nav-item"
                            role="presentation"
                            onClick={() => {
                              props.getClubOrganizerEvent();
                              setShowSearch(false);
                            }}
                          >
                            <a
                              className="nav-link"
                              id="bookmarked"
                              data-toggle="pill"
                              href="#bookmarked-tab"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              Bookmarked
                            </a>
                          </li>
                        </ul>

                        {/* Search Start */}
                        {showSearch && (
                          <div className="flex items-center">
                            <div className="search-section">
                              <i className="mdi mdi-magnify search-ico"></i>
                              <input
                                type="text"
                                className="form-control"
                                id="search"
                                name="search"
                                value={state.search}
                                placeholder="Search"
                                onChange={Searchval}
                                autoComplete="off"
                              />
                            </div>

                            <div className="dropdown event-drp mt-4 mt-md-0">
                              <i
                                className="mdi mdi-filter-outline filter-ico text-2xl"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              ></i>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenu2"
                              >
                                <Link
                                  className="dropdown-item"
                                  to="/events"
                                  onClick={getOpenEventsHandelar}
                                >
                                  Open Events
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to="/events"
                                  onClick={getPastEvents}
                                >
                                  Past Events
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Search End */}
                        <div className="tab-content" id="pills-tabContent">
                          <div className="d-flex justify-content-center">
                            {loading && <Spinner />}
                          </div>
                          <div
                            className="tab-pane fade show active"
                            id="registered-tab"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                          >
                            {/* desktop section */}
                            {!loading && (
                              <div className="mt-30 table-responsive d-none d-md-block">
                                <table className="table event-table register-table">
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Name</th>
                                      <th>Event Date</th>
                                      <th>Registration Status</th>
                                      <th>Event Status</th>
                                      <th>Max Teams</th>
                                      <th></th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {filteredEvents.length == 0 && !loading ? (
                                      <div className="container d-flex justify-content-center align-items-center">
                                        <span className="noti-type">
                                          No events found
                                        </span>
                                      </div>
                                    ) : (
                                      filteredEvents?.map((v, i) => {
                                        return (
                                          <EventList
                                            v={v}
                                            i={i}
                                            getClubOrganizerEvent={
                                              props.getClubOrganizerEvent
                                            }
                                            bookmarkHandler={
                                              props.bookmarkHandler
                                            }
                                            filteredEvents={filteredEvents}
                                            loading={loading}
                                          />
                                        );
                                      })
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            )}

                            {/* mobile view  */}

                            <div className="mt-30 d-md-none">
                              <div className="mt-4">
                                {!loading &&
                                  filteredEvents &&
                                  filteredEvents?.map((v, i) => {
                                    return (
                                      <MobileEventList
                                        v={v}
                                        i={i}
                                        bookmarkHandler={props.bookmarkHandler}
                                      />
                                    );
                                  })}
                              </div>
                            </div>
                          </div>

                          {/* Main Start */}
                          <div
                            className="tab-pane fade"
                            id="main-tab"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                          >
                            {/* desktop section */}
                            {!loading ? (
                              <>
                                {getClubEvent && getClubEvent.length === 0 ? (
                                  <>
                                    <div className="container d-flex justify-content-center align-items-center">
                                      <span className="noti-type">
                                        No main events found
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="mt-30 table-responsive d-none d-md-block">
                                      <table className="table event-table bookmark-table">
                                        <thead>
                                          <tr>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {getClubEvent?.map((v, i) => {
                                            return (
                                              <tr
                                                key={i}
                                                className="bookmark-row"
                                              >
                                                <td>
                                                  <div className="d-flex align-items-center">
                                                    <div className="dashboard-img">
                                                      <img
                                                        src={
                                                          v.event_image !== ""
                                                            ? v.event_image
                                                            : "./images/pch-images/pch.svg"
                                                        }
                                                      />
                                                    </div>
                                                    <Link
                                                      className="ml-12 text-decoration-none heading"
                                                      to={
                                                        v.type === "club"
                                                          ? `/event-details/${v._id}`
                                                          : `/events/${v._id}`
                                                      }
                                                    >
                                                      <span className="bookmark-table filter-string">
                                                        {v.event_name}
                                                      </span>
                                                    </Link>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="py-1">
                                                    <Moment
                                                      date={
                                                        new Date(
                                                          v.event_start_date
                                                        )
                                                      }
                                                      format="MM/DD/YYYY"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="dropdown event-drp">
                                                    <i
                                                      className="mdi mdi-dots-vertical"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    ></i>
                                                    <div
                                                      className="dropdown-menu"
                                                      aria-labelledby="dropdownMenuLink"
                                                    >
                                                      <Link
                                                        className="dropdown-item"
                                                        to="/events"
                                                        onClick={() => {
                                                          props.bookmarkHandler(
                                                            {
                                                              bookmarkType:
                                                                "EVENT",
                                                              id: v._id,
                                                            }
                                                          );
                                                          props.getClubOrganizerEvent();
                                                        }}
                                                      >
                                                        <i className="mdi mdi-bookmark-outline"></i>
                                                        Remove Bookmark
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </>
                                )}
                                {/* mobile view  */}
                                <div className="mt-30 d-md-none">
                                  {!loading &&
                                    getClubEvent.map((v, i) => {
                                      return (
                                        <div
                                          className="d-flex justify-content-between reg-details bookmark-row"
                                          key={i}
                                        >
                                          <div className="d-flex">
                                            <div className="dashboard-img mr-3">
                                              <img
                                                src={
                                                  v.event_image !== ""
                                                    ? v.event_image
                                                    : "./images/pch-images/pch.svg"
                                                }
                                              />
                                            </div>
                                            <div className="reg-content">
                                              <Link
                                                className="text-decoration-none text-blue fw-m bookmark-table filter-stringnotif"
                                                to="/event-details"
                                              >
                                                {v.event_name}
                                              </Link>
                                              <span className="d-block text-gray mt-2">
                                                Starts:
                                                <div className="py-1">
                                                  <Moment
                                                    date={
                                                      new Date(
                                                        v.event_start_date
                                                      )
                                                    }
                                                    format="MM/DD/YYYY"
                                                  />
                                                </div>
                                              </span>
                                            </div>
                                          </div>
                                          <div className="dropdown event-drp">
                                            <i
                                              className="mdi mdi-dots-vertical"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            ></i>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuLink"
                                            >
                                              <Link
                                                className="dropdown-item"
                                                to="/events"
                                                onClick={() =>
                                                  props.bookmarkHandler({
                                                    bookmarkType: "EVENT",
                                                    id: v._id,
                                                  })
                                                }
                                              >
                                                <i className="mdi mdi-bookmark-outline"></i>
                                                Remove Bookmark
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </>
                            ) : null}
                          </div>

                          {/* Main End */}

                          <div
                            className="tab-pane fade"
                            id="bookmarked-tab"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                          >
                            {/* desktop section */}
                            {!loading ? (
                              <>
                                {bookMarkedEvents &&
                                bookMarkedEvents.length === 0 ? (
                                  <>
                                    <div className="container d-flex justify-content-center align-items-center">
                                      <span className="noti-type">
                                        No bookmarks found
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="mt-30 table-responsive d-none d-md-block">
                                      <table className="table event-table bookmark-table">
                                        <thead>
                                          <tr>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {bookMarkedEvents?.map((v, i) => {
                                            return (
                                              <tr
                                                key={i}
                                                className="bookmark-row"
                                              >
                                                <td>
                                                  <div className="d-flex align-items-center">
                                                    <div className="dashboard-img">
                                                      <img
                                                        src={
                                                          v.event_image !== ""
                                                            ? v.event_image
                                                            : "./images/pch-images/pch.svg"
                                                        }
                                                      />
                                                    </div>
                                                    <Link
                                                      className="ml-12 text-decoration-none heading"
                                                      to={
                                                        v.type === "club"
                                                          ? `/event-details/${v._id}`
                                                          : `/events/${v._id}`
                                                      }
                                                    >
                                                      <span className="bookmark-table filter-string">
                                                        {v.event_name}
                                                      </span>
                                                    </Link>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="py-1">
                                                    <Moment
                                                      date={
                                                        new Date(
                                                          v.event_start_date
                                                        )
                                                      }
                                                      format="MM/DD/YYYY"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="dropdown event-drp">
                                                    <i
                                                      className="mdi mdi-dots-vertical"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    ></i>
                                                    <div
                                                      className="dropdown-menu"
                                                      aria-labelledby="dropdownMenuLink"
                                                    >
                                                      <Link
                                                        className="dropdown-item"
                                                        to="/events"
                                                        onClick={() => {
                                                          props.bookmarkHandler(
                                                            {
                                                              bookmarkType:
                                                                "EVENT",
                                                              id: v._id,
                                                            }
                                                          );
                                                          props.getClubOrganizerEvent();
                                                        }}
                                                      >
                                                        <i className="mdi mdi-bookmark-outline"></i>
                                                        Remove Bookmark
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </>
                                )}
                                {/* mobile view  */}
                                <div className="mt-30 d-md-none">
                                  {!loading &&
                                    bookmark.map((v, i) => {
                                      return (
                                        <div
                                          className="d-flex justify-content-between reg-details bookmark-row"
                                          key={i}
                                        >
                                          <div className="d-flex">
                                            <div className="dashboard-img mr-3">
                                              <img
                                                src={
                                                  v.event_image !== ""
                                                    ? v.event_image
                                                    : "./images/pch-images/pch.svg"
                                                }
                                              />
                                            </div>
                                            <div className="reg-content">
                                              <Link
                                                className="text-decoration-none text-blue fw-m bookmark-table filter-stringnotif"
                                                to="/event-details"
                                              >
                                                {v.event_name}
                                              </Link>
                                              <span className="d-block text-gray mt-2">
                                                Starts:
                                                <div className="py-1">
                                                  <Moment
                                                    date={
                                                      new Date(
                                                        v.event_start_date
                                                      )
                                                    }
                                                    format="MM/DD/YYYY"
                                                  />
                                                </div>
                                              </span>
                                            </div>
                                          </div>
                                          <div className="dropdown event-drp">
                                            <i
                                              className="mdi mdi-dots-vertical"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            ></i>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuLink"
                                            >
                                              <Link
                                                className="dropdown-item"
                                                to="/events"
                                                onClick={() =>
                                                  props.bookmarkHandler({
                                                    bookmarkType: "EVENT",
                                                    id: v._id,
                                                  })
                                                }
                                              >
                                                <i className="mdi mdi-bookmark-outline"></i>
                                                Remove Bookmark
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* leagues */}
                  <div
                    className="tab-pane fade"
                    id="leagueTab"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    {!league || (!!league && league.length === 0) ? (
                      <>
                        <div className="container d-flex justify-content-center align-items-center">
                          <span className="noti-type">No leagues found</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="table-responsive">
                          <table className="table green-table mt-24">
                            <thead>
                              <tr>
                                <th>League Name</th>
                                <th></th>
                              </tr>
                            </thead>
                            {/* Season data callS */}
                            <tbody>
                              {league?.map((v, i) => {
                                // alert("Inside")
                                return (
                                  <tr className="pay-row" key={i}>
                                    <td>
                                      <span className="filter-string">
                                        {v.league_name}
                                      </span>
                                    </td>
                                    <td>
                                      <div className="dropdown event-drp">
                                        <i
                                          className="mdi mdi-dots-vertical"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        ></i>
                                        <div
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuLink"
                                        >
                                          <Link
                                            className="dropdown-item"
                                            to={`/leagues/${v._id}`}
                                            onClick={localStorage.setItem(
                                              "league_id",
                                              v._id
                                            )}
                                          >
                                            <i className="mdi mdi-border-color"></i>
                                            Edit
                                          </Link>
                                          <Link
                                            className="dropdown-item"
                                            to="/events"
                                            onClick={() => {
                                              setIsDelete(true);
                                              setDeleteId(v._id);
                                            }}
                                          >
                                            <i className="fas fa-trash-alt"></i>{" "}
                                            Delete
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => props.deleteLeague(deleteId)}
      />
    </React.Fragment>
  );
};

// export default Events;
const mapStateToProps = (state) => ({
  submit: state.deleteEventReducer,
  submit_1: state.clonEventReducer,
  bookmark: state.bookmarkReducer,
  EditMsg: state.editEventReducer,
  event: state.getAllClubEventReducer,
  CreateEvent: state.createEventReducer,
  league: state.getLeagueReducer,
  getClubOrgEventReducer: state.getClubOrgEventReducer,
});

// API calls
const mapDispatchToProps = (dispatch) => ({
  getEventHandler: (user) => dispatch(getAllClubEvent(user)),
  deleteEventHandler: (user) => dispatch(deleteEvent(user)),
  clonEventHandler: (user) => dispatch(clonEvent(user)),
  bookmarkHandler: (user) => dispatch(bookmark(user)),
  resetdeleteEventReducer: () => dispatch({ type: RESET_DELETE_EVENT }),
  resetclonEventReducer: () => dispatch({ type: RESET_CLON_EVENT }),
  resetbookmarkReducer: () => dispatch({ type: RESET_BOOKMARK }),
  resetEditEventReducer: () => dispatch({ type: RESET_EDIT_EVENT }),
  resetCreateEventReducer: () => dispatch({ type: RESET_CREATE_EVENT }),
  getLeagueHandler: (user) => dispatch(getLeague(user)),
  resetCreateLeague: () => dispatch({ type: RESET_LEAGUE }),
  deleteLeague: (user) => dispatch(deleteLeague(user)),
  resetdeletePaymentCodeReducer: () => dispatch({ type: RESET_DELETE_EVENT }),
  getClubOrganizerEvent: () => {
    dispatch(getClubOrganizerEvent());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);

// 554
