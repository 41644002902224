import React, { useState } from "react";
import logo from "../../assets/images/logo.svg";

import {useHistory} from "react-router-dom";
import OtpInput from "react-otp-input";

export default function Otp() {

  let history = useHistory();

  const loginSuccess = () => {
    history.push('/superadmin/newPassword');
  }

  const [otp, setOtp] = useState("");

  const handleChange = (otp) => {
        setOtp(otp);
      }

    return (
      <div className="signin">
            <div className="signin-logo">
              
              <img src={logo} className="img-fluid logo-img" alt="logo" />
            </div>

            {/* Login form */}
            <div className="login-form">
            <h1 className="heading text-white text-center">Enter OTP</h1>
            <form className="mt-4">
            <p className="text-white text-center mt-4 mb-0">Please enter the 6 Digit code sent to <span className="h5 text-secondary"> yourname@company.com</span></p>            
              <div className="form-group mt-5">
              <OtpInput value={otp} onChange={handleChange} numInputs={6} isInputNum= {true} inputStyle = {"form-control w-100 mx-2"} />
              </div>                
              <div className="text-center">
                <button type="submit" className="btn btn-secondary" onClick={loginSuccess}>Send Code</button>
              </div>
            </form>
            </div>
      </div> 
    );
}