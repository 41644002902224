import { ADD_TEAM_PLAYER_ERROR, ADD_TEAM_PLAYER_LOADING, ADD_TEAM_PLAYER_SUCCESS, RESET_ADD_TEAM_PLAYER } from "../../../constants";

let initialState = {
 error: false,
 success: false,
 loading: false,
 errors: [],
 data: []
}

export default function addTeamPlayerReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TEAM_PLAYER_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, error: false, loading: true };

    case ADD_TEAM_PLAYER_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };

    case ADD_TEAM_PLAYER_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors, success: false };

    case RESET_ADD_TEAM_PLAYER:
      // alert(" reducer Loading")
      return { ...state, error: false, loading: false, success: false };

    default:
      return state;
  }
}