import { GET_STATE_ERROR, GET_STATE_LOADING, GET_STATE_SUCCESS } from "../../constants"

let initialState={
    error:false,
    loading:false,
    errors:[],
    success:false,
    states:[]
}

export default function getStateReducer(state=initialState,action){
    switch(action.type){
        case GET_STATE_LOADING:
            return {...state,error:false,loading:true,success:false}
        case GET_STATE_SUCCESS:
            return {...state,error:false,loading:false,success:true,states:action.payload}
        case GET_STATE_ERROR:
            return {...state,error:true,errors:[],success:false,loading:false}
        default:
            return state

    }
}