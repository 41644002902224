import React from "react";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import Toaster from "../../Toaster/Toaster";
import { toast } from "react-toastify";
import { connect, useSelector } from "react-redux";
import { useState } from "react";
import {
  changeStaffPermission,
  getStaffPermissionDetails,
} from "../../service/action/userAction";
import { useEffect } from "react";
import { RESET_SET_STAFF_PERMISSION } from "../../service/constants";

function StaffPermission(props) {
  let history = useHistory();

  const { id } = useParams();

  const [details, setDetails] = useState(null);
  useEffect(() => {
    props.getStaffPermissionDetails(id);
  }, []);

  const notify = (msg) => toast(msg);
  useEffect(() => {
    if (props.getStaffPermissionReducer.success) {
      const data = props.getStaffPermissionReducer.data;
      setDetails(data.data);
    }
  }, [props.getStaffPermissionReducer]);
  const [check, setCheck] = useState({
    // <<--Event -->>
    DetailsR: 1,
    DetailsW: 0,
    TeamR: 1,
    TeamW: 0,
    DivisionR: 1,
    DivisionW: 0,
    ScheduleR: 1,
    ScheduleW: 0,
  });

  useEffect(() => {
    if (!!details) {
      setCheck({
        //         // <<--Event -->>
        DetailsR: details?.event?.detail.view,
        DetailsW: details?.event?.detail.edit,
        TeamR: details?.event?.team.view,
        TeamW: details?.event?.team.edit,
        DivisionR: details?.event?.division.view,
        DivisionW: details?.event?.division.edit,
        ScheduleR: details?.event?.schedule.view,
        ScheduleW: details?.event?.schedule.edit,
      });
    }
  }, [details]);

  const checkVal = (e) => {
    const { id, value, name } = e.target;
    setCheck((prevState) => ({
      ...prevState,
      [id]: value == 0 ? 1 : value == 1 ? 0 : 1,
    }));
  };

  const submit = (e) => {
    e.preventDefault();

    props.changeStaffPermission({
      // role: role.role,
      event: {
        detail: {
          view: !!check.DetailsR ? check.DetailsR : 0,
          edit: !!check.DetailsW ? check.DetailsW : 0,
        },
        team: {
          view: !!check.TeamR ? check.TeamR : 0,
          edit: !!check.TeamW ? check.TeamW : 0,
        },
        division: {
          view: !!check.DivisionR ? check.DivisionR : 0,
          edit: !!check.DivisionW ? check.DivisionW : 0,
        },
        schedule: {
          view: !!check.ScheduleR ? check.ScheduleR : 0,
          edit: !!check.ScheduleW ? check.ScheduleW : 0,
        },
      },
      group: id,
    });
  };

  useEffect(() => {
    if (props.submit?.success) {
      props.resetPermissionReducer();
      notify("Permission Changed Successfully");

      setTimeout(() => {
        history.push("/staff-permission");
        setCheck({
          // <<--Event -->>
          DetailsR: 1,
          DetailsW: 0,
          TeamR: 1,
          TeamW: 0,
          DivisionR: 1,
          DivisionW: 0,
          ScheduleR: 1,
          ScheduleW: 0,
        });
      }, 3000);
    }
  }, [props.submit]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head-space ">
              <h5 className="dash-head  mr-4 ">Permission</h5>

              <div className="mt-3 mt-md-0 text-right ">
                <Link to="/create-payment-req">
                  <button className="btn-create-new " onClick={submit}>
                    Save
                  </button>
                </Link>
              </div>
            </div>
          </div>

          {/* <<<<<<<<<<--------------Event Start------------>>>>>>>>>>> */}
          <div className="green-section ">
            {/* <h6 className="text-left">Event</h6> */}
            <div className="table-responsive d-none d-md-block">
              <table className="table green-table ">
                <thead>
                  <tr>
                    <th></th>
                    <th>Read</th>
                    <th>Write</th>

                    {/* <th>Payment Id</th> */}
                  </tr>
                </thead>
                <tbody>
                  {/* {
                                      green_tbl.map((v, i) => {
                                      return  */}
                  {/* <<<<<<<<------------Event-start------------>>>>>>>>> */}
                  <tr className="pay-row">
                    <td>
                      <strong className="filter-string mb-5">Event</strong>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Details</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DetailsR"
                        name="DetailsR"
                        value={check.DetailsR}
                        onChange={checkVal}
                        checked={
                          check.DetailsR == 0
                            ? false
                            : check.DetailsR == 1
                            ? true
                            : false
                        }
                        // defaultChecked={state.paymentchecked}
                        // onClick={paycheck}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DetailsW"
                        name="DetailsW"
                        value={check.DetailsW}
                        onChange={checkVal}
                        checked={
                          check.DetailsW == 0
                            ? false
                            : check.DetailsW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Team</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="TeamR"
                        name="TeamR"
                        value={check.TeamR}
                        onChange={checkVal}
                        checked={
                          check.TeamR == 0
                            ? false
                            : check.TeamR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="TeamW"
                        name="TeamW"
                        value={check.TeamW}
                        onChange={checkVal}
                        checked={
                          check.TeamW == 0
                            ? false
                            : check.TeamW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Division</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DivisionR"
                        name="DivisionR"
                        value={check.DivisionR}
                        onChange={checkVal}
                        checked={
                          check.DivisionR == 0
                            ? false
                            : check.DivisionR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DivisionW"
                        name="DivisionW"
                        value={check.DivisionW}
                        onChange={checkVal}
                        checked={
                          check.DivisionW == 0
                            ? false
                            : check.DivisionW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Schedule</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ScheduleR"
                        name="ScheduleR"
                        value={check.ScheduleR}
                        onChange={checkVal}
                        checked={
                          check.ScheduleR == 0
                            ? false
                            : check.ScheduleR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ScheduleW"
                        name="ScheduleW"
                        value={check.ScheduleW}
                        onChange={checkVal}
                        checked={
                          check.ScheduleW == 0
                            ? false
                            : check.ScheduleW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  {/* <<<<<<<<------------Event-End------------>>>>>>>>> */}
                </tbody>
              </table>
            </div>

            {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<------------------------------- Mobile view ----------------------------------------------------------------------------------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/}

            <div className="table-responsive d-md-none">
              <table className="table green-table mt-24">
                <thead>
                  <tr>
                    <th></th>
                    <th>Read</th>
                    <th>Write</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="pay-row">
                    <td>
                      <strong className="filter-string mb-5">Event</strong>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Details</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DetailsR"
                        name="DetailsR"
                        value={check.DetailsR}
                        onChange={checkVal}
                        checked={
                          check.DetailsR == 0
                            ? false
                            : check.DetailsR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DetailsW"
                        name="DetailsW"
                        value={check.DetailsW}
                        onChange={checkVal}
                        checked={
                          check.DetailsW == 0
                            ? false
                            : check.DetailsW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Team</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="TeamR"
                        name="TeamR"
                        value={check.TeamR}
                        onChange={checkVal}
                        checked={
                          check.TeamR == 0
                            ? false
                            : check.TeamR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="TeamW"
                        name="TeamW"
                        value={check.TeamW}
                        onChange={checkVal}
                        checked={
                          check.TeamW == 0
                            ? false
                            : check.TeamW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Division</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DivisionR"
                        name="DivisionR"
                        value={check.DivisionR}
                        onChange={checkVal}
                        checked={
                          check.DivisionR == 0
                            ? false
                            : check.DivisionR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="DivisionW"
                        name="DivisionW"
                        value={check.DivisionW}
                        onChange={checkVal}
                        checked={
                          check.DivisionW == 0
                            ? false
                            : check.DivisionW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>

                  <tr className="pay-row">
                    <td>
                      <span className="filter-string">Schedule</span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ScheduleR"
                        name="ScheduleR"
                        value={check.ScheduleR}
                        onChange={checkVal}
                        checked={
                          check.ScheduleR == 0
                            ? false
                            : check.ScheduleR == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="mr-18"
                        id="ScheduleW"
                        name="ScheduleW"
                        value={check.ScheduleW}
                        onChange={checkVal}
                        checked={
                          check.ScheduleW == 0
                            ? false
                            : check.ScheduleW == 1
                            ? true
                            : false
                        }
                      ></input>
                    </td>
                  </tr>
                  {/* <<<<<<<<------------Event-End------------>>>>>>>>> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  submit: state.OrgPermissionReducer.changeStaffPermission,
  getStaffPermissionReducer: state.OrgPermissionReducer.getStaffPermission,
});

const mapDispatchToProps = (dispatch) => ({
  resetPermissionReducer: () => dispatch({ type: RESET_SET_STAFF_PERMISSION }),
  changeStaffPermission: (user) => dispatch(changeStaffPermission(user)),
  getStaffPermissionDetails: (user) =>
    dispatch(getStaffPermissionDetails(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StaffPermission));
