import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";
import { withRouter, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { connect, useSelector } from "react-redux";
// import { createSeason } from '../../service/action/userAction';
// import { RESET_CREATE_SEASON } from '../../service/constants';
import classNames from "classnames";
import {
  divisionCustomPaymentcode,
  getAllPaymentCode,
} from "../../service/action/userAction";
import Spinner from "../../Spinner/Spinner";
import {
  RESET_GET_DIVISIONCUSTOMPAYMENTCODE,
  RESET_GET_PAYMENTCODE,
} from "../../service/constants";

const Org_paymentCode = (props) => {
  let history = useHistory();
  const { id, id1 } = useParams();

  const [loading, setLoading] = useState(true);
  const [paymentcodeList, setPaymentcodeList] = useState([]);
  const allPaymentcode = useSelector((state) => state.getAllPayment);
  useEffect(() => {
    if (id) {
      props.getPaymentCodeHandler({ id });
    }
  }, []);
  useEffect(() => {
    if (props.getAllPayment.success) {
      setLoading(false);
      let payment = props.getAllPayment.user.filter((e) => e.type == 1);
      setPaymentcodeList(payment);
      props.resetGetPaymentCodeReducer();
    }
  }, allPaymentcode);

  const [state, setState] = useState({
    paymentcode: "",
  });
  const [errors, setErrors] = useState({
    paymentcode: false,
  });

  // const [checked, setChecked] = useState(false)
  // const onClickCheck = () => setChecked(!checked)
  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    switch (name) {
      case "paymentcode":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            paymentcode: "paymentcode not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            paymentcode: false,
          }));
        }
        break;
      default:
    }
  };
  const notify = (msg) => toast(msg);
  const submitRequest = (e) => {
    e.preventDefault();
    // alert("click")
    if (state.paymentcode == "") {
      setErrors((prevState) => ({
        ...prevState,
        paymentcode: "paymentcode name can not be empty",
      }));
    } else {
      props.divisionCustomPaymentcodehandler({
        payment_code: state.paymentcode,
        division: id1,
      });
    }
  };
  useEffect(() => {
    if (props.submit?.success) {
      history.push(`/event-division-details/${id}/${id1}`);
      props.resetPaymentCodeReducer();
    } else if (props.submit?.error) {
      notify("Paymentcode Error");
    }
  }, [props.submit]);
  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">paymentcode</h5>
          </div>
          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>
          {!loading && (
            <div>
              <div className="row mx-0">
                <div className="col-md-4 col-xl-4">
                  <div className="mt-24">
                    <div className="text-left">
                      <>
                        <select
                          className={classNames(
                            "form-control team-name payterm p-1",
                            { error: errors.paymentcode }
                          )}
                          id="paymentcode"
                          name="paymentcode"
                          value={state.paymentcode}
                          onChange={eventHandler}
                        >
                          <option value="" disabled>
                            Select Custom Payment Code*
                          </option>
                          {paymentcodeList.map((v, i) => {
                            return (
                              <option value={v._id} key={i}>
                                {v.name}
                              </option>
                            );
                          })}
                        </select>

                        {errors.paymentcode && (
                          <span className="text-red small d-block">
                            * {errors.paymentcode}
                          </span>
                        )}
                      </>
                    </div>
                    <div className="mt-3 text-left">
                      <a
                        className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={submitRequest}
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Toaster />
    </>
  );
};
// export default withRouter(Org_CreateDivision)
const mapStateToProps = (state) => ({
  getAllPayment: state.getAllPaymentCodeReducer,
  submit: state.divisionCustomPaymentcodeReducer,
});
const mapDispatchToProps = (dispatch) => ({
  getPaymentCodeHandler: (user) => dispatch(getAllPaymentCode(user)),
  divisionCustomPaymentcodehandler: (payload) =>
    dispatch(divisionCustomPaymentcode(payload)),
  resetGetPaymentCodeReducer: () => dispatch({ type: RESET_GET_PAYMENTCODE }),
  resetPaymentCodeReducer: () =>
    dispatch({ type: RESET_GET_DIVISIONCUSTOMPAYMENTCODE }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Org_paymentCode));
