import {
  RESET_VIEW_FIELD_MAP,
  VIEW_FIELD_MAP_ERROR,
  VIEW_FIELD_MAP_LOADING,
  VIEW_FIELD_MAP_SUCCESS,
} from "../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
};

export default function viewfieldmapReducer(state = initialState, action) {
  switch (action.type) {
    case VIEW_FIELD_MAP_LOADING:
      return { ...state, success: false, success: false };

    case VIEW_FIELD_MAP_SUCCESS:
      return { ...state, loading: false, success: true, user: action.user.data };

    case VIEW_FIELD_MAP_ERROR:
      return { ...state, error: true, errors: action.errors };

    case RESET_VIEW_FIELD_MAP:
      return {
        ...state,
        loading: false,
        user: [],
        error: false,
        errors: [],
        success: false,
      };

    default:
      return state;
  }
}
