import {
  RESET_UPDATE_FIELD_MAP,
  UPDATE_FIELD_MAP_ERROR,
  UPDATE_FIELD_MAP_LOADING,
  UPDATE_FIELD_MAP_SUCCESS,
} from "../../constants";

let initialState = {
  error: false,
  success: false,
  loading: false,
  errors: [],
};

export default function updatefieldmapReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FIELD_MAP_LOADING:
      return { ...state, error: false, loading: true, success: false };
    case UPDATE_FIELD_MAP_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        message: action.msg,
      };
    case UPDATE_FIELD_MAP_ERROR:
      return {
        ...state,
        error: true,
        errors: [],
        success: false,
        loading: false,
      };
    case RESET_UPDATE_FIELD_MAP:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        errors: [],
      };
    default:
      return state;
  }
}
