import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";

import React, { useEffect } from "react";
import { useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  RESET_ADD_SPORT_LIST,
  RESET_DELETE_SPORT_LIST,
  RESET_UPDATE_SPORT_LIST,
  removeStorage,
} from "../Services/constant";
import { useHistory } from "react-router-dom";

import classNames from "classnames";
import {
  AddSportDetails,
  DeleteSportDetails,
  GetSportDetails,
  UpdateSportDetails,
} from "../Services/Actions/sportAction";

const DATA_ITEM_KEY = "_id";

function Sport(props) {
  const history = useHistory();
  const [xlShow, setXlShow] = useState(false);
  const handleClose = () => setXlShow(false);
  const handleShow = () => setXlShow(true);

  const [delShow, setDelShow] = useState(false);
  const delDialClose = () => setDelShow(false);
  const delDialShow = () => setDelShow(true);

  const [successShow, setSuccessShow] = useState(false);
  const successDialClose = () => setSuccessShow(false);
  const successDialShow = () => {
    setDelShow(false);
    // setSuccessShow(true);
    props.DeleteSportDetails(deletedID);
  };

  const [state, setState] = useState([]);
  const [InitialState, setInitialState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [deletedID, setdeletedID] = useState();
  const [selectedID, setSelectedId] = useState("");
  const InitialForm = {
    sport: "",
    status: false,
    association_flag: false,
    association: "",
    position_flag: false,
    position: [{ position: "" }],
  };

  const InitialError = {
    sport: false,
    association: false,
    position: [false],
  };
  const [detailsForm, setDetailsForm] = useState(InitialForm);
  const [errorForm, setErrorForm] = useState(InitialError);
  const [pageDetails, setPageDetails] = useState({
    skip: 0,
    take: 10,
  });

  useEffect(() => {
    props.GetSportDetails();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (props.getSportDetailsReducer.success) {
      const data = props.getSportDetailsReducer.data;
      const _data = data.data.map((e) => {
        return {
          ...e,
          status: e.status == "1" ? "Active" : "Inactive",
          position:
            e.position.length == 0
              ? [{ position: "" }]
              : e.position.map((x) => {
                  return {
                    position: x.name,
                  };
                }),
        };
      });
      setState(_data);
      setInitialState(_data);
      setLoading(false);
    } else if (props.getSportDetailsReducer.error) {
      const errMsg = props.getSportDetailsReducer.errorMsg;
      !!errMsg && toast.error(errMsg);
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      }
    }
  }, [props.getSportDetailsReducer]);

  useEffect(() => {
    if (props.updateSportDetailsReducer.success) {
      handleClose();
      toast.success("Sport updated successfully");
      props.resetUpdateSport();
      props.GetSportDetails();
      setLoading(true);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
      setSelectedId("");
    } else if (props.updateSportDetailsReducer.error) {
      const errMsg = props.updateSportDetailsReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg && !!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.updateSportDetailsReducer]);

  useEffect(() => {
    if (props.deleteSportDetailsReducer.success) {
      handleClose();
      toast.success("Sport deleted successfully");
      props.resetdeleteSport();
      props.GetSportDetails();
      setLoading(true);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
    } else if (props.deleteSportDetailsReducer.error) {
      const errMsg = props.deleteSportDetailsReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.deleteSportDetailsReducer]);

  useEffect(() => {
    if (props.getSportDetailsReducer.success) {
      if (searchVal !== "") {
        const data = [...InitialState];
        const filter = data.filter((e) =>
          e.sport.toLowerCase().includes(searchVal.toLowerCase())
        );
        setState(filter);
      } else {
        const data = [...InitialState];
        setState(data);
      }
    }
  }, [searchVal]);

  const customHeader = (cell) => {
    return <b>{cell.title}</b>;
  };

  const customAction = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <button
          onClick={() => {
            const details = cell.dataItem;
            setSelectedId(details._id);
            setDetailsForm({
              ...details,
              sport: details.sport == "N/A" ? "" : details.sport,
              status: details.status == "Inactive" ? 0 : 1,
              association: !!details.association ? details.association : "",
            });
            setErrorForm({
              ...InitialError,
              sport: false,
            });
            handleShow();
          }}
          className="btn-link btn text-secondary p-0 mx-2"
        >
          <i className="ri-pencil-fill"></i>
        </button>
        <button
          onClick={() => {
            const details = cell.dataItem;
            delDialShow();
            setdeletedID(details?._id);
          }}
          className="btn-link btn text-danger p-0 mx-2"
        >
          <i className="ri-delete-bin-fill"></i>
        </button>
      </td>
    );
  };

  const grid = (
    <Grid
      data={state.slice(pageDetails.skip, pageDetails.skip + pageDetails.take)}
      skip={pageDetails.skip}
      take={pageDetails.take}
      total={state.length}
      pageable={{
        buttonCount: 3,
        pageSizes: [5, 10, 15],
      }}
      onPageChange={(e) => {
        setPageDetails(() => ({
          ...e.page,
          take: e.page.take,
        }));
      }}
      dataItemKey={DATA_ITEM_KEY}
    >
      <GridNoRecords>No records are available</GridNoRecords>
      <GridColumn field="sport" title="Sport" headerCell={customHeader} />
      <GridColumn field="status" title="Status" headerCell={customHeader} />

      <GridColumn
        field="action"
        title="Actions"
        width="100"
        headerCell={customHeader}
        cell={customAction}
      />
    </Grid>
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    setDetailsForm((pre) => ({
      ...pre,
      [name]: value,
    }));

    switch (name) {
      case "sport":
        if (value == "") {
          errorForm["sport"] = "Required";
        } else {
          errorForm["sport"] = false;
        }
        break;

      case "association":
        if (value == "") {
          errorForm["association"] = "Required";
        } else {
          errorForm["association"] = false;
        }
        break;

      default:
        break;
    }
  };
  const handleCreate = (e) => {
    e.preventDefault();
    if (detailsForm.sport == "") {
      setErrorForm((prev) => ({
        ...prev,
        sport: "Required",
      }));
    }

    const isPosition = detailsForm.position.filter((e) => e.position == "");
    const isPosition_flag =
      (detailsForm.position_flag == 1 && isPosition.length == 0) ||
      detailsForm.position_flag == 0
        ? true
        : false;

    const isAssociation_Flag =
      (detailsForm.association_flag == 1 && detailsForm.association != "") ||
      detailsForm.association_flag == 0
        ? true
        : false;

    if (!isAssociation_Flag) {
      if (detailsForm.association == "") {
        setErrorForm((pre) => ({
          ...pre,
          association: "Required",
        }));
      } else {
        setErrorForm((pre) => ({
          ...pre,
          association: false,
        }));
      }
    }

    if (!isPosition_flag) {
      const error = detailsForm.position.map((e) => {
        if (e.position != "") {
          return false;
        } else {
          return "Required";
        }
      });
      setErrorForm((pre) => ({
        ...pre,
        position: [...error],
      }));
    }

    if (detailsForm.sport !== "" && isPosition_flag && isAssociation_Flag) {
      selectedID == ""
        ? props.AddSportDetails({
            sport: detailsForm.sport,
            status: !!detailsForm.status ? 1 : 0,
            association_flag: !!detailsForm.association_flag ? 1 : 0,
            position_flag: !!detailsForm.position_flag ? 1 : 0,
            ...(!!detailsForm.position_flag && {
              position: detailsForm.position.map((e) => e.position),
            }),
            ...(detailsForm.association_flag && {
              association: detailsForm.association,
            }),
          })
        : props.UpdateSportDetails({
            details: {
              sport: detailsForm.sport,
              status: !!detailsForm.status ? 1 : 0,
              association_flag: !!detailsForm.association_flag ? 1 : 0,
              position_flag: !!detailsForm.position_flag ? 1 : 0,
              ...(!!detailsForm.position_flag && {
                position: detailsForm.position.map((e) => e.position),
              }),
              ...(!!detailsForm.association_flag && {
                association: detailsForm.association,
              }),
            },
            id: selectedID,
          });
    }
  };

  useEffect(() => {
    if (props.addSportDetailsReducer.success) {
      handleClose();
      toast.success("Sport created successfully");
      props.resetAddSport();
      props.GetSportDetails();
      setLoading(true);
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
    } else if (props.addSportDetailsReducer.error) {
      const errMsg = props.addSportDetailsReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.addSportDetailsReducer]);

  const handlePosition = (e, index) => {
    const { value } = e.target;

    const position = detailsForm.position.map((x, i) => {
      if (index == i) {
        return {
          position: value,
        };
      } else return x;
    });

    setDetailsForm((pre) => ({
      ...pre,
      position: [...position],
    }));

    if (value.trim() == "") {
      const errorList = errorForm.position.map((x, i) => {
        if (index == i) {
          return "Required";
        } else return x;
      });
      setErrorForm((pre) => ({
        ...pre,
        position: [...errorList],
      }));
    } else {
      const errorList = errorForm.position.map((x, i) => {
        if (index == i) {
          return false;
        } else return x;
      });
      setErrorForm((pre) => ({
        ...pre,
        position: [...errorList],
      }));
    }
  };

  const handleAddPosition = () => {
    const position = [...detailsForm.position, { position: "" }];

    setDetailsForm((pre) => ({
      ...pre,
      position: [...position],
    }));
    setErrorForm((pre) => ({
      ...pre,
      position: [...pre.position, false],
    }));
  };
  const handleRemovePosition = (e, index) => {
    const postionList = [...detailsForm.position].filter((x, i) => index !== i);
    const errorList = [...errorForm.position].filter((x, i) => index !== i);
    setDetailsForm((pre) => ({
      ...pre,
      position: [...postionList],
    }));
    setErrorForm((pre) => ({
      ...pre,
      position: [...errorList],
    }));
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2 className="page-heading">Sports</h2>
      </div>

      <Row className="justify-content-between mx-0">
        <Col md="4" className="search px-0">
          <i className="ri-search-line"></i>
          <Form.Control
            type="text"
            placeholder="Search"
            value={searchVal}
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
          />
        </Col>
        <Col md="6" className="text-end px-0">
          <Button
            variant="secondary"
            onClick={() => {
              handleShow();
              setSelectedId("");
            }}
          >
            Add Sport
          </Button>
        </Col>
      </Row>
      <div className="table-card">{grid}</div>
      <Modal
        size="md"
        show={xlShow}
        oonHide={() => {
          setDetailsForm(InitialForm);
          setErrorForm(InitialError);
          handleClose();
        }}
      >
        <Modal.Header>
          <Modal.Title>Sports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Col} className="mb-4" controlId="">
              <Form.Label>Sport Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="sport"
                value={detailsForm.sport}
                className={classNames({
                  errorBorder: errorForm.sport,
                })}
                onChange={handleChange}
              />
            </Form.Group>

            <Row className="mx-0">
              <Form.Group as={Col} className="mb-2" controlId="">
                <label className="super-switch ">
                  Status
                  <input
                    type="checkbox"
                    checked={detailsForm.status}
                    onChange={(e) => {
                      setDetailsForm((prev) => ({
                        ...prev,
                        status: e.target.checked,
                      }));
                    }}
                  />
                  <span className="super-slider order-1"></span>
                  {/* <p className="off mb-0 order-0" style={{ color: "#143D4A" }}>
                  Inactive
                </p>
                <p className="on mb-0 order-2">Active</p> */}
                </label>
              </Form.Group>
            </Row>
            <Row className="mx-0">
              <Form.Group as={Col} className="mb-2" controlId="">
                <label className="super-switch ">
                  Association
                  <input
                    type="checkbox"
                    checked={detailsForm.association_flag}
                    onChange={(e) => {
                      setDetailsForm((prev) => ({
                        ...prev,
                        association_flag: e.target.checked,
                      }));
                    }}
                  />
                  <span className="super-slider order-1"></span>
                </label>
              </Form.Group>

              {!!detailsForm.association_flag && (
                <Form.Group as={Col} className="mb-2" controlId="">
                  <Form.Label>Association Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="association"
                    value={detailsForm.association}
                    className={classNames({
                      errorBorder: errorForm.association,
                    })}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}
            </Row>

            <Row className="mx-0">
              <Form.Group as={Col} className="mb-2" controlId="">
                <label className="super-switch ">
                  Positions
                  <input
                    type="checkbox"
                    checked={detailsForm.position_flag}
                    onChange={(e) => {
                      setDetailsForm((prev) => ({
                        ...prev,
                        position_flag: e.target.checked,
                      }));
                    }}
                  />
                  <span className="super-slider order-1"></span>
                </label>
              </Form.Group>
            </Row>

            {!!detailsForm.position_flag && (
              <div>
                <Form.Label>
                  <strong>Positions</strong>
                </Form.Label>
                <div
                  style={{
                    maxHeight: "396px",
                    overflowY: "auto",
                  }}
                >
                  {detailsForm.position.map((e, i) => {
                    return (
                      <Row
                        className="mx-0 justify-content-between align-items-center mt-1"
                        key={i}
                      >
                        <Form.Group as={Col} className="col-11" controlId="">
                          <Form.Control
                            type="text"
                            placeholder="position"
                            name="positon_list"
                            value={e.position}
                            className={classNames({
                              errorBorder: errorForm.position[i],
                            })}
                            onChange={(e) => handlePosition(e, i)}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          className="col-1 pointer"
                          controlId=""
                          style={{
                            textAlign: "center",
                            fontSize: "30px",
                          }}
                          onClick={
                            i == detailsForm.position.length - 1
                              ? handleAddPosition
                              : (e) => handleRemovePosition(e, i)
                          }
                        >
                          {i == detailsForm.position.length - 1 ? (
                            <i className="mdi mdi-plus"></i>
                          ) : (
                            <i className="mdi mdi-close"></i>
                          )}
                        </Form.Group>
                      </Row>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="text-end ml-auto mt-4">
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setDetailsForm(InitialForm);
                  setErrorForm(InitialError);
                  handleClose();
                }}
                style={{ fontSize: "18px" }}
                className="mr-3 pointer"
              >
                Cancel
              </Button>
              <Button variant="secondary" type="submit" onClick={handleCreate}>
                {selectedID == "" ? "Submit" : "Update"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete confirmation */}
      <Modal
        size="sm"
        show={delShow}
        onHide={delDialClose}
        centered
        className="del-dialogue"
      >
        {/* <Modal.Header closeButton>
                              <Modal.Title>delete</Modal.Title>
                        </Modal.Header> */}
        <Modal.Body>
          <h6 className="text-center">
            Are you sure you want to delete this sport?
          </h6>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Button className="btn-gray" onClick={successDialClose}>
            No
          </Button>
          <Button variant="secondary" onClick={successDialShow}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete success */}
      <Modal
        size="sm"
        show={successShow}
        onHide={successDialClose}
        centered
        className="del-dialogue success"
      >
        <Modal.Body className="text-center">
          <i className="ri-close-circle-line fa-4x text-secondary"></i>
          <h5 className="text-center">Record deleted Successfully !!</h5>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProp = (state) => ({
  getSportDetailsReducer:
    state.superAdminRootReducer.SportReducer.getSportDetails,
  addSportDetailsReducer:
    state.superAdminRootReducer.SportReducer.addSportDetails,
  deleteSportDetailsReducer:
    state.superAdminRootReducer.SportReducer.deleteSportDetails,
  updateSportDetailsReducer:
    state.superAdminRootReducer.SportReducer.updateSportDetails,
});

const mapDispatchToProps = (dispatch) => ({
  GetSportDetails: () => dispatch(GetSportDetails()),
  AddSportDetails: (details) => dispatch(AddSportDetails(details)),
  DeleteSportDetails: (details) => dispatch(DeleteSportDetails(details)),
  UpdateSportDetails: (details) => dispatch(UpdateSportDetails(details)),
  resetAddSport: () => dispatch({ type: RESET_ADD_SPORT_LIST }),
  resetdeleteSport: () => dispatch({ type: RESET_DELETE_SPORT_LIST }),
  resetUpdateSport: () => dispatch({ type: RESET_UPDATE_SPORT_LIST }),
});

export default connect(mapStateToProp, mapDispatchToProps)(Sport);
