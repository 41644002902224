import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  deletePaymentMethod,
  getPaymentMethod,
} from "../../../service/action/Club/clubAction";
import {
  REMOVE_RESET_PAYMENT_METHOD,
  RESET_GET_PAYMENT_METHOD,
  RESET_PAYMENT_METHOD,
} from "../../../service/constants";
import Spinner from "../../../Spinner/Spinner";
import DeletePopUp from "../../components/DeletePopUp/DeletePopUp";
import BreadcrumbHistory from "../../../BreadcumbHistory/BreadcrumbHistory";

function PaymentMethod(props) {
  const [loading, setLoading] = useState(true);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [cardList, setCardList] = useState([]);
  const location = useLocation();
  const cardDetails = useSelector(
    (state) => state.getPaymentMethodReducer?.data?.data
  );

  useEffect(() => {
    if (!!cardDetails) {
      setCardList(cardDetails);
    }
  }, [cardDetails]);

  useEffect(() => {
    props.getPaymentMethod();
  }, []);

  useEffect(() => {
    setLoading(props.getPaymentMethod?.loading);
    if (props.getPaymentMethod?.success) {
      props.resetgetPaymentMethod();
    }
  }, [props.getPaymentMethod]);

  useEffect(() => {
    if (props.removePayment?.success) {
      props.getPaymentMethod();
      props.resetRemovePaymentMethod();
    }
  }, [props.removePayment]);

  const handleDeleteCard = (id) => {
    setDeleteId(id);
    setIsDelete(true);
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          <div className="text-left d-flex justify-content-between align-items-center">
            <div className="create-team-head">
              <h5 className="dash-head">Store Payment Methods</h5>
            </div>
            <div
              className="mt-2 mt-md-0 rounded border"
              style={{
                background: "#d4eeff",
              }}
            >
              <Link
                to="/payment/payment-method/add-payment-method"
                className="text-decoration-none "
              >
                <div className="text-black py-2 px-3 text-info">
                  Add Payment Method
                </div>
              </Link>
            </div>
          </div>

          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          ) : (
            <>
              {/* PREFERRED */}
              <div>
                <h4 className="text-left mt-42">Preferred</h4>
                {cardDetails && cardDetails.length !== 0 ? (
                  <div className="row mx-0">
                    <div className="col-12 col-md-7">
                      <div className="d-flex justify-content-between align-items-center border rounded p-3">
                        <div>{`${
                          cardDetails[0]?.card_name
                        } XXXX XXXX XXXX ${cardDetails[0]?.card_number.substring(
                          cardDetails[0]?.card_number.length - 4
                        )}`}</div>
                        <div className="dropdown event-drp">
                          <i
                            className="mdi mdi-dots-vertical"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></i>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <Link
                              className="dropdown-item"
                              to={`/payment/payment-method/${cardDetails[0]?._id}`}
                            >
                              <i className="mdi mdi-border-color"></i>
                              Edit
                            </Link>
                            <Link
                              className="dropdown-item"
                              to="/payment/payment-method"
                              onClick={() =>
                                handleDeleteCard(cardDetails[0]?._id)
                              }
                            >
                              <i className="fas fa-trash-alt"></i>Delete
                            </Link>
                            <Link className="dropdown-item" to="#">
                              <i className="mdi mdi-check pb"></i>Mark as
                              default
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-gray text-center">
                    No Preferred method to display
                  </div>
                )}
              </div>

              {/*  ALTERNATE */}
              <div>
                <h4 className="text-left mt-42">Alternate</h4>
                {cardList && cardList?.length !== 0 ? (
                  <>
                    {cardList?.map((v, i) => {
                      const { card_name, card_number } = v;
                      return (
                        <div className="row mt-18 mx-0" key={i}>
                          <div className="col-12 col-md-7">
                            <div className="d-flex justify-content-between align-items-center border rounded p-3">
                              <div>{`${card_name} XXXX XXXX XXXX ${card_number.substring(
                                card_number.length - 4
                              )}`}</div>
                              <div className="dropdown event-drp">
                                <i
                                  className="mdi mdi-dots-vertical"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                ></i>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuLink"
                                >
                                  <Link
                                    className="dropdown-item"
                                    to={`/payment/payment-method/${v._id}`}
                                  >
                                    <i className="mdi mdi-border-color"></i>
                                    Edit
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="/payment/payment-method"
                                    onClick={() => handleDeleteCard(v._id)}
                                  >
                                    <i className="fas fa-trash-alt"></i>Delete
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    <i className="mdi mdi-check pb"></i>Mark as
                                    default
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="text-gray text-center">
                    No Alternate method to display
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => props.deletePaymentMethod(deleteId)}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  getPaymentMethod: state.getPaymentMethodReducer,
  removePayment: state.removePaymentMethodReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentMethod: (user) => dispatch(getPaymentMethod()),
  deletePaymentMethod: (user) => dispatch(deletePaymentMethod(user)),
  resetgetPaymentMethod: () => dispatch({ type: RESET_GET_PAYMENT_METHOD }),
  resetRemovePaymentMethod: () =>
    dispatch({ type: REMOVE_RESET_PAYMENT_METHOD }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
