import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";
import { withRouter } from "react-router-dom";
import { useHistory, useLocation, useParams } from "react-router";
import { connect, useSelector } from "react-redux";

import {

  RESET_LEAGUE,
} from "../../service/constants";

import {
  createLeague,
  createLeagueUploadExcel,
  getAllDivision_club,
  getAllSeason_club,
  getClubAllTeam,
  getClubTeam,
  getField,
} from "../../service/action/Club/clubAction";

import BreadcrumbHistory from "../../BreadcumbHistory/BreadcrumbHistory";

const CreateLeague = (props) => {
  let history = useHistory();
  const notify = (msg) => toast(msg);
  const location = useLocation()

  const division = props.getAllDivisionHandler;
  const season = props.getAllSeasonHandler;
  const getteam = props.getTeamHandler;
  const getFieled = props.getFieledHandler;


  const accountDetails = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );

  useEffect(() => {
    if (accountDetails) {
      season();
      division();
      getFieled();
      getteam({ id: accountDetails._id });
    }
    // getInvitation()
  }, [accountDetails]);

  const initialState = {
    league_name: "",
  };

  const [state, setState] = useState({
    league_name: "",
  });

  const [errors, setErrors] = useState({
    league_name: false,
  });

  const clearState = () => {
    setState({ ...initialState });
  };

  function resetData() {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }

  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    switch (name) {
      case "league_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            league_name: "Please enter league name",
          }));

        } else {
          setErrors((prevState) => ({
            ...prevState,
            league_name: false,
          }));

        }
        break;
      default:
    }
  };

  const submitRequest = (e) => {
    e.preventDefault();

    if (state.league_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        league_name: "Please enter league name",
      }));
    }

    if (state.division_name !== "") {
      props.addSchedulehandler({
        league_name: state.league_name,
      });
    }
  };

  useEffect(() => {
    if (props.submit?.success) {
      // notify("Division Created");
      resetData().then(clearState);
      // props.resetCreateLeagueReducer();
      history.push(`/leagues`);
      // return <Redirect to={`/event-details/${id}`}/>;
    } else if (props.submit?.error) {
      notify("League Error");
    }
  }, [props.submit]);

  return (
    <>
      <div className="main-panel">
      <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} />

        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Add League</h5>
          </div>

          <form>
            <div className="text-left mb-3">
              <label className="text-gray fs-13">
                League <span className="text-red fw-m">*</span>
              </label>
              <input
                type="text"
                className="form-control team-name"
                id="league_name"
                name="league_name"
                value={state.league_name}
                placeholder="Leagues name"
                required
                onChange={eventHandler}
              />

              {errors.league_name && (
                <span className="text-red small d-block">
                  * {errors.league_name}
                </span>
              )}
            </div>
            <div className="mt-3 text-left">
              <a
                className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                onClick={submitRequest}
              >
                Submit
              </a>
            </div>
          </form>
          <Toaster />
        </div>
      </div>
    </>
  );
};
const mapStateToProp = (state) => ({
  // submit: state.createTeamReducer,
  submit: state.createLeagueReducer,
  submitcsv: state.createLeagueReducer,
});

const mapDispatchToProps = (dispatch) => ({
  addSchedulehandler: (user) => dispatch(createLeague(user)),
  resetCreateLeagueReducer: () => dispatch({ type: RESET_LEAGUE }),
  getAllDivisionHandler: (user) => dispatch(getAllDivision_club(user)),
  getAllSeasonHandler: (user) => dispatch(getAllSeason_club(user)),
  getTeamHandler: (user) => dispatch(getClubTeam(user)),
  getFieledHandler: (user) => dispatch(getField(user)),
  addCsvFileHandler: (user) => dispatch(createLeagueUploadExcel(user)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(CreateLeague));
