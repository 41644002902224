import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
// import $ from 'jquery'
import { connect, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useParams } from 'react-router';
import Toaster from '../../../Toaster/Toaster'
import { clubRegisterSelectedTeam, getClubAllTeam } from '../../../service/action/Club/clubAction'
// import { RESET_REGISTER_SELECTED_TEAM } from '../../../service/constants'

const ClubRegisterTeam = (props) => {

    const history = useHistory()

    const { id, id1 } = useParams();

    const notify = (msg) => toast(msg);

    const getdata = props.getTeamHandler;



    // useEffect(() => {
    //     props.getEventTeamHandler({ id: id })
    //     // getInvitation()
    // }, [])

    useEffect(() => {
        getdata()
        // getInvitation()
    }, [])


    const [teamId, setTeamId] = useState([])

    const toggle = (event) => {
        let newArray = [...teamId, event.target.id];
        if (teamId.includes(event.target.id)) {
            newArray = newArray.filter(e => e !== event.target.id);
        }
        setTeamId(newArray);
    }


    const allTeam = useSelector(state => state.getClubAllTeamReducer.user.data)

    // const registered = useSelector(state => state.registerSelectedTeamReducer.message)

    // useEffect(()=>{
    //     if(allTeam){
    //         let orgId=allTeam[0] !==undefined? allTeam[0].team_id.user_id:null
    //         setOrganizerId(orgId)
    //     }
    // },[allTeam])

    // const TeamTbl = [
    //     {
    //         teamName: "team name",
    //         gender: "male",
    //         coach: "coach",
    //         division: "Division",
    //         season: "Season"
    //     },
    //     {
    //         teamName: "xyz",
    //         gender: "male",
    //         coach: "coach",
    //         division: "Division",
    //         season: "Season"
    //     },
    // ]


    // const [state, setState] = useState({
    //     search: ""
    // })


    const onSubmit = (e) => {
        e.preventDefault();
        if (teamId.length == 0) {
            notify("Please Select Team")
        }
        if (teamId.length !== 0) {
            props.selectedTeamHandler({
                event: id,
                team: teamId,

            })
        }
    }


    useEffect(() => {
        if (props.registered?.success) {
            // notify(registered.message)
            // props.resetregisteredSelectedTeamReducer() 
                history.push(`/events/${id}`)

        }
    }, [props.registered])



    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <div className="create-team-head-space">
                            <h5 className="dash-head mr-2">Register Team</h5>
                            {/* <Link > */}
                            <div className="mt-2 mt-md-0 text-right" >
                                <button className="btn-save" onClick={onSubmit} >Save</button>
                            </div>
                            {/* </Link> */}
                            {/* <div className="d-md-flex align-items-center d-none"> */}
                            {/* <div className="search-section mt-3 mt-md-0">
                                    <i className="mdi mdi-magnify search-ico"></i>
                                    <input type="text" className="form-control" id="search" name="search" value={state.search} placeholder="Search" onChange={Searchval} autoComplete="off" />
                                </div> */}

                            {/* <div className="mt-md-0 mt-3">
                                    <i className="mdi mdi-filter-outline filter-ico"></i>
                                </div> */}
                            {/* </div> */}

                        </div>
                    </div>

                    <div className="team-details mt-4 mt-md-0 justify-content-between">


                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="teams-tab" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="mt-30">
                                    <h6 className="text-left">Club Teams</h6>

                                    {/* desktop view */}
                                    {
                                        allTeam ? <>

                                            <div className="table-responsive">

                                                <table className="table event-table team-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Teams</th>
                                                            {/* <th>Gender</th> */}
                                                            <th>Coach</th>
                                                            <th>Club</th>
                                                            {/* <th>season</th> */}
                                                            <th></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            allTeam.map((v, i) => {
                                                                return <tr key={v._id} className="team-row">
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <input
                                                                                className='mr-3'
                                                                                readOnly
                                                                                type="checkbox"
                                                                                onChange={toggle}
                                                                                // checked={check}
                                                                                // onClick={() => toggle(v._id)}
                                                                                value={v._id}
                                                                                id={v._id}

                                                                            />


                                                                            {/* <Link className="ml-12 heading text-decoration-none" to={`/team-details/${v._id}`}> */}
                                                                            <span className="filter-string" >{v.team_name}</span>
                                                                            {/* </Link> */}
                                                                        </div>
                                                                    </td>
                                                                    {/* <td>{v.gender}</td> */}
                                                                    <td>{v.coach !== null ? v.coach[0].coach_name : "null"}</td>
                                                                    <td>{v.club !== null ? v.club.name : "null"}</td>
                                                                    {/* <td>{v.season !== null ? v.season.season_name : "null"}</td> */}
                                                                    <td>

                                                                    </td>
                                                                </tr>

                                                            })
                                                        }


                                                    </tbody>

                                                </table>
                                                {/* <input type="text" value={teamId}/> */}
                                            </div>

                                          
                                        </> : <></>
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                    <Toaster />
                </div>
            </div>
        </React.Fragment>
    )
}

// export default TeamsCoches;

const mapStateToProps = (state) => ({
    registered: state.registerSelectedTeamReducer

})

const mapDispatchToProps = dispatch => ({
    getTeamHandler: user => dispatch(getClubAllTeam(user)),
    selectedTeamHandler: user => dispatch(clubRegisterSelectedTeam(user)),
    
    // sendTeamArrayHandler:user=>dispatch(teamArray(user))

})

export default connect(mapStateToProps, mapDispatchToProps)((ClubRegisterTeam)) 