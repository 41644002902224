import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import { withRouter, Redirect, Link } from "react-router-dom";
import { useParams } from "react-router";
import { connect, useSelector } from "react-redux";
import classNames from "classnames";
import {
  createDivision,
  getAllDivision,
  getEventDetails,
} from "../../../service/action/userAction";
import { RESET_CREATE_DIVISION } from "../../../service/constants";
import { useHistory } from "react-router-dom";

const Org_CreateDivision = (props) => {
  let history = useHistory();
  const { id } = useParams();
  const [currentEvent, setcurrentEvent] = useState(null);
  const [alldivisionteam, setalldivisionteam] = useState();
  const [alldivisionplayer, setalldivisionplayer] = useState();
  const initialState = {
    division_name: "",
    division_type: "",
  };
  const [state, setState] = useState({
    division_name: "",
    division_type: "",
    max_participated: "",
  });

  const [errors, setErrors] = useState({
    division_name: false,
    division_type: false,
    max_participated: false,
  });

  useEffect(() => {
    if (!!id) {
      props.getEventDetails({ id: id });
    }
  }, [id]);

  useEffect(() => {
    if (props.getOrgEventDetailsByIdReducer?.success) {
      const data = props.getOrgEventDetailsByIdReducer?.user;
      setcurrentEvent(data.data);
    }
  }, [props.getOrgEventDetailsByIdReducer]);

  useEffect(() => {
    props.getAllDivisionHandler({ id: id });
  }, []);

  useEffect(() => {
    if (props.getAllDivisionReducer?.success) {
      const data = props.getAllDivisionReducer?.user?.data;
      const _dataTeam = data
        .filter((e) => e.type == 0)
        ?.map((a) => {
          return a?.max_participated;
        });
      const _dataPlayer = data
        .filter((e) => e.type == 1)
        ?.map((a) => {
          return a?.max_participated;
        });
      setalldivisionteam(
        _dataTeam.length != 0 ? _dataTeam?.reduce((a, b) => a + b) : 0
      );
      setalldivisionplayer(
        _dataPlayer.length != 0 ? _dataPlayer?.reduce((a, b) => a + b) : 0
      );
    }
  }, [props.getAllDivisionReducer]);

  const clearState = () => {
    setState({ ...initialState });
  };

  function resetData() {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }

  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    if (name == "max_participated") {
      if (!isNaN(value)) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else return;
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    switch (name) {
      case "division_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            division_name: "Division name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            division_name: false,
          }));
        }
        break;
      case "division_type":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            division_type: "Division name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            division_type: false,
          }));
        }
        break;

      case "max_participated":
        if (state.division_type == "0") {
          if (!currentEvent?.infinity_max_team) {
            if (value == "") {
              setErrors((preve) => ({
                ...preve,
                max_participated: `Max team can not be empty`,
              }));
            } else if (
              currentEvent?.event_max_team - alldivisionteam <
              parseInt(value)
            ) {
              setErrors((preve) => ({
                ...preve,
                max_participated: `Max team is less than  ${
                  currentEvent?.event_max_team - alldivisionteam
                }`,
              }));
            } else {
              setErrors((prevState) => ({
                ...prevState,
                max_participated: false,
              }));
            }
          } else {
            setErrors((prevState) => ({
              ...prevState,
              max_participated: false,
            }));
          }
        } else if (state.division_type == "1") {
          if (!currentEvent?.infinity_max_player) {
            if (value == "") {
              setErrors((preve) => ({
                ...preve,
                max_participated: `Max player can not be empty`,
              }));
            } else if (
              currentEvent?.event_max_player - alldivisionplayer <
              parseInt(value)
            ) {
              setErrors((preve) => ({
                ...preve,
                max_participated: `Max palyer is less than  ${
                  currentEvent?.event_max_player - alldivisionplayer
                }`,
              }));
            } else {
              setErrors((prevState) => ({
                ...prevState,
                max_participated: false,
              }));
            }
          } else {
            setErrors((prevState) => ({
              ...prevState,
              max_participated: false,
            }));
          }
        }
        break;

      default:
    }
  };

  useEffect(() => {
    if (state.division_type !== "") {
      setState((pre) => ({
        ...pre,
        max_participated: "",
      }));
      setErrors((pre) => ({
        ...pre,
        max_participated: false,
      }));
    }
  }, [state.division_type]);

  const notify = (msg) => toast(msg);

  const submitRequest = (e) => {
    e.preventDefault();

    if (state.division_name == "") {
      setErrors((preve) => ({
        ...preve,
        division_name: "Enter Division Name",
      }));
    }

    if (state.division_type == "") {
      setErrors((preve) => ({
        ...preve,
        division_type: "Error",
      }));
    }

    if (state.division_type == "0" && state.max_participated == "") {
      if (!currentEvent?.infinity_max_team) {
        setErrors((preve) => ({
          ...preve,
          max_participated: "Max team can not be empty",
        }));
      } else {
        setErrors((preve) => ({
          ...preve,
          max_participated: false,
        }));
      }
    } else if (state.division_type == "1" && state.max_participated == "") {
      if (!currentEvent?.infinity_max_player) {
        setErrors((preve) => ({
          ...preve,
          max_participated: "Max player can not be empty",
        }));
      } else {
        setErrors((preve) => ({
          ...preve,
          max_participated: false,
        }));
      }
    }

    const isVallid =
      state.division_type == "0"
        ? !currentEvent?.infinity_max_team
          ? state.max_participated == ""
            ? false
            : true
          : true
        : state.division_type == "1"
        ? !currentEvent?.infinity_max_player
          ? state.max_participated == ""
            ? false
            : true
          : true
        : false;

    if (
      state.division_name !== "" &&
      state.division_type !== "" &&
      isVallid &&
      !errors?.max_participated
    ) {
      props.createDivisionhandler({
        event_id: id,
        division_name: state.division_name,
        type: state.division_type,
        max_participated: state.max_participated,
      });
    }
  };

  useEffect(() => {
    if (props.submitRequest?.success) {
      notify("Division Created");
      resetData().then(clearState);
      props.resetCreateSDivisionReducer();
      history.push(`/event-details/${id}`);
    } else if (props.submitRequest?.error) {
      notify("Division Error");
    }
  }, [props.submitRequest]);
  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Create Division</h5>
          </div>

          {!!currentEvent && (
            <div>
              <div className="row mx-0">
                <div className="col-md-4 col-xl-4">
                  <div className="mt-24">
                    <div className="text-left mb-3">
                      <select
                        value={state.division_type}
                        id="division_type"
                        name="division_type"
                        onChange={eventHandler}
                        className={classNames("form-control", {
                          requireField: errors.division_type,
                        })}
                      >
                        <option disabled value={""}>
                          Division Type
                        </option>
                        {(currentEvent?.event_type == 0 ||
                          currentEvent?.event_type == 2) && (
                          <option value={"0"}>Club</option>
                        )}
                        {(currentEvent?.event_type == 1 ||
                          currentEvent?.event_type == 2) && (
                          <option value={"1"}>Parent/player</option>
                        )}
                      </select>
                    </div>
                    {errors.division_type && (
                      <span className="text-red small d-block">
                        * {errors.division_type}
                      </span>
                    )}

                    {state.division_type != "" && (
                      <>
                        <div className="text-left mb-3">
                          <input
                            type="text"
                            id="division_name"
                            name="division_name"
                            value={state.division_name}
                            placeholder="Division name"
                            required
                            onChange={eventHandler}
                            className={classNames("form-control team-name", {
                              requireField: errors.division_name,
                            })}
                          />

                          {errors.division_name && (
                            <span className="text-red small d-block">
                              * {errors.division_name}
                            </span>
                          )}
                        </div>
                        <div className="text-left mb-3">
                          <input
                            type="text"
                            id="max_participated"
                            name="max_participated"
                            placeholder={
                              state.division_type == 0
                                ? "Max Teams"
                                : "Max Players"
                            }
                            value={state.max_participated}
                            onChange={eventHandler}
                            className={classNames("form-control team-name", {
                              requireField: errors.max_participated,
                            })}
                          />
                          {errors.max_participated && (
                            <span className="text-red small d-block">
                              * {errors.max_participated}
                            </span>
                          )}
                        </div>

                        <div className="mt-3 text-left">
                          <a
                            className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                            onClick={submitRequest}
                          >
                            Submit
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Toaster />
    </>
  );
};

// export default withRouter(Org_CreateDivision)

const mapStateToProp = (state) => ({
  submitRequest: state.createDivisionReducer,
  getOrgEventDetailsByIdReducer: state.getOrgEventDetailsByIdReducer,
  getAllDivisionReducer: state.getAllDivisionReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createDivisionhandler: (user) => dispatch(createDivision(user)),
  resetCreateSDivisionReducer: () => dispatch({ type: RESET_CREATE_DIVISION }),
  getEventDetails: (user) => dispatch(getEventDetails(user)),
  getAllDivisionHandler: (user) => dispatch(getAllDivision(user)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Org_CreateDivision));
