import React, { useState, useEffect } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
// import Toaster from '../../../Toaster/Toaster';
import { withRouter, Redirect } from 'react-router-dom';
import { connect, useSelector, useParams } from 'react-redux';
// import { createPaymentCode } from "../../../service/action/userAction"
// import { RESET_CREATE_PAYMENT_CODE } from '../../../service/constants';
import DatePicker from "react-datepicker";
import classNames from 'classnames';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Toaster from '../../Toaster/Toaster';
import { RESET_CREATE_LIABILITY } from '../../service/constants';
import { getClubLiability, saveClubLiability } from '../../service/action/Club/clubAction';
import Spinner from '../../Spinner/Spinner';



const Club_Liability = (props) => {


    const [addData, setVal] = useState("")
    const [addedData, showData] = useState(0)

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        props.getLiabilityHandler()
    }, [])

    const liabilityMsg = useSelector(state => state.createLiabilityReducer.message)
    const liability = useSelector(state => state.getPrintOrgLiabilityReducer.user.data)

    const handleChange = (e, editor) => {
        // const { name, value, id } = e.target
        const data = editor.getData();
        setVal(data);

        // switch (name) {
        //     case "terms_conditions":
        //         if (addData == "") {
        //             setErrors(prevState => ({
        //                 ...prevState,
        //                 addData: "Liability conditions can not be empty"
        //             }))
        //             document.getElementById("terms-conditions").classList.add("error")
        //         }else {
        //             setErrors(prevState=>({
        //                 ...prevState,
        //                 addData:false
        //             }))
        //             document.getElementById("terms-conditions").classList.remove("error")
        //         }
        // }
    }

    // const [datePick, setDatePick] = useState('')
    const [state, setState] = useState({
        liability: ""
    })


    const [errors, setErrors] = useState({
        addData: false,
    })


    useEffect(() => {
        if (liability) {
            setVal(liability.liability)
        }
    }, [liability])

    const notify = (msg) => toast(msg);

    const submitRequest = (e) => {
        e.preventDefault();
        // alert("click")
        if (addData == "") {
            setErrors(prevState => ({
                ...prevState,
                addData: "liability can not be empty"
            }))
            document.getElementById("terms-conditions").classList.add("error")
        }


        if (addData !== "") {
            props.saveClubLiabilityHandler({
                liability: addData
            })
            // if (props.submitRequest?.success) {
            //     notify("Data submitted successfully.")
            // }
            // resetData().then(clearState);

        }
    }

    useEffect(() => {
        if (props.liability?.success) {
            notify(liabilityMsg.message)
            props.resetCreateLiability()
        }
    }, [props.liability])

    useEffect(() => {
        if (props.print?.success) {
            setLoading(false)
        }
    }, [props.print])


    return (
        <>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-left">
                        <h5 className="dash-head">Create Liability Terms </h5>
                    </div>

                    <div className='d-flex justify-content-center'>

                        {loading ? <Spinner /> : null}
                    </div>
                    {
                        !loading && <>

                            <form >
                                <div className="row">
                                    <div className="col-md-8 col-xl-8">
                                        <div className="mt-24">


                                            <div className="form-row">

                                                <div className="col-md-12 col-12">
                                                    <div className="text-left mt-18">
                                                        <label className="text-gray fs-13">Terms Condition <span className="text-red fw-m">*</span></label>
                                                        {/* <input type="number" className="form-control team-name" placeholder="Number of day" id="balancedueday" name="balancedueday" value={state.balancedueday} required onChange={eventHandler} /> */}
                                                        <div className='document-editor' name="terms_condition" >

                                                            <CKEditor className="document-editor" editor={ClassicEditor} data={addData} onChange={handleChange} id="terms-conditions" name="terms_conditions" />
                                                        </div>

                                                        {errors.addData &&
                                                            <span className='text-red small d-block'>* {errors.addData}</span>}

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="mt-3 text-left">
                                                <a className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={submitRequest} >Send</a>
                                            </div>

                                            <Toaster />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </>
                    }
                </div>
            </div>

        </>
    )
}

// export default withRouter(CreateInvoice)



const mapStateToProp = state => ({
    liability: state.createLiabilityReducer,
    print: state.getPrintOrgLiabilityReducer

})

const mapDispatchToProps = dispatch => ({
    // paymentcodehandler: user => dispatch(createPaymentCode(user)),
    resetCreateLiability: () => dispatch({ type: RESET_CREATE_LIABILITY }),
    saveClubLiabilityHandler: user => dispatch(saveClubLiability(user)),
    getLiabilityHandler: () => dispatch(getClubLiability())




})
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(Club_Liability));