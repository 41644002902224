import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { connect } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createRoster } from '../../../service/action/Club/clubAction'



function CreateRoster(props) {

  const { id } = useParams()
  const history = useHistory()

  const notify = (msg) => toast(msg);

  const [rosterName, setRosterName] = useState('')
  const [error, setError] = useState(false)

  const eventHandler = (e) => {
    setRosterName(e.target.value)
    if (e.target.value === '') {
      setError('Please enter roster name')
    } else {
      setError(false)
    }
  }

  const createRoster = () => {
    if (rosterName === '') {
      setError('Please enter roster name')
    }
    if (rosterName !== '') {
      props.createRoster({
        team: id,
        roster: rosterName
      })
    }
  }

  useEffect(() => {
    if (props.roster.success) {
      // notify("Create Roster ")
      // props.resetRoster()
      setTimeout(() => {
        history.goBack()
      }, 1000)
    }

  }, [props.roster])

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Create Roster</h5>
          </div>

          <div className="row">
            <div className="col-md-4 col-xl-4">
              <div className="mt-24">
                <div className="text-left">
                  <label className="text-gray fs-13">Roster Name <span className="text-red fw-m">*</span></label>
                  <input type="text" className="form-control team-name" id="roster_name" name="roster_name" value={rosterName} placeholder="Roster Name *" onChange={eventHandler} />

                  {error &&
                    <span className='text-red small d-block'>* {error}</span>}

                </div>

                <div className="mt-3 text-left">
                  <div className="btn btn-primary btn-lg font-weight-medium auth-form-btn" onClick={createRoster}>Submit</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <Toaster />
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  roster: state.createRosterReducer

})

const mapDispatchToProps = dispatch => ({
  createRoster: user => dispatch(createRoster(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)((CreateRoster))