import {
  CLON_EVENT_ERROR,
  CLON_EVENT_LOADING,
  CLON_EVENT_SUCCESS,
  RESET_CLON_EVENT,
} from "../../constants";

let initialState = {
  success: false,
  error: false,
  loading: false,
  error: [],
};

export default function deleteEventReducer(state = initialState, action) {
  switch (action.type) {
    case CLON_EVENT_LOADING:
      return { ...state, success: false, loading: true, error: false };
    case CLON_EVENT_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        message: action.msg,
      };
    case CLON_EVENT_ERROR:
      return { ...state, success: false, loading: false, error: false };
    case RESET_CLON_EVENT:
      return { ...state, success: false, loading: false, error: false };
    default:
      return state;
  }
}
