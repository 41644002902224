import { LEAGUE_BOOKMARK_ERROR, LEAGUE_BOOKMARK_LOADING, LEAGUE_BOOKMARK_SUCCESS, RESET_LEAGUE_BOOKMARK } from "../../../constants";

let initialState={
    success:false,
    error:false,
    loading:false,
    error:[]
}


export default function LeagueBookmarkReducer(state=initialState,action){
    switch(action.type){
        case LEAGUE_BOOKMARK_LOADING:
            return {...state,success:false,loading:true,error:false}
        case LEAGUE_BOOKMARK_SUCCESS:
            return {...state,success:true,loading:false,error:false,message:action.msg}
        case LEAGUE_BOOKMARK_ERROR:
            return {...state,success:false,loading:false,error:false,errors:action.errors}
        case RESET_LEAGUE_BOOKMARK:
            return {...state,success:false,loading:false,error:false}
        default :
        return state
    }
}