import {
  REMINDER_CLUB_ERROR,
  REMINDER_CLUB_LOADING,
  REMINDER_CLUB_RESET,
  REMINDER_CLUB_SUCCESS,
  REMINDER_ERROR,
  REMINDER_LOADING,
  REMINDER_RESET,
  REMINDER_SUCCESS,
} from "../../../constants";
let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
  data: [],
};
export default function reminderReducer(state = initialState, action) {
  switch (action.type) {
    case REMINDER_LOADING:
      return { ...state, success: false, success: false };

    case REMINDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        user: action.payload,
        message: action.msg,
      };

    case REMINDER_ERROR:
      return { ...state, error: true, success: false, errors: action.errors };

    case REMINDER_RESET:
      return { ...state, error: false, success: false, loading: false };

    case REMINDER_CLUB_LOADING:
      return { ...state, success: false, success: false };

    case REMINDER_CLUB_SUCCESS:
      return { ...state, loading: false, success: true, user: action.payload };

    case REMINDER_CLUB_ERROR:
      return { ...state, error: true, success: false, errors: action.errors };

    case REMINDER_CLUB_RESET:
      return { ...state, error: false, success: false, loading: false };

    default:
      return state;
  }
}
