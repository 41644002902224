
import { CREATE_NEW_GROUP_ERROR, CREATE_NEW_GROUP_LOADING, CREATE_NEW_GROUP_SUCCESS, RESET_CREATE_NEW_GROUP } from "../../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}
};

export default function createNewGroupReducer(state = initialState, action) {
  switch (action.type) {

    case CREATE_NEW_GROUP_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case CREATE_NEW_GROUP_SUCCESS:
      return { ...state, error: false, loading: false, success: true, message:action.msg };

    case CREATE_NEW_GROUP_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: [] }

    case RESET_CREATE_NEW_GROUP:
        return {...state,error:false,loading:false,success:false,errors:[]}  

    default:
      return state;
  }
}
