import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import { withRouter, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { connect, useSelector } from "react-redux";
// import { createSeason } from '../../service/action/userAction';
// import { RESET_CREATE_SEASON } from '../../service/constants';
import classNames from "classnames";
import {
  createDivision,
  createPool,
  getAllDivision,
} from "../../../service/action/userAction";
import {
  RESET_CREATE_DIVISION,
  RESET_CREATE_POOL,
  SIGNATURE_LOADING,
} from "../../../service/constants";
import Spinner from "../../../Spinner/Spinner";

const Org_CreatePool = (props) => {
  let history = useHistory();
  const { id, id1, id2 } = useParams();
  const [poolID, setpoolID] = useState();

  const [loading, setLoading] = useState(true);
  // const getdivision= props.getAllDivisionHandler({id})
  const allDivision = useSelector(
    (state) => state.getAllDivisionReducer.user.data
  );
  useEffect(() => {
    if (id) {
      props.getAllDivisionHandler({ id });
    }
  }, []);

  const initialState = {
    // division_name: "",
    pool_name: "",
  };

  const [state, setState] = useState({
    // division_name: "",
    pool_name: "",
  });

  const [errors, setErrors] = useState({
    // division_name: false,
    pool_name: false,
  });
  const clearState = () => {
    setState({ ...initialState });
  };

  function resetData() {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }

  // const [checked, setChecked] = useState(false)
  // const onClickCheck = () => setChecked(!checked)

  const eventHandler = (e) => {
    const { id, value, name } = e.target;

    setState((prevState) => ({
      ...prevState,
      [id]: value,
      // [e.target.name]: e.target.value.trim()
    }));
    switch (name) {
      // case "division_name":
      //     if (value.length == 0) {
      //         setErrors(prevState => ({
      //             ...prevState,
      //             division_name: "Division name can not be empty"
      //         }))

      //
      //     }
      //     else {

      //         setErrors(prevState => ({
      //             ...prevState,
      //             division_name: false
      //         }))

      //
      //     }

      //     break;
      case "pool_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            pool_name: "Pool name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            pool_name: false,
          }));
        }

        break;

      default:
    }
  };

  const notify = (msg) => toast(msg);

  const submitRequest = (e) => {
    e.preventDefault();
    // alert("click")

    // if (state.division_name == "") {
    //     setErrors(prevState => ({
    //         ...prevState,
    //         division_name: "Division name can not be empty"
    //     }))
    //
    // }
    if (state.pool_name == "") {
      setErrors((prevState) => ({
        ...prevState,
        pool_name: "Pool name can not be empty",
      }));
    }

    if (state.pool_name !== "") {
      props.createPoolhandler({
        division_id: id1,
        pool_name: state.pool_name,
      });
      // if (props.submitRequest?.success) {
      //     notify("Data submitted successfully.")
      // }
      // resetData().then(clearState);
    }
  };

  useEffect(() => {
    if (props.submit?.success) {
      // notify("Pool Created")
      history.push(`/event-division-details/${id}/${id1}`);
      props.resetCreatePoolReducer();
    } else if (props.submit?.error) {
      notify("Pool Error");
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.division?.success) {
      setLoading(false);
    }
  }, [props.division]);
  // if(props.submit?.success){
  //         notify("Season submitted successfully.")
  //         props.resetCreateSeasonReducer()
  //         return <Redirect to="/season" />;
  //     }

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Create Pool</h5>
          </div>

          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>
          {!loading && (
            <div>
              <div className="row mx-0">
                <div className="col-md-4 col-xl-4">
                  <div className="mt-24">
                    {/* <div className="text-left">
                      <label className="text-gray fs-13">
                        Division Name <span className="text-red fw-m">*</span>
                      </label>
                      {allDivision ? (
                        <>
                          <select
                            className={classNames(
                              "form-control team-name payterm p-1",
                              { error: errors.division_name }
                            )}
                            id="division_name"
                            name="division_name"
                            value={state.division_name}
                            onChange={eventHandler}
                          >
                            <option value="#">Division Name *</option>
                            {allDivision.map((v, i) => {
                              return (
                                <option value={v._id} key={v._id}>
                                  {v.division_name}
                                </option>
                              );
                            })}
                          </select>

                          {errors.division_name && (
                            <span className="text-red small d-block">
                              * {errors.division_name}
                            </span>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div> */}
                    <div className="text-left">
                      <label className="text-gray fs-13">
                        Pool Name <span className="text-red fw-m">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control team-name"
                        id="pool_name"
                        name="pool_name"
                        value={state.pool_name}
                        placeholder="Pool name"
                        required
                        onChange={eventHandler}
                      />

                      {errors.pool_name && (
                        <span className="text-red small d-block">
                          * {errors.pool_name}
                        </span>
                      )}
                    </div>

                    <div className="mt-3 text-left">
                      <a
                        className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={submitRequest}
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Toaster />
        </div>
      </div>
    </>
  );
};

// export default withRouter(Org_CreateDivision)

const mapStateToProp = (state) => ({
  submit: state.createPoolReducer,
  division: state.getAllDivisionReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createPoolhandler: (user) => dispatch(createPool(user)),
  resetCreatePoolReducer: () => dispatch({ type: RESET_CREATE_POOL }),
  getAllDivisionHandler: (user) => dispatch(getAllDivision(user)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Org_CreatePool));
