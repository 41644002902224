import { REMOVE_PAYMENT_METHOD_ERROR, REMOVE_PAYMENT_METHOD_LOAING, REMOVE_PAYMENT_METHOD_SUCCESS } from "../../constants";

let initialState = {
 loading: false,
 error: false,
 success: false,
 payload: {}


};

export default function removePaymentMethodReducer(state = initialState, action) {
 switch (action.type) {

  case REMOVE_PAYMENT_METHOD_LOAING:
   return { ...state, error: false, loading: true, success: false };

  case REMOVE_PAYMENT_METHOD_SUCCESS:
   return { ...state, error: false, loading: false, success: true, message: action.msg };

  case REMOVE_PAYMENT_METHOD_ERROR:
   return { ...state, error: true, loading: false, success: false, errors: action.errors, msg: action.errors }

  default:
   return state;
 }
}

