import {
  GET_REG_TEAM_PAY_AMOUNT_ERROR,
  GET_REG_TEAM_PAY_AMOUNT_SUCCESS,
  RESET_GET_REG_TEAM_PAY_AMOUNT,
} from "../../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {},
};

export default function getRegTeamPayAmountReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_REG_TEAM_PAY_AMOUNT_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        user: action.data,
      };

    case GET_REG_TEAM_PAY_AMOUNT_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        errors: action.errors,
        msg: action.errors,
      };

    case RESET_GET_REG_TEAM_PAY_AMOUNT:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        errors: [],
      };

    default:
      return state;
  }
}
