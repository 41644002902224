import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  getAccountDetails,
  getAllEvent,
  getDashBoardPayment,
  eventDateFilter,
} from "../../service/action/userAction";
import { getClubNotification } from "../../service/action/Club/clubAction";
import Spinner from "../../Spinner/Spinner";
import { toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";
import { RESET_SWITCH } from "../../service/constants";
import Moment from "react-moment";
import moment from "moment";

const Org_Dashboard = (props) => {
  const notify = (msg) => toast(msg);
  let [loading, setLoading] = useState(true);
  const getNotification = props.getNotificationhandler;
  const getdata = props.getEventHandler;
  const dashboardPayment = props.getDashBoardPaymentHandler;
  const getUserDetails = props.getAccountDetails;
  const dateFilter = props.getFilteredDate;
  const userDetails = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );
  // const switchToken = useSelector(state => state.switchAccountReducer)
  const [pendingAmount, setPendingAmount] = useState(null);
  const [receiveAmount, setReceiveAmount] = useState(null);
  const [firstRender, setFirstRender] = useState(false);
  useEffect(() => {
    // if (props.switch?.success) {
    if (firstRender) {
      if (!props.switch?.loading) {
        getUserDetails();
        getdata({ id: 0 });
        getNotification();
        dashboardPayment();
        dateFilter({ start_date: moment().format("Y-MM-D"), end_date: "" });
      }
    }
    // }
  }, [firstRender, props.switch]);
  useEffect(() => {
    setFirstRender(true);
  }, []);

  const Notification = useSelector(
    (state) => state.getNotificationReducer.user.data
  );
  const payment = useSelector(
    (state) => state.getDashboardPaymentReducer.user.data
  );
  const dashboardError = useSelector(
    (state) => state.getDashboardPaymentReducer
  );
  const UpcomingEvent = useSelector(
    (state) => state.getEventDateFilterReducer.events
  );
  const [error, setError] = useState(false);
  useEffect(() => {
    if (props.paymentDash?.error) {
      notify(dashboardError.error.message);
      setLoading(false);
      setError(true);
    }
  }, [props.paymentDash]);

  useEffect(() => {
    if (props.paymentDash?.success) {
      setLoading(false);
      setReceiveAmount(props.paymentDash.user.data.received);
      setPendingAmount(payment.totalPandingAmount);
    }
  }, [props.paymentDash]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Dashboard</h5>
          </div>
          <div className="d-flex justify-content-center">
            {loading ? (
              <div className="w-full h-[50vh] flex flex-col justify-center items-center">
                <Spinner />
              </div>
            ) : null}
            {error ? (
              <div className="alert alert-danger" role="alert">
                Server Error please{" "}
                <a className="pointer" onClick={() => window.location.reload()}>
                  Refresh
                </a>
                page
              </div>
            ) : null}
          </div>
          {!loading && !error && (
            <>
              <div className="row mx-0">
                <div className="col-md-6">
                  <div className="mt-30">
                    <div className="payment-head notification-head">
                      <h6 className="payment">
                        <strong>Payments</strong>
                      </h6>
                      {/* <span className="see-all">Last 30 days</span> */}
                    </div>
                    <div className="payment-content form-row">
                      <div className="col-md-5 col-6 ">
                        <Link
                          to={{
                            pathname: "/report",
                            state: "=outstanding",
                          }}
                          className="text-decoration-none"
                        >
                          <div className="outstanding org-dash">
                            {pendingAmount !== null ? (
                              <span className="pay-amount ">
                                ${parseFloat(pendingAmount).toFixed(2)}
                              </span>
                            ) : (
                              0
                            )}
                            <span className="pay-method">Outstanding</span>
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-5 col-6">
                        <Link
                          to={{
                            pathname: "/report",
                            state: "=recevied",
                          }}
                          className="text-decoration-none"
                        >
                          <div className="outstanding org-dash">
                            {receiveAmount !== null ? (
                              <span className="pay-amount">
                                ${parseFloat(receiveAmount).toFixed(1)}
                              </span>
                            ) : (
                              5
                            )}
                            <span className="pay-method">Received</span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mx-0 mt-5">
                <div className="col-md-6 basic-info_container event-continer border-right p-3">
                  <div className="text-left d-flex align-items-center justify-content-between border-bottom pb-3">
                    <h6 className="payment">
                      <strong>Upcoming Events</strong>
                    </h6>
                    <Link className="see-all text-decoration-none" to="/events">
                      See all
                    </Link>
                  </div>
                  {/* Begin:: Upcoming event */}
                  {UpcomingEvent ? (
                    <>
                      {UpcomingEvent.length !== 0 ? (
                        UpcomingEvent?.filter((item, index) => index < 5).map(
                          (eve, i) => {
                            return (
                              <Link
                                to={`/event-details/${eve._id}`}
                                className="text-decoration-none"
                              >
                                <div
                                  className="basic-info align-items-start"
                                  key={eve._id}
                                >
                                  <div className="dashboard-img">
                                    <img
                                      src={
                                        eve.event_image !== ""
                                          ? eve.event_image
                                          : "./images/pch-images/pch.svg"
                                      }
                                    />
                                  </div>
                                  <div className="ml-12">
                                    <label className="heading mb-0">
                                      {eve.event_name}
                                    </label>
                                    <span className="noti-type d-flex flex-wrap">
                                      Event Date: &nbsp;{" "}
                                      <Moment
                                        date={new Date(eve?.event_start_date)}
                                        format="MM-DD-YYYY"
                                      />
                                    </span>
                                    <span className="noti-type">
                                      Registration Status:{" "}
                                      {moment(eve?.registration_end_date) >
                                      moment()
                                        ? "Open"
                                        : "Close"}
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            );
                          }
                        )
                      ) : (
                        <span
                          className="noti-type text-center mt-2"
                          style={{ fontWeight: "bold" }}
                        >
                          No events found
                        </span>
                      )}
                    </>
                  ) : null}
                  {/* End:: Upcoming event */}
                </div>

                <div className="col-md-6 basic-info_container p-3">
                  <div className="text-left d-flex align-items-center justify-content-between border-bottom pb-3">
                    <h6 className="payment">
                      <strong>Notification</strong>
                    </h6>
                    <Link
                      className="see-all text-decoration-none"
                      to={{
                        pathname: `/notification-accounts/${userDetails?._id}`,
                        state: {
                          id: userDetails?._id,
                          type: parseInt(localStorage.getItem("type")),
                          name: "notification",
                        },
                      }}
                    >
                      See all
                    </Link>
                  </div>
                  {Notification ? (
                    <>
                      {Notification.length == 0 ? (
                        <>
                          <span
                            className="noti-type text-center mt-2"
                            style={{ fontWeight: "bold" }}
                          >
                            No notifications found
                          </span>
                        </>
                      ) : (
                        <>
                          {Notification.filter((item, index) => index < 5).map(
                            (v, i) => {
                              return (
                                <Link
                                  className="text-decoration-none"
                                  to={{
                                    pathname: `/notification-accounts/${userDetails?._id}`,
                                    state: {
                                      id: userDetails?._id,
                                      type: parseInt(
                                        localStorage.getItem("type")
                                      ),
                                      name: "notification",
                                    },
                                  }}
                                >
                                  <div className="basic-info mt-3" key={v._id}>
                                    <div className="dashboard-img">
                                      <img src="./images/pch-images/pch.svg" />
                                    </div>
                                    <div className="ml-12">
                                      <label className="heading mb-0">
                                        {v.heading}
                                      </label>
                                      <span className="noti-type">
                                        {v.message}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              );
                            }
                          )}
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </>
          )}
          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(Org_Dashboard);
const mapStateToProps = (state) => ({
  switch: state.switchAccountReducer,
  paymentDash: state.getDashboardPaymentReducer,
  accountDetails: state.accountDetailsReducer,
  events: state.getAllEventReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getEventHandler: (user) => dispatch(getAllEvent(user)),
  getNotificationhandler: (user) => dispatch(getClubNotification(user)),
  getDashBoardPaymentHandler: (user) => dispatch(getDashBoardPayment(user)),
  getAccountDetails: (user) => dispatch(getAccountDetails(user)),
  resetSwitchReducer: () => dispatch({ type: RESET_SWITCH }),
  getFilteredDate: (data) => dispatch(eventDateFilter(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Org_Dashboard));
// 303
