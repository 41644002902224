import { CREATE_POOL_ERROR, CREATE_POOL_LOADING, CREATE_POOL_SUCCESS, RESET_CREATE_POOL } from "../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function createPoolReducer(state = initialState, action) {
  switch (action.type) {

    case CREATE_POOL_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case CREATE_POOL_SUCCESS:
      return { ...state, error: false, loading: false, success: true,message:action.msg };

    case CREATE_POOL_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: [] }

    case RESET_CREATE_POOL:
        return {...state,error:false,loading:false,success:false,errors:[],user:[{}]}  

    default:
      return state;
  }
}


