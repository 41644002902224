import { GET_CREDIT_ERROR, GET_CREDIT_LOADING, GET_CREDIT_SUCCESS, GET_EVENTS_REGISTER_TEAM_ERROR, GET_EVENTS_REGISTER_TEAM_LOADING, GET_EVENTS_REGISTER_TEAM_SUCCESS, RESET_GET_CREDIT } from "../../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false
};

export default function getOrgCreditReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CREDIT_LOADING:
      return { ...state, success: false,success:false};
     
    case GET_CREDIT_SUCCESS:  
      return { ...state, loading: false,success:true,user:action.payload};
 
    case GET_CREDIT_ERROR:
      return { ...state,error: true, errors: action.errors };

    case RESET_GET_CREDIT:
      return { ...state,error:false, loading:false,success:false}
   
    default:
      return state;
  }
}


