import classNames from "classnames";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addAccount,
  getAllCity,
  getAllSport,
  getAllState,
  getAllUserRoll,
  switchAccountData,
} from "../service/action/userAction";
import { RESET_ADDACCOUNT } from "../service/constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "react-bootstrap";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactDatePicker from "react-datepicker";
import CoachFrom from "./components/CoachForm";
import StaffAccountForm from "./components/StaffAccountForm";
import CoachAccountForm from "./components/CoachAccountForm";
import PlayerAccountForm from "./components/PlayerAccountForm";
import ParentAccountForm from "./components/ParentAccountForm";
import ClubAccountForm from "./components/ClubAccountForm";
import { getAccountDetails } from "../service/action/userAction";
import EventAccountForm from "./components/EventAccountForm";

const AddAccountForm = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [currentUser, setcurrentUser] = useState();
  const dispatch = useDispatch();
  const accountDetails = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );
  const mainUserMail = accountDetails?.user_id?.email;

  const [state, setState] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    telephone: "",
    DOB: null,
    gender: "",
    terms_condition: "",
    clubName: "",
    address: "",
    state: "",
    city: "",
    zip: "",
    website: "",
    sport: "",
    director_name: "",
    director_email: "",
    director_mobile_number: "",
    organizer_personal_mail: "",
    primary_contact: [],
    orgName: "",
    description: "",
  });
  const [errors, setErrors] = useState({
    first_name: false,
    middle_name: false,
    last_name: false,
    telephone: false,
    DOB: false,
    gender: false,
    terms_condition: false,
    clubName: false,
    address: false,
    state: false,
    city: false,
    city: false,
    zip: false,
    website: false,
    sport: false,
    director_name: false,
    director_email: false,
    director_mobile_number: false,
    organizer_personal_mail: false,
    primary_contact: [],
    description: false,
    orgName: false,
  });

  // const [opstate, setopState] = useState({
  //     name: "",
  //     option: "",
  // });

  useEffect(() => {
    dispatch(getAccountDetails());
  }, []);

  const [DOB, setDOB] = useState(null);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [formID, setformID] = useState();

  useEffect(() => {
    if (id) {
      setformID(id);
    }
  }, [id]);

  useEffect(() => {
    const userID = localStorage.getItem("Id");
    if (props.getUserRollReducer?.success) {
      const data = props.getUserRollReducer?.user?.organizer?.find(
        (e) => e?._id == userID
      );
      setcurrentUser(data);
    }
  }, [props.getUserRollReducer]);

  const eventhandler = (e) => {
    const { id, value, name } = e.target;

    if (name === "terms_condition") {
      setState((preve) => ({
        ...preve,
        terms_condition: e.target.checked === true ? 1 : 0,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    switch (name) {
      case "first_name":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            first_name: "first name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            first_name: false,
          }));
        }
        break;
      case "middle_name":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            middle_name: "middle name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            middle_name: false,
          }));
        }
        break;
      case "last_name":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            last_name: "Last name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            last_name: false,
          }));
        }
        break;
      case "telephone":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            telephone: "telephone can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            telephone: false,
          }));
        }
        break;
      case "DOB":
        setDOB(value);
        // capital != null && capital.length < 1
        if (value !== null && value.length < 0) {
          setErrors((prevState) => ({
            ...prevState,
            DOB: "DOB Required",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            DOB: false,
          }));
        }
        break;

      case "address":
        if (value.length == 0) {
          setErrors((prev) => {
            return { ...prev, address: true };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, address: false };
          });
        }

        break;

      case "state":
        if (value == "") {
          setErrors((prev) => {
            return { ...prev, state: true };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, state: false };
          });
        }
        break;
      case "city":
        if (value == "") {
          setErrors((prev) => {
            return { ...prev, city: true };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, city: false };
          });
        }
        break;

      case "zip":
        if (value == "_____") {
          setErrors((prev) => {
            return { ...prev, zip: true };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, zip: false };
          });
        }
        break;

      case "clubName":
        if (value == "") {
          setErrors((prev) => {
            return { ...prev, clubName: true };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, clubName: false };
          });
        }
        break;

      default:
    }
  };

  const handleSubmit = (e) => {
    // Staf account creation
    if (state.first_name == "") {
      setErrors((prev) => {
        return { ...prev, first_name: true };
      });
    }

    if (state.last_name == "") {
      setErrors((prev) => {
        return { ...prev, last_name: true };
      });
    }

    if (state.telephone == "") {
      setErrors((prev) => {
        return { ...prev, telephone: true };
      });
    }

    if (state.DOB == null) {
      setErrors((prev) => {
        return { ...prev, DOB: "required" };
      });
    }

    if (
      formID == 2 &&
      state.DOB !== "" &&
      state.first_name !== "" &&
      state.last_name !== "" &&
      state.telephone !== ""
    ) {
      props.addAccountHandler({
        type: 2,
        dob: state?.DOB,
        first_name: state?.first_name,
        middle_name: state?.middle_name,
        last_name: state?.last_name,
        mobile_number: state?.telephone,
      });
    }

    // Coach Account Creation

    if (state.address == "") {
      setErrors((prev) => {
        return { ...prev, address: true };
      });
    }

    if (state.city == "") {
      setErrors((prev) => {
        return { ...prev, city: true };
      });
    }

    if (state.zip == "") {
      setErrors((prev) => {
        return { ...prev, zip: true };
      });
    }

    if (state.state == "") {
      setErrors((prev) => {
        return { ...prev, state: true };
      });
    }

    if (
      formID == 3 &&
      state.DOB !== "" &&
      state.first_name !== "" &&
      state.middle_name !== "" &&
      state.last_name !== "" &&
      state.telephone !== "" &&
      state.address !== "" &&
      state.state !== "" &&
      state.city !== "" &&
      state.zip !== ""
    ) {
      props.addAccountHandler({
        type: 3,
        dob: state?.DOB,
        first_name: state?.first_name,
        middle_name: state?.middle_name,
        last_name: state?.last_name,
        mobile_number: state?.telephone,
        address: state?.address,
        state: state?.state,
        city: state?.city,
        zip: state?.zip,
      });
    }

    // Player Account Creation
    // Parent Account Creation
    // Both are the same field

    if (
      (formID == 5 || 6) &&
      state.DOB !== "" &&
      state.first_name !== "" &&
      state.middle_name !== "" &&
      state.last_name !== "" &&
      state.telephone !== "" &&
      state.terms_condition !== false &&
      state.gender !== ""
    ) {
      props.addAccountHandler({
        type: 5,
        dob: state?.DOB,
        first_name: state?.first_name,
        middle_name: state?.middle_name,
        last_name: state?.last_name,
        mobile_number: state?.telephone,
        gender: state?.gender,
        terms_condition: state.terms_condition,
      });
    }

    // Club Account Creation

    if (state.clubName == "") {
      setErrors((prev) => {
        return { ...prev, clubName: true };
      });
    }

    if (
      formID == 4 &&
      state.clubName !== "" &&
      state.address !== "" &&
      state.city !== "" &&
      state.zip !== "" &&
      state.telephone !== "" &&
      state.sport !== ""
    ) {
      const payload = {
        first_name: state.first_name,
        last_name: state.last_name,
        dob: state.DOB,
        type: 4,
        name: state.clubName,
        address: state?.address,
        state: state?.state,
        city: state.city,
        zip: state.city,
        mobile_number: state.telephone,
        website: state.website,
        primary_contact: [
          {
            primary_email: state.director_email,
            primary_dob: state.DOB,
            middle_name: "",
            primary_mobile: state.director_mobile_number,
            last_name: state.last_name,
            first_name: state.first_name,
          },
        ],
        sport: state.sport,
        email: mainUserMail,
      };

      props.addAccountHandler(payload);
    }

    // Organizer Account Creation

    if (
      formID == 1 &&
      state.orgName !== "" &&
      state.address !== "" &&
      state.city !== "" &&
      state.zip !== "" &&
      state.telephone !== " "
    ) {
      const payload = {
        first_name: state.first_name,
        last_name: state.last_name,
        dob: state.DOB,
        type: 1,
        name: state.orgName,
        address: state.address,
        state: state.state,
        city: state.city,
        zip: state.city,
        mobile_number: state.telephone,
        website: state.website,
        primary_contact: [
          {
            primary_email: state.director_email,
            primary_dob: state.DOB,
            middle_name: "",
            primary_mobile: state.director_mobile_number,
            last_name: state.last_name,
            first_name: state.first_name,
          },
        ],
        sport: state.sport,
        email: mainUserMail,
        description: state.description,
        personal_email: state.organizer_personal_mail,
      };
      props.addAccountHandler(payload);
    }
  };

  const notify = (msg) => toast(msg);
  //For redirect page to /switch account after add account succesfully
  useEffect(() => {
    if (props.addaccount?.success) {
      props.resetAddAccount();
      notify("New Account Created ");
      setTimeout(() => {
        history.push("/switch");
      }, 2000);
      return <Redirect to="/switch" />;
    }
  }, [props.addaccount]);

  return (
    <div className="main-panel">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex auth my-4">
          <div className="row flex-grow">
            <div className="col-lg-5 mx-auto mt-24">
              <div className="auth-form-light text-left p-5">
                <>
                  <div className="d-flex justify-content-beetwen">
                    <div
                      style={{ marginRight: "10px", fontSize: "25px" }}
                      className="text-gray d-flex justify-content-center align-items-center"
                    >
                      <div>
                        <i className="fa fa-shirt"></i>
                      </div>
                    </div>
                    <div className="text-gray" style={{ fontWeight: "bold" }}>
                      {formID == 2 && (
                        <>
                          <div>Create Account</div>
                          <div>I'm a Event Staff</div>
                        </>
                      )}
                      {formID == 3 && (
                        <>
                          <div>Create Account</div>
                          <div>I'm a Coach</div>
                        </>
                      )}
                      {formID == 5 && (
                        <>
                          <div>Create Account</div>
                          <div>I'm a Player</div>
                        </>
                      )}
                      {formID == 6 && (
                        <>
                          <div>Create Account</div>
                          <div>I'm a Parent/guardian</div>
                        </>
                      )}
                      {formID == 4 && (
                        <>
                          <div>Create Account</div>
                          <div>I'm a Club Director</div>
                        </>
                      )}
                      {formID == 1 && (
                        <>
                          <div>Create Account</div>
                          <div>I'm a Event Organizer</div>
                        </>
                      )}
                    </div>
                  </div>
                  {/* Staff Account ID : 2 */}
                  {formID == 2 && (
                    <StaffAccountForm
                      state={state}
                      eventhandler={eventhandler}
                      errors={errors}
                      DOB={DOB}
                      handleSubmit={handleSubmit}
                    />
                  )}
                  {formID == 3 && (
                    <CoachAccountForm
                      state={state}
                      eventhandler={eventhandler}
                      errors={errors}
                      DOB={DOB}
                      handleSubmit={handleSubmit}
                    />
                  )}
                  {formID == 5 && (
                    <PlayerAccountForm
                      state={state}
                      eventhandler={eventhandler}
                      errors={errors}
                      DOB={DOB}
                      handleSubmit={handleSubmit}
                    />
                  )}{" "}
                  {formID == 6 && (
                    <ParentAccountForm
                      state={state}
                      eventhandler={eventhandler}
                      errors={errors}
                      DOB={DOB}
                      handleSubmit={handleSubmit}
                    />
                  )}
                  {formID == 4 && (
                    <ClubAccountForm
                      state={state}
                      eventhandler={eventhandler}
                      errors={errors}
                      DOB={DOB}
                      handleSubmit={handleSubmit}
                    />
                  )}
                  {formID == 1 && (
                    <EventAccountForm
                      state={state}
                      eventhandler={eventhandler}
                      errors={errors}
                      DOB={DOB}
                      handleSubmit={handleSubmit}
                    />
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="main-panel">

    // <CoachFrom />
    // </div>
  );
};

const mapStateToProp = (state) => ({
  addaccount: state.addAccountReducer,
  token: state.switchAccountReducer.token,
  signin: state.signinReducer,
  getUserRollReducer: state.getUserRollReducer,
});

const mapDispatchToProps = (dispatch) => ({
  addAccountHandler: (user) => dispatch(addAccount(user)),
  getUserRollHandler: (user) => dispatch(getAllUserRoll(user)),
  resetAddAccount: () => dispatch({ type: RESET_ADDACCOUNT }),
  getAllStateHandler: () => dispatch(getAllState()),
  getAllCityHandler: (user) => dispatch(getAllCity(user)),
  getAllSportHandler: (user) => dispatch(getAllSport(user)),
  switchAccountHandler: (user) => dispatch(switchAccountData(user)),
});

export default connect(mapStateToProp, mapDispatchToProps)(AddAccountForm);
