// import { GET_MERCHANT_ORGANIZER_ERROR, GET_MERCHANT_ORGANIZER_LOADING, GET_MERCHANT_ORGANIZER_SUCCESS } from "../constants";

import { GET_MERCHANT_ORGANIZER_ERROR, GET_MERCHANT_ORGANIZER_LOADING, GET_MERCHANT_ORGANIZER_SUCCESS } from "../constants";

//   let initialState = {
//     loading: false,
//     user: [{}],
//     error: false,
//     errors: [],
//     success: false,
//     payload: {},
//   };
  
//   export default function getMerchantOrgReducer(state = initialState, action) {
//     switch (action.type) {
//       case GET_MERCHANT_ORGANIZER_LOADING:
//         return { ...state, success: false, success: false };
  
//       case GET_MERCHANT_ORGANIZER_SUCCESS:
//         return { ...state, loading: false, success: true, user: action.payload };
  
//       case GET_MERCHANT_ORGANIZER_ERROR:
//         return { ...state, error: true, errors: action.errors };
  
//       default:
//         return state;
//     }
//   }


let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {}
};

export default function getMerchantOrgReducer(state = initialState, action) {

  switch (action.type) {
    case  GET_MERCHANT_ORGANIZER_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case  GET_MERCHANT_ORGANIZER_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };

    case  GET_MERCHANT_ORGANIZER_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}



  