import React from "react";
import { Link } from "react-router-dom";
const EventsHeading = () => {
  return (
    <div className="text-left payment-head notification-head border-bottom pb-3">
      <h6 className="payment">Events </h6>
      <Link className="see-all text-decoration-none" to="/events">
        See all
      </Link>
    </div>
  );
};

export default EventsHeading;
