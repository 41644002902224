import React, { useEffect, useState } from "react";
import { useSelector, connect, useDispatch } from "react-redux";

import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import {
  RESET_CREATE_ORG_TEAM,
  RESET_CREATE_TEAM,
  RESET_SEND_INVITATION_EMAIL,
  RESET_SEND_ORG_INVITATION_EMAIL,
} from "../../../service/constants";
import { useParams } from "react-router";
import {
  findByEmail,
  findUser,
  getAccountDetails,
  getOrgPlayerList,
  InvitePlayerOrg,
  sendInvitationBymail,
} from "../../../service/action/userAction";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { createDynamicUrl } from "../../../helpers/createDynamicUrl";

const OrgInvitePlayer = (props) => {
  const { id, id1 } = useParams();

  // const [loading, setLoading] = useState(true);
  // const [receipientList, setReceipientList] = useState([]);
  const [email, setEmail] = useState("");
  const [emailsList, setEmailsList] = useState([]);
  const [error, setError] = useState(false);
  // const [initialState, setInitialState] = useState({
  //   email: "",
  // });
  // const [state, setState] = useState({
  //   email: "",
  // });
  // useEffect(() => {
  //   if (id) {
  //     props.findUserHandler({ type: 4, event: id, email: [], invite_type: 4 });
  //   }
  // }, []);
  // useEffect(() => {
  //   if (!props.receipient.loading) {
  //     setLoading(false);
  //     setReceipientList(props.receipient.user);
  //   }
  // }, [props.receipient]);

  useEffect(() => {
    if (props.sendByMail?.success) {
      notify("Invitation send successfully");
      props.resetInvitationByMailReducer();
      setTimeout(() => {
        setEmail("");
      }, 1000);
      setError(false);
    }
  }, [props.sendByMail]);

  // const clearState = () => {
  //   setState({ ...initialState });
  // };
  // function resetData() {
  //   return new Promise((resolve) => {
  //     setTimeout(resolve, 1000);
  //   });
  // }
  const notify = (msg) => toast(msg);
  // useEffect(() => {
  //   setLoading(false);
  // }, [props.receipient]);

  // const Searchval = (e) => {
  //   const { value } = e.target;
  //   props.findByEmailHandler({ email: value, type: 4 });
  // };

  // const [copied, setCopied] = useState(false);
  // function copy() {
  //   const el = document.createElement("input");
  //   el.value = `${window.location.origin}/registernow/${id}`;
  //   document.body.appendChild(el);
  //   el.select();
  //   document.execCommand("copy");
  //   document.body.removeChild(el);
  //   setCopied(true);
  //   setTimeout(() => {
  //     setCopied(false);
  //   }, 2000);
  // }

  const eventHandler = (e) => {
    setEmail(e.target.value);
  };

  const addEmails = () => {
    if (email === "") {
      notify("Please Check Email");
    } else {
      const validEmailRegex = RegExp(
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      );
      const list = email.split(/,|, /);
      const errorList = [];
      const isValid = list.map((v) => {
        if (!validEmailRegex.test(v.trim())) {
          errorList.push(v);
          return false;
        } else if (list.length === 0 || v.trim().length === 0) {
          return false;
        } else {
          setError(false);
          return v;
        }
      });
      const _list = isValid.filter((e) => !!e);
      const combainArray = [...emailsList, ..._list];
      setEmailsList([...new Set(combainArray)]);
      if (errorList.length != 0) {
        setEmail(errorList.join(", "));
        setError("Email is not valid !");
      } else {
        setEmail("");
      }
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (email.length == 0) {
      setError("Value can not be empty");
    }
    if (email !== "" && error == false) {
      props.sendInvitationBymailHandler({
        invite_user_email: email,
        invite_user_type: "2",
        invitation_for: id,
      });
    }
  };

  // Creating Deep Link
  const [deepLink, setDeepLink] = useState("");
  const dispatch = useDispatch();
  const accountDetails = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );
  useEffect(() => {
    dispatch(getAccountDetails());
  }, []);
  const eventID = id;
  const currentUserID = accountDetails?.user_id?.id;
  const dynamicLink = "https://playersclearinghouse.page.link";
  const route = "/SwitchScreenLink";
  const para = id1
    ? `?invitationFor=5,6&invitationFrom=1&id=${eventID}&hostId=${currentUserID}&divisionId=${id1}`
    : `?invitationFor=5,6&invitationFrom=4&id=${eventID}&hostId=${currentUserID}`;
  const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;

  const REQUEST_URL = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseApiKey}`;

  const BODY = {
    dynamicLinkInfo: {
      domainUriPrefix: "https://playersclearinghouse.page.link",
      link: dynamicLink + route + para,
      androidInfo: {
        androidPackageName: "com.playersclearinghouse.app",
      },
    },
  };

  const getDeepLink = async () => {
    const res = await createDynamicUrl(REQUEST_URL, BODY);
    if (res) {
      setDeepLink(res?.shortLink);
    }
  };

  useEffect(() => {
    getDeepLink();
  }, []);

  // Copy Link

  const [copied, setCopied] = useState(false);
  function copy() {
    navigator.clipboard.writeText(deepLink).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    });
  }

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <div className="create-team-head">
              <h5 className="dash-head mr-3 mb-0">Invite Players</h5>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-md-6">
              <div className="text-left invite-email mt-42">
                <i
                  className="fas fa-circle-info pointer"
                  title="You can add multiple Emails separated by comma."
                  style={{
                    fontSize: "10px",
                    top: "18px",
                  }}
                ></i>
                <i
                  className="mdi mdi-email"
                  style={{
                    left: "28px",
                  }}
                ></i>
                <input
                  type="email"
                  className={classNames("form-control team-name", {
                    error: error,
                  })}
                  style={{
                    paddingLeft: "52px",
                    width: "100%",
                  }}
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={eventHandler}
                />
              </div>

              {error && <span className="text-red small d-block">{error}</span>}
              <div className="mt-3 fw-m text-red pointer" onClick={addEmails}>
                Add Email
              </div>

              {emailsList.length != 0 ? (
                <div className="mt-2">
                  {emailsList.map((e, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div className="row mx-0">
                          <div className="col-10 text-gray">{e}</div>
                          <div
                            className="col-2 text-right pointer"
                            onClick={() => {
                              const list = emailsList.filter(
                                (list, index) => index != i
                              );
                              setEmailsList(list);
                            }}
                          >
                            <i className="fas fa-close"></i>
                          </div>
                        </div>
                        <hr className="my-1" />
                      </React.Fragment>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}

              <div className="mt-3 text-left">
                <a
                  className="btn btn-primary font-weight-medium auth-form-btn"
                  onClick={sendEmail}
                >
                  Send
                </a>
              </div>
            </div>
          </div>
          <button
            className="btn  p-0 ml-1 mt-3"
            style={{ color: "#143D4A" }}
            onClick={copy}
          >
            <i
              className="mdi mdi-open-in-new website-ico"
              style={{ color: "#80bdff" }}
            ></i>{" "}
            {!copied ? "Copy Invite link" : "Copied!"}
          </button>
        </div>
      </div>
      <Toaster />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  receipient: state.findUserReducer,
  findByReceipient: state.findByEmailUserReducer,
  sendByMail: state.sendInvitationByMailReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // findUserHandler: (data) => dispatch(findUser(data)),
  findByEmailHandler: (data) => dispatch(findByEmail(data)),
  sendInvitationBymailHandler: (user) => dispatch(sendInvitationBymail(user)),
  resetInvitationByMailReducer: () =>
    dispatch({ type: RESET_SEND_INVITATION_EMAIL }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgInvitePlayer);
