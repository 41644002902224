import { EDIT_SEASON_ERROR, EDIT_SEASON_LOADING, EDIT_SEASON_SUCCESS,RESET_EDIT_SEASON} from "../../constants";

let initialState={
    error:false,
    success:false,
    loading:false,
    errors:[]
}

export default function editSeasonReducer(state=initialState,action){
    switch(action.type){
        case EDIT_SEASON_LOADING:
            return{...state,error:false,loading:true,success:false}
        case EDIT_SEASON_SUCCESS:
            return {...state,error:false,loading:false,success:true,message:action.msg}
        case EDIT_SEASON_ERROR:
            return {...state,error:true,errors:[],success:false,loading:false}
        case RESET_EDIT_SEASON:
            return {...state,error:false,loading:false,success:false,errors:[]}  
        default:
            return state;
    }
}