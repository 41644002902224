import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import { withRouter, Redirect, useHistory, Link } from "react-router-dom";
import { connect, useSelector, useParams } from "react-redux";
import { createPaymentCode } from "../../../service/action/userAction";
import { RESET_CREATE_PAYMENT_CODE } from "../../../service/constants";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useEffect } from "react";
import {
  AMOUNT,
  AMOUNT_DUE,
  BALANCE_DUE_DAYS,
  DISCRIPTION,
  EVENT_TYPE,
  EVET_PAYMENT_CODE_TYPE,
  PAYMENT_CODE,
  PAYMENT_TERM_CONDITION,
} from "../../../Validation/organizer";

const CreatePaymentCode = (props) => {
  const history = useHistory();

  const initialState = {
    type: "",
    event_type: "",
    name: "",
    discription: "",
    amount: "",
    amountdue: "",
    reminder: [],
    payment_terms_type: 0,
    balancedueday: "",
    terms_condition: "",
  };
  const initialError = {
    type: false,
    event_type: false,
    name: false,
    discription: false,
    amount: false,
    amountdue: false,
    reminder: [],
    payment_terms_type: false,
    balancedueday: false,
    terms_condition: false,
  };
  const [state, setState] = useState(initialState);
  const [errors, setErrors] = useState(initialError);
  const [inputList, setInputList] = useState([{ remind: "" }]);

  useEffect(() => {
    if (state.type !== "") {
      setErrors(initialError);
    }
  }, [state.type]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    if (!isNaN(value)) {
      if (parseInt(value) <= parseInt(state.balancedueday) || value == "") {
        list[index][name] = value !== "" ? parseInt(value) : "";
        setInputList(list);
      } else return;
    } else return;
  };

  const handleChange = (e, editor) => {
    // const { name } = e.target
    const data = editor.getData();
    setState((pre) => ({
      ...pre,
      terms_condition: data,
    }));

    if (data.length == 0) {
      setErrors((prevState) => ({
        ...prevState,
        terms_condition: PAYMENT_TERM_CONDITION,
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        terms_condition: false,
      }));
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    if (!inputList.find((e) => e.remind == ""))
      setInputList([...inputList, { remind: "" }]);
  };
  const eventHandler = (e) => {
    const { value, name } = e.target;

    if (name == "amount") {
      if (!isNaN(value)) {
        setState((prevState) => ({
          ...prevState,
          amount: value,
        }));
      } else return;
    } else if (name == "amountdue") {
      if (!isNaN(value)) {
        if (state.payment_terms_type == "0") {
          if (parseFloat(value) <= parseFloat(state.amount) || value == "") {
            setState((prevState) => ({
              ...prevState,
              amountdue: value,
            }));
          } else return;
        } else {
          if (parseFloat(value) <= 100 || value == "") {
            setState((prevState) => ({
              ...prevState,
              amountdue: value,
            }));
          } else return;
        }
      } else return;
    } else if (name == "balancedueday") {
      if (!isNaN(value)) {
        setState((prevState) => ({
          ...prevState,
          balancedueday: value !== "" ? parseInt(value) : "",
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (name == "payment_terms_type") {
      setState((prevState) => ({
        ...prevState,
        amountdue: "",
      }));
      setErrors((pre) => ({
        ...pre,
        amountdue: false,
      }));
    }
    switch (name) {
      case "type":
        if (value === "") {
          setErrors((preve) => ({
            ...preve,
            type: EVENT_TYPE,
          }));
        } else {
          setErrors((preve) => ({
            ...preve,
            type: false,
          }));
        }
        break;
      //     break;
      case "discription":
        if (value.length == "") {
          setErrors((prevState) => ({
            ...prevState,
            discription: DISCRIPTION,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            discription: false,
          }));
        }
        break;
      case "event_type":
        if (value === "") {
          setErrors((preve) => ({
            ...preve,
            event_type: EVENT_TYPE,
          }));
        } else {
          setErrors((preve) => ({
            ...preve,
            event_type: false,
          }));
        }
        break;
      case "name":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            name: PAYMENT_CODE,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            name: false,
          }));
        }
        break;
      case "amount":
        if (value == "") {
          setErrors((prevState) => ({
            ...prevState,
            amount: AMOUNT,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            amount: false,
          }));
        }
        break;

      case "amountdue":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            amountdue: AMOUNT_DUE,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            amountdue: false,
          }));
        }
        break;
      case "payment_term_type":
        if (value == 0) {
          setState((prevState) => ({
            ...prevState,
            payment_terms_type: 0,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            payment_terms_type: 1,
          }));
        }
        break;
      case "balancedueday":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            balancedueday: BALANCE_DUE_DAYS,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            balancedueday: false,
          }));
        }
        break;
      default:
    }
  };
  const notify = (msg) => toast(msg);
  const submitRequest = (e) => {
    e.preventDefault();
    if (state.name == "") {
      setErrors((prevState) => ({
        ...prevState,
        name: PAYMENT_CODE,
      }));
    }
    if (state.type == "") {
      setErrors((prevState) => ({
        ...prevState,
        type: EVET_PAYMENT_CODE_TYPE,
      }));
    }
    if (state.event_type == "") {
      setErrors((prevState) => ({
        ...prevState,
        event_type: EVENT_TYPE,
      }));
    }
    if (state.discription == "" && state.type == 1) {
      setErrors((prevState) => ({
        ...prevState,
        discription: DISCRIPTION,
      }));
    }
    if (state.amount == "") {
      setErrors((prevState) => ({
        ...prevState,
        amount: AMOUNT,
      }));
    }

    if (state.amountdue == "") {
      setErrors((prevState) => ({
        ...prevState,
        amountdue: AMOUNT_DUE,
      }));
    }

    if (state.balancedueday === "") {
      setErrors((prevState) => ({
        ...prevState,
        balancedueday: BALANCE_DUE_DAYS,
      }));
    }
    if (state.terms_condition == "") {
      setErrors((prevState) => ({
        ...prevState,
        terms_condition: PAYMENT_TERM_CONDITION,
      }));
    }

    const isDescription =
      state.type == "1" ? (state.discription !== "" ? true : false) : true;

    if (
      state.name !== "" &&
      state.amount !== "" &&
      state.amountdue !== "" &&
      state.balancedueday !== "" &&
      state.terms_condition !== "" &&
      state.type !== "" &&
      isDescription
    ) {
      const result = inputList.map((a) => a.remind).filter((a) => a);
      let data = {
        type: state.type,
        paymentcode_type: state.event_type,
        name: state.name,
        reminder: result,
        ...(state.type == "1" && { discription: state.discription }),
        amount: state.amount,
        payment_due: state.balancedueday, //datePick.toISOString().slice(0, 10),
        payment_terms: state.amountdue,
        payment_terms_type: state.payment_terms_type,
        terms_condition: state.terms_condition,
      };
      props.paymentcodehandler(data);
    }
  };
  useEffect(() => {
    if (props.submitRequest?.success) {
      notify("Data submitted successfully.");
      props.resetCreatePaymentCode();
      setState({ ...initialState });
      setErrors({ ...initialError });
      setInputList([{ remind: "" }]);
      history.push("/paymentcode");
    }
  }, [props.submitRequest]);

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="text-left">
            <h5 className="dash-head">Create Payment Code </h5>
          </div>
          <form>
            <div className="row col-md-6 px-0">
              <div className="col-md-12">
                <div className="form-group event-drp mt-24">
                  <label className="text-gray fs-13">
                    {/* Paymentcode Type <span className="text-red fw-m">*</span> */}
                  </label>
                  <select
                    className={classNames("form-control team-name payterm", {
                      error: errors.type,
                    })}
                    id="type"
                    name="type"
                    value={state.type}
                    onChange={(e) => {
                      setState((prevState) => ({
                        ...prevState,
                        type: e.target.value,
                      }));
                      if (e.target.value === "") {
                        setErrors((preve) => ({
                          ...preve,
                          type: EVENT_TYPE,
                        }));
                      } else {
                        setErrors((preve) => ({
                          ...preve,
                          type: false,
                        }));
                      }
                    }}
                  >
                    <option value="" disabled>
                      Payment request type*
                    </option>
                    <option value="0">Event</option>
                    <option value="1">Custom</option>
                    {/* <option value="2">Club & Parent</option> */}
                  </select>
                  {errors.type && (
                    <span className="text-red small d-block">
                      * {errors.type}
                    </span>
                  )}
                </div>
                {state.type !== "" && (
                  <>
                    {state.type == "0" && (
                      <>
                        {" "}
                        <div
                          className="form-group event-drp mt-24"
                          id="event-type-drp"
                        >
                          {/* <label className="text-gray fs-13">
                            Event Type <span className="text-red fw-m">*</span>
                          </label> */}
                          <select
                            className={classNames(
                              "form-control team-name payterm",
                              {
                                errorBorder: errors.event_type,
                              }
                            )}
                            id="event_type"
                            name="event_type"
                            value={state.event_type}
                            onChange={(e) => {
                              setState((prevState) => ({
                                ...prevState,
                                event_type: e.target.value,
                              }));
                              if (e.target.value === "") {
                                setErrors((preve) => ({
                                  ...preve,
                                  event_type: EVENT_TYPE,
                                }));
                              } else {
                                setErrors((preve) => ({
                                  ...preve,
                                  event_type: false,
                                }));
                              }
                            }}
                          >
                            <option value="" disabled>
                              Event Type*
                            </option>
                            <option value="0">Club</option>
                            <option value="1">Parent/player</option>
                            {/* <option value="2">Club & Parent</option> */}
                          </select>
                          {errors.event_type && (
                            <span className="text-red small d-block">
                              * {errors.event_type}
                            </span>
                          )}
                        </div>
                      </>
                    )}
                    {state.type == "1" && (
                      <>
                        <div className="mt-18">
                          {/* <label className="text-gray fs-13">
                            Title<span className="text-red fw-m">*</span>
                          </label> */}
                          <input
                            type="text"
                            className={classNames("form-control team-name", {
                              errorBorder: errors.name,
                            })}
                            id="name"
                            name="name"
                            value={state.name}
                            placeholder="Title*"
                            required
                            onChange={eventHandler}
                          />
                          {errors.name && (
                            <span className="text-red small d-block">
                              * {errors.name}
                            </span>
                          )}
                        </div>
                        <div className="mt-18" id="custom-tab">
                          {/* <label className="text-gray fs-13">
                            Discription<span className="text-red fw-m">*</span>
                          </label> */}
                          <textarea
                            type="text"
                            className={classNames("form-control team-name", {
                              errorBorder: errors.discription,
                            })}
                            name="discription"
                            value={state.discription}
                            placeholder="Description*"
                            onChange={eventHandler}
                          />
                          {errors.name && (
                            <span className="text-red small d-block">
                              * {errors.discription}
                            </span>
                          )}
                        </div>
                      </>
                    )}

                    {state.type == "0" && (
                      <div className="mt-18">
                        <input
                          // type="number"
                          className={classNames("form-control team-name", {
                            errorBorder: errors.name,
                          })}
                          id="name"
                          name="name"
                          value={state.name}
                          placeholder="Payment code name*"
                          required
                          onChange={eventHandler}
                          // onKeyPress={numericValue}
                        />
                        {errors.name && (
                          <span className="text-red small d-block">
                            * {errors.name}
                          </span>
                        )}
                      </div>
                    )}
                    <div className="mt-18">
                      {/* <label className="text-gray fs-13">
                        Total Amount <span className="text-red fw-m">*</span>
                      </label> */}
                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          errorBorder: errors.amount,
                        })}
                        id="amount"
                        name="amount"
                        value={state.amount}
                        placeholder="Total Amount*"
                        required
                        onChange={eventHandler}
                        // onKeyPress={numericValue}
                      />
                      {errors.amount && (
                        <span className="text-red small d-block">
                          * {errors.amount}
                        </span>
                      )}
                    </div>
                    <div className="mt-24">
                      <strong>Payment Terms*</strong>
                    </div>

                    <div className="d-flex align-items-center">
                      <div className="form-check form-check-inline mr-3">
                        <input
                          className="form-check-input d-none"
                          type="radio"
                          name="payment_terms_type"
                          id="%"
                          value="1"
                          checked={state.payment_terms_type == "1"}
                          onChange={eventHandler}
                        />
                        <label className="form-check-label ml-0" for="%">
                          %
                        </label>
                      </div>
                      <div className="form-check form-check-inline mr-3">
                        <input
                          className="form-check-input d-none"
                          type="radio"
                          name="payment_terms_type"
                          id="$"
                          value="0"
                          checked={state.payment_terms_type == "0"}
                          onChange={eventHandler}
                        />
                        <label className="form-check-label ml-0" for="$">
                          $
                        </label>
                      </div>
                    </div>

                    <div className="form-row ">
                      <div className="col-md-10 col-10">
                        <input
                          type="text"
                          className={classNames("form-control team-name", {
                            errorBorder: errors.amountdue,
                          })}
                          placeholder="Amount due up front *"
                          id="amountdue"
                          name="amountdue"
                          value={state.amountdue}
                          onChange={eventHandler}
                        />
                        {errors.amountdue && (
                          <span className="text-red small d-block">
                            * {errors.amountdue}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <div className="mt-24">
                        <strong>Balance Due*</strong>
                      </div>

                      <input
                        type="text"
                        className={classNames("form-control team-name", {
                          errorBorder: errors.balancedueday,
                        })}
                        placeholder="In days prior to event startdate*"
                        name="balancedueday"
                        value={state.balancedueday}
                        onChange={eventHandler}
                      />
                      {errors.balancedueday && (
                        <span className="text-red small d-block">
                          * {errors.balancedueday}
                        </span>
                      )}
                    </div>
                    <div className="">
                      <div className="mt-24">
                        <strong>Balance due reminder*</strong>
                      </div>
                      {inputList.map((x, i) => {
                        return (
                          // reminderAr.length - 1 === i &&
                          <>
                            {inputList.length !== 1 && (
                              <div className="text-right add-more mt-2">
                                <Link
                                  to="#"
                                  className="text-decoration-none"
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  -Remove
                                </Link>
                              </div>
                            )}
                            <input
                              type="text"
                              className={classNames("form-control team-name")}
                              placeholder="No. of days before balance due*"
                              name="remind"
                              value={x.remind}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                            {inputList.length - 1 === i && (
                              <div className="text-right add-more mt-2">
                                <Link
                                  to="#"
                                  className="text-decoration-none"
                                  onClick={handleAddClick}
                                >
                                  + Add More
                                </Link>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                    <div className=" mt-18">
                      <div className="mt-24">
                        <strong>Terms & Conditions</strong>
                      </div>
                      {/* <input type="number" className="form-control team-name" placeholder="Number of day" id="balancedueday" name="balancedueday" value={state.balancedueday} required onChange={eventHandler} /> */}
                      <div
                        name="terms_condition"
                        id="terms_condition"
                        className={classNames({
                          errorBorder: errors.terms_condition,
                        })}
                      >
                        <CKEditor
                          editor={ClassicEditor}
                          data={state.terms_condition}
                          onChange={handleChange}
                          name="terms_condition"
                          id="terms_condition"
                        />
                      </div>

                      {errors.terms_condition && (
                        <span className="text-red small d-block">
                          * {errors.terms_condition}
                        </span>
                      )}
                    </div>
                  </>
                )}
                <div className="mt-3 text-left">
                  <a
                    className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                    onClick={submitRequest}
                  >
                    Create
                  </a>
                </div>
                <Toaster />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

// export default withRouter(CreateInvoice)

const mapStateToProp = (state) => ({
  submitRequest: state.createPaymentCodeReducer,
});

const mapDispatchToProps = (dispatch) => ({
  paymentcodehandler: (user) => dispatch(createPaymentCode(user)),
  resetCreatePaymentCode: () => dispatch({ type: RESET_CREATE_PAYMENT_CODE }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(CreatePaymentCode));
