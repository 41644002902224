import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useHistory, Redirect, withRouter } from "react-router-dom";
import $, { map } from "jquery";
// import axios from 'axios';
import {
  getAccountDetails,
  reSet,
  resetReduxStore,
  signout,
} from "../../../service/action/userAction";
import {
  RESET_ACCOUNT_DETAILS,
  RESET_ALL_REPORT,
  RESET_GET_CREDIT,
  RESET_GET_PAYMENT_REQUEST,
  RESET_OUTSTANDING_REPORT,
  RESET_RECEIVE_REPORT,
  RESET_REFUND_REPORT,
  RESET_SIGNIN,
} from "../../../service/constants";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const Header = (props) => {
  const history = useHistory();

  const getVal = localStorage.getItem("status");

  const accountDetails = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );

  const getToken = localStorage.getItem("token");
  const openSidebar = () => {
    $("#sidebar").toggleClass("active");
  };

  const header = localStorage.getItem("type");

  const signOutResp = useSelector((state) => state.signoutReducer);

  const userId = localStorage.getItem("Id");

  // const ProfileImage=localStorage.setItem("profileImg",accountDetails.profile_image)

  //for confirmation when sign out button click
  // This is from npm library
  const submit = () => {
    confirmAlert({
      title: "Confirm to sign out",
      message: "Are you sure you want to sign out?",
      buttons: [
        {
          label: "Yes",
          onClick: () => alert("Click Yes"),
          //   style:{backgroud-co    lor:"#143D4A"}
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
      customUI: ({ onClose }) => {
        return (
          <div id="react-confirm-alert">
            <div
              className="react-confirm-alert-overlay"
              style={{ background: "rgba(255, 255, 255, 0.1)" }}
            >
              <div className="react-confirm-alert">
                <div className="react-confirm-alert-body">
                  <h2 style={{ color: "#143D4A" }}>Confirm to sign out</h2>
                  Are you sure you want to sign out?
                  <div className="react-confirm-alert-button-group">
                    <button
                      style={{ background: "#143D4A" }}
                      onClick={() => {
                        signout();
                        onClose();
                      }}
                    >
                      Yes
                    </button>
                    <button
                      style={{ background: "#143D4A" }}
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const signout = () => {
    // alert("outer logout")
    // if (props.signout?.success) {
    // alert("logout")

    props.resetAccountDetails();
    props.signoutHandler();

    localStorage.removeItem("token");
    localStorage.removeItem("status");
    localStorage.removeItem("club");
    localStorage.removeItem("PaymentCodeId");
    localStorage.removeItem("auth");
    localStorage.clear();
    sessionStorage.removeItem("link");
    sessionStorage.clear();
    props.resetSigninHandler();
    props.resetRedux();
    props.resetGetPaymentRequest();

    history.push("/");
  };

  useEffect(() => {
    if (props.signout?.success) {
    }
  }, [props.signout]);

  const switchAction = () => {
    props.resetAccountDetails();
    props.reset_getCreditReducer();
    props.resetReceive();
    props.resetOutStanding();
    props.resetRefund();
    props.resetAllreport();
  };

  return (
    <React.Fragment>
      {header ? (
        <>
          <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <div className="navbar-menu-wrapper d-flex align-items-stretch w-100">
              <button
                onClick={openSidebar}
                className="navbar-toggler navbar-toggler-right d-lg-none align-self-center p-0"
                type="button"
                data-toggle="offcanvas"
              >
                <span className="mdi mdi-menu"></span>
              </button>
              <ul className="navbar-nav navbar-nav-right ml-auto">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link"
                    id="actions"
                    href="#"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="nav-profile-text">
                      <i className="mdi mdi-plus-circle-outline mr-3 action-ico"></i>
                    </div>
                    <div className="nav-action-text">
                      <p className="mb-1 mr-3 head-text">Actions</p>
                    </div>
                  </a>

                  <div
                    className="dropdown-menu dropdown-menu-right navbar-dropdown event-drp"
                    aria-labelledby="actions"
                  >
                    <div className="dropdown-divider"></div>
                    {header == 4 ? (
                      [
                        <div key={getVal}>
                          <Link
                            className="dropdown-item"
                            to={`/events/find-event`}
                          >
                            <i className="mdi mdi-plus"></i> Find Event{" "}
                          </Link>
                          <Link
                            className="dropdown-item"
                            to={`/teams/add-coach`}
                          >
                            <i className="mdi mdi-plus"></i> Add Coach{" "}
                          </Link>
                          <Link
                            className="dropdown-item"
                            to={`/teams/invite-player`}
                          >
                            <i className="mdi mdi-plus"></i> Invite Player{" "}
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="/send-broadcast-messages"
                          >
                            <i className="mdi mdi-message-text"></i> Send
                            Broadcast{" "}
                          </Link>
                        </div>,
                      ]
                    ) : (
                      <>
                        {header == 1
                          ? [
                              [
                                <div key={getVal}>
                                  <Link
                                    className="dropdown-item"
                                    to={`/create-event`}
                                  >
                                    <i className="mdi mdi-plus"></i> Create
                                    Event{" "}
                                  </Link>
                                  {/* <Link className="dropdown-item" to="/create-team">
                                                                <i className="mdi mdi-plus"></i> Add Team </Link> */}
                                  <Link
                                    className="dropdown-item"
                                    to="/club-invite"
                                  >
                                    <i className="mdi mdi-plus"></i> Add Club{" "}
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to={{
                                      pathname: `/notification-accounts/${accountDetails?._id}`,
                                      state: {
                                        id: accountDetails?._id,
                                        type: parseInt(
                                          localStorage.getItem("type")
                                        ),
                                        name: "dm",
                                      },
                                    }}
                                  >
                                    <i className="mdi mdi-message-text"></i>{" "}
                                    Send DM
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="/send-broadcast-messages"
                                  >
                                    <i className="mdi mdi-message-text"></i>{" "}
                                    Send Broadcast{" "}
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="/new-payment-request"
                                  >
                                    <i className="mdi mdi-plus"></i>Create
                                    Payment Request{" "}
                                  </Link>
                                </div>,
                              ],
                            ]
                          : ""}
                      </>
                    )}
                  </div>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link count-indicator"
                    // to={
                    //   header == 1
                    //     ? "/notification-accounts"
                    //     : "/account-notifications"
                    // }

                    to={{
                      pathname:
                        header == 1
                          ? `/notification-accounts/${userId}`
                          : `/account-notifications/${userId}`,
                      state: {
                        id: userId,
                        type: +header,
                      },
                    }}
                  >
                    <i className="mdi mdi-bell-outline noti-ico"></i>
                    <span className="count-symbol bg-danger"></span>
                  </Link>
                </li>
                <li className="nav-item nav-profile dropdown">
                  <a
                    className="nav-link"
                    id="profileDropdown"
                    href="#"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="nav-profile-text">
                      <p className="mb-1 mr-3 head-text">
                        {localStorage.getItem("username")}{" "}
                      </p>
                    </div>
                    <div className="nav-profile-img">
                      {accountDetails ? (
                        <img
                          src={
                            accountDetails.profile_image == ""
                              ? "./images/pch-images/pch.svg"
                              : accountDetails.profile_image
                          }
                          className="img-fluid"
                        />
                      ) : (
                        ""
                      )}
                      <span className="availability-status online"></span>
                    </div>
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right navbar-dropdown event-drp"
                    aria-labelledby="profileDropdown"
                  >
                    <div className="dropdown-divider"></div>
                    {header == 4 ? (
                      <div>
                        <Link className="dropdown-item" to="/account">
                          <i className="mdi mdi-account mr-2 text-primary"></i>{" "}
                          My Account{" "}
                        </Link>
                        <Link className="dropdown-item" to="/add-account">
                          <i className="mdi mdi-account-plus mr-2 text-primary"></i>{" "}
                          Add New Account{" "}
                        </Link>

                        <Link
                          className="dropdown-item"
                          to="/switch"
                          onClick={switchAction}
                        >
                          <i className="mdi mdi-swap-horizontal mr-2 text-primary"></i>{" "}
                          Select Accounts{" "}
                        </Link>

                        <Link className="dropdown-item" to="/changepassword">
                          <i className="fa-solid fa-lock mr-2 text-primary"></i>{" "}
                          Change Password
                        </Link>
                        {/* <Link className="dropdown-item" to="/season" >
                                                    <i className="mdi mdi-plus mr-2 text-primary"></i> Seasons </Link> */}
                        {/* <Link className="dropdown-item" to="/league" >
                                                    <i className="mdi mdi-account mr-2 text-primary"></i> League </Link> */}
                        {/* <Link className="dropdown-item" to="/field" >
                                                    <i className="mdi mdi-plus mr-2 text-primary"></i> Fields </Link>
                                                <Link className="dropdown-item" to="/division" >
                                                    <i className="mdi mdi-plus mr-2 text-primary"></i> Divisions </Link> */}
                        {/* <Link className="dropdown-item" to="/division-pool" >
                                                    <i className="mdi mdi-plus mr-2 text-primary"></i> Divisions/Pool </Link> */}

                        {/* <a className="dropdown-item" href="https://www.google.com/" rel="noopener noreferrer" target="_blank" >
                                                    <i className="mdi mdi-information-variant mr-2 text-primary"></i> About Us </a>
                                                <a className="dropdown-item" href="https://www.google.com/" rel="noopener noreferrer" target="_blank" >
                                                    <i className="mdi mdi-information-variant mr-2 text-primary"></i> Contact Us </a>
                                                <a className="dropdown-item" href="https://www.google.com/" rel="noopener noreferrer" target="_blank">
                                                    <i className="mdi mdi-information-variant mr-2 text-primary"></i> Help </a> */}
                        <a className="dropdown-item" onClick={submit}>
                          <i className="mdi mdi-logout mr-2 text-primary"></i>{" "}
                          Sign out{" "}
                        </a>
                      </div>
                    ) : (
                      <>
                        {header == 1 ? (
                          <>
                            <div style={{ fontSize: "24px" }}>
                              <Link className="dropdown-item" to="/account">
                                <i className="mdi mdi-account mr-2 text-primary"></i>{" "}
                                My Account{" "}
                              </Link>
                              <Link className="dropdown-item" to="/add-account">
                                <i className="mdi mdi-account-plus mr-2 text-primary"></i>{" "}
                                Add New Account{" "}
                              </Link>

                              <Link
                                className="dropdown-item"
                                to="/switch"
                                onClick={switchAction}
                              >
                                {/* <i className="fas fa-exchange-alt mr-2 text-primary"></i>  */}
                                <i className="mdi mdi-swap-horizontal mr-2 text-primary"></i>
                                Select Accounts{" "}
                              </Link>
                              {/* <Link className="dropdown-item" to="/season" >
                                                            <i className="mdi mdi-plus mr-2 text-primary"></i> Season </Link> */}
                              {/* <a className="dropdown-item" href="https://www.google.com/" rel="noopener noreferrer" target="_blank" >
                                                            <i className="mdi mdi-information-variant mr-2 text-primary"></i> About us </a>
                                                        <a className="dropdown-item" href="https://www.google.com/" rel="noopener noreferrer" target="_blank" >
                                                            <i className="mdi mdi-information-variant mr-2 text-primary"></i> Contact us </a>
                                                        <a className="dropdown-item" href="https://www.google.com/" rel="noopener noreferrer" target="_blank">
                                                            <i className="mdi mdi-information-variant mr-2 text-primary"></i> Help </a> */}
                              {/* <Link className="dropdown-item" to="/league" >
                                                            <i className="mdi mdi-account mr-2 text-primary"></i> League </Link>
                                                        <Link className="dropdown-item" to="/field" >
                                                            <i className="mdi mdi-account mr-2 text-primary"></i> Field </Link>
                                                        <Link className="dropdown-item" to="/division" >
                                                            <i className="mdi mdi-account mr-2 text-primary"></i> Division </Link> */}
                              <a className="dropdown-item" onClick={submit}>
                                <i className="mdi mdi-logout mr-2 text-primary"></i>{" "}
                                Sign out{" "}
                              </a>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <div
                    className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                    aria-labelledby="messageDropdown"
                  >
                    <h6 className="p-3 mb-0">Messages</h6>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <img
                          src="assets/./images/faces/face4.jpg"
                          alt="image"
                          className="profile-pic"
                        />
                      </div>
                      <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                        <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                          Mark send you a message
                        </h6>
                        <p className="text-gray mb-0"> 1 Minutes ago </p>
                      </div>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <img
                          src="assets/./images/faces/face2.jpg"
                          alt="image"
                          className="profile-pic"
                        />
                      </div>
                      <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                        <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                          Cregh send you a message
                        </h6>
                        <p className="text-gray mb-0"> 15 Minutes ago </p>
                      </div>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <img
                          src="assets/./images/faces/face3.jpg"
                          alt="image"
                          className="profile-pic"
                        />
                      </div>
                      <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                        <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                          Profile picture updated
                        </h6>
                        <p className="text-gray mb-0"> 18 Minutes ago </p>
                      </div>
                    </a>
                    <div className="dropdown-divider"></div>
                    <h6 className="p-3 mb-0 text-center">4 new messages</h6>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </>
      ) : null}
    </React.Fragment>
  );
};

// export default Header;

const mapStateToProps = (state) => ({
  signout: state.signoutReducer,
  switch: state.switchAccountReducer,
  accountdetails: state.editAccountDetailsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // getEventDataHandler:user=>dispatch(getDatas(user))
  // //     // reset:(e) => dispatch(reSet(e))
  signoutHandler: (user) => dispatch(signout(user)),
  resetSigninHandler: () => dispatch({ type: RESET_SIGNIN }),
  getAccountDetails: (user) => dispatch(getAccountDetails(user)),
  resetAccountDetails: () => dispatch({ type: RESET_ACCOUNT_DETAILS }),
  resetRedux: () => dispatch(resetReduxStore()),
  reset_getCreditReducer: () => dispatch({ type: RESET_GET_CREDIT }),
  resetReceive: () => dispatch({ type: RESET_RECEIVE_REPORT }),
  resetOutStanding: () => dispatch({ type: RESET_OUTSTANDING_REPORT }),
  resetRefund: () => dispatch({ type: RESET_REFUND_REPORT }),
  resetAllreport: () => dispatch({ type: RESET_ALL_REPORT }),
  resetGetPaymentRequest: () => dispatch({ type: RESET_GET_PAYMENT_REQUEST }),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
