import {
  DISCOUNT_CODE_APPLY_ERROR,
  DISCOUNT_CODE_APPLY_LOADING,
  DISCOUNT_CODE_APPLY_SUCCESS,
  RESET_DISCOUNT_CODE_APPLY,
} from "../../../constants";

let initialState = {
  loading: false,
  user: null,
  error: false,
  errors: [],
  success: false,
  message: "",
};

function applydiscountcodeReducer(state = initialState, action) {
  switch (action.type) {
    case DISCOUNT_CODE_APPLY_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case DISCOUNT_CODE_APPLY_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        message: !action.user?.status ? action.user?.message : "",
        user: action.user,
      };

    case DISCOUNT_CODE_APPLY_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        errors: action.errors,
        msg: action.errors,
      };

    case RESET_DISCOUNT_CODE_APPLY:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        errors: [],
        user: [{}],
      };

    default:
      return state;
  }
}

export default applydiscountcodeReducer;
