import { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";

import {
  ChangeStatusAction,
  createSubSuperAdmin,
  deleteSubSuperadminList,
  getSubSuperadminList,
  updateSubSuperadminList,
} from "../../Services/Actions/userAction";
import {
  RESET_ADD_SUB_SUPER_ADMIN,
  RESET_CHANGE_STATUS,
  RESET_DELETE_SUB_SUPERADMIN_LIST,
  RESET_UPDATE_SUB_SUPERADMIN_LIST,
  removeStorage,
  validEmailRegex,
} from "../../Services/constant";
import BreadcrumbHistory from "../BreadcrumbHistory/BreadcrumbHistory";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import classNames from "classnames";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";

const DATA_ITEM_KEY = "_id";

function SuperSubAdmin(props) {
  const history = useHistory();

  const [xlShow, setXlShow] = useState(false);
  const [state, setState] = useState([]);
  const [InitialState, setInitialState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [status, setStatus] = useState({
    status: "",
    isChanged: false,
  });
  const [selectedID, setSelectedId] = useState("");
  const [deletedID, setdeletedID] = useState();

  const handleClose = () => setXlShow(false);
  const handleShow = () => setXlShow(true);

  const [delShow, setDelShow] = useState(false);
  const delDialClose = () => setDelShow(false);
  const delDialShow = () => setDelShow(true);

  const [successShow, setSuccessShow] = useState(false);
  const successDialClose = () => setSuccessShow(false);
  const successDialShow = () => {
    setDelShow(false);
    // setSuccessShow(true);
  };
  const InitialForm = {
    email: "",
    first_name: "",
    last_name: "",
  };

  const InitialError = {
    email: false,
    first_name: false,
    last_name: false,
  };

  const [detailsForm, setDetailsForm] = useState(InitialForm);
  const [errorForm, setErrorForm] = useState(InitialError);

  const [pageDetails, setPageDetails] = useState({
    skip: 0,
    take: 10,
  });

  useEffect(() => {
    props.getSubSuperadminList(0);
  }, []);

  useEffect(() => {
    if (props.getSubSuperAdminListReducer.success) {
      const data = props.getSubSuperAdminListReducer.data;
      const _data = data.data.map((e) => {
        return { ...e };
      });
      setState(_data);
      setInitialState(_data);
      setLoading(false);
    } else if (props.getSubSuperAdminListReducer.error) {
      const errMsg = props.getSubSuperAdminListReducer.errorMsg;
      !!errMsg && toast.error(errMsg);
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      }
    }
  }, [props.getSubSuperAdminListReducer]);

  useEffect(() => {
    if (props.getSubSuperAdminListReducer.success) {
      if (searchVal !== "") {
        const data = [...InitialState];
        const filter = data.filter(
          (e) =>
            e.first_name.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.last_name.toLowerCase().includes(searchVal.toLowerCase()) ||
            e.email.toLowerCase().includes(searchVal.toLowerCase())
        );
        setState(filter);
      } else {
        const data = [...InitialState];
        setState(data);
      }
    }
  }, [searchVal]);

  const customHeader = (cell) => {
    return <b title={cell.title}>{cell.title}</b>;
  };
  const customStatus = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <Button
          type="button"
          className="btn-status  btn-primary active"
          onClick={(e) => {
            setStatus({
              status: cell.dataItem.status == 1 ? "deactivate" : "activate",
              isChanged: true,
            });
            setSelectedId(cell.dataItem?._id);
          }}
        >
          {cell.dataItem.status == 1 ? "Active" : "Inactive"}
        </Button>
      </td>
    );
  };

  useEffect(() => {
    if (props.ChangeStatusReducer.success) {
      const _data = state.map((e) => {
        if (e?._id == selectedID) {
          return {
            ...e,
            status: e.status == 0 ? 1 : 0,
          };
        } else
          return {
            ...e,
          };
      });
      setState(_data);
      setStatus({
        status: "",
        isChanged: false,
      });
      setSelectedId("");
      toast.success("Status Changed Successfuly");
      props.resetStatus();
    } else if (props.ChangeStatusReducer.error) {
      setSelectedId("");
      toast.success("Get Error To change Status");
      props.resetStatus();
    }
  }, [props.ChangeStatusReducer]);

  const customAction = (cell) => {
    return (
      <td className="k-table-td" role="gridcell">
        <button
          onClick={() => {
            const details = cell.dataItem;
            setSelectedId(details._id);
            setDetailsForm({
              email: details.email,
              first_name: details.first_name,
              last_name: details.last_name,
              status: details.status == "Active" ? 1 : 0,
            });
            setErrorForm({
              ...InitialError,
            });
            handleShow();
          }}
          className="btn-link btn text-secondary p-0 mx-2"
        >
          <i className="ri-pencil-fill"></i>
        </button>
        <button
          onClick={() => {
            const details = cell.dataItem;
            delDialShow();
            setSelectedId(details._id);
          }}
          className="btn-link btn text-danger p-0 mx-2"
        >
          <i className="ri-delete-bin-fill"></i>
        </button>
      </td>
    );
  };
  const customCell = (cell) => {
    return (
      <td {...cell.tdProps} title={cell.children}>
        {cell.children}
      </td>
    );
  };
  const grid = (
    <Grid
      style={
        {
          // height: "60vh",
        }
      }
      data={state.slice(pageDetails.skip, pageDetails.skip + pageDetails.take)}
      skip={pageDetails.skip}
      take={pageDetails.take}
      total={state.length}
      pageable={{
        buttonCount: 3,
        pageSizes: [5, 10, 15],
      }}
      onPageChange={(e) => {
        setPageDetails(() => ({
          ...e.page,
          take: e.page.take,
        }));
      }}
      dataItemKey={DATA_ITEM_KEY}
    >
      <GridNoRecords>No records are available</GridNoRecords>
      <GridColumn
        field="first_name"
        title="First Name"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="last_name"
        title="Last Name"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="email"
        title="Email"
        headerCell={customHeader}
        cells={{
          data: customCell,
        }}
      />
      <GridColumn
        field="status"
        title="Status"
        headerCell={customHeader}
        cell={customStatus}
      />
      <GridColumn
        field="action"
        title="Actions"
        headerCell={customHeader}
        cell={customAction}
      />
    </Grid>
  );

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setDetailsForm((prev) => ({
      ...prev,
      [name]: value,
    }));

    switch (name) {
      case "first_name":
        if (value == "") {
          errorForm["first_name"] = "Required";
        } else {
          errorForm["first_name"] = false;
        }
        break;

      case "last_name":
        if (value == "") {
          errorForm["last_name"] = "Required";
        } else {
          errorForm["last_name"] = false;
        }
        break;

      case "email":
        if (value == "") {
          errorForm["email"] = "Required";
        } else if (!validEmailRegex.test(value)) {
          errorForm["email"] = "Enter Valid Email";
        } else {
          errorForm["email"] = false;
        }
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (detailsForm.first_name == "") {
      setErrorForm((pre) => ({
        ...pre,
        first_name: "Required",
      }));
    }
    if (detailsForm.last_name == "") {
      setErrorForm((pre) => ({
        ...pre,
        last_name: "Required",
      }));
    }
    if (detailsForm.email == "") {
      setErrorForm((pre) => ({
        ...pre,
        email: "Required",
      }));
    }

    if (
      detailsForm.first_name !== "" &&
      detailsForm.last_name !== "" &&
      detailsForm.email !== "" &&
      !errorForm.email
    ) {
      !selectedID
        ? props.createSubSuperAdmin({
            ...detailsForm,
            role: 0,
            password: 123456789,
          })
        : props.updateSubSuperadminList({
            id: selectedID,
            details: {
              ...detailsForm,
              role: "0",
            },
          });
    }
  };

  useEffect(() => {
    if (props.createSubSuperAdminReducer.success) {
      handleClose();
      toast.success("Super sub admin created successfully");
      props.resetCreateSubSuperadmin();
      props.getSubSuperadminList(0);
      setLoading(true);
      setSelectedId("");
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
    } else if (props.createSubSuperAdminReducer.error) {
      const errMsg = props.createSubSuperAdminReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.createSubSuperAdminReducer]);

  useEffect(() => {
    if (props.updateSubSuperAdminListReducer.success) {
      handleClose();
      toast.success("Super sub admin updated successfully");
      props.resetUpdateSubSuperadmin();
      props.getSubSuperadminList(0);
      setLoading(true);
      setSelectedId("");
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
    } else if (props.updateSubSuperAdminListReducer.error) {
      const errMsg = props.updateSubSuperAdminListReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.updateSubSuperAdminListReducer]);

  const handleDelete = (e) => {
    e.preventDefault();
    props.deleteSubSuperadminList(selectedID);
  };

  useEffect(() => {
    if (props.deleteSubSuperAdminListReducer.success) {
      delDialClose();
      toast.success("Super sub admin deleted successfully");
      props.resetDeleteSubSuperadmin();
      props.getSubSuperadminList(0);
      setLoading(true);
      setSelectedId("");
      setDetailsForm(InitialForm);
      setErrorForm(InitialError);
    } else if (props.deleteSubSuperAdminListReducer.error) {
      const errMsg = props.deleteSubSuperAdminListReducer.errorMsg;
      if (errMsg == "UnAuthorized") {
        removeStorage();
        history.push("/superadmin/login");
      } else {
        if (!!errMsg.email) {
          toast.error(errMsg.email);
        } else {
          toast.error(Object.values[0]);
        }
      }
    }
  }, [props.deleteSubSuperAdminListReducer]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2 className="page-heading">Super Sub Admin</h2>
        {/* <Breadcrumb>
          <Breadcrumb.Item href="/superadmin/Dashboard">Home</Breadcrumb.Item>
          <Breadcrumb.Item active className="text-primary">
            User
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Super Sub Admin</Breadcrumb.Item>
        </Breadcrumb> */}
      </div>
      <Row className="justify-content-between mx-0">
        <Col md="4" className="search px-0">
          <i className="ri-search-line"></i>
          <Form.Control
            type="text"
            placeholder="Search"
            value={searchVal}
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
          />
        </Col>
        <Col md="6" className="text-end px-0">
          <button className="btn-secondary px-4 py-2" onClick={handleShow}>
            Add Super Sub Admin
          </button>
        </Col>
      </Row>
      <div className="table-card">{grid}</div>

      <Modal
        size="md"
        show={xlShow}
        onHide={() => {
          setDetailsForm(InitialForm);
          setErrorForm(InitialError);
          handleClose();
        }}
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title>Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-4" controlId="">
              <Form.Label>
                First Name <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="first_name"
                value={detailsForm.first_name}
                onChange={handleChange}
                className={classNames({
                  errorBorder: errorForm.first_name,
                })}
              />
            </Form.Group>

            <Form.Group className="mb-4" controlId="">
              <Form.Label>
                Last Name <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="last_name"
                value={detailsForm.last_name}
                onChange={handleChange}
                className={classNames({
                  errorBorder: errorForm.last_name,
                })}
              />
            </Form.Group>

            <Form.Group className="mb-4" controlId="">
              <Form.Label>
                Email <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder=""
                name="email"
                value={detailsForm.email}
                onChange={handleChange}
                className={classNames({
                  errorBorder: errorForm.email,
                })}
                disabled={selectedID !== ""}
              />
            </Form.Group>

            <div className="text-end ml-auto">
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setDetailsForm(InitialForm);
                  setErrorForm(InitialError);
                  handleClose();
                }}
                style={{ fontSize: "18px" }}
                className="mr-3 pointer"
              >
                Cancel
              </Button>
              <Button variant="secondary" type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete confirmation */}
      <Modal
        size="sm"
        show={delShow}
        onHide={delDialClose}
        centered
        className="del-dialogue"
      >
        {/* <Modal.Header closeButton>
                              <Modal.Title>delete</Modal.Title>
                        </Modal.Header> */}
        <Modal.Body>
          <h6 className="text-center">
            Are you sure you want to delete this account?
          </h6>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Button className="btn-gray" onClick={delDialClose}>
            No
          </Button>
          <Button variant="secondary" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete success */}
      <Modal
        size="sm"
        show={successShow}
        onHide={successDialClose}
        centered
        className="del-dialogue success"
      >
        <Modal.Body className="text-center">
          <i className="ri-close-circle-line fa-4x text-secondary"></i>
          <h5 className="text-center">Record deleted Successfully !!</h5>
        </Modal.Body>
      </Modal>

      {/* Status Changed  */}
      <Modal
        size="sm"
        show={status.isChanged}
        onHide={() =>
          setStatus({
            isChanged: false,
            status: "",
          })
        }
        centered
        className="del-dialogue"
      >
        <Modal.Body>
          <h6 className="text-center">
            Are you sure you want to <strong>{status.status}</strong> this account?
          </h6>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Button
            className="btn-gray"
            onClick={() =>
              setStatus({
                isChanged: false,
                status: "",
              })
            }
          >
            No
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              props.ChangeStatusHandler(selectedID);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
const mapStateToProp = (state) => ({
  createSubSuperAdminReducer:
    state.superAdminRootReducer.UserReducer.createSubSuperAdmin,
  getSubSuperAdminListReducer:
    state.superAdminRootReducer.UserReducer.getSubSuperAdminList,
  updateSubSuperAdminListReducer:
    state.superAdminRootReducer.UserReducer.updateSubSuperAdminList,
  deleteSubSuperAdminListReducer:
    state.superAdminRootReducer.UserReducer.deleteSubSuperAdminList,
  ChangeStatusReducer: state.superAdminRootReducer.UserReducer.changestatus,
});

const mapDispatchToProps = (dispatch) => ({
  createSubSuperAdmin: (details) => dispatch(createSubSuperAdmin(details)),
  getSubSuperadminList: (role) => dispatch(getSubSuperadminList(role)),
  updateSubSuperadminList: (id) => dispatch(updateSubSuperadminList(id)),
  deleteSubSuperadminList: (id) => dispatch(deleteSubSuperadminList(id)),
  ChangeStatusHandler: (id) => dispatch(ChangeStatusAction(id)),
  resetCreateSubSuperadmin: () => dispatch({ type: RESET_ADD_SUB_SUPER_ADMIN }),
  resetUpdateSubSuperadmin: () =>
    dispatch({ type: RESET_UPDATE_SUB_SUPERADMIN_LIST }),
  resetDeleteSubSuperadmin: () =>
    dispatch({ type: RESET_DELETE_SUB_SUPERADMIN_LIST }),
  resetStatus: () => dispatch({ type: RESET_CHANGE_STATUS }),
});

export default connect(mapStateToProp, mapDispatchToProps)(SuperSubAdmin);
