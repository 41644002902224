import {
  REQ_ADD_PAYMENT_ERROR,
  REQ_ADD_PAYMENT_LOADING,
  REQ_ADD_PAYMENT_SUCCESS,
  RESET_REQ_ADD_PAYMENT,
} from "../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
};

export default function reqaddpaymentReducer(state = initialState, action) {
  switch (action.type) {
    case REQ_ADD_PAYMENT_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case REQ_ADD_PAYMENT_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        success: true,
        message: action.msg,
      };

    case REQ_ADD_PAYMENT_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        success: false,
        errors: action.errors,
        msg: action.errors,
      };

    case RESET_REQ_ADD_PAYMENT:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        errors: [],
        user: [{}],
      };
    default:
      return state;
  }
}
