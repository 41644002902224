import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { connect, useSelector } from "react-redux";
import { deleteLeague, getLeague } from "../../service/action/Club/clubAction";
import {
  RESET_DELETE_EVENT,
  RESET_LEAGUE,
  UPDATE_RESET_LEAGUE,
} from "../../service/constants";
import Toaster from "../../Toaster/Toaster";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../../Spinner/Spinner";
import DeletePopUp from "../components/DeletePopUp/DeletePopUp";
import { toast } from "react-toastify";
import BreadcrumbHistory from "../../BreadcumbHistory/BreadcrumbHistory";

const League = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const accountDetails = useSelector(
    (state) => state.accountDetailsReducer.user.data
  );
  const league = useSelector((state) => state.getLeagueReducer.user);
  const notify = (msg) => toast(msg);
  const location = useLocation();

  useEffect(() => {
    if (accountDetails) {
      props.getLeagueHandler();
    }
  }, [accountDetails]);

  useEffect(() => {
    if (props.league?.success) {
      setLoading(false);
    }
  }, [props.league]);

  useEffect(() => {
    if (props.createLeagueMsg.success) {
      notify("League Created");
      props.resetCreateLeague();
    }
  }, [props.createLeagueMsg]);
  useEffect(() => {
    if (props.updateLeagueREducer.success) {
      notify("League edited");
      props.resetUpdate();
    }
  }, [props.updateLeagueREducer]);

  useEffect(() => {
    if (props.submit.success) {
      props.getLeagueHandler();
      notify("League deleted");
      props.resetdeletePaymentCodeReducer();
    }
  }, [props.submit]);

  const Searchval = (e) => {
    setSearchValue(e.target.value);
    UpdateArray(e.target.value);
  };

  const UpdateArray = (search) => {
    var query = search;
    $(".team-table .filter-string").each(function (i, v) {
      if ($(v).text().toLowerCase().indexOf(query) === -1) {
        $(v).parents(".team-row").addClass("d-none");
      } else {
        $(v).parents(".team-row").removeClass("d-none");
      }
    });
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <BreadcrumbHistory
          url={location.pathname !== "/" ? location.pathname : ""}
        />

        <div className="content-wrapper">
          <div className="text-left ">
            <div className="create-team-head-space">
              <div className="d-md-flex align-items-center d-none">
                <h5 className="dash-head mr-3 mb-0">Leagues</h5>
                <div className="search-section mt-3 mt-md-0">
                  <i className="mdi mdi-magnify search-ico"></i>
                  <input
                    type="text"
                    className="form-control"
                    id="search"
                    name="search"
                    value={searchValue}
                    placeholder="Search"
                    onChange={Searchval}
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="mt-2 mt-md-0 text-right">
                <Link to="/leagues/create-league">
                  <button className="btn-save">Add</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="team-details mt-4">
            {loading ? (
              <div className="h-[30rem] w-full flex flex-col justify-center items-center">
                <Spinner />
              </div>
            ) : (
              <>
                <div className="green-section mt-30">
                  {!league || (!!league && league.length === 0) ? (
                    <>
                      <div className="container d-flex justify-content-center align-items-center">
                        <span className="noti-type">No leagues to display</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="table-responsive">
                        <table className="table team-table mt-24">
                          <thead>
                            <tr>
                              <th>
                                <b>League Name</b>
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {league.map((v, i) => {
                              return (
                                <tr className="team-row" key={v._id}>
                                  <td>
                                    <span className="filter-string">
                                      {v.league_name}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="dropdown event-drp">
                                      <i
                                        className="mdi mdi-dots-vertical"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      ></i>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuLink"
                                      >
                                        <Link
                                          className="dropdown-item"
                                          to={`/leagues/${v._id}`}
                                          onClick={localStorage.setItem(
                                            "league_id",
                                            v._id
                                          )}
                                        >
                                          <i className="mdi mdi-border-color"></i>
                                          Edit
                                        </Link>
                                        <Link
                                          className="dropdown-item"
                                          to="/leagues"
                                          onClick={() => {
                                            setIsDelete(true);
                                            setDeleteId(v._id);
                                          }}
                                        >
                                          <i className="fas fa-trash-alt"></i>{" "}
                                          Delete
                                        </Link>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => props.deleteLeague(deleteId)}
      />
      <Toaster />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  submit: state.deleteEventReducer,
  createLeagueMsg: state.createLeagueReducer,
  updateLeagueREducer: state.updateLeagueREducer,
  bookmark: state.LeagueBookmarkReducer,
  league: state.getLeagueReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getLeagueHandler: (user) => dispatch(getLeague(user)),
  resetCreateLeague: () => dispatch({ type: RESET_LEAGUE }),
  deleteLeague: (user) => dispatch(deleteLeague(user)),
  resetdeletePaymentCodeReducer: () => dispatch({ type: RESET_DELETE_EVENT }),
  resetUpdate: () => dispatch({ type: UPDATE_RESET_LEAGUE }),
});

export default connect(mapStateToProps, mapDispatchToProps)(League);
