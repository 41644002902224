import { GET_CLUB_TEAM_ERROR, GET_CLUB_TEAM_LOADING, GET_CLUB_TEAM_SUCCESS, GET_TEAM_DETAIL_ERROR, GET_TEAM_DETAIL_LOADING, GET_TEAM_DETAIL_SUCCESS, RESET_TEAM_DETAIL_ERROR } from "../../../constants";


let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {}
};

export default function getTeamDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEAM_DETAIL_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case GET_TEAM_DETAIL_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };

    case GET_TEAM_DETAIL_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    case RESET_TEAM_DETAIL_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: false, loading: false, success: false };

    default:
      return state;
  }
}
