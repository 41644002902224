import { GET_PAYMENTCODE_LOADING, GET_PAYMENTCODE_SUCCESS, GET_PAYMENTCODE_ERROR, RESET_GET_PAYMENTCODE, DIVISIONCUSTOMPAYMENTCODE_LOADING, DIVISIONCUSTOMPAYMENTCODE_SUCCESS, DIVISIONCUSTOMPAYMENTCODE_ERROR, RESET_GET_DIVISIONCUSTOMPAYMENTCODE} from "../constants"

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false
};

export default function getPaymentcodeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENTCODE_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false,success:false};
     
    case GET_PAYMENTCODE_SUCCESS:  
      // alert(" reducer Error")
      return { ...state, loading: false ,success:true,user:action.payload};
 
    case GET_PAYMENTCODE_ERROR:
      // alert(" reducer Loading")
      return { ...state,error: true, errors: action.errors };

    case RESET_GET_PAYMENTCODE:
      return {...state,success:false,error:false, loading:false,user:[]}
   
    default:
      return state;
  }
}
export function divisionCustomPaymentcodeReducer(state = initialState, action) {
  switch (action.type) {
    case DIVISIONCUSTOMPAYMENTCODE_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false,success:false};
     
    case DIVISIONCUSTOMPAYMENTCODE_SUCCESS:  
      // alert(" reducer Error")
      return { ...state, loading: false ,success:true,user:action.payload};
 
    case DIVISIONCUSTOMPAYMENTCODE_ERROR:
      // alert(" reducer Loading")
      return { ...state,error: true, errors: action.errors };

    case RESET_GET_DIVISIONCUSTOMPAYMENTCODE:
      return {...state,success:false,error:false, loading:false,user:[]}
   
    default:
      return state;
  }
}


