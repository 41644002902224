export const RESET_STORE = "RESET_STORE";

// login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const RESET_LOGIN = "RESET_LOGIN";

// login
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const RESET_LOGOUT = "RESET_LOGOUT";

// user details
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_ERROR = "GET_USER_DETAILS_ERROR";
export const RESET_GET_USER_DETAILS = "RESET_GET_USER_DETAILS";

// dashBoard Details
export const GET_DASHBOARD_DETAILS_SUCCESS = "GET_DASHBOARD_DETAILS_SUCCESS";
export const GET_DASHBOARD_DETAILS_ERROR = "GET_DASHBOARD_DETAILS_ERROR";
export const RESET_GET_DASHBOARD_DETAILS = "RESET_GET_DASHBOARD_DETAILS";

// merchant Details
export const GET_MERCHANT_DETAILS_SUCCESS = "GET_MERCHANT_DETAILS_SUCCESS";
export const GET_MERCHANT_DETAILS_ERROR = "GET_MERCHANT_DETAILS_ERROR";
export const RESET_GET_MERCHANT_DETAILS = "RESET_GET_MERCHANT_DETAILS";

// transaction Details
export const GET_TRANSACTION_DETAILS_SUCCESS =
  "GET_TRANSACTION_DETAILS_SUCCESS";
export const GET_TRANSACTION_DETAILS_ERROR = "GET_TRANSACTION_DETAILS_ERROR";
export const RESET_GET_TRANSACTION_DETAILS = "RESET_GET_TRANSACTION_DETAILS";

// OrgList
export const GET_ORG_LIST_SUCCESS = "GET_ORG_LIST_SUCCESS";
export const GET_ORG_LIST_ERROR = "GET_ORG_LIST_ERROR";
export const RESET_GET_ORG_LIST = "RESET_GET_ORG_LIST";

export const CREATE_ORG_SUCCESS = "CREATE_ORG_SUCCESS";
export const CREATE_ORG_ERROR = "CREATE_ORG_ERROR";
export const RESET_CREATE_ORG = "RESET_CREATE_ORG";

export const UPDATE_ORG_SUCCESS = "UPDATE_ORG_SUCCESS";
export const UPDATE_ORG_ERROR = "UPDATE_ORG_ERROR";
export const RESET_UPDATE_ORG = "RESET_UPDATE_ORG";

export const DELETE_ORG_SUCCESS = "DELETE_ORG_SUCCESS";
export const DELETE_ORG_ERROR = "DELETE_ORG_ERROR";
export const RESET_DELETE_ORG = "RESET_DELETE_ORG";

export const UPDATE_CLUB_SUCCESS = "UPDATE_CLUB_SUCCESS";
export const UPDATE_CLUB_ERROR = "UPDATE_CLUB_ERROR";
export const RESET_UPDATE_CLUB = "RESET_UPDATE_CLUB";

export const DELETE_CLUB_SUCCESS = "DELETE_CLUB_SUCCESS";
export const DELETE_CLUB_ERROR = "DELETE_CLUB_ERROR";
export const RESET_DELETE_CLUB = "RESET_DELETE_CLUB";

// clubList
export const GET_CLUB_LIST_SUCCESS = "GET_CLUB_LIST_SUCCESS";
export const GET_CLUB_LIST_ERROR = "GET_CLUB_LIST_ERROR";
export const RESET_GET_CLUB_LIST = "RESET_GET_CLUB_LIST";

export const CREATE_CLUB_SUCCESS = "CREATE_CLUB_SUCCESS";
export const CREATE_CLUB_ERROR = "CREATE_CLUB_ERROR";
export const RESET_CREATE_CLUB = "RESET_CREATE_CLUB";

// staff List
export const GET_STAFF_LIST_SUCCESS = "GET_STAFF_LIST_SUCCESS";
export const GET_STAFF_LIST_ERROR = "GET_STAFF_LIST_ERROR";
export const RESET_GET_STAFF_LIST = "RESET_GET_STAFF_LIST";

// coach List
export const GET_COACHES_LIST_SUCCESS = "GET_COACHES_LIST_SUCCESS";
export const GET_COACHES_LIST_ERROR = "GET_COACHES_LIST_ERROR";
export const RESET_GET_COACHES_LIST = "RESET_GET_COACHES_LIST";

// palyer List
export const GET_PLAYER_LIST_SUCCESS = "GET_PLAYER_LIST_SUCCESS";
export const GET_PLAYER_LIST_ERROR = "GET_PLAYER_LIST_ERROR";
export const RESET_GET_PLAYER_LIST = "RESET_GET_PLAYER_LIST";

// oarent List
export const GET_PARENT_LIST_SUCCESS = "GET_PARENT_LIST_SUCCESS";
export const GET_PARENT_LIST_ERROR = "GET_PARENT_LIST_ERROR";
export const RESET_GET_PARENT_LIST = "RESET_GET_PARENT_LIST";

// State List
export const GET_STATE_LIST_SUCCESS = "GET_STATE_LIST_SUCCESS";
export const GET_STATE_LIST_ERROR = "GET_STATE_LIST_ERROR";
export const RESET_GET_STATE_LIST = "RESET_GET_STATE_LIST";

// city List
export const GET_CITY_LIST_SUCCESS = "GET_CITY_LIST_SUCCESS";
export const GET_CITY_LIST_ERROR = "GET_CITY_LIST_ERROR";
export const RESET_GET_CITY_LIST = "RESET_GET_CITY_LIST";

// Sport List
export const GET_SPORT_LIST_SUCCESS = "GET_SPORT_LIST_SUCCESS";
export const GET_SPORT_LIST_ERROR = "GET_SPORT_LIST_ERROR";
export const RESET_GET_SPORT_LIST = "RESET_GET_SPORT_LIST";

export const ADD_SPORT_LIST_SUCCESS = "ADD_SPORT_LIST_SUCCESS";
export const ADD_SPORT_LIST_ERROR = "ADD_SPORT_LIST_ERROR";
export const RESET_ADD_SPORT_LIST = "RESET_ADD_SPORT_LIST";

export const UPDATE_SPORT_LIST_SUCCESS = "UPDATE_SPORT_LIST_SUCCESS";
export const UPDATE_SPORT_LIST_ERROR = "UPDATE_SPORT_LIST_ERROR";
export const RESET_UPDATE_SPORT_LIST = "RESET_UPDATE_SPORT_LIST";

export const DELETE_SPORT_LIST_SUCCESS = "DELETE_SPORT_LIST_SUCCESS";
export const DELETE_SPORT_LIST_ERROR = "DELETE_SPORT_LIST_ERROR";
export const RESET_DELETE_SPORT_LIST = "RESET_DELETE_SPORT_LIST";

// OrgLogin
export const ORG_LOGIN_BTN_SUCCESS = "ORG_LOGIN_BTN_SUCCESS";
export const ORG_LOGIN_BTN_ERROR = "ORG_LOGIN_BTN_ERROR";
export const RESET_ORG_LOGIN_BTN = "RESET_ORG_LOGIN_BTN";

// Staff
export const CREATE_STAFF_SUCCESS = "CREATE_STAFF_SUCCESS";
export const CREATE_STAFF_ERROR = "CREATE_STAFF_ERROR";
export const RESET_CREATE_STAFF = "RESET_CREATE_STAFF";

export const UPDATE_STAFF_SUCCESS = "UPDATE_STAFF_SUCCESS";
export const UPDATE_STAFF_ERROR = "UPDATE_STAFF_ERROR";
export const RESET_UPDATE_STAFF = "RESET_UPDATE_STAFF";

export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS";
export const DELETE_STAFF_ERROR = "DELETE_STAFF_ERROR";
export const RESET_DELETE_STAFF = "RESET_DELETE_STAFF";

// Coach
export const CREATE_COACH_SUCCESS = "CREATE_COACH_SUCCESS";
export const CREATE_COACH_ERROR = "CREATE_COACH_ERROR";
export const RESET_CREATE_COACH = "RESET_CREATE_COACH";

export const UPDATE_COACH_SUCCESS = "UPDATE_COACH_SUCCESS";
export const UPDATE_COACH_ERROR = "UPDATE_COACH_ERROR";
export const RESET_UPDATE_COACH = "RESET_UPDATE_COACH";

export const DELETE_COACH_SUCCESS = "DELETE_COACH_SUCCESS";
export const DELETE_COACH_ERROR = "DELETE_COACH_ERROR";
export const RESET_DELETE_COACH = "RESET_DELETE_COACH";

// parent
export const CREATE_PARENT_SUCCESS = "CREATE_PARENT_SUCCESS";
export const CREATE_PARENT_ERROR = "CREATE_PARENT_ERROR";
export const RESET_CREATE_PARENT = "RESET_CREATE_PARENT";

export const UPDATE_PARENT_SUCCESS = "UPDATE_PARENT_SUCCESS";
export const UPDATE_PARENT_ERROR = "UPDATE_PARENT_ERROR";
export const RESET_UPDATE_PARENT = "RESET_UPDATE_PARENT";

export const DELETE_PARENT_SUCCESS = "DELETE_PARENT_SUCCESS";
export const DELETE_PARENT_ERROR = "DELETE_PARENT_ERROR";
export const RESET_DELETE_PARENT = "RESET_DELETE_PARENT";

// player
export const CREATE_PLAYER_SUCCESS = "CREATE_PLAYER_SUCCESS";
export const CREATE_PLAYER_ERROR = "CREATE_PLAYER_ERROR";
export const RESET_CREATE_PLAYER = "RESET_CREATE_PLAYER";

export const UPDATE_PLAYER_SUCCESS = "UPDATE_PLAYER_SUCCESS";
export const UPDATE_PLAYER_ERROR = "UPDATE_PLAYER_ERROR";
export const RESET_UPDATE_PLAYER = "RESET_UPDATE_PLAYER";

export const DELETE_PLAYER_SUCCESS = "DELETE_PLAYER_SUCCESS";
export const DELETE_PLAYER_ERROR = "DELETE_PLAYER_ERROR";
export const RESET_DELETE_PLAYER = "RESET_DELETE_PLAYER";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const RESET_CHANGE_PASSWORD = "RESET_CHANGE_PASSWORD";

export const ADD_SUPER_ADMIN_SUCCESS = "ADD_SUPER_ADMIN_SUCCESS";
export const ADD_SUPER_ADMIN_ERROR = "ADD_SUPER_ADMIN_ERROR";
export const RESET_ADD_SUPER_ADMIN = "RESET_ADD_SUPER_ADMIN";

export const GET_SUPERADMIN_LIST_SUCCESS = "GET_SUPERADMIN_LIST_SUCCESS";
export const GET_SUPERADMIN_LIST_ERROR = "GET_SUPERADMIN_LIST_ERROR";
export const RESET_GET_SUPERADMIN_LIST = "RESET_GET_SUPERADMIN_LIST";

export const UPDATE_SUPERADMIN_LIST_SUCCESS = "UPDATE_SUPERADMIN_LIST_SUCCESS";
export const UPDATE_SUPERADMIN_LIST_ERROR = "UPDATE_SUPERADMIN_LIST_ERROR";
export const RESET_UPDATE_SUPERADMIN_LIST = "RESET_UPDATE_SUPERADMIN_LIST";

export const DELETE_SUPERADMIN_LIST_SUCCESS = "DELETE_SUPERADMIN_LIST_SUCCESS";
export const DELETE_SUPERADMIN_LIST_ERROR = "DELETE_SUPERADMIN_LIST_ERROR";
export const RESET_DELETE_SUPERADMIN_LIST = "RESET_DELETE_SUPERADMIN_LIST";

export const ADD_SUB_SUPER_ADMIN_SUCCESS = "ADD_SUB_SUPER_ADMIN_SUCCESS";
export const ADD_SUB_SUPER_ADMIN_ERROR = "ADD_SUB_SUPER_ADMIN_ERROR";
export const RESET_ADD_SUB_SUPER_ADMIN = "RESET_ADD_SUB_SUPER_ADMIN";

export const GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS";
export const GET_CONFIG_ERROR = "GET_CONFIG_ERROR";
export const RESET_GET_CONFIG = "RESET_GET_CONFIG";

export const ADD_CONFIG_SUCCESS = "ADD_CONFIG_SUCCESS";
export const ADD_CONFIG_ERROR = "ADD_CONFIG_ERROR";
export const RESET_ADD_CONFIG = "RESET_ADD_CONFIG";

export const DELETE_CONFIG_SUCCESS = "DELETE_CONFIG_SUCCESS";
export const DELETE_CONFIG_ERROR = "DELETE_CONFIG_ERROR";
export const RESET_DELETE_CONFIG = "RESET_DELETE_CONFIG";

export const UPDATE_CONFIG_SUCCESS = "UPDATE_CONFIG_SUCCESS";
export const UPDATE_CONFIG_ERROR = "UPDATE_CONFIG_ERROR";
export const RESET_UPDATE_CONFIG = "RESET_UPDATE_CONFIG";

//  liability
export const GET_LIABILITY_SUCCESS = "GET_LIABILITY_SUCCESS";
export const GET_LIABILITY_ERROR = "GET_LIABILITY_ERROR";
export const RESET_GET_LIABILITY = "RESET_GET_LIABILITY";

export const ADD_LIABILITY_SUCCESS = "ADD_LIABILITY_SUCCESS";
export const ADD_LIABILITY_ERROR = "ADD_LIABILITY_ERROR";
export const RESET_ADD_LIABILITY = "RESET_ADD_LIABILITY";

export const UPDATE_LIABILITY_SUCCESS = "UPDATE_LIABILITY_SUCCESS";
export const UPDATE_LIABILITY_ERROR = "UPDATE_LIABILITY_ERROR";
export const RESET_UPDATE_LIABILITY = "RESET_UPDATE_LIABILITY";

// Change Status
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_ERROR = "CHANGE_STATUS_ERROR";
export const RESET_CHANGE_STATUS = "RESET_CHANGE_STATUS";

// ............................

export const GET_SUB_SUPERADMIN_LIST_SUCCESS =
  "GET_SUB_SUPERADMIN_LIST_SUCCESS";
export const GET_SUB_SUPERADMIN_LIST_ERROR = "GET_SUB_SUPERADMIN_LIST_ERROR";
export const RESET_GET_SUB_SUPERADMIN_LIST = "RESET_GET_SUB_SUPERADMIN_LIST";

export const UPDATE_SUB_SUPERADMIN_LIST_SUCCESS =
  "UPDATE_SUB_SUPERADMIN_LIST_SUCCESS";
export const UPDATE_SUB_SUPERADMIN_LIST_ERROR =
  "UPDATE_SUB_SUPERADMIN_LIST_ERROR";
export const RESET_UPDATE_SUB_SUPERADMIN_LIST =
  "RESET_UPDATE_SUB_SUPERADMIN_LIST";

export const DELETE_SUB_SUPERADMIN_LIST_SUCCESS =
  "DELETE_SUB_SUPERADMIN_LIST_SUCCESS";
export const DELETE_SUB_SUPERADMIN_LIST_ERROR =
  "DELETE_SUB_SUPERADMIN_LIST_ERROR";
export const RESET_DELETE_SUB_SUPERADMIN_LIST =
  "RESET_DELETE_SUB_SUPERADMIN_LIST";

export const USER_STATUS_SUCCESS = "USER_STATUS_SUCCESS";
export const USER_STATUS_ERROR = "USER_STATUS_ERROR";
export const RESET_USER_STATUS = "RESET_USER_STATUS";

//  check email
export const CHECK_MAIL_EXIST_SUCCESS = "CHECK_MAIL_EXIST_SUCCESS";
export const CHECK_MAIL_EXIST_ERROR = "CHECK_MAIL_EXIST_ERROR";
export const RESET_CHECK_MAIL_EXIST = "RESET_CHECK_MAIL_EXIST";

//
export const validEmailRegex = new RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
export const validatePhoneString = new RegExp( /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/);

export const removeStorage = () => {
  localStorage.clear();
  localStorage.removeItem("token");
};

export const getDoubleDigitNumber = (n) => {
  return parseInt(n) > 9 ? `${n}` : `0${n}`;
};
