import React from "react";
import DeletePopUp from "../../club/components/DeletePopUp/DeletePopUp";
import { connect } from "react-redux";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import {
  deleteOrgStaffGroup,
  getStaffGroupList,
} from "../../service/action/userAction";
import {
  RESET_DELETE_ADMIN_PERMISSION_GROUP,
  RESET_DELETE_STAFF_PERMISSION_GROUP,
} from "../../service/constants";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";

function StaffUserDetails(props) {
  const { id } = useParams();

  const [details, setDetails] = useState(null);

  const [isDelete, setIsDelete] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!!id) {
      if (!props.getStaffPermissionListReducer.success) {
        props.getStaffGroupList();
      } else if (props.getStaffPermissionListReducer.success) {
        const data = props.getStaffPermissionListReducer?.data?.data;
        const _details = data?.find((e) => e._id === id);

        if (_details !== undefined && Object.keys(_details)?.length !== 0) {
          setDetails(_details);
        } else {
          history.push("/staff-permission");
        }
      }
    }
  }, [id, props.getStaffPermissionListReducer]);

  const handleDelete = () => {
    props.deleteOrgStaffGroup(details._id);
  };

  useEffect(() => {
    if (props.deleteStaffGroupReducer.success) {
      props.resetDelete();
      toast("Permission Group Deleted Succesfully");
      setTimeout(() => {
        history.push("/staff-permission");
      }, 2500);
    }
  }, [props.deleteStaffGroupReducer]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div>
            <h4>Account Users</h4>
          </div>

          <div className="mt-3">
            <div
              className="border pointer p-2 m-2 d-flex justify-content-between align-items-center admin_group"
              // onClick={() => history.push(`/permission/${details?._id}/permission`)}
            >
              <div className=" text-danger">{details?.group_name}</div>
              <div className="dropdown">
                <i
                  className="mdi mdi-dots-vertical"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <Link
                    className="dropdown-item px-2"
                    to={`/staff-permission/${details?._id}/permission`}
                  >
                    <i className="mdi mdi-account-plus action-ico"></i> Manage
                    Permission
                  </Link>
                  <Link
                    className="dropdown-item px-2"
                    to={"#"}
                    onClick={() => setIsDelete(true)}
                  >
                    <i className="mdi mdi-delete action-ico"></i> Delete
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={handleDelete}
      />
      <Toaster />
    </React.Fragment>
  );
}

const mapStateToProp = (state) => ({
  getStaffPermissionListReducer:
    state.OrgPermissionReducer.getStaffPermissionList,
  deleteStaffGroupReducer: state.OrgPermissionReducer.deleteStaffGroup,
});

const mapDispatchToProps = (dispatch) => ({
  getStaffGroupList: (user) => dispatch(getStaffGroupList(user)),
  deleteOrgStaffGroup: (id) => dispatch(deleteOrgStaffGroup(id)),
  resetDelete: (id) => dispatch({ type: RESET_DELETE_STAFF_PERMISSION_GROUP }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(StaffUserDetails));
