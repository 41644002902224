import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { GetDashBoardDetails } from "../Services/Actions/userAction";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import BreadcrumbHistory from "./BreadcrumbHistory/BreadcrumbHistory";
import {useHistory, useLocation } from "react-router-dom";
import { removeStorage } from "../Services/constant";
function Dashboard(props) {

      const history = useHistory();

      const location = useLocation()
      const [state, setState] = useState(null)
      const [loading, setLoading] = useState(true)



      useEffect(() => {
            props.GetDashBoardDetails()
            setLoading(true)
      }, [])

      useEffect(() => {
            if (props.dashboardReducer.success) {
                  const data = props.dashboardReducer.data
                  setState(data.data)
                  setLoading(false)
            } else if (props.dashboardReducer.error) {
                  const errMsg = props.dashboardReducer.errorMsg
                  !!errMsg && toast.error(errMsg)
                  // setLoading(false)
                  if (errMsg == 'UnAuthorized') {
                        removeStorage()
                        history.push('/superadmin/login')
                  }
            }
      }, [props.dashboardReducer])
      return (
            <>
                  <div className="d-flex justify-content-between">
                        <h2 className="page-heading">Dashboard</h2>
                        {/* <BreadcrumbHistory url={location.pathname !== '/' ? location.pathname : ''} /> */}

                  </div>
                  {
                        loading ? <>Loading..... </> :
                              <>
                                    <Row className="mx-0">
                                          <Col>
                                                <div className="total-block pointer" onClick={() => history.push('/superadmin/event-organizer')}>
                                                      <h2>{state.organizer}</h2>
                                                      <h5>Event Organizers</h5>
                                                </div>
                                          </Col>
                                          <Col>
                                                <div className="total-block pointer" onClick={() => history.push('/superadmin/club')}>
                                                      <h2>{state.club}</h2>
                                                      <h5>Clubs</h5>
                                                </div>
                                          </Col>
                                          <Col>
                                                <div className="total-block pointer" onClick={() => history.push('/superadmin/staff')}>
                                                      <h2>{state.staff}</h2>
                                                      <h5>Staff</h5>
                                                </div>
                                          </Col>
                                          <Col>
                                                <div className="total-block pointer" onClick={() => history.push('/superadmin/coach')}>
                                                      <h2>{state.coaches}</h2>
                                                      <h5>Coaches</h5>
                                                </div>
                                          </Col>
                                          <Col>
                                                <div className="total-block pointer" onClick={() => history.push('/superadmin/players')}>
                                                      <h2>{state.player}</h2>
                                                      <h5>Players</h5>
                                                </div>
                                          </Col>
                                    </Row>
                              </>
                  }
            </>
      );
}


const mapStateToProp = state => ({
      dashboardReducer: state.superAdminRootReducer.UserReducer.dashBoardDetails,

})

const mapDispatchToProps = dispatch => ({
      GetDashBoardDetails: () => dispatch(GetDashBoardDetails()),
})

export default connect(mapStateToProp, mapDispatchToProps)(Dashboard)