import {
  GET_ORG_CONNECTED_USER_ERROR,
  GET_ORG_CONNECTED_USER_LOADING,
  GET_ORG_CONNECTED_USER_SUCCESS,
  RESET_GET_ORG_CONNECTED,
} from "../../constants";

let initialState = {
  loading: false,
  user: {},
  error: false,
  errors: [],
  success: false,
};

export default function OrgConnectedUserReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORG_CONNECTED_USER_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case GET_ORG_CONNECTED_USER_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };

    case GET_ORG_CONNECTED_USER_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    case RESET_GET_ORG_CONNECTED:
      return {
        ...state,
        error: false,
        loading: false,
        success: false,
        user: {},
      };
    default:
      return state;
  }
}
