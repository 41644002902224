import React from "react";
import { OrggetPlayerDetails } from "../../../service/action/userAction";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";

const RoasterPersonDetails = (props) => {
  const { id, id1 } = useParams();
  const [playerDetails, setplayerDetails] = useState();
  const [contactSectionVisble, setContactSectionVisible] = useState(true);
  const [playerbioSectionVisble, setplayerbioSectionVisible] = useState(true);
  useEffect(() => {
    props.OrggetPlayerDetails({ id: id });
  }, []);

  useEffect(() => {
    if (props.OrgplayerReducer?.success) {
      const data = props.OrgplayerReducer?.data?.data?.player;
      setplayerDetails(data);
    }
  }, [props.OrgplayerReducer]);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="mb-5">
          <div
            className="d-flex align-items-center justify-content-center w-100"
            style={{ height: "150px" }}
          >
            <img
              src={
                playerDetails?.profile_image
                  ? playerDetails?.profile_image
                  : "./images/pch-images/pch.svg"
              }
              className="w-30 h-100"
            />
          </div>
          <div className="mt-3">
            <h4 className="text-center mb-0">
              {`${
                playerDetails?.first_name ? playerDetails?.first_name : "N/A"
              }${" "}${
                playerDetails?.last_name ? playerDetails?.last_name : "N/A"
              }`}
            </h4>
          </div>
          <div className="text-center text-gray mt-2">
            DOB:{" "}
            {moment(playerDetails?.dob ? playerDetails?.dob : "N/A").format(
              "MM/DD/YYYY"
            )}
          </div>
          <div className="text-center text-gray mt-2">
            Email: {playerDetails?.email ? playerDetails?.email : "N/A"}
          </div>

          <hr />
          {/* ..........................................    Contact    .................................................................. */}
          <div style={{ borderBottom: "1px solid #899ea4" }} className="pb-2">
            <div className="d-flex justify-content-between mt-4 align-items-center">
              <div
                className="d-flex"
                onClick={() => {
                  setContactSectionVisible(!contactSectionVisble);
                  document.getElementById("contact").style.display =
                    contactSectionVisble ? "none" : "block";
                }}
              >
                <h4 className="text-left mb-0 pointer">
                  Emergency Parent/Guardian Contact
                </h4>
                <a className="d-block text-info text-decoration-none ml-12 pointer fs-18">
                  {contactSectionVisble ? (
                    <i className="fas fa-angle-down"></i>
                  ) : (
                    <i className="fas fa-angle-up"></i>
                  )}
                </a>
              </div>
            </div>
            {!playerDetails ? (
              <div id="contact">No Contact to display</div>
            ) : (
              <>
                <div>
                  <div className="mt-2 p-2" id="contact">
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        First Name :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.primary_contact[0]?.first_name !== ""
                          ? playerDetails?.primary_contact[0]?.first_name
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Last Name :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.primary_contact[0]?.last_name !== ""
                          ? playerDetails?.primary_contact[0]?.last_name
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Relationship :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.primary_contact[0]
                          ?.relationship_player !== ""
                          ? playerDetails?.primary_contact[0]
                              ?.relationship_player
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Mobile No. :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.primary_contact[0]?.mobile_no !== ""
                          ? playerDetails?.primary_contact[0]?.mobile_no
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Email :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.primary_contact[0]?.email !== ""
                          ? playerDetails?.primary_contact[0]?.email
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Sport :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.sport[0]?.sport !== ""
                          ? playerDetails?.sport[0]?.sport
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                  </div>
                </div>
              </>
            )}
          </div>

          {/* ............................................   Player Bio   .......................................................... */}

          <div style={{ borderBottom: "1px solid #899ea4" }} className="pb-2">
            <div className="d-flex justify-content-between mt-4 align-items-center">
              <div
                className="d-flex"
                onClick={() => {
                  setplayerbioSectionVisible(!playerbioSectionVisble);
                  document.getElementById("playerbio").style.display =
                    playerbioSectionVisble ? "none" : "block";
                }}
              >
                <h4 className="text-left mb-0 pointer">Player bio</h4>
                <a className="d-block text-info text-decoration-none ml-12 pointer fs-18">
                  {contactSectionVisble ? (
                    <i className="fas fa-angle-down"></i>
                  ) : (
                    <i className="fas fa-angle-up"></i>
                  )}
                </a>
              </div>
            </div>
            {!playerDetails ? (
              <div id="playerbio">No Contact to display</div>
            ) : (
              <>
                <div>
                  <div className="mt-2 p-2" id="playerbio">
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        School lavel :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.player_bio?.school_information
                          ?.school_level
                          ? playerDetails?.player_bio?.school_information
                              ?.school_level
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Grad. month :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.player_bio?.school_information
                          ?.graduation_month
                          ? playerDetails?.player_bio?.school_information
                              ?.graduation_month
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Grad. year:{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.player_bio?.school_information
                          ?.graduation_year
                          ? playerDetails?.player_bio?.school_information
                              ?.graduation_year
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Current school :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.player_bio?.school_information
                          ?.current_school
                          ? playerDetails?.player_bio?.school_information
                              ?.current_school
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Height :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.player_bio?.school_information?.height
                          ? playerDetails?.player_bio?.school_information
                              ?.height
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Weight :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.player_bio?.school_information?.weight
                          ? playerDetails?.player_bio?.school_information
                              ?.weight
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Us Lacrosse no. :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.player_bio?.sport_information
                          ?.us_lacrosse_number
                          ? playerDetails?.player_bio?.sport_information
                              ?.us_lacrosse_number
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Position 1 :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.player_bio?.sport_information?.position1
                          ? playerDetails?.player_bio?.sport_information
                              ?.position1
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Position 2 :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.player_bio?.sport_information?.position2
                          ? playerDetails?.player_bio?.sport_information
                              ?.position2
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Shirt size :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.player_bio?.attire_info?.shirt_size
                          ? playerDetails?.player_bio?.attire_info?.shirt_size
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Short size :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.player_bio?.attire_info?.short_size
                          ? playerDetails?.player_bio?.attire_info?.short_size
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Uniform size :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.player_bio?.attire_info?.uniform_size
                          ? playerDetails?.player_bio?.attire_info?.uniform_size
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Glove size :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.player_bio?.attire_info?.glove_size
                          ? playerDetails?.player_bio?.attire_info?.glove_size
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex align-items-center mb-2 py-2">
                      <p className="text-left text-info mb-0  fs-14">
                        Helmet size :{" "}
                      </p>
                      <h6 className="text-left ml-1 text-gray m-0">
                        {playerDetails?.player_bio?.attire_info?.helmet_size
                          ? playerDetails?.player_bio?.attire_info?.helmet_size
                          : "N/A"}
                      </h6>
                    </div>
                    <hr className="m-0" />
                  </div>
                </div>
              </>
            )}
          </div>

          <hr />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  OrgplayerReducer: state.OrgplayerdetailsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  OrggetPlayerDetails: (user) => dispatch(OrggetPlayerDetails(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoasterPersonDetails);
