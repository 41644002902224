// local server
// export const baseUrl = 'http://192.168.0.33:5555';

// development server
// const baseUrl = 'https://myfmexpo.com';

// stagging server
// const baseUrl = "https://staging.playersclearinghouse.com";

// app-server
// export const baseUrl = "https://app-server.playersclearinghouse.com";

// dev-server
export const baseUrl = "https://dev-server.playersclearinghouse.com";

// local-server
// export const baseUrl = "http://192.168.0.25:5555";

export const signInUrl = `${baseUrl}/api/organizer/register`;

export const forgot_password_email_Url = `${baseUrl}/api/forgot-password-sendmail`;

export const verify_otp_Url = `${baseUrl}/api/verifyOtp`;

export const reset_password_Url = `${baseUrl}/api/reset-password`;

export const change_password_Url = `${baseUrl}/api/change-password`;

export const signInUserUrl = `${baseUrl}/api/organizer/login`;

export const signout_Url = `${baseUrl}/api/log-out`;

export const createEventUrl = `${baseUrl}/api/organizer/create-event`;

export const getEventBy_id_Url = `${baseUrl}/api/organizer/event`;

export const createPaymentCode_Url = `${baseUrl}/api/organizer/payment-code`;

export const DivisionCustomPaymentCode_Url = `${baseUrl}/api/organizer/division-custom-payment-code`;

export const getAllpaymentCode_Url = `${baseUrl}/api/organizer/get-all-payment-code`;

export const getAllEvent_Url = `${baseUrl}/api/organizer/event`;

export const getAllClubList_by_Name_Url = `${baseUrl}/api/organizer/find-club-by-name`;

export const getEventDateFilter = `${baseUrl}/api/organizer/event/date-filter`;

export const getUserRoll_Url = `${baseUrl}/api/login-user-role`;

export const switch_Account_Url = `${baseUrl}/api/switch-account`;

export const add_account_Url = `${baseUrl}/api/add-account`;

export const getAllSeason_Url = `${baseUrl}/api/organizer/event-season`;

export const createSeason_Url = `${baseUrl}/api/organizer/add-event-season`;

export const signupByMail_Url = `${baseUrl}/api/accept-mail-initiation`;

export const state_Url = `${baseUrl}/api/state/612f28651f40ec33c4bb4ce3`;

export const city_Url = `${baseUrl}/api/city`;

export const sport_Url = `${baseUrl}/api/sport`;

export const getAllDiscountCode_Url = `${baseUrl}/api/organizer/discount-code`;

export const createDiscountCode_Url = `${baseUrl}/api/organizer/discount-code`;

export const AddEventSeasonOrg_Url = `${baseUrl}/api/organizer/add-event-season`;

export const AddEventSeasonClub_Url = `${baseUrl}/api/club/add-event-season`;

export const EventSeasonOrg_Url = `${baseUrl}/api/organizer/event-season`;

export const EventSeasonClub_Url = `${baseUrl}/api/club/event-season`;

export const EditEventData_Url = `${baseUrl}/api/organizer/event`; //use for delete And Edit

export const getInvitationData_Url = `${baseUrl}/api/check-mail-invitation-status`;

export const cloneEvent_Url = `${baseUrl}/api/organizer/event/copy`;

export const Org_Account_Details_Url = `${baseUrl}/api/organizer/user`; //Profile details api //organizer Profile details

export const Org_EditAccount_details_Url = `${baseUrl}/api/organizer/update-user`;

export const Org_CreateDivision_Url = `${baseUrl}/api/organizer/create-event-division`;

export const Org_GetAllDivision_Url = `${baseUrl}/api/organizer/event-division`;

export const Org_CreatePool_Url = `${baseUrl}/api/organizer/create-division-pool`;

export const Org_EditDivision_Url = `${baseUrl}/api/organizer/event-division`;

export const Org_EditPool_Url = `${baseUrl}/api/organizer/division-pool`;

export const Org_RemovePoolTeam_Url = `${baseUrl}/api/organizer/remove-pool-team`;

export const Org_RemovePoolPlayer_Url = `${baseUrl}/api/organizer/remove-pool-player`;

export const CreateTeam_Url = `${baseUrl}/api/organizer/create-team`;

export const getOrgClubById_Url = `${baseUrl}/api/organizer/club`;

export const GetAllClub_Url = `${baseUrl}/api/club`;

export const Invite_Club_Url = `${baseUrl}/api/organizer/invite-club`; //invite club api

export const get_Organizer_Club_List_Url = `${baseUrl}/api/organizer/organizer-club`;

export const Invite_Staff_Url = `${baseUrl}/api/organizer/add-staff`;

export const GetAllStaff_Url = `${baseUrl}/api/all-staff`;

export const UpdateFreeTeam_Url = `${baseUrl}/api/organizer/event-team-update-division`;

export const GetEventStaff_Url = `${baseUrl}/api/organizer/event-staff`; //event Stafff api

export const GetActivityLog_Url = `${baseUrl}/api/organizer/activity-log`;

export const Remove_Event_Staff_Url = `${baseUrl}/api/organizer/remove-event-staff`; //remove staff api

export const Add_Schedule_Url = `${baseUrl}/api/organizer/create-schedule`;

export const getAllSchedule_Url = `${baseUrl}/api/organizer/event-schedule`;

export const deleteSchedule_Url = `${baseUrl}/api/organizer/delete-schedule`;

export const freeTeam_Url = `${baseUrl}/api/organizer/get-event-free-team`;

export const Event_Team_Url = `${baseUrl}/api/organizer/get-event-team`;

export const Team_detail_Url = `${baseUrl}/api/organizer/team-detail`;

export const Club_Team_detail_Url = `${baseUrl}/api/organizer/club-team-detail`;

export const Remove_Team_Url = `${baseUrl}/api/organizer/delete-team`;

export const Create_Liability_Url = `${baseUrl}/api/organizer/organizer-liability`;

export const create_Group_Url = `${baseUrl}/api/organizer/permission-group`;

export const get_Groups_Url = `${baseUrl}/api/organizer/permission-group`;

export const Add_staff_In_Group_Url = `${baseUrl}/api/organizer/staff-group`;

export const get_Permission_Details_Url = `${baseUrl}/api/organizer/get-admin-group-permission`;

export const change_permission_Url = `${baseUrl}/api/organizer/change-admin-permission`;

export const delete_permission_Url = `${baseUrl}/api/organizer/permission-admin-group`;

export const getAdmin_Url = `${baseUrl}/api/organizer/get-admin`;

export const InvitePlayer_Org_Url = `${baseUrl}/api/organizer/invite-player`;

export const get_Org_notification = `${baseUrl}/api/organizer/notifications`;

export const getEvent_register_Team_url = `${baseUrl}/api/organizer/get-payment-event`;

export const get_paid_Event_Url = `${baseUrl}/api/organizer/get-paid-event`;

export const getRecipient_Url = `${baseUrl}/api/organizer/get-register-team-recipient`;

export const getPendingAmount_Url = `${baseUrl}/api/organizer/get-panding-amount`;

export const payment_request_Url = `${baseUrl}/api/organizer/bulk-recipient-request`;

export const getPaymentRequest_Url = `${baseUrl}/api/organizer/get-payment-request`;

export const getLiability_Url = `${baseUrl}/api/organizer/organizer-liability`;

export const getOrgPlayer_Url = `${baseUrl}/api/organizer/accepted-player-list`;

export const createTeamGroup_Url = `${baseUrl}/api/organizer/player-group`;

export const add_PlayerToGroup_Url = `${baseUrl}/api/organizer/add-player-group`;

export const get_Group_Details_Url = `${baseUrl}/api/organizer/group`;

export const remove_player_from_group_Url = `${baseUrl}/api/organizer/delete-player`;

export const get_recipient_Url = `${baseUrl}/api/organizer/get-paid-recipient`;

export const create_Credit_Note_Url = `${baseUrl}/api/organizer/credit-note`;

export const Org_connected_user_Url = `${baseUrl}/api/organizer/connected-user`;

export const get_Credit_Url = `${baseUrl}/api/organizer/credit-note`;

export const create_Refund_Url = `${baseUrl}/api/organizer/refund`;

export const get_Org_Request_Url = `${baseUrl}/api/club/get-organizer-request`;

export const Org_Club_Details_Url = `${baseUrl}/api/organizer/organizer-club`;

export const Org_Club_Team_Details_Url = `${baseUrl}/api/organizer/organizer-club-team`;

export const Event_Image_Upload_Url = `${baseUrl}/api/organizer/update-image-event`;

export const OutsideStaff_Url = `${baseUrl}/api/organizer/get-staff`;

export const admin_Url = `${baseUrl}/api/organizer/get-admin`;

export const getReceiveRepoet_Url = `${baseUrl}/api/organizer/get-payment-history`;

export const getOutStandingReport_Url = `${baseUrl}/api/organizer/get-outstanding-amount`;

export const getRefundReport_Url = `${baseUrl}/api/organizer/refund`;

export const dashbordPayment_Url = `${baseUrl}/api/organizer/dashboard`;

export const edit_team_Url = `${baseUrl}/api/organizer/update-team`;

export const all_Report_Url = `${baseUrl}/api/organizer/all-report`;

export const get_filter_Event_Url = `${baseUrl}/api/organizer/event/filter`;

export const staffInvite_By_Mail = `${baseUrl}/api/invite-user`;

export const get_single_chat_Url = `${baseUrl}/api/message?`;

export const sendBroadcast_Url = `${baseUrl}/api/organizer/send-dm`;

export const published_schedule_Url = `${baseUrl}/api/organizer/event-publish-schedule`;

export const reset_Url = `${baseUrl}/api/resend-verification-link`;

export const accounting_Url = `${baseUrl}/api/organizer/accounting`;

export const merchant_Organizer_Url = `${baseUrl}/api/get-merchant`;

export const merchant_Status_Url = `${baseUrl}/api/get-merchant-status`;

export const Signed_Waiver_Url = `${baseUrl}/api/organizer/get-approved-liability`;

export const Liability_Terms_Url = `${baseUrl}/api/organizer/organizer-liability`;

//   <<<<<<<<<<<<<<<-------------------------Club Api ------------------------->>>>>>>>>>>>>>>

export const get_Club_Event_Url = `${baseUrl}/api/club/event`;

export const clubEventDateFilter = `${baseUrl}/api/club/event/date-filter/`;

export const Club_Payment_Url = `${baseUrl}/api/club/payment-code`; //use for payment code get/create/edit/create in club

export const Get_Club_Payment_Url = `${baseUrl}/api/club/get-all-payment-code`; //use for payment code get

// export const Delete_Club_Payment_Code_Url=`${baseUrl}/api/club/payment-code`

// export const Create_Club_Payment_Code_Url=`${baseUrl}/api/club/payment-code`

export const create_Club_Event_Url = `${baseUrl}/api/club/create-event`;

export const Edit_Club_EventData_Url = `${baseUrl}/api/club/event`; //use For Delete And Edit

export const cloneEvent_Club_Url = `${baseUrl}/api/club/event/copy`;

export const Club_DiscountCode_Url = `${baseUrl}/api/club/discount-code`;

export const Club_Account_Details_Url = `${baseUrl}/api/club/user`; //use For get account details

export const Club_Update_Profile_Url = `${baseUrl}/api/club/update-profile`;

export const BookMark_Url = `${baseUrl}/api/add-to-bookmark`;

export const club_all_team_Url = `${baseUrl}/api/club/club-team`;

export const Player_Invitation_Url = `${baseUrl}/api/club/player-join-request`; //for accept pass id

export const getCoachInvitation_Url = `${baseUrl}/api/club/coach-list`;

export const Accept_Reject_Coach_Url = `${baseUrl}/api/club/change-club-accept-reject-list`;

export const getFiled_Url = `${baseUrl}/api/club/field`;

export const Field_Url = `${baseUrl}/api/club/field`;

export const get_Division_Url = `${baseUrl}/api/club/get-club-division`;

export const Division_Url = `${baseUrl}/api/club/club-division`; //for delete and edit division

export const Create_Division_Url = `${baseUrl}/api/club/create-club-division`;

export const Get_Player_List_Url = `${baseUrl}/api/player`;

export const InvitePlayer_Url = `${baseUrl}/api/club/invite-player`;

export const CreateTeam_club = `${baseUrl}/api/club/club-team`;

export const Create_league_Url = `${baseUrl}/api/club/league`;

export const Upload_excel_Url = `${baseUrl}/api/club/upload-excel`;

export const getLeague_Url = `${baseUrl}/api/club/league`;

export const bank_details_Url = `${baseUrl}/ank-detail`;

export const coach_Invitation_Url = `${baseUrl}/api/coach`;

export const Coach_InviteClick_Url = `${baseUrl}/api/club/send-coach-to-join-request`;

export const getTeamDetails_Url = `${baseUrl}/api/club/club-team`;

export const getAllInvitation_Url = `${baseUrl}/api/get-all-invitation`;

export const Club_change_request_status_Url = `${baseUrl}/api/club/change-request-status`;

export const get_Coach_Accept_List_Url = `${baseUrl}/api/club/get-coach-accept-list`; // ?

export const getClub_DashBoard_Url = `${baseUrl}/api/club/dashboard`;

export const getClubs_Org_List_Url = `${baseUrl}/api/club/get-organizer`;

export const get_Club_Org_Liability_Url = `${baseUrl}/api/club/organizer-liability`;

export const signature_Post_Url = `${baseUrl}/api/club/approve-liability-By-club`;

export const get_Club_Org_Event_Details_Url = `${baseUrl}/api/event`;

export const get_Org_Event_Team_Url = `${baseUrl}/api/club/get-event-team`;

export const get_Staff_Join_request_Url = `${baseUrl}/api/club/staff-join-request`; // get invitation notification in club

export const get_Coach_Join_request_Url = `${baseUrl}/api/club/coach-list`; //get invitation notification in Club

export const get_Club_Notification_Url = `${baseUrl}/api/club/notifications`; //get Notification in Club

export const get_Event_Details_for_RegisterTeam_Url = `${baseUrl}/api/event`;

export const team_register_Url = `${baseUrl}/api/club/team-register`;

export const register_selected_team_Url = `${baseUrl}/api/club/register-team-payable-amount`;

export const apply_discountcode_Url = `${baseUrl}/api/club/apply-discount-code`;

export const payNow_Url = `${baseUrl}/api/club/club-payment`;

export const connected_user_Url = `${baseUrl}/api/club/connected-user`;

export const accept_player_list = `${baseUrl}/api/club/player`;

export const save_club_liability = `${baseUrl}/api/club/club-liability`;

export const create_club_refund_Url = `${baseUrl}/api/club/refund`;

export const get_payement_event_Url = `${baseUrl}/api/club/get-payment-event`;

export const club_bulk_recipient_Url = `${baseUrl}/api/club/bulk-recipient-request`;

export const get_Club_recipient_Url = `${baseUrl}/api/club/get-paid-recipient`;

export const getClubPendingAmount_Url = `${baseUrl}/api/club/get-panding-amount`;

export const payment_request_Club_Url = `${baseUrl}/api/club/payment-request`;

export const getPaymentRequest_Club_Url = `${baseUrl}/api/club/get-payment-request`;

export const Create_Credit_CLub_Url = `${baseUrl}/api/club/credit-note`;

export const get_payment_club_Url = `${baseUrl}/api/club/get-payment`;

export const requested_Payment_Url = `${baseUrl}/api/club/requested-club-payment`;

export const addRoaster_Url = `${baseUrl}/api/club/add-team-roster`;

export const addPlayer_Url = `${baseUrl}/api/club/add-team-player`;

export const create_merchand_account_Url = `${baseUrl}/api/create-merchant-account`;

export const Club_Event_Image_Upload_Url = `${baseUrl}/api/club/update-image-event`;

export const club_team_Image_Url = `${baseUrl}/api/club/update-team-image`;

export const league_bookmark_Url = `${baseUrl}/api/club/league-bookmark`;

export const club_register_Team_Url = `${baseUrl}/api/club/register-team-club-event`;

export const getClubReceiveRepoet_Url = `${baseUrl}/api/club/get-payment-history`;

export const getClubOutStandingReport_Url = `${baseUrl}/api/club/get-outstanding-amount`;

export const getClubRefundReport_Url = `${baseUrl}/api/club/refund`;

export const getClubOrganizerRefund_Url = `${baseUrl}/api/club/organizer-refund`;

export const Club_dashbordPayment_Url = `${baseUrl}/api/club/dashboard`;

export const club_Create_pool = `${baseUrl}/api/club/create-division-pool`;

export const Team_Event_Url = `${baseUrl}/api/organizer/get-team-event`;

export const Club_Team_Event_Url = `${baseUrl}/api/club/get-team-event`;

export const assignCoach_Url = `${baseUrl}/api/club/add-coach-team`;

export const all_Club_Report_Url = `${baseUrl}/api/club/get-participated-report`;

export const get_payment_Url = `${baseUrl}/api/organizer/get-payment`;

export const get_payment_Club_Url = `${baseUrl}/api/club/get-payment`;

export const create_Refund_Club_Url = `${baseUrl}/api/club/refund`;

export const get_filter_Club_Event_Url = `${baseUrl}/api/club/event/filter`;

export const create_event_division_Url = `${baseUrl}/api/club/create-event-division`;

export const get_Event_Division_Url = `${baseUrl}/api/club/event-division`;

export const edit_Event_Division_Url = `${baseUrl}/api/club/event-division`;

export const event_pool_Url = `${baseUrl}/api/club/division-pool`;

export const delete_event_pool_Url = `${baseUrl}/api/club/delete-division-pool`;

export const add_team_Division_Url = `${baseUrl}/api/club/add-team-division`;

export const club_event_team_Url = `${baseUrl}/api/club/get-club-event-team`;

export const active_deactive_Player_Url = `${baseUrl}/api/club/player-status`;

export const sendBroadcast_Club_Url = `${baseUrl}/api/club/send-dm`;

export const getParent_Invitation_Url = `${baseUrl}/api/club/parent-request-list`;

export const parent_accept_Reject_Url = `${baseUrl}/api/club/parent-join-request`;

export const get_bulk_recipient__Url = `${baseUrl}/api/organizer/get-bulk-data`;

export const reminder_Url = `${baseUrl}/api/organizer/payment-reminder`;

export const findUser_Url = `${baseUrl}/api/organizer/find-user`;

export const findUserByEmail_Url = `${baseUrl}/api/organizer/find-by-email`;

export const getClubTeam_Url = `${baseUrl}/api/club/get-club-team`;

export const getClubOrganizerEvent_Url = `${baseUrl}/api/club/organizer-event`;

export const getMainLeague_Url = `${baseUrl}/api/club/main-league`;

export const removeAccount_Url = `${baseUrl}/api/delete-account`;

export const approveLiability_Url = `${baseUrl}/api/club/approve-liability-By-club`;

export const getPaymentMethod_Url = `${baseUrl}/api/club/payment-method`;

export const updatePaymentMethod_Url = `${baseUrl}/api/club/payment-method`; // edit and delete

export const clubFindUser_Url = `${baseUrl}/api/club/find-user`;

export const addTeamRoster_Url = `${baseUrl}/api/club/team-roster`;

export const addTeamOrgRoster_Url = `${baseUrl}/api/organizer/team-roster`;

export const removeTeamRoster_Url = `${baseUrl}/api/club/remove-roster`;

export const getPlayerDetails_Url = `${baseUrl}/api/club/club-player`;

export const addSelectedRoster_Url = `${baseUrl}/api/club/add-selected-roster`;

export const updateProfileImage_Url = `${baseUrl}/api/club/update-image`;

export const getpaymentreqSend_Url = `${baseUrl}/api/club/get-hoster-report`;

export const clubreminder_Url = `${baseUrl}/api/club/payment-reminder`;

export const getOrgInvitation_Url = `${baseUrl}/api/admin-inviation`;

export const eventRegistrationON_Url = `${baseUrl}/api/organizer/end-registration`;

export const eventRegistrationOFF_Url = `${baseUrl}/api/organizer/re-start-registration`;

export const reqaddpayment_Url = `${baseUrl}/api/organizer/all-reports`;

export const removeTeamPlayer_Url = `${baseUrl}/api/club/remove-team-player`;
export const Player_details_Url = `${baseUrl}/api/organizer/org-player`;

export const club_discount_Url = `${baseUrl}/api/organizer/club-discount-code`;

export const club_credit_Url = `${baseUrl}/api/organizer/club-credit-note`;

export const Org_getallclub_Url = `${baseUrl}/api/organizer/get-all-club`;

export const clearClubNotification_Url = `${baseUrl}/api/club/clear-notifications`;

export const Org_balances_due_Url = `${baseUrl}/api/organizer/club-accounting`;

export const switchAccess_Url = `${baseUrl}/api/organizer/moved-team`;

export const switchPlayerAccess_Url = `${baseUrl}/api/organizer/moved-player`;

export const eventdivisionpool_Url = `${baseUrl}/api/organizer/event-division`;

export const addteamtodivision_Url = `${baseUrl}/api/organizer/add-team-to-division`;

export const addplayertodivision_Url = `${baseUrl}/api/organizer/add-player-to-division-pool`;

export const UpdateSchedule_Url = `${baseUrl}/api/organizer/update-schedule`;

export const createfieldmap_Url = `${baseUrl}/api/organizer/create-field-map`;

export const viewfieldmap_Url = `${baseUrl}/api/organizer/event-field-map`;

export const editfieldmap_Url = `${baseUrl}/api/organizer/update-field-map`;

export const get_Club_Recipient_Url = `${baseUrl}/api/club/get-bulk-data`;

export const clearOrgNotifications_Url = `${baseUrl}/api/organizer/clear-notifications`;

export const getPermissionAdminGroup_Url = `${baseUrl}/api/club/permission-admin-group`;

export const create_schedule_message_Url = `${baseUrl}/api/organizer/create-schedule-message`;

export const create_fieldmap_message_Url = `${baseUrl}/api/organizer/create-fieldmap-message`;

export const delete_fieldmap_message_Url = `${baseUrl}/api/organizer/delete-field-map`;

export const view_stayandplay_Url = `${baseUrl}/api/organizer/event-stay_and_play`;

export const create_stayandplay_Url = `${baseUrl}/api/organizer/create-stay_and_play`;

export const update_stayandplay_Url = `${baseUrl}/api/organizer/update-stay_and_play`;

export const delete_stayandplay_Url = `${baseUrl}/api/organizer/delete-stay_and_play`;

export const create_stayandplay_message_Url = `${baseUrl}/api/organizer/stay_and_play-message`;

export const getAdminPermissionList_Url = `${baseUrl}/api/organizer/permission-admin-group`;

export const CreateOrgAdminPermissionGroup_Url = `${baseUrl}/api/organizer/permission-admin-group`;

export const GetAllUsersList_Url = `${baseUrl}/api/get-all-user`;

export const InviteAdmin_Url = `${baseUrl}/api/organizer/invite-admin`;

export const getStaffPermissionList_Url = `${baseUrl}/api/organizer/staff-group`;

export const CreateOrgStaffPermissionGroup_Url = `${baseUrl}/api/organizer/permission-group`;

export const delete_Staff_permission_Url = `${baseUrl}/api/organizer/permission-group`;

export const get_Staff_permission_Url = `${baseUrl}/api/organizer/get-group-permission`;

export const set_Staff_permission_Url = `${baseUrl}/api/organizer/change-permission`;

export const get_staff_list_Url = `${baseUrl}/api/organizer/get-staff`;

export const create_merchat_Url = `${baseUrl}/api/create-merchant-account`;

export const move_to_free_team_player_Url = `${baseUrl}/api/organizer/moveTo-free-team-player`;

export const Org_Dms_user_Url = `${baseUrl}/api/organizer/connected-all-user`;
export const Club_Dms_user_Url = `${baseUrl}/api/club/connected-all-user`;

export const getAllInvitationById_Url = `${baseUrl}/api/organizer/get-all-invitation`;
export const getAllClubInvitationById_Url = `${baseUrl}/api/club/get-all-invitation`;

export const changeOrgInvitation_Url = `${baseUrl}/api/organizer/change-invitation`;
export const changeClubInvitation_Url = `${baseUrl}/api/club/change-invitation`;

export const switch_Notification_Account_Url = `${baseUrl}/api/user-switch-account`;

export const api_liability = `${baseUrl}/api/liability`;

export const getRegisterTeamPaybleAmount_Url = `${baseUrl}/api/club/register-team-payable-amount`;

export const ReadNotificationUrl_Url = `${baseUrl}/api/read-notification`;

export const getNotificationCount_Url = `${baseUrl}/api/get-notification-count`;

export const ReadOrgInvitation_Url = `${baseUrl}/api/organizer/read-invitation`;

export const ReadClubInvitation_Url = `${baseUrl}/api/club/read-invitation`;
