
import { CREATE_DISCOUNT_CODE_ERROR, CREATE_DISCOUNT_CODE_LOADING, CREATE_DISCOUNT_CODE_SUCCESS, RESET_CREATE_DISCOUNT_CODE } from "../../constants";

let initialState = {
    error: false,
    success: false,
    loading: false,
    errors: [],
    payload: {},
}

export default function createDiscountCodeReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_DISCOUNT_CODE_LOADING:
            return { ...state, error: false, loading: true, success: false }
        case CREATE_DISCOUNT_CODE_SUCCESS:
            return { ...state, error: false, loading: false, success: true, message: action.msg }
        case CREATE_DISCOUNT_CODE_ERROR:
            return { ...state, error: true, loading: false, success: false, errors: [] }
        case RESET_CREATE_DISCOUNT_CODE:
            return { ...state, error: false, success: false, loading: false, errors: [] }
        default:
            return state
    }
}