import React, { useEffect, useState } from "react";
import { useSelector, connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  addRoaster,
  addTeamPlayer,
  getPlayerList,
  InvitePlayerClub,
  playerAcceptList,
} from "../../../service/action/Club/clubAction";

import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import {
  RESET_ADD_TEAM_PLAYER,
  RESET_CREATE_TEAM,
} from "../../../service/constants";
import { useHistory, useParams } from "react-router";
import { getTeamDetails } from "../../../service/action/Club/clubAction";
import Spinner from "../../../Spinner/Spinner";

const AddPlayerToTeam = (props) => {
  const { id, id1 } = useParams();
  const history = useHistory();

  const [selectedPlayer, setSelectedPlayer] = useState([]);

  const getPlayerAcceptList = props.getPlayerAcceptListHandler;
  useEffect(() => {
    getPlayerAcceptList();
    props.getTeamDetails({ id: id });
    // getPlayerList()
  }, []);

  // const getPlayerList = props.getPlayerListHandler

  const notify = (msg) => toast(msg);

  const [playerMember, setPlayerMember] = useState(null);

  const playerAcceptList = useSelector(
    (state) => state.playerAcceptListReducer.user.data
  );
  const teamDetail = useSelector(
    (state) => state.getTeamDetailsReducer.user.data
  );

  useEffect(() => {
    if (playerAcceptList) {
      const list = playerAcceptList.filter((e) => e.club_player !== false);
      const teamPlayerIds = teamDetail?.player.map((e) => e._id);
      setPlayerMember(list.filter((e) => !teamPlayerIds.includes(e._id)));
    }
  }, [playerAcceptList]);

  // const getPlayers = useSelector(state => state.getAllSeasonReducer.user)
  const InvitedMsg = useSelector((state) => state.createTeamReducer.message);

  const invite = () => {
    // props.addTeamPlayer({
    //     player: selectedPlayer.map(v => v._id),
    //     is_roster: false,
    //     team: id
    // })
  };

  useEffect(() => {
    if (props.submit?.success) {
      history.push(`/teams/${id}`);
    }
  }, [props.submit]);

  const handlePlayer = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      const player = playerMember.find((v) => v._id === value);
      setSelectedPlayer([...selectedPlayer, player]);
    } else {
      const playerIndex = selectedPlayer.findIndex((v) => v._id === value);
      selectedPlayer.splice(playerIndex, 1);
      setSelectedPlayer([...selectedPlayer]);
    }
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="dash-head">Add player</h5>
            <div>
              <div>
                <button className="btn-save" onClick={invite}>
                  Add
                </button>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table event-table mt-30">
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
              {!!playerMember && playerMember.length !== 0 ? (
                <>
                  <tbody>
                    {playerMember.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="dashboard-img">
                                <img src="./images/pch-images/pch.svg" />
                              </div>
                              <span className="ml-12">{v.first_name}</span>
                            </div>
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              name={v.first_name}
                              value={v._id}
                              onChange={(e) => handlePlayer(e)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              ) : (
                <>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "500px" }}
                  >
                    <span className="noti-type" style={{ fontSize: "1.5rem" }}>
                      No players to display
                    </span>
                  </div>
                </>
              )}
            </table>
            {!playerMember && (
              <>
                <Spinner />
              </>
            )}
          </div>
          <Toaster />
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  submit: state.addTeamPlayerReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // getAllClubHandler: user => dispatch(getAllClub(user)),
  getTeamDetails: (user) => dispatch(getTeamDetails(user)),
  invitePlayerHandler: (user) => dispatch(addRoaster(user)),
  addTeamPlayer: (user) => dispatch(addTeamPlayer(user)),
  // getPlayerListHandler: user => dispatch(getPlayerList(user)),
  // resetcreateTeamReducer: () => dispatch({ type: RESET_ADD_TEAM_PLAYER }),
  // userDataHandler: user => dispatch(userData(user)),
  getPlayerAcceptListHandler: (user) => dispatch(playerAcceptList(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPlayerToTeam);
