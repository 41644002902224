import { baseUrl } from "../../apiSheet";

export const signInUrl = `${baseUrl}/super-admin/login`;
export const logOutUrl = `${baseUrl}/super-admin/log-out`;
export const getStateUrl = `${baseUrl}/api/state`;
export const getCity = `${baseUrl}/api/city`;
export const getSportUrl = `${baseUrl}/api/sport`;
export const changePassword_Url = `${baseUrl}/super-admin/change-password`;
export const userDetails_Url = `${baseUrl}/super-admin/admin-profile`;

export const addSuperAdminUrl = `${baseUrl}/super-admin/super-admin`;
export const getSuperadminList_Url = `${baseUrl}/super-admin/get-admin-list`;
export const updateSuperadminList_Url = `${baseUrl}/super-admin/update-admin`;
export const deleteSuperadminList_Url = `${baseUrl}/super-admin/delete-admin`;

export const CreateOrgUrl = `${baseUrl}/super-admin/event-organizer`; // same for all create api change only type
export const dashboardUrl = `${baseUrl}/super-admin/dashboard`;
export const GetOrgListUrl = `${baseUrl}/super-admin/organizer-list`;
export const UpdateOrgUrl = `${baseUrl}/super-admin/update-event-organizer`;
export const DeleteOrgUrl = `${baseUrl}/super-admin/delete-organizer`;

export const merchantUrl = `${baseUrl}/super-admin/merchant-list`;
export const transactionUrl = `${baseUrl}/super-admin/transaction-list`;

// ........................  Club  ........................
export const GetClubListUrl = `${baseUrl}/super-admin/club-list`;
export const UpdateClubUrl = `${baseUrl}/super-admin/update-club`;
export const DeleteClubUrl = `${baseUrl}/super-admin/delete-club`;

// ......................  Organizer Login ...........
export const OrgLogin_Url = `${baseUrl}/super-admin/user-impersonation`;

// .........................   Staff   ........................
export const GetStaffListUrl = `${baseUrl}/super-admin/staff-list`;
export const UpdateStaff_Url = `${baseUrl}/super-admin/update-staff`;
export const DeleteStaff_Url = `${baseUrl}/super-admin/delete-staff`;

// .......................   Coach  ............................
export const GetCoachListUrl = `${baseUrl}/super-admin/coach-list`;
export const UpdateCoachUrl = `${baseUrl}/super-admin/update-coach`;
export const DeleteCoachUrl = `${baseUrl}/super-admin/delete-coach`;

// ..................  Parent  ..............................
export const GetParent_GaurdianListUrl = `${baseUrl}/super-admin/parent-list`;
export const UpdateParent_GaurdianListUrl = `${baseUrl}/super-admin/update-parent`;
export const DeleteParent_GaurdianListUrl = `${baseUrl}/super-admin/delete-parent`;

// ...................  Player  ...................................
export const GetPlayerListUrl = `${baseUrl}/super-admin/player-list`;
export const UpdatePlayerUrl = `${baseUrl}/super-admin/update-player`;
export const DeletePlayerUrl = `${baseUrl}/super-admin/delete-player`;

// ...................  config  ...................................
export const GetConfigUrl = `${baseUrl}/super-admin/configuration`;
export const AddConfigUrl = `${baseUrl}/super-admin/configuration`;
export const UpdateConfigUrl = `${baseUrl}/super-admin/configuration`;
export const DeleteConfigUrl = `${baseUrl}/super-admin/configuration`;

//  sport url
export const sportUrl = `${baseUrl}/super-admin/sport`;

//  check mail is Exist url
export const checkMailExistUrl = `${baseUrl}/super-admin/check-email`;

//  liability url
export const liabiltyUrl = `${baseUrl}/super-admin/liability`;

// ...............  Change Status  .................
export const ChangeStatusUrl = `${baseUrl}/super-admin/update-admin-status`;

export const UserStatusUrl = `${baseUrl}/super-admin/update-user-status`;
