import { EDIT_DISCOUNT_CODE_ERROR, EDIT_DISCOUNT_CODE_LOADING, EDIT_DISCOUNT_CODE_SUCCESS, RESET_EDIT_DISCOUNT_CODE } from "../../constants";

let initialState={
    error:false,
    success:false,
    loading:false,
    errors:[]
}

export default function editDiscountCodeReducer(state=initialState,action){
    switch(action.type){
        case EDIT_DISCOUNT_CODE_LOADING:
            return{...state,error:false,loading:true,success:false};
        case EDIT_DISCOUNT_CODE_SUCCESS:
            return{...state,error:false,success:true,loading:false,message:action.msg};
        case EDIT_DISCOUNT_CODE_ERROR:
            return{...state,error:true,success:false,loading:false};
        case RESET_EDIT_DISCOUNT_CODE:
                return { ...state, error: false, success: false, loading: false, errors: [] }
        default:
            return state;
    }
}