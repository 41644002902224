import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  getAllCity,
  getAllSport,
  getAllState,
} from "../../service/action/userAction";
import { useSelector, useDispatch } from "react-redux";
import ReactInputMask from "react-input-mask";

const EventAccountForm = ({ state, eventhandler, errors, handleSubmit }) => {
  const dispatch = useDispatch();
  const allstate = useSelector((state) => state.getStateReducer.states);
  const allcity = useSelector((state) => state.getCityReducer.citys);
  const sportList = useSelector((state) => state.allSportReducer).SPORTs;

  useEffect(() => {
    const stateID = allstate.find((v) => v.name === state.state)?._id;
    if (stateID !== undefined) {
      dispatch(getAllCity({ id: stateID }));
    }
    dispatch(getAllState());
  }, [state.state]);

  useEffect(() => {
    dispatch(getAllSport());
  }, []);

  return (
    <>
      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {
            error: errors.orgName,
          })}
          placeholder="Organizer Name*"
          id="orgName"
          name="orgName"
          value={state.orgName}
          onChange={(e) => eventhandler(e)}
        />
        {errors.orgName && (
          <span className="text-red small d-block">
            Enter organization name
          </span>
        )}
      </div>

      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {
            error: errors.address,
          })}
          placeholder="Address*"
          id="address"
          name="address"
          // value={x.last_name}
          value={state.address}
          onChange={(e) => eventhandler(e)}
        />
        {errors.address && (
          <span className="text-red small d-block">Enter last address</span>
        )}
      </div>

      {/* <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {
            // error: errors.first_name,
          })}
          placeholder="Middle Name"
          id="middle_name"
          name="middle_name"
          value={state.middle_name}
          onChange={(e) => eventhandler(e)}
        />
      </div>
      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {
            error: errors.last_name,
          })}
          placeholder="Last Name*"
          id="last_name"
          name="last_name"
          // value={x.last_name}
          onChange={(e) => eventhandler(e)}
        />
        {errors.last_name && (
          <span className="text-red small d-block">Enter last name</span>
        )}
      </div> */}

      {/* 
      <div className="mt-18">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            value={state.DOB}
            clearable
            placeholder="DOB"
            onChange={(e) =>
              eventhandler({
                target: {
                  value: e,
                  name: "DOB",
                  id: "DOB",
                },
              })
            }
            maxDate={new Date("01-01-2005")}
            format="MM/dd/yyyy"
            mask="__/__/____"
            inputVariant="outlined"
            autoOk={true}
            InputAdornmentProps={{ position: "end" }}
            className={classNames("form-control team-name", {
              //   error: DOB,
            })}
          />
        </MuiPickersUtilsProvider>
        {errors.DOB == "required" && (
          <span className="text-red small d-block">Enter birth date</span>
        )}
      </div> */}

      <div className="mt-18">
        <select
          className={classNames("form-control team-name", {
            error: errors.state,
          })}
          placeholder="State *"
          id="state"
          name="state"
          value={state.state}
          onChange={eventhandler}
        >
          <option value="">State *</option>
          {allstate.map((v, i) => {
            return (
              <option key={v._id} value={v._id}>
                {v.name}
              </option>
            );
          })}
        </select>
        {errors.state && (
          <span className="text-red small d-block">
            Select state {errors.state}
          </span>
        )}
      </div>

      <div className="mt-18">
        <input
          type="text"
          list="cityNameList"
          className={classNames("form-control team-name", {
            error: errors?.city,
          })}
          placeholder="City *"
          name="city"
          id="city"
          value={state.city}
          onChange={eventhandler}
          autoComplete="off"
        />
        {errors?.city && (
          <span className="text-red small d-block">Enter city</span>
        )}

        <datalist id="cityNameList">
          {allcity.map((e, i) => {
            return <option value={e.name} key={i} />;
          })}
        </datalist>
      </div>

      <div className="mt-18">
        <ReactInputMask
          mask="99999"
          className="form-control team-name quantity"
          value={state.zip}
          onChange={(e) => eventhandler(e)}
          maskChar={"_"}
          placeholder="Zip *"
          name="zip"
          id="zip"
        />

        {errors.zip && (
          <span className="text-red small d-block">Enter zip code</span>
        )}
      </div>

      <div className="mt-18">
        <ReactInputMask
          mask="999-999-9999"
          className="form-control team-name quantity"
          value={state.telephone}
          onChange={(e) => eventhandler(e)}
          maskChar={"_"}
          placeholder="Phone *"
          name="telephone"
          id="telephone"
        />
        {errors.telephone && (
          <span className="text-red small d-block">Enter phone number.</span>
        )}
      </div>

      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {})}
          placeholder="Website"
          id="website"
          name="website"
          value={state.website}
          onChange={(e) => eventhandler(e)}
        />
      </div>

      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {})}
          placeholder="description"
          id="description"
          name="description"
          value={state.description}
          onChange={(e) => eventhandler(e)}
        />
      </div>

      {/* Organizer Details */}

      <h2 className="text-white text-center my-3">Event Organizer Details</h2>

      <div className="mt-18">
        <input
          type="text"
          className="form-control team-name"
          placeholder="First Name"
          id="first_name"
          name="first_name"
          value={state.first_name}
          onChange={(e) => eventhandler(e)}
        />
      </div>

      <div className="mt-18">
        <input
          type="text"
          className="form-control team-name"
          placeholder="Last Name"
          id="last_name"
          name="last_name"
          value={state.last_name}
          onChange={(e) => eventhandler(e)}
        />
      </div>

      <div className="mt-18">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            value={state.DOB}
            clearable
            placeholder="DOB"
            onChange={(e) =>
              eventhandler({
                target: {
                  value: e,
                  name: "DOB",
                  id: "DOB",
                },
              })
            }
            maxDate={new Date("01-01-2005")}
            format="MM/dd/yyyy"
            mask="__/__/____"
            inputVariant="outlined"
            autoOk={true}
            InputAdornmentProps={{ position: "end" }}
            className="form-control team-name"
          />
        </MuiPickersUtilsProvider>
      </div>

      <div className="mt-18">
        <input
          type="email"
          className="form-control team-name"
          placeholder="Personal Email"
          id="organizer_personal_mail"
          name="organizer_personal_mail"
          value={state.organizer_personal_mail}
          onChange={(e) => eventhandler(e)}
        />
      </div>
      <Button
        className="mt-3"
        style={{ backgroundColor: "#189AD6" }}
        onClick={handleSubmit}
      >
        Create
      </Button>
    </>
  );
};

export default EventAccountForm;
