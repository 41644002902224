import { GET_EVENTS_REGISTER_TEAM_ERROR, GET_EVENTS_REGISTER_TEAM_LOADING, GET_EVENTS_REGISTER_TEAM_SUCCESS } from "../../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false
};

const getEventRegisterTeamReducer = (state = initialState, action) =>  {
  switch (action.type) {
    case GET_EVENTS_REGISTER_TEAM_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false,success:false};
     
    case GET_EVENTS_REGISTER_TEAM_SUCCESS:  
      // alert(" reducer Error")
      return { ...state, loading: false,success:true,user:action.payload};
 
    case GET_EVENTS_REGISTER_TEAM_ERROR:
      // alert(" reducer Loading")
      return { ...state,error: true, errors: action.errors };
   
    default:
      return state;
  }
}
export default getEventRegisterTeamReducer;



