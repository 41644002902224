import { GET_PLAYER_LIST_ERROR, GET_PLAYER_LIST_LOADING, GET_PLAYER_LIST_SUCCESS, RESET_GET_PLAYER_LIST } from "../../../constants";

let initialState = {
 error: false,
 success: false,
 loading: false,
 errors: [],
 data: []
}

export default function getClubPlayer(state = initialState, action) {
 switch (action.type) {
  case GET_PLAYER_LIST_LOADING:
   // alert(" reducer succesfull")
   return { ...state, success: false, error: false, loading: true };

  case GET_PLAYER_LIST_SUCCESS:
   // alert(" reducer Error")
   return { ...state, loading: false, success: true, user: action.payload };

  case GET_PLAYER_LIST_ERROR:
   // alert(" reducer Loading")
   return { ...state, error: true, errors: action.errors, success: false };

  case RESET_GET_PLAYER_LIST:
   // alert(" reducer Loading")
   return { ...state, error: false, loading: false, success: false };

  default:
   return state;
 }
}