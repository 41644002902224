import {
  ACCOUNTING_ERROR,
  ACCOUNTING_LOADING,
  ACCOUNTING_SUCCESS,
} from "../../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
};

export default function getAccountingReducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNTING_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case ACCOUNTING_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };

    case ACCOUNTING_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}
