import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useHistory, useParams } from "react-router-dom";
import { addDays } from "date-fns";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  RegistrationAction,
  clonEvent,
  deleteEvent,
  getAccountDetails,
  getAllEvent,
  publishSchedule,
} from "../../../service/action/userAction";
import {
  RESET_CLON_EVENT,
  RESET_CREATE_EVENT,
  RESET_CREATE_TEAM,
  RESET_DELETE_EVENT,
  RESET_EDIT_EVENT,
  RESET_EVENT_REGISTRATION,
  RESET_PUBLISH_SCHEDULE,
} from "../../../service/constants";
import { toast } from "react-toastify";
import Toaster from "../../../Toaster/Toaster";
import Spinner from "../../../Spinner/Spinner";
import Moment from "react-moment";
import moment from "moment";
import DeletePopUp from "../../../club/components/DeletePopUp/DeletePopUp";

const Org_Event = (props) => {
  // const getdata = props.getEventHandler({id:0});
  // const [pageLoad, setPageLoad] = useState(false)
  let [loading, setLoading] = useState(true);
  // let [cloneLoading, setCLoneLoading] = useState(false);
  const [filterEvent, setFilterEvent] = useState(0);
  const [state, setState] = useState({
    search: "",
  });

  const [startDate, setStartDate] = useState(new Date());
  const [weekStart, setWeekStartDate] = useState(new Date());

  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [eventList, setEventList] = useState([]);

  // const getToken = localStorage.getItem("token")
  const allEvent = useSelector((state) => state.getAllEventReducer.user);
  const allEventMsg = useSelector((state) => state.getAllEventReducer);
  const deleteEventMsg = useSelector(
    (state) => state.deleteEventReducer.message
  );
  const clonEventMsg = useSelector((state) => state.clonEventReducer.message);
  const saveEventMsg = useSelector((state) => state.createEventReducer.message);
  const editEventMsg = useSelector((state) => state.editEventReducer.message);
  const createTeamMsg = useSelector((state) => state.createTeamReducer.message);
  const publishScheduleMsg = useSelector(
    (state) => state.publishScheduleRedcuer.message
  );

  useEffect(() => {
    props.getEventHandler({ id: 0 });
    props.getAccountDetails();
  }, []);

  const filter = ({ id }) => {
    props.getEventHandler({ id: id });
    setFilterEvent(id);
  };

  const publishSchedules = ({ id, schedule }) => {
    if (schedule == 0) {
      schedule = 1;
    } else {
      schedule = 0;
    }
    props.publishHandler({
      event: id,
      is_publish: schedule,
    });
  };

  const eventRegistration = ({ id, schedule }) => {
    props.RegistrationAction({
      event: id,
      show_switch: schedule === 0 ? 1 : 0,
    });
  };

  // useEffect(() => {
  //   setCLoneLoading(props.deleteEventReducer.loading);
  // }, [props.deleteEventReducer]);

  const notify = (msg) => toast(msg);
  useEffect(() => {
    if (props.submit?.success) {
      notify(deleteEventMsg.apiMessage);
      props.resetdeleteEventReducer();
      props.getEventHandler({ id: 0 });
    }
  }, [props.submit]);

  useEffect(() => {
    if (props.submit_1?.success) {
      notify(clonEventMsg.message);
      props.resetclonEventReducer();
      props.getEventHandler({ id: 0 });
    }
  }, [props.submit_1]);

  //create event Msg
  useEffect(() => {
    if (props.saveData?.success) {
      notify(saveEventMsg.message);
      props.resetCreateEventReducer();
    }
  }, [props.saveData]);

  //edit event Msg
  useEffect(() => {
    if (props.editEventmessage?.success) {
      notify(editEventMsg.message);
      props.reseteditEventReducer();
    }
  }, [props.editEventmessage]);

  //create team Msg
  useEffect(() => {
    if (props.createTeammessage?.success) {
      notify(createTeamMsg.message);
      props.resetCreateTeamReducer();
    }
  }, [props.createTeammessage]);

  //create team Msg
  useEffect(() => {
    if (props.publish?.success) {
      notify(publishScheduleMsg.message);
      props.resetPublishSchedule();
      props.getEventHandler({ id: 0 });
    }
  }, [props.publish]);

  useEffect(() => {
    if (props.registration?.success) {
      notify(props.registration.message);
      props.resetRegistration();
      props.getEventHandler({ id: 0 });
    }
  }, [props.registration]);

  const [serverError, setServerError] = useState(false);
  useEffect(() => {
    if (props.allEvent?.success) {
      setLoading(false);
      setEventList(props.allEvent.user);
    } else if (props.allEvent?.error) {
      notify(allEventMsg.error.message);
    }
    if (props.allEvent?.error) {
      if (allEventMsg.networkError.error) {
        setLoading(false);
        setServerError(true);
      }
    }
  }, [props.allEvent]);

  const Searchval = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    UpdateArray(e.target.value);
  };

  const UpdateArray = (search) => {
    if (search == "") {
      setEventList(allEvent);
    } else {
      const data = allEvent?.filter((e) => {
        const value = e.event_name.toLowerCase();
        return value.trim().includes(search.toLowerCase().trim());
      });
      setEventList(data);
    }
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="create-team-head">
            <h5 className="dash-head mb-0 mr-4">
              {filterEvent == 0
                ? "All "
                : filterEvent == 1
                ? "Weekly "
                : "Monthly "}
              Events
            </h5>
            <div className="d-md-flex align-items-center d-none">
              <div className="search-section">
                <i className="mdi mdi-magnify search-ico"></i>
                <input
                  type="text"
                  className="form-control"
                  id="search"
                  name="search"
                  value={state.search}
                  placeholder="Search"
                  onChange={Searchval}
                  autoComplete="off"
                />
              </div>
              <div className="dropdown event-drp mt-4 mt-md-0">
                <i
                  className="mdi mdi-filter-outline filter-ico"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <Link
                    className="dropdown-item"
                    to="/events"
                    onClick={() => filter({ id: 0 })}
                  >
                    All
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/events"
                    onClick={() => filter({ id: 1 })}
                  >
                    Weekly
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/events"
                    onClick={() => filter({ id: 2 })}
                  >
                    Monthly
                  </Link>
                </div>
              </div>
            </div>

            <div className="team-details mt-3">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link border-bottom-0 py-0"
                    id="calendar"
                    data-toggle="pill"
                    href="#cal-tab"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    <i className="mdi mdi-calendar-blank fs-18"></i>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active border-bottom-0 py-0"
                    id="grid"
                    data-toggle="pill"
                    href="#grid-tab"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    <i className="mdi mdi-format-list-bulleted fs-18"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade"
              id="cal-tab"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="team-details ">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="months"
                      data-toggle="pill"
                      href="#months-tab"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Months
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="week"
                      data-toggle="pill"
                      href="#week-tab"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Weeks
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content" id="pills-dateContent">
                <div
                  className="tab-pane fade show active"
                  id="months-tab"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="mt-42">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="form-control"
                      inline
                      id="dates"
                    />
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="week-tab"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="mt-42">
                    <DatePicker
                      selected={weekStart}
                      onChange={(date) => setWeekStartDate(date)}
                      minDate={new Date()}
                      maxDate={addDays(new Date(), 6)}
                      inline
                      placeholderText="Select a date before 5 days in the future"
                      id="week"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade show active"
              id="grid-tab"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="team-details">
                <div className="d-flex justify-content-center">
                  {loading ? <Spinner /> : null}
                </div>
                {serverError && (
                  <div className="alert alert-danger" role="alert">
                    {allEventMsg.networkError.error}
                  </div>
                )}
                {!loading && eventList ? (
                  <>
                    {/* desktop section */}
                    <div className="mt-30 table-responsive d-none d-md-block">
                      <table className="table event-table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            {/* <th>location</th> */}
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {eventList.length == 0 ? (
                            <tr>
                              <td
                                className="noti-type1 text-center"
                                colSpan="8"
                              >
                                No events to display
                              </td>
                            </tr>
                          ) : (
                            eventList.map((v, i) => {
                              const evedate = new Date(
                                v.event_start_date
                              ).toLocaleDateString();
                              const enddate = new Date(
                                v.event_end_date
                              ).toLocaleDateString();
                              const now = new Date().toLocaleDateString();
                              const { _id, event_name } = v;
                              return (
                                <tr key={i} className="pay-row">
                                  <td>{i + 1}</td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="dashboard-img">
                                        <img
                                          src={
                                            v.event_image !== ""
                                              ? v.event_image
                                              : "./images/pch-images/pch.svg"
                                          }
                                          alt="Event image"
                                        />
                                      </div>
                                      <Link
                                        className="ml-2 text-decoration-none heading filter-string"
                                        to={`/event-details/${_id}`}
                                      >
                                        {event_name}
                                      </Link>
                                    </div>
                                  </td>
                                  <td>
                                    {v.event_type == 0
                                      ? "Club Only"
                                      : v.event_type == 1
                                      ? "Parent/Player Only "
                                      : "Club & Parent/Player"}
                                  </td>
                                  <td>
                                    <Moment
                                      date={new Date(v.event_start_date)}
                                      format="MM DD, YYYY"
                                    />
                                  </td>
                                  <td>
                                    <Moment
                                      date={new Date(v.event_end_date)}
                                      format="MM DD, YYYY"
                                    />
                                  </td>
                                  {/* <td>
                                  {v.event_facility
                                    .filter((item, index) => index < 1)
                                    .map((a, i) => {
                                      return (
                                        // <td key={a._id}>
                                        a?.event_facility !== "" &&
                                          a?.facility_city_name
                                          ? a?.facility_city_name
                                          : "N/A"

                                        // </td>
                                      );
                                    })}
                                </td> */}
                                  <td>
                                    {/* <span className={"badge badge-" + (evedate > now ? 'success' : evedate === now ? 'warning' : 'danger')}>{evedate > now ? "Upcoming" : evedate <= now && evedate >= now ? "Open" : "Closed "} </span> */}
                                    {/* <span className={"badge badge-" + (evedate > now? 'success' : enddate < now ? 'danger' : 'warning')}>{evedate > now? "Upcoming" : enddate < now ? "Closed" : "Open"} </span> */}
                                    {/* <span className={"badge badge-" + (evedate > now? 'success' : enddate < now ? 'danger' : 'warning')}>{evedate > now ? "Upcoming" : enddate < now ? "Closed" : evedate > now && enddate < now ? "Open" } </span> */}
                                    {moment(evedate).isAfter(now) ? (
                                      <span className="badge badge-success">
                                        Upcoming
                                      </span>
                                    ) : moment(enddate).isBefore(now) ? (
                                      <span className="badge badge-danger">
                                        close
                                      </span>
                                    ) : (
                                      <span className="badge badge-warning">
                                        Open
                                      </span>
                                    )}

                                    {/* {
                                                                         if(moment(evedate).isAfter(now) && moment(enddate).isBefore(now)) &&
                                                                          <span className="badge badge-warning">Open</span>
                                                                      } */}
                                  </td>
                                  <td>
                                    <div className="dropdown event-drp">
                                      <span
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="mdi mdi-dots-vertical"></i>{" "}
                                      </span>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuLink"
                                      >
                                        <Link
                                          className="dropdown-item"
                                          to={`/editEvent/${v._id}`}
                                        >
                                          <i className="mdi mdi-border-color"></i>
                                          Edit
                                        </Link>
                                        <Link
                                          className="text-decoration-none dropdown-item"
                                          to={`/events`}
                                          onClick={() => {
                                            setIsDelete(true);
                                            setDeleteId(v._id);
                                          }}
                                        >
                                          <i className="fas fa-trash-alt  mr-2 text-danger"></i>
                                          Delete
                                        </Link>
                                        <Link
                                          className="dropdown-item"
                                          to="/events"
                                          onClick={() => {
                                            setLoading(true);
                                            props.clonEventHandler(v._id);
                                          }}
                                        >
                                          <i className="mdi mdi-content-copy"></i>{" "}
                                          Clone
                                        </Link>
                                        <div className="dropdown-item">
                                          {/* onClick={() => props.publishHandler({ event: v._id, is_publish: v.is_publish == 1 ? 0 : 1 })} */}
                                          <div className="custom-control custom-switch align-item-center">
                                            <input
                                              type="checkbox"
                                              id="publish"
                                              value={v?._id}
                                              className="custom-control-input payment p-1"
                                              checked={
                                                v.is_publish == 1 ? true : false
                                              }
                                              onChange={(e) =>
                                                publishSchedules({
                                                  id: v._id,
                                                  schedule: v.is_publish,
                                                })
                                              }
                                            />
                                            <label
                                              className="custom-control-label p-1"
                                              htmlFor="publish"
                                            >
                                              Publish Schedule
                                            </label>
                                          </div>
                                        </div>
                                        {/* <Link
                                        className="dropdown-item"
                                        to="/events"
                                      > */}
                                        <div className="dropdown-item">
                                          <div className="custom-control custom-switch align-item-center">
                                            <input
                                              type="checkbox"
                                              id="registration"
                                              value={v?._id}
                                              className="custom-control-input payment p-1"
                                              checked={
                                                v.show_switch == 0
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                eventRegistration({
                                                  id: v._id,
                                                  schedule: v.show_switch,
                                                });
                                              }}
                                            />
                                            <label
                                              className="custom-control-label p-1"
                                              htmlFor="registration"
                                            >
                                              Registration
                                            </label>
                                          </div>
                                          {/* </Link> */}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null}
                {/* mobile view  */}
                <div className="mt-30 d-md-none">
                  <div className="mt-4">
                    {eventList.map((v, i) => {
                      const evedate = new Date(
                        v.event_start_date
                      ).toLocaleDateString();
                      const enddate = new Date(
                        v.event_end_date
                      ).toLocaleDateString();
                      const now = new Date().toLocaleDateString();
                      return (
                        <div
                          className="d-flex justify-content-between reg-details pay-row"
                          key={v._id}
                        >
                          <div className="d-flex">
                            <div className="dashboard-img mr-3">
                              <img
                                src={
                                  v.event_image !== ""
                                    ? v.event_image
                                    : "./images/pch-images/pch.svg"
                                }
                              />
                            </div>
                            <div className="reg-content">
                              <Link
                                className="text-decoration-none text-blue fw-m filter-string"
                                to={`/event-details/${v._id}`}
                              >
                                {v.event_name}
                              </Link>
                              {/* <span className="d-block text-gray mt-2">Starts: {formatYmd(new Date(v.event_from_date))}</span> */}
                              <span className="d-block text-gray mt-2">
                                Event Date:{" "}
                                {
                                  <Moment
                                    date={new Date(v.event_start_date)}
                                    format="MM DD, YYYY"
                                  />
                                }
                              </span>
                              {/* <span className="d-block text-gray mt-2">Host: {v.hosts}</span> */}
                              {v.event_facility.map((a, i) => {
                                return (
                                  <span
                                    className="d-block text-gray mt-2"
                                    key={a._id}
                                  >
                                    Registration Satus:{" "}
                                    {moment(evedate).isAfter(now) ? (
                                      <span className="">Upcoming</span>
                                    ) : moment(enddate).isBefore(now) ? (
                                      <span className="">close</span>
                                    ) : (
                                      <span className="">Open</span>
                                    )}
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                          <div className="dropdown event-drp">
                            <i
                              className="mdi mdi-dots-vertical"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></i>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <Link
                                className="dropdown-item"
                                to="/events"
                                onClick={() => props.clonEventHandler(v._id)}
                              >
                                <i className="mdi mdi-content-copy"></i>
                                Clone
                              </Link>
                              <Link
                                className="dropdown-item"
                                to={`/editEvent/${v._id}`}
                              >
                                <i className="mdi mdi-border-color"></i>Edit
                              </Link>
                              <Link
                                className="dropdown-item"
                                to="/events"
                                onClick={() => props.deleteEventHandler(v._id)}
                              >
                                <i className="fas fa-trash-alt"></i> Delete
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={() => props.deleteEventHandler(deleteId)}
      />
    </React.Fragment>
  );
};

// export default withRouter(Org_Event);
const mapStateToProps = (state) => ({
  submit: state.deleteEventReducer,
  submit_1: state.clonEventReducer,
  saveData: state.createEventReducer,
  editEventmessage: state.editEventReducer,
  createTeammessage: state.createTeamReducer,
  allEvent: state.getAllEventReducer,
  publish: state.publishScheduleRedcuer,
  registration: state.EventRegistrationReducer,
  deleteEventReducer: state.deleteEventReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getEventHandler: (user) => dispatch(getAllEvent(user)),
  // eventDataHandler: user => dispatch(eventData(user)),
  deleteEventHandler: (user) => dispatch(deleteEvent(user)),
  clonEventHandler: (user) => dispatch(clonEvent(user)),
  resetdeleteEventReducer: () => dispatch({ type: RESET_DELETE_EVENT }),
  resetclonEventReducer: () => dispatch({ type: RESET_CLON_EVENT }),
  resetCreateEventReducer: () => dispatch({ type: RESET_CREATE_EVENT }),
  reseteditEventReducer: () => dispatch({ type: RESET_EDIT_EVENT }),
  resetCreateTeamReducer: () => dispatch({ type: RESET_CREATE_TEAM }),
  getAccountDetails: (user) => dispatch(getAccountDetails(user)),
  publishHandler: (user) => dispatch(publishSchedule(user)),
  RegistrationAction: (user) => dispatch(RegistrationAction(user)),
  resetPublishSchedule: () => dispatch({ type: RESET_PUBLISH_SCHEDULE }),
  resetRegistration: () => dispatch({ type: RESET_EVENT_REGISTRATION }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Org_Event));
// 448
