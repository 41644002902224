import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { getAllClub, getAllStaff, inviteClub, inviteStaff, sendInvitationBymail } from '../../../service/action/userAction';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import Toaster from '../../../Toaster/Toaster';
import { RESET_CREATE_TEAM, RESET_SEND_INVITATION_EMAIL } from '../../../service/constants';
import $ from "jquery";
import Spinner from '../../../Spinner/Spinner';
import classNames from 'classnames';


const AddStaff = (props) => {
    const notify = (msg) => toast(msg);
    const [loading, setLoading] = useState(true)



    const getdata = props.getAllClubHandler;
    const { id } = useParams()

    useEffect(() => {
        getdata()

    }, [])
    const allstaff = useSelector(state => state.getAllClubReducer.user.data)
    const msg = useSelector(state => state.createTeamReducer.message)

    const invite = (_id) => {
        props.invitestaff({
            invitation_to: _id,
            invitation_for: id,
            invitation_as: 2
        })
    }

    useEffect(() => {
        if (props.submit?.success) {
            notify(msg.message)
            props.resetcreateTeamReducer()

            // history.push(`/event-details/${id}`);
        } else if (props.submit?.error) {
            notify("User already invited ")
        }
    }, [props.submit])

    useEffect(() => {
        if (props.staff?.success) {
            setLoading(false)
        }
    }, [props.staff])

    // const staff = [
    //     {
    //         name: 'username',
    //         email: "xyz@gmail.com"
    //     },
    //     {
    //         name: 'username',
    //         email: "xyz@gmail.com"
    //     },
    //     {
    //         name: 'username',
    //         email: "xyz@gmail.com"
    //     },
    //     {
    //         name: 'username',
    //         email: "xyz@gmail.com"
    //     }
    // ]

    const [state, setState] = useState({
        search: ""
    })



    const Searchval = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
        UpdateArray(e.target.value);
    }


    const UpdateArray = (search) => {
        var query = search;

        $(".filter-string").each(function (i, v) {
            if ($(v).text().toLowerCase().indexOf(query) === -1) {
                $(v).parents(".pay-row").addClass("d-none");
            } else {
                $(v).parents(".pay-row").removeClass("d-none");
            }
        });


    }
    const [email, setEmail] = useState("")

    const [error, setError] = useState(false)

    //send invitation email filed onchange
    const eventHandler = (e) => {
        const { id, value, name } = e.target;
        const validEmailRegex = RegExp(
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        );
        setEmail(
            e.target.value,
        );

        switch (name) {

            case "email":
                if (value.length == 0) {
                    setError("Email can not be empty");
                } else if (!validEmailRegex.test(value)) {
                    setError("Email is not valid !");
                } else {
                    setError(false);
                }
                break;
            default:
        }

    }

    //send invitastion by mail send button
    const sendEmail = (e) => {
        e.preventDefault()
        if (email.length == 0) {
            setError("Value can not be empty")
        }
        if (email !== "" && error==false) {
            props.sendInvitationBymailHandler({

                invite_user_email: email,
                invite_user_type: "2",
                invitation_for: id
            })

        }

    }

    useEffect(() => {
        if (props.sendByMail?.success) {
            notify("Invitation send successfully")
            props.resetInvitationByMailReducer()
            setTimeout(() => {
                setEmail("")

            }, 1000)
            setError(false)
        }
    }, [props.sendByMail])


    return (
        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="create-team-head">
                        <h5 className="dash-head mr-3 mb-0">Add Staff</h5>
                        <div className="d-flex align-items-center">
                            <div className="search-section mt-3 mt-md-0">
                                <i className="mdi mdi-magnify search-ico"></i>
                                <input type="text" className="form-control" id="search" name="search" value={state.search} placeholder="Search" onChange={Searchval} autoComplete="off" />
                            </div>
                            <div className="mt-4 mt-md-0 d-flex align-items-center ml-4">
                                {/* <i className="mdi mdi-open-in-new website-ico"></i>
                                <h5 className="payment mb-0 ml-12">Invite Link</h5> */}
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-md-6">
                            <div className="text-left invite-email mt-42">
                                <i className="mdi mdi-email"></i>
                                <input type="email" className={classNames("form-control team-name", { "error": error })} id="email" name="email" placeholder="Email" value={email} onChange={eventHandler} />
                            </div>

                            {error &&
                                <span className='text-red small d-block'>* {error}</span>}

                            <div className="mt-3 text-left">
                                <a className="btn btn-primary font-weight-medium auth-form-btn" onClick={sendEmail} >Send</a>
                            </div>

                            {/* <div className="mt-42 text-left">
                                <i className="mdi mdi-open-in-new website-ico fs-18"></i>
                                <Link to="#" className="ml-3 fs-15 text-decoration-none heading">Copy invite Link</Link>
                            </div> */}

                        </div>
                        <div className="col-md-6">
                            <div className="">
                                <img className="img-fluid img-invite-email" src="./images/invite-email.png" />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>

                        {loading ? <Spinner /> : null}
                    </div>
                    <div className="mt-30">
                        {
                            allstaff && !loading ? <>

                                {/* desktop view */}
                                <div className="table-responsive d-none d-md-block">
                                    <table className="table event-table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allstaff.map((v, i) => {
                                                    return <tr key={i} className='pay-row'>
                                                        <td>
                                                            <div className="d-flex align-items-center"> <div className="dashboard-img">
                                                                <img src="./images/pch-images/pch.svg" />
                                                            </div>
                                                                <span className="ml-12 filter-string">{v.name}</span>
                                                            </div>
                                                        </td>
                                                        <td>{v.user_id.email}</td>
                                                        <td>
                                                            <Link to="#" className="text-decoration-none" onClick={() => invite(v._id)}>
                                                                <i className="mdi mdi-plus text-blue mr-2 website-ico"></i>
                                                                <span className="text-blue">Invite</span></Link>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                {/* mobile view */}
                                <div className="mt-3 d-md-none">
                                    {
                                        allstaff.map((v, i) => {
                                            return <div className="d-flex justify-content-between reg-details" key={i}>
                                                <div className="d-flex w-100">
                                                    <div className="dashboard-img mr-3">
                                                        <img src="./images/pch-images/pch.svg" />
                                                    </div>
                                                    <div className="reg-content w-100">
                                                        <div className="d-flex justify-content-between">
                                                            <Link className="text-decoration-none text-blue fw-m" to="/event-details">{v.name}</Link>

                                                            <Link className="text-decoration-none text-blue fs-13 fw-m" to="/event-details"><i className="mdi mdi-plus text-blue mr-1 website-ico"></i>Add</Link>

                                                        </div>
                                                        <span className="d-block text-gray mt-2">Email: {v.email}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </>

                                : <></>
                        }


                    </div>
                    <Toaster />
                </div>
            </div>
        </React.Fragment>
    )
}

// export default withRouter(AddStaff);

const mapStateToProps = (state) => ({
    submit: state.createTeamReducer,
    staff: state.getAllClubReducer,
    sendByMail: state.sendInvitationByMailReducer
})

const mapDispatchToProps = dispatch => ({
    getAllClubHandler: user => dispatch(getAllStaff(user)),
    invitestaff: user => dispatch(inviteStaff(user)),
    resetcreateTeamReducer: () => dispatch({ type: RESET_CREATE_TEAM }),
    sendInvitationBymailHandler: user => dispatch(sendInvitationBymail(user)),
    resetInvitationByMailReducer: () => dispatch({ type: RESET_SEND_INVITATION_EMAIL })
    // userDataHandler: user => dispatch(userData(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddStaff))