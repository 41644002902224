import { UPDATE_LEAGUE_ERROR, UPDATE_LEAGUE_LOADING, UPDATE_LEAGUE_SUCCESS, UPDATE_RESET_LEAGUE } from "../../../constants";

let initialState = {
 loading: false,
 user: [{}],
 error: false,
 errors: [],
 success: false,
 payload: {}


};

export default function updateLeagueREducer(state = initialState, action) {
 switch (action.type) {

  case UPDATE_LEAGUE_LOADING:
   return { ...state, error: false, loading: true, success: false };

  case UPDATE_LEAGUE_SUCCESS:
   return { ...state, error: false, loading: false, success: true, message: action.msg };

  case UPDATE_LEAGUE_ERROR:
   return { ...state, error: true, loading: false, success: false, errors: action.errors, msg: action.errors }

  case UPDATE_RESET_LEAGUE:
   return { ...state, error: false, loading: false, success: false, errors: [], user: [{}] }

  default:
   return state;
 }
}

