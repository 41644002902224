import {
  CHANGE_INVITATION_STATUS_ERROR,
  CHANGE_INVITATION_STATUS_LOADING,
  CHANGE_INVITATION_STATUS_SUCCESS,
  RESET_CHANGE_INVITATION_STATUS,
} from "../../../constants";

let initialState = {
  loading: false,
  user: {},
  error: false,
  errors: [],
  success: false,
};

export default function changeNotificationStatusReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CHANGE_INVITATION_STATUS_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case CHANGE_INVITATION_STATUS_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };

    case CHANGE_INVITATION_STATUS_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    case RESET_CHANGE_INVITATION_STATUS:
      // alert(" reducer Loading")
      return { ...state, error: false, errors: [], success: false };

    default:
      return state;
  }
}
