import React from 'react'
// import { Link } from 'react-router-dom'



const PageNoteFound = () => {
    return (

        <React.Fragment>
            <div className="main-panel">
                <div className="content-wrapper justify-content-center d-flex align-item-center h-50">

                    <div className=" page-not-found ">

                        <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
                        <div className="inline-block align-middle">
                            <h2 className="font-weight-normal lead" id="desc">The page you requested was not found.</h2>
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default PageNoteFound
