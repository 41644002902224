import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import {
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import {
  addSchedule,
  createfieldmap,
} from "../../../service/action/userAction";
import {
  RESET_CREATE_FIELD_MAP,
  RESET_CREATE_TEAM,
} from "../../../service/constants";
import classNames from "classnames";
import Toaster from "../../../Toaster/Toaster";

const Addfieldmap = (props) => {
  const { id } = useParams();
  const notify = (msg) => toast(msg);
  const [Image, setImage] = useState(null);
  const [prevImg, setPrevImg] = useState();
  const history = useHistory();

  const [state, setstate] = useState({
    URLtext: "",
    image: "",
  });

  const [error, seterror] = useState({
    image: false,
  });

  useEffect(() => {
    if (props.createfieldmapReducer?.success) {
      notify(props.createfieldmapReducer?.message?.message);
      history.push(`/event-details/${id}`);
    }
  }, [props.createfieldmapReducer]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "image") {
      setstate((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setImage(e.target.files[0]);
      setPrevImg(
        e.target.files?.length !== 0 && URL.createObjectURL(e.target.files[0])
      );
    }

    switch (e.target.name) {
      case "image":
        if (value == "") {
          seterror((prevState) => ({
            ...prevState,
            image: "image can not be empty",
          }));
        } else if (!RegExp(/^image/gim).test(e.target.files[0].type)) {
          seterror((prevState) => ({
            ...prevState,
            image: "Only accept image file",
          }));
        } else {
          seterror((prevState) => ({
            ...prevState,
            image: false,
          }));
        }
        break;

      default:
    }
  };
  const imgDefault = (e) => {
    e.target.src = "/images/menu-placeholder.jpg";
    e.target.onerror = "/images/menu-placeholder.jpg";
  };

  const handlecreate = () => {
    if (state.image === "") {
      seterror((preve) => ({
        ...preve,
        image: "Enter Text",
      }));
    }

    if (state.image !== "") {
      props.createfieldmaphandler({
        event_id: id ? id : "",
        file: Image,
        type: 1,
      });
    }
  };

  return (
    <>
      {" "}
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="">
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: "bold" }}>Document </Form.Label>
              <div
                className={classNames("custom-file-upload", {
                  errorBorder: error.image,
                })}
              >
                <input
                  type="file"
                  name="image"
                  id="image"
                  accept="image/*"
                  label="image"
                  onChange={handleChange}
                />
                <label htmlFor="image">
                  {prevImg ? (
                    <div className="uploader-prev">
                      <img
                        className="img-fluid"
                        alt="droped image"
                        src={prevImg}
                      />
                      <span className="replace-btn">Replace</span>
                    </div>
                  ) : (
                    <div className="upload-placeholder text-center">
                      <i
                        className="fa fa-cloud-upload fa-3x"
                        aria-hidden="true"
                      ></i>
                      <h3>Upload Document</h3>
                    </div>
                  )}
                </label>
              </div>
            </Form.Group>
          </div>
          <button className="mt-4 btn-save" onClick={handlecreate}>
            Submit
          </button>
        </div>
      </div>
      <Toaster />
    </>
  );
};

const mapStateToProp = (state) => ({
  createfieldmapReducer: state.createfieldmapReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createfieldmaphandler: (user) => dispatch(createfieldmap(user)),
  resetcreatefieldmapReducer: () => dispatch({ type: RESET_CREATE_FIELD_MAP }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Addfieldmap));
