import {EDIT_GROUP_ERROR, EDIT_GROUP_LOADING, EDIT_GROUP_SUCCESS, RESET_EDIT_GROUP } from "../../constants";

let initialState = {
  loading: false,
  user: [{}],
  error: false,
  errors: [],
  success: false,
  payload: {}


};

export default function editGroupReducer(state = initialState, action) {
  switch (action.type) {

    case EDIT_GROUP_LOADING:
      return { ...state, error: false, loading: true, success: false };

    case EDIT_GROUP_SUCCESS:
      return { ...state, error: false, loading: false, success: true,message:action.msg };

    case EDIT_GROUP_ERROR:
      return { ...state, error: true, loading: false, success: false, errors: [] }

    case RESET_EDIT_GROUP:
        return {...state,error:false,loading:false,success:false,errors:[],user:[{}]}  

    default:
      return state;
  }
}


