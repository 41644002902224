import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { connect, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createOrgRoster,
  getTeamDetails,
} from "../../../service/action/userAction";
import { RESET_CREATE_ORG_ROSTER } from "../../../service/constants";

const CreateRosterOrg = (props) => {
  const { id } = useParams();

  const history = useHistory();

  const notify = (msg) => toast(msg);

  const getTeamdetails = useSelector(
    (state) => state.getTeamDetailsReducer.user.data
  );
  const loading = useSelector((state) => state.getTeamDetailsReducer.loading);

  // const [rosterName, setRosterName] = useState("");
  const [error, setError] = useState(false);

  const initialState = {
    roster: "",
    team: "",
  };

  const [state, setState] = useState({
    roster: "",
    team: "",
  });

  const [errors, setErrors] = useState({
    roster: false,
    team: false,
  });

  const clearState = () => {
    setState({ ...initialState });
  };

  function resetData() {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }

  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
      // [e.target.name]: e.target.value.trim()
    }));
    // setRosterName(e.target.value);
    // if (e.target.value === "") {
    //   setError("Enter Roster Name");
    // } else {
    //   setError(false);
    // }

    switch (name) {
      case "roster":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            roster: "roster name can not be empty",
          }));

          document.getElementById("roster").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            roster: false,
          }));

          document.getElementById("roster").classList.remove("error");
        }
        break;
      case "team":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            team: "team name can not be empty",
          }));

          document.getElementById("team").classList.add("error");
        } else {
          setErrors((prevState) => ({
            ...prevState,
            team: false,
          }));
          document.getElementById("team").classList.remove("error");
        }
        break;

      default:
    }
  };

  useEffect(() => {
    props.getTeamDetailsHandler({ id: id });
  }, []);

  useEffect(() => {
    if (!loading) {
      setState((prevState) => ({
        ...prevState,
        team: getTeamdetails?.club?.team_name,
        division:
          getTeamdetails?.division == null
            ? "null"
            : getTeamdetails?.division?.division_name,
        pool:
          getTeamdetails?.pool == null
            ? "null"
            : getTeamdetails?.pool?.pool_name,
        image: getTeamdetails?.team?.image,
        player: getTeamdetails?.player,
      }));
    }
  }, [!loading]);

  // const createRoster = () => {
  //   if (rosterName === "") {
  //     setError("Enter Roster Name");
  //   }
  //   if (rosterName !== "") {
  //     props.createOrgRoster({
  //       team: id,
  //       roster: rosterName,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (props.roster.success) {
  //     notify("Create Roster ");
  //     props.resetRoster();
  //     setTimeout(() => {
  //       history.goBack();
  //     }, 3000);
  //   }
  // }, [props.roster]);

  const submitRequest = (e) => {
    e.preventDefault();
    // alert("click")

    if (state.roster == "" && state.team == "") {
      setErrors((prevState) => ({
        ...prevState,
        roster: "Roster name can not be empty",
        team: "Team name can not be empty",
      }));
      document.getElementById("roster").classList.add("error");
      document.getElementById("team").classList.add("error");
    }

    // if (state.division_type == "") {
    //   setErrors((prevState) => ({
    //     ...prevState,
    //     division_type: "Please Select Division type",
    //   }));
    //   document.getElementById("division_type").classList.add("error");
    // }

    if (state.roster !== "" && state.team !== "") {
      props.createOrgRoster({
        event_id: id,
        roster: state.roster,
        team: id,
        // max_participated: state.max_participated,
      });
      // if (props.submitRequest?.success) {
      //     notify("Data submitted successfully.")
      // }
      // resetData().then(clearState);
    }

    // if (state.division_type !== "") {
    //   props.createDivisionhandler({
    //     event_id: id,
    //     type: state.division_type,
    //   });
    // }
    history.push(`/team-details/${id}`);
  };
  useEffect(() => {
    if (props.submitRequest?.success) {
      notify("roster Created");
      resetData().then(clearState);
      props.resetRoster();
      history.push(`/event-details/${id}`);
      // return <Redirect to={`/event-details/${id}`}/>;
    } else if (props.submitRequest?.error) {
      notify("roster Error");
    }
  }, [props.submitRequest]);


  const nextBtnAction = () => {
    history.push(`/team-details/player/${id}`);
    const showSubmitebtn = document.getElementById("submite-btn");
    showSubmitebtn.style.display = "block";

    const InputShow = document.getElementById("input-show");
    InputShow.style.display = "block";

    const HideNextbtn = document.getElementById("next-btn");
    HideNextbtn.style.display = "none";
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="text-left">
          <h5 className="dash-head">Create New Roster</h5>
        </div>

        <div className="row mx-0">
          <div className="col-md-4 col-xl-4">
            <div className="mt-24">
              <div className="text-left">
                <label className="text-gray fs-13">
                  Roster Name <span className="text-red fw-m">*</span>
                </label>
                <input
                  type="text"
                  className="form-control team-name"
                  id="roster"
                  name="roster"
                  value={state.roster}
                  placeholder="Roster Name *"
                  onChange={eventHandler}
                />

                {error.roster && (
                  <span className="text-red small d-block">* {error}</span>
                )}
              </div>

              <div
                className="text-left"
                id="input-show"
                style={{ display: "none" }}
              >
                <div>
                  {getTeamdetails?.player?.map((v, i) => {
                    return (
                      <>
                        <div className="row ml-2 mt-2">
                          {" "}
                          <div value={v._id} key={i} className="col-6">
                            {v.name}
                          </div>
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
                {error.team && (
                  <span className="text-red small">* {error}</span>
                )}
              </div>

              <div
                className="mt-3 text-left"
                style={{ display: "block" }}
                id="next-btn"
              >
                <div
                  className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                  onClick={nextBtnAction}
                >
                  Next
                </div>
              </div>
              <div className="mt-3 text-left">
                <div
                  className="btn btn-primary btn-lg font-weight-medium auth-form-btn "
                  id="submite-btn"
                  onClick={submitRequest}
                  style={{ display: "none" }}
                >
                  Submit
                </div>
              </div>
            </div>
          </div>
        </div>
        <Toaster />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitRequest: state.createRosterReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createOrgRoster: (user) => dispatch(createOrgRoster(user)),
  getTeamDetailsHandler: (user) => dispatch(getTeamDetails(user)),
  resetRoster: () => dispatch({ type: RESET_CREATE_ORG_ROSTER }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateRosterOrg);
