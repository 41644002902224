import { ACCEPT_PARENT_ERROR, ACCEPT_PARENT_LOADING, ACCEPT_PARENT_SUCCESS, RESET_ACCEPT_PARENT } from "../../../constants";

let initialState={
    error:false,
    success:false,
    loading:false,
    errors:[]
}

export default function acceptParentReducer(state=initialState,action){
    switch(action.type){
        case ACCEPT_PARENT_LOADING:
            return{...state,error:false,loading:true,success:false}
        case ACCEPT_PARENT_SUCCESS:
            return {...state,error:false,loading:false,success:true,message:action.msg}
        case ACCEPT_PARENT_ERROR:
            return {...state,error:true,errors:[],success:false,loading:false}
        case RESET_ACCEPT_PARENT:
            return {...state,error:false,loading:false,success:false,errors:[]}  
        default:
            return state;
    }
}