import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import {
  deleteOrgAdminGroup,
  getAdminList,
  getOrgAdminPermissionList,
} from "../../service/action/userAction";
import {
  RESET_DELETE_ADMIN_PERMISSION_GROUP,
  RESET_GET_ADMIN_LIST,
} from "../../service/constants";
import { Button, Modal } from "react-bootstrap";
import DeletePopUp from "../../club/components/DeletePopUp/DeletePopUp";
import { toast } from "react-toastify";
import Toaster from "../../Toaster/Toaster";

function GroupUserDetails(props) {
  const { id } = useParams();

  const [details, setDetails] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!!id) {
      if (!props.getAdminPermissionListReducer.success) {
        props.getOrgAdminPermissionList();
      } else if (props.getAdminPermissionListReducer.success) {
        const data = props.getAdminPermissionListReducer?.data?.data;
        const _details = data?.find((e) => e._id === id);

        if (_details !== undefined && Object.keys(_details)?.length !== 0) {
          setDetails(_details);
        } else {
          history.push("/admin-permission");
        }
      }
    }
  }, [id, props.getAdminPermissionListReducer]);

  const handleDelete = () => {
    props.deleteOrgAdminGroup(details._id);
  };

  useEffect(() => {
    if (props.deleteAdminGroupReducer.success) {
      props.resetDelete();
      toast("Permission Group Deleted Succesfully");
      setTimeout(() => {
        history.push("/admin-permission");
      }, 2500);
    }
  }, [props.deleteAdminGroupReducer]);

  useEffect(() => {
    if (props.getAdminListReducer.success) {
      setIsOpen(true);
      const data = props.getAdminListReducer.data;
      props.resetAddAdmin();
    }
  }, [props.getAdminListReducer]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div>
            <h4>Account Users</h4>
          </div>

          <div className="mt-3">
            <div
              className="border pointer p-2 m-2 d-flex justify-content-between align-items-center admin_group"
              // onClick={() => history.push(`/permission/${details?._id}/permission`)}
            >
              <div className=" text-danger">{details?.group_name}</div>
              <div className="dropdown">
                <i
                  className="mdi mdi-dots-vertical"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <Link
                    className="dropdown-item px-2"
                    to={`/admin-permission/${details?._id}/permission`}
                  >
                    <i className="mdi mdi-account-plus action-ico"></i> Manage
                    Permission
                  </Link>
                  <Link
                    className="dropdown-item px-2"
                    to={"#"}
                    onClick={() => {
                      props.getAdminList();
                    }}
                  >
                    <i className="mdi mdi-plus action-ico"></i> Add Users
                  </Link>
                  <Link
                    className="dropdown-item px-2"
                    to={"#"}
                    onClick={() => setIsDelete(true)}
                  >
                    <i className="mdi mdi-delete action-ico"></i> Delete
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeletePopUp
        isOpen={isDelete}
        isClose={setIsDelete}
        handleEvent={handleDelete}
      />

      <Modal
        show={isOpen}
        onHide={() => setIsOpen(false)}
        backdrop="static"
        keyboard={false}
        centered
        // style={{ color: "#143d4a" }}
      >
        <Modal.Header>Add Admin</Modal.Header>
        <Modal.Body>
          <div></div>
        </Modal.Body>
        <div className="d-flex justify-content-center mt-3 mb-3">
          <Button
            className=""
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>

          <Button style={{ marginLeft: "20px" }}>Confirm</Button>
        </div>
      </Modal>
      <Toaster />
    </React.Fragment>
  );
}

const mapStateToProp = (state) => ({
  getAdminPermissionListReducer:
    state.OrgPermissionReducer.getAdminPermissionList,
  deleteAdminGroupReducer: state.OrgPermissionReducer.deleteAdminGroup,
  getAdminListReducer: state.OrgPermissionReducer.getAdminList,
});

const mapDispatchToProps = (dispatch) => ({
  getOrgAdminPermissionList: (user) =>
    dispatch(getOrgAdminPermissionList(user)),
  getAdminList: (id) => dispatch(getAdminList(id)),
  deleteOrgAdminGroup: (id) => dispatch(deleteOrgAdminGroup(id)),
  resetDelete: (id) => dispatch({ type: RESET_DELETE_ADMIN_PERMISSION_GROUP }),
  resetAddAdmin: (id) => dispatch({ type: RESET_GET_ADMIN_LIST }),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(GroupUserDetails));
