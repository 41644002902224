import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  getAllCity,
  getAllState,
  getTermsAndConditions,
} from "../../service/action/userAction";
import { useSelector, useDispatch } from "react-redux";
import ReactInputMask from "react-input-mask";
import TermsPop from "./TermsPop";
import { Checkbox } from "@material-ui/core";

const CoachSingupForm = ({ state, eventhandler, errors, handleSubmit }) => {
  const dispatch = useDispatch();
  const allstate = useSelector((state) => state.getStateReducer.states);
  const allcity = useSelector((state) => state.getCityReducer.citys);

  //   Handeling Terms and conditions

  const [open, setOpen] = React.useState(false);
  const [agree, setAgree] = useState(false);
  const [termText, setTermText] = useState("");
  const termsAndConditions = useSelector(
    (state) => state.termsAndConditionsReducer?.payload?.data?.liability
  );

  const handleClickOpen = () => {
    if (agree) {
      setAgree(false);
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setAgree(true);
    setOpen(false);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const checked = { checked: agree ? true : false };

  useEffect(() => {
    dispatch(getTermsAndConditions({ title: "coach accept terms of use" }));
  }, []);

  useEffect(() => {
    if (termsAndConditions) {
      setTermText(termsAndConditions);
    }
  }, [termsAndConditions, termText]);

  //   End Terms and conditions state and logics

  useEffect(() => {
    const stateID = allstate.find((v) => v.name === state.state)?._id;
    if (stateID !== undefined) {
      dispatch(getAllCity({ id: stateID }));
    }
    dispatch(getAllState());
  }, [state.state]);

  return (
    <>
      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {
            error: errors.first_name,
          })}
          placeholder="First Name*"
          id="first_name"
          name="first_name"
          value={state.first_name}
          onChange={(e) => eventhandler(e)}
        />
        {errors.first_name && (
          <span className="text-red small d-block">Enter first name</span>
        )}
      </div>
      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {
            // error: errors.first_name,
          })}
          placeholder="Middle Name"
          id="middle_name"
          name="middle_name"
          value={state.middle_name}
          onChange={(e) => eventhandler(e)}
        />
      </div>
      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {
            error: errors.last_name,
          })}
          placeholder="Last Name*"
          id="last_name"
          name="last_name"
          // value={x.last_name}
          onChange={(e) => eventhandler(e)}
        />
        {errors.last_name && (
          <span className="text-red small d-block">Enter last name</span>
        )}
      </div>

      <div className="mt-18">
        <input
          type="text"
          className={classNames("form-control team-name", {
            error: errors.address,
          })}
          placeholder="Address*"
          id="address"
          name="address"
          // value={x.last_name}
          onChange={(e) => eventhandler(e)}
        />
        {errors.address && (
          <span className="text-red small d-block">Enter last address</span>
        )}
      </div>

      <div className="mt-18">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            value={state.DOB}
            clearable
            placeholder="DOB"
            onChange={(e) =>
              eventhandler({
                target: {
                  value: e,
                  name: "DOB",
                  id: "DOB",
                },
              })
            }
            maxDate={new Date("01-01-2005")}
            format="MM/dd/yyyy"
            mask="__/__/____"
            inputVariant="outlined"
            autoOk={true}
            InputAdornmentProps={{ position: "end" }}
            className={classNames("form-control team-name", {
              //   error: DOB,
            })}
          />
        </MuiPickersUtilsProvider>
        {errors.DOB == "required" && (
          <span className="text-red small d-block">Enter birth date</span>
        )}
      </div>

      <div className="mt-18">
        <select
          className={classNames("form-control team-name", {
            error: errors.state,
          })}
          placeholder="State *"
          id="state"
          name="state"
          value={state.state}
          onChange={eventhandler}
        >
          <option value="">State *</option>
          {allstate.map((v, i) => {
            return (
              <option key={v._id} value={v._id}>
                {v.name}
              </option>
            );
          })}
        </select>
        {errors.state && (
          <span className="text-red small d-block">
            Select state {errors.state}
          </span>
        )}
      </div>

      <div className="mt-18">
        <input
          type="text"
          list="cityNameList"
          className={classNames("form-control team-name", {
            error: errors?.city,
          })}
          placeholder="City *"
          name="city"
          id="city"
          value={state.city}
          onChange={eventhandler}
          autoComplete="off"
        />
        {errors?.city && (
          <span className="text-red small d-block">Enter city</span>
        )}

        <datalist id="cityNameList">
          {allcity.map((e, i) => {
            return <option value={e.name} key={i} />;
          })}
        </datalist>
      </div>

      <div className="mt-18">
        <ReactInputMask
          mask="99999"
          className="form-control team-name quantity"
          value={state.zip}
          onChange={(e) => eventhandler(e)}
          maskChar={"_"}
          placeholder="Zip *"
          name="zip"
          id="zip"
        />

        {errors.zip && (
          <span className="text-red small d-block">Enter zip code</span>
        )}
      </div>

      <div className="mt-18">
        <input
          type="number"
          className={classNames("form-control team-name", {
            error: errors.telephone,
          })}
          placeholder="Phone *"
          id="telephone"
          name="telephone"
          // value={x.tele}
          onChange={(e) => eventhandler(e)}
        />
        {errors.telephone && (
          <span className="text-red small d-block">Enter phone number.</span>
        )}
      </div>

      <div className="mt-18">
        <input
          type="email"
          className={classNames("form-control team-name", {
            error: errors.email,
          })}
          placeholder="Email *"
          id="email"
          name="email"
          // value={x.tele}
          onChange={(e) => eventhandler(e)}
        />
        {errors.email && (
          <span className="text-red small d-block">Enter email.</span>
        )}
      </div>

      <div className="mt-18">
        <input
          type="password"
          className={classNames("form-control team-name", {
            error: errors.email,
          })}
          placeholder="Password *"
          id="password"
          name="password"
          // value={x.tele}
          onChange={(e) => eventhandler(e)}
        />
        {errors.password && (
          <span className="text-red small d-block">Enter password.</span>
        )}
      </div>

      <div className="mt-18">
        <input
          type="password"
          className={classNames("form-control team-name", {
            error: errors.email,
          })}
          placeholder="Confirm password *"
          id="confirmPassword"
          name="confirmPassword"
          // value={x.tele}
          onChange={(e) => eventhandler(e)}
        />
        {errors.confirmPassword && (
          <span className="text-red small d-block">Password doesn't match</span>
        )}
      </div>

      <div className="mt-18">
        <div className="form-check flex items-center">
          <div onClick={handleClickOpen}>
            <Checkbox {...label} {...checked} />
          </div>
          <label className="form-check-label text-light" for="flexCheckDefault">
            Terms & Condition
          </label>
        </div>
      </div>

      <TermsPop
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        termText={termText}
      />

      <Button
        className="mt-3"
        style={{ backgroundColor: "#189AD6" }}
        onClick={handleSubmit}
      >
        Create
      </Button>
    </>
  );
};

export default CoachSingupForm;
