import React, { useState, useEffect } from "react";
import { withRouter, Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { useSelector, connect } from "react-redux";
import classNames from "classnames";
import Toaster from "../../../Toaster/Toaster";
import {
  getAllDivision,
  getEventTeam,
} from "../../../service/action/userAction";
import Spinner from "../../../Spinner/Spinner";
import { editClubTeam } from "../../../service/action/Club/clubAction";
import { RESET_EDIT_CLUB_TEAM } from "../../../service/constants";
import InputMask from "react-input-mask";

const Edit_Team = (props) => {
  let history = useHistory();
  const { id, id1 } = useParams();
  const allDivision = useSelector(
    (state) => state.getAllDivisionReducer.user.data
  );
  const getEventTeam = useSelector((state) => state.getEventTeamReducer.user);
  const editTeamMsg = useSelector((state) => state.editClubTeamReducer.message);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props.getTeamInfohandler({ id: id });
    props.getAllDivisionHandler({ id: id });
  }, []);

  const initialState = {
    team_name: "",
    club_name: "",
    division: "",
    pool: "",
    coach_name: "",
    coach_email: "",
    coach_mobile: "",
  };

  const [state, setState] = useState({
    team_name: "",
    club_name: "",
    division: "",
    pool: "",
    coach_name: "",
    coach_email: "",
    coach_mobile: "",
  });

  const [pool, setPool] = useState([]);
  const [errors, setErrors] = useState({
    team_name: false,
    club_name: false,
    division: false,
    pool: false,
    coach_name: false,
    coach_email: false,
    coach_mobile: false,
  });

  const [image, setImage] = useState("");
  const [pitcher, setPitcher] = useState({
    file: {},
  });

  useEffect(() => {
    if (getEventTeam.organizer) {
      const team = getEventTeam?.organizer.find((e) => e.team_id._id == id1);
      const getTeam = team;
      if (allDivision) {
        if (getTeam.division) {
          const pool = allDivision.find((e) => e._id == getTeam.division._id);
          const poolList = pool.pool;
          setPool(poolList);
        }
      }

      setState((prevState) => ({
        ...prevState,
        club_name: getTeam.team_id.club_name,
        division: getTeam.division == null ? "null" : getTeam.division._id,
        pool: pool == null ? "null" : pool._id,
        coach_name: getTeam.team_id.coach_name,
        coach_mobile: getTeam.team_id.coach_mobile,
        coach_email: getTeam.team_id.coach_email,
        team_name: getTeam.team_id.team_name,
      }));

      if (getTeam) {
        setImage(
          getTeam.team_id === null || undefined ? "" : getTeam.team_id.image
        );
        setPitcher({
          file: getTeam.team_id.image === null ? "" : getTeam.team_id.image,
        });
      }
    }
  }, [getEventTeam]);

  const clearState = () => {
    setState({ ...initialState });
  };

  function resetData() {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }

  const eventHandler = (e) => {
    const { id, value, name } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    switch (name) {
      case "team_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            team_name: "Team name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            team_name: false,
          }));
        }
        break;
      case "club_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            club_name: "Club name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            club_name: false,
          }));
        }
        break;
      case "division":
        if (value !== "") {
          const pools = allDivision.find((e) => e._id == value);
          const poolList = pools.pool;
          setPool(poolList);
        }
        if (value == 0) {
          setErrors((prevState) => ({
            ...prevState,
            division: "Division can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            division: false,
          }));
        }
        break;
      case "pool":
        if (value == 0) {
          setErrors((prevState) => ({
            ...prevState,
            pool: "pool can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            pool: false,
          }));
        }
        break;
      case "coach_name":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            coach_name: "Coach name can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            coach_name: false,
          }));
        }
        break;
      case "coach_email":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            coach_email: "Coach email can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            coach_email: false,
          }));
        }
        break;
      case "coach_mobile":
        if (value.length == 0) {
          setErrors((prevState) => ({
            ...prevState,
            coach_mobile: "Coach mobile can not be empty",
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            coach_mobile: false,
          }));
        }
        break;
      default:
    }
  };

  const notify = (msg) => toast(msg);
  const onChangePicture = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    if (event.target.files && event.target.files[0]) {
      setPitcher({ file: event.target.files[0] });
    }
  };
  const submitRequest = (e) => {
    e.preventDefault();
    if (state.team_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        team_name: "Team name can not be empty",
      }));
    }
    if (state.club_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        club_name: "Club name can not be empty",
      }));
    }
    if (state.coach_name === "") {
      setErrors((prevState) => ({
        ...prevState,
        coach_name: "Coach name can not be empty",
      }));
    }
    if (state.coach_email === "") {
      setErrors((prevState) => ({
        ...prevState,
        coach_email: "Coach email can not be empty",
      }));
    }
    if (state.coach_mobile === "") {
      setErrors((prevState) => ({
        ...prevState,
        coach_mobile: "Coach mobile can not be empty",
      }));
    }
    if (state.team_name !== "") {
      props.editTeamhandler({
        id: id1,
        event_id: id,
        team_name: state.team_name,
        club_name: state.club_name,
        // coach_name: state.coach_name,
        // coach_email: state.coach_email,
        // coach_mobile: state.coach_mobile,
        division: state.division,
        pool: state.pool,
        image: pitcher.file,
      });
    }
  };

  useEffect(() => {
    if (props.team?.success) {
      setLoading(false);
    }
  }, [props.team]);

  useEffect(() => {
    if (props.editTeam?.success) {
      notify(editTeamMsg.message);
      resetData().then(clearState);
      props.resetClubteamHandler();
      history.push(`/event-details/${id}`);
    } else if (props.editTeam?.error) {
      notify("Team Error");
    }
  }, [props.editTeam]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-center">
            {loading ? <Spinner /> : null}
          </div>
          {!loading && (
            <>
              <div className="text-left">
                <div className="create-team-head-event">
                  <h5 className="dash-head">Edit Team</h5>
                  <button className="btn-done" onClick={submitRequest}>
                    Edit
                  </button>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-10 px-0">
                {/* <h6 className="payment fw-m">Basic Info</h6> */}
                <div className="mt-30 d-flex justify-content-center">
                  <div className="basic-info border-bottom-0">
                    <input
                      type="file"
                      className="input-file file-upload"
                      id="file-upload"
                      onChange={onChangePicture}
                    />
                    <label className="change-pic" htmlFor="file-upload">
                      <img
                        src={
                          image === "" ? "./images/pch-images/pch.svg" : image
                        }
                        className="img-fluid"
                      />
                    </label>
                    <span className="ml-12">Upload Picture</span>
                  </div>
                </div>
                <h6 className="payment mt-30">Team Info</h6>
                <div className="row">
                  <div className="col-md-6 mt-18">
                    <input
                      type="text"
                      placeholder="Team Name *"
                      className="form-control team-name"
                      id="team_name"
                      name="team_name"
                      value={state.team_name}
                      required
                      onChange={eventHandler}
                    />
                    {errors.team_name && (
                      <span className="text-red small d-block">
                        * {errors.team_name}
                      </span>
                    )}
                  </div>
                  {/* <div className="col-md-6 mt-18 ">
                                        <input type="text" className="form-control team-name" placeholder="Club Name *" id="club_name" name="club_name" value={state.club_name} required onChange={eventHandler} />
                                        {errors.club_name &&
                                            <span className='text-red small d-block'>* {errors.club_name}</span>}
                                    </div> */}
                  <div className="col-md-6 mt-18">
                    {allDivision ? (
                      <>
                        <select
                          className={classNames(
                            "form-control team-name payterm",
                            { error: errors.division }
                          )}
                          id="division"
                          name="division"
                          value={state.division}
                          onChange={eventHandler}
                        >
                          <option value="" disabled>
                            Division *
                          </option>
                          {allDivision.map((v, i) => {
                            return (
                              <option key={v._id} value={v._id}>
                                {v.division_name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.division && (
                          <span className="text-red small d-block">
                            * {errors.division}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        <h4>Loading...</h4>
                      </>
                    )}
                  </div>
                  <div className="col-md-6 mt-18">
                    <select
                      className={classNames("form-control team-name payterm", {
                        error: errors.pool,
                      })}
                      id="pool"
                      name="pool"
                      value={state.pool}
                      onChange={eventHandler}
                    >
                      <option value="" disabled>
                        Pool *
                      </option>
                      {pool.map((v, i) => {
                        return (
                          <option key={v._id} value={v._id}>
                            {v.pool_name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.pool && (
                      <span className="text-red small d-block">
                        * {errors.pool}
                      </span>
                    )}
                  </div>
                </div>
                {/* <h6 className="text-left payment mt-30">Coach Info</h6> */}
                {/* <div className="row">
                                    <div className="col-md-12 mt-18">
                                        <input type="text" className="form-control team-name" placeholder="Coach Name *" id="coach_name" name="coach_name" value={state.coach_name} required onChange={eventHandler} />
                                        {errors.coach_name &&
                                            <span className="text-red small d-block">*{errors.coach_name}</span>}
                                    </div>
                                    <div className="col-md-6 mt-18">
                                        <input type="text" className="form-control team-name" placeholder="Coach Email *" id="coach_email" name="coach_email" value={state.coach_email} required onChange={eventHandler} />
                                        {errors.coach_email &&
                                            <span className="text-red small d-block">*{errors.coach_email}</span>}

                                    </div>
                                    <div className="col-md-6 mt-18">
                                        <InputMask mask="999-999-9999" className="form-control team-name" value={state.coach_mobile} onChange={eventHandler} maskChar={"_"} placeholder="Mobile No. *" name="coach_mobile" id="coach_mobile" required />
                                        <input type="number" className="form-control team-name" placeholder="Mobile No. *" id="coach_mobile" name="coach_mobile" value={state.coach_mobile} required onChange={eventHandler} />
                                        {errors.coach_mobile &&
                                            <span className="text-red small d-block">*{errors.coach_mobile}</span>}
                                    </div>
                                </div> */}
              </div>
              <Toaster />
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(EventTeamCreate);
const mapStateToProp = (state) => ({
  submit: state.createTeamReducer,
  team: state.getEventTeamReducer,
  editTeam: state.editClubTeamReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // createTeamhandler: user => dispatch(createTeam(user)),
  // resetCreateTeamReducer:()=>dispatch({type:RESET_CREATE_TEAM}),
  resetClubteamHandler: () => dispatch({ type: RESET_EDIT_CLUB_TEAM }),
  editTeamhandler: (user) => dispatch(editClubTeam(user)),
  getAllDivisionHandler: (user) => dispatch(getAllDivision(user)),
  getTeamInfohandler: (user) => dispatch(getEventTeam(user)),
});
export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Edit_Team));
// 543
