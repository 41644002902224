import {
  GET_REFUND_ERROR,
  GET_REFUND_LOADING,
  GET_REFUND_SUCCESS,
  RESET_GET_REFUND,
} from "../../../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
};

export default function getPaymentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REFUND_LOADING:
      return { ...state, success: false, success: false };

    case GET_REFUND_SUCCESS:
      return { ...state, loading: false, success: true, user: action.user };

    case GET_REFUND_ERROR:
      return { ...state, error: true, errors: action.errors };

    case RESET_GET_REFUND:
      return {
        ...state,
        error: false,
        success: false,
        loading: false,
        user: [],
      };

    default:
      return state;
  }
}
