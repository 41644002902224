import React from 'react'
import $ from "jquery";
import { useHistory,withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';






const Org_PaymentRef = () => {


    const green_tbl = [
        {
            invoicecodename: "Rio",
            amout: "200",
            amountdue: "20%",
            balancedueday: "7"

        },
        {
            invoicecodename: "Jhon",
            amout: "500",
            amountdue: "25%",
            balancedueday: "9"

        }
    ]

    return (
        <>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="text-right">
                        {/* <div >

                            <div className="mt-3 mt-md-0 text-right create-team-head-btn">
                                <Link to="/createInvoice">
                                    <button className="btn-create-new ">Create New</button>
                                </Link>
                            </div>
                        </div> */}
                    </div>
                    <div className="green-section mt-30">
                        <h6 className="text-left green-head">Payment Refunded List</h6>
                        <div className="table-responsive d-none d-md-block">
                            <table className="table green-table mt-24">
                                <thead>
                                    <tr>
                                        <th>Code Name</th>
                                        <th>Amount</th>
                                        <th>Amount Due(%)</th>
                                        <th>Balance Due Day</th>
                                        <th></th>

                                    </tr>
                                </thead>

                                {/* Invoice data callS */}

                                <tbody>
                                    {
                                        green_tbl.map((v, i) => {
                                            return <tr className="pay-row" key={i}>
                                                <td><span className="filter-string">{v.invoicecodename}</span></td>
                                                <td>{v.amout}</td>
                                                <td>{v.amountdue}</td>
                                                <td>{v.balancedueday}</td>
                                                <td>
                                                            <div className="dropdown event-drp">
                                                                <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                                {/* More */}
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                    <a className="dropdown-item" href="#">
                                                                    
                                                                    Refund(% or $) </a>
                                                                    <a className="dropdown-item" href="#">
                                                                    Issue Credit(% or $)</a>
                                                                    <a className="dropdown-item" href="#">
                                                                    Apply Credit(% or $)</a>
                                                                </div>
                                                            </div>
                                                        </td>

                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                            {/* Mobile view */}

                            <div className="mt-30 d-md-none">
                            <div className="mt-4">
                                {
                                    green_tbl.map((v, i) => {
                                        return <div className="d-flex justify-content-between reg-details" key={i}>
                                            <div className="d-flex">
                                                <div className="reg-content">
                                                    <Link className="text-decoration-none text-blue fw-m" to="/event-details">{v.recipient}</Link>
                                                    <span className="d-block text-gray mt-2">Amount: {v.event}</span>
                                                    <span className="d-block text-gray mt-2">ExpirationDate: {v.invoice}</span>
                                                    <span className="d-block text-gray mt-2">ExpirationDate: {v.date}</span>
                                                    <span className="d-block text-gray mt-2">ExpirationDate: {v.payment_id}</span>

                                                </div>
                                            </div>
                                            <div className="dropdown event-drp">
                                                <i className="mdi mdi-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <a className="dropdown-item" href="#">
                                                        Refund(% or $)
                                                    </a>
                                                    <a className="dropdown-item" href="#">
                                                        Issue Credit(% or $)</a>
                                                    <a className="dropdown-item" href="#">
                                                        Apply Credit(% or $)</a>
                                                </div>
                                            </div>


                                        </div>
                                    })
                                }

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default withRouter(Org_PaymentRef);
