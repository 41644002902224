import {
  GET_LIABILITY_TERMS_ERROR,
  GET_LIABILITY_TERMS_LOADING,
  GET_LIABILITY_TERMS_SUCCESS,
} from "../constants";

let initialState = {
  loading: false,
  user: [],
  error: false,
  errors: [],
  success: false,
  payload: {},
};

export default function getLiabilityTermsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIABILITY_TERMS_LOADING:
      // alert(" reducer succesfull")
      return { ...state, success: false, success: false };

    case GET_LIABILITY_TERMS_SUCCESS:
      // alert(" reducer Error")
      return { ...state, loading: false, success: true, user: action.payload };

    case GET_LIABILITY_TERMS_ERROR:
      // alert(" reducer Loading")
      return { ...state, error: true, errors: action.errors };

    default:
      return state;
  }
}
